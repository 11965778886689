import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';

const StarScoreBar = (props) => {
  const starColor = props.starColor || "#4fa2f3";
  if (props.score >= props.maxScore) {
    return (
      <div>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
      </div>
    )
  } else if (props.score >= props.maxScore * .9) {
    return (
      <div>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStarHalfAlt} color={starColor}/>
      </div>
    );
  } else if (props.score >= props.maxScore * .8) {
    return (
      <div>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
      </div>
    );
  } else if (props.score >= props.maxScore * .7) {
    return (
      <div>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStarHalfAlt} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
      </div>
    );
  } else if (props.score >= props.maxScore * .6) {
    return (
      <div>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
      </div>
    );
  }  else if (props.score >= props.maxScore * .5) {
    return (
      <div>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStarHalfAlt} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
      </div>
    );
  }  else if (props.score >= props.maxScore * .4) {
    return (
      <div>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
      </div>
    );
  }  else if (props.score >= props.maxScore * .3) {
    return (
      <div>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStarHalfAlt} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
      </div>
    );
  }  else if (props.score >= props.maxScore * .2) {
    return (
      <div>
        <FontAwesomeIcon icon={faStar} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
      </div>
    );
  }  else if (props.score >= props.maxScore * .1) {
    return (
      <div>
        <FontAwesomeIcon icon={faStarHalfAlt} color={starColor}/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
      </div>
    );
  } else {
    return (
      <div>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
        <FontAwesomeIcon icon={faStar} color="#d9d9d9"/>
      </div>
    );
  }
};

export default StarScoreBar;
