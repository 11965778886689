import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartArea,
  faClipboardList,
  faDesktop,
  faEnvelope,
  faFileAlt,
  faFolderOpen,
  faGlobe,
  faMagnifyingGlass,
  faNewspaper,
  faPodcast,
  faStarHalfAlt,
  faUsers,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import {
  faTwitter,
  faFacebook,
  faLinkedin,
  faInstagram,
  faYoutube,
  faGoogle,
  faReddit,
} from '@fortawesome/free-brands-svg-icons';

const channelSiteIconMap = {
  1: { icon: faTwitter, color: '#08a0e9' }, // Twitter
  2: { icon: faFacebook, color: '#4267B2' }, // Facebook
  3: { icon: faLinkedin, color: '#2867B2' }, // LinkedIn
  4: { icon: faInstagram, color: '#c13584' }, // Instagram
  5: { icon: faYoutube, color: '#ff0000' }, // YouTube
  6: { icon: faUsers, color: '##6c757d' }, // All Social
  7: { icon: faStarHalfAlt, color: '#ffd700' }, // All Reviews
  8: { icon: faEnvelope, color: '#6c757d' }, // Campaigns
  9: { icon: faEnvelope, color: '#6c757d' }, // Email Collector
  10: { icon: faNewspaper, color: '#6c757d' }, // News Sites
  11: { icon: faGlobe, color: '#6c757d' }, // Site Crawler
  12: { icon: faFileAlt, color: '#6c757d' }, // Blog Crawler
  13: { icon: faGoogle, color: '#4285f4' }, // Google Analytics
  14: { icon: faDesktop, color: '#6c757d' }, // Webinar Detector
  15: { icon: faMagnifyingGlass, color: '#6c757d' }, // SERP-API
  16: { icon: faGlobe, color: '#6c757d' }, // External-AWIS
  17: { icon: faClipboardList, color: '#6c757d' }, // Survey
  18: { icon: faGlobe, color: '#6c757d' }, // Moz
  19: { icon: faGlobe, color: '#6c757d' }, // Semrush
  20: { icon: faPodcast, color: '#6c757d' }, // Podcast
  21: { icon: faUser, color: '#6c757d' }, // Customer
  22: { icon: faChartArea, color: '#6c757d' }, // Industry Analysts
  23: { icon: faReddit, color: '#ff4500' }, // Reddit
  24: { icon: faStarHalfAlt, color: '#ffd700' }, // Employer-Reviews
  25: { icon: faFolderOpen, color: '#6c757d' }, // Other
};

export const getMetricIcon = (metricChannelSiteId) => {
  if (channelSiteIconMap[metricChannelSiteId]) {
    return (
      <FontAwesomeIcon
        icon={channelSiteIconMap[metricChannelSiteId].icon}
        color={channelSiteIconMap[metricChannelSiteId].color}
      />
    )
  } else {
    return (
      <FontAwesomeIcon
        icon={faFolderOpen}
        color="#6c757d"
      />
    )
  }
}
