import React from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class NewsManualAddModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newsArticleUrl: '',
      addNewsArticleLoading: false,
      errors: {},
      errorMessage: '',
    };
  }

  onOpen = () => {
    this.setState(() => ({
      newsArticleUrl: '',
      addNewsArticleLoading: false,
      errors: {},
      errorMessage: '',
    }));
  };

  onNewsArticleUrlChange = (event) => {
    const newsArticleUrl = event.currentTarget.value;
    this.setState(() => ({ newsArticleUrl }));
  };

  isValidForm = () => {
    const errors = {};
    let errorMessage = '';
    if (!this.state.newsArticleUrl || this.state.newsArticleUrl.trim() === '') {
      errors.newsArticleUrl = true;
      errorMessage = 'Must provide valid news article URL.';
    }

    this.setState(() => ({ errors }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  addNewsArticle = () => {
    if (this.isValidForm) {
      this.setState(() => ({ addNewsArticleLoading: true }));
      const newNewsArticle = {
        news_article_url: this.state.newsArticleUrl,
        product_brand_id: this.props.productBrand.id,
      };

      axios.post(
        `${CONTENT_SCRAPING_ENDPOINT}/api/news-articles`,
        newNewsArticle,
        HEADERS
      ).then(response => {
        this.props.refreshNewsArticles();
        this.props.handleClose();
        this.setState(() => ({ addNewsArticleLoading: false }));
      }).catch(error => {
        console.error(`Error: failed to add manual news article`);
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        this.setState(() => ({
          addNewsArticleLoading: false,
          errorMessage: 'Failed to add news article.'
        }));
      });
    }
  };

  render() {
    return (
      <Modal
        show={this.props.isOpen}
        onHide={this.props.handleClose}
        onEntering={this.onOpen}
        size="md"
      >
        <Modal.Header
          className="bg-bops-blue text-light"
          closeButton
        >
          <Modal.Title>
            Add News Article
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              News Article Url
            </Form.Label>
            <Form.Control
              type="text"
              value={this.state.newsArticleUrl}
              onChange={this.onNewsArticleUrlChange}
              isInvalid={this.state.errors.newsArticleUrl}
              size="sm"
            />
          </Form.Group>
          <Alert
            show={Boolean(this.state.errorMessage)}
            className="mt-4"
            variant="danger"
          >
            {this.state.errorMessage}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => this.props.handleClose()}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => this.addNewsArticle()}
            disabled={this.state.addNewsArticleLoading}
          >
            { this.state.addNewsArticleLoading &&
              <span className="mr-1">
                <ClipLoader
                  size={15}
                  color="ffffff"
                />
              </span>
            }
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
};
