import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GeneralSummaryGraph from '../../graphs/GeneralSummaryGraph';
import GoogleAnalyticsStackedGraph from './GoogleAnalyticsStackedGraph';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class GoogleAnalyticsSourceTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gaTrafficSourceTrendData: [],
      gaTrafficSourceTrendDataLoading: false,
    }
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchTrafficSourceTrendData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.companyId !== this.props.companyId) {
      this.fetchTrafficSourceTrendData();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchTrafficSourceTrendData = () => {
    if (this.props.companyId) {
      this.setState(() => ({ gaTrafficSourceTrendDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/ga-traffic-source-trend?company_id=${this.props.companyId}&source_metrics=all`,
        HEADERS
      ).then(response => {
        const gaTrafficSourceTrendData = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            gaTrafficSourceTrendData,
            gaTrafficSourceTrendDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch google analytic traffic source trend data...`);
        if (this.state.isMounted) {
          this.setState(() => ({
            gaTrafficSourceTrendData: [],
            gaTrafficSourceTrendDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="my-2">
        <Row>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GoogleAnalyticsStackedGraph
                title="Referral, Direct, Paid Sessions"
                category={this.props.category}
                brand={this.props.brand}
                data={this.state.gaTrafficSourceTrendData}
                barsData={[
                  { name: 'Referral Sessions', key: 'referral_sessions' },
                  { name: 'Direct Sessions', key: 'direct_sessions' },
                  { name: 'Paid Sessions', key: 'paid_sessions' },
                ]}
                loading={this.state.gaTrafficSourceTrendDataLoading}
                allowDecimals={false}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Google Search"
                data={this.state.gaTrafficSourceTrendData}
                dataKey="google_organic_traffic"
                dataLabel="Google Search"
                loading={this.state.gaTrafficSourceTrendDataLoading}
                allowDecimals={false}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Web Analytics"
                contextChartName="Google Search"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Google Paid Traffic (Ads)"
                data={this.state.gaTrafficSourceTrendData}
                dataKey="google_paid_traffic"
                dataLabel="Google Paid Traffic (Ads)"
                loading={this.state.gaTrafficSourceTrendDataLoading}
                allowDecimals={false}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Web Analytics"
                contextChartName="Google Paid Traffic (Ads)"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GoogleAnalyticsStackedGraph
                title="LinkedIn"
                category={this.props.category}
                brand={this.props.brand}
                data={this.state.gaTrafficSourceTrendData}
                barsData={[
                  { name: 'Referral', key: 'referral_linkedin' },
                  { name: 'Paid', key: 'paid_linkedin' },
                ]}
                loading={this.state.gaTrafficSourceTrendDataLoading}
                allowDecimals={false}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GoogleAnalyticsStackedGraph
                title="Twitter"
                category={this.props.category}
                brand={this.props.brand}
                data={this.state.gaTrafficSourceTrendData}
                barsData={[
                  { name: 'Referral', key: 'referral_twitter' },
                  { name: 'Paid', key: 'paid_twitter' },
                ]}
                loading={this.state.gaTrafficSourceTrendDataLoading}
                allowDecimals={false}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GoogleAnalyticsStackedGraph
                title="Facebook"
                category={this.props.category}
                brand={this.props.brand}
                data={this.state.gaTrafficSourceTrendData}
                barsData={[
                  { name: 'Referral', key: 'referral_facebook' },
                  { name: 'Paid', key: 'paid_facebook' },
                ]}
                loading={this.state.gaTrafficSourceTrendDataLoading}
                allowDecimals={false}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GoogleAnalyticsStackedGraph
                title="Instagram"
                category={this.props.category}
                brand={this.props.brand}
                data={this.state.gaTrafficSourceTrendData}
                barsData={[
                  { name: 'Referral', key: 'referral_instagram' },
                  { name: 'Paid', key: 'paid_instagram' },
                ]}
                loading={this.state.gaTrafficSourceTrendDataLoading}
                allowDecimals={false}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GoogleAnalyticsStackedGraph
                title="Youtube"
                category={this.props.category}
                brand={this.props.brand}
                data={this.state.gaTrafficSourceTrendData}
                barsData={[
                  { name: 'Referral', key: 'referral_youtube' },
                  { name: 'Paid', key: 'paid_youtube' },
                ]}
                loading={this.state.gaTrafficSourceTrendDataLoading}
                allowDecimals={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};
