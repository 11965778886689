import React from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class SlackTokenModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      token: '',
      errors: {},
      errorMessage: '',
    };
  };

  onOpen = () => {

    this.setState(() => ({
      token: '',
      errors: {},
      errorMessage: ''
    }));
  };

  onTokenChange = (event) => {
    const token = event.currentTarget.value;
    this.setState(() => ({ token }));
  };

  isValidForm = () => {
    const errors = {};
    let errorMessage = '';
    if (!this.state.token || this.state.token.trim() === '') {
      errors.token = true;
      errorMessage = 'Must provide a token'
    }

    this.setState(() => ({
      errors,
      errorMessage
    }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  saveSlackToken = () => {
    if (this.isValidForm()) {
      const token = {
        company_id: this.props.companyId,
        token: this.state.token
      };
      if (this.props.companySlackToken) {
        // Update
        axios.put(
          `${LISTEN_ENDPOINT}/api/company-slack-tokens/${this.props.companySlackToken.id}`,
          token,
          HEADERS
        ).then(response => {
          this.props.refreshCompanySlackToken();
          this.props.refreshSlackChannels(this.props.companyId);
          this.props.handleClose();
        }).catch(error => {
          this.setState(() => ({ errorMessage: 'Failed to update company slack token' }));
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      } else {
        // Create
        axios.post(
          `${LISTEN_ENDPOINT}/api/company-slack-tokens`,
          token,
          HEADERS
        ).then(response => {
          this.props.refreshCompanySlackToken();
          this.props.refreshSlackChannels(this.props.companyId);
          this.props.handleClose();
          this.props.triggerConfetti();
        }).catch(error => {
          this.setState(() => ({ errorMessage: 'Failed to create new company slack token' }));
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  render () {
    return (
      <Modal
        size="md"
        show={this.props.isOpen}
        onHide={this.props.handleClose}
        onEntering={this.onOpen}
      >
        <Modal.Header
          className="bg-bops-blue text-light"
          closeButton
        >
          <Modal.Title>
            Add Slack Bot Token
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Bot Token</Form.Label>
              <Form.Control
                type="text"
                value={this.state.token}
                onChange={this.onTokenChange}
              />
            </Form.Group>
          </Form>
          <Alert show={Boolean(this.state.errorMessage)} variant="danger">
            {this.state.errorMessage}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.saveSlackToken}>
            { this.props.companySlackToken ? 'Update' : 'Add'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};
