import { connect } from 'react-redux';
import SSOLoginHandler from '../../components/sso/SSOLoginHandler';
import { ssoLogin } from '../../actions/user/user';

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  handleSSOLogin: (token) => dispatch(ssoLogin(token))
});

export default connect(mapStateToProps, mapDispatchToProps)(SSOLoginHandler);
