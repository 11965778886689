import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactSelect from 'react-select';
import {
  ComposedChart,
  Scatter,
  Line,
  XAxis,
  YAxis,
  Label,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';
import { numberWithCommas } from '../../../../utils/numbers';
import { sortAlphabeticalByKey } from '../../../../utils/sorts';
import { LISTEN_ENDPOINT, HEADERS } from '../../../../utils/constants';
import { dispatchReportError } from '../../../../actions/api/errors';


export default class CorrelationScatter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scatterData: [],
      scatterDataLoading: false,
      selectedFilter: 'all',
      selectedSecondMetric: undefined,
      scatterNotAvailable: false,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.selectedMetricName !== this.props.selectedMetricName ||
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate ||
      prevProps.selectedAudienceSize !== this.props.selectedAudienceSize ||
      prevState.selectedFilter !== this.state.selectedFilter ||
      prevState.selectedSecondMetric !== this.state.selectedSecondMetric
    ) {
      this.fetchCorrelationScatterData();
    }
    if (
      prevProps.selectedMetricIsPrivate === false &&
      this.props.selectedMetricIsPrivate === true &&
      this.state.selectedFilter === 'category'
    ) {
      this.setState(
        () => ({ selectedFilter: 'brand' }),
        () => this.fetchCorrelationScatterData()
      )
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  onSelectedFilterChange = (selectedFilter) => {
    if (this.state.selectedFilter !== selectedFilter) {
      this.setState(
        () => ({ selectedFilter }),
        () => this.fetchCorrelationScatterData()
      );
    }
  }

  onSecondMetricSelect = (selectedOption) => {
    this.setState(
      () => ({ selectedSecondMetric: selectedOption }),
      () => this.fetchCorrelationScatterData()
    );
  };

  fetchCorrelationScatterData = () => {
    if (this.props.selectedMetricName && this.state.selectedSecondMetric) {
      if (
        this.props.brandMetricsMap[this.props.selectedMetricName] &&
        this.props.brandMetricsMap[this.props.selectedMetricName].id &&
        this.state.selectedSecondMetric.metric &&
        this.state.selectedSecondMetric.metric.id
      ) {
        this.setState(() => ({
          scatterDataLoading: true,
          scatterNotAvailable: false,
        }));
        const formattedStartDate = this.props.startDate.format('YYYY-MM-DD HH:mm:ss');
        const formattedEndDate = this.props.endDate.format('YYYY-MM-DD HH:mm:ss');
        let additionalParams = '';
        if (this.state.selectedFilter === 'all') {
          // do nothing
        } else if (this.state.selectedFilter === 'category') {
          additionalParams += `&category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`;
        } else if (this.state.selectedFilter === 'brand') {
          additionalParams += `&product_brand_id=${this.props.brand.id}`;
        }
        if (this.props.selectedAudienceSize !== 'all') {
          additionalParams += `&audience_size=${this.props.selectedAudienceSize}`;
        }
        axios.get(
          `${LISTEN_ENDPOINT}/api/linear-regression-cache-analysis?dependent_metric_name=${this.props.selectedMetricName}&independent_metric_names=${this.state.selectedSecondMetric.value}&start_date=${formattedStartDate}&end_date=${formattedEndDate}${additionalParams}`,
          HEADERS
        ).then(response => {
          const linearRegressionData = response.data;
          const scatterData = linearRegressionData.metric_data || [];
          scatterData.sort((a,b) => a[this.state.selectedSecondMetric.value] - b[this.state.selectedSecondMetric.value]);
          if (this.state.isMounted) {
            this.setState(() => ({
              scatterData,
              scatterDataLoading: false,
            }));
          }
        }).catch(error => {
          console.log(`Error: failed to fetch correlation scatter data`);
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          if (this.state.isMounted) {
            this.setState(() => ({
              scatterData: [],
              scatterDataLoading: false,
            }));
          }
        });
      } else {
        // scatter data not available
        this.setState(() => ({ scatterNotAvailable: true }));
      }
    }
  };

  render () {
    return (
      <div>
        <div className="mt-2 mb-3">
          <Form.Check
            type="radio"
            label="All Brands"
            checked={this.state.selectedFilter === 'all'}
            onChange={() => this.onSelectedFilterChange('all')}
            inline
          />
          <Form.Check
            type="radio"
            label="Category"
            checked={this.state.selectedFilter === 'category'}
            onChange={() => this.onSelectedFilterChange('category')}
            disabled={this.props.selectedMetricIsPrivate === true}
            inline
          />
          <Form.Check
            type="radio"
            label={`Brand (${this.props.brand.name})`}
            checked={this.state.selectedFilter === 'brand'}
            onChange={() => this.onSelectedFilterChange('brand')}
            inline
          />
          <div
            className="d-inline-block ml-3"
            style={{
              fontSize: '.875rem',
              width: '300px'
            }}
          >
            <ReactSelect
              cacheOptions
              value={this.state.selectedSecondMetric}
              options={
                Object.entries(this.props.brandMetricsMap).map(([metricName, metric]) => {
                  return ({
                    label: metric.metric_label,
                    value: metric.metric_name,
                    metric
                  })
                }).sort(sortAlphabeticalByKey('label'))
              }
              onChange={this.onSecondMetricSelect}
              isClearable={true}
              placeholder="Select second metric..."
            />
          </div>
        </div>
        { this.state.scatterNotAvailable &&
          <div className="m-4 text-center">
            <div className="d-inline-block">
              <Alert variant="warning">
                Scatter is not available.
              </Alert>
            </div>
          </div>
        }
        { !this.state.scatterNotAvailable &&
          <div className="mt-4">
            { this.state.scatterDataLoading &&
              <div className="m-4 text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { (this.state.selectedSecondMetric && !this.state.scatterDataLoading) &&
              <ResponsiveContainer width="99%" height={400}>
                <ComposedChart
                  data={this.state.scatterData}
                  margin={{
                    top: 25, right: 30, bottom: 30, left: 30,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    type="number"
                    dataKey={this.state.selectedSecondMetric.value}
                    tick={{ fontSize: 12 }}
                    tickFormatter={(value) => {
                      return numberWithCommas(value);
                    }}
                  >
                    <Label
                      value={this.state.selectedSecondMetric.label}
                      position="bottom"
                      style={{
                        fontSize: '1rem',
                        fontWeight: 'bold'
                      }}
                    />
                  </XAxis>
                  <YAxis
                    type="number"
                    dataKey={
                      this.props.brandMetricsMap[this.props.selectedMetricName] ?
                        this.props.brandMetricsMap[this.props.selectedMetricName].metric_name :
                        ''
                    }
                    tick={{ fontSize: 12 }}
                    tickFormatter={(value) => {
                      return numberWithCommas(value);
                    }}
                  >
                    <Label
                      value={
                        this.props.brandMetricsMap[this.props.selectedMetricName] ?
                          this.props.brandMetricsMap[this.props.selectedMetricName].metric_label :
                          ''
                      }
                      position="left"
                      offset={20}
                      angle={-90}
                      style={{
                        textAnchor: 'middle',
                        fontSize: '1rem',
                        fontWeight: 'bold'
                      }}
                    />
                  </YAxis>

                  <Scatter
                    data={this.state.scatterData}
                    fill="#007bff"
                  />
                  <Line
                    name="Linear Regession"
                    type="monotone"
                    dataKey="y_prediction"
                    stroke="#ff0000"
                    strokeWidth={3}
                    dot={false}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            }
          </div>
        }
      </div>
    );
  }
};
