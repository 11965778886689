import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Tooltip from 'reactjs-popup';
import AddEditCommentReviewScoreModal from './AddEditCommentReviewScoreModal';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faMeh } from '@fortawesome/free-regular-svg-icons';
import { LCX_DISPLAY_EXPRESS_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class Listen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      AddEditCommentReviewScoreModalOpen: false,
      commentId: undefined,
      editCommentReviewScore: undefined,
      deleteCRSConfirmationModalOpen: false,
      deleteCommentReviewScoreId: undefined,
      deleteCommentReviewScoreName: undefined,
      deleteCommentReviewScoreSource: undefined
    };
  }

  openAddCommentReviewScoreModal = (event) => {
    const commentId = event.currentTarget.dataset.commentId;
    this.setState(() => ({
      AddEditCommentReviewScoreModalOpen: true,
      editCommentReviewScore: undefined,
      commentId
    }));
  };

  openEditCommentReviewScoreModal = (editCommentReviewScore) => {
    this.setState(() => ({
      AddEditCommentReviewScoreModalOpen: true,
      editCommentReviewScore,
      commentId: editCommentReviewScore.comment_id
    }));
  };

  closeAddEditCommentReviewScoreModal = () => {
    this.setState(() => ({ AddEditCommentReviewScoreModalOpen: false }));
  };

  openDeleteCRSConfirmationModal = (commentReviewScore) => {
    this.setState(() => ({
      deleteCRSConfirmationModalOpen: true,
      deleteCommentReviewScoreId: commentReviewScore.id,
      deleteCommentReviewScoreName: `${commentReviewScore.touchpoint} - ${commentReviewScore.aspect}`,
      deleteCommentReviewScoreSource: commentReviewScore.scoring_source
    }));
  };

  deleteCommentReviewScore = () => {
    if (this.state.deleteCommentReviewScoreSource === 'moake') {
      axios.put(
        `${LCX_DISPLAY_EXPRESS_ENDPOINT}/api/comment-review-scores/${this.state.deleteCommentReviewScoreId}`,
        { overwrite_crs_id: 0 },
        HEADERS
      ).then(response => {
        this.props.refreshComments();
        this.closeDeleteCRSConfirmationModal();
      }).catch(error => {
        console.error('Error: failed to update comment review score');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    } else {
      axios.delete(
        `${LCX_DISPLAY_EXPRESS_ENDPOINT}/api/comment-review-scores/${this.state.deleteCommentReviewScoreId}`,
        HEADERS
      ).then(response => {
        this.props.refreshComments();
        this.closeDeleteCRSConfirmationModal();
      }).catch(error => {
        console.error('Error: failed to delete comment review score');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  closeDeleteCRSConfirmationModal = () => {
    this.setState(() => ({
      deleteCRSConfirmationModalOpen: false,
      deleteCommentReviewScoreId: undefined,
      deleteCommentReviewScoreName: undefined,
      deleteCommentReviewScoreSource: undefined
    }));
  };

  render () {
    return (
      <div>
        { this.props.comment.comment_review_scores.map(crs => {
            if (crs.overwrite_crs_id || crs.overwrite_crs_id === 0) {
              return;
            } else {
              return (
                <Badge
                  key={`crs${crs.id}`}
                  className="mr-2 mb-2"
                  variant={crs.score > 0 ? 'success' : 'danger'}
                >
                  <Row noGutters>
                    <Col className="m-1">
                      <div className="text-warning" style={{ fontSize: '1rem'}}>
                        { crs.score > 0 ?
                            <span>{`+${crs.score}`}</span>
                          :
                            <span>{crs.score}</span>
                        }
                      </div>
                    </Col>
                    <Col className="mx-2">
                      <div
                        onClick={() => {
                          this.openEditCommentReviewScoreModal(crs);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <div><b>{crs.touchpoint}</b></div>
                        <div>{crs.aspect}</div>
                      </div>
                    </Col>
                    { crs.ignore_score_reason &&
                      <Col className="m-1">
                        <Tooltip
                          trigger = {
                            <span>
                              <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                size="lg"
                              />
                            </span>
                          }
                          position="top center"
                          on="hover"
                          contentStyle={{ borderRadius: '5px' }}
                        >
                          <div className="text-dark">
                            <b>{crs.ignore_score_reason}</b>
                          </div>
                        </Tooltip>
                      </Col>
                    }
                    <Col className="m-1">
                      <FontAwesomeIcon
                        icon={faTimes}
                        color="grey" size="lg"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          this.openDeleteCRSConfirmationModal(crs);
                        }}
                      />
                    </Col>
                  </Row>
                </Badge>
              )
            }
          })
        }
        <Button
          className="align-top"
          variant="outline-secondary"
          size="sm"
          data-comment-id={this.props.comment.id}
          onClick={this.openAddCommentReviewScoreModal}
        >
          <FontAwesomeIcon icon={faMeh} />
        </Button>

        <AddEditCommentReviewScoreModal
          isOpen={this.state.AddEditCommentReviewScoreModalOpen}
          handleClose={this.closeAddEditCommentReviewScoreModal}
          commentId={this.state.commentId}
          editCommentReviewScore={this.state.editCommentReviewScore}
          refreshCustomerComments={this.props.refreshComments}
        />
        <DeleteConfirmationModal
          isOpen={this.state.deleteCRSConfirmationModalOpen}
          handleClose={this.closeDeleteCRSConfirmationModal}
          resourceName={this.state.deleteCommentReviewScoreName}
          deleteResource={this.deleteCommentReviewScore}
        />
      </div>
    );
  }
};
