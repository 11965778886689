import { connect } from 'react-redux';
import SlackShareModal from '../../components/share/SlackShareModal';

const mapStateToProps = (state) => ({
  user: state.user,
  slackChannels: state.slackChannels,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SlackShareModal);
