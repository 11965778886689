import React from 'react';
import axios from 'axios';
import CategoryPodcastsSummary from './CategoryPodcastsSummary';
import CategoryPodcastsAvgGraph from './CategoryPodcastsAvgGraph';
import ShareOfChart from '../graphs/ShareOfChart';
import { round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryPodcasts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryPodcastData: [],
      categoryPodcastDataLoading: false,
      podcastMentionShareOfGraphData: {},
      categoryPodcastMentionShareOfData: [],
      podcastEstimatedReachShareOfData: {},
      categoryPodcastEstimatedReachShareOfData: [],
      categoryPodcastEstimatedReachShareOfDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchPodcastMentionData();
    this.fetchPodcastEstimatedReachData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevProps.categoryId !== this.props.categoryId
    ) {
      this.fetchPodcastMentionData();
      this.fetchPodcastEstimatedReachData();
    }
  };

  fetchPodcastMentionData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryPodcastDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-share-podcast-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const categoryPodcastData = response.data;
        const podcastMentionShareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const categoryPodcastMentionShareOfData = [];
        for (const monthStats of categoryPodcastData) {
          const mentionMonthData = { month: monthStats.month };
          let monthTotalMentions = 0;

          // get totals for percent
          for (const pb of monthStats.product_brands) {
            monthTotalMentions += pb.mention_count;
            // insert graph data
            podcastMentionShareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.mention_count || 0,
            ]);
          }

          // loop again to determine %
          for (const pb of monthStats.product_brands) {
            let mentionPercent = 0;
            if (monthTotalMentions > 0) {
              mentionPercent = round((pb.mention_count/monthTotalMentions)*100, 1);
            }
            mentionMonthData[pb.product_brand_name] = {
              count: pb.mention_count,
              percent: mentionPercent
            }
          }
          categoryPodcastMentionShareOfData.push(mentionMonthData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryPodcastData,
            podcastMentionShareOfGraphData,
            categoryPodcastMentionShareOfData,
            categoryPodcastDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch category share of podcast data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            categoryPodcastData: [],
            podcastMentionShareOfGraphData: {},
            categoryPodcastMentionShareOfData: [],
            categoryPodcastDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchPodcastEstimatedReachData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryPodcastEstimatedReachShareOfDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-share-podcast-estimated-reach?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const categoryPodcastReachData = response.data;
        const podcastEstimatedReachShareOfData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const categoryPodcastEstimatedReachShareOfData = [];
        for (const monthStats of categoryPodcastReachData) {
          const reachMonthData = { month: monthStats.month };
          let monthTotalReach = 0;

          // get totals for percent
          for (const pb of monthStats.product_brands) {
            monthTotalReach += pb.podcast_estimated_reach;
            // insert graph data
            podcastEstimatedReachShareOfData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.podcast_estimated_reach || 0,
            ]);
          }

          // loop again to determine %
          for (const pb of monthStats.product_brands) {
            let reachPercent = 0;
            if (monthTotalReach > 0) {
              reachPercent = round((pb.podcast_estimated_reach/monthTotalReach)*100, 1);
            }
            reachMonthData[pb.product_brand_name] = {
              count: pb.podcast_estimated_reach,
              percent: reachPercent
            }
          }
          categoryPodcastEstimatedReachShareOfData.push(reachMonthData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            podcastEstimatedReachShareOfData,
            categoryPodcastEstimatedReachShareOfData,
            categoryPodcastEstimatedReachShareOfDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch category share of podcast estimate reach data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            podcastEstimatedReachShareOfData: {},
            categoryPodcastEstimatedReachShareOfData: [],
            categoryPodcastEstimatedReachShareOfDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  }

  render () {
    return (
      <div>
        <CategoryPodcastsSummary
          user={this.props.user}
          sixMonths={this.props.sixMonths}
          selectedMonth={this.props.selectedMonth}
          updateSelectedMonth={this.props.updateSelectedMonth}
          category={this.props.category}
          categoryPodcastData={this.state.categoryPodcastData}
          categoryPodcastDataLoading={this.state.categoryPodcastDataLoading}
        />
        <div className="mt-5 mb-4 band-separator" />
        <CategoryPodcastsAvgGraph
          user={this.props.user}
          category={this.props.category}
        />
        <div className="mt-4 mb-2 band-separator" />
        <ShareOfChart
          title="Share of Podcast Mentions"
          data={this.state.podcastMentionShareOfGraphData}
          legendData={this.state.categoryPodcastMentionShareOfData}
          loading={this.state.categoryPodcastDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Podcast"
          contextChartName="Share of Podcast Mentions"
          shareClassName="category-share-of-podcast-mentions-img"
        />
        <ShareOfChart
          title="Share of Podcast Estimated Subscriber Reach"
          data={this.state.podcastEstimatedReachShareOfData}
          legendData={this.state.categoryPodcastEstimatedReachShareOfData}
          loading={this.state.categoryPodcastEstimatedReachShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Podcast"
          contextChartName="Share of Podcast Estimated Subscriber Reach"
          shareClassName="category-share-of-podcast-estimated-subscriber-reach-img"
        />
      </div>
    );
  }
};
