import React from 'react';
import axios from 'axios';
import CategoryFacebookSummary from './CategoryFacebookSummary';
import CategoryFacebookTopPosts from './CategoryFacebookTopPosts';
import ShareOfChart from '../../graphs/ShareOfChart';
import { round } from '../../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class CategoryFacebook extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryFacebookShareOfData: [],
      ownedPostsShareOfGraphData: {},
      categoryOwnedPostsShareOfData: [],
      promoterShareOfGraphData: {},
      categoryPromoterSentimentShareOfData: [],
      detractorShareOfGraphData: {},
      categoryDetractorSentimentShareOfData: [],
      categoryFacebookShareOfDataLoading: false,
      netFollowersShareOfGraphData: {},
      categoryNetFollowersShareOfData: [],
      categoryNetFollowersShareOfDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchFacebookData();
    this.fetchNetNewFollowersData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevProps.category !== this.props.category
    ) {
      this.fetchFacebookData();
      this.fetchNetNewFollowersData();
    }
  };

  fetchFacebookData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryFacebookShareOfDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-facebook-brand-sentiments?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const categoryFacebookData = response.data;
        const ownedPostsShareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const promoterShareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const detractorShareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const categoryFacebookShareOfData = [];
        const categoryOwnedPostsShareOfData = [];
        const categoryPromoterSentimentShareOfData = [];
        const categoryDetractorSentimentShareOfData = [];
        for (const monthStats of categoryFacebookData) {
          const facebookMonthData = { month: monthStats.month };
          const ownedPostMonthData = { month: monthStats.month };
          const promoterMonthData = { month: monthStats.month };
          const detractorMonthData = { month: monthStats.month };
          let monthOwnedPostTotalCount = 0;
          let monthPromoterCount = 0;
          let monthDetractorCount = 0;

          // get totals for percent
          for (const pb of monthStats.product_brands) {
            monthOwnedPostTotalCount += pb.total_facebook_posts;
            monthPromoterCount += pb.promoter_count;
            monthDetractorCount += pb.detractor_count;
            // insert graph data
            ownedPostsShareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.total_facebook_posts,
            ]);
            promoterShareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.promoter_count,
            ]);
            detractorShareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.detractor_count,
            ]);
          }

          // loop again to determine %
          for (const pb of monthStats.product_brands) {
            facebookMonthData[pb.product_brand_name] = pb;

            let ownedPostPercent = 0;
            if (monthOwnedPostTotalCount > 0) {
              ownedPostPercent = round((pb.total_facebook_posts/monthOwnedPostTotalCount)*100, 1);
            }
            ownedPostMonthData[pb.product_brand_name] = {
              count: pb.total_facebook_posts,
              percent: ownedPostPercent
            }

            let promoterPercent = 0;
            if (monthPromoterCount > 0) {
              promoterPercent = round((pb.promoter_count/monthPromoterCount)*100, 1);
            }
            promoterMonthData[pb.product_brand_name] = {
              count: pb.promoter_count,
              percent: promoterPercent
            }

            let detractorPercent = 0;
            if (monthDetractorCount > 0) {
              detractorPercent = round((pb.detractor_count/monthDetractorCount)*100, 1);
            }
            detractorMonthData[pb.product_brand_name] = {
              count: pb.detractor_count,
              percent: detractorPercent
            }
          }
          categoryFacebookShareOfData.push(facebookMonthData);
          categoryOwnedPostsShareOfData.push(ownedPostMonthData);
          categoryPromoterSentimentShareOfData.push(promoterMonthData);
          categoryDetractorSentimentShareOfData.push(detractorMonthData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryFacebookShareOfData,
            ownedPostsShareOfGraphData,
            categoryOwnedPostsShareOfData,
            promoterShareOfGraphData,
            categoryPromoterSentimentShareOfData,
            detractorShareOfGraphData,
            categoryDetractorSentimentShareOfData,
            categoryFacebookShareOfDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch category share of facebook data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            categoryFacebookShareOfData: [],
            ownedPostsShareOfGraphData: {},
            categoryOwnedPostsShareOfData: [],
            promoterShareOfGraphData: {},
            categoryPromoterSentimentShareOfData: [],
            detractorShareOfGraphData: {},
            categoryDetractorSentimentShareOfData: [],
            categoryFacebookShareOfDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchNetNewFollowersData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryNetFollowersShareOfDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-facebook-share-of-net-followers?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const netFollowersShareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const categoryNetFollowersShareOfData = [];
        for (const monthStats of response.data) {
          const netFollowersMonthData = { month: monthStats.month };
          let monthNetNewFollowers = 0;

          // get totals for percent
          for (const pb of monthStats.product_brands) {
            monthNetNewFollowers += pb.net_followers;
            // insert graph data
            netFollowersShareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.net_followers || 0,
            ]);
          }

          // loop again to determine %
          for (const pb of monthStats.product_brands) {
            let netNewFollowersPercent = 0;
            if (monthNetNewFollowers > 0) {
              netNewFollowersPercent = round((pb.net_followers/monthNetNewFollowers)*100, 1);
            }
            netFollowersMonthData[pb.product_brand_name] = {
              count: pb.net_followers || 0,
              percent: netNewFollowersPercent
            }
          }
          categoryNetFollowersShareOfData.push(netFollowersMonthData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            netFollowersShareOfGraphData,
            categoryNetFollowersShareOfData,
            categoryNetFollowersShareOfDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch category share of facebook net new followers...');
        if (this.state.isMounted) {
          this.setState(() => ({
            netFollowersShareOfGraphData: {},
            categoryNetFollowersShareOfData: [],
            categoryNetFollowersShareOfDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  render () {
    return (
      <div>
        <CategoryFacebookSummary
          user={this.props.user}
          sixMonths={this.props.sixMonths}
          selectedMonth={this.props.selectedMonth}
          updateSelectedMonth={this.props.updateSelectedMonth}
          category={this.props.category}
          categoryFacebookData={this.state.categoryFacebookShareOfData}
          categoryFacebookDataLoading={this.state.categoryFacebookShareOfDataLoading}
        />
        <div className="mt-5 mb-4 band-separator" />
        <ShareOfChart
          title="Share of Owned Posts"
          data={this.state.ownedPostsShareOfGraphData}
          legendData={this.state.categoryOwnedPostsShareOfData}
          loading={this.state.categoryFacebookShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="Facebook"
          contextChartName="Share of Owned Posts"
        />
        <ShareOfChart
          title="Share of Net New Followers"
          data={this.state.netFollowersShareOfGraphData}
          legendData={this.state.categoryNetFollowersShareOfData}
          loading={this.state.categoryNetFollowersShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="Facebook"
          contextChartName="Share of Net New Followers"
          shareClassName="category-facebook-share-of-net-new-followers-img"
        />
        <ShareOfChart
          title="Share of Promoter Comments"
          data={this.state.promoterShareOfGraphData}
          legendData={this.state.categoryPromoterSentimentShareOfData}
          loading={this.state.categoryFacebookShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="Facebook"
          contextChartName="Share of Promoter Comments"
        />
        <ShareOfChart
          title="Share of Detractor Comments"
          data={this.state.detractorShareOfGraphData}
          legendData={this.state.categoryDetractorSentimentShareOfData}
          loading={this.state.categoryFacebookShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="Facebook"
          contextChartName="Share of Detractor Comments"
        />
        <CategoryFacebookTopPosts
          category={this.props.category}
        />
      </div>
    );
  }
};
