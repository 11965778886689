import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class TotalVolume extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalVolume: 0
    };
  }

  componentDidMount() {
    this.loadCompanyLcxData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.topicId !== this.props.topicId) {
      this.loadCompanyLcxData();
    }
  }

  loadCompanyLcxData = () => {
    if (this.props.topicId) {
      const closeEndDate = moment.utc().endOf('day');
      const closeStartDate = moment.utc().startOf('day').subtract(this.props.totalDays, 'days');

      axios.get(
        `${LISTEN_ENDPOINT}/api/topics/${this.props.topicId}/lcx-closes?start-date=${closeStartDate.toISOString()}&end-date=${closeEndDate.toISOString()}`,
        HEADERS
      ).then(response => {
        const lcxCloseData = response.data;
        let totalVolume = 0;
        for (const i in lcxCloseData) {
          totalVolume += lcxCloseData[i].daily_volume;
        }
        this.setState(() => ({ totalVolume }));
      }).catch(error => {
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        <div>{this.props.totalDays} Day Volume</div>
        <div><b>{numberWithCommas(this.state.totalVolume)}</b></div>
      </div>
    );
  }
};
