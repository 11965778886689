import React from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ReactSelect from 'react-select';
import ClipLoader from 'react-spinners/ClipLoader';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class SlackShareModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      slackChannel: undefined,
      message: '',
      addPageLink: false,
      errors: {},
      errorMessage: '',
      postToSlackLoading: false,
    };
  }

  onOpen = () => {
    this.setState(() => ({
      slackChannel: undefined,
      message: '',
      addPageLink: false,
      errors: {},
      errorMessage: '',
      postToSlackLoading: false,
    }));
  };

  onMessageChange = (event) => {
    const message = event.currentTarget.value;
    this.setState(() => ({ message }));
  };

  onAddPageLinkChange = (event) => {
    const addPageLink = event.currentTarget.checked;
    this.setState(() => ({ addPageLink }));
  };

  onSlackChannelChange = (slackChannel) => {
    if (slackChannel !== this.state.slackChannel) {
      this.setState(() => ({ slackChannel }));
    }
  };

  isValidForm = () => {
    const errors = {};
    let errorMessage = '';
    if (!this.state.slackChannel) {
      errors.slackChannel = true;
    }
    if (!this.props.imageDataUrl) {
      errors.imageDataUrl = true;
    }

    this.setState(() => ({
      errors,
      errorMessage,
    }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  shareToSlack = () => {
    if (this.isValidForm()) {
      this.setState(() => ({ postToSlackLoading: true }));
      const chatPost = {
        company_id: this.props.user.customerId,
        channel_name: this.state.slackChannel.label,
        channel_id: this.state.slackChannel.value,
        image_data: this.props.imageDataUrl,
      };
      if (this.state.message && this.state.addPageLink) {
        chatPost.text = this.state.message + '\n' + window.location.href;
      } else if (this.state.message) {
        chatPost.text = this.state.message;
      } else if (this.state.addPageLink) {
        chatPost.text = window.location.href;
      }

      axios.post(
        `${LISTEN_ENDPOINT}/api/slack-chat-post`,
        chatPost,
        HEADERS
      ).then(response => {
        this.props.handleClose();
      }).catch(error => {
        console.error('Error: failed to post to slack');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        this.setState(() => ({
          errorMessage: 'Failed to post to slack.',
          postToSlackLoading: false,
        }));
      });
    }
  };

  render () {
    return (
      <Modal
        show={this.props.isOpen}
        onHide={this.props.handleClose}
        onEntering={this.onOpen}
        size="lg"
      >
        <Modal.Header
          className="bg-bops-blue text-light"
          closeButton
        >
          <Modal.Title>
            Share to Slack
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  Slack Channel
                  <span className="ml-1 text-danger">*</span>
                </Form.Label>
                <ReactSelect
                  cacheOptions
                  value={this.state.slackChannel}
                  options={
                    this.props.slackChannels.map(channel => {
                      return ({
                        label: `#${channel.name}`,
                        value: channel.id,
                        channel,
                      })
                    })
                  }
                  onChange={this.onSlackChannelChange}
                  aria-invalid={true}
                  isClearable={true}
                  placeholder="Slack channel..."
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Message
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  value={this.state.message}
                  onChange={this.onMessageChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Add page link"
                  checked={this.state.addPageLink}
                  onChange={this.onAddPageLinkChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>
                  Preview
                </Form.Label>
                { this.state.message &&
                  <pre
                    style={{
                      fontFamily: 'inherit',
                      fontSize: 'unset',
                      whiteSpace: 'pre-wrap',
                      wordWrap: 'break-word'
                    }}
                  >
                    {this.state.message}
                  </pre>
                }
                { this.state.addPageLink &&
                  <div>
                    {window.location.href}
                  </div>
                }
                { this.props.imageDateUrlLoading &&
                  <div className="m-4 text-center">
                    <ClipLoader size={50}/>
                  </div>
                }
                { !this.props.imageDateUrlLoading &&
                  <div>
                    <img
                      src={this.props.imageDataUrl}
                      alt=""
                      crossOrigin="anonymous"
                      width="100%"
                    />
                  </div>
                }
              </Form.Group>
            </Col>
          </Row>
          <div>
            <Alert show={Boolean(this.state.errorMessage)} variant="danger">
              {this.state.errorMessage}
            </Alert>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={this.shareToSlack}
            disabled={
              !this.state.slackChannel ||
              this.state.postToSlackLoading
            }
          >
            Share
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};
