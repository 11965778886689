import { connect } from 'react-redux';
import SingleSignOnSetup from '../../../components/settings/contentCollectors/SingleSignOnSetup';

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SingleSignOnSetup);
