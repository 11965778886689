import React from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import CategorySearchAds from './CategorySearchAds';
import { createUserActivityLog } from '../../../utils/users';
import history from '../../../routers/history';

export default class CategoryAds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'searchAds',
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let selectedTab = hash.indexOf("-")>0 ? hash.substring(hash.indexOf('-')+1) : 'searchAds';
      this.setState(() => ({ selectedTab }));
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {

  }

  changeSelectedTab = (selectedTab, subTabName) => {
    history.push(`#ads-${selectedTab}`);
    this.setState(() => ({ selectedTab }));
    if (subTabName) {
      createUserActivityLog(
        this.props.user,
        `Category Tabs: Ads: ${subTabName}`,
        this.props.category,
      )
    }
  }

  render () {
    return (
      <div className="p-4">
        <div className="mb-4">
          <button
            type="button"
            className= {
              this.state.selectedTab === 'searchAds' ?
                'btn border-primary bg-white mr-1' :
                'btn border bg-white mr-1'
            }
            onClick={(e) => this.changeSelectedTab('searchAds', 'Search Ads')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faChartLine} color="#454d54" />
            </div>
            <div className="d-inline-block">
              Search Ads
            </div>
          </button>
        </div>

        <div
          className="mt-2 pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 200px)',
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
          ref={(ref) => this.parentScrollRef = ref }
        >
          { this.state.selectedTab === 'searchAds' &&
            <CategorySearchAds
              user={this.props.user}
              sixMonths={this.props.sixMonths}
              selectedMonth={this.props.selectedMonth}
              updateSelectedMonth={this.props.updateSelectedMonth}
              category={this.props.category}
            />
          }
        </div>
      </div>
    );
  }
};
