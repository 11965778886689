import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import CategoryNewsStatsSummary from './CategoryNewsStatsSummary';
import CategoryNewsShareOfGraphs from './CategoryNewsShareOfGraphs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryNewsHighlights extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryNewsData: {},
      mentionsShareOfGraphData: {},
      categoryNewsShareOfData: [],
      reachShareOfGraphData: {},
      categoryReachShareOfData: [],
      categoryNewsDataLoading: false,
      selectedMonthNewsData: [],
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchCategoryNewsData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      this.fetchCategoryNewsData();
    }
    if (prevProps.selectedMonth !== this.props.selectedMonth) {
      this.setNewsData(this.props.selectedMonth);
    }
  };

  fetchCategoryNewsData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryNewsDataLoading: true }));
      const categoryNewsDataRequests = [];
      categoryNewsDataRequests.push(
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/category-share-of-news-mention?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
          HEADERS
        ).then(response => {
          const categoryNewsData = response.data;
          const mentionsShareOfGraphData = {
            series: [
              { name: 'Month', type: 'dimension' },
              { name: 'Brand', type: 'dimension' },
              { name: 'Value', type: 'measure' },
            ],
            records: [],
          };
          const categoryNewsShareOfData = [];
          for (const monthStats of categoryNewsData) {
            const mentionsMonthData = { month: monthStats.month };
            let monthTotalMentions = 0;

            // get totals for percent
            for (const pb of monthStats.product_brands) {
              monthTotalMentions += pb.total_mentions;
              // insert graph data
              mentionsShareOfGraphData.records.push([
                monthStats.month,
                pb.product_brand_name,
                pb.total_mentions || 0,
              ]);
            }

            // loop again to determine %
            for (const pb of monthStats.product_brands) {
              let mentionPercent = 0;
              if (monthTotalMentions > 0) {
                mentionPercent = round((pb.total_mentions/monthTotalMentions)*100, 1);
              }
              mentionsMonthData[pb.product_brand_name] = {
                count: pb.total_mentions,
                percent: mentionPercent
              }
            }
            categoryNewsShareOfData.push(mentionsMonthData);
          }

          return {
            mentionsShareOfGraphData,
            categoryNewsShareOfData
          };
        }).catch(error => {
          console.error('Error: unable to fetch category share of news mention data...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return {
            mentionsShareOfGraphData: {},
            categoryNewsShareOfData: []
          };
        })
      );
      categoryNewsDataRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-share-of-news-mention-by-reach?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
          HEADERS
        ).then(response => {
          const categoryReachData = response.data;
          const reachShareOfGraphData = {
            series: [
              { name: 'Month', type: 'dimension' },
              { name: 'Brand', type: 'dimension' },
              { name: 'Value', type: 'measure' },
            ],
            records: [],
          };
          const categoryReachShareOfData = [];
          for (const monthStats of categoryReachData) {
            const reachMonthData = { month: monthStats.month };
            let monthTotalReach = 0;

            // get totals for percent
            for (const pb of monthStats.product_brands) {
              monthTotalReach += pb.reach;
              // insert graph data
              reachShareOfGraphData.records.push([
                monthStats.month,
                pb.product_brand_name,
                pb.reach || 0,
              ]);
            }

            // loop again to determine %
            for (const pb of monthStats.product_brands) {
              let reachPercent = 0;
              if (monthTotalReach > 0) {
                reachPercent = round((pb.reach/monthTotalReach)*100, 1);
              }
              reachMonthData[pb.product_brand_name] = {
                count: pb.reach,
                percent: reachPercent
              }
            }
            categoryReachShareOfData.push(reachMonthData);
          }
          return {
            reachShareOfGraphData,
            categoryReachShareOfData
          };
        }).catch(error => {
          console.error('Error: unable to fetch category share of news reach data...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return {
            reachShareOfGraphData: {},
            categoryReachShareOfData: []
          };
        })
      );

      Promise.all(categoryNewsDataRequests).then(responses => {
        let categoryNewsData = {};
        let mentionsShareOfGraphData = {};
        let categoryNewsShareOfData = [];
        let reachShareOfGraphData = {};
        let categoryReachShareOfData = [];
        for (const response of responses) {
          if (response.categoryNewsShareOfData) {
            categoryNewsShareOfData = response.categoryNewsShareOfData;
            mentionsShareOfGraphData = response.mentionsShareOfGraphData;
          } else if (response.categoryReachShareOfData) {
            categoryReachShareOfData = response.categoryReachShareOfData;
            reachShareOfGraphData = response.reachShareOfGraphData;
          }
        }

        // merge mentions and reach data
        const productBrandsMonthMap = {};
        // add mention data
        for (const mentionMonthData of categoryNewsShareOfData) {
          for (const [key, value] of Object.entries(mentionMonthData)) {
            if (key !== 'month') {
              if (!productBrandsMonthMap[mentionMonthData.month]) {
                productBrandsMonthMap[mentionMonthData.month] = {};
              }
              if (!productBrandsMonthMap[mentionMonthData.month][key]) {
                productBrandsMonthMap[mentionMonthData.month][key] = {};
              }
              productBrandsMonthMap[mentionMonthData.month][key].total_mentions = value.count || 0;
            }
          }
        }
        // add reach data
        for (const reachMonthData of categoryReachShareOfData) {
          for (const [key, value] of Object.entries(reachMonthData)) {
            if (key !== 'month') {
              if (!productBrandsMonthMap[reachMonthData.month]) {
                productBrandsMonthMap[reachMonthData.month] = {};
              }
              if (!productBrandsMonthMap[reachMonthData.month][key]) {
                productBrandsMonthMap[reachMonthData.month][key] = {};
              }
              productBrandsMonthMap[reachMonthData.month][key].reach = value.count || 0;
            }
          }
        }
        // update categoryNewsData to have brands as array for table
        for (const [month, monthData] of Object.entries(productBrandsMonthMap)) {
          for (const [brandName, brandData] of Object.entries(monthData)) {
            if (!categoryNewsData[month]) {
              categoryNewsData[month] = [];
            }
            categoryNewsData[month].push({
              product_brand_name: brandName,
              ...brandData
            });
          }
        }

        if (this.state.isMounted) {
          this.setState(
            () => ({
              categoryNewsData,
              mentionsShareOfGraphData,
              categoryNewsShareOfData,
              reachShareOfGraphData,
              categoryReachShareOfData,
              categoryNewsDataLoading: false,
            }),
            () => this.setNewsData(this.props.selectedMonth)
          );
        }
      });
    }
  };

  selectMonth = (selectedMonth) => {
    if (selectedMonth !== this.props.selectedMonth) {
      this.props.updateSelectedMonth(selectedMonth);
      this.setNewsData(selectedMonth);
    }
  };

  setNewsData = (selectedMonth) => {
    let selectedMonthNewsData = [];
    if (this.state.categoryNewsData[selectedMonth.label]) {
      selectedMonthNewsData = this.state.categoryNewsData[selectedMonth.label];
    }
    this.setState(() => ({ selectedMonthNewsData }));
  };

  render () {
    return (
      <div>
        { this.state.categoryNewsDataLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.categoryNewsDataLoading &&
          <div>
            <CategoryNewsStatsSummary
              user={this.props.user}
              loading={this.state.categoryNewsDataLoading}
              sixMonths={this.props.sixMonths}
              selectedMonth={this.props.selectedMonth}
              updateSelectedMonth={this.selectMonth}
              data={this.state.selectedMonthNewsData}
              category={this.props.category}
            />
          <div className="mt-5 mb-4 band-separator" />
            <CategoryNewsShareOfGraphs
              category={this.props.category}
              mentionsShareOfGraphData={this.state.mentionsShareOfGraphData}
              categoryNewsShareOfData={this.state.categoryNewsShareOfData}
              reachShareOfGraphData={this.state.reachShareOfGraphData}
              categoryReachShareOfData={this.state.categoryReachShareOfData}
              categoryNewsDataLoading={this.state.categoryNewsDataLoadin}
            />
          </div>
        }
      </div>
    );
  }
};
