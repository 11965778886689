import React from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import CardComponent from '../common/CardComponent';
import StarScoreBar from '../graphs/StarScoreBar';
import { capitalizeWords } from '../../utils/strings';
import { numberWithCommas } from '../../utils/numbers';
import ClipLoader from 'react-spinners/ClipLoader';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class EmployerReviewSiteScores extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reviewsData: [],
      reviewsDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.loadEmployerReviewsData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.brand !== this.props.brand ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter
    ) {
      this.loadEmployerReviewsData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  loadEmployerReviewsData = () => {
    this.setState(() => ({ reviewsDataLoading: true }));
    const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
    axios.get(
      `${CONTENT_SCRAPING_ENDPOINT}/api/companies/${this.props.brand.company_id}/employer-review-site-trend?start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
      HEADERS
    ).then(response => {
      const reviewsData = response.data;
      if (this.state.isMounted) {
        for (const review of reviewsData) {
          review.checked = true;
          if (review.review_site_url.includes('indeed.com')) {
            review.review_site_favicon_url = 'https://www.indeed.com/images/favicon.ico';
          } else if (review.review_site_url.includes('glassdoor.com')) {
            review.review_site_favicon_url = 'https://www.glassdoor.com/favicon.ico';
          }
        }
        this.props.filterEmployerReviews(reviewsData);
        if (this.state.isMounted) {
          this.setState(() => ({
            reviewsData,
            reviewsDataLoading: false
          }));
        }
      }
    }).catch(error => {
      if (this.state.isMounted) {
        this.setState(() => ({
          reviewsData: [],
          reviewsDataLoading: false
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  filterEmployerReviews = (reviewIndex) => {
    let reviewsData = this.state.reviewsData;
    for (const i in reviewsData) {
      const review = reviewsData[i];
      if (i == reviewIndex)
        review.checked = review.checked == false ? true : false;
    }
    this.props.filterEmployerReviews(reviewsData);
    this.setState(() => ({ reviewsData }));
  }

  render () {
    return (
      <CardComponent
        title="Employer Review Site Scores"
        description="Employer Review Site Scores gives a view of review count and stars by review site"
        body={
          <div style={{ fontSize: '.825rem' }}>
            { this.state.reviewsDataLoading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.reviewsDataLoading &&
              <div>
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }}></th>
                      <th style={{ width: "40%" }}>Review Site</th>
                      <th style={{ width: "25%" }}>Stars</th>
                      <th style={{ width: "15%" }}>Average Stars</th>
                      <th style={{ width: "15%" }}>Reviews</th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.state.reviewsData.map((reviewSite, i) => {
                        return (
                          <tr key={`rs${i}`}>
                            <td className="text-center">
                              <Form>
                                <Form.Check
                                  type="checkbox"
                                  checked={reviewSite.checked}
                                  onChange={() => this.filterEmployerReviews(i)}
                                  style={{ transform: 'scale(1.5)' }}
                                />
                              </Form>
                            </td>
                            <td>
                              <img
                                src={reviewSite.review_site_favicon_url}
                                style={{ width: '30px' }}
                              />
                              <a
                                className="ml-2"
                                href={reviewSite.review_site_url}
                                target="_blank"
                              >
                                {capitalizeWords(reviewSite.review_site_name)}
                              </a>
                            </td>
                            <td>
                              <StarScoreBar
                                score={reviewSite.score_average}
                                maxScore={1}
                                starColor="gold"
                              />
                            </td>
                            <td>
                              {((reviewSite.score_average * 10) / 2).toFixed(1)}
                            </td>
                            <td>
                              {numberWithCommas(reviewSite.comment_count)}
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
              </div>
            }
          </div>
        }
        contextType="brand"
        contextCategory={this.props.category}
        contextBrand={this.props.brand}
        contextChannel="Employer Reviews"
        contextChartName="Employer Review Site Scores"
        contextDateRange={`${this.props.startDateFilter.format('MM/DD/YYYY')} - ${this.props.endDateFilter.format('MM/DD/YYYY')}`}
      />
    );
  }
};
