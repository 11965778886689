import React from 'react';
import axios from 'axios';
import ShareOfChart from '../graphs/ShareOfChart';
import { round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryLinkedInShares extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      postsShareOfGraphData: {},
      categoryPostsShareOfData: [],
      engagementShareOfGraphData: {},
      categoryEngagementShareOfData: [],
      followersShareOfGraphData: {},
      categoryFollowersShareOfData: [],
      netFollowersShareOfGraphData: {},
      categoryNetFollowersShareOfData: [],
      categoryLinkedInShareOfDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchLinkedInShareOfData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.categoryId !== this.props.categoryId ||
      prevProps.user.customerId !== this.props.user.customerId) {
      this.fetchLinkedInShareOfData();
    }
  };

  fetchLinkedInShareOfData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryLinkedInShareOfDataLoading: true }));
      const shareOfRequests = [];
      shareOfRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-linkedin-share-stats?category_type=${this.props.category.category_type}&category_id=${this.props.categoryId}`,
          HEADERS
        ).then(response => {
          const postsShareOfGraphData = {
            series: [
              { name: 'Month', type: 'dimension' },
              { name: 'Brand', type: 'dimension' },
              { name: 'Value', type: 'measure' },
            ],
            records: [],
          };
          const engagementShareOfGraphData = {
            series: [
              { name: 'Month', type: 'dimension' },
              { name: 'Brand', type: 'dimension' },
              { name: 'Value', type: 'measure' },
            ],
            records: [],
          };
          const categoryLinkedInShareOfData = response.data;
          const categoryPostsShareOfData = [];
          const categoryEngagementShareOfData = [];
          for (const monthStats of categoryLinkedInShareOfData) {
            const postsMonthData = { month: monthStats.month };
            let monthTotalPosts = 0;
            const engagementMonthData = { month: monthStats.month };
            let monthTotalEngagement = 0;

            // get totals for percent
            for (const pb of monthStats.product_brands) {
              monthTotalPosts += pb.post_count;
              monthTotalEngagement += pb.engagement;
              // insert graph data
              postsShareOfGraphData.records.push([
                monthStats.month,
                pb.product_brand_name,
                pb.post_count,
              ]);
              engagementShareOfGraphData.records.push([
                monthStats.month,
                pb.product_brand_name,
                pb.engagement,
              ]);
            }

            // loop again to determine %
            for (const pb of monthStats.product_brands) {
              let postsPercent = 0;
              if (monthTotalPosts > 0) {
                postsPercent = round((pb.post_count/monthTotalPosts)*100, 1);
              }
              postsMonthData[pb.product_brand_name] = {
                count: pb.post_count,
                percent: postsPercent
              }

              let engagementPercent = 0;
              if (monthTotalEngagement > 0) {
                engagementPercent = round((pb.engagement/monthTotalEngagement)*100, 1);
              }
              engagementMonthData[pb.product_brand_name] = {
                count: pb.engagement,
                percent: engagementPercent
              }
            }
            categoryPostsShareOfData.push(postsMonthData);
            categoryEngagementShareOfData.push(engagementMonthData);
          }
          return {
            postsShareOfGraphData,
            engagementShareOfGraphData,
            categoryPostsShareOfData,
            categoryEngagementShareOfData,
          }
        }).catch(error => {
          console.error('Error: unable to fetch category share of linkedin stats...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return {
            postsShareOfGraphData: {},
            engagementShareOfGraphData: {},
            categoryPostsShareOfData: [],
            categoryEngagementShareOfData: [],
          }
        })
      );

      shareOfRequests.push(
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/category-linkedin-share-of-followers?category_type=${this.props.category.category_type}&category_id=${this.props.categoryId}`,
          HEADERS
        ).then(response => {
          const followersShareOfGraphData = {
            series: [
              { name: 'Month', type: 'dimension' },
              { name: 'Brand', type: 'dimension' },
              { name: 'Value', type: 'measure' },
            ],
            records: [],
          };
          const categoryFollowersShareOfData = [];
          for (const monthStats of response.data) {
            const followersMonthData = { month: monthStats.month };
            let monthTotalFollowers = 0;

            // get totals for percent
            for (const pb of monthStats.product_brands) {
              monthTotalFollowers += pb.followers;
              // insert graph data
              followersShareOfGraphData.records.push([
                monthStats.month,
                pb.product_brand_name,
                pb.followers,
              ]);
            }

            // loop again to determine %
            for (const pb of monthStats.product_brands) {
              let followersPercent = 0;
              if (monthTotalFollowers > 0) {
                followersPercent = round((pb.followers/monthTotalFollowers)*100, 1);
              }
              followersMonthData[pb.product_brand_name] = {
                count: pb.followers,
                percent: followersPercent
              }
            }
            categoryFollowersShareOfData.push(followersMonthData);
          }
          return {
            followersShareOfGraphData,
            categoryFollowersShareOfData
          }
        }).catch(error => {
          console.error('Error: unable to fetch category share of linkedin followers...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return {
            followersShareOfGraphData: {},
            categoryFollowersShareOfData: []
          }
        })
      );

      shareOfRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-linkedin-share-of-net-followers?category_type=${this.props.category.category_type}&category_id=${this.props.categoryId}`,
          HEADERS
        ).then(response => {
          const netFollowersShareOfGraphData = {
            series: [
              { name: 'Month', type: 'dimension' },
              { name: 'Brand', type: 'dimension' },
              { name: 'Value', type: 'measure' },
            ],
            records: [],
          };
          const categoryNetFollowersShareOfData = [];
          for (const monthStats of response.data) {
            const netFollowersMonthData = { month: monthStats.month };
            let monthNetNewFollowers = 0;

            // get totals for percent
            for (const pb of monthStats.product_brands) {
              monthNetNewFollowers += pb.net_followers;
              // insert graph data
              netFollowersShareOfGraphData.records.push([
                monthStats.month,
                pb.product_brand_name,
                pb.net_followers || 0,
              ]);
            }

            // loop again to determine %
            for (const pb of monthStats.product_brands) {
              let netNewFollowersPercent = 0;
              if (monthNetNewFollowers > 0) {
                netNewFollowersPercent = round((pb.net_followers/monthNetNewFollowers)*100, 1);
              }
              netFollowersMonthData[pb.product_brand_name] = {
                count: pb.net_followers || 0,
                percent: netNewFollowersPercent
              }
            }
            categoryNetFollowersShareOfData.push(netFollowersMonthData);
          }
          return {
            netFollowersShareOfGraphData,
            categoryNetFollowersShareOfData
          }
        }).catch(error => {
          console.error('Error: unable to fetch category share of linkedin net new followers...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return {
            netFollowersShareOfGraphData: {},
            categoryNetFollowersShareOfData: []
          }
        })
      );

      Promise.all(shareOfRequests).then(responses => {
        let postsShareOfGraphData = {};
        let categoryPostsShareOfData = [];
        let engagementShareOfGraphData = {};
        let categoryEngagementShareOfData = [];
        let followersShareOfGraphData = {};
        let categoryFollowersShareOfData = [];
        let netFollowersShareOfGraphData = {};
        let categoryNetFollowersShareOfData = [];

        for (const response of responses) {
          if (response.categoryPostsShareOfData) {
            postsShareOfGraphData = response.postsShareOfGraphData;
            categoryPostsShareOfData = response.categoryPostsShareOfData;
            engagementShareOfGraphData = response.engagementShareOfGraphData;
            categoryEngagementShareOfData = response.categoryEngagementShareOfData;
          } else if (response.categoryFollowersShareOfData) {
            followersShareOfGraphData = response.followersShareOfGraphData;
            categoryFollowersShareOfData = response.categoryFollowersShareOfData;
          } else if (response.categoryNetFollowersShareOfData) {
            netFollowersShareOfGraphData = response.netFollowersShareOfGraphData;
            categoryNetFollowersShareOfData = response.categoryNetFollowersShareOfData;
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            postsShareOfGraphData,
            categoryPostsShareOfData,
            engagementShareOfGraphData,
            categoryEngagementShareOfData,
            followersShareOfGraphData,
            categoryFollowersShareOfData,
            netFollowersShareOfGraphData,
            categoryNetFollowersShareOfData,
            categoryLinkedInShareOfDataLoading: false,
          }));
        }
      });
    }
  }

  render () {
    return (
      <div>
        <ShareOfChart
          title="Share of Followers"
          data={this.state.followersShareOfGraphData}
          legendData={this.state.categoryFollowersShareOfData}
          loading={this.state.categoryLinkedInShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="LinkedIn"
          contextChartName="Share of Followers"
          shareClassName="category-linkedin-share-of-followers-img"
        />
        <ShareOfChart
          title="Share of Net New Followers"
          data={this.state.netFollowersShareOfGraphData}
          legendData={this.state.categoryNetFollowersShareOfData}
          loading={this.state.categoryLinkedInShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="LinkedIn"
          contextChartName="Share of Net New Followers"
          shareClassName="category-linkedin-share-of-net-new-followers-img"
        />
        <ShareOfChart
          title="Share of Posts"
          data={this.state.postsShareOfGraphData}
          legendData={this.state.categoryPostsShareOfData}
          loading={this.state.categoryLinkedInShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="LinkedIn"
          contextChartName="Share of Posts"
          shareClassName="category-linkedin-share-of-posts-img"
        />
        <ShareOfChart
          title="Share of Engagements"
          data={this.state.engagementShareOfGraphData}
          legendData={this.state.categoryEngagementShareOfData}
          loading={this.state.categoryLinkedInShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="LinkedIn"
          contextChartName="Share of Engagements"
          shareClassName="category-linkedin-share-of-engagement-img"
        />
      </div>
    );
  }
};
