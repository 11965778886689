import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../../common/CardComponent';
import { numberWithCommas } from '../../../utils/numbers';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import {
  LISTEN_ENDPOINT,
  HEADERS
} from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class Hashtag extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hashtagData: [],
      hashtagDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.loadHashtagData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.topicId !== this.props.topicId ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter ||
      prevProps.companyChecked !== this.props.companyChecked ||
      prevProps.portfolioChecked !== this.props.portfolioChecked ||
      prevProps.productChecked !== this.props.productChecked
    ) {
      this.loadHashtagData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  loadHashtagData = () => {
    this.setState(() => ({ hashtagDataLoading: true }));
    const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
    let brandLevelsParam = '';
    if (this.props.companyChecked) {
      brandLevelsParam += '&brand_levels=company';
    }
    if (this.props.portfolioChecked) {
      brandLevelsParam += '&brand_levels=portfolio';
    }
    if (this.props.productChecked) {
      brandLevelsParam += '&brand_levels=product';
    }
    axios.get(
      `${LISTEN_ENDPOINT}/api/topics/${this.props.topicId}/tweet-hashtag-trend?start-date=${formattedStartDate}&end-date=${formattedEndDate}${brandLevelsParam}`,
      HEADERS
    ).then(response => {
      const hashtagData = response.data;
      if (this.state.isMounted) {
        this.setState(() => ({
          hashtagData,
          hashtagDataLoading: false
        }));
      }
    }).catch(error => {
      if (this.state.isMounted) {
        this.setState(() => ({
          hashtagData: [],
          hashtagDataLoading: false
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  render () {
    return (
      <CardComponent
        title="Hashtags"
        icon={
          <img
            className="mr-2 align-top"
            src="/images/Hashtags_Icon.png"
          />
        }
        description="Hashtags allows you to view trending social hashtags"
        body={
          <div>
            { this.state.hashtagDataLoading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.hashtagDataLoading &&
              <div>
                <ResponsiveContainer height={350} width="98%">
                  <BarChart
                    data={this.state.hashtagData}
                    layout="vertical"
                  >
                    <XAxis
                      type="number"
                      tickFormatter={(count) => {
                        return numberWithCommas(count);
                      }}
                    />
                    <YAxis
                      type="category"
                      dataKey="hashtag_text"
                      tickFormatter={(label) => {
                        return `#${label}`;
                      }}
                      width={175}
                    />
                    <Tooltip cursor={false}/>
                    <Bar dataKey="count" fill="#b3b3b3"/>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            }
          </div>
        }
        contextType="brand"
        contextCategory={this.props.category}
        contextBrand={this.props.brand}
        contextChannel="Social"
        contextProperty="Twitter"
        contextChartName="Hashtags"
        contextDateRange={`${this.props.startDateFilter.format('MM/DD/YYYY')} - ${this.props.endDateFilter.format('MM/DD/YYYY')}`}
      />
    );
  }
};
