import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import ClipLoader from 'react-spinners/ClipLoader';
import Tooltip from 'reactjs-popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '../../utils/numbers';
import { CONTENT_SCRAPING_ENDPOINT, TOPIC_MODELS_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class BlogsDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'blog',
      selectedBlogPostId: undefined,
      selectedBlogPost: undefined,
      selectedBlogPostLoading: false,
      classifierResults: {},
      classifierResultsLoading: false,
      keyPhrases: [],
      keyPhrasesLoading: false,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.blogs && this.props.blogs.length > 0) {
      let selectedBlogPostId;
      if (this.props.blogs && this.props.blogs.length > 0) {
        selectedBlogPostId = this.props.blogs[0].id;
        this.fetchBlogPost(this.props.blogs[0]);
      }
      this.setState(() => ({ selectedBlogPostId }));
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.blogs !== this.props.blogs) {
      let selectedBlogFound = false;
      for (const b of this.props.blogs) {
        if (this.state.selectedBlogPostId === b.id) {
          selectedBlogFound = true;
        }
      }
      if (!selectedBlogFound) {
        let selectedBlogPostId;
        if (this.props.blogs && this.props.blogs.length > 0) {
          selectedBlogPostId = this.props.blogs[0].id;
          this.setState(() => ({ selectedBlogPostId }));
          this.fetchBlogPost(this.props.blogs[0]);
        } else {
          this.setState(() => ({
            selectedBlogPostId: undefined,
            selectedBlogPost: undefined
          }));
        }
      }
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchBlogPost = (blogPost) => {
    if (blogPost && blogPost.id) {
      this.setState(() => ({ selectedBlogPostLoading: true }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/company-blogs/${blogPost.id}?show_full_text=true`,
        HEADERS
      ).then(response => {
        const selectedBlogPost = response.data;
        selectedBlogPost.brand_types = blogPost.brand_types;
        if (this.state.isMounted) {
          this.setState(() => ({
            selectedBlogPost,
            selectedBlogPostLoading: false
          }), () => this.fetchAdditionalBlogStats());
        }
        this.blogImage.scrollLeft = 300;
        this.blogImage.scrollTop = 0;
      }).catch(error => {
        console.error('Error: failed to fetch blog post...');
        if (this.state.isMounted) {
          this.setState(() => ({
            selectedBlogPost: undefined,
            selectedBlogPostLoading: false,
            classifierResults: {}
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchAdditionalBlogStats = () => {
    this.fetchClassifierResults();
    this.fetchKeyPhrases();
  };

  fetchClassifierResults = () => {
    if (this.state.selectedBlogPost && this.state.selectedBlogPost.text) {
      this.setState(() => ({ classifierResultsLoading: true }));
      axios.post(
        `${TOPIC_MODELS_ENDPOINT}/api/topics`,
        {
          content: this.state.selectedBlogPost.text,
          taxonomy: 'all'
        },
        HEADERS
      ).then(response => {
        const classifierResults = response.data;
        const keywordsDisplay = [];
        if (classifierResults.keywords.length > 0) {
          for (const keyword of classifierResults.keywords) {
            keywordsDisplay.push(keyword.word);
          }
        }
        classifierResults.keywords_display = keywordsDisplay;
        if (classifierResults.taxonomies.length > 0) {
          for (const t of classifierResults.taxonomies) {
            if (t.taxonomy === 'iptc') {
              if (t.topic_categories.length > 0) {
                classifierResults.iptc_display = t.topic_categories[0].topic;
              }
            } else if (t.taxonomy === 'iab') {
              if (t.topic_categories.length > 0) {
                classifierResults.iab_display = t.topic_categories[0].topic;
              }
            }
          }
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            classifierResults,
            classifierResultsLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch IAB/IPTC topic classifier results...');
        if (this.state.isMounted) {
          this.setState(() => ({
            classifierResults: {},
            classifierResultsLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    } else {
      this.setState(() => ({
        classifierResults: {}
      }));
    }
  };

  fetchKeyPhrases = () => {
    if (this.state.selectedBlogPost) {
      this.setState(() => ({ keyPhrasesLoading: true }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/company-blog-key-phrases?blog_id=${this.state.selectedBlogPost.id}`,
        HEADERS
      ).then(response => {
        const keyPhrasesData = response.data;
        const keyPhrases = [];
        for (const kp of keyPhrasesData) {
          keyPhrases.push(kp.key_phrase);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            keyPhrases,
            keyPhrasesLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch blog key phrases...');
        if (this.state.isMounted) {
          this.setState(() => ({
            keyPhrases: [],
            keyPhrasesLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    } else {
      this.setState(() => ({
        keyPhrases: []
      }));
    }
  };

  onBlogSelect = (blogPost) => {
    this.setState(() => ({ selectedBlogPostId: blogPost.id }));
    this.fetchBlogPost(blogPost);
  };

  render () {
    return (
      <div>
        <Row>
          <Col xs={4}>
            <ButtonGroup className="mb-1 rounded border">
              <Button
                variant="info"
                size="sm"
              >Date</Button>
            </ButtonGroup>
            <div className="border rounded bg-white">
              <div
                className="pre-scrollable"
                style={{ minHeight: '600px', maxHeight: '600px' }}
              >
                { this.props.blogs.map((blogPost, i) => {
                    return (
                      <Tooltip
                        key={`b-${blogPost.id}-${i}`}
                        position="right center"
                        on="hover"
                        contentStyle={{ borderRadius: '5px', width: '500px' }}
                        trigger={
                          <div
                            className={
                              (
                                this.state.selectedBlogPost &&
                                this.state.selectedBlogPost.id === blogPost.id
                              ) ?
                                'px-2 bg-primary text-light rounded' :
                                `px-2${!blogPost.brand_types.includes('product') ? ' bg-grey': ''}`
                            }
                            onClick={() => this.onBlogSelect(blogPost)}
                            style={{
                              cursor: 'pointer',
                              overflowX: 'hidden'
                            }}
                          >
                            <Row className="py-2">
                              <Col xs={4}>
                                { blogPost.meta_image &&
                                  <div className="my-1 text-center align-middle">
                                    <img
                                      src={blogPost.meta_image}
                                      style={{ height: 'auto', maxWidth: '88px' }}
                                    />
                                  </div>
                                }
                                { !blogPost.meta_image &&
                                  <div className="text-center">
                                    <FontAwesomeIcon
                                      icon={faImage}
                                      color="#d9d9d9"
                                      size="5x"
                                    />
                                  </div>
                                }
                              </Col>
                              <Col>
                                <div style={{ fontSize: '.875rem' }}>
                                  <div>
                                    {blogPost.title}
                                  </div>
                                  <div>
                                    {moment.utc(blogPost.published_date).format('MM/DD/YY')}
                                    { (this.props.blogPageViewsMap && this.props.blogPageViewsMap[blogPost.id] !== undefined) &&
                                      <span className="float-right">
                                        {`Page Views: ${numberWithCommas(this.props.blogPageViewsMap[blogPost.id])}`}
                                      </span>
                                    }
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <hr className="m-0"/>
                          </div>
                        }
                      >
                        <div style={{ fontSize: '.875rem' }}>
                          <Row noGutters>
                            <Col xs={4}>
                              { blogPost.meta_image &&
                                <div className="my-1 text-center align-middle">
                                  <img
                                    src={blogPost.meta_image}
                                    onClick={() => window.open(blogPost.blog_url, '_blank')}
                                    style={{
                                      height: 'auto',
                                      maxWidth: '88px',
                                      cursor: 'pointer'
                                    }}
                                  />
                                </div>
                              }
                              { !blogPost.meta_image &&
                                <div
                                  className="text-center"
                                  onClick={() => window.open(blogPost.blog_url, '_blank')}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <FontAwesomeIcon
                                    icon={faImage}
                                    color="#d9d9d9"
                                    size="5x"
                                  />
                                </div>
                              }
                              <div className="text-center">
                                {moment.utc(blogPost.published_date).format('MM/DD/YY')}
                              </div>
                            </Col>
                            <Col>
                              <div
                                onClick={() => window.open(blogPost.blog_url, '_blank')}
                                style={{ cursor: 'pointer' }}
                              >
                                <b>{blogPost.title}</b>
                              </div>
                              <div style={{ maxHeight: '126px', overflow: 'hidden' }}>
                                {blogPost.meta_description}
                              </div>
                              { blogPost.author &&
                                <div>
                                  {`by ${blogPost.author}`}
                                </div>
                              }
                            </Col>
                          </Row>
                        </div>
                      </Tooltip>
                    )
                  })
                }
              </div>
            </div>
          </Col>
          <Col xs={8}>
            { this.state.selectedBlogPostId &&
              <Tabs
                activeKey={this.state.selectedTab}
                onSelect={(selectedTab) => this.setState(() => ({ selectedTab }))}
              >
                <Tab eventKey="blog" title="Blog">
                  <div className="container px-0">
                    { (this.state.selectedBlogPost && this.state.selectedBlogPost.blog_url) &&
                      <div
                        ref={(ref) => this.blogImage = ref}
                        style={{ overflow: 'auto', minHeight: '600px', maxHeight: '600px' }}
                      >
                        <img
                          width="1600px"
                          src={this.state.selectedBlogPost.image_snapshot_url}
                          alt=""
                          crossOrigin="anonymous"
                          onClick={() => window.open(this.state.selectedBlogPost.blog_url, '_blank')}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    }
                  </div>
                </Tab>
                <Tab eventKey="stats" title="Stats">
                  <div
                    className="pre-scrollable"
                    style={{ overflow: 'auto', minHeight: '600px', maxHeight: '600px' }}
                  >
                    <Card className="mt-2 mb-4">
                      <Card.Body>
                        { (this.state.selectedBlogPost && this.state.selectedBlogPost.brand_types) &&
                          <div>
                            { this.state.selectedBlogPost.brand_types.includes('company') &&
                              <Badge
                                className="mr-1"
                                variant="warning"
                                pill
                              >
                                Corporate
                              </Badge>
                            }
                            { this.state.selectedBlogPost.brand_types.includes('product') &&
                              <Badge
                                className="mr-1"
                                variant="warning"
                                pill
                              >
                                Product
                              </Badge>
                            }
                            { this.state.selectedBlogPost.brand_types.includes('portfolio') &&
                              <Badge
                                className="mr-1"
                                variant="warning"
                                pill
                              >
                                Portfolio
                              </Badge>
                            }
                          </div>
                        }
                        { (
                            this.state.selectedBlogPostLoading ||
                            this.state.classifierResultsLoading ||
                            this.state.keyPhrasesLoading
                          ) &&
                          <div className="text-center">
                            <ClipLoader size={25}/>
                          </div>
                        }
                        { (!this.state.selectedBlogPostLoading && !this.state.classifierResultsLoading) &&
                          <div>
                            { this.state.classifierResults.taxonomies &&
                              <div>
                                {this.state.classifierResults.iab_display &&
                                  <div>
                                    <div className="d-inline-block mr-2 font-weight-bold">IAB:</div>
                                    {this.state.classifierResults.iab_display}
                                  </div>
                                }
                                {this.state.classifierResults.iptc_display &&
                                  <div>
                                    <div className="d-inline-block mr-2 font-weight-bold">IPTC:</div>
                                    {this.state.classifierResults.iptc_display}
                                  </div>
                                }
                              </div>
                            }
                            { this.state.classifierResults.keywords_display &&
                              <div>
                                <div className="d-inline-block mr-2 font-weight-bold">Keywords:</div>
                                {this.state.classifierResults.keywords_display.join(', ')}
                              </div>
                            }
                          </div>
                        }
                        { (!this.state.selectedBlogPostLoading && !this.state.keyPhrasesLoading) &&
                          <div>
                            <div className="d-inline-block mr-2 font-weight-bold">Key Phrases:</div>
                            {this.state.keyPhrases.join(', ')}
                          </div>
                        }
                      </Card.Body>
                    </Card>
                    <pre
                      className="p-4 bg-white rounded border"
                      style={{
                        minHeight: '24px',
                        fontFamily: 'inherit',
                        fontSize: 'unset',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word'
                      }}
                    >
                      {this.state.selectedBlogPost ? this.state.selectedBlogPost.text || '' : ''}
                    </pre>
                  </div>
                </Tab>
              </Tabs>
            }
          </Col>
        </Row>
      </div>
    );
  }
};
