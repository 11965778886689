import axios from 'axios';
import moment from 'moment';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { reportError } from '../api/errors';

export const fetchCompanyCustomReports = (companyId) => {
  return (dispatch) => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/company-custom-reports?company_id=${companyId}`,
      HEADERS
    ).then(response => {
      const customReports = response.data;
      // Sort by reports created at
      customReports.sort((a, b) => moment(b.created_at) - moment(a.created_at));
      dispatch(addCustomReports(customReports));
    }).catch(error => {
      console.log('Error: unable to fetch company custom reports');
      dispatch(addCustomReports([]));
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatch(reportError(error.response));
      }
    });
  };
};

export const addCustomReports = (customReports) => ({
  type: 'ADD_CUSTOM_REPORTS',
  customReports
});

export const updateLastCustomReportIdUsed = (lastCustomReportIdUsed) => ({
  type: 'UPDATE_LAST_CUSTOM_REPORT_ID_USED',
  lastCustomReportIdUsed
})
