import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const SSOSetupHelper = (props) => {
  return (
    <Card className="h-100">
      <Card.Header className="bg-bops-blue text-light">
        <b>SSO Setup</b>
      </Card.Header>
      <Card.Body>
        <div
          className="pre-scrollable"
          style={{
            maxHeight: props.maxHeight || 'calc(100vh - 200px)',
            overflow: 'auto',
            overflowX: 'hidden'
          }}
        >
          <h5>Getting Started</h5>
          <ul>
            <li>
              <a href="https://help.okta.com/oag/en-us/Content/Topics/Access-Gateway/add-app-saml-pass-thru-add-okta.htm" target="_blank">
                Okta SSO Integration Help
              </a>
            </li>
            <li>
              <a href="https://help.okta.com/en-us/Content/Topics/Provisioning/azure/azure-map-attributes.htm" target="_blank">
                For advanced integrations such as Azure AD
              </a>
            </li>
          </ul>
          <h5>SAML Settings</h5>
          <div className="mb-1">
            <div>
              Single Sign On URL
            </div>
            <div className="py-1 px-2 bg-light border rounded">
              <code>{'https://auth.brandmasters.com/saml/acs/'}</code>
            </div>
          </div>
          <div className="mb-1">
            <div>
              Recipient URL
            </div>
            <div className="py-1 px-2 bg-light border rounded">
              <code>{'https://auth.brandmasters.com/saml/acs/'}</code>
            </div>
          </div>
          <div className="mb-1">
            <div>
              Destination URL
            </div>
            <div className="py-1 px-2 bg-light border rounded">
              <code>{'https://auth.brandmasters.com/saml/acs/'}</code>
            </div>
          </div>
          <div className="mb-1">
            <div>
              Audience Restriction
            </div>
            <div className="py-1 px-2 bg-light border rounded">
              <code>{'http://auth.brandmasters.com/saml/metadata.xml'}</code>
            </div>
          </div>
          <div className="mb-2">
            <div>
              Name ID Format
            </div>
            <div className="py-1 px-2 bg-light border rounded">
              <code>{'EmailAddress'}</code>
            </div>
          </div>
          <div className="mt-3">
            <b>Attribute Statements</b>
          </div>
          <Row className="mb-1">
            <Col>firstName</Col>
            <Col>Unspecified</Col>
            <Col>user.firstName</Col>
          </Row>
          <Row className="mb-1">
            <Col>lastName</Col>
            <Col>Unspecified</Col>
            <Col>user.lastName</Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SSOSetupHelper;
