import React from 'react';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';


export default class AudienceYoutube extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      youtubeData: [],
      youtubeDisplayData: [],
      youtubeDataLoading: false,
      showMoreToggle: false,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchYoutubeData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.brand !== this.props.brand) {
      this.fetchYoutubeData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchYoutubeData = () => {
    if (this.props.brand) {
      this.setState(() => ({
        youtubeDataLoading: true,
        showMoreToggle: false,
      }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/audience-proxy?product_brand_id=${this.props.brand.id}&type=youtube`,
        HEADERS
      ).then(response => {
        const youtubeData = [];
        if (
          response.data &&
          response.data.data
        ) {
          for (const youtube of response.data.data) {
            youtube.share = round(youtube.share, 1);
            youtubeData.push(youtube);
          }
        }
        // only show up to 5 websites originally
        const youtubeDisplayData = youtubeData.slice(0, 5);

        if (this.state.isMounted) {
          this.setState(() => ({
            youtubeData,
            youtubeDisplayData,
            youtubeDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch audience youtube data.');
        if (this.state.isMounted) {
          this.setState(() => ({
            youtubeData: [],
            youtubeDisplayData: [],
            youtubeDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  onShowMoreToggle = () => {
    this.setState((prevState) => {
      const showMoreToggle = !prevState.showMoreToggle;
      let youtubeDisplayData = prevState.youtubeData;
      if (!showMoreToggle) {
        youtubeDisplayData = prevState.youtubeData.slice(0, 5);
      }
      return ({
        showMoreToggle,
        youtubeDisplayData,
      })
    });
  };

  render () {
    return (
      <Card>
        <Card.Header className="bg-white font-weight-bold">
          <FontAwesomeIcon
            className="mr-2"
            icon={faVideo}
            color="#4fa2f3"
            style={{ fontSize: '1.2rem' }}
          />
          YouTube Channels They Subscribe To
        </Card.Header>
        <Card.Body>
          { this.state.youtubeDataLoading &&
            <div className="m-4 text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !this.state.youtubeDataLoading &&
            <div>
              { this.state.youtubeDisplayData.map((youtubeData, i) => {
                  return (
                    <div key={`aw-wd-${i}`}>
                      <div
                        className="d-inline-block text-right"
                        style={{ width: '150px' }}
                      >
                        {`${youtubeData.share}% engage with`}
                      </div>
                      { youtubeData.image &&
                        <img
                          className="ml-2"
                          src={youtubeData.image}
                          alt=""
                          style={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%'
                          }}
                          onError={(img) => {
                            img.target.src='/images/image.png';
                          }}
                        />
                      }
                      <a
                        className="ml-2"
                        href={youtubeData.channel_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {youtubeData.title}
                      </a>
                    </div>
                  )
                })
              }
            </div>
          }
        </Card.Body>
        { (!this.state.youtubeDataLoading && this.state.youtubeData.length > 5) &&
          <Card.Footer className="bg-white text-center">
            <div
              onClick={() => this.onShowMoreToggle()}
              style={{ cursor: 'pointer' }}
            >
              {this.state.showMoreToggle ? 'Show Less': 'Show More'}
            </div>
          </Card.Footer>
        }
      </Card>
    );
  }
};
