import { connect } from 'react-redux';
import SettingsMenu from '../../components/settings/SettingsMenu';
import { fetchCompanySetupChecklist } from '../../actions/company/companySetupChecklist';

const mapStateToProps = (state) => ({
  user: state.user,
  companySetupChecklist: state.companySetupChecklist
});

const mapDispatchToProps = (dispatch) => ({
  fetchCompanySetupChecklist: (companyId) => dispatch(fetchCompanySetupChecklist(companyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsMenu);
