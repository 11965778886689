import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Tooltip from 'reactjs-popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';

export default class PodcastEpisodesDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPodcastEpisodeId: undefined,
      selectedPodcastEpisode: undefined
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    let selectedPodcastEpisodeId;
    let selectedPodcastEpisode;
    if (this.props.podcastEpisodes && this.props.podcastEpisodes.length > 0) {
      selectedPodcastEpisodeId = this.props.podcastEpisodes[0].id;
      selectedPodcastEpisode = this.props.podcastEpisodes[0];
    }
    this.setState(() => ({
      selectedPodcastEpisodeId,
      selectedPodcastEpisode
    }));
  };

  componentDidUpdate(prevProps) {
    if (prevProps.podcastEpisodes !== this.props.podcastEpisodes) {
      // check if selected episode is still available in podcast episode results
      let selectedPodcastEpisodeFound = false;
      if (this.state.selectedPodcastEpisode) {
        for (const pe of this.props.podcastEpisodes) {
          if (this.state.selectedPodcastEpisode.id === pe.id) {
            selectedPodcastEpisodeFound = true;
          }
        }
      }

      if (!selectedPodcastEpisodeFound) {
        let selectedPodcastEpisodeId;
        let selectedPodcastEpisode;
        if (this.props.podcastEpisodes.length > 0) {
          selectedPodcastEpisodeId = this.props.podcastEpisodes[0].id;
          selectedPodcastEpisode = this.props.podcastEpisodes[0];
          selectedPodcastEpisode.embedded_url = `https://www.listennotes.com/embedded/e/${selectedPodcastEpisode.episode_id}`;
        }
        this.setState(() => ({
          selectedPodcastEpisodeId,
          selectedPodcastEpisode
        }));
      }
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  onPodcaseEpisodeSelect = (selectedPodcastEpisode) => {
    if (selectedPodcastEpisode.id !== this.state.selectedPodcastEpisode.id) {
      selectedPodcastEpisode.embedded_url = `https://www.listennotes.com/embedded/e/${selectedPodcastEpisode.episode_id}`;
      this.setState(() => ({
        selectedPodcastEpisodeId: selectedPodcastEpisode.id,
        selectedPodcastEpisode
      }));
    }
  };

  render () {
    return (
      <div>
        <Row>
          <Col xs={4}>
            <ButtonGroup className="rounded border">
              <Button
                variant="info"
                size="sm"
              >Date</Button>
            </ButtonGroup>
            <div className="border rounded bg-white">
              <div
                className="pre-scrollable"
                style={{ minHeight: '600px', maxHeight: '600px' }}
              >
                { this.props.podcastEpisodes.map(podcastEpisode => {
                    return (
                      <Tooltip
                        key={`b${podcastEpisode.id}`}
                        position="right center"
                        on="hover"
                        contentStyle={{ borderRadius: '5px', width: '500px' }}
                        trigger={
                          <div
                            key={`pe-${podcastEpisode.id}`}
                            className={
                              this.state.selectedPodcastEpisodeId === podcastEpisode.id ?
                                'px-2 bg-primary text-light rounded' :
                                `px-2${!podcastEpisode.brand_types.includes('product') ? ' bg-grey': ''}`
                            }
                            onClick={() => this.onPodcaseEpisodeSelect(podcastEpisode)}
                            style={{
                              cursor: 'pointer',
                              overflowX: 'hidden'
                            }}
                          >
                            <Row className="py-2">
                              <Col xs={4}>
                                { podcastEpisode.thumbnail &&
                                  <div className="my-1 text-center align-middle">
                                    <img
                                      src={podcastEpisode.thumbnail}
                                      style={{ height: 'auto', maxWidth: '88px' }}
                                    />
                                  </div>
                                }
                                { !podcastEpisode.thumbnail &&
                                  <div className="text-center">
                                    <FontAwesomeIcon
                                      icon={faImage}
                                      color="#d9d9d9"
                                      size="5x"
                                    />
                                  </div>
                                }
                              </Col>
                              <Col>
                                <div style={{ fontSize: '.875rem' }}>
                                  <div>
                                    {podcastEpisode.title_original}
                                  </div>
                                  <div>
                                    {moment(podcastEpisode.pub_date).format('MM/DD/YY')}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <hr className="m-0"/>
                          </div>
                        }
                      >
                        <div style={{ fontSize: '.875rem' }}>
                          <Row noGutters>
                            <Col xs={4}>
                              { podcastEpisode.thumbnail &&
                                <div className="my-1 text-center align-middle">
                                  <img
                                    src={podcastEpisode.thumbnail}
                                    onClick={() => window.open(podcastEpisode.listennotes_url, '_blank')}
                                    style={{
                                      height: 'auto',
                                      maxWidth: '88px',
                                      cursor: 'pointer'
                                    }}
                                  />
                                </div>
                              }
                              { !podcastEpisode.thumbnail &&
                                <div
                                  className="text-center"
                                  onClick={() => window.open(podcastEpisode.listennotes_url, '_blank')}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <FontAwesomeIcon
                                    icon={faImage}
                                    color="#d9d9d9"
                                    size="5x"
                                  />
                                </div>
                              }
                              <div className="text-center">
                                {moment(podcastEpisode.pub_date).format('MM/DD/YY')}
                              </div>
                            </Col>
                            <Col>
                              <div
                                onClick={() => window.open(podcastEpisode.listennotes_url, '_blank')}
                                style={{ cursor: 'pointer' }}
                              >
                                <b>
                                  <div dangerouslySetInnerHTML={{__html: podcastEpisode.title_original}} />
                                </b>
                              </div>
                              <div
                                dangerouslySetInnerHTML={{__html: podcastEpisode.description_original}}
                                style={{ maxHeight: '126px', overflow: 'hidden' }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Tooltip>
                    )
                  })
                }
              </div>
            </div>
          </Col>
          <Col xs={8}>
            <div className="container px-0" style={{ marginTop: '34px' }}>
              { this.state.selectedPodcastEpisode &&
                <div>
                  { this.state.selectedPodcastEpisode.brand_types &&
                    <div className="mb-1">
                      { this.state.selectedPodcastEpisode.brand_types.includes('company') &&
                        <Badge
                          className="mr-1"
                          variant="warning"
                          pill
                        >
                          Corporate
                        </Badge>
                      }
                      { this.state.selectedPodcastEpisode.brand_types.includes('product') &&
                        <Badge
                          className="mr-1"
                          variant="warning"
                          pill
                        >
                          Product
                        </Badge>
                      }
                      { this.state.selectedPodcastEpisode.brand_types.includes('portfolio') &&
                        <Badge
                          className="mr-1"
                          variant="warning"
                          pill
                        >
                          Portfolio
                        </Badge>
                      }
                    </div>
                  }
                  <iframe
                    src={this.state.selectedPodcastEpisode.embedded_url}
                    height="140px"
                    width="100%"
                    style={{ width: '1px', minWidth: '100%' }}
                    frameBorder="0"
                    scrolling="no"
                    loading="lazy"
                  />
                  <div className="mt-2">
                    <div>
                      <b>
                        <div dangerouslySetInnerHTML={{__html: this.state.selectedPodcastEpisode.title_original}} />
                      </b>
                    </div>
                    <div>
                      {moment(this.state.selectedPodcastEpisode.pub_date).format('MM/DD/YY')}
                    </div>
                    <div
                      className="mt-2"
                      style={{
                        maxHeight: '400px',
                        overflow: 'auto'
                      }}
                    >
                      <div dangerouslySetInnerHTML={{__html: this.state.selectedPodcastEpisode.description_original}} />
                    </div>
                  </div>
                </div>
              }
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};
