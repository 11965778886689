import React from 'react';
import Card from 'react-bootstrap/Card';

export default class BrandTabOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: `bt-overview-${Math.random().toString(36).substring(7)}`,
      overviewText: {
        'Employer Reviews': 'Brands are often evaluated by how they are perceived as an employer. BrandOps leverages reviews from popular employer evaluation sites to assess reputuation.',
        'Calendar': 'Analyze the marketing calendars of your competitors. Understand the channels that they emphasize, the messages they produce and the cadence of their messaging.',
        'Page Properties': 'As you consider your messaging strategy, you should understand what keywords are used in your Web content. Use Page Properties to identify what terms you\'re emphasizing, as well as those of the competition.',
        'Page Keywords': 'Everyone wants ALL of their web pages to rank high on Google Search, but in reality only a few pages typically do. Are the right pages ranking on the first page for you?',
        'Page Changes': 'Great brands keep their website fresh with new content. Evaluate the extent to which your web pages are being changed, and compare the results to your compeititors.',
        'Search Highlights': 'A key factor to having a great brand is it\'s online discoverability. Assess how your brand performs in search engines on the most important keywords - and compare the results to your closest competitors.',
        'Google Search': 'Which search terms are customers using to find your brand?',
        'SERP Details': 'How are you performing in Google\'s SEO on your most important keywords relative to the competition?',
        'SERP Samples': 'Analyze the search engine results for a specific keyword across Google, Bing and Yahoo, for both organic and paid content.',
        'Share of Search': 'Analyze how you are performing in Google Trends in comparison to your closest competitors.',
        'Share of Branded Search': 'Share of Branded Search compares Google search volume for each brand\'s name. The result correlates with market share in most categories.',
        'Share of Search Results': 'See your brand\'s presence in Google\'s SEO. Validate that your most important keywords are performing better than the competition.',
        'Review Search Results': 'Did you know that Review Sites also function as a search engine? They do - and it\'s important to make sure that you rank high on the list! See how your brand ranks on various review site search engines relative to the competition.',
        'LinkedIn': 'Analyze key metrics on LinkedIn. Determine how social posts and responses are affecting your brand\'s presence and attention.',
        'Twitter': 'Analyze key metrics on Twitter. Determine how social posts and responses are affecting your brand\'s presence and attention.',
        'Facebook': 'Analyze key metrics on Facebook. Determine how social posts and responses are affecting your brand\'s presence and attention.',
        'Instagram': 'Analyze key metrics on Instagram. Determine how social posts and responses are affecting your brand\'s presence and attention.',
        'Reddit': 'Use our brand search across all subreddits to see how people are commenting on your brand.',
        'Youtube Search': 'YouTube is often used as a search engine. It\'s important that the keywords that are important to you, return videos highlighting your brand.',
        'Imagery': 'Great brands use consistent colors and imagery. Review your brand assets and see how they performed in social channels.',
        'Fonts': 'Too many fonts can be a visual distraction. Identify the number of fonts being used at your website and reconcile if too many are in use.',
        'Key Phrases': 'Identify the key phrases being used in your messaging and compare it with your closest competitors. Are your unique phrases the emphasizing the right elements?',
        'Web Headers': 'Your website identifies your key messages. Are the headers indicating the messages you want to deliver?',
      }
    };
  }

  render () {
    return (
      <div className="mb-4">
        <Card className="h-100">
          <Card.Header className="bg-white" style={{ fontSize: '1.2rem'}}>
            <img
              className="mt-1 mr-2 align-top"
              src="/images/Reviews_Detail_Icon.png"
            />
            <b>
              Overview
            </b>
          </Card.Header>
          <Card.Body>
            { this.state.overviewText[this.props.overviewType] &&
              <div>
                {this.state.overviewText[this.props.overviewType]}
              </div>
            }
          </Card.Body>
        </Card>
      </div>
    );
  }
};
