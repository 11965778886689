import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ClipLoader from 'react-spinners/ClipLoader';

export default class MarketShareDataModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      marketShare: {},
      errors: {},
      errorMessage: '',
      saveLoading: false
    };
  }

  onOpen = () => {
    // convert float values to string for form and consistency
    const marketShare = {};
    for (const [brandName, value] of Object.entries(this.props.marketShare)) {
      marketShare[brandName] = String(value);
    }
    this.setState(() => ({
      marketShare,
      errors: {},
      errorMessage: '',
    }));
  };

  onMarketShareChange = (brandName, event) => {
    const msValue = event.currentTarget.value;
    this.setState((prevState) => ({
      marketShare: {
        ...prevState.marketShare,
        [brandName]: msValue
      }
    }));
  };

  validForm = () => {
    const errors = {};
    for (const [brandName, value] of Object.entries(this.state.marketShare)) {
      if (value.trim() === '') {
        errors[brandName] = true;
      } else {
        if (isNaN(Number(value))) {
          errors[brandName] = true;
        }
      }
    }

    this.setState(() => ({ errors }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  updateMarketShare = () => {
    if (this.validForm()) {
      // update string values back to float
      const marketShare = {};
      for (const [brandName, value] of Object.entries(this.state.marketShare)) {
        marketShare[brandName] = parseFloat(value);
      }
      this.props.updateMarketShare(marketShare);
      this.props.handleClose();
    }
  };

  render () {
    return (
      <Modal
        size="md"
        show={this.props.isOpen}
        onHide={this.props.handleClose}
        onEntering={this.onOpen}
      >
        <Modal.Header
          className="bg-bops-blue text-light"
          closeButton
        >
          <Modal.Title>
            Update Market Share
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { this.props.marketShareLoading &&
            <div className="m-4 text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !this.props.marketShareLoading &&
            <Form>
              { Object.keys(this.props.marketShare).map((brandName, i) => {
                  return (
                    <Form.Group key={`ms-b-${i}`}>
                      <Form.Row>
                        <Col>
                          <div className="mt-1 text-right">
                            {brandName}
                          </div>
                        </Col>
                        <Col>
                          <div className="d-inline-block">
                            <Form.Control
                              type="text"
                              value={this.state.marketShare[brandName] != null ? this.state.marketShare[brandName] : ''}
                              onChange={(event) => this.onMarketShareChange(brandName, event)}
                              isInvalid={this.state.errors[brandName]}
                              size="sm"
                              style={{ width: '52px' }}
                            />
                          </div>
                          <span className="ml-1">%</span>
                        </Col>
                      </Form.Row>
                    </Form.Group>
                  )
                })
              }
            </Form>
          }
          <Alert show={Boolean(this.state.errorMessage)} variant="danger">
            {this.state.errorMessage}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.updateMarketShare}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};
