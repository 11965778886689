import React from 'react';

import moment from 'moment';
import queryString from 'query-string';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Badge from 'react-bootstrap/Badge';
import AnalysisTab from './AnalysisTab';
import CompanyTab from './CompanyTab';
import SocialTab from './SocialTab';
import MediaCastTab from './MediaCastTab';
import NewsTab from './NewsTab';
import ReviewsTab from './ReviewsTab';
import EmailTab from './EmailTab';
import WebTab from './WebTab';
import CategorySearch from '../../categories/search/CategorySearch';
import AdsTab from './AdsTab';
import IdentityTab from './IdentityTab';
import AudienceTab from './AudienceTab';
import EcommerceSearch from '../../categories/search/EcommerceSearch';
import BrandScienceTab from './BrandScienceTab';
// import AffirmationsTab from './AffirmationsTab';
// import SurveyTab from './SurveyTab';
import { capitalizeFirstLetter } from '../../../utils/strings';
import { createUserActivityLog } from '../../../utils/users';
import history from '../../../routers/history';

export default class BrandTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'analysis',
      categoryId: undefined,
      categoryType: undefined,
      category: undefined,
      brandId: undefined,
      brand: undefined,
      allowTabToLoadMap: {
        'analysis': true
      },
      scrollToDetails: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.categoryName &&
      this.props.match.params.brandName
    ) {
      this.setStateFromParams(
        this.props.match.params.categoryName,
        this.props.match.params.brandName
      );
    }
    this.processLocation();
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.categoryName !== this.props.match.params.categoryName ||
      prevProps.match.params.brandName !== this.props.match.params.brandName ||
      prevProps.categories !== this.props.categories
    ) {
      this.setStateFromParams(
        this.props.match.params.categoryName,
        this.props.match.params.brandName
      );
    }
    if (prevProps.location !== this.props.location) {
      this.processLocation();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
    this.props.setHeaderLabels('', '');
  };

  setStateFromParams = (categoryName, brandName) => {
    let categoryId;
    let categoryType;
    let category;
    let brandId;
    let brand;
    let topicId;
    // find category
    for (const c of this.props.categories) {
      if (c.name === decodeURIComponent(categoryName)) {
        categoryId = c.id;
        categoryType = c.category_type;
        category = c;
        // find product brand
        for (const b of c.product_brands) {
          if (b.name === decodeURIComponent(brandName)) {
            brandId = b.id;
            brand = b;
            if (b.topic) {
              topicId = b.topic.id;
            }
          }
        }
      }
    }

    if (category && brand) {
      this.props.setHeaderLabels(
        category.name,
        <div className="d-inline-block">
          { brand.company_favicon_url &&
            <img
              className="mr-2"
              src={brand.company_favicon_url}
              alt=""
              crossOrigin="anonymous"
              style={{ width: '16px', height: '16px' }}
            />
          }
          {brand.name}
        </div>
      );
    } else {
      this.props.setHeaderLabels(
        decodeURIComponent(categoryName),
        <div className="d-inline-block">
          {decodeURIComponent(brandName)}
        </div>
      );
    }
    if (categoryId && categoryType && brand) {
      const companyId = brand.company_id;
      const audienceProfileId = category.audience_company_id;
      this.props.fetchBrandChannelScoresMap(this.props.user.customerId, companyId, brand.id, categoryId, categoryType, audienceProfileId);
      createUserActivityLog(
        this.props.user,
        `Brand Tabs: ${capitalizeFirstLetter(this.state.selectedTab)}`,
        category,
        brand
      );
    }

    this.setState(() => ({
      categoryId,
      categoryType,
      category,
      brandId,
      brand,
      topicId
    }));
  };

  processLocation = () => {
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let selectedTab = hash.indexOf("-")>0 ? hash.substring(0, hash.indexOf("-")) : hash;
      if (!selectedTab) {
        selectedTab = 'analysis';
      }
      this.setState(() => ({
        selectedTab,
        allowTabToLoadMap: {
          [selectedTab]: true
        }
      }));
    }
    if (this.props.location.search) {
      const queryValues = queryString.parse(this.props.location.search);
      const startDate = moment(queryValues.startDate);
      const endDate = moment(queryValues.endDate);
      history.replace(`${this.props.location.pathname}${this.props.location.hash}`);
      this.onFilterDatesChange(startDate, endDate);
      this.setState(() => ({ scrollToDetails: true }));
    }
  };

  onFilterDatesChange = (startDate, endDate) => {
    this.props.setStartDateFilter(startDate);
    this.props.setEndDateFilter(endDate);
  };

  onCppCheckChange = (type, checked) => {
    if (type === 'company') {
      this.props.updateCompanyChecked(checked);
    } else if (type === 'portfolio') {
      this.props.updatePortfolioChecked(checked);
    } else if (type === 'product') {
      this.props.updateProductChecked(checked);
    }
  };

  unsetScrollToDetails = () => {
    this.setState(() => ({ scrollToDetails: false }));
  };

  render () {
    return (
      <div>
        { this.props.user.customerId &&
          <Tabs
            className="top-level-tabs"
            defaultActiveKey= "analysis"
            activeKey={this.state.selectedTab}
            onSelect={(selectedTab) => {
              // update hash for tab routing
              history.push(`#${selectedTab}`);
              this.setState((prevState) => ({
                selectedTab,
                allowTabToLoadMap: {
                  ...prevState.allowTabToLoadMap,
                  [selectedTab]: true
                }
              }));
              createUserActivityLog(
                this.props.user,
                `Brand Tabs: ${capitalizeFirstLetter(selectedTab)}`,
                this.state.category,
                this.state.brand
              );
            }}
          >
            <Tab
              eventKey="analysis"
              title="Analysis"
              tabClassName="brand-analysis-tab"
            >
              { (
                  this.state.allowTabToLoadMap.analysis &&
                  this.state.brand
                ) &&
                <AnalysisTab
                  location={this.props.location}
                  user={this.props.user}
                  customerId={this.props.user.customerId}
                  categoryId={this.state.categoryId}
                  categoryType={this.state.categoryType}
                  category={this.state.category}
                  brandId={this.state.brandId}
                  brand={this.state.brand}
                  brandName={this.state.brand.name}
                />
              }
            </Tab>
            <Tab
              eventKey="company"
              title="Company"
              tabClassName="brand-company-tab"
            >
              { (
                  this.state.allowTabToLoadMap.company &&
                  this.state.brand
                ) &&
                <CompanyTab
                  location={this.props.location}
                  sixMonths={this.props.sixMonths}
                  selectedMonth={this.props.selectedMonth}
                  updateSelectedMonth={this.props.updateSelectedMonth}
                  user={this.props.user}
                  customerId={this.props.user.customerId}
                  categoryId={this.state.categoryId}
                  categoryType={this.state.categoryType}
                  category={this.state.category}
                  brandId={this.state.brandId}
                  brand={this.state.brand}
                  brandName={this.state.brand.name}
                  brandChannelScoresMap={this.props.brandChannelScoresMap}
                  brandChannelScoresMapLoading={this.props.brandChannelScoresMapLoading}
                  allMonthsChecked={this.props.allMonthsChecked}
                  updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                  allMonthsStartDate={moment(this.props.customer.license_start_date).startOf('month')}
                />
              }
            </Tab>
            <Tab
              eventKey="web"
              title="Website"
              tabClassName="brand-website-tab"
            >
              { (
                  this.state.allowTabToLoadMap.web &&
                  this.state.topicId &&
                  this.state.brand
                ) &&
                <WebTab
                  location={this.props.location}
                  customerId={this.props.user.customerId}
                  customerUserId={this.props.user.id}
                  categoryId={this.state.categoryId}
                  categoryType={this.state.categoryType}
                  category={this.state.category}
                  brandId={this.state.brandId}
                  brand={this.state.brand}
                  brandName={this.state.brand.name}
                  topicId={this.state.topicId}
                  companyId={this.state.brand.company_id}
                  startDateFilter={this.props.startDateFilter}
                  endDateFilter={this.props.endDateFilter}
                  onFilterDatesChange={this.onFilterDatesChange}
                  scrollToDetails={this.state.scrollToDetails}
                  unsetScrollToDetails={this.unsetScrollToDetails}
                  allMonthsChecked={this.props.allMonthsChecked}
                  updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                  allMonthsStartDate={moment(this.props.customer.license_start_date).startOf('month')}
                  companyChecked={this.props.companyChecked}
                  portfolioChecked={this.props.portfolioChecked}
                  productChecked={this.props.productChecked}
                  onCppCheckChange={this.onCppCheckChange}
                />
              }
            </Tab>
            <Tab
              eventKey="search"
              title="Search"
              tabClassName="brand-search-tab"
            >
              { (
                  this.state.allowTabToLoadMap.search &&
                  this.state.category
                ) &&
                <CategorySearch
                  displayType="brand"
                  location={this.props.location}
                  user={this.props.user}
                  sixMonths={this.props.sixMonths}
                  selectedMonth={this.props.selectedMonth}
                  updateSelectedMonth={this.props.updateSelectedMonth}
                  categoryId={this.state.categoryId}
                  category={this.state.category}
                  categories={this.props.categories}
                  brand={this.state.brand}
                />
              }
            </Tab>
            <Tab
              eventKey="reviews"
              title="Reviews"
              tabClassName="brand-reviews-tab"
            >
              { (
                  this.state.allowTabToLoadMap.reviews &&
                  this.state.topicId &&
                  this.state.brand
                ) &&
                <ReviewsTab
                  location={this.props.location}
                  customerId={this.props.user.customerId}
                  categoryId={this.state.categoryId}
                  categoryType={this.state.categoryType}
                  category={this.state.category}
                  brandId={this.state.brandId}
                  brand={this.state.brand}
                  brandName={this.state.brand.name}
                  topicId={this.state.topicId}
                  startDateFilter={this.props.startDateFilter}
                  endDateFilter={this.props.endDateFilter}
                  onFilterDatesChange={this.onFilterDatesChange}
                  scrollToDetails={this.state.scrollToDetails}
                  unsetScrollToDetails={this.unsetScrollToDetails}
                  allMonthsChecked={this.props.allMonthsChecked}
                  updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                  allMonthsStartDate={moment(this.props.customer.license_start_date).startOf('month')}
                />
              }
            </Tab>
            <Tab
              eventKey="email"
              title="Email"
              tabClassName="brand-email-tab"
            >
              { (
                  this.state.allowTabToLoadMap.email &&
                  this.state.topicId &&
                  this.state.brand
                ) &&
                <EmailTab
                  location={this.props.location}
                  customerId={this.props.user.customerId}
                  customerUserId={this.props.user.id}
                  categoryId={this.state.categoryId}
                  categoryType={this.state.categoryType}
                  category={this.state.category}
                  brandId={this.state.brandId}
                  brand={this.state.brand}
                  brandName={this.state.brand.name}
                  topicId={this.state.topicId}
                  companyId={this.state.brand.company_id}
                  scrollToDetails={this.state.scrollToDetails}
                  unsetScrollToDetails={this.unsetScrollToDetails}
                  allMonthsChecked={this.props.allMonthsChecked}
                  updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                  allMonthsStartDate={moment(this.props.customer.license_start_date).startOf('month')}
                  companyChecked={this.props.companyChecked}
                  portfolioChecked={this.props.portfolioChecked}
                  productChecked={this.props.productChecked}
                  onCppCheckChange={this.onCppCheckChange}
                />
              }
            </Tab>
            <Tab
              eventKey="social"
              title="Social"
              tabClassName="brand-social-tab"
            >
              { (
                  this.state.allowTabToLoadMap.social &&
                  this.state.topicId &&
                  this.state.brand
                ) &&
                <SocialTab
                  location={this.props.location}
                  customerId={this.props.user.customerId}
                  customerUserId={this.props.user.id}
                  categoryId={this.state.categoryId}
                  categoryType={this.state.categoryType}
                  category={this.state.category}
                  brandId={this.state.brandId}
                  brand={this.state.brand}
                  brandName={this.state.brand.name}
                  topicId={this.state.topicId}
                  companyId={this.state.brand.company_id}
                  startDateFilter={this.props.startDateFilter}
                  endDateFilter={this.props.endDateFilter}
                  onFilterDatesChange={this.onFilterDatesChange}
                  scrollToDetails={this.state.scrollToDetails}
                  unsetScrollToDetails={this.unsetScrollToDetails}
                  allMonthsChecked={this.props.allMonthsChecked}
                  updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                  allMonthsStartDate={moment(this.props.customer.license_start_date).startOf('month')}
                  companyChecked={this.props.companyChecked}
                  portfolioChecked={this.props.portfolioChecked}
                  productChecked={this.props.productChecked}
                  onCppCheckChange={this.onCppCheckChange}
                />
              }
            </Tab>
            <Tab
              eventKey="mediacast"
              title="Media Cast"
              tabClassName="brand-mediacast-tab"
            >
              { (
                  this.state.allowTabToLoadMap.mediacast &&
                  this.state.brand
                ) &&
                <MediaCastTab
                  location={this.props.location}
                  user={this.props.user}
                  customerId={this.props.user.customerId}
                  categoryId={this.state.categoryId}
                  categoryType={this.state.categoryType}
                  category={this.state.category}
                  brandId={this.state.brandId}
                  brand={this.state.brand}
                  brandName={this.state.brand.name}
                  companyId={this.state.brand.company_id}
                  allMonthsChecked={this.props.allMonthsChecked}
                  updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                  allMonthsStartDate={moment(this.props.customer.license_start_date).startOf('month')}
                  companyChecked={this.props.companyChecked}
                  portfolioChecked={this.props.portfolioChecked}
                  productChecked={this.props.productChecked}
                  onCppCheckChange={this.onCppCheckChange}
                />
              }
            </Tab>
            <Tab
              eventKey="news"
              title="News"
              tabClassName="brand-news-tab"
            >
              { (
                  this.state.allowTabToLoadMap.news &&
                  this.state.topicId &&
                  this.state.brand
                ) &&
                <NewsTab
                  user={this.props.user}
                  categoryId={this.state.categoryId}
                  categoryType={this.state.categoryType}
                  category={this.state.category}
                  brandId={this.state.brandId}
                  brand={this.state.brand}
                  brandName={this.state.brand.name}
                  topicId={this.state.topicId}
                  startDateFilter={this.props.startDateFilter}
                  endDateFilter={this.props.endDateFilter}
                  onFilterDatesChange={this.onFilterDatesChange}
                  scrollToDetails={this.state.scrollToDetails}
                  unsetScrollToDetails={this.unsetScrollToDetails}
                  allMonthsChecked={this.props.allMonthsChecked}
                  updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                  allMonthsStartDate={moment(this.props.customer.license_start_date).startOf('month')}
                  companyChecked={this.props.companyChecked}
                  portfolioChecked={this.props.portfolioChecked}
                  productChecked={this.props.productChecked}
                  onCppCheckChange={this.onCppCheckChange}
                />
              }
            </Tab>
            <Tab
              eventKey="audience"
              title="Audience"
              tabClassName="brand-audience-tab"
            >
              { (
                  this.state.allowTabToLoadMap.audience &&
                  this.state.topicId &&
                  this.state.brand
                ) &&
                <AudienceTab
                  customerId={this.props.user.customerId}
                  customerUserId={this.props.user.id}
                  categoryId={this.state.categoryId}
                  categoryType={this.state.categoryType}
                  category={this.state.category}
                  brandId={this.state.brandId}
                  brand={this.state.brand}
                  brandName={this.state.brand.name}
                  topicId={this.state.topicId}
                  companyId={this.state.brand.company_id}
                />
              }
            </Tab>
            {/*
              <Tab
                eventKey="affirmation"
                title="Affirmation"
                tabClassName="brand-affirmation-tab"
              >
                { (
                    this.state.allowTabToLoadMap.affirmation &&
                    this.state.topicId &&
                    this.state.brand
                  ) &&
                  <AffirmationsTab
                    user={this.props.user}
                    categoryId={this.state.categoryId}
                    categoryType={this.state.categoryType}
                    category={this.state.category}
                    brandId={this.state.brandId}
                    brand={this.state.brand}
                    brandName={this.state.brand.name}
                    topicId={this.state.topicId}
                    scrollToDetails={this.state.scrollToDetails}
                    unsetScrollToDetails={this.unsetScrollToDetails}
                  />
                }
              </Tab>
            */}
            <Tab
              eventKey="identity"
              title="Identity"
              tabClassName="brand-identity-tab"
            >
              { (
                  this.state.allowTabToLoadMap.identity &&
                  this.state.topicId &&
                  this.state.brand
                ) &&
                <IdentityTab
                  location={this.props.location}
                  sixMonths={this.props.sixMonths}
                  selectedMonth={this.props.selectedMonth}
                  updateSelectedMonth={this.props.updateSelectedMonth}
                  categoryId={this.state.categoryId}
                  categoryType={this.state.categoryType}
                  category={this.state.category}
                  brandId={this.state.brandId}
                  brand={this.state.brand}
                  brandName={this.state.brand.name}
                />
              }
            </Tab>
            <Tab
              eventKey="ads"
              title="Ads"
              tabClassName="brand-ads-tab"
            >
              { (
                  this.state.allowTabToLoadMap.ads &&
                  this.state.brand
                ) &&
                <AdsTab
                  location={this.props.location}
                  customerId={this.props.user.customerId}
                  customerUserId={this.props.user.id}
                  categoryId={this.state.categoryId}
                  categoryType={this.state.categoryType}
                  category={this.state.category}
                  brandId={this.state.brandId}
                  brand={this.state.brand}
                  brandName={this.state.brand.name}
                  allMonthsChecked={this.props.allMonthsChecked}
                  updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                  allMonthsStartDate={moment(this.props.customer.license_start_date).startOf('month')}
                />
              }
            </Tab>
            { (this.props.customer && this.props.customer.show_ecommerce) &&
              <Tab
                eventKey="ecommerce"
                title="Ecommerce"
                tabClassName="brand-ecommerce-tab"
              >
                { (
                    this.state.allowTabToLoadMap.ecommerce &&
                    this.state.brand
                  ) &&
                  <EcommerceSearch
                    user={this.props.user}
                    sixMonths={this.props.sixMonths}
                    selectedMonth={this.props.selectedMonth}
                    updateSelectedMonth={this.props.updateSelectedMonth}
                    categoryId={this.state.categoryId}
                    category={this.state.category}
                    categories={this.props.categories}
                  />
                }
              </Tab>
            }
            { (this.props.customer && this.props.customer.show_brand_science) &&
              <Tab
                eventKey="brandscience"
                title={
                  <div>
                    Brand Science
                    <Badge
                      className="ml-1"
                      variant="warning"
                      pill
                    >
                      Beta
                    </Badge>
                  </div>
                }
                tabClassName="brand-science-tab"
              >
                { (
                    this.state.allowTabToLoadMap.brandscience &&
                    this.state.brand
                  ) &&
                  <BrandScienceTab
                    user={this.props.user}
                    location={this.props.location}
                    categoryId={this.state.categoryId}
                    categoryType={this.state.categoryType}
                    category={this.state.category}
                    brandId={this.state.brandId}
                    brand={this.state.brand}
                    brandName={this.state.brand.name}
                  />
                }
              </Tab>
            }
            { /*
              <Tab
                eventKey="surveys"
                title={
                  <div style={{ color: 'red' }}>
                    Surveys
                  </div>
                }
                tabClassName="brand-surveys-tab"
              >
                { (
                    this.state.allowTabToLoadMap.surveys &&
                    this.state.topicId &&
                    this.state.brand
                  ) &&
                  <SurveyTab
                    customerId={this.props.user.customerId}
                    customerUserId={this.props.user.id}
                    categoryId={this.state.categoryId}
                    categoryType={this.state.categoryType}
                    category={this.state.category}
                    brandId={this.state.brandId}
                    brand={this.state.brand}
                    brandName={this.state.brand.name}
                    topicId={this.state.topicId}
                    companyId={this.state.brand.company_id}
                  />
                }
              </Tab>
            */}
          </Tabs>
        }
      </div>
    );
  }
};
