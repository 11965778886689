import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../common/CardComponent';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { round } from '../../utils/numbers';
import { colors } from '../../utils/graphs';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryReviews extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryReviewsData: [],
      categoryReviewsGraphData: [],
      reviewSites: {},
      categoryReviewsDataLoading: false,
      startDate: moment().utc().subtract(6, 'month').startOf('month'),
      endDate: moment().utc().endOf('day'),
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchCategoryReviews();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.fetchCategoryReviews();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchCategoryReviews = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryReviewsDataLoading: true }));
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-reviews?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        const categoryReviewsData = response.data;
        const reviewSites = {};
        for (const review of categoryReviewsData) {
          if (!reviewSites[review.review_site_id]) {
            reviewSites[review.review_site_id] = {
              id: review.review_site_id,
              name: review.review_site_name,
              checked: true
            }
          }
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            categoryReviewsData,
            reviewSites,
            categoryReviewsDataLoading: false
          }), () => this.setCategoryReviewsGraphData());
        }
      }).catch(error => {
        console.error('Error: failed to fetch category reviews data.');
        if (this.state.isMounted) {
          this.setState(() => ({
            categoryReviewsData: [],
            reviewSites: {},
            categoryReviewsDataLoading: false
          }), () => this.setCategoryReviewsGraphData());
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  setCategoryReviewsGraphData = () => {
    const categoryReviewsGraphData = [];
    // aggregate stars by month based on checked review sites
    const dataAggregateMap = {};
    // populate months
    let date = moment.utc(this.state.startDate);
    while (date.isBefore(this.state.endDate)) {
      dataAggregateMap[date.format('MMMM')] = { date: date.format('YYYY-MM-DD HH:mm:ss') };
      date = moment(date).add(1, 'month');
    }
    for (const review of this.state.categoryReviewsData) {
      if (
        this.state.reviewSites[review.review_site_id] &&
        this.state.reviewSites[review.review_site_id].checked
      ) {
        const reviewMonth = moment.utc(review.review_date).format('MMMM');
        if (!dataAggregateMap[reviewMonth][review.product_brand_name]) {
          dataAggregateMap[reviewMonth][review.product_brand_name] = {
            total_stars: review.score * 5,
            total_reviews: 1
          };
        } else {
          dataAggregateMap[reviewMonth][review.product_brand_name].total_stars += review.score * 5;
          dataAggregateMap[reviewMonth][review.product_brand_name].total_reviews += 1;
        }
      }
    }
    for (const [monthName, monthData] of Object.entries(dataAggregateMap)) {
      const graphMonthData = { month: monthName };
      for (const brand of this.props.category.product_brands) {
        graphMonthData[brand.name] = null;
      }
      for (const [key, values] of Object.entries(monthData)) {
        if (key === 'date') {
          graphMonthData[key] = values;
        } else {
          graphMonthData[key] = round((values.total_stars / values.total_reviews), 1)
        }
      }
      categoryReviewsGraphData.push(graphMonthData)
    }
    this.setState(() => ({ categoryReviewsGraphData }));
  };

  onReviewSiteCheckedChange = (reviewSite, event) => {
    const checked = event.currentTarget.checked;
    if (this.state.reviewSites[reviewSite.id].checked !== checked) {
      this.setState((prevState) => {
        const reviewSites = prevState.reviewSites;
        reviewSites[reviewSite.id].checked = checked;
        return ({ reviewSites });
      }, () => this.setCategoryReviewsGraphData());
    }
  };

  render () {
    return (
      <CardComponent
        title="Review Stars"
        body={
          <div>
            { this.state.categoryReviewsDataLoading &&
              <div className="m-4 text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.categoryReviewsDataLoading &&
              <div>
                <div className="text-secondary" style={{ fontSize: '14px' }}>
                  <Row>
                    <Col xs={12} md={3} lg={2}>
                      <div className="mt-4 ml-4">
                        { this.props.category.product_brands.map((brand, i) => {
                            return (
                              <Row
                                key={`crs-l-b-${brand.id}`}
                                style={{
                                  flexWrap: 'nowrap',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}
                                noGutters
                              >
                                <Col style={{ flex: '0 0 16px'}}>
                                  <div
                                    className="mt-1"
                                    style={{
                                      width: '16px',
                                      height: '16px',
                                      borderRadius: '50%',
                                      backgroundColor: colors[i % colors.length]
                                    }}
                                  />
                                </Col>
                                <Col style={{ flex: '0 0 calc(100% - 16px)' }}>
                                  <div className="ml-2">
                                    {brand.name}
                                  </div>
                                </Col>
                              </Row>
                            )
                          })
                        }
                      </div>
                    </Col>
                    <Col xs={12} md={9} lg={10}>
                      <div className="mb-2 ml-5">
                        { Object.values(this.state.reviewSites).map((reviewSite) => {
                            return (
                              <Form.Check
                                key={`crs-rs-${reviewSite.id}`}
                                className="mr-3"
                                type="checkbox"
                                label={reviewSite.name}
                                checked={reviewSite.checked}
                                onChange={(event) => this.onReviewSiteCheckedChange(reviewSite, event)}
                                inline
                              />
                            )
                          })
                        }
                      </div>
                      <ResponsiveContainer height={300} width="99%">
                        <LineChart data={this.state.categoryReviewsGraphData}>
                          <XAxis
                            dataKey="date"
                            tickFormatter={(date) => {
                              return moment.utc(date).format('MMMM');
                            }}
                          />
                          <YAxis domain={[0, 5]}/>
                          <CartesianGrid strokeDasharray="5 5"/>
                          <Tooltip labelFormatter={(label) => moment(label).format('MMMM')}/>
                          { this.props.category.product_brands.map((brand, i) => {
                              return (
                                <Line
                                  key={`crs-l-${brand.id}`}
                                  name={brand.name}
                                  type="monotone"
                                  dataKey={brand.name}
                                  stroke={colors[i % colors.length]}/>
                              )
                            })
                          }
                        </LineChart>
                      </ResponsiveContainer>
                    </Col>
                  </Row>
                </div>
              </div>
            }
          </div>
        }
        contextType="category"
        contextCategory={this.props.category}
        contextBrand={null}
        contextChannel="Product Reviews"
        contextChartName="Review Stars"
      />
    );
  }
};
