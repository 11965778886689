import React from 'react';
import moment from 'moment';
import CardComponent from '../common/CardComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '../../utils/numbers';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

const VideoInteractionsGraph = (props) => (
  <CardComponent
    title="Net New Interactions"
    icon={
      <FontAwesomeIcon
        className="mr-2"
        icon={faThumbsUp}
        color="#4fa2f3"
      />
    }
    description="Video interactions such as likes, dislikes, comments, etc."
    body={
      <ResponsiveContainer width="99%" height={250}>
        <LineChart
          data={props.captureData}
          margin={{
            top: 10, right: 20, bottom: 0, left: 25,
          }}
        >
          <XAxis
            dataKey="captured_at"
            tickFormatter={(date) => {
              return moment(date).endOf('day').format('MM/DD');
            }}
            minTickGap={20}
          />
          <YAxis
            tick={{ fontSize: 12 }}
            tickFormatter={(count) => {
              return numberWithCommas(count);
            }}
            allowDecimals={false}
          />
          <Tooltip
            labelFormatter={(label) => moment(label).format('dddd, MMMM Do YYYY')}
            formatter={(value) => numberWithCommas(value)}
          />
          <CartesianGrid strokeDasharray="5 5"/>
          <Line name="Net New Likes" type="monotone" dataKey="net_likes_count" stroke="#007bff"/>
          <Line name="Net New Dislikes" type="monotone" dataKey="net_dislikes_count" stroke="#ff4d4d"/>
          <Line name="Net New Comments" type="monotone" dataKey="net_comments_count" stroke="#ffcc00"/>
          <Line name="Net New Favorites" type="monotone" dataKey="net_favorites_count" stroke="#009900"/>
        </LineChart>
      </ResponsiveContainer>
    }
    contextType="brand"
    contextCategory={props.category}
    contextBrand={props.brand}
    contextChannel="Youtube"
    contextProperty="Video Stats"
    contextChartName="Net New Interactions"
  />
);

export default VideoInteractionsGraph;
