import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Support = () => (
  <div className='m-4'>
    <Jumbotron>
      <h1>
        Brand<b className="text-bops-blue">Ops</b> Support
      </h1>
      <hr />
      <p>
        <FontAwesomeIcon
          className="mr-2"
          icon={faEnvelope}
        />
        Please email us at <a href = "mailto: support@brandops.io">support@brandops.io</a> if you have any questions, issues, or suggestions.
        We would love to hear from you!
      </p>
    </Jumbotron>
  </div>
);

export default Support;
