import axios from 'axios';
import moment from 'moment';
import { store } from '../../store/configureStore';
import { setShowRefreshAlert } from '../refreshAlert/refreshAlert';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';

export const setListenProjectBuild = () => {
  return (dispatch) => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/build-check`,
      HEADERS
    ).then(response => {
      const listenProjectBuild = response.data;
      dispatch(addListenProjectBuild(listenProjectBuild));
    }).catch(error => {
      dispatch(addListenProjectBuild({}));
    });
  };
};

export const listenBuildCheck = (errorResponse) => {
  return (dispatch, getStore, er) => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/build-check`,
      HEADERS
    ).then(response => {
      const reduxStore = getStore();
      const latestListenProjectBuild = response.data;
      const appListenProjectBuild = reduxStore.listenProjectBuild;
      if (appListenProjectBuild.build_tag !== latestListenProjectBuild.build_tag) {
        dispatch(setShowRefreshAlert(true));
      } else {
        let customerUserId;
        if (reduxStore.user && reduxStore.user.id) {
          customerUserId = reduxStore.user.id
        }
        if (process.env.NODE_ENV === 'production' && appListenProjectBuild.project_build_id && customerUserId) {
          const apiPath = `/api/${errorResponse.config.url.split('/api/')[1]}`;
          const exceptionBody = {
            project_build_id: appListenProjectBuild.project_build_id,
            exception_at: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            customer_user_id: customerUserId,
            api_path: apiPath,
            message: errorResponse.data.message || errorResponse.statusText,
            status_code: errorResponse.status
          };

          axios.post(
            `${LISTEN_ENDPOINT}/api/project-build-exceptions`,
            exceptionBody,
            HEADERS
          );
        }
      }
    }).catch(error => {
      console.log('Error: build check failed');
    });
  };
};

export const dispatchListenBuildCheck = (errorResponse) => {
  store.dispatch(listenBuildCheck(errorResponse));
};

export const addListenProjectBuild = (listenProjectBuild) => ({
  type: 'ADD_LISTEN_PROJECT_BUILD',
  listenProjectBuild
});
