import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../../common/CardComponent';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  ResponsiveContainer
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { intToShorthandString, numberWithCommas, round } from '../../../utils/numbers';
import { shorthandMonth } from '../../../utils/dates';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class BrandPowerTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brandPowerTrendData: [],
      brandPowerTrendDataLoading: false,
      brandPower: undefined,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchBrandPowerTrendData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.category !== this.props.category ||
      prevProps.brand !== this.props.brand
    ) {
      this.fetchBrandPowerTrendData();
    }
  };

  fetchBrandPowerTrendData = () => {
    if (this.props.brand) {
      this.setState(() => ({ brandPowerTrendDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/product-brands/${this.props.brand.id}/brand-power-trend`,
        HEADERS
      ).then(response => {
        const brandPowerTrendData = response.data.monthly_stats;
        // remove last month if data is null
        if (brandPowerTrendData[brandPowerTrendData.length-1].brand_power_with_cpp == null) {
          brandPowerTrendData.pop();
        }
        // if second to last month also null, display available date label
        if (brandPowerTrendData[brandPowerTrendData.length-1].brand_power_with_cpp == null) {
          brandPowerTrendData[brandPowerTrendData.length-1].show_date_available_label = true;
          brandPowerTrendData[brandPowerTrendData.length-1].brand_power_with_cpp = 0;
        }
        // round brand power numbers
        for (const bp of brandPowerTrendData) {
          if (bp.brand_power_with_cpp) {
            if (bp.brand_power_with_cpp > 2) {
              bp.brand_power_with_cpp = Math.round(bp.brand_power_with_cpp);
            } else {
              bp.brand_power_with_cpp = round(bp.brand_power_with_cpp, 1);
            }
          }
        }
        let brandPower;
        for (let i = brandPowerTrendData.length-1; i > 0; i--) {
          if (
            brandPowerTrendData[i].brand_power_with_cpp != null &&
            !brandPowerTrendData[i].show_date_available_label
          ) {
            brandPower = brandPowerTrendData[i].brand_power_with_cpp;
            break;
          }
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            brandPowerTrendData,
            brandPowerTrendDataLoading: false,
            brandPower,
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch brand power trend data...`);
        if (this.state.isMounted) {
          if (this.state.isMounted) {
            this.setState(() => ({
              brandPowerTrendData: [],
              brandPowerTrendDataLoading: false,
              brandPower: undefined,
            }));
          }
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        <CardComponent
          title="Brand Power"
          body={
            <div>
              { this.state.brandPowerTrendDataLoading &&
                <div className="m-4 text-center">
                  <ClipLoader size={100}/>
                </div>
              }
              { !this.state.brandPowerTrendDataLoading &&
                <div>
                  <Row>
                    <Col xs={6} lg={3}>
                      <div
                        className="pt-2 h-100 bg-bops-blue text-light text-center"
                        style={{
                          fontSize: '1.2rem',
                          fontWeight: 'bold'
                        }}
                      >
                        Brand Power
                      </div>
                    </Col>
                    <Col xs={6} lg={9}>
                      Brand Power measures the extent to which a brand can
                      attract and influence buyers. Grow your brand power by
                      increasing followers, reach and traffic to digital
                      properties.
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={6} lg={3}>
                      <div
                        className="pt-5 text-center"
                        style={{ fontSize: '3rem', fontWeight: 'bold' }}
                      >
                        { this.state.brandPower !== undefined &&
                          <div>
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faBolt}
                            />
                            {numberWithCommas(this.state.brandPower)}
                          </div>
                        }
                      </div>
                    </Col>
                    <Col xs={6} lg={9}>
                      <ResponsiveContainer width="99%" height={250}>
                        <BarChart
                          data={this.state.brandPowerTrendData}
                          margin={{
                            top: 20,
                            left: 10
                          }}
                        >
                          <XAxis
                            dataKey="month"
                            tickFormatter={(month) => {
                              return shorthandMonth(month);
                            }}
                          />
                          <YAxis
                            dataKey={this.props.dataKey}
                            tick={{ fontSize: 12 }}
                            tickFormatter={(value) => {
                              if (this.props.percentValues) {
                                return `${numberWithCommas(value)}%`
                              } else {
                                if (this.props.yAxisShorthandInt) {
                                  return intToShorthandString(value);
                                } else {
                                  return numberWithCommas(value);
                                }
                              }
                            }}
                          />
                          <Bar
                            name="Brand Power"
                            dataKey="brand_power_with_cpp"
                            fill="#007bff"
                          >
                            <LabelList
                              valueAccessor={(data, index) => {
                                let value = data.brand_power_with_cpp;
                                if (value === 0 && data.show_date_available_label) {
                                  const dateDisplay = moment().date(3).format('MM/DD');
                                  return `Avail. on ${dateDisplay}`;
                                } else {
                                  return value;
                                }
                              }}
                              fill="#000000"
                              position="top"
                              formatter={(value) => {
                                if (typeof(value) === 'string') {
                                  return value;
                                } else if (value > 999999) {
                                  return intToShorthandString(value);
                                } else {
                                  return numberWithCommas(value);
                                }
                              }}
                              style={{ fontSize: '.75rem' }}
                            />

                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </Col>
                  </Row>
                </div>
              }
            </div>
          }
          contextType="brand"
          contextCategory={this.props.category}
          contextBrand={this.props.brand}
          contextChannel="Analysis"
          contextChartName="Brand Power"
          shareClassName="brand-power-img"
        />
      </div>
    );
  }
};
