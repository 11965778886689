import React from 'react';
import axios from 'axios';
import moment from 'moment';
import GeneralSummaryGraph from './GeneralSummaryGraph';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class NewsReachPerMillionGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newsReachPerMillionData: [],
      newsReachPerMillionScoreType: undefined,
      newsReachPerMillionDataLoading: false,
      categoryAverageData: [],
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getNewsReachPerMillionData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.brand !== this.props.brand ||
      prevProps.allMonthsChecked !== this.props.allMonthsChecked
    ) {
      this.getNewsReachPerMillionData();
    }
  }

  getNewsReachPerMillionData = () => {
    if (this.props.brand) {
      this.setState(() => ({ newsReachPerMillionDataLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/news-reach-per-million-trend?product_brand_id=${this.props.brand.id}${allMonthsStartDateParam ? `&start-date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let newsReachPerMillionData = response.data.monthly_stats;
        const newsReachPerMillionScoreType = response.data.total_reach_per_million ? response.data.total_reach_per_million.score_type : undefined;
        // round total reach
        for (const result of newsReachPerMillionData) {
          if (result.total_reach_per_million) {
            result.total_reach_per_million = Math.round(result.total_reach_per_million);
          }
        }
        // merge category average data into original data source
        newsReachPerMillionData = newsReachPerMillionData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAverageData[i]))
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            newsReachPerMillionData,
            newsReachPerMillionScoreType,
            newsReachPerMillionDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error loading news reach per million trend...');
        this.setState(() => ({
          newsReachPerMillionData: [],
          newsReachPerMillionScoreType: undefined,
          newsReachPerMillionDataLoading: false
        }));
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=news_avg_reach${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAverageData = response.data.category_stats;
          // merge category average data into original data source
          const newsReachPerMillionData = this.state.newsReachPerMillionData.map((x, i) => {
            return (Object.assign({}, x, categoryAverageData[i]))
          });

          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAverageData,
              newsReachPerMillionData
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average news reach data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAverageData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  }

  render () {
    return (
      <div>
        <GeneralSummaryGraph
          title="Aggregated News Reach"
          metricName="news_aggregate_reach_per_million"
          data={this.state.newsReachPerMillionData}
          dataKey="total_reach_per_million"
          dataLabel="Aggregated News Reach"
          yAxisShorthandInt={true}
          scoreType={this.state.newsReachPerMillionScoreType}
          loading={this.state.newsReachPerMillionDataLoading}
          showPercentChange={true}
          showAverageData={true}
          averageDataKey="news_avg_reach"
          averageDataLabel="Category Average"
          showTooltip={true}
          contextType="brand"
          contextCategory={this.props.category}
          contextBrand={this.props.brand}
          contextChannel="News"
          contextChartName="Aggregated News Reach"
        />
      </div>
    );
  }
};
