import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../common/CardComponent';
import GeneralSummaryGraph from '../graphs/GeneralSummaryGraph';
import StarScoreBar from '../graphs/StarScoreBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';

export default class BrandChannelSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      channelText: {
        'Blogs': {
          description: 'The Blog score measures if a brand is actively using the blog channel to provide fresh content to its audience.',
          key_to_success: 'The key to success is to consistently add new content or refresh existing content in a way that causes it to show as a new blog entry.'
        },
        'Email Campaigns': {
          description: (
            <span>Emails Campaign is <b>private data</b> only available for your brands. The score measures how engaged your email audience is with your brand.</span>
          ),
          key_to_success: 'The key to success is delivering emails that users open and with content that compels them to click through to your website. The score is also increased when emails are sent consistently and few users mark them as spam or unsubscribe.'
        },
        'Emails': {
          description: 'The Emails Captured score measures if a brand is actively initiating and maintaining relationships with those who have expressed interest in your brand by providing their contact information.',
          key_to_success: 'The key to success is adding those who have provided their email address to your nurture campaigns and newsletters and maintaining a steady stream of messages to your lists.'
        },
        'News': {
          description: 'The News score measures the likelihood that news readers encounter mentions of the brand.',
          key_to_success: 'The key to success is having a combination of news mentions on a few sites with broad reach or many sites whose aggregated reach is large.'
        },
        'Product Reviews': {
          description: 'The Reviews score measures the degree to which users advocate for the brand and share positive sentiment. ',
          key_to_success: 'The key to success is ensuring that the share of negative reviews stays low because those are so damaging to the brand. It’s also important to have a high overall star rating and a high percentage of 4 & 5 star reviews. Finally, since buyers value recent reviews, it helps to hit monthly goals for total review counts.'
        },
        'Social': {
          description: "The Social score measures a brand's activity, follower growth, engagement and sentiment across social networks.",
          key_to_success: 'The key to success is regularly posting content that causes network users to follow the brand, engage on and share the content, and encourages positive discussion about the brand.'
        },
        'Surveys via BrandOps': {
          description: '',
          key_to_success: ''
        },
        'Videos': {
          description: 'The Video score measures if a brand is effectively using video to grow engagement with its audience. Brands must both be present in the channel and deliver compelling content.',
          key_to_success: 'The key to success is increasing total views of the brand’s video content. The score is also increased when the brand’s videos rank highly in video search results, users subscribe to the brand’s video channels, and the cadence of posting is consistent.'
        },
        'Web Analytics': {
          description: 'Web Analytics is private data only available for your brands. The score measures your brand’s ability to move visitors to brand consideration and then express buying interest.',
          key_to_success: 'The key to success in this channel is converting more of your total traffic into Buying Signal Traffic (e.g., pricing and contact pages). It’s also important to grow the number of direct (unaided) visits and Consideration Traffic, which includes the other page types shown here.'
        },
        'Web': {
          description: 'The Web External Stats score measures a brand’s ability to be found by prospects via search and other sites. It also considers overall site users and the level of engagement with the brand’s web content.',
          key_to_success: 'A key to success is being present on the first page of search results for the category’s search terms (via ads, if necessary). It’s also important to create content that others link to, attracts many users, and keeps users engaged.'
        },
        'Webinar': {
          description: 'The Webinar score measures if a brand is regularly conducting and promoting webinars.',
          key_to_success: 'The key to success is having a regular cadence of webinars and promoting them on social channels. For brands that provide private data, a high ratio of attendees to sign ups has a significant impact on the score.'
        },
        'Podcast': {
          description: 'The Podcast score measures the brand’s success in producing its own podcasts and getting interest among other podcasters.',
          key_to_success: 'The key to success is having a consistent podcast owned by the brand and generating mentions of the brand in podcasts produced by others.'
        },
        'LinkedIn': {
          description: 'The LinkedIn score measures if a brand is getting significant engagement on the network. Engagement on LinkedIn is often highlighted to users across the network which grows brand awareness and interest.',
          key_to_success: 'The key to success is posting content that leads to engagement. The score is also increased by regular posting and getting an appropriate number of followers for the category. Sentiment does not affect this score, but impacts broader brand scores.'
        },
        'Twitter': {
          description: 'The Twitter score measures if a brand is effective at using the network to amplify the brand’s messages.',
          key_to_success: 'The key to success is posting content regularly that includes backlinks and gets shared. Increasing mentions of the brand and the total follower count also boost the score. Post sentiment does not affect this score, but impacts broader brand scores.'
        },
        'Facebook': {
          description: 'The Facebook score measures if a brand is effective at using the network to amplify the brand’s messages.',
          key_to_success: 'The key to success is posting content regularly that includes backlinks.'
        },
        'Instagram': {
          description: 'The Instagram score measures if a brand is effective at using the network to amplify the brand’s messages.',
          key_to_success: 'The key to success is posting content regularly that includes backlinks.'
        },
        'Display Ads': {
          description: 'The Display Ads score measures the brand’s active use of ads to bring traffic to its web properties.',
          key_to_success: 'The key to success is experimenting with and refreshing content, which is measured by the count of ads identified for the brand, and building a number of landing pages that can capture prospects with particular interests.'
        },
        'Search Ads': {
          description: 'The Search Ads score measures the brand’s active use of ads to bring traffic to its web properties.',
          key_to_success: 'The key to success is bringing significant traffic to the brand’s properties via AdWords ads.'
        },
        'Search Engine Results': {
          description: 'The Search score measures the brand’s effectiveness to rank well in search results.',
          key_to_success: 'The key to success is to post quality content and improve your SEO.'
        },
        'Employer Reviews': {
          description: 'The Reviews score measures the degree to which employees advocate for the brand and share positive sentiment.',
          key_to_success: 'The key to success is ensuring that the share of negative reviews stays low because those are so damaging to the brand. It’s also important to have a high overall star rating and a high percentage of 4 & 5 star reviews.'
        }
      }
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {

  };

  render () {
    return (
      <div>
        <Row>
          <Col>
            <CardComponent
              title="Overview"
              body={
                <div>
                  { this.state.channelText[this.props.overviewOverride] &&
                    <div style={{ fontSize: '.875rem' }}>
                      <div>
                        {this.state.channelText[this.props.overviewOverride].description}
                      </div>
                      <div className="mt-4">
                        {this.state.channelText[this.props.overviewOverride].key_to_success}
                      </div>
                    </div>
                  }
                  { (!this.props.overviewOverride && this.state.channelText[this.props.channel]) &&
                    <div style={{ fontSize: '.875rem' }}>
                      <div>
                        {this.state.channelText[this.props.channel].description}
                      </div>
                      <div className="mt-4">
                        {this.state.channelText[this.props.channel].key_to_success}
                      </div>
                    </div>
                  }
                  { !this.props.brandChannelScoresMapLoading &&
                    <div>
                      { (
                          this.props.brandChannelScoresMap[this.props.channel] &&
                          this.props.brandChannelScoresMap[this.props.channel][this.props.brandChannelScoresMap[this.props.channel].length-1]
                        ) &&
                        <div className="mt-3 text-center">
                          <div style={{ fontSize: '2rem' }}>
                            <StarScoreBar
                              score={this.props.brandChannelScoresMap[this.props.channel][this.props.brandChannelScoresMap[this.props.channel].length-1].score}
                              maxScore={100}
                            />
                          </div>
                          <div style={{ fontSize: '.75rem' }}>
                            Trailing 30 days
                          </div>
                        </div>
                      }
                    </div>
                  }
                </div>
              }
              contextType="brand"
              contextCategory={this.props.category}
              contextBrand={this.props.brand}
              contextChannel={this.props.channel}
              contextChartName="Overview"
            />
          </Col>
          <Col>
            { this.props.brandChannelScoresMapLoading &&
              <div className="m-4 text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.props.brandChannelScoresMapLoading &&
              <GeneralSummaryGraph
                icon={
                  <FontAwesomeIcon
                    className="mt-1 mr-2"
                    icon={faChartLine}
                    color="#4fa2f3"
                    style={{ fontSize: '1.4rem' }}
                  />
                }
                title="Goal Attainment"
                data={this.props.brandChannelScoresMap[this.props.channel] || []}
                dataKey="score"
                dataLabel={this.props.channel}
                loading={false}
                percentValues={true}
                minDomainValue={0}
                maxDomainValue={100}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel={this.props.channel}
                contextChartName="Goal Attainment"
              />
            }
          </Col>
        </Row>
      </div>
    );
  }
};
