import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';
import CustomerObjectiveForm from './CustomerObjectiveForm';
import CustomerObjectiveMetricForm from './CustomerObjectiveMetricForm';
import CustomerObjectiveUserForm from './CustomerObjectiveUserForm';
import CustomerObjectiveMetricGoalForm from './CustomerObjectiveMetricGoalForm';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { sortAlphabeticalByKey } from '../../utils/sorts';
import { numberWithCommas, round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';
import history from '../../routers/history';

export default class CustomerObjectives extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      goalPeriods: [],
      selectedGoalPeriod: undefined,
      objectivesAndMetrics: [],
      objectivesAndMetricsLoading: false,
      objectiveUsers: [],
      selectedObjective: undefined,
      selectedObjectiveMetric: undefined,
      selectedObjectiveUser: undefined,
      brandMetrics: [],
      users: [],
      objectiveForms: [],
      objectiveMetricForms: [],
      objectiveUserForms: [],
      brandGoals: [],
      brandGoalsMap: {},
      deleteObjectiveConfirmationModalOpen: false,
      deleteMetricConfirmationModalOpen: false,
      deleteUserConfirmationModalOpen: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.myCategoryBrands.length > 0) {
      this.setState(() => ({
        category: this.props.myCategoryBrands[0].category,
        brand: this.props.myCategoryBrands[0].brand,
      }), () => this.fetchBrandMetricGoals());
    }
    this.setGoalPeriods();
    this.fetchObjectivesAndMetrics();
    this.fetchBrandMetrics();
    this.fetchUsers();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.customerId !== this.props.user.customerId) {
      this.fetchObjectivesAndMetrics();
      this.fetchUsers();
    }
    if (prevState.selectedObjective !== this.state.selectedObjective) {
      this.fetchObjectiveUsers();
    }
    if (
      prevProps.myCategoryBrands !== this.props.myCategoryBrands &&
      this.props.myCategoryBrands.length > 0
    ) {
      this.setState(() => ({
        category: this.props.myCategoryBrands[0].category,
        brand: this.props.myCategoryBrands[0].brand,
      }), () => this.fetchBrandMetricGoals());
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  setGoalPeriods = () => {
    const goalPeriods = [];
    let date = moment();
    const selectedGoalPeriod = {
      label: `Q${date.quarter()} - ${date.year()}`,
      startDate: moment(date).startOf('quarter'),
      endDate: moment(date).endOf('quarter'),
    };
    goalPeriods.push(selectedGoalPeriod);
    for (let i = 0; i < 4; i++) {
      date.add(3, 'months');
      goalPeriods.push({
        label: `Q${date.quarter()} - ${date.year()}`,
        startDate: moment(date).startOf('quarter'),
        endDate: moment(date).endOf('quarter'),
      });
    }

    this.setState(() => ({
      goalPeriods,
      selectedGoalPeriod
    }), () => this.fetchBrandMetricGoals());
  };

  fetchObjectivesAndMetrics = () => {
    if (this.props.user && this.props.user.customerId) {
      this.setState(() => ({ objectivesAndMetricsLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/customer-objectives-and-metrics?customer_id=${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        const objectivesAndMetrics = response.data;
        // sort objectives and metrics alphabetically
        objectivesAndMetrics.sort(sortAlphabeticalByKey('objective_name'));
        for (let obj of objectivesAndMetrics) {
          obj.metrics.sort(sortAlphabeticalByKey('metric_label'));
        }
        // set selected objective
        let selectedObjective;
        if (objectivesAndMetrics.length > 0) {
          if (this.state.selectedObjective) {
            for (let objective of objectivesAndMetrics) {
              if (objective.customer_objective_id === this.state.selectedObjective.customer_objective_id) {
                selectedObjective = objective;
              }
            }
          } else {
            selectedObjective = objectivesAndMetrics[0];
          }
        }
        // set selected objective metric
        let selectedObjectiveMetric;
        if (selectedObjective && this.state.selectedObjectiveMetric) {
          for (let objective of objectivesAndMetrics) {
            for (let metric of objective.metrics) {
              if (metric.metric_id === this.state.selectedObjectiveMetric.metric_id) {
                selectedObjectiveMetric = metric;
              }
            }
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            objectivesAndMetrics,
            objectivesAndMetricsLoading: false,
            selectedObjective,
            selectedObjectiveMetric,
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch objectives and metrics');
        if (this.state.isMounted) {
          this.setState(() => ({
            objectivesAndMetrics: [],
            objectivesAndMetricsLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchBrandMetrics = () => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/brand-metrics`,
      HEADERS
    ).then(response => {
      const brandMetrics = [];
      // filter brand metrics for applicable metrics
      for (const bm of response.data) {
        if (
          bm.production_status === 'prod' &&
          bm.brand_stage_id !== 6 &&
          !bm.hide_metric &&
          !bm.metric_label.toLowerCase().includes('net new') &&
          bm.metric_label !== 'Review Stars (Smoothed)' &&
          bm.metric_label !== 'Negative Sentiment Multiplier'
        ) {
          brandMetrics.push(bm)
        }
      }
      brandMetrics.sort(sortAlphabeticalByKey('metric_label'));
      if (this.state.isMounted) {
        this.setState(() => ({ brandMetrics }));
      }
    }).catch(error => {
      console.error('Error: failed to fetch brand metrics');
      if (this.state.isMounted) {
        this.setState(() => ({ brandMetrics: [] }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  fetchBrandMetricGoals = () => {
    if (this.state.brand && this.state.goalPeriods.length > 0) {
      const formattedStartDate = this.state.goalPeriods[0].startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.goalPeriods[this.state.goalPeriods.length-1].endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/product-brands/${this.state.brand.id}/brand-metric-goals?start-date=${formattedStartDate}&end-date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        const brandGoals = response.data;
        const brandGoalsMap = {};
        for (let pbg of brandGoals) {
          const date = moment.utc(pbg.goal_date);
          const goalPeriod = `Q${date.quarter()} - ${date.year()}`;
          if (brandGoalsMap[goalPeriod]) {
            if (brandGoalsMap[goalPeriod][pbg.brand_metric_id]) {
              if (pbg.brand_metric_aggregation_type === 'count') {
                brandGoalsMap[goalPeriod][pbg.brand_metric_id].goal += pbg.goal_value;
              } else {
                brandGoalsMap[goalPeriod][pbg.brand_metric_id].goal = pbg.goal_value;
              }
              brandGoalsMap[goalPeriod][pbg.brand_metric_id].existingGoals.push(pbg);
            } else {
              brandGoalsMap[goalPeriod][pbg.brand_metric_id] = {
                goal: pbg.goal_value,
                weight: pbg.goal_weight,
                existingGoals: [pbg],
              }
            }
          } else {
            brandGoalsMap[goalPeriod] = {
              [pbg.brand_metric_id]: {
                goal: pbg.goal_value,
                weight: pbg.goal_weight,
                existingGoals: [pbg],
              }
            }
          }
          // set weight label for each goal
          if (pbg.goal_weight === 1) {
            brandGoalsMap[goalPeriod][pbg.brand_metric_id].weight_label = 'Low';
          } else if (pbg.goal_weight === 2) {
            brandGoalsMap[goalPeriod][pbg.brand_metric_id].weight_label = 'Medium';
          } else if (pbg.goal_weight === 3) {
            brandGoalsMap[goalPeriod][pbg.brand_metric_id].weight_label = 'High';
          } else {
            brandGoalsMap[goalPeriod][pbg.brand_metric_id].weight_label = '-';
          }
        }

        // attempt to round goals due to discrepancy of period and months
        for (let period of Object.values(brandGoalsMap)) {
          for (let metricGoal of Object.values(period)) {
            metricGoal.goal = round(metricGoal.goal, 2);
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            brandGoals,
            brandGoalsMap
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch brand goals');
        if (this.state.isMounted) {
          this.setState(() => ({
            brandGoals: [],
            brandGoalsMap: {}
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchUsers = () => {
    if (this.props.user && this.props.user.customerId) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/customer-users/customer-id/${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        const users = response.data;
        for (let user of users) {
          if (user.first_name) {
            if (user.last_name) {
                user.name_label = `${user.first_name} ${user.last_name}`;
            } else {
              user.name_label = user.first_name;
            }
          } else {
            user.name_label = user.email;
          }
        }
        users.sort(sortAlphabeticalByKey('name_label'));
        if (this.state.isMounted) {
          this.setState(() => ({ users }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch users');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        if (this.state.isMounted) {
          this.setState(() => ({ users: [] }));
        }
      });
    }
  };

  fetchObjectiveUsers = () => {
    if (this.state.selectedObjective) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/customer-objective-to-users?customer_objective_id=${this.state.selectedObjective.customer_objective_id}`,
        HEADERS
      ).then(response => {
        const objectiveUsers = response.data;
        for (let user of objectiveUsers) {
          if (user.first_name) {
            if (user.last_name) {
                user.name_label = `${user.first_name} ${user.last_name}`;
            } else {
              user.name_label = user.first_name;
            }
          } else {
            user.name_label = user.email;
          }
        }
        objectiveUsers.sort(sortAlphabeticalByKey('name_label'));
        // set selected objective user
        let selectedObjectiveUser;
        if (this.state.selectedObjectiveUser) {
          for (let user of objectiveUsers) {
            if (user.id === this.state.selectedObjectiveUser.customer_user_id) {
              selectedObjectiveUser = user;
            }
          }
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            objectiveUsers,
            selectedObjectiveUser,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch objective users');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            objectiveUsers: [],
            selectedObjectiveUser: undefined,
          }));
        }
      });
    }
  };

  onGoalPeriodChange = (event) => {
    const goalPeriodLabel = event.currentTarget.value;
    let selectedGoalPeriod;
    for (let gp of this.state.goalPeriods) {
      if (goalPeriodLabel === gp.label) {
        selectedGoalPeriod = gp;
      }
    }
    this.setState(() => ({ selectedGoalPeriod }));
  };

  selectObjective = (selectedObjective) => {
    if (selectedObjective !== this.state.selectedObjective) {
      this.setState(() => ({
        selectedObjective,
        objectiveMetricForms: [],
      }));
    }
  };

  addObjectiveForm = () => {
    const id = Math.random().toString(36).substring(7);
    this.setState((prevState) => ({
      objectiveForms: [...prevState.objectiveForms, id],
      selectedObjective: undefined,
    }));
  };

  removeObjectiveForm = (id) => {
    this.setState((prevState) => ({
      objectiveForms: prevState.objectiveForms.filter(cofi => cofi !== id)
    }));
  };

  openDeleteObjectiveConfirmationModal = () => {
    if (this.state.selectedObjective) {
      this.setState(() => ({
        deleteObjectiveConfirmationModalOpen: true,
        deleteObjectiveId: this.state.selectedObjective.customer_objective_id,
        deleteObjectiveName: this.state.selectedObjective.objective_name
      }));
    }
  };

  closeDeleteObjectiveConfirmationModal = () => {
    this.setState(() => ({
      deleteObjectiveConfirmationModalOpen: false,
      deleteObjectiveId: undefined,
      deleteObjectiveName: undefined
    }));
  };

  deleteObjective = () => {
    if (this.state.deleteObjectiveId) {
      axios.delete(
        `${LISTEN_ENDPOINT}/api/customer-objectives/${this.state.deleteObjectiveId}`,
        HEADERS
      ).then(response => {
        if (this.state.isMounted) {
          this.fetchObjectivesAndMetrics();
          this.setState(() => ({ selectedObjective: undefined }));
          this.closeDeleteObjectiveConfirmationModal();
        }
      }).catch(error => {
        console.error('Error: failed to delete customer objective');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  selectObjectiveMetric = (selectedObjectiveMetric) => {
    if (selectedObjectiveMetric !== this.state.selectedObjectiveMetric) {
      this.setState(() => ({ selectedObjectiveMetric }));
    }
  };

  isObjectiveSetup = (objective) => {
    let objectiveSetup = true;
    if (objective.metrics.length === 0) {
      objectiveSetup = false;
    } else {
      for (let metric of objective.metrics) {
        if (
          !this.state.brandGoalsMap ||
          !this.state.selectedGoalPeriod ||
          !this.state.brandGoalsMap[this.state.selectedGoalPeriod.label] ||
          !this.state.brandGoalsMap[this.state.selectedGoalPeriod.label][metric.metric_id]
        ) {
          objectiveSetup = false;
        }
      }
    }
    return objectiveSetup;
  };

  addObjectiveMetricForm = () => {
    const id = Math.random().toString(36).substring(7);
    this.setState((prevState) => ({
      objectiveMetricForms: [...prevState.objectiveMetricForms, id],
      selectedObjectiveMetric: undefined,
    }));
  };

  removeObjectiveMetricForm = (id) => {
    this.setState((prevState) => ({
      objectiveMetricForms: prevState.objectiveMetricForms.filter(comfi => comfi !== id)
    }));
  };

  openDeleteMetricConfirmationModal = () => {
    if (this.state.selectedObjectiveMetric) {
      this.setState(() => ({
        deleteMetricConfirmationModalOpen: true,
        deleteObjectiveMetricId: this.state.selectedObjectiveMetric.objective_metric_id,
        deleteObjectiveMetricName: this.state.selectedObjectiveMetric.metric_label
      }));
    }
  };

  closeDeleteMetricConfirmationModal = () => {
    this.setState(() => ({
      deleteMetricConfirmationModalOpen: false,
      deleteObjectiveMetricId: undefined,
      deleteObjectiveMetricName: undefined
    }));
  };

  deleteObjectiveMetric = () => {
    if (this.state.deleteObjectiveMetricId) {
      axios.delete(
        `${LISTEN_ENDPOINT}/api/customer-objective-to-metrics/${this.state.deleteObjectiveMetricId}`,
        HEADERS
      ).then(response => {
        if (this.state.isMounted) {
          this.fetchObjectivesAndMetrics();
          this.setState(() => ({ selectedObjectiveMetric: undefined }));
          this.closeDeleteMetricConfirmationModal();
        }
      }).catch(error => {
        console.error('Error: failed to delete customer objective metric');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  selectObjectiveUser = (selectedObjectiveUser) => {
    if (selectedObjectiveUser !== this.state.selectedObjectiveUser) {
      this.setState(() => ({ selectedObjectiveUser }));
    }
  };

  addObjectiveUserForm = () => {
    const id = Math.random().toString(36).substring(7);
    this.setState((prevState) => ({
      objectiveUserForms: [...prevState.objectiveUserForms, id],
      selectedObjectiveUser: undefined,
    }));
  };

  removeObjectiveUserForm = (id) => {
    this.setState((prevState) => ({
      objectiveUserForms: prevState.objectiveUserForms.filter(coufi => coufi !== id)
    }));
  };

  openDeleteUserConfirmationModal = () => {
    if (this.state.selectedObjectiveUser) {
      this.setState(() => ({
        deleteUserConfirmationModalOpen: true,
        deleteObjectiveUserId: this.state.selectedObjectiveUser.customer_objective_to_user_id,
        deleteObjectiveUserName: this.state.selectedObjectiveUser.name_label
      }));
    }
  };

  closeDeleteUserConfirmationModal = () => {
    this.setState(() => ({
      deleteUserConfirmationModalOpen: false,
      deleteObjectiveUserId: undefined,
      deleteObjectiveUserName: undefined
    }));
  };

  deleteObjectiveUser = () => {
    if (this.state.deleteObjectiveUserId) {
      axios.delete(
        `${LISTEN_ENDPOINT}/api/customer-objective-to-users/${this.state.deleteObjectiveUserId}`,
        HEADERS
      ).then(response => {
        if (this.state.isMounted) {
          this.fetchObjectiveUsers();
          this.setState(() => ({ selectedObjectiveUser: undefined }));
          this.closeDeleteUserConfirmationModal();
        }
      }).catch(error => {
        console.error('Error: failed to delete customer objective user');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="m-2">
        <div className="mb-2">
          <div className="d-inline-block ml-3 mr-2">Period</div>
          <div className="d-inline-block">
            <Form.Control
              as="select"
              value={
                this.state.selectedGoalPeriod ?
                  this.state.selectedGoalPeriod.label : ''
              }
              onChange={this.onGoalPeriodChange}
              size="sm"
            >
              { this.state.goalPeriods.map((goalPeriod, i) => {
                  return (
                    <option
                      key={`co-gp-${i}`}
                      value={goalPeriod.label}
                    >
                      {goalPeriod.label}
                    </option>
                  )
                })
              }
            </Form.Control>
          </div>
        </div>
        <Row>
          <Col>
            <div className="mx-3">
              <h4>
                Objectives
                <div className="d-inline-block float-right">
                  <Button
                    className="mr-2"
                    variant="success"
                    onClick={this.addObjectiveForm}
                    size="sm"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={this.openDeleteObjectiveConfirmationModal}
                    size="sm"
                    disabled={!this.state.selectedObjective}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </Button>
                </div>
              </h4>
              <ListGroup
                as="ul"
                className="pre-scrollable border rounded"
                style={{
                  minHeight: '250px',
                  maxHeight: '250px',
                  overflow: 'auto',
                  overflowX: 'hidden'
                }}
              >
                { this.state.objectiveForms.map(id => {
                    return (
                      <ListGroup.Item
                        key={`of-${id}`}
                        as="li"
                      >
                        <CustomerObjectiveForm
                          id={id}
                          customerId={this.props.user.customerId}
                          removeObjectiveForm={this.removeObjectiveForm}
                          refreshObjectives={this.fetchObjectivesAndMetrics}
                        />
                      </ListGroup.Item>
                    )
                  })
                }
                { this.state.objectivesAndMetrics.map(obj => {
                    return (
                      <ListGroup.Item
                        key={`o-${obj.customer_objective_id}`}
                        as="li"
                        onClick={() => this.selectObjective(obj)}
                        active={this.state.selectedObjective && this.state.selectedObjective.customer_objective_id === obj.customer_objective_id}
                        style={{ cursor: 'pointer' }}
                      >
                        { this.isObjectiveSetup(obj) &&
                          <FontAwesomeIcon
                            className="text-success mr-2"
                            icon={faCheck}
                          />
                        }
                        {obj.objective_name}
                      </ListGroup.Item>
                    )
                  })
                }
              </ListGroup>
            </div>
          </Col>
          <Col>
            <div className="mx-3">
              <h4>
                Supporting Metrics
                <div className="d-inline-block float-right">
                  <Button
                    className="mr-2"
                    variant="success"
                    onClick={this.addObjectiveMetricForm}
                    size="sm"
                    disabled={!this.state.selectedObjective}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={this.openDeleteMetricConfirmationModal}
                    size="sm"
                    disabled={!this.state.selectedObjective || !this.state.selectedObjectiveMetric}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </Button>
                </div>
              </h4>
              <ListGroup
                as="ul"
                className="pre-scrollable border rounded"
                style={{
                  minHeight: '250px',
                  maxHeight: '250px',
                  overflow: 'auto',
                  overflowX: 'hidden'
                }}
              >
                { this.state.selectedObjective &&
                  <React.Fragment>
                    { this.state.objectiveMetricForms.map(id => {
                        return (
                          <ListGroup.Item
                            key={`omf-${id}`}
                            as="li"
                          >
                            <CustomerObjectiveMetricForm
                              id={id}
                              objectiveId={this.state.selectedObjective.customer_objective_id}
                              brandMetrics={this.state.brandMetrics}
                              removeObjectiveMetricForm={this.removeObjectiveMetricForm}
                              refreshObjectives={this.fetchObjectivesAndMetrics}
                            />
                          </ListGroup.Item>
                        )
                      })
                    }
                    { this.state.selectedObjective.metrics.map(metric => {
                        return (
                          <ListGroup.Item
                            key={`om-${metric.metric_id}`}
                            as="li"
                            onClick={() => this.selectObjectiveMetric(metric)}
                            active={this.state.selectedObjectiveMetric && this.state.selectedObjectiveMetric.metric_id === metric.metric_id}
                            style={{ cursor: 'pointer' }}
                          >
                            { (
                                this.state.brandGoalsMap &&
                                this.state.selectedGoalPeriod &&
                                this.state.brandGoalsMap[this.state.selectedGoalPeriod.label] &&
                                this.state.brandGoalsMap[this.state.selectedGoalPeriod.label][metric.metric_id]
                              ) &&
                              <Row noGutters>
                                <Col xs={7}>
                                  <FontAwesomeIcon
                                    className="text-success mr-2"
                                    icon={faCheck}
                                  />
                                  {metric.metric_label}
                                </Col>
                                <Col xs={2}>
                                  {this.state.brandGoalsMap[this.state.selectedGoalPeriod.label][metric.metric_id].weight_label}
                                </Col>
                                <Col xs={3}>
                                  { this.state.brandGoalsMap[this.state.selectedGoalPeriod.label][metric.metric_id].goal != null ?
                                      numberWithCommas(this.state.brandGoalsMap[this.state.selectedGoalPeriod.label][metric.metric_id].goal) :
                                      '-'
                                  }
                                </Col>
                              </Row>
                            }
                            { !(
                                this.state.brandGoalsMap &&
                                this.state.selectedGoalPeriod &&
                                this.state.brandGoalsMap[this.state.selectedGoalPeriod.label] &&
                                this.state.brandGoalsMap[this.state.selectedGoalPeriod.label][metric.metric_id]
                              ) &&
                              <div>
                                {metric.metric_label}
                              </div>
                            }
                          </ListGroup.Item>
                        )
                      })
                    }
                  </React.Fragment>
                }
              </ListGroup>
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <div className="mx-3">
              <h4>
                People
                <div className="d-inline-block float-right">
                  <Button
                    className="mr-2"
                    variant="link"
                    onClick={() => history.push('/company-settings/users')}
                    size="sm"
                  >
                    Add users
                  </Button>
                  <Button
                    className="mr-2"
                    variant="success"
                    onClick={this.addObjectiveUserForm}
                    size="sm"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={this.openDeleteUserConfirmationModal}
                    size="sm"
                    disabled={!this.state.selectedObjectiveUser}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </Button>
                </div>
              </h4>
              <ListGroup
                as="ul"
                className="pre-scrollable border rounded"
                style={{
                  minHeight: '250px',
                  maxHeight: '250px',
                  overflow: 'auto',
                  overflowX: 'hidden'
                }}
              >
                { this.state.objectiveUserForms.map(id => {
                    return (
                      <ListGroup.Item
                        key={`ouf-${id}`}
                        as="li"
                      >
                        <CustomerObjectiveUserForm
                          id={id}
                          objectiveId={this.state.selectedObjective.customer_objective_id}
                          users={this.state.users}
                          removeObjectiveUserForm={this.removeObjectiveUserForm}
                          refreshObjectiveUsers={this.fetchObjectiveUsers}
                        />
                      </ListGroup.Item>
                    )
                  })
                }
                { this.state.objectiveUsers.map(user => {
                    return (
                      <ListGroup.Item
                        key={`u-${user.customer_user_id}`}
                        as="li"
                        onClick={() => this.selectObjectiveUser(user)}
                        active={this.state.selectedObjectiveUser && this.state.selectedObjectiveUser.customer_user_id === user.customer_user_id}
                        style={{ cursor: 'pointer' }}
                      >
                        {user.name_label}
                      </ListGroup.Item>
                    )
                  })
                }
              </ListGroup>
            </div>
          </Col>
          <Col>
            <div className="mx-3">
              { !this.state.selectedObjectiveMetric &&
                <Alert variant="info">
                  Select a metric above.
                </Alert>
              }
              { this.state.selectedObjectiveMetric &&
                <CustomerObjectiveMetricGoalForm
                  customerId={this.props.user.customerId}
                  goalPeriod={this.state.selectedGoalPeriod}
                  objectivesAndMetrics={this.state.objectivesAndMetrics}
                  brandMetricId={this.state.selectedObjectiveMetric.metric_id}
                  brandMetric={this.state.selectedObjectiveMetric}
                  brandId={
                    this.state.brand ?
                      this.state.brand.id :
                      undefined
                  }
                  brand={this.state.brand}
                  category={this.state.category}
                  brandGoalsMap={this.state.brandGoalsMap}
                  refreshGoals={this.fetchBrandMetricGoals}
                />
              }
            </div>
          </Col>
        </Row>

        <DeleteConfirmationModal
          isOpen={this.state.deleteObjectiveConfirmationModalOpen}
          handleClose={this.closeDeleteObjectiveConfirmationModal}
          resourceName={this.state.deleteObjectiveName}
          deleteResource={this.deleteObjective}
        />
        <DeleteConfirmationModal
          isOpen={this.state.deleteMetricConfirmationModalOpen}
          handleClose={this.closeDeleteMetricConfirmationModal}
          resourceName={this.state.deleteObjectiveMetricName}
          deleteResource={this.deleteObjectiveMetric}
        />
        <DeleteConfirmationModal
          isOpen={this.state.deleteUserConfirmationModalOpen}
          handleClose={this.closeDeleteUserConfirmationModal}
          resourceName={this.state.deleteObjectiveUserName}
          deleteResource={this.deleteObjectiveUser}
        />
      </div>
    );
  }
};
