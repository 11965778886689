import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import LoginContainer from '../../../containers/common/LoginContainer';
import AdminAuthenticationMessage from '../../common/AdminAuthenticationMessage';
import { isAuthenticatedAdmin } from '../../../utils/auth';
import LicenseUsage from '../../common/LicenseUsage';
import DeleteConfirmationModal from '../../common/DeleteConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailchimp } from '@fortawesome/free-brands-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class MailChimpSetup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companyPrivateKeys: [],
      mailchimpPrivateKey: undefined,
      mailchimpPrivateKeyLoading: false,
      apiKey: '',
      deleteConfirmationModalOpen: false,
      deletePrivateKeyId: undefined,
      licenseAllowsAdd: false,
      errors: {},
      errorMessage: ''
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchCompanyMailchimpKey();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.customerId !== this.props.user.customerId) {
      this.fetchCompanyMailchimpKey();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchCompanyMailchimpKey = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({
        mailchimpPrivateKeyLoading: true,
        apiKey: ''
      }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/private-keys?company_id=${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        const companyPrivateKeys = response.data;
        let mailchimpPrivateKey;
        for (const privateKey of companyPrivateKeys) {
          if (privateKey.key_type === 'mail_chimp') {
            mailchimpPrivateKey = privateKey;
          }
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            companyPrivateKeys,
            mailchimpPrivateKey,
            mailchimpPrivateKeyLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch company private keys');
        if (this.state.isMounted) {
          this.setState(() => ({
            companyPrivateKeys: [],
            mailchimpPrivateKey: undefined,
            mailchimpPrivateKeyLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  refreshCompanyPrivateKeys = () => {
    this.licenseUsage.refresh();
    this.fetchCompanyMailchimpKey();
  };

  onApiKeyChange = (event) => {
    const apiKey = event.currentTarget.value;
    this.setState(() => ({ apiKey }));
  };

  isValidForm = () => {
    const errors = {};
    let errorMessage = '';
    if (!this.state.apiKey || this.state.apiKey.trim() === '') {
      errors.apiKey = true;
      errorMessage = 'Must provide an API key'
    }

    this.setState(() => ({
      errors,
      errorMessage
    }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  savePrivateKey = () => {
    if (this.isValidForm()) {
      const privateKey = {
        company_id: this.props.user.customerId,
        key_type: 'mail_chimp',
        private_key: this.state.apiKey
      };

      axios.post(
        `${LISTEN_ENDPOINT}/api/private-keys`,
        privateKey,
        HEADERS
      ).then(response => {
        this.refreshCompanyPrivateKeys();
      }).catch(error => {
        this.setState(() => ({ errorMessage: 'Failed to create new private key' }));
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  openDeleteConfirmationModal = () => {
    if (this.state.mailchimpPrivateKey) {
      this.setState(() => ({
        deleteConfirmationModalOpen: true,
        deletePrivateKeyId: this.state.mailchimpPrivateKey.id,
      }));
    }
  };

  closeDeleteConfirmationModal = () => {
    this.setState(() => ({
      deleteConfirmationModalOpen: false,
      deletePrivateKeyId: undefined,
    }));
  };

  deletePrivateKey = () => {
    if (this.state.deletePrivateKeyId) {
      axios.delete(
        `${LISTEN_ENDPOINT}/api/private-keys/${this.state.deletePrivateKeyId}`,
        HEADERS
      ).then(response => {
        this.closeDeleteConfirmationModal();
        this.refreshCompanyPrivateKeys();
      }).catch(error => {
        console.log('Error: failed to delete company private key');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  updateLicenseAllowsAdd = (licenseAllowsAdd) => {
    if (licenseAllowsAdd !== this.state.licenseAllowsAdd) {
      this.setState(() => ({ licenseAllowsAdd }));
    }
  };

  render () {
    return (
      <div className="m-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        <AdminAuthenticationMessage user={this.props.user}/>
        { isAuthenticatedAdmin(this.props.user) &&
          <div>
            <div
              className="border rounded p-4"
              style={{ backgroundColor: '#e9ecef' }}
            >
              <div className="d-inline-block pr-2">
                <FontAwesomeIcon
                  icon={faMailchimp}
                  size="2x"
                  color="#454d54"
                />
              </div>
              <div className="d-inline-block">
                <h4>Mailchimp</h4>
              </div>
              <p>Connect your Mailchimp provider.</p>
              <hr className="my-4" />
              <p>Email campaign statistics like "opens", "unsubscribed", "bounce", and "spam complaints" can be used to gauge the success of a brand across the funnel.</p>
            </div>
            <div className="mt-4">
              <LicenseUsage
                ref={(ref) => this.licenseUsage = ref}
                type="privateCollectors"
                customerId={this.props.user.customerId}
                updateLicenseAllowsAdd={this.updateLicenseAllowsAdd}
              />
              <div className="mt-4">
                <Row>
                  <Col>
                    <div>
                    </div>
                    { this.state.mailchimpPrivateKeyLoading &&
                      <div className="m-4 text-center">
                        <ClipLoader size={100}/>
                      </div>
                    }
                    { !this.state.mailchimpPrivateKeyLoading &&
                      <div
                        className="pre-scrollable"
                        style={{
                          maxHeight: 'calc(100vh - 400px)',
                          overflow: 'auto'
                        }}
                      >
                        { this.state.mailchimpPrivateKey &&
                          <div>
                            <b>Existing Setup</b>
                            <div className="mt-2">
                              <div>
                                {`API Key: ***************`}
                              </div>
                              <hr />
                              <div className="mt-3">
                                <Button
                                  variant="danger"
                                  onClick={this.openDeleteConfirmationModal}
                                  size="sm"
                                >
                                  Delete Mailchimp Setup
                                </Button>
                              </div>
                            </div>
                          </div>
                        }
                        { !this.state.mailchimpPrivateKey &&
                          <div>
                            <Form>
                              <Form.Group>
                                <Form.Label>API Key</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={this.state.apiKey}
                                  onChange={this.onApiKeyChange}
                                  isInvalid={this.state.errors.apiKey}
                                />
                              </Form.Group>
                              <Alert show={Boolean(this.state.errorMessage)} variant="danger">
                                {this.state.errorMessage}
                              </Alert>
                              <Button
                                variant="primary"
                                onClick={this.savePrivateKey}
                              >
                                Save
                              </Button>
                            </Form>
                          </div>
                        }
                      </div>
                    }
                  </Col>
                  <Col>
                  </Col>
                </Row>
              </div>
            </div>

            <DeleteConfirmationModal
              isOpen={this.state.deleteConfirmationModalOpen}
              handleClose={this.closeDeleteConfirmationModal}
              resourceName="Mailchimp setup"
              deleteResource={this.deletePrivateKey}
            />
          </div>
        }
      </div>
    );
  }
};
