import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import ClipLoader from 'react-spinners/ClipLoader';
import DateRangePicker from '../../common/DateRangePicker';
import BrandChannelSummaryContainer from '../../../containers/brands/BrandChannelSummaryContainer';
import BrandMetricsByMonthTableContainer from '../../../containers/metrics/BrandMetricsByMonthTableContainer';
import BlogSummaryGraph from '../../blogs/BlogSummaryGraph';
import BlogsDisplay from '../../blogs/BlogsDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBlog } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '../../../utils/numbers';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class BlogsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gaSetup: false,
      blogRssSetup: false,
      blogUrls: [], // only shown if rss not setup
      blogUrlsDrawerOpen: false,
      blogs: [],
      blogResults: [],
      blogsLoading: false,
      blogPageViewsMap: {},
      cppCounts: {},
      startDate: moment().utc().subtract(6, 'month').startOf('day'),
      endDate: moment().utc().endOf('day'),
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.gaSetupCheck();
    this.loadCompanyBlogData();
    if (this.props.scrollToDetails) {
      this.blogScroll.scrollTop = this.blogDetails.offsetTop - this.blogScroll.offsetTop;
      this.props.unsetScrollToDetails();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.category !== this.props.category ||
      prevProps.brand !== this.props.brand
    ) {
      this.gaSetupCheck();
      this.loadCompanyBlogData();
    }
    if (
        prevState.startDate !== this.state.startDate ||
        prevState.endDate !== this.state.endDate
    ) {
      this.loadCompanyBlogData();
    }
    if (
      prevProps.companyChecked !== this.props.companyChecked ||
      prevProps.portfolioChecked !== this.props.portfolioChecked ||
      prevProps.productChecked !== this.props.productChecked
    ) {
      this.updateBlogResults();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  gaSetupCheck = () => {
    this.setState(() => ({ gaSetup: false }));
    if (this.props.customerId === this.props.companyId) {
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/ga-company-accounts?company_id=${this.props.companyId}`,
        HEADERS
      ).then(response => {
        if (this.state.isMounted) {
          if (Array.isArray(response.data) && response.data.length > 0) {
            // check if blogs returned before ga setup
            if (this.state.blogs.length > 0 && this.state.blogs[0].company_id === this.props.companyId) {
              this.setState(
                () => ({ gaSetup: true }),
                () => this.fetchBlogPageViews()
              );
            } else {
              this.setState(() => ({ gaSetup: true }));
            }
          } else {
            this.setState(() => ({ gaSetup: false }));
          }
        }
      }).catch(error => {
        console.error('Error: failed to check google analytics setup');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        if (this.state.isMounted) {
          this.setState(() => ({ gaSetup: false }));
        }
      });
    }
  };

  loadCompanyBlogData = () => {
    if (this.props.brand) {
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      this.setState(() => ({ blogsLoading: true }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/blogs?company_id=${this.props.brand.company_id}&product_brand_id=${this.props.brand.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        if (response.data && Array.isArray(response.data)) {
          const blogs = response.data;
          const cppCounts = {
            companyCount: 0,
            portfolioCount: 0,
            productCount: 0,
          };
          for (const b of blogs) {
            if (Array.isArray(b.brand_types)) {
              if (b.brand_types.includes('company')) {
                cppCounts.companyCount++;
              }
              if (b.brand_types.includes('portfolio')) {
                cppCounts.portfolioCount++;
              }
              if (b.brand_types.includes('product')) {
                cppCounts.productCount++;
              }
            } else {
              b.brand_types = [];
            }
          }

          if (this.state.isMounted) {
            this.setState(
              () => ({
                blogRssSetup: true,
                blogUrls: [],
                blogs,
                cppCounts,
                blogsLoading: false,
              }),
              () => this.handleBlogsReturn()
            );
          }
        } else if (response.data && Array.isArray(response.data.blog_urls)) {
          if (this.state.isMounted) {
            this.setState(() => ({
              blogRssSetup: false,
              blogUrls: response.data.blog_urls,
              blogs: [],
              cppCounts: {},
              blogsLoading: false,
            }));
          }
        }
      }).catch(error => {
        console.error('Error: failed to fetch blogs...');
        if (this.state.isMounted) {
          this.setState(() => ({
              blogRssSetup: false,
              blogUrls: [],
              blogs: [],
              cppCounts: {},
              blogsLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  handleBlogsReturn = () => {
    this.updateBlogResults();
    if (this.state.gaSetup) {
      this.fetchBlogPageViews();
    }
  };

  onFilterDatesChange = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  };

  updateBlogResults = () => {
    let blogResults = [];
    for (const b of this.state.blogs) {
      if (
        Array.isArray(b.brand_types) &&
        (
          (this.props.companyChecked && b.brand_types.includes('company')) ||
          (this.props.productChecked && b.brand_types.includes('product')) ||
          (this.props.portfolioChecked && b.brand_types.includes('portfolio'))
        )
      ) {
        blogResults.push(b);
      }
    }

    if (this.state.isMounted) {
      this.setState(() => ({ blogResults }));
    }
  };

  fetchBlogPageViews = async () => {
    for (const blog of this.state.blogs) {
      const formattedStartDate = moment.utc(blog.published_date).format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = moment.utc().endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const axiosInstance = axios.create({
        baseURL: LISTEN_ENDPOINT
      });
      const blogPageViewsResponse = await axiosInstance.get(
        `/api/ga-url-page-views?company_id=${this.props.companyId}&url=${blog.blog_url.replace('https://', '')}&start-date=${formattedStartDate}&end-date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        return response.data.total_page_views;
      }).catch(error => {
        console.error('Error: failed to fetch blog page views...');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        return undefined;
      });
      if (this.state.isMounted) {
        this.setState((prevState) => {
          let blogPageViewsMap = prevState.blogPageViewsMap;
          blogPageViewsMap[blog.id] = blogPageViewsResponse;
          return ({ blogPageViewsMap });
        });
      }
    }
  };

  toggleBlogUrlsDrawer = () => {
    this.setState((prevState) => ({
      blogUrlsDrawerOpen: !prevState.blogUrlsDrawerOpen
    }));
  };

  render () {
    return (
      <div ref={(ref) => this.blogScroll = ref}>
        <div>
          <BrandChannelSummaryContainer
            channel="Blogs"
            category={this.props.category}
            brand={this.props.brand}
          />
        </div>
        <div>
          <h5 className="my-2 p-2 bg-bops-blue text-light">
            Metric Trends
            <div
              className="d-inline-block ml-4"
              style={{ fontSize: '1rem', fontWeight: 'normal' }}
            >
              <Form.Check
                id="blogAllMonthsCheckbox"
                className="ml-2"
                type="checkbox"
                label="All Months"
                checked={this.props.allMonthsChecked}
                onChange={(event) => this.props.updateAllMonthsChecked(event.currentTarget.checked)}
                inline
              />
            </div>
          </h5>
          <Row>
            <Col xs={12} lg={6}>
              <BlogSummaryGraph
                brand={this.props.brand}
                category={this.props.category}
                allMonthsChecked={this.props.allMonthsChecked}
                updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                allMonthsStartDate={this.props.allMonthsStartDate}
              />
            </Col>
            <Col xs={12} lg={6}>
            </Col>
          </Row>
        </div>
        <div>
          <BrandMetricsByMonthTableContainer
            type="Blogs"
            category={this.props.category}
            brand={this.props.brand}
            audienceProfileId={this.props.category.audience_profile_id}
          />
        </div>
        <div>
          <div ref={(ref) => this.blogDetails = ref}>
            <h5 className="my-2 p-2 bg-bops-blue text-light">
              Details
              <span
                className="ml-4 mr-2"
                style={{ fontSize: '1rem', fontWeight: 'normal' }}
              >Date Range</span>
              <DateRangePicker
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                updateDates={this.onFilterDatesChange}
              />
              <div
                className="d-inline-block ml-4"
                style={{ fontSize: '1rem', fontWeight: 'normal' }}
              >
                <Form.Check
                  className="ml-4"
                  type="checkbox"
                  label={`Corporate ${this.state.cppCounts.companyCount != null ? `(${numberWithCommas(this.state.cppCounts.companyCount)})` : ''}`}
                  checked={this.props.companyChecked}
                  onChange={(event) => this.props.onCppCheckChange('company', event.currentTarget.checked)}
                  inline
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label={`Portfolio ${this.state.cppCounts.portfolioCount != null ? `(${numberWithCommas(this.state.cppCounts.portfolioCount)})` : ''}`}
                  checked={this.props.portfolioChecked}
                  onChange={(event) => this.props.onCppCheckChange('portfolio', event.currentTarget.checked)}
                  inline
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label={`Product Brand ${this.state.cppCounts.productCount != null ? `(${numberWithCommas(this.state.cppCounts.productCount)})` : ''}`}
                  checked={this.props.productChecked}
                  onChange={(event) => this.props.onCppCheckChange('product', event.currentTarget.checked)}
                  inline
                />
              </div>
            </h5>
          </div>
          { this.state.blogsLoading &&
            <div className="m-4 text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !this.state.blogsLoading &&
            <div className="mt-2">
              { this.state.blogRssSetup &&
                <BlogsDisplay
                  brand={this.props.brand}
                  blogs={this.state.blogResults}
                  blogPageViewsMap={this.state.blogPageViewsMap}
                />
              }
              { (!this.state.blogRssSetup && this.state.blogUrls.length > 0) &&
                <ListGroup className="mb-4">
                  { this.state.blogUrls.map((blogUrl, i) => {
                      return (
                        <ListGroup.Item>
                          <div className="d-inline-block">
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faBlog}
                              color="#454d54"
                            />
                          </div>
                          <div className="d-inline-block">
                            <a
                              className="ml-2"
                              href={blogUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {blogUrl}
                            </a>
                          </div>
                          <div className="mt-2">
                            Blog feed is incompatible.
                          </div>
                        </ListGroup.Item>
                      )
                    })
                  }
                </ListGroup>
              }
              { (!this.state.blogRssSetup && this.state.blogUrls.length === 0) &&
                <div className="p-4 bg-white border rounded">
                  Blog RSS is not setup. No blogs are available.
                </div>
              }
            </div>
          }
        </div>
      </div>
    );
  }
};
