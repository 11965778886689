import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../common/CardComponent';
import TwitterEmbeddedLinkPreview from './TwitterEmbeddedLinkPreview';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class TwitterEmbeddedLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      embeddedLinks: [],
      embeddedLinksLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchEmbeddedLinks();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.topicId !== this.props.topicId ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter ||
      prevProps.companyChecked !== this.props.companyChecked ||
      prevProps.portfolioChecked !== this.props.portfolioChecked ||
      prevProps.productChecked !== this.props.productChecked
    ) {
      this.fetchEmbeddedLinks();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  fetchEmbeddedLinks = () => {
    this.setState(() => ({ embeddedLinksLoading: true }));
    const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
    let brandLevelsParam = '';
    if (this.props.companyChecked) {
      brandLevelsParam += '&brand_levels=company';
    }
    if (this.props.portfolioChecked) {
      brandLevelsParam += '&brand_levels=portfolio';
    }
    if (this.props.productChecked) {
      brandLevelsParam += '&brand_levels=product';
    }
    axios.get(
      `${LISTEN_ENDPOINT}/api/topics/${this.props.topicId}/tweet-url-trend?start_date=${formattedStartDate}&end_date=${formattedEndDate}${brandLevelsParam}&limit=9`,
      HEADERS
    ).then(response => {
      const embeddedLinks = response.data;
      if (this.state.isMounted) {
        this.setState(() => ({
          embeddedLinks,
          embeddedLinksLoading: false
        }));
      }
    }).catch(error => {
      if (this.state.isMounted) {
        this.setState(() => ({
          embeddedLinks: [],
          embeddedLinksLoading: false
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  render () {
    return (
      <CardComponent
        title="Social: Embedded Links"
        icon={
          <img
            className="mr-2 align-top"
            src="/images/Social_Embedded_Links_Icon.png"
          />
        }
        description="Social: Embedded Links allows you to view trending embedded links"
        body={
          <div style={{ fontSize: '.825rem' }}>
            { this.state.embeddedLinksLoading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.embeddedLinksLoading &&
              <Row>
                { this.state.embeddedLinks.map((link, i) => {
                    return (
                      <Col
                        key={`tel-${i}`}
                        className="mb-4"
                        xs={12}
                        md={6}
                        lg={4}
                      >
                        <TwitterEmbeddedLinkPreview
                          url={link.url}
                          postCount={link.count}
                        />
                      </Col>
                    )
                  })
                }
              </Row>
            }
          </div>
        }
        contextType="brand"
        contextCategory={this.props.category}
        contextBrand={this.props.brand}
        contextChannel="Social"
        contextProperty="Twitter"
        contextChartName="Embedded Links"
        contextDateRange={`${this.props.startDateFilter.format('MM/DD/YYYY')} - ${this.props.endDateFilter.format('MM/DD/YYYY')}`}
      />
    );
  }
};
