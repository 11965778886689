import React from 'react';

export default class SentimentRange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lowScore: 0,
      highScore: 0
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = prevState;
    if (nextProps.ratingData && nextProps.ratingData.length > 0) {
      let lowScore, highScore;
      for (const i in nextProps.ratingData) {
        if (!lowScore) {
          lowScore = nextProps.ratingData[i][nextProps.ratingKey];
        } else if (nextProps.ratingData[i][nextProps.ratingKey] < lowScore) {
          lowScore = nextProps.ratingData[i][nextProps.ratingKey];
        }
        if (!highScore) {
          highScore = nextProps.ratingData[i][nextProps.ratingKey];
        } else if (nextProps.ratingData[i][nextProps.ratingKey] > highScore) {
          highScore = nextProps.ratingData[i][nextProps.ratingKey];
        }
      }
      newState.lowScore = lowScore;
      newState.highScore = highScore;
    } else {
      newState.lowScore = 0;
      newState.highScore = 0;
    }
    return newState;
  }

  render () {
    return (
      <div>
        <div>Sentiment Range</div>
        <div>
          <b>{this.state.lowScore.toFixed(1)}</b>
          {' to '}
          <b>{this.state.highScore.toFixed(1)}</b>
        </div>
      </div>
    );
  }
};
