import axios from 'axios';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { reportError } from '../api/errors';

export const fetchCompany = (id) => {
  return (dispatch) => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/companies/${id}`,
      HEADERS
    ).then(response => {
      const company = response.data;
      dispatch(addCompany(company));
    }).catch(error => {
      dispatch(addCompany({}));
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatch(reportError(error.response));
      }
    });
  };
};

export const addCompany = (company) => ({
  type: 'ADD_COMPANY',
  company
});
