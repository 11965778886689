import React from 'react';
import CardComponent from '../../common/CardComponent';
import LCXCustomGraph from '../../graphs/LCXCustomGraph';

export default class SocialSentiment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  render () {
    return (
      <CardComponent
        title="Social Sentiment"
        icon={
          <img
            className="mr-2 align-top"
            src="/images/Brand_Experience_Icon.png"
          />
        }
        description="Social Sentiment gives you a market style overview of the brand"
        body={
          <LCXCustomGraph
            customerId={this.props.customerId}
            topicId={this.props.topicId}
            brandId={this.props.brandId}
            startDateFilter={this.props.startDateFilter}
            endDateFilter={this.props.endDateFilter}
          />
        }
        contextType="brand"
        contextCategory={this.props.category}
        contextBrand={this.props.brand}
        contextChannel="Social"
        contextChartName="Social Sentiment"
      />
    );
  }
};
