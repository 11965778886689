import React from 'react';
import axios from 'axios';
import ShareOfChart from '../graphs/ShareOfChart';
import { round } from '../../utils/numbers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryNewsShareOfGraphs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pressReleaseShareOfGraphData: {},
      categoryPressReleaseShareOfData: [],
      categoryPressReleaseShareOfDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getCategoryPressReleaseShareOfData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.getCategoryPressReleaseShareOfData();
    }
  }

  getCategoryPressReleaseShareOfData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryPressReleaseShareOfDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-share-press-release-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const categoryNewsData = response.data;
        const pressReleaseShareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const categoryPressReleaseShareOfData = [];
        for (const monthStats of categoryNewsData) {
          const prMonthData = { month: monthStats.month };
          let monthTotalPressReleases = 0;

          // get totals for percent
          for (const pb of monthStats.product_brands) {
            monthTotalPressReleases += pb.press_release_count;
            // insert graph data
            pressReleaseShareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.press_release_count || 0,
            ]);
          }

          // loop again to determine %
          for (const pb of monthStats.product_brands) {
            let prPercent = 0;
            if (monthTotalPressReleases > 0) {
              prPercent = round((pb.press_release_count/monthTotalPressReleases)*100, 1);
            }
            prMonthData[pb.product_brand_name] = {
              count: pb.press_release_count,
              percent: prPercent
            }
          }
          categoryPressReleaseShareOfData.push(prMonthData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            pressReleaseShareOfGraphData,
            categoryPressReleaseShareOfData,
            categoryPressReleaseShareOfDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch category share of press release data...');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            pressReleaseShareOfGraphData: {},
            categoryPressReleaseShareOfData: [],
            categoryPressReleaseShareOfDataLoading: false,
          }));
        }
      });
    }
  }

  render () {
    return (
      <div>
        <ShareOfChart
          title="Share of News Mentions"
          icon={
            <FontAwesomeIcon
              className="mr-2"
              icon={faNewspaper}
              color="#4fa2f3"
            />
          }
          data={this.props.mentionsShareOfGraphData}
          legendData={this.props.categoryNewsShareOfData}
          loading={this.state.categoryNewsDataLoading}
          shareClassName="category-news-mentions-img"
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="News"
          contextChartName="Share of News Mentions"
        />
        <ShareOfChart
          title="Share of News Mentions (by Reach)"
          icon={
            <FontAwesomeIcon
              className="mr-2"
              icon={faNewspaper}
              color="#4fa2f3"
            />
          }
          data={this.props.reachShareOfGraphData}
          legendData={this.props.categoryReachShareOfData}
          loading={this.props.categoryNewsDataLoading}
          shareClassName="category-news-mentions-reach-img"
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="News"
          contextChartName="Share of News Mentions (by Reach)"
        />
        <ShareOfChart
          title="Share of Press Releases"
          icon={
            <FontAwesomeIcon
              className="mr-2"
              icon={faNewspaper}
              color="#4fa2f3"
            />
          }
          data={this.state.pressReleaseShareOfGraphData}
          legendData={this.state.categoryPressReleaseShareOfData}
          loading={this.state.categoryPressReleaseShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="News"
          contextChartName="Share of Press Releases"
        />
      </div>
    );
  }
};
