import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactTable from 'react-table';
import { numberWithCommas } from '../../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class GoogleAnalyticsTopPagesVisited extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      webAnalyticsTopPagesVisited: [],
      webAnalyticsTopPagesVisitedLoading: true,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchGoogleAnalyticsTopPagesVisited();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.companyId !== this.props.companyId) {
      this.fetchGoogleAnalyticsTopPagesVisited();
    }
  };

  fetchGoogleAnalyticsTopPagesVisited = () => {
    if (this.props.companyId) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/ga-top-url-page-view-trend?company_id=${this.props.companyId}`,
        HEADERS
      ).then(response => {
        const webAnalyticsTopPagesVisited = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            webAnalyticsTopPagesVisited,
            webAnalyticsTopPagesVisitedLoading: false,
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch web analytics top pages visited data...`);
        if (this.state.isMounted) {
          this.setState(() => ({
            webAnalyticsTopPagesVisited: [],
            webAnalyticsTopPagesVisitedLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  render () {
    return (
      <div>
        { this.state.webAnalyticsTopPagesVisitedLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.webAnalyticsTopPagesVisitedLoading &&
          <div>
            { this.state.webAnalyticsTopPagesVisited.length === 0 &&
              <Alert variant="warning">
                For insights on the top web pages visited, please connect BrandOps to Google Analytics. <Link to="/content-collectors/web-analytics">Learn More</Link>.
              </Alert>
            }
            { this.state.webAnalyticsTopPagesVisited.length > 0 &&
              <ReactTable
                className="-striped -highlight scrollable"
                data={this.state.webAnalyticsTopPagesVisited}
                columns={[
                  {
                    Header: 'Page Views',
                    headerClassName: 'text-center wordwrap bg-dark text-light font-weight-bold border-light',
                    accessor: 'total_page_views',
                    sortable: true,
                    width: 120,
                    Cell: row => (
                      <div className="text-center">
                        {numberWithCommas(row.original.total_page_views)}
                      </div>
                    ),
                  },
                  {
                    Header: 'URL',
                    headerClassName: 'text-center wordwrap bg-dark text-light font-weight-bold border-light',
                    accessor: 'page_path',
                    sortable: true,
                    Cell: row => (
                      <div>
                        <a
                          href={row.original.expanded_url}
                          target="_blank"
                        >
                        {row.original.page_path}
                        </a>
                      </div>
                    ),
                  }
                ]}
                minRows={1}
                showPagination={false}
                defaultSorted={[{ id: 'total_page_views', desc: true }]}
                style={{ fontSize: '.875rem' }}
              />
            }
          </div>
        }
      </div>
    );
  }
};
