import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import ClipLoader from 'react-spinners/ClipLoader';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class WebAnalyticsPollingModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pollTries: 0,
      dataFound: false,
      errorMessage: '',

    };
  };

  onOpen = () => {
    this.setState(() => ({
      pollTries: 0,
      maxPollTries: 10,
      polling: true,
      dataFound: false,
      errorMessage: '',
    }), () => this.webAnalyticsPoll());
  };

  handleClose = () => {
    if (this.currentTimeoutId) {
      clearTimeout(this.currentTimeoutId);
    }
    this.props.handleClose();
  };

  webAnalyticsPoll = () => {
    if (this.props.companyId) {
      this.setState((prevState) => ({ pollTries: prevState.pollTries + 1 }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/ga-data-available?company_id=${this.props.companyId}`,
        HEADERS
      ).then(response => {
        if (this.props.isOpen) {
          if (response.data.has_data) {
            this.setState(() => ({
              dataFound: true,
              polling: false,
            }));
          } else {
            if (this.state.pollTries < this.state.maxPollTries) {
              this.currentTimeoutId = setTimeout(() => this.webAnalyticsPoll(), '3000');
            } else {
              this.setState(() => ({
                polling: false,
                errorMessage: 'We are unable gather Google Analytics data. Please revise your GA4 setup and permissions. If the setup is believed to be correct, please contact your BrandOps account executive.'
              }));
            }
          }
        }
      }).catch(error => {
        console.error('Error: failed to poll web analytics for data...');
        this.currentTimeoutId = setTimeout(() => this.webAnalyticsPoll(), '3000');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <Modal
        size="md"
        show={this.props.isOpen}
        onHide={this.handleClose}
        onEntering={this.onOpen}
      >
        <Modal.Header
          className="bg-bops-blue text-light"
          closeButton
        >
          <Modal.Title>
            Web Analytics Setup
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { (this.state.polling && !this.state.errorMessage) &&
            <p>
              We are gathering web analytics data for you...
            </p>
          }
          { this.state.polling &&
            <div className="m-4 text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { (!this.state.polling && this.state.dataFound) &&
            <div>
              { this.props.myCategoryBrand &&
                <p>
                  We are successfully gathering web analytics data! You may begin reviewing <Link to={`/category/${encodeURIComponent(this.props.myCategoryBrand.category.name)}/brand/${encodeURIComponent(this.props.myCategoryBrand.brand.name)}#web-webAnalytics`}>here</Link>.
                </p>
              }
              { !this.props.myCategoryBrand &&
                <p>
                  We are successfully gathering web analytics data!
                </p>
              }
            </div>
          }
          <Alert show={Boolean(this.state.errorMessage)} variant="danger">
            {this.state.errorMessage}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};
