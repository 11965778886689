import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import history from '../../routers/history';
import { dispatchReportError } from '../../actions/api/errors';

export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      errors: {},
      errorMessage: '',
      resetRequestComplete: false
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user && nextProps.user.email) {
      history.push('/');
    }
    return prevState;
  }

  onEmailChange = (event) => {
    const email = event.currentTarget.value;
    this.setState(() => ({ email }));
  };

  isValidForm = () => {
    const errors = {};
    let errorMessage = '';
    if (!this.state.email) {
      errors.email = true;
      errorMessage = 'Must provide email for password reset';
    }

    this.setState(() => ({
      errors,
      errorMessage
    }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  sendPasswordResetRequest = () => {
    if (this.isValidForm()) {
      const requestBody = {
        email: this.state.email,
        origin: window.location.origin
      };

      axios.post(
        `${LISTEN_ENDPOINT}/api/customer-users/password-reset-request`,
        requestBody,
        HEADERS,
      ).then(response => {
        if (this.state.isMounted) {
          this.setState(() => ({ resetRequestComplete: true }));
        }
      }).catch(error => {
        console.error('Error: failed to send password reset email...');
        if (this.state.isMounted) {
          this.setState(() => ({ resetRequestComplete: true }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render() {
    return (
      <div className="m-4">
        { !this.state.resetRequestComplete &&
          <Row>
            <Col>
              <Card className="mx-auto w-50">
                <Card.Header>
                  Password Reset
                </Card.Header>
                <Card.Body>
                  <Form>
                    <p>
                      Please enter your email and we'll send you a link to reset your password.
                    </p>
                    <Form.Group>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        onChange={this.onEmailChange}
                        onKeyUp={(e) => {
                          if (e.keyCode === 13) {
                            this.sendPasswordResetRequest();
                          }
                        }}
                        isInvalid={this.state.errors.email}
                      />
                    </Form.Group>
                  </Form>
                  <Alert show={Boolean(this.state.errorMessage)} variant="danger">
                    {this.state.errorMessage}
                  </Alert>
                  <Button variant="primary" onClick={this.sendPasswordResetRequest}>
                    Submit
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        }
        { this.state.resetRequestComplete &&
          <Alert variant="success">
            We sent you an email to reset your password.
          </Alert>
        }
      </div>
    );
  }
};
