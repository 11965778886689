import { connect } from 'react-redux';
import QuickSearch from '../../components/quickSearch/QuickSearch';

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(QuickSearch);
