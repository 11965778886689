import React from 'react';
import axios from 'axios';
import CardComponent from '../common/CardComponent';
import CategoryTwitterPromotersGraph from '../graphs/CategoryTwitterPromotersGraph';
import CategoryTwitterMentionsGraph from '../graphs/CategoryTwitterMentionsGraph';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryTwitterGraphs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryTwitterData: [],
      categoryTwitterDataLoading: false,
      categoryKingName: '',
      ownersBrandName: '',
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getCategoryTwitterData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.categoryId !== this.props.categoryId ||
      prevProps.user.customerId !== this.props.user.customerId) {
      this.getCategoryTwitterData();
    }
  };

  getCategoryTwitterData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryTwitterDataLoading: true }));
      const categoryTwitterRequests = [];
      // get category average Twitter data
      categoryTwitterRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-twitter-share-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=category-average`,
          HEADERS
        ).then(response => {
          const categoryAvgTwitterData = response.data;
          return { categoryAvgTwitterData };
        }).catch(error => {
          console.error('Error: unable to fetch category Twitter data');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryAvgTwitterData: [] };
        })
      );
      // get category average Twitter mentions data
      categoryTwitterRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-twitter-avg-mention-trend?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=category-average`,
          HEADERS
        ).then(response => {
          const categoryAvgTwitterMentionsData = response.data;
          return { categoryAvgTwitterMentionsData };
        }).catch(error => {
          console.error('Error: unable to fetch category Twitter mentions data');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryAvgTwitterMentionsData: [] };
        })
      );
      // get category king Twitter data
      categoryTwitterRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-twitter-share-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=category-king`,
          HEADERS
        ).then(response => {
          const results = response.data;
          const categoryKingTwitterData = [];
          if (results.stats) {
            for (const twitterData of results.stats) {
              categoryKingTwitterData.push({
                ...twitterData,
                product_brand_name: results.product_brand_name
              });
            }
          }
          return { categoryKingTwitterData };
        }).catch(error => {
          console.error('Error: unable to fetch category king Twitter data');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryKingTwitterData: [] };
        })
      );
      // get customer Twitter data
      let ownersBrand;
      for (const brand of this.props.category.product_brands) {
        if (!ownersBrand && brand.company_id === this.props.user.customerId) {
          ownersBrand = brand;
        }
      }
      if (ownersBrand) {
        categoryTwitterRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/category-avg-twitter-share-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=product-brand&product_brand_id=${ownersBrand.id}`,
            HEADERS
          ).then(response => {
            const results = response.data;
            const ownedBrandTwitterData = [];
            if (results.stats) {
              for (const twitterData of results.stats) {
                ownedBrandTwitterData.push(twitterData);
              }
            }
            return {
              ownedBrandTwitterData,
              ownersBrand
            };
          }).catch(error => {
            console.error('Error: unable to fetch owned brand Twitter data');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return { ownedBrandTwitterData: [] };
          })
        );
      }

      Promise.all(categoryTwitterRequests).then(responses => {
        let categoryAvgTwitterData = [];
        let categoryAvgTwitterMentionsData = [];
        let categoryKingTwitterData = [];
        let ownedBrandTwitterData = [];
        let highestMentionsCount = 0;
        let highestMentionsKey = 'category_king_mentions_count';
        let highestPromoterCount = 0;
        let highestPromoterKey = 'category_king_promoters_count';
        let categoryKingName = '';
        let ownersBrandName = '';
        for (let response of responses) {
          if (response.categoryAvgTwitterData) {
            categoryAvgTwitterData = response.categoryAvgTwitterData;
          } else if (response.categoryAvgTwitterMentionsData) {
            categoryAvgTwitterMentionsData = response.categoryAvgTwitterMentionsData;
          } else if (response.categoryKingTwitterData) {
            categoryKingTwitterData = response.categoryKingTwitterData;
            if (categoryKingTwitterData.length > 0) {
              categoryKingName = categoryKingTwitterData[0].product_brand_name;
            }
          } else if (response.ownedBrandTwitterData) {
            ownedBrandTwitterData = response.ownedBrandTwitterData;
            ownersBrandName = response.ownersBrand.name;
          }
        }
        // merge the 3 data arrays
        // may be able to optimize this, but its a small data set
        const categoryTwitterData = [];
        for (let cd of categoryAvgTwitterData) {
          if (cd.avg_promoters > highestPromoterCount) {
            highestPromoterCount = cd.avg_promoters;
            highestPromoterKey = 'category_avg_promoters_count';
          }
          categoryTwitterData.push({
            month: cd.month,
            category_avg_promoters_count: cd.avg_promoters
          });
        }
        for (let cd of categoryAvgTwitterMentionsData) {
          for (let scoreData of categoryTwitterData) {
            if (cd.avg_mentions > highestMentionsCount) {
              highestMentionsCount = cd.avg_mentions;
              highestMentionsKey = 'category_avg_mentions_count';
            }
            if (cd.month === scoreData.month) {
              scoreData.category_avg_mentions_count = cd.avg_mentions;
            }
          }
        }
        for (let ckd of categoryKingTwitterData) {
          for (let scoreData of categoryTwitterData) {
            if (ckd.mentions > highestMentionsCount) {
              highestMentionsCount = ckd.mentions;
              highestMentionsKey = 'category_king_mentions_count';
            }
            if (ckd.promoters > highestPromoterCount) {
              highestPromoterCount = ckd.promoters;
              highestPromoterKey = 'category_king_promoters_count';
            }
            if (ckd.month === scoreData.month) {
              scoreData.category_king_mentions_count = ckd.mentions;
              scoreData.category_king_promoters_count = ckd.promoters;
            }
          }
        }
        for (let obd of ownedBrandTwitterData) {
          for (let scoreData of categoryTwitterData) {
            if (obd.mentions > highestMentionsCount) {
              highestMentionsCount = obd.mentions;
              highestMentionsKey = 'owned_brand_mentions_count';
            }
            if (obd.promoters > highestPromoterCount) {
              highestPromoterCount = obd.promoters;
              highestPromoterKey = 'owned_brand_promoters_count';
            }
            if (obd.month === scoreData.month) {
              scoreData.owned_brand_mentions_count = obd.mentions;
              scoreData.owned_brand_promoters_count = obd.promoters;
            }
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryTwitterData,
            categoryTwitterDataLoading: false,
            highestMentionsKey,
            highestPromoterKey,
            categoryKingName,
            ownersBrandName
          }));
        }
      });
    }
  }

  render () {
    return (
      <div>
        <CardComponent
          title="Mentions"
          body={
            <CategoryTwitterMentionsGraph
              categoryTwitterDataLoading={this.state.categoryTwitterDataLoading}
              categoryTwitterData={this.state.categoryTwitterData}
              highestDataKey={this.state.highestMentionsKey}
              categoryKingName={this.state.categoryKingName}
              ownersBrandName={this.state.ownersBrandName}
            />
          }
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="Twitter"
          contextChartName="Mentions"
        />
        <CardComponent
          title="Promoter Sentiment"
          body={
            <CategoryTwitterPromotersGraph
              categoryTwitterDataLoading={this.state.categoryTwitterDataLoading}
              categoryTwitterData={this.state.categoryTwitterData}
              highestDataKey={this.state.highestPromoterKey}
              categoryKingName={this.state.categoryKingName}
              ownersBrandName={this.state.ownersBrandName}
            />
          }
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="Twitter"
          contextChartName="Promoter Sentiment"
        />
      </div>
    );
  }
};
