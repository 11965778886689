import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Badge from 'react-bootstrap/Badge';
import ClipLoader from 'react-spinners/ClipLoader';
import SharePopUpContainer from '../../../containers/share/SharePopUpContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCog } from '@fortawesome/free-solid-svg-icons';
import SearchEngineStatsSummary from './SearchEngineStatsSummary';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import history from '../../../routers/history';
import { dispatchReportError } from '../../../actions/api/errors';

export default class SearchEngineReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchEngineReportData: {},
      searchEngineReportDataLoading: false
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchSearchEngineReportData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      this.fetchSearchEngineReportData();
    }
  };

  fetchSearchEngineReportData = () => {
    if (this.props.category) {
      this.setState(() => ({ searchEngineReportDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/seo-sem-report?category_id=${this.props.category.id}&category_type=${this.props.category.category_type}`,
        HEADERS
      ).then(response => {
        const searchEngineReportData = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            searchEngineReportData,
            searchEngineReportDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch search engine report data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            searchEngineReportData: {},
            searchEngineReportDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        { this.state.searchEngineReportDataLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.searchEngineReportDataLoading &&
          <div>
            { (
               this.state.searchEngineReportData &&
               this.state.searchEngineReportData.private &&
               this.state.searchEngineReportData.private.queries
             ) &&
             <div className="mb-4">
               <div id="searchEngineReport">
                 <h3>Keywords</h3>
                 <div className="mb-2">
                   <span className="mr-2">
                     Search Terms Used
                     <FontAwesomeIcon
                       className="ml-2"
                       icon={faArrowRight}
                     />
                   </span>
                   { this.state.searchEngineReportData.private.queries.map((query, i) => {
                       return (
                         <Badge
                           key={`pub-q-${i}`}
                           className="mr-1 mb-1 p-2"
                           variant="warning"
                           style={{ fontSize: '1rem' }}
                         >
                           {query}
                         </Badge>
                       )
                     })
                   }
                 </div>
                 <SearchEngineStatsSummary
                   user={this.props.user}
                   sixMonths={this.props.sixMonths}
                   selectedMonth={this.props.selectedMonth}
                   updateSelectedMonth={this.props.updateSelectedMonth}
                   type="private"
                   searchEngineReportData={this.state.searchEngineReportData}
                   category={this.props.category}
                 />
               </div>
               <SharePopUpContainer
                 shareElementId="searchEngineReport"
                 elementLabel="search-engine-report-img"
                 position="bottom"
                 contextType="category"
                 contextCategory={this.props.category}
                 contextBrand={null}
                 contextChannel="Search"
                 contextChartName="Summary"
                 contextDateRange={`${this.props.selectedMonth.label} ${this.props.selectedMonth.date.format('YYYY')}`}
               />
               <div style={{ height: '100px' }}/>
            </div>
            }
          </div>
        }
      </div>
    );
  }
};
