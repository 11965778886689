import React from 'react';
import axios from 'axios';
import AWS from 'aws-sdk';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import LoginContainer from '../../containers/common/LoginContainer';
import AdminAuthenticationMessage from '../common/AdminAuthenticationMessage';
import { isAuthenticatedAdmin } from '../../utils/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faUsers, faCheck } from '@fortawesome/free-solid-svg-icons';
import {
  LISTEN_ENDPOINT,
  HEADERS,
  BUCKET_KEY,
  BUCKET_SKEY
} from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CompanyInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bucketName: 'listen-company-logos',
      acceptedLogoFileTypes: [
        'jpeg',
        'jpg',
        'png',
        'gif'
      ],
      id: undefined,
      name: '',
      domain: '',
      faviconUrl: '',
      companyLogo: '',
      showToast: false,
      toastMessageJSX: undefined
    };
  }

  componentDidMount() {
    if (this.props.company.id) {
      this.setState(() => ({
        id: this.props.company.id,
        name: this.props.company.name,
        domain: this.props.company.domain || '',
        faviconUrl: this.props.company.favicon_url || '',
        companyLogo: this.props.company.company_logo || '',
        isMounted: true
      }));
    } else {
      this.setState(() => ({ isMounted: true }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.company.id !== this.props.company.id) {
      this.setState(() => ({
        id: this.props.company.id,
        name: this.props.company.name,
        domain: this.props.company.domain || '',
        faviconUrl: this.props.company.favicon_url || '',
        companyLogo: this.props.company.company_logo || '',
        isMounted: true
      }));
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  onDomainChange = (event) => {
    const domain = event.currentTarget.value;
    this.setState(() => ({ domain }));
  };

  onFaviconUrlChange = (event) => {
    const faviconUrl = event.currentTarget.value;
    this.setState(() => ({ faviconUrl }));
  };

  updateCompanyInformation = () => {
    const domainAnchor = document.createElement('a');
    if (this.state.domain.includes('http')) {
      domainAnchor.href = this.state.domain;
    } else {
      domainAnchor.href = `https://${this.state.domain}`;
    }
    const domain = domainAnchor.hostname.replace('www.', '');
    const companyInformationBody = { domain };

    if (this.state.faviconUrl) {
      companyInformationBody.favicon_url = this.state.faviconUrl;
    }

    axios.put(
      `${LISTEN_ENDPOINT}/api/companies/${this.state.id}`,
      companyInformationBody,
      HEADERS
    ).then(response => {
      if (this.state.isMounted) {
        this.setState(() => ({
          domain: response.data.domain,
          faviconUrl: response.data.favicon_url,
          showToast: true,
          toastMessageJSX: (
            <div className="mr-auto">
              <FontAwesomeIcon
                className="text-success mr-2"
                icon={faCheck}
              />
              Save successful
            </div>
          )
        }));
      }
    }).catch(error => {
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  updateCustomerDomain = () => {
    const domainAnchor = document.createElement('a');
    if (this.state.domain.includes('http')) {
      domainAnchor.href = this.state.domain;
    } else {
      domainAnchor.href = `https://${this.state.domain}`;
    }
    const domain = domainAnchor.hostname.replace('www.', '');
    const customerUpdateBody = { domain };

    axios.put(
      `${LISTEN_ENDPOINT}/api/companies/${this.state.id}`,
      customerUpdateBody,
      HEADERS
    ).then(response => {
      if (this.state.isMounted) {
        this.setState(() => ({
          domain: response.data.domain,
          faviconUrl: response.data.favicon_url || null
        }));
      }
    }).catch(error => {
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  }

  onLogoFileUpload = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      this.uploadLogoFile(file);
    }
  };

  uploadLogoFile = (file) => {
    const fileName = `${this.state.name} Logo ${file.name}`;
    const fileExt = file.name.split('.').pop();
    if (this.state.acceptedLogoFileTypes.includes(fileExt.toLowerCase())) {
      const reader = new FileReader();
      reader.onabort = () => {
        console.log('Upload failed and abort');
      }
      reader.onerror = () => {
        console.log('Upload failed with reader');
      }
      reader.onload = (e) => {
        const fileContent = e.target.result.split(',')[1];
        // upload to aws bucket
        const s3 = new AWS.S3({
          accessKeyId: BUCKET_KEY,
          secretAccessKey: BUCKET_SKEY
        });
        const s3Params = {
          Bucket: this.state.bucketName,
          Key: fileName,
          Body: new Buffer(fileContent, 'base64'),
          ACL: 'public-read'
        }
        s3.upload(s3Params, (s3Error, fileContent) => {
          if (s3Error) {
            console.log('Upload failure');
          } else {
            const objectUrl = `https://${this.state.bucketName}.s3.amazonaws.com/${encodeURIComponent(fileName)}`;
            axios.put(
              `${LISTEN_ENDPOINT}/api/companies/${this.state.id}`,
              { company_logo: objectUrl },
              HEADERS
            ).then(response => {
              if (this.state.isMounted) {
                this.setState(() => ({ companyLogo: response.data.company_logo }));
              }
            }).catch(error => {
              if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
                dispatchReportError(error.response);
              }
            });
          }
        });
      }
      reader.readAsDataURL(file);
    }
  }

  render () {
    return (
      <div className="p-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        <AdminAuthenticationMessage user={this.props.user}/>
        { isAuthenticatedAdmin(this.props.user) &&
          <div>
            { this.state.id &&
              <div>
                <div>
                  <b>Company Logo</b>
                  { (this.state.companyLogo) &&
                    <div className="m-2">
                      <img
                        src={this.state.companyLogo}
                        alt=""
                        crossOrigin="anonymous"
                        style={{ height: '84px' }}
                      />
                    </div>
                  }
                  { (!this.state.companyLogo) &&
                    <div className="m-2">
                      <FontAwesomeIcon icon={faUsers} size="6x" color="#d9d9d9"/>
                    </div>
                  }
                  <span className="btn btn-light btn-file">
                    Upload
                    <FontAwesomeIcon className="ml-1" icon={faUpload} size="lg"/>
                    <input
                      type="file"
                      multiple={false}
                      accept={`.${this.state.acceptedLogoFileTypes.join(',.')}`}
                      onChange={this.onLogoFileUpload}
                    />
                  </span>
                </div>
                <hr />
                <div className="mt-2">
                  <div
                    className="d-inline-block mr-2 text-right"
                    style={{ width: '120px' }}
                  >
                    Domain
                  </div>
                  <div className="d-inline-block">
                    <Form.Control
                      type="text"
                      value={this.state.domain}
                      onChange={this.onDomainChange}
                      style={{ width: '400px' }}
                    />
                  </div>
                  <div className="d-inline-block">
                    <Button
                      className="ml-2"
                      variant="info"
                      onClick={this.updateCustomerDomain}
                    >
                      Search for Favicon
                    </Button>
                  </div>
                </div>
                <div className="mt-2">
                  <div
                    className="d-inline-block mr-2 text-right"
                    style={{ width: '120px' }}
                  >
                    Favicon URL
                    { this.state.faviconUrl &&
                      <img
                        className="ml-2 mt-1 align-top"
                        src={this.state.faviconUrl}
                        alt=""
                        crossOrigin="anonymous"
                        style={{ width: '16px', height: '16px' }}
                      />
                    }
                  </div>
                  <div className="d-inline-block">
                    <Form.Control
                      type="text"
                      value={this.state.faviconUrl}
                      onChange={this.onFaviconUrlChange}
                      style={{ width: '400px' }}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <div
                    className="d-inline-block mr-2 text-right"
                    style={{ width: '120px' }}
                  >
                  </div>
                  <div className="d-inline-block">
                    <Button
                      variant="primary"
                      onClick={this.updateCompanyInformation}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            }

            <Toast
              onClose={() => this.setState(() => ({ showToast: false }))}
              show={this.state.showToast}
              delay={2000}
              autohide
              style={{
                position: 'absolute',
                top: 100,
                right: 10
              }}
            >
              <Toast.Header>
                {this.state.toastMessageJSX}
              </Toast.Header>
            </Toast>
          </div>
        }
      </div>
    );
  }
};
