import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import {
  LCX_CORE_EXPRESS_ENDPOINT,
  LCX_DISPLAY_EXPRESS_ENDPOINT,
  HEADERS
} from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class AddEditCommentReviewScoreModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTouchpoint: 'Company',
      selectedAspect: 'General',
      selectedScore: undefined
    };
  }

  onOpen = () => {
    let selectedTouchpoint;
    if (this.props.editCommentReviewScore) {
      selectedTouchpoint = this.props.editCommentReviewScore.touchpoint;
      this.setState(() => ({
        selectedTouchpoint,
        selectedAspect: this.props.editCommentReviewScore.aspect,
        selectedScore: String(this.props.editCommentReviewScore.score)
      }));

    } else {
      selectedTouchpoint = 'Company';
      this.setState(() => ({
        selectedTouchpoint,
        selectedAspect: 'General',
        selectedScore: undefined
      }));
    }
  }

  onScoreSelect = (event) => {
    const selectedScore = event.currentTarget.dataset.score;
    this.setState(
      () => ({ selectedScore }),
      () => this.addEditCommentReviewScore()
    );
  };

  addEditCommentReviewScore = () => {
    if (this.state.selectedScore === '0') {
      if (this.props.editCommentReviewScore) {
        // Delete comment review score
        axios.delete(
          `${LCX_DISPLAY_EXPRESS_ENDPOINT}/api/comment-review-scores/${this.props.editCommentReviewScore.id}`,
          HEADERS
        ).then(response => {
          this.props.refreshCustomerComments();
          this.props.handleClose();
        }).catch(error => {
          console.error('Error: failed to delete comment review score...');
          this.props.handleClose();
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      } else {
        // Nothing to do but close
        this.props.handleClose();
      }
    } else {
      if (this.props.editCommentReviewScore) {
        // Edit
        if (this.props.editCommentReviewScore.scoring_source === 'moake') {
          // Create new Comment Review Score and update existing as overwritten
          axios.post(
            `${LCX_DISPLAY_EXPRESS_ENDPOINT}/api/comment-review-scores`,
            {
              comment_id: this.props.commentId,
              touchpoint: this.state.selectedTouchpoint,
              aspect: this.state.selectedAspect,
              score: parseInt(this.state.selectedScore),
              human_reviewed: true,
              scoring_source: 'manual'
            },
            HEADERS
          ).then(response => {
            const commentReviewScore = response.data;
            axios.put(
              `${LCX_DISPLAY_EXPRESS_ENDPOINT}/api/comment-review-scores/${this.props.editCommentReviewScore.id}`,
              { overwrite_crs_id: commentReviewScore.id },
              HEADERS
            ).then(response => {
              const updatedCommentReviewScore = response.data;
              this.props.refreshCustomerComments();
              this.props.handleClose();
            }).catch(error => {
              console.error('Error: failed to update comment review score');
              if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
                dispatchReportError(error.response);
              }
            });
          });
        } else {
          axios.put(
            `${LCX_DISPLAY_EXPRESS_ENDPOINT}/api/comment-review-scores/${this.props.editCommentReviewScore.id}`,
            {
              touchpoint: this.state.selectedTouchpoint,
              aspect: this.state.selectedAspect,
              score: parseInt(this.state.selectedScore),
              human_reviewed: true,
              scoring_source: 'manual'
            },
            HEADERS
          ).then(response => {
            const updatedCommentReviewScore = response.data;
            this.props.refreshCustomerComments();
            this.props.handleClose();
          }).catch(error => {
            console.error('Error: failed to update comment review score');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
          });
        }
      } else {
        // Create
        axios.post(
          `${LCX_DISPLAY_EXPRESS_ENDPOINT}/api/comment-review-scores`,
          {
            comment_id: this.props.commentId,
            touchpoint: this.state.selectedTouchpoint,
            aspect: this.state.selectedAspect,
            score: parseInt(this.state.selectedScore),
            human_reviewed: true,
            scoring_source: 'manual'
          },
          HEADERS
        ).then(response => {
          const commentReviewScore = response.data;
          this.props.refreshCustomerComments();
          this.props.handleClose();
        }).catch(error => {
          console.error('Error: failed to create comment review score');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  render () {
    return (
      <Modal
        size="lg"
        show={this.props.isOpen}
        onHide={this.props.handleClose}
        onEntering={this.onOpen}
      >
        <Modal.Header
          className="bg-bops-blue text-light"
          closeButton
        >
          <Modal.Title>
            { this.props.editCommentReviewScore ? 'Edit':'Add'}
            {' Sentiment'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div className="mt-2 text-center">
                <b>Manual Sentiment</b>
              </div>
              <Row>
                <Col>
                  <div className="mt-2 text-right">
                    Negative
                  </div>
                </Col>
                <Col>
                  <ButtonGroup className="mx-auto rounded border" size="lg">
                    <Button
                      variant={`${this.state.selectedScore === '-3' ? 'primary': 'light'}`}
                      data-score="-3"
                      onClick={this.onScoreSelect}
                    >-3</Button>
                    <Button
                      variant={`${this.state.selectedScore === '-2' ? 'primary': 'light'}`}
                      data-score="-2"
                      onClick={this.onScoreSelect}
                    >-2</Button>
                    <Button
                      variant={`${this.state.selectedScore === '-1' ? 'primary': 'light'}`}
                      data-score="-1"
                      onClick={this.onScoreSelect}
                    >-1</Button>
                    <Button
                      variant={`${this.state.selectedScore === '0' ? 'primary': 'light'}`}
                      data-score="0"
                      onClick={this.onScoreSelect}
                    >0</Button>
                    <Button
                      variant={`${this.state.selectedScore === '1' ? 'primary': 'light'}`}
                      data-score="1"
                      onClick={this.onScoreSelect}
                    >+1</Button>
                    <Button
                      variant={`${this.state.selectedScore === '2' ? 'primary': 'light'}`}
                      data-score="2"
                      onClick={this.onScoreSelect}
                    >+2</Button>
                    <Button
                      variant={`${this.state.selectedScore === '3' ? 'primary': 'light'}`}
                      data-score="3"
                      onClick={this.onScoreSelect}
                    >+3</Button>
                  </ButtonGroup>
                </Col>
                <Col>
                  <div className="mt-2">
                    Positive
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};
