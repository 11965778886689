import React from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import LicenseUsage from '../common/LicenseUsage';
import CompanySearchComboBox from '../common/CompanySearchComboBox';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class AddBrandModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'subscribe',
      allBrands: [],
      brands: [],
      brandsSearchTerm: '',
      companySearchTerm: '',
      selectedBrandId: undefined,
      selectedBrand: undefined,
      selectedBrandPositionInStageId: 2,
      newBrandName: '',
      newBrandProductUrl: '',
      newBrandCompanyName: '',
      newBrandCompanyUrl: '',
      newBrandCompanyDetailedDescription: null,
      newBrandCompanyWikipediaUrl: null,
      newBrandCompanyBusinessCategory: null,
      newBrandCompanyImageSourceUrl: null,
      newBrandCompanyImageContentUrl: null,
      newBrandDescription: '',
      newBrandPositionInStageId: 2,
      newBrandErrors: {},
      newBrandErrorMessage: '',
      createDisabled: false,
      showSummaryScreen: false,
      summaryContents: [],
    };
  }

  onOpen = () => {
    this.setState(() => ({
      selectedTab: 'subscribe',
      allBrands: [],
      brands: [],
      brandsSearchTerm: '',
      companySearchTerm: '',
      selectedCategoryId: this.props.initialCategoryId,
      selectedCategoryType: this.props.initialCategoryType,
      selectedBrandId: undefined,
      selectedBrand: undefined,
      selectedBrandPositionInStageId: 2,
      newBrandName: '',
      newBrandProductUrl: '',
      newBrandCompanyName: '',
      newBrandCompanyUrl: '',
      newBrandCompanyDetailedDescription: null,
      newBrandCompanyWikipediaUrl: null,
      newBrandCompanyBusinessCategory: null,
      newBrandCompanyImageSourceUrl: null,
      newBrandCompanyImageContentUrl: null,
      newBrandDescription: '',
      newBrandPositionInStageId: 2,
      newBrandErrors: {},
      newBrandErrorMessage: '',
      createDisabled: false,
      showSummaryScreen: false,
      summaryContents: [],
      licenseAllowsAdd: false,
    }));
    this.fetchAllProductBrands();
  };

  fetchAllProductBrands = () => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/product-brands`,
      HEADERS
    ).then(response => {
      const allBrands = response.data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      });
      this.setState(() => ({
        brands: allBrands,
        allBrands
      }));
    }).catch(error => {
      console.error('Error: failed to fetch product brands');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  onCategoryChange = (event) => {
    const categoryValues = event.currentTarget.value.split('-');
    this.setState(() => ({
      selectedCategoryType: categoryValues[0],
      selectedCategoryId: categoryValues[1]
    }));
  };

  searchBrands = (searchTerm) => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/product-brands/search/${searchTerm}`,
      HEADERS
    ).then(response => {
      const brands = response.data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      });
      this.setState(() => ({ brands }));
    }).catch(error => {
      console.error('Error: failed to fetch product brands by search term');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  searchBrandsByCompany = (searchTerm) => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/product-brands/company/search/${searchTerm}`,
      HEADERS
    ).then(response => {
      const brands = response.data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      });
      this.setState(() => ({ brands }));
    }).catch(error => {
      console.error('Error: failed to fetch product brands by company search term');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  onBrandsSearchTermChange = (event) => {
    const brandsSearchTerm = event.currentTarget.value;
    if (brandsSearchTerm === '') {
      this.setState(() => ({
        brandsSearchTerm,
        brands: this.state.allBrands
      }));
    } else {
      this.setState(() => ({ brandsSearchTerm }));
      this.searchBrands(brandsSearchTerm);
    }
  };

  onCompanySearchTermChange = (event) => {
    const companySearchTerm = event.currentTarget.value;
    if (companySearchTerm === '') {
      this.setState(() => ({
        companySearchTerm,
        brands: this.state.allBrands
      }));
    } else {
      this.setState(() => ({ companySearchTerm }));
      this.searchBrandsByCompany(companySearchTerm);
    }
  };

  selectBrand = (selectedBrandId, selectedBrand) => {
    this.setState(() => ({
      selectedBrandId,
      selectedBrand,
      selectedBrandPositionInStageId: 2
    }));
  };

  onSelectedBrandPositionInStageIdChange = (event) => {
    const selectedBrandPositionInStageId = parseInt(event.currentTarget.value);
    this.setState(() => ({ selectedBrandPositionInStageId }));
  };

  onNewBrandNameChange = (event) => {
    const newBrandName = event.currentTarget.value;
    this.setState(() => ({ newBrandName }));
  };

  onNewBrandProductUrlChange = (event) => {
    const newBrandProductUrl = event.currentTarget.value;
    this.setState(() => ({ newBrandProductUrl }));
  };

  onNewBrandCompanyNameChange = (event) => {
    const newBrandCompanyName = event.currentTarget.value;
    this.setState(() => ({ newBrandCompanyName }));
  };

  onNewBrandCompanyUrlChange = (event) => {
    const newBrandCompanyUrl = event.currentTarget.value;
    this.setState(() => ({ newBrandCompanyUrl }));
  };

  onNewBrandDescriptionChange = (event) => {
    const newBrandDescription = event.currentTarget.value;
    this.setState(() => ({ newBrandDescription }));
  };

  onNewBrandPositionInStageIdChange = (event) => {
    const newBrandPositionInStageId = parseInt(event.currentTarget.value);
    this.setState(() => ({ newBrandPositionInStageId }));
  };

  onCompanySearchSelect = (selectedOption) => {
    const newBrandCompanyName = selectedOption.companyResult.name || '';
    const newBrandCompanyUrl = selectedOption.companyResult.url || '';
    let newBrandCompanyDetailedDescription = null;
    let newBrandCompanyWikipediaUrl = null;
    let newBrandCompanyBusinessCategory = null;
    let newBrandCompanyImageSourceUrl = null;
    let newBrandCompanyImageContentUrl = null;
    if (selectedOption.companyResult.detailedDescription) {
      if (selectedOption.companyResult.detailedDescription.articleBody) {
        newBrandCompanyDetailedDescription = selectedOption.companyResult.detailedDescription.articleBody;
      }
      if (selectedOption.companyResult.detailedDescription.url) {
        newBrandCompanyWikipediaUrl = selectedOption.companyResult.detailedDescription.url;
      }
    }
    if (selectedOption.companyResult.description) {
      newBrandCompanyBusinessCategory = selectedOption.companyResult.description;
    }
    if (selectedOption.companyResult.image) {
      newBrandCompanyImageSourceUrl = selectedOption.companyResult.image.url;
      newBrandCompanyImageContentUrl = selectedOption.companyResult.image.contentUrl;
    }
    this.setState(() => ({
      newBrandCompanyName,
      newBrandCompanyUrl,
      newBrandCompanyDetailedDescription,
      newBrandCompanyWikipediaUrl,
      newBrandCompanyBusinessCategory,
      newBrandCompanyImageSourceUrl,
      newBrandCompanyImageContentUrl
    }));
  };

  updateLicenseAllowsAdd = (licenseAllowsAdd) => {
    if (licenseAllowsAdd !== this.state.licenseAllowsAdd) {
      this.setState(() => ({ licenseAllowsAdd }));
    }
  };

  subscribeToBrand = (closeOnSuccess = true) => {
    if (this.state.selectedCategoryId && this.state.selectedBrandId) {
      const newCategoryProductBrand = {
        [`${this.state.selectedCategoryType}_category_id`]: this.state.selectedCategoryId,
        product_brand_id: this.state.selectedBrandId,
        position_in_stage: this.state.selectedBrandPositionInStageId
      };

      axios.post(
        `${LISTEN_ENDPOINT}/api/${this.state.selectedCategoryType}-category-product-brands`,
        newCategoryProductBrand,
        HEADERS
      ).then(response => {
        this.props.refreshCompanyCategories(this.props.companyId);
        if (closeOnSuccess) {
          this.props.handleClose();
        }
      }).catch(error => {
        console.error('Error: failed to subscribe to product brand');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  createNewIsValidForm = () => {
    const newBrandErrors = {};
    let newBrandErrorMessage = '';
    if (!this.state.newBrandName || this.state.newBrandName.trim() === '') {
      newBrandErrors.newBrandName = true;
      newBrandErrorMessage = 'Please provide Product Brand Name';
    }
    if (!this.state.newBrandCompanyName || this.state.newBrandName.trim() === '') {
      newBrandErrors.newBrandCompanyName = true;
      newBrandErrorMessage = 'Please provide Company Brand selection';
    }
    if (!this.state.newBrandCompanyUrl || this.state.newBrandCompanyUrl.trim() === '') {
      newBrandErrors.newBrandCompanyUrl = true;
      newBrandErrorMessage = 'Please provide Company URL';
    }

    this.setState(() => ({
      newBrandErrors,
      newBrandErrorMessage
    }));
    if (Object.keys(newBrandErrors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  createNewBrand = () => {
    if (this.createNewIsValidForm()) {
      const body = {
        company_name: this.state.newBrandCompanyName,
        company_url: this.state.newBrandCompanyUrl,
        detailed_description: this.state.newBrandCompanyDetailedDescription,
        wikipedia_url: this.state.newBrandCompanyWikipediaUrl,
        business_category: this.state.newBrandCompanyBusinessCategory,
        img_source_url: this.state.newBrandCompanyImageSourceUrl,
        img_content_url: this.state.newBrandCompanyImageContentUrl,
        product_brands: []
      };
      const newProductBrand = {
        product_brand_name: this.state.newBrandName,
        company_name: this.state.newBrandCompanyName,
        company_url: this.state.newBrandCompanyUrl,
        sponsor_customer_id: this.props.companyId
      };
      if (this.state.newBrandProductUrl) {
        newProductBrand.product_url = this.state.newBrandProductUrl;
      }
      if (this.state.newBrandDescription) {
        newProductBrand.description = this.state.newBrandDescription;
      }
      body.product_brands.push(newProductBrand);

      this.setState(() => ({ createDisabled: true }));
      axios.post(
        `${LISTEN_ENDPOINT}/api/public-company`,
        body,
        HEADERS
      ).then(response => {
        const productBrand = response.data.product_brands[0];
        this.setState(
          () => ({
            selectedBrandId: productBrand.id,
            selectedBrandPositionInStageId: this.state.newBrandPositionInStageId,
            showSummaryScreen: true,
            summaryContents: response.data.product_brand_creation_msgs,
          }),
          () => this.subscribeToBrand(false)
        );
      }).catch(error => {
        console.log('Error: failed to create new product brand');
        if (error.response && error.response.data) {
          this.setState(() => ({
            newBrandErrorMessage: error.response.data.message,
            createDisabled: false
          }));
        } else {
          this.setState(() => ({ createDisabled: false }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <Modal
        show={this.props.isOpen}
        onHide={this.props.handleClose}
        onEntering={this.onOpen}
        size="xl"
      >
        <Modal.Header
          className="bg-bops-blue text-light"
          closeButton
        >
          <Modal.Title>
            Add Brand to Category
            <div className="d-inline-block ml-4">
              <Form.Control
                as="select"
                value={`${this.state.selectedCategoryType}-${this.state.selectedCategoryId}`}
                onChange={this.onCategoryChange}
                style={{
                  minWidth: '250px',
                  maxWidth: '300px'
                }}
              >
                { this.props.categories.map(category => {
                    return (
                      <option
                        key={`${category.category_type}-${category.id}`}
                        value={`${category.category_type}-${category.id}`}
                      >
                        {category.name}
                      </option>
                    )
                  })
                }
              </Form.Control>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { !this.state.showSummaryScreen &&
            <div>
              <LicenseUsage
                type="brands"
                customerId={this.props.companyId}
                categories={this.props.categories}
                updateLicenseAllowsAdd={this.updateLicenseAllowsAdd}
              />
              <Tabs
                activeKey={this.state.selectedTab}
                onSelect={(selectedTab) => this.setState(() => ({ selectedTab }))}
              >
                <Tab eventKey="subscribe" title="Follow Existing Brands">
                  <div className="mt-2">
                    <Form inline>
                      <Form.Group>
                        <Form.Label className="mr-2">
                          Search BrandOps Brands
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={this.state.brandsSearchTerm}
                          onChange={this.onBrandsSearchTermChange}
                          style={{ width: '300px' }}
                        />
                      </Form.Group>
                      <Form.Group className="ml-4">
                        <Form.Label className="mr-2">
                          Search By Company Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={this.state.companySearchTerm}
                          onChange={this.onCompanySearchTermChange}
                          style={{ width: '300px' }}
                        />
                      </Form.Group>
                    </Form>
                    <hr />
                    <Row>
                      <Col xs={4}>
                        <div>
                          <b>Brands</b>
                        </div>
                        <div className="border rounded">
                          <div
                            className="pre-scrollable"
                            style={{ height: '200px', minHeight: '200px' }}
                          >
                            { this.state.brands.map(brand => {
                                let className = 'px-2';
                                if (brand.id === this.state.selectedBrandId) {
                                  className += ' bg-primary text-light';
                                }
                                return (
                                  <div
                                    key={`brand${brand.id}`}
                                    className={className}
                                    onClick={() => this.selectBrand(brand.id, brand)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {brand.name}
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </Col>
                      <Col>
                        { this.state.selectedBrand &&
                          <div className="mt-4">
                            <div>
                              <b>Description: </b>
                              <span>
                                {
                                  this.state.selectedBrand.description ?
                                    this.state.selectedBrand.description :
                                    'N/A'
                                }
                              </span>
                            </div>
                            <div>
                              <b>Product URL: </b>
                              <span>
                                {
                                  this.state.selectedBrand.product_url ?
                                    <a
                                      href={this.state.selectedBrand.product_url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {this.state.selectedBrand.product_url}
                                    </a>
                                    :
                                    'N/A'
                                }
                              </span>
                            </div>
                            <div>
                              <b>Company Name: </b>
                              <span>
                                {
                                  this.state.selectedBrand.company_name ?
                                    this.state.selectedBrand.company_name :
                                    'N/A'
                                }
                              </span>
                            </div>
                            <div>
                              <b>Company URL: </b>
                              <span>
                                {
                                  this.state.selectedBrand.company_url ?
                                    <a
                                      href={this.state.selectedBrand.company_url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {this.state.selectedBrand.company_url}
                                    </a>
                                    :
                                    'N/A'
                                }
                              </span>
                            </div>
                            <div className="mt-2">
                              <Form inline>
                                <Form.Group>
                                  <Form.Label>
                                    <b>Position in Stage:</b>
                                  </Form.Label>
                                  <Form.Control
                                    className="ml-2"
                                    as="select"
                                    value={this.state.selectedBrandPositionInStageId}
                                    onChange={this.onSelectedBrandPositionInStageIdChange}
                                    size="sm"
                                  >
                                    { this.props.positionInStages.map(positionInStage => {
                                        return (
                                          <option
                                            key={`sbpis${positionInStage.id}`}
                                            value={positionInStage.id}
                                          >
                                            {positionInStage.name}
                                          </option>
                                        )
                                      })
                                    }
                                  </Form.Control>
                                </Form.Group>
                              </Form>
                            </div>
                          </div>
                        }
                      </Col>
                    </Row>
                  </div>
                </Tab>
                <Tab eventKey="create" title="Follow Other Brands">
                  <div className="mt-2">
                    <Form>
                      <Form.Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Company Brand</Form.Label>
                            <CompanySearchComboBox
                              value={this.newBrandCompanyName}
                              onSelect={this.onCompanySearchSelect}
                              isInvalid={this.state.newBrandErrors.newBrandCompanyName}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Company URL</Form.Label>
                            <Form.Control
                              type="text"
                              value={this.state.newBrandCompanyUrl}
                              onChange={this.onNewBrandCompanyUrlChange}
                              isInvalid={this.state.newBrandErrors.newBrandCompanyUrl}
                            />
                          </Form.Group>
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Product Brand</Form.Label>
                            <Form.Control
                              type="text"
                              value={this.state.newBrandName}
                              onChange={this.onNewBrandNameChange}
                              isInvalid={this.state.newBrandErrors.newBrandName}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Product URL</Form.Label>
                            <Form.Control
                              type="text"
                              value={this.state.newBrandProductUrl}
                              onChange={this.onNewBrandProductUrlChange}
                              isInvalid={this.state.newBrandErrors.newBrandProductUrl}
                            />
                          </Form.Group>
                        </Col>
                      </Form.Row>
                      <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="1"
                          value={this.state.newBrandDescription}
                          onChange={this.onNewBrandDescriptionChange}
                          isInvalid={this.state.newBrandErrors.newBrandDescription}
                        />
                      </Form.Group>
                      <Form.Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Position in Stage</Form.Label>
                            <Form.Control
                              className="w-50"
                              as="select"
                              value={this.state.newBrandPositionInStageId}
                              onChange={this.onNewBrandPositionInStageIdChange}
                            >
                              { this.props.positionInStages.map(positionInStage => {
                                  return (
                                    <option
                                      key={`nbpis${positionInStage.id}`}
                                      value={positionInStage.id}
                                    >
                                      {positionInStage.name}
                                    </option>
                                  )
                                })
                              }
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col></Col>
                      </Form.Row>
                    </Form>
                    <Alert show={Boolean(this.state.newBrandErrorMessage)} variant="danger">
                      {this.state.newBrandErrorMessage}
                    </Alert>
                  </div>
                </Tab>
              </Tabs>
            </div>
          }
          { this.state.showSummaryScreen &&
            <div>
              <div><b>Summary</b></div>
              <hr />
              { this.state.summaryContents.map((content, i) => {
                  return (
                    <div key={`sc-${i}`}>
                      {content}
                    </div>
                  )
                })
              }
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
          { !this.state.showSummaryScreen &&
            <React.Fragment>
              { this.state.selectedTab === 'subscribe' &&
                <Button
                  variant="primary"
                  onClick={this.subscribeToBrand}
                  disabled={!this.state.licenseAllowsAdd}
                >
                  Add
                </Button>
              }
              { this.state.selectedTab === 'create' &&
                <Button
                  variant="primary"
                  onClick={this.createNewBrand}
                  disabled={this.state.createDisabled || !this.state.licenseAllowsAdd}
                >
                  { this.state.createDisabled &&
                    <span className="mr-1">
                      <ClipLoader
                        size={15}
                        color="ffffff"
                      />
                    </span>
                  }
                  Add
                </Button>
              }
            </React.Fragment>
          }
        </Modal.Footer>
      </Modal>
    );
  }
};
