import React from 'react';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  ScatterChart,
  Scatter,
  Dot,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  CartesianGrid,
  ReferenceArea,
  ResponsiveContainer
} from 'recharts';
import { colors } from '../../../../utils/graphs';
import { round } from '../../../../utils/numbers';
import { sortAlphabeticalByKey } from '../../../../utils/sorts';


export default class CorrelationGraphs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scatterData: [],
      allBrandsSelected: true,
      categorySelected: false,
      brandSelected: false,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.setScatterData();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.allMetricCorrelationData !== this.props.allMetricCorrelationData ||
      prevProps.categoryMetricCorrelationData !== this.props.categoryMetricCorrelationData ||
      prevProps.brandMetricCorrelationData !== this.props.brandMetricCorrelationData ||
      prevProps.selectedMetricName !== this.props.selectedMetricName
    ) {
      this.setScatterData();
    }
    if (
      prevProps.selectedMetricIsPrivate === false &&
      this.props.selectedMetricIsPrivate === true &&
      this.state.categorySelected
    ) {
      this.setState(
        () => ({
          categorySelected: false,
        }),
        () => this.updateScatterDataSort()
      )
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  setScatterData = () => {
    if (
      this.props.allMetricCorrelationData &&
      this.props.selectedMetricName &&
      this.props.allMetricCorrelationData[this.props.selectedMetricName]
    ) {
      let scatterData = Object.entries(this.props.allMetricCorrelationData[this.props.selectedMetricName]).map(([metricName, allBrandsValue]) => {
        const metricLabel = this.props.brandMetricsMap[metricName] ? this.props.brandMetricsMap[metricName].metric_label : metricName;
        const isPrivate = this.props.brandMetricsMap[metricName] ? this.props.brandMetricsMap[metricName].is_private : false;
        const scatter = {
          metricLabel,
          metricName,
        };
        // add all brands value
        if (!isPrivate) {
          // null out all brands value for private data
          scatter.allBrandsValue = allBrandsValue;
        } else {
          scatter.allBrandsValue = null;
        }
        // add category value
        if (
          !isPrivate && // null out category value for private data
          this.props.categoryMetricCorrelationData &&
          this.props.categoryMetricCorrelationData[this.props.selectedMetricName] &&
          this.props.categoryMetricCorrelationData[this.props.selectedMetricName][metricName] != null
        ) {
          scatter.categoryValue = this.props.categoryMetricCorrelationData[this.props.selectedMetricName][metricName];
        } else {
          scatter.categoryValue = null;
        }
        // add brand value
        if (
          this.props.brandMetricCorrelationData &&
          this.props.brandMetricCorrelationData[this.props.selectedMetricName] &&
          this.props.brandMetricCorrelationData[this.props.selectedMetricName][metricName] != null
        ) {
          scatter.brandValue = this.props.brandMetricCorrelationData[this.props.selectedMetricName][metricName];
        } else {
          scatter.brandValue = null;
        }
        return scatter;
      });
      this.setState(
        () => ({ scatterData }),
        () => this.updateScatterDataSort()
      );
    } else {
      this.setState(
        () => ({ scatterData: [] }),
        () => this.updateScatterDataSort()
      );
    }
  };

  onAllBrandsSelectedChange = (event) => {
    const allBrandsSelected = event.currentTarget.checked;
    this.setState(
      () => ({ allBrandsSelected }),
      () => this.updateScatterDataSort()
    );
  };

  onCategorySelectedChange = (event) => {
    const categorySelected = event.currentTarget.checked;
    this.setState(
      () => ({ categorySelected }),
      () => this.updateScatterDataSort()
    );
  };

  onBrandSelectedChange = (event) => {
    const brandSelected = event.currentTarget.checked;
    this.setState(
      () => ({ brandSelected }),
      () => this.updateScatterDataSort()
    );
  };

  updateScatterDataSort = () => {
    // update the sort based on the largest dataset selected
    if (this.state.allBrandsSelected) {
      this.setState((prevState) => {
        let scatterData = prevState.scatterData;
        scatterData.sort((a, b) => a.allBrandsValue - b.allBrandsValue);
        return ({ scatterData });
      });
    } else if (this.state.categorySelected) {
      this.setState((prevState) => {
        let scatterData = prevState.scatterData;
        scatterData.sort((a, b) => a.categoryValue - b.categoryValue);
        return ({ scatterData });
      });
    } else if (this.state.brandSelected) {
      this.setState((prevState) => {
        let scatterData = prevState.scatterData;
        scatterData.sort((a, b) => a.brandValue - b.brandValue);
        return ({ scatterData });
      });
    }
  };

  customTooltip = ({ active, payload, label }) => {
    if (active && payload && Array.isArray(payload)) {
      if (payload[0] && payload[0].payload) {
        const data = payload[0].payload;
        label = (
          <React.Fragment>
            <b>{this.props.brandMetricsMap[this.props.selectedMetricName].metric_label}</b> to <b>{data.metricLabel}</b>
          </React.Fragment>
        )
        // label = `${this.props.brandMetricsMap[this.props.selectedMetricName].metric_label} to ${data.metricLabel}`;
        return (
          <div
            className="p-2"
            style={{
              backgroundColor: '#ffffff',
              borderStyle: 'solid',
              borderWidth: 'thin',
              borderColor: '#bfbfbf',
              fontSize: '.875rem'
            }}
          >
            <div>{label}</div>
            { this.state.allBrandsSelected &&
              <div style={{ color: colors[0]}}>
                {`All Brands Correlation: ${data.allBrandsValue == null ? 'N/A' : round(data.allBrandsValue, 2)}`}
              </div>
            }
            { this.state.categorySelected &&
              <div style={{ color: colors[1]}}>
                {`Category Correlation: ${data.categoryValue == null ? 'N/A' : round(data.categoryValue, 2)}`}
              </div>
            }
            { this.state.brandSelected &&
              <div style={{ color: colors[2]}}>
                {`Brand (${this.props.brand.name}) Correlation: ${data.brandValue == null ? 'N/A' : round(data.brandValue, 2)}`}
              </div>
            }
          </div>
        )
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  render () {
    return (
      <div>
        <div className="mt-2 mb-3">
          <Form.Check
            type="checkbox"
            label={
              <div className="d-inline-block">
                <div
                  className="d-inline-block mr-1 align-middle"
                  style={{
                    width: '16px',
                    height: '16px',
                    backgroundColor: colors[0],
                    borderRadius: '50%'
                  }}
                />
                <div className="d-inline-block align-middle">
                  All Brands
                </div>
              </div>
            }
            checked={this.state.allBrandsSelected}
            onChange={this.onAllBrandsSelectedChange}
            inline
          />
          <Form.Check
            type="checkbox"
            label={
              <div className="d-inline-block">
                <div
                  className="d-inline-block mr-1 align-middle"
                  style={{
                    width: '16px',
                    height: '16px',
                    backgroundColor: colors[1],
                    borderRadius: '50%'
                  }}
                />
                <div className="d-inline-block align-middle">
                  Category
                </div>
              </div>
            }
            checked={this.state.categorySelected}
            onChange={this.onCategorySelectedChange}
            disabled={this.props.selectedMetricIsPrivate === true}
            inline
          />
          <Form.Check
            type="checkbox"
            label={
              <div className="d-inline-block">
                <div
                  className="d-inline-block mr-1 align-middle"
                  style={{
                    width: '16px',
                    height: '16px',
                    backgroundColor: colors[2],
                    borderRadius: '50%'
                  }}
                />
                <div className="d-inline-block align-middle">
                  {`Brand (${this.props.brand.name})`}
                </div>
              </div>
            }
            checked={this.state.brandSelected}
            onChange={this.onBrandSelectedChange}
            inline
          />
        </div>
        <div>
          { this.props.allMetricCorrelationDataLoading &&
            <div className="m-4 text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !this.props.allMetricCorrelationDataLoading &&
            <ResponsiveContainer
              width="99%"
              height={
                this.state.scatterData.length > 1 ?
                  this.state.scatterData.length * 20 :
                  400
                }
              minWidth={0}
              minHeight={0}
            >
              <ScatterChart
                margin={{
                  top: 30, bottom: 30, right: 25
                }}
              >
                <CartesianGrid />
                <XAxis
                  xAxisId={0}
                  orientation="top"
                  type="number"
                  dataKey="allBrandsValue"
                  name="Correlation"
                  domain={[-1, 1]}
                >
                  <Label
                    value="Metric-to-Metric Correlation"
                    position="top"
                    style={{
                      textAnchor: 'middle',
                      fontSize: '1rem',
                      fontWeight: 'bold'
                    }}
                  />
                </XAxis>
                <XAxis
                  xAxisId={1}
                  type="number"
                  dataKey="categoryValue"
                  domain={[-1, 1]}
                  hide={true}
                />
                <XAxis
                  xAxisId={2}
                  orientation="bottom"
                  type="number"
                  dataKey="brandValue"
                  name="Correlation"
                  domain={[-1, 1]}
                >
                  <Label
                    value="Metric-to-Metric Correlation"
                    position="bottom"
                    style={{
                      textAnchor: 'middle',
                      fontSize: '1rem',
                      fontWeight: 'bold'
                    }}
                  />
                  { this.state.scatterData.length === 0 &&
                    <Label
                      value="Not enough data gathered"
                      position="top"
                      offset={225}
                      style={{ fontSize: '1.5rem', fill: '#6c757d' }}
                    />
                  }
                </XAxis>
                <YAxis
                  type="category"
                  dataKey="metricLabel"
                  name="Metric"
                  width={275}
                  interval={0}
                  style={{
                    fontSize: '.8rem'
                  }}
                />
                <Tooltip content={this.customTooltip} />
                <ReferenceArea x1={-1} x2={-0.7} />
                <ReferenceArea x1={0.7} x2={1} />
                { this.state.allBrandsSelected &&
                  <Scatter
                    xAxisId={0}
                    data={this.state.scatterData}
                    fill={colors[0]}
                  />
                }
                { this.state.categorySelected &&
                  <Scatter
                    xAxisId={1}
                    data={this.state.scatterData}
                    fill={colors[1]}
                  />
                }
                { this.state.brandSelected &&
                  <Scatter
                    xAxisId={2}
                    data={this.state.scatterData}
                    fill={colors[2]}
                  />
                }
              </ScatterChart>
            </ResponsiveContainer>
          }
        </div>
      </div>
    );
  }
};
