import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class WebinarTooltipContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      webinarMentionsData: [],
      webinarMentionsDataLoading: false
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchMentions();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  fetchMentions = () => {
    this.setState(() => ({ webinarMentionsDataLoading: true }));
    if (this.props.webinarId) {
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/webinars-detected-mentions?webinar_detected_id=${this.props.webinarId}`,
        HEADERS
      ).then(response => {
        const webinarMentionsData = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            webinarMentionsData,
            webinarMentionsDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch webinar mentions data');
        if (this.state.isMounted) {
          this.setState(() => ({
            webinarMentionsData: [],
            webinarMentionsDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  }

  render () {
    return (
      <div>
        { this.state.webinarMentionsDataLoading &&
          <div className="text-center">
            <ClipLoader size={20}/>
          </div>
        }
        { !this.state.webinarMentionsDataLoading &&
          <div style={{ fontSize: '.875rem' }}>
            <a href={this.props.webinarSignUpLink} target="_blank" rel="noreferrer">
              Signup Page
            </a>
            {  this.state.webinarMentionsData.map(webinarMentions => {
                return (
                  <div key={`wm${webinarMentions.id}`} className="px-1">
                    {webinarMentions.source} {moment(webinarMentions.mention_date).format('MM/DD/YY')}
                  </div>
                )
              })
            }
          </div>
        }
      </div>
    );
  }
};
