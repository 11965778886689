import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GeneralSummaryGraph from '../graphs/GeneralSummaryGraph';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class EmailCampaignsSummaryGraphs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailCampaignsTrendData: [],
      emailCampaignsTrendDataLoading: false,
    }
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchEmailCampaignsTrendData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.companyId !== this.props.companyId) {
      this.fetchEmailCampaignsTrendData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchEmailCampaignsTrendData = () => {
    if (this.props.companyId) {
      this.setState(() => ({ emailCampaignsTrendDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/companies/${this.props.companyId}/email-campaigns-trend`,
        HEADERS
      ).then(response => {
        const emailCampaignsTrendData = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            emailCampaignsTrendData,
            emailCampaignsTrendDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch company email campaigns trend...');
        if (this.state.isMounted) {
          this.setState(() => ({
            emailCampaignsTrendData: [],
            emailCampaignsTrendDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        { this.props.companyId &&
          <div>
            <Row>
              <Col xs={12} lg={6}>
                <div className="mb-2">
                  <GeneralSummaryGraph
                    title="Outbound Email Count"
                    data={this.state.emailCampaignsTrendData}
                    dataKey="emails_sent"
                    dataLabel="Outbound Email Count"
                    loading={this.state.emailCampaignsTrendDataLoading}
                    allowDecimals={false}
                    contextType="brand"
                    contextCategory={this.props.category}
                    contextBrand={this.props.brand}
                    contextChannel="Email Campaigns"
                    contextChartName="Outbound Email Count"
                  />
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <div className="mb-2">
                  <GeneralSummaryGraph
                    title="Emails Opened"
                    data={this.state.emailCampaignsTrendData}
                    dataKey="emails_opened"
                    dataLabel="Emails Opened"
                    loading={this.state.emailCampaignsTrendDataLoading}
                    allowDecimals={false}
                    contextType="brand"
                    contextCategory={this.props.category}
                    contextBrand={this.props.brand}
                    contextChannel="Email Campaigns"
                    contextChartName="Emails Opened"
                  />
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <div className="mb-2">
                  <GeneralSummaryGraph
                    title="Emails Click Through"
                    data={this.state.emailCampaignsTrendData}
                    dataKey="emails_clicked"
                    dataLabel="Emails Clicked"
                    loading={this.state.emailCampaignsTrendDataLoading}
                    allowDecimals={false}
                    contextType="brand"
                    contextCategory={this.props.category}
                    contextBrand={this.props.brand}
                    contextChannel="Email Campaigns"
                    contextChartName="Emails Click Through"
                  />
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <div className="mb-2">
                  <GeneralSummaryGraph
                    title="Unsubscribed"
                    data={this.state.emailCampaignsTrendData}
                    dataKey="emails_unsubscribed"
                    dataLabel="Unsubscribed"
                    loading={this.state.emailCampaignsTrendDataLoading}
                    allowDecimals={false}
                    contextType="brand"
                    contextCategory={this.props.category}
                    contextBrand={this.props.brand}
                    contextChannel="Email Campaigns"
                    contextChartName="Unsubscribed"
                  />
                </div>
              </Col>
            </Row>
          </div>
        }
      </div>
    );
  }
};
