import React from 'react';
import axios from 'axios';
import moment from 'moment';
import GeneralSummaryGraph from '../graphs/GeneralSummaryGraph';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class ReviewActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reviewVolumeData: [],
      reviewVolumeScoreType: undefined,
      reviewVolumeDataLoading: false,
      categoryAverageVolumeData: [],
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getReviews();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.topicId !== this.props.topicId ||
      prevProps.allMonthsChecked !== this.props.allMonthsChecked
    ) {
      this.getReviews();
    }
  }

  getReviews = () => {
    this.setState(() => ({ reviewVolumeDataLoading: true }));
    let allMonthsStartDateParam = '';
    if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
      const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
      if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
        allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
      }
    }
    axios.get(
      `${LISTEN_ENDPOINT}/api/topics/${this.props.topicId}/review-volume-trend${allMonthsStartDateParam ? `?start-date=${allMonthsStartDateParam}` : ''}`,
      HEADERS
    ).then(response => {
      let reviewVolumeData = response.data.monthly_stats;
      const reviewVolumeScoreType = response.data.review_volume ? response.data.review_volume : undefined;
      // merge category average data into original data source
      reviewVolumeData = reviewVolumeData.map((x, i) => {
        return (Object.assign({}, x, this.state.categoryAverageVolumeData[i]))
      });

      if (this.state.isMounted) {
        this.setState(() => ({
          reviewVolumeData,
          reviewVolumeScoreType,
          reviewVolumeDataLoading: false
        }));
      }
    }).catch(error => {
      console.error('Error loading review volume trend...');
      if (this.state.isMounted) {
        this.setState(() => ({
          reviewVolumeData: [],
          reviewVolumeScoreType: undefined,
          reviewVolumeDataLoading: false
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });

    if (this.props.category) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=pb_reviews_avg_volume${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        const categoryAverageVolumeData = response.data.category_stats;
        // merge category average data into original data source
        const reviewVolumeData = this.state.reviewVolumeData.map((x, i) => {
          return (Object.assign({}, x, categoryAverageVolumeData[i]))
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryAverageVolumeData,
            reviewVolumeData
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch category average product review volume data');
        if (this.state.isMounted) {
          this.setState(() => ({ categoryAverageVolumeData: [] }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  }

  render () {
    return (
      <div>
        <GeneralSummaryGraph
          title="Number of Product Brand Reviews"
          metricName="reviews_count_all"
          data={this.state.reviewVolumeData}
          dataKey="review_volume"
          dataLabel="Number of Product Brand Reviews"
          scoreType={this.state.reviewVolumeScoreType}
          loading={this.state.reviewVolumeDataLoading}
          allowDecimals={false}
          showPercentChange={true}
          showAverageData={true}
          averageDataKey="pb_reviews_avg_volume"
          averageDataLabel="Category Average"
          showTooltip={true}
          contextType="brand"
          contextCategory={this.props.category}
          contextBrand={this.props.brand}
          contextChannel="Product Reviews"
          contextChartName="Number of Product Brand Reviews"
          shareClassName="product-brand-reviews-count-img"
        />
      </div>
    );
  }
};
