import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import CardComponent from '../../common/CardComponent';
import { intToShorthandString } from '../../../utils/numbers';
import {
  LISTEN_ENDPOINT,
  HEADERS
} from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class TwitterUserTweets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tweetVolumeData: [],
      volumeSum: 0,
      volumeMax: 0,
      volumeMaxDate: undefined,
      volumeAveragePerDay: 0,
      tweetVolumeDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.loadTweetVolumeData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.twitterUserId !== this.props.twitterUserId ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter ||
      prevProps.companyChecked !== this.props.companyChecked ||
      prevProps.portfolioChecked !== this.props.portfolioChecked ||
      prevProps.productChecked !== this.props.productChecked
    ) {
      this.loadTweetVolumeData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  loadTweetVolumeData = () => {
    this.setState(() => ({ tweetVolumeDataLoading: true }));
    const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
    let brandLevelsParam = '';
    if (this.props.companyChecked) {
      brandLevelsParam += '&brand_levels=company';
    }
    if (this.props.portfolioChecked) {
      brandLevelsParam += '&brand_levels=portfolio';
    }
    if (this.props.productChecked) {
      brandLevelsParam += '&brand_levels=product';
    }
    axios.get(
      `${LISTEN_ENDPOINT}/api/twitter-user-analysis/${this.props.twitterUserId}/tweet-volume?topic_id=${this.props.topicId}&start-date=${formattedStartDate}&end-date=${formattedEndDate}${brandLevelsParam}`,
      HEADERS
    ).then(response => {
      const tweetVolumeData = response.data;
      let volumeSum = 0;
      let volumeMax = 0;
      let volumeMaxDate;
      let volumeAveragePerDay = 0;

      for (const volume of tweetVolumeData) {
        volumeSum += volume.count;
        if (volume.count > volumeMax) {
          volumeMax = volume.count;
          volumeMaxDate = moment(volume.date).format('MM/DD/YYYY');
        }
      };

      if (tweetVolumeData.length > 0) {
        volumeAveragePerDay = parseInt(volumeSum / tweetVolumeData.length);
      }

      if (this.state.isMounted) {
        this.setState(() => ({
          tweetVolumeData,
          volumeSum,
          volumeMax,
          volumeMaxDate,
          volumeAveragePerDay,
          tweetVolumeDataLoading: false
        }));
      }
    }).catch(error => {
      if (this.state.isMounted) {
        this.setState(() => ({
          tweetVolumeData: [],
          tweetVolumeDataLoading: false
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  render () {
    return (
      <CardComponent
        title="Tweet Volume"
        icon={
          <img
            className="mr-2 align-top"
            src="/images/Tweet_Volume_Icon.png"
          />
        }
        description="Tweet Volume allows you to view tweet counts as a trend for a specific Twitter account"
        body={
          <div>
            { this.state.tweetVolumeDataLoading &&
              <div
                className="text-center"
                style={{ height: `300px` }}
              >
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.tweetVolumeDataLoading &&
              <Row>
                <Col xs={8}>
                  <ResponsiveContainer height={300} width="100%">
                    <LineChart data={this.state.tweetVolumeData}>
                      <XAxis
                        dataKey="date"
                        tickFormatter={(date) => {
                          date = moment(date);
                          if (date.isValid()) {
                            return date.endOf('day').format('MM/DD');
                          } else {
                            return '';
                          }
                        }}
                      />
                      <YAxis allowDecimals={false}/>
                      <Tooltip labelFormatter={(label) => moment(label).format('dddd, MMMM Do YYYY')}/>
                      <CartesianGrid strokeDasharray="5 5"/>
                      <Line name="Tweets" type="monotone" dataKey="count" stroke="#007bff"/>
                    </LineChart>
                  </ResponsiveContainer>
                </Col>
                <Col xs={4}>
                  <div>Sum of Volume</div>
                  <div style={{ color: '#007bff' }}>
                    {intToShorthandString(this.state.volumeSum)}
                  </div>

                  <div>Max Volume</div>
                  <div style={{ color: '#007bff' }}>
                    {intToShorthandString(this.state.volumeMax)}
                  </div>
                  <div style={{ fontSize: '.75rem' }}>
                    {this.state.volumeMaxDate}
                  </div>

                  <div>Average Volume per Day</div>
                  <div style={{ color: '#007bff' }}>
                    {intToShorthandString(this.state.volumeAveragePerDay)}
                  </div>
                </Col>
              </Row>
            }
          </div>
        }
        contextType="brand"
        contextCategory={this.props.category}
        contextBrand={this.props.brand}
        contextChannel="Social"
        contextProperty="Twitter"
        contextChartName="Tweet Volume"
      />
    );
  }
};
