import React from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import ClipLoader from 'react-spinners/ClipLoader';
import MetricDistributionSummary from './MetricDistributionSummary';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class MetricDistributionSummaryModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brandMetric: {},
      loading: false
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  onOpen = () => {
    if (this.state.brandMetric.metric_name !== this.props.metricName) {
      this.fetchBrandMetric();
    }
  };

  fetchBrandMetric = () => {
    this.setState(() => ({ loading: true }));
    axios.get(
      `${LISTEN_ENDPOINT}/api/brand-metrics?metric_name=${this.props.metricName}&add_frequency_data=True`,
      HEADERS
    ).then(response => {
      const brandMetric = response.data[0];
      if (this.state.isMounted) {
        this.setState(() => ({
          brandMetric,
          loading: false
        }));
      }
    }).catch(error => {
      console.error('Error: failed to fetch brand metric');
      if (this.state.isMounted) {
        this.setState(() => ({
          brandMetric: {},
          loading: false
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  render () {
    return (
      <Modal
        show={this.props.isOpen}
        onHide={this.props.handleClose}
        onEntering={this.onOpen}
        size="lg"
      >
        <Modal.Body
          style={{
            borderRadius: '5px'
          }}
        >
          { this.state.loading &&
            <div className="m-4 text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !this.state.loading &&
            <MetricDistributionSummary
              metric={this.state.brandMetric}
              audienceProfileId={this.props.audienceProfileId}
              customerId={this.props.customerId}
            />
          }
        </Modal.Body>
      </Modal>
    );
  }
};
