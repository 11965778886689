import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import { BrandsMultiSelect } from '../../common/BrandsMultiSelect';
import DateRangePicker from '../../common/DateRangePicker';
import ExcessShareOfEverythingGraph from './ExcessShareOfEverythingGraph';
import MarketShareDataModal from './MarketShareDataModal';
import { round } from '../../../utils/numbers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class CategoryExcessShareOfEverything extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brandSelectOptions: [],
      selectedBrands: [],
      selectedBrand: undefined,
      selectedShareOf: 'shareOfEverything',
      selectedShareOfLabel: 'Share of Everything (%)',
      marketShareEqualsSearch: true,
      shareOfEverything: {},
      shareOfEarned: {},
      shareOfOwned: {},
      shareOfSearch: {},
      marketShare: {},
      shareOfEverythingLoading: false,
      brandData: [],
      selectedBrandData: undefined,
      marketShareDataModalOpen: false,
      earnedShareOfs: [
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-reviews',
          name: 'Share of Reviews',
          key: 'shareOfReviews',
          countKey: 'review_count',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-reviews',
          additionalParams: '&condition=gte&threshold=4',
          name: 'Share of 4 & 5 Star Reviews',
          key: 'shareOfGoodReviews',
          countKey: 'review_count',
          type: 'earned'
        },
        {
          endpoint: CONTENT_SCRAPING_ENDPOINT,
          pathname: '/api/category-share-of-employer-reviews',
          name: 'Share of Employer Reviews',
          key: 'shareOfEmployerReviews',
          countKey: 'review_count',
          type: 'earned'
        },
        {
          endpoint: CONTENT_SCRAPING_ENDPOINT,
          pathname: '/api/category-share-of-employer-reviews',
          additionalParams: '&condition=gte&threshold=4',
          name: 'Share of 4 & 5 Star Employer Reviews',
          key: 'shareOfGoodEmployerReviews',
          countKey: 'review_count',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-video-stats',
          name: 'Share of Youtube Followers',
          key: 'shareOfYoutubeFollowers',
          countKey: 'subscribers_count',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-youtube-share-of-net-followers',
          name: 'Share of Youtube Net New Followers',
          key: 'shareOfYoutubeNetNewFollowers',
          countKey: 'net_followers',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-video-stats',
          name: 'Share of Youtube Net New Views',
          key: 'shareOfYoutubeViews',
          countKey: 'net_views',
          type: 'earned'
        },
        {
          endpoint: CONTENT_SCRAPING_ENDPOINT,
          pathname: '/api/category-linkedin-share-of-followers',
          name: 'Share of LinkedIn Followers',
          key: 'shareOfLinkedInFollowers',
          countKey: 'followers',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-linkedin-share-of-net-followers',
          name: 'Share of LinkedIn Net New Followers',
          key: 'shareOfLinkedInNetNewFollowers',
          countKey: 'net_followers',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-linkedin-share-stats',
          name: 'Share of LinkedIn Engagement',
          key: 'shareOfLinkedInEngagement',
          countKey: 'engagement',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-twitter-share-of-followers',
          name: 'Share of Twitter Followers',
          key: 'shareOfTwitterFollowers',
          countKey: 'followers',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-twitter-share-of-net-followers',
          name: 'Share of Twitter Net New Followers',
          key: 'shareOfTwitterNetNewFollowers',
          countKey: 'net_followers',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-twitter-share-stats',
          name: 'Share of Twitter Mentions',
          key: 'shareOfTwitterMentions',
          countKey: 'mentions',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-twitter-share-stats',
          name: 'Share of Twitter Promoter Mentions',
          key: 'shareOfTwitterPromoterMentions',
          countKey: 'promoters',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-facebook-share-of-followers',
          name: 'Share of Facebook Followers',
          key: 'shareOfFacebookFollowers',
          countKey: 'followers',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-facebook-share-of-net-followers',
          name: 'Share of Facebook Net New Followers',
          key: 'shareOfFacebookNetNewFollowers',
          countKey: 'net_followers',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-instagram-share-of-followers',
          name: 'Share of Instagram Followers',
          key: 'shareOfInstagramFollowers',
          countKey: 'followers',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-instagram-share-of-net-followers',
          name: 'Share of Instagram Net New Followers',
          key: 'shareOfInstagramNetNewFollowers',
          countKey: 'net_followers',
          type: 'earned'
        },
        {
          endpoint: CONTENT_SCRAPING_ENDPOINT,
          pathname: '/api/category-share-of-news-mention',
          name: 'Share of News Mentions',
          key: 'shareOfNewsMentions',
          countKey: 'total_mentions',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-podcast-stats',
          name: 'Share of Podcast Mentions',
          key: 'shareOfPodcastMentions',
          countKey: 'mention_count',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-web-stats',
          name: 'Share of Organic Traffic',
          key: 'shareOfOrganicTraffic',
          countKey: 'organic_traffic',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-serp',
          name: 'Share of Google Organic Results',
          key: 'shareOfOrganicSearchResults',
          countKey: 'serp_points',
          type: 'earned',
          additionalParams: '&type=google-organic',
        },
      ],
      ownedShareOfs: [
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-blogs',
          name: 'Share of Blog Posts',
          key: 'shareOfBlogPosts',
          countKey: 'blog_count',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-linkedin-share-stats',
          name: 'Share of LinkedIn Posts',
          key: 'shareOfLinkedInPosts',
          countKey: 'post_count',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-twitter-share-stats',
          name: 'Share of Owned Tweets',
          key: 'shareOfOwnedTweets',
          countKey: 'owned_tweets',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-facebook-share-of-owned-posts',
          name: 'Share of Facebook Posts',
          key: 'shareOfFacebookPosts',
          countKey: 'facebook_owned_posts',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-new-videos',
          name: 'Share of Youtube New Videos',
          key: 'shareOfYoutubeNewVideos',
          countKey: 'new_video_count',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-podcast-stats',
          name: 'Share of Owned Podcast Episodes',
          key: 'shareOfOwnedPodcastEpisodes',
          countKey: 'owned_episode_count',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-webinars',
          name: 'Share of Webinars',
          key: 'shareOfWebinars',
          countKey: 'total_webinars',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-press-release-stats',
          name: 'Share of Press Releases',
          key: 'shareOfPressReleases',
          countKey: 'press_release_count',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-email-stats',
          name: 'Share of Emails',
          key: 'shareOfEmails',
          countKey: 'email_count',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-web-pages',
          name: 'Share of New Web Pages',
          key: 'shareOfNewWebPages',
          countKey: 'count_new',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-web-pages',
          name: 'Share of Modified Web Pages',
          key: 'shareOfModifiedWebPages',
          countKey: 'count_modified',
          type: 'owned'
        },
      ],
      paidShareOfs: [
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-serp',
          name: 'Share of Google Ad Impressions',
          key: 'shareOfPaidSearchResults',
          countKey: 'serp_points',
          type: 'paid',
          additionalParams: '&type=google-paid',
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-ads',
          name: 'Share of Google Adword Spend',
          key: 'shareOfGoogleAdwordSpend',
          countKey: 'adwords_costs',
          type: 'paid'
        }
      ],
    };
  }

  componentDidMount() {
    const brandSelectOptions = [];
    if (this.props.category && this.props.category.product_brands) {
      for (const brand of this.props.category.product_brands) {
        brandSelectOptions.push({
          label: brand.name,
          value: brand.id,
          brand,
        });
      }
    }
    this.setState(() => ({
      isMounted: true,
      brandSelectOptions,
      selectedBrands: brandSelectOptions,
    }));
    this.fetchShareOfEverything();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      const brandSelectOptions = [];
      if (this.props.category && this.props.category.product_brands) {
        for (const brand of this.props.category.product_brands) {
          brandSelectOptions.push({
            label: brand.name,
            value: brand.id,
            brand
          });
        }
      }
      this.setState(() => ({
        brandSelectOptions,
        selectedBrands: brandSelectOptions,
        selectedBrand: undefined,
        marketShareEqualsSearch: true,
        shareOfEverything: {},
        shareOfEarned: {},
        shareOfOwned: {},
        shareOfPaid: {},
        shareOfSearch: {},
        shareOfEverythingLoading: false,
        brandData: [],
        selectedBrandData: undefined,
      }));
      this.fetchShareOfEverything();
    }
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      this.fetchShareOfEverything();
    }
  };

  fetchShareOfEverything = () => {
    if (this.props.category) {
      this.setState(() => ({ shareOfEverythingLoading: true }));
      const shareOfs = this.state.earnedShareOfs.concat(this.state.ownedShareOfs).concat(this.state.paidShareOfs);
      const formattedStartDate = this.props.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.props.endDate.format('YYYY-MM-DD HH:mm:ss');
      const shareOfEverythingRequests = [];

      let defaultShare = 0;
      if (this.props.category.product_brands.length > 0) {
        defaultShare = round(100/this.props.category.product_brands.length, 2);
      }

      // fetch share of search
      shareOfEverythingRequests.push(
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/google-trend-category-share?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
          HEADERS
        ).then(response => {
          const shareOfSearch = {};
          for (const [brandName, value] of Object.entries(response.data)) {
            if (value == null) {
              shareOfSearch[brandName] = defaultShare;
            } else if (value > 50){
              shareOfSearch[brandName] = 50;
            } else {
              shareOfSearch[brandName] = value;
            }
          }
          return { shareOfSearch };
        }).catch(error => {
          console.error('Error: failed to fetch share of search.');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          const shareOfSearch = {}
          for (const b of this.props.category.product_brands) {
            shareOfSearch[b.name] = defaultShare;
          }
          return { shareOfSearch };
        })
      );

      for (const s of shareOfs) {
        this.setState(() => ({ [`${s.key}Loading`]: true }));
        const additionalParams = s.additionalParams || '';
        shareOfEverythingRequests.push(
          axios.get(
            `${s.endpoint}${s.pathname}?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}&group_by=custom${additionalParams}`,
            HEADERS
          ).then(response => {
            const shareOfAttributes = this.getShareOfAttributes(
              s.name,
              s.type,
              response.data,
              s.countKey
            );

            if (this.state.isMounted) {
              this.setState(() => ({
                [s.key]: shareOfAttributes,
                [`${s.key}Loading`]: false,
              }));
            }
            return { key: s.key, [s.key]: shareOfAttributes };
          }).catch(error => {
            console.error(`Error: unable to load Category ${s.name}...`);
            if (this.state.isMounted) {
              this.setState(() => ({
                [s.key]: {
                  name: s.name,
                  type: s.type,
                  highest_count: 0,
                  total: 0
                },
                [`${s.key}Loading`]: false,
              }));
            }
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return {
              key: s.key,
              [s.key]: {
                name: s.name,
                type: s.type,
                highest_count: 0,
                total: 0
              }
            };
          })
        );
      }

      Promise.all(shareOfEverythingRequests).then(responses => {
        const shareOfEverything = {
          total_percent: 0,
          total_share_of: 0
        };
        const shareOfEarned = {
          total_percent: 0,
          total_share_of: 0
        };
        const shareOfOwned = {
          total_percent: 0,
          total_share_of: 0
        };
        const shareOfPaid = {
          total_percent: 0,
          total_share_of: 0
        };
        let shareOfSearch = {};
        let marketShare = {};

        const respIgnoredKeys = ['name', 'type', 'total', 'highest_count'];
        const aggIgnoredKeys = ['total_percent', 'total_share_of'];
        for (const response of responses) {
          if (response && response.key) {
            // add totals for share of everything/eared/owned
            shareOfEverything.total_share_of += 1;
            if (response[response.key].type === 'earned') {
              shareOfEarned.total_share_of += 1;
            } else if (response[response.key].type === 'owned') {
              shareOfOwned.total_share_of += 1;
            } else if (response[response.key].type === 'paid') {
              shareOfPaid.total_share_of += 1;
            }

            // add values for share of everything/eared/owned
            for (const [statKey, statValue] of Object.entries(response[response.key])) {
              if (!respIgnoredKeys.includes(statKey)) {
                if (shareOfEverything[statKey]) {
                  shareOfEverything[statKey].total_percent += statValue.percent;
                } else {
                  shareOfEverything[statKey] = {
                    total_percent: statValue.percent
                  }
                }

                if (response[response.key].type === 'earned') {
                  if (shareOfEarned[statKey]) {
                    shareOfEarned[statKey].total_percent += statValue.percent;
                  } else {
                    shareOfEarned[statKey] = {
                      total_percent: statValue.percent
                    }
                  }
                } else if (response[response.key].type === 'owned') {
                  if (shareOfOwned[statKey]) {
                    shareOfOwned[statKey].total_percent += statValue.percent;
                  } else {
                    shareOfOwned[statKey] = {
                      total_percent: statValue.percent,
                    }
                  }
                } else if (response[response.key].type === 'paid') {
                  if (shareOfPaid[statKey]) {
                    shareOfPaid[statKey].total_percent += statValue.percent;
                  } else {
                    shareOfPaid[statKey] = {
                      total_percent: statValue.percent,
                    }
                  }
                }
              }
            }
          } else if (response && response.shareOfSearch) {
            shareOfSearch = response.shareOfSearch;
            marketShare = response.shareOfSearch;
          }
        }
        const brandsCount = this.props.category.product_brands.length;
        // loop everything to determine percents
        for (const [key, value] of Object.entries(shareOfEverything)) {
          if (!aggIgnoredKeys.includes(key)) {
            if (shareOfEverything.total_share_of) {
              shareOfEverything[key].percent = round((value.total_percent/shareOfEverything.total_share_of), 1);
              // add total percent and re-average
              shareOfEverything.total_percent += shareOfEverything[key].percent;
              shareOfEverything.average_percent = round((shareOfEverything.total_percent/brandsCount), 1);
            } else {
              shareOfEverything[key].percent = 0;
            }
          }
        }
        // loop earned to determine percents
        for (const [key, value] of Object.entries(shareOfEarned)) {
          if (!aggIgnoredKeys.includes(key)) {
            if (shareOfEarned.total_share_of) {
              shareOfEarned[key].percent = round((value.total_percent/shareOfEarned.total_share_of), 1);
              // add total percent and re-average
              shareOfEarned.total_percent += shareOfEarned[key].percent;
              shareOfEarned.average_percent = round((shareOfEarned.total_percent/brandsCount), 1);
            } else {
              shareOfEarned[key].percent = 0;
            }
          }
        }
        // loop owned to determine percents
        for (const [key, value] of Object.entries(shareOfOwned)) {
          if (!aggIgnoredKeys.includes(key)) {
            if (shareOfOwned.total_share_of) {
              shareOfOwned[key].percent = round((value.total_percent/shareOfOwned.total_share_of), 1);
              // add total percent and re-average
              shareOfOwned.total_percent += shareOfOwned[key].percent;
              shareOfOwned.average_percent = round((shareOfOwned.total_percent/brandsCount), 1);
            } else {
              shareOfOwned[key].percent = 0;
            }
          }
        }
        // loop paid to determine percents
        for (const [key, value] of Object.entries(shareOfPaid)) {
          if (!aggIgnoredKeys.includes(key)) {
            if (shareOfPaid.total_share_of) {
              shareOfPaid[key].percent = round((value.total_percent/shareOfPaid.total_share_of), 1);
              // add total percent and re-average
              shareOfPaid.total_percent += shareOfPaid[key].percent;
              shareOfPaid.average_percent = round((shareOfPaid.total_percent/brandsCount), 1);
            } else {
              shareOfPaid[key].percent = 0;
            }
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            shareOfEverything,
            shareOfEarned,
            shareOfOwned,
            shareOfPaid,
            shareOfSearch,
            marketShare,
            shareOfEverythingLoading: false,
          }), () => this.onBrandSelectChange(this.state.selectedBrands));
        }
      });
    }
  };

  getShareOfAttributes = (name, type, data, countKey) => {
    const categoryShareOfAttributes = { name, type };
    const stats = data.length > 0 ? data[0].product_brands : [];
    let total = 0;
    // get total for percent
    for (const brandStats of stats) {
      total += brandStats[countKey];
    }
    categoryShareOfAttributes.total = total;

    // get highest count and set percent
    categoryShareOfAttributes.highest_count = 0;
    for (const brandStats of stats) {
      categoryShareOfAttributes[brandStats.product_brand_name] = brandStats;
      categoryShareOfAttributes[brandStats.product_brand_name].count = brandStats[countKey];
      // highest count
      if (brandStats[countKey] > categoryShareOfAttributes.highest_count) {
        categoryShareOfAttributes.highest_count = brandStats[countKey];
      }
      // percent
      if (total) {
        categoryShareOfAttributes[brandStats.product_brand_name].percent = round((brandStats[countKey]/total)*100, 1);
      } else {
        categoryShareOfAttributes[brandStats.product_brand_name].percent = 0;
      }
    }
    return categoryShareOfAttributes;
  };

  onBrandSelectChange = (selectedBrands) => {
    // select user brand if all brands is selected
    let selectedBrand;
    for (const b of selectedBrands) {
      if (b.brand.company_id === this.props.user.customerId) {
        selectedBrand = b.brand;
        break
      }
    }
    // user brand not found for all brands, so use first one
    if (!selectedBrand) {
      selectedBrand = selectedBrands.length > 0 ?
        selectedBrands[0].brand :
        undefined;
    }
    this.setState(
      () => ({
        selectedBrands,
        selectedBrand
      }),
      () => this.setBrandData()
    );
  };

  onShareOfChange = (event) => {
    const selectedShareOf = event.currentTarget.value;
    let selectedShareOfLabel = '';
    if (selectedShareOf === 'shareOfEverything') {
      selectedShareOfLabel = 'Share of Everything (%)';
    } else if (selectedShareOf === 'shareOfEarned') {
      selectedShareOfLabel = 'Share of Earned (%)';
    } else if (selectedShareOf === 'shareOfOwned') {
      selectedShareOfLabel = 'Share of Owned (%)';
    } else if (selectedShareOf === 'shareOfPaid') {
      selectedShareOfLabel = 'Share of Paid (%)';
    } else if (selectedShareOf === 'shareOfSearch') {
      selectedShareOfLabel = 'Share of Search (%)';
    }
    this.setState(() => ({
      selectedShareOf,
      selectedShareOfLabel
    }));
  };

  onShareOfSearchChange = (marketShareEqualsSearch) => {
    if (marketShareEqualsSearch) {
      this.setState(() => ({
        marketShareEqualsSearch,
        marketShare: this.state.shareOfSearch
      }), () => this.setBrandData());
    } else {
      this.setState(() => ({ marketShareEqualsSearch }));
    }
  };

  setBrandData = () => {
    const brandData = [];
    let selectedBrandData;
    for (const b of this.state.selectedBrands) {
      const brandEverythingPercent = this.state.shareOfEverything[b.label] ? this.state.shareOfEverything[b.label].percent : 0;
      const brandEarnedPercent = this.state.shareOfEarned[b.label] ? this.state.shareOfEarned[b.label].percent : 0;
      const brandOwnedPercent = this.state.shareOfOwned[b.label] ? this.state.shareOfOwned[b.label].percent : 0;
      const brandPaidPercent = this.state.shareOfPaid[b.label] ? this.state.shareOfPaid[b.label].percent : 0;
      const bd = {
        name: b.label,
        shareOfEverything: brandEverythingPercent,
        shareOfEarned: brandEarnedPercent,
        shareOfOwned: brandOwnedPercent,
        shareOfPaid: brandPaidPercent,
        shareOfSearch: this.state.shareOfSearch[b.label],
        marketShare: this.state.marketShare[b.label],
        shareOfEverythingDelta: round(brandEverythingPercent - this.state.marketShare[b.label], 1),
        shareOfEarnedDelta: round(brandEarnedPercent - this.state.marketShare[b.label], 1),
        shareOfOwnedDelta: round(brandOwnedPercent - this.state.marketShare[b.label], 1),
        shareOfPaidDelta: round(brandPaidPercent - this.state.marketShare[b.label], 1),
        shareOfSearchDelta: round(this.state.shareOfSearch[b.label] - this.state.marketShare[b.label], 1),
      }
      brandData.push(bd);
      if (b.brand.id === this.state.selectedBrand.id) {
        selectedBrandData = bd;
      }
    }
    this.setState(() => ({
      brandData,
      selectedBrandData,
    }));
  }

  openMarketShareDataModal = () => {
    this.setState(() => ({ marketShareDataModalOpen: true }));
  };

  closeMarketShareDataModal = () => {
    this.setState(() => ({ marketShareDataModalOpen: false }));
  };

  updateMarketShare = (marketShare) => {
    this.setState(
      () => ({ marketShare }),
      () => this.setBrandData()
    );
  };

  render () {
    return (
      <div className="m-4">
        <div className="p-2 bg-bops-blue">
          <div className="d-inline-block" style={{ width: '250px' }}>
            <BrandsMultiSelect
              options={this.state.brandSelectOptions}
              value={this.state.selectedBrands}
              onChange={this.onBrandSelectChange}
              placeholder="Select Brands..."
            />
          </div>
          <div className="d-inline-block ml-4">
            <Form.Control
              as="select"
              value={this.state.selectedShareOf}
              onChange={this.onShareOfChange}
            >
              <option value="shareOfEverything">Share Of Everything</option>
              <option value="shareOfEarned">Share Of Earned</option>
              <option value="shareOfOwned">Share Of Owned</option>
              <option value="shareOfPaid">Share Of Paid</option>
              <option value="shareOfSearch">Share Of Search</option>
            </Form.Control>
          </div>
          <div className="d-inline-block ml-4">
            <DateRangePicker
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              updateDates={this.props.updateDates}
              size="normal"
            />
          </div>
          <div className="d-inline-block ml-4 pl-4 text-light">
            <div className="d-inline-block mr-3 font-weight-bold">
              Market Share
            </div>
            <Form.Check
              type="radio"
              label="Use Share of Search"
              checked={this.state.marketShareEqualsSearch===true}
              onChange={() => this.onShareOfSearchChange(true)}
              inline
            />
            <Form.Check
              type="radio"
              label="Set Manually"
              checked={this.state.marketShareEqualsSearch===false}
              onChange={() => this.onShareOfSearchChange(false)}
              onClick={() => this.openMarketShareDataModal()}
              inline
            />
          </div>
          <Row>
            <Col>

            </Col>
            <Col>

            </Col>
          </Row>
        </div>
        <div className="mt-4">
          <Row>
            <Col xs={8}>
              <ExcessShareOfEverythingGraph
                yAxisKey={this.state.selectedShareOf}
                yAxisLabel={this.state.selectedShareOfLabel}
                data={this.state.brandData}
                category={this.props.category}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
              />
            </Col>
            <Col xs={4}>
              <div>
                <h5>Is your brand set up to grow, lose, or maintain market share?</h5>
                <p>
                  The dashed line represents the equilibrium - where 'share of x'
                  and market share are equal. This is the level where a brand
                  neither gains nor loses market share.
                </p>
                <hr />
                <p>
                  Market research indicates that a +10 point difference between
                  Share of Voice and Share of Market leads to 0.5% of extra
                  market share growth over a year. Note that brand leaders
                  achieve 1.4% of market share growth per 10% of ESoE, compared
                  to 0.4% for challenger brands.
                </p>
              </div>
              <div className="mt-4">
                { this.state.shareOfEverythingLoading &&
                  <div className="m-4 text-center">
                    <ClipLoader size={100}/>
                  </div>
                }
                {/*
                  { !this.state.shareOfEverythingLoading &&
                    <div>
                      { (this.state.selectedBrand && this.state.selectedBrandData) &&
                        <Card>
                          <Card.Header className="bg-bops-blue text-light font-weight-bold">
                            {this.state.selectedBrand.name}
                          </Card.Header>
                          <Card.Body>
                            <div style={{ fontSize: '.875rem' }}>
                              <table style={{ width: '100%' }}>
                                <thead>
                                  <tr>
                                    <th>Share Of</th>
                                    <th>Delta</th>
                                    <th>Effect on Market Share</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>So Everything</td>
                                    <td>{this.state.selectedBrandData.shareOfEverythingDelta}</td>
                                    <td>{`${round(this.state.selectedBrandData.shareOfEverythingDelta * .005, 1)}%`}</td>
                                  </tr>
                                  <tr>
                                    <td>So Earned</td>
                                    <td>{this.state.selectedBrandData.shareOfEarnedDelta}</td>
                                    <td>{`${round(this.state.selectedBrandData.shareOfEarnedDelta * .005, 1)}%`}</td>
                                  </tr>
                                  <tr>
                                    <td>So Owned</td>
                                    <td>{this.state.selectedBrandData.shareOfOwnedDelta}</td>
                                    <td>{`${round(this.state.selectedBrandData.shareOfOwnedDelta * .005, 1)}%`}</td>
                                  </tr>
                                  <tr>
                                    <td>So Search</td>
                                    <td>{this.state.selectedBrandData.shareOfSearchDelta}</td>
                                    <td>{`${round(this.state.selectedBrandData.shareOfSearchDelta * .005, 1)}%`}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </Card.Body>
                        </Card>
                      }
                    </div>
                  }
                */}
              </div>
            </Col>
          </Row>
        </div>

        <MarketShareDataModal
          isOpen={this.state.marketShareDataModalOpen}
          handleClose={this.closeMarketShareDataModal}
          marketShare={this.state.marketShare}
          marketShareLoading={this.state.shareOfEverythingLoading}
          updateMarketShare={this.updateMarketShare}
        />
      </div>
    );
  }
};
