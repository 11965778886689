import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ClipLoader from 'react-spinners/ClipLoader';
import SkipListModal from './SkipListModal';
import RequestNewMonitoredCompetitorModal from './RequestNewMonitoredCompetitorModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faTimes, faLink, faImage } from '@fortawesome/free-solid-svg-icons';
import { getDomainFromUrl } from '../../../utils/urls';
import { sortAlphabeticalByKey } from '../../../utils/sorts';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class CategoryPotentialCompetitors extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      potentialCompetitorsData: [],
      potentialCompetitorsSkipList: [],
      potentialCompetitorsDetectedList: [],
      potentialCompetitors: [],
      potentialCompetitorsDataLoading: false,
      selectedDetectedCompetitorId: undefined,
      selectedDetectedCompetitor: undefined,
      selectedPotentialCompetitorId: undefined,
      selectedPotentialCompetitor: undefined,
      skipListModalOpen: false,
      updateSkipListLoading: false,
      requestNewMonitoredCompetitorModalOpen: false,

    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchPotentialCompetitorsData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      this.fetchPotentialCompetitorsData();
    }
  };

  fetchPotentialCompetitorsData = () => {
    if (this.props.category) {
      this.setState(() => ({ potentialCompetitorsDataLoading: true }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/potential-competitors?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const potentialCompetitorsData = response.data;
        const potentialCompetitorsSkipList = [];
        const potentialCompetitorsDetectedList = [];
        const potentialCompetitors = [];
        const monitoredCompetitorDomainMap = {};
        for (const pb of this.props.category.product_brands) {
          const productBrandDomain = getDomainFromUrl(pb.product_url);
          if (productBrandDomain) {
            monitoredCompetitorDomainMap[productBrandDomain] = true;
          }
          const companyDomain = getDomainFromUrl(pb.company_url);
          if (companyDomain) {
            monitoredCompetitorDomainMap[companyDomain] = true;
          }
        }

        for (let pc of potentialCompetitorsData) {
          const pcDomain = getDomainFromUrl(pc.potential_competitor_domain);
          // filter potential competitors that are already monitored
          if (!monitoredCompetitorDomainMap[pcDomain]) {
            if (pc.competitive_status === 'not_competitor') {
              potentialCompetitorsSkipList.push(pc);
            } else if (pc.competitive_status === 'competitor') {
              potentialCompetitors.push(pc);
            } else {
              potentialCompetitorsDetectedList.push(pc);
            }
          }
        }
        // sort lists alphabetically
        potentialCompetitorsSkipList.sort(sortAlphabeticalByKey('potential_competitor_domain'));
        potentialCompetitorsDetectedList.sort(sortAlphabeticalByKey('potential_competitor_domain'));
        potentialCompetitors.sort(sortAlphabeticalByKey('potential_competitor_domain'));

        if (this.state.isMounted) {
          this.setState(() => ({
            potentialCompetitorsData,
            potentialCompetitorsSkipList,
            potentialCompetitorsDetectedList,
            potentialCompetitors,
            potentialCompetitorsDataLoading: false
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch category potential competitors data...`);
        if (this.state.isMounted) {
          this.setState(() => ({
            potentialCompetitorsData: [],
            potentialCompetitorsSkipList: [],
            potentialCompetitorsDetectedList: [],
            potentialCompetitors: [],
            potentialCompetitorsDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  openSkipListModal = () => {
    this.setState(() => ({ skipListModalOpen: true }));
  };

  closeSkipListModal = () => {
    this.setState(() => ({ skipListModalOpen: false }));
  };

  openRequestNewMonitoredCompetitorModal = () => {
    this.setState(() => ({ requestNewMonitoredCompetitorModalOpen: true }));
  };

  closeRequestNewMonitoredCompetitorModal = () => {
    this.setState(() => ({ requestNewMonitoredCompetitorModalOpen: false }));
  };

  onPotentialCompetitorSelect = (selectedPotentialCompetitor) => {
    if (selectedPotentialCompetitor.id === this.state.selectedPotentialCompetitorId) {
      this.setState(() => ({
        selectedPotentialCompetitorId: undefined,
        selectedPotentialCompetitor: undefined,
      }));
    } else {
      this.setState(() => ({
        selectedPotentialCompetitorId: selectedPotentialCompetitor.id,
        selectedPotentialCompetitor,
      }));
    }
  };

  onDetectedCompetitorSelect = (selectedDetectedCompetitor) => {
    if (selectedDetectedCompetitor.id === this.state.selectedDetectedCompetitorId) {
      this.setState(() => ({
        selectedDetectedCompetitorId: undefined,
        selectedDetectedCompetitor: undefined,
      }));
    } else {
      this.setState(() => ({
        selectedDetectedCompetitorId: selectedDetectedCompetitor.id,
        selectedDetectedCompetitor,
      }));
    }
  };

  updatePotentialCompetitor = (fromGroup, toGroup) => {
    if (fromGroup === 'detected' && toGroup === 'competitor') {
      if (this.state.selectedDetectedCompetitor) {
        axios.put(
          `${CONTENT_SCRAPING_ENDPOINT}/api/potential-competitors/${this.state.selectedDetectedCompetitor.id}`,
          { competitive_status: 'competitor' },
          HEADERS
        ).then(response => {
          this.setState((prevState) => {
            let potentialCompetitorsDetectedList = prevState.potentialCompetitorsDetectedList;
            potentialCompetitorsDetectedList = potentialCompetitorsDetectedList.filter(pc => pc.id !== this.state.selectedDetectedCompetitor.id);
            let potentialCompetitors = prevState.potentialCompetitors;
            potentialCompetitors.push(this.state.selectedDetectedCompetitor);
            potentialCompetitors.sort(sortAlphabeticalByKey('potential_competitor_domain'));
            return ({
              potentialCompetitorsDetectedList,
              potentialCompetitors,
              selectedDetectedCompetitorId: undefined,
              selectedDetectedCompetitor: undefined,
            });
          });
        }).catch(error => {
          console.error('Error: failed to update potential competitor status');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    } else if (fromGroup === 'competitor' && toGroup === 'detected') {
      if (this.state.selectedPotentialCompetitor) {
        axios.put(
          `${CONTENT_SCRAPING_ENDPOINT}/api/potential-competitors/${this.state.selectedPotentialCompetitor.id}`,
          { competitive_status: 'detected' },
          HEADERS
        ).then(response => {
          this.setState((prevState) => {
            let potentialCompetitors = prevState.potentialCompetitors;
            potentialCompetitors = potentialCompetitors.filter(pc => pc.id !== this.state.selectedPotentialCompetitor.id);
            let potentialCompetitorsDetectedList = prevState.potentialCompetitorsDetectedList;
            potentialCompetitorsDetectedList.push(this.state.selectedPotentialCompetitor);
            potentialCompetitorsDetectedList.sort(sortAlphabeticalByKey('potential_competitor_domain'));
            return ({
              potentialCompetitors,
              potentialCompetitorsDetectedList,
              selectedPotentialCompetitorId: undefined,
              selectedPotentialCompetitor: undefined,
            });
          });
        }).catch(error => {
          console.error('Error: failed to update potential competitor status');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  updateNotCompetitor = (potentialCompetitor) => {
    if (potentialCompetitor) {
      axios.put(
        `${CONTENT_SCRAPING_ENDPOINT}/api/potential-competitors/${potentialCompetitor.id}`,
        { competitive_status: 'not_competitor' },
        HEADERS
      ).then(response => {
        this.setState((prevState) => {
          let potentialCompetitorsDetectedList = prevState.potentialCompetitorsDetectedList;
          potentialCompetitorsDetectedList = potentialCompetitorsDetectedList.filter(pc => pc.id !== potentialCompetitor.id);
          let potentialCompetitorsSkipList = prevState.potentialCompetitorsSkipList;
          potentialCompetitorsSkipList.push(potentialCompetitor);
          potentialCompetitorsSkipList.sort(sortAlphabeticalByKey('potential_competitor_domain'));
          return ({
            potentialCompetitorsDetectedList,
            potentialCompetitorsSkipList,
            selectedDetectedCompetitorId: undefined,
            selectedDetectedCompetitor: undefined,
          });
        });
      }).catch(error => {
        console.error('Error: failed to update potential competitor status');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  updateSkipListToPotentialCompetitors = (potentialCompetitors) => {
    if (potentialCompetitors.length > 0) {
      this.setState(() => ({ updateSkipListLoading: true }));
      const updatePotentialCompetitorRequests = [];
      for (const pc of potentialCompetitors) {
        updatePotentialCompetitorRequests.push(
          axios.put(
            `${CONTENT_SCRAPING_ENDPOINT}/api/potential-competitors/${pc.id}`,
            { competitive_status: 'competitor' },
            HEADERS
          ).then(response => {
            return {
              potenticalCompetitor: pc,
              updated: true
            };
          }).catch(error => {
            console.error('Error: failed to update potential competitor status');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return {
              potenticalCompetitor: pc,
              updated: false
            };
          })
        );
      }

      Promise.all(updatePotentialCompetitorRequests).then(responses => {
        const newPotentialCompetitors = [];
        const newPotentialCompetitorsIds = [];
        for (const response of responses) {
          if (response.updated) {
            newPotentialCompetitors.push(response.potenticalCompetitor);
            newPotentialCompetitorsIds.push(response.potenticalCompetitor.id);
          }
        }

        if (this.state.isMounted) {
          this.setState((prevState) => {
            let potentialCompetitorsSkipList = prevState.potentialCompetitorsSkipList;
            potentialCompetitorsSkipList = potentialCompetitorsSkipList.filter(pc => !newPotentialCompetitorsIds.includes(pc.id));
            const potentialCompetitors = [
              ...prevState.potentialCompetitors,
              ...newPotentialCompetitors
            ];
            potentialCompetitors.sort(sortAlphabeticalByKey('potential_competitor_domain'));
            return ({
              potentialCompetitors,
              potentialCompetitorsSkipList,
              updateSkipListLoading: false,
              skipListModalOpen: false,
            })
          });
        }
      });
    }
  }

  render () {
    return (
      <div className="m-4">
        { this.props.category &&
          <div>
            { this.state.potentialCompetitorsDataLoading &&
              <div className="m-4 text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.potentialCompetitorsDataLoading &&
              <Row noGutters>
                <Col xs={3}>
                  <div className="mb-4 text-center font-weight-bold">
                    Competitors Monitored
                  </div>
                  <div
                    className="pre-scrollable bg-white border rounded"
                    style={{
                      minHeight: '400px',
                      maxHeight: '400px',
                      overflow: 'auto',
                    }}
                  >
                    { this.props.category.product_brands &&
                      <React.Fragment>
                        { this.props.category.product_brands.map((pb, i) => {
                            return (
                              <Row noGutters>
                                <Col style={{ flex: '0 0 20px' }}>
                                  <div className="mx-2">
                                    { pb.company_favicon_url &&
                                      <img
                                        src={pb.company_favicon_url}
                                        alt=""
                                        crossOrigin="anonymous"
                                        width="16px"
                                        onError={(img) => {
                                          img.target.src='/images/image.png';
                                        }}
                                      />
                                    }
                                    { !pb.company_favicon_url &&
                                      <FontAwesomeIcon
                                        icon={faImage}
                                        color="#b3b3b3"
                                      />
                                    }
                                  </div>
                                </Col>
                                <Col className="ellipsis">
                                  <div className="ellipsis">
                                    {pb.name}
                                  </div>
                                </Col>
                              </Row>
                            )
                          })
                        }
                      </React.Fragment>
                    }
                  </div>
                </Col>
                <Col xs={1} className="d-flex align-items-center justify-content-center">
                  <div>
                    <Button
                      variant="primary"
                      style={{
                        borderRadius: '50%'
                      }}
                      onClick={() => this.openRequestNewMonitoredCompetitorModal()}
                      disabled={!this.state.selectedPotentialCompetitorId}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="mb-4 text-center font-weight-bold">
                    Potential Competitors
                  </div>
                  <div
                    className="pre-scrollable bg-white border rounded"
                    style={{
                      minHeight: '400px',
                      maxHeight: '400px',
                      overflow: 'auto',
                    }}
                  >
                    { this.state.potentialCompetitors.map((pc, i) => {
                        return (
                          <div
                            key={`cpc-pc-${i}`}
                            className={
                              this.state.selectedPotentialCompetitorId === pc.id ?
                                'px-2 bg-primary text-light rounded' :
                                'px-2'
                            }
                          >
                            <Row noGutters>
                              <Col style={{ flex: '0 0 25px' }}>
                                { pc.domain_favicon_url &&
                                  <img
                                    src={pc.domain_favicon_url}
                                    alt=""
                                    crossOrigin="anonymous"
                                    width="20px"
                                    onError={(img) => {
                                      img.target.src='/images/image.png';
                                    }}
                                  />
                                }
                                { !pc.domain_favicon_url &&
                                  <FontAwesomeIcon
                                    icon={faImage}
                                    color="#b3b3b3"
                                  />
                                }
                              </Col>
                              <Col className="ellipsis">
                                <div
                                  className="ellipsis"
                                  onClick={() => this.onPotentialCompetitorSelect(pc)}
                                  style={{
                                    cursor: 'pointer'
                                  }}
                                >
                                  {pc.potential_competitor_domain}
                                </div>
                              </Col>
                              <Col style={{ flex: '0 0 20px' }}>
                                <FontAwesomeIcon
                                  icon={faLink}
                                  color={
                                    this.state.selectedPotentialCompetitorId === pc.id ?
                                      '#ffffff' :
                                      '#4fa2f3'
                                  }
                                  onClick={() => window.open(`https://${pc.potential_competitor_domain}`, '_blank')}
                                  style={{
                                    fontSize: '.875rem',
                                    cursor: 'pointer'
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>
                        )
                      })
                    }
                  </div>
                </Col>
                <Col xs={1} className="d-flex align-items-center justify-content-center">
                  <div>
                    <div className="mb-3">
                      <Button
                        variant="primary"
                        style={{
                          borderRadius: '50%'
                        }}
                        onClick={() => this.updatePotentialCompetitor('detected', 'competitor')}
                        disabled={!this.state.selectedDetectedCompetitorId}
                      >
                        <FontAwesomeIcon icon={faArrowLeft} />
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="primary"
                        style={{
                          borderRadius: '50%'
                        }}
                        onClick={() => this.updatePotentialCompetitor('competitor', 'detected')}
                        disabled={!this.state.selectedPotentialCompetitorId}
                      >
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="text-center">
                    <div className="font-weight-bold">
                      Recently Noticed
                    </div>
                    <div>
                      <span
                        style={{
                          fontSize: '.75rem',
                          cursor: 'pointer'
                        }}
                        onClick={() => this.openSkipListModal()}
                      >
                        <u>See Skip List</u>
                      </span>
                    </div>
                  </div>
                  <div
                    className="pre-scrollable bg-white border rounded"
                    style={{
                      minHeight: '400px',
                      maxHeight: '400px',
                      overflow: 'auto',
                    }}
                  >
                    { this.state.potentialCompetitorsDetectedList.map((pcd, i) => {
                        return (
                          <div
                            key={`cpc-pcd-${i}`}
                            className={
                              this.state.selectedDetectedCompetitorId === pcd.id ?
                                'px-2 bg-primary text-light rounded' :
                                'px-2'
                            }
                          >
                            <Row noGutters>
                              <Col style={{ flex: '0 0 25px' }}>
                                { pcd.domain_favicon_url &&
                                  <img
                                    src={pcd.domain_favicon_url}
                                    alt=""
                                    crossOrigin="anonymous"
                                    width="20px"
                                    onError={(img) => {
                                      img.target.src='/images/image.png';
                                    }}
                                  />
                                }
                                { !pcd.domain_favicon_url &&
                                  <FontAwesomeIcon
                                    icon={faImage}
                                    color="#b3b3b3"
                                  />
                                }
                              </Col>
                              <Col className="ellipsis">
                                <div
                                  className="ellipsis"
                                  onClick={() => this.onDetectedCompetitorSelect(pcd)}
                                  style={{
                                    cursor: 'pointer'
                                  }}
                                >
                                  {pcd.potential_competitor_domain}
                                </div>
                              </Col>
                              <Col style={{ flex: '0 0 40px' }}>
                                <FontAwesomeIcon
                                  className="mr-2"
                                  icon={faLink}
                                  color={
                                    this.state.selectedDetectedCompetitorId === pcd.id ?
                                      '#ffffff' :
                                      '#4fa2f3'
                                  }
                                  onClick={() => window.open(`https://${pcd.potential_competitor_domain}`, '_blank')}
                                  style={{
                                    fontSize: '.875rem',
                                    cursor: 'pointer'
                                  }}
                                />
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  color="#dc3545"
                                  onClick={() => this.updateNotCompetitor(pcd)}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Col>
                            </Row>
                          </div>
                        )
                      })
                    }
                  </div>
                </Col>
              </Row>
            }
          </div>
        }

        <SkipListModal
          isOpen={this.state.skipListModalOpen}
          handleClose={this.closeSkipListModal}
          potentialCompetitorsSkipList={this.state.potentialCompetitorsSkipList}
          updateSkipListToPotentialCompetitors={this.updateSkipListToPotentialCompetitors}
          updateSkipListLoading={this.state.updateSkipListLoading}
        />
        <RequestNewMonitoredCompetitorModal
          isOpen={this.state.requestNewMonitoredCompetitorModalOpen}
          handleClose={this.closeRequestNewMonitoredCompetitorModal}
          user={this.props.user}
          categories={this.props.categories}
          category={this.props.category}
          potentialCompetitor={this.state.selectedPotentialCompetitor}
        />
      </div>
    );
  }
};
