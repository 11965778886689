import { connect } from 'react-redux';
import HubspotSetup from '../../../components/settings/contentCollectors/HubspotSetup';

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(HubspotSetup);
