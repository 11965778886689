const nodeEnv = process.env.NODE_ENV || 'development';
const axios = require('axios');
const moment = require('moment');
const Cookies = require('universal-cookie');
const { LISTEN_ENDPOINT, HEADERS } = require('./constants');
const { dispatchUserLogout } = require('../actions/user/user');
const { dispatchReportError } = require('../actions/api/errors');

export const userStatusCheck = (user) => {
  const cookies = new Cookies();
  const statusLastCheckedEpoch = cookies.get('statusLastChecked');
  let checkStatus = true;
  if (statusLastCheckedEpoch) {
    const now = moment();
    const statusLastChecked = moment.unix(statusLastCheckedEpoch);
    // if status last checked is less than 1 hour (3600 seconds)
    if (now.diff(statusLastChecked, 'seconds') < 3600) {
      checkStatus = false;
    }
  }

  if (checkStatus) {
    axios.get(
      `${LISTEN_ENDPOINT}/api/customer-user-status?customer_user_id=${user.id}`,
      HEADERS
    ).then(response => {
      if (
        response.data &&
        response.data.status &&
        (response.data.status === 'active' || response.data.status === 'trial') &&
        !response.data.sso_login_expired
      ) {
        cookies.set('statusLastChecked', moment().unix(), { path: '/', maxAge: 3600 });
      } else {
        dispatchUserLogout(user);
      }
    }).catch(error => {
      console.error('Error: unable to check customer status...');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  }
}

export const createUserActivityLog = (user, action, category, brand) => {
  if (
    nodeEnv === 'production' &&
    user &&
    user.id &&
    action
  ) {
    // check if user status is still valid
    userStatusCheck(user);

    const userActivityLog = {
      customer_user_id: user.id,
      action_at: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
      action
    };
    if (category) {
      userActivityLog.active_category_name = category.name;
      userActivityLog.active_category_type = category.category_type;
      userActivityLog.active_category_id = category.id;
    }
    if (brand) {
      userActivityLog.active_brand_name = brand.name;
      userActivityLog.active_brand_id = brand.id;
    }

    axios.post(
      `${LISTEN_ENDPOINT}/api/user-activity-logs`,
      userActivityLog,
      HEADERS
    ).catch(error => {
      console.error('Error: unable to create user activity log...');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  }
}
