import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import LoginContainer from '../../containers/common/LoginContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAd,
  faAward,
  faCalendarCheck,
  faChartBar,
  faClipboardList,
  faCloudDownloadAlt,
  faCopyright,
  faCubes,
  faEnvelope,
  faExclamationTriangle,
  faExpand,
  faFire,
  faGlobeAmericas,
  faKey,
  faLayerGroup,
  faLink,
  faLock,
  faMoneyCheckAlt,
  faNewspaper,
  faSearch,
  faTasks,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import {
  faGoogle,
  faHubspot,
  faMailchimp,
  faSlack,
  faSpotify
} from '@fortawesome/free-brands-svg-icons';
import { sortAlphabeticalByKey } from '../../utils/sorts';
import history from '../../routers/history';

export default class SettingsMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userSettings: [
        {
          history: '/user-settings/change-email',
          icon: faEnvelope,
          name: 'Email',
          description: 'Change your email address.'
        },
        {
          history: '/user-settings/change-password',
          icon: faLock,
          name: 'Change Password',
          description: 'Change your login password.'
        },
        {
          history: '/user-settings/alerts',
          icon: faExclamationTriangle,
          name: 'Alerts & Notifications',
          description: 'Set time and threshold based notifications.'
        },
      ],
      companySettings: [
        {
          history: '/company-settings/users',
          icon: faUsers,
          name: "Users",
          description: "Add and remove users, set permissions.",
        },
        {
          history: '/company-settings/company-information',
          icon: faNewspaper,
          name: "Company Information",
          description: "Edit company name, logo, website, and more.",
        },
        {
          history: '/content-collectors/category-setup',
          icon: faLayerGroup,
          name: "Category & Brand Setup",
          description: "Manage the categories and the brands in each category.",
        },
        {
          history: '/company-settings/objectives-and-goals',
          icon: faCalendarCheck,
          name: "Objectives & Goals",
          description: "Set metric driven performance goals.",
        },
        {
          history: '/content-collectors/category-checklist',
          icon: faTasks,
          name: "Review Checklist",
          description: "Use a checklist to manage the setup for a category.",
        },
        {
          history: '/company-settings/export',
          icon: faCloudDownloadAlt,
          name: "Export",
          description: "Export brand scores and metric values for a category.",
        },
      ],
      publicCollectors: [
        {
          history: '/content-collectors/news',
          icon: faNewspaper,
          name: "News Sites",
          description: "BrandOps reads over 7,000 publications daily. Suggest additional sites to ingest.",
          setupKey: 'news_sites_setup'
        },
        {
          history: '/content-collectors/email',
          icon: faEnvelope,
          name: "Email Subscriptions",
          description: "Manage email subscriptions to competitor & industry newsletters and announcements.",
          setupKey: 'email_subscriptions_setup'
        },
        {
          history: '/content-collectors/search-engine-terms',
          icon: faSearch,
          name: "Search Engine Results",
          description: "Manage keyword analysis across search engines (SERP).",
          setupKey: 'search_terms_setup'
        }
      ].sort(sortAlphabeticalByKey('name')),
      privateCollectors: [
        {
          history: '/content-collectors/mailchimp',
          icon: faMailchimp,
          name: "Mailchimp",
          description: "Connect your Mailchimp provider.",
          setupKey: 'mailchimp_setup'
        },
        {
          history: '/content-collectors/constant-contact',
          icon: faCopyright,
          name: "Constant Contact",
          description: "Connect your Constant Contact provider.",
          setupKey: 'constant_contact_setup'
        },
        {
          history: '/content-collectors/marketo',
          icon: faSpotify,
          name: "Marketo",
          description: "Connect your Marketo instance.",
          rotate: true,
          rotateDegrees: 90,
          setupKey: 'marketo_setup'
        },
        {
          history: '/content-collectors/hubspot',
          icon: faHubspot,
          name: "HubSpot",
          description: "Connect your HubSpot instance.",
          setupKey: 'hubspot_setup'
        },
        {
          history: '/content-collectors/google-ads',
          icon: faGoogle,
          name: "Google Ads",
          description: "Connect to Google Ads for ad analysis.",
          setupKey: 'google_ads_setup'
        },
        {
          history: '/content-collectors/web-analytics',
          icon: faGoogle,
          name: "Google Analytics",
          description: "Connect to Google Analytics for website analysis.",
          setupKey: 'google_analytics_setup'
        },
        {
          history: '/content-collectors/google-search-console',
          icon: faGoogle,
          name: "Google Search Console",
          description: "Connect to Google Search Console for search analysis.",
          setupKey: 'google_search_console_setup'
        },
        {
          history: '/content-collectors/semrush',
          icon: faFire,
          name: "SEMrush",
          description: "Connect SEMrush API key for global statistics.",
          rotate: true,
          rotateDegrees: 270,
          setupKey: 'semrush_setup'
        },
        {
          history: '/content-collectors/surveys',
          icon: faClipboardList,
          name: "Surveys",
          description: "Manage surveys to key target audiences for you and your competitors.",
          setupKey: 'surveys_setup'
        },
      ].sort(sortAlphabeticalByKey('name')),
      appIntegrations: [
        {
          history: '/content-collectors/slack',
          icon: faSlack,
          name: "Slack",
          description: "Connect Slack workspace to share insights.",
          setupKey: 'slack_setup'
        },
        {
          history: '/content-collectors/sso',
          icon: faKey,
          name: "SSO & MFA",
          description: "Leverage single sign-on provider and multi-factor authentication.",
          setupKey: 'sso_setup'
        },
      ].sort(sortAlphabeticalByKey('name')),
    };
  };

  componentDidMount() {
    if (this.props.user.customerId) {
      // fetch latest setup checklist
      this.props.fetchCompanySetupChecklist(this.props.user.customerId);
    }
  }

  onMenuSelection = (menuSelection) => {
    history.push(`${menuSelection}`);
  };

  render () {
    return (
      <div className="p-4 pre-scrollable app-content hide-scrollbar">
      { !(this.props.user && this.props.user.id) &&
        <LoginContainer />
      }
      { (this.props.user && this.props.user.id) &&
        <div className="container">
          <hr />
          <h3>User Settings</h3>
          <hr />
          <Row>
            { this.state.userSettings.map((userSetting, i) => {
                return (
                  <Col key={`us-${i}`} xs={12} md={4} className="pb-4">
                    <div
                      className="p-4 rounded border text-center settings-menu-item"
                      style={{ height: '100%', cursor: 'pointer' }}
                      onClick={() => this.onMenuSelection(userSetting.history)}
                    >
                      <div>
                        <FontAwesomeIcon icon={userSetting.icon} size="4x"/>
                      </div>
                      <div>{userSetting.name}</div>
                      <div className="mt-2">
                        {userSetting.description}
                      </div>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
          <hr />
          <h3>Company Settings</h3>
          <hr />
          <Row>
            { this.state.companySettings.map((companySetting, i) => {
                return (
                  <Col key={`cs-${i}`} xs={12} md={4} className="pb-4">
                    <div
                      className="p-4 rounded border text-center settings-menu-item"
                      style={{ height: '100%', cursor: 'pointer' }}
                      onClick={() => this.onMenuSelection(companySetting.history)}
                    >
                      <div>
                        <FontAwesomeIcon icon={companySetting.icon} size="4x"/>
                      </div>
                      <div>{companySetting.name}</div>
                      <div className="mt-2">
                        {companySetting.description}
                      </div>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
          <hr />
          <h3>Public Collectors</h3>
          <hr />
          <Row>
            { this.state.publicCollectors.map((collector, i) => {
                return (
                  <Col key={`pub-c-${i}`} xs={12} md={4} className="pb-4">
                    <div
                      className="p-4 rounded border settings-menu-item"
                      style={{ height: '100%', cursor: 'pointer' }}
                      onClick={() => this.onMenuSelection(collector.history)}
                    >
                      <span
                        style={{
                          position: 'absolute',
                          right: '24px',
                          top: '8px',
                        }}
                      >
                        <Badge
                          variant={
                            this.props.companySetupChecklist[collector.setupKey] ?
                              'success' :
                              'danger'
                          }
                          pill
                        >
                          {`${this.props.companySetupChecklist[collector.setupKey] ? '' : 'Not '}Configured`}
                        </Badge>
                      </span>
                      <div className="text-center">
                        <div>
                          <FontAwesomeIcon icon={collector.icon} size="4x"/>
                        </div>
                        <div>{collector.name}</div>
                        <div className="mt-2">
                          {collector.description}
                        </div>
                      </div>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
          <hr />
          <h3>Private Collectors</h3>
          <hr />
          <Row>
            { this.state.privateCollectors.map((collector, i) => {
                return (
                  <Col key={`priv-c-e-${i}`} xs={12} md={4} className="pb-4">
                    <div
                      className="p-4 rounded border settings-menu-item"
                      style={{ height: '100%', cursor: 'pointer' }}
                      onClick={() => this.onMenuSelection(collector.history)}
                    >
                      <span
                        style={{
                          position: 'absolute',
                          right: '24px',
                          top: '8px',
                        }}
                      >
                        <Badge
                          variant={
                            this.props.companySetupChecklist[collector.setupKey] ?
                              'success' :
                              'danger'
                          }
                          pill
                        >
                          {`${this.props.companySetupChecklist[collector.setupKey] ? '' : 'Not '}Connected`}
                        </Badge>
                      </span>
                      <div className="text-center">
                        <div>
                          <FontAwesomeIcon
                            className={
                              collector.rotate ?
                                `fa-rotate-${collector.rotateDegrees}`: ''
                            }
                            icon={collector.icon}
                            size="4x"
                          />
                        </div>
                        <div>{collector.name}</div>
                        <div className="mt-2">
                          {collector.description}
                        </div>
                      </div>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
          <hr />
          <h3>App Integrations</h3>
          <hr />
          <Row>
            { this.state.appIntegrations.map((collector, i) => {
                return (
                  <Col key={`ai-${i}`} xs={12} md={4} className="pb-4">
                    <div
                      className="p-4 rounded border settings-menu-item"
                      style={{ height: '100%', cursor: 'pointer' }}
                      onClick={() => this.onMenuSelection(collector.history)}
                    >
                      <span
                        style={{
                          position: 'absolute',
                          right: '24px',
                          top: '8px',
                        }}
                      >
                        <Badge
                          variant={
                            this.props.companySetupChecklist[collector.setupKey] ?
                              'success' :
                              'danger'
                          }
                          pill
                        >
                          {`${this.props.companySetupChecklist[collector.setupKey] ? '' : 'Not '}Connected`}
                        </Badge>
                      </span>
                      <div className="text-center">
                        <div>
                          <FontAwesomeIcon icon={collector.icon} size="4x"/>
                        </div>
                        <div>{collector.name}</div>
                        <div className="mt-2">
                          {collector.description}
                        </div>
                      </div>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
        </div>
      }
      </div>
    );
  }
};
