import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';
import CardComponent from '../common/CardComponent';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryBlogsAvgGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sixMonthDates: [],
      categoryBlogsAvgData: [],
      categoryBlogsLoading: false,
      categoryKingName: '',
      ownersBrandName: '',
      maxBlogCount: 0
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getSixMonthDates();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.fetchBlogs();
    }
  }

  getSixMonthDates = () => {
    const sixMonthDates = [];
    let date = moment();
    let sixMonthsDate = moment().subtract(6 * 30, 'day');
    while (date.isAfter(sixMonthsDate)) {
      sixMonthDates.push(date);
      date = moment(date).subtract(1, 'month');
    }

    this.setState(
      () => ({ sixMonthDates }),
      () => this.fetchBlogs()
    );
  };

  fetchBlogs = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryBlogsLoading: true }));
      let allBrandBlogRequests = [];
      for (const month of this.state.sixMonthDates) {
        const formattedStartDate = moment(month).startOf('month').format('YYYY-MM-DD HH:mm:ss');
        const formattedEndDate = moment(month).endOf('month').format('YYYY-MM-DD HH:mm:ss');
        for (const brand of this.props.category.product_brands) {
          allBrandBlogRequests.push(
            axios.get(
              `${LISTEN_ENDPOINT}/api/company-blog-stats?product_brand_id=${brand.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
              HEADERS
            ).then(response => {
              let brandBlogData = response.data;
              brandBlogData.month = month.format('MMMM');
              brandBlogData.name = brand.name;
              brandBlogData.inbound_links = "n/a";
              brandBlogData.page_views = "n/a";
              return brandBlogData;
            }).catch(error => {
              console.error('Error: failed to fetch blogs...');
              if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
                dispatchReportError(error.response);
              }
            })
          );
        }
      }

      Promise.all(allBrandBlogRequests).then(responses => {
        let allCategoryBlogsAvgData = [];
        let maxBlogCount = 0;
        for (const response of responses) {
          if (response) {
            if (response.total_blogs!=0) {
              allCategoryBlogsAvgData.push(response);
              if (response.total_blogs > maxBlogCount)
                maxBlogCount = response.total_blogs;
            }
          }
        }
        // find overall category king
        let maxBlogsAvg = 0;
        let categoryKingName = "";
        let ownersBrandName = "";
        for (const pb of this.props.category.product_brands) {
          let brandBlogs = allCategoryBlogsAvgData.filter(function(blogsData) {
            return blogsData.name == pb.name;
          });
          if (brandBlogs.length>0) {
            let brandBlogCountSum = 0;
            for (const blogsData of brandBlogs) {
              brandBlogCountSum = brandBlogCountSum + blogsData.total_blogs;
            }
            let brandBlogCountAvg = brandBlogCountSum / brandBlogs.length;
            if (brandBlogCountAvg > maxBlogsAvg) {
              maxBlogsAvg = brandBlogCountAvg;
              categoryKingName = pb.name;
            }
          }
          // find owner's brand
          if (this.props.user.customerId == pb.company_id)
            ownersBrandName = pb.name;
        }
        // find average of each month
        let categoryBlogsAvgData = [];
        for (const month of this.state.sixMonthDates) {
          let category_king_num_blogs = 0;
          let owned_brand_num_blogs = 0;
          let category_avg_blogs = 0;
          let monthBlogs = allCategoryBlogsAvgData.filter(function(blogsData) {
            return blogsData.month == month.format("MMMM");
          });
          if (monthBlogs.length>0) {
            let monthBlogCountSum = 0;
            for (const blog of monthBlogs) {
              if (blog.name == categoryKingName)
                category_king_num_blogs = blog.total_blogs;
              if (blog.name == ownersBrandName)
                owned_brand_num_blogs = blog.total_blogs;
              monthBlogCountSum = monthBlogCountSum + blog.total_blogs;
            }
            category_avg_blogs = monthBlogCountSum / monthBlogs.length;
          }

          categoryBlogsAvgData.push({
            month: month.format("MMMM"),
            category_king_num_blogs,
            owned_brand_num_blogs,
            category_avg_blogs
          });
        }
        categoryBlogsAvgData.reverse();

        this.setState(() => ({
          categoryBlogsAvgData,
          categoryBlogsLoading: false,
          categoryKingName,
          ownersBrandName,
          maxBlogCount
        }))
      }).catch(error => {
        console.log('Error: failed to promise all blogs...');
        this.setState(() => ({
          categoryBlogsAvgData: [],
          categoryBlogsLoading: false,
          categoryKingName: '',
          ownersBrandName: '',
          maxBlogCount: 0
        }))
      });
    }
  }

  render () {
    return (
      <CardComponent
        title="Number of Product Brand Blog Posts"
        body={
          <div>
            { this.state.categoryBlogsLoading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.categoryBlogsLoading &&
              <ResponsiveContainer width="99%" height={200}>
                <ComposedChart data={this.state.categoryBlogsAvgData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month"/>
                  <YAxis
                    dataKey="count"
                    domain={[0, Math.ceil(this.state.maxBlogCount)]}
                    tickFormatter={(count) => {
                      return numberWithCommas(count);
                    }}
                  />
                  <Tooltip />

                  <Area
                    name={
                      this.state.categoryKingName ?
                        `Leader, ${this.state.categoryKingName} # of Blog Posts` :
                        'Category King # of Blog Posts'
                    }
                    type="monotone"
                    dataKey="category_king_num_blogs"
                    stroke="#007bff"
                    fillOpacity={.4}
                  />
                  <Line
                    name="Category Average # of Blog Posts"
                    type="monotone"
                    dataKey="category_avg_blogs"
                    strokeDasharray="10 10"
                    stroke="#009900"
                    strokeWidth={3}
                  />
                  <Line
                    name={
                      this.state.ownersBrandName ?
                        `${this.state.ownersBrandName} # of Blog Posts` :
                        'Your Brand # of Blog Posts'
                    }
                    type="monotone"
                    dataKey="owned_brand_num_blogs"
                    stroke="#ffc107"
                    strokeWidth={3}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            }
          </div>
        }
        contextType="category"
        contextCategory={this.props.category}
        contextBrand={null}
        contextChannel="Blogs"
        contextChartName="Number of Product Brand Blog Posts"
      />
    );
  }
};
