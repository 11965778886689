import React from 'react';
import axios from 'axios';
import moment from 'moment';
import dompurify from 'dompurify';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ClipLoader from 'react-spinners/ClipLoader';
import DateRangePicker from '../../common/DateRangePicker';
import { RegionMultiSelect } from '../../common/RegionMultiSelect';
import BrandChannelSummaryContainer from '../../../containers/brands/BrandChannelSummaryContainer';
import BrandMetricsByMonthTableContainer from '../../../containers/metrics/BrandMetricsByMonthTableContainer';
import NewsReachPerMillionGraph from '../../graphs/NewsReachPerMillionGraph';
import GeneralSummaryGraph from '../../graphs/GeneralSummaryGraph';
import NewsDisplay from '../../news/NewsDisplay';
import { numberWithCommas } from '../../../utils/numbers';
import { getDomainFromUrl } from '../../../utils/urls';
import {
  LISTEN_ENDPOINT,
  CONTENT_SCRAPING_ENDPOINT,
  HEADERS
} from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class NewsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newsSummaryData: [],
      newsMentionCountScoreType: undefined,
      newsSummaryDataLoading: false,
      categoryAverageNewsData: [],
      pressReleaseSummaryData: [],
      pressReleaseCountScoreType: undefined,
      pressReleaseSummaryDataLoading: false,
      categoryAveragePressReleaseData: [],
      newslettersSummaryData: [],
      newslettersMentionCountScoreType: undefined,
      newslettersSummaryDataLoading: false,
      categoryAverageNewsletterData: [],
      selectedRegions: [
        { label: 'Americas', value: 'americas' },
        { label: 'APAC', value: 'apac' },
        { label: 'EMEA', value: 'emea' },
      ],
      newsResults: [],
      newsResultsLoading: false,
      news: [],
      pressReleases: [],
      newsletters: [],
      selectedNews: undefined,
      cppCounts: {},
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchNewsSummaryData();
    this.fetchPressReleaseSummaryData();
    this.fetchNewsletterSummaryData();
    this.fetchNewsResults();
    if (this.props.scrollToDetails) {
      this.newsScroll.scrollTop = this.newsDetails.offsetTop - this.newsScroll.offsetTop;
      this.props.unsetScrollToDetails();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.brandName !== this.props.brandName ||
      prevProps.allMonthsChecked !== this.props.allMonthsChecked
    ) {
      this.fetchNewsSummaryData();
      this.fetchPressReleaseSummaryData();
      this.fetchNewsletterSummaryData();
    }
    if (
      prevProps.brandName !== this.props.brandName ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter
    ) {
      this.fetchNewsResults();
    }
    if (
      prevProps.companyChecked !== this.props.companyChecked ||
      prevProps.portfolioChecked !== this.props.portfolioChecked ||
      prevProps.productChecked !== this.props.productChecked
    ) {
      this.updateNewsResults()
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  fetchNewsSummaryData = () => {
    if (this.props.brand) {
      this.setState(() => ({ newsSummaryDataLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/product-brand/${this.props.brand.id}/news/stats?group_by=month${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let newsSummaryData = response.data.monthly_stats;
        const newsMentionCountScoreType = response.data.mention_count ? response.data.mention_count.score_type : undefined;
        // merge category average data into original data source
        newsSummaryData = newsSummaryData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAveragePressReleaseData[i]))
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            newsSummaryData,
            newsMentionCountScoreType,
            newsSummaryDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to load news summary data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            newsSummaryData: [],
            newsMentionCountScoreType: undefined,
            newsSummaryDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=news_avg_mention${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAverageNewsData = response.data.category_stats;
          // merge category average data into original data source
          const newsSummaryData = this.state.newsSummaryData.map((x, i) => {
            return (Object.assign({}, x, categoryAverageNewsData[i]))
          });

          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAverageNewsData,
              newsSummaryData
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average news data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAverageNewsData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  fetchPressReleaseSummaryData = () => {
    if (this.props.brand && this.props.brand.company_id) {
      this.setState(() => ({ pressReleaseSummaryDataLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/press-release-stats-trend?brand_identifier=${this.props.brand.id}${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let pressReleaseSummaryData = response.data.monthly_stats;
        const pressReleaseCountScoreType = response.data.count ? response.data.count.score_type : undefined;
        // merge category average data into original data source
        pressReleaseSummaryData = pressReleaseSummaryData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAveragePressReleaseData[i]))
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            pressReleaseSummaryData,
            pressReleaseCountScoreType,
            pressReleaseSummaryDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to load press release summary data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            pressReleaseSummaryData: [],
            pressReleaseCountScoreType: undefined,
            pressReleaseSummaryDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=press_releases_avg_mention${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAveragePressReleaseData = response.data.category_stats;
          // merge category average data into original data source
          const pressReleaseSummaryData = this.state.pressReleaseSummaryData.map((x, i) => {
            return (Object.assign({}, x, categoryAveragePressReleaseData[i]))
          });

          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAveragePressReleaseData,
              pressReleaseSummaryData
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average press release data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAveragePressReleaseData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  fetchNewsletterSummaryData = () => {
    if (this.props.brand && this.props.brand.company_id) {
      this.setState(() => ({ newslettersSummaryDataLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/product-brand/${this.props.brand.id}/newsletters/stats?group_by=month${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let newslettersSummaryData = response.data.monthly_stats;
        const newslettersMentionCountScoreType = response.data.mention_count ? response.data.mention_count.score_type : undefined;
        // merge category average data into original data source
        newslettersSummaryData = newslettersSummaryData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAverageNewsletterData[i]))
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            newslettersSummaryData,
            newslettersMentionCountScoreType,
            newslettersSummaryDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to load newsletters summary data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            newslettersSummaryData: [],
            newslettersMentionCountScoreType: undefined,
            newslettersSummaryDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=newsletter_avg_mention${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAverageNewsletterData = response.data.category_stats;
          // merge category average data into original data source
          const newslettersSummaryData = this.state.newslettersSummaryData.map((x, i) => {
            return (Object.assign({}, x, categoryAverageNewsletterData[i]))
          });

          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAverageNewsletterData,
              newslettersSummaryData
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average newsletter data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAverageNewsletterData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  fetchNewsResults = () => {
    if (this.props.brand) {
      this.setState(() => ({
        newsResultsLoading: true,
        selectedNews: undefined,
      }));
      const newsRequests = [];
      const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
      // fetch news
      newsRequests.push(
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/product-brand/${this.props.brand.id}/news?start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
          HEADERS
        ).then(response => {
          if (response.data && Array.isArray(response.data.news_articles)) {
            const news = [];
            for (const article of response.data.news_articles) {
              const articleSource = {
                title: article.title,
                domain: getDomainFromUrl(article.link),
                domain_favicon: article.news_site_favicon_url,
                published_at: article.published_date,
                brand_types: article.brand_types,
                country_code: article.country_code,
                country: article.country,
                region: article.region,
              };
              const newsArticle = {
                ...article,
                _id: article.source_document_id,
                _index: 'articles',
                _source: articleSource
              };
              news.push(newsArticle);
            }
            return { news };
          } else {
            console.error(`Error: unable to parse news response`);
            return { news: [] };
          }
        }).catch(error => {
          console.error('Error: failed to fetch news articles');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { news: [] };
        })
      );
      // fetch press releases
      newsRequests.push(
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/press-releases?company_id=${this.props.brand.company_id}&product_brand_id=${this.props.brand.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
          HEADERS
        ).then(response => {
          const pressReleases = response.data;
          // conform press release data to map to same keys as news
          for (let pressRelease of pressReleases) {
            pressRelease._source.published_at = pressRelease._source.published_date;
            pressRelease._source.domain = getDomainFromUrl(pressRelease._source.url);
            pressRelease._source.text = pressRelease._source.text_original;
            pressRelease._source['IPTC-topic-level1'] = pressRelease._source.iptc_topic_level1;
            pressRelease._source['IPTC-topic-level2'] = pressRelease._source.iptc_topic_level2;
            pressRelease._source['IPTC-topic-level3'] = pressRelease._source.iptc_topic_level3;
            pressRelease._source['IPTC-topic-level4'] = pressRelease._source.iptc_topic_level4;
            pressRelease._source['IAB-topic-level1'] = pressRelease._source.iab_topic_level1;
            pressRelease._source['IAB-topic-level2'] = pressRelease._source.iab_topic_level2;
            pressRelease._source['IAB-topic-level3'] = pressRelease._source.iab_topic_level3;
            pressRelease._source['IAB-topic-level4'] = pressRelease._source.iab_topic_level4;
            if (pressRelease._source.domain === 'businesswire.com') {
              pressRelease._source.domain_favicon = 'https://listen-company-favicons.s3.amazonaws.com/businesswire.com-favicon.ico';
            } else if (pressRelease._source.domain === 'globenewswire.com') {
              pressRelease._source.domain_favicon = 'https://listen-company-favicons.s3.amazonaws.com/globenewswire.com-favicon.ico';
            }
            // set press releases to us/na for now
            pressRelease._source.country_code = 'us';
            pressRelease._source.country = 'United States of America';
            pressRelease._source.region = 'na';
            pressRelease.show_site = true;

          }
          return { pressReleases };
        }).catch(error => {
          console.error('Error: failed to fetch press releases');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { pressReleases: [] };
        })
      );
      // fetch newsletters
      newsRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/all-newsletters?company_id=${this.props.brand.company_id}&product_brand_id=${this.props.brand.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}&add_full_news_article=true`,
          HEADERS
        ).then(response => {
          const sanitizer = dompurify.sanitize;
          // conform newsletters data to map to same keys as news
          if (response.data && Array.isArray(response.data.news_articles)) {
            const newsletters = [];
            for (const letter of response.data.news_articles) {
              const newsletterSource = letter.article;
              newsletterSource.brand_types = letter.brand_types;
              newsletterSource.domain_favicon = letter.domain_favicon_url;
              newsletterSource.published_at = newsletterSource.mail_date;
              newsletterSource.title = newsletterSource.subject;
              newsletterSource.text = sanitizer(
                newsletterSource.text_original,
                {FORBID_TAGS: ['style']}
              );
              // set newsletter to us/na for now
              newsletterSource.country_code = 'us';
              newsletterSource.country = 'United States of America';
              newsletterSource.region = 'na';
              const newsletter = {
                _id: letter.id,
                _index: 'newsletters',
                _source: newsletterSource,
                show_site: true,
              };
              newsletters.push(newsletter);
            }
            return { newsletters };
          } else {
            console.error(`Error: unable to parse newsletters response`);
            return { newsletters: [] };
          }
        }).catch(error => {
          console.error('Error: failed to fetch newsletters');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { newsletters: [] };
        })
      );

      Promise.all(newsRequests).then(responses => {
        let news = [];
        let pressReleases = [];
        let newsletters = [];
        const cppCounts = {
          companyCount: 0,
          portfolioCount: 0,
          productCount: 0,
        };
        for (const response of responses) {
          if (response.news) {
            news = response.news;
            for (const n of news) {
              if (Array.isArray(n._source.brand_types)) {
                if (n._source.brand_types.includes('company')) {
                  cppCounts.companyCount++;
                }
                if (n._source.brand_types.includes('portfolio')) {
                  cppCounts.portfolioCount++;
                }
                if (n._source.brand_types.includes('product')) {
                  cppCounts.productCount++;
                }
              } else {
                n._source.brand_types = []
              }
            }
          } else if (response.pressReleases) {
            pressReleases = response.pressReleases;
            for (const p of pressReleases) {
              if (Array.isArray(p._source.brand_types)) {
                if (p._source.brand_types.includes('company')) {
                  cppCounts.companyCount++;
                }
                if (p._source.brand_types.includes('portfolio')) {
                  cppCounts.portfolioCount++;
                }
                if (p._source.brand_types.includes('product')) {
                  cppCounts.productCount++;
                }
              } else {
                p._source.brand_types = []
              }
            }
          } else if (response.newsletters) {
            newsletters = response.newsletters;
            for (const n of newsletters) {
              if (Array.isArray(n._source.brand_types)) {
                if (n._source.brand_types.includes('company')) {
                  cppCounts.companyCount++;
                }
                if (n._source.brand_types.includes('portfolio')) {
                  cppCounts.portfolioCount++;
                }
                if (n._source.brand_types.includes('product')) {
                  cppCounts.productCount++;
                }
              } else {
                n._source.brand_types = []
              }
            }
          }
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            news,
            pressReleases,
            newsletters,
            cppCounts,
          }), () => this.updateNewsResults());
        }
      });
    }
  };

  updateNewsResults = () => {
    let newsResults = [];
    const selectedRegionsSet = new Set();
    for (const region of this.state.selectedRegions) {
      if (region.value === 'americas') {
        // americas includes 2 regions
        selectedRegionsSet.add('na');
        selectedRegionsSet.add('latam');
      } else {
        selectedRegionsSet.add(region.value);
      }
    }

    for (const n of this.state.news) {
      if (
        Array.isArray(n._source.brand_types) &&
        (
          (this.props.companyChecked && n._source.brand_types.includes('company')) ||
          (this.props.productChecked && n._source.brand_types.includes('product')) ||
          (this.props.portfolioChecked && n._source.brand_types.includes('portfolio'))
        )
      ) {
        if (selectedRegionsSet.has(n._source.region)) {
          newsResults.push(n);
        }
      }
    }
    for (const pr of this.state.pressReleases) {
      if (
        Array.isArray(pr._source.brand_types) &&
        (
          (this.props.companyChecked && pr._source.brand_types.includes('company')) ||
          (this.props.productChecked && pr._source.brand_types.includes('product')) ||
          (this.props.portfolioChecked && pr._source.brand_types.includes('portfolio'))
        )
      ) {
        if (selectedRegionsSet.has(pr._source.region)) {
          newsResults.push(pr);
        }
      }
    }
    for (const nl of this.state.newsletters) {
      if (
        Array.isArray(nl._source.brand_types) &&
        (
          (this.props.companyChecked && nl._source.brand_types.includes('company')) ||
          (this.props.productChecked && nl._source.brand_types.includes('product')) ||
          (this.props.portfolioChecked && nl._source.brand_types.includes('portfolio'))
        )
      ) {
        if (selectedRegionsSet.has(nl._source.region)) {
          newsResults.push(nl);
        }
      }
    }

    // sort news by date
    newsResults.sort((a, b) => {
      return moment(b._source.published_at).format('YYYYMMDDHHmmss') - moment(a._source.published_at).format('YYYYMMDDHHmmss')
    });

    let selectedNews;
    if (newsResults.length > 0) {
      selectedNews = newsResults[0];
    }

    if (this.state.isMounted) {
      this.setState(() => ({
        newsResults,
        newsResultsLoading: false,
        selectedNews
      }));
    }
  };

  onRegionSelectChange = (selectedRegions) => {
    this.setState(
      () => ({ selectedRegions }),
      () => this.updateNewsResults()
    );
  };

  render () {
    return (
      <div
        ref={(ref) => this.newsScroll = ref}
        className="p-4 pre-scrollable"
        style={{
          minHeight: 'calc(100vh - 100px)',
          maxHeight: 'calc(100vh - 100px)'
        }}
      >
        <div>
          <div>
            <BrandChannelSummaryContainer
              channel="News"
              category={this.props.category}
              brand={this.props.brand}
            />
          </div>
          <div>
            <h5 className="my-2 p-2 bg-bops-blue text-light">
              Metric Trends
              <div
                className="d-inline-block ml-4"
                style={{ fontSize: '1rem', fontWeight: 'normal' }}
              >
                <Form.Check
                  id="newsAllMonthsCheckbox"
                  className="ml-2"
                  type="checkbox"
                  label="All Months"
                  checked={this.props.allMonthsChecked}
                  onChange={(event) => this.props.updateAllMonthsChecked(event.currentTarget.checked)}
                  inline
                />
              </div>
            </h5>
            <Row>
              <Col xs={12} lg={6}>
                <div className="mb-2">
                  <NewsReachPerMillionGraph
                    brand={this.props.brand}
                    category={this.props.category}
                    allMonthsChecked={this.props.allMonthsChecked}
                    updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                    allMonthsStartDate={this.props.allMonthsStartDate}
                  />
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <div className="mb-2">
                  <GeneralSummaryGraph
                    title="News Mentions"
                    metricName="news_mention_count"
                    data={this.state.newsSummaryData}
                    dataKey="mention_count"
                    dataLabel="News Mentions"
                    scoreType={this.state.newsMentionCountScoreType}
                    loading={this.state.newsSummaryDataLoading}
                    allowDecimals={false}
                    showPercentChange={true}
                    showAverageData={true}
                    averageDataKey="news_avg_mention"
                    averageDataLabel="Category Average"
                    showTooltip={true}
                    minDomainValue={0}
                    contextType="brand"
                    contextCategory={this.props.category}
                    contextBrand={this.props.brand}
                    contextChannel="News"
                    contextChartName="News Mentions"
                    shareClassName="new-mentions-img"
                  />
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <div className="mb-2">
                  <GeneralSummaryGraph
                    title="Press Release Count"
                    metricName="press_releases_count"
                    data={this.state.pressReleaseSummaryData}
                    dataKey="count"
                    dataLabel="Press Release Count"
                    scoreType={this.state.pressReleaseCountScoreType}
                    loading={this.state.pressReleaseSummaryDataLoading}
                    allowDecimals={false}
                    showPercentChange={true}
                    showAverageData={true}
                    averageDataKey="press_releases_avg_mention"
                    averageDataLabel="Category Average"
                    showTooltip={true}
                    minDomainValue={0}
                    contextType="brand"
                    contextCategory={this.props.category}
                    contextBrand={this.props.brand}
                    contextChannel="News"
                    contextChartName="Press Release Count"
                  />
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <div className="mb-2">
                  <GeneralSummaryGraph
                    title="Newsletter Mentions"
                    description="Newsletter mentions gives a trend of newsletters by month over the past 6 months"
                    data={this.state.newslettersSummaryData}
                    dataKey="mention_count"
                    dataLabel="Newsletter Mentions"
                    scoreType={this.state.newslettersMentionCountScoreType}
                    loading={this.state.newslettersSummaryDataLoading}
                    allowDecimals={false}
                    showPercentChange={true}
                    showAverageData={true}
                    averageDataKey="newsletter_avg_mention"
                    averageDataLabel="Category Average"
                    showTooltip={true}
                    minDomainValue={0}
                    contextType="brand"
                    contextCategory={this.props.category}
                    contextBrand={this.props.brand}
                    contextChannel="News"
                    contextChartName="Newsletter Mentions"
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <BrandMetricsByMonthTableContainer
              type="News"
              category={this.props.category}
              brand={this.props.brand}
              audienceProfileId={this.props.category.audience_profile_id}
            />
          </div>
          <h5
            ref={(ref) => this.newsDetails = ref}
            className="my-2 p-2 bg-bops-blue text-light"
          >
            Details
            <span
              className="ml-4 mr-2"
              style={{ fontSize: '1rem', fontWeight: 'normal' }}
            >Date Range</span>
            <DateRangePicker
              startDate={this.props.startDateFilter}
              endDate={this.props.endDateFilter}
              updateDates={this.props.onFilterDatesChange}
            />
            <div
              className="d-inline-block ml-3 align-middle"
              style={{ fontSize: '1rem', fontWeight: 'normal' }}
            >
              <Form.Check
                className="ml-4"
                type="checkbox"
                label={`Corporate ${this.state.cppCounts.companyCount != null ? `(${numberWithCommas(this.state.cppCounts.companyCount)})` : ''}`}
                checked={this.props.companyChecked}
                onChange={(event) => this.props.onCppCheckChange('company', event.currentTarget.checked)}
                inline
              />
              <Form.Check
                className="ml-2"
                type="checkbox"
                label={`Portfolio ${this.state.cppCounts.portfolioCount != null ? `(${numberWithCommas(this.state.cppCounts.portfolioCount)})` : ''}`}
                checked={this.props.portfolioChecked}
                onChange={(event) => this.props.onCppCheckChange('portfolio', event.currentTarget.checked)}
                inline
              />
              <Form.Check
                className="ml-2"
                type="checkbox"
                label={`Product Brand ${this.state.cppCounts.productCount != null ? `(${numberWithCommas(this.state.cppCounts.productCount)})` : ''}`}
                checked={this.props.productChecked}
                onChange={(event) => this.props.onCppCheckChange('product', event.currentTarget.checked)}
                inline
              />
            </div>
            <div
              className="d-inline-block ml-3"
              style={{ fontSize: '.875rem', fontWeight: 'normal', color: '#000000' }}
            >
              <RegionMultiSelect
                value={this.state.selectedRegions}
                onChange={this.onRegionSelectChange}
              />
            </div>
          </h5>
          { this.state.newsResultsLoading &&
            <div className="m-4 text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !this.state.newsResultsLoading &&
            <NewsDisplay
              user={this.props.user}
              brand={this.props.brand}
              newsResults={this.state.newsResults}
              selectedNews={this.state.selectedNews}
              refreshNewsArticles={this.fetchNewsResults}
            />
          }
        </div>
      </div>
    );
  }
};
