import React from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class GoolgeSearchConsoleForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      domainName: '',
      errorMessage: '',
      errors: {}
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  onDomainNameChange = (event) => {
    const domainName = event.currentTarget.value;
    this.setState(() => ({ domainName }));
  };

  isValidForm = () => {
    const errors = {};
    let errorMessage = '';
    if (!this.state.domainName || this.state.domainName.trim() === '') {
      errors.domainName = true;
      errorMessage = 'Must provide domain name for google search console.'
    }

    this.setState(() => ({
      errors,
      errorMessage
    }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  saveGoogleSearchConsoleAccount = () => {
    if (this.isValidForm()) {
      this.setState(() => ({ saveLoading: true }));
      const googleSearchConsoleAccount = {
        company_id: this.props.companyId,
        domain_name: this.state.domainName,
      };

      axios.post(
        `${CONTENT_SCRAPING_ENDPOINT}/api/google-search-console-accounts`,
        googleSearchConsoleAccount,
        HEADERS
      ).then(response => {
        this.props.refreshGoogleSearchConsoleAccounts();
        this.props.removeGoogleSearchConsoleForm(this.props.id);
      }).catch(error => {
        this.setState(() => ({ errorMessage: 'Failed to create new google search console account' }));
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        <Form.Row>
          <Col>
            <Form.Control
              type="text"
              value={this.state.domainName}
              onChange={this.onDomainNameChange}
              isInvalid={this.state.errors.domainName}
              placeholder="Domain Name"
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  this.saveGoogleSearchConsoleAccount();
                }
              }}
              size="sm"
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errors.domainName}
            </Form.Control.Feedback>
          </Col>
          <Col style={{ flex: '0 0 140px' }} className="text-right">
            <Button
              className="px-1 py-0 mr-2"
              size="sm"
              variant="success"
              onClick={this.saveGoogleSearchConsoleAccount}
            >
              <FontAwesomeIcon icon={faCheck}/>
            </Button>
            <Button
              className="px-1 py-0"
              size="sm"
              variant="danger"
              onClick={() => this.props.removeGoogleSearchConsoleForm(this.props.id)}
            >
              <FontAwesomeIcon icon={faTimes}/>
            </Button>
          </Col>
        </Form.Row>
        <Alert
          className="mt-2 py-1 px-2"
          show={Boolean(this.state.errorMessage)}
          variant="danger"
        >
          {this.state.errorMessage}
        </Alert>
      </div>
    );
  }
};
