import { connect } from 'react-redux';
import ChangePassword from '../../components/settings/ChangePassword';

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
