import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import LoginContainer from '../../../containers/common/LoginContainer';
import AdminAuthenticationMessage from '../../common/AdminAuthenticationMessage';
import { isAuthenticatedAdmin } from '../../../utils/auth';
import SurveySetup from './SurveySetup';
import SurveyPreview from './SurveyPreview';
import SurveyPrepareLaunch from './SurveyPrepareLaunch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faUpload } from '@fortawesome/free-solid-svg-icons';
import history from '../../../routers/history';
import {
  CONTENT_SCRAPING_ENDPOINT,
  HEADERS
} from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class SurveysCollector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      surveyStep: 'setup',
      selectedSurvey: undefined,
      categories: [],
      selectedCategoryId: undefined,
      selectedCategoryName: undefined,
      brands: [],
      selectedBrandId: undefined,
      selectedBrandName: undefined,
      surveyTemplate: 'official brandops survey',
      selectedCompetitors: {},
      selectedSurveyQuestions: [],
      surveyParticipantsFileName: undefined,
      surveyParticipants: [],
      waveDescription: ''
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.categories.length > 0) {
      this.onCategoryChange({
        currentTarget: {
          value: `${this.props.categories[0].category_type}-${this.props.categories[0].id}`
        }
      });
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevProps.categories !== this.props.categories
    ) {
      if (this.props.categories.length > 0) {
        this.onCategoryChange({
          currentTarget: {
            value: `${this.props.categories[0].category_type}-${this.props.categories[0].id}`
          }
        });
      } else {
        this.setState(() => ({
          selectedCategoryId: undefined,
          brands: [],
          selectedBrandId: undefined
        }));
      }
    }
  };

  onCategoryChange = (event) => {
    const selectedCategoryId = event.currentTarget.value;
    let selectedCategoryName;
    let brands = [];
    let selectedBrandId;
    let selectedBrandName;
    for (const category of this.props.categories) {
      if (selectedCategoryId === `${category.category_type}-${category.id}`) {
        selectedCategoryName = category.name;
        brands = category.product_brands;
        const selectableBrands = category.product_brands.filter(b => b.company_id === this.props.user.customerId);
        for (const brand of selectableBrands) {
          if (selectedBrandId === undefined) {
            selectedBrandId = brand.id;
            selectedBrandName = brand.name;
          }
        }
      }
    }
    this.setState(() => ({
      selectedCategoryId,
      selectedCategoryName,
      brands,
      selectedBrandId,
      selectedBrandName
    }));
  };

  onBrandChange = (event) => {
    const selectedBrandId = parseInt(event.currentTarget.value);
    let selectedBrandName;
    for (const b of this.state.brands) {
      if (b.id === selectedBrandId) {
        selectedBrandName = b.name;
      }
    }
    this.setState(() => ({
      selectedBrandId,
      selectedBrandName
    }));
  };

  onChangeSurveyStep = (surveyStep) => {
    this.setState(() => ({ surveyStep }));
  };

  onSurveyTemplateChange = (event) => {
    const surveyTemplate = event.currentTarget.value;
    this.setState(() => ({ surveyTemplate }));
  };

  toggleSelectedCompetitors = (brandName, checked) => {
    this.setState((prevState) => {
      const selectedCompetitors = prevState.selectedCompetitors;
      if (checked) {
        let selectedCompetitorsCount = 0;
        for (let [key, value] of Object.entries(selectedCompetitors)) {
          if (value) {
            selectedCompetitorsCount += 1;
          }
        }
        if (selectedCompetitorsCount < 5) {
          selectedCompetitors[brandName] = checked;
        }
        return ({ selectedCompetitors });
      } else {
        selectedCompetitors[brandName] = checked;
        return ({ selectedCompetitors });
      }
    });
  };

  createSurvey = () => {
    const categoryIdSplit = this.state.selectedCategoryId.split('-');
    const categoryType = categoryIdSplit[0];
    const categoryId = categoryIdSplit[1];
    const competitors = [];
    for (let [key, value] of Object.entries(this.state.selectedCompetitors)) {
      if (value) {
        competitors.push(key);
      }
    }
    const newSurvey = {
      survey_type: this.state.surveyTemplate,
      customer_user_id: this.props.user.id,
      customer_id: this.props.user.customerId,
      survey_name: this.state.surveyTemplate,
      category_id: categoryId,
      category_type: categoryType,
      product_brand_id: this.state.selectedBrandId,
      category_name: this.state.selectedCategoryName,
      product_brand_name: this.state.selectedBrandName,
      competitors
    };

    axios.post(
      `${CONTENT_SCRAPING_ENDPOINT}/api/customer-surveys`,
      newSurvey,
      HEADERS
    ).then(response => {
      const selectedSurvey = response.data;
      if (this.state.isMounted) {
        this.setState(() => ({
          selectedSurvey,
          surveyStep: 'preview'
        }));
        this.fetchSurveyQuestions(selectedSurvey.id);
      }
    }).catch(error => {
      console.error('Error: failed to create new survey');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  fetchSurveyQuestions = (surveyId) => {
    if (surveyId) {
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/customer-survey-questions?survey_id=${surveyId}`,
        HEADERS
      ).then(response => {
        const selectedSurveyQuestions = response.data.questions;
        if (this.state.isMounted) {
          this.setState(() => ({ selectedSurveyQuestions }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch survey questions');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onSurveyParticipantsUpload = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (onloadEvent) => {
        const fileContents = onloadEvent.target.result;
        const rows = fileContents.split('\n');
        const surveyParticipants = [];
        for (let row of rows) {
          const rowContents = row.split(',');
          if (rowContents.length === 3) {
            surveyParticipants.push({
              first_name: rowContents[0],
              last_name: rowContents[1],
              email: rowContents[2]
            });
          }
        }
        this.setState(() => ({
          surveyParticipantsFileName: file.name,
          surveyParticipants
        }));
      };
      reader.readAsText(file);
    }
  };

  onWaveDescriptionChange = (event) => {
    const waveDescription = event.currentTarget.value;
    this.setState(() => ({ waveDescription }));
  };

  launchSurvey = (startDate, endDate) => {
    const newSurveyIssuance = {
      customer_id: this.props.user.customerId,
      survey_id: this.state.selectedSurvey.id,
      wave_description: this.state.waveDescription,
      run_start_date: startDate,
      run_finish_date: endDate,
      respondents: this.state.surveyParticipants
    };

    axios.post(
      `${CONTENT_SCRAPING_ENDPOINT}/api/customer-survey-issuances`,
      newSurveyIssuance,
      HEADERS
    ).then(response => {
      console.log('Successful survey issuance');
      history.push('/content-collectors');
    }).catch(error =>  {
      console.error('Error: unable to create survey issueance');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  render () {
    return (
      <div className="m-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        <AdminAuthenticationMessage user={this.props.user}/>
        { isAuthenticatedAdmin(this.props.user) &&
          <div>
            <div
              className="border rounded p-4"
              style={{ backgroundColor: '#e9ecef' }}
            >
              <div className="d-inline-block pr-2">
                <FontAwesomeIcon icon={faClipboardList} size="2x" color="#454d54" />
              </div>
              <div className="d-inline-block">
                <h4>Surveys</h4>
              </div>
              <p>Manage surveys to key target audiences for you and your competitors.</p>
              <hr className="my-4" />
              <p>Select a category, a product brand, a survey template, and your competitors to generate your brand tracking surveys.</p>
            </div>
            <div className="pt-4 mb-2">
              Create a new brand tracking survey.
            </div>
            <Row>
              <Col xs={4} md={3} lg={2}>
                <div className={`mb-2 p-4 border rounded d-flex align-items-center${this.state.surveyStep==='setup'?' bg-dark text-light':' bg-white'}`}>
                  1. Setup
                </div>
                <div className={`mb-2 p-4 border rounded d-flex align-items-center${this.state.surveyStep==='preview'?' bg-dark text-light':' bg-white'}`}>
                  2. Preview
                </div>
                <div className={`mb-2 p-4 border rounded d-flex align-items-center${this.state.surveyStep==='prepare_launch'?' bg-dark text-light':' bg-white'}`}>
                  3. Prepare Launch
                </div>
              </Col>
              <Col>
                { this.state.surveyStep === 'setup' &&
                  <SurveySetup
                    companyId={this.props.user.customerId}
                    selectedSurvey={this.state.selectedSurvey}
                    categories={this.props.categories}
                    selectedCategoryId={this.state.selectedCategoryId}
                    onCategoryChange={this.onCategoryChange}
                    brands={this.state.brands}
                    selectedBrandId={this.state.selectedBrandId}
                    onBrandChange={this.onBrandChange}
                    surveyTemplate={this.state.surveyTemplate}
                    onSurveyTemplateChange={this.onSurveyTemplateChange}
                    selectedCompetitors={this.state.selectedCompetitors}
                    toggleSelectedCompetitors={this.toggleSelectedCompetitors}
                    createSurvey={this.createSurvey}
                    onChangeSurveyStep={this.onChangeSurveyStep}
                  />
                }
                { this.state.surveyStep === 'preview' &&
                  <SurveyPreview
                    selectedSurvey={this.state.selectedSurvey}
                    questions={this.state.selectedSurveyQuestions}
                    onChangeSurveyStep={this.onChangeSurveyStep}
                  />
                }
                { this.state.surveyStep === 'prepare_launch' &&
                  <SurveyPrepareLaunch
                    selectedSurvey={this.state.selectedSurvey}
                    surveyParticipantsFileName={this.state.surveyParticipantsFileName}
                    surveyParticipants={this.state.surveyParticipants}
                    onSurveyParticipantsUpload={this.onSurveyParticipantsUpload}
                    waveDescription={this.state.waveDescription}
                    onWaveDescriptionChange={this.onWaveDescriptionChange}
                    onChangeSurveyStep={this.onChangeSurveyStep}
                    launchSurvey={this.launchSurvey}
                  />
                }
              </Col>
            </Row>
          </div>
        }
      </div>
    );
  }
};
