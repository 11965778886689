import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import StarScoreBar from '../graphs/StarScoreBar';
import { sentimentWordsMap } from '../../utils/sentiment';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class ReviewDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reviewsData: [],
      reviewsDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.loadReviewsData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.topicId !== this.props.topicId ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter
    ) {
      this.loadReviewsData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  loadReviewsData = () => {
    this.setState(() => ({ reviewsDataLoading: true }));
    const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
    axios.get(
      `${LISTEN_ENDPOINT}/api/brand-reviews?topic_id=${this.props.topicId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
      HEADERS
    ).then(response => {
      const reviewsData = response.data;
      for (const review of reviewsData) {
        let textDisplay = review.text;
        const words = review.text.split(/\s+/);
        const replacedMap = {};
        for (const word of words) {
          if (
            sentimentWordsMap[word.toLowerCase()] != null &&
            !replacedMap[word]
          ) {
            try {
              const regex = new RegExp('(\\s+)' + word + '((\\s+)|([.,!?:;\"\')]))', 'g');
              textDisplay = textDisplay.replaceAll(
                regex,
                (regexMatch) => {
                  return regexMatch.replace(word, `<mark style="background-color:#ffc107">${word}</mark>`);
                }
              );
              replacedMap[word] = true;
            } catch (error) {
              // do nothing
            }
          }
        }
        review.text_display = textDisplay;
      }
      if (this.state.isMounted) {
        this.setState(() => ({
          reviewsData,
          reviewsDataLoading: false
        }));
      }
    }).catch(error => {
      console.error('Error loading review details data...');
      if (this.state.isMounted) {
        this.setState(() => ({
          reviewsData: [],
          reviewsDataLoading: false
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  toggleOptions = () => {
    this.setState((prevState) => ({ showConfig: !prevState.showConfig }));
  };

  render () {
    return (
      <div>
        { this.state.reviewsDataLoading &&
          <div className="text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.reviewsDataLoading &&
          <div>
            { this.state.reviewsData.map((review, i) => {
              if (
                this.props.selectedReviewLocation === 'all' ||
                parseInt(this.props.selectedReviewUrlId) === review.review_url_id
              ) {
                if (this.props.filteredReviewSites.includes(review.review_site_name)) {
                  return (
                    <Card
                      id={`r-review-${i}`}
                      key={`r-${review.id}`}
                      className="mb-4"
                    >
                      <Card.Body>
                        <Row noGutters>
                          <Col style={{ flex: '0 0 40px' }}>
                            <div className="mt-2">
                              <img
                                src={review.review_site_favicon_url}
                                onClick={() => window.open(review.review_url, '_blank')}
                                style={{
                                  width: '30px',
                                  cursor: 'pointer'
                                }}
                              />
                            </div>
                          </Col>
                          <Col>
                            <div>
                              { review.title &&
                                <div className="d-inline-block mr-4">
                                  {review.title}
                                </div>
                              }
                              { review.author_user_id &&
                                <div
                                  className="d-inline-block mr-4 align-bottom text-bops-blue"
                                  style={{
                                    fontSize: '.875rem',
                                    maxWidth: '200px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                  }}
                                >
                                  {review.author_user_id}
                                </div>
                              }
                              <div className="d-inline-block float-right">
                                <SharePopUpContainer
                                  shareElementId={`r-review-${i}`}
                                  elementLabel={`r-review-${i}-img`}
                                  position="top"
                                  contextType="brand"
                                  contextCategory={this.props.category}
                                  contextBrand={this.props.brand}
                                  contextChannel="Product Reviews"
                                  contextProperty="Review"
                                  contextChartName="Review"
                                />
                              </div>
                            </div>
                            <div>
                              {moment(review.review_date).format('MM/DD/YY')}
                            </div>
                          </Col>
                        </Row>
                        { review.review_site_has_stars !== 0 &&
                          <div className="mt-2">
                            <StarScoreBar
                              score={review.score}
                              maxScore={1}
                              starColor="gold"
                            />
                          </div>
                        }
                        <pre
                          className="mt-4"
                          style={{
                            maxWidth: 'calc(100vw - 400px)',
                            fontFamily: 'inherit',
                            fontSize: 'unset',
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word'
                          }}
                          dangerouslySetInnerHTML={{ __html: review.text_display }}
                        />
                      </Card.Body>
                    </Card>
                  )
                }
              }
              })
            }
          </div>
        }
      </div>
    );
  }
};
