import React from 'react';
import AllSocialTab from './AllSocialTab';
import BrandTabOverview from '../BrandTabOverview';
import LinkedInTab from '../../linkedin/LinkedInTab';
import TwitterTab from './TwitterTab';
import FacebookTab from './FacebookTab';
import InstagramTab from './InstagramTab';
import RedditTab from './RedditTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faTwitter, faFacebook, faInstagram, faReddit } from '@fortawesome/free-brands-svg-icons';
import { createUserActivityLog } from '../../../utils/users';
import history from '../../../routers/history';

export default class SocialTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: "allSocial"
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let selectedTab = hash.indexOf("-")>0 ? hash.substring(hash.indexOf("-")+1) : "allSocial";
      this.setState(() => ({ selectedTab }));
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  changeSelectedTab = (selectedTab, subTabName) => {
    history.push(`#social-${selectedTab}`);
    this.setState(() => ({ selectedTab }));
    if (subTabName) {
      createUserActivityLog(
        this.props.user,
        `Brand Tabs: Social: ${subTabName}`,
        this.props.category,
        this.props.brand,
      )
    }
  }

  render () {
    return (
      <div className="p-4">
        <div className="mb-4">
          <button
            id="brandAllSocialButton"
            type="button"
            className= { this.state.selectedTab === "allSocial" ? "btn border-primary bg-white mr-1" : "btn border bg-white mr-1" }
            onClick={(e) => this.changeSelectedTab('allSocial', 'All Social')}
          >
            <FontAwesomeIcon
              className="pr-2"
              icon={faUsers}
              color="#6c757d"
              style={{ paddingTop: '6px', paddingBottom: '2px', fontSize: '1rem' }}
            />
            <div className="pr-2 d-inline-block">
              All Social
            </div>
          </button>
          <button
            id="brandLinkedInButton"
            type="button"
            className= { this.state.selectedTab === "linkedIn" ? "btn border-primary bg-white mr-1" : "btn border bg-white mr-1" }
            onClick={(e) => this.changeSelectedTab('linkedIn', 'LinkedIn')}
          >
            <FontAwesomeIcon
              className="pt-1 pr-2"
              icon={faLinkedin}
              color="#2867B2"
              style={{ fontSize: '1.2rem' }}
            />
            LinkedIn
          </button>
          <button
            id="brandTwitterButton"
            type="button"
            className= { this.state.selectedTab === "twitter" ? "btn border-primary bg-white mr-1" : "btn border bg-white mr-1" }
            onClick={(e) => this.changeSelectedTab('twitter', 'Twitter')}
          >
            <FontAwesomeIcon
              className="pt-1 pr-2"
              icon={faTwitter}
              color="#1DA1F2"
              style={{ fontSize: '1.2rem' }}
            />
            Twitter
          </button>
          <button
            id="brandFacebookButton"
            type="button"
            className= { this.state.selectedTab === "facebook" ? "btn border-primary bg-white mr-1" : "btn border bg-white mr-1" }
            onClick={(e) => this.changeSelectedTab('facebook', 'Facebook')}
          >
            <FontAwesomeIcon
              className="pt-1 pr-2"
              icon={faFacebook}
              color="#4267B2"
              style={{ fontSize: '1.2rem' }}
            />
            Facebook
          </button>
          <button
            id="brandInstagramButton"
            type="button"
            className= { this.state.selectedTab === "instagram" ? "btn border-primary bg-white mr-1" : "btn border bg-white mr-1" }
            onClick={(e) => this.changeSelectedTab('instagram', 'Instagram')}
          >
            <FontAwesomeIcon
              className="pt-1 pr-2"
              icon={faInstagram}
              color="#C13584"
              style={{ fontSize: '1.2rem' }}
            />
            Instagram
          </button>
          {/* <button
            id="brandRedditButton"
            type="button"
            className= { this.state.selectedTab === "reddit" ? "btn border-primary bg-white mr-1" : "btn border bg-white mr-1" }
            onClick={(e) => this.changeSelectedTab('reddit', 'Reddit')}
          >
            <FontAwesomeIcon
              className="pt-1 pr-2"
              icon={faReddit}
              color="#FF4500"
              style={{ fontSize: '1.2rem' }}
            />
            Reddit
          </button> */}
        </div>

        <div
          ref={(ref) => this.socialScroll = ref}
          className="mt-2 pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 250px)',
            maxHeight: 'calc(100vh - 250px)',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          { this.state.selectedTab === 'allSocial' &&
            <AllSocialTab
              customerId={this.props.customerId}
              categoryId={this.props.categoryId}
              categoryType={this.props.categoryType}
              category={this.props.category}
              brandId={this.props.brandId}
              brand={this.props.brand}
              brandName={this.props.brandName}
              topicId={this.props.topicId}
              startDateFilter={this.props.startDateFilter}
              endDateFilter={this.props.endDateFilter}
              onFilterDatesChange={this.props.onFilterDatesChange}
              scrollToDetails={this.props.scrollToDetails}
              unsetScrollToDetails={this.props.unsetScrollToDetails}
              socialScroll={this.socialScroll}
            />
          }
          { this.state.selectedTab === 'linkedIn' &&
            <div>
              <BrandTabOverview
                overviewType="LinkedIn"
              />
              <LinkedInTab
                customerId={this.props.customerId}
                companyId={this.props.companyId}
                category={this.props.category}
                brand={this.props.brand}
                brandName={this.props.brandName}
                startDateFilter={this.props.startDateFilter}
                endDateFilter={this.props.endDateFilter}
                onFilterDatesChange={this.props.onFilterDatesChange}
                allMonthsChecked={this.props.allMonthsChecked}
                updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                allMonthsStartDate={this.props.allMonthsStartDate}
                companyChecked={this.props.companyChecked}
                portfolioChecked={this.props.portfolioChecked}
                productChecked={this.props.productChecked}
                onCppCheckChange={this.props.onCppCheckChange}
              />
            </div>
          }
          { this.state.selectedTab === 'twitter' &&
            <div>
              <BrandTabOverview
                overviewType="Twitter"
              />
              <TwitterTab
                customerId={this.props.customerId}
                companyId={this.props.companyId}
                categoryId={this.props.categoryId}
                categoryType={this.props.categoryType}
                category={this.props.category}
                brandId={this.props.brandId}
                brand={this.props.brand}
                brandName={this.props.brandName}
                topicId={this.props.topicId}
                startDateFilter={this.props.startDateFilter}
                endDateFilter={this.props.endDateFilter}
                onFilterDatesChange={this.props.onFilterDatesChange}
                allMonthsChecked={this.props.allMonthsChecked}
                updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                allMonthsStartDate={this.props.allMonthsStartDate}
                companyChecked={this.props.companyChecked}
                portfolioChecked={this.props.portfolioChecked}
                productChecked={this.props.productChecked}
                onCppCheckChange={this.props.onCppCheckChange}
              />
            </div>
          }
          { this.state.selectedTab === 'facebook' &&
            <div>
              <BrandTabOverview
                overviewType="Facebook"
              />
              <FacebookTab
                customerId={this.props.customerId}
                categoryId={this.props.categoryId}
                categoryType={this.props.categoryType}
                category={this.props.category}
                brandId={this.props.brandId}
                brand={this.props.brand}
                brandName={this.props.brandName}
                topicId={this.props.topicId}
                startDateFilter={this.props.startDateFilter}
                endDateFilter={this.props.endDateFilter}
                onFilterDatesChange={this.props.onFilterDatesChange}
                allMonthsChecked={this.props.allMonthsChecked}
                updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                allMonthsStartDate={this.props.allMonthsStartDate}
                companyChecked={this.props.companyChecked}
                portfolioChecked={this.props.portfolioChecked}
                productChecked={this.props.productChecked}
                onCppCheckChange={this.props.onCppCheckChange}
              />
            </div>
          }
          { this.state.selectedTab === 'instagram' &&
            <div>
              <BrandTabOverview
                overviewType="Instagram"
              />
              <InstagramTab
                customerId={this.props.customerId}
                categoryId={this.props.categoryId}
                categoryType={this.props.categoryType}
                category={this.props.category}
                brandId={this.props.brandId}
                brand={this.props.brand}
                brandName={this.props.brandName}
                topicId={this.props.topicId}
                startDateFilter={this.props.startDateFilter}
                endDateFilter={this.props.endDateFilter}
                onFilterDatesChange={this.props.onFilterDatesChange}
                allMonthsChecked={this.props.allMonthsChecked}
                updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                allMonthsStartDate={this.props.allMonthsStartDate}
                companyChecked={this.props.companyChecked}
                portfolioChecked={this.props.portfolioChecked}
                productChecked={this.props.productChecked}
                onCppCheckChange={this.props.onCppCheckChange}
              />
            </div>
          }
          { this.state.selectedTab === 'reddit' &&
            <div>
              <BrandTabOverview
                overviewType="Reddit"
              />
              <RedditTab
                customerId={this.props.customerId}
                companyId={this.props.companyId}
                category={this.props.category}
                brand={this.props.brand}
                brandName={this.props.brandName}
                startDateFilter={this.props.startDateFilter}
                endDateFilter={this.props.endDateFilter}
                onFilterDatesChange={this.props.onFilterDatesChange}
              />
            </div>
          }
        </div>
      </div>
    );
  }
};
