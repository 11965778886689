import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

export default class UpdateOverallScoreWeights extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brandPresenceWeight: '0',
      brandAttentionWeight: '0',
      brandReputationWeight: '0',
      brandMomentumWeight: '0',
      errors: {},
      errorMessage: ''
    };
  }

  onOpen = () => {
    this.setState(() => ({
      brandPresenceWeight: (this.props.overallScoreWeights['Brand Presence'] * 100).toString(),
      brandAttentionWeight: (this.props.overallScoreWeights['Brand Attention'] * 100).toString(),
      brandReputationWeight: (this.props.overallScoreWeights['Brand Reputation'] * 100).toString(),
      brandMomentumWeight: (this.props.overallScoreWeights['Brand Momentum'] * 100).toString(),
      errors: {},
      errorMessage: ''
    }));
  };

  onBrandPresenceWeightChange = (event) => {
    const brandPresenceWeight = event.currentTarget.value;
    this.setState(() => ({ brandPresenceWeight }));
  };

  onBrandAttentionWeightChange = (event) => {
    const brandAttentionWeight = event.currentTarget.value;
    this.setState(() => ({ brandAttentionWeight }));
  };

  onBrandReputationWeightChange = (event) => {
    const brandReputationWeight = event.currentTarget.value;
    this.setState(() => ({ brandReputationWeight }));
  };

  onBrandMomentumWeightChange = (event) => {
    const brandMomentumWeight = event.currentTarget.value;
    this.setState(() => ({ brandMomentumWeight }));
  };

  isValidForm = () => {
    const errors = {};
    let errorMessage = '';
    const brandPresenceWeight = parseFloat(this.state.brandPresenceWeight);
    const brandAttentionWeight = parseFloat(this.state.brandAttentionWeight);
    const brandReputationWeight = parseFloat(this.state.brandReputationWeight);
    const brandMomentumWeight = parseFloat(this.state.brandMomentumWeight);
    if (
      !this.state.brandPresenceWeight ||
      this.state.brandPresenceWeight.trim() === '' ||
      isNaN(brandPresenceWeight)
    ) {
      errors.brandPresenceWeight = true;
      errorMessage = 'Must provide numbers';
    }
    if (
      !this.state.brandAttentionWeight ||
      this.state.brandAttentionWeight.trim() === '' ||
      isNaN(brandAttentionWeight)
    ) {
      errors.brandAttentionWeight = true;
      errorMessage = 'Must provide numbers';
    }
    if (
      !this.state.brandReputationWeight ||
      this.state.brandReputationWeight.trim() === '' ||
      isNaN(brandReputationWeight)
    ) {
      errors.brandReputationWeight = true;
      errorMessage = 'Must provide numbers';
    }
    if (!(brandPresenceWeight + brandAttentionWeight + brandReputationWeight + brandMomentumWeight === 100)) {
      errors.overallScore = true;
      errorMessage = 'Overall scores must evaluate to 100';
    }
    if (
      !this.state.brandMomentumWeight ||
      this.state.brandMomentumWeight.trim() === '' ||
      isNaN(brandMomentumWeight)
    ) {
      errors.brandMomentumWeight = true;
      errorMessage = 'Must provide numbers';
    }

    this.setState(() => ({
      errors,
      errorMessage
    }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  updateWeights = () => {
    if (this.isValidForm()) {
      const overallScoreWeights = {
        'Brand Presence': parseFloat(this.state.brandPresenceWeight) / 100,
        'Brand Attention': parseFloat(this.state.brandAttentionWeight) / 100,
        'Brand Reputation': parseFloat(this.state.brandReputationWeight) / 100,
        'Brand Momentum': parseFloat(this.state.brandMomentumWeight) / 100,
      };
      this.props.updateOverallScoreWeights(overallScoreWeights);
      this.props.handleClose();
    }
  };

  render() {
    return (
      <Modal
        show={this.props.isOpen}
        onHide={this.props.handleClose}
        onEntering={this.onOpen}
        size="sm"
      >
        <Modal.Header
          className="bg-bops-blue text-light"
          closeButton
        >
          <Modal.Title>
            Overall Weights
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form>
              <Form.Group className="mb-1">
                <Form.Row>
                  <Col xs={4}>
                    <div className="d-inline-block w-50">
                      <Form.Control
                        className={this.state.errors.brandPresenceWeight?'border border-danger':''}
                        type="text"
                        value={this.state.brandPresenceWeight}
                        onChange={this.onBrandPresenceWeightChange}
                        size="sm"
                      />
                    </div>
                    <div className="d-inline-block ml-1">%</div>
                  </Col>
                  <Col>
                    <Form.Label className="mt-1">Presence</Form.Label>
                  </Col>
                </Form.Row>
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Row>
                  <Col xs={4}>
                    <div className="d-inline-block w-50">
                      <Form.Control
                        className={this.state.errors.brandAttentionWeight?'border border-danger':''}
                        type="text"
                        value={this.state.brandAttentionWeight}
                        onChange={this.onBrandAttentionWeightChange}
                        size="sm"
                      />
                    </div>
                    <div className="d-inline-block ml-1">%</div>
                  </Col>
                  <Col>
                    <Form.Label className="mt-1">Attention</Form.Label>
                  </Col>
                </Form.Row>
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Row>
                  <Col xs={4}>
                    <div className="d-inline-block w-50">
                      <Form.Control
                        className={this.state.errors.brandReputationWeight?'border border-danger':''}
                        type="text"
                        value={this.state.brandReputationWeight}
                        onChange={this.onBrandReputationWeightChange}
                        size="sm"
                      />
                    </div>
                    <div className="d-inline-block ml-1">%</div>
                  </Col>
                  <Col>
                    <Form.Label className="mt-1">Reputation</Form.Label>
                  </Col>
                </Form.Row>
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Row>
                  <Col xs={4}>
                    <div className="d-inline-block w-50">
                      <Form.Control
                        className={this.state.errors.brandMomentumWeight?'border border-danger':''}
                        type="text"
                        value={this.state.brandMomentumWeight}
                        onChange={this.onBrandMomentumWeightChange}
                        size="sm"
                      />
                    </div>
                    <div className="d-inline-block ml-1">%</div>
                  </Col>
                  <Col>
                    <Form.Label className="mt-1">Momentum</Form.Label>
                  </Col>
                </Form.Row>
              </Form.Group>
            </Form>
          </div>
          <div>
            <Alert show={Boolean(this.state.errorMessage)} variant="danger">
              {this.state.errorMessage}
            </Alert>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => this.props.handleClose()}
          >
            Close
          </Button>
          <Button variant="primary" onClick={this.updateWeights}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
};
