import { connect } from 'react-redux';
import Alerts from '../../components/alerts/Alerts';

const mapStateToProps = (state) => ({
  user: state.user,
  categories: state.categories,
  slackChannels: state.slackChannels
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
