import React from 'react';
import Joyride from 'react-joyride';
import history from '../../routers/history';

export default class NewUserGuidedTour extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      guidedTourRun: true,
      guidedTourStepIndex: 0,
    };
  }

  componentDidMount() {

  };

  componentDidUpdate(prevProps, prevState) {

  };

  componentWillUnmount() {

  };

  guidedTourCallback = (event) => {
    const { action, index, status, type } = event;
    if (
      index === 5 &&
      type === 'step:after'
    ) {
      // after reports step, route to first category to continue tour
      if (this.props.myCategoryBrands.length > 0) {
        this.setState(() => ({ guidedTourRun: false }));
        history.push(`/category-dashboard/${encodeURIComponent(this.props.myCategoryBrands[0].category.name)}#analysis`);
        setTimeout(() => {
          this.setState(() => ({
            guidedTourRun: true,
            guidedTourStepIndex: 6
          }));
        }, 1000);
      }
    } else if (
      index === 7 &&
      type === 'step:after'
    ) {
      // after category tour, route to brand to continue tour
      if (this.props.myCategoryBrands.length > 0) {
        this.setState(() => ({ guidedTourRun: false }));
        history.push(`/category/${encodeURIComponent(this.props.myCategoryBrands[0].category.name)}/brand/${encodeURIComponent(this.props.myCategoryBrands[0].brand.name)}#analysis`);
        setTimeout(() => {
          this.setState(() => ({
            guidedTourRun: true,
            guidedTourStepIndex: 8
          }));
        }, 1000);
      }
    } else if (type === 'step:after') {
      this.setState(() => ({ guidedTourStepIndex: index + 1 }));
    }
  };

  render () {
    return (
      <Joyride
        run={this.state.guidedTourRun}
        stepIndex={this.state.guidedTourStepIndex}
        steps={[
          {
            target: '#competitive-landscape',
            title: 'Competitive Landscape',
            content: "This is where you'll see how your brand compares to key competitors.",
            placement: 'right-start',
            disableBeacon: true, // this auto starts first tooltip
          },
          {
            target: '.compare-brands',
            title: 'Compare Brands',
            content: 'Use Compare Brands to return to the big picture for the category, including Share Of values.',
            placement: 'right',
          },
          {
            target: '.left-menu-brand.nav-link-highlight',
            title: 'Brand',
            content: 'Click a brand to see its metrics and content.',
            placement: 'right',
          },
          {
            target: '#internal-stack',
            title: 'Internal Stack',
            content: 'Connect your internal stack to see cross-channel impacts. See your results, all in one place.',
            placement: 'right-start',
          },
          {
            target: '#custom-reports',
            title: 'Reports',
            content: 'Review the full monthly report or create your own to share with your team.',
            placement: 'right-start',
          },
          {
            target: '.compare-brands',
            title: 'Compare Brands',
            content: 'Click Compare Brands to see the big picture for the category.',
            placement: 'right',
          },
          {
            target: '#ranksButton',
            title: 'Ranks',
            content: 'Understand your performance vs. competitors with Brand Scores and Share Of measures.',
            placement: 'auto',
          },
          {
            target: '.left-menu-brand.nav-link-highlight',
            title: 'Brand',
            content: 'Click a brand to see its metrics and content.',
            placement: 'right',
          },
          {
            target: '#pillarsButton',
            title: 'Pillars',
            content: 'Look deeper into the recommendations and metrics for your brand.',
            placement: 'auto',
          },
        ]}
        callback={this.guidedTourCallback}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        hideBackButton={true}
        disableScrollParentFix={true}
        scrollOffset={100}
        styles={{
          options: {
            arrowColor: '#4fa2f3',
            backgroundColor: '#4fa2f3',
            textColor: '#ffffff',
            primaryColor: '#dc3545',
          },
          tooltipContainer: {
            textAlign: 'left',
          },
          tooltipContent: {
            padding: '20px 15px 10px',
            fontSize: '17px',
          },
        }}
      />
    );
  }
};
