import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';
import ClipLoader from 'react-spinners/ClipLoader';
import NewsManualAddModal from './NewsManualAddModal';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import EditEventFormModal from '../eventTimeline/EditEventFormModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullhorn,
  faChartLine,
  faCommentDots,
  faEllipsisH,
  faEnvelope,
  faImage,
  faLink,
  faNewspaper,
  faPlus,
  faUnlink,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { getDomainFromUrl } from '../../utils/urls';
import { numberWithCommas } from '../../utils/numbers';
import { CONTENT_SCRAPING_ENDPOINT, TOPIC_MODELS_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class NewsDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedNews: undefined,
      selectedNewsLoading: false,
      keywords: [],
      keywordsLoading: false,
      brandMentions: [],
      brandMentionsLoading: false,
      awisStats: {},
      awisStatsLoading: false,
      semrushStats: {},
      semrushStatsLoading: false,
      newsManualAddModalOpen: false,
      removeArticleConfirmationModalOpen: false,
      removeArticle: undefined,
      editEventFormModalOpen: false,
      selectedEvent: undefined,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.selectedNews) {
      this.selectNewsArticle(this.props.selectedNews);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedNews !== this.props.selectedNews) {
      if (this.newsArticleDisplay) {
        this.newsArticleDisplay.scrollTop = 0;
      }
      this.selectNewsArticle(this.props.selectedNews);
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  selectNewsArticle = (article) => {
    if (this.state.selectedNews !== article) {
      if (
        this.props.brand &&
        article &&
        article._index === 'articles' &&
        !article._source.text
      ) {
        this.setState(() => ({
          selectedNews: article,
          selectedNewsLoading: true,
        }));
        // fetch article details
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/news-from-es?es_document_id=${article.source_document_id}`,
          HEADERS
        ).then(response => {
          const articleSource = response.data;
          articleSource.brand_types = article.brand_types;
          articleSource.domain_favicon = article._source.domain_favicon;
          articleSource.country_code = article.country_code;
          articleSource.country = article.country;
          articleSource.region = article.region;
          article._source = articleSource;
          // mark/highlight brand name within article text
          const originalText = articleSource.text;
          const regex = new RegExp(this.props.brand.name, 'ig');
          article._source.text = originalText.replaceAll(
            regex,
            `<mark style="background-color:#ffc107">$&</mark>`
          );
          if (this.state.isMounted) {
            this.setState(
              () => ({
                selectedNews: article,
                selectedNewsLoading: false,
              }),
              () => this.fetchAdditionalArticleData()
            );
          }
        }).catch(error => {
          console.error('Error: failed to fetch news article document.');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          if (this.state.isMounted) {
            this.setState(
              () => ({
                selectedNews: undefined,
                selectedNewsLoading: false,
              }),
              () => this.fetchAdditionalArticleData()
            );
          }
        });
      } else {
        this.setState(
          () => ({ selectedNews: article }),
          () => this.fetchAdditionalArticleData()
        );
      }
    }
  };

  fetchAdditionalArticleData = () => {
    this.fetchKeywords();
    this.fetchBrandsMentioned();
    this.fetchAwisStats();
    this.fetchSemrushStats();
  };

  fetchKeywords = () => {
    if (this.state.selectedNews) {
      this.setState(() => ({ keywordsLoading: true }));
      const text = this.state.selectedNews._source.text_cleaned || this.state.selectedNews._source.text;
      axios.post(
        `${TOPIC_MODELS_ENDPOINT}/api/topics`,
        { content: text },
        HEADERS
      ).then(response => {
        const classifierResults = response.data;
        const keywords = [];
        if (classifierResults.keywords.length > 0) {
          for (const keyword of classifierResults.keywords) {
            keywords.push(keyword.word);
          }
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            keywords,
            keywordsLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch news article keywords.');
        if (this.state.isMounted) {
          this.setState(() => ({
            keywords: {},
            keywordsLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  }

  fetchBrandsMentioned = () => {
    if (this.state.selectedNews) {
      this.setState(() => ({ brandMentionsLoading: true }));
      let source = this.state.selectedNews._index;
      if (this.state.selectedNews._index === 'articles') {
        source = 'news';
      }
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/tagged-ner-article?source=${source}&source_document_id=${this.state.selectedNews._id}`,
        HEADERS
      ).then(response => {
        const brandMentionsMap = {};
        const brandMentions = [];
        for (const nerTag of response.data) {
          if (nerTag.company_name && !brandMentionsMap[nerTag.company_name]) {
            brandMentionsMap[nerTag.company_name] = true;
            brandMentions.push(nerTag.company_name);
          }
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            brandMentions,
            brandMentionsLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch tagged ner article for brand mentions.');
        if (this.state.isMounted) {
          this.setState(() => ({
            brandMentions: [],
            brandMentionsLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchAwisStats = () => {
    if (this.state.selectedNews) {
      const domain = getDomainFromUrl(this.state.selectedNews._source.url || '');
      if (domain) {
        this.setState(() => ({ awisStatsLoading: true }));
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/awis-news-sites-stat?domain_name=${domain}`,
          HEADERS
        ).then(response => {
          let awisStats = {};
          if (response.data && response.data.length > 0) {
            awisStats = response.data[0];
          }
          if (this.state.isMounted) {
            this.setState(() => ({
              awisStats,
              awisStatsLoading: false
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch awis stats for domain.');
          if (this.state.isMounted) {
            this.setState(() => ({
              awisStats: {},
              awisStatsLoading: false
            }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      } else {
        this.setState(() => ({
          awisStats: {}
        }));
      }
    }
  };

  fetchSemrushStats = () => {
    if (this.state.selectedNews) {
      const domain = getDomainFromUrl(this.state.selectedNews._source.url || '');
      if (domain) {
        this.setState(() => ({ semrushStatsLoading: true }));
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/semrush-news-sites-stats?domain_name=${domain}`,
          HEADERS
        ).then(response => {
          let semrushStats = {};
          if (response.data && response.data.length > 0) {
            semrushStats = response.data[0];
          }
          if (this.state.isMounted) {
            this.setState(() => ({
              semrushStats,
              semrushStatsLoading: false
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch semrush stats for domain.');
          if (this.state.isMounted) {
            this.setState(() => ({
              semrushStats: {},
              semrushStatsLoading: false
            }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      } else {
        this.setState(() => ({
          semrushStats: {}
        }));
      }
    }
  };

  openNewsManualAddModal = () => {
    this.setState(() => ({ newsManualAddModalOpen: true }));
  };

  closeNewsManualAddModal = () => {
    this.setState(() => ({ newsManualAddModalOpen: false }));
  };

  openRemoveArticleConfirmationModal = (article) => {
    this.setState(() => ({
      removeArticleConfirmationModalOpen: true,
      removeArticle: article,
    }));
  };

  closeRemoveArticleConfirmationModal = () => {
    this.setState(() => ({ removeArticleConfirmationModalOpen: false }));
  };

  removeArticle = () => {
    if (this.state.removeArticle) {
      const removeRequests = [];
      for (const brandType of this.state.removeArticle.brand_types) {
        let brandIdentifier;
        if (brandType === 'product') {
          brandIdentifier = this.props.brand.id;
        } else if (brandType === 'company') {
          brandIdentifier = this.props.brand.company_id;
        }

        removeRequests.push(
          axios.delete(
            `${CONTENT_SCRAPING_ENDPOINT}/api/news-article-brand-tags/es_document_id/${this.state.removeArticle._id}?brand_type=${brandType}&brand_identifier=${brandIdentifier}`,
            HEADERS
          ).then(response => {
            return response;
          }).catch(error => {
            console.error(`Error: failed to remove ${brandType} news article.`);
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
          })
        );
      }

      Promise.all(removeRequests).then(responses => {
        this.props.refreshNewsArticles();
      });
    }
  };

  openEditEventFormModal = (newsArticle) => {
    if (newsArticle) {
      const selectedEvent = {
        customer_id: this.props.user.customerId,
        user_id: this.props.user.id,
        product_brand_id: this.props.brand.id,
        summary: newsArticle._source.title,
        is_public: 0,
        event_created_on: moment(newsArticle._source.published_at).utc()
      };
      this.setState(() => ({
        selectedEvent,
        editEventFormModalOpen: true
      }));
    }
  };

  closeEditEventFormModal = () => {
    this.setState(() => ({
      selectedEvent: undefined,
      editEventFormModalOpen: false
    }));
  };

  refreshEvents = () => {
    // try and refresh events and detect event to hide button?
  }

  render () {
    return (
      <div>
        <Row className="pt-2">
          <Col xs={12} md={3}>
            <div
              className="pre-scrollable container px-0 bg-white border rounded"
              style={{
                overflow: 'auto',
                fontSize: '10pt',
                minHeight: '400px',
                maxHeight: '600px',
              }}
            >
              { this.props.newsResults.map((article, i) => {
                  return (
                    <div
                      key={`na-${i}`}
                      className={
                        (this.state.selectedNews && this.state.selectedNews._id === article._id) ?
                          'px-2 bg-primary text-light rounded' :
                          `px-2${!article._source.brand_types.includes('product') ? ' bg-grey': ''}`
                      }
                      onClick={() => this.selectNewsArticle(article)}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className="py-2">
                        <div
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          { article._index === 'articles' &&
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faNewspaper}
                            />
                          }
                          { article._index === 'press-releases' &&
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faBullhorn}
                            />
                          }
                          { article._index === 'newsletters' &&
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faEnvelope}
                            />
                          }
                          <span
                            dangerouslySetInnerHTML={{ __html: article._source.title }}
                          />
                        </div>
                        <div>
                          <div className="d-inline-block mr-2">
                            {moment.utc(article._source.published_at).local().format('MM/DD')}
                          </div>
                          { article._source.domain_favicon &&
                            <img
                              className="mr-1 align-top"
                              src={article._source.domain_favicon}
                              alt=""
                              crossOrigin="anonymous"
                              height="14px"
                              style={{ marginTop: '2px' }}
                              onError={(img) => {
                                img.target.src='/images/image.png';
                              }}
                            />
                          }
                          { ! article._source.domain_favicon &&
                            <FontAwesomeIcon
                              className="mr-1"
                              icon={faImage}
                              color="#b3b3b3"
                            />
                          }
                          <div className="d-inline-block">
                            {article._source.domain}
                          </div>
                        </div>
                      </div>
                      <hr className="m-0"/>
                    </div>
                  )
                })
              }
            </div>
            <button
              className="px-1 py-0 bg-white border border-top-0 rounded-bottom"
              onClick={() => this.openNewsManualAddModal()}
            >
              <FontAwesomeIcon
                className="mr-1"
                icon={faPlus}
                color="#28a745"
              />
              <span style={{ fontSize: '10pt' }}>Add an article</span>
            </button>
          </Col>
          <Col xs={12} md={9}>
            { this.state.selectedNews &&
              <div>
                { this.state.selectedNewsLoading &&
                  <div
                    className="m-4 text-center"
                    style={{ minHeight: '600px' }}
                  >
                    <ClipLoader size={100}/>
                  </div>
                }
                { !this.state.selectedNewsLoading &&
                  <div>
                    <Card className="mb-4">
                      <Card.Header className="bg-dark text-light font-weight-bold">
                        Summary
                      </Card.Header>
                      <Card.Body>
                        <div>
                          { this.state.selectedNews._index === 'articles' &&
                            <Button
                              className="float-right"
                              variant="light"
                              onClick={() => this.openRemoveArticleConfirmationModal(this.state.selectedNews)}
                              size="sm"
                            >
                              <FontAwesomeIcon className="mr-2" icon={faUnlink} />
                              Remove
                            </Button>
                          }
                          { this.state.selectedNews.brand_types &&
                            <div>
                              { this.state.selectedNews.brand_types.includes('company') &&
                                <Badge
                                  className="mr-1"
                                  variant="warning"
                                  pill
                                >
                                  Corporate
                                </Badge>
                              }
                              { this.state.selectedNews.brand_types.includes('product') &&
                                <Badge
                                  className="mr-1"
                                  variant="warning"
                                  pill
                                >
                                  Product
                                </Badge>
                              }
                              { this.state.selectedNews.brand_types.includes('portfolio') &&
                                <Badge
                                  className="mr-1"
                                  variant="warning"
                                  pill
                                >
                                  Portfolio
                                </Badge>
                              }
                            </div>
                          }
                          { this.state.selectedNews._source['IAB-topic-level1'] &&
                            <div>
                              <div className="d-inline-block mr-2 font-weight-bold">IAB:</div>
                              {`${[
                                    this.state.selectedNews._source['IAB-topic-level1'],
                                    this.state.selectedNews._source['IAB-topic-level2'],
                                    this.state.selectedNews._source['IAB-topic-level3'],
                                    this.state.selectedNews._source['IAB-topic-level4']
                                  ].filter(Boolean).join(', ')
                                }`
                              }
                            </div>
                          }
                          { this.state.selectedNews._source['IPTC-topic-level1'] &&
                            <div>
                              <div className="d-inline-block mr-2 font-weight-bold">IPTC:</div>
                              {`${[
                                    this.state.selectedNews._source['IPTC-topic-level1'],
                                    this.state.selectedNews._source['IPTC-topic-level2'],
                                    this.state.selectedNews._source['IPTC-topic-level3'],
                                    this.state.selectedNews._source['IPTC-topic-level4']
                                  ].filter(Boolean).join(', ')
                                }`
                              }
                            </div>
                          }
                          <div>
                            <div className="d-inline-block mr-2 font-weight-bold">
                              Keywords:
                            </div>
                            <div className="d-inline-block">
                              { this.state.keywordsLoading &&
                                <ClipLoader size={10}/>
                              }
                              { !this.state.keywordsLoading &&
                                <React.Fragment>
                                  {this.state.keywords.join(', ')}
                                </React.Fragment>
                              }
                            </div>
                          </div>
                          <div>
                            <div className="d-inline-block mr-2 font-weight-bold">
                              Brand Mentions:
                            </div>
                            <div className="d-inline-block">
                              { this.state.brandMentionsLoading &&
                                <ClipLoader size={10}/>
                              }
                              { !this.state.brandMentionsLoading &&
                                <React.Fragment>
                                  {this.state.brandMentions.join(', ')}
                                </React.Fragment>
                              }
                            </div>
                          </div>
                          { (this.state.selectedNews._source.url && this.state.selectedNews._index !== 'newsletters') &&
                            <React.Fragment>
                              <div>
                                <div className="d-inline-block mr-2 font-weight-bold">
                                  Site Rank:
                                </div>
                                <div className="d-inline-block">
                                  { this.state.awisStatsLoading &&
                                    <ClipLoader size={10}/>
                                  }
                                  { !this.state.awisStatsLoading &&
                                    <React.Fragment>
                                      { (this.state.awisStats && this.state.awisStats.last_7_days_rank_value) ?
                                          numberWithCommas(this.state.awisStats.last_7_days_rank_value) :
                                          ''
                                      }
                                    </React.Fragment>
                                  }
                                </div>
                              </div>
                              <div>
                                <div className="d-inline-block mr-2 font-weight-bold">
                                  Reach per Million:
                                </div>
                                <div className="d-inline-block">
                                  { this.state.awisStatsLoading &&
                                    <ClipLoader size={10}/>
                                  }
                                  { !this.state.awisStatsLoading &&
                                    <React.Fragment>
                                      { (this.state.awisStats && this.state.awisStats.last_7_days_reach_per_million) ?
                                          numberWithCommas(this.state.awisStats.last_7_days_reach_per_million) :
                                          ''
                                      }
                                    </React.Fragment>
                                  }
                                </div>
                              </div>
                              <div>
                                <div className="d-inline-block mr-2 font-weight-bold">
                                  Organic Traffic:
                                </div>
                                <div className="d-inline-block">
                                  { this.state.semrushStatsLoading &&
                                    <ClipLoader size={10}/>
                                  }
                                  { !this.state.semrushStatsLoading &&
                                    <React.Fragment>
                                      { (this.state.semrushStats && this.state.semrushStats.organic_traffic) ?
                                          `${numberWithCommas(this.state.semrushStats.organic_traffic)} U.S. visitors` :
                                          ''
                                      }
                                    </React.Fragment>
                                  }
                                </div>
                              </div>
                            </React.Fragment>
                          }
                        </div>
                      </Card.Body>
                    </Card>
                    <div className="bg-white rounded border p-2">
                      <div className="p-2">
                        <div className="my-1">
                          { this.state.selectedNews._source.domain_favicon &&
                            <img
                              className="mr-2"
                              src={this.state.selectedNews._source.domain_favicon}
                              alt=""
                              crossOrigin="anonymous"
                              width="20px"
                              onError={(img) => {
                                img.target.src='/images/image.png';
                              }}
                            />
                          }
                          <div
                            className="d-inline-block"
                            dangerouslySetInnerHTML={{ __html: this.state.selectedNews._source.title }}
                            style={{ fontWeight: 'bold' }}
                          />
                          { this.state.selectedNews._source.url &&
                            <FontAwesomeIcon
                              className="ml-2"
                              icon={faLink}
                              color="#4fa2f3"
                              onClick={() => window.open(this.state.selectedNews._source.url, '_blank')}
                              style={{ cursor: 'pointer' }}
                            />
                          }
                          <div className="d-inline-block mt-n1 float-right">
                            <Button
                              variant="success"
                              onClick={() => this.openEditEventFormModal(this.state.selectedNews)}
                              size="sm"
                            >
                              Add as Event
                            </Button>
                          </div>
                        </div>
                      </div>
                      <hr className="m-0"/>
                      { this.state.selectedNews.show_site &&
                        <div
                          className="p-2 container"
                          style={{
                            fontSize: '11pt',
                            whiteSpace: 'pre-wrap'
                          }}
                          dangerouslySetInnerHTML={{ __html: this.state.selectedNews._source.text }}
                        />
                      }
                      { !this.state.selectedNews.show_site &&
                        <div className="p-2">
                          This mention is on a paid site. You may need a subscription. You may view it
                          <a
                            className="pl-1"
                            href={this.state.selectedNews._source.url}
                            target="_blank"
                          >
                            here
                          </a>
                          .
                        </div>
                      }
                    </div>
                  </div>
                }
              </div>
            }
          </Col>
        </Row>

        <NewsManualAddModal
          isOpen={this.state.newsManualAddModalOpen}
          handleClose={this.closeNewsManualAddModal}
          productBrand={this.props.brand}
          refreshNewsArticles={this.props.refreshNewsArticles}
        />
        <DeleteConfirmationModal
          isOpen={this.state.removeArticleConfirmationModalOpen}
          handleClose={this.closeRemoveArticleConfirmationModal}
          deleteMessage="Are you sure you would like to remove the article?"
          deleteButtonLabel="Remove"
          deleteResource={this.removeArticle}
        />
        <EditEventFormModal
          isOpen={this.state.editEventFormModalOpen}
          handleClose={this.closeEditEventFormModal}
          event={this.state.selectedEvent}
          refreshEvents={this.refreshEvents}
        />
      </div>
    );
  }
};
