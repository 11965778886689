import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import BrandTabOverview from '../../brands/BrandTabOverview';
import BrandChannelSummaryContainer from '../../../containers/brands/BrandChannelSummaryContainer';
import SearchEngineReport from './SearchEngineReport';
import SerpDetails from '../../searchEngines/SerpDetails';
import SearchEngines from '../../searchEngines/SearchEngines';
import CategoryShareOfSearch from './CategoryShareOfSearch';
import CategoryShareOfSearchResults from './CategoryShareOfSearchResults';
import BrandMetricsByMonthTableContainer from '../../../containers/metrics/BrandMetricsByMonthTableContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faChartPie,
  faBars,
  faSearchPlus,
  faCubes
} from '@fortawesome/free-solid-svg-icons';
import { createUserActivityLog } from '../../../utils/users';
import history from '../../../routers/history';

export default class CategorySearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'highlights'
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let selectedTab = hash.indexOf('-')>0 ? hash.substring(hash.indexOf('-')+1) : 'highlights';
      this.setState(() => ({ selectedTab }));
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let selectedTab = hash.indexOf('-')>0 ? hash.substring(hash.indexOf('-')+1) : 'highlights';
      this.setState(() => ({ selectedTab }));
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  changeSelectedTab = (selectedTab, subTabName) => {
    if (selectedTab !== this.state.selectedTab) {
      history.push(`#search-${selectedTab}`);
      this.setState(() => ({ selectedTab }));
      if (subTabName) {
        let type = 'Category';
        if (this.props.displayType === 'brand') {
          type = 'Brand'
        }
        createUserActivityLog(
          this.props.user,
          `${type} Tabs: Search: ${subTabName}`,
          this.props.category,
        )
      }
    }
  }

  render () {
    return (
      <div className="p-4">
        <div className="mb-4">
          <button
            id="categorySearchHighlightsButton"
            type="button"
            className= { this.state.selectedTab === 'highlights' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('highlights', 'Highlights')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faChartLine} />
            </div>
            <div className="d-inline-block">
              Highlights
            </div>
          </button>
          <button
            id="categorySerpDetailsButton"
            type="button"
            className= { this.state.selectedTab === 'details' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('details', 'SERP Details')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faBars} />
            </div>
            <div className="d-inline-block">
              SERP Details
            </div>
          </button>
          <button
            id="categorySerpSamplesButton"
            type="button"
            className= { this.state.selectedTab === 'samples' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('samples', 'SERP Samples')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faSearchPlus} />
            </div>
            <div className="d-inline-block">
              SERP Samples
            </div>
          </button>
          <button
            id="categoryShareOfSearchResultsButton"
            type="button"
            className= { this.state.selectedTab === 'shareOfSearchResults' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('shareOfSearchResults', 'Share of Search Results')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faChartPie} />
            </div>
            <div className="d-inline-block">
              Share of Search Results
            </div>
          </button>
          <button
            id="categoryShareOfSearchButton"
            type="button"
            className= { this.state.selectedTab === 'shareOfSearch' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('shareOfSearch', 'Share of Branded Search')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faChartPie} />
            </div>
            <div className="d-inline-block">
              Share of Branded Search
            </div>
          </button>
        </div>
        <div
          className="pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 200px)',
            maxHeight: 'calc(100vh - 200px)',
            overflow: 'auto',
            overflowX: 'hidden'
          }}
        >
          { this.state.selectedTab === 'highlights' &&
            <div>
              { this.props.displayType !== 'brand' &&
                <BrandTabOverview
                  overviewType="Search Highlights"
                />
              }
              { this.props.displayType === 'brand' &&
                <div>
                  <div>
                    <BrandChannelSummaryContainer
                      channel="Search Engine Results"
                      category={this.props.category}
                      brand={this.props.brand}
                    />
                  </div>
                  <div>
                    <BrandMetricsByMonthTableContainer
                      type="Search Engine Results"
                      siteName="SERP-API"
                      category={this.props.category}
                      brand={this.props.brand}
                      audienceProfileId={this.props.category.audience_profile_id}
                    />
                  </div>
                  <div className="mt-5 mb-4 band-separator"></div>
                </div>
              }
              <SearchEngineReport
                user={this.props.user}
                sixMonths={this.props.sixMonths}
                selectedMonth={this.props.selectedMonth}
                updateSelectedMonth={this.props.updateSelectedMonth}
                categoryId={this.props.categoryId}
                category={this.props.category}
              />
            </div>
          }
          { this.state.selectedTab === 'details' &&
            <div>
              <BrandTabOverview
                overviewType="SERP Details"
              />
              <SerpDetails
                user={this.props.user}
                categoryId={this.props.categoryId}
                categoryType={this.props.category.category_type}
                category={this.props.category}
              />
            </div>
          }
          { this.state.selectedTab === 'samples' &&
            <div>
              <BrandTabOverview
                overviewType="SERP Samples"
              />
              <SearchEngines
                user={this.props.user}
                categoryId={this.props.categoryId}
                categoryType={this.props.category.category_type}
                category={this.props.category}
              />
            </div>
          }
          { this.state.selectedTab === 'shareOfSearchResults' &&
            <div>
              <BrandTabOverview
                overviewType="Share of Search Results"
              />
              <CategoryShareOfSearchResults
                user={this.props.user}
                categoryId={this.props.categoryId}
                categoryType={this.props.category.category_type}
                category={this.props.category}
              />
            </div>
          }
          { this.state.selectedTab === 'shareOfSearch' &&
            <div>
              <BrandTabOverview
                overviewType="Share of Branded Search"
              />
              <CategoryShareOfSearch
                user={this.props.user}
                categoryId={this.props.categoryId}
                categoryType={this.props.category.category_type}
                category={this.props.category}
              />
            </div>
          }
        </div>
      </div>
    );
  }
};
