import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';
import { numberWithCommas } from '../../utils/numbers';

export default class CategoryTwitterPromotersGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
    }
  }

  render () {
    return (
      <div>
      { this.props.categoryTwitterDataLoading &&
        <div className="text-center">
          <ClipLoader size={100}/>
        </div>
      }
      { !this.props.categoryTwitterDataLoading &&
        <ResponsiveContainer width="99%" height={200}>
          <ComposedChart data={this.props.categoryTwitterData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month"/>
            <YAxis
              dataKey={this.props.highestDataKey}
              tickFormatter={(count) => {
                return numberWithCommas(count);
              }}
            />
            <Tooltip
              formatter={(value) => numberWithCommas(value)}
            />
            <Area
              name={
                this.props.categoryKingName ?
                  `Leader, ${this.props.categoryKingName} Promoters` :
                  'Category King Promoters'
              }
              type="monotone"
              dataKey="category_king_promoters_count"
              stroke="#007bff"
              fillOpacity={.4}
            />
            <Line
              name="Category Average Promoters"
              type="monotone"
              dataKey="category_avg_promoters_count"
              strokeDasharray="10 10"
              stroke="#009900"
              strokeWidth={3}
            />
            <Line
              name={
                this.props.ownersBrandName ?
                  `${this.props.ownersBrandName} Promoters` :
                  'Your Brand Promoters'
              }
              type="monotone"
              dataKey="owned_brand_promoters_count"
              stroke="#ffc107"
              strokeWidth={3}
            />
          </ComposedChart>
        </ResponsiveContainer>
      }
      </div>
    );
  }
};
