import React from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CustomerSearchComboBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  loadCustomerOptions = (searchInput) => {
    return axios.get(
      `${LISTEN_ENDPOINT}/api/customers/search/${searchInput}`,
      HEADERS
    ).then(response => {
      const customers = response.data;
      const customerOptions = [];
      for (const i in customers) {
        customerOptions.push({
          value: customers[i].name,
          label: customers[i].name,
          customer: customers[i]
        });
      }
      return customerOptions;
    }).catch(error => {
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  render() {
    return (
      <AsyncSelect
        id="customerSearchComboBox"
        classNamePrefix="represent-as-select"
        cacheOptions
        value={null}
        loadOptions={this.loadCustomerOptions}
        onChange={this.props.onSelect}
        placeholder={this.props.placeholder || "Search for customers..."}
      />
    )
  }
};
