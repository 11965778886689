import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import ClipLoader from 'react-spinners/ClipLoader';
import RangeSlider from 'react-bootstrap-range-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faPause,
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faVolumeHigh,
  faVolumeXmark,
  faRotate
} from '@fortawesome/free-solid-svg-icons';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';
import { isMobile, isDesktop, deviceType, browserName, osName } from 'react-device-detect';
import { isOdd } from '../../utils/numbers';
import { LISTEN_ENDPOINT, LAMBDA_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CustomReports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showInitialPlay: true,
      carouselPlay: false,
      widgetIndex: 0,
      publishToken: '',
      customReport: undefined,
      customReportLoading: false,
      customReportNotFound: false,
      companyDetails: undefined,
      voicePlaybackRate: 1,
      noVoiceSlideDelay: 3000,
      voiceOnSlideDelay: 500,
      showNotes: true,
      showAudio: false,
      cacheBreaker: Math.random().toString(36).substring(7),
      customReportView: undefined,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.publishToken
    ) {
      this.setState(
        () => ({ publishToken: this.props.match.params.publishToken }),
        () => this.fetchCustomReport()
      );
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.publishToken !== this.props.match.params.publishToken) {
      this.setState(
        () => ({ publishToken: this.props.match.params.publishToken }),
        () => this.fetchCustomReport()
      );
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchCustomReport = () => {
    if (this.state.publishToken) {
      this.setState(() => ({
        customReport: undefined,
        customReportLoading: true,
        customReportNotFound: false
      }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/custom-report-published?publish_token=${this.state.publishToken}`,
        HEADERS
      ).then(response => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.fetchCompanyDetails(response.data[0].company_id);
          this.createCustomReportView(response.data[0].id, response.data[0].publish_token);
          let voicePlaybackRate = 1;
          if (response.data[0].voice_playback_rate) {
            voicePlaybackRate = response.data[0].voice_playback_rate;
          }
          let noVoiceSlideDelay = 3000;
          if (response.data[0].no_voice_slide_delay_seconds) {
            noVoiceSlideDelay = response.data[0].no_voice_slide_delay_seconds * 1000;
          }
          let voiceOnSlideDelay = 500;
          if (response.data[0].voice_on_slide_delay_seconds) {
            voiceOnSlideDelay = response.data[0].voice_on_slide_delay_seconds * 1000;
          }
          this.setState(() => ({
            customReport: response.data[0],
            customReportLoading: false,
            voicePlaybackRate,
            noVoiceSlideDelay,
            voiceOnSlideDelay,
          }), () => this.loadWidgetAudio());
        } else {
          this.setState(() => ({
            customReportLoading: false,
            customReportNotFound: true,
            voicePlaybackRate: 1,
            noVoiceSlideDelay: 3000
          }));
        }
      }).catch(error => {
        console.error('Error: failed find published report.');
        if (this.state.isMounted) {
          this.setState(() => ({
            customReportLoading: false,
            customReportNotFound: true,
            voicePlaybackRate: 1,
            noVoiceSlideDelay: 3000
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchCompanyDetails = (companyId) => {
    if (companyId) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/companies/${companyId}`,
        HEADERS
      ).then(response => {
        const companyDetails = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({ companyDetails }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch company details.');
        if (this.state.isMounted) {
          this.setState(() => ({ companyDetails: undefined }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  createCustomReportView = (customReportId, reportPublishToken) => {
    let formattedDeviceType;
    if (isDesktop) {
      formattedDeviceType = 'desktop';
    } else if (isMobile) {
      formattedDeviceType = 'mobile';
    } else {
      formattedDeviceType = deviceType;
    }

    const customReportView = {
      custom_report_id: customReportId,
      report_publish_token: reportPublishToken,
      device_type: formattedDeviceType,
      browser: browserName,
      os_name: osName
    };

    axios.post(
      `${LISTEN_ENDPOINT}/api/custom-report-views`,
      customReportView,
      HEADERS
    ).then(response => {
      const customReportView = response.data;
      if (this.state.isMounted) {
        this.setState(() => ({ customReportView }));
      }
    }).catch(error => {
      console.error('Error: failed to create custom report view.');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  customReportViewCompleted = () => {
    if (this.state.customReportView) {
      axios.put(
        `${LISTEN_ENDPOINT}/api/custom-report-views/${this.state.customReportView.id}`,
        { view_completed: true },
        HEADERS
      ).catch(error => {
        console.error('Error: failed to update custom report view.');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onInitialStart = () => {
    this.widgetNotesAudio.playbackRate = this.state.voicePlaybackRate;
    this.widgetNotesAudio.play();
    this.setState(() => ({
      showInitialPlay: false
    }), () => this.onCarouselPlay());
  }

  onFirstWidget = () => {
    this.onCarouselSelect(0);
  };

  onPreviousWidget = () => {
    this.onCarouselSelect(this.state.widgetIndex-1);
  };

  onNextWidget = () => {
    this.onCarouselSelect(this.state.widgetIndex+1);
  };

  onLastWidget = () => {
    if (this.state.customReport && this.state.customReport.widgets) {
      this.onCarouselSelect(this.state.customReport.widgets.length - 1);
    }
  };

  onCarouselSelect = (widgetIndex) => {
    this.clearCurrentTimeout();
    this.setState(
      () => ({ widgetIndex }),
      () => this.loadWidgetAudio()
    );
  };

  onCarouselPause = (event) => {
    this.clearCurrentTimeout();
    if (this.widgetNotesAudio) {
      this.widgetNotesAudio.pause();
      if (event.type === 'click') {
        this.setState(() => ({ carouselPlay: false }));
      }
    };
  };

  onCarouselPlay = () => {
    this.setState(() => ({ carouselPlay: true }));
    if (
      this.state.customReport &&
      this.state.customReport.widgets[this.state.widgetIndex] &&
      this.state.customReport.widgets[this.state.widgetIndex].voice_url
    ) {
      if (this.widgetNotesAudio) {
        this.widgetNotesAudio.playbackRate = this.state.voicePlaybackRate;
        this.widgetNotesAudio.play();
      }
    } else {
      this.currentTimeoutId = setTimeout(() => this.onAudioEnd(), this.state.noVoiceSlideDelay);
    }
  };

  clearCurrentTimeout = () => {
    if (this.currentTimeoutId) {
      clearTimeout(this.currentTimeoutId);
      this.currentTimeoutId = null;
    }
  };

  loadWidgetAudio = () => {
    if (this.widgetNotesAudio) {
      this.widgetNotesAudio.load();
      if (this.state.carouselPlay) {
        if (
          this.state.customReport &&
          this.state.customReport.widgets[this.state.widgetIndex] &&
          this.state.customReport.widgets[this.state.widgetIndex].voice_url
        ) {
          this.playWidgetAudio();
        } else {
          this.currentTimeoutId = setTimeout(() => this.onAudioEnd(), this.state.noVoiceSlideDelay)
        }
      }
    }
    // if second to last slide is loaded, trigger views completed
    // second to last was chosen because of skip to last button
    if (this.state.widgetIndex === this.state.customReport.widgets.length - 2) {
      this.customReportViewCompleted();
    }
  };

  playWidgetAudio = () => {
    this.currentTimeoutId = setTimeout(() => {
      if (this.widgetNotesAudio) {
        this.widgetNotesAudio.playbackRate = this.state.voicePlaybackRate;
        this.widgetNotesAudio.play();
      }
    }, this.state.voiceOnSlideDelay);
  };

  onAudioEnd = () => {
    if (
      this.state.customReport &&
      this.state.customReport.widgets[this.state.widgetIndex+1]
    ) {
      this.currentTimeoutId = setTimeout(() => {
        this.setState(
          () => ({ widgetIndex: this.state.widgetIndex + 1 }),
          () => this.loadWidgetAudio()
        );
      }, 1000)
    }
  };

  onVoicePlaybackRateChange = (event) => {
    const voicePlaybackRate = event.currentTarget.value;
    this.setState(() => ({ voicePlaybackRate }));
    this.widgetNotesAudio.playbackRate = voicePlaybackRate;
  };

  render () {
    return (
      <div>
        <div
          className="d-block d-sm-none d-flex w-100 justify-content-center align-items-center"
          style={{
            zIndex: '1005',
            position: 'absolute',
            minHeight: 'calc(100vh - 53px)',
            maxHeight: 'calc(100vh - 53px)',
            backgroundColor: '#808080',
          }}
        >
          <div className="d-inline-block">
            <div className="text-center">
              <FontAwesomeIcon
                className="text-light"
                icon={faRotate}
                style={{ fontSize: '10rem' }}
              />
            </div>
            <div className="text-light text-center">
              Rotate to landscape
            </div>
          </div>
        </div>
        { this.state.showInitialPlay &&
          <div
            className="d-flex w-100 justify-content-center align-items-center"
            onClick={this.onInitialStart}
            style={{
              zIndex: '1000',
              position: 'absolute',
              minHeight: 'calc(100vh - 55px)',
              maxHeight: 'calc(100vh - 55px)',
              cursor: 'pointer'
            }}
          >
            <FontAwesomeIcon
              className="text-warning"
              icon={faCirclePlay}
              style={{ fontSize: '15rem' }}
            />
          </div>

        }
        <div
          className="px-4 py-2"
          style={{
            minHeight: 'calc(100vh - 55px)',
            maxHeight: 'calc(100vh - 55px)',
          }}
        >
          { this.state.customReportLoading &&
            <div className="m-4 text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { (!this.state.customReportLoading && this.state.customReport) &&
            <div>
              <Carousel
                activeIndex={this.state.widgetIndex}
                onSelect={this.onCarouselSelect}
                interval={null}
                controls={false}
                indicators={false}
              >
                { this.state.customReport.widgets.map((widget, i) => {
                    return (
                      <Carousel.Item key={`cr-p-w-${i}`}>
                        { widget.context_category_id &&
                          <div className="mb-2 bg-secondary text-light font-weight-bold rounded">
                            <Row style={{ height: '40px' }}>
                              <Col>
                                <div className="p-2 ellipsis">
                                  { (widget.context_category_name && widget.context_brand_name) &&
                                    <React.Fragment>
                                      {`${widget.context_category_name} - ${widget.context_brand_name}`}
                                    </React.Fragment>
                                  }
                                  { !(widget.context_category_name && widget.context_brand_name) &&
                                    <React.Fragment>
                                      {widget.context_category_name || ''}
                                      {widget.context_brand_name || ''}
                                    </React.Fragment>
                                  }
                                </div>
                              </Col>
                              <Col>
                                <div className="p-2 text-center ellipsis">
                                  {widget.title || ''}
                                </div>
                              </Col>
                              <Col>
                                <div
                                  className="mt-1 p-2 float-right"
                                  style={{
                                    fontSize: '.75rem',
                                    fontWeight: 'normal'
                                  }}
                                >
                                  {widget.date_range_context || ''}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        }
                        <div
                          className="text-center"
                          style={{
                            minHeight: 'calc(100vh - 175px)',
                            maxHeight: 'calc(100vh - 175px)',
                          }}
                        >
                          <img
                            src={`${widget.image_url}?${this.state.cacheBreaker}`}
                            style={{
                              maxWidth: '100%',
                              maxHeight: 'calc(100vh - 275px)'
                            }}
                            alt=""
                            crossOrigin="anonymous"
                          />
                          { (this.state.showNotes && widget.notes) &&
                            <div
                              className="container pre-scrollable"
                              style={{
                                maxHeight: '200px',
                                overflowX: 'hidden',
                                overflowY: 'auto',
                              }}
                            >
                              <div className="pt-4 text-left">
                                {widget.notes}
                              </div>
                            </div>
                          }
                        </div>
                        { !widget.context_category_id &&
                          <div style={{ height: '48px' }} />
                        }
                      </Carousel.Item>
                    )
                  })
                }
              </Carousel>
              <Row className="mt-2">
                <Col className="d-none d-sm-block">
                  { (this.state.companyDetails && this.state.companyDetails.company_logo) &&
                    <img
                      src={this.state.companyDetails.company_logo}
                      style={{
                        maxWidth: '100%',
                        maxHeight: '49px',
                      }}
                      alt=""
                    />
                  }
                </Col>
                <Col className="d-none d-lg-block">
                  <div
                    className="mt-2 text-center"
                    style={{ fontSize: '1.2rem'}}
                  >
                    Brand Analysis
                  </div>
                </Col>
                <Col xs={0} sm={6} lg={4}>
                  <div className="text-center">
                    <Button
                      className="m-1"
                      variant="primary"
                      onClick={this.onFirstWidget}
                      disabled={this.state.widgetIndex === 0}
                    >
                      <FontAwesomeIcon icon={faAnglesLeft}/>
                    </Button>
                    <Button
                      className="m-1"
                      variant="primary"
                      onClick={this.onPreviousWidget}
                      disabled={this.state.widgetIndex === 0}
                    >
                      <FontAwesomeIcon icon={faAngleLeft}/>
                    </Button>
                    <Button
                      className="m-1"
                      variant="primary"
                      onClick={
                        this.state.carouselPlay ?
                          this.onCarouselPause :
                          this.onCarouselPlay
                      }
                    >
                      { this.state.carouselPlay &&
                        <FontAwesomeIcon icon={faPause}/>
                      }
                      { !this.state.carouselPlay &&
                        <FontAwesomeIcon icon={faPlay}/>
                      }
                    </Button>
                    <Button
                      className="m-1"
                      variant="primary"
                      onClick={this.onNextWidget}
                      disabled={this.state.widgetIndex === this.state.customReport.widgets.length-1}
                    >
                      <FontAwesomeIcon icon={faAngleRight}/>
                    </Button>
                    <Button
                      className="m-1"
                      variant="primary"
                      onClick={this.onLastWidget}
                      disabled={this.state.widgetIndex === this.state.customReport.widgets.length-1}
                    >
                      <FontAwesomeIcon icon={faAnglesRight}/>
                    </Button>
                    { (this.state.customReport && this.state.customReport.widgets) &&
                      <div className="d-inline-block ml-2">
                        {`Slide ${this.state.widgetIndex+1} of ${this.state.customReport.widgets.length}`}
                      </div>
                    }
                  </div>
                </Col>
                <Col className="d-none d-sm-block">
                  <div className="text-center">
                    <RangeSlider
                      value={this.state.voicePlaybackRate}
                      onChange={this.onVoicePlaybackRateChange}
                      min={.5}
                      max={2}
                      step={.1}
                      size='lg'
                      tooltip="auto"
                      tooltipPlacement="bottom"
                      tooltipLabel={(value) => {
                        if (value === 1) {
                          return 'Playback Rate Normal';
                        } else {
                          return `Playback Rate ${value}x`;
                        }
                      }}
                    />
                  </div>
                </Col>
                <Col className="d-none d-lg-block">
                  <div className="text-right">
                    <div
                      style={{
                        fontSize: '.875rem',
                        paddingRight: '31px'
                      }}
                    >
                      Powered by
                    </div>
                    <div
                      className="mt-n2"
                      style={{ fontSize: '1.5rem' }}
                    >
                      Brand<b className="text-bops-blue">Ops</b>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          }
          { (!this.state.customReportLoading && this.state.customReportNotFound) &&
            <div>
              <h1>Oops</h1>
              <div>We could not find the report you seek. It may have been unpublished.</div>
            </div>
          }
          <div className="text-center">
            <audio
              ref={(ref) => this.widgetNotesAudio = ref}
              className="mt-2"
              controls={this.state.showAudio}
              onEnded={this.onAudioEnd}
              muted={this.state.muteAudio}
              crossOrigin="anonymous"
            >
              <source
                src={
                  (
                    this.state.customReport &&
                    this.state.customReport.widgets &&
                    this.state.customReport.widgets[this.state.widgetIndex] &&
                    this.state.customReport.widgets[this.state.widgetIndex].voice_url
                  ) ?
                    `${this.state.customReport.widgets[this.state.widgetIndex].voice_url}?${this.state.cacheBreaker}` :
                    null
                }
                type="audio/mpeg"
              />
            </audio>
          </div>
        </div>
      </div>
    );
  }
};
