import React from 'react';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import { getDomainFromUrl } from '../../utils/urls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faLink } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class TwitterEmbeddedLinkPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      urlPreviewData: {},
      loading: false
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchUrlPreviewData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchUrlPreviewData = () => {
    if (this.props.url) {
      this.setState(() => ({ loading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/url-preview?url=${this.props.url}`,
        HEADERS
      ).then(response => {
        const urlPreviewData = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            urlPreviewData,
            loading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch url for preview');
        if (this.state.isMounted) {
          this.setState(() => ({
            urlPreviewData: {
              url: this.props.url
            },
            loading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <Card className="h-100">
        { !this.state.loading &&
          <Card.Header className="m-0 p-0">
            { !this.state.urlPreviewData.image &&
              <div className="text-center" style={{ maxHeight: '225px' }}>
                <FontAwesomeIcon
                  className="m-2"
                  icon={faImage}
                  color="#d9d9d9"
                  size="10x"
                />
              </div>
            }
            { this.state.urlPreviewData.image &&
              <div className="text-center">
                <img
                  src={this.state.urlPreviewData.image}
                  width="100%"
                  style={{ maxHeight: '225px' }}
                />
              </div>
            }
          </Card.Header>
        }
        <Card.Body className="p-2">
          { this.state.loading &&
              <div className="m-4 text-center">
                <ClipLoader size={100}/>
              </div>
          }
          { this.state.urlPreviewData.url &&
            <div>
              <div className="px-2">
                { this.state.urlPreviewData.title &&
                  <div>
                    <b>{this.state.urlPreviewData.title}</b>
                  </div>
                }
                <div
                  style={{
                    color: '#a6a6a6',
                    fontSize: '.875rem',
                  }}
                >
                  { this.state.urlPreviewData.description &&
                    <div
                      style={{
                        maxHeight: '46px',
                        overflow: 'hidden'
                      }}
                    >
                      {this.state.urlPreviewData.description}
                    </div>
                  }
                </div>
              </div>
            </div>
          }
        </Card.Body>
        <Card.Footer>
          <div
            onClick={() => window.open(this.props.url, '_blank')}
            style={{
              cursor: 'pointer'
            }}
          >
            <FontAwesomeIcon
              className="mr-2"
              icon={faLink}
            />
          {getDomainFromUrl(this.props.url)}
          </div>
          { this.props.postCount === 1 &&
            <div>
              {`Posted 1 time`}
            </div>
          }
          { this.props.postCount !== 1 &&
            <div>
              {`Posted ${this.props.postCount} times`}
            </div>
          }
        </Card.Footer>
      </Card>
    );
  }
};
