import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import BrandChannelSummaryContainer from '../../../containers/brands/BrandChannelSummaryContainer';
import BrandTabOverview from '../BrandTabOverview';
import BrandMetricsByMonthTableContainer from '../../../containers/metrics/BrandMetricsByMonthTableContainer';
import WebExternalStatsSummaryGraphs from '../../webExternalStats/WebExternalStatsSummaryGraphs';
import BlogTab from './BlogTab';
import WebCaptureDisplay from '../../webCapture/WebCaptureDisplay';
import PageProperties from '../../pageProperties/PageProperties';
import PageKeywordRanks from '../../pageProperties/PageKeywordRanks';
import PageChanges from '../../pageProperties/PageChanges';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faChartBar,
  faExpand,
  faDesktop,
  faTachometerAlt,
  faListOl,
  faFileAlt
} from '@fortawesome/free-solid-svg-icons';
import { getDomainFromUrl } from '../../../utils/urls';
import { createUserActivityLog } from '../../../utils/users';
import history from '../../../routers/history';

export default class WebTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'highlights'
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let selectedTab = hash.indexOf("-")>0 ? hash.substring(hash.indexOf("-")+1) : "highlights";
      this.setState(() => ({ selectedTab }));
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  changeSelectedTab = (selectedTab, subTabName) => {
    history.push(`#web-${selectedTab}`);
    this.setState(() => ({ selectedTab }));
    if (subTabName) {
      createUserActivityLog(
        this.props.user,
        `Brand Tabs: Website: ${subTabName}`,
        this.props.category,
        this.props.brand,
      )
    }
  }

  render () {
    return (
      <div className="p-4">
        <div className="mb-4">
          <button
            id="brandWebsiteHighlightsButton"
            type="button"
            className= { this.state.selectedTab === 'highlights' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('highlights', 'Highlights')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faChartLine} />
            </div>
            <div className="d-inline-block">
              Highlights
            </div>
          </button>
          <button
            id="brandBlogButton"
            type="button"
            className= { this.state.selectedTab === 'blog' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('blog', 'Blog')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faFileAlt} />
            </div>
            <div className="d-inline-block">
              Blog
            </div>
          </button>
          <button
            id="brandWebCaptureButton"
            type="button"
            className= { this.state.selectedTab === 'webCapture' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('webCapture', 'Web Capture')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faExpand} />
            </div>
            <div className="d-inline-block">
              Web Capture
            </div>
          </button>
          <button
            id="brandPagePropertiesButton"
            type="button"
            className= { this.state.selectedTab === 'pageProperties' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('pageProperties', 'Page Properties')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faTachometerAlt} />
            </div>
            <div className="d-inline-block">
              Page Properties
            </div>
          </button>
          <button
            id="brandPageKeywordsButton"
            type="button"
            className= { this.state.selectedTab === 'pageKeywords' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('pageKeywords', 'Page Ranks')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faListOl} />
            </div>
            <div className="d-inline-block">
              Page Ranks
            </div>
          </button>
          <button
            id="brandPageChangesButton"
            type="button"
            className= { this.state.selectedTab === 'pageChanges' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('pageChanges', 'Page Changes')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faFileAlt} />
            </div>
            <div className="d-inline-block">
              Page Changes
            </div>
          </button>
        </div>
        <div
          className="pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 200px)',
            maxHeight: 'calc(100vh - 200px)',
            overflow: 'auto',
            overflowX: 'hidden'
          }}
        >
          { this.state.selectedTab === 'highlights' &&
            <div>
              <div>
                <BrandChannelSummaryContainer
                  channel="Web"
                  category={this.props.category}
                  brand={this.props.brand}
                />
              </div>
              <div>
                <h5 className="my-2 p-2 bg-bops-blue text-light">
                  Metric Trends
                  <div
                    className="d-inline-block ml-4"
                    style={{ fontSize: '1rem', fontWeight: 'normal' }}
                  >
                    <Form.Check
                      id="webAllMonthsCheckbox"
                      className="ml-2"
                      type="checkbox"
                      label="All Months"
                      checked={this.props.allMonthsChecked}
                      onChange={(event) => this.props.updateAllMonthsChecked(event.currentTarget.checked)}
                      inline
                    />
                  </div>
                </h5>
                <div>
                  <WebExternalStatsSummaryGraphs
                    domain={getDomainFromUrl(this.props.brand.company_url)}
                    brandId={this.props.brandId}
                    brand={this.props.brand}
                    category={this.props.category}
                    allMonthsChecked={this.props.allMonthsChecked}
                    updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                    allMonthsStartDate={this.props.allMonthsStartDate}
                  />
                </div>
              </div>
              <div>
                <BrandMetricsByMonthTableContainer
                  type="Web"
                  category={this.props.category}
                  brand={this.props.brand}
                  excludeMetrics={[
                    'web_serp_rank_organic_google',
                    'web_serp_rank_paid_and_organic_google',
                    'web_serp_rank_organic_bing',
                    'web_serp_rank_paid_and_organic_bing',
                    'web_serp_rank_paid_google',
                    'web_serp_rank_paid_bing',
                    'web_semrush_organic_cost',
                    'web_semrush_adwords_traffic',
                    'web_semrush_adwords_cost',
                    'web_semrush_search_ads_count',
                    'web_semrush_display_ads_count',
                    'web_semrush_landing_pages_count',
                    'web_semrush_advertisers_rank',
                    'web_page_refresh_rate',
                    'share_of_search_google_trends'
                  ]}
                  audienceProfileId={this.props.category.audience_profile_id}
                />
              </div>
            </div>
          }
          { this.state.selectedTab === 'blog' &&
            <BlogTab
              customerId={this.props.customerId}
              customerUserId={this.props.customerUserId}
              categoryId={this.props.categoryId}
              categoryType={this.props.categoryType}
              category={this.props.category}
              brandId={this.props.brandId}
              brand={this.props.brand}
              brandName={this.props.brandName}
              topicId={this.props.topicId}
              companyId={this.props.companyId}
              startDateFilter={this.props.startDateFilter}
              endDateFilter={this.props.endDateFilter}
              onFilterDatesChange={this.props.onFilterDatesChange}
              scrollToDetails={this.props.scrollToDetails}
              unsetScrollToDetails={this.props.unsetScrollToDetails}
              allMonthsChecked={this.props.allMonthsChecked}
              updateAllMonthsChecked={this.props.updateAllMonthsChecked}
              allMonthsStartDate={this.props.allMonthsStartDate}
              companyChecked={this.props.companyChecked}
              portfolioChecked={this.props.portfolioChecked}
              productChecked={this.props.productChecked}
              onCppCheckChange={this.props.onCppCheckChange}
            />
          }
          { this.state.selectedTab === 'webCapture' &&
            <div>
              <div>
                <BrandChannelSummaryContainer
                  channel="Web"
                  category={this.props.category}
                  brand={this.props.brand}
                />
              </div>
              <div>
                <BrandMetricsByMonthTableContainer
                  type="Web"
                  category={this.props.category}
                  brand={this.props.brand}
                  audienceProfileId={this.props.category.audience_profile_id}
                  excludeMetrics={[
                    'web_serp_rank_organic_google',
                    'web_serp_rank_organic_bing',
                    'web_serp_rank_paid_google',
                    'web_serp_rank_paid_bing',
                    'web_serp_rank_paid_and_organic_google',
                    'web_serp_rank_paid_and_organic_bing',
                    'web_semrush_organic_cost',
                    'web_semrush_adwords_traffic',
                    'web_semrush_adwords_cost',
                    'web_semrush_search_ads_count',
                    'web_semrush_display_ads_count',
                    'web_semrush_landing_pages_count',
                    'web_semrush_advertisers_rank',
                    'web_awis_backlink_count',
                    'web_alexa_rank',
                    'web_page_views_per_user',
                    'web_reach_per_million',
                    'web_moz_domain_authority',
                    'web_moz_backlink_count',
                    'web_semrush_domain_rank',
                    'web_semrush_organic_traffic',
                    'web_semrush_organic_cost',
                    'web_semrush_adwords_traffic',
                    'web_semrush_adwords_cost',
                    'web_semrush_search_ads_count',
                    'web_semrush_display_ads_count',
                    'web_semrush_landing_pages_count',
                    'web_semrush_advertisers_rank',
                    'web_traffic_momentum',
                    'share_of_search_google_trends'
                  ]}
                />
              </div>
              <div>
                <WebCaptureDisplay
                  category={this.props.category}
                  brandId={this.props.brandId}
                  brand={this.props.brand}
                  brandName={this.props.brandName}
                  companyChecked={this.props.companyChecked}
                  portfolioChecked={this.props.portfolioChecked}
                  productChecked={this.props.productChecked}
                  onCppCheckChange={this.props.onCppCheckChange}
                />
              </div>
            </div>
          }
          { this.state.selectedTab === 'pageProperties' &&
            <div>
              <BrandTabOverview
                overviewType="Page Properties"
              />
              <PageProperties
                customerId={this.props.customerId}
                customerUserId={this.props.customerUserId}
                categoryId={this.props.categoryId}
                categoryType={this.props.categoryType}
                category={this.props.category}
                brandId={this.props.brandId}
                brand={this.props.brand}
                brandName={this.props.brandName}
                topicId={this.props.topicId}
                companyId={this.props.companyId}
              />
            </div>
          }
          { this.state.selectedTab === 'pageKeywords' &&
            <div>
              <BrandTabOverview
                overviewType="Page Keywords"
              />
              <PageKeywordRanks
                customerId={this.props.customerId}
                customerUserId={this.props.customerUserId}
                categoryId={this.props.categoryId}
                categoryType={this.props.categoryType}
                category={this.props.category}
                brandId={this.props.brandId}
                brand={this.props.brand}
                brandName={this.props.brandName}
                topicId={this.props.topicId}
                companyId={this.props.companyId}
              />
            </div>
          }
          { this.state.selectedTab === 'pageChanges' &&
            <div>
              <BrandTabOverview
                overviewType="Page Changes"
              />
              <PageChanges
                customerId={this.props.customerId}
                customerUserId={this.props.customerUserId}
                categoryId={this.props.categoryId}
                categoryType={this.props.categoryType}
                category={this.props.category}
                brandId={this.props.brandId}
                brand={this.props.brand}
                brandName={this.props.brandName}
                startDateFilter={this.props.startDateFilter}
                endDateFilter={this.props.endDateFilter}
                onFilterDatesChange={this.props.onFilterDatesChange}
              />
            </div>
          }
        </div>
      </div>
    );
  }
};
