import React from 'react';
import { CannyProvider, CannyWidget } from 'react-canny';
import { CANNY_BOARD_TOKEN } from '../../utils/constants';

const Feedback = () => {
  return (
    <div className="m-4">
      <CannyProvider>
        <CannyWidget
          basePath="/feedback"
          boardToken={CANNY_BOARD_TOKEN}
        />
      </CannyProvider>
    </div>
  );
}

export default Feedback;
