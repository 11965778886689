import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Tooltip from 'reactjs-popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';

export default class MetricCategoryMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '0'
    };
  }

  componentDidMount() {

  };

  toggleAccordion = () => {
    this.setState(prevState => {
      if (prevState.activeKey === '0') {
        return { activeKey: '1' }
      } else {
        return { activeKey: '0' }
      }
    })
  };

  openCategory = () => {
    if (this.state.activeKey === '1') {
      this.setState(() => ({ activeKey: '0' }));
    }
  };

  render () {
    return (
      <div className="mb-2" style={{ fontSize: '.875rem' }}>
        <Accordion activeKey={this.state.activeKey}>
          <Accordion.Toggle as="span" eventKey="0" onClick={this.toggleAccordion}>
            <div style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon
                className="mx-2"
                icon={this.state.activeKey == '0' ? faCaretDown : faCaretRight}
              />
              {this.props.categoryName}
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <div className="ml-3">
              { this.props.metrics.map((metric, i) => {
                  if (
                    (metric.channel_name === 'Email Campaigns' && metric.is_private) ||
                    (metric.channel_name === 'Surveys via BrandOps')
                  ) {
                    // disabled metrics
                    return (
                      <div
                        key={metric.metric_name}
                        className="px-2 ellipsis"
                        style={{
                          cursor: 'not-allowed',
                          color: '#bbc0c4'
                        }}
                      >
                        {metric.metric_label}
                      </div>
                    )
                  } else {
                    return (
                      <Tooltip
                        key={metric.metric_name}
                        trigger = {
                          <div
                            className={
                              this.props.selectedMetricName === metric.metric_name ?
                                'bg-primary text-light rounded px-2 ellipsis' :
                                'px-2 ellipsis'
                            }
                            onClick={() => this.props.onMetricSelect(metric)}
                            style={{ cursor: 'pointer' }}
                          >
                            {metric.metric_label}
                          </div>
                        }
                        position="right center"
                        on="hover"
                        contentStyle={{
                          borderRadius: '5px',
                          width: '275px'
                        }}
                      >
                        <div>{metric.metric_label}</div>
                        <hr className="my-1"/>
                        <div>Frequency: {metric.frequency || 'N/A'}</div>
                      </Tooltip>
                    )
                  }
                })
              }
            </div>
          </Accordion.Collapse>
        </Accordion>
      </div>
    );
  }
};
