import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import { numberWithOrdinalSuffix, getPercentChange, round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class BrandRecommendationsSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryLeaderBrand: undefined,
      overallSummaryOne: '',
      overallSummaryTwo: '',
      metricRecommendations: [],
      metricRecommendationsLoading: false,
      lowercaseExemptions: [
        'Twitter',
        'LinkedIn',
        'YouTube',
        'Facebook',
        'Instagram',
        'Google',
        'Bing',
        'Alexa',
        'Moz',
        'SEMrush',
        'SERP'
      ]
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.brandScores !== this.props.brandScores ||
      prevProps.category !== this.props.category ||
      prevProps.brand !== this.props.brand
    ) {
      this.setOverallSummary();
      this.setPillarSummary();
      this.fetchMetricRecommendations();
    }
  };

  setOverallSummary = () => {
    if (this.props.category && this.props.brand && this.props.brandScores.length > 0) {
      let previousBrandRank;
      let latestBrandRank;
      let comparisonBrand;
      let comparisonBrandLabel;
      let dateRangeLabel;
      let rankChangeLabel;
      let scoreChange;
      let scoreChangeLabel;
      const firstDateBrandsByOverallScores = this.sortBrandsByOverallScores(this.props.brandScores[0]);
      const secondDateBrandsByOverallScores = this.sortBrandsByOverallScores(this.props.brandScores[this.props.brandScores.length-1]);
      if (firstDateBrandsByOverallScores && firstDateBrandsByOverallScores.length > 0) {
        // get previous brand rank
        const previousBrandIndex = firstDateBrandsByOverallScores.findIndex(x => x.brand.id === this.props.brand.id);
        if (previousBrandIndex !== -1) {
          previousBrandRank = previousBrandIndex + 1;
        }
      }
      if (secondDateBrandsByOverallScores && secondDateBrandsByOverallScores.length > 0) {
        // get lastest brand rank
        const latestBrandIndex = secondDateBrandsByOverallScores.findIndex(x => x.brand.id === this.props.brand.id);
        if (latestBrandIndex !== -1) {
          latestBrandRank = latestBrandIndex + 1;
        }
        // get comparison brand label
        const categoryLeaderBrand = secondDateBrandsByOverallScores[0].brand;
        if (categoryLeaderBrand.id === this.props.brand.id) {
          if (secondDateBrandsByOverallScores.length > 1) {
            comparisonBrand = secondDateBrandsByOverallScores[1].brand;
            comparisonBrandLabel = `${comparisonBrand.name} is 2nd in the category.`;
          }
        } else {
          comparisonBrand = categoryLeaderBrand;
          comparisonBrandLabel = `${categoryLeaderBrand.name} is the leader.`;
        }
      }
      // get date range label
      dateRangeLabel = `${moment(this.props.brandScores[0].score_at).format('MM/DD/YYYY')} to ${moment(this.props.brandScores[this.props.brandScores.length-1].score_at).format('MM/DD/YYYY')}`;
      // get rank change label
      if (previousBrandRank && latestBrandRank) {
        if (previousBrandRank === latestBrandRank) {
          rankChangeLabel = 'did not change';
        } else if (latestBrandRank < previousBrandRank) {
          rankChangeLabel = 'increased';
        } else {
          rankChangeLabel = 'decreased';
        }
      }
      // get score change & label
      let previousBrandScore = this.getBrandScore(this.props.brand.id, this.props.brandScores[0]);
      let latestBrandScore = this.getBrandScore(this.props.brand.id, this.props.brandScores[this.props.brandScores.length-1]);
      if (previousBrandScore != null && latestBrandScore != null) {
        previousBrandScore = Math.round(previousBrandScore);
        latestBrandScore = Math.round(latestBrandScore);
        scoreChange = round(getPercentChange(previousBrandScore !== 0 ? previousBrandScore : 1, latestBrandScore), 1);
        if (scoreChange === 0) {
          scoreChangeLabel = ' and its score did not change.';
        } else if (scoreChange > 0) {
          scoreChangeLabel = ` and its score increased by ${scoreChange}%.`;
        } else {
          scoreChangeLabel = ` and its score decreased by ${Math.abs(scoreChange)}%.`;
        }
      }


      let overallSummaryOne = '';
      if (latestBrandRank && comparisonBrandLabel) {
        overallSummaryOne = `${this.props.brand.name} is ${numberWithOrdinalSuffix(latestBrandRank) || 'N/A'} in the category. ${comparisonBrandLabel}`;
      }
      let overallSummaryTwo = '';
      if (dateRangeLabel && rankChangeLabel && scoreChangeLabel) {
        overallSummaryTwo = `From ${dateRangeLabel} your brand's rank ${rankChangeLabel}${scoreChangeLabel}`;
      }

      this.setState(() => ({
        overallSummaryOne,
        overallSummaryTwo,
      }));
    } else {
      this.setState(() => ({
        overallSummaryOne: '',
        overallSummaryTwo: '',
      }));
    }
  };

  setPillarSummary = () => {
    if (this.props.category && this.props.brand && this.props.brandScores.length > 0) {
      let pillarSummaryOne = '';
      const brandLeadingPillars = this.getBrandLeadingPillars(this.props.brand.id, this.props.brandScores[this.props.brandScores.length-1]);
      if (brandLeadingPillars.length > 0) {
        if (brandLeadingPillars.length == 1) {
          pillarSummaryOne = `${this.props.brand.name} leads on ${brandLeadingPillars[0]}.`
        } else if (brandLeadingPillars.length == 2) {
          pillarSummaryOne = `${this.props.brand.name} leads on ${brandLeadingPillars.join(' and ')}.`;
        } else if (brandLeadingPillars.length > 2) {
          pillarSummaryOne = `${this.props.brand.name} leads on ${brandLeadingPillars.slice(0,brandLeadingPillars.length-1).join(', ')}, and ${brandLeadingPillars[brandLeadingPillars.length-1]}.`;
        }
      } else {
        // brand does not lead any pillars, so get strongest pillar
        const brandStrongestPillar = this.getBrandStrongestPillar(this.props.brand.id, this.props.brandScores[this.props.brandScores.length-1]);
        if (brandStrongestPillar) {
          pillarSummaryOne = `${this.props.brand.name} is strongest on ${brandStrongestPillar}.`;
        }
      }


      let pillarSummaryTwo = '';
      // TODO NEEDS WORK
      // const brandsByOverallScores = this.sortBrandsByOverallScores(this.props.brandScores[this.props.brandScores.length-1]);
      // const categoryLeaderBrand = brandsByOverallScores[0].brand;
      // if (categoryLeaderBrand.id === this.props.brand.id) {
      //   // text on smallest gap with 2nd place
      //   const comparisonBrand = brandsByOverallScores[1].brand;
      //   const comparisonBrandsPillarData = this.getComparisonBrandsPillarGapData(this.props.brand.id, comparisonBrand.id, this.props.brandScores[0], this.props.brandScores[this.props.brandScores.length-1]);
      //   if (comparisonBrandsPillarData) {
      //     pillarSummaryTwo = `The smallest gap with ${comparisonBrand.name} is in ${comparisonBrandsPillarData.smallestGap.name}. `;
      //     pillarSummaryTwo += `Your brand's score is ${Math.abs(comparisonBrandsPillarData.smallestGap.gap)} ${comparisonBrandsPillarData.smallestGap.gap > 0 ? 'higher' : 'lower'}. `
      //     pillarSummaryTwo += `From ${moment(this.props.brandScores[0].score_at).format('MM/DD/YYYY')} to ${moment(this.props.brandScores[this.props.brandScores.length-1].score_at).format('MM/DD/YYYY')} the gap ${comparisonBrandsPillarData.smallestGap.gapChange > 0 ? 'increased' : 'decreased'} by ${Math.abs(comparisonBrandsPillarData.smallestGap.gapChange)}%.`
      //   }
      // } else {
      //   // text on largest gap to leader
      //   const comparisonBrand = categoryLeaderBrand;
      //   const comparisonBrandsPillarData = this.getComparisonBrandsPillarGapData(this.props.brand.id, comparisonBrand.id, this.props.brandScores[0], this.props.brandScores[this.props.brandScores.length-1]);
      //   pillarSummaryTwo = `The largest gap with ${comparisonBrand.name} is in ${comparisonBrandsPillarData.largestGap.name}. `;
      //   pillarSummaryTwo += `Your brand's score is ${Math.abs(comparisonBrandsPillarData.largestGap.gap)} ${comparisonBrandsPillarData.largestGap.gap > 0 ? 'higher' : 'lower'}. `
      //   pillarSummaryTwo += `From ${moment(this.props.brandScores[0].score_at).format('MM/DD/YYYY')} to ${moment(this.props.brandScores[this.props.brandScores.length-1].score_at).format('MM/DD/YYYY')} the gap ${comparisonBrandsPillarData.largestGap.gapChange > 0 ? 'increased' : 'decreased'} by ${Math.abs(comparisonBrandsPillarData.largestGap.gapChange)}%.`
      // }

      this.setState(() => ({
        pillarSummaryOne,
        pillarSummaryTwo,
      }));
    } else {
      this.setState(() => ({
        pillarSummaryOne: '',
        pillarSummaryTwo: '',
      }));
    }
  };

  fetchMetricRecommendations = () => {
    // only fetch for "your brand"
    if (
      this.props.category &&
      this.props.brand &&
      this.props.user &&
      this.props.brand.company_id === this.props.user.customerId
    ) {
      this.setState(() => ({ metricRecommendationsLoading: true }));
      const metricRecommendationRequests = [];
      const formattedStartDate = this.props.initialDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.props.endDate.format('YYYY-MM-DD HH:mm:ss');
      // fetch metrics data
      metricRecommendationRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/brand-metrics`,
          HEADERS
        ).then(response => {
          const metrics = response.data;
          const metricsMap = {};
          for (const m of metrics) {
            metricsMap[m.metric_name] = m;
          }

          return { metricsMap };
        }).catch(error => {
          console.error('Error: unable to fetch metrics data...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { metricsMap: {} };
        })
      );
      // fetch metric scores
      metricRecommendationRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/scores/brand-scores?logged_customer_id=${this.props.user.customerId}&company_id=${this.props.brand.company_id}&product_brand_id=${this.props.brand.id}&audience_profile_id=${this.props.category.audience_profile_id}&category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
          HEADERS
        ).then(response => {
          const brandScoresData = response.data;
          const metrics = [];
          if (typeof(brandScoresData) === 'object') {
            for (const [key, s] of Object.entries(brandScoresData)) {
              if (
                s.type === 'brand_metric' &&
                !s.private
              ) {
                s.impact = s.points_available - s.points;
                metrics.push(s);
              }
            }
            // sort by impact
            metrics.sort((a, b) => {
              if (a.impact < b.impact) {
                return 1;
              } else if (a.impact > b.impact) {
                return -1;
              } else {
                return 0;
              }
            });

            // only allow 2 from pillar and channel
            // do not allow momentum pillar metrics
            const pillarMap = { 'Momentum': 2 };
            const channelMap = {};
            const limitedMetrics = [];
            const excludeMetricIdsList = [
              59, // Sentiment: Percent of Promoters, Twitter
              60, // Sentiment: Percent of Detractors, Twitter
              64, // Sentiment, Twitter
              159, // Sentiment: Percent of Promoters, Facebook
              160, // Sentiment: Percent of Detractors, Facebook
              161, // Sentiment, Facebook
              162, // Sentiment: Percent of Promoters, Instagram
              163, // Sentiment: Percent of Detractors, Instagram
              164, // Sentiment, Instagram
            ];
            for (const mr of metrics) {
              if (
                (!pillarMap[mr.stage_name] || pillarMap[mr.stage_name] < 2) &&
                (!channelMap[mr.channel_name] || channelMap[mr.channel_name] < 2) &&
                !excludeMetricIdsList.includes(mr.id)
              ) {
                if (!pillarMap[mr.stage_name]) {
                  pillarMap[mr.stage_name] = 1;
                } else {
                  pillarMap[mr.stage_name] += 1;
                }
                if (!channelMap[mr.channel_name]) {
                  channelMap[mr.channel_name] = 1;
                } else {
                  channelMap[mr.channel_name] += 1;
                }
                limitedMetrics.push(mr);
              }
            }

            // only keep 5 recommendations
            const metricRecommendations = limitedMetrics.slice(0, 5);
            return { metricRecommendations };
          } else {
            return { metricRecommendations: [] };
          }
        }).catch(error => {
          console.error('Error: unable to fetch metric scores for recommendations...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { metricRecommendations: [] };
        })
      );

      Promise.all(metricRecommendationRequests).then(responses => {
        let metricRecommendations = [];
        let metricsMap = {};

        for (const response of responses) {
          if (response.metricRecommendations) {
            metricRecommendations = response.metricRecommendations;
          } else if (response.metricsMap) {
            metricsMap = response.metricsMap;
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            metricRecommendations,
            metricsMap,
            metricRecommendationsLoading: false,
          }))
        }
      });
    }
  };

  sortBrandsByOverallScores = (scoresObj) => {
    if (typeof(scoresObj) === 'object' && this.props.category) {
      const brandsByOverallScores = [];
      for (const [key, value] of Object.entries(scoresObj)) {
        if (key !== 'score_at') {
          for (const brand of this.props.category.product_brands) {
            if (value.product_brand_id === brand.id) {
              brandsByOverallScores.push({
                score: value.brand_score,
                brand
              });
            }
          }
        }
      }
      return brandsByOverallScores.sort((a, b) => {
        if (a.score < b.score) {
          return 1;
        } else if (a.score > b.score) {
          return -1;
        } else {
          return 0;
        }
      });
    } else {
      return;
    }
  };

  getBrandScore = (brandId, scoresObj) => {
    if (typeof(scoresObj) === 'object') {
      for (const [key, value] of Object.entries(scoresObj)) {
        if (value.product_brand_id === brandId) {
          return value.brand_score;
        }
      }
      return;
    } else {
      return;
    }
  };

  getBrandLeadingPillars = (brandId, scoresObj) => {
    if (typeof(scoresObj) === 'object') {
      let brandScores = {};
      // determine highest pillar scores
      let highestAudienceAttentionScore = 0;
      let highestDigitalPresenceScore = 0;
      let highestBrandReputationScore = 0;
      let highestMomentumScore = 0;
      for (const [key, value] of Object.entries(scoresObj)) {
        if (value.product_brand_id === brandId) {
          brandScores = value;
        }
        if (value.audience_attention_score > highestAudienceAttentionScore) {
          highestAudienceAttentionScore = value.audience_attention_score;
        }
        if (value.digital_presence_score > highestDigitalPresenceScore) {
          highestDigitalPresenceScore = value.digital_presence_score;
        }
        if (value.brand_reputation_score > highestBrandReputationScore) {
          highestBrandReputationScore = value.brand_reputation_score;
        }
        if (value.momentum_score > highestMomentumScore) {
          highestMomentumScore = value.momentum_score;
        }
      }

      const brandLeadingPillars = [];
      if (brandScores.audience_attention_score === highestAudienceAttentionScore) {
        brandLeadingPillars.push('Audience Attention');
      }
      if (brandScores.digital_presence_score === highestDigitalPresenceScore) {
        brandLeadingPillars.push('Digital Presence');
      }
      if (brandScores.brand_reputation_score === highestBrandReputationScore) {
        brandLeadingPillars.push('Brand Reputation');
      }
      if (brandScores.momentum_score === highestMomentumScore) {
        brandLeadingPillars.push('Momentum');
      }
      return brandLeadingPillars;
    } else {
      return [];
    }
  };

  getBrandStrongestPillar = (brandId, scoresObj) => {
    if (typeof(scoresObj) === 'object') {
      let highestPillarScore = 0;
      let highestPillarName;
      for (const [key, value] of Object.entries(scoresObj)) {
        if (value.product_brand_id === brandId) {
          if (value.audience_attention_score > highestPillarScore) {
            highestPillarScore = value.audience_attention_score;
            highestPillarName = 'Audience Attention';
          }
          if (value.digital_presence_score > highestPillarScore) {
            highestPillarScore = value.digital_presence_score;
            highestPillarName = 'Digital Presence';
          }
          if (value.brand_reputation_score > highestPillarScore) {
            highestPillarScore = value.brand_reputation_score;
            highestPillarName = 'Brand Reputation';
          }
          if (value.momentum_score > highestPillarScore) {
            highestPillarScore = value.momentum_score;
            highestPillarName = 'Momentum';
          }
        }
      }
      return highestPillarName;
    }
  };

  getComparisonBrandsPillarGapData = (brandId, comparisonBrandId, initialScoresObj, endScoresObj) => {
    if (typeof(initialScoresObj) === 'object' && typeof(endScoresObj) === 'object') {
      // get brands scores
      let initialBrandScores;
      let initialComparisonBrandScores;
      for (const [key, value] of Object.entries(initialScoresObj)) {
        if (value.product_brand_id === brandId) {
          initialBrandScores = value;
        } else if (value.product_brand_id === comparisonBrandId) {
          initialComparisonBrandScores = value;
        }
      }
      let endBrandScores;
      let endComparisonBrandScores;
      for (const [key, value] of Object.entries(endScoresObj)) {
        if (value.product_brand_id === brandId) {
          endBrandScores = value;
        } else if (value.product_brand_id === comparisonBrandId) {
          endComparisonBrandScores = value;
        }
      }

      if (endBrandScores && endComparisonBrandScores) {
        const pillarGaps = [];
        // attention gap
        pillarGaps.push({
          name: 'Audience Attention',
          key: 'audience_attention_score',
          gap: Math.round(endComparisonBrandScores.audience_attention_score - endBrandScores.audience_attention_score)
        });
        // presence gap
        pillarGaps.push({
          name: 'Digital Presence',
          key: 'digital_presence_score',
          gap: Math.round(endComparisonBrandScores.digital_presence_score - endBrandScores.digital_presence_score)
        });
        // reputation gap
        pillarGaps.push({
          name: 'Brand Reputation',
          key: 'brand_reputation_score',
          gap: Math.round(endComparisonBrandScores.brand_reputation_score - endBrandScores.brand_reputation_score)
        });
        // momentum gap
        pillarGaps.push({
          name: 'Momentum',
          key: 'momentum_score',
          gap: Math.round(endComparisonBrandScores.momentum_score - endBrandScores.momentum_score)
        });


        if (pillarGaps.length > 0) {
          const largestPillarGaps = pillarGaps.sort((a, b) => {
            if (a.gap < b.gap) {
              return 1;
            } else if (a.gap > b.gap) {
              return -1;
            } else {
              return 0;
            }
          });
          const largestGap = largestPillarGaps[0];
          const previousLargestGap = initialComparisonBrandScores[largestGap.key] - initialBrandScores[largestGap.key];
          largestGap.gapChange = round(previousLargestGap - largestGap.gap, 1);

          const smallestPillarGaps = pillarGaps.sort((a, b) => {
            if (Math.abs(a.gap) > Math.abs(b.gap)) {
              return 1;
            } else if (Math.abs(a.gap) < Math.abs(b.gap)) {
              return -1;
            } else {
              return 0;
            }
          });
          const smallestGap = smallestPillarGaps[0];
          const previousSmallestGap = initialComparisonBrandScores[smallestGap.key] - initialBrandScores[smallestGap.key];
          smallestGap.gapChange = round(previousSmallestGap - smallestGap.gap, 1);
          return {
            largestGap,
            smallestGap,
          };
        } else {
          return;
        }
      }
    }
  };

  render () {
    return (
      <div>
        { (
            this.props.selectedPillar === 'brandScore' &&
            this.props.category &&
            this.props.brand
          ) &&
          <div>
            <h5>Overall Summary</h5>
            <ul>
              { this.state.overallSummaryOne &&
                <li>{this.state.overallSummaryOne}</li>
              }
              { this.state.overallSummaryTwo &&
                <li>{this.state.overallSummaryTwo}</li>
              }
            </ul>
            <h5>Pillar Summary</h5>
            <ul>
              { this.state.pillarSummaryOne &&
                <li>{this.state.pillarSummaryOne}</li>
              }
              { this.state.pillarSummaryTwo &&
                <li>{this.state.pillarSummaryTwo}</li>
              }
            </ul>
            { (
              this.props.brand &&
              this.props.user &&
              this.props.brand.company_id === this.props.user.customerId
            ) &&
              <div>
                <h5>Priority Ranked Recommendations</h5>
                { this.state.metricRecommendationsLoading &&
                  <div className="my-2 mx-4">
                    <div className="text-bops-blue">
                      We're identifying the most valuable recommendations for your brand.
                    </div>
                    <ClipLoader size={50}/>
                  </div>
                }
                { (!this.state.metricRecommendationsLoading && this.state.metricRecommendations.length > 0) &&
                  <ol>
                    { this.state.metricRecommendations.map((mr, index) => {
                        const metricData = this.state.metricsMap[mr.name];
                        // lower case metric label except exempty words like company names, acronyms, etc
                        let metricLabelWords = metricData.metric_label.split(' ');
                        metricLabelWords = metricLabelWords.map(word => {
                          if (this.state.lowercaseExemptions.includes(word)) {
                            return word;
                          } else {
                            return word.toLowerCase();
                          }
                        });
                        const metricLabel = metricLabelWords.join(' ');
                        if (metricData) {
                          return (
                            <li key={`brs-mr-${index}`}>
                              {`${metricData.score_type === 'high_is_good' ? 'Increase': 'Improve'} ${metricLabel}.`}
                            </li>
                          )
                        }
                      })
                    }
                  </ol>
                }
                { (!this.state.metricRecommendationsLoading && this.state.metricRecommendations.length === 0) &&
                  <ul>
                    <li>No recommendations at this time.</li>
                  </ul>
                }
              </div>
            }
          </div>
        }
      </div>
    );
  }
};
