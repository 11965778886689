import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Dropdown from 'react-bootstrap/Dropdown';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactTable from 'react-table';
import DateRangePicker from '../common/DateRangePicker';
import CardComponent from '../common/CardComponent';
import LoginContainer from '../../containers/common/LoginContainer';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { numberWithCommas, intToShorthandString, round } from '../../utils/numbers';
import { colors } from '../../utils/graphs';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';
import history from '../../routers/history';

export default class GoogleSearchConsole extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      googleSearchConsoleAccounts: [],
      selectedGscAccountId: undefined,
      selectedGscAccount: undefined,
      startDate: moment().subtract(90, 'days').startOf('day'),
      endDate: moment().startOf('day').endOf('day'),
      gscAggregatedData: {},
      gscData: [],
      gscDataLoading: false,
      totalClicksChecked: true,
      totalImpressionsChecked: true,
      avgCtrChecked: false,
      avgPositionChecked: false,
      selectedTab: 'queries',
      gscQueries: [],
      gscQueriesLoading: false,
      gscPages: [],
      gscPagesLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchGoogleSearchConsoleAccounts();
    if (this.props.myCategoryBrands.length > 0) {
      this.setState(() => ({
        category: this.props.myCategoryBrands[0].category,
        brand: this.props.myCategoryBrands[0].brand,
      }));
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.customerId !== this.props.user.customerId) {
      this.fetchGoogleSearchConsoleAccounts();
    }
    if (
      prevProps.myCategoryBrands !== this.props.myCategoryBrands &&
      this.props.myCategoryBrands.length > 0
    ) {
      this.setState(() => ({
        category: this.props.myCategoryBrands[0].category,
        brand: this.props.myCategoryBrands[0].brand,
      }));
    }
    if (
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      this.loadGoogleSearchConsoleData();
    }
  };

  fetchGoogleSearchConsoleAccounts = () => {
    if (this.props.user.customerId) {
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/google-search-console-accounts?company_id=${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        const googleSearchConsoleAccounts = response.data;
        let selectedGscAccountId;
        let selectedGscAccount;
        if (googleSearchConsoleAccounts.length > 0) {
          selectedGscAccountId = response.data[0].id;
          selectedGscAccount = response.data[0];
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            googleSearchConsoleAccounts,
            selectedGscAccountId,
            selectedGscAccount,
          }), () => this.loadGoogleSearchConsoleData());
        }
      }).catch(error => {
        console.error('Error: unable to fetch google search console accounts');
        if (this.state.isMounted) {
          this.setState(() => ({
            googleSearchConsoleAccounts: [],
            selectedGscAccountId: undefined,
            selectedGscAccount: undefined,
          }), () => this.loadGoogleSearchConsoleData());
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  loadGoogleSearchConsoleData = () => {
    this.fetchGoogleSearchConsoleData();
    this.fetchGoogleSearchConsoleQueries();
    this.fetchGoogleSearchConsolePages();
  };

  fetchGoogleSearchConsoleData = () => {
    if (this.state.selectedGscAccount) {
      this.setState(() => ({ gscDataLoading: true }));
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      const googleSearchConsoleDataRequests = [];
      // fetch google search console aggregate data
      googleSearchConsoleDataRequests.push(
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/google-search-console-latest-stats?google_search_console_account_id=${this.state.selectedGscAccount.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
          HEADERS
        ).then(response => {
          const gscAggregatedData = response.data;
          return { gscAggregatedData };
        }).catch(error => {
          console.error(`Error: unable to fetch google search console aggregate data`);
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { gscAggregatedData: {} };
        })
      );
      // fetch google search console trend data
      googleSearchConsoleDataRequests.push(
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/google-search-console-stats?google_search_console_account_id=${this.state.selectedGscAccount.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
          HEADERS
        ).then(response => {
          const gscData = response.data;
          for (const data of gscData) {
            data.average_ctr = round(data.average_ctr, 1);
          }
          return { gscData };
        }).catch(error => {
          console.error(`Error: unable to fetch google search console data`);
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { gscData: [] };
        })
      );

      Promise.all(googleSearchConsoleDataRequests).then(responses => {
        let gscAggregatedData = {};
        let gscData = [];
        for (const response of responses) {
          if (response.gscAggregatedData) {
            gscAggregatedData = response.gscAggregatedData;
          } else if (response.gscData) {
            gscData = response.gscData;
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            gscAggregatedData,
            gscData,
            gscDataLoading: false,
          }));
        }
      });
    }
  };

  fetchGoogleSearchConsoleQueries = () => {
    if (this.state.selectedGscAccount) {
      this.setState(() => ({ gscQueriesLoading: true }));
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/google-search-console-queries?google_search_console_account_id=${this.state.selectedGscAccount.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        const gscQueries = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            gscQueries,
            gscQueriesLoading: false,
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch google search console queries`);
        if (this.state.isMounted) {
          this.setState(() => ({
            gscQueries: [],
            gscQueriesLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchGoogleSearchConsolePages = () => {
    if (this.state.selectedGscAccount) {
      this.setState(() => ({ gscPagesLoading: true }));
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/google-search-console-pages?google_search_console_account_id=${this.state.selectedGscAccount.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        const gscPages = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            gscPages,
            gscPagesLoading: false,
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch google search console pages`);
        if (this.state.isMounted) {
          this.setState(() => ({
            gscPages: [],
            gscPagesLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  selectGoogleSearchConsoleAccount = (selectedGscAccount) => {
    if (selectedGscAccount) {
      this.setState(() => ({
        selectedGscAccountId: selectedGscAccount.id,
        selectedGscAccount,
      }), () => this.loadGoogleSearchConsoleData());
    }
  };

  onFilterDatesChange = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  };

  onCheckboxChange = (checked, checkbox) => {
    if (checked !== this.state[checkbox]) {
      this.setState(() => ({
        [checkbox]: checked
      }));
    }
  }

  render () {
    return (
      <div className="p-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        { (this.props.user.id && !this.props.companySetupChecklist.google_search_console_setup) &&
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              minHeight: 'calc(100vh - 200px)',
              maxHeight: 'calc(100vh - 200px)',
            }}
          >
            <div className="d-inline-block">
              <Alert variant="primary">
                Google Search Console is not setup for your company. You can get setup <Link to="/content-collectors/google-search-console">here</Link>.
              </Alert>
            </div>
          </div>
        }
        { (this.props.user.customerId && this.props.companySetupChecklist.google_search_console_setup) &&
          <div>
            <h4>
              <FontAwesomeIcon
                className="mr-2"
                icon={faGoogle}
                color="#6c757d"
              />
              Google Search Console
            </h4>
            <hr />
            <div>
              <h5 className="mb-2 p-2 bg-bops-blue text-light">
                <span
                  className="mr-2"
                  style={{ fontSize: '1rem', fontWeight: 'normal' }}
                >
                  Domain
                </span>
                <div className="d-inline-block">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="light"
                      size="sm"
                      style={{ cursor: 'pointer' }}
                    >
                      { this.state.selectedGscAccount &&
                        <div className="d-inline-block">
                          {this.state.selectedGscAccount.domain_name}
                        </div>
                      }
                      { !this.state.selectedGscAccount &&
                        <div className="d-inline-block">
                          Select Domain
                        </div>
                      }
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      { this.state.googleSearchConsoleAccounts.map(gscAccount => {
                          return (
                            <Dropdown.Item
                              key={`gsc-a-${gscAccount.id}`}
                              className="px-2"
                              onClick={() => this.selectGoogleSearchConsoleAccount(gscAccount)}
                            >
                              {gscAccount.domain_name}
                            </Dropdown.Item>
                          )
                        })
                      }
                      <Dropdown.Divider />
                      <Dropdown.Item
                        className="px-2"
                        onClick={() => history.push('/content-collectors/google-search-console')}
                      >
                        Add/Remove Domains
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <span
                  className="ml-4 mr-2"
                  style={{ fontSize: '1rem', fontWeight: 'normal' }}
                >
                  Date Range
                </span>
                <DateRangePicker
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  updateDates={this.onFilterDatesChange}
                />
              </h5>
            </div>
            { this.state.selectedGscAccount &&
              <div
                className="pre-scrollable"
                style={{
                  minHeight: 'calc(100vh - 200px)',
                  maxHeight: 'calc(100vh - 200px)',
                  overflow: 'auto',
                  overflowX: 'hidden'
                }}
              >
                <CardComponent
                  title="Search Performance"
                  body={
                    <div>
                      { this.state.gscDataLoading &&
                        <div className="m-4 text-center">
                          <ClipLoader size={100}/>
                        </div>
                      }
                      { !this.state.gscDataLoading &&
                        <div>
                          <div>
                            <div
                              className="d-inline-block px-4 py-3 border"
                              onClick={() => this.onCheckboxChange(!this.state.totalClicksChecked, 'totalClicksChecked')}
                              style={{
                                color: this.state.totalClicksChecked ? '#ffffff' : '#000000',
                                backgroundColor: this.state.totalClicksChecked ? colors[0] : '#f8f9fa'
                              }}
                            >
                              <Form.Check
                                type="checkbox"
                                label="Total clicks"
                                checked={this.state.totalClicksChecked}
                                readOnly
                              />
                              <div style={{ fontSize: '2rem' }}>
                                { (this.state.gscAggregatedData && this.state.gscAggregatedData.total_clicks != null) ?
                                    intToShorthandString(this.state.gscAggregatedData.total_clicks) : '-'
                                }
                              </div>
                            </div>
                            <div
                              className="d-inline-block px-4 py-3 border"
                              onClick={() => this.onCheckboxChange(!this.state.totalImpressionsChecked, 'totalImpressionsChecked')}
                              style={{
                                color: this.state.totalImpressionsChecked ? '#ffffff' : '#000000',
                                backgroundColor: this.state.totalImpressionsChecked ? colors[1] : '#f8f9fa'
                              }}
                            >
                              <Form.Check
                                type="checkbox"
                                label="Total impressions"
                                checked={this.state.totalImpressionsChecked}
                                readOnly
                              />
                              <div style={{ fontSize: '2rem' }}>
                                { (this.state.gscAggregatedData && this.state.gscAggregatedData.total_impressions != null) ?
                                    intToShorthandString(this.state.gscAggregatedData.total_impressions) : '-'
                                }
                              </div>
                            </div>
                            <div
                              className="d-inline-block px-4 py-3 border"
                              onClick={() => this.onCheckboxChange(!this.state.avgCtrChecked, 'avgCtrChecked')}
                              style={{
                                color: this.state.avgCtrChecked ? '#ffffff' : '#000000',
                                backgroundColor: this.state.avgCtrChecked ? colors[2] : '#f8f9fa'
                              }}
                            >
                              <Form.Check
                                type="checkbox"
                                label="Average CTR"
                                checked={this.state.avgCtrChecked}
                                readOnly
                              />
                              <div style={{ fontSize: '2rem' }}>
                                { (this.state.gscAggregatedData && this.state.gscAggregatedData.average_ctr != null) ?
                                    `${this.state.gscAggregatedData.average_ctr}%` : '-'
                                }
                              </div>
                            </div>
                            <div
                              className="d-inline-block px-4 py-3 border"
                              onClick={() => this.onCheckboxChange(!this.state.avgPositionChecked, 'avgPositionChecked')}
                              style={{
                                color: this.state.avgPositionChecked ? '#ffffff' : '#000000',
                                backgroundColor: this.state.avgPositionChecked ? colors[3] : '#f8f9fa'
                              }}
                            >
                              <Form.Check
                                type="checkbox"
                                label="Average position"
                                checked={this.state.avgPositionChecked}
                                readOnly
                              />
                              <div style={{ fontSize: '2rem' }}>
                                { (this.state.gscAggregatedData && this.state.gscAggregatedData.average_position != null) ?
                                    intToShorthandString(this.state.gscAggregatedData.average_position) : '-'
                                }
                              </div>
                            </div>
                          </div>
                          <ResponsiveContainer height={300} width="99%">
                            <LineChart
                              data={this.state.gscData}
                              margin={{
                                left: 10,
                                right: 10,
                              }}
                            >
                              <XAxis
                                dataKey="date"
                                tickFormatter={(date) => {
                                  return moment(date).format('MM/DD');
                                }}
                                minTickGap={10}
                              />
                              <YAxis
                                yAxisId={0}
                                orientation="left"
                                tickFormatter={(value) => {
                                  return numberWithCommas(value)
                                }}
                              >
                                <Label
                                  value="Clicks, CTR, & Position"
                                  position="left"
                                  offset={0}
                                  angle={-90}
                                  style={{
                                    textAnchor: 'middle',
                                    fontWeight: 'bold'
                                  }}
                                />
                              </YAxis>>
                              { this.state.totalImpressionsChecked &&
                                <YAxis
                                  yAxisId={1}
                                  orientation="right"
                                  tickFormatter={(value) => {
                                    return numberWithCommas(value)
                                  }}
                                >
                                  <Label
                                    value="Impressions"
                                    position="right"
                                    offset={0}
                                    angle={90}
                                    style={{
                                      textAnchor: 'middle',
                                      fontWeight: 'bold'
                                    }}
                                  />
                                </YAxis>
                              }
                              <CartesianGrid strokeDasharray="5 5"/>
                              <Tooltip
                                labelFormatter={(label) => moment(label).format('dddd, MMMM Do YYYY')}
                                formatter={(value) => numberWithCommas(value)}
                              />
                              { this.state.totalClicksChecked &&
                                <Line
                                  name="Clicks"
                                  type="monotype"
                                  dataKey="total_clicks"
                                  stroke={colors[0]}
                                  dot={false}
                                  yAxisId={0}
                                />
                              }
                              { this.state.totalImpressionsChecked &&
                                <Line
                                  name="Impressions"
                                  type="monotype"
                                  dataKey="total_impressions"
                                  stroke={colors[1]}
                                  dot={false}
                                  yAxisId={1}
                                />
                              }
                              { this.state.avgCtrChecked &&
                                <Line
                                  name="CTR"
                                  type="monotype"
                                  dataKey="average_ctr"
                                  stroke={colors[2]}
                                  dot={false}
                                  yAxisId={0}
                                />
                              }
                              { this.state.avgPositionChecked &&
                                <Line
                                  name="Position"
                                  type="monotype"
                                  dataKey="average_position"
                                  stroke={colors[3]}
                                  dot={false}
                                  yAxisId={0}
                                />
                              }
                            </LineChart>
                          </ResponsiveContainer>
                        </div>
                      }
                    </div>
                  }
                  contextType="brand"
                  contextCategory={this.state.category}
                  contextBrand={this.state.category}
                  contextChannel="Google Search Console"
                  contextChartName="Search Performance"
                />
                <div className="mt-4">
                  <Tabs
                    activekey={this.state.selectedTab}
                    onSelect={(selectedTab) => this.setState(() => ({ selectedTab }))}
                  >
                    <Tab
                      eventKey="queries"
                      title="Queries"
                    >
                      { this.state.gscQueriesLoading &&
                        <div className="m-4 text-center">
                          <ClipLoader size={100}/>
                        </div>
                      }
                      { !this.state.gscQueriesLoading &&
                        <ReactTable
                          className="-striped -highlight"
                          data={this.state.gscQueries}
                          columns={[
                            {
                              Header: 'Queries',
                              headerClassName: 'bg-dark text-light border-light wordwrap',
                              accessor: 'query',
                              sortable: true,
                            }, {
                              Header: 'Clicks',
                              headerClassName: 'bg-dark text-light border-light wordwrap',
                              accessor: 'total_clicks',
                              sortable: true,
                              width: 100,
                              Cell: row => (
                                <div className="text-center">
                                  {numberWithCommas(row.original.total_clicks) || '-'}
                                </div>
                              )
                            }, {
                              Header: 'Impressions',
                              headerClassName: 'bg-dark text-light border-light wordwrap',
                              accessor: 'total_impressions',
                              sortable: true,
                              width: 100,
                              Cell: row => (
                                <div className="text-center">
                                  {numberWithCommas(row.original.total_impressions) || '-'}
                                </div>
                              )
                            }, {
                              Header: 'CTR',
                              headerClassName: 'bg-dark text-light border-light wordwrap',
                              accessor: 'average_ctr',
                              sortable: true,
                              width: 100,
                              Cell: row => (
                                <div className="text-center">
                                  { row.original.average_ctr == null &&
                                    <React.Fragment>-</React.Fragment>
                                  }
                                  { row.original.average_ctr != null &&
                                    <React.Fragment>
                                      {`${row.original.average_ctr}%`}
                                    </React.Fragment>
                                  }
                                </div>
                              )
                            }, {
                              Header: 'Position',
                              headerClassName: 'bg-dark text-light border-light wordwrap',
                              accessor: 'average_position',
                              sortable: true,
                              width: 100,
                              Cell: row => (
                                <div className="text-center">
                                  {numberWithCommas(row.original.average_position) || '-'}
                                </div>
                              )
                            }
                          ]}
                          minRows={1}
                          defaultPageSize={10}
                          showPagination={true}
                          defaultSorted={[{ id: 'total_clicks', desc: true }]}
                        />
                      }
                    </Tab>
                    <Tab
                      eventKey="pages"
                      title="Pages"
                    >
                      { this.state.gscPagesLoading &&
                        <div className="m-4 text-center">
                          <ClipLoader size={100}/>
                        </div>
                      }
                      { !this.state.gscPagesLoading &&
                        <ReactTable
                          className="-striped -highlight"
                          data={this.state.gscPages}
                          columns={[
                            {
                              Header: 'Pages',
                              headerClassName: 'bg-dark text-light border-light wordwrap',
                              accessor: 'page',
                              sortable: true,
                              Cell: row => (
                                <div>
                                  <a href={row.original.page} target="_blank">
                                    {row.original.page}
                                  </a>
                                </div>
                              )
                            }, {
                              Header: 'Clicks',
                              headerClassName: 'bg-dark text-light border-light wordwrap',
                              accessor: 'total_clicks',
                              sortable: true,
                              width: 100,
                              Cell: row => (
                                <div className="text-center">
                                  {numberWithCommas(row.original.total_clicks) || '-'}
                                </div>
                              )
                            }, {
                              Header: 'Impressions',
                              headerClassName: 'bg-dark text-light border-light wordwrap',
                              accessor: 'total_impressions',
                              sortable: true,
                              width: 100,
                              Cell: row => (
                                <div className="text-center">
                                  {numberWithCommas(row.original.total_impressions) || '-'}
                                </div>
                              )
                            }, {
                              Header: 'CTR',
                              headerClassName: 'bg-dark text-light border-light wordwrap',
                              accessor: 'average_ctr',
                              sortable: true,
                              width: 100,
                              Cell: row => (
                                <div className="text-center">
                                  { row.original.average_ctr == null &&
                                    <React.Fragment>-</React.Fragment>
                                  }
                                  { row.original.average_ctr != null &&
                                    <React.Fragment>
                                      {`${row.original.average_ctr}%`}
                                    </React.Fragment>
                                  }
                                </div>
                              )
                            }, {
                              Header: 'Position',
                              headerClassName: 'bg-dark text-light border-light wordwrap',
                              accessor: 'average_position',
                              sortable: true,
                              width: 100,
                              Cell: row => (
                                <div className="text-center">
                                  {numberWithCommas(row.original.average_position) || '-'}
                                </div>
                              )
                            }
                          ]}
                          minRows={1}
                          defaultPageSize={10}
                          showPagination={true}
                          defaultSorted={[{ id: 'total_clicks', desc: true }]}
                        />
                      }
                    </Tab>
                  </Tabs>
                </div>
              </div>
            }
          </div>
        }
      </div>
    );
  }
};
