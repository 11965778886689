import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import DateRangePicker from '../../../common/DateRangePicker';
import MetricCategoryMenu from './MetricCategoryMenu';
import CorrelationGraphs from './CorrelationGraphs';
import CorrelationScatter from './CorrelationScatter';
import MetricDistributionSummary from '../../../metrics/MetricDistributionSummary';
import { numberWithCommas, round } from '../../../../utils/numbers';
import { sortAlphabeticalByKey } from '../../../../utils/sorts';
import { LISTEN_ENDPOINT, HEADERS } from '../../../../utils/constants';
import { dispatchReportError } from '../../../../actions/api/errors';
import history from '../../../../routers/history';

export default class CorrelationsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFilter: 'all',
      selectedSubtab: 'graphs',
      brandMetrics: [],
      brandMetricsMap: {},
      metricCategories: {
        'Ads': [],
        'Brand Pillars': [],
        'Economics': [],
        'Email': [],
        'Employer Review': [],
        'Google Analytics (Private)': [],
        'Media Cast': [],
        'Other': [],
        'PR, News, Blogs': [],
        'Product Review': [],
        'Public Markets': [],
        'Social': [],
        'Survey (Private)': [],
        'Web & Search': [],
      },
      metricExcludeList: [
        'industry_report_performance',
        'industry_award_performance',
        'customer_affirmation_impact',
        'industry_award_count',
        'industry_report_count',
      ],
      selectedMetric: undefined,
      selectedMetricName: undefined,
      selectedMetricIsPrivate: undefined,
      selectedAudienceSize: 'all',
      categoryMetricCorrelationsData: {},
      categoryMetricCorrelationsDataLoading: false,
      brandMetricCorrelationData: {},
      brandMetricCorrelationDataLoading: false,
      allMetricCorrelationData: {},
      allMetricCorrelationDataLoading:false,
      startDate: moment().utc().subtract(6, 'month').startOf('day'),
      endDate: moment().utc().endOf('day'),
      correlationGroups: [],
      correlationData: {},
      showFavorableResultsAlert: true,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchBrandMetrics();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.categories !== this.props.categories ||
      prevProps.brand !== this.props.brand ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      this.fetchCategoryMetricCorrelationsData();
      this.fetchBrandMetricCorrelationData();
      this.fetchAllMetricCorrelationData();
    }
    if (prevState.selectedAudienceSize !== this.state.selectedAudienceSize) {
      this.fetchAllMetricCorrelationData();
    }
    if (
      prevState.selectedMetricIsPrivate === false &&
      this.state.selectedMetricIsPrivate === true &&
      this.state.selectedFilter === 'category'
    ) {
      this.onSelectedFilterChange('brand');
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchBrandMetrics = () => {
    this.setState(() => ({ brandMetricsLoading: true }));
    axios.get(
      `${LISTEN_ENDPOINT}/api/brand-metrics?add_frequency_data=True`,
      HEADERS
    ).then(response => {
      let brandMetrics = [];
      for (const bm of response.data) {
        if (bm.production_status === 'prod') {
          brandMetrics.push(bm);
        }
      }
      // add brand pillars to metrics
      brandMetrics = brandMetrics.concat([
        {
          metric_name: 'brand_presence',
          metric_label: 'Brand Presence',
          channel_name: 'Brand Pillars',
          is_private: false,
          production_status: 'prod',
          frequency: 'Daily',
        },
        {
          metric_name: 'brand_attention',
          metric_label: 'Brand Attention',
          channel_name: 'Brand Pillars',
          is_private: false,
          production_status: 'prod',
          frequency: 'Daily',
        },
        {
          metric_name: 'brand_reputation',
          metric_label: 'Brand Reputation',
          channel_name: 'Brand Pillars',
          is_private: false,
          production_status: 'prod',
          frequency: 'Daily',
        },
        {
          metric_name: 'brand_momentum',
          metric_label: 'Brand Momentum',
          channel_name: 'Brand Pillars',
          is_private: false,
          production_status: 'prod',
          frequency: 'Daily',
        }
      ]);
      // add other to metrics
      brandMetrics = brandMetrics.concat([
        {
          metric_name: 'share_of_everything',
          metric_label: 'Share of Everything',
          channel_name: 'Other',
          is_private: false,
          production_status: 'prod',
          frequency: 'Every month',
        },
        {
          metric_name: 'share_of_owned',
          metric_label: 'Share of Owned',
          channel_name: 'Other',
          is_private: false,
          production_status: 'prod',
          frequency: 'Every month',
        },
        {
          metric_name: 'share_of_earned',
          metric_label: 'Share of Earned',
          channel_name: 'Other',
          is_private: false,
          production_status: 'prod',
          frequency: 'Every month',
        },
        {
          metric_name: 'share_of_paid',
          metric_label: 'Share of Paid',
          channel_name: 'Other',
          is_private: false,
          production_status: 'prod',
          frequency: 'Every month',
        },
        {
          metric_name: 'brandops_usage',
          metric_label: 'BrandOps Usage',
          channel_name: 'Other',
          is_private: false,
          production_status: 'prod',
          frequency: 'Every month',
        },
        {
          metric_name: 'brandops_months_active',
          metric_label: 'BrandOps Months Active',
          channel_name: 'Other',
          is_private: false,
          production_status: 'prod',
          frequency: 'Every month',
        },
      ]);
      // add economics to metrics
      brandMetrics = brandMetrics.concat([
        {
          metric_name: 'us_consumer_sentiment_index',
          metric_label: 'US Consumer Sentiment Index',
          channel_name: 'Economics',
          is_private: false,
          production_status: 'prod',
          frequency: 'Every month',
        },
        {
          metric_name: 'us_unemployment_rate_adjusted',
          metric_label: 'US Unemployment Rate Adjusted',
          channel_name: 'Economics',
          is_private: false,
          production_status: 'prod',
          frequency: 'Every month',
        },
        {
          metric_name: 'us_unemployment_rate_not_adjusted',
          metric_label: 'US Unemployment Rate',
          channel_name: 'Economics',
          is_private: false,
          production_status: 'prod',
          frequency: 'Every month',
        },
        {
          metric_name: 'us_consumer_price_index',
          metric_label: 'US Consumer Price Index',
          channel_name: 'Economics',
          is_private: false,
          production_status: 'prod',
          frequency: 'Every month',
        },
        {
          metric_name: 'us_purchasing_managers_index',
          metric_label: 'US Purchasing Managers Index',
          channel_name: 'Economics',
          is_private: false,
          production_status: 'prod',
          frequency: 'Every month',
        }
      ]);
      // add public markets to metrics
      brandMetrics = brandMetrics.concat([
        {
          metric_name: 'snp_market_summary',
          metric_label: 'S&P 500',
          channel_name: 'Public Markets',
          is_private: false,
          production_status: 'prod',
          frequency: 'Daily',
        },
        {
          metric_name: 'dji_market_summary',
          metric_label: 'Dow Jones',
          channel_name: 'Public Markets',
          is_private: false,
          production_status: 'prod',
          frequency: 'Daily',
        },
        {
          metric_name: 'nasdaq_market_summary',
          metric_label: 'Nasdaq Composite',
          channel_name: 'Public Markets',
          is_private: false,
          production_status: 'prod',
          frequency: 'Daily',
        },
      ]);

      brandMetrics.sort(sortAlphabeticalByKey('metric_label'));

      const brandMetricsMap = {};
      for (const metric of brandMetrics) {
        if (metric.production_status === 'prod') {
          brandMetricsMap[metric.metric_name] = metric;
        }
      }

      if (this.state.isMounted) {
        this.setState(() => ({
          brandMetrics,
          brandMetricsMap,
          brandMetricsLoading: false,
        }), () => this.setMetricCategories());
      }
    }).catch(error => {
      console.error('Error: unable to fetch brand metrics');
      if (this.state.isMounted) {
        this.setState(() => ({
          brandMetrics: [],
          brandMetricsMap: {},
          brandMetricsLoading: false,
        }), () => this.setMetricCategories());
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  }

  updateCorrelationDataForFavorableResults = (correlationData) => {
    // if one and only one of the metrics is "low_is_good",
    // multiply the value by -1 to reverse the correlation direction
    for (const [firstMetricName, firstMetricValues] of Object.entries(correlationData)) {
      for (const [secondMetricName, value] of Object.entries(correlationData[firstMetricName])) {
        if (this.state.brandMetricsMap[firstMetricName] && this.state.brandMetricsMap[secondMetricName]) {
          if (
            (
              this.state.brandMetricsMap[firstMetricName].score_type === 'low_is_good' &&
              this.state.brandMetricsMap[secondMetricName].score_type === 'high_is_good'
            ) ||
            (
              this.state.brandMetricsMap[firstMetricName].score_type === 'high_is_good' &&
              this.state.brandMetricsMap[secondMetricName].score_type === 'low_is_good'
            ) &&
            value != null
          ) {
            correlationData[firstMetricName][secondMetricName] = value * -1;
          }
        }
      }
    }
    return correlationData;
  }

  fetchCategoryMetricCorrelationsData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryMetricCorrelationsDataLoading: true }));
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/metric-correlations?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        let categoryMetricCorrelationData = response.data;
        categoryMetricCorrelationData = this.updateCorrelationDataForFavorableResults(categoryMetricCorrelationData);

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryMetricCorrelationData,
            categoryMetricCorrelationDataLoading: false,
          }), () => this.setCorrelationData());
        }
      }).catch(error => {
        console.log(`Error: failed to fetch category "${this.props.category.name}" metrics correlation...`);
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            categoryMetricCorrelationData: {},
            categoryMetricCorrelationDataLoading: false,
          }), () => this.setCorrelationData());
        }
      });
    }
  }

  fetchBrandMetricCorrelationData = () => {
    if (this.props.brand) {
      this.setState(() => ({ brandMetricCorrelationDataLoading: true }));
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/metric-correlations?product_brand_id=${this.props.brand.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        let  brandMetricCorrelationData = response.data;
        brandMetricCorrelationData = this.updateCorrelationDataForFavorableResults(brandMetricCorrelationData);

        if (this.state.isMounted) {
          this.setState(() => ({
            brandMetricCorrelationData,
            brandMetricCorrelationDataLoading: false,
          }), () => this.setCorrelationData());
        }
      }).catch(error => {
        console.log('Error: failed to fetch brand metrics correlation...');
        if (this.state.isMounted) {
          this.setState(() => ({
            brandMetricCorrelationData: {},
            brandMetricCorrelationDataLoading: false,
          }), () => this.setCorrelationData());
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchAllMetricCorrelationData = () => {
    this.setState(() => ({ allMetricCorrelationDataLoading: true }));
    const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
    let additionalParams = '';
    if (this.state.selectedAudienceSize !== 'all') {
      additionalParams = `&audience_size=${this.state.selectedAudienceSize}`
    }
    axios.get(
      `${LISTEN_ENDPOINT}/api/metric-correlations?start_date=${formattedStartDate}&end_date=${formattedEndDate}${additionalParams}`,
      HEADERS
    ).then(response => {
      let allMetricCorrelationData = response.data;
      allMetricCorrelationData = this.updateCorrelationDataForFavorableResults(allMetricCorrelationData);

      if (this.state.isMounted) {
        this.setState(() => ({
          allMetricCorrelationData,
          allMetricCorrelationDataLoading: false,
        }), () => this.setCorrelationData());
      }
    }).catch(error => {
      console.log('Error: failed to fetch all metrics correlation...');
      if (this.state.isMounted) {
        this.setState(() => ({
          allMetricCorrelationData: {},
          allMetricCorrelationDataLoading: false,
        }), () => this.setCorrelationData());
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  setMetricCategories = () => {
    // fetch correlation data now that brand metrics are retrieved
    this.fetchCategoryMetricCorrelationsData();
    this.fetchBrandMetricCorrelationData();
    this.fetchAllMetricCorrelationData();
    // build metric categories
    const metricCategories = {
      'Ads': [],
      'Brand Pillars': [],
      'Economics': [],
      'Email': [],
      'Employer Review': [],
      'Google Analytics (Private)': [],
      'Media Cast': [],
      'Other': [],
      'PR, News, Blogs': [],
      'Product Review': [],
      'Public Markets': [],
      'Social': [],
      'Survey (Private)': [],
      'Web & Search': [],
    };
    for (const metric of this.state.brandMetrics) {
      if (!this.state.metricExcludeList.includes(metric.metric_name)) {
        switch (metric.channel_name) {
          case 'Ads':
            metricCategories['Ads'].push(metric);
            break;
          case 'Brand Pillars':
            metricCategories['Brand Pillars'].push(metric);
            break;
          case 'Economics':
            metricCategories['Economics'].push(metric);
            break;
          case 'Emails':
          case 'Email Campaigns':
            metricCategories['Email'].push(metric);
            break;
          case 'Employer Reviews':
            metricCategories['Employer Review'].push(metric);
            break;
          case 'Web Analytics':
            metricCategories['Google Analytics (Private)'].push(metric);
            break;
          case 'Videos':
          case 'Podcast':
          case 'Webinar':
            metricCategories['Media Cast'].push(metric);
              break;
          case 'Blogs':
          case 'News':
            metricCategories['PR, News, Blogs'].push(metric);
            break;
          case 'Product Reviews':
            metricCategories['Product Review'].push(metric);
            break;
          case 'Public Markets':
            metricCategories['Public Markets'].push(metric);
            break;
          case 'Other':
            metricCategories['Other'].push(metric);
            break;
          case 'Social':
            metricCategories['Social'].push(metric);
            break;
          case 'Surveys via BrandOps':
            metricCategories['Survey (Private)'].push(metric);
            break;
          case 'Web':
          case 'Search Engine Results':
            metricCategories['Web & Search'].push(metric);
            break;
        }
      }
    }

    this.setState(() => ({
      metricCategories,
      selectedMetric: metricCategories['Ads'].length > 0 ?
        metricCategories['Ads'][0] :
        undefined,
      selectedMetricName: metricCategories['Ads'].length > 0 ?
        metricCategories['Ads'][0].metric_name :
        undefined,
      selectedMetricIsPrivate: metricCategories['Ads'].length > 0 ?
        metricCategories['Ads'][0].is_private :
        undefined,
    }), () => this.setCorrelationData());
  };

  onSelectedFilterChange = (selectedFilter) => {
    this.setState(
      () => ({ selectedFilter }),
      () => this.setCorrelationData()
    );
  }

  onFilterDatesChange = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  };

  onMetricSelect = (metric) => {
    if (metric && metric.metric_name !== this.state.selectedMetricName) {
      this.setState(
        () => ({
          selectedMetric: metric,
          selectedMetricName: metric.metric_name,
          selectedMetricIsPrivate: metric.is_private,
        }),
        () => this.setCorrelationData()
      );
    }
  };

  onAudienceSizeChange = (event) => {
    const selectedAudienceSize = event.currentTarget.value;
    this.setState(() => ({ selectedAudienceSize }));
  };

  setCorrelationData = () => {
    if (this.state.selectedMetricName) {
      const correlationGroupsMap = {
        highest: [],
        midHigh: [],
        medium: [],
        midLow: [],
        lowest: [],
      };

      let correlationData;
      if (this.state.selectedFilter === 'brand') {
        correlationData = this.state.brandMetricCorrelationData[this.state.selectedMetricName];
      } else if (this.state.selectedFilter === 'category') {
        correlationData = this.state.categoryMetricCorrelationData[this.state.selectedMetricName];
      } else if (this.state.selectedFilter === 'all') {
        correlationData = this.state.allMetricCorrelationData[this.state.selectedMetricName];
      }

      if (correlationData) {
        // add metrics to their respective correlation groups
        for (const [metricName, value] of Object.entries(correlationData)) {
          if (value !== null) {
            const metricLabel = this.state.brandMetricsMap[metricName] ? this.state.brandMetricsMap[metricName].metric_label : metricName;
            const correlationValue = round(value, 2);
            if (correlationValue >= .7) {
              correlationGroupsMap.highest.push({
                metricName,
                metricLabel,
                correlationValue
              });
            } else if (correlationValue >= .4) {
              correlationGroupsMap.midHigh.push({
                metricName,
                metricLabel,
                correlationValue
              });
            } else if (correlationValue >= -.39) {
              correlationGroupsMap.medium.push({
                metricName,
                metricLabel,
                correlationValue
              });
            } else if (correlationValue >= -.69) {
              correlationGroupsMap.midLow.push({
                metricName,
                metricLabel,
                correlationValue
              });
            } else {
              correlationGroupsMap.lowest.push({
                metricName,
                metricLabel,
                correlationValue
              });
            }
          }
        }
      }

      // convert map to array and sort groups
      const correlationGroups = Object.entries(correlationGroupsMap).map(([group, metrics]) => {
        let groupLabel = '';
        if (group === 'highest') {
          return {
            label: 'where r is 1.0 ⟷ 0.7',
            metrics: metrics.sort((a, b) => b.correlationValue - a.correlationValue)
          }
        } else if (group === 'midHigh') {
          return {
            label: 'where r is 0.69 ⟷ 0.4',
            metrics: metrics.sort((a, b) => b.correlationValue - a.correlationValue)
          }
        } else if (group === 'medium') {
          return {
            label: 'where r is 0.39 ⟷ -0.39',
            metrics: metrics.sort((a, b) => b.correlationValue - a.correlationValue)
          }
        } else if (group === 'midLow') {
          return {
            label: 'where r is -0.4 ⟷ -0.69',
            metrics: metrics.sort((a, b) => b.correlationValue - a.correlationValue)
          }
        } else if (group === 'lowest') {
          // sort from lowest to highest
          return {
            label: 'where r is -0.7 ⟷ -1.0',
            metrics: metrics.sort((a, b) => b.correlationValue - a.correlationValue)
          }
        }
      });
      this.setState(() => ({
        correlationGroups,
        correlationData: correlationData || {},
      }));
    } else {
      this.setState(() => ({
        correlationGroups: [],
        correlationData: {},
      }));
    }
  };

  render () {
    return (
      <div>
        <div className="mb-2 p-2 bg-bops-blue text-light">
          <div className="d-inline-block">
            <span
              className="mr-2"
              style={{ fontSize: '1rem', fontWeight: 'normal' }}
            >
              Date Range
            </span>
            <DateRangePicker
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              updateDates={this.onFilterDatesChange}
            />
          </div>
          <div className="d-inline-block ml-4">
            <span
              className="mr-2"
              style={{ fontSize: '1rem', fontWeight: 'normal' }}
            >
              Audience Size
            </span>
            <div
              className="d-inline-block"
              style={{ width: '100px' }}
            >
              <Form.Control
                as="select"
                value={this.state.selectedAudienceSize}
                onChange={this.onAudienceSizeChange}
                size="sm"
              >
                <option value="all">All</option>
                <option value="1">Small</option>
                <option value="2">Medium</option>
                <option value="3">Large</option>
              </Form.Control>
            </div>
          </div>
        </div>
        <Row>
          <Col xs={12} md={6} lg={3}>
            <div
              className="pre-scrollable border rounded bg-white"
              style={{
                minHeight: 'calc(100vh - 300px)',
                maxHeight: 'calc(100vh - 300px)',
              }}
            >
              { Object.entries(this.state.metricCategories).map(([categoryName, metrics], i) => {
                  return (
                    <MetricCategoryMenu
                      key={`mkm-${i}`}
                      categoryName={categoryName}
                      metrics={metrics}
                      selectedMetricName={this.state.selectedMetricName}
                      onMetricSelect={this.onMetricSelect}
                    />
                  )
                })
              }
            </div>
          </Col>
          <Col xs={12} md={6} lg={9}>
            <Alert
              className="mb-2"
              variant="warning"
              show={this.state.showFavorableResultsAlert}
              onClose={() => this.setState({ showFavorableResultsAlert: false })}
              dismissible
            >
              For some metrics, a lower value is better. Correlations are based on favorable results.
            </Alert>
            <Tabs
              activeKey={this.state.selectedSubtab}
              onSelect={(selectedSubtab) => this.setState({ selectedSubtab })}
            >
              <Tab
                eventKey="graphs"
                title="Correlation Chart"
              >
                <div
                  className="pre-scrollable"
                  style={{
                    minHeight: this.state.showFavorableResultsAlert ?
                      'calc(100vh - 400px)' : 'calc(100vh - 342px)',
                    maxHeight: this.state.showFavorableResultsAlert ?
                      'calc(100vh - 400px)' : 'calc(100vh - 342px)',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                  }}
                >
                  { this.state.brandMetricsLoading &&
                    <div className="m-4 text-center">
                      <ClipLoader size={100}/>
                    </div>
                  }
                  { !this.state.brandMetricsLoading &&
                    <CorrelationGraphs
                      category={this.props.category}
                      brand={this.props.brand}
                      brandMetricCorrelationData={this.state.brandMetricCorrelationData}
                      categoryMetricCorrelationData={this.state.categoryMetricCorrelationData}
                      allMetricCorrelationData={this.state.allMetricCorrelationData}
                      allMetricCorrelationDataLoading={this.state.allMetricCorrelationDataLoading}
                      selectedMetricName={this.state.selectedMetricName}
                      selectedMetricIsPrivate={this.state.selectedMetricIsPrivate}
                      brandMetricsMap={this.state.brandMetricsMap}
                    />
                  }
                </div>
              </Tab>
              <Tab
                eventKey="rbuckets"
                title="Correlation Groupings"
              >
                <div className="mt-2 mb-3">
                  <Form.Check
                    type="radio"
                    label="All Brands"
                    checked={this.state.selectedFilter === 'all'}
                    onChange={() => this.onSelectedFilterChange('all')}
                    inline
                  />
                  <Form.Check
                    type="radio"
                    label="Category"
                    checked={this.state.selectedFilter === 'category'}
                    onChange={() => this.onSelectedFilterChange('category')}
                    disabled={this.state.selectedMetricIsPrivate === true}
                    inline
                  />
                  <Form.Check
                    type="radio"
                    label={`Brand (${this.props.brand.name})`}
                    checked={this.state.selectedFilter === 'brand'}
                    onChange={() => this.onSelectedFilterChange('brand')}
                    inline
                  />
                </div>
                <div
                  className="pre-scrollable"
                  style={{
                    minHeight: this.state.showFavorableResultsAlert ?
                      'calc(100vh - 408px)' : 'calc(100vh - 350px)',
                    maxHeight: this.state.showFavorableResultsAlert ?
                      'calc(100vh - 408px)' : 'calc(100vh - 350px)',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                  }}
                >
                  <Row>
                    { this.state.correlationGroups.map((correlationGroup, i) => {
                        return (
                          <Col
                            key={`mc-cg-${i}`}
                            xs={4}
                          >
                            <Card className="mb-4">
                              <Card.Header className="bg-white text-center font-weight-bold p-2">
                                {correlationGroup.label}
                              </Card.Header>
                              <Card.Body
                                className="pre-scrollable p-2"
                                style={{
                                  minHeight: '225px',
                                  maxHeight: '225px',
                                  fontSize: '.875rem'
                                }}
                              >
                                { correlationGroup.metrics.map((metric, mi) => {
                                    let valueDisplay = metric.correlationValue;
                                    // add .0 for 1 values for display consistency
                                    if (
                                      metric.correlationValue === 1 ||
                                      metric.correlationValue === -1
                                    ) {
                                      valueDisplay = metric.correlationValue.toFixed(1);
                                    }

                                    return (
                                      <div
                                        key={`mc-cg-${i}-m-${mi}`}
                                        className="ellipsis"
                                      >
                                        {`${valueDisplay} ${metric.metricLabel}`}
                                      </div>
                                    )
                                  })
                                }
                              </Card.Body>
                            </Card>
                          </Col>
                        )
                      })
                    }
                  </Row>
                </div>
              </Tab>
              <Tab
                eventKey="scatter"
                title="Correlation Scatter"
              >
                <div
                  className="pre-scrollable"
                  style={{
                    minHeight: this.state.showFavorableResultsAlert ?
                      'calc(100vh - 400px)' : 'calc(100vh - 342px)',
                    maxHeight: this.state.showFavorableResultsAlert ?
                      'calc(100vh - 400px)' : 'calc(100vh - 342px)',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                  }}
                >
                  <CorrelationScatter
                    category={this.props.category}
                    brand={this.props.brand}
                    brandMetricsMap={this.state.brandMetricsMap}
                    selectedMetricName={this.state.selectedMetricName}
                    selectedMetricIsPrivate={this.state.selectedMetricIsPrivate}
                    selectedAudienceSize={this.state.selectedAudienceSize}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                  />
                </div>
              </Tab>
              <Tab
                eventKey="distributions"
                title="Distributions"
              >
                <div
                  className="pre-scrollable"
                  style={{
                    minHeight: this.state.showFavorableResultsAlert ?
                      'calc(100vh - 400px)' : 'calc(100vh - 342px)',
                    maxHeight: this.state.showFavorableResultsAlert ?
                      'calc(100vh - 400px)' : 'calc(100vh - 342px)',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                  }}
                >
                  { (this.state.selectedMetric && this.state.selectedMetric.id) &&
                    <MetricDistributionSummary
                      metric={this.state.selectedMetric}
                      audienceProfileId={
                        this.state.selectedAudienceSize !== 'all' ?
                          this.state.selectedAudienceSize:
                          undefined
                      }
                      customerId={this.props.user.customerId}
                    />
                  }
                  { !(this.state.selectedMetric && this.state.selectedMetric.id) &&
                    <div className="m-4 text-center">
                      <div className="d-inline-block">
                        <Alert variant="warning">
                          Distribution is not available.
                        </Alert>
                      </div>
                    </div>
                  }
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </div>
    );
  }
};
