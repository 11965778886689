import { connect } from 'react-redux';
import WebAnalyticsCollector from '../../../components/settings/contentCollectors/WebAnalyticsCollector';

const mapStateToProps = (state) => ({
  user: state.user,
  myCategoryBrands: state.myCategoryBrands,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(WebAnalyticsCollector);
