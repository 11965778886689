import React from 'react';
import CategoryVideoStatsSummary from './CategoryVideoStatsSummary';
import CategoryVideoSummaryGraphs from './CategoryVideoSummaryGraphs';
import CategoryVideoShareOfGraphs from './CategoryVideoShareOfGraphs';
import CategoryYoutubeTopVideos from '../video/CategoryYoutubeTopVideos';

export default class CategoryVideos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevProps.categoryId !== this.props.categoryId
    ) {

    }
  };

  render () {
    return (
      <div>
        <CategoryVideoStatsSummary
          user={this.props.user}
          sixMonths={this.props.sixMonths}
          selectedMonth={this.props.selectedMonth}
          updateSelectedMonth={this.props.updateSelectedMonth}
          category={this.props.category}
        />
        <div className="mt-5 mb-4 band-separator" />
        <CategoryVideoSummaryGraphs
          user={this.props.user}
          category={this.props.category}
        />
        <div className="my-4 band-separator" />
        <CategoryVideoShareOfGraphs
          category={this.props.category}
        />
        <CategoryYoutubeTopVideos
          category={this.props.category}
        />
      </div>
    );
  }
};
