import axios from 'axios';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { reportError } from '../api/errors';

export const fetchAlerts = (customerId) => {
  return (dispatch) => {
    if (customerId) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/brand-alert-histories-custom?added_by_customer_id=${customerId}&date_range=7&limit=30`,
        HEADERS
      ).then(response => {
        const alerts = response.data;
        // add metric label for brand scores
        for (const a of alerts) {
          if (a.brand_alert.alert_on === 'brand_score') {
            a.brand_alert.metric_label = 'Brand Score';
          } else if (a.brand_alert.alert_on === 'brand_stage_score') {
            switch (a.brand_alert.identifier) {
              case 1:
                a.brand_alert.metric_label = 'Digital Presence Score';
                break;
              case 3:
                a.brand_alert.metric_label = 'Audience Attention Score';
                break;
              case 5:
                a.brand_alert.metric_label = 'Brand Reputation Score';
                break;
              case 6:
                a.brand_alert.metric_label = 'Momentum Score';
                break;
              default:
                a.brand_alert.metric_label = 'Unknown';
                break;
            }
          }
        }
        dispatch(addAlerts(alerts));
      }).catch(error => {
        dispatch(addAlerts([]));
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatch(reportError(error.response));
        }
      });
    }
  };
};

export const addAlerts = (alerts) => ({
  type: 'ADD_ALERTS',
  alerts
});
