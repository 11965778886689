import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GeneralSummaryGraph from '../graphs/GeneralSummaryGraph';
import FollowersSummaryGraph from './FollowersSummaryGraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class LinkedInSummaryGraphs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companyLinkedInPostsTrendData: [],
      linkedInPostsScoreType: undefined,
      linkedInEngagementScoreType: undefined,
      companyLinkedInPostsTrendDataLoading: false,
      categoryAveragePostsData: [],
    }
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchCompanyLinkedInPostsTrendData();
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.companyId !== this.props.companyId ||
      prevProps.allMonthsChecked !== this.props.allMonthsChecked
    ) {
      this.fetchCompanyLinkedInPostsTrendData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchCompanyLinkedInPostsTrendData = () => {
    if (this.props.brand) {
      this.setState(() => ({ companyLinkedInPostsTrendDataLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/product-brands/${this.props.brand.id}/linkedin-posts-trend${allMonthsStartDateParam ? `?start-date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let companyLinkedInPostsTrendData = response.data.monthly_stats;
        const linkedInPostsScoreType = response.data.linkedin_post_count ? response.data.linkedin_post_count.score_type : undefined;
        const linkedInEngagementScoreType = response.data.engagement ? response.data.engagement.score_type : undefined;
        // merge category average data into original data source
        companyLinkedInPostsTrendData = companyLinkedInPostsTrendData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAveragePostsData[i]))
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            companyLinkedInPostsTrendData,
            linkedInPostsScoreType,
            linkedInEngagementScoreType,
            companyLinkedInPostsTrendDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch company linkedin posts trend data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            companyLinkedInPostsTrendData: [],
            linkedInPostsScoreType: undefined,
            linkedInEngagementScoreType: undefined,
            companyLinkedInPostsTrendDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=linkedin_avg_post_stats${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAveragePostsData = response.data.category_stats;
          // merge category average data into original data source
          const companyLinkedInPostsTrendData = this.state.companyLinkedInPostsTrendData.map((x, i) => {
            return (Object.assign({}, x, categoryAveragePostsData[i]))
          });

          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAveragePostsData,
              companyLinkedInPostsTrendData
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average linkedin posts data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAveragePostsData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  render () {
    return (
      <div>
        <Row>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Product Brand Posts"
                icon={
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={faStickyNote}
                    color="#4fa2f3"
                  />
                }
                metricName="social_owned_post_count_linkedin"
                data={this.state.companyLinkedInPostsTrendData}
                dataKey="linkedin_post_count"
                dataLabel="Product Brand Posts"
                scoreType={this.state.linkedInPostsScoreType}
                loading={this.state.companyLinkedInPostsTrendDataLoading}
                showPercentChange={true}
                showAverageData={true}
                averageDataKey="avg_linkedin_posts"
                averageDataLabel="Category Average"
                showTooltip={true}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Social"
                contextProperty="LinkedIn"
                contextChartName="Product Brand Posts"
                shareClassName="linkedin-posts-img"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <FollowersSummaryGraph
                companyId={this.props.companyId}
                category={this.props.category}
                brand={this.props.brand}
                allMonthsChecked={this.props.allMonthsChecked}
                updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                allMonthsStartDate={this.props.allMonthsStartDate}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Engagement"
                icon={
                  <img
                    className="mr-2 align-top"
                    src="/images/Sentiment_Intensity_Icon.png"
                  />
                }
                metricName="social_engagement_linkedin"
                data={this.state.companyLinkedInPostsTrendData}
                dataKey="engagement"
                dataLabel="Engagement"
                scoreType={this.state.linkedInEngagementScoreType}
                loading={this.state.companyLinkedInPostsTrendDataLoading}
                showPercentChange={true}
                showAverageData={true}
                averageDataKey="avg_linkedin_engagement"
                averageDataLabel="Category Average"
                showTooltip={true}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Social"
                contextProperty="LinkedIn"
                contextChartName="Engagement"
                shareClassName="linkedin-engagement-img"
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};
