import React from 'react';
import CategoryEmployerReviewStatsSummary from './CategoryEmployerReviewStatsSummary';
import CategoryShareOfEmployerReviewsGraph from '../graphs/CategoryShareOfEmployerReviewsGraph';

export default class CategoryEmployerReviews extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {

  };

  render () {
    return (
      <div>
        <CategoryEmployerReviewStatsSummary
          user={this.props.user}
          sixMonths={this.props.sixMonths}
          selectedMonth={this.props.selectedMonth}
          updateSelectedMonth={this.props.updateSelectedMonth}
          category={this.props.category}
        />
        <div className="mt-5 mb-4 band-separator" />
        <CategoryShareOfEmployerReviewsGraph
          title="Share of All Employer Reviews"
          description="Share of All Employer Reviews allows you to visualize the amount of reviews by company for the category"
          categoryType={this.props.category.category_type}
          categoryId={this.props.categoryId}
          category={this.props.category}
          reviewType="all"
        />
        <CategoryShareOfEmployerReviewsGraph
          title="Share of 1 & 2 Star Employer Reviews"
          description="Share of 1 & 2 Star Employer Reviews allows you to visualize the amount of poor reviews by company for the category"
          categoryType={this.props.category.category_type}
          categoryId={this.props.categoryId}
          category={this.props.category}
          reviewType="bad"
        />
        <CategoryShareOfEmployerReviewsGraph
          title="Share of 4 & 5 Star Employer Reviews"
          description="Share of 4 & 5 Star Employer Reviews allows you to visualize the amount of good reviews by company for the category"
          categoryType={this.props.category.category_type}
          categoryId={this.props.categoryId}
          category={this.props.category}
          reviewType="good"
        />
      </div>
    );
  }
};
