import React from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import EditEventForm from './EditEventForm';
import Event from './Event';
import LoginContainer from '../../containers/common/LoginContainer';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryEventTimeline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productBrands: [],
      selectedEvents: [],
      eventsLoading: false,
      showPublicEvents: true,
      enabledEditEventIds: [],
      newEvents: []
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.categoryId) {
      this.fetchProductBrands();
      this.fetchEvents();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.customerId !== this.props.customerId) ||
        (prevProps.categoryId !== this.props.categoryId) ) {
      this.fetchProductBrands();
      this.fetchEvents();
    }
  };

  fetchProductBrands = () => {
    let productBrands = []; let allBrandFavIcons = [];
    for (const brand of this.props.category.product_brands) {
      let companyId = brand.company_id;
      allBrandFavIcons.push(axios.get(
          `${LISTEN_ENDPOINT}/api/companies/${companyId}`,
          HEADERS
        ).then(response => {
          const company = response.data;
          brand.favIcon = company.favicon_url;
          brand.checked = true;
          productBrands.push(brand);
        }).catch(error => {
          console.error('Error: unable to fetch company fav icons');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        })
      );
    }

    Promise.all(allBrandFavIcons).then(responses => {
      this.setState(() => ({ productBrands }));
    }).catch(error => {
      console.log('Error: failed to promise all brand fav icons');
    });
  }

  fetchEvents = () => {
    this.setState(() => ({ eventsLoading: true }));
    axios.get(
      `${LISTEN_ENDPOINT}/api/events-curated-categories?category_id=${this.props.categoryId}&category_type=${this.props.categoryType}&customer_id=${this.props.customerId}`,
      HEADERS
    ).then(response => {
      const categoryEvents = response.data;
      categoryEvents.sort(function(a, b){
        let dateA = new Date(a.event_created_on);
        let dateB = new Date(b.event_created_on);
        if (dateA>dateB)
          return -1;
        else if (dateA<dateB)
          return 1;
        else {
          dateA = new Date(a.updated_at);
          dateB = new Date(b.updated_at);
          return dateA>dateB ? -1 : dateA<dateB ? 1 : 0;
        }
      });
      this.setState(() => ({
        selectedEvents: categoryEvents,
        categoryEvents,
        eventsLoading: false
      }));
    }).catch(error => {
      console.error('Error: unable to fetch customer events');
      this.setState(() => ({
        selectedEvents: [],
        categoryEvents: [],
        eventsLoading: false
      }));
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  }

  filterEvents = (productBrands) => {
    let selectedEvents = [];
    for (const event of this.state.categoryEvents) {
      for (const brand of productBrands) {
        if (event.product_brand_id == brand.id && brand.checked == true) {
          selectedEvents.push(event);
        }
      }
    }
    this.setState(() => ({ selectedEvents }));
  }

  onProductBrandChange = (brandId) => {
    let productBrands = this.state.productBrands;
    for (const brand of productBrands) {
      if (brand.id == brandId)
        brand.checked = brand.checked==false ? true : false;
    }
    this.filterEvents(productBrands);
    this.setState(() => ({ productBrands }));
  }

  addEvent = () => {
    const id = Math.random().toString(36).substring(7);
    let event = {
      id: id,
      customer_id: this.props.customerId,
      user_id: this.props.user.id,
      event_created_on: moment(new Date()).startOf('day').format('YYYY-MM-DD hh:mm:ss'),
      product_brand_id: this.props.category.product_brands[0].id,
      summary: '',
      is_public: 0
    }
    const newEvent = {
      id,
      form: (
        <EditEventForm
          event={event}
          handleClose={this.removeAddedEvent}
          refreshEvents={this.fetchEvents}
          new={true}
          categoryEvent={true}
          productBrands={this.props.category.product_brands}
        />
      )
    };
    this.setState((prevState) => ({
      newEvents: [...prevState.newEvents, newEvent]
    }));
  }

  getFavIcon = (brandId) => {
    for (const brand of this.state.productBrands) {
      if (brand.id == brandId)
        return brand.favIcon;
    }
  }

  showPublicEventsChange = () => {
    if (this.state.showPublicEvents==false)
      this.setState(() => ({ showPublicEvents: true }));
    else
      this.setState(() => ({ showPublicEvents: false }));
  }

  enableEditEvent = (id) => {
    this.setState((prevState) => ({
      enabledEditEventIds: [...prevState.enabledEditEventIds, id]
    }));
  };

  disbleEditEvent = (id) => {
    this.setState((prevState) => ({
      enabledEditEventIds: prevState.enabledEditEventIds.filter(bsti => bsti !== id)
    }));
  };

  removeAddedEvent = (id) => {
    this.setState((prevState) => ({
      newEvents: prevState.newEvents.filter((bsti) => bsti.id !== id)
    }));
  };

  deleteEvent = (id) => {
    axios.delete(
      `${LISTEN_ENDPOINT}/api/events-curated/${id}`,
      HEADERS
    ).then(response => {
      this.fetchEvents();
    }).catch(error => {
      console.log('Error: unable to remove event');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  }

  render () {
    return (
      <div className="p-4">
        { this.props.customerId &&
          <div>
            <Row>
              <div className="pl-2">
              { this.state.productBrands.map(brand => {
                  return (
                    <Form.Check
                      key={brand.id}
                      className="pl-4"
                      inline
                      label={brand.name}
                      type="checkbox"
                      checked={brand.checked}
                      onChange={(e) => this.onProductBrandChange(brand.id)}
                    />
                  )
                })
              }
              </div>
            </Row>
            <hr/>
            <Row>
              <Col>
                <Row>
                  <div className="pl-2">
                    <Button
                      variant="success"
                      onClick={this.addEvent}
                    >
                      Add New Event
                    </Button>
                  </div>
                </Row>
                <Row>
                  <div
                    className="pre-scrollable pt-2"
                    style={{
                      overflow: 'auto',
                      maxHeight: 'calc(100vh - 270px)',
                      width: '100%'
                    }}
                  >
                    <VerticalTimeline
                      className="pl-2 border-light rounded"
                      animate={true}
                      layout='1-column'
                    >
                    { this.state.eventsLoading &&
                      <div className="m-4 text-center">
                        <ClipLoader size={100}/>
                    </div>
                    }
                    { !this.state.eventsLoading &&
                      this.state.newEvents.map(newEvent => {
                        return (
                          <div key={`nsqf${newEvent.id}`} className="pb-2 pr-2" >
                            <VerticalTimelineElement
                              contentStyle={{
                                borderRight: '2px solid  #ADD8E6',
                                borderLeft: '2px solid  #ADD8E6',
                                borderBottom: '2px solid  #ADD8E6',
                                borderTop: '2px solid  #ADD8E6'
                              }}
                              contentArrowStyle={{ borderRight: '8px solid  #ADD8E6' }}
                              position="right"
                              className="vertical-timeline-element--education"
                              iconStyle={{ background: '#ADD8E6' }}
                            >
                              {newEvent.form}
                            </VerticalTimelineElement>
                          </div>
                        )
                      })
                    }
                    { !this.state.eventsLoading &&
                      this.state.selectedEvents.map(event => {
                        if ((this.state.showPublicEvents==false && event.is_public==0)
                          || this.state.showPublicEvents==true){
                          if (event.customer_id==this.props.customerId) {
                            return (
                              <div key={event.id} className="pb-2 pr-2" >
                                { this.state.enabledEditEventIds.includes(event.id) &&
                                  <VerticalTimelineElement
                                    contentStyle={{
                                      borderRight: '2px solid  #ADD8E6',
                                      borderLeft: '2px solid  #ADD8E6',
                                      borderBottom: '2px solid  #ADD8E6',
                                      borderTop: '2px solid  #ADD8E6'
                                    }}
                                    contentArrowStyle={{ borderRight: '8px solid  #ADD8E6' }}
                                    position="right"
                                    className="vertical-timeline-element--education"
                                    iconStyle={{ background: '#ADD8E6' }}
                                  >
                                    <EditEventForm
                                      event={event}
                                      handleClose={this.disbleEditEvent}
                                      refreshEvents={this.fetchEvents}
                                      new={false}
                                      categoryEvent={true}
                                      productBrands={this.props.category.product_brands}
                                    />
                                  </VerticalTimelineElement>
                                }
                                { !this.state.enabledEditEventIds.includes(event.id) &&
                                  <VerticalTimelineElement
                                    contentStyle={{
                                      borderRight: '2px solid  #ADD8E6',
                                      borderLeft: '2px solid  #ADD8E6',
                                      borderBottom: '2px solid  #ADD8E6',
                                      borderTop: '2px solid  #ADD8E6'
                                    }}
                                    contentArrowStyle={{ borderRight: '8px solid  #ADD8E6' }}
                                    position="right"
                                    className="vertical-timeline-element--education"
                                    date={moment.utc(moment(event.event_created_on)._d).local().format('MM/DD/YYYY')}
                                    dateClassName="pl-2 pb-1 pt-1"
                                    iconStyle={{ background: '#ADD8E6' }}
                                    textClassName="p-1"
                                  >
                                    <Event
                                      event={event}
                                      favIcon={this.getFavIcon(event.product_brand_id)}
                                      customerId={this.props.customerId}
                                      enableEditEvent={this.enableEditEvent}
                                      deleteEvent={this.deleteEvent}
                                    />
                                  </VerticalTimelineElement>
                                }
                              </div>
                            )
                          }
                        }
                        if (this.state.showPublicEvents==true) {
                          if (event.customer_id!=this.props.customerId) {
                            return (
                              <div key={event.id} className="pb-2">
                                <VerticalTimelineElement
                                  contentStyle={{
                                    borderRight: '2px solid  #98FB98',
                                    borderLeft: '2px solid  #98FB98',
                                    borderBottom: '2px solid  #98FB98',
                                    borderTop: '2px solid  #98FB98'
                                  }}
                                  contentArrowStyle={{ borderRight: '8px solid  #98FB98' }}
                                  position="right"
                                  className="vertical-timeline-element--education"
                                  date={moment.utc(moment(event.event_created_on)._d).local().format('MM/DD/YYYY')}
                                  dateClassName="pl-2 pb-1 pt-1"
                                  iconStyle={{ background: '#98FB98', color: '#fff' }}
                                  textClassName="p-1"
                                >
                                  <Event
                                    event={event}
                                    favIcon={this.getFavIcon(event.product_brand_id)}
                                    customerId={this.props.customerId}
                                    enableEditEvent={this.enableEditEvent}
                                    deleteEvent={this.deleteEvent}
                                  />
                                </VerticalTimelineElement>
                              </div>
                            )
                          }
                        }
                      })
                    }
                    </VerticalTimeline>
                  </div>
                </Row>
              </Col>
              <Col>

              </Col>
            </Row>
          </div>
        }
      </div>
    );
  }
};
