import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ReactTable from 'react-table';
import ClipLoader from 'react-spinners/ClipLoader';
import MetricTooltipContainer from '../../containers/metrics/MetricTooltipContainer';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import MetricDistributionSummaryModal from './MetricDistributionSummaryModal';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class BrandMetricsByMonthTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      metricWeightsAndRangesMap: {},
      metricWeightsAndRangesMapLoading: false,
      metricsDataByMonthWithGoals: {},
      metricsByMonthWithGoals: [],
      metricsByMonthWithGoalsLoading: false,
      metricDistributionModalOpen: false,
      selectedMetricName: undefined,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchBrandMetricWeightAndRanges();
    this.fetchBrandMetricsByMonthWithGoals();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.audienceProfileId !== this.props.audienceProfileId) {
      this.fetchBrandMetricWeightAndRanges();
    }
    if (
      prevProps.category !== this.props.category ||
      prevProps.brand !== this.props.brand
    ) {
      this.setState(() => ({
        metricsDataByMonthWithGoals: {},
        metricsByMonthWithGoals: [],
        metricsByMonthWithGoalsLoading: false,
        metricDistributionModalOpen: false,
        selectedMetricName: undefined,
      }), () => this.fetchBrandMetricsByMonthWithGoals());
    }
    if (prevProps.selectedMonth !== this.props.selectedMonth) {
      this.fetchBrandMetricsByMonthWithGoals();
    }
  };

  selectMonth = (selectedMonth) => {
    if (selectedMonth !== this.props.selectedMonth) {
      this.props.updateSelectedMonth(selectedMonth);
    }
  };

  fetchBrandMetricWeightAndRanges = () => {
    if (this.props.audienceProfileId) {
      this.setState(() => ({ metricWeightsAndRangesMapLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/brand-metric-weights-and-ranges?audience_profile_id=${this.props.audienceProfileId}`,
        HEADERS
      ).then(response => {
        const metricWeightsAndRanges = response.data;
        const metricWeightsAndRangesMap = {};
        for (const mwr of metricWeightsAndRanges) {
          metricWeightsAndRangesMap[mwr.brand_metric_id] = mwr;
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            metricWeightsAndRangesMap,
            metricWeightsAndRangesMapLoading: false
          }), () => this.setMetricsByMonthWithGoals());
        }
      }).catch(error => {
        console.error(`Error: unable to fetch metric weights and ranges...`);
        if (this.state.isMounted) {
          this.setState(() => ({
            metricWeightsAndRangesMap: {},
            metricWeightsAndRangesMapLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  fetchBrandMetricsByMonthWithGoals = () => {
    if (this.props.type && this.props.category && this.props.brand && this.props.selectedMonth) {
      if (this.state.metricsDataByMonthWithGoals[this.props.selectedMonth.label]) {
        this.setMetricsByMonthWithGoals();
      } else {
        this.setState(() => ({ metricsByMonthWithGoalsLoading: true }));
        const date = moment(this.props.selectedMonth.date);
        const formattedStartDate = moment(date).startOf('month').format('YYYY-MM-DD HH:mm:ss');
        const formattedEndDate = moment(date).endOf('month').format('YYYY-MM-DD HH:mm:ss');
        axios.get(
          `${LISTEN_ENDPOINT}/api/metrics/channel/brand-scores/range?logged_customer_id=${this.props.user.customerId}&company_id=${this.props.brand.company_id}&product_brand_id=${this.props.brand.id}&category_id=${this.props.category.id}&category_type=${this.props.category.category_type}&channel_category_name=${this.props.type}&start_date=${formattedStartDate}&end_date=${formattedEndDate}&show_brand_goals=true`,
          HEADERS
        ).then(response => {
          let brandMetricWithGoals = response.data;
          // remove brand momentum metrics
          brandMetricWithGoals = brandMetricWithGoals.filter(bm => bm.stage_name !== 'Brand Momentum')
          if (this.state.isMounted) {
            this.setState((prevState) => ({
              metricsDataByMonthWithGoals: {
                ...prevState.metricsDataByMonthWithGoals,
                [date.format('MMMM')]: brandMetricWithGoals
              },
              metricsByMonthWithGoalsLoading: false
            }), () => this.setMetricsByMonthWithGoals());
          }
        }).catch(error => {
          console.error(`Error: unable to fetch monthly brand metrics with goals for ${date.format('MMMM')}...`);
          if (this.state.isMounted) {
            this.setState(() => ({
              metricsByMonthWithGoalsLoading: false
            }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  getGoalPerformanceJSX = (value, customGoal, defaultGoal, scoreType) => {
    let goalLabel;
    let goalPercent;
    let goalValue;

    if (customGoal != null) {
      goalLabel = 'Custom Goal: ';
      goalValue = customGoal;
    } else if (defaultGoal) {
      goalLabel = 'Default Goal: ';
      goalValue = defaultGoal;
    }

    if (goalLabel) {
      if (value === null) {
        goalPercent = 0;
      } else {
        if (scoreType === 'high_is_good') {
          goalPercent = Math.round(((value / goalValue) * 100) * 10) / 10;
        } else {
          if (value <= goalValue) {
            goalPercent = 100;
          } else {
            goalPercent = Math.round(((goalValue / value) * 100) * 10) / 10;
          }
        }
      }

      // if below 0, just set to 0 - fix for momentum metrics
      if (goalPercent < 0) {
        goalPercent = 0;
      }
      const today = moment();
      const endOfMonth = moment(this.props.selectedMonth.date).endOf('month');
      const dateDiff = moment.duration(endOfMonth.diff(today));
      let daysLeft = 0;
      if (dateDiff._data.days > 0) {
        daysLeft = dateDiff._data.days;
      }

      return (
        <span className="align-middle">
          <div style={{ fontSize: '.75rem' }}>
            {`${goalLabel}${numberWithCommas(goalValue)}`}
          </div>
          <div>
            <ProgressBar
              variant="warning"
              now={goalPercent}
              style={{
                height: '10px',
                backgroundColor: '#d3d9df'
              }}
            />
          </div>
          <div style={{ fontSize: '.75rem' }}>
            {`${goalPercent}% complete`}
            <span className="float-right">
              {`${daysLeft} days left`}
            </span>
          </div>
        </span>
      );
    } else {
      return '';
    }
  };

  setMetricsByMonthWithGoals = () => {
    if (this.props.selectedMonth) {
      const brandMetrics = this.state.metricsDataByMonthWithGoals[this.props.selectedMonth.label] || [];
      const metricsByMonthWithGoals = [];
      for (const metric of brandMetrics) {
        if (
          this.props.type === metric.channel_name &&
          (
            !this.props.siteName ||
            this.props.siteName === metric.site_name
          ) &&
          (
            !this.props.metricName ||
            metric.metric_name.includes(this.props.metricName)
          )
        ) {
          if (
            !this.props.excludeMetrics ||
            !this.props.excludeMetrics.includes(metric.metric_name)
          ) {
            // add default goal
            if (this.state.metricWeightsAndRangesMap[metric.brand_metric_id]) {
              if (metric.score_type === 'high_is_good') {
                metric.default_goal = this.state.metricWeightsAndRangesMap[metric.brand_metric_id].low_value;
              } else if (metric.score_type === 'low_is_good') {
                metric.default_goal = this.state.metricWeightsAndRangesMap[metric.brand_metric_id].high_value;
              }
            }
            metricsByMonthWithGoals.push(metric);
          }
        }
      }
      metricsByMonthWithGoals.sort((a, b) => {
        // sort by stage name
        if (a.stage_name.toLowerCase() < b.stage_name.toLowerCase()) return -1;
        if (a.stage_name.toLowerCase() > b.stage_name.toLowerCase()) return 1;
        // sort by metric label
        if (a.metric_label.toLowerCase() < b.metric_label.toLowerCase()) return -1;
        if (a.metric_label.toLowerCase() > b.metric_label.toLowerCase()) return 1;
        return 0
      });
      this.setState(() => ({ metricsByMonthWithGoals }));
    }
  };

  openMetricDistributionModal = (selectedMetricName) => {
    this.setState(() => ({
      selectedMetricName,
      metricDistributionModalOpen: true
    }));
  };

  closeMetricDistributionModal = () => {
    this.setState(() => ({
      metricDistributionModalOpen: false
    }));
  };

  render () {
    return (
      <div>
        <h5 className="my-2 p-2 bg-bops-blue text-light">Metrics & Goals</h5>
        <Row id="brandMetricsGoals">
          <Col style={{ flex: '0 0 100px' }}>
            { this.props.sixMonths.map((month, i) => {
                return (
                  <button
                    key={`bm-m${i}`}
                    type="button"
                    className= {
                      (this.props.selectedMonth && this.props.selectedMonth.label === month.label) ?
                        'mb-1 btn btn-primary w-100' :
                        'mb-1 btn border bg-white w-100'
                    }
                    onClick={() => this.selectMonth(month)}
                  >
                    {month.label}
                  </button>
                )
              })
            }
          </Col>
          <Col>
            { this.props.selectedMonth &&
              <div>
                { this.state.metricsByMonthWithGoalsLoading &&
                  <div className="m-4 text-center">
                    <ClipLoader size={100}/>
                  </div>
                }
                { !this.state.metricsByMonthWithGoalsLoading &&
                  <div>
                    <ReactTable
                      className="-striped -highlight"
                      data={this.state.metricsByMonthWithGoals}
                      columns={[
                        {
                          Header: 'Brand Metric',
                          accessor: 'metric_name',
                          sortable: true,
                          className: 'd-flex',
                          Cell: row => (
                            <MetricTooltipContainer
                              tooltipTrigger={
                                <span className="align-self-center">
                                  {row.original.metric_label}
                                </span>
                              }
                              metricName={row.original.metric_name}
                              openMetricDistributionModal={this.openMetricDistributionModal}
                            />
                          )
                        }, {
                          Header: 'Pillar',
                          accessor: 'stage_name',
                          sortable: true,
                          className: 'd-flex text-center',
                          Cell: row => (
                            <div className="w-100 align-self-center">
                              {row.original.stage_name}
                            </div>
                          )
                        }, {
                          Header: 'Result',
                          accessor: 'metric_score',
                          className: 'd-flex text-center',
                          Cell: row => (
                            <div className="w-100 align-self-center">
                              { (
                                  row.original.metric_score !== null &&
                                  row.original.metric_label.includes(' Cost')
                                ) &&
                                <span>{'$'}</span>
                              }
                              {numberWithCommas(row.original.metric_score)}
                              { (
                                  row.original.metric_score !== null &&
                                  row.original.is_percent == true
                                ) &&
                                <span>{'%'}</span>
                              }
                            </div>
                          )
                        }, {
                          Header: 'Performance',
                          accessor: 'goal_value',
                          className: 'd-flex',
                          Cell: row => (
                            <div className="w-100 align-self-center">
                              {this.getGoalPerformanceJSX(row.original.metric_score, row.original.goal_value, row.original.default_goal, row.original.score_type)}
                            </div>
                          )
                        }
                      ]}
                      minRows = {1}
                      defaultPageSize={100}
                      showPagination={false}
                    />
                    <SharePopUpContainer
                      shareElementId="brandMetricsGoals"
                      elementLabel="brand-metrics-goals-img"
                      position="bottom"
                      contextType="brand"
                      contextCategory={this.props.category}
                      contextBrand={this.props.brand}
                      contextChannel={this.props.type}
                      contextProperty="Summary"
                      contextChartName="Summary"
                      contextDateRange={`${this.props.selectedMonth.label} ${this.props.selectedMonth.date.format('YYYY')}`}
                    />
                  </div>
                }
              </div>
            }
          </Col>
        </Row>

        <MetricDistributionSummaryModal
          isOpen={this.state.metricDistributionModalOpen}
          handleClose={this.closeMetricDistributionModal}
          metricName={this.state.selectedMetricName}
          audienceProfileId={this.props.audienceProfileId}
          customerId={this.props.user.customerId}
        />
      </div>
    );
  }
};
