import React from 'react';
import StickyTooltip from './StickyTooltip';

const Tooltip = (props) => {
  return (
    <StickyTooltip
      position={props.tooltipPosition || 'right'}
      overlay={
        <div>
          {props.tooltip}
        </div>
      }
    >
      {props.tooltipTrigger}
    </StickyTooltip>
  );
};

export default Tooltip;
