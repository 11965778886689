import React from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import LoginContainer from '../../../containers/common/LoginContainer';
import AdminAuthenticationMessage from '../../common/AdminAuthenticationMessage';
import { isAuthenticatedAdmin } from '../../../utils/auth';
import SSOSamlSettings from '../../sso/SSOSamlSettings';
import DeleteConfirmationModal from '../../common/DeleteConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';
import history from '../../../routers/history';

export default class SingleSignOnSetup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      samlIdentityProviderId: undefined,
      entityId: '',
      ssoUrl: '',
      sloUrl: '',
      certificate: '',
      errors: {},
      deleteConfirmationModalOpen: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchSignOnSetup();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.customerId !== this.props.user.customerId) {
      this.fetchSignOnSetup();
    }
  }

  fetchSignOnSetup = () => {
    if (this.props.user.customerId) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/saml-identity-providers?company_id=${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        let samlIdentityProviderId;
        let entityId = '';
        let ssoUrl = '';
        let sloUrl = '';
        let certificate = '';
        if (response.data.length > 0) {
          const samlIdentityProvider = response.data[0];
          samlIdentityProviderId = samlIdentityProvider.id;
          entityId = samlIdentityProvider.entity_id || '';
          ssoUrl = samlIdentityProvider.sso_url || '';
          sloUrl = samlIdentityProvider.slo_url || '';
          certificate = samlIdentityProvider.certificate || '';
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            samlIdentityProviderId,
            entityId,
            ssoUrl,
            sloUrl,
            certificate,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch sso setup');
        if (this.state.isMounted) {
          this.setState(() => ({
            samlIdentityProvider: undefined,
            entityId: '',
            ssoUrl: '',
            sloUrl: '',
            certificate: '',
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onEntityIdChange = (event) => {
    const entityId = event.currentTarget.value;
    this.setState(() => ({ entityId }));
  };

  onSsoUrlChange = (event) => {
    const ssoUrl = event.currentTarget.value;
    this.setState(() => ({ ssoUrl }));
  };

  onSloUrlChange = (event) => {
    const sloUrl = event.currentTarget.value;
    this.setState(() => ({ sloUrl }));
  };

  onCertificateChange = (event) => {
    const certificate = event.currentTarget.value;
    this.setState(() => ({ certificate }));
  };

  saveSsoSetup = () => {
    const samlIdentityProvider = {
      company_id: this.props.user.customerId,
      provider_name: 'SSO',
      entity_id: this.state.entityId,
      sso_url: this.state.ssoUrl,
      slo_url: this.state.sloUrl || null,
      certificate: this.state.certificate
    };

    if (this.state.samlIdentityProviderId) {
      // Update
      axios.put(
        `${LISTEN_ENDPOINT}/api/saml-identity-providers/${this.state.samlIdentityProviderId}`,
        samlIdentityProvider,
        HEADERS
      ).then(response => {
        if (this.state.isMounted) {
          this.fetchSignOnSetup();
        }
      }).catch(error => {
        console.error('Error: failed to update sso setup');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    } else {
      // Create
      axios.post(
        `${LISTEN_ENDPOINT}/api/saml-identity-providers`,
        samlIdentityProvider,
        HEADERS
      ).then(response => {
        if (this.state.isMounted) {
          this.fetchSignOnSetup();
        }
      }).catch(error => {
        console.error('Error: failed to create sso setup');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  openDeleteConfirmationModal = () => {
    this.setState(() => ({ deleteConfirmationModalOpen: true }));
  };

  closeDeleteConfirmationModal = () => {
    this.setState(() => ({ deleteConfirmationModalOpen: false }));
  };

  deleteSsoSetup = () => {
    if (this.state.samlIdentityProviderId) {
      axios.delete(
        `${LISTEN_ENDPOINT}/api/saml-identity-providers/${this.state.samlIdentityProviderId}`,
        HEADERS
      ).then(response => {
        if (this.state.isMounted) {
          this.fetchSignOnSetup();
          this.closeDeleteConfirmationModal();
        }
      }).catch(error => {
        console.error('Error: failed to delete sso setup');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="m-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        <AdminAuthenticationMessage user={this.props.user}/>
        { isAuthenticatedAdmin(this.props.user) &&
          <div>
            <div
              className="border rounded p-4"
              style={{ backgroundColor: '#e9ecef' }}
            >
              <div className="d-inline-block pr-2">
                <FontAwesomeIcon icon={faKey} size="2x" color="#454d54" />
              </div>
              <div className="d-inline-block">
                <h4>Single Sign-On</h4>
              </div>
              <p>Connect your enterprise Single Sign-On provider.</p>
              <hr className="my-4" />
              <p className="mb-0">
                Increase security and usability of BrandOps by integrating with your single sign-on provider. Companies that have SSO implemented can use their existing implementation to provide multi-factor authentication.
              </p>
            </div>
            <div className="mt-4">
              <Row>
                <Col>
                  <div
                    className="pre-scrollable"
                    style={{
                      maxHeight: 'calc(100vh - 325px)',
                      overflow: 'auto',
                      overflowX: 'hidden'
                    }}
                  >
                    <Form>
                      <Form.Group>
                        <Form.Label>Audience URI (SP Entity ID)</Form.Label>
                        <Form.Control
                          type="text"
                          value={this.state.entityId}
                          onChange={this.onEntityIdChange}
                          isInvalid={this.state.errors.entityId}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Single Sign-On URL</Form.Label>
                        <Form.Control
                          type="text"
                          value={this.state.ssoUrl}
                          onChange={this.onSsoUrlChange}
                          isInvalid={this.state.errors.ssoUrl}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Single Logout URL - Optional</Form.Label>
                        <Form.Control
                          type="text"
                          value={this.state.sloUrl}
                          onChange={this.onSloUrlChange}
                          isInvalid={this.state.errors.sloUrl}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Certificate</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="4"
                          value={this.state.certificate}
                          onChange={this.onCertificateChange}
                          isInvalid={this.state.errors.certificate}
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        onClick={this.saveSsoSetup}
                        disabled={!this.props.user.customerId}
                      >
                        Save
                      </Button>
                      { this.state.samlIdentityProviderId &&
                        <Button
                          className="ml-4"
                          variant="danger"
                          onClick={this.openDeleteConfirmationModal}
                        >
                          Delete
                        </Button>
                      }
                    </Form>
                  </div>
                </Col>
                <Col>
                  <SSOSamlSettings
                    maxHeight="calc(100vh - 425px)"
                  />
                </Col>
              </Row>
            </div>

            <DeleteConfirmationModal
              isOpen={this.state.deleteConfirmationModalOpen}
              handleClose={this.closeDeleteConfirmationModal}
              deleteMessage="Are you sure you would like to delete the current SSO configuration?"
              deleteResource={this.deleteSsoSetup}
            />
          </div>
        }
      </div>
    );
  }
};
