export const setAllMonthsChecked = (allMonthsChecked) => {
  return (dispatch) => {
    dispatch(updateAllMonthsChecked(allMonthsChecked));
  };
};

export const updateAllMonthsChecked = (allMonthsChecked) => ({
  type: 'UPDATE_ALL_MONTHS_CHECKED',
  allMonthsChecked
});
