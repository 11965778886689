import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';

export default class CategoriesMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '0'
    };
  }

  componentDidMount() {

  };

  toggleAccordion = () => {
    this.setState(prevState => {
      if (prevState.activeKey === '0') {
        return { activeKey: '1' }
      } else {
        return { activeKey: '0' }
      }
    })
  };

  openCategory = () => {
    if (this.state.activeKey === '1') {
      this.setState(() => ({ activeKey: '0' }));
    }
  };

  render () {
    return (
      <div className="mb-2">
        { this.props.category &&
          <Accordion activeKey={this.state.activeKey}>
            <div
              className={
                (this.props.selectedCategory === this.props.category) ?
                  'bg-primary text-light rounded' : ''
              }
            >
              <Accordion.Toggle as="span" eventKey="0" onClick={this.toggleAccordion}>
                <FontAwesomeIcon
                  className="mx-2"
                  icon={this.state.activeKey == '0' ? faCaretDown : faCaretRight}
                  style={{ cursor: 'pointer' }}
                />
              </Accordion.Toggle>
              <span
                className={
                  (
                    this.props.selectedCategory !== this.props.category &&
                    this.props.category.category_type === 'private'
                  ) ? 'text-success' : ''
                }
                onClick={() => this.props.onCategorySelect(this.props.category)}
                style={{ cursor: 'pointer' }}
              >
                {this.props.category.name}
              </span>
            </div>
            <Accordion.Collapse eventKey="0">
              <div>
                { this.props.category.product_brands.map((pb, i) => {
                    return (
                      <div
                        key={`cm-c-${this.props.category.id}-b-${pb.id}`}
                        className={`pl-3${(this.props.selectedBrand && this.props.selectedBrand.id === pb.id) ? ' bg-primary text-light rounded' : ''}`}
                        onClick={() => this.props.onBrandSelect(pb, this.props.category)}
                        style={{ cursor: 'pointer' }}
                      >
                        {pb.name}
                      </div>
                    )
                  })
                }
              </div>
            </Accordion.Collapse>
          </Accordion>
        }
      </div>
    );
  }
};
