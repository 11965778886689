import React from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import RangeSlider from 'react-bootstrap-range-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CustomReportModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      description: '',
      createdBy: '',
      voicePlaybackRate: 1.3,
      noVoiceSlideDelaySeconds: 3,
      voiceOnSlideDelaySeconds: .1,
      errors: {},
      errorMessage: '',
      saveLoading: false
    };
  }

  onOpen = () => {
    if (this.props.customReport) {
      this.setState(() => ({
        name: this.props.customReport.name,
        description: this.props.customReport.description || '',
        createdBy: this.props.customReport.created_by || '',
        voicePlaybackRate: this.props.customReport.voice_playback_rate,
        noVoiceSlideDelaySeconds: this.props.customReport.no_voice_slide_delay_seconds,
        voiceOnSlideDelaySeconds: this.props.customReport.voice_on_slide_delay_seconds,
        errors: {},
        errorMessage: '',
        saveLoading: false
      }));
    } else {
      // reset defaults
      this.setState(() => ({
        name: '',
        description: '',
        createdBy: '',
        voicePlaybackRate: 1,
        noVoiceSlideDelaySeconds: 3,
        voiceOnSlideDelaySeconds: .5,
        errors: {},
        errorMessage: '',
        saveLoading: false
      }));
    }
  };

  onNameChange = (event) => {
    const name = event.currentTarget.value;
    this.setState(() => ({ name }));
  };

  onDescriptionChange = (event) => {
    const description = event.currentTarget.value;
    this.setState(() => ({ description }));
  };

  onCreatedByChange = (event) => {
    const createdBy = event.currentTarget.value;
    this.setState(() => ({ createdBy }));
  };

  onVoicePlaybackRateChange = (event) => {
    const voicePlaybackRate = event.currentTarget.value;
    this.setState(() => ({ voicePlaybackRate }));
  };

  onNoVoiceSlideDelaySecondsChange = (event) => {
    const noVoiceSlideDelaySeconds = event.currentTarget.value;
    this.setState(() => ({ noVoiceSlideDelaySeconds }));
  };

  onVoiceOnSlideDelaySecondsChange = (event) => {
    const voiceOnSlideDelaySeconds = event.currentTarget.value;
    this.setState(() => ({ voiceOnSlideDelaySeconds }));
  };

  validForm = () => {
    const errors = {};
    if (!this.state.name) {
      errors.name = true;
    }
    if (!this.state.noVoiceSlideDelaySeconds) {
      errors.noVoiceSlideDelaySeconds = true;
    }

    this.setState(() => ({ errors }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  updateCustomReport = () => {
    if (this.validForm()) {
      this.setState(() => ({ errorMessage: '', saveLoading: true }));
      const customReport = {
        name: this.state.name,
        description: this.state.description,
        created_by: this.state.createdBy,
        voice_playback_rate: this.state.voicePlaybackRate,
        no_voice_slide_delay_seconds: this.state.noVoiceSlideDelaySeconds,
        voice_on_slide_delay_seconds: this.state.voiceOnSlideDelaySeconds,
      };
      // update
      axios.put(
        `${LISTEN_ENDPOINT}/api/custom-reports/${this.props.customReport.id}`,
        customReport,
        HEADERS
      ).then(response => {
        this.props.refreshReports();
        this.props.handleClose();
      }).catch(error => {
        console.error('Error: failed to update report');
        this.setState(() => ({
          errorMessage: 'Failed to update report',
          saveLoading: false,
        }))
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <Modal
        size="lg"
        show={this.props.isOpen}
        onHide={this.props.handleClose}
        onEntering={this.onOpen}
      >
        <Modal.Header
          className="bg-bops-blue text-light"
          closeButton
        >
          <Modal.Title>
            <FontAwesomeIcon
              className="mr-2"
              icon={faGear}
            />
            Update Report Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={this.state.name}
                    onChange={this.onNameChange}
                    isInvalid={this.state.errors.name}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Created By</Form.Label>
                  <Form.Control
                    type="text"
                    value={this.state.createdBy}
                    onChange={this.onCreatedByChange}
                    isInvalid={this.state.errors.createdBy}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                value={this.state.description}
                onChange={this.onDescriptionChange}
                isInvalid={this.state.errors.description}
              />
            </Form.Group>
            <Form.Row>
              <Col xs={3}>
                <Form.Group>
                  <Form.Label>Voice Playback Rate</Form.Label>
                  <div>
                    <RangeSlider
                      value={this.state.voicePlaybackRate}
                      onChange={this.onVoicePlaybackRateChange}
                      min={.5}
                      max={2}
                      step={.1}
                      size='lg'
                      tooltip="auto"
                      tooltipPlacement="bottom"
                      tooltipLabel={(value) => {
                        if (value === 1) {
                          return 'Normal';
                        } else {
                          return `${value}x`;
                        }
                      }}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    Slide delay without text
                    <div>(in seconds)</div>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={this.state.noVoiceSlideDelaySeconds}
                    onChange={this.onNoVoiceSlideDelaySecondsChange}
                    isInvalid={this.state.errors.noVoiceSlideDelaySeconds}
                    size="sm"
                    style={{ width: '60px' }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    Slide delay with text
                    <div>(in seconds)</div>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={this.state.voiceOnSlideDelaySeconds}
                    onChange={this.onVoiceOnSlideDelaySecondsChange}
                    isInvalid={this.state.errors.voiceOnSlideDelaySeconds}
                    size="sm"
                    style={{ width: '60px' }}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
          <Alert show={Boolean(this.state.errorMessage)} variant="danger">
            {this.state.errorMessage}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.updateCustomReport} disabled={this.state.saveLoading}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};
