import React from 'react';
import axios from 'axios';
import queryString from 'query-string';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import history from '../../routers/history';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';

export default class SSOLoginHandler extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: ''
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user && nextProps.user.email) {
      history.push('/');
    }
    return prevState;
  }

  componentDidMount() {
    this.ssoLoginHandler();
  };

  ssoLoginHandler = () => {
    const queryValues = queryString.parse(this.props.location.search);
    const token = queryValues.token;
    if (token) {
      this.props.handleSSOLogin(token);
    } else {
      this.setState(() => ({ errorMessage: 'Invalid SSO Token' }));
    }
  };

  render () {
    return (
      <div className="p-4">
        { (!this.state.errorMessage && !this.props.user.error) &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        <Alert show={Boolean(this.state.errorMessage)} variant="danger">
          {this.state.errorMessage}
        </Alert>
        <Alert show={Boolean(this.props.user.error)} variant="danger">
          {this.props.user.error}
        </Alert>
      </div>
    );
  }
};
