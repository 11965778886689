import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import RangeSlider from 'react-bootstrap-range-slider';
import Button from 'react-bootstrap/Button';
import { SingleDatePicker } from 'react-dates';
import { createUserActivityLog } from '../../utils/users';

export default class CategoryRaceChartSetupModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scoreType: 'Overall',
      dateType: 'daily',
      dailyDaysCount: '30',
      weeklyWeeksCount: '',
      monthlyMonthsCount: '',
      endDate: moment(),
      secondsToDelay: 0,
      productBrands: [],
      speed: 5,
      minSpeed: 1,
      maxSpeed: 10,
      showIcons: true,
      showBrandNames: true,
      showBrandScores: true,
      errors: {},
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  onOpen = () => {
    if (this.props.category && this.props.category.product_brands) {
      let productBrands = this.props.category.product_brands;
      for (const brand of productBrands) {
        if (!brand.checked)
          brand.checked=true;
      }
      this.setState(() => ({ productBrands }));
    }
  }

  switchScoreType = (brandScoreType) => {
    const scoreType = brandScoreType;
    this.setState(() => ({ scoreType }));
  }

  switchDateType = (dateType) => {
    this.setState(() => ({ dateType }));
  }

  onDailyDaysCountChange = (event) => {
    const dailyDaysCount = event.currentTarget.value;
    this.setState(() => ({ dailyDaysCount }));
  }

  onWeeklyWeeksCountChange = (event) => {
    const weeklyWeeksCount = event.currentTarget.value;
    this.setState(() => ({ weeklyWeeksCount }));
  }

  onMonthlyMonthsCountChange = (event) => {
    const monthlyMonthsCount = event.currentTarget.value;
    this.setState(() => ({ monthlyMonthsCount }));
  }

  onStartDateChange = (event) => {
    const startDate = event.currentTarget.value;
    this.setState(() => ({ startDate }));
  }

  onEndDateChange = (event) => {
    const endDate = event.currentTarget.value;
    this.setState(() => ({ endDate }));
  }

  onFilterDatesChange = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  };

  onDelayChange = (event) => {
    const secondsToDelay = event.currentTarget.value;
    this.setState(() => ({ secondsToDelay }));
  }

  selectProductBrands = (brandId) => {
    let productBrands = this.state.productBrands;
    for (const brand of productBrands) {
      if (brand.id == brandId)
        brand.checked = brand.checked==true ? false : true;
    }
    this.setState(() => ({ productBrands }));
  }

  changeShowIcons = () => {
    let showIcons = this.state.showIcons==true ? false : true;
    this.setState(() => ({ showIcons }));
  }

  changeShowBrandNames = () => {
    let showBrandNames = this.state.showBrandNames==true ? false : true;
    this.setState(() => ({ showBrandNames }));
  }

  setSpeed = (event) => {
    let speed = event.target.value;
    this.setState(() => ({ speed }));
  }

  switchShowBrandScores = () => {
    let showBrandScores = this.state.showBrandScores==true ? false : true;
    this.setState(() => ({ showBrandScores }));
  }

  isValidForm = () => {
    if (
      this.state.dateType === 'daily' &&
      (
        this.state.dailyDaysCount.trim() === '' ||
        isNaN(Number(this.state.dailyDaysCount))
      )
    ) {
      this.setState(() => ({ errors: { dailyDaysCount: true } }));
      return false;
    } else if (
      this.state.dateType === 'weekly' &&
      (
        this.state.weeklyWeeksCount.trim() === '' ||
        isNaN(Number(this.state.weeklyWeeksCount))
      )
    ) {
      this.setState(() => ({ errors: { weeklyWeeksCount: true } }));
      return false;
    } else if (
      this.state.dateType === 'monthly' &&
      (
        this.state.monthlyMonthsCount.trim() === '' ||
        isNaN(Number(this.state.monthlyMonthsCount))
      )
    ) {
      this.setState(() => ({ errors: { monthlyMonthsCount: true } }));
      return false;
    }
    return true;
  };

  createChart = () => {
    if (this.isValidForm()) {
      const endDate = this.state.endDate;
      let startDate;
      if (this.state.dateType === 'daily') {
        startDate = moment(this.state.endDate).subtract(parseInt(this.state.dailyDaysCount), 'days').startOf('day');
      } else if (this.state.dateType === 'weekly') {
        startDate = moment(this.state.endDate).subtract(parseInt(this.state.weeklyWeeksCount), 'weeks').startOf('day');
      } else if (this.state.dateType === 'monthly') {
        startDate = moment(this.state.endDate).subtract(parseInt(this.state.monthlyMonthsCount), 'months').startOf('day');
      }
      const validDateType = this.state.dateType;
      let productBrands = [];
      for (const brand of this.state.productBrands) {
        if (brand.checked==true)
          productBrands.push(brand);
      }
      const chartType = 'barChartRace';
      const scoreType = this.state.scoreType;
      const secondsToDelay = this.state.secondsToDelay;
      const speed = this.state.maxSpeed - this.state.speed;
      const showIcons = this.state.showIcons;
      const showBrandNames = this.state.showBrandNames;
      const showBrandScores = this.state.showBrandScores;
      this.props.buildChart(startDate, endDate, validDateType, chartType, scoreType, secondsToDelay, speed, showIcons, showBrandNames, showBrandScores);
      this.props.handleClose();
      createUserActivityLog(
        this.props.user,
        'Executive Report: Race Chart',
        this.props.category
      );
    }
  }

  render () {
    return (
      <Modal
        size="lg"
        show={this.props.isOpen}
        onHide={this.props.handleClose}
        onEntering={this.onOpen}
      >
        <Modal.Header
          className="bg-bops-blue text-light"
          closeButton
        >
          <Modal.Title>
            Setup Race
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Row>
              <Col className="pl-4 border-right">
                <Form.Check
                  label="Overall Score"
                  type="radio"
                  checked={this.state.scoreType == "Overall" ? true : false}
                  onChange={() => this.switchScoreType("Overall")}
                />
                <Form.Check
                  label="Digital Presence"
                  type="radio"
                  checked={this.state.scoreType == "Presence" ? true : false}
                  onChange={() => this.switchScoreType("Presence")}
                />
                <Form.Check
                  label="Audience Attention"
                  type="radio"
                  checked={this.state.scoreType == "Attention" ? true : false}
                  onChange={() => this.switchScoreType("Attention")}
                />
                <Form.Check
                  label="Brand Reputation"
                  type="radio"
                  checked={this.state.scoreType == "Reputation" ? true : false}
                  onChange={() => this.switchScoreType("Reputation")}
                />
                <Form.Check
                  label="Momentum"
                  type="radio"
                  checked={this.state.scoreType == "Momentum" ? true : false}
                  onChange={() => this.switchScoreType("Momentum")}
                />
              </Col>
              <Col>
                <div className="mb-3 mx-4">
                  <Form.Check
                    className="d-inline-block"
                    label="Daily"
                    type="radio"
                    checked={this.state.dateType == 'daily' ? true : false}
                    onChange={() => this.switchDateType('daily')}
                  />
                  <Form.Control
                    className="float-right"
                    type="text"
                    placeholder="# of Days"
                    value={this.state.dailyDaysCount}
                    onChange={this.onDailyDaysCountChange}
                    onKeyDown={(e) => {
                      if (
                        !/\d/.test(e.key) &&
                        e.key !== 'Delete' &&
                        e.key !== 'Backspace' &&
                        e.key !== 'ArrowLeft' &&
                        e.key !== 'ArrowRight'
                      ) {
                        e.preventDefault();
                      }
                    }}
                    size="sm"
                    style={{ width: '100px' }}
                    isInvalid={this.state.errors.dailyDaysCount}
                    disabled={this.state.dateType !== 'daily'}
                  />
                </div>
                <div className="mb-3 mx-4">
                  <Form.Check
                    className="d-inline-block"
                    label="Weekly"
                    type="radio"
                    checked={this.state.dateType == 'weekly' ? true : false}
                    onChange={() => this.switchDateType('weekly')}
                  />
                  <Form.Control
                    className="float-right"
                    type="text"
                    placeholder="# of Weeks"
                    value={this.state.weeklyWeeksCount}
                    onChange={this.onWeeklyWeeksCountChange}
                    onKeyDown={(e) => {
                      if (
                        !/\d/.test(e.key) &&
                        e.key !== 'Delete' &&
                        e.key !== 'Backspace' &&
                        e.key !== 'ArrowLeft' &&
                        e.key !== 'ArrowRight'
                      ) {
                        e.preventDefault();
                      }
                    }}
                    size="sm"
                    style={{ width: '100px' }}
                    isInvalid={this.state.errors.weeklyWeeksCount}
                    disabled={this.state.dateType !== 'weekly'}
                  />
                </div>
                <div className="mb-3 mx-4">
                  <Form.Check
                    className="d-inline-block"
                    label="Monthly"
                    type="radio"
                    checked={this.state.dateType == 'monthly' ? true : false}
                    onChange={() => this.switchDateType('monthly')}
                  />
                  <Form.Control
                    className="float-right"
                    type="text"
                    placeholder="# of Months"
                    value={this.state.monthlyMonthsCount}
                    onChange={this.onMonthlyMonthsCountChange}
                    onKeyDown={(e) => {
                      if (
                        !/\d/.test(e.key) &&
                        e.key !== 'Delete' &&
                        e.key !== 'Backspace' &&
                        e.key !== 'ArrowLeft' &&
                        e.key !== 'ArrowRight'
                      ) {
                        e.preventDefault();
                      }
                    }}
                    size="sm"
                    style={{ width: '100px' }}
                    isInvalid={this.state.errors.monthlyMonthsCount}
                    disabled={this.state.dateType !== 'monthly'}
                  />
                </div>
              </Col>
              <Col>
                End Date
                <div className="d-inline-block ml-2">
                  <SingleDatePicker
                    id="endDatePicker"
                    date={this.state.endDate}
                    onDateChange={endDate => this.setState(({ endDate }))}
                    focused={this.state.dateFocused}
                    onFocusChange={({ focused }) => this.setState(() => ({ dateFocused: focused }))}
                    isOutsideRange={() => false}
                    small
                  />
                </div>
              </Col>
            </Form.Row>
          </Form.Group>
          <hr/>
          <Form.Group>
            <Form.Row>
              <div>
                <strong> Participating Brands </strong>
              </div>
            </Form.Row>
            <Form.Row className="pl-2 pr-2">
            { this.state.productBrands.length>0 &&
              this.state.productBrands.map(brand => {
                return (
                  <Form.Check
                    key={brand.id}
                    className="m-2"
                    label={brand.name}
                    checked={brand.checked}
                    onChange={(e) => this.selectProductBrands(brand.id)}
                  />
                )
              })
            }
            </Form.Row>
          </Form.Group>
          <hr/>
          <Form.Group>
            <Form.Row>
              <div>
                <strong> Graph Details </strong>
              </div>
            </Form.Row>
            <Form.Row className="pl-2 pr-2">
              <Col xs={4} sm={3} className="px-2 mt-2">
                <Form.Check
                  label="Scores"
                  type="radio"
                  checked={this.state.showBrandScores}
                  onChange={this.switchShowBrandScores}
                />
                <Form.Check
                  label="Ranks"
                  type="radio"
                  checked={!this.state.showBrandScores}
                  onChange={this.switchShowBrandScores}
                />
              </Col>
              <Col xs={3} sm={2} className="px-2 mt-2">
                <Form.Check
                  label="Show Icons"
                  checked={this.state.showIcons}
                  onChange={this.changeShowIcons}
                />
              </Col>
              <Col xs={4} sm={3} className="px-2 mt-2">
                <Form.Check
                  label="Show Brand Names"
                  checked={this.state.showBrandNames}
                  onChange={this.changeShowBrandNames}
                />
              </Col>
              <Col xs={2} sm={1} className="px-2">
                <div className="mt-2 text-right">
                  Speed
                </div>
              </Col>
              <Col className="px-2 mt-2">
                <RangeSlider
                  value={this.state.speed}
                  onChange={this.setSpeed}
                  min={this.state.minSpeed} max={this.state.maxSpeed}
                  size='lg'
                  tooltip="auto"
                  tooltipPlacement="bottom"
                />
              </Col>
            </Form.Row>
          </Form.Group>
          <hr/>
          <Form.Group>
            <Form.Row>
              <div>
                <strong> Animation </strong>
              </div>
            </Form.Row>
            <Form.Row className="pl-2 pr-2">
              <Col xs={4} sm={3} className="px-2">
                <div className="mt-2 text-right">
                  Delay Start (Seconds)
                </div>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  style={{ maxWidth: "50px" }}
                  value={this.state.secondsToDelay}
                  onChange={this.onDelayChange}
                />
              </Col>
            </Form.Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.createChart}>
            Setup Race
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};
