import React from 'react';
import ReactDOM from 'react-dom';
import Button from 'react-bootstrap/Button';
import * as d3 from 'd3';
import { select } from "d3-selection";
import 'd3-transition';

export default class BarChartRace extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      setupComplete: false,
      introPicOn: false,
      closingPicOn: false
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.dates && this.props.brandScoresData) {
      this.createBarChart(this.props.secondsToDelay, this.props.speed, this.props.showIcons, this.props.showBrandNames, this.props.showBrandScores);
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.secondsToDelay !== this.props.secondsToDelay) || (prevProps.speed !== this.props.speed) ||
        (prevProps.category !== this.props.category) || (prevProps.showIcons !== this.props.showIcons) ||
        (prevProps.showBrandNames !== this.props.showBrandNames) ||
        (prevProps.showBrandScores !== this.props.showBrandScores) ) {
      this.createBarChart(this.props.openWindow, this.props.secondsToDelay, this.props.speed, this.props.showIcons, this.props.showBrandNames, this.props.showBrandScores);
    }
  };

  createBarChart = (secondsToDelay, speed, showIcons, showBrandNames, showBrandScores) => {
    this.setState(() => ({ closingPicOn: false }));
    this.props.changePlayAccessibility(false);
    let element = document.getElementById("raceBarChart");
    if (element) {
      element.style.display = "block";
    }
    const productBrands = this.props.category.product_brands;

    let msToDelay = secondsToDelay*1000;
    let data = this.props.brandScoresData;
    let dates = this.props.dates;
    let width = window.innerWidth*.8; // Make dynamic w/ viewBox & preserveAspectRatio
    let height = window.innerHeight*.65;
    let maxBars = productBrands.length;
    const margin = {
      top: 80,
      right: 100,
      bottom: 20,
      left: 150
    };
    let barPadding = (height-(margin.bottom+margin.top))/(maxBars*10);
    let svg = this.state.mySvg;

    if (this.state.setupComplete == false) {
      // First setup
      svg = d3.select('#raceBarChart')
        .append("svg")
        .attr("width", width)
        .attr("height", height);
        // .attr("preserveAspectRatio", "xMinYMin meet")
        // .attr("viewBox", "0 0 500 500");
    } else {
      svg.selectAll("*").remove();
    }

    let title = this.props.category.name;
    if (this.props.showBrandScores) {
      title += ' - Scores';
    } else {
      title += ' - Ranking';
    }
    if (this.props.scoreType !== "Overall") {
      title += `: ${this.props.scoreType}`;
    }
    // Bar graph title
    svg.append("text")
      .attr("x", 0)
      .attr("y", 35)
      .attr('font-size', "30px")
      .style("fill", "grey")
      .html(title);

    for (let thisData of data) {
      let nameArr = thisData.name.split(" ");
      thisData.nameSplit = [];
      let totalLen = 0;
      let nameLine = "";
      let recentName = "";
      for (const name of nameArr) {
        recentName = name;
        let newLength = name.length + totalLen;
        // if name length is greater than 20 characters,
        if (newLength <= 20) {
          if (nameLine.length == 0)
            nameLine = nameLine.concat(name);
          else
            nameLine = nameLine.concat(" ").concat(name);
          totalLen = newLength;
        } else {
          // create new line for brand name & reset variables
          thisData.nameSplit.push({
            name: nameLine,
            index: thisData.nameSplit.length,
            id: thisData.id
          });
          nameLine = "";
          totalLen = 0;
        }
      }
      let newNameLine = {
        index: thisData.nameSplit.length,
        id: thisData.id
      };
      if (nameLine.length == 0)
        newNameLine.name = recentName;
      else
        newNameLine.name = nameLine;
      thisData.nameSplit.push(newNameLine);
    }

    let date = dates[0];
    data.forEach((d, index) => {
      d.id = d.id;
      d.name = d.name;
      d.favIcon = d.favIcon;
      d.value = parseFloat(d.value);
      d.date = d.date;
      d.colour = d3.hsl(Math.random()*360,0.75,0.75);
      d.lastValue = d.value;
      d.nameSplit = d.nameSplit;
    });

    // Filter data for only product brands selected by user
    let allFilteredData = [];
    for (const brand of productBrands) {
      let dFound = false;
      for (const d of data) {
        if (d.name == brand.name) {
          allFilteredData.push(d);
        }
      }
    }
    data = allFilteredData;

    let dateSlice = data.filter(function (d) {
      return d.date == date;
    }).sort(function(a, b){
      return b.value<a.value ? -1 : b.value>a.value ? 1 : 0;
    }).slice(0, maxBars);

    // add missing product brands to date slice
    const missingBrands = [];
    for (const brand of productBrands) {
      let brandFound = false;
      for (const d of dateSlice) {
        if (brand.id === d.id) {
          brandFound = true;
        }
      }

      if (!brandFound) {
        missingBrands.push({
          id: brand.id,
          name: brand.name,
          favIcon: brand.company_favicon_url,
          value: null,
          date: dateSlice[0].date,
          colour: d3.hsl(Math.random()*360,0.75,0.75),
          lastValue: null,
          nameSplit: [{
            index: 0,
            id: brand.id,
            name: brand.name
          }]
        })
      }
    }
    dateSlice = dateSlice.concat(missingBrands);

    for (const i in dateSlice) {
      let index=i; index++;
      dateSlice[i].rank = index;
    }

    var x = d3.scaleLinear()
      .domain([0, d3.max(dateSlice, d => d.value)!=100 ? d3.max(dateSlice, d => d.value)+1 : d3.max(dateSlice, d => d.value)])
      .range([margin.left, width-margin.right]);

    let numBars = maxBars; numBars++;
    var y = d3.scaleLinear()
      .domain([1, numBars])
      .range([margin.top, height-margin.bottom]);

    let xAxis = d3.axisTop()
      .scale(x)
      .ticks(width > 500 ? 5 : 2)
      .tickSize(-(height-margin.top-margin.bottom))
      .tickFormat(d => d3.format('')(d));

    let xLine = svg.append('g')
      .attr('class', 'axis xAxis')
      .attr('transform', `translate(0, ${margin.top})`)
      .call(xAxis);
    xLine.selectAll(".domain").remove();
    xLine
      .selectAll('.tick line')
      .attr("stroke", "lightgrey")
      .attr("stroke-width", "1")
      .classed('origin', d => d == 0);

    const barHeight = y(1)-y(0)-barPadding;
    svg.selectAll('rect.bar')
      .data(dateSlice, d => d.value)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', x(0)+1)
      .attr('width', d => (x(d.value)-x(0)-1) >= 0 ? x(d.value)-x(0)-1 : 0 )
      .attr('y', d => y(d.rank))
      .attr('height', barHeight)
      .style('fill', d => d.colour);

    if (showBrandNames==true) {
      for (let data of dateSlice) {
        svg.selectAll('text.label')
          .data(data.nameSplit, d => d.id)
          .enter()
          .append('text')
          .attr('class', 'label')
          .attr('font-size', "14px")
          .attr('x', d => x(0)-5)
          .attr('y', d => y(data.rank)+5+((y(1)-y(0))/2)+1 + (d.index*15))
          .style('text-anchor', 'end')
          .html(d => d.name);
      }
    }

    if (showIcons==true){

      const imageWidth = y(1)-y(0)-(barPadding*3);
      const imageHeight = y(1)-y(0)-(barPadding*3);
      const yOffset = (barHeight-imageHeight)/2;
      svg.selectAll('#favIcons')
        .data(dateSlice, d => d.id)
        .enter()
        .append("svg:image")
        .attr("id", "favIcons")
        .attr('xlink:href', d => (d.value>0 && d.favIcon) ? d.favIcon : undefined)
        .attr("width", imageWidth)
        .attr("height", imageHeight)
        .attr("x", d => x(d.value)-imageWidth-yOffset)
        .attr("y", d => y(d.rank)+yOffset);
    }

    let barLabel = svg.selectAll('text.valueLabel')
      .data(dateSlice, d => d.id)
      .enter()
      .append('text')
      .attr('class', 'valueLabel')
      .attr('font-size', "20px")
      .attr('x', d => x(d.value)+5)
      .attr('y', d => y(d.rank)+5+((y(1)-y(0))/2)+1);
    if (showBrandScores==true)
      barLabel.text(d => d3.format(",")(d.lastValue));
    else
      barLabel.text(d => (d.rank));

    let formattedDate;
    if (this.props.dateType === 'monthly') {
      formattedDate = d3.timeFormat("%B - %Y")(new Date(date));
    } else {
      formattedDate = d3.timeFormat("%B %d, %Y")(new Date(date));
    }
    let dateText = svg.append('text')
      .attr('class', 'dateText')
      .attr('font-size', "30px")
      .attr('x', width-margin.right)
      .attr('y', margin.top/2)
      .style('text-anchor', 'end')
      .style("fill", "grey")
      .html(formattedDate);

    this.setState(() => ({
      mySvg: svg,
      width,
      height,
      data,
      dateSlice,
      dateText,
      x,
      y,
      xAxis,
      speed,
      showBrandScores,
      setupComplete: true
    }), () => this.props.changePlayAccessibility(true));

    // setTimeout(() => {
    //   this.runBarChartRace(data, dateSlice, width, height, x, y, xAxis, dateText, showBrandScores, svg, speed);
    // }, msToDelay);
  }

  runBarChartRace = (myData, myDateSlice, myWidth, myHeight, myX, myY, myXAxis, myDateText, brandScores, mySvg, speed) => {
    let data = myData ? myData : this.state.data;
    let dateSlice = myDateSlice ? myDateSlice : this.state.dateSlice;
    let maxBars = this.props.category.product_brands.length;
    let width = myWidth ? myWidth : this.state.width;
    let height = myHeight ? myHeight : this.state.height;
    let x = myX ? myX : this.state.x;
    let y = myY ? myY : this.state.y;
    let xAxis = myXAxis ? myXAxis : this.state.xAxis;
    let dateText = myDateText ? myDateText : this.state.dateText;
    let showBrandScores = brandScores ? brandScores : this.state.showBrandScores;
    let svg = mySvg ? mySvg : this.state.mySvg;
    let tickDuration = speed ? speed*100 : (this.state.speed)*100;
    let dates = this.props.dates;
    const margin = {
      top: 80,
      right: 10,
      bottom: 5,
      left: 125
    };
    let barPadding = (height-(margin.bottom+margin.top))/(maxBars*10);
    let index = 0;
    let date = dates[index];

    let ticker = d3.interval(e => {
      dateSlice = data.filter(d => d.date == date && !isNaN(d.value))
        .sort((a,b) => b.value - a.value)
        .slice(0,maxBars);

      for (const i in dateSlice) {
        let index=i; index++;
        dateSlice[i].rank = index;
      }

      x.domain([0, d3.max(dateSlice, d => d.value)!=100 ? d3.max(dateSlice, d => d.value)+1 : d3.max(dateSlice, d => d.value)]);

      let xLine = svg.select('.xAxis')
        .transition()
        .duration(tickDuration)
        .ease(d3.easeLinear)
        .call(xAxis);
      svg.selectAll(".domain").remove();
      xLine
        .selectAll('.tick line')
        .attr("stroke", "lightgrey")
        .attr("stroke-width", "1");

      const imageWidth = y(1)-y(0)-(barPadding*3);
      const imageHeight = y(1)-y(0)-(barPadding*3);
      const barHeight = y(1)-y(0)-barPadding;
      const yOffset = (barHeight-imageHeight)/2;
      let favIcons = svg.selectAll('#favIcons').data(dateSlice, d => d.id);
      favIcons
        .transition()
          .duration(tickDuration)
          .ease(d3.easeLinear)
          .attr("x", d => x(d.value)-imageWidth-yOffset)
          .attr("y", d => y(d.rank)+yOffset)
          .attr('xlink:href', d => (d.value>0 && d.favIcon) ? d.favIcon : undefined);

      let bars = svg.selectAll('.bar').data(dateSlice, d => d.id);
      bars
        .transition()
          .duration(tickDuration)
          .ease(d3.easeLinear)
          .attr('width', d => (x(d.value)-x(0)-1) >= 0 ? x(d.value)-x(0)-1 : 0)
          .attr('y', d => y(d.rank));

      for (const data of dateSlice) {
        let labels = svg.selectAll('.label').data(data.nameSplit, d => d.id);
        labels
          .transition()
          .duration(tickDuration)
            .ease(d3.easeLinear)
            .attr('y', d => y(data.rank)+5+((y(1)-y(0))/2)+1 + (d.index*15));
      }

      let valueLabels = svg.selectAll('.valueLabel').data(dateSlice, d => d.id)
        .transition()
          .duration(tickDuration)
          .ease(d3.easeLinear)
          .attr('x', d => x(d.value)+5)
          .attr('y', d => y(d.rank)+5+((y(1)-y(0))/2)+1);
      if (showBrandScores==true) {
        valueLabels
          .tween("text", function(d) {
            let i = d3.interpolate(d.lastValue, d.value);
              return function(t) {
                this.textContent = d3.format(",")(i(t));
            };
          });
      } else {
        valueLabels
          .tween("text", function(d) {
            let i = d3.interpolate(d.rank, d.rank);
              return function(t) {
                this.textContent = i(t);
            };
          });
      }

      if (this.props.dateType === 'monthly') {
        dateText.html(d3.timeFormat("%B - %Y")(new Date(date)));
      } else {
        dateText.html(d3.timeFormat("%B %d, %Y")(new Date(date)));
      }
      index++;
      if (index == dates.length) {
        ticker.stop();
        this.props.changePlayAccessibility(true);
      }
      date = dates[index];
    }, tickDuration);
  }

  render () {
    return (
      <div>
        { this.props.brandScoresData.length > 0 &&
          <div id="raceBarChart" className="pt-2" />
        }
      </div>
    );
  }
};
