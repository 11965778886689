import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import NewsResultsDisplay from './NewsResultsDisplay';
import NewsResultsHistogram from './NewsResultsHistogram';
import TweetResult from './TweetResult';
import LinkedInResult from './LinkedInResult';
import FacebookResult from './FacebookResult';
import ClipLoader from "react-spinners/ClipLoader";
import LoginContainer from '../../containers/common/LoginContainer';
import { getDomainFromUrl } from '../../utils/urls';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class QuickSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      selectedQuickSearchTab: 'News',
      searchTerm: '',
      searchedTerm:'',
      socialResults: [],
      socialSearch: '',
      newsResults: [],
      newsHistogramData: [],
      newsSearch: '',
      selectedNews: undefined,
      formattedStartDate: moment().utc().subtract(1, 'year').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      formattedEndDate: moment().utc().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedQuickSearchTab != this.state.selectedQuickSearchTab) {
      if (this.validSearch(this.state.searchTerm))
        this.displaySearchResults(this.state.searchTerm);
    }
  };

  validSearch = (searchTerm) => {
    const errors = {};
    if (!searchTerm || searchTerm.trim() === '') {
      errors.invalidSearch = true;
    }
    this.setState(() => ({ errors }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  }

  elasticSearchItems = () => {
    let searchTerm = this.state.searchTerm;
    if (this.validSearch(searchTerm)) {
      this.displaySearchResults(searchTerm);
    }
  }

  displaySearchResults = (searchTerm) => {
    if (this.state.selectedQuickSearchTab == 'Social') {
      if (searchTerm != this.state.socialSearch) {
        this.setState(() => ({
          socialSearch: searchTerm,
          socialResults: []
        }));
        this.fetchElasticSearchSource(searchTerm, 'social');
      }
    } else if (this.state.selectedQuickSearchTab == 'News') {
      if (searchTerm != this.state.newsSearch) {
        this.setState(() => ({
          newsSearch: searchTerm,
          newsResults: []
        }));
        this.fetchElasticSearchSource(searchTerm, 'news');
      }
    }
    this.setState(() => ({ searchedTerm: searchTerm }));
  }

  fetchElasticSearchSource = (searchTerm, source) => {
    this.setState(() => ({ loading: true }));
    if (source === 'news') {
      this.fetchNewsAndPressReleases(searchTerm);
    } else if (source === 'social') {
      this.fetchSocial(searchTerm);
    } else {
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/elastic-search-data?search_term=${searchTerm}&source=${source}&sort_order=desc&start_date=${this.state.formattedStartDate}&end_date=${this.state.formattedEndDate}&limit=500`,
        HEADERS
      ).then(response => {
        if (this.state.isMounted) {
          let searchData = response.data;
          let searchResults = [];
          if (Array.isArray(searchData.results)) {
            for (const result of searchData.results) {
              searchResults.push(result);
            }
          } else {
            searchResults = searchData.results; // message for not found
          }
          this.setState(() => ({ loading: false }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch elastic search w/ search term & source');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        this.setState(() => ({ loading: false }));
      });
    }
  };

  fetchNewsAndPressReleases = (searchTerm) => {
    const newsPrRequests = [];
    // fetch news
    newsPrRequests.push(
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/elastic-search-data?search_term=${searchTerm}&source=articles&sort_order=desc&start_date=${this.state.formattedStartDate}&end_date=${this.state.formattedEndDate}&limit=400`,
        HEADERS
      ).then(response => {
        const news = response.data.results;
        if (Array.isArray(news)) {
          return { news };
        } else {
          return { news: [] };
        }
      }).catch(error => {
        console.error('Error: failed to fetch news...');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        return { news: [] };
      })
    );
    // fetch press releases
    newsPrRequests.push(
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/elastic-search-data?search_term=${searchTerm}&source=press-releases&sort_order=desc&start_date=${this.state.formattedStartDate}&end_date=${this.state.formattedEndDate}&limit=200`,
        HEADERS
      ).then(response => {
        const pressReleases = response.data.results;
        if (Array.isArray(pressReleases)) {
          for (let pressRelease of pressReleases) {
            pressRelease._source.published_at = pressRelease._source.published_date;
            pressRelease._source.domain = getDomainFromUrl(pressRelease._source.url);
          }
          return { pressReleases };
        } else {
          return { pressReleases: [] };
        }
      }).catch(error => {
        console.error('Error: failed to fetch press releases...');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        return { pressReleases: [] };
      })
    );
    this.fetchHistogram(searchTerm, 'articles');

    Promise.all(newsPrRequests).then(responses => {
      let newsResults = [];
      let news = [];
      let pressReleases = [];
      for (const response of responses) {
        if (response.news) {
          newsResults = newsResults.concat(response.news);
        } else if (response.pressReleases) {
          newsResults = newsResults.concat(response.pressReleases);
        }
      }
      newsResults.sort((a, b) => moment(b._source.published_at) - moment(a._source.published_at));

      let selectedNews;
      if (newsResults.length > 0) {
        selectedNews = newsResults[0];
      }

      if (this.state.isMounted) {
        this.setState(() => ({
          newsResults,
          selectedNews,
          loading: false
        }));
      }
    }).catch(error => {
      console.log('Error: failed fetch news and press releases...');
      if (this.state.isMounted) {
        this.setState(() => ({
          newsResults: [],
          selectedNews: undefined,
          loading: false
        }));
      }
    });
  };

  fetchSocial = (searchTerm) => {
    const socialRequests = [];
    // fetch tweets
    socialRequests.push(
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/elastic-search-data?search_term=${searchTerm}&source=twit_tweets&sort_order=desc&start_date=${this.state.formattedStartDate}&end_date=${this.state.formattedEndDate}&limit=200`,
        HEADERS
      ).then(response => {
        const twitterResults = response.data.results;
        if (Array.isArray(twitterResults)) {
          for (let tweet of twitterResults) {
            tweet._source.posted_at = tweet._source.tweet_created_at;
            tweet.type = 'tweet';
          }
          return { twitterResults };
        } else {
          return { twitterResults: [] };
        }
      }).catch(error => {
        console.error('Error: failed to fetch tweets...');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        return { twitterResults: [] };
      })
    );
    // fetch linkedin posts
    socialRequests.push(
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/elastic-search-data?search_term=${searchTerm}&source=linkedin-posts-archive&sort_order=desc&start_date=${this.state.formattedStartDate}&end_date=${this.state.formattedEndDate}&limit=200`,
        HEADERS
      ).then(response => {
        const linkedInResults = response.data.results;
        if (Array.isArray(linkedInResults)) {
          for (let post of linkedInResults) {
            post._source.posted_at = post._source.post_published_date;
            post.type = 'linkedin';
          }
          return { linkedInResults };
        } else {
          return { linkedInResults: [] };
        }
      }).catch(error => {
        console.error('Error: failed to fetch linkedin posts...');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        return { linkedInResults: [] };
      })
    );
    // fetch facebook posts
    socialRequests.push(
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/elastic-search-data?search_term=${searchTerm}&source=facebook-posts&sort_order=desc&start_date=${this.state.formattedStartDate}&end_date=${this.state.formattedEndDate}&limit=200`,
        HEADERS
      ).then(response => {
        const facebookResults = response.data.results;
        if (Array.isArray(facebookResults)) {
          for (let post of facebookResults) {
            post.type = 'facebook';
          }
          return { facebookResults };
        } else {
          return { facebookResults: [] };
        }
      }).catch(error => {
        console.error('Error: failed to fetch facebook posts...');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        return { facebookResults: [] };
      })
    );

    Promise.all(socialRequests).then(responses => {
      let socialResults = [];
      // add common date key to ease sorting
      for (const response of responses) {
        if (response.twitterResults) {
          socialResults = socialResults.concat(response.twitterResults);
        } else if (response.linkedInResults) {
          socialResults = socialResults.concat(response.linkedInResults);
        } else if (response.facebookResults) {
          socialResults = socialResults.concat(response.facebookResults);
        }
      }

      socialResults.sort((a, b) => moment(b._source.posted_at) - moment(a._source.posted_at));
      if (this.state.isMounted) {
        this.setState(() => ({
          socialResults,
          loading: false
        }));
      }
    }).catch(error => {
      console.log('Error: failed social requests...');
      if (this.state.isMounted) {
        this.setState(() => ({
          socialResults: [],
          loading: false
        }));
      }
    });
  };

  fetchHistogram = (searchTerm, source) => {
    axios.get(
      `${CONTENT_SCRAPING_ENDPOINT}/api/elastic-search-histogram?search_term=${searchTerm}&source=${source}&start_date=${this.state.formattedStartDate}&end_date=${this.state.formattedEndDate}&grouping=week`,
      HEADERS
    ).then(response => {
      let histogramData = response.data;
      if (
        histogramData.results &&
        histogramData.results.groupings &&
        histogramData.results.groupings.buckets
      ) {
        let histogramBuckets = histogramData.results.groupings.buckets;
        let newsHistogramData = [];
        for (const data of histogramBuckets) {
          newsHistogramData.push(data);
        }
        this.setState(() => ({ newsHistogramData }));
      }
    }).catch(error => {
      console.error('Error: failed to fetch elastic search histogram w/ search term & source');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  changeSearchTerm = (event) => {
    const searchTerm = event.currentTarget.value;
    this.setState(() => ({
      searchTerm
    }));
  }

  render () {
    return (
      <div className="p-4">
        { !(this.props.user && this.props.user.customerId) &&
          <LoginContainer />
        }
        { (this.props.user && this.props.user.customerId) &&
          <div>
            <Row>
              <Col>
                <Row>
                  <div className="pt-2 pl-3 d-inline-block">
                    <Form.Control
                      className="d-inline-block"
                      type="text"
                      value={this.state.searchTerm}
                      onChange={this.changeSearchTerm}
                      onKeyUp={(e) => {
                          if (e.keyCode === 13) {
                              this.elasticSearchItems();
                          }
                      }}
                      isInvalid={this.state.errors.invalidSearch}
                      style={{ width: '225px'}}
                    />
                    <Button
                      className="align-top"
                      variant="secondary"
                      onClick={this.elasticSearchItems}
                    >
                      <FontAwesomeIcon
                          icon={faSearch}
                      />
                    </Button>
                    <div className="d-inline-block pl-2">
                      (last 1 year, up to 500 results)
                    </div>
                  </div>
                </Row>
                <Row>
                  <Tabs
                    id="quick-search-tabs"
                    className="pt-4 pl-3"
                    activeKey={this.state.selectedQuickSearchTab}
                    onSelect={selectedQuickSearchTab => this.setState({ selectedQuickSearchTab })}
                  >
                    <Tab
                      eventKey="News"
                      title="News & PR"
                    >
                    </Tab>
                    <Tab
                      eventKey="Social"
                      title="Social"
                    >
                    </Tab>
                  </Tabs>
                </Row>
              </Col>
              { (
                  this.state.selectedQuickSearchTab == "News" &&
                  this.state.newsResults &&
                  this.state.newsHistogramData.length>0
                ) &&
                <Col
                  xs={12}
                  md={4}
                  style={{
                      minHeight: "110px",
                      maxHeight: "110px"
                  }}
                >
                  <NewsResultsHistogram
                    newsHistogramData = {this.state.newsHistogramData}
                  />
                </Col>
              }
            </Row>

            <div className="p-2 pt-4">
              { this.state.loading &&
                <div className="clip-loader">
                  <ClipLoader
                    css=""
                    color={"#000000"}
                    loading={this.state.loading}
                  />
                </div>
              }
              { (!this.state.loading && this.state.selectedQuickSearchTab == 'Social') &&
                <div>
                  { this.state.socialResults.length === 0 &&
                    this.state.searchedTerm.length > 0 &&
                    <div>
                      No results found for that search term
                    </div>
                  }
                  { this.state.socialResults.length > 0 &&
                    <div
                      className="pre-scrollable pt-2"
                      style={{
                        overflow: 'auto',
                        minHeight: "500px",
                        maxHeight: "500px"
                      }}
                    >
                    { this.state.socialResults.map((result, i) => {
                        if (result.type === 'tweet') {
                          return (
                            <TweetResult
                              key={`sr-t-${result._id}`}
                              comment={result._source}
                            />
                          )
                        } else if (result.type === 'linkedin') {
                          return (
                            <LinkedInResult
                              key={`sr-li-${result._id}`}
                              comment={result._source}
                            />
                          )
                        } else if (result.type === 'facebook') {
                          return (
                            <FacebookResult
                              key={`sr-fb-${result._id}`}
                              comment={result._source}
                            />
                          )
                        }
                      })
                    }
                    </div>
                  }
                </div>
              }
              { (!this.state.loading && this.state.selectedQuickSearchTab == "News") &&
                <div>
                  { this.state.newsResults.length === 0 &&
                    this.state.searchedTerm.length > 0 &&
                    <div>
                      No results found for that search term
                    </div>
                  }
                  { this.state.newsResults.length > 0 &&
                    <NewsResultsDisplay
                      newsResults={this.state.newsResults}
                      selectedNews={this.state.selectedNews}
                      newsHistogramData = {this.state.newsHistogramData}
                    />
                  }
                </div>
              }
            </div>
          </div>
        }
      </div>
    );
  }
};
