import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import ClipLoader from 'react-spinners/ClipLoader';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { sortAlphabeticalByKey } from '../../utils/sorts';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryTopSpot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brandsMap: {},
      topSpotMetrics: [
        {
          label: 'Review ratings',
          key: 'reviewRatingsData',
          locationHash: '#reviews',
        },
        {
          label: 'News mention count',
          key: 'newsMentionsData',
          locationHash: '#news',
        },
        {
          label: 'News reach',
          key: 'newsReachData',
          locationHash: '#news',
        },
        {
          label: 'Podcast mention count',
          key: 'podcastMentionData',
          locationHash: '#mediacast-podcast',
        },
        {
          label: 'Podcast reach',
          key: 'podcastReachData',
          locationHash: '#mediacast-podcast',
        },
        {
          label: 'Social follower growth',
          key: 'socialFollowerGrowthData',
          locationHash: '#social',
        },
        {
          label: 'New video views',
          key: 'newVideosData',
          locationHash: '#mediacast-youtubeActivity',
        },
        {
          label: 'Organic search rank',
          key: 'organicSearchRankData',
          locationHash: '#search-shareOfSearchResults',
        },
        {
          label: 'Paid search rank',
          key: 'paidSearchRankData',
          locationHash: '#search-shareOfSearchResults',
        },
        {
          label: 'Web site traffic',
          key: 'webTrafficData',
          locationHash: '#web',
        },
        {
          label: 'Employer review ratings',
          key: 'employerReviewRatingsData',
          locationHash: '#company',
        },
      ].sort(sortAlphabeticalByKey('label')),
      metricsLoaded: 0,
      startDate: moment.utc().subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment.utc().endOf('day').format('YYYY-MM-DD HH:mm:ss')
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.setBrandsMap();
    this.fetchTopSpotData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.setBrandsMap();
      this.fetchTopSpotData();
    }
  };

  setBrandsMap = () => {
    if (this.props.category) {
      const brandsMap = {};
      for (const brand of this.props.category.product_brands) {
        brandsMap[brand.id] = brand;
      }
      this.setState(() => ({ brandsMap }));
    }
  };

  fetchTopSpotData = () => {
    if (this.props.category) {
      // fetch review ratings data
      this.setState(() => ({
        reviewRatingsData: {},
        reviewRatingsDataLoading: true,
        metricsLoaded: 0,
      }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-review-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&start_date=${this.state.startDate}&end_date=${this.state.endDate}`,
        HEADERS
      ).then(response => {
        const metricData = {
          metricLabel: 'Review ratings',
          topValue: 0
        };
        for (const brandData of response.data) {
          if (brandData.avg_review_score > metricData.topValue) {
            metricData.topValue = brandData.avg_review_score;
            if (this.state.brandsMap[brandData.product_brand_id]) {
              metricData.topBrand = this.state.brandsMap[brandData.product_brand_id];
            }
          }
        }

        if (this.state.isMounted) {
          this.setState((prevState) => ({
            reviewRatingsData: metricData,
            reviewRatingsDataLoading: false,
            metricsLoaded: prevState.metricsLoaded + 1,
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to load Category Review ratings...`);
        if (this.state.isMounted) {
          this.setState((prevState) => ({
            reviewRatingsData: {},
            reviewRatingsDataLoading: false,
            metricsLoaded: prevState.metricsLoaded + 1,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      // fetch social follower growth
      this.setState(() => ({
        socialFollowerGrowthData: {},
        socialFollowerGrowthDataLoading: true
      }));
      const socialFollowerGrowthRequests = [];
      for (const brand of this.props.category.product_brands) {
        socialFollowerGrowthRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/metrics/channel/brand-scores/range?logged_customer_id=${this.props.user.customerId}&company_id=${brand.company_id}&product_brand_id=${brand.id}&category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&channel_category_name=Social&start_date=${this.state.startDate}&end_date=${this.state.endDate}`,
            HEADERS
          ).then(response => {
            const metricData = {
              brandId: brand.id,
              brand,
              totalFollowerGrowth: 0
            };
            for (const metricScoreData of response.data) {
              if (
                metricScoreData.metric_name === 'net_new_followers_twitter' ||
                metricScoreData.metric_name === 'net_new_followers_linkedin' ||
                metricScoreData.metric_name === 'net_new_followers_instagram'
              ) {
                metricData.totalFollowerGrowth += metricScoreData.metric_score;
              }
            }
            return metricData;
          }).catch(error => {
            console.error(`Error: unable to load social follower growth for "${brand.name}"...`);
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return {
              brandId: brand.id,
              brand,
              totalFollowerGrowth: null
            }
          })
        );
      }
      // evaulate social follower growth results
      Promise.all(socialFollowerGrowthRequests).then(responses => {
        const socialFollowerGrowthData = {
          metricLabel: 'Social follower growth',
          topValue: 0
        };
        for (const response of responses) {
          if (response.totalFollowerGrowth > socialFollowerGrowthData.topValue) {
            socialFollowerGrowthData.topValue = response.totalFollowerGrowth;
            socialFollowerGrowthData.topBrand = response.brand;
          }
        }

        if (this.state.isMounted) {
          this.setState((prevState) => ({
            socialFollowerGrowthData,
            socialFollowerGrowthDataLoading: false,
            metricsLoaded: prevState.metricsLoaded + 1,
          }));
        }
      });

      // fetch employer review ratings data
      this.setState(() => ({
        employerReviewRatingsData: {},
        employerReviewRatingsDataLoading: true
      }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/category-employer-review-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&start_date=${this.state.startDate}&end_date=${this.state.endDate}`,
        HEADERS
      ).then(response => {
        const metricData = {
          metricLabel: 'Employer review ratings',
          topValue: 0
        };
        for (const brandData of response.data) {
          if (brandData.avg_review_score > metricData.topValue) {
            metricData.topValue = brandData.avg_review_score;
            if (this.state.brandsMap[brandData.product_brand_id]) {
              metricData.topBrand = this.state.brandsMap[brandData.product_brand_id];
            }
          }
        }

        if (this.state.isMounted) {
          this.setState((prevState) => ({
            employerReviewRatingsData: metricData,
            employerReviewRatingsDataLoading: false,
            metricsLoaded: prevState.metricsLoaded + 1,
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to load Category Employer review ratings...`);
        if (this.state.isMounted) {
          this.setState((prevState) => ({
            employerReviewRatingsData: {},
            employerReviewRatingsDataLoading: false,
            metricsLoaded: prevState.metricsLoaded + 1,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      // these match category share of standard request/response format, so are loopable
      const categoryShareOfMetrics = [
        {
          label: 'News mention count',
          endpoint: `${CONTENT_SCRAPING_ENDPOINT}/api/category-share-of-news-mention`,
          stateKey: 'newsMentionsData',
          valueKey: 'total_mentions',
          type: 'standard',
        },
        {
          label: 'News reach',
          endpoint: `${LISTEN_ENDPOINT}/api/category-share-of-news-mention-by-reach`,
          stateKey: 'newsReachData',
          valueKey: 'reach',
          type: 'standard',
        },
        {
          label: 'Podcast mention count',
          endpoint: `${LISTEN_ENDPOINT}/api/category-share-podcast-stats`,
          stateKey: 'podcastMentionData',
          valueKey: 'mention_count',
          type: 'standard',
        },
        {
          label: 'Podcast reach',
          endpoint: `${LISTEN_ENDPOINT}/api/category-share-podcast-estimated-reach`,
          stateKey: 'podcastReachData',
          valueKey: 'podcast_estimated_reach',
          type: 'standard',
        },
        {
          label: 'New video views',
          endpoint: `${LISTEN_ENDPOINT}/api/category-share-of-new-videos`,
          stateKey: 'newVideosData',
          valueKey: 'new_views_count',
          type: 'standard',
        },
        {
          label: 'Organic search rank',
          endpoint: `${LISTEN_ENDPOINT}/api/category-share-of-serp`,
          additionalParams: '&type=google-organic',
          stateKey: 'organicSearchRankData',
          valueKey: 'serp_points',
          type: 'rank',
        },
        {
          label: 'Paid search rank',
          endpoint: `${LISTEN_ENDPOINT}/api/category-share-of-serp`,
          additionalParams: '&type=google-paid',
          stateKey: 'paidSearchRankData',
          valueKey: 'serp_points',
          type: 'rank',
        },
        {
          label: 'Web site traffic',
          endpoint: `${LISTEN_ENDPOINT}/api/category-share-of-web-stats`,
          stateKey: 'webTrafficData',
          valueKey: 'organic_traffic',
          type: 'standard',
        },
      ]
      for (const metric of categoryShareOfMetrics) {
        this.setState(() => ({
          [metric.stateKey]: {},
          [`${metric.stateKey}Loading`]: true
        }));

        const additionalParams = metric.additionalParams || '';
        axios.get(
          `${metric.endpoint}?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&start_date=${this.state.startDate}&end_date=${this.state.endDate}&group_by=custom${additionalParams}`,
          HEADERS
        ).then(response => {
          const metricData = {
            metricLabel: metric.label,
            topValue: 0
          };
          for (const brandData of response.data[0].product_brands) {
            if (brandData[metric.valueKey] > metricData.topValue) {
              metricData.topValue = brandData[metric.valueKey];
              if (this.state.brandsMap[brandData.product_brand_id]) {
                metricData.topBrand = this.state.brandsMap[brandData.product_brand_id];
              }
            }
          }

          if (metric.type === 'rank') {
            // update the highest value to rank 1
            metricData.topValue = 1;
          }

          if (this.state.isMounted) {
            this.setState((prevState) => ({
              [metric.stateKey]: metricData,
              [`${metric.stateKey}Loading`]: false,
              metricsLoaded: prevState.metricsLoaded + 1,
            }));
          }
        }).catch(error => {
          console.error(`Error: unable to load Category ${metric.label}...`);
          if (this.state.isMounted) {
            this.setState((prevState) => ({
              [metric.stateKey]: {},
              [`${metric.stateKey}Loading`]: false,
              metricsLoaded: prevState.metricsLoaded + 1,
            }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  render () {
    return (
      <div className="m-4">
        { this.props.category &&
          <div>
            <Row>
              <Col></Col>
              <Col xs={9}>
                <Table
                  id="categoryTopSpot"
                  className="fixed-header mb-0"
                  striped
                  bordered
                >
                  <thead className="bg-dark text-light">
                    <tr>
                      <th>Metric</th>
                      <th>Brand</th>
                      <th>Value (Trailing 30 days)</th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.state.topSpotMetrics.map((tsm, i) => {
                        return (
                          <tr key={`tsm-${i}`}>
                            { this.state[`${tsm.key}Loading`] &&
                              <React.Fragment>
                                <td>
                                  <NavLink
                                    to={`/category-dashboard/${encodeURIComponent(this.props.category.name)}${tsm.locationHash}`}
                                  >
                                    {tsm.label}
                                  </NavLink>
                                </td>
                                <td></td>
                                <td>
                                  <ClipLoader size={20} />
                                </td>
                              </React.Fragment>
                            }
                            { !this.state[`${tsm.key}Loading`] &&
                              <React.Fragment>
                                { !(this.state[tsm.key] && this.state[tsm.key].topBrand) &&
                                  <React.Fragment>
                                    <td>
                                      <NavLink
                                        to={`/category-dashboard/${encodeURIComponent(this.props.category.name)}${tsm.locationHash}`}
                                      >
                                        {tsm.label}
                                      </NavLink>
                                    </td>
                                    <td>-</td>
                                    <td>-</td>
                                  </React.Fragment>
                                }
                                { (this.state[tsm.key] && this.state[tsm.key].topBrand) &&
                                  <React.Fragment>
                                    <td>
                                      <NavLink
                                        to={`/category-dashboard/${encodeURIComponent(this.props.category.name)}${tsm.locationHash}`}
                                      >
                                        {tsm.label}
                                      </NavLink>
                                    </td>
                                    <td>
                                      { this.state[tsm.key].topBrand.company_favicon_url &&
                                        <img
                                          className="mr-2"
                                          src={this.state[tsm.key].topBrand.company_favicon_url}
                                          alt=""
                                          crossOrigin="anonymous"
                                          style={{ width: '16px', height: '16px' }}
                                        />
                                      }
                                      { !this.state[tsm.key].topBrand.company_favicon_url &&
                                        <FontAwesomeIcon
                                          className="mr-2"
                                          icon={faImage}
                                          color="#d9d9d9"
                                          style={{ fontSize: '1.2rem' }}
                                        />
                                      }
                                      {this.state[tsm.key].topBrand.name}
                                    </td>
                                    <td>{numberWithCommas(this.state[tsm.key].topValue)}</td>
                                  </React.Fragment>
                                }
                              </React.Fragment>
                            }
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
                { this.state.metricsLoaded === this.state.topSpotMetrics.length &&
                  <SharePopUpContainer
                    shareElementId="categoryTopSpot"
                    elementLabel="category-top-spot-img"
                    position="bottom"
                    contextType="category"
                    contextCategory={this.props.category}
                    contextBrand={null}
                    contextProperty="Top Spot"
                    contextChartName="Summary"
                    contextDateRange={`${moment.utc(this.state.startDate).format('MM/DD/YYYY')} - ${moment.utc(this.state.endDate).format('MM/DD/YYYY')}`}
                  />
                }
              </Col>
              <Col></Col>
            </Row>
          </div>
        }
      </div>
    );
  }
};
