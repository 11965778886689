import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../../common/CardComponent';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class CommentPromoterDetractor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      commentPromoterDetractorData: [],
      commentPromoterDetractorDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.loadCommentPromoterDetractorData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.topicId !== this.props.topicId ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter
    ) {
      this.loadCommentPromoterDetractorData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  loadCommentPromoterDetractorData = () => {
    this.setState(() => ({ commentPromoterDetractorDataLoading: true }));
    const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
    axios.get(
      `${LISTEN_ENDPOINT}/api/topics/${this.props.topicId}/comment-promoter-detractor-trend?start-date=${formattedStartDate}&end-date=${formattedEndDate}`,
      HEADERS
    ).then(response => {
      const commentPromoterDetractorData = response.data;
      if (this.state.isMounted) {
        this.setState(() => ({
          commentPromoterDetractorData,
          commentPromoterDetractorDataLoading: false
        }));
      }
    }).catch(error => {
      if (this.state.isMounted) {
        this.setState(() => ({
          commentPromoterDetractorData: [],
          commentPromoterDetractorDataLoading: false
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  toggleOptions = () => {
    this.setState((prevState) => ({ showConfig: !prevState.showConfig }));
  };

  render () {
    return (
      <CardComponent
        title="Counts of Promoters vs Detractors"
        icon={
          <img
            className="mr-2 align-top"
            src="/images/Counts_of_Promoters_Detractors_Icon.png"
          />
        }
        description="Counts of Promoters vs Detractors provides a break down of promoter, neutral, and detractor comments"
        body={
          <div>
            { this.state.commentPromoterDetractorDataLoading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.commentPromoterDetractorDataLoading &&
              <ResponsiveContainer height={300} width="99%">
                <BarChart data={this.state.commentPromoterDetractorData}>
                  <XAxis
                    dataKey="date"
                    tickFormatter={(date) => {
                      return moment(date).endOf('day').format('MM/DD');
                    }}
                  />
                  <YAxis
                    allowDecimals={false}
                  />
                  <Tooltip
                    cursor={false}
                    labelFormatter={(label) => moment(label).format('dddd, MMMM Do YYYY')}
                  />
                  <CartesianGrid strokeDasharray="5 5"/>
                  <Bar name="Detractors" dataKey="detractors" stackId="a" fill="#ff0000"/>
                  <Bar name="Neutral" dataKey="neutral" stackId="a" fill="#b3b3b3"/>
                  <Bar name="Promoters" dataKey="promoters" stackId="a" fill="#00cc00"/>
                </BarChart>
              </ResponsiveContainer>
            }
          </div>
        }
        contextType="brand"
        contextCategory={this.props.category}
        contextBrand={this.props.brand}
        contextChannel="Social"
        contextChartName="Counts of Promoters vs Detractors"
      />
    );
  }
};
