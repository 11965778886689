import React from 'react';
import Form from 'react-bootstrap/Form';
import BrandChannelSummaryContainer from '../../../containers/brands/BrandChannelSummaryContainer';
import CompanyYoutubeChannel from '../../video/CompanyYoutubeChannel';

export default class VideoDetailsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  render () {
    return (
      <div>
        <div>
          <BrandChannelSummaryContainer
            channel="Videos"
            category={this.props.category}
            brand={this.props.brand}
          />
        </div>
        <div>
          <h5 className="my-2 p-2 bg-bops-blue text-light">
            Metric Trends
            <div
              className="d-inline-block ml-4"
              style={{ fontSize: '1rem', fontWeight: 'normal' }}
            >
              <Form.Check
                id="videoAllMonthsCheckbox"
                className="ml-2"
                type="checkbox"
                label="All Months"
                checked={this.props.allMonthsChecked}
                onChange={(event) => this.props.updateAllMonthsChecked(event.currentTarget.checked)}
                inline
              />
            </div>
          </h5>
          <div>
            <CompanyYoutubeChannel
              customerId={this.props.customerId}
              companyId={this.props.companyId}
              category={this.props.category}
              brandName={this.props.brandName}
              brand={this.props.brand}
              startDateFilter={this.props.startDateFilter}
              endDateFilter={this.props.endDateFilter}
              onFilterDatesChange={this.props.onFilterDatesChange}
              scrollToDetails={this.props.scrollToDetails}
              unsetScrollToDetails={this.props.unsetScrollToDetails}
              allMonthsChecked={this.props.allMonthsChecked}
              updateAllMonthsChecked={this.props.updateAllMonthsChecked}
              allMonthsStartDate={this.props.allMonthsStartDate}
              companyChecked={this.props.companyChecked}
              portfolioChecked={this.props.portfolioChecked}
              productChecked={this.props.productChecked}
              onCppCheckChange={this.props.onCppCheckChange}
            />
          </div>
        </div>
      </div>
    );
  }
};
