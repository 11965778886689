import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import ClipLoader from 'react-spinners/ClipLoader';
import LoginContainer from '../../containers/common/LoginContainer';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class Leaderboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      category: undefined,
      brand: undefined,
      quarterStartDate: moment().startOf('quarter'),
      quarterEndDate: moment().endOf('quarter'),
      daysRemaining: moment().endOf('quarter').diff(moment(), 'days'),
      leaderboardData: [],
      leaderboardDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.myCategoryBrands.length > 0) {
      this.setState(() => ({
        category: this.props.myCategoryBrands[0].category,
        brand: this.props.myCategoryBrands[0].brand,
      }), () => this.fetchLeaderboardData());
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.myCategoryBrands !== this.props.myCategoryBrands &&
      this.props.myCategoryBrands.length > 0
    ) {
      this.setState(() => ({
        category: this.props.myCategoryBrands[0].category,
        brand: this.props.myCategoryBrands[0].brand,
      }), () => this.fetchLeaderboardData());
    }
  };

  fetchLeaderboardData = () => {
    if (
      this.props.user &&
      this.state.category &&
      this.state.brand
    ) {
      this.setState(() => ({ leaderboardDataLoading: true }));
      const leaderboardDataRequests = [];
      // fetch customer objective data
      const formattedStartDate = this.state.quarterStartDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.quarterEndDate.format('YYYY-MM-DD HH:mm:ss');
      leaderboardDataRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/customer-objectives-progress?customer_id=${this.props.user.customerId}&private_category_id=${this.state.category.id}&product_brand_id=${this.state.brand.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
          HEADERS
        ).then(response => {
          const customerObjectives = response.data;
          return { customerObjectives };
        }).catch(error => {
          console.error('Error: failed to fetch customer objective data');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { customerObjectives: [] };
        })
      );
      // fetch customer users data
      leaderboardDataRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/customer-users/customer-id/${this.props.user.customerId}`,
          HEADERS
        ).then(response => {
          const customerUsers = response.data;
          return { customerUsers }
        }).catch(error => {
          console.error('Error: unable to fetch customer users');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { customerUsers: [] };
        })
      );

      Promise.all(leaderboardDataRequests).then(responses => {
        let customerObjectives = [];
        let customerUsers = [];
        for (let response of responses) {
          if (response.customerObjectives) {
            customerObjectives = response.customerObjectives;
          } else if (response.customerUsers) {
            customerUsers = response.customerUsers;
          }
        }

        const usersMap = {};
        for (let user of customerUsers) {
          usersMap[user.id] = {
            ...user,
            objectives: [],
            objectiveAverage: null,
          }
        }
        // add objectives to included users
        for (let objective of customerObjectives) {
          for (let user of objective.users) {
            if (usersMap[user.user_id]) {
              usersMap[user.user_id].objectives.push(objective);
            }
          }
        }
        // convert user map to array and evaluate objective progress average
        const leaderboardData = [];
        for (const [userId, userData] of Object.entries(usersMap)) {
          let objectiveProgressTotal = 0;
          for (let obj of userData.objectives) {
            objectiveProgressTotal += obj.objective_goal_progress || 0;
          }
          if (userData.objectives.length > 0) {
            userData.objectiveAverage = Math.round(objectiveProgressTotal/userData.objectives.length);
          }
          leaderboardData.push(userData);
        }
        // sort leaderboard users
        leaderboardData.sort((a, b) => {
          if (a.objectiveAverage === b.objectiveAverage) {
            return 0;
          }
          // nulls sort after anything else
          if (a.objectiveAverage === null) {
            return 1;
          }
          if (b.objectiveAverage === null) {
            return -1;
          }
          return a.objectiveAverage < b.objectiveAverage ? 1 : -1;
        });
        // add appropriate ranks
        let rank = 1;
        for (let i = 0; i < leaderboardData.length; i++) {
          if (
            i > 0 &&
            leaderboardData[i-1].objectiveAverage === leaderboardData[i].objectiveAverage
          ) {
            // allow for tied ranks
            leaderboardData[i].rank = leaderboardData[i-1].rank;
          } else {
            leaderboardData[i].rank = rank;
          }

          rank++;
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            leaderboardData,
            leaderboardDataLoading: false,
          }));
        }
      });
    }
  };

  render () {
    return (
      <div
        className="p-4"
        style={{
          minHeight: 'calc(100vh - 53px)',
          maxHeight: 'calc(100vh - 53px)',
          backgroundImage: this.props.user.customerId ?
            'url("https://brandops-app-assets.s3.amazonaws.com/leaderboard_background.jpg")' :
            '',
          backgroundSize: '100%',
        }}
      >
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        { this.props.user.customerId &&
          <div>
            { this.state.leaderboardDataLoading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { (!this.state.leaderboardDataLoading && this.state.leaderboardData.length > 0) &&
              <div>
                <Row>
                  <Col xs={4}>
                    <div className="text-center" style={{ paddingTop: '96px' }}>
                      <img
                        className="border border-secondary"
                        src="https://brandops-app-assets.s3.amazonaws.com/leaderboard_image.png"
                        width="100%"
                        alt=""
                        style={{ borderRadius: '50%' }}
                      />
                    </div>
                  </Col>
                  <Col xs={8}>
                    <div>
                      <div className="text-center">
                        <div style={{ fontSize: '3rem', fontWeight: 'bold' }}>
                          {`Q${this.state.quarterEndDate.quarter()} - Leaderboard`}
                        </div>
                        <div>
                          { this.state.daysRemaining === 1 &&
                            <div className="d-inline-block">
                              {`${this.state.daysRemaining} day remaining in the quarter`}
                            </div>
                          }
                          { this.state.daysRemaining !== 1 &&
                            <div className="d-inline-block">
                              {`${this.state.daysRemaining} days remaining in the quarter`}
                            </div>
                          }
                        </div>
                      </div>
                      <div
                        className="rounded"
                        style={{
                          maxHeight: 'calc(100vh - 200px)',
                          overflow: 'auto',
                          overflowX: 'hidden',
                          backgroundColor: '#0d4fa6'
                        }}
                      >
                        <Table
                          className="mb-0 text-light text-left"
                          striped
                        >
                          <tbody style={{ fontSize: '1.5rem' }}>
                            { this.state.leaderboardData.map((userData, i) => {
                                return (
                                  <tr key={`lb-r-${i}`}>
                                    <td
                                      className="align-middle"
                                      style={{ width: '100px' }}
                                    >
                                      <div className="text-center">
                                        {userData.rank}
                                      </div>
                                    </td>
                                    <td className="align-middle">
                                      <div>
                                        {`${userData.first_name} ${userData.last_name}`}
                                      </div>
                                    </td>
                                    <td className="align-middle">
                                      { userData.objectiveAverage !== null ?
                                          userData.objectiveAverage :
                                          <Link
                                            className="text-light"
                                            to="/company-settings/objectives-and-goals"
                                            style={{ fontSize: '.875rem' }}
                                          >
                                            Set Objectives
                                          </Link>
                                      }
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            }
          </div>
        }
      </div>
    );
  }
};
