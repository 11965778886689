import React from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import LoginContainer from '../../../containers/common/LoginContainer';
import AdminAuthenticationMessage from '../../common/AdminAuthenticationMessage';
import { isAuthenticatedAdmin } from '../../../utils/auth';
import DeleteConfirmationModal from '../../common/DeleteConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';
import history from '../../../routers/history';

export default class MarketoSetup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      marketoCompanyAccount: undefined,
      marketoCompanyAccountLoading: true,
      munchkinId: '',
      clientId: '',
      clientSecret: '',
      reenterClientSecret: false,
      errors: {},
      errorMessage: '',
      deleteConfirmationModalOpen: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchMarketoCompanyAccount();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.customerId !== this.props.user.customerId) {
      this.fetchMarketoCompanyAccount();
    }
  }

  fetchMarketoCompanyAccount = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({
        marketoCompanyAccountLoading: true,
        reenterClientSecret: false,
      }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/marketo-company-accounts?company_id=${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        let marketoCompanyAccount = undefined;
        let munchkinId = '';
        let clientId = '';
        let clientSecret = '';
        if (Array.isArray(response.data) && response.data.length > 0) {
          marketoCompanyAccount = response.data[0];
          munchkinId = marketoCompanyAccount.munchkin_id;
          clientId = marketoCompanyAccount.client_id;
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            marketoCompanyAccount,
            marketoCompanyAccountLoading: false,
            munchkinId,
            clientId,
            clientSecret,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch marketo company account');
        if (this.state.isMounted) {
          this.setState(() => ({
            marketoCompanyAccount: undefined,
            marketoCompanyAccountLoading: false,
            munchkinId: '',
            clientId: '',
            clientSecret: '',
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onMunchkinIdChange = (event) => {
    const munchkinId = event.currentTarget.value;
    this.setState(() => ({ munchkinId }));
  };

  onClientIdChange = (event) => {
    const clientId = event.currentTarget.value;
    this.setState(() => ({ clientId }));
  };

  onClientSecretChange = (event) => {
    const clientSecret = event.currentTarget.value;
    this.setState(() => ({ clientSecret }));
  };

  isValidForm = () => {
    const errors = {};
    let errorMessage = '';
    if (!this.state.munchkinId || this.state.munchkinId.trim() === '') {
      errors.munchkinId = true;
    }
    if (!this.state.clientId || this.state.clientId.trim() === '') {
      errors.clientId = true;
    }
    if (
      !this.state.marketoCompanyAccount ||
      (this.state.marketoCompanyAccount && this.state.reenterClientSecret)
    ) {
      if (!this.state.clientSecret || this.state.clientSecret.trim() === '') {
        errors.clientSecret = true;
      }
    }

    this.setState(() => ({
      errors,
      errorMessage,
    }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  saveMarketoCompanyAccount = () => {
    if (this.isValidForm()) {
      const marketoCompanyAccount = {
        company_id: this.props.user.customerId,
        munchkin_id: this.state.munchkinId,
        client_id: this.state.clientId,
      };

      if (this.state.marketoCompanyAccount) {
        // Update
        if (this.state.reenterClientSecret) {
          marketoCompanyAccount.client_secret = this.state.clientSecret;
        }

        axios.put(
          `${LISTEN_ENDPOINT}/api/marketo-company-accounts/${this.state.marketoCompanyAccount.id}`,
          marketoCompanyAccount,
          HEADERS
        ).then(response => {
          this.fetchMarketoCompanyAccount();
        }).catch(error => {
          console.error('Error: failed to update marketo company account');
          this.setState(() => ({
            errorMessage: 'Unable to update account at this time. Please try again later.'
          }));
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      } else {
        // Create
        marketoCompanyAccount.client_secret = this.state.clientSecret;

        axios.post(
          `${LISTEN_ENDPOINT}/api/marketo-company-accounts`,
          marketoCompanyAccount,
          HEADERS
        ).then(response => {
          this.fetchMarketoCompanyAccount();
        }).catch(error => {
          console.error('Error: failed to create marketo company account');
          this.setState(() => ({
            errorMessage: 'Unable to create account at this time. Please try again later.'
          }));
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  openDeleteConfirmationModal = () => {
    this.setState(() => ({ deleteConfirmationModalOpen: true }));
  };

  closeDeleteConfirmationModal = () => {
    this.setState(() => ({ deleteConfirmationModalOpen: false }));
  };

  deleteMarketoCompanyAccount = () => {
    if (this.state.marketoCompanyAccount) {
      axios.delete(
        `${LISTEN_ENDPOINT}/api/marketo-company-accounts/${this.state.marketoCompanyAccount.id}`,
        HEADERS
      ).then(response => {
        if (this.state.isMounted) {
          this.fetchMarketoCompanyAccount();
          this.closeDeleteConfirmationModal();
        }
      }).catch(error => {
        console.error('Error: failed to delete marketo company account');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="m-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        <AdminAuthenticationMessage user={this.props.user}/>
        { isAuthenticatedAdmin(this.props.user) &&
          <div>
            <div
              className="border rounded p-4"
              style={{ backgroundColor: '#e9ecef' }}
            >
              <div className="d-inline-block pr-2">
                <FontAwesomeIcon
                  className="fa-rotate-90"
                  icon={faSpotify}
                  size="2x"
                  color="#454d54"
                />
              </div>
              <div className="d-inline-block">
                <h4>Marketo</h4>
              </div>
              <p>Connect your Marketo instance.</p>
              <hr className="my-4" />
              <p className="mb-0">
                Gain email campaign and landing page insights by connecting Marketo in BrandOps.
              </p>
            </div>
            <div className="mt-4">
              <Row>
                <Col>
                  <div
                    className="pre-scrollable"
                    style={{
                      maxHeight: 'calc(100vh - 300px)',
                      overflow: 'auto',
                      overflowX: 'hidden'
                    }}
                  >
                    <Form>
                      <Form.Group>
                        <Form.Label>Munchkin ID</Form.Label>
                        <Form.Control
                          type="text"
                          value={this.state.munchkinId}
                          onChange={this.onMunchkinIdChange}
                          isInvalid={this.state.errors.munchkinId}
                          style={{ maxWidth: '250px' }}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Client ID</Form.Label>
                        <Form.Control
                          type="text"
                          value={this.state.clientId}
                          onChange={this.onClientIdChange}
                          isInvalid={this.state.errors.clientId}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Client Secret</Form.Label>
                        { (!this.state.marketoCompanyAccount || this.state.reenterClientSecret) &&
                          <Form.Control
                            type="text"
                            value={this.state.clientSecret}
                            onChange={this.onClientSecretChange}
                            isInvalid={this.state.errors.clientSecret}
                          />
                        }
                        { (this.state.marketoCompanyAccount && !this.state.reenterClientSecret) &&
                          <div>
                            <div className="d-inline-block">
                              ***************
                            </div>
                            <div className="d-inline-block ml-3">
                              <Button
                                variant="warning"
                                onClick={() => {
                                  this.setState(() => ({
                                    reenterClientSecret: true,
                                    clientSecret: '',
                                  }))
                                }}
                                size="sm"
                              >
                                Re-enter Client Secret
                              </Button>
                            </div>
                          </div>
                        }
                      </Form.Group>
                      <Button
                        variant="primary"
                        onClick={this.saveMarketoCompanyAccount}
                        disabled={!this.props.user.customerId}
                      >
                        Save
                      </Button>
                      { this.state.marketoCompanyAccount &&
                        <Button
                          className="ml-4"
                          variant="danger"
                          onClick={this.openDeleteConfirmationModal}
                        >
                          Delete
                        </Button>
                      }
                    </Form>
                  </div>
                </Col>
                <Col>
                  <Card>
                    <Card.Header className="bg-bops-blue text-light font-weight-bold">
                      Setup
                    </Card.Header>
                    <Card.Body
                      className="pre-scrollable"
                      style={{
                        maxHeight: 'calc(100vh - 350px)',
                        overflow: 'auto'
                      }}
                    >
                      <div>
                        <h5>Getting Started</h5>
                        <ul>
                          <li>
                            <a href="https://developers.marketo.com/rest-api/" target="_blank">
                              Learning about Marketo API
                            </a>
                          </li>
                          <li>
                            <a href="https://developers.marketo.com/blog/quick-start-guide-for-marketo-rest-api/" target="_blank">
                              API Enablement & Access Quickstart
                            </a>
                          </li>
                        </ul>
                        <h5>Quickstart</h5>
                        <p>
                          Follow these steps <a href="https://developers.marketo.com/blog/quick-start-guide-for-marketo-rest-api/" target="_blank">here</a> for a more detailed walkthrough
                        </p>
                        <ol>
                          <li>Setup API Access</li>
                          <li>
                            Provide Munchkin ID from Admin
                            <FontAwesomeIcon className="mx-1" icon={faArrowRight}/>
                            Integration
                            <FontAwesomeIcon className="mx-1" icon={faArrowRight}/>
                            Munchkin
                          </li>
                          <li>
                            Provide Client ID & Client Secret from Admin
                            <FontAwesomeIcon className="mx-1" icon={faArrowRight}/>
                            LaunchPoint
                            <FontAwesomeIcon className="mx-1" icon={faArrowRight}/>
                            View Details
                          </li>
                        </ol>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>

            <DeleteConfirmationModal
              isOpen={this.state.deleteConfirmationModalOpen}
              handleClose={this.closeDeleteConfirmationModal}
              deleteMessage="Are you sure you would like to delete the current Marketo setup?"
              deleteResource={this.deleteMarketoCompanyAccount}
            />
          </div>
        }
      </div>
    );
  }
};
