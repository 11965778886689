import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClipLoader from 'react-spinners/ClipLoader';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class WebHeaders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      webHeaders: [],
      actionVerbs: [],
      descriptives: [],
      beneficiaries: [],
      webHeadersLoading: false,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchWebHeaderData();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.brandId !== this.props.brandId) {
      this.fetchWebHeaderData();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchWebHeaderData = () => {
    if (this.props.type && this.props.brand) {
      this.setState(() => ({ webHeadersLoading: true }));

      axios.get(
        `${LISTEN_ENDPOINT}/api/web-image-scrape-messages?product_brand_id=${this.props.brand.id}${this.props.type==='inactive'?'&type=expired':''}`,
        HEADERS
      ).then(response => {
        let webHeaders = response.data;
        webHeaders = webHeaders.slice(0, 10);
        for (const wh of webHeaders) {
          try {
            wh.path = new URL(wh.url).pathname;
          } catch (e) {
            wh.path = '';
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            webHeaders,
            webHeadersLoading: false
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch ${this.props.type} web headers data...`);
        if (this.state.isMounted) {
          this.setState(() => ({
            webHeaders: [],
            webHeadersLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div
        className="pre-scrollable"
        style={{
          minHeight: 'calc(100vh - 400px)',
          maxHeight: 'calc(100vh - 400px)',
          overflow: 'auto',
          overflowX: 'hidden'
        }}
      >
        { this.state.webHeadersLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.webHeadersLoading &&
          <div>
            <div
              className="pre-scrollable bg-white border"
              style={{
                minHeight: '200px',
                maxHeight: '200px',
                overflow: 'auto',
                overflowX: 'hidden',
                fontSize: '.875rem'
              }}
            >
              { this.state.webHeaders.map((wh, i) => {
                  return (
                    <Row key={`m-${this.props.type}-${i}`}>
                      <Col xs={8}>
                        <div className="ml-2">
                          {wh.text}
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div>
                          {wh.path}
                        </div>
                      </Col>
                    </Row>
                  )
                })
              }
            </div>
          </div>
        }
      </div>
    );
  }
};
