import axios from 'axios';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { reportError } from '../api/errors';

export const fetchCompanySetupChecklist = (companyId) => {
  return (dispatch) => {
    if (companyId)
    axios.get(
      `${LISTEN_ENDPOINT}/api/customer-setup-checklist?customer_id=${companyId}`,
      HEADERS
    ).then(response => {
      const companySetupChecklist = response.data;
      dispatch(addCompanySetupChecklist(companySetupChecklist));
    }).catch(error => {
      dispatch(addCompanySetupChecklist({}));
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatch(reportError(error.response));
      }
    });
  };
};

export const addCompanySetupChecklist = (companySetupChecklist) => ({
  type: 'ADD_COMPANY_SETUP_CHECKLIST',
  companySetupChecklist
});
