import { connect } from 'react-redux';
import Header from '../../components/common/Header';
import {
  addUser,
  userLogout,
  handleUpdateRepresentedCustomer,
  updateShowRolePersonalizationSetup,
  updateShowNewUserGuidedTour,
} from '../../actions/user/user';

const mapStateToProps = (state) => ({
  user: state.user,
  alerts: state.alerts,
  categoryHeaderLabel: state.categoryHeaderLabel,
  brandHeaderLabel: state.brandHeaderLabel,
  showRolePersonalizationSetup: state.showRolePersonalizationSetup,
});

const mapDispatchToProps = (dispatch) => ({
  handleUserCookie: (user) => dispatch(addUser(user)),
  handleUpdateRepresentedCustomer: (user, customerId, companyName) => dispatch(handleUpdateRepresentedCustomer(user, customerId, companyName)),
  handleUserLogout: (user) => dispatch(userLogout(user)),
  updateShowRolePersonalizationSetup: (showRolePersonalizationSetup) => dispatch(updateShowRolePersonalizationSetup(showRolePersonalizationSetup)),
  updateShowNewUserGuidedTour: (showNewUserGuidedTour) => dispatch(updateShowNewUserGuidedTour(showNewUserGuidedTour)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
