import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../../common/CardComponent';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class SentimentIntensity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      promoterIntensity: 0.0,
      destractorIntensity: 0.0,
      sentimentIntensityLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.sentimentIntensity();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.topicId !== this.props.topicId ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter
    ) {
      this.sentimentIntensity();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  sentimentIntensity = () => {
    this.setState(() => ({ sentimentIntensityLoading: true }));
    const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
    axios.get(
      `${LISTEN_ENDPOINT}/api/comment-intensity/topic-id/${this.props.topicId}?start-date=${formattedStartDate}&end-date=${formattedEndDate}`,
      HEADERS
    ).then(response => {
      const intensityScores = response.data;
      if (this.state.isMounted) {
        this.setState(() => ({
          promoterIntensity: intensityScores.promoter_intensity.toFixed(1),
          detractorIntensity: intensityScores.detractor_intensity.toFixed(1),
          sentimentIntensityLoading: false
        }));
      }
    }).catch(error => {
      console.error('Error loading sentiment intensity data...');
      if (this.state.isMounted) {
        this.setState(() => ({
          promoterIntensity: 0.0,
          detractorIntensity: 0.0,
          sentimentIntensityLoading: false
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  toggleOptions = () => {
    this.setState((prevState) => ({ showConfig: !prevState.showConfig }));
  };

  render () {
    return (
      <CardComponent
        title="Comment Intensity"
        icon={
          <img
            className="mr-2 align-top"
            src="/images/Sentiment_Intensity_Icon.png"
          />
        }
        description="Comment Intensity gives you a glimpse at strong sentiment in relation to total comments"
        body={
          <div>
            { this.state.sentimentIntensityLoading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.sentimentIntensityLoading &&
              <Card className="p-2 bg-light">
                <Card.Body className="py-2">
                  <Row className="text-center">
                    <Col>
                      <div>
                        <div style={{ fontSize: '2rem' }}>
                          <b>{`${this.state.promoterIntensity}%`}</b>
                        </div>
                        <div>
                          Strongly Approve
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <div style={{ fontSize: '2rem' }}>
                          <b>{`${this.state.detractorIntensity}%`}</b>
                        </div>
                        <div>
                          Strongly Disapprove
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            }
          </div>
        }
        contextType="brand"
        contextCategory={this.props.category}
        contextBrand={this.props.brand}
        contextChannel="Social"
        contextChartName="Comment Intensity"
        contextDateRange={`${this.props.startDateFilter.format('MM/DD/YYYY')} - ${this.props.endDateFilter.format('MM/DD/YYYY')}`}
      />
    );
  }
};
