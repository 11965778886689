import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ShareOfChart from '../../graphs/ShareOfChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSearchDollar } from '@fortawesome/free-solid-svg-icons';
import { round } from '../../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class CategoryShareOfSearchResults extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organicShareOfGraphData: {},
      categoryGoogleOrganicShareOfData: [],
      categoryGoogleOrganicShareOfDataLoading: false,
      paidShareOfGraphData: {},
      categoryGooglePaidShareOfData: [],
      categoryGooglePaidShareOfDataLoading: false,
      keywords: [],
      chartDescription: undefined,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getCategorySearchShareOfData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.getCategorySearchShareOfData();
    }
  }

  getCategorySearchShareOfData = () => {
    if (this.props.category) {
      this.setState(() => ({
        categoryGoogleOrganicShareOfDataLoading: true,
        categoryGooglePaidShareOfDataLoading: true,
        chartDescription: undefined,
      }));
      // get share of google organic
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-share-of-serp?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=google-organic`,
        HEADERS
      ).then(response => {
        const organicShareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const categoryGoogleOrganicShareOfData = [];
        for (const monthStats of response.data) {
          const organicMonthData = { month: monthStats.month };
          let monthTotalSerpPoints = 0;

          // get totals for percent
          for (const pb of monthStats.product_brands) {
            monthTotalSerpPoints += pb.serp_points;
            // insert graph data
            organicShareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.serp_points,
            ]);
          }

          // loop again to determine %
          for (const pb of monthStats.product_brands) {
            let organicPercent = 0;
            if (monthTotalSerpPoints > 0) {
              organicPercent = round((pb.serp_points/monthTotalSerpPoints)*100, 1);
            }
            organicMonthData[pb.product_brand_name] = {
              count: pb.serp_points,
              percent: organicPercent
            }
          }
          categoryGoogleOrganicShareOfData.push(organicMonthData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            organicShareOfGraphData,
            categoryGoogleOrganicShareOfData,
            categoryGoogleOrganicShareOfDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch category share of google organic results...');
        if (this.state.isMounted) {
          this.setState(() => ({
            organicShareOfGraphData: {},
            categoryGoogleOrganicShareOfData: [],
            categoryGoogleOrganicShareOfDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      // get share of google paid
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-share-of-serp?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=google-paid`,
        HEADERS
      ).then(response => {
        const paidShareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const categoryGooglePaidShareOfData = [];
        for (const monthStats of response.data) {
          const paidMonthData = { month: monthStats.month };
          let monthTotalSerpPoints = 0;

          // get totals for percent
          for (const pb of monthStats.product_brands) {
            monthTotalSerpPoints += pb.serp_points;
            // insert graph data
            paidShareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.serp_points,
            ]);
          }

          // loop again to determine %
          for (const pb of monthStats.product_brands) {
            let paidPercent = 0;
            if (monthTotalSerpPoints > 0) {
              paidPercent = round((pb.serp_points/monthTotalSerpPoints)*100, 1);
            }
            paidMonthData[pb.product_brand_name] = {
              count: pb.serp_points,
              percent: paidPercent
            }
          }
          categoryGooglePaidShareOfData.push(paidMonthData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            paidShareOfGraphData,
            categoryGooglePaidShareOfData,
            categoryGooglePaidShareOfDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch category share of google paid results...');
        if (this.state.isMounted) {
          this.setState(() => ({
            paidShareOfGraphData: {},
            categoryGooglePaidShareOfData: [],
            categoryGooglePaidShareOfDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      // get search terms count
      axios.get(
        `${LISTEN_ENDPOINT}/api/search_query/customer?linked_category_id=${this.props.category.id}&linked_category_type=${this.props.category.category_type}`,
        HEADERS
      ).then(response => {
        const keywords = response.data;
        const chartDescription = `Based on ${keywords.length} priority keywords for this category.`;
        if (this.state.isMounted)
          this.setState(() => ({
            keywords,
            chartDescription,
          }));
      }).catch(error => {
        console.error('Error: unable to fetch keywords');
        if (this.state.isMounted) {
          this.setState(() => ({
            keywords: [],
            chartDescription: undefined
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  }

  render () {
    return (
      <div>
        <ShareOfChart
          title="Share of Google Organic Results"
          icon={
            <FontAwesomeIcon
              className="mr-2"
              icon={faSearch}
              color="#4fa2f3"
            />
          }
          description="The share of URLs for the brand discovered on the first page of organic Google results for monitored keywords."
          chartDescription={this.state.chartDescription}
          data={this.state.organicShareOfGraphData}
          legendData={this.state.categoryGoogleOrganicShareOfData}
          loading={this.state.categoryGoogleOrganicShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Search"
          contextChartName="Share of Google Organic Results"
          shareClassName="category-share-of-search-results-organic-img"
        />
        <ShareOfChart
          title="Share of Google Ad Impressions"
          icon={
            <FontAwesomeIcon
              className="mr-2"
              icon={faSearchDollar}
              color="#4fa2f3"
            />
          }
          description="The share of ad impressions for the brand discovered on the first page of Google results for monitored keywords."
          chartDescription={this.state.chartDescription}
          data={this.state.paidShareOfGraphData}
          legendData={this.state.categoryGooglePaidShareOfData}
          loading={this.state.categoryGooglePaidShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Search"
          contextChartName="Share of Google Ad Impressions"
          shareClassName="category-share-of-search-results-paid-img"
        />
      </div>
    );
  }
};
