import React from 'react';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import Card from 'react-bootstrap/Card';
import Popup from 'reactjs-popup';
import DescriptionTooltip from '../../common/Tooltip';
import SharePopUpContainer from '../../../containers/share/SharePopUpContainer';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  LabelList,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';
import { intToShorthandString, numberWithCommas } from '../../../utils/numbers';
import { shorthandMonth } from '../../../utils/dates';
import { colors } from '../../../utils/graphs';

export default class OutboundEmailsStackedGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: `hs-sg-${Math.random().toString(36).substring(7)}`
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {

  }

  render () {
    return (
      <Card id={this.state.id} className="h-100">
        <Card.Header className="bg-white">
          { this.props.icon &&
            <React.Fragment>
              {this.props.icon}
            </React.Fragment>
          }
          { !this.props.icon &&
            <img
              className="mt-1 mr-2 align-top"
              src="/images/Reviews_Detail_Icon.png"
            />
          }
          { this.props.description &&
            <DescriptionTooltip
              tooltipPosition="top"
              tooltipTrigger={
                <b style={{ fontSize: '1.2rem'}}>
                  {this.props.title}
                </b>
              }
              tooltip={this.props.description}
            />
          }
          { !this.props.description &&
            <b style={{ fontSize: '1.2rem'}}>
              {this.props.title}
            </b>
          }
          <div className="d-inline-block float-right">
            <SharePopUpContainer
              shareElementId={this.state.id}
              elementLabel={`${this.state.id}-img`}
              position="top"
              contextType="brand"
              contextCategory={this.props.category}
              contextBrand={this.props.brand}
              contextChannel="Emails"
              contextChartName={this.props.title}
            />
          </div>
        </Card.Header>
        <Card.Body>
          <div>
            { this.props.loading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.props.loading &&
              <ResponsiveContainer width="99%" height={300}>
                <BarChart
                  data={this.props.data}
                  barSize={50}
                  margin={{
                    top: 16,
                    left: 10
                  }}
                  onClick={this.props.onMonthSelect}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="month"
                    tickFormatter={(month) => {
                      return shorthandMonth(month);
                    }}
                  />
                  <YAxis
                    dataKey={this.props.dataKey}
                    tick={{ fontSize: 12 }}
                    tickFormatter={(value) => {
                      if (this.props.percentValues) {
                        return `${numberWithCommas(value)}%`
                      } else {
                        if (this.props.yAxisShorthandInt) {
                          return intToShorthandString(value);
                        } else {
                          return numberWithCommas(value);
                        }
                      }
                    }}
                    domain={[
                      this.props.minDomainValue !== undefined ? this.props.minDomainValue : 'auto',
                      this.props.maxDomainValue !== undefined ? this.props.maxDomainValue : 'auto'
                    ]}
                    allowDecimals={
                      this.props.allowDecimals !== undefined ?
                        this.props.allowDecimals :
                        true
                    }
                  />
                  <Tooltip
                    formatter={(value) => numberWithCommas(value)}
                    cursor={{ fill: 'transparent' }}
                    wrapperStyle={{ zIndex: 1005 }}
                  />
                  { this.props.barsData.map((barData, i) => {
                      return (
                        <Bar
                          key={`${this.state.id}-b-${i}`}
                          name={barData.name}
                          dataKey={barData.name}
                          stackId="a"
                          fill={barData.color || colors[i % colors.length]}
                        />
                      )
                    })
                  }
                </BarChart>
              </ResponsiveContainer>
            }
          </div>
        </Card.Body>
      </Card>
    );
  }
};
