import React from 'react';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { numberWithCommas, round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';


export default class AudiencePhrases extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phraseData: [],
      phraseDisplayData: [],
      phraseDataLoading: false
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchPhraseData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.brand !== this.props.brand) {
      this.fetchPhraseData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchPhraseData = () => {
    if (this.props.brand) {
      this.setState(() => ({ phraseDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/audience-proxy?product_brand_id=${this.props.brand.id}&type=phrases`,
        HEADERS
      ).then(response => {
        const phraseData = [];
        if (
          response.data &&
          response.data.data
        ) {
          for (const phrase of response.data.data) {
            phrase.share = round(phrase.share, 1);
            phraseData.push(phrase);
          }
        }
        // only show top 10 phrases
        const phraseDisplayData = phraseData.slice(0, 10);

        if (this.state.isMounted) {
          this.setState(() => ({
            phraseData,
            phraseDisplayData,
            phraseDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch audience phrase data.');
        if (this.state.isMounted) {
          this.setState(() => ({
            phraseData: [],
            phraseDisplayData: [],
            phraseDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  render () {
    return (
      <Card>
        <Card.Header className="bg-white font-weight-bold">
          <FontAwesomeIcon
            className="mr-2"
            icon={faQuoteRight}
            color="#4fa2f3"
            style={{ fontSize: '1.2rem' }}
          />
          Frequently Used Phrases
        </Card.Header>
        <Card.Body>
          { this.state.phraseDataLoading &&
            <div className="m-4 text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !this.state.phraseDataLoading &&
            <div>
              <ResponsiveContainer height={300} width="99%">
                <BarChart
                  data={this.state.phraseDisplayData}
                  layout="vertical"
                >
                  <XAxis
                    type="number"
                    tickFormatter={(share) => {
                      return `${numberWithCommas(share)}%`;
                    }}
                  />
                  <YAxis
                    type="category"
                    dataKey="keyword"
                    width={175}
                    interval={0}
                  />
                  <Tooltip
                    cursor={false}
                    formatter={(value) => `${numberWithCommas(value)}%`}
                  />
                  <Bar name="Share" dataKey="share" fill="#ffb84d"/>
                </BarChart>
              </ResponsiveContainer>
            </div>
          }
        </Card.Body>
      </Card>
    );
  }
};
