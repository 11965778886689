import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history'
import Container from 'react-bootstrap/Container';
import SplitPane from 'react-split-pane';
import ErrorBoundaryContainer from '../containers/common/ErrorBoundaryContainer';
import LeftMenuContainer from '../containers/common/LeftMenuContainer';
import HeaderContainer from '../containers/common/HeaderContainer';
import HomeContainer from '../containers/common/HomeContainer';
import CategoriesContainer from '../containers/categories/CategoriesContainer';
import CategoryDashboardContainer from '../containers/categories/CategoryDashboardContainer';
import BrandTabsContainer from '../containers/brands/BrandTabsContainer';
import LeaderboardContainer from '../containers/objectives/LeaderboardContainer';
import ObjectivesContainer from '../containers/objectives/ObjectivesContainer';
import GoogleAdsContainer from '../containers/privateStack/GoogleAdsContainer';
import GoogleAnalyticsContainer from '../containers/privateStack/GoogleAnalyticsContainer';
import WebAnalyticsSearchContainer from '../containers/privateStack/WebAnalyticsSearchContainer';
import GoogleSearchConsoleContainer from '../containers/privateStack/GoogleSearchConsoleContainer';
import HubspotContainer from '../containers/privateStack/HubspotContainer';
import MarketoEmailCampaignsContainer from '../containers/privateStack/MarketoEmailCampaignsContainer';
import MarketoLandingPagesContainer from '../containers/privateStack/MarketoLandingPagesContainer';
import CategoryDetectedTechnologyContainer from '../containers/privateStack/CategoryDetectedTechnologyContainer';
import CustomReportsContainer from '../containers/customReports/CustomReportsContainer';
import CustomReportPublic from '../components/customReports/CustomReportPublic';
import CustomReportPublicScroll from '../components/customReports/CustomReportPublicScroll';
import AlertsContainer from '../containers/alerts/AlertsContainer';
import AlertRouterContainer from '../containers/alerts/AlertRouterContainer';
import LoginContainer from '../containers/common/LoginContainer';
import SSOLoginContainer from '../containers/sso/SSOLoginContainer'
import SSOLoginHandlerContainer from '../containers/sso/SSOLoginHandlerContainer';
import UsersContainer from '../containers/settings/UsersContainer';
import ChangeEmailContainer from '../containers/settings/ChangeEmailContainer';
import ChangePasswordContainer from '../containers/settings/ChangePasswordContainer';
import CompanyInformationContainer from '../containers/settings/CompanyInformationContainer';
import UpdatePassword from '../components/settings/UpdatePassword';
import ForgotPasswordContainer from '../containers/common/ForgotPasswordContainer';
import ObjectivesAndGoalsContainer from '../containers/settings/ObjectivesAndGoalsContainer';
import ExportContainer from '../containers/settings/ExportContainer';
import SettingsMenuContainer from '../containers/settings/SettingsMenuContainer';
import CategorySetupChecklistContainer from '../containers/settings/contentCollectors/CategorySetupChecklistContainer';
import EmailSubscriptionsContainer from '../containers/settings/contentCollectors/EmailSubscriptionsContainer';
import NewsCollectorContainer from '../containers/settings/contentCollectors/NewsCollectorContainer';
import SurveysCollectorContainer from '../containers/settings/contentCollectors/SurveysCollectorContainer';
import MailChimpSetupContainer from '../containers/settings/contentCollectors/MailChimpSetupContainer';
import ConstantContactSetupContainer from '../containers/settings/contentCollectors/ConstantContactSetupContainer';
import GoogleAdsSetupContainer from '../containers/settings/contentCollectors/GoogleAdsSetupContainer';
import WebAnalyticsCollectorContainer from '../containers/settings/contentCollectors/WebAnalyticsCollectorContainer';
import GoogleSearchConsoleSetupContainer from '../containers/settings/contentCollectors/GoogleSearchConsoleSetupContainer';
import MarketoSetupContainer from '../containers/settings/contentCollectors/MarketoSetupContainer';
import HubspotSetupContainer from '../containers/settings/contentCollectors/HubspotSetupContainer';
import SemrushSetupContainer from '../containers/settings/contentCollectors/SemrushSetupContainer';
import SlackSetupContainer from '../containers/settings/contentCollectors/SlackSetupContainer';
import SingleSignOnSetupContainer from '../containers/settings/contentCollectors/SingleSignOnSetupContainer';
import SearchEngineTermsContainer from '../containers/searchEngines/SearchEngineTermsContainer';
import QuickSearchContainer from '../containers/quickSearch/QuickSearchContainer';
import SaasIndexContainer from '../containers/saasIndex/SaasIndexContainer';
import Survey from '../components/surveys/Survey';
import OAuthHandlerContainer from '../containers/oauth/OAuthHandlerContainer';
import SSOSamlSettingsWrapper from '../components/sso/SSOSamlSettingsWrapper';
import Feedback from '../components/common/Feedback';
import Support from '../components/common/Support';
import NotFound from '../components/common/NotFound';


export default class AppRouter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      leftMenuSize: 250
    };
  }

  maintainHashToBrand = (categoryName, brandName) => {
    let lastRef = window.location.href;
    let index = lastRef.indexOf("#");
    if (lastRef.includes(`/category-dashboard/`) && index !== -1) {
      // switching from category to brand
      index+=1;
      let hash = lastRef.substring(index);
      if (hash === 'events') {
        // no associated brand tab available
        hash = '';
      }
      // grab selected subtab if it exists
      let subIndex = hash.indexOf('-');
      if (subIndex !== -1) {
        const unavailableSubTabs = [
          'ranks',
          'races',
          'shareOf',
          'esoe',
          'topspot',
          'competitors',
          'brandPower',
          'search',
        ];
        subIndex+=1;
        let subtab = hash.substring(subIndex);
        if (unavailableSubTabs.includes(subtab)) {
          // remove subtab unavailable in category
          hash = hash.indexOf("-")>0 ? hash.substring(0, hash.indexOf("-")) : hash;
        }
      }
      history.push(`/category/${encodeURIComponent(categoryName)}/brand/${encodeURIComponent(brandName)}#${hash}`);
    } else if (lastRef.includes(`/brand/`) && index !== -1) {
      // switching from brand to brand
      index+=1;
      let hash = lastRef.substring(index);
      history.push(`/category/${encodeURIComponent(categoryName)}/brand/${encodeURIComponent(brandName)}#${hash}`);
    } else {
      history.push(`/category/${encodeURIComponent(categoryName)}/brand/${encodeURIComponent(brandName)}`);
    }
  };

  maintainHashToCategory = (categoryName) => {
    let lastRef = window.location.href;
    let index = lastRef.indexOf("#");
    // switching from brand to category
    if (lastRef.includes(`/category/`) && index !== -1) {
      index+=1;
      let hash = lastRef.substring(index);
      if (
        hash === 'audience' ||
        hash === 'surveys' ||
        hash.includes('brandscience')
      ) {
        // no associated category tab available
        hash = '';
      } else {
        // grab selected subtab if it exists
        let subIndex = hash.indexOf('-');
        if (subIndex !== -1) {
          const unavailableSubTabs = [
            'webCapture',
            'pillars',
            'pillars-brandScore',
            'pillars-brandPresence',
            'pillars-brandAttention',
            'pillars-brandReputation',
            'pillars-brandMomentum',
            'fonts',
            'keyPhrases',
            'webHeaders',
            'calendar',
          ];
          subIndex+=1;
          let subtab = hash.substring(subIndex);
          if (unavailableSubTabs.includes(subtab)) {
            // remove subtab unavailable in category
            hash = hash.indexOf("-")>0 ? hash.substring(0, hash.indexOf("-")) : hash;
          }
        }
      }
      history.push(`/category-dashboard/${categoryName}#${hash}`);
    } else if (lastRef.includes(`/category-dashboard/`) && index !== -1) {
      // switching from category to category
      index+=1;
      let hash = lastRef.substring(index);
      history.push(`/category-dashboard/${encodeURIComponent(categoryName)}#${hash}`);
    } else {
      history.push(`/category-dashboard/${encodeURIComponent(categoryName)}`);
    }
  };

  changeLeftMenuSize = (leftMenuSize) => {
    this.setState(() => ({ leftMenuSize }));
  };

  render () {
    return (
      <Router history={history}>
        <Container fluid className="px-0">
          <SplitPane
            split="vertical"
            minSize={16}
            maxSize={400}
            defaultSize={250}
            size={this.state.leftMenuSize}
            allowResize={true}
            onChange={(leftMenuSize) => this.setState(() => ({ leftMenuSize }))}
          >
            <LeftMenuContainer
              maintainHashToBrand={this.maintainHashToBrand}
              maintainHashToCategory={this.maintainHashToCategory}
              changeLeftMenuSize={this.changeLeftMenuSize}
            />
            <div>
              <HeaderContainer
                maintainHashToBrand={this.maintainHashToBrand}
                maintainHashToCategory={this.maintainHashToCategory}
              />
              <ErrorBoundaryContainer>
                <Switch>
                  <Route exact path='/' component={HomeContainer} />
                  <Route path='/home' component={HomeContainer} />

                  <Route exact path='/category-dashboard' component={CategoryDashboardContainer} />
                  <Route path='/category-dashboard/:categoryName' component={CategoryDashboardContainer} />
                  <Route exact path='/category/:categoryName/brand/:brandName' component={BrandTabsContainer} />
                  <Route exact path='/leaderboard' component={LeaderboardContainer} />
                  <Route exact path='/objectives' component={ObjectivesContainer} />
                  <Route path='/private-stack/google-ads' component={GoogleAdsContainer} />
                  <Route path='/private-stack/google-analytics' component={GoogleAnalyticsContainer} />
                  <Route path='/private-stack/google-search-terms' component={WebAnalyticsSearchContainer} />
                  <Route path='/private-stack/google-search-console' component={GoogleSearchConsoleContainer} />
                  <Route path='/private-stack/hubspot' component={HubspotContainer} />
                  <Route path='/private-stack/marketo-email-campaigns' component={MarketoEmailCampaignsContainer} />
                  <Route path='/private-stack/marketo-landing-pages' component={MarketoLandingPagesContainer} />
                  <Route path='/private-stack/category-detected-technology' component={CategoryDetectedTechnologyContainer} />
                  <Route exact path='/reports/:reportName' component={CustomReportsContainer} />
                  <Route exact path='/reports/public/:publishToken' component={CustomReportPublic} />
                  <Route exact path='/reports/public/scrollable/:publishToken' component={CustomReportPublicScroll} />
                  <Route path='/alert/:productBrandName' component={AlertRouterContainer} />
                  <Route path='/login' component={LoginContainer} />
                  <Route path='/sso/login' component={SSOLoginContainer} />
                  <Route path='/sso-login' component={SSOLoginHandlerContainer} />
                  <Route exact path='/settings' component={SettingsMenuContainer} />
                  <Route exact path='/user-settings/alerts' component={AlertsContainer} />
                  <Route path='/user-settings/change-email' component={ChangeEmailContainer} />
                  <Route path='/user-settings/change-password' component={ChangePasswordContainer} />
                  <Route path='/invite/:customerUserId' component={UpdatePassword} />
                  <Route path='/password-reset/:customerUserId' component={UpdatePassword} />
                  <Route path='/password-help' component={ForgotPasswordContainer} />
                  <Route path='/company-settings/users' component={UsersContainer} />
                  <Route path='/company-settings/company-information' component={CompanyInformationContainer} />
                  <Route path='/company-settings/objectives-and-goals' component={ObjectivesAndGoalsContainer} />
                  <Route path='/company-settings/export' component={ExportContainer} />
                  <Route path='/content-collectors/category-setup' component={CategoriesContainer} />
                  <Route path='/content-collectors/category-checklist' component={CategorySetupChecklistContainer} />
                  <Route path='/content-collectors/email' component={EmailSubscriptionsContainer} />
                  <Route path='/content-collectors/news' component={NewsCollectorContainer} />
                  <Route path='/content-collectors/surveys' component={SurveysCollectorContainer} />
                  <Route path='/content-collectors/search-engine-terms' component={SearchEngineTermsContainer} />
                  <Route path='/content-collectors/mailchimp' component={MailChimpSetupContainer} />
                  <Route path='/content-collectors/constant-contact' component={ConstantContactSetupContainer} />
                  <Route path='/content-collectors/google-ads' component={GoogleAdsSetupContainer} />
                  <Route path='/content-collectors/web-analytics' component={WebAnalyticsCollectorContainer} />
                  <Route path='/content-collectors/google-search-console' component={GoogleSearchConsoleSetupContainer} />
                  <Route path='/content-collectors/marketo' component={MarketoSetupContainer} />
                  <Route path='/content-collectors/hubspot' component={HubspotSetupContainer} />
                  <Route path='/content-collectors/slack' component={SlackSetupContainer} />
                  <Route path='/content-collectors/semrush' component={SemrushSetupContainer} />
                  <Route path='/content-collectors/sso' component={SingleSignOnSetupContainer} />
                  <Route path='/sso-saml-settings' component={SSOSamlSettingsWrapper} />
                  <Route path='/quick-search' component={QuickSearchContainer} />
                  <Route path='/saas-index' component={SaasIndexContainer} />
                  <Route path='/survey' component={Survey} />
                  <Route path='/oauth/:oauthType' component={OAuthHandlerContainer} />
                  <Route path="/feedback" component={Feedback} />
                  <Route path='/support' component={Support} />
                  <Route component={NotFound} />
                </Switch>
              </ErrorBoundaryContainer>
            </div>
          </SplitPane>
        </Container>
      </Router>
    );
  }
};
