import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import AudienceGeography from '../../audience/AudienceGeography';
import AudiencePhrases from '../../audience/AudiencePhrases';
import AudienceWebsites from '../../audience/AudienceWebsites';
import AudiencePress from '../../audience/AudiencePress';
import AudienceYoutube from '../../audience/AudienceYoutube';
import AudiencePodcasts from '../../audience/AudiencePodcasts';

export default class AudienceTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  render () {
    return (
      <div
        className="p-4 pre-scrollable"
        style={{
          minHeight: 'calc(100vh - 100px)',
          maxHeight: 'calc(100vh - 100px)'
        }}
      >
        <Row className="mb-4">
          <Col style={{ flex: '0 0 400px' }}>
            <Card className="h-100">
              <Card.Body className="align-middle">
                Audience Insights Powered By
                <img
                  className="ml-2"
                  src="https://listen-company-logos.s3.amazonaws.com/sparktoro-logo.jpeg"
                  alt=""
                  crossOrigin="anonymous"
                  style={{ width: '100px' }}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="h-100">
              <Card.Body style={{ padding: '15px' }}>
                <Row noGutters>
                  <Col style={{ flex: '0 0 60px' }}>
                    <Badge
                      className="mt-1"
                      variant="dark"
                      style={{ fontSize: '1.1rem' }}
                    >
                      Beta
                    </Badge>
                  </Col>
                  <Col>
                    <div style={{ fontSize: '.75rem' }}>
                      BrandOps is pleased to be working with SparkToro, our technology provider for Audience Intelligence. This feature is currently in beta - please let us know if you have issues or suggestions!
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <div className="mb-4">
              <AudienceGeography
                brand={this.props.brand}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-4">
              <AudiencePhrases
                brand={this.props.brand}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-4">
              <AudienceWebsites
                brand={this.props.brand}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-4">
              <AudiencePress
                brand={this.props.brand}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-4">
              <AudienceYoutube
                brand={this.props.brand}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-4">
              <AudiencePodcasts
                brand={this.props.brand}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};
