import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';
import LoginContainer from '../../containers/common/LoginContainer';
import AdminAuthenticationMessage from '../common/AdminAuthenticationMessage';
import { isAuthenticatedAdmin } from '../../utils/auth';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class Users extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      search: '',
      selectedUser: undefined,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      accountRole: 'user',
      position: '',
      errors: {},
      showToast: false,
      toastIcon: '',
      toastMessage: '',
      deleteUserConfirmationModalOpen: false,
      deleteUserId: undefined,
      deleteUserEmail: undefined
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchUsers();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.user && prevProps.user.customerId !== this.props.user.customerId) {
      this.setState(() => ({
        search: '',
        selectedUser: undefined,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        accountRole: 'user',
        position: '',
        errors: {},
        showToast: false,
        deleteUserConfirmationModalOpen: false,
        deleteUserId: undefined,
        deleteUserEmail: undefined
      }));
      this.fetchUsers();
    }
  }

  fetchUsers = (selectedUserEmail) => {
    if (this.props.user.customerId) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/customer-users/customer-id/${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        const users = response.data;
        if (this.state.isMounted) {
          if (selectedUserEmail) {
            let selectedUser;
            for (const u of users) {
              if (u.email === selectedUserEmail) {
                selectedUser = u;
              }
              this.setState(() => ({
                users,
                selectedUser
              }));
            }
          } else {
            this.setState(() => ({ users }));
          }
        }
      }).catch(error => {
        console.error('Error: unable to fetch users');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  addUser = () => {
    this.setState(() => ({
      selectedUser: {},
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      accountRole: 'user',
      position: ''
    }));
  };

  onUserSelect = (selectedUser) => {
    this.setState(() => ({
      selectedUser,
      firstName: selectedUser.first_name || '',
      lastName: selectedUser.last_name || '',
      email: selectedUser.email || '',
      phone: selectedUser.phone || '',
      accountRole: selectedUser.permission_level || 'user',
      position: selectedUser.position || ''
    }));
  };

  onSearchChange = (event) => {
    const search = event.currentTarget.value;
    this.setState(() => ({ search }));
  };

  onFirstNameChange = (event) => {
    const firstName = event.currentTarget.value;
    this.setState(() => ({ firstName }));
  };

  onLastNameChange = (event) => {
    const lastName = event.currentTarget.value;
    this.setState(() => ({ lastName }));
  };

  onEmailChange = (event) => {
    const email = event.currentTarget.value;
    this.setState(() => ({ email }));
  };

  onPhoneChange = (event) => {
    const phone = event.currentTarget.value;
    this.setState(() => ({ phone }));
  };

  onAccountRoleChange = (event) => {
    const accountRole = event.currentTarget.value;
    this.setState(() => ({ accountRole }));
  };

  onPositionChange = (event) => {
    const position = event.currentTarget.value;
    this.setState(() => ({ position }));
  };

  openDeleteUserConfirmationModal = () => {
    if (this.state.selectedUser) {
      this.setState(() => ({
        deleteUserConfirmationModalOpen: true,
        deleteUserId: this.state.selectedUser.id,
        deleteUserEmail: this.state.selectedUser.email
      }));
    }
  };

  closeDeleteUserConfirmationModal = () => {
    this.setState(() => ({
      deleteUserConfirmationModalOpen: false,
      deleteUserId: undefined,
      deleteUserEmail: undefined
    }));
  };

  closeUser = () => {
    this.setState(() => ({
      selectedUser: undefined,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      accountRole: 'user',
      position: ''
    }));
  };

  deleteUser = () => {
    if (this.state.deleteUserId) {
      axios.delete(
        `${LISTEN_ENDPOINT}/api/customer-users/${this.state.deleteUserId}`,
        HEADERS
      ).then(response => {
        if (this.state.isMounted) {
          this.setState(() => ({ selectedUser: undefined }));
        }
        this.fetchUsers();
        this.closeDeleteUserConfirmationModal();
      }).catch(error => {
        console.log('Error: unable to delete user');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  validForm = () => {
    // validate
    const errors = {};
    if (!this.state.firstName || this.state.firstName.trim() === '') {
      errors.firstName = true;
    }
    if (!this.state.lastName || this.state.lastName.trim() === '') {
      errors.lastName = true;
    }
    if (!this.state.email || this.state.email.trim() === '') {
      errors.email = true;
    }

    this.setState(() => ({ errors }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  onUserSave = () => {
    if (this.validForm()) {
      const updateUser = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        permission_level: this.state.accountRole
      };

      if (this.state.phone && this.state.phone.trim() !== '') {
        updateUser.phone = this.state.phone;
      }
      if (this.state.position && this.state.position.trim !== '') {
        updateUser.position = this.state.position;
      }

      axios.put(
        `${LISTEN_ENDPOINT}/api/customer-users/${this.state.selectedUser.id}`,
        updateUser,
        HEADERS
      ).then(response => {
        const user = response.data;
        this.fetchUsers(user.email);
      }).catch(error => {
        console.log('Error: unable to create update user');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onSendUserInvite = () => {
    if (this.validForm()) {
      this.setState(() => ({ newUserCreating: true }));
      let origin;
      if (window.location.origin) {
        origin = window.location.origin;
      } else if (window.location.protocol && window.location.host) {
        origin = `${window.location.protocol}//${window.location.host}`;
      } else {
        origin = 'https://app.brandops.io';
      }

      const newUser = {
        customer_id: this.props.user.customerId,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        permission_level: this.state.accountRole,
        invite_from: this.props.user.fullName || 'BrandOps Admin',
        origin
      };

      if (this.state.phone && this.state.phone.trim() !== '') {
        newUser.phone = this.state.phone;
      }
      if (this.state.position && this.state.position.trim !== '') {
        newUser.position = this.state.position;
      }

      axios.post(
        `${LISTEN_ENDPOINT}/api/customer-users/invite`,
        newUser,
        HEADERS
      ).then(response => {
        const user = response.data;
        this.setState(() => ({ newUserCreating: false }));
        this.fetchUsers(user.email);
      }).catch(error => {
        console.error('Error: unable to create new user');
        this.fetchUsers();
        if (error.response && error.response.status === 409) {
          this.setState(() => ({
            showToast: true,
            toastIcon: 'bad',
            toastMessage: 'The email address is already used with BrandOps',
            newUserCreating: false
          }));
        } else {
          this.setState(() => ({ newUserCreating: false }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onSendUserReinvite = (userId) => {
    if (userId) {
      this.setState(() => ({ [`reinviteUser${userId}`]: true }));
      let origin;
      if (window.location.origin) {
        origin = window.location.origin;
      } else if (window.location.protocol && window.location.host) {
        origin = `${window.location.protocol}//${window.location.host}`;
      } else {
        origin = 'https://app.brandops.io';
      }

      axios.post(
        `${LISTEN_ENDPOINT}/api/customer-users/reinvite`,
        {
          id: userId,
          invite_from: this.props.user.fullName || 'BrandOps Admin',
          origin,
        },
        HEADERS
      ).then(response => {
        this.setState(() => ({
          [`reinviteUser${userId}`]: false,
          showToast: true,
          toastIcon: 'good',
          toastMessage: 'Reinvite sent',
        }));
      }).catch(error => {
        console.error('Error: unable to send reinvite');
        this.setState(() => ({
          [`reinviteUser${userId}`]: false,
          showToast: true,
          toastIcon: 'bad',
          toastMessage: 'Failed to send reinvite',
        }));
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="p-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        <AdminAuthenticationMessage user={this.props.user}/>
        { isAuthenticatedAdmin(this.props.user) &&
          <Row style={{ minHeight: '85vh' }}>
            <Col xs={4} className="border-right">
              <div>
                <h2>
                  Users
                  { this.props.user.isAdmin &&
                    <Button
                      className="float-right"
                      variant="success"
                      onClick={this.addUser}
                    >
                      <FontAwesomeIcon className="mr-1" icon={faPlus}/>
                      Add User
                    </Button>
                  }
                </h2>
              </div>
              <div>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text className="bg-white">
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    className="border-left-0"
                    type="text"
                    placeholder="Search"
                    value={this.state.search}
                    onChange={this.onSearchChange}
                  />
                </InputGroup>
              </div>
              <div
                className="mt-2 pre-scrollable"
                style={{
                  minHeight: 'calc(100vh - 200px)',
                  maxHeight: 'calc(100vh - 200px)',
                  overflow: 'auto',
                  overflowX: 'hidden'
                }}
              >
                { this.state.users.map(user => {
                    const name = `${user.first_name} ${user.last_name}`;
                    if (
                      user.email.includes(this.state.search) ||
                      name.includes(this.state.search)
                    ) {
                      const userDetails = [];
                      if (!user.invite_accepted) {
                        userDetails.push('Pending Invite');
                      }
                      if (user.permission_level === 'admin') {
                        userDetails.push('Account Admin');
                      } else {
                        userDetails.push('Account User');
                      }
                      if (this.props.user.id === user.id) {
                        userDetails.push('Your Profile');
                      }

                      const style = { cursor: 'pointer' };
                      if (
                        this.state.selectedUser &&
                        this.state.selectedUser.id === user.id
                      ) {
                        style.backgroundColor = '#cccccc';
                      }

                      return (
                        <div
                          key={`user${user.id}`}
                          className="rounded p-1"
                          onClick={() => this.onUserSelect(user)}
                          style={style}
                        >
                          <Row noGutters>
                            <Col style={{ flex: '0 0 35px' }}>
                              <div
                                className="mt-1 mr-2 px-2 py-1 rounded-circle float-left text-light"
                                style={{ backgroundColor: '#17a2b8' }}
                              >
                                {`${user.first_name.charAt(0)}${user.last_name.charAt(0)}`}
                              </div>
                            </Col>
                            <Col>
                              <div>
                                <div>{name}</div>
                                <div style={{ fontSize: '.75rem' }}>
                                  {userDetails.join(', ')}
                                </div>
                              </div>
                            </Col>
                            { !user.invite_accepted &&
                              <Col style={{ flex: '0 0 75px' }}>
                                <Button
                                  className="mt-1"
                                  variant="warning"
                                  onClick={() => this.onSendUserReinvite(user.id)}
                                  size="sm"
                                  disabled={this.state[`reinviteUser${user.id}`]}
                                >
                                  Reinvite
                                </Button>
                              </Col>
                            }
                          </Row>
                        </div>
                      )
                    }
                  })
                }
              </div>
            </Col>
            <Col xs={8}>
              { !this.state.selectedUser &&
                <div>
                  Select a user to view details
                </div>
              }
              { this.state.selectedUser &&
                <div>
                  <h2>
                    {this.state.selectedUser.email}
                    { (this.props.user.isAdmin && this.state.selectedUser.id) &&
                      <Button
                        className="float-right"
                        variant="danger"
                        onClick={this.openDeleteUserConfirmationModal}
                      >
                        Remove User
                      </Button>
                    }
                  </h2>

                  <Form>
                    <Form.Group>
                      <Form.Row className="mt-4">
                        <Col>
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="First name"
                            value={this.state.firstName}
                            onChange={this.onFirstNameChange}
                            disabled={!this.props.user.isAdmin}
                            isInvalid={this.state.errors.firstName}
                          />
                          <Form.Control.Feedback type="invalid">
                            Required
                          </Form.Control.Feedback>
                        </Col>
                        <Col>
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Last name"
                            value={this.state.lastName}
                            onChange={this.onLastNameChange}
                            disabled={!this.props.user.isAdmin}
                            isInvalid={this.state.errors.lastName}
                          />
                          <Form.Control.Feedback type="invalid">
                            Required
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Row>
                    </Form.Group>
                    <Form.Group>
                      <Form.Row className="mt-4">
                        <Col>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={this.onEmailChange}
                            disabled={!this.props.user.isAdmin}
                            isInvalid={this.state.errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            Required
                          </Form.Control.Feedback>
                        </Col>
                        <Col>
                          <Form.Label>Phone (Optional)</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Phone"
                            value={this.state.phone}
                            onChange={this.onPhoneChange}
                            disabled={!this.props.user.isAdmin}
                          />
                        </Col>
                      </Form.Row>
                    </Form.Group>
                    <Form.Group>
                      <Form.Row className="mt-4">
                        <Col>
                          <Form.Label>Account Role</Form.Label>
                          <Form.Control
                            as="select"
                            value={this.state.accountRole}
                            onChange={this.onAccountRoleChange}
                            disabled={!this.props.user.isAdmin}
                          >
                            <option value="user">Account User</option>
                            <option value="admin">Account Admin</option>
                          </Form.Control>
                        </Col>
                        <Col>
                          <Form.Label>Position (Optional)</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Position"
                            value={this.state.position}
                            onChange={this.onPositionChange}
                            disabled={!this.props.user.isAdmin}
                          />
                        </Col>
                      </Form.Row>
                    </Form.Group>
                    <Form.Group>
                      <Form.Row className="mt-4">
                        <Button variant="secondary" onClick={this.closeUser}>
                          Close
                        </Button>
                        { (this.props.user.isAdmin && this.state.selectedUser.id) &&
                          <Button
                            className="ml-4"
                            variant="primary"
                            onClick={this.onUserSave}
                          >
                            Save
                          </Button>
                        }
                        { (this.props.user.isAdmin && !this.state.selectedUser.id) &&
                          <Button
                            className="ml-4"
                            variant="primary"
                            onClick={this.onSendUserInvite}
                            disabled={this.state.newUserCreating}
                          >
                            Send Invite
                          </Button>
                        }
                      </Form.Row>
                    </Form.Group>
                  </Form>
                  <Toast
                    onClose={() => this.setState(() => ({
                      showToast: false,
                      toastIcon: '',
                      toastMessage: '',
                    }))}
                    show={this.state.showToast}
                    delay={2000}
                    autohide
                    style={{
                      position: 'absolute',
                      top: 50,
                      right: 0
                    }}
                  >
                    <Toast.Header>
                      <div className="mr-auto">
                        { this.state.toastIcon === 'good' &&
                          <FontAwesomeIcon
                            className="text-success mr-2"
                            icon={faCheck}
                          />
                        }
                        { this.state.toastIcon === 'bad' &&
                          <FontAwesomeIcon
                            className="text-danger mr-2"
                            icon={faExclamationCircle}
                          />
                        }
                        { this.state.toastMessage}
                      </div>
                    </Toast.Header>
                  </Toast>
                </div>
              }
            </Col>
          </Row>
        }

        <DeleteConfirmationModal
          isOpen={this.state.deleteUserConfirmationModalOpen}
          handleClose={this.closeDeleteUserConfirmationModal}
          resourceName={this.state.deleteUserEmail}
          deleteResource={this.deleteUser}
        />
      </div>
    );
  }
};
