import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import LoginContainer from '../../../containers/common/LoginContainer';
import DateRangePicker from '../../common/DateRangePicker';
import CardComponent from '../../common/CardComponent';
import GoogleAdsMonthSummaryTab from './GoogleAdsMonthSummaryTab';
import GoogleAdsConversionsTab from './GoogleAdsConversionsTab';
import GoogleAdsCampaignDataTab from './GoogleAdsCampaignDataTab';
import GoogleAdsAdGroupTab from './GoogleAdsAdGroupTab';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas, intToShorthandString } from '../../../utils/numbers';
import { colors } from '../../../utils/graphs';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class GoogleAds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      category: undefined,
      brand: undefined,
      googleAdCampaigns: [],
      selectedCampaignId: 'all',
      selectedCampaign: undefined,
      startDate: moment().subtract(90, 'days').startOf('day'),
      endDate: moment().startOf('day').endOf('day'),
      gaAggregatedData: {},
      gaData: [],
      gaDataLoading: false,
      clicksChecked: true,
      impressionsChecked: true,
      conversionsChecked: false,
      selectedTab: 'summaryByMonth',
      refreshLoading: false,
      refreshLabel: undefined,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchGoogleAdCampaigns();
    this.fetchGoogleAdCampaignData();
    if (this.props.myCategoryBrands.length > 0) {
      this.setState(() => ({
        category: this.props.myCategoryBrands[0].category,
        brand: this.props.myCategoryBrands[0].brand,
      }));
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.customerId !== this.props.user.customerId) {
      this.setState(() => ({
        selectedCampaignId: 'all',
        selectedCampaign: undefined,
      }), () => {
        this.fetchGoogleAdCampaigns();
        this.fetchGoogleAdCampaignData();
      });
    }
    if (
      prevState.selectedCampaign !== this.state.selectedCampaign ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      this.fetchGoogleAdCampaignData();
    }
    if (
      prevProps.myCategoryBrands !== this.props.myCategoryBrands &&
      this.props.myCategoryBrands.length > 0
    ) {
      this.setState(() => ({
        category: this.props.myCategoryBrands[0].category,
        brand: this.props.myCategoryBrands[0].brand,
      }));
    }
  };

  fetchGoogleAdCampaigns = () => {
    if (this.props.user.customerId) {
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/google-ad-campaigns?company_id=${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        const googleAdCampaigns = response.data;
        googleAdCampaigns.sort((a, b) => {
          if (a.status === 'ENABLED' && b.status !== 'ENABLED') return -1;
          if (a.status !== 'ENABLED' && b.status === 'ENABLED') return 1;
          if (
            a.status === 'ENABLED' && b.status === 'ENABLED' ||
            a.status !== 'ENABLED' && b.status !== 'ENABLED'
          ) {
            // alphabetize
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          }
          return 0;
        });
        if (this.state.isMounted) {
          this.setState(() => ({ googleAdCampaigns }));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch google ad campaigns`);
        if (this.state.isMounted) {
          this.setState(() => ({ googleAdCampaigns: [] }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchGoogleAdCampaignData = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({ gaDataLoading: true }));
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      let campaignParam = '';
      if (this.state.selectedCampaign) {
        campaignParam = `&campaign_id=${this.state.selectedCampaign.campaign_id}`;
      }
      const gaDataRequests = [];
      // fetch google ads aggregate data
      gaDataRequests.push(
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/google-ads-latest-stats?company_id=${this.props.user.customerId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}${campaignParam}`,
          HEADERS
        ).then(response => {
          const gaAggregatedData = response.data;
          return { gaAggregatedData };
        }).catch(error => {
          console.error('Error: unable to fetch google ads aggragated data');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { gaAggregatedData: {} };
        })
      );
      // fetch google ads trend data
      gaDataRequests.push(
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/google-ads-daily-stats?company_id=${this.props.user.customerId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}${campaignParam}`,
          HEADERS
        ).then(response => {
          const gaData = response.data;
          return { gaData };
        }).catch(error => {
          console.error('Error: unable to fetch google ads data');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { gaData: [] };
        })
      );

      Promise.all(gaDataRequests).then(responses => {
        let gaAggregatedData = {};
        let gaData = [];
        for (const response of responses) {
          if (response.gaAggregatedData) {
            gaAggregatedData = response.gaAggregatedData;
          } else if (response.gaData) {
            gaData = response.gaData;
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            gaAggregatedData,
            gaData,
            gaDataLoading: false,
          }));
        }
      });
    }
  };

  onCampaignSelect = (campaign) => {
    if (campaign && campaign.campaign_id !== this.state.selectedCampaignId) {
      if (campaign.campaign_id === 'all') {
        this.setState(() => ({
          selectedCampaignId: 'all',
          selectedCampaign: undefined
        }));
      } else {
        this.setState(() => ({
          selectedCampaignId: campaign.campaign_id,
          selectedCampaign: campaign
        }));
      }
    }
  };

  onFilterDatesChange = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  };

  onCheckboxChange = (checked, checkbox) => {
    if (checked !== this.state[checkbox]) {
      this.setState(() => ({
        [checkbox]: checked
      }));
    }
  };

  runRefreshPoll = () => {
    if (this.props.user.customerId && !this.state.refreshLoading) {
      this.setState(() => ({ refreshLoading: true }));
      axios.post(
        `${CONTENT_SCRAPING_ENDPOINT}/api/google-ads-refresh-poll`,
        { company_id: this.props.user.customerId },
        HEADERS
      ).then(response => {
        const refreshMetadata = response.data;
        const refreshLabel = `Latest update ${moment.utc(refreshMetadata.last_refresh_date).format('MM/DD')}`
        if (this.state.isMounted) {
          this.setState(() => ({
            refreshLabel,
            refreshLoading: false,
          }));
        }
      }).catch(error => {
        console.error(`Error: failed to refresh google ads data`);
        if (this.state.isMounted) {
          this.setState(() => ({
            refreshLabel: 'Refresh failed',
            refreshLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  render () {
    return (
      <div className="p-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        { (this.props.user.id && !this.props.companySetupChecklist.google_ads_setup) &&
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              minHeight: 'calc(100vh - 200px)',
              maxHeight: 'calc(100vh - 200px)',
            }}
          >
            <div className="d-inline-block">
              <Alert variant="primary">
                Google Ads is not setup for your company. You can get setup <Link to="/content-collectors/google-ads">here</Link>.
              </Alert>
            </div>
          </div>
        }
        { (this.props.user.customerId && this.props.companySetupChecklist.google_ads_setup) &&
          <div>
            <h4>
              <img
                className="mr-2"
                src="https://listen-company-logos.s3.amazonaws.com/google_ads_logo.png"
                alt=""
                crossOrigin="anonymous"
                style={{ width: '24px' }}
              />
              Google Ads
            </h4>
            <hr />
            <div>
              <h5 className="mb-2 p-2 bg-bops-blue text-light">
                <span
                  className="mr-2"
                  style={{ fontSize: '1rem', fontWeight: 'normal' }}
                >
                  Campaign
                </span>
                <div className="d-inline-block">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="light"
                      size="sm"
                      style={{ cursor: 'pointer' }}
                    >
                      { !this.state.selectedCampaign &&
                        <div className="d-inline-block">
                          <div
                            className="d-inline-block mr-1"
                            style={{
                              position: 'relative',
                              top: '2px',
                              width: '16px',
                              height: '16px',
                              backgroundColor: '#28a745',
                              borderRadius: '50%'
                            }}
                          />
                          All
                        </div>
                      }
                      { this.state.selectedCampaign &&
                        <div className="d-inline-block">
                          <div
                            className="d-inline-block mr-1"
                            style={{
                              position: 'relative',
                              top: '2px',
                              width: '16px',
                              height: '16px',
                              backgroundColor: this.state.selectedCampaign.status === 'ENABLED' ?
                                '#28a745' : '#6c757d',
                              borderRadius: '50%'
                            }}
                          />
                          {this.state.selectedCampaign.name}
                        </div>
                      }
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className="px-2"
                        onClick={() => this.onCampaignSelect({ campaign_id: 'all' })}
                      >
                        <div
                          className="d-inline-block mr-1"
                          style={{
                            position: 'relative',
                            top: '2px',
                            width: '16px',
                            height: '16px',
                            backgroundColor: '#28a745',
                            borderRadius: '50%'
                          }}
                        />
                        All
                      </Dropdown.Item>
                      { this.state.googleAdCampaigns.map(gaCampaign => {
                          return (
                            <Dropdown.Item
                              key={`gads-c-${gaCampaign.campaign_id}`}
                              className="px-2"
                              onClick={() => this.onCampaignSelect(gaCampaign)}
                            >
                              <div
                                className="d-inline-block mr-1"
                                style={{
                                  position: 'relative',
                                  top: '2px',
                                  width: '16px',
                                  height: '16px',
                                  backgroundColor: gaCampaign.status === 'ENABLED' ?
                                    '#28a745' : '#6c757d',
                                  borderRadius: '50%'
                                }}
                              />
                              {gaCampaign.name}
                            </Dropdown.Item>
                          )
                        })
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <span
                  className="ml-4 mr-2"
                  style={{ fontSize: '1rem', fontWeight: 'normal' }}
                >
                  Date Range
                </span>
                <DateRangePicker
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  updateDates={this.onFilterDatesChange}
                />
                <div className="d-inline-block mt-1 float-right">
                  { this.state.refreshLabel &&
                    <span
                      className="mr-2"
                      style={{ fontSize: '1rem', fontWeight: 'normal' }}
                    >
                      {this.state.refreshLabel}
                    </span>
                  }
                  { this.state.refreshLoading &&
                    <ClipLoader
                      size={15}
                      color="ffffff"
                    />
                  }
                  { !this.state.refreshLoading &&
                    <FontAwesomeIcon
                      icon={faSyncAlt}
                      onClick={() => this.runRefreshPoll()}
                      style={{ cursor: 'pointer' }}
                    />
                  }
                </div>
              </h5>
            </div>
            <div
              className="pre-scrollable"
              style={{
                minHeight: 'calc(100vh - 200px)',
                maxHeight: 'calc(100vh - 200px)',
                overflow: 'auto',
                overflowX: 'hidden'
              }}
            >
              <CardComponent
                title="Google Ad Performance"
                body={
                  <div>
                    { this.state.gaDataLoading &&
                      <div className="m-4 text-center">
                        <ClipLoader size={100}/>
                      </div>
                    }
                    { !this.state.gaDataLoading &&
                      <div>
                        <div>
                          <div
                            className="d-inline-block px-4 py-3 border"
                            onClick={() => this.onCheckboxChange(!this.state.clicksChecked, 'clicksChecked')}
                            style={{
                              color: this.state.clicksChecked ? '#ffffff' : '#000000',
                              backgroundColor: this.state.clicksChecked ? colors[0] : '#f8f9fa'
                            }}
                          >
                            <Form.Check
                              type="checkbox"
                              label="Clicks"
                              checked={this.state.clicksChecked}
                              readOnly
                            />
                            <div style={{ fontSize: '2rem' }}>
                              { (this.state.gaAggregatedData && this.state.gaAggregatedData.total_clicks != null) ?
                                  intToShorthandString(this.state.gaAggregatedData.total_clicks) : '-'
                              }
                            </div>
                          </div>
                          <div
                            className="d-inline-block px-4 py-3 border"
                            onClick={() => this.onCheckboxChange(!this.state.impressionsChecked, 'impressionsChecked')}
                            style={{
                              color: this.state.impressionsChecked ? '#ffffff' : '#000000',
                              backgroundColor: this.state.impressionsChecked ? colors[1] : '#f8f9fa'
                            }}
                          >
                            <Form.Check
                              type="checkbox"
                              label="Impressions"
                              checked={this.state.impressionsChecked}
                              readOnly
                            />
                            <div style={{ fontSize: '2rem' }}>
                              { (this.state.gaAggregatedData && this.state.gaAggregatedData.total_impressions != null) ?
                                  intToShorthandString(this.state.gaAggregatedData.total_impressions) : '-'
                              }
                            </div>
                          </div>
                          <div
                            className="d-inline-block px-4 py-3 border"
                            onClick={() => this.onCheckboxChange(!this.state.conversionsChecked, 'conversionsChecked')}
                            style={{
                              color: this.state.conversionsChecked ? '#ffffff' : '#000000',
                              backgroundColor: this.state.conversionsChecked ? colors[2] : '#f8f9fa'
                            }}
                          >
                            <Form.Check
                              type="checkbox"
                              label="Conversions"
                              checked={this.state.conversionsChecked}
                              readOnly
                            />
                            <div style={{ fontSize: '2rem' }}>
                              { (this.state.gaAggregatedData && this.state.gaAggregatedData.total_conversion != null) ?
                                  intToShorthandString(this.state.gaAggregatedData.total_conversion) : '-'
                              }
                            </div>
                          </div>
                          <div
                            className="d-inline-block px-4 py-3 border"
                            style={{
                              color: '#000000',
                              backgroundColor: '#f8f9fa'
                            }}
                          >
                            CTR
                            <div style={{ fontSize: '2rem' }}>
                              { (this.state.gaAggregatedData && this.state.gaAggregatedData.ctr != null) ?
                                  `${this.state.gaAggregatedData.ctr}%` : '-'
                              }
                            </div>
                          </div>
                          <div
                            className="d-inline-block px-4 py-3 border"
                            style={{
                              color: '#000000',
                              backgroundColor: '#f8f9fa'
                            }}
                          >
                            CPC
                            <div style={{ fontSize: '2rem' }}>
                              { (this.state.gaAggregatedData && this.state.gaAggregatedData.cost_per_click != null) ?
                                  `$${this.state.gaAggregatedData.cost_per_click}` : '-'
                              }
                            </div>
                          </div>
                        </div>
                        <ResponsiveContainer height={300} width="99%">
                          <LineChart
                            data={this.state.gaData}
                            margin={{
                              left: 10,
                              right: 10,
                            }}
                          >
                            <XAxis
                              dataKey="date"
                              tickFormatter={(date) => {
                                return moment(date).format('MM/DD');
                              }}
                              minTickGap={15}
                            />
                            <YAxis
                              yAxisId={0}
                              orientation="left"
                              tickFormatter={(value) => {
                                return numberWithCommas(value)
                              }}
                            >
                              <Label
                                value="Clicks & Conversions"
                                position="left"
                                offset={0}
                                angle={-90}
                                style={{
                                  textAnchor: 'middle',
                                  fontWeight: 'bold'
                                }}
                              />
                            </YAxis>>
                            { this.state.impressionsChecked &&
                              <YAxis
                                yAxisId={1}
                                orientation="right"
                                tickFormatter={(value) => {
                                  return numberWithCommas(value)
                                }}
                              >
                                <Label
                                  value="Impressions"
                                  position="right"
                                  offset={0}
                                  angle={90}
                                  style={{
                                    textAnchor: 'middle',
                                    fontWeight: 'bold'
                                  }}
                                />
                              </YAxis>
                            }
                            <CartesianGrid strokeDasharray="5 5"/>
                            <Tooltip
                              labelFormatter={(label) => moment(label).format('dddd, MMMM Do YYYY')}
                              formatter={(value) => numberWithCommas(value)}
                            />
                          { this.state.clicksChecked &&
                              <Line
                                name="Clicks"
                                type="monotype"
                                dataKey="total_clicks"
                                stroke={colors[0]}
                                dot={false}
                                yAxisId={0}
                              />
                            }
                            { this.state.impressionsChecked &&
                              <Line
                                name="Impressions"
                                type="monotype"
                                dataKey="total_impressions"
                                stroke={colors[1]}
                                dot={false}
                                yAxisId={1}
                              />
                            }
                            { this.state.conversionsChecked &&
                              <Line
                                name="Conversions"
                                type="monotype"
                                dataKey="total_conversion"
                                stroke={colors[2]}
                                dot={false}
                                yAxisId={0}
                              />
                            }
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    }
                  </div>
                }
                contextType="brand"
                contextCategory={this.state.category}
                contextBrand={this.state.category}
                contextChannel="Google Ads"
                contextChartName="Google Ad Performance"
              />
              <div className="mt-4">
                <Tabs
                  activekey={this.state.selectedTab}
                  onSelect={(selectedTab) => this.setState(() => ({ selectedTab }))}
                >
                  <Tab
                    eventKey="summaryByMonth"
                    title="Summary by Month"
                  >
                    <GoogleAdsMonthSummaryTab
                      companyId={this.props.user.customerId}
                      campaignId={this.state.selectedCampaignId}
                    />
                  </Tab>
                  <Tab
                    eventKey="highestConversions"
                    title="Highest Conversions"
                  >
                    <GoogleAdsConversionsTab
                      companyId={this.props.user.customerId}
                      campaignId={this.state.selectedCampaignId}
                    />
                  </Tab>
                  { this.state.selectedCampaignId !== 'all' &&
                    <Tab
                      eventKey="campaignData"
                      title="Campaign Data"
                    >
                      <GoogleAdsCampaignDataTab
                        campaignId={this.state.selectedCampaignId}
                      />
                    </Tab>
                  }
                  { this.state.selectedCampaignId !== 'all' &&
                    <Tab
                      eventKey="adGroups"
                      title="Ad Groups"
                    >
                      <GoogleAdsAdGroupTab
                        campaignId={this.state.selectedCampaignId}
                      />
                    </Tab>
                  }
                </Tabs>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
};
