import React from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ClipLoader from 'react-spinners/ClipLoader';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class BrandContributions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCategory: undefined,
      brandContributionsMap: {},
      brandContributionsLoading: false,
      errors: {},
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchBrandContributions();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedCategory !== this.props.selectedCategory) {
      this.fetchBrandContributions();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchBrandContributions = () => {
    if (this.props.selectedCategory) {
      this.setState(() => ({ brandContributionsLoading: true }));
      const brandContributionRequests = [];
      brandContributionRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/product-brand-contributions-custom?category_type=${this.props.selectedCategory.category_type}&category_id=${this.props.selectedCategory.id}&customer_id=${this.props.customerId}`,
          HEADERS
        ).then(response => {
          const brandContributionsMap = {};
          for (const bc of response.data) {
            brandContributionsMap[bc.product_brand_id] = bc;
            brandContributionsMap[bc.product_brand_id].contribution_rate = `${bc.contribution_rate}%`;
          }
          return { brandContributionsMap };
        }).catch(error => {
          console.error('Error: failed to fetch brand contributions');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { brandContributionsMap: {} };
        })
      );
      brandContributionRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-identified-brands?category_type=${this.props.selectedCategory.category_type}&category_id=${this.props.selectedCategory.id}`,
          HEADERS
        ).then(response => {
          const categoryIdentifiedBrands = response.data;
          return { categoryIdentifiedBrands };
        }).catch(error => {
          console.error('Error: failed to fetch category identified brands');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryIdentifiedBrands: [] };
        })
      );

      Promise.all(brandContributionRequests).then(responses => {
        let brandContributionsMap = {};
        let categoryIdentifiedBrands = [];
        for (const response of responses) {
          if (response.brandContributionsMap) {
            brandContributionsMap = response.brandContributionsMap;
          } else if (response.categoryIdentifiedBrands) {
            categoryIdentifiedBrands = response.categoryIdentifiedBrands;
          }
        }
        // merge category identified brand resuls into brand contributions map
        for (const cib of categoryIdentifiedBrands) {
          if (brandContributionsMap[cib.product_brand_id]) {
            brandContributionsMap[cib.product_brand_id].identified_brand_is_verified = cib.is_verified;
            brandContributionsMap[cib.product_brand_id].news_based_pbc = cib.news_based_pbc;
            brandContributionsMap[cib.product_brand_id].web_page_based_pbc = cib.web_page_based_pbc;
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            brandContributionsMap,
            brandContributionsLoading: false,
          }));
        }
      });
    }
  };

  onBrandContributionChange = (brand, event) => {
    const brandContribution = event.currentTarget.value;
    this.setState((prevState) => ({
      brandContributionsMap: {
        ...prevState.brandContributionsMap,
        [brand.id]: {
          ...prevState.brandContributionsMap[brand.id],
          contribution_rate: brandContribution
        }
      }
    }));
  };

  validForm = () => {
    const errors = {};
    for (const [id, value] of Object.entries(this.state.brandContributionsMap)) {
      if (value.contribution_rate.trim() === '') {
        errors[`brand${id}Contribution`] = true;
      } else {
        const contributionPercent = value.contribution_rate.replace('%', '');
        if (isNaN(Number(contributionPercent))) {
          errors[`brand${id}Contribution`] = true;
        }
      }
    }

    this.setState(() => ({ errors }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  saveBrandContributions = () => {
    if (this.validForm()) {
      const updateContributionRequests = [];
      for (const [id, value] of Object.entries(this.state.brandContributionsMap)) {
        const contributionBody = {
          product_brand_id: id,
          added_by_customer_id: this.props.customerId,
          contribution_rate: parseFloat(value.contribution_rate)
        };

        updateContributionRequests.push(
          axios.post(
            `${LISTEN_ENDPOINT}/api/product-brand-contributions`,
            contributionBody,
            HEADERS
          ).then(response => {
            return response;
          }).catch(error => {
            console.error('Error: failed to update brand contribution');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
          })
        );
      }

      Promise.all(updateContributionRequests).then(responses => {
        this.fetchBrandContributions();
      });
    }
  };

  render () {
    return (
      <div>
        <div>
          <p>
            BrandOps allows our customers to use a fractional multiplier to convert
            corporate data impact to a product level. For example, Microsoft might
            have hundreds of product brands which account for their Web traffic,
            but you may estimate that only 1% of that traffic is going to Product X.
            In this case, the BrandOps user would set Microsoft’s Product Brand
            Contribution to 1%.
          </p>
          <p>
            Changes here will apply to the brands across all categories you track.
            The changes will not be retroactive, but will apply going forward.
          </p>
        </div>
        <div>
          { this.props.selectedCategory &&
            <div>
              { this.state.brandContributionsLoading &&
                <div className="m-4 text-center">
                  <ClipLoader size={100}/>
                </div>
              }
              { !this.state.brandContributionsLoading &&
                <Table size="sm" borderless striped>
                  <thead className="bg-dark text-light text-center">
                    <tr>
                      <th>Brand</th>
                      <th style={{ width: '80px' }}>Contribution</th>
                      <th>Estimated from News</th>
                      <th>Estimated from Web</th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.props.selectedCategory.product_brands.map(brand => {
                        return (
                          <tr key={`b-${brand.id}-contrib-row`}>
                            <td className="text-center align-middle">
                              {brand.name}
                            </td>
                            <td className="text-center">
                              <div className="d-inline-block">
                                <Form.Control
                                  type="text"
                                  value={
                                    this.state.brandContributionsMap[brand.id] ?
                                      this.state.brandContributionsMap[brand.id].contribution_rate : ''
                                  }
                                  onChange={(e) => this.onBrandContributionChange(brand, e)}
                                  isInvalid={this.state.errors[`brand${brand.id}Contribution`]}
                                  size="sm"
                                  style={{ width: '60px' }}
                                />
                              </div>
                            </td>
                            <td className="text-center align-middle">
                              { (
                                  this.state.brandContributionsMap[brand.id] &&
                                  this.state.brandContributionsMap[brand.id].news_based_pbc
                                ) &&
                                <React.Fragment>
                                  {`${this.state.brandContributionsMap[brand.id].news_based_pbc}%`}
                                </React.Fragment>
                              }
                            </td>
                            <td className="text-center align-middle">
                              { (
                                  this.state.brandContributionsMap[brand.id] &&
                                  this.state.brandContributionsMap[brand.id].web_page_based_pbc
                                ) &&
                                <React.Fragment>
                                  {`${this.state.brandContributionsMap[brand.id].web_page_based_pbc}%`}
                                </React.Fragment>
                              }
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
              }
            </div>
          }
        </div>
        <Button
          variant="primary"
          onClick={this.saveBrandContributions}
          disabled={this.brandContributionsLoading}
        >
          Save
        </Button>
      </div>
    );
  }
};
