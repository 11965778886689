import React from 'react';
import Popup from 'reactjs-popup';
import html2canvas from 'html2canvas';
import AddToReportsModalContainer from '../../containers/share/AddToReportsModalContainer';
import SlackShareModalContainer from '../../containers/share/SlackShareModalContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faShareSquare, faFileImage, faFileLines } from '@fortawesome/free-regular-svg-icons';
import { faSlack } from '@fortawesome/free-brands-svg-icons';

export default class SharePopUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: `spu-${Math.random().toString(36).substring(7)}`,
      addToReportModalOpen: false,
      slackShareModalOpen: false,
    };
  };

  componentDidMount() {

  };

  closePopUp = () => {
    // emulate click to close popup
    const triggerElement = document.getElementById(this.state.id);
    triggerElement.click();
  };

  openAddToReportsModal = () => {
    this.closePopUp();
    this.setState(() => ({
      addToReportModalOpen: true,
      imageDateUrlLoading: true,
    }));
    const element = document.getElementById(this.props.shareElementId);
    html2canvas(element, { useCORS: true }).then(canvas => {
      const dataUrl = canvas.toDataURL('image/png');
      this.setState(() => ({
        imageDataUrl: dataUrl,
        imageDateUrlLoading: false,
      }));
    }).catch(error => {
      console.log('Error: failed to generate canvas for download.');
      this.setState(() => ({
        imageDataUrl: undefined,
        imageDateUrlLoading: false,
      }));
    });
  };

  closeAddToReportsModal = () => {
    this.setState(() => ({
      addToReportModalOpen: false
    }));
  };

  openSlackShareModal = () => {
    this.closePopUp();
    this.setState(() => ({
      slackShareModalOpen: true,
      imageDateUrlLoading: true,
    }));
    const element = document.getElementById(this.props.shareElementId);
    html2canvas(element, { useCORS: true }).then(canvas => {
      const dataUrl = canvas.toDataURL('image/png');
      this.setState(() => ({
        imageDataUrl: dataUrl,
        imageDateUrlLoading: false,
      }));
    }).catch(error => {
      console.log('Error: failed to generate canvas for download.');
      this.setState(() => ({
        imageDataUrl: undefined,
        imageDateUrlLoading: false,
      }));
    });
  };

  closeSlackShareModal = () => {
    this.setState(() => ({
      slackShareModalOpen: false
    }));
  };

  copyImage = () => {
    this.closePopUp();
    const element = document.getElementById(this.props.shareElementId);
    html2canvas(element, { useCORS: true }).then(canvas => {
      canvas.toBlob((imageBlob) => {
        navigator.clipboard.write([new window.ClipboardItem({ 'image/png': imageBlob })]);
      });
    }).catch(error => {
      console.log('Error: failed to generate canvas for copy.');
    });
  };

  downloadImage = () => {
    this.closePopUp();
    const element = document.getElementById(this.props.shareElementId);
    html2canvas(element, { useCORS: true }).then(canvas => {
      const dataUrl = canvas.toDataURL('image/png');
      var link = document.createElement('a');
      document.body.appendChild(link);
      link.setAttribute('href', dataUrl);
      link.setAttribute('download', `${this.props.elementLabel ? `${this.props.elementLabel}` : 'brandops'}.png`);
      link.click();
    }).catch(error => {
      console.log('Error: failed to generate canvas for download.');
    });
  };

  render() {
    return (
      <div data-html2canvas-ignore="true">
        <Popup
          trigger={
            <button
              id={this.state.id}
              className={`float-right px-1 py-0 bg-white border ${this.props.position === 'top' ? ' rounded' : ' rounded-bottom border-top-0'} share-${this.props.elementLabel}`}
              onClick={this.togglePopup}
            >
              <FontAwesomeIcon
                icon={faShareSquare}
                color="#6c757d"
                style={{
                  fontSize: '.875rem',
                  paddingBottom: '1px'
                }}
              />
            </button>
          }
          on="click"
          position={ this.props.position === 'top' ? 'bottom right' : 'top right'}
          closeOnDocumentClick={true}
          contentStyle={{ borderRadius: '5px', width: '150px' }}
        >
          <div
            className="p-2" style={{
              fontWeight: 'normal',
              fontSize: '.875rem'
            }}
          >
            <div>Send To</div>
            <div>
              <div
                className="d-inline-block p-2 basic-menu-item text-center rounded share-report"
                onClick={this.openAddToReportsModal}
                style={{ cursor: 'pointer' }}
              >
                <div>
                  <FontAwesomeIcon
                    icon={faFileLines}
                    color="#5f666d"
                    style={{ fontSize: '1.2rem' }}
                  />
                </div>
                <div>Reports</div>
              </div>
              { this.props.slackChannels.length === 0 &&
                <div className="d-inline-block p-2 text-center rounded">
                  <div>
                    <FontAwesomeIcon
                      icon={faSlack}
                      color="#c8ccd0"
                      style={{
                        fontSize: '1.2rem',
                      }}
                    />
                  </div>
                  <div style={{ color: '#c8ccd0' }}>
                    Slack
                  </div>
                </div>
              }
              { this.props.slackChannels.length > 0 &&
                <div
                  className="d-inline-block p-2 basic-menu-item text-center rounded share-slack"
                  style={{ cursor: 'pointer' }}
                  onClick={this.openSlackShareModal}
                >
                  <div>
                    <img
                      src="https://listen-company-favicons.s3.amazonaws.com/slack-favicon.ico"
                      alt=""
                      crossOrigin="anonymous"
                      style={{ width: '20px' }}
                    />
                  </div>
                  <div>
                    Slack
                  </div>
                </div>
              }
            </div>
            <hr className="my-1" />

            <div>Quick Download</div>
            <div>
              <div
                className="d-inline-block p-2 basic-menu-item text-center rounded share-copy"
                onClick={this.copyImage}
                style={{ cursor: 'pointer' }}
              >
                <div>
                  <FontAwesomeIcon
                    icon={faCopy}
                    color="#5f666d"
                    style={{ fontSize: '1.2rem' }}
                  />
                </div>
                <div>Copy</div>
              </div>
              <div
                className="d-inline-block p-2 basic-menu-item text-center rounded share-download"
                onClick={this.downloadImage}
                style={{ cursor: 'pointer' }}
              >
                <div>
                  <FontAwesomeIcon
                    icon={faFileImage}
                    color="#5f666d"
                    style={{ fontSize: '1.2rem' }}
                  />
                </div>
                <div>PNG</div>
              </div>
            </div>
          </div>
        </Popup>

        <AddToReportsModalContainer
          isOpen={this.state.addToReportModalOpen}
          handleClose={this.closeAddToReportsModal}
          imageDataUrl={this.state.imageDataUrl}
          imageDateUrlLoading={this.state.imageDateUrlLoading}
          contextType={this.props.contextType}
          contextCategory={this.props.contextCategory}
          contextBrand={this.props.contextBrand}
          contextChannel={this.props.contextChannel}
          contextProperty={this.props.contextProperty}
          contextChartName={this.props.contextChartName}
          contextDateRange={this.props.contextDateRange}
        />
        <SlackShareModalContainer
          isOpen={this.state.slackShareModalOpen}
          handleClose={this.closeSlackShareModal}
          imageDataUrl={this.state.imageDataUrl}
          imageDateUrlLoading={this.state.imageDateUrlLoading}
        />
      </div>
    );
  }
};
