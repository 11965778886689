import React from 'react';
import axios from 'axios';
import ShareOfChart from '../graphs/ShareOfChart';
import { round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryShareOfReviewsGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      graphData: {},
      categoryShareOfReviews: [],
      categoryShareOfReviewsLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.loadCategoryShareOfReviews(this.props.categoryType, this.props.categoryId);
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.categoryType !== this.props.categoryType ||
      prevProps.categoryId !== this.props.categoryId
    ) {
      this.loadCategoryShareOfReviews(this.props.categoryType, this.props.categoryId);
    }
  }

  loadCategoryShareOfReviews = (categoryType, categoryId) => {
    this.setState(() => ({ categoryShareOfReviewsLoading: true }));
    let typeFilter = '';
    switch (this.props.reviewType) {
      case 'good':
        typeFilter = '&condition=gte&threshold=4';
        break;
      case 'bad':
        typeFilter = '&condition=lte&threshold=2';
        break;
    }

    axios.get(
      `${LISTEN_ENDPOINT}/api/category-share-of-reviews?category_type=${categoryType}&category_id=${categoryId}${typeFilter}`,
      HEADERS
    ).then(response => {
      const graphData = {
        series: [
          { name: 'Month', type: 'dimension' },
          { name: 'Brand', type: 'dimension' },
          { name: 'Value', type: 'measure' },
        ],
        records: [],
      };
      const categoryShareOfReviews = [];
      for (const monthStats of response.data) {
        const reviewsMonthData = { month: monthStats.month };
        let monthTotalReviews = 0;
        const viewsMonthData = { month: monthStats.month };
        let monthTotalViews = 0;

        // get totals for percent
        for (const pb of monthStats.product_brands) {
          monthTotalReviews += pb.review_count;
          // insert graph data
          graphData.records.push([
            monthStats.month,
            pb.product_brand_name,
            pb.review_count,
          ]);
        }

        // loop again to determine %
        for (const pb of monthStats.product_brands) {
          let reviewsPercent = 0;
          if (monthTotalReviews > 0) {
            reviewsPercent = round((pb.review_count/monthTotalReviews)*100, 1);
          }
          reviewsMonthData[pb.product_brand_name] = {
            count: pb.review_count,
            percent: reviewsPercent
          }
        }
        categoryShareOfReviews.push(reviewsMonthData);
      }

      if (this.state.isMounted) {
        this.setState(() => ({
          graphData,
          categoryShareOfReviews,
          categoryShareOfReviewsLoading: false,
        }));
      }
    }).catch(error => {
      console.error(`Error: unable to load share of ${this.props.reviewType} reviews...`)
      this.setState(() => ({
        graphData: {},
        categoryShareOfReviews: [],
        categoryShareOfReviewsLoading: false,
      }));
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  render () {
    return (
      <ShareOfChart
        title={this.props.title}
        icon={this.props.icon}
        description={this.props.description}
        data={this.state.graphData}
        legendData={this.state.categoryShareOfReviews}
        loading={this.state.categoryShareOfReviewsLoading}
        shareClassName={`category-${this.props.reviewType}-reviews-img`}
        contextType="category"
        contextCategory={this.props.category}
        contextBrand={null}
        contextChannel="Product Reviews"
        contextChartName={this.props.title}
      />
    );
  }
};
