import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import LoginContainer from '../../containers/common/LoginContainer';
import CardComponent from '../common/CardComponent';
import DateRangePicker from '../common/DateRangePicker';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { intToShorthandString, numberWithCommas, round } from '../../utils/numbers';
import { capitalizeFirstLetter } from '../../utils/strings';
import { colors } from '../../utils/graphs';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class LandingPages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      category: undefined,
      brand: undefined,
      startDate: moment().subtract(90, 'days').startOf('day'),
      endDate: moment().startOf('day').endOf('day'),
      sortType: 'recentLeads',
      allLandingPageData: {},
      marketoLandingPages: [],
      marketoLandingPagesLoading: false,
      viewsChecked: true,
      leadsChecked: true,
      selectedTab: 'landingPages',
      sortType: 'recentLeads',
      selectedLandingPageId: undefined,
      selectedLandingPage: undefined,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchLandingPages();
    if (this.props.myCategoryBrands.length > 0) {
      this.setState(() => ({
        category: this.props.myCategoryBrands[0].category,
        brand: this.props.myCategoryBrands[0].brand,
      }));
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.customerId !== this.props.user.customerId) {
      this.fetchLandingPages();
    }
    if (
      prevProps.myCategoryBrands !== this.props.myCategoryBrands &&
      this.props.myCategoryBrands.length > 0
    ) {
      this.setState(() => ({
        category: this.props.myCategoryBrands[0].category,
        brand: this.props.myCategoryBrands[0].brand,
      }));
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchLandingPages = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({ marketoLandingPagesLoading: true }));
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/company-marketo-landing-pages?company_id=${this.props.user.customerId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        let marketoLandingPages = response.data;
        let selectedLandingPageId;
        let selectedLandingPage;
        for (let mlp of marketoLandingPages) {
          mlp.landing_page_url_label = '';
          try {
            const urlPathSplit = new URL(mlp.landing_page_url).pathname.split('/');
            const endPath = urlPathSplit[urlPathSplit.length-1] || '';
            mlp.landing_page_url_label = endPath.split('.')[0];
          } catch (error) {
            // do nothing
          }
          mlp.program_leads.sort((a, b) => moment(b.lead_created_at) - moment(a.lead_created_at));
          for (const lead of mlp.program_leads) {
            lead.domain_from_email = capitalizeFirstLetter(lead.domain_from_email);
          }
        }

        // condense landing pages into an "all" as first selection
        const allLandingPageData = {
          landing_page_id: 'all',
          landing_page_name: 'All',
          total_views: 0,
          total_leads: 0,
          most_recent_lead: moment().add(1, 'day'),
          data: [],
        }
        if (marketoLandingPages.length > 0) {
          const allDataMap = {};
          const marketoProgramIds = new Set();
          for (const lp of marketoLandingPages) {
            // update landing page url
            let x = 0;
            lp.landing_page_url = lp.landing_page_url.replace(/\/lp/g, match => ++x === 2 ? '' : match)
            // aggregate into all landing page
            allLandingPageData.total_views += lp.total_views;
            allLandingPageData.total_leads += lp.total_leads;

            for (const lpd of lp.data) {
              if (!allDataMap[lpd.captured_at]) {
                allDataMap[lpd.captured_at] = {
                  captured_at: lpd.captured_at,
                  views_count: 0,
                  lead_count: 0,
                }
              }
              allDataMap[lpd.captured_at].views_count += lpd.views_count;
              allDataMap[lpd.captured_at].lead_count += lpd.lead_count;
            }
          }
          for (const d of Object.values(allDataMap)) {
            allLandingPageData.data.push(d);
          }
          if (allLandingPageData.total_views > 0) {
            allLandingPageData.conversion_rate = round((allLandingPageData.total_leads / allLandingPageData.total_views) * 100, 1)
          } else {
            allLandingPageData.conversion_rate = 0;
          }
          allLandingPageData.data.sort((a, b) => moment(b.date) - moment(a.date));
          // apply appropriate sort
          if (this.state.sortType === 'recentLeads') {
            marketoLandingPages.sort((a, b) => {
              if (a.most_recent_lead && b.most_recent_lead) {
                return moment(b.most_recent_lead) - moment(a.most_recent_lead);
              } else if (a.most_recent_lead) {
                return -1;
              } else {
                return 1;
              }
            });
          } else if (this.state.sortType === 'mostLeads') {
            marketoLandingPages.sort((a, b) => b.total_leads - a.total_leads);
          } else if (this.state.sortType === 'date') {
            marketoLandingPages.sort((a, b) => moment(b.landing_page_created_at) - moment(a.landing_page_created_at));
          }

          selectedLandingPageId = marketoLandingPages[0].landing_page_id;
          selectedLandingPage = marketoLandingPages[0];
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            allLandingPageData,
            marketoLandingPages,
            marketoLandingPagesLoading: false,
            selectedLandingPageId,
            selectedLandingPage,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch company marketo landing pages...');
        if (this.state.isMounted) {
          this.setState(() => ({
            allLandingPageData: {},
            marketoLandingPages: [],
            marketoLandingPagesLoading: false,
            selectedLandingPageId: undefined,
            selectedLandingPage: undefined,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  updateDates = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }), () => this.fetchLandingPages());
  };

  onCheckboxChange = (checked, checkbox) => {
    if (checked !== this.state[checkbox]) {
      this.setState(() => ({
        [checkbox]: checked
      }));
    }
  };

  onSortTypeChange = (event) => {
    const sortType = event.currentTarget.dataset.sortType;
    if (this.state.sortType !== sortType) {
      const marketoLandingPages = Object.assign([], this.state.marketoLandingPages);
      if (sortType === 'recentLeads') {
        marketoLandingPages.sort((a, b) => {
          if (a.most_recent_lead && b.most_recent_lead) {
            return moment(b.most_recent_lead) - moment(a.most_recent_lead);
          } else if (a.most_recent_lead) {
            return -1;
          } else {
            return 1;
          }
        });
      } else if (sortType === 'mostLeads') {
        marketoLandingPages.sort((a, b) => b.total_leads - a.total_leads);
      } else if (this.state.sortType === 'date') {
        marketoLandingPages.sort((a, b) => moment(b.landing_page_created_at) - moment(a.landing_page_created_at));
      }

      this.setState(() => ({
        sortType,
        marketoLandingPages
      }));
    }
  };

  onLandingPageSelect = (selectedLandingPage) => {
    if (selectedLandingPage && selectedLandingPage.landing_page_id !== this.state.selectedLandingPageId) {
      this.setState(() => ({
        selectedLandingPageId: selectedLandingPage.landing_page_id,
        selectedLandingPage,
      }));
    }
  };

  render () {
    return (
      <div className="p-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        { (this.props.user.id && !this.props.companySetupChecklist.marketo_setup) &&
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              minHeight: 'calc(100vh - 200px)',
              maxHeight: 'calc(100vh - 200px)',
            }}
          >
            <div className="d-inline-block">
              <Alert variant="primary">
                Marketo is not setup for your company. You can get setup <Link to="/content-collectors/marketo">here</Link>.
              </Alert>
            </div>
          </div>
        }
        { (this.props.user.customerId && this.props.companySetupChecklist.marketo_setup) &&
          <div>
            <h4>
              <img
                className="mr-2"
                src="https://listen-company-logos.s3.amazonaws.com/marketo_logo.png"
                alt=""
                crossOrigin="anonymous"
                style={{ width: '30px' }}
              />
              Marketo - Landing Pages
            </h4>
            <hr />
            <div className="mb-2 p-2 bg-bops-blue text-light">
              <span
                className="mr-2"
              >Date Range</span>
              <DateRangePicker
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                updateDates={this.updateDates}
              />
            </div>
            <div
              className="pre-scrollable"
              style={{
                minHeight: 'calc(100vh - 200px)',
                maxHeight: 'calc(100vh - 200px)',
                overflow: 'auto',
                overflowX: 'hidden'
              }}
            >
              <CardComponent
                title="Overall Performance"
                body={
                  <div>
                    { this.state.marketoLandingPagesLoading &&
                      <div className="m-4 text-center">
                        <ClipLoader size={100}/>
                      </div>
                    }
                    { !this.state.marketoLandingPagesLoading &&
                      <div>
                        <div>
                          <div
                            className="d-inline-block px-4 py-3 border"
                            onClick={() => this.onCheckboxChange(!this.state.viewsChecked, 'viewsChecked')}
                            style={{
                              color: this.state.viewsChecked ? '#ffffff' : '#000000',
                              backgroundColor: this.state.viewsChecked ? colors[0] : '#f8f9fa'
                            }}
                          >
                            <Form.Check
                              type="checkbox"
                              label="Views"
                              checked={this.state.viewsChecked}
                              readOnly
                            />
                            <div style={{ fontSize: '2rem' }}>
                              { (this.state.allLandingPageData && this.state.allLandingPageData.total_views != null) ?
                                  intToShorthandString(this.state.allLandingPageData.total_views) : '-'
                              }
                            </div>
                          </div>
                          <div
                            className="d-inline-block px-4 py-3 border"
                            onClick={() => this.onCheckboxChange(!this.state.leadsChecked, 'leadsChecked')}
                            style={{
                              color: this.state.leadsChecked ? '#ffffff' : '#000000',
                              backgroundColor: this.state.leadsChecked ? colors[1] : '#f8f9fa'
                            }}
                          >
                            <Form.Check
                              type="checkbox"
                              label="Leads"
                              checked={this.state.leadsChecked}
                              readOnly
                            />
                            <div style={{ fontSize: '2rem' }}>
                              { (this.state.allLandingPageData && this.state.allLandingPageData.total_leads != null) ?
                                  intToShorthandString(this.state.allLandingPageData.total_leads) : '-'
                              }
                            </div>
                          </div>
                          <div
                            className="d-inline-block px-4 py-3 border"
                            style={{
                              color: '#000000',
                              backgroundColor: '#f8f9fa'
                            }}
                          >
                            Conversion Rate
                            <div style={{ fontSize: '2rem' }}>
                              { (this.state.allLandingPageData && this.state.allLandingPageData.conversion_rate != null) ?
                                  `${this.state.allLandingPageData.conversion_rate}%` : '-'
                              }
                            </div>
                          </div>
                        </div>
                        <ResponsiveContainer height={300} width="99%">
                          <LineChart
                            data={
                              (this.state.allLandingPageData && this.state.allLandingPageData.data) ?
                                this.state.allLandingPageData.data :
                                []
                            }
                            margin={{
                              left: 10,
                              right: 10,
                            }}
                          >
                            <XAxis
                              dataKey="captured_at"
                              tickFormatter={(date) => {
                                return moment(date).format('MM/DD');
                              }}
                              minTickGap={15}
                            />
                            <YAxis
                              yAxisId={0}
                              orientation="left"
                              tickFormatter={(value) => {
                                return numberWithCommas(value)
                              }}
                            >
                              <Label
                                value="Views"
                                position="left"
                                offset={0}
                                angle={-90}
                                style={{
                                  textAnchor: 'middle',
                                  fontWeight: 'bold'
                                }}
                              />
                            </YAxis>>
                            { this.state.leadsChecked &&
                              <YAxis
                                yAxisId={1}
                                orientation="right"
                                tickFormatter={(value) => {
                                  return numberWithCommas(value)
                                }}
                              >
                                <Label
                                  value="Leads"
                                  position="right"
                                  offset={0}
                                  angle={90}
                                  style={{
                                    textAnchor: 'middle',
                                    fontWeight: 'bold'
                                  }}
                                />
                              </YAxis>
                            }
                            <CartesianGrid strokeDasharray="5 5"/>
                            <Tooltip
                              labelFormatter={(label) => moment(label).format('dddd, MMMM Do YYYY')}
                              formatter={(value) => numberWithCommas(value)}
                            />
                            { this.state.viewsChecked &&
                              <Line
                                name="Views"
                                type="monotype"
                                dataKey="views_count"
                                stroke={colors[0]}
                                dot={false}
                                yAxisId={0}
                              />
                            }
                            { this.state.leadsChecked &&
                              <Line
                                name="Leads"
                                type="monotype"
                                dataKey="lead_count"
                                stroke={colors[1]}
                                dot={false}
                                yAxisId={1}
                              />
                            }
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    }
                  </div>
                }
                contextType="brand"
                contextCategory={this.state.category}
                contextBrand={this.state.category}
                contextChannel="Marketo Landing Pages"
                contextChartName="Overall Performance"
              />
              <div className="mt-4">
                <Tabs
                  activekey={this.state.selectedTab}
                  onSelect={(selectedTab) => this.setState(() => ({ selectedTab }))}
                >
                  <Tab
                    eventKey="landingPages"
                    title="Landing Pages"
                  >
                    { this.state.marketoLandingPagesLoading &&
                      <div className="m-4 text-center">
                        <ClipLoader size={100}/>
                      </div>
                    }
                    { !this.state.marketoLandingPagesLoading &&
                      <div className="my-2">
                        { this.state.marketoLandingPages.length === 0 &&
                          <div>
                            <div className="border rounded mb-4 p-4 text-center">
                              Landing pages not found
                            </div>
                            <div className="text-center">
                              <img
                                src="https://listen-company-logos.s3.amazonaws.com/LandingPageSample.png"
                                alt=""
                                crossOrigin="anonymous"
                                width="100%"
                                style={{ maxWidth: '1200px' }}
                              />
                            </div>
                          </div>
                        }
                        { this.state.marketoLandingPages.length > 0 &&
                          <Row>
                            <Col xs={6} md={4} lg={3}>
                              <ButtonGroup className="rounded border">
                                <Button
                                  variant={`${this.state.sortType === 'recentLeads' ? 'info': 'light'}`}
                                  size="sm"
                                  data-sort-type="recentLeads"
                                  onClick={this.onSortTypeChange}
                                >Recent Leads</Button>
                                <Button
                                  variant={`${this.state.sortType === 'mostLeads' ? 'info': 'light'}`}
                                  size="sm"
                                  data-sort-type="mostLeads"
                                  onClick={this.onSortTypeChange}
                                >Most Leads</Button>
                              </ButtonGroup>
                              <div
                                className="pre-scrollable bg-white border rounded"
                                style={{ overflow: 'auto' }}
                              >
                                { this.state.marketoLandingPages.map(landingPage => {
                                    return (
                                      <div
                                        key={`c-lp-${landingPage.landing_page_id}`}
                                        className={landingPage.landing_page_id === this.state.selectedLandingPageId ? 'bg-primary text-light rounded px-2 py-1 ellipsis border-bottom' : 'px-2 py-1 ellipsis border-bottom'}
                                        onClick={() => this.onLandingPageSelect(landingPage)}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <div className="ellipsis">
                                          {landingPage.landing_page_name}
                                        </div>
                                        <div
                                          className="ellipsis ml-2"
                                          style={{ fontSize: '.75rem' }}
                                        >
                                          {landingPage.landing_page_url_label}
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </Col>
                            <Col xs={6} md={8} lg={9}>
                              { this.state.selectedLandingPage &&
                                <div>
                                  <Card className="mb-4">
                                    <Card.Header
                                      className="bg-white font-weight-bold"
                                      style={{ fontSize: '1.2rem' }}
                                    >
                                      Landing Page Details
                                    </Card.Header>
                                    <Card.Body>
                                      <Row>
                                        <Col className="text-center" xs={3}>
                                          <div>
                                            <div className="bg-bops-blue text-light font-weight-bold">
                                              Total Views
                                            </div>
                                            <div style={{ fontSize: '1.5rem' }}>
                                              {numberWithCommas(this.state.selectedLandingPage.total_views)}
                                            </div>
                                          </div>
                                          <div>
                                            <div className="bg-bops-blue text-light font-weight-bold">
                                              Total Leads
                                            </div>
                                            <div style={{ fontSize: '1.5rem' }}>
                                              {numberWithCommas(this.state.selectedLandingPage.total_leads)}
                                            </div>
                                          </div>
                                        </Col>
                                        { this.state.selectedLandingPage.landing_page_id !== 'all' &&
                                          <Col xs={9} className="border-left">
                                            <table style={{ fontSize: '.875rem' }}>
                                              <tbody>
                                                <tr>
                                                  <td className="font-weight-bold text-right" style={{ width: '120px' }}>
                                                    Name:
                                                  </td>
                                                  <td className="pl-2">
                                                    {this.state.selectedLandingPage.landing_page_name}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="font-weight-bold text-right" style={{ width: '120px' }}>
                                                    URL:
                                                  </td>
                                                  <td className="pl-2">
                                                    <a href={this.state.selectedLandingPage.landing_page_url} target="_blank">
                                                      Preview
                                                    </a>
                                                  </td>
                                                </tr>
                                                { this.state.selectedLandingPage.landing_page_description &&
                                                  <tr>
                                                    <td className="font-weight-bold text-right" style={{ width: '120px' }}>
                                                      Description:
                                                    </td>
                                                    <td className="pl-2">
                                                      {this.state.selectedLandingPage.landing_page_description}
                                                    </td>
                                                  </tr>
                                                }
                                                <tr>
                                                  <td className="font-weight-bold text-right" style={{ width: '120px' }}>
                                                    Created At:
                                                  </td>
                                                  <td className="pl-2">
                                                    {moment(this.state.selectedLandingPage.landing_page_created_at).format('MM/DD/YYYY')}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </Col>
                                        }
                                      </Row>
                                    </Card.Body>
                                  </Card>
                                  <CardComponent
                                    title="Landing Page Views & Leads"
                                    body={
                                      <ResponsiveContainer height={270} width="99%">
                                        <LineChart data={this.state.selectedLandingPage.data}>
                                          <XAxis
                                            dataKey="captured_at"
                                            tickFormatter={(date) => {
                                              return moment(date).format('MM/DD');
                                            }}
                                            minTickGap={10}
                                          />
                                          <YAxis
                                            allowDecimals={false}
                                          />
                                          <Tooltip labelFormatter={(label) => moment(label).format('dddd, MMMM Do YYYY')}/>
                                          <CartesianGrid strokeDasharray="5 5"/>
                                          <Line name="Views" type="monotone" dataKey="views_count" stroke="#007bff"/>
                                          <Line name="Leads" type="monotone" dataKey="lead_count" stroke="#03ae71"/>
                                        </LineChart>
                                      </ResponsiveContainer>
                                    }
                                    contextType="brand"
                                    contextCategory={
                                      this.props.myCategoryBrands.length > 0 ?
                                        this.props.myCategoryBrands[0].category :
                                        undefined
                                    }
                                    contextBrand={
                                      this.props.myCategoryBrands.length > 0 ?
                                        this.props.myCategoryBrands[0].brand :
                                        undefined
                                    }
                                    contextChannel="Marketo"
                                    contextChartName="Landing Page Views & Leads"
                                  />

                                  { Array.isArray(this.state.selectedLandingPage.program_leads) &&
                                    <div>
                                      <h5 className="my-4 p-2 bg-bops-blue text-light">
                                        Associated Program Leads
                                        <div
                                          className="d-inline-block ml-3"
                                          style={{ fontSize: '.875rem' }}
                                        >
                                          Leads derived from the same Program as this landing page
                                        </div>
                                      </h5>
                                      { this.state.selectedLandingPage.program_leads.length > 0 &&
                                        <Table
                                          className="fixed-header text-center"
                                          size="sm"
                                          striped
                                        >
                                          <thead className="bg-dark text-light">
                                            <tr>
                                              <th style={{ width: '130px' }}>
                                                Month
                                              </th>
                                              <th>
                                                Title
                                              </th>
                                              <th>
                                                Company / Email Domain
                                              </th>
                                              <th style={{ width: '100px' }}>
                                                Stage
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody style={{ maxHeight: '500px' }}>
                                            { this.state.selectedLandingPage.program_leads.map((lead, i) => {
                                                return (
                                                  <tr key={`h-lpl-${i}`}>
                                                    <td style={{ width: '130px' }}>
                                                      {moment(lead.lead_created_at).format('MMMM YYYY')}
                                                    </td>
                                                    <td>
                                                      {lead.job_title || '-'}
                                                    </td>
                                                    <td>
                                                      {lead.company_name || lead.domain_from_email || '-'}
                                                    </td>
                                                    <td style={{ width: '100px' }}>
                                                      {lead.lifecycle_stage || '-'}
                                                    </td>
                                                  </tr>
                                                )
                                              })
                                            }
                                          </tbody>
                                        </Table>
                                      }
                                      { this.state.selectedLandingPage.program_leads.length === 0 &&
                                        <div className="border rounded mb-4 p-4 text-center">
                                          No program leads have been detected.
                                        </div>
                                      }
                                    </div>
                                  }
                                </div>
                              }
                            </Col>
                          </Row>
                        }
                      </div>
                    }
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
};
