import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import ReactTable from "react-table";

export default class NewsResultsDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedNews: undefined
    };
  }

  componentDidMount() {
    if (this.props.selectedNews) {
      this.setState(() => ({ selectedNews: this.props.selectedNews }));
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedNews !== this.props.selectedNews) {
      if (this.newsArticleDisplay) {
        this.newsArticleDisplay.scrollTop = 0;
      }
      this.setState(() => ({ selectedNews: this.props.selectedNews }));
    }
  }

  render () {
    return (
      <Row className="pt-2">
        <Col xs={12} md={7}>
          <div
            className="pre-scrollable"
            style={{
              overflow: 'auto',
              fontSize: '10pt',
              maxHeight: 'calc(100vh - 270px)'
            }}
          >
            <ReactTable
              className="-striped -highlight"
              data={this.props.newsResults}
              columns={[
                {
                    Header: 'Date',
                    accessor: '_source.published_at',
                    sortable: true,
                    width: 70,
                    Cell: row => (
                      <div className="text-center">
                        {moment.utc(row.original._source.published_at).local().format('MM/DD')}
                      </div>
                    )
                }, {
                  Header: 'Title',
                  accessor: '_source.title',
                  sortable: true,
                  Cell: row => (
                    <div
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      dangerouslySetInnerHTML={{ __html: row.original._source.title }}
                    />
                  )
                }, {
                  Header: 'Source',
                  accessor: '_source.domain',
                  sortable: true,
                  width: 150,
                  Cell: row => (
                    <div>
                      <a
                        href={row.original._source.url}
                        target="_blank"
                      >
                        {row.original._source.domain}
                      </a>
                    </div>
                  )
                }
              ]}
              getTrProps={(state, rowInfo) => ({
                onClick: () => {
                  if (this.newsArticleDisplay) {
                    this.newsArticleDisplay.scrollTop = 0;
                  }
                  this.setState({ selectedNews: rowInfo.original })
                },
                style: {
                  background: (
                    rowInfo &&
                    this.state.selectedNews &&
                    rowInfo.original === this.state.selectedNews
                  ) ?
                    '#ADD8E6' :
                    'white'
                }
              })}
              minRows = {1}
              defaultPageSize={20}
              showPagination={true}
              defaultSorted={[{ id: '_source.published_at', desc: true }]}
            />
          </div>
        </Col>
        <Col xs={12} md={5}>
          { this.state.selectedNews &&
            <div
              className="bg-white rounded border p-2"
              style={{
                overflow: 'hidden',
                maxHeight: 'calc(100vh - 270px)'
              }}
            >
              <div className="p-2">
                <b>
                  <div dangerouslySetInnerHTML={{ __html: this.state.selectedNews._source.title }}/>
                </b>
              </div>
              <hr className="m-0"/>
              <div className="pt-2" style={{ fontSize: '11pt' }}>
                { this.state.selectedNews._source.show_site &&
                  <Form>
                    <Form.Control
                      ref={(ref) => this.newsArticleDisplay = ref}
                      className="border-white bg-white"
                      as="textarea"
                      value={this.state.selectedNews._source.text}
                      disabled
                      style={{
                        minHeight: 'calc(100vh - 340px)',
                        maxHeight: 'calc(100vh - 340px)'
                      }}
                    />
                  </Form>
                }
                { !this.state.selectedNews._source.show_site &&
                  <div className="p-2">
                    This mention is on a paid site. You may need a subscription. You may view it
                    <a
                      className="pl-1"
                      href={this.state.selectedNews._source.url}
                      target="_blank"
                    >
                      here
                    </a>
                    .
                  </div>
                }
              </div>
            </div>
          }
        </Col>
      </Row>
    );
  }
};
