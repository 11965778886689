import { connect } from 'react-redux';
import MailChimpSetup from '../../../components/settings/contentCollectors/MailChimpSetup';

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(MailChimpSetup);
