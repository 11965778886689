import { connect } from 'react-redux';
import Leaderboard from '../../components/objectives/Leaderboard';

const mapStateToProps = (state) => ({
  user: state.user,
  myCategoryBrands: state.myCategoryBrands,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
