import React from 'react';
import ProfileTab from './ProfileTab';
import EmployerReviewsTab from './EmployerReviewsTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { createUserActivityLog } from '../../../utils/users';
import history from '../../../routers/history';

export default class CompanyTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'profile'
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let selectedTab = hash.indexOf('-')>0 ? hash.substring(hash.indexOf('-')+1) : 'profile';
      this.setState(() => ({ selectedTab }));
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps) {

  };

  changeSelectedTab = (selectedTab, subTabName) => {
    history.push(`#company-${selectedTab}`);
    this.setState(() => ({ selectedTab }));
    if (subTabName) {
      createUserActivityLog(
        this.props.user,
        `Brand Tabs: Company: ${subTabName}`,
        this.props.category,
        this.props.brand,
      )
    }
  }

  render () {
    return (
      <div className="p-4">
        <div className="mb-4">
          <button
            id="brandProfileButton"
            type="button"
            className= { this.state.selectedTab === 'profile' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('profile', 'Profile')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon
                icon={faAddressCard}
                color="#6c757d"
              />
            </div>
            <div className="pr-2 d-inline-block">
              Profile
            </div>
          </button>
          <button
            id="brandEmployerReviewsButton"
            type="button"
            className= { this.state.selectedTab === 'employerReviews' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('employerReviews', 'Employer Reviews')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon
                icon={faStarHalfAlt}
                color="#6c757d"
              />
            </div>
            <div className="pr-2 d-inline-block">
              Employer Reviews
            </div>
          </button>
        </div>
        <div
          className="pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 200px)',
            maxHeight: 'calc(100vh - 200px)',
            overflow: 'auto',
            overflowX: 'hidden'
          }}
        >
          { this.state.selectedTab === 'profile' &&
            <div>
              <ProfileTab
                user={this.props.user}
                category={this.props.category}
                brand={this.props.brand}
              />
            </div>
          }
          { this.state.selectedTab === 'employerReviews' &&
            <div>
              <EmployerReviewsTab
                sixMonths={this.props.sixMonths}
                selectedMonth={this.props.selectedMonth}
                updateSelectedMonth={this.props.updateSelectedMonth}
                user={this.props.user}
                customerId={this.props.user.customerId}
                categoryId={this.props.categoryId}
                categoryType={this.props.categoryType}
                category={this.props.category}
                brandId={this.props.brandId}
                brand={this.props.brand}
                brandName={this.props.brand.name}
                brandChannelScoresMap={this.props.brandChannelScoresMap}
                brandChannelScoresMapLoading={this.props.brandChannelScoresMapLoading}
                allMonthsChecked={this.props.allMonthsChecked}
                updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                allMonthsStartDate={this.props.allMonthsStartDate}
              />
            </div>
          }
        </div>
      </div>
    );
  }
};
