import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

export default class SurveyPrepareLaunch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment(),
      endDate: moment().add(30, 'day'),
      errors: {},
      errorMessage: ''
    };
  };

  onPreviousButtonClick = () => {
    this.props.onChangeSurveyStep('preview');
  };

  isValidForm = () => {
    const errors = {};
    let errorMessage = '';
    if (this.props.surveyParticipants.length < 1) {
      errors.surveyParticipants = true;
      errorMessage = 'Please upload a valid .csv of survey participants.';
    }

    this.setState(() => ({
      errors,
      errorMessage
    }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  onLaunchClick = () => {
    if (this.isValidForm()) {
      this.props.launchSurvey(
        this.state.startDate.format('YYYY-MM-DD HH:mm:ss'),
        this.state.endDate.format('YYYY-MM-DD HH:mm:ss')
      );
    }
  };

  render () {
    return (
      <div>
        <div>
          Upload Survey Participants (use csv containing: first name, last name, email)
        </div>
        <div>
          <div className="d-inline-block align-top">
            <span
              className="btn btn-secondary btn-file"
              style={{ cursor: 'pointer' }}
            >
              <FontAwesomeIcon className="mr-1" icon={faUpload} size="lg"/>
              <input
                type="file"
                multiple={false}
                accept=".csv"
                onChange={this.props.onSurveyParticipantsUpload}
              />
              Upload
            </span>
            { this.props.surveyParticipantsFileName &&
              <div style={{ fontSize: '.75rem' }}>
                {this.props.surveyParticipantsFileName}
              </div>
            }
          </div>
          { this.props.surveyParticipants.length > 0 &&
            <div
              className="d-inline-block"
              style={{ width: '400px' }}
            >
              <div className="ml-2">
                <b>Preview</b>
              </div>
              <Table
                className="ml-2 mb-0 bg-white"
                bordered
                responsive="sm"
                size="sm"
                style={{
                  fontSize: '.75rem'
                }}
              >
                <thead>
                  <tr>
                    <th>
                      First Name
                    </th>
                    <th>
                      Last Name
                    </th>
                    <th>
                      Email
                    </th>
                  </tr>
                </thead>
                <tbody>
                  { this.props.surveyParticipants.map((participant, i) => {
                      if (i < 5) {
                        return (
                          <tr key={`p-${i}`}>
                            <td>{participant.first_name}</td>
                            <td>{participant.last_name}</td>
                            <td>{participant.email}</td>
                          </tr>
                        )
                      }
                    })
                  }
                </tbody>
              </Table>
            </div>
          }
        </div>
        <div className="mt-2">
          <Form.Group>
            <Form.Label>Wave Description</Form.Label>
            <Form.Control
              as="textarea"
              value={this.props.waveDescription}
              onChange={this.props.onWaveDescriptionChange}
            />
          </Form.Group>
        </div>
        <div className="mt-2">
          <div className="d-inline-block">
            <span className="mr-2">
              Start Date
            </span>
            <SingleDatePicker
              id="startDatePicker"
              date={this.state.startDate}
              onDateChange={startDate => this.setState(({ startDate }))}
              focused={this.state.startDateFocused}
              onFocusChange={({ focused }) => this.setState(() => ({ startDateFocused: focused }))}
              small
            />
          </div>
          <div className="d-inline-block ml-4">
            <span className="mx-2">End Date</span>
            <SingleDatePicker
              id="endDatePicker"
              date={this.state.endDate}
              onDateChange={endDate => this.setState(({ endDate }))}
              focused={this.state.endDateFocused}
              onFocusChange={({ focused }) => this.setState(() => ({ endDateFocused: focused }))}
              small
            />
          </div>
        </div>
        <Alert className="mt-2" show={Boolean(this.state.errorMessage)} variant="danger">
          {this.state.errorMessage}
        </Alert>
        <div className="mt-2">
          <Button
            variant="outline-secondary"
            onClick={this.onPreviousButtonClick}
          >
            Previous
          </Button>
          <Button
            className="ml-2"
            variant="success"
            onClick={this.onLaunchClick}
          >
            Launch
          </Button>
        </div>
      </div>
    );
  }
};
