import React from 'react';
import moment from 'moment';
import DateRangePicker from '../../common/DateRangePicker';
import BrandChannelSummaryContainer from '../../../containers/brands/BrandChannelSummaryContainer';
import SocialSentiment from '../../topics/components/SocialSentiment';
import CommentVolume from '../../topics/components/CommentVolume';
import CommentPromoterDetractor from '../../topics/components/CommentPromoterDetractor';
import SentimentIntensity from '../../topics/components/SentimentIntensity';
import SocialStreamSentiment from '../../topics/components/SocialStreamSentiment';

export default class AllSocialTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.scrollToDetails &&
      this.props.socialScroll &&
      this.props.socialScroll.scrollHeight > 1000 &&
      this.allSocialDetails
    ) {
      this.props.socialScroll.scrollTop = this.allSocialDetails.offsetTop - this.props.socialScroll.offsetTop;
      this.props.unsetScrollToDetails();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  render () {
    return (
      <div>
        <div>
          <BrandChannelSummaryContainer
            channel="Social"
            overviewOverride="Social"
            category={this.props.category}
            brand={this.props.brand}
          />
        </div>
        <h5
          ref={(ref) => this.allSocialDetails = ref}
          className="my-2 p-2 bg-bops-blue text-light"
        >
          Details
          <span
            className="ml-4 mr-2"
            style={{ fontSize: '1rem', fontWeight: 'normal' }}
          >Date Range</span>
          <DateRangePicker
            startDate={this.props.startDateFilter}
            endDate={this.props.endDateFilter}
            updateDates={this.props.onFilterDatesChange}
          />
        </h5>
        <div>
          <SocialSentiment
            customerId={this.props.customerId}
            category={this.props.category}
            topicId={this.props.topicId}
            brandId={this.props.brandId}
            brand={this.props.brand}
            startDateFilter={this.props.startDateFilter}
            endDateFilter={this.props.endDateFilter}
          />
        </div>
        <div className="mt-4">
          <CommentVolume
            category={this.props.category}
            brand={this.props.brand}
            topicId={this.props.topicId}
            startDateFilter={this.props.startDateFilter}
            endDateFilter={this.props.endDateFilter}
          />
        </div>
        <div className="mt-4">
          <CommentPromoterDetractor
            category={this.props.category}
            brand={this.props.brand}
            topicId={this.props.topicId}
            startDateFilter={this.props.startDateFilter}
            endDateFilter={this.props.endDateFilter}
          />
        </div>
        <div className="mt-4">
          <SentimentIntensity
            category={this.props.category}
            brand={this.props.brand}
            topicId={this.props.topicId}
            startDateFilter={this.props.startDateFilter}
            endDateFilter={this.props.endDateFilter}
          />
        </div>
        <div className="mt-4">
          <SocialStreamSentiment
            category={this.props.category}
            brand={this.props.brand}
            topicId={this.props.topicId}
            startDateFilter={this.props.startDateFilter}
            endDateFilter={this.props.endDateFilter}
          />
        </div>
      </div>
    );
  }
};
