import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import CardComponent from '../common/CardComponent';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class InstagramSignificantTerms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      significantTermsData: [],
      significantTermsDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.loadSignificantTermsData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.brand !== this.props.brand ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter
    ) {
      this.loadSignificantTermsData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  loadSignificantTermsData = () => {
    if (this.props.brand && this.props.startDateFilter && this.props.endDateFilter) {
      this.setState(() => ({ significantTermsDataLoading: true }));
      const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/instagram-posts/significant-terms?company_id=${this.props.brand.company_id}&product_brand_id=${this.props.brand.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}&limit=10`,
        HEADERS
      ).then(response => {
        const significantTermsData = response.data;
        significantTermsData.sort((a, b) => b.doc_count - a.doc_count);
        if (this.state.isMounted) {
          this.setState(() => ({
            significantTermsData,
            significantTermsDataLoading: false
          }));
        }
      }).catch(error => {
        this.setState(() => ({
          significantTermsData: [],
          significantTermsDataLoading: false
        }));
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <CardComponent
        title="Significant / Common Terms"
        icon={
          <img
            className="mr-2 align-top"
            src="/images/Significant_Common_Terms_Icon.png"
          />
        }
        description="Significant / Common Terms allows you to view trending significant terms"
        body={
          <div>
            { this.state.significantTermsDataLoading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.significantTermsDataLoading &&
              <div>
                { this.state.significantTermsData.length === 0 &&
                  <div className="p-4 text-center">
                    Not enough data collected
                  </div>
                }
                { this.state.significantTermsData.length > 0 &&
                  <ResponsiveContainer height={350} width="98%">
                    <BarChart
                      data={this.state.significantTermsData}
                      layout="vertical"
                    >
                      <XAxis
                        type="number"
                        tickFormatter={(count) => {
                          return numberWithCommas(count);
                        }}
                      />
                      <YAxis type="category" dataKey="key" width={175}/>
                      <Tooltip cursor={false} />
                      <Bar name="count" dataKey="doc_count" fill="#b3b3b3"/>
                    </BarChart>
                  </ResponsiveContainer>
                }
              </div>
            }
          </div>
        }
        contextType="brand"
        contextCategory={this.props.category}
        contextBrand={this.props.brand}
        contextChannel="Social"
        contextProperty="Instagram"
        contextChartName="Significant / Common Terms"
      />
    );
  }
};
