import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import ListGroup from 'react-bootstrap/ListGroup';
import LoginContainer from '../../../containers/common/LoginContainer';
import AdminAuthenticationMessage from '../../common/AdminAuthenticationMessage';
import { isAuthenticatedAdmin } from '../../../utils/auth';
import ClipLoader from 'react-spinners/ClipLoader';
import RssPreview from './RssPreview';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';
import { sortAlphabeticalByKey } from '../../../utils/sorts';
import { numberWithCommas } from '../../../utils/numbers';

export default class NewsCollector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newsSites: [],
      newsSitesLoading: false,
      selectedNewsSite: undefined,
      selectedNewsSiteRssFeedsLoading: false,
      selectedNewsSiteRssFeeds: [],
      awisNewsSiteStats: undefined,
      awisNewsSiteStatsLoading: false,
      newNewsSiteFormDisplay: false,
      newDomain: '',
      rssFeedLinksMap: {},
      rssPreviewUrl: undefined,
      saveLoading: false,
      saveErrorMessage: '',
      errors: {}
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchNewsSitesData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  fetchNewsSitesData = () => {
    this.setState(() => ({ newsSitesLoading: true }));
    axios.get(
      `${CONTENT_SCRAPING_ENDPOINT}/api/news-sites`,
      HEADERS
    ).then(response => {
      const newsSitesData = response.data;
      const newsSitesDomainMap = {};
      const newsSites = [];
      let selectedNewsSite;
      for (const newsSite of newsSitesData) {
        if (!newsSitesDomainMap[newsSite.domain]) {
          newsSitesDomainMap[newsSite.domain] = true;
          if (this.state.selectedNewsSite && this.state.selectedNewsSite.domain === newsSite.domain) {
            selectedNewsSite = newsSite;
          }
          newsSites.push(newsSite);
        }
      }
      newsSites.sort(sortAlphabeticalByKey('domain'));

      if (this.state.isMounted) {
        this.setState(() => ({
          newsSites,
          newsSitesLoading: false
        }), () => this.fetchDomainResources());
      }
    }).catch(error => {
      console.error('Error: unable to fetch news sites...');
      if (this.state.isMounted) {
        this.setState(() => ({
          newsSites: [],
          newsSitesLoading: false
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    })
  };

  onSelectNewsSite = (selectedNewsSiteArray) => {
    const selectedNewsSite = selectedNewsSiteArray[0];
    if (selectedNewsSite && selectedNewsSite.customOption) {
      this.setState(() => ({
        newDomain: selectedNewsSite.domain
      }));
      this.fetchRssLinksByDomain(selectedNewsSite.domain);
    } else {
      this.setState(
        () => ({
          selectedNewsSite,
          newNewsSiteFormDisplay: false,
        }),
        () => this.fetchDomainResources()
      );
    }
  };

  fetchDomainResources = () => {
    this.fetchDomainRssFeeds();
    this.fetchAwisNewsSiteStats();
  };

  fetchDomainRssFeeds = () => {
    if (this.state.selectedNewsSite) {
      this.setState(() => ({ selectedNewsSiteRssFeedsLoading: true }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/rss-feed-links?domain=${this.state.selectedNewsSite.domain}`,
        HEADERS
      ).then(response => {
        const selectedNewsSiteRssFeeds = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            selectedNewsSiteRssFeeds,
            selectedNewsSiteRssFeedsLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch news site rss feeds...');
        if (this.state.isMounted) {
          this.setState(() => ({
            selectedNewsSiteRssFeeds: [],
            selectedNewsSiteRssFeedsLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }  else {
      this.setState(() => ({ selectedNewsSiteRssFeeds: [] }));
    }
  };

  fetchAwisNewsSiteStats = () => {
    if (this.state.selectedNewsSite) {
      this.setState(() => ({ awisNewsSiteStatsLoading: true }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/awis-news-sites-stat?domain_name=${this.state.selectedNewsSite.domain}`,
        HEADERS
      ).then(response => {
        const awisNewsSiteStats = response.data[0];
        if (this.state.isMounted) {
          this.setState(() => ({
            awisNewsSiteStats: awisNewsSiteStats || {},
            awisNewsSiteStatsLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch news site awis stats...');
        if (this.state.isMounted) {
          this.setState(() => ({
            awisNewsSiteStats: {},
            awisNewsSiteStatsLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    } else {
      this.setState(() => ({ awisNewsSiteStats: undefined }));
    }
  };

  fetchRssLinksByDomain = (domain) => {
    if (domain) {
      this.setState(() => ({
        newNewsSiteFormDisplay: true,
        awisNewsSiteStats: undefined,
        rssFeedLinks: [],
        rssFeedLinksLoading: true,
      }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/rss-links-finder?url=${domain}`,
        HEADERS
      ).then(response => {
        const rssFeedLinks = response.data;
        const rssFeedLinksMap = {};
        for (const rfl of rssFeedLinks) {
          const id = Math.random().toString(36).substring(7);
          rssFeedLinksMap[id] = {
            loading: false,
            rss_feed_link: rfl,
            validated: true
          };
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            rssFeedLinksMap,
            rssFeedLinksLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to find rss links...');
        if (this.state.isMounted) {
          this.setState(() => ({
            rssFeedLinksMap: {},
            rssFeedLinksLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  addRssFeedLink = () => {
    const id = Math.random().toString(36).substring(7);
    this.setState((prevState) => ({
      rssFeedLinksMap: {
        ...prevState.rssFeedLinksMap,
        [id]: {
          loading: false,
          rss_feed_link: '',
          validated: false
        }
      }
    }));
  };

  removeRssFeedLink = (id) => {
    this.setState((prevState) => {
      let rssFeedLinksMap = { ...prevState.rssFeedLinksMap };
      delete rssFeedLinksMap[id];
      return ({ rssFeedLinksMap });
    });
  };

  onRssFeedLinkChange = (id, value) => {
    this.setState((prevState) => {
      let rssFeedLinksMap = { ...prevState.rssFeedLinksMap };
      rssFeedLinksMap[id].rss_feed_link = value;
      rssFeedLinksMap[id].validated = false;
      return ({ rssFeedLinksMap });
    });
  };

  validateUrl = (id, url) => {
    if (id && url) {
      this.setState((prevState) => {
        let rssFeedLinksMap = { ...prevState.rssFeedLinksMap };
        rssFeedLinksMap[id].loading = true;
        rssFeedLinksMap[id].error = false;
        return ({ rssFeedLinksMap });
      });
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/scrape-rss?url=${url}`,
        HEADERS
      ).then(response => {
        const rssFeeds = response.data;
        if (rssFeeds.length > 0) {
          this.previewRss(rssFeeds[0]);
          if (this.state.isMounted) {
            this.setState((prevState) => {
              let rssFeedLinksMap = { ...prevState.rssFeedLinksMap };
              rssFeedLinksMap[id].loading = false;
              rssFeedLinksMap[id].validated = true;
              return ({ rssFeedLinksMap });
            });
          }
        } else {
          console.error('Error: validate failed to find rss feed');
          if (this.state.isMounted) {
            this.setState((prevState) => {
              let rssFeedLinksMap = { ...prevState.rssFeedLinksMap };
              rssFeedLinksMap[id].loading = false;
              rssFeedLinksMap[id].validated = false;
              rssFeedLinksMap[id].error = 'Unable to find RSS feed.';
              return ({ rssFeedLinksMap });
            });
          }
        }
      }).catch(error => {
        console.error('Error: failed to validate news rss url...');
        if (this.state.isMounted) {
          this.setState((prevState) => {
            let rssFeedLinksMap = { ...prevState.rssFeedLinksMap };
            rssFeedLinksMap[id].loading = false;
            rssFeedLinksMap[id].validated = false;
            rssFeedLinksMap[id].error = 'Failed to validate RSS URL.';
            return ({ rssFeedLinksMap });
          });
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  previewRss = (rssPreviewUrl) => {
    if (rssPreviewUrl !== this.state.rssPreviewUrl) {
      this.setState(() => ({ rssPreviewUrl }));
    }
  };

  isValidFormCheck = () => {
    let validForm = true;
    for (let [id, rssFeedLink] of Object.entries(this.state.rssFeedLinksMap)) {
      if (!rssFeedLink.validated) {
        validForm = false;
      }
    }
    return validForm;
  };

  saveNewsSite = () => {
    if (this.state.newDomain) {
      this.setState(() => ({
        saveLoading: true,
        saveErrorMessage: '',
      }));
      const newNewsSite = {
        domain: this.state.newDomain,
        rss_links: []
      };
      for (let [id, rssFeedLink] of Object.entries(this.state.rssFeedLinksMap)) {
        if (rssFeedLink.validated) {
          newNewsSite.rss_links.push(rssFeedLink.rss_feed_link);
        }
      }
      axios.post(
        `${CONTENT_SCRAPING_ENDPOINT}/api/news-sites`,
        newNewsSite,
        HEADERS
      ).then(response => {
        if (this.state.isMounted) {
          this.setState(() => ({
            newsSites: [],
            newsSitesLoading: false,
            selectedNewsSite: response.data.news_site,
            awisNewsSiteStats: undefined,
            awisNewsSiteStatsLoading: false,
            newNewsSiteFormDisplay: false,
            newDomain: '',
            rssFeedLinksMap: {},
            rssPreviewUrl: undefined,
            saveLoading: false,
            saveErrorMessage: '',
            errors: {}
          }), () => this.fetchNewsSitesData());
        }
      }).catch(error => {
        console.error('Error: failed to create news site...');
        if (this.state.isMounted) {
          this.setState(() => ({
            saveLoading: false,
            saveErrorMessage: 'Failed to create news site'
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="m-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        <AdminAuthenticationMessage user={this.props.user}/>
        { isAuthenticatedAdmin(this.props.user) &&
          <div>
            <div
              className="border rounded p-4"
              style={{ backgroundColor: '#e9ecef' }}
            >
              <div className="d-inline-block pr-2">
                <FontAwesomeIcon icon={faNewspaper} size="2x" color="#454d54" />
              </div>
              <div className="d-inline-block">
                <h4>News Sites</h4>
              </div>
              <p>We gather articles from a broad array of general global and national news sites.</p>
              <hr className="my-4" />
              <p>Collect industry relevant data by adding news sites that are specific to your brand.</p>
            </div>

            <div className="mt-4">
              <Row>
                <Col>
                  { this.state.newsSitesLoading &&
                    <div className="m-4 text-center">
                      <ClipLoader size={100}/>
                    </div>
                  }
                  { !this.state.newsSitesLoading &&
                    <div>
                      <Typeahead
                        id="news-sites-typeahead"
                        placeholder="Search domain"
                        labelKey="domain"
                        allowNew={true}
                        newSelectionPrefix="Add New: "
                        onChange={this.onSelectNewsSite}
                        options={this.state.newsSites}
                        selected={this.state.selectedNewsSite ? [this.state.selectedNewsSite] : []}
                      />
                    </div>
                  }
                  { this.state.selectedNewsSite &&
                    <div
                      className="pre-scrollable"
                      style={{
                        maxHeight: 'calc(100vh - 375px)',
                        overflow: 'auto',
                        overflowX: 'hidden'
                      }}
                    >
                      <Alert className="my-2" variant="info">
                        {`${this.state.selectedNewsSite.domain} is a news site that BrandOps currently monitors.`}
                      </Alert>
                      <Card>
                        <Card.Header className="bg-white" style={{ fontWeight: 'bold' }}>
                          RSS Feeds
                        </Card.Header>
                        <Card.Body>
                          { this.state.selectedNewsSiteRssFeedsLoading &&
                            <div className="m-4 text-center">
                              <ClipLoader size={25}/>
                            </div>
                          }
                          { !this.state.selectedNewsSiteRssFeedsLoading &&
                            <ListGroup>
                              { this.state.selectedNewsSiteRssFeeds.map((rssFeedLink, i) => {
                                  return (
                                    <ListGroup.Item key={`sns-rss-${rssFeedLink.id}`}>
                                      <Row noGutters>
                                        <Col>
                                          {rssFeedLink.rss_feed_links}
                                        </Col>
                                        <Col style={{ flex: '0 0 60' }}>
                                          <Button
                                            className="px-1 py-0 mr-2 float-right"
                                            size="sm"
                                            variant="info"
                                            onClick={() => this.previewRss(rssFeedLink.rss_feed_links)}
                                          >
                                            Preview
                                          </Button>
                                        </Col>
                                      </Row>
                                    </ListGroup.Item>
                                  )
                                })
                              }
                            </ListGroup>
                          }
                        </Card.Body>
                      </Card>
                      <Card className="mt-2">
                        <Card.Header className="bg-white" style={{ fontWeight: 'bold' }}>
                          Site Statistics
                        </Card.Header>
                        <Card.Body>
                          { this.state.awisNewsSiteStatsLoading &&
                            <div className="m-4 text-center">
                              <ClipLoader size={25}/>
                            </div>
                          }
                          { (!this.state.awisNewsSiteStatsLoading && this.state.awisNewsSiteStats) &&
                            <div>
                              <Row noGutters>
                                <Col style={{ flex: '0 0 250px' }}>
                                  Avg. Traffic Rank (last 3 month):
                                </Col>
                                <Col>
                                  {numberWithCommas(this.state.awisNewsSiteStats.average_traffic_rank_3_months) || 'N/A'}
                                </Col>
                              </Row>
                              <Row noGutters>
                                <Col style={{ flex: '0 0 250px' }}>
                                  Views per Million (last 7 days):
                                </Col>
                                <Col>
                                  {numberWithCommas(this.state.awisNewsSiteStats.last_7_days_views_per_million) || 'N/A'}
                                </Col>
                              </Row>
                            </div>
                          }
                        </Card.Body>
                      </Card>
                    </div>
                  }
                  { this.state.newNewsSiteFormDisplay &&
                    <div className="mt-4">
                      <div className="mb-2">
                        <div className="d-inline-block mr-2" style={{ fontWeight: 'bold' }}>
                          New Domain:
                        </div>
                        <div className="d-inline-block">
                          {this.state.newDomain}
                        </div>
                      </div>
                      { this.state.rssFeedLinksLoading &&
                        <div className="m-4 text-center">
                          <ClipLoader size={25}/>
                        </div>
                      }
                      { !this.state.rssFeedLinksLoading &&
                        <div>
                          <div className="mb-2">
                            <b>RSS Feeds</b>
                            <Button
                              className="float-right"
                              variant="success"
                              onClick={this.addRssFeedLink}
                              size="sm"
                            >
                              Add RSS Feed
                            </Button>
                          </div>
                          { Object.entries(this.state.rssFeedLinksMap).map(([id, rssFeedLink], i) => {
                              return (
                                <div key={`rfl-${id}`} className="mb-1">
                                  <Form.Row>
                                    <Col>
                                      <Form.Control
                                        type="text"
                                        value={rssFeedLink.rss_feed_link}
                                        onChange={(event) => this.onRssFeedLinkChange(id, event.currentTarget.value)}
                                        isInvalid={this.state.errors[id]}
                                        placeholder="News RSS Feed - Full URL"
                                        size="sm"
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {this.state.errors[id]}
                                      </Form.Control.Feedback>
                                    </Col>
                                    <Col style={{ flex: '0 0 140px' }} className="text-right">
                                      { rssFeedLink.validated &&
                                        <FontAwesomeIcon
                                          className="mr-2"
                                          icon={faCheck}
                                          color="green"
                                        />
                                      }
                                      <Button
                                        className="px-1 py-0 mr-2"
                                        size="sm"
                                        variant="info"
                                        onClick={
                                          rssFeedLink.validated ?
                                            () => this.previewRss(rssFeedLink.rss_feed_link) :
                                            () => this.validateUrl(id, rssFeedLink.rss_feed_link)
                                        }
                                        disabled={
                                          rssFeedLink.loading ||
                                          rssFeedLink.rss_feed_link.trim() === ''
                                        }
                                      >
                                        { rssFeedLink.loading &&
                                          <span>
                                            <ClipLoader
                                              size={10}
                                              color="ffffff"
                                            />
                                          </span>
                                        }
                                        { !rssFeedLink.loading &&
                                          <React.Fragment>
                                            { rssFeedLink.validated &&
                                              <span>Preview</span>
                                            }
                                            { !rssFeedLink.validated &&
                                              <span>Validate</span>
                                            }
                                          </React.Fragment>
                                        }
                                      </Button>
                                      <Button
                                        className="px-1 py-0"
                                        size="sm"
                                        variant="danger"
                                        onClick={() => this.removeRssFeedLink(id)}
                                      >
                                        <FontAwesomeIcon icon={faTimes}/>
                                      </Button>
                                    </Col>
                                  </Form.Row>
                                </div>
                              )
                            })
                          }
                          { Object.keys(this.state.rssFeedLinksMap).length === 0 &&
                            <div className="mt-4">
                              No RSS Feeds found.
                            </div>
                          }
                          { Object.keys(this.state.rssFeedLinksMap).length > 0 &&
                            <div>
                              <Alert show={Boolean(this.state.saveErrorMessage)} variant="danger">
                                {this.state.saveErrorMessage}
                              </Alert>
                              <Button
                                className="primary"
                                onClick={this.saveNewsSite}
                                disabled={!this.isValidFormCheck() || this.state.saveLoading}
                              >
                                Save
                              </Button>
                            </div>
                          }
                        </div>
                      }
                    </div>
                  }
                </Col>
                <Col>
                  { this.state.rssPreviewUrl &&
                    <RssPreview
                      rssUrl={this.state.rssPreviewUrl}
                      itemLimit={3}
                      typeLabel="news"
                    />
                  }
                </Col>
              </Row>
            </div>
          </div>
        }
      </div>
    );
  }
};
