import React from 'react';
import axios from 'axios';
import moment from 'moment';
import GeneralSummaryGraph from '../graphs/GeneralSummaryGraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class FollowersSummaryGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companyLinkedInFollowersTrendData: [],
      linkedInFollowersScoreType: undefined,
      totalFollowers: undefined,
      companyLinkedInFollowersTrendDataLoading: false,
      categoryAverageFollowersData: [],
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getCompanyLinkedInFollowersTrendData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.companyId !== this.props.companyId ||
      prevProps.allMonthsChecked !== this.props.allMonthsChecked
    ) {
      this.getCompanyLinkedInFollowersTrendData();
    }
  }

  getCompanyLinkedInFollowersTrendData = () => {
    if (this.props.brand) {
      this.setState(() => ({ companyLinkedInFollowersTrendDataLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/product-brands/${this.props.brand.id}/linkedin-followers-trend${allMonthsStartDateParam ? `?start-date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let companyLinkedInFollowersTrendData = response.data.monthly_stats;
        const linkedInFollowersScoreType = response.data.net_followers_gain ? response.data.net_followers_gain.score_type : undefined;
        const totalFollowers = companyLinkedInFollowersTrendData[companyLinkedInFollowersTrendData.length-1] ? companyLinkedInFollowersTrendData[companyLinkedInFollowersTrendData.length-1].followers_count : undefined;
        // merge category average data into original data source
        companyLinkedInFollowersTrendData = companyLinkedInFollowersTrendData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAverageFollowersData[i]))
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            companyLinkedInFollowersTrendData,
            linkedInFollowersScoreType,
            totalFollowers,
            companyLinkedInFollowersTrendDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch company linkedin followers trend data');
        if (this.state.isMounted) {
          this.setState(() => ({
            companyLinkedInFollowersTrendData: [],
            linkedInFollowersScoreType: undefined,
            totalFollowers: undefined,
            companyLinkedInFollowersTrendDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=linkedin_avg_net_new_followers${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAverageFollowersData = response.data.category_stats;
          // merge category average data into original data source
          const companyLinkedInFollowersTrendData = this.state.companyLinkedInFollowersTrendData.map((x, i) => {
            return (Object.assign({}, x, categoryAverageFollowersData[i]))
          });

          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAverageFollowersData,
              companyLinkedInFollowersTrendData
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average linkedin posts data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAveragePostsData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  }

  render () {
    return (
      <div>
        <GeneralSummaryGraph
          icon={
            <FontAwesomeIcon
              className="mr-2"
              icon={faUserPlus}
              color="#4fa2f3"
            />
          }
          title="Net New Followers"
          metricName="net_new_followers_linkedin"
          data={this.state.companyLinkedInFollowersTrendData}
          dataKey="net_followers_gain"
          dataLabel="Net New Followers"
          scoreType={this.state.linkedInFollowersScoreType}
          loading={this.state.companyLinkedInFollowersTrendDataLoading}
          allowDecimals={false}
          showPercentChange={true}
          showAverageData={true}
          averageDataKey="linkedin_avg_net_new_followers"
          averageDataLabel="Category Average"
          showTooltip={true}
          contextType="brand"
          contextCategory={this.props.category}
          contextBrand={this.props.brand}
          contextChannel="Social"
          contextProperty="LinkedIn"
          contextChartName="Net New Followers"
          shareClassName="linkedin-net-new-followers-img"
        />
      </div>
    );
  }
};
