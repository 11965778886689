import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { LAMBDA_ENDPOINT, HEADERS } from '../../utils/constants';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nodeEnv: process.env.NODE_ENV,
      hasError: false
    };
  }

  componentDidCatch(error, errorInfo) {
    // TODO: This does not alleviate the issue
    // if (error.message === 'WebAssembly.Memory(): could not allocate memory') {
    //   this.props.setShowRefreshAlert(true);
    // }
    // Display fallback UI
    this.setState(() => ({ hasError: true }));
    if (this.state.nodeEnv === 'production') {
      // Send to github issue
      const issue = {
        repo: 'LegendaryAI/BrandOpsUIErrors',
        title: this.props.location.pathname,
        body: `${error.stack}\n\nUser: ${this.props.user.email}`,
        labels: ['bug'],
        assignee: 'bears4barrett'
      };
      axios.post(
        `${LAMBDA_ENDPOINT}/github-issue`,
        issue,
        HEADERS
      ).then(response => {
        // do nothing
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState(() => ({ hasError: false }));
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="p-4">
          <h3>
            <FontAwesomeIcon
              className="mr-2"
              icon={faExclamationTriangle}
              color="red"
            />
            Oops, something went wrong
          </h3>
          <h4>We are looking into it!</h4>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
