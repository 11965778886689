import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../common/CardComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarHalfAlt, faRss, faEnvelope, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faLinkedin, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class ProfileOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profileOverviewData: {},
      profileOverviewDataLoading: false,
      profileCollectorsData: {},
      profileCollectorsDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getProfileOverviewData();
    this.getProfileCollectorsData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.category !== this.props.category ||
      prevProps.brand !== this.props.brand
    ) {
      this.getProfileOverviewData();
      this.getProfileCollectorsData();
    }
  }

  getProfileOverviewData = () => {
    if (this.props.brand) {
      this.setState(() => ({ profileOverviewDataLoading: true }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/linkedin-descriptions?company_id=${this.props.brand.company_id}`,
        HEADERS
      ).then(response => {
        let profileOverviewData = {};
        if (response.data.length > 0) {
          profileOverviewData = response.data[0];
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            profileOverviewData,
            profileOverviewDataLoading: false
          }))
        }
      }).catch(error => {
        console.error('Error: unable to fetch company linkedin accounts...');
        if (this.state.isMounted) {
          this.setState(() => ({
            profileOverviewData: {},
            profileOverviewDataLoading: false
          }))
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  getProfileCollectorsData = () => {
    if (this.props.brand) {
      this.setState(() => ({ profileCollectorsDataLoading: true }));
      const profileDataRequests = [];
      // twitter account
      profileDataRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/twitter-accounts?company_id=${this.props.brand.company_id}`,
          HEADERS
        ).then(response => {
          const twitterAccounts = response.data;
          for (const ta of twitterAccounts) {
            if (ta.account_type === 'primary') {
              return {
                twitter: `https://twitter.com/${ta.screen_name}`
              };
            }
          }
          return { twitter: undefined };
        }).catch(error => {
          console.error('Error: failed to fetch twitter accounts...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { twitter: undefined };
        })
      );
      // linkedin account
      profileDataRequests.push(
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/company-linkedin-account?company_id=${this.props.brand.company_id}`,
          HEADERS
        ).then(response => {
          const linkedInAccounts = response.data;
          let linkedIn;
          if (linkedInAccounts.length > 0) {
            linkedIn = linkedInAccounts[0].linkedin_url;
          }
          return { linkedIn };
        }).catch(error => {
          console.error('Error: unable to fetch company linkedin accounts...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { linkedIn: undefined };
        })
      );
      // facebook account
      profileDataRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/facebook-urls?topic_id=${this.props.brand.topic.id}`,
          HEADERS
        ).then(response => {
          const facebookAccounts = response.data;
          let facebook;
          if (facebookAccounts.length > 0) {
            facebook = facebookAccounts[0].url;
          }
          return { facebook };
        }).catch(error => {
          console.error('Error: failed to fetch facebook accounts...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { facebook: undefined };
        })
      );
      // youtube account
      profileDataRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/company-youtube-channels?company_id=${this.props.brand.company_id}`,
          HEADERS
        ).then(response => {
          const youtubeAccounts = response.data;
          let youtube;
          if (youtubeAccounts.length > 0) {
            youtube = youtubeAccounts[0].channel_url;
          }
          return { youtube };
        }).catch(error => {
          console.error('Error: failed to fetch youtube accounts...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { youtube: undefined };
        })
      );
      // blog
      profileDataRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/product-brand/${this.props.brand.id}/blog-home-pages`,
          HEADERS
        ).then(response => {
          const blogs = response.data;
          let blog;
          if (blogs.length > 0) {
            blog = blogs[0].url;
          }
          return { blog };
        }).catch(error => {
          console.error('Error: failed to fetch blogs...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { blog: undefined };
        })
      );
      // emails
      const formattedStartDate = moment().subtract(3, 'years').startOf('day').format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
      profileDataRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/emails/search?company_id=${this.props.brand.company_id}&product_brand_id=${this.props.brand.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
          HEADERS
        ).then(response => {
          const emails = response.data;
          let emailsDiscovered;
          if (emails.length > 0) {
            emailsDiscovered = true;
          }
          return { emailsDiscovered };
        }).catch(error => {
          console.error('Error: failed to fetch emails discovered...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { emailsDiscovered: undefined };
        })
      );

      Promise.all(profileDataRequests).then(responses => {
        const profileCollectorsData = {};
        for (const response of responses) {
          if ('twitter' in response) {
            profileCollectorsData.twitter = response.twitter;
          } else if ('linkedIn' in response) {
            profileCollectorsData.linkedIn = response.linkedIn;
          } else if ('facebook' in response) {
            profileCollectorsData.facebook = response.facebook;
          } else if ('youtube' in response) {
            profileCollectorsData.youtube = response.youtube;
          } else if ('blog' in response) {
            profileCollectorsData.blog = response.blog;
          } else if ('emailsDiscovered' in response) {
            profileCollectorsData.emailsDiscovered = response.emailsDiscovered;
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            profileCollectorsData,
            profileCollectorsDataLoading: false
          }));
        }
      });
    }
  }

  render () {
    return (
      <CardComponent
        title="Overview"
        body={
          <div>
            { this.state.profileOverviewDataLoading &&
              <div className="m-4 text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.profileOverviewDataLoading &&
              <div>
                <div>
                  <Row className="mb-4">
                    <Col xs={6} lg={5} xl={4}>
                      <Row>
                        <Col style={{ flex: '0 0 135px' }}>Headquarters:</Col>
                        <Col>{this.state.profileOverviewData.headquarter || ''}</Col>
                      </Row>
                      <Row>
                        <Col style={{ flex: '0 0 135px' }}>Founded:</Col>
                        <Col>{this.state.profileOverviewData.founded_on || ''}</Col>
                      </Row>
                      <Row>
                        <Col style={{ flex: '0 0 135px' }}>Industry:</Col>
                        <Col>{this.state.profileOverviewData.industry || ''}</Col>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Col style={{ flex: '0 0 135px' }}>Website:</Col>
                        <Col>
                          { this.state.profileOverviewData.website &&
                            <a
                              href={this.state.profileOverviewData.website}
                              target="_blank"
                              style={{ wordBreak: 'break-word' }}
                            >
                              {this.state.profileOverviewData.website}
                            </a>
                          }
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ flex: '0 0 135px' }}>Size:</Col>
                        <Col>{this.state.profileOverviewData.company_size || ''}</Col>
                      </Row>
                      <Row>
                        <Col style={{ flex: '0 0 135px' }}>Type:</Col>
                        <Col>{this.state.profileOverviewData.company_type || ''}</Col>
                      </Row>
                    </Col>
                  </Row>
                  { this.state.profileOverviewData.description &&
                    <div>
                      {this.state.profileOverviewData.description}
                    </div>
                  }
                </div>
                { !this.state.profileCollectorsDataLoading &&
                  <div className="mt-2 text-center">
                    <div className="d-inline-block px-2 py-3 border">
                      { Object.entries(this.state.profileCollectorsData).map(([accountType, accountValue], i) => {
                          let icon;
                          let color;
                          if (accountType === 'twitter') {
                            icon = faTwitter;
                            color = '#1da1f2'
                          } else if (accountType === 'linkedIn') {
                            icon = faLinkedin;
                            color = '#0072b1';
                          } else if (accountType === 'facebook') {
                            icon = faFacebook;
                            color = '#4267b2';
                          } else if (accountType === 'youtube') {
                            icon = faYoutube;
                            color = '#ff0000';
                          } else if (accountType === 'blog') {
                            icon = faRss;
                            color = '#ff9933';
                          } else if (accountType === 'emailsDiscovered') {
                            icon = faEnvelope;
                            color = '#4fa2f3';
                          } else {
                            icon = faQuestion;
                            color = '#4fa2f3';
                          }

                          let bgClassName = 'bg-dark';
                          let link;
                          if (accountValue === true) {
                            bgClassName = 'bg-white';
                            link = false;
                          } else if (accountValue) {
                            bgClassName = 'bg-white';
                            link = true;
                          }

                          return (
                            <span
                              key={`${accountType}-${i}`}
                              className={`mx-2 p-2 border rounded ${bgClassName}`}
                              style={{ cursor: link ? 'pointer': 'default' }}
                              onClick={() => link ? window.open(accountValue, '_blank') : null}
                            >
                              <FontAwesomeIcon
                                className="pt-1"
                                icon={icon}
                                color={color}
                                style={{ fontSize: '1.4rem' }}
                              />
                            </span>
                          )
                        })
                      }
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
        contextType="brand"
        contextCategory={this.props.category}
        contextBrand={this.props.brand}
        contextChannel="Company"
        contextProperty="Profile"
        contextChartName="Overview"
      />
    );
  }
};
