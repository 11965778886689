import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import DateRangePicker from '../../common/DateRangePicker';
import BrandChannelSummaryContainer from '../../../containers/brands/BrandChannelSummaryContainer';
import BrandMetricsByMonthTableContainer from '../../../containers/metrics/BrandMetricsByMonthTableContainer';
import ReviewActivity from '../../reviews/ReviewActivity';
import AverageReviewScores from '../../reviews/AverageReviewScores';
import ReviewScoresSummary from '../../reviews/ReviewScoresSummary';
import ReviewSiteScores from '../../reviews/ReviewSiteScores';
import ReviewDetails from '../../reviews/ReviewDetails';
import { getRandomInteger, getRandomPercentChange } from '../../../utils/numbers';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class ReviewDetailsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredReviewSites: [],
      selectedReviewLocation: 'all',
      selectedReviewUrlId: 0,
      selectedReviewType: '',
      reviewLocations: [],
      startDate: moment().utc().subtract(1, 'year').startOf('day'),
      endDate: moment().utc().endOf('day'),
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchReviewLocations();
    if (this.props.scrollToDetails) {
      this.reviewScroll.scrollTop = this.reviewDetails.offsetTop - this.reviewScroll.offsetTop;
      this.props.unsetScrollToDetails();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.customerId !== this.props.customerId ||
      prevProps.brandId !== this.props.brandId
    ) {
      this.fetchReviewLocations();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  fetchReviewLocations = () => {
    if (this.props.customerId && this.props.brandId) {
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/review-locations?sponsor_customer_id=${this.props.customerId}&product_brand_id=${this.props.brandId}`,
        HEADERS
      ).then(response => {
        const reviewLocations = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            selectedReviewLocation: 'all',
            selectedReviewUrlId: 0,
            selectedReviewType: '',
            reviewLocations
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch review locations');
        if (this.state.isMounted) {
          this.setState(() => ({
            selectedReviewLocation: 'all',
            selectedReviewUrlId: 0,
            selectedReviewType: '',
            reviewLocations: []
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  filterReviews = (reviewsData) => {
    let filteredReviewSites = [];
    for (const reviewSummary of reviewsData) {
      if (reviewSummary.checked == true)
        filteredReviewSites.push(reviewSummary.review_site_name);
    }
    this.setState(() => ({ filteredReviewSites }));
  }

  onFilterDatesChange = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  }

  onSelectedReviewLocationChange = (event) => {
    const selectedReviewLocation = event.currentTarget.value;
    const selectedReviewUrlId = event.currentTarget.options[event.currentTarget.selectedIndex].dataset.reviewUrlId;
    const selectedReviewType = event.currentTarget.options[event.currentTarget.selectedIndex].dataset.reviewType;
    this.setState(() => ({
      selectedReviewLocation,
      selectedReviewUrlId,
      selectedReviewType,
    }));
  };

  render () {
    return (
      <div ref={(ref) => this.reviewScroll = ref}>
        <div>
          <BrandChannelSummaryContainer
            channel="Product Reviews"
            category={this.props.category}
            brand={this.props.brand}
          />
        </div>
        <div>
          <h5 className="my-2 p-2 bg-bops-blue text-light">
            Metric Trends
            <div
              className="d-inline-block ml-4"
              style={{ fontSize: '1rem', fontWeight: 'normal' }}
            >
              <Form.Check
                id="reviewsAllMonthsCheckbox"
                className="ml-2"
                type="checkbox"
                label="All Months"
                checked={this.props.allMonthsChecked}
                onChange={(event) => this.props.updateAllMonthsChecked(event.currentTarget.checked)}
                inline
              />
            </div>
          </h5>
          <Row>
            <Col xs={12} lg={6}>
              <ReviewActivity
                topicId={this.props.topicId}
                category={this.props.category}
                brand={this.props.brand}
                allMonthsChecked={this.props.allMonthsChecked}
                updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                allMonthsStartDate={this.props.allMonthsStartDate}
              />
            </Col>
            <Col xs={12} lg={6}>
              <AverageReviewScores
                topicId={this.props.topicId}
                category={this.props.category}
                brand={this.props.brand}
                allMonthsChecked={this.props.allMonthsChecked}
                updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                allMonthsStartDate={this.props.allMonthsStartDate}
              />
            </Col>
          </Row>
        </div>
        <div>
          <BrandMetricsByMonthTableContainer
            type="Product Reviews"
            siteName="All-Reviews"
            category={this.props.category}
            brand={this.props.brand}
            audienceProfileId={this.props.category.audience_profile_id}
          />
        </div>
        <h5 className="my-2 p-2 bg-bops-blue text-light">
          Details
          <span
            className="ml-4 mr-2"
            style={{ fontSize: '1rem', fontWeight: 'normal' }}
          >Date Range</span>
          <DateRangePicker
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            updateDates={this.onFilterDatesChange}
          />
        <div className="d-inline-block ml-4" style={{ width: '300px' }}>
            <Form.Control
              as="select"
              value={this.state.selectedReviewLocation}
              onChange={this.onSelectedReviewLocationChange}
              disabled={this.state.reviewLocations.length === 0}
              size="sm"
            >
              <option value="all">All Locations</option>
              { this.state.reviewLocations.map(rl => {
                  return (
                    <option
                      key={`rl-o-${rl.id}`}
                      value={rl.id}
                      data-review-url-id={rl.review_url_id}
                      data-review-type={rl.review_type}
                    >
                      {`${rl.name} - ${rl.address}`}
                    </option>
                  )
                })
              }
            </Form.Control>
          </div>
        </h5>
        <div ref={(ref) => this.reviewDetails = ref}>
          <Row>
            <Col xs={12} md={5}>
              <ReviewScoresSummary
                category={this.props.category}
                brand={this.props.brand}
                topicId={this.props.topicId}
                startDateFilter={this.state.startDate}
                endDateFilter={this.state.endDate}
              />
            </Col>
            <Col xs={12} md={7}>
              <ReviewSiteScores
                customerId={this.props.customerId}
                category={this.props.category}
                brand={this.props.brand}
                topicId={this.props.topicId}
                startDateFilter={this.state.startDate}
                endDateFilter={this.state.endDate}
                filterReviews={this.filterReviews}
                selectedReviewLocation={this.state.selectedReviewLocation}
                selectedReviewUrlId={this.state.selectedReviewUrlId}
                selectedReviewType={this.state.selectedReviewType}
              />
            </Col>
          </Row>
        </div>
        <div className="mt-4">
          <ReviewDetails
            category={this.props.category}
            brand={this.props.brand}
            topicId={this.props.topicId}
            startDateFilter={this.state.startDate}
            endDateFilter={this.state.endDate}
            filteredReviewSites={this.state.filteredReviewSites}
            setReviewDetailsData={this.setReviewDetailsData}
            selectedReviewLocation={this.state.selectedReviewLocation}
            selectedReviewUrlId={this.state.selectedReviewUrlId}
            selectedReviewType={this.state.selectedReviewType}
          />
        </div>
      </div>
    );
  }
};
