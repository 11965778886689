import { connect } from 'react-redux';
import BrandTabs from '../../components/brands/brandTabs/BrandTabs';
import { setHeaderLabels } from '../../actions/header/headerLabels';
import { fetchBrandChannelScoresMap } from '../../actions/scores/brandChannelScoresMap';
import { setStartDateFilter, setEndDateFilter } from '../../actions/filters/dateFilters';
import { updateSelectedMonth } from '../../actions/filters/selectedMonth';
import { updateAllMonthsChecked } from '../../actions/filters/allMonthsCheck';
import { updateCompanyChecked, updatePortfolioChecked, updateProductChecked } from '../../actions/filters/cppChecks';

const mapStateToProps = (state) => ({
  user: state.user,
  customer: state.customer,
  dashboards: state.dashboards,
  categories: state.categories,
  heroBrands: state.heroBrands,
  sentimentWinners: state.sentimentWinners,
  sentimentLosers: state.sentimentLosers,
  startDateFilter: state.startDateFilter,
  endDateFilter: state.endDateFilter,
  sixMonths: state.sixMonths,
  selectedMonth: state.selectedMonth,
  allMonthsChecked: state.allMonthsChecked,
  companyChecked: state.companyChecked,
  portfolioChecked: state.portfolioChecked,
  productChecked: state.productChecked,
  brandChannelScoresMap: state.brandChannelScoresMap,
  brandChannelScoresMapLoading: state.brandChannelScoresMapLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setHeaderLabels: (categoryLabel, brandLabel) => dispatch(setHeaderLabels(categoryLabel, brandLabel)),
  fetchBrandChannelScoresMap: (loggedCustomerId, companyId, productBrandId, categoryId, categoryType, audienceProfileId) => dispatch(fetchBrandChannelScoresMap(loggedCustomerId, companyId, productBrandId, categoryId, categoryType, audienceProfileId)),
  setStartDateFilter: (startDate) => dispatch(setStartDateFilter(startDate)),
  setEndDateFilter: (endDate) => dispatch(setEndDateFilter(endDate)),
  updateSelectedMonth: (selectedMonth) => dispatch(updateSelectedMonth(selectedMonth)),
  updateAllMonthsChecked: (allMonthsChecked) => dispatch(updateAllMonthsChecked(allMonthsChecked)),
  updateCompanyChecked: (companyChecked) => dispatch(updateCompanyChecked(companyChecked)),
  updatePortfolioChecked: (portfolioChecked) => dispatch(updatePortfolioChecked(portfolioChecked)),
  updateProductChecked: (productChecked) => dispatch(updateProductChecked(productChecked)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandTabs);
