import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ListGroup from 'react-bootstrap/ListGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import ClipLoader from 'react-spinners/ClipLoader';
import LoginContainer from '../../../containers/common/LoginContainer';
import AdminAuthenticationMessage from '../../common/AdminAuthenticationMessage';
import { isAuthenticatedAdmin } from '../../../utils/auth';
import WebAnalyticsPollingModal from './WebAnalyticsPollingModal';
import WebAnalyticsLandingPageRegexForm from './WebAnalyticsLandingPageRegexForm';
import DeleteConfirmationModal from '../../common/DeleteConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEllipsisH, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class WebAnalyticsCollector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      propertyIds: '',
      credentialsContent: '',
      errors: {},
      setupAlert: '',
      gaCompanyAccount: null,
      landingPageRegexes: [],
      enabledEditLandingPageRegexIds: [],
      landingPageRegexForms: [],
      webAnalyticsPollingModalOpen: false,
      deleteConfirmationModalOpen: false,
      deleteGaSetupId: undefined,
      deleteLandingPageRegexConfirmationModalOpen: false,
      deleteLandingPageRegexId: undefined,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchGoogleAnalyticsSetup();
    this.fetchLandingPageRegexes();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.customerId !== this.props.user.customerId) {
      this.fetchGoogleAnalyticsSetup();
      this.fetchLandingPageRegexes();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchGoogleAnalyticsSetup = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({ gaCompanyAccountSetupLoading: true }));
      const googleAnalyticsSetupRequests = [];
      googleAnalyticsSetupRequests.push(
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/ga-company-accounts?company_id=${this.props.user.customerId}`,
          HEADERS
        ).then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            return { gaCompanyAccount: response.data[0] };
          } else {
            return { gaCompanyAccount: null };
          }
        }).catch(error => {
          console.error('Error: failed to fetch google analytics company account');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { gaCompanyAccount: null };
        })
      );
      googleAnalyticsSetupRequests.push(
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/ga-company-account-properties?company_id=${this.props.user.customerId}`,
          HEADERS
        ).then(response => {
          return { gaCompanyAccountProperties: response.data };
        }).catch(error => {
          console.error('Error: failed to fetch google analytics company account properties');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { gaCompanyAccountProperties: [] };
        })
      );

      Promise.all(googleAnalyticsSetupRequests).then(responses => {
        let gaCompanyAccountSetup = {};
        for (const response of responses) {
          if (response.gaCompanyAccount) {
            gaCompanyAccountSetup.account = response.gaCompanyAccount;
          } else if (response.gaCompanyAccountProperties) {
            gaCompanyAccountSetup.properties = response.gaCompanyAccountProperties;
          }
        }
        // unset if account not found
        if (!gaCompanyAccountSetup.account) {
          gaCompanyAccountSetup = undefined;
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            gaCompanyAccountSetup,
            gaCompanyAccountSetupLoading: false
          }));
        }
      });
    }
  };

  fetchLandingPageRegexes = () => {
    if (this.props.user.customerId) {
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/ga-landing-page-regex?company_id=${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        if (this.state.isMounted) {
          this.setState(() => ({ landingPageRegexes: response.data }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch landing page regexes');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        return { landingPageRegexes: [] };
      });
    }
  };

  onPropertyIdsChange = (event) => {
    const propertyIds = event.currentTarget.value;
    this.setState(() => ({ propertyIds }));
  };

  onCredentialsContentChange = (event) => {
    const credentialsContent = event.currentTarget.value;
    this.setState(() => ({ credentialsContent }));
  };

  validForm = () => {
    const errors = {};
    if (!this.state.propertyIds || this.state.propertyIds.trim() === '') {
      errors.propertyIds = true;
    }
    if (!this.state.credentialsContent || this.state.credentialsContent.trim() === '') {
      errors.credentialsContent = true;
    }

    this.setState(() => ({ errors }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  saveGaCompanyAccountSetup = () => {
    this.setState(() => ({ setupAlert: '' }));
    if (this.validForm()) {
      const propertyIds = this.state.propertyIds.split(',');
      for (let propertyId of propertyIds) {
        propertyId = propertyId.trim();
      }
      const gaCompanyAccount = {
        company_id: this.props.user.customerId,
        private_key: this.state.credentialsContent,
        property_ids: propertyIds
      };

      axios.post(
        `${CONTENT_SCRAPING_ENDPOINT}/api/ga-company-accounts`,
        gaCompanyAccount,
        HEADERS
      ).then(response => {
        if (this.state.isMounted) {
          this.setState(() => ({
            propertyIds: '',
            credentialsContent: '',
          }));
        }
        this.fetchGoogleAnalyticsSetup();
        this.openWebAnalyticsPollingModal();
      }).catch(error => {
        console.error('Error: failed to create google analytics company account');
        if (this.state.isMounted) {
          if (error && error.response && error.response.data && error.response.data.message) {
            this.setState(() => ({ setupAlert: error.response.data.message }));
          } else {
            this.setState(() => ({ setupAlert: 'Failed to save Google Analytics setup. Please try again later.' }));
          }
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  addLandingPageRegexForm = () => {
    const id = Math.random().toString(36).substring(7);
    this.setState((prevState) => ({
      landingPageRegexForms: [...prevState.landingPageRegexForms, id]
    }));
  };

  removeLandingPageRegexForm = (id) => {
    this.setState((prevState) => ({
      landingPageRegexForms: prevState.landingPageRegexForms.filter(lprfi => lprfi !== id)
    }));
  };

  enableEditLandingPageRegexId = (id) => {
    this.setState((prevState) => ({
      enabledEditLandingPageRegexIds: [...prevState.enabledEditLandingPageRegexIds, id]
    }));
  };

  disableEditLandingPageRegexId = (id) => {
    this.setState((prevState) => ({
      enabledEditLandingPageRegexIds: prevState.enabledEditLandingPageRegexIds.filter(elpr => elpr !== id)
    }));
  };

  openWebAnalyticsPollingModal = () => {
    this.setState(() => ({ webAnalyticsPollingModalOpen: true }));
  };

  closeWebAnalyticsPollingModal = () => {
    this.setState(() => ({ webAnalyticsPollingModalOpen: false }));
  };

  openDeleteLandingPageRegexConfirmationModal = (landingPageRegexId) => {
    this.setState(() => ({
      deleteLandingPageRegexConfirmationModalOpen: true,
      deleteLandingPageRegexId: landingPageRegexId,
    }));
  };

  closeDeleteLandingPageRegexConfirmationModal = () => {
    this.setState(() => ({
      deleteLandingPageRegexConfirmationModalOpen: false,
      deleteLandingPageRegexId: undefined,
    }));
  };

  deleteLandingPageRegex = () => {
    if (this.state.deleteLandingPageRegexId) {
      axios.delete(
        `${CONTENT_SCRAPING_ENDPOINT}/api/ga-landing-page-regex/${this.state.deleteLandingPageRegexId}`,
        HEADERS
      ).then(response => {
        this.closeDeleteLandingPageRegexConfirmationModal();
        this.fetchLandingPageRegexes();
      }).catch(error => {
        console.log('Error: failed to delete landing page regex');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  openDeleteConfirmationModal = () => {
    this.setState(() => ({
      deleteConfirmationModalOpen: true,
      deleteGaSetupId: this.state.gaCompanyAccountSetup.account.id,
    }));
  };

  closeDeleteConfirmationModal = () => {
    this.setState(() => ({
      deleteConfirmationModalOpen: false,
      deleteGaSetupId: undefined,
    }));
  };

  deleteGaSetup = () => {
    if (this.state.deleteGaSetupId) {
      axios.delete(
        `${CONTENT_SCRAPING_ENDPOINT}/api/ga-company-accounts/${this.state.deleteGaSetupId}`,
        HEADERS
      ).then(response => {
        this.closeDeleteConfirmationModal();
        this.fetchGoogleAnalyticsSetup();
      }).catch(error => {
        console.log('Error: failed to delete google analytics account setup');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="m-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        <AdminAuthenticationMessage user={this.props.user}/>
        { isAuthenticatedAdmin(this.props.user) &&
          <div>
            <div
              className="border rounded p-4"
              style={{ backgroundColor: '#e9ecef' }}
            >
              <div>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/4/46/Google_Analytics_Logo_2015.png"
                  alt=""
                  crossOrigin="anonymous"
                  style={{ width: '225px' }}
                />
              </div>
              <p className="mt-2">
                Integrate Google Analytics for web traffic data to determine brand success.
              </p>
              <hr className="my-4" />
              <p>Web traffic provides great insight into the amount of organic traffic (brand awareness) as well as visitors to blogs, product and pricing pages and demos and sales requests (brand interest).</p>
            </div>
            <div className="mt-4">
              <Row>
                <Col>
                  <div>
                  </div>
                  { this.state.gaCompanyAccountSetupLoading &&
                    <div className="m-4 text-center">
                      <ClipLoader size={100}/>
                    </div>
                  }
                  { !this.state.gaCompanyAccountSetupLoading &&
                    <div
                      className="pre-scrollable"
                      style={{
                        maxHeight: 'calc(100vh - 400px)',
                        overflow: 'auto'
                      }}
                    >
                      { this.state.gaCompanyAccountSetup &&
                        <div>
                          <b>Existing Setup</b>
                          <Alert
                            className="d-inline-block float-right"
                            variant="warning"
                            style={{ fontSize: '.875rem' }}
                          >
                            To modify the current setup, delete and resubmit the form. This is due to the credentials being tied to the property ids.
                          </Alert>
                          <div className="mt-2 ml-4">
                            <div className="">
                              Property Ids:
                            </div>
                            <ul>
                              { this.state.gaCompanyAccountSetup.properties.map((property, i) => {
                                  return (
                                    <li key={`ga-cas-p-${i}`}>
                                      {property.property_id}
                                    </li>
                                  )
                                })
                              }
                            </ul>
                            <div>
                              {`Private Key: ***************`}
                            </div>
                            <hr />
                            <div>
                              <div className="mb-1 font-weight-bold">
                                Landing Page RegExes
                                <Button
                                  className="ml-2 py-0 px-1"
                                  variant="success"
                                  onClick={this.addLandingPageRegexForm}
                                  size="sm"
                                >
                                  <FontAwesomeIcon icon={faPlus}/>
                                </Button>
                              </div>
                              { this.state.landingPageRegexes.length + this.state.landingPageRegexForms.length === 0 &&
                                <Alert variant="info">
                                  No landing page regular expressions setup.
                                </Alert>
                              }
                              { this.state.landingPageRegexes.length + this.state.landingPageRegexForms.length > 0 &&
                                <ListGroup
                                  as="ul"
                                  className="pre-scrollable"
                                  style={{
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    minHeight: '150px',
                                    maxHeight: '400px',
                                    width: '100%',
                                    fontSize: '.875rem'
                                  }}
                                >
                                  { this.state.landingPageRegexForms.map((id, i) => {
                                      return (
                                        <ListGroup.Item key={`lpr-n-${id}`}>
                                          <WebAnalyticsLandingPageRegexForm
                                            id={id}
                                            companyId={this.props.user.customerId}
                                            gaCompanyAccount={this.state.gaCompanyAccountSetup.account}
                                            removeLandingPageRegexForm={this.removeLandingPageRegexForm}
                                            refreshLandingPageRegexes={this.fetchLandingPageRegexes}
                                          />
                                        </ListGroup.Item>
                                      )
                                    })
                                  }
                                  { this.state.landingPageRegexes.map((landingPageRegex, i) => {
                                      if (this.state.enabledEditLandingPageRegexIds.includes(landingPageRegex.id)) {
                                        return (
                                          <ListGroup.Item key={`lpr-e-${landingPageRegex.id}`}>
                                            <WebAnalyticsLandingPageRegexForm
                                              id={landingPageRegex.id}
                                              companyId={this.props.user.customerId}
                                              gaCompanyAccount={this.state.gaCompanyAccountSetup.account}
                                              landingPageRegex={landingPageRegex}
                                              removeLandingPageRegexForm={this.disableEditLandingPageRegexId}
                                              refreshLandingPageRegexes={this.fetchLandingPageRegexes}
                                            />
                                          </ListGroup.Item>
                                        )
                                      } else {
                                        return (
                                          <ListGroup.Item key={`lpr-${landingPageRegex.id}`}>
                                            <Row className="mt-1">
                                              <Col>
                                                <div>
                                                  {landingPageRegex.regex}
                                                </div>
                                              </Col>
                                              <Col xs={2} className="text-right">
                                                <Dropdown>
                                                  <Dropdown.Toggle
                                                    as="div"
                                                    bsPrefix="none"
                                                  >
                                                    <Button variant="light" size="sm">
                                                      <FontAwesomeIcon icon={faEllipsisH} style={{ cursor: 'pointer' }} color="#5f666d"/>
                                                    </Button>
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => this.enableEditLandingPageRegexId(landingPageRegex.id)}>
                                                      <FontAwesomeIcon className="mr-2" icon={faEdit}/>
                                                      Edit
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => this.openDeleteLandingPageRegexConfirmationModal(landingPageRegex.id)}>
                                                      <FontAwesomeIcon className="mr-2" icon={faTrash}/>
                                                      Delete
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </Col>
                                            </Row>
                                          </ListGroup.Item>
                                        )
                                      }
                                    })
                                  }
                                </ListGroup>
                              }
                            </div>
                            <hr />
                            <div className="mt-3">
                              <Button
                                variant="danger"
                                onClick={this.openDeleteConfirmationModal}
                                size="sm"
                              >
                                Delete GA Setup
                              </Button>
                            </div>
                          </div>
                        </div>
                      }
                      { !this.state.gaCompanyAccountSetup &&
                        <div>
                          <Form>
                            <Form.Group>
                              <Form.Label>Property Ids</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows="1"
                                value={this.state.propertyIds}
                                onChange={this.onPropertyIdsChange}
                                isInvalid={this.state.errors.propertyIds}
                              />
                              <div className="mt-1" style={{ fontSize: '.75rem' }}>
                                Separate property ids with a comma
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Credentials Content</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows="6"
                                value={this.state.credentialsContent}
                                onChange={this.onCredentialsContentChange}
                                isInvalid={this.state.errors.credentialsContent}
                              />
                              <div className="mt-1" style={{ fontSize: '.75rem' }}>
                                Paste the entire contents from credentials.json
                              </div>
                            </Form.Group>
                            <Alert show={Boolean(this.state.setupAlert)} variant="danger">
                              {this.state.setupAlert}
                            </Alert>
                            <Button
                              variant="primary"
                              onClick={this.saveGaCompanyAccountSetup}
                            >
                              Save
                            </Button>
                          </Form>
                        </div>
                      }
                    </div>
                  }
                </Col>
                <Col>
                  <Card>
                    <Card.Header className="bg-bops-blue text-light font-weight-bold">
                      Setup
                    </Card.Header>
                    <Card.Body
                      className="pre-scrollable"
                      style={{
                        maxHeight: 'calc(100vh - 450px)',
                        overflow: 'auto'
                      }}
                    >
                      <div>
                        <h5>Getting Started</h5>
                        <ul>
                          <li>
                            <a href="https://developers.google.com/analytics/devguides/collection/ga4" target="_blank">
                              Learning about Google Analytics 4
                            </a>
                          </li>
                          <li>
                            <a href="https://developers.google.com/analytics/devguides/reporting/data/v1/quickstart-client-libraries" target="_blank">
                              API Enablement & Access Quickstart
                            </a>
                          </li>
                          <li>
                            <a href="https://support.google.com/analytics/answer/10737381?hl=en" target="_blank">
                              Learning about GA4 Search Console Integration
                            </a>
                          </li>
                        </ul>
                        <h5>Quickstart</h5>
                        <p>
                          Follow these steps <a href="https://developers.google.com/analytics/devguides/reporting/data/v1/quickstart-client-libraries" target="_blank">here</a> for a more detailed walkthrough
                        </p>
                        <ol>
                          <li>Enable the API</li>
                          <li>Add service account to Google Analytics 4 property</li>
                          <li>Configure authentication</li>
                          <li>Provide credentials.json contents and property ids.</li>
                        </ol>
                        <h5>Integrate Search Console with GA4</h5>
                        <p></p>
                        <ol>
                          <li>In Admin, make sure that you have the desired account and property selected.</li>
                          <li>In the Property column, under PRODUCT LINKS, click Search Console Links.</li>
                          <li>In the link table, click Link.</li>
                          <li>If you are a verified owner for one or more Search Console properties, in the row for Link to Search Console properties I manage, click Choose accounts, then select the account you want to link your property to.</li>
                          <li>Click Confirm.</li>
                          <li>Click Next.</li>
                          <li>Select the web data stream for your site.</li>
                          <li>Click Next, then review and submit your configuration settings.</li>
                        </ol>
                        <h5>Landing Page RegExes</h5>
                        <p>Regular expressions (RegExes) can be used to check if a url contains the specified search pattern. Landing page regexes are used to find and tag landing page urls.</p>
                        <p>For example: to check if a url starts with 'https://page' use regular expression '^https://page'</p>
                        <p>More details can be found <a href="https://www.w3schools.com/python/python_regex.asp" target="_blanks">here</a>.</p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>

            <WebAnalyticsPollingModal
              isOpen={this.state.webAnalyticsPollingModalOpen}
              handleClose={this.closeWebAnalyticsPollingModal}
              companyId={this.props.user.customerId}
              myCategoryBrand={this.props.myCategoryBrands[0]}
            />
            <DeleteConfirmationModal
              isOpen={this.state.deleteLandingPageRegexConfirmationModalOpen}
              handleClose={this.closeDeleteLandingPageRegexConfirmationModal}
              resourceName="landing page regex"
              deleteResource={this.deleteLandingPageRegex}
            />
            <DeleteConfirmationModal
              isOpen={this.state.deleteConfirmationModalOpen}
              handleClose={this.closeDeleteConfirmationModal}
              resourceName="Google Analytics setup"
              deleteResource={this.deleteGaSetup}
            />
          </div>
        }
      </div>
    );
  }
};
