import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ShareOfChart from '../graphs/ShareOfChart';
import { round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryVideoShareOfGraphs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tweetMentionsShareOfGraphData: {},
      categoryTweetMentionsShareOfData: [],
      ownedTweetsShareOfGraphData: {},
      categoryOwnedTweetsShareOfData: [],
      promoterShareOfGraphData: {},
      categoryPromotersShareOfData: [],
      detractorShareOfGraphData: {},
      categoryDetractorsShareOfData: [],
      followersShareOfGraphData: {},
      categoryFollowersShareOfData: [],
      netFollowersShareOfGraphData: {},
      categoryNetFollowersShareOfData: [],
      engagementShareOfGraphData: {},
      categoryEngagementShareOfData: [],
      categoryTwitterShareOfDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getCategoryTwitterShareOfData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.getCategoryTwitterShareOfData();
    }
  }

  getCategoryTwitterShareOfData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryTwitterShareOfDataLoading: true }));
      const shareOfRequests = [];
      shareOfRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-twitter-share-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
          HEADERS
        ).then(response => {
          const categoryTwitterShareOfData = response.data;
          const tweetMentionsShareOfGraphData = {
            series: [
              { name: 'Month', type: 'dimension' },
              { name: 'Brand', type: 'dimension' },
              { name: 'Value', type: 'measure' },
            ],
            records: [],
          };
          const ownedTweetsShareOfGraphData = {
            series: [
              { name: 'Month', type: 'dimension' },
              { name: 'Brand', type: 'dimension' },
              { name: 'Value', type: 'measure' },
            ],
            records: [],
          };
          const promoterShareOfGraphData = {
            series: [
              { name: 'Month', type: 'dimension' },
              { name: 'Brand', type: 'dimension' },
              { name: 'Value', type: 'measure' },
            ],
            records: [],
          };
          const detractorShareOfGraphData = {
            series: [
              { name: 'Month', type: 'dimension' },
              { name: 'Brand', type: 'dimension' },
              { name: 'Value', type: 'measure' },
            ],
            records: [],
          };
          const categoryTweetMentionsShareOfData = [];
          const categoryOwnedTweetsShareOfData = [];
          const categoryPromotersShareOfData = [];
          const categoryDetractorsShareOfData = [];
          for (const monthStats of categoryTwitterShareOfData) {
            const mentionsMonthData = { month: monthStats.month };
            let monthTotalMentions = 0;
            const ownedTweetsMonthData = { month: monthStats.month };
            let monthTotalOwnedTweets = 0;
            const promotersMonthData = { month: monthStats.month };
            let monthTotalPromoters = 0;
            const detractorsMonthData = { month: monthStats.month };
            let monthTotalDetractors = 0;

            // get totals for percent
            for (const pb of monthStats.product_brands) {
              monthTotalMentions += pb.mentions;
              monthTotalOwnedTweets += pb.owned_tweets;
              monthTotalPromoters += pb.promoters;
              monthTotalDetractors += pb.detractors;
              // insert graph data
              tweetMentionsShareOfGraphData.records.push([
                monthStats.month,
                pb.product_brand_name,
                pb.mentions,
              ]);
              ownedTweetsShareOfGraphData.records.push([
                monthStats.month,
                pb.product_brand_name,
                pb.owned_tweets,
              ]);
              promoterShareOfGraphData.records.push([
                monthStats.month,
                pb.product_brand_name,
                pb.promoters,
              ]);
              detractorShareOfGraphData.records.push([
                monthStats.month,
                pb.product_brand_name,
                pb.detractors,
              ]);
            }

            // loop again to determine %
            for (const pb of monthStats.product_brands) {
              let mentionsPercent = 0;
              if (monthTotalMentions > 0) {
                mentionsPercent = round((pb.mentions/monthTotalMentions)*100, 1);
              }
              mentionsMonthData[pb.product_brand_name] = {
                count: pb.mentions,
                percent: mentionsPercent
              }

              let ownedTweetsPercent = 0;
              if (monthTotalOwnedTweets > 0) {
                ownedTweetsPercent = round((pb.owned_tweets/monthTotalOwnedTweets)*100, 1);
              }
              ownedTweetsMonthData[pb.product_brand_name] = {
                count: pb.owned_tweets,
                percent: ownedTweetsPercent
              }

              let promotersPercent = 0;
              if (monthTotalPromoters > 0) {
                promotersPercent = round((pb.promoters/monthTotalPromoters)*100, 1);
              }
              promotersMonthData[pb.product_brand_name] = {
                count: pb.promoters,
                percent: promotersPercent
              }

              let detractorsPercent = 0;
              if (monthTotalDetractors > 0) {
                detractorsPercent = round((pb.detractors/monthTotalDetractors)*100, 1);
              }
              detractorsMonthData[pb.product_brand_name] = {
                count: pb.detractors,
                percent: detractorsPercent
              }
            }
            categoryTweetMentionsShareOfData.push(mentionsMonthData);
            categoryOwnedTweetsShareOfData.push(ownedTweetsMonthData);
            categoryPromotersShareOfData.push(promotersMonthData);
            categoryDetractorsShareOfData.push(detractorsMonthData);
          }
          return {
            tweetMentionsShareOfGraphData,
            categoryTweetMentionsShareOfData,
            ownedTweetsShareOfGraphData,
            categoryOwnedTweetsShareOfData,
            promoterShareOfGraphData,
            categoryPromotersShareOfData,
            detractorShareOfGraphData,
            categoryDetractorsShareOfData,
          };
        }).catch(error => {
          console.error('Error: unable to fetch category share of twitter stats...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return {
            tweetMentionsShareOfGraphData: {},
            categoryTweetMentionsShareOfData: [],
            ownedTweetsShareOfGraphData: {},
            categoryOwnedTweetsShareOfData: [],
            promoterShareOfGraphData: {},
            categoryPromotersShareOfData: [],
            detractorShareOfGraphData: {},
            categoryDetractorsShareOfData: [],
          }
        })
      );

      shareOfRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-twitter-share-of-followers?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
          HEADERS
        ).then(response => {
          const followersShareOfGraphData = {
            series: [
              { name: 'Month', type: 'dimension' },
              { name: 'Brand', type: 'dimension' },
              { name: 'Value', type: 'measure' },
            ],
            records: [],
          };
          const categoryFollowersShareOfData = [];
          for (const monthStats of response.data) {
            const followersMonthData = { month: monthStats.month };
            let monthTotalFollowers = 0;

            // get totals for percent
            for (const pb of monthStats.product_brands) {
              monthTotalFollowers += pb.followers;
              // insert graph data
              followersShareOfGraphData.records.push([
                monthStats.month,
                pb.product_brand_name,
                pb.followers,
              ]);
            }

            // loop again to determine %
            for (const pb of monthStats.product_brands) {
              let followersPercent = 0;
              if (monthTotalFollowers > 0) {
                followersPercent = round((pb.followers/monthTotalFollowers)*100, 1);
              }
              followersMonthData[pb.product_brand_name] = {
                count: pb.followers,
                percent: followersPercent
              }
            }
            categoryFollowersShareOfData.push(followersMonthData);
          }
          return {
            followersShareOfGraphData,
            categoryFollowersShareOfData
          }
        }).catch(error => {
          console.error('Error: unable to fetch category share of linkedin followers...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return {
            followersShareOfGraphData: {},
            categoryFollowersShareOfData: []
          }
        })
      );

      shareOfRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-twitter-share-of-net-followers?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
          HEADERS
        ).then(response => {
          const netFollowersShareOfGraphData = {
            series: [
              { name: 'Month', type: 'dimension' },
              { name: 'Brand', type: 'dimension' },
              { name: 'Value', type: 'measure' },
            ],
            records: [],
          };
          const categoryNetFollowersShareOfData = [];
          for (const monthStats of response.data) {
            const netFollowersMonthData = { month: monthStats.month };
            let monthNetNewFollowers = 0;

            // get totals for percent
            for (const pb of monthStats.product_brands) {
              monthNetNewFollowers += pb.net_followers;
              // insert graph data
              netFollowersShareOfGraphData.records.push([
                monthStats.month,
                pb.product_brand_name,
                pb.net_followers || 0,
              ]);
            }

            // loop again to determine %
            for (const pb of monthStats.product_brands) {
              let netNewFollowersPercent = 0;
              if (monthNetNewFollowers > 0) {
                netNewFollowersPercent = round((pb.net_followers/monthNetNewFollowers)*100, 1);
              }
              netFollowersMonthData[pb.product_brand_name] = {
                count: pb.net_followers || 0,
                percent: netNewFollowersPercent
              }
            }
            categoryNetFollowersShareOfData.push(netFollowersMonthData);
          }
          return {
            netFollowersShareOfGraphData,
            categoryNetFollowersShareOfData
          }
        }).catch(error => {
          console.error('Error: unable to fetch category share of twitter net new followers...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return {
            netFollowersShareOfGraphData: {},
            categoryNetFollowersShareOfData: []
          }
        })
      );

      shareOfRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-twitter-share-of-engagement?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
          HEADERS
        ).then(response => {
          const engagementShareOfGraphData = {
            series: [
              { name: 'Month', type: 'dimension' },
              { name: 'Brand', type: 'dimension' },
              { name: 'Value', type: 'measure' },
            ],
            records: [],
          };
          const categoryEngagementShareOfData = [];
          for (const monthStats of response.data) {
            const engagementMonthData = { month: monthStats.month };
            let monthEngagement = 0;

            // get totals for percent
            for (const pb of monthStats.product_brands) {
              monthEngagement += pb.avg_post_engagement_without_replies;
              // insert graph data
              engagementShareOfGraphData.records.push([
                monthStats.month,
                pb.product_brand_name,
                pb.avg_post_engagement_without_replies || 0,
              ]);
            }

            // loop again to determine %
            for (const pb of monthStats.product_brands) {
              let engagementPercent = 0;
              if (monthEngagement > 0) {
                engagementPercent = round((pb.avg_post_engagement_without_replies/monthEngagement)*100, 1);
              }
              engagementMonthData[pb.product_brand_name] = {
                count: pb.avg_post_engagement_without_replies || 0,
                percent: engagementPercent
              }
            }
            categoryEngagementShareOfData.push(engagementMonthData);
          }
          return {
            engagementShareOfGraphData,
            categoryEngagementShareOfData
          }
        }).catch(error => {
          console.error('Error: unable to fetch category share of twitter engagement...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return {
            engagementShareOfGraphData: {},
            categoryEngagementShareOfData: []
          }
        })
      );

      Promise.all(shareOfRequests).then(responses => {
        let tweetMentionsShareOfGraphData = {};
        let categoryTweetMentionsShareOfData = [];
        let ownedTweetsShareOfGraphData = {};
        let categoryOwnedTweetsShareOfData = [];
        let promoterShareOfGraphData = {};
        let categoryPromotersShareOfData = [];
        let detractorShareOfGraphData = {};
        let categoryDetractorsShareOfData = [];
        let followersShareOfGraphData = {};
        let categoryFollowersShareOfData = [];
        let netFollowersShareOfGraphData = {};
        let categoryNetFollowersShareOfData = [];
        let engagementShareOfGraphData = {};
        let categoryEngagementShareOfData = [];

        for (const response of responses) {
          if (response.categoryTweetMentionsShareOfData) {
            tweetMentionsShareOfGraphData = response.tweetMentionsShareOfGraphData;
            categoryTweetMentionsShareOfData = response.categoryTweetMentionsShareOfData;
            ownedTweetsShareOfGraphData = response.ownedTweetsShareOfGraphData;
            categoryOwnedTweetsShareOfData = response.categoryOwnedTweetsShareOfData;
            promoterShareOfGraphData = response.promoterShareOfGraphData;
            categoryPromotersShareOfData = response.categoryPromotersShareOfData;
            detractorShareOfGraphData = response.detractorShareOfGraphData;
            categoryDetractorsShareOfData = response.categoryDetractorsShareOfData;
          } else if (response.categoryFollowersShareOfData) {
            followersShareOfGraphData = response.followersShareOfGraphData;
            categoryFollowersShareOfData = response.categoryFollowersShareOfData;
          } else if (response.categoryNetFollowersShareOfData) {
            netFollowersShareOfGraphData = response.netFollowersShareOfGraphData;
            categoryNetFollowersShareOfData = response.categoryNetFollowersShareOfData;
          } else if (response.categoryEngagementShareOfData) {
            engagementShareOfGraphData = response.engagementShareOfGraphData;
            categoryEngagementShareOfData = response.categoryEngagementShareOfData;
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            tweetMentionsShareOfGraphData,
            categoryTweetMentionsShareOfData,
            ownedTweetsShareOfGraphData,
            categoryOwnedTweetsShareOfData,
            promoterShareOfGraphData,
            categoryPromotersShareOfData,
            detractorShareOfGraphData,
            categoryDetractorsShareOfData,
            followersShareOfGraphData,
            categoryFollowersShareOfData,
            netFollowersShareOfGraphData,
            categoryNetFollowersShareOfData,
            engagementShareOfGraphData,
            categoryEngagementShareOfData,
            categoryTwitterShareOfDataLoading: false,
          }));
        }
      });
    }
  }

  render () {
    return (
      <div>
        <ShareOfChart
          title="Share of Followers"
          data={this.state.followersShareOfGraphData}
          legendData={this.state.categoryFollowersShareOfData}
          loading={this.state.categoryTwitterShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="Twitter"
          contextChartName="Share of Followers"
          shareClassName="category-twitter-share-of-followers-img"
        />
        <ShareOfChart
          title="Share of Net New Followers"
          data={this.state.netFollowersShareOfGraphData}
          legendData={this.state.categoryNetFollowersShareOfData}
          loading={this.state.categoryTwitterShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="Twitter"
          contextChartName="Share of Net New Followers"
          shareClassName="category-twitter-share-of-net-new-followers-img"
        />
        <ShareOfChart
          title="Share of Mentions"
          data={this.state.tweetMentionsShareOfGraphData}
          legendData={this.state.categoryTweetMentionsShareOfData}
          loading={this.state.categoryTwitterShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="Twitter"
          contextChartName="Share of Mentions"
          shareClassName="category-twitter-share-of-mentions-img"
        />
        <ShareOfChart
          title="Share of Owned Tweets"
          data={this.state.ownedTweetsShareOfGraphData}
          legendData={this.state.categoryOwnedTweetsShareOfData}
          loading={this.state.categoryTwitterShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="Twitter"
          contextChartName="Share of Owned Tweets"
          shareClassName="category-twitter-share-of-owned-tweets-img"
        />
        <ShareOfChart
          title="Share of Promoter Mentions"
          icon={
            <img
              className="mr-2 align-top"
              src="/images/Brand_Experience_Icon.png"
            />
          }
          data={this.state.promoterShareOfGraphData}
          legendData={this.state.categoryPromotersShareOfData}
          loading={this.state.categoryTwitterShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="Twitter"
          contextChartName="Share of Promoter Mentions"
          shareClassName="category-twitter-share-of-promoters-img"
        />
        <ShareOfChart
          title="Share of Detractor Mentions"
          icon={
            <img
              className="mr-2 align-top"
              src="/images/Brand_Experience_Icon.png"
            />
          }
          data={this.state.detractorShareOfGraphData}
          legendData={this.state.categoryDetractorsShareOfData}
          loading={this.state.categoryTwitterShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="Twitter"
          contextChartName="Share of Detractor Mentions"
          shareClassName="category-twitter-share-of-detractors-img"
        />
        <ShareOfChart
          title="Share of Engagement"
          data={this.state.engagementShareOfGraphData}
          legendData={this.state.categoryEngagementShareOfData}
          loading={this.state.categoryTwitterShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="Twitter"
          contextChartName="Share of Engagement"
          shareClassName="category-twitter-share-of-engagement-img"
        />
      </div>
    );
  }
};
