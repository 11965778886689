import React from 'react';
import ProfileOverview from '../../profile/ProfileOverview';
import EmployeesGraph from '../../profile/EmployeesGraph';

export default class ProfileTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps) {

  };

  render () {
    return (
      <div>
        <div>
          <ProfileOverview
            user={this.props.user}
            category={this.props.category}
            brand={this.props.brand}
          />
        </div>
        <div className="mt-4">
          <EmployeesGraph
            user={this.props.user}
            category={this.props.category}
            brand={this.props.brand}
          />
        </div>
      </div>
    );
  }
};
