import { connect } from 'react-redux';
import RefreshAlert from '../../components/common/RefreshAlert';
import { setShowRefreshAlert } from '../../actions/refreshAlert/refreshAlert';

const mapStateToProps = (state) => ({
  showRefreshAlert: state.showRefreshAlert
});

const mapDispatchToProps = (dispatch) => ({
  setShowRefreshAlert: (showRefreshAlert) => dispatch(setShowRefreshAlert(showRefreshAlert)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefreshAlert);
