import { connect } from 'react-redux';
import ConstantContactSetup from '../../../components/settings/contentCollectors/ConstantContactSetup';

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ConstantContactSetup);
