import { connect } from 'react-redux';
import CategorySetupChecklist from '../../../components/settings/contentCollectors/CategorySetupChecklist';

const mapStateToProps = (state) => ({
  user: state.user,
  categories: state.categories,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CategorySetupChecklist);
