import React from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import history from '../../routers/history';
import queryString from 'query-string';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      oauthProcessed: false,
      redirectByTypeMap: {
        'constant_contact': '/content-collectors/email-campaign',
        'google_analytics': '/content-collectors/web-analytics',
      }
    };
  }

  componentDidMount() {
    if (this.props.user.id) {
      this.setState(
        () => ({ oauthProcessed: true }),
        () => this.processOAuthAuthentication()
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.id !== this.props.user.id &&
      !this.state.oauthProcessed
    ) {
      this.setState(
        () => ({ oauthProcessed: true }),
        () => this.processOAuthAuthentication()
      );
    }
  };

  processOAuthAuthentication = () => {
    const queryValues = queryString.parse(this.props.location.search);
    const oauthType = this.props.location.pathname.split('/')[2];
    const oauthToken = queryValues.code;
    if (oauthType && oauthToken) {
      // Call backend service
      const oauthBody = {
        oauth_type: oauthType,
        oauth_authorize_token: oauthToken,
        customer_id: this.props.user.customerId,
        customer_user_id: this.props.user.id
      };
      axios.post(
        `${LISTEN_ENDPOINT}/api/oauth-authorize`,
        oauthBody,
        HEADERS
      ).then(response => {
        console.log('Successfully processed OAuth Authentication');
        if (this.state.redirectByTypeMap[oauthType]) {
          history.push(this.state.redirectByTypeMap[oauthType]);
        }
      }).catch(error => {
        console.error('Error: failed to post oauth authorize...');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    } else {
      console.log('Error: not enough OAuth information...');
    }
  };

  render () {
    return (
      <div className="m-4">
        <Alert variant="success">
          Successfully authenticated with OAuth!
        </Alert>
      </div>
    );
  }
};
