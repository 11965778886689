import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const DeleteConfirmationModal = (props) => {
  return (
    <Modal
      size="md"
      show={props.isOpen}
      onHide={props.discardChanges}
    >
      <Modal.Header
        className="bg-bops-blue text-light"
        closeButton
      >
        <Modal.Title>
          Unsaved Changes
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You have unsaved changes on the previous slide. Would you like to save or discard the changes?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.discardChanges}>
          Discard
        </Button>
        <Button variant="primary" onClick={props.saveChanges}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmationModal;
