import { connect } from 'react-redux';
import ChangeEmail from '../../components/settings/ChangeEmail';
import { updateUserAndCookie } from '../../actions/user/user';

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  updateUserAndCookie: (user) => dispatch(updateUserAndCookie(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmail);
