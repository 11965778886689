import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class EditEventForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      productBrandId: this.props.event.product_brand_id, //  || this.props.productBrands[0].id,
      eventSummary: this.props.event.summary,
      date: moment.utc(moment(this.props.event.event_created_on)._d).local().format('MM/DD/YYYY'),
      eventTags: this.props.event.event_tags || '',
      tagsAvailable: ["#product", "#finance", "#partnership", "#employees", "#marketing", "#general", "#issue"],
      eventTagsArray: []
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.createEventTagsArray();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  createEventTagsArray = () => {
    let eventTags = this.state.eventTags;
    if (eventTags && eventTags.trim() != '') {
      let eventTagsArray = eventTags.split(" ");
      this.setState(() => ({ eventTagsArray }));
    }
  }

  validEventSummary = (eventSummary) => {
    const errors = {};
    if (!eventSummary || eventSummary.trim() === '') {
      errors.invalidEventSummary = true;
    }
    this.setState(() => ({ errors }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  }

  validEventDate = (eventDate) => {
    const errors = {};
    if (!eventDate || eventDate.trim() === '' || new Date(eventDate) == "Invalid Date") {
      errors.invalidEventDate = true;
    }
    this.setState(() => ({ errors }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  }

  saveEvent = () => {
    if (this.validEventSummary(this.state.eventSummary) && this.validEventDate(this.state.date)) {
      if (this.props.new==true) {
        this.postNewEvent();
      } else { // update event
        let event = {
          product_brand_id: this.state.productBrandId,
          summary: this.state.eventSummary,
          is_public: 0,
          event_tags: this.state.eventTags,
          event_created_on: moment(new Date(this.state.date)).endOf('day').format('YYYY-MM-DD hh:mm:ss')
        }
        axios.put(
          `${LISTEN_ENDPOINT}/api/events-curated/${this.props.event.id}`,
          event,
          HEADERS
        ).then(response => {
          this.props.refreshEvents(true);
          this.props.handleClose(this.props.event.id);
        }).catch(error => {
          console.error('Error: failed to update event');
          this.props.handleClose(this.props.event.id);
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  }

  postNewEvent = () => {
    let event = {
      customer_id: this.props.event.customer_id,
      user_id: this.props.event.user_id,
      product_brand_id: this.state.productBrandId,
      summary: this.state.eventSummary,
      is_public: 0,
      event_tags: this.state.eventTags,
      event_created_on: moment(new Date(this.state.date)).endOf('day').format('YYYY-MM-DD hh:mm:ss')
    }
    axios.post(
      `${LISTEN_ENDPOINT}/api/events-curated `,
      event,
      HEADERS
    ).then(response => {
      this.props.refreshEvents(true);
      this.props.handleClose(this.props.event.id);
    }).catch(error => {
      console.error('Error: failed to add new event');
      this.props.handleClose(this.props.event.id);
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  }

  onEventSummaryChange = (event) => {
    const eventSummary = event.currentTarget.value;
    this.setState(() => ({ eventSummary }));
  };

  onEventTagsChange = (event) => {
    const eventTags = event.currentTarget.value;
    this.setState(() => ({ eventTags }));
  };

  addTag = (event) => {
    const eventTag = event.currentTarget.value;
    let eventTagsArray = this.state.eventTagsArray;
    eventTagsArray.push(eventTag);

    let eventTags = '';
    for (const i in eventTagsArray) {
      let event = eventTagsArray[i];
      if (i == 0)
        eventTags = eventTags.concat(event);
      else
        eventTags = eventTags.concat(" " + event);
    }

    this.setState(() => ({ eventTags, eventTagsArray }));
  }

  removeTag = (event) => {
    const eventTag = event.currentTarget.value;
    let eventTagsArray = this.state.eventTagsArray; let index;
    for (const i in eventTagsArray) {
      if (eventTagsArray[i] == eventTag) {
        index = i;
      }
    }
    if (index)
      eventTagsArray.splice(index, 1);

    let eventTags = '';
    for (const i in eventTagsArray) {
      let event = eventTagsArray[i];
      if (i == 0)
        eventTags = eventTags.concat(event);
      else
        eventTags = eventTags.concat(" " + event);
    }

    this.setState(() => ({ eventTags, eventTagsArray }));
  }

  dateChange = (event) => {
    const date = event.currentTarget.value;
    this.setState(() => ({ date }));
  }

  onProductBrandChange = (event) => {
    const productBrandId = event.currentTarget.value;
    this.setState(() => ({ productBrandId }));
  };

  render () {
    if (this.props.event) {
    return (
      <div>
        <Form>
          <Form.Row className="pb-2">
            <Form.Control
              type="text"
              size="sm"
              placeholder="Enter Event Summary"
              value={this.state.eventSummary}
              onChange={this.onEventSummaryChange}
              isInvalid={this.state.errors.invalidEventSummary}
            />
          </Form.Row>
          <Form.Row className="pb-2" style={{ fontSize: '10pt' }}>
            <Col>
              <div className="d-inline-block mr-2">
                Date
              </div>
              <div className="d-inline-block">
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="MM/DD/YYYY"
                  value={this.state.date}
                  onChange={this.dateChange}
                  isInvalid={this.state.errors.invalidEventDate}
                />
              </div>
            </Col>
          </Form.Row>
          { this.props.categoryEvent == true &&
            <Form.Row className="pb-2">
              <Form.Control
                as="select"
                value={this.state.productBrandId}
                onChange={this.onProductBrandChange}
                style={{ width: '300px' }}
              >
              { this.props.productBrands.map(brand => {
                  return (
                    <option
                      key={`ta-${brand.id}`}
                      value={brand.id}
                    >
                      {brand.name}
                    </option>
                  )
                })
              }
              </Form.Control>
            </Form.Row>
          }
          <Form.Row>
            <div className="pr-2 pt-1 pl-1" style={{ fontSize: '10pt' }}>
              Event Tags
            </div>
            <div>
            { this.state.eventTagsArray.map(eventTag => {
                return (
                  <Button
                    key={eventTag}
                    className="text-dark bg-light mr-2"
                    style={{ fontSize: '9pt' }}
                    size="sm"
                    variant="outline-white"
                    value={eventTag}
                    onClick={this.removeTag}
                  >
                    {eventTag}
                    <FontAwesomeIcon className="ml-1" icon={faMinus} />
                  </Button>
                )
              })
            }
            </div>
          </Form.Row>
          <Form.Row>
            { this.state.tagsAvailable.map(eventTag => {
                if (!this.state.eventTagsArray.includes(eventTag)) {
                  return (
                    <Button
                      key={eventTag}
                      className="text-success"
                      style={{ fontSize: '9pt' }}
                      size="sm"
                      variant="outline-white"
                      value={eventTag}
                      onClick={this.addTag}
                    >
                      {eventTag}
                      <FontAwesomeIcon className="ml-1" icon={faPlus} />
                    </Button>
                  )
                }
              })
            }
          </Form.Row>
        </Form>
        <Button
          className="mt-1 float-right"
          size="sm"
          variant="primary"
          onClick={this.saveEvent}
        >
          Save
        </Button>
        <Button
          className="mt-1 mr-2 float-right"
          size="sm"
          variant="secondary"
          onClick={() => this.props.handleClose(this.props.event.id)}
        >
          Cancel
        </Button>
      </div>
    )};
  }
};
