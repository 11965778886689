import { connect } from 'react-redux';
import MarketoLandingPages from '../../components/landingPages/MarketoLandingPages';

const mapStateToProps = (state) => ({
  user: state.user,
  companySetupChecklist: state.companySetupChecklist,
  myCategoryBrands: state.myCategoryBrands,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(MarketoLandingPages);
