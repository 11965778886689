import { combineReducers } from 'redux';
import userReducer from './userReducer';
import customerReducer from './customerReducer';
import companySetupChecklistReducer from './companySetupChecklistReducer';
import companyReducer from './companyReducer';
import categoriesReducer from './categoriesReducer';
import myCategoryBrandsReducer from './myCategoryBrandsReducer';
import alertsReducer from './alertsReducer';
import slackChannelsReducer from './slackChannelsReducer';
import customReportsReducer from './customReportsReducer';
import lastCustomReportIdUsedReducer from './lastCustomReportIdUsedReducer';
import brandMetricsReducer from './brandMetricsReducer';
import brandMetricsMapReducer from './brandMetricsMapReducer';
import brandChannelScoresMapLoadingReducer from './brandChannelScoresMapLoadingReducer';
import brandChannelScoresMapReducer from './brandChannelScoresMapReducer';
import brandMetricsByMonthWithGoalsLoadingReducer from './brandMetricsByMonthWithGoalsLoadingReducer';
import brandMetricsByMonthWithGoalsReducer from './brandMetricsByMonthWithGoalsReducer';
import overallScoreWeightsReducer from './overallScoreWeightsReducer';
import categoryHeaderLabelReducer from './categoryHeaderLabelReducer';
import brandHeaderLabelReducer from './brandHeaderLabelReducer';
import sixMonthsReducer from './sixMonthsReducer';
import selectedMonthReducer from './selectedMonthReducer';
import startDateFilterReducer from './startDateFilterReducer';
import endDateFilterReducer from './endDateFilterReducer';
import allMonthsCheckedReducer from './allMonthsCheckedReducer';
import companyCheckedReducer from './companyCheckedReducer';
import portfolioCheckedReducer from './portfolioCheckedReducer';
import productCheckedReducer from './productCheckedReducer';
import listenProjectBuildReducer from './listenProjectBuildReducer';
import showRolePersonalizationSetupReducer from './showRolePersonalizationSetupReducer';
import showNewUserGuidedTourReducer from './showNewUserGuidedTourReducer';
import showRefreshAlertReducer from './showRefreshAlertReducer';

export default combineReducers({
  user: userReducer,
  customer: customerReducer,
  company: companyReducer,
  companySetupChecklist: companySetupChecklistReducer,
  categories: categoriesReducer,
  customReports: customReportsReducer,
  lastCustomReportIdUsed: lastCustomReportIdUsedReducer,
  alerts: alertsReducer,
  slackChannels: slackChannelsReducer,
  myCategoryBrands: myCategoryBrandsReducer,
  brandMetrics: brandMetricsReducer,
  brandMetricsMap: brandMetricsMapReducer,
  brandChannelScoresMapLoading: brandChannelScoresMapLoadingReducer,
  brandChannelScoresMap: brandChannelScoresMapReducer,
  brandMetricsByMonthWithGoalsLoading: brandMetricsByMonthWithGoalsLoadingReducer,
  brandMetricsByMonthWithGoals: brandMetricsByMonthWithGoalsReducer,
  overallScoreWeights: overallScoreWeightsReducer,
  categoryHeaderLabel: categoryHeaderLabelReducer,
  brandHeaderLabel: brandHeaderLabelReducer,
  sixMonths: sixMonthsReducer,
  selectedMonth: selectedMonthReducer,
  startDateFilter: startDateFilterReducer,
  endDateFilter: endDateFilterReducer,
  allMonthsChecked: allMonthsCheckedReducer,
  companyChecked: companyCheckedReducer,
  portfolioChecked: portfolioCheckedReducer,
  productChecked: productCheckedReducer,
  listenProjectBuild: listenProjectBuildReducer,
  showRolePersonalizationSetup: showRolePersonalizationSetupReducer,
  showNewUserGuidedTour: showNewUserGuidedTourReducer,
  showRefreshAlert: showRefreshAlertReducer,
});
