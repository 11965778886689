import { connect } from 'react-redux';
import WebAnalyticsCommonSearchTerms from '../../components/webAnalytics/WebAnalyticsCommonSearchTerms';

const mapStateToProps = (state) => ({
  user: state.user,
  companySetupChecklist: state.companySetupChecklist,
  myCategoryBrands: state.myCategoryBrands,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(WebAnalyticsCommonSearchTerms);
