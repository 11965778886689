import React from 'react';
import CategoryReviewStatsSummary from './CategoryReviewStatsSummary';
import CategoryReviewStars from '../reviews/CategoryReviewStars';
import CategoryReviewAvgScoresGraph from './CategoryReviewAvgScoresGraph';
import CategoryShareOfReviewsGraph from '../graphs/CategoryShareOfReviewsGraph';
import BrandTabOverview from '../brands/BrandTabOverview';
import ReviewSearchResults from '../reviews/ReviewSearchResults';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faSearch } from '@fortawesome/free-solid-svg-icons';
import { createUserActivityLog } from '../../utils/users';
import history from '../../routers/history';

export default class CategoryReviews extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'highlights'
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let selectedTab = hash.indexOf('-')>0 ? hash.substring(hash.indexOf('-')+1) : 'highlights';
      this.setState(() => ({ selectedTab }));
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  changeSelectedTab = (selectedTab, subTabName) => {
    history.push(`#reviews-${selectedTab}`);
    this.setState(() => ({ selectedTab }));
    if (subTabName) {
      createUserActivityLog(
        this.props.user,
        `Category Tabs: Reviews: ${subTabName}`,
        this.props.category,
      )
    }
  }

  render () {
    return (
      <div className="p-4">
        <div className="mb-4">
          <button
            id="categoryReviewHighlightsButton"
            type="button"
            className= { this.state.selectedTab === 'highlights' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('highlights', 'Highlights')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faChartLine} />
            </div>
            <div className="d-inline-block">
              Highlights
            </div>
          </button>
          <button
            id="categoryReviewSearchButton"
            type="button"
            className= { this.state.selectedTab === 'search' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('search', 'Search Results')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <div className="d-inline-block">
              Search Results
            </div>
          </button>
        </div>

        <div
          className="mt-2 pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 200px)',
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          { this.state.selectedTab === 'highlights' &&
            <div>
              <CategoryReviewStatsSummary
                user={this.props.user}
                sixMonths={this.props.sixMonths}
                selectedMonth={this.props.selectedMonth}
                updateSelectedMonth={this.props.updateSelectedMonth}
                category={this.props.category}
              />
              <div className="mt-5 mb-4 band-separator" />
              <CategoryReviewStars
                category={this.props.category}
              />
              <div className="my-4 band-separator" />
              <CategoryReviewAvgScoresGraph
                user={this.props.user}
                category={this.props.category}
              />
              <div className="my-4 band-separator" />
              <CategoryShareOfReviewsGraph
                title="Share of All Reviews"
                description="Share of All Reviews allows you to visualize the amount of reviews by brand for the category"
                categoryType={this.props.category.category_type}
                categoryId={this.props.categoryId}
                category={this.props.category}
                reviewType="all"
              />
              <CategoryShareOfReviewsGraph
                title="Share of 1 & 2 Star Reviews"
                description="Share of 1 & 2 Star Reviews allows you to visualize the amount of poor reviews by brand for the category"
                categoryType={this.props.category.category_type}
                categoryId={this.props.categoryId}
                category={this.props.category}
                reviewType="bad"
              />
              <CategoryShareOfReviewsGraph
                title="Share of 4 & 5 Star Reviews"
                description="Share of 4 & 5 Star Reviews allows you to visualize the amount of good reviews by brand for the category"
                categoryType={this.props.category.category_type}
                categoryId={this.props.categoryId}
                category={this.props.category}
                reviewType="good"
              />
            </div>
          }
          { this.state.selectedTab === 'search' &&
            <div>
              <BrandTabOverview
                overviewType="Review Search Results"
              />
              <ReviewSearchResults
                location={this.props.location}
                customerId={this.props.customerId}
                categoryId={this.props.categoryId}
                categoryType={this.props.categoryType}
                category={this.props.category}
              />
            </div>
          }
        </div>
      </div>
    );
  }
};
