import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DateRangePicker from '../../common/DateRangePicker';
import RedditMentionsDisplay from '../../reddit/RedditMentionsDisplay';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class RedditTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  }

  componentDidUpdate(prevProps) {

  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  render () {
    return (
      <div>
        <h5 className="my-2 p-2 bg-bops-blue text-light">
          Details
          <span
            className="ml-4 mr-2"
            style={{ fontSize: '1rem', fontWeight: 'normal' }}
          >Date Range</span>
          <DateRangePicker
            startDate={this.props.startDateFilter}
            endDate={this.props.endDateFilter}
            updateDates={this.props.onFilterDatesChange}
          />
        </h5>
        <div>
          { this.props.brand &&
            <RedditMentionsDisplay
              category={this.props.category}
              brand={this.props.brand}
              brandId={this.props.brand.id}
              startDateFilter={this.props.startDateFilter}
              endDateFilter={this.props.endDateFilter}
            />
          }
        </div>
      </div>
    );
  }
};
