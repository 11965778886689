import React from 'react';
import axios from 'axios';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import CategoryAnalysis from './CategoryAnalysis';
import CategoryCompany from './CategoryCompany';
import CategoryEventTimeline from '../eventTimeline/CategoryEventTimeline';
import CategoryNews from './CategoryNews';
import CategorySocial from './CategorySocial';
import CategoryMediaCast from './CategoryMediaCast';
import CategoryWeb from './CategoryWeb';
import CategorySearch from './search/CategorySearch';
import CategoryReviews from './CategoryReviews';
import CategoryEmail from './CategoryEmail';
import CategoryIdentity from './CategoryIdentity';
import CategoryAds from './ads/CategoryAds';
import EcommerceSearch from './search/EcommerceSearch';
import { capitalizeFirstLetter } from '../../utils/strings';
import { createUserActivityLog } from '../../utils/users';
import history from '../../routers/history';

export default class CategoryDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'analysis',
      categoryId: undefined,
      category: undefined,
      allowTabToLoadMap: {
        'analysis': true
      }
    };
  }

  componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.categoryName
    ) {
      this.setCategory();
    }
    if (this.props.location.hash) {
      this.processLocation();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.categoryName) {
      if (prevProps.match.params.categoryName !== this.props.match.params.categoryName) {
        this.setCategory();
      } else if (prevProps.categories !== this.props.categories) {
        this.setCategory();
      }
    } else {
      if (this.state.category) {
        this.setState(() => ({
          categoryId: undefined,
          category: undefined
        }));
      }
    }
    if (prevProps.location !== this.props.location) {
      this.processLocation();
    }
  };

  componentWillUnmount() {
    this.props.setHeaderLabels('', '');
  };

  setCategory = () => {
    let categoryId;
    let category;
    for (const c of this.props.categories) {
      if (c.name === decodeURIComponent(this.props.match.params.categoryName)) {
        categoryId = c.id;
        category = c
      }
    }
    this.setState(() => ({
      categoryId,
      category
    }));
    if (category) {
      this.props.setHeaderLabels(category.name, '');
      createUserActivityLog(
        this.props.user,
        `Category Tabs: ${capitalizeFirstLetter(this.state.selectedTab)}`,
        category
      );
    }
  };

  processLocation = () => {
    // remove hash and set selected tab
    let hash = this.props.location.hash.replace('#', '');
    let selectedTab = hash.indexOf("-")>0 ? hash.substring(0, hash.indexOf("-")) : hash;
    if (!selectedTab) {
      selectedTab = 'analysis';
    }
    this.setState(() => ({
      selectedTab,
      allowTabToLoadMap: {
        [selectedTab]: true
      }
    }));
  }

  render () {
    return (
      <div className="app-content">
        { this.state.categoryId &&
          <div>
          <Tabs
            className="top-level-tabs"
            defaultActiveKey="analysis"
            activeKey={this.state.selectedTab}
            onSelect={(selectedTab) => {
              // update hash for tab routing
              history.push(`#${selectedTab}`);
              this.setState((prevState) => ({
                selectedTab,
                allowTabToLoadMap: {
                  ...prevState.allowTabToLoadMap,
                  [selectedTab]: true
                }
              }));
              createUserActivityLog(
                this.props.user,
                `Category Tabs: ${capitalizeFirstLetter(selectedTab)}`,
                this.state.category
              );
            }}
          >
            <Tab
              eventKey="analysis"
              title="Analysis"
              tabClassName="category-analysis-tab"
            >
              { this.state.allowTabToLoadMap.analysis &&
                <CategoryAnalysis
                  location={this.props.location}
                  user={this.props.user}
                  sixMonths={this.props.sixMonths}
                  selectedMonth={this.props.selectedMonth}
                  updateSelectedMonth={this.props.updateSelectedMonth}
                  categoryId={this.state.categoryId}
                  categoryType={this.state.category.category_type}
                  category={this.state.category}
                  overallScoreWeights={this.props.overallScoreWeights}
                />
              }
            </Tab>
            <Tab
              eventKey="company"
              title="Company"
              tabClassName="category-company-tab"
            >
              { this.state.allowTabToLoadMap.company &&
                <CategoryCompany
                  location={this.props.location}
                  user={this.props.user}
                  sixMonths={this.props.sixMonths}
                  selectedMonth={this.props.selectedMonth}
                  updateSelectedMonth={this.props.updateSelectedMonth}
                  categoryId={this.state.categoryId}
                  categoryType={this.state.category.category_type}
                  category={this.state.category}
                />
              }
            </Tab>
            <Tab
              eventKey="web"
              title="Website"
              tabClassName="category-website-tab"
            >
              { this.state.allowTabToLoadMap.web &&
                <CategoryWeb
                  location={this.props.location}
                  user={this.props.user}
                  sixMonths={this.props.sixMonths}
                  selectedMonth={this.props.selectedMonth}
                  updateSelectedMonth={this.props.updateSelectedMonth}
                  categoryId={this.state.categoryId}
                  category={this.state.category}
                  categories={this.props.categories}
                />
              }
            </Tab>
            <Tab
              eventKey="search"
              title="Search"
              tabClassName="category-search-tab"
            >
              { this.state.allowTabToLoadMap.search &&
                <CategorySearch
                  location={this.props.location}
                  user={this.props.user}
                  sixMonths={this.props.sixMonths}
                  selectedMonth={this.props.selectedMonth}
                  updateSelectedMonth={this.props.updateSelectedMonth}
                  categoryId={this.state.categoryId}
                  category={this.state.category}
                  categories={this.props.categories}
                />
              }
            </Tab>
            <Tab
              eventKey="reviews"
              title="Reviews"
              tabClassName="category-reviews-tab"
            >
              { this.state.allowTabToLoadMap.reviews &&
                <CategoryReviews
                  location={this.props.location}
                  user={this.props.user}
                  sixMonths={this.props.sixMonths}
                  selectedMonth={this.props.selectedMonth}
                  updateSelectedMonth={this.props.updateSelectedMonth}
                  categoryId={this.state.categoryId}
                  category={this.state.category}
                />
              }
            </Tab>
            <Tab
              eventKey="email"
              title="Email"
              tabClassName="category-email-tab"
            >
              { this.state.allowTabToLoadMap.email &&
                <CategoryEmail
                  user={this.props.user}
                  sixMonths={this.props.sixMonths}
                  selectedMonth={this.props.selectedMonth}
                  updateSelectedMonth={this.props.updateSelectedMonth}
                  categoryId={this.state.categoryId}
                  category={this.state.category}
                />
              }
            </Tab>
            <Tab
              eventKey="social"
              title="Social"
              tabClassName="category-social-tab"
            >
              { this.state.allowTabToLoadMap.social &&
                <CategorySocial
                  location={this.props.location}
                  user={this.props.user}
                  sixMonths={this.props.sixMonths}
                  selectedMonth={this.props.selectedMonth}
                  updateSelectedMonth={this.props.updateSelectedMonth}
                  categoryId={this.state.categoryId}
                  category={this.state.category}
                />
              }
            </Tab>
            <Tab
              eventKey="mediacast"
              title="Media Cast"
              tabClassName="category-mediacast-tab"
            >
              { this.state.allowTabToLoadMap.mediacast &&
                <CategoryMediaCast
                  location={this.props.location}
                  user={this.props.user}
                  sixMonths={this.props.sixMonths}
                  selectedMonth={this.props.selectedMonth}
                  updateSelectedMonth={this.props.updateSelectedMonth}
                  categoryId={this.state.categoryId}
                  category={this.state.category}
                />
              }
            </Tab>
            <Tab
              eventKey="news"
              title="News"
              tabClassName="category-news-tab"
            >
              { this.state.allowTabToLoadMap.news &&
                <CategoryNews
                  location={this.props.location}
                  user={this.props.user}
                  sixMonths={this.props.sixMonths}
                  selectedMonth={this.props.selectedMonth}
                  updateSelectedMonth={this.props.updateSelectedMonth}
                  categoryId={this.state.categoryId}
                  category={this.state.category}
                />
              }
            </Tab>
            <Tab
              eventKey="identity"
              title="Identity"
              tabClassName="category-identity-tab"
            >
              { this.state.allowTabToLoadMap.identity &&
                <CategoryIdentity
                  location={this.props.location}
                  user={this.props.user}
                  categoryId={this.state.categoryId}
                  categoryType={this.state.category.category_type}
                  category={this.state.category}
                />
              }
            </Tab>
            <Tab
              eventKey="events"
              title="Events"
              tabClassName="category-events-tab"
            >
              { this.state.allowTabToLoadMap.events &&
                <CategoryEventTimeline
                  customerId={this.props.user.customerId}
                  user={this.props.user}
                  categoryId={this.state.categoryId}
                  categoryType={this.state.category.category_type}
                  category={this.state.category}
                />
              }
            </Tab>
            <Tab
              eventKey="ads"
              title="Ads"
              tabClassName="category-ads-tab"
            >
              { this.state.allowTabToLoadMap.ads &&
                <CategoryAds
                  location={this.props.location}
                  user={this.props.user}
                  sixMonths={this.props.sixMonths}
                  selectedMonth={this.props.selectedMonth}
                  updateSelectedMonth={this.props.updateSelectedMonth}
                  categoryId={this.state.categoryId}
                  category={this.state.category}
                />
              }
            </Tab>
            { (this.props.customer && this.props.customer.show_ecommerce) &&
              <Tab
                eventKey="ecommerce"
                title="Ecommerce"
                tabClassName="category-ecommerce-tab"
              >
                { this.state.allowTabToLoadMap.ecommerce &&
                  <EcommerceSearch
                    user={this.props.user}
                    sixMonths={this.props.sixMonths}
                    selectedMonth={this.props.selectedMonth}
                    updateSelectedMonth={this.props.updateSelectedMonth}
                    categoryId={this.state.categoryId}
                    category={this.state.category}
                    categories={this.props.categories}
                  />
                }
              </Tab>
            }
          </Tabs>
          </div>
        }
      </div>
    );
  }
};
