import React from 'react';
import Button from 'react-bootstrap/Button';
import StickyTooltip from '../common/StickyTooltip';

export default class MetricTooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render () {
    return (
      <StickyTooltip
        position={this.props.tooltipPosition || 'right'}
        overlay={
          <div className="text-left">
            { (this.props.metricName && this.props.brandMetricsMap[this.props.metricName]) &&
              <div style={{ whiteSpace: 'pre-wrap' }}>
                <div>
                  {this.props.brandMetricsMap[this.props.metricName].description}
                </div>
                { (this.props.showChartDescription && this.props.brandMetricsMap[this.props.metricName].chart_description) &&
                  <div className="mt-2">
                      {this.props.brandMetricsMap[this.props.metricName].chart_description}
                  </div>
                }
                <hr className="my-1"/>
                { this.props.openMetricDistributionModal &&
                  <Button
                    variant="link"
                    onClick={() => this.props.openMetricDistributionModal(this.props.metricName)}
                    size="sm"
                  >
                    View Distribution
                  </Button>
                }
              </div>
            }
            { (this.props.metricId && this.props.brandMetricsMap[this.props.metricId]) &&
              <div style={{ whiteSpace: 'pre-wrap' }}>
                <div>
                  {this.props.brandMetricsMap[this.props.metricId].description}
                </div>
                { (this.props.showChartDescription && this.props.brandMetricsMap[this.props.metricId].chart_description) &&
                  <div className="mt-2">
                      {this.props.brandMetricsMap[this.props.metricId].chart_description}
                  </div>
                }
                <hr className="my-1"/>
                { this.props.openMetricDistributionModal &&
                  <Button
                    variant="link"
                    onClick={() => this.props.openMetricDistributionModal(this.props.brandMetricsMap[this.props.metricId].metric_name)}
                    size="sm"
                  >
                    View Distribution
                  </Button>
                }
              </div>
            }
            { this.props.description &&
              <div style={{ whiteSpace: 'pre-wrap' }}>
                <div>
                  {this.props.description}
                </div>
                { (this.props.showChartDescription && this.props.chartDescription) &&
                  <div className="mt-2">
                      {this.props.chartDescription}
                  </div>
                }
              </div>
            }
          </div>
        }
      >
        {this.props.tooltipTrigger}
      </StickyTooltip>
    );
  }
};
