import React from 'react';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';
import CardComponent from '../common/CardComponent';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategorySemrushDomainRankGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryDomainRankData: [],
      categoryDomainRankDataLoading: false,
      categoryKingName: '',
      ownersBrandName: '',
      maxDomainRank: 0
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getCategoryDomainRankData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.getCategoryDomainRankData();
    }
  }

  getCategoryDomainRankData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryDomainRankDataLoading: true }));
      const categoryAvgWebStatsRequests = [];
      // get category average domain ranks
      categoryAvgWebStatsRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-average-domain-rank-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=category-average`,
          HEADERS
        ).then(response => {
          const categoryAvgDomainRankData = response.data;
          return { categoryAvgDomainRankData };
        }).catch(error => {
          console.error('Error: unable to fetch category domain ranks');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryAvgDomainRankData: [] };
        })
      );
      // get category king average domain ranks
      categoryAvgWebStatsRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-average-domain-rank-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=category-king`,
          HEADERS
        ).then(response => {
          const categoryKingDomainRankData = response.data;
          return { categoryKingDomainRankData };
        }).catch(error => {
          console.error('Error: unable to fetch category king domain ranks');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryKingDomainRankData: [] };
        })
      );
      // get customer average domain ranks
      let ownersBrand;
      for (const brand of this.props.category.product_brands) {
        if (!ownersBrand && brand.company_id === this.props.user.customerId) {
          ownersBrand = brand;
        }
      }
      if (ownersBrand) {
        categoryAvgWebStatsRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/category-average-domain-rank-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=product-brand&product_brand_id=${ownersBrand.id}`,
            HEADERS
          ).then(response => {
            const ownedBrandDomainRankData = response.data;
            return {
              ownedBrandDomainRankData,
              ownersBrand
            };
          }).catch(error => {
            console.error('Error: unable to fetch owned brand domain ranks');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return { ownedBrandDomainRankData: [] };
          })
        );
      }

      Promise.all(categoryAvgWebStatsRequests).then(responses => {
        let categoryAvgDomainRankData = [];
        let categoryKingDomainRankData = [];
        let ownedBrandDomainRankData = [];
        let categoryKingName = '';
        let ownersBrandName = '';
        for (let response of responses) {
          if (response.categoryAvgDomainRankData) {
            categoryAvgDomainRankData = response.categoryAvgDomainRankData;
          } else if (response.categoryKingDomainRankData) {
            categoryKingDomainRankData = response.categoryKingDomainRankData.monthly_stats || [];
            if (categoryKingDomainRankData.length > 0) {
              categoryKingName = categoryKingDomainRankData[0].product_brand_name;
            }
          } else if (response.ownedBrandDomainRankData) {
            ownedBrandDomainRankData = response.ownedBrandDomainRankData.monthly_stats || [];
            ownersBrandName = response.ownersBrand.name;
          }
        }
        // merge the 3 data arrays
        // may be able to optimize this, but its a small data set
        const categoryDomainRankData = [];
        let maxDomainRank = 0;
        for (let cd of categoryAvgDomainRankData) {
          if (cd.avg_semrush_domain_rank > maxDomainRank) {
            maxDomainRank = cd.avg_semrush_domain_rank;
          }
          categoryDomainRankData.push({
            month: cd.month,
            category_avg_domain_rank: cd.avg_semrush_domain_rank
          });
        }
        for (let ckd of categoryKingDomainRankData) {
          for (let domainRankData of categoryDomainRankData) {
            if (ckd.rank > maxDomainRank) {
              maxDomainRank = ckd.rank;
            }
            if (ckd.month === domainRankData.month) {
              domainRankData.category_king_avg_domain_rank = ckd.rank;
            }
          }
        }
        for (let obd of ownedBrandDomainRankData) {
          for (let domainRankData of categoryDomainRankData) {
            if (obd.rank > maxDomainRank) {
              maxDomainRank = obd.rank;
            }
            if (obd.month === domainRankData.month) {
              domainRankData.owned_brand_avg_domain_rank = obd.rank;
            }
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryDomainRankData,
            categoryDomainRankDataLoading: false,
            categoryKingName,
            ownersBrandName,
            maxDomainRank
          }));
        }
      }).catch(error => {
        console.log('Error: unable to promise all domain ranks');
        this.setState(() => ({
          categoryDomainRankData: [],
          categoryDomainRankDataLoading: false,
          categoryKingName: '',
          ownersBrandName: '',
          maxDomainRank: 0
        }));
      });
    }
  }

  render () {
    return (
      <CardComponent
        title="Domain Rank, SEMrush"
        description="The lower the domain rank, the better."
        body={
          <div>
            { this.state.categoryDomainRankDataLoading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.categoryDomainRankDataLoading &&
              <ResponsiveContainer width="99%" height={200}>
                <ComposedChart data={this.state.categoryDomainRankData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month"/>
                  <YAxis
                    dataKey="count"
                    domain={[0, Math.ceil(this.state.maxDomainRank)]}
                    tick={{ fontSize: 12 }}
                    tickFormatter={(count) => {
                      return numberWithCommas(count);
                    }}
                  />
                  <Tooltip
                    formatter={(value) => numberWithCommas(value)}
                  />

                  <Area
                    name={
                      this.state.categoryKingName ?
                        `Leader, ${this.state.categoryKingName} Domain Rank` :
                        'Category King Domain Rank'
                    }
                    type="monotone"
                    dataKey="category_king_avg_domain_rank"
                    stroke="#007bff"
                    fillOpacity={.4}
                  />
                  <Line
                    name="Category Average Domain Rank"
                    type="monotone"
                    dataKey="category_avg_domain_rank"
                    strokeDasharray="10 10"
                    stroke="#009900"
                    strokeWidth={3}
                  />
                  <Line
                    name={
                      this.state.ownersBrandName ?
                        `${this.state.ownersBrandName} Domain Rank` :
                        'Your Brand Domain Rank'
                    }
                    type="monotone"
                    dataKey="owned_brand_avg_domain_rank"
                    stroke="#ffc107"
                    strokeWidth={3}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            }
          </div>
        }
        contextType="category"
        contextCategory={this.props.category}
        contextBrand={null}
        contextChannel="Web"
        contextChartName="Domain Rank, SEMrush"
      />
    );
  }
};
