import React from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';
import CardComponent from '../common/CardComponent';
import { numberWithCommas } from '../../utils/numbers';

export default class EmailsDiscoveredGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryEmailsData: [],
      categoryKingName: '',
      ownersBrandName: '',
      maxNumEmails: 0
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.emailsDiscoveredDataLoading == false)
      this.getCategoryNewsData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customerId !== this.props.customerId ||
        prevProps.category.id !== this.props.category.id ||
        prevProps.emailsDiscoveredDataLoading !== this.props.emailsDiscoveredDataLoading ) {
      this.getCategoryNewsData();
    }
  }

  getCategoryNewsData = () => {
    if (this.props.customerId && this.props.category) {
      let ownersBrandName = "";
      // get customer brand
      for (const pb of this.props.category.product_brands) {
        if (pb.company_id == this.props.customerId)
          ownersBrandName = pb.name;
      }
      // find category king
      let maxAvgEmails = 0;
      let categoryKingName = "";
      for (const brandEmailsData of this.props.data) {
        if (brandEmailsData.total_emails_discovered > maxAvgEmails) {
          maxAvgEmails = brandEmailsData.total_emails_discovered;
          categoryKingName = brandEmailsData.product_brand_name;
        }
      }
      // create data structure for average emails discovered per month
      // for category king, owned brand, and average of all brands
      let categoryEmailsData = [];
      let maxNumEmails = 0;
      for (const month of this.props.sixMonths) {
        let category_avg_emails = 0;
        let category_king_avg_emails = 0;
        let owned_brand_avg_emails = 0;
        let monthAvgEmailsSum = 0;
        let count = 0;
        for (const brandEmailsData of this.props.data) {
          for (const key in brandEmailsData.stats) {
            if (key == month.label) {
              // find brand's emails for month to calculate average reach per month
              let monthEmails = brandEmailsData.stats[key].email_count || 0;
              if (monthEmails > 0) {
                monthAvgEmailsSum = monthAvgEmailsSum + monthEmails;
                count++;
                if (monthEmails > maxNumEmails)
                  maxNumEmails = monthEmails;

              }
              // category king's average emails discovered this month
              if (brandEmailsData.product_brand_name == categoryKingName)
                category_king_avg_emails = Math.round(monthEmails);
              // owner's brand average emails discovered this month
              if (brandEmailsData.product_brand_name == ownersBrandName)
                owned_brand_avg_emails = Math.round(monthEmails);
            }
          }
        }
        if (count > 0)
          category_avg_emails = Math.round(monthAvgEmailsSum / count);
        categoryEmailsData.push({
          month: month.label,
          category_avg_emails,
          category_king_avg_emails,
          owned_brand_avg_emails
        });
      }
      categoryEmailsData.reverse();

      this.setState(() => ({
        categoryEmailsData,
        categoryKingName,
        ownersBrandName,
        maxNumEmails
      }));
    }
  }

  render () {
    return (
      <CardComponent
        title="Product Brand Emails Discovered"
        body={
          <div>
            { this.state.categoryEmailsData.length>0 &&
              <ResponsiveContainer width="99%" height={200}>
                <ComposedChart
                  data={this.state.categoryEmailsData}
                  margin={{ left: 30 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month"/>
                  <YAxis
                    dataKey="count"
                    domain={[0, Math.ceil(this.state.maxNumEmails)]}
                    tickFormatter={(count) => {
                      return numberWithCommas(count);
                    }}
                  />
                  <Tooltip />

                  <Area
                    name={
                      this.state.categoryKingName ?
                        `Leader, ${this.state.categoryKingName} Emails Discovered` :
                        'Category King Emails Discovered'
                    }
                    type="monotone"
                    dataKey="category_king_avg_emails"
                    stroke="#007bff"
                    fillOpacity={.4}
                  />
                  <Line
                    name="Category Average Emails Discovered"
                    type="monotone"
                    dataKey="category_avg_emails"
                    strokeDasharray="10 10"
                    stroke="#009900"
                    strokeWidth={3}
                  />
                  <Line
                    name={
                      this.state.ownersBrandName ?
                        `${this.state.ownersBrandName} Emails Discovered` :
                        'Your Brand Emails Discovered'
                    }
                    type="monotone"
                    dataKey="owned_brand_avg_emails"
                    stroke="#ffc107"
                    strokeWidth={3}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            }
          </div>
        }
        contextType="category"
        contextCategory={this.props.category}
        contextBrand={null}
        contextChannel="Emails"
        contextChartName="Product Brand Emails Discovered"
      />
    );
  }
};
