import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import ClipLoader from 'react-spinners/ClipLoader';
import DateRangePicker from '../../common/DateRangePicker';
import BrandRecommendationsSummary from '../BrandRecommendationsSummary';
import BrandScoreGraph from '../../graphs/BrandScoreGraph';
import BrandMetricsTable from '../BrandMetricsTable';
import ScoreVisibilityTable from '../ScoreVisibilityTable';
import BrandMetricsVsCompetitorTable from '../BrandMetricsVsCompetitorTable';
import { sortAlphabeticalByKey } from '../../../utils/sorts';
import { getPercentChange, round } from '../../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import history from '../../../routers/history';
import { dispatchReportError } from '../../../actions/api/errors';

export default class PillarsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPillar: '',
      selectedTab: 'activity',
      initialDate: moment().subtract(30, 'days').startOf('day'),
      endDate: moment().startOf('day').endOf('day'),
      channels: [
        'News',
        'Social',
        'Product Reviews',
        'Employer Reviews',
        'Email Campaigns',
        'Emails',
        'Videos',
        'Web Analytics',
        'Web',
        'Search Engine Results',
        'Ads',
        'Webinar',
        'Blogs',
        'Podcast',
        'Surveys via BrandOps',
      ],
      metricsMap: {},
      metricsSetupMap: {},
      brandScores: [],
      brandScoresData: [],
      brandScoresDataLoading: false,
      brandMetricsData: [],
      brandMetricsDataLoading: false,
      startScore: undefined,
      endScore: undefined,
      scoreChange: undefined,
      averageScores: {},
      predictionDisplay: false,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchMetricsSetupMap();
    this.refreshData();
    if (this.props.location && this.props.location.hash) {
      const hash = this.props.location.hash;
      const hashSplit = hash.split('-');
      if (hash.includes('pillars') || !hashSplit[1]) {
        let selectedPillar = 'brandScore';
        if (hashSplit.length > 2) {
          selectedPillar = hashSplit[2];
        }
        const pillarSubtabs = [
          'brandScore',
          'brandPresence',
          'brandAttention',
          'brandReputation',
          'brandMomentum'
        ];
        // check if hash subtab is appropriate
        if (pillarSubtabs.includes(selectedPillar)) {
          this.changeSelectedPillar(selectedPillar);
        } else {
          this.changeSelectedPillar('brandScore');
        }
      }
    } else {
      this.changeSelectedPillar('brandScore');
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.categoryId !== this.props.categoryId ||
      prevProps.brandId !== this.props.brandId
    ) {
      this.fetchMetricsSetupMap();
      this.refreshData();
    }
  };

  refreshData = () => {
    this.fetchBrandScoresData();
    this.fetchBrandMetricsData();
  };

  updateDates = (initialDate, endDate) => {
    this.setState(() => ({
      initialDate,
      endDate
    }), () => this.refreshData());
  };

  fetchMetricsSetupMap = () => {
    if (this.props.brandId) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/pb-setup-checklist?product_brand_id=${this.props.brandId}&category_type=${this.props.categoryType}&category_id=${this.props.categoryId}`,
        HEADERS
      ).then(response => {
        const metricsSetupMap = response.data.checklist_data;
        if (this.state.isMounted) {
          this.setState(() => ({ metricsSetupMap }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch metrics setup data...');
        if (this.state.isMounted) {
          this.setState(() => ({ metricsSetupMap: {} }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  fetchBrandScoresData = () => {
    if (this.props.brandId) {
      this.setState(() => ({ brandScoresDataLoading: true }));
      const brandScoresDataRequests = [];
      const formattedStartDate = this.state.initialDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      brandScoresDataRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/scores/brand-scores-for-category/range?category_type=${this.props.categoryType}&category_id=${this.props.categoryId}&include_stage_scores=true&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
          HEADERS
        ).then(response => {
          const brandScores = response.data;
          let startScore, endScore, scoreChange;
          const averageScores = {
            total_brand_scores: 0,
            total_digital_presence_scores: 0,
            total_audience_attention_scores: 0,
            total_brand_reputation_scores: 0,
            total_momentum_scores: 0,
          };
          // flatten data for graph
          const brandScoresData = [];
          for (const brandScore of brandScores) {
            const flattenedData = {};
            for (const [key, value] of Object.entries(brandScore)) {
              if (key === 'score_at') {
                flattenedData[key] = value;
              } else {
                for (const [scoreName, scoreValue] of Object.entries(value)) {
                  flattenedData[`${value['product_brand_id']}_${scoreName}`] = Math.round(scoreValue);
                }
                // this may be removed when brand momentum score is returned by api
                // it is added to push ui before backend is ready
                if (!flattenedData[`${value['product_brand_id']}_momentum_score`]) {
                  flattenedData[`${value['product_brand_id']}_momentum_score`] = 0;
                }
              }
            }
            // calculate averages scores for the pillars of the main brand
            averageScores.total_brand_scores += flattenedData[`${this.props.brand.id}_brand_score`] || 0;
            averageScores.total_digital_presence_scores += flattenedData[`${this.props.brand.id}_digital_presence_score`] || 0;
            averageScores.total_audience_attention_scores += flattenedData[`${this.props.brand.id}_audience_attention_score`] || 0;
            averageScores.total_brand_reputation_scores += flattenedData[`${this.props.brand.id}_brand_reputation_score`] || 0;
            averageScores.total_momentum_scores += flattenedData[`${this.props.brand.id}_momentum_score`] || 0;
            // add to brandScoresData
            brandScoresData.push(flattenedData);
          }
          if (brandScoresData.length > 0) {
            startScore = brandScoresData[0][`${this.props.brand.id}_${this.state.scoreKey}`];
            endScore = brandScoresData[brandScoresData.length-1][`${this.props.brand.id}_${this.state.scoreKey}`];
            // if initial score is 0, change to 1 to allow percent change calculation
            scoreChange = round(getPercentChange(startScore !== 0 ? startScore : 1, endScore), 1);
            averageScores.avg_brand_score = round(averageScores.total_brand_scores / brandScoresData.length, 1);
            averageScores.avg_digital_presence_score = round(averageScores.total_digital_presence_scores / brandScoresData.length, 1);
            averageScores.avg_audience_attention_score = round(averageScores.total_audience_attention_scores / brandScoresData.length, 1);
            averageScores.avg_brand_reputation_score = round(averageScores.total_brand_reputation_scores / brandScoresData.length, 1);
            averageScores.avg_momentum_score = round(averageScores.total_momentum_scores / brandScoresData.length, 1);
          }
          return {
            brandScores,
            brandScoresData,
            startScore,
            endScore,
            scoreChange,
            averageScores,
          };
        }).catch(error => {
          console.error('Error: unable to fetch brand scores data...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return {
            brandScores: [],
            brandScoresData: [],
            startScore: 0,
            endScore: 0,
            scoreChange: 0,
            averageScores: {},
          };
        })
      );

      for (const brand of this.props.category.product_brands) {
        // get private events
        brandScoresDataRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/events-curated?customer_id=${this.props.customerId}&product_brand_id=${brand.id}&is_public=false&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
            HEADERS
          ).then(response => {
            const privateEventsData = response.data;
            return { privateEventsData };
          }).catch(error => {
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
          })
        );
      }

      Promise.all(brandScoresDataRequests).then(responses => {
        let brandScores = [];
        let brandScoresData = [];
        let eventsData = [];
        let startScore = 0;
        let endScore = 0;
        let scoreChange = 0;
        let averageScores = {};
        for (const response of responses) {
          if (response.brandScoresData) {
            brandScores = response.brandScores;
            brandScoresData = response.brandScoresData;
            startScore = response.startScore;
            endScore = response.endScore;
            scoreChange = response.scoreChange;
            averageScores = response.averageScores;
          } else if (response.privateEventsData) {
            eventsData = eventsData.concat(response.privateEventsData);
          }
        }

        // merge events data into brand scores data
        const scoreTypes = [
          'brand_score',
          'digital_presence_score',
          'audience_attention_score',
          'brand_reputation_score',
          'momentum_score',
        ];
        for (const event of eventsData) {
          const eventDate = moment(event.event_created_on).format('YYYY-MM-DD');
          for (const bsd of brandScoresData) {
            if (eventDate === moment(bsd.score_at).format('YYYY-MM-DD')) {
              // place event for each pillar line
              for (const scoreType of scoreTypes) {
                bsd[`${event.product_brand_id}_${scoreType}_event`] = bsd[`${event.product_brand_id}_${scoreType}`];
                bsd[`${event.product_brand_id}_${scoreType}_event_summary`] = event.summary;
              }
              bsd.eventSize = 200;
            }
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            brandScores,
            brandScoresData,
            brandScoresDataLoading: false,
            eventsData,
            startScore,
            endScore,
            scoreChange,
            averageScores,
          }));
        }
      });
    } else {
      this.setState(() => ({
        brandScores: [],
        brandScoresData: [],
        brandScoresDataLoading: false,
        eventsData: [],
        startScore: 0,
        endScore: 0,
        scoreChange: 0,
        averageScores: {},
      }));
    }
  };

  refreshEvents = () => {
    const eventRequests = [];
    const formattedStartDate = this.state.initialDate.format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
    for (const brand of this.props.category.product_brands) {
      // get private events
      eventRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/events-curated?customer_id=${this.props.customerId}&product_brand_id=${brand.id}&is_public=false&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
          HEADERS
        ).then(response => {
          const privateEventsData = response.data;
          return { privateEventsData };
        }).catch(error => {
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        })
      );
    }

    Promise.all(eventRequests).then(responses => {
      let eventsData = [];
      for (const response of responses) {
        eventsData = eventsData.concat(response.privateEventsData);
      }

      if (this.state.isMounted) {
        this.setState((prevState) => {
          const brandScoresData = prevState.brandScoresData;
          // merge events data into brand scores data
          const scoreTypes = [
            'brand_score',
            'digital_presence_score',
            'audience_attention_score',
            'brand_reputation_score',
            'momentum_score',
          ];
          for (const event of eventsData) {
            const eventDate = moment(event.event_created_on).format('YYYY-MM-DD');
            for (const bsd of brandScoresData) {
              if (eventDate === moment(bsd.score_at).format('YYYY-MM-DD')) {
                // place event for each pillar line
                for (const scoreType of scoreTypes) {
                  bsd[`${event.product_brand_id}_${scoreType}_event`] = bsd[`${event.product_brand_id}_${scoreType}`];
                  bsd[`${event.product_brand_id}_${scoreType}_event_summary`] = event.summary;
                }
                bsd.eventSize = 200;
              }
            }
          }
          return ({
            brandScoresData,
            eventsData
          });
        });
      }
    });
  };

  fetchBrandMetricsData = () => {
    if (this.props.category && this.props.brand) {
      this.setState(() => ({ brandMetricsDataLoading: true }));
      const brandMetricsDataRequests = [];
      brandMetricsDataRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/brand-metrics`,
          HEADERS
        ).then(response => {
          const metrics = response.data;
          const metricsMap = {};
          for (const m of metrics) {
            metricsMap[m.metric_name] = m;
          }
          if (this.state.isMounted) {
            this.setState(() => ({ metricsMap }));
          }
          return { metricsMap };
        }).catch(error => {
          console.error('Error: unable to fetch metrics data...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          if (this.state.isMounted) {
            this.setState(() => ({ metricsMap: {} }));
          }
          return { metricsMap: {} };
        })
      )
      // fetch between date brand metrics
      const formattedBetweenDateStartDate = this.state.initialDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
      const formattedBetweenDateEndDate = this.state.endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');
      for (const channel of this.state.channels) {
        brandMetricsDataRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/metrics/channel/brand-scores/range?logged_customer_id=${this.props.user.customerId}&company_id=${this.props.brand.company_id}&product_brand_id=${this.props.brand.id}&category_id=${this.props.category.id}&category_type=${this.props.category.category_type}&channel_category_name=${channel}&start_date=${formattedBetweenDateStartDate}&end_date=${formattedBetweenDateEndDate}`,
            HEADERS
          ).then(response => {
            const betweenDateBrandMetrics = response.data;
            return { betweenDateBrandMetrics };
          }).catch(error => {
            console.error('Error: unable to fetch between date brand metrics...');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return { betweenDateBrandMetrics: [] };
          })
        );
      }
      // fetch initial date brand metrics
      const formattedInitialDateStartDate = moment(this.state.initialDate).subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
      const formattedInitialDateEndDate = this.state.initialDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');
      for (const channel of this.state.channels) {
        brandMetricsDataRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/metrics/channel/brand-scores/range?logged_customer_id=${this.props.user.customerId}&company_id=${this.props.brand.company_id}&product_brand_id=${this.props.brand.id}&category_id=${this.props.category.id}&category_type=${this.props.category.category_type}&channel_category_name=${channel}&start_date=${formattedInitialDateStartDate}&end_date=${formattedInitialDateEndDate}`,
            HEADERS
          ).then(response => {
            const initalDateBrandMetrics = response.data;
            return { initalDateBrandMetrics };
          }).catch(error => {
            console.error('Error: unable to fetch inital date brand metrics...');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return { initalDateBrandMetrics: [] };
          })
        );
      }
      // fetch end date brand metrics
      const formattedEndDateStartDate = moment(this.state.endDate).subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDateEndDate = this.state.endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');
      for (const channel of this.state.channels) {
        brandMetricsDataRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/metrics/channel/brand-scores/range?logged_customer_id=${this.props.user.customerId}&company_id=${this.props.brand.company_id}&product_brand_id=${this.props.brand.id}&category_id=${this.props.category.id}&category_type=${this.props.category.category_type}&channel_category_name=${channel}&start_date=${formattedEndDateStartDate}&end_date=${formattedEndDateEndDate}`,
            HEADERS
          ).then(response => {
            const endDateBrandMetrics = response.data;
            return { endDateBrandMetrics };
          }).catch(error => {
            console.error('Error: unable to fetch end date brand metrics...');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return { endDateBrandMetrics: [] };
          })
        );
      }

      Promise.all(brandMetricsDataRequests).then(responses => {
        let metricsMap = {};
        let betweenDateMetricsMap = {};
        let initialDateMetricsMap = {};
        let endDateMetrics = [];
        for (const response of responses) {
          if (response.metricsMap) {
            metricsMap = response.metricsMap;
          } else if (response.betweenDateBrandMetrics) {
            // build between date metrics map for ease of merging
            for (const bdm of response.betweenDateBrandMetrics) {
              betweenDateMetricsMap[bdm.brand_metric_id] = bdm;
            }
          } else if (response.initalDateBrandMetrics) {
            // build initial date metrics map for ease of merging
            for (const idm of response.initalDateBrandMetrics) {
              initialDateMetricsMap[idm.brand_metric_id] = idm;
            }
          } else if (response.endDateBrandMetrics) {
            endDateMetrics = endDateMetrics.concat(response.endDateBrandMetrics);
          }
        }
        const brandMetricsData = [];
        for (const metric of endDateMetrics) {
          if (
            metric.metric_score != null ||
            (initialDateMetricsMap[metric.brand_metric_id] && initialDateMetricsMap[metric.brand_metric_id].metric_score != null)
          ) {
            metric.end_date_score = metric.metric_score;
            delete metric.metric_score;
            if (betweenDateMetricsMap[metric.brand_metric_id]) {
              metric.amount_value = betweenDateMetricsMap[metric.brand_metric_id].metric_score;
            } else {
              metric.amount_value = null;
            }
            if (initialDateMetricsMap[metric.brand_metric_id]) {
              metric.initial_date_score = initialDateMetricsMap[metric.brand_metric_id].metric_score;
            } else {
              metric.initial_date_score = null;
            }
            metric.percentage_change = getPercentChange(metric.initial_date_score, metric.end_date_score);
            if (metric.percentage_change != null) {
              metric.percentage_change = round(metric.percentage_change, 1)
            } else if (metric.initial_date_score === 0 && metric.end_date_score > 0) {
              metric.percentage_change = 1;
            } else if (metric.initial_date_score === 0 && metric.end_date_score < 0) {
              metric.percentage_change = -1;
            } else if (metric.initial_date_score === 0 && metric.end_date_score === 0) {
              metric.percentage_change = 0;
            }
            // set display variables
            if (metricsMap[metric.metric_name]) {
              metric.amount_display = metricsMap[metric.metric_name].amount_display;
              metric.change_display = metricsMap[metric.metric_name].change_display;
              metric.values_display = metricsMap[metric.metric_name].values_display;
            }

            brandMetricsData.push(metric);
          }
        }
        brandMetricsData.sort(sortAlphabeticalByKey('metric_label'));

        if (this.state.isMounted) {
          this.setState(() => ({
            metricsMap,
            brandMetricsData,
            brandMetricsDataLoading: false
          }));
        }
      });
    }
  };

  updateBrandScoresDataWithPrediction = (predictionKey, predictionDataMap) => {
    this.setState((prevState) => {
      const brandScoresData = prevState.brandScoresData;
      for (const bsd of brandScoresData) {
        bsd[predictionKey] = predictionDataMap[bsd.score_at];
      }
      return ({ brandScoresData });
    })
  };

  changeSelectedPillar = (selectedPillar) => {
    if (selectedPillar !== this.state.selectedPillar) {
      let scoreLabel;
      let scoreKey;
      let stageName;
      switch (selectedPillar) {
        case 'brandPresence':
          scoreLabel = 'Digital Presence';
          scoreKey = 'digital_presence_score';
          stageName = 'Digital Presence';
          break;
        case 'brandAttention':
          scoreLabel = 'Audience Attention';
          scoreKey = 'audience_attention_score';
          stageName = 'Audience Attention';
          break;
        case 'brandReputation':
          scoreLabel = 'Brand Reputation';
          scoreKey = 'brand_reputation_score';
          stageName = 'Brand Reputation';
          break;
        case 'brandMomentum':
          scoreLabel = 'Momentum';
          scoreKey = 'momentum_score';
          stageName = 'Momentum';
          break;
        case 'brandScore':
          scoreLabel = 'Score';
          scoreKey = 'brand_score';
          stageName = 'All';
          break;
        default:
          break;
      }
      history.push(`${this.props.location.pathname}${this.props.location.search}#analysis-pillars-${selectedPillar}`);

      let startScore, endScore, scoreChange;
      if (this.state.brandScoresData.length > 0) {
        startScore = this.state.brandScoresData[0][`${this.props.brand.id}_${scoreKey}`];
        endScore = this.state.brandScoresData[this.state.brandScoresData.length-1][`${this.props.brand.id}_${scoreKey}`];
        // if initial score is 0, change to 1 to allow percent change calculation
        scoreChange = round(getPercentChange(startScore !== 0 ? startScore : 1, endScore), 1);
      }

      this.setState(() => ({
        selectedPillar,
        scoreLabel,
        scoreKey,
        stageName,
        startScore,
        endScore,
        scoreChange
      }));
    }
  };

  updatePredictionDisplay = (predictionDisplay) => {
    if (predictionDisplay !== this.state.predictionDisplay) {
      this.setState(() => ({ predictionDisplay }));
    }
  }

  render () {
    return (
      <div>
        <div className="d-table w-100">
          <div className="d-table-row">
            <div
              className="d-table-cell align-top"
              style={{ width: '130px' }}
            >
              <div>
                <Button
                  id="brandScoreButton"
                  className= {
                    this.state.selectedPillar === "brandScore" ?
                    "mb-2 px-1 w-100 btn btn-primary" :
                    "mb-2 px-1 w-100 btn btn-secondary"
                  }
                  onClick={() => this.changeSelectedPillar("brandScore")}
                  style={{ height: '62px' }}
                >
                  <Row noGutters>
                    <Col style={{ flex: '0 0 85px' }}>
                      <div>Score</div>
                    </Col>
                    <Col style={{ flex: '0 0 35px' }}>
                      <div className="d-inline-block">
                        <Badge variant="light">
                          { this.state.brandScoresDataLoading &&
                            <ClipLoader size={10}/>
                          }
                          { !this.state.brandScoresDataLoading &&
                            <React.Fragment>
                              { (this.state.brandScoresData[this.state.brandScoresData.length-1] && this.state.brandScoresData[this.state.brandScoresData.length-1][`${this.props.brand.id}_brand_score`] != null) ?
                                  this.state.brandScoresData[this.state.brandScoresData.length-1][`${this.props.brand.id}_brand_score`] :
                                  '-'
                              }
                            </React.Fragment>
                          }
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                </Button>
              </div>
              <div>
                <Button
                  id="brandPresenceButton"
                  className= {
                    this.state.selectedPillar === "brandPresence" ?
                    "mb-2 px-1 w-100 btn btn-primary" :
                    "mb-2 px-1 w-100 btn btn-secondary"
                  }
                  onClick={() => this.changeSelectedPillar("brandPresence")}
                  style={{ height: '62px' }}
                >
                  <Row noGutters>
                    <Col style={{ flex: '0 0 85px' }}>
                      <div>Digital Presence</div>
                    </Col>
                    <Col style={{ flex: '0 0 35px' }}>
                      <div className="d-inline-block" style={{ marginTop: '12px' }}>
                        <Badge variant="light">
                          { this.state.brandScoresDataLoading &&
                            <ClipLoader size={10}/>
                          }
                          { !this.state.brandScoresDataLoading &&
                            <React.Fragment>
                              { (this.state.brandScoresData[this.state.brandScoresData.length-1] && this.state.brandScoresData[this.state.brandScoresData.length-1][`${this.props.brand.id}_digital_presence_score`] != null) ?
                                  this.state.brandScoresData[this.state.brandScoresData.length-1][`${this.props.brand.id}_digital_presence_score`] :
                                  '-'
                              }
                            </React.Fragment>
                          }
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                </Button>
              </div>
              <div>
                <Button
                  id="brandAttentionButton"
                  className= {
                    this.state.selectedPillar === "brandAttention" ?
                    "mb-2 px-1 w-100 btn btn-primary" :
                    "mb-2 px-1 w-100 btn btn-secondary"
                  }
                  onClick={() => this.changeSelectedPillar("brandAttention")}
                  style={{ height: '62px' }}
                >
                  <Row noGutters>
                    <Col style={{ flex: '0 0 85px' }}>
                      <div>Audience Attention</div>
                    </Col>
                    <Col style={{ flex: '0 0 35px' }}>
                      <div className="d-inline-block" style={{ marginTop: '12px' }}>
                        <Badge variant="light">
                          { this.state.brandScoresDataLoading &&
                            <ClipLoader size={10}/>
                          }
                          { !this.state.brandScoresDataLoading &&
                            <React.Fragment>
                              { (this.state.brandScoresData[this.state.brandScoresData.length-1] && this.state.brandScoresData[this.state.brandScoresData.length-1][`${this.props.brand.id}_audience_attention_score`] != null) ?
                                  this.state.brandScoresData[this.state.brandScoresData.length-1][`${this.props.brand.id}_audience_attention_score`] :
                                  '-'
                              }
                            </React.Fragment>
                          }
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                </Button>
              </div>
              <div>
                <Button
                  id="brandReputationButton"
                  className= {
                    this.state.selectedPillar === "brandReputation" ?
                    "mb-2 px-1 w-100 btn btn-primary" :
                    "mb-2 px-1 w-100 btn btn-secondary"
                  }
                  onClick={() => this.changeSelectedPillar("brandReputation")}
                  style={{ height: '62px' }}
                >
                  <Row noGutters>
                    <Col style={{ flex: '0 0 85px' }}>
                      <div>Brand Reputation</div>
                    </Col>
                    <Col style={{ flex: '0 0 35px' }}>
                      <div className="d-inline-block" style={{ marginTop: '12px' }}>
                        <Badge variant="light">
                          { this.state.brandScoresDataLoading &&
                            <ClipLoader size={10}/>
                          }
                          { !this.state.brandScoresDataLoading &&
                            <React.Fragment>
                              { (this.state.brandScoresData[this.state.brandScoresData.length-1] && this.state.brandScoresData[this.state.brandScoresData.length-1][`${this.props.brand.id}_brand_reputation_score`] != null) ?
                                  this.state.brandScoresData[this.state.brandScoresData.length-1][`${this.props.brand.id}_brand_reputation_score`] :
                                  '-'
                              }
                            </React.Fragment>
                          }
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                </Button>
              </div>
              <div>
                <Button
                  id="brandMomentumButton"
                  className= {
                    this.state.selectedPillar === "brandMomentum" ?
                    "mb-2 px-1 w-100 btn btn-primary" :
                    "mb-2 px-1 w-100 btn btn-secondary"
                  }
                  onClick={() => this.changeSelectedPillar("brandMomentum")}
                  style={{ height: '62px' }}
                >
                  <Row noGutters>
                    <Col style={{ flex: '0 0 85px' }}>
                      <div>Momentum</div>
                    </Col>
                    <Col style={{ flex: '0 0 35px' }}>
                      <div className="d-inline-block">
                        <Badge variant="light">
                          { this.state.brandScoresDataLoading &&
                            <ClipLoader size={10}/>
                          }
                          { !this.state.brandScoresDataLoading &&
                            <React.Fragment>
                              { (this.state.brandScoresData[this.state.brandScoresData.length-1] && this.state.brandScoresData[this.state.brandScoresData.length-1][`${this.props.brand.id}_momentum_score`] != null) ?
                                  this.state.brandScoresData[this.state.brandScoresData.length-1][`${this.props.brand.id}_momentum_score`] :
                                  '-'
                              }
                            </React.Fragment>
                          }
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                </Button>
              </div>
            </div>
            <div className="d-table-cell">
              <div className="ml-4">
                <div
                  className="pre-scrollable border rounded bg-white p-4"
                  style={{
                    minHeight: 'calc(100vh - 200px)',
                    maxHeight: 'calc(100vh - 200px)',
                    overflow: 'auto',
                    overflowX: 'hidden'
                  }}
                >
                  <div className="mb-3 p-2 bg-bops-blue text-light">
                    <span
                      className="mr-2"
                    >Date Range</span>
                    <DateRangePicker
                      startDate={this.state.initialDate}
                      endDate={this.state.endDate}
                      updateDates={this.updateDates}
                    />
                  </div>
                  <Alert variant="warning">
                    { this.state.brandScoresDataLoading &&
                      <div>
                        <ClipLoader size={20}/>
                      </div>
                    }
                    { !this.state.brandScoresDataLoading &&
                      <div>
                        <span>
                          Start Score:
                          <span
                            className="pl-1"
                            style={{
                              fontWeight: 'bold',
                              fontSize: '1.2rem'
                            }}
                          >
                            {this.state.startScore}
                          </span>
                        </span>
                        <span className="ml-2">
                          End Score:
                          <span
                            className="pl-1"
                            style={{
                              fontWeight: 'bold',
                              fontSize: '1.2rem'
                            }}
                          >
                            {this.state.endScore}
                          </span>
                        </span>
                        <span className="ml-2">
                          Avg Score:
                          <span
                            className="pl-1"
                            style={{
                              fontWeight: 'bold',
                              fontSize: '1.2rem'
                            }}
                          >
                            {this.state.averageScores[`avg_${this.state.scoreKey}`] || 'N/A'}
                          </span>
                        </span>
                        <span className="ml-4">
                          Performance:
                          <span
                            className="pl-1"
                            style={{
                              fontWeight: 'bold',
                              fontSize: '1.2rem'
                            }}
                          >
                            { this.state.scoreChange > 0 &&
                              <span>+</span>
                            }
                            {`${this.state.scoreChange}%`}
                          </span>
                        </span>
                      </div>
                    }
                  </Alert>
                  <div>
                    <BrandRecommendationsSummary
                      user={this.props.user}
                      category={this.props.category}
                      brand={this.props.brand}
                      brandScores={this.state.brandScores}
                      brandScoresLoading={this.state.brandScoresDataLoading}
                      selectedPillar={this.state.selectedPillar}
                      initialDate={this.state.initialDate}
                      endDate={this.state.endDate}
                    />
                    { this.state.selectedPillar === '' &&
                      <hr />
                    }
                  </div>
                  <div>
                    { this.state.brandScoresDataLoading &&
                      <div className="m-4 text-center">
                        <ClipLoader size={100}/>
                      </div>
                    }
                    { !this.state.brandScoresDataLoading &&
                      <div>
                        <BrandScoreGraph
                          user={this.props.user}
                          brandScoresData={this.state.brandScoresData}
                          category={this.props.category}
                          primaryBrand={this.props.brand}
                          primaryBrandName={this.props.brand.name}
                          primaryBrandId={this.props.brand.id}
                          scoreLabel={this.state.scoreLabel}
                          scoreKey={this.state.scoreKey}
                          predictionDisplay={this.state.predictionDisplay}
                          updatePredictionDisplay={this.updatePredictionDisplay}
                          updateBrandScoresDataWithPrediction={this.updateBrandScoresDataWithPrediction}
                          refreshEvents={this.refreshEvents}
                        />
                      </div>
                    }
                    <hr />
                  </div>
                  <div>
                    { this.state.stageName &&
                      <Tabs
                        defaultActiveKey= "activity"
                        activeKey={this.state.selectedTab}
                        onSelect={(selectedTab) => this.setState(() => ({ selectedTab }))}
                      >
                        <Tab
                          eventKey="activity"
                          title="Activity & Results"
                        >
                          <BrandMetricsTable
                            user={this.props.user}
                            category={this.props.category}
                            brand={this.props.brand}
                            metricsMap={this.state.metricsMap}
                            metricsSetupMap={this.state.metricsSetupMap}
                            brandMetricsData={this.state.brandMetricsData}
                            brandMetricsDataLoading={this.state.brandMetricsDataLoading}
                            stageName={this.state.stageName}
                            initialDate={this.state.initialDate}
                            endDate={this.state.endDate}
                          />
                        </Tab>
                        <Tab
                          eventKey="scoreVisibility"
                          title={
                            <div>
                              Score Visibility
                              <Badge
                                className="ml-1 align-middle"
                                variant="warning"
                                pill
                              >
                                Beta
                              </Badge>
                            </div>
                          }
                        >
                          <ScoreVisibilityTable
                            user={this.props.user}
                            category={this.props.category}
                            brand={this.props.brand}
                            metricsMap={this.state.metricsMap}
                            metricsSetupMap={this.state.metricsSetupMap}
                            stageName={this.state.stageName}
                          />
                        </Tab>
                        <Tab
                          eventKey="vsCompetitor"
                          title="vs. Competitor"
                        >
                          <BrandMetricsVsCompetitorTable
                            user={this.props.user}
                            category={this.props.category}
                            brand={this.props.brand}
                            metricsMap={this.state.metricsMap}
                            metricsSetupMap={this.state.metricsSetupMap}
                            brandMetricsData={this.state.brandMetricsData}
                            brandMetricsDataLoading={this.state.brandMetricsDataLoading}
                            stageName={this.state.stageName}
                            channels={this.state.channels}
                            endDate={this.state.endDate}
                          />
                        </Tab>
                      </Tabs>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
