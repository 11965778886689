import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { fabric } from 'fabric';
import Popup from 'reactjs-popup';
import { SketchPicker } from 'react-color';
import ClipLoader from 'react-spinners/ClipLoader';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowPointer,
  faPen,
  faFont,
  faSlash,
  faEraser,
  faRotateLeft,
  faPlay,
  faStop
} from '@fortawesome/free-solid-svg-icons';
import { faSquareFull } from '@fortawesome/free-regular-svg-icons';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, LAMBDA_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CustomReportWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      selectedTool: undefined,
      selectedColor: '#000000',
      notes: '',
      voiceId: 'none',
      voiceSource: 'none',
      notesToSpeechLoading: false,
      notesToSpeechData: undefined,
      notesToSpeechPlay: false,
      errorMessage: '',
      deleteWidgetConfirmationModalOpen: false,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.widget) {
      this.fabricCanvas = new fabric.Canvas(this.canvasRef);
      this.setupCanvas();
      this.setState(() => ({
        title: this.props.widget.title || '',
        notes: this.props.widget.notes || '',
        voiceId: this.props.widget.voice_id || 'none',
        voiceSource: this.props.widget.voice_source || 'none',
      }));
    }
    const canvasWrapper = document.getElementById('canvas-wrapper');
    canvasWrapper.addEventListener('keyup', this.removeCanvasActiveObjects);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.widget !== this.props.widget) {
      this.setupCanvas();
      this.setState(() => ({
        title: this.props.widget.title || '',
        notes: this.props.widget.notes || '',
        voiceId: this.props.widget.voice_id || 'none',
        voiceSource: this.props.widget.voice_source || 'none',
      }));
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
    const canvasWrapper = document.getElementById('canvas-wrapper');
    canvasWrapper.removeEventListener('keyup', this.removeCanvasActiveObjects);
  };

  setupCanvas = () => {
    if (this.fabricCanvas) {
      // remove everything from canvas
      this.fabricCanvas.remove(...this.fabricCanvas.getObjects());
    }
    const canvas = this.fabricCanvas;
    fabric.Image.fromURL(this.props.widget.image_url, (image) => {
      const img = image.set({
        left: 0,
        top: 0,
        selectable: false
      });
      const containerWidth = document.getElementById('report-widget').offsetWidth;
      let canvasWidth;
      let canvasHeight;
      if (img.width > containerWidth) {
        canvasWidth = containerWidth;
      } else {
        canvasWidth = img.width;
      }
      img.scaleToWidth(canvasWidth);
      canvasHeight = img.height * img.scaleY;
      canvas.setWidth(canvasWidth);
      canvas.setHeight(canvasHeight);
      canvas.add(img);
    }, { crossOrigin: 'anonymous' });

    canvas.on('mouse:down', this.onCanvasMouseDown);
    canvas.on('mouse:up', this.onCanvasMouseUp);
    canvas.on('mouse:move', this.onCanvasMouseMove);
  };

  onTitleChange = (event) => {
    const title = event.currentTarget.value;
    this.setState(() => ({ title }));
  };

  onToolSelect = (selectedTool) => {
    const canvas = this.fabricCanvas;
    if (selectedTool === this.state.selectedTool) {
      canvas.isDrawingMode = false;
      canvas.hoverCursor = 'default';
      canvas.selection = false;
      this.setState(() => ({ selectedTool: undefined }));
    } else {
      switch (selectedTool) {
        case 'draw':
          canvas.isDrawingMode = true;
          canvas.freeDrawingBrush.color = this.state.selectedColor;
          canvas.freeDrawingBrush.width = 4;
          break;
        case 'text':
          canvas.isDrawingMode = false;
          break;
        case 'select':
          canvas.isDrawingMode = false;
          canvas.hoverCursor = 'default';
          canvas.selection = true;
          break;
        case 'line':
        case 'rectangle':
          canvas.isDrawingMode = false;
          canvas.hoverCursor = 'default';
          canvas.selection = false;
          break;
        default:
          canvas.isDrawingMode = false;
          canvas.hoverCursor = 'default';
          canvas.selection = false;
      };
      this.setState(() => ({ selectedTool }));
    }
  };

  onCanvasMouseDown = (e) => {
    if (this.state.selectedTool === 'text') {
      if (e.target.type === 'image') {
        this.addText(e);
      }
    } else if (this.state.selectedTool === 'line') {
      this.addLine(e);
    } else if (this.state.selectedTool === 'rectangle') {
      this.addRectangle(e);
    }
  };

  onCanvasMouseUp = (e) => {
    if (this.state.selectedTool === 'line') {
      this.setState(() => ({ lineStarted: false }));
    } else if (this.state.selectedTool === 'rectangle') {
      this.setState(() => ({ rectangleStarted: false }));
    }
  };

  onCanvasMouseMove = (e) => {
    if (
      this.state.selectedTool === 'line' &&
      this.state.lineStarted
    ) {
      const canvas = this.fabricCanvas;
      const line = canvas.getActiveObject();
      const { x, y } = canvas.getPointer(e.e);
      if (!x || !y) {
          return false;
      }
      line.set({
        x2: x,
        y2: y
      })
      canvas.renderAll();
    } else if (
      this.state.selectedTool === 'rectangle' &&
      this.state.rectangleStarted
    ) {
      const canvas = this.fabricCanvas;
      const rectangle = canvas.getActiveObject();
      const { x, y } = canvas.getPointer(e.e);
      const w = Math.abs(this.state.rectangleX - x);
      const h = Math.abs(this.state.rectangleY - y);
      if (!w || !h) {
          return false;
      }
      rectangle.set('width', w);
      rectangle.set('height', h);
      canvas.renderAll();
    }
  };

  removeCanvasActiveObjects = () => {
    const canvas = this.fabricCanvas;
    canvas.getActiveObjects().forEach(object => {
      canvas.remove(object);
    });
    canvas.discardActiveObject();
  };

  addText = (e) => {
    const canvas = this.fabricCanvas;
    const { x, y } = canvas.getPointer(e.e);
    this.text = new fabric.IText('', {
      fontFamily: 'arial',
      fontSize: 12,
      left: x,
      top: y,
      erasable: true,
      fill: this.state.selectedColor
    });

    this.setState(() => ({ add: false }));
    canvas.selection = true;
    canvas.isDrawingMode = false;
    canvas.hoverCursor = 'grab';
    canvas.moveCursor = 'grabbing';
    canvas.add(this.text);
    canvas.setActiveObject(this.text);
    if (this.text) {
      this.text.selectable = true;
      this.text.enterEditing();
    }
  };

  addLine = (e) => {
    const canvas = this.fabricCanvas;
    const { x, y } = canvas.getPointer(e.e);
    const line = new fabric.Line([x, y, x, y], {
      stroke: this.state.selectedColor,
      selectable: true
    });
    this.setState(() => ({
      lineStarted: true,
      lineX: x,
      lineY: y,
    }));
    canvas.add(line);
    canvas.setActiveObject(line);
    canvas.renderAll();
  };

  addRectangle = (e) => {
    const canvas = this.fabricCanvas;
    const { x, y } = canvas.getPointer(e.e);
    const rectangle = new fabric.Rect({
      left: x,
      top: y,
      width: 2,
      height: 2,
      fill: 'transparent',
      stroke: this.state.selectedColor
    });
    this.setState(() => ({
      rectangleStarted: true,
      rectangleX: x,
      rectangleY: y,
    }));
    canvas.add(rectangle);
    canvas.setActiveObject(rectangle);
    canvas.renderAll();
  };

  onSelectedColorChange = (color) => {
    const selectedColor = color.hex;
    if (this.state.selectedTool === 'draw') {
      const canvas = this.fabricCanvas;
      canvas.freeDrawingBrush.color = selectedColor;
    }
    this.setState(() => ({ selectedColor }));
  };

  onNotesChange = (event) => {
    const notes = event.currentTarget.value;
    this.setState(() => ({ notes }));
  };

  onVoiceIdChange = (event) => {
    const voiceId = event.currentTarget.value;
    const voiceSource = event.currentTarget.selectedOptions[0].dataset.voiceSource;
    this.setState(() => ({
      voiceId,
      voiceSource
    }));
  };

  revertToOriginalImage = () => {
    if (this.props.widget && this.props.widget.image_url !== this.props.widget.original_image_url) {
      axios.put(
        `${LISTEN_ENDPOINT}/api/custom-report-widgets/${this.props.widget.widget_id}`,
        { image_url: this.props.widget.original_image_url },
        HEADERS
      ).then(response => {
        this.props.refreshReports();
      }).catch(error => {
        console.error('Error: failed to update widget image.');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        if (this.state.isMounted) {
          this.setState(() => ({ errorMessage: 'Failed to update widget image.' }));
        }
      });
    }
  };

  notesToSpeech = () => {
    if (this.state.notes && this.state.voiceId && this.state.voiceId !== 'none') {
      this.setState(() => ({ notesToSpeechLoading: true }));
      if (this.state.voiceSource === 'polly') {
        axios.post(
          `${LAMBDA_ENDPOINT}/polly-text-to-speech`,
          {
            text: this.state.notes,
            voice: this.state.voiceId
          },
          HEADERS
        ).then(response => {
          if (this.state.isMounted) {
            this.setState(() => ({
              notesToSpeechLoading: false,
              notesToSpeechData: response.data.audio_data
            }), () => this.playNotesToSpeech());
          }
        }).catch(error => {
          console.error('Error: failed to get notes to speech.');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          if (this.state.isMounted) {
            this.setState(() => ({
              notesToSpeechLoading: false,
              notesToSpeechData: undefined
            }));
          }
        });
      } else if (this.state.voiceSource === 'azure') {
        axios.post(
          `${CONTENT_SCRAPING_ENDPOINT}/api/azure-text-to-speech`,
          {
            text: this.state.notes,
            voice: this.state.voiceId
          },
          HEADERS
        ).then(response => {
          if (this.state.isMounted) {
            this.setState(() => ({
              notesToSpeechLoading: false,
              notesToSpeechData: response.data.audio_data
            }), () => this.playNotesToSpeech());
          }
        }).catch(error => {
          console.error('Error: failed to get notes to speech.');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          if (this.state.isMounted) {
            this.setState(() => ({
              notesToSpeechLoading: false,
              notesToSpeechData: undefined
            }));
          }
        });
      }
    }
  };

  playNotesToSpeech = () => {
    if (this.notesToSpeechAudio && this.state.notesToSpeechData) {
      this.setState(() => ({ notesToSpeechPlay: true }));
      this.notesToSpeechAudio.load();
      this.notesToSpeechAudio.play();
    }
  };

  stopNotesToSpeech = () => {
    if (this.notesToSpeechAudio) {
      this.setState(() => ({ notesToSpeechPlay: false }));
      this.notesToSpeechAudio.pause();
    }
  };

  saveWidget = () => {
    if (this.props.widget) {
      this.setState(() => ({ errorMessage: '' }));
      const widget = {
        title: this.state.title,
        notes: this.state.notes
      }
      if (this.fabricCanvas.getObjects().length > 1) {
        // objects found on canvas, save new image
        widget.image_data = this.fabricCanvas.toDataURL()
      }
      let selectedVoiceId = this.state.voiceId;
      let selectedVoiceSource = this.state.voiceSource;
      if (selectedVoiceId === 'none') {
        selectedVoiceId = null;
        selectedVoiceSource = null;
      }
      if (this.props.widget.voice_id !== selectedVoiceId) {
        widget.voice_id = selectedVoiceId;
        widget.voice_source = selectedVoiceSource;
        this.props.updateWidgetsVoice(selectedVoiceId, selectedVoiceSource, true);
      }

      axios.put(
        `${LISTEN_ENDPOINT}/api/custom-report-widgets/${this.props.widget.widget_id}`,
        widget,
        HEADERS
      ).then(response => {
        this.props.refreshReports();
      }).catch(error => {
        console.error('Error: failed to save widget.');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        if (this.state.isMounted) {
          this.setState(() => ({ errorMessage: 'Failed to save widget.' }));
        }
      });
    }
  };

  openDeleteWidgetConfirmationModal = () => {
    this.setState(() => ({ deleteWidgetConfirmationModalOpen: true }));
  };

  closeDeleteWidgetConfirmationModal = () => {
    this.setState(() => ({ deleteWidgetConfirmationModalOpen: false }));
  };

  deleteWidget = () => {
    if (this.props.widget) {
      this.setState(() => ({ errorMessage: '' }));
      axios.delete(
        `${LISTEN_ENDPOINT}/api/custom-report-widgets/${this.props.widget.widget_id}`,
        HEADERS
      ).then(response => {
        this.props.refreshReports();
        if (this.state.isMounted) {
          this.setState(() => ({ deleteWidgetConfirmationModalOpen: false }));
        }
      }).catch(error => {
        console.error('Error: failed to delete widget.');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            deleteWidgetConfirmationModalOpen: false,
            errorMessage: 'Failed to delete widget.'
          }));
        }
      });
    }
  };

  unsavedChangesCheck = () => {
    let widgetTitle = this.props.widget.title || '';
    let widgetNotes = this.props.widget.notes || '';
    let widgetVoiceId = this.props.widget.voice_id || 'none';
    if (
      this.state.title !== widgetTitle ||
      this.state.notes !== widgetNotes ||
      this.state.voiceId !== widgetVoiceId ||
      (this.fabricCanvas.getObjects().length > 1 && !this.fabricCanvas.getObjects()[1]._originalElement)

    ) {
      return true;
    } else {
      return false;
    }
  };

  render () {
    return (
      <div>
        { this.props.widget &&
          <div>
            <div
              className="pre-scrollable border rounded p-2"
              style={{
                minHeight: 'calc(100vh - 200px)',
                maxHeight: 'calc(100vh - 200px)',
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
            >
              <div id="report-widget">
                <div className="mb-2">
                  <div className="d-inline-block mr-2">
                    Title:
                  </div>
                  <div className="d-inline-block">
                    <Form.Control
                      type="text"
                      value={this.state.title}
                      onChange={this.onTitleChange}
                      style={{ width: '300px' }}
                      size="sm"
                    />
                  </div>
                </div>
                <div className="mb-1 p-2 border rounded">
                  <Popup
                    trigger = {
                      <Button
                        className="mr-2"
                        variant={
                          this.state.selectedTool === 'select' ?
                            'primary':
                            'light'
                        }
                        onClick={() => this.onToolSelect('select')}
                        style={{ padding: '1px 6px 1px 8px' }}
                      >
                        <FontAwesomeIcon icon={faArrowPointer}/>
                      </Button>
                    }
                    position="bottom center"
                    on="hover"
                    contentStyle={{
                      width: '65px',
                      borderRadius: '5px',
                      textAlign: 'center'
                    }}
                  >
                    Select
                  </Popup>
                  <Popup
                    trigger = {
                      <Button
                        className="mr-2"
                        variant={
                          this.state.selectedTool === 'draw' ?
                            'primary':
                            'light'
                        }
                        onClick={() => this.onToolSelect('draw')}
                        style={{ padding: '1px 4px' }}
                      >
                        <FontAwesomeIcon icon={faPen}/>
                      </Button>
                    }
                    position="bottom center"
                    on="hover"
                    contentStyle={{
                      width: '55px',
                      borderRadius: '5px',
                      textAlign: 'center'
                    }}
                  >
                    Draw
                  </Popup>
                  <Popup
                    trigger = {
                      <Button
                        className="mr-2"
                        variant={
                          this.state.selectedTool === 'text' ?
                            'primary':
                            'light'
                        }
                        onClick={() => this.onToolSelect('text')}
                        style={{ padding: '1px 5px' }}
                      >
                        <FontAwesomeIcon icon={faFont}/>
                      </Button>
                    }
                    position="bottom center"
                    on="hover"
                    contentStyle={{
                      width: '50px',
                      borderRadius: '5px',
                      textAlign: 'center'
                    }}
                  >
                    Text
                  </Popup>
                  <Popup
                    trigger = {
                      <Button
                        className="mr-2"
                        variant={
                          this.state.selectedTool === 'line' ?
                            'primary':
                            'light'
                        }
                        onClick={() => this.onToolSelect('line')}
                        style={{ padding: '1px 2px' }}
                      >
                        <FontAwesomeIcon icon={faSlash}/>
                      </Button>
                    }
                    position="bottom center"
                    on="hover"
                    contentStyle={{
                      width: '55px',
                      borderRadius: '5px',
                      textAlign: 'center'
                    }}
                  >
                    Line
                  </Popup>
                  <Popup
                    trigger = {
                      <Button
                        className="mr-2"
                        variant={
                          this.state.selectedTool === 'rectangle' ?
                            'primary':
                            'light'
                        }
                        onClick={() => this.onToolSelect('rectangle')}
                        style={{ padding: '1px 4px' }}
                      >
                        <FontAwesomeIcon icon={faSquareFull}/>
                      </Button>
                    }
                    position="bottom center"
                    on="hover"
                    contentStyle={{
                      width: '90px',
                      borderRadius: '5px',
                      textAlign: 'center'
                    }}
                  >
                    Rectangle
                  </Popup>
                  <Popup
                    nested={true}
                    trigger = {
                      <div className="d-inline-block mr-4">
                        <Popup
                          trigger = {
                            <div
                              className="d-inline-block align-middle"
                              style={{
                                width: '20px',
                                height: '20px',
                                backgroundColor: this.state.selectedColor,
                                borderRadius: '50%',
                                cursor: 'pointer'
                              }}
                            />
                          }
                          position="bottom center"
                          on="hover"
                          contentStyle={{
                            width: '55px',
                            borderRadius: '5px',
                            textAlign: 'center'
                          }}
                        >
                          Color
                        </Popup>
                      </div>
                    }
                    position="bottom center"
                    on="click"
                    contentStyle={{
                      width: '232px',
                      borderRadius: '5px'
                    }}
                  >
                    <SketchPicker
                      color={this.state.selectedColor}
                      onChangeComplete={this.onSelectedColorChange}
                    />
                  </Popup>
                  <Popup
                    trigger = {
                      <Button
                        className="mr-2"
                        variant='light'
                        onClick={this.removeCanvasActiveObjects}
                        style={{ padding: '1px 4px' }}
                      >
                        <FontAwesomeIcon icon={faEraser}/>
                      </Button>
                    }
                    position="bottom center"
                    on="hover"
                    contentStyle={{
                      width: '75px',
                      borderRadius: '5px',
                      textAlign: 'center'
                    }}
                  >
                    Remove
                  </Popup>
                  <Popup
                    trigger = {
                      <Button
                        className="float-right"
                        variant='light'
                        onClick={this.revertToOriginalImage}
                        style={{ padding: '1px 4px' }}
                      >
                        <FontAwesomeIcon
                          icon={faRotateLeft}
                          color={
                            this.props.widget.image_url === this.props.widget.original_image_url ?
                              '#cccccc':
                              '#ffc107'
                          }
                        />
                      </Button>
                    }
                    position="bottom right"
                    on="hover"
                    contentStyle={{
                      borderRadius: '5px',
                      textAlign: 'center'
                    }}
                  >
                    Revert to original image
                  </Popup>
                </div>
                <div
                  id="canvas-wrapper"
                  tabIndex="1"
                  style={{ outline: 'none' }}
                >
                  <canvas
                    ref={(ref) => this.canvasRef = ref}
                  />
                </div>
                <div className="mt-2">
                  <div className="font-weight-bold">
                    Notes
                  </div>
                  <div className="mt-2">
                    <Form.Control
                      as="textarea"
                      rows="3"
                      value={this.state.notes}
                      onChange={this.onNotesChange}
                    />
                  </div>
                </div>
                <div className="my-2">
                  <div className="d-inline-block mr-2 font-weight-bold">
                    Voice
                  </div>
                  <div className="d-inline-block">
                    <Form.Control
                      as="select"
                      value={this.state.voiceId}
                      onChange={this.onVoiceIdChange}
                      style={{ maxWidth: '350px' }}
                      size="sm"
                    >
                      <option value="none" data-voice-source="none">
                        None
                      </option>
                      { this.props.voiceOptions.map((voice, i) => {
                          return (
                            <option
                              key={`voice-o-${i}`}
                              value={voice['Id']}
                              data-voice-source={voice.voice_source}
                            >
                              {`${voice['Name']} (${voice['SourceLabel']} - ${voice['LanguageName']})`}
                            </option>
                          )
                        })
                      }
                    </Form.Control>
                  </div>
                  <div className="d-inline-block ml-2 align-top">
                    <Button
                      variant="success"
                      onClick={
                        this.state.notesToSpeechPlay ?
                          this.stopNotesToSpeech :
                          this.notesToSpeech
                      }
                      disabled={
                        !this.state.notes ||
                        this.state.notesToSpeechLoading ||
                        this.state.voiceId === 'none'
                      }
                      size="sm"
                    >
                      { (!this.state.notesToSpeechPlay && !this.state.notesToSpeechLoading) &&
                        <FontAwesomeIcon icon={faPlay}/>
                      }
                      { this.state.notesToSpeechLoading &&
                        <ClipLoader
                          size={15}
                          color="ffffff"
                        />
                      }
                      { this.state.notesToSpeechPlay &&
                        <FontAwesomeIcon icon={faStop}/>
                      }
                    </Button>
                    <Button
                      className="ml-4"
                      variant="secondary"
                      onClick={() => this.props.updateWidgetsVoice(this.state.voiceId, this.state.voiceSource, false)}
                      disabled={
                        this.state.voiceId === this.props.widget.voice_id ||
                        (this.state.voiceId === 'none' && this.props.widget.voice_id === null) ||
                        this.props.updateWidgetsVoiceLoading === true
                      }
                      size="sm"
                    >
                      { this.props.updateWidgetsVoiceLoading === true &&
                        <div className="d-inline-block mr-2">
                          <ClipLoader
                            size={15}
                            color="ffffff"
                          />
                        </div>
                      }
                      Update All Report Voices
                    </Button>
                  </div>
                  <audio
                    ref={(ref) => this.notesToSpeechAudio = ref}
                    controls={false}
                    onEnded={() => this.setState(() => ({ notesToSpeechPlay: false }))}
                    crossOrigin="anonymous"
                  >
                    <source
                      src={this.state.notesToSpeechData}
                      type="audio/mpeg"
                    />
                  </audio>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <Button
                className="mr-4"
                variant="primary"
                onClick={this.saveWidget}
                size="sm"
              >
                Save
              </Button>
              <Button
                className="mr-4"
                variant="danger"
                onClick={this.openDeleteWidgetConfirmationModal}
                size="sm"
              >
                Delete
              </Button>
              <div className="d-inline-block">
                <Alert
                  className="mt-1 mb-0 px-2 py-1"
                  show={Boolean(this.state.errorMessage)}
                  variant="danger"
                >
                  {this.state.errorMessage}
                </Alert>
              </div>
            </div>
          </div>
        }

        <DeleteConfirmationModal
          isOpen={this.state.deleteWidgetConfirmationModalOpen}
          handleClose={this.closeDeleteWidgetConfirmationModal}
          resourceName="this slide"
          deleteResource={this.deleteWidget}
        />
      </div>
    );
  }
};
