import React from 'react';
import { numberWithCommas } from '../../utils/numbers';
import {
  PieChart,
  Pie,
  Cell,
  Label,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

const EmailStatDonutChart = (props) => (
  <div>
    <ResponsiveContainer height={170} width="99%">
      <PieChart>
        <Pie
          data={props.emailStatData}
          nameKey="label"
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius="60%"
          outerRadius="90%"
        >
          { props.emailStatData.map((entry, index) => {
              if (index === 0) {
                return <Cell key={`cell${index}`} fill={props.color} />;
              } else {
                return <Cell key={`cell${index}`} fill={props.offColor} />;
              }
            })
          }
          { (props.centerLabel !== undefined) &&
            <Label
              value={props.centerLabel}
              position="center"
            />
          }
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  </div>
);

export default EmailStatDonutChart;
