import React from 'react';
import axios from 'axios';
import queryString from 'query-string';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck, faEllipsisH, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import NewSearchQueryModal from './NewSearchQueryModal';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import history from '../../routers/history';
import { dispatchReportError } from '../../actions/api/errors';

export default class SearchEngineTermsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCategoryId: undefined,
      selectedCategory: undefined,
      keywords: [],
      newSearchQueryModalOpen: false,
      upperLimit: 15
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchSerpKeywordsLicenseUsageData();
    if (this.props.categories.length > 0) {
      this.onCategoryChange({
        currentTarget: {
          value: `${this.props.categories[0].category_type}-${this.props.categories[0].id}`
        }
      });
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevProps.categories !== this.props.categories
    ) {
      this.fetchSerpKeywordsLicenseUsageData();
      if (this.props.categories.length > 0) {
        this.setState(() => ({
          keywords: [],
        }));
        this.onCategoryChange({
          currentTarget: {
            value: `${this.props.categories[0].category_type}-${this.props.categories[0].id}`
          }
        });
      } else {
        this.setState(() => ({
          selectedCategoryId: undefined,
          selectedCategory: undefined,
          keywords: [],
        }));
      }
    }
  };

  fetchSerpKeywordsLicenseUsageData = () => {
    if (this.props.user) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/customers/${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        const customer = response.data;
        let licenseBase = 15;
        if (this.state.isMounted) {
          this.setState(() => ({
            upperLimit: licenseBase + customer.expansion_serp_keyword_count,
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch customer for license usage...');
        if (this.state.isMounted) {
          this.setState(() => ({
            upperLimit: 15
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onCategoryChange = (event) => {
    let selectedCategoryId;
    let selectedCategoryType;
    // if query strings for category and/or brand, override
    const queryValues = queryString.parse(this.props.location.search);
    if (queryValues.category) {
      for (const category of this.props.categories) {
        if (queryValues.category === category.name) {
          selectedCategoryId = `${category.category_type}-${category.id}`;
          selectedCategoryType = category.category_type;
        }
      }
    } else {
      selectedCategoryId = event.currentTarget.value;
      selectedCategoryType = selectedCategoryId.split('-')[0];
    }

    // remove query strings as they have been processed for selection
    history.push('/content-collectors/search-engine-terms');

    this.setState(() => ({
      selectedCategoryId,
      selectedCategoryType,
    }));
    this.fetchKeywords(selectedCategoryId);
  };

  fetchKeywords = (selectedCategoryId) => {
    if (selectedCategoryId) {
      const idSplit = selectedCategoryId.split('-');
      const categoryType = idSplit[0];
      const categoryId = idSplit[1];
      if (categoryType && categoryId) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/search_query/customer?linked_category_id=${categoryId}&linked_category_type=${categoryType}`,
          HEADERS
        ).then(response => {
          const keywords = response.data;
          if (this.state.isMounted)
            this.setState(() => ({ keywords }));
        }).catch(error => {
          console.error('Error: unable to fetch keywords');
          if (this.state.isMounted) {
            this.setState(() => ({ keywords: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  openNewSearchQueryModal = () => {
    this.setState(() => ({ newSearchQueryModalOpen: true }));
  };

  closeNewSearchQueryModal = () => {
    this.setState(() => ({ newSearchQueryModalOpen: false }));
  };

  deleteSearchEngineTerm = (id) => {
    const selectedCategoryId = this.state.selectedCategoryId;
    const idSplit = selectedCategoryId.split('-');
    const categoryType = idSplit[0];
    const categoryId = idSplit[1];
    axios.delete(
      `${LISTEN_ENDPOINT}/api/search_query/query_id/${id}/linked_category_id/${categoryId}/linked_category_type/${categoryType}`,
      HEADERS
    ).then(response => {
      this.fetchKeywords(selectedCategoryId);
    }).catch(error => {
      console.log('Error: unable to remove search engine term');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  }

  render () {
    return (
      <div className="pt-4">
        { this.props.user && this.props.user.customerId &&
          <div>
            <div className="mb-2">
              <Form.Control
                as="select"
                value={this.state.selectedCategoryId}
                onChange={this.onCategoryChange}
                style={{ maxWidth: '300px' }}
              >
                { this.props.categories.map(category => {
                    return (
                      <option
                        key={`${category.category_type}-${category.id}`}
                        value={`${category.category_type}-${category.id}`}
                      >
                        {category.name}
                      </option>
                    )
                  })
                }
              </Form.Control>
            </div>
            <Row>
              <Col xs={12} md={5}>
                <div>
                  <div className="pb-2 pt-4" style={{ fontSize: "14pt" }}>
                    <b className="mt-2">Keywords</b>
                    <Button
                      className="mb-2 ml-2 float-right"
                      variant="success"
                      size="sm"
                      onClick={this.openNewSearchQueryModal}
                      disabled={this.state.keywords.length >= this.state.upperLimit}
                    >
                      Add Term
                    </Button>
                  </div>
                  <div
                    className="pre-scrollable border rounded bg-white p-2"
                    style={{
                      minHeight: '136px',
                      maxHeight: '136px',
                      width: "100%",
                      overflowX: "hidden"
                    }}
                  >
                    { this.state.keywords.map(search => {
                        return (
                          <div key={`s${search.id}`}>
                            <Row>
                              <Col>
                                <div
                                  style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                  }}
                                >
                                  <div className="d-inline-block">
                                    {search.query_term}
                                  </div>
                                  <div
                                    className="d-inline-block ml-2 text-muted"
                                    style={{ fontSize: '.75rem' }}
                                  >
                                    ({search.search_location})
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    className="float-right pr-1"
                                    as="div"
                                    bsPrefix="none"
                                  >
                                    <Button variant="light" size="sm">
                                      <FontAwesomeIcon icon={faEllipsisH} style={{ cursor: 'pointer' }} color="#5f666d"/>
                                    </Button>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => this.deleteSearchEngineTerm(search.id)}
                                    >
                                      <FontAwesomeIcon className="mr-2" icon={faTrash} />
                                        Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Col>
                            </Row>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </Col>
              <Col>
                <div style={{ fontSize: '11pt'}} className="mt-4 pt-4">
                  <div className="pt-3">
                    <Alert variant="info" style={{ width: '265px' }}>
                      {`Add up to ${this.state.upperLimit} keyword searches.`}
                    </Alert>
                  </div>
                  <div className="pt-2">
                    <strong>Frequency:</strong> Weekly
                  </div>
                  <div className="pt-2">
                    <strong>Retention:</strong> 2 years
                  </div>
                </div>
              </Col>
            </Row>

            <NewSearchQueryModal
              isOpen={this.state.newSearchQueryModalOpen}
              handleClose={this.closeNewSearchQueryModal}
              selectedCategoryId={this.state.selectedCategoryId}
              customerId={this.props.user.customerId}
              refreshSearches={this.fetchKeywords}
            />
          </div>
        }
      </div>
    );
  }
};
