import { connect } from 'react-redux';
import Login from '../../components/common/Login';
import { userLogin } from '../../actions/user/user';

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  handleUserLogin: (email, password) => dispatch(userLogin(email, password))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
