import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import ClipLoader from 'react-spinners/ClipLoader';
import ColorSwath from './ColorSwath';
import DateRangePicker from '../common/DateRangePicker';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class Imagery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visualsData: [],
      visualsDataLoading: false,
      startDate: moment().utc().subtract(90, 'day').startOf('day'),
      endDate: moment().utc().endOf('day'),
      sortType: 'date',
      socialChecked: true,
      videoChecked: true,
      emailChecked: true,
      podcastChecked: true,
      webChecked: true,
      colorFilter: undefined,
      selectedVisualId: undefined,
      selectedVisual: undefined,
      selectedVisualColorProperties: [],
      filteredVisualsCount: 0,
      dominantColors: [],
      dominantColorCombinations: [],
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.loadVisualsData();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
        prevProps.brandId !== this.props.brandId ||
        prevState.startDate !== this.state.startDate ||
        prevState.endDate !== this.state.endDate
    ) {
      this.loadVisualsData();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  loadVisualsData = () => {
    if (this.props.brand) {
      this.setState(() => ({
        visualsDataLoading: true,
        selectedVisualId: undefined,
        selectedVisual: undefined,
      }));
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');

      axios.get(
        `${LISTEN_ENDPOINT}/api/media-visual-properties?product_brand_id=${this.props.brand.id}&company_id=${this.props.brand.company_id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        const visualsData = response.data;

        if (this.state.sortType === 'date') {
          // sort visuals by date
          visualsData.sort((a, b) => moment(b.date) - moment(a.date));
        } else if (this.state.sortType === 'reactions') {
          // sort by reactions
          visualsData.sort((a, b) => b.reactions - a.reactions);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            visualsData,
            visualsDataLoading: false
          }), () => this.updateVisualsSummary());
        }
      }).catch(error => {
        console.error('Error: unable to load visuals data...');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            visualsData: [],
            visualsDataLoading: false
          }));
        }
      });
    }
  };

  onSortTypeChange = (event) => {
    const sortType = event.currentTarget.dataset.sortType;
    if (this.state.sortType !== sortType) {
      let sortedVisuals = Array.from(this.state.visualsData);
      if (sortType === 'date') {
        // sort visuals by date
        sortedVisuals.sort((a, b) => moment(b.date) - moment(a.date));
      } else if (sortType === 'reactions') {
        // sort by reactions
        sortedVisuals.sort((a, b) => b.reactions - a.reactions);
      }
      this.setState(() => ({
        sortType,
        visualsData: sortedVisuals
      }));
    }
  };

  onFilterDatesChange = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  };

  onCheckboxChange = (event, checkbox) => {
    const checked = event.currentTarget.checked;
    if (checked !== this.state[checkbox]) {
      this.setState(() => ({
        [checkbox]: checked
      }), () => this.updateVisualsSummary());
    }
  };

  updateVisualsSummary = () => {
    let filteredVisualsCount = 0;
    let dominantColorsMap = {};
    let dominantColorCombinationsMap = {};
    let selectedFirstVisual = false;
    for (const visual of this.state.visualsData) {
      if (
        visual.type === 'tweet' && this.state.socialChecked ||
        visual.type === 'linkedin' && this.state.socialChecked ||
        visual.type === 'video' && this.state.videoChecked ||
        visual.type === 'email' && this.state.emailChecked ||
        visual.type === 'podcast' && this.state.podcastChecked ||
        visual.type === 'web' && this.state.webChecked
      ) {
        // update visuals count
        filteredVisualsCount += 1;
        // update dominant colors map
        if (visual.visual_properties && visual.visual_properties.length > 0) {
          if (dominantColorsMap[visual.visual_properties[0].color_hex]) {
            dominantColorsMap[visual.visual_properties[0].color_hex].count += 1;
          } else {
            dominantColorsMap[visual.visual_properties[0].color_hex] = {
              count: 1,
              key: visual.visual_properties[0].color_hex,
              ...visual.visual_properties[0]
            };
          }
        }
        // update dominant color combinations map
        if (visual.visual_properties && visual.visual_properties.length > 1) {
          const key = `${visual.visual_properties[0].color_hex}-${visual.visual_properties[1].color_hex}`;
          if (dominantColorCombinationsMap[key]) {
            dominantColorCombinationsMap[key].count += 1;
          } else {
            dominantColorCombinationsMap[key] = {
              count: 1,
              key: `${visual.visual_properties[0].color_hex}-${visual.visual_properties[1].color_hex}`,
              combo_one: visual.visual_properties[0],
              combo_two: visual.visual_properties[1]
            };
          }
        }
        // select first visual if appropriate
        if (!selectedFirstVisual) {
          selectedFirstVisual = true;
          this.selectVisual(visual);
        }
      }
    }

    // update dominant colors map into array and sort by count
    let dominantColors = Object.values(dominantColorsMap).sort((a, b) => b.count - a.count);
    // only display top 30 dominant colors
    dominantColors = dominantColors.slice(0, 30);
    // update dominant colors by combination into array and sort by count
    let dominantColorCombinations = Object.values(dominantColorCombinationsMap).sort((a, b) => b.count - a.count);
    // only display top 30 dominant color combinations
    dominantColorCombinations = dominantColorCombinations.slice(0, 30);

    if (this.state.isMounted) {
      this.setState(() => ({
        filteredVisualsCount,
        dominantColors,
        dominantColorCombinations,
      }));
    }
  };

  selectVisual = (selectedVisual) => {
    if (this.state.selectedVisual !== selectedVisual) {
      this.setState(
        () => ({
          selectedVisualId: selectedVisual.id,
          selectedVisual,
          selectedVisualColorProperties: selectedVisual.visual_properties || []
        })
      );
    }
  };

  onColorFilterClick = (colorFilter) => {
    if (this.state.colorFilter && this.state.colorFilter.key === colorFilter.key) {
      this.setState(() => ({ colorFilter: undefined }));
    } else {
      this.setState(() => ({ colorFilter }));
    }
  };

  render () {
    return (
      <div>
        <div>
          <ButtonGroup className="rounded border mr-4">
            <Button
              variant={`${this.state.sortType === 'date' ? 'info': 'light'}`}
              size="sm"
              data-sort-type="date"
              onClick={this.onSortTypeChange}
            >Date</Button>
            <Button
              variant={`${this.state.sortType === 'reactions' ? 'info': 'light'}`}
              size="sm"
              data-sort-type="reactions"
              onClick={this.onSortTypeChange}
            >Reactions</Button>
          </ButtonGroup>
          <span className="mr-2">Date Range</span>
          <DateRangePicker
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            updateDates={this.onFilterDatesChange}
          />
          <div className="d-inline-block">
            <Form.Check
              className="ml-4"
              type="checkbox"
              label="Social"
              checked={this.state.socialChecked}
              onChange={(event) => this.onCheckboxChange(event, 'socialChecked')}
              inline
            />
            <Form.Check
              className="ml-2"
              type="checkbox"
              label="Video"
              checked={this.state.videoChecked}
              onChange={(event) => this.onCheckboxChange(event, 'videoChecked')}
              inline
            />
            <Form.Check
              className="ml-2"
              type="checkbox"
              label="Email"
              checked={this.state.emailChecked}
              onChange={(event) => this.onCheckboxChange(event, 'emailChecked')}
              inline
            />
            <Form.Check
              className="ml-2"
              type="checkbox"
              label="Podcast"
              checked={this.state.podcastChecked}
              onChange={(event) => this.onCheckboxChange(event, 'podcastChecked')}
              inline
            />
            <Form.Check
              className="ml-2"
              type="checkbox"
              label="Web"
              checked={this.state.webChecked}
              onChange={(event) => this.onCheckboxChange(event, 'webChecked')}
              inline
            />
          </div>
        </div>
        <div className="my-2 band-separator" />
        { this.state.visualsDataLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.visualsDataLoading &&
          <div>
            { this.state.visualsData.length === 0 &&
              <div>
                No visuals are available.
              </div>
            }
            { this.state.visualsData.length > 0 &&
              <div>
                <div>
                  <div className="mb-4" style={{ fontWeight: 'bold' }}>
                    <div className="d-inline-block mr-4">
                      {`Digital Assets: ${this.state.filteredVisualsCount}`}
                    </div>
                    <div className="d-inline-block">
                      {`Dominant Colors Used: ${this.state.dominantColors.length}`}
                    </div>
                  </div>
                  <Row>
                    <Col>
                      <div style={{ fontWeight: 'bold' }}>
                        Dominant Colors
                      </div>
                      <div
                        className="pre-scrollable bg-white border"
                        style={{
                          minHeight: '200px',
                          maxHeight: '200px',
                          overflow: 'auto'
                        }}
                      >
                        <table className="w-100">
                          <tbody>
                            { this.state.dominantColors.map((dominantColor, i) => {
                                let className = '';
                                if (this.state.colorFilter && this.state.colorFilter.key === dominantColor.key) {
                                  className = 'bg-primary text-light rounded';
                                }

                                return (
                                  <tr
                                    key={`dc-${i}`}
                                    className={className}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.onColorFilterClick(dominantColor)}
                                  >
                                    <td>
                                      <div className="pl-2">
                                        {numberWithCommas(dominantColor.count)}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="mx-2 mb-1 border"
                                        style={{
                                          width: '25px',
                                          height: '24px',
                                          backgroundColor: dominantColor.color_hex
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <div className="pr-2">
                                        {`${dominantColor.color_hex}, RGB(${dominantColor.color_red}, ${dominantColor.color_green}, ${dominantColor.color_blue})`}
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </Col>
                    <Col>
                      <div style={{ fontWeight: 'bold' }}>
                        Dominant Color Combinations
                      </div>
                      <div
                        className="pre-scrollable bg-white border"
                        style={{
                          minHeight: '200px',
                          maxHeight: '200px',
                          overflow: 'auto',
                          overflowX: 'hidden'
                        }}
                      >
                        <table className="w-100">
                          <tbody>
                            { this.state.dominantColorCombinations.map((dcCombo, i) => {
                                let className = 'mb-2 border-bottom';
                                if (this.state.colorFilter && this.state.colorFilter.key === dcCombo.key) {
                                  className += ' bg-primary text-light rounded';
                                }

                                return (
                                  <tr
                                    key={`dcc-${i}`}
                                    className={className}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.onColorFilterClick(dcCombo)}
                                  >
                                    <td className="align-middle">
                                      <div className="pl-2">
                                        {numberWithCommas(dcCombo.count)}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="m-2 border"
                                        style={{
                                          width: '25px',
                                          height: '24px',
                                          backgroundColor: dcCombo.combo_one.color_hex
                                        }}
                                      />
                                      <div
                                        className="m-2 border"
                                        style={{
                                          width: '25px',
                                          height: '24px',
                                          backgroundColor: dcCombo.combo_two.color_hex
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <div className="pr-2">
                                        <div>
                                          {`${dcCombo.combo_one.color_hex}, RGB(${dcCombo.combo_one.color_red}, ${dcCombo.combo_one.color_green}, ${dcCombo.combo_one.color_blue})`}
                                        </div>
                                        <div>
                                          {`${dcCombo.combo_two.color_hex}, RGB(${dcCombo.combo_two.color_red}, ${dcCombo.combo_two.color_green}, ${dcCombo.combo_two.color_blue})`}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>
                </div>
                <h5 className="my-2 p-2 bg-bops-blue text-light">
                  Details
                </h5>
                <Row noGutters>
                  <Col xs={4}>
                    <div
                      className="mt-2 pre-scrollable"
                      style={{
                        minHeight: 'calc(100vh - 225px)',
                        maxHeight: 'calc(100vh - 225px)',
                        overflow: 'auto',
                        overflowX: 'hidden'
                      }}
                    >
                      <div>
                        { this.state.visualsData.map((visual, i) => {
                            // color filter
                            if (this.state.colorFilter) {
                              if (visual.visual_properties && visual.visual_properties.length > 0) {
                                if (this.state.colorFilter.key.includes('-')) {
                                  if (visual.visual_properties.length > 1) {
                                    if (this.state.colorFilter.key !== `${visual.visual_properties[0].color_hex}-${visual.visual_properties[1].color_hex}`) {
                                      return;
                                    }
                                  } else {
                                    return;
                                  }
                                } else {
                                  if (this.state.colorFilter.key !== visual.visual_properties[0].color_hex) {
                                    return;
                                  }
                                }
                              } else {
                                return;
                              }
                            }

                            if (visual.type === 'tweet' && !this.state.socialChecked) {
                              return;
                            } else if (visual.type === 'linkedin' && !this.state.socialChecked) {
                              return;
                            } else if (visual.type === 'video' && !this.state.videoChecked) {
                              return;
                            } else if (visual.type === 'email' && !this.state.emailChecked) {
                              return;
                            } else if (visual.type === 'podcast' && !this.state.podcastChecked) {
                              return;
                            } else if (visual.type === 'web' && !this.state.webChecked) {
                               return;
                            } else {
                              let className = "p-2 text-center"
                              if (this.state.selectedVisualId === visual.id) {
                                className = `${className} border border-primary rounded`;
                              }
                              return (
                                <div
                                  key={`${visual.id}-${i}`}
                                  className={className}
                                  onClick={() => this.selectVisual(visual)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <img
                                    src={visual.image_url}
                                    alt=""
                                    crossOrigin="anonymous"
                                    style={{ maxWidth: '100%' }}
                                  />
                                </div>
                              )
                            }
                          })
                        }
                      </div>
                    </div>
                  </Col>
                  <Col xs={8}>
                    { this.state.selectedVisual &&
                      <div className="ml-3">
                        <div>
                          <Row>
                            <Col xs={6}>
                              Source: {this.state.selectedVisual.source_display}
                            </Col>
                            <Col xs={6}>
                              Date: {moment.utc(this.state.selectedVisual.date).local().format('MM/DD/YYYY')}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              { this.state.selectedVisual.show_reactions &&
                                <div>
                                  {`${numberWithCommas(this.state.selectedVisual.reactions)} Reactions`}
                                </div>
                              }
                            </Col>
                            <Col xs={6}>
                              { this.state.selectedVisual.link_url &&
                                <div className="d-inline-block">
                                  <a href={this.state.selectedVisual.link_url} target="_blank">Link</a>
                                </div>
                              }
                            </Col>
                          </Row>
                        </div>
                        <hr />
                        <Row>
                          <Col xs={6}>
                            <div>
                              <ColorSwath
                                label="Dominant Colors"
                                colors={this.state.selectedVisualColorProperties}
                                loading={false}
                              />
                            </div>
                          </Col>
                          <Col xs={6}></Col>
                        </Row>
                        <hr />
                        <div className="container px-0">
                          <div
                            style={{
                              overflow: 'auto',
                              maxHeight: '400px'
                            }}
                          >
                            <img
                              src={this.state.selectedVisual.image_url}
                              alt=""
                              crossOrigin="anonymous"
                            />
                          </div>
                        </div>
                      </div>
                    }
                  </Col>
                </Row>
              </div>
            }
          </div>
        }
      </div>
    );
  }
};
