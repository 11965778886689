import moment from 'moment';

const sixMonths = [];
let date = moment().subtract(1, 'day');
let sixMonthsDate = moment().subtract(1, 'day').subtract(6 * 30, 'day');
while (date.isAfter(sixMonthsDate)) {
  sixMonths.push({
    label: date.format('MMMM'),
    date
  });
  date = moment(date).subtract(1, 'month');
}

export default function updateSixMonthsReducer(state = sixMonths, action) {
  switch (action.type) {
    case 'UPDATE_SIX_MONTHS':
      return action.sixMonths;
    default:
      return state
  }
};
