import React from 'react';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

export default class TweetResult extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    parseURLs = (text) => {
        return text.replace(/[A-Za-z]+:\/\/[A-Za-z0-9-_]+\.[A-Za-z0-9-_:%&~\?\/.=]+/g, (url) => {
            return `<a href="${url}" target="_blank">${url}</a>`;
        });
    };

    parseUsernames = (text) => {
        return text.replace(/[@]+[A-Za-z0-9-_]+/g, (handle) => {
            var username = handle.replace('@','');
            return `<a href="http://twitter.com/${username}" target="_blank">${handle}</a>`;
        });
    };

    parseHashtags = (text) => {
        return text.replace(/[#]+[A-Za-z0-9-_]+/g, function(hashtag) {
            var hashtagUrlEncoded = hashtag.replace('#','%23');
            return `<a href="http://twitter.com/search?q=${hashtagUrlEncoded}" target="_blank">${hashtag}</a>`;
        });
    };

    parseTextForHTMLReplacements = (text) => {
        text = this.parseURLs(text);
        text = this.parseUsernames(text);
        text = this.parseHashtags(text);
        return text;
    };

    render () {
        return (
            <div
                className="mb-2 bg-white border rounded"
                style={{
                    fontSize: '.875rem'
                }}
            >
                <Row noGutters className="p-2">
                    <Col md={9} sm={12} className="px-4 py-2">
                        <div>
                        { this.props.comment.tweet_in_reply_to_tweet_id &&
                            <FontAwesomeIcon className="mr-2" icon={faReply} />
                        }
                            <div
                                className="d-inline"
                                dangerouslySetInnerHTML={{__html: this.parseTextForHTMLReplacements(this.props.comment.tweet_text)}}
                            />
                        </div>
                    </Col>
                    <Col md={3} sm={12} className="px-4 py-2 border-left">
                        <Row>
                            <Col>
                                {moment.utc(this.props.comment.tweet_created_at).local().format('MM/DD/YYYY h:mm:ss A')}
                                <FontAwesomeIcon
                                    className="float-right"
                                    icon={faTwitter}
                                    color="#08a0e9"
                                    size="lg"
                                    onClick={() => {
                                        window.open(`https://twitter.com/user/status/${this.props.comment.tweet_id}`, '_blank');
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
};
