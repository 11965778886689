import React from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faChartArea, faEdit, faEllipsisH, faTrash } from '@fortawesome/free-solid-svg-icons';
import LoginContainer from '../../containers/common/LoginContainer';
import AlertModal from './AlertModal';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import { sortAlphabeticalByKey } from '../../utils/sorts';
import { capitalizeFirstLetter } from '../../utils/strings';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class Alerts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMetrics: [],
      alerts: [],
      alertsLoading: false,
      selectedAlert: undefined,
      alertModalOpen: false,
      deleteAlertConfirmationModalOpen: false,
      deleteAlertId: undefined,
      deleteAlertName: undefined,
      reports: [],
      reportsLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchBrandMetrics();
    this.fetchAlerts();
    this.fetchReports();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.customerId !== this.props.user.customerId) {
      this.fetchAlerts();
      this.fetchReports();
    }
  }

  fetchBrandMetrics = () => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/brand-metrics`,
      HEADERS
    ).then(response => {
      const brandMetrics = response.data;
      const alertMetrics = brandMetrics.concat([
        { id: null, metric_label: 'Overall Score', alert_type: 'brand_score' },
        { id: 1, metric_label: 'Digital Presence Score', alert_type: 'brand_stage_score' },
        { id: 3, metric_label: 'Audience Attention Score', alert_type: 'brand_stage_score' },
        { id: 5, metric_label: 'Brand Reputation Score', alert_type: 'brand_stage_score' },
        { id: 6, metric_label: 'Momentum Score', alert_type: 'brand_stage_score' }
      ]);
      // sort alert metrics
      alertMetrics.sort(sortAlphabeticalByKey('metric_label'));
      if (this.state.isMounted) {
        this.setState(() => ({ alertMetrics }));
      }
    }).catch(error => {
      console.error('Error: unable to fetch brand metrics');
      if (this.state.isMounted) {
        this.setState(() => ({
          alertMetrics: []
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  fetchAlerts = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({ alertsLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/brand-alert?added_by_customer_id=${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        const alerts = response.data;
        // add metric label for brand scores
        for (const a of alerts) {
          if (a.alert_on === 'brand_score') {
            a.metric_label = 'Brand Score';
          } else if (a.alert_on === 'brand_stage_score') {
            switch (a.identifier) {
              case 1:
                a.metric_label = 'Digital Presence Score';
                break;
              case 3:
                a.metric_label = 'Audience Attention Score';
                break;
              case 5:
                a.metric_label = 'Brand Reputation Score';
                break;
              case 6:
                a.metric_label = 'Momentum Score';
                break;
              default:
                a.metric_label = '';
                break;
            }
          }
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            alerts,
            alertsLoading: false,
          }));
        }
      }).catch(error => {
        if (this.state.isMounted) {
          this.setState(() => ({
            alerts: [],
            alertsLoading: false,
          }));
        }
        console.error('Error: unable to fetch alerts');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchReports = (showLoading=true) => {
    if (this.props.user.id) {
      if (showLoading) {
        this.setState(() => ({ reportsLoading: true }));
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/customer-user-reports?customer_user_id=${this.props.user.id}`,
        HEADERS
      ).then(response => {
        const reports = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            reports,
            reportsLoading: false,
          }));
        }
      }).catch(error => {
        if (this.state.isMounted) {
          this.setState(() => ({
            reports: [],
            reportsLoading: false,
          }));
        }
        console.error('Error: unable to fetch customer user reports');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  openAlertModal = (alert) => {
    this.setState(() => ({
      selectedAlert: alert,
      alertModalOpen: true
    }));
  };

  closeAlertModal = () => {
    this.setState(() => ({
      selectedAlert: undefined,
      alertModalOpen: false
    }));
  };

  openDeleteAlertConfirmationModal = (alert) => {
    this.setState(() => ({
      deleteAlertConfirmationModalOpen: true,
      deleteAlertId: alert.id,
      deleteAlertName: `Alert`
    }));
  };

  closeDeleteAlertConfirmationModal = () => {
    this.setState(() => ({
      deleteAlertConfirmationModalOpen: false,
      deleteAlertId: undefined,
      deleteAlertName: undefined
    }));
  };

  deleteAlert = () => {
    axios.delete(
      `${LISTEN_ENDPOINT}/api/brand-alert/${this.state.deleteAlertId}`,
      HEADERS
    ).then(response => {
      if (this.state.isMounted) {
        this.closeDeleteAlertConfirmationModal();
        this.fetchAlerts();
      }
    }).catch(error => {
      console.error('Error: unable to delete alert');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  updateReportSubscription = (customerUserReportId, subscribed) => {
    if (customerUserReportId) {
      axios.put(
        `${LISTEN_ENDPOINT}/api/customer-user-reports/${customerUserReportId}`,
        { subscribed },
        HEADERS
      ).then(response => {
        this.fetchReports(false);
      }).catch(error => {
        console.error('Error: unable to update report subscription');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="p-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        { (this.props.user && this.props.user.id) &&
          <div
            className="pre-scrollable"
            style={{
              minHeight: 'calc(100vh - 100px)',
              maxHeight: 'calc(100vh - 100px)',
              overflow: 'auto',
            }}
          >
            <div className="mb-4">
              <Card className="h-100">
                <Card.Header className="bg-white" style={{ fontSize: '1.2rem'}}>
                  <img
                    className="mt-1 mr-2 align-top"
                    src="/images/Reviews_Detail_Icon.png"
                  />
                  <b>
                    Overview
                  </b>
                </Card.Header>
                <Card.Body>
                  Set up alerts to notify you of changes to brand scores or metrics. The alerts can be sent via email or to a Slack channel.
                </Card.Body>
              </Card>
            </div>
            <div>
              <h5 className="p-2 bg-bops-blue text-light rounded">
                <div className="d-inline-block my-1">
                  Alerts
                </div>
                <Button
                  className="float-right"
                  variant="success"
                  onClick={() => this.openAlertModal()}
                  disabled={this.props.categories.length === 0}
                  size="sm"
                >
                  Add Alert
                </Button>
              </h5>
              { this.state.alertsLoading &&
                <div className="m-4 text-center">
                  <ClipLoader size={100}/>
                </div>
              }
              { !this.state.alertsLoading &&
                <div>
                  <Table>
                    <thead>
                      <tr>
                        <th className="border-top-0 border-bottom-0">
                          Brand
                        </th>
                        <th className="border-top-0 border-bottom-0">
                          Metric
                        </th>
                        <th className="border-top-0 border-bottom-0">
                          Trigger
                        </th>
                        <th className="border-top-0 border-bottom-0">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      { this.state.alerts.length === 0 &&
                        <tr>
                          <td>No alerts</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      }
                      { this.state.alerts.map((alert, i) => {
                          return (
                            <tr key={`alert${i}`}>
                              <td>
                                <FontAwesomeIcon
                                  className="mr-2"
                                  icon={faBell}
                                  color="#ffc107"
                                />
                                {alert.product_brand_name}
                              </td>
                              <td>
                                {alert.metric_label}
                              </td>
                              <td>
                                {`Change ${alert.percent_change * 100}% in ${alert.change_period_time} day(s)`}
                              </td>
                              <td>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    as="div"
                                    bsPrefix="none"
                                  >
                                    <Button variant="light" size="sm">
                                      <FontAwesomeIcon icon={faEllipsisH} style={{ cursor: 'pointer' }} color="#5f666d"/>
                                    </Button>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => this.openAlertModal(alert)}>
                                      <FontAwesomeIcon className="mr-2" icon={faEdit}/>
                                      Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.openDeleteAlertConfirmationModal(alert)}>
                                      <FontAwesomeIcon className="mr-2" icon={faTrash}/>
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </div>
              }
            </div>
            <div>
              <h5 className="my-2 p-2 bg-bops-blue text-light rounded">
                Notifications
              </h5>
              { this.state.reportsLoading &&
                <div className="m-4 text-center">
                  <ClipLoader size={100}/>
                </div>
              }
              { !this.state.reportsLoading &&
                <div>
                  <Table>
                    <thead>
                      <tr>
                        <th className="border-top-0 border-bottom-0">
                          Notification
                        </th>
                        <th className="border-top-0 border-bottom-0">
                          Frequency
                        </th>
                        <th className="border-top-0 border-bottom-0">
                          Subscribed
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      { this.state.reports.length === 0 &&
                        <tr>
                          <td>No notifications</td>
                          <td></td>
                          <td></td>
                        </tr>
                      }
                      { this.state.reports.map((report, i) => {
                          return (
                            <tr key={`alert${i}`}>
                              <td>
                                <FontAwesomeIcon
                                  className="mr-2"
                                  icon={faChartArea}
                                  color="#6c757d"
                                />
                                {report.report_name}
                              </td>
                              <td>
                                {capitalizeFirstLetter(report.report_frequency)}
                              </td>
                              <td>
                                <Form.Check
                                  className="px-4"
                                  type="checkbox"
                                  checked={report.subscribed}
                                  onChange={(event) => this.updateReportSubscription(report.customer_user_report_id, event.currentTarget.checked)}
                                  inline
                                />
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </div>
              }
            </div>
          </div>
        }

        <AlertModal
          isOpen={this.state.alertModalOpen}
          handleClose={this.closeAlertModal}
          categories={this.props.categories}
          user={this.props.user}
          customerId={this.props.user.customerId}
          alertMetrics={this.state.alertMetrics}
          alert={this.state.selectedAlert}
          refreshAlerts={this.fetchAlerts}
          slackChannels={this.props.slackChannels}
        />
        <DeleteConfirmationModal
          isOpen={this.state.deleteAlertConfirmationModalOpen}
          handleClose={this.closeDeleteAlertConfirmationModal}
          resourceName={this.state.deleteAlertName}
          deleteResource={this.deleteAlert}
        />
      </div>
    );
  }
};
