import { connect } from 'react-redux';
import MetricTooltip from '../../components/metrics/MetricTooltip';

const mapStateToProps = (state) => ({
  brandMetricsMap: state.brandMetricsMap
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(MetricTooltip);
