import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import ClipLoader from 'react-spinners/ClipLoader';
import { numberWithCommas, round } from '../../utils/numbers';
import { getDomainFromUrl } from '../../utils/urls';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class ReviewSearchResultsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reviewSearchResultApps: [],
      reviewSearchResultsMap: {},
      brands: [],
      date: undefined,
      reviewSearchResultsLoading: false,
      borderEnhancedStyle: '1px solid #343a40'
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.setBrands();
    this.fetchReviewSearchResults();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.setBrands();
      this.fetchReviewSearchResults();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  setBrands = () => {
    if (this.props.category && this.props.category.product_brands) {
      const brands = [];
      for (const b of this.props.category.product_brands) {
        brands.push(b.name);
      }
      this.setState(() => ({ brands }));
    }
  };

  fetchReviewSearchResults = () => {
    if (this.props.category) {
      this.setState(() => ({ reviewSearchResultsLoading: true }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/review-search-results?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const reviewSearchResultApps = [];
        const reviewSearchResultsMap = {};
        let date;
        for (const [appName, appResults] of Object.entries(response.data)) {
          reviewSearchResultApps.push(appName);
          // setup initial map structure for review site
          reviewSearchResultsMap[appName] = {
            best_position: 100,
            highest_stars: 0,
            highest_review_count: 0,
          };
          for (const result of appResults) {
            // set/update date
            if (!date || moment.utc(result.scrape_date).isAfter(date)) {
              date = moment.utc(result.scrape_date);
            }

            for (const brand of this.props.category.product_brands) {
              if (
                result.product_domain === getDomainFromUrl(brand.company_url) ||
                result.product_name === brand.name
              ) {
                reviewSearchResultsMap[appName][brand.name] = result;
                reviewSearchResultsMap[appName][brand.name].stars = round((result.review_score * 10) / 2, 1);
                // check for best position
                if (result.position < reviewSearchResultsMap[appName].best_position) {
                  reviewSearchResultsMap[appName].best_position = result.position;
                }
                // check for highest review stars
                if (result.stars > reviewSearchResultsMap[appName].highest_stars) {
                  reviewSearchResultsMap[appName].highest_stars = result.stars;
                }
                // check for highest review count
                if (result.review_count > reviewSearchResultsMap[appName].highest_review_count) {
                  reviewSearchResultsMap[appName].highest_review_count = result.review_count;
                }
              }
            }
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            reviewSearchResultApps,
            reviewSearchResultsMap,
            reviewSearchResultsLoading: false,
            date
          }))
        }
      }).catch(error => {
        console.error('Error: failed to fetch review search results...');
        if (this.state.isMounted) {
          this.setState(() => ({
            reviewSearchResultApps: [],
            reviewSearchResultsMap: {},
            reviewSearchResultsLoading: false,
            date: undefined
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        <h5 className="my-2 p-2 bg-bops-blue text-light">
          {`Review Site Positions for "${this.props.category.name}"`}
        </h5>
        { this.state.reviewSearchResultsLoading &&
          <div className="text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.reviewSearchResultsLoading &&
          <div
            className="pre-scrollable"
            style={{
              overflow: 'auto',
              minHeight: 'calc(100vh - 260px)',
              maxHeight: 'calc(100vh - 260px)',
            }}
          >
            <Table
              striped
              size="sm"
              style={{
                overflow: 'auto',
              }}
            >
              <thead className="bg-dark text-light text-center border-top">
                <tr
                  style={{
                    position: 'sticky',
                    top: -1,
                    zIndex: 1
                  }}
                >
                  <th
                    className="py-1 bg-dark border-bottom border-right"
                    style={{
                      borderTop: this.state.borderEnhancedStyle,
                      borderLeft: this.state.borderEnhancedStyle
                    }}
                  >
                    <div>Review Site</div>
                    { this.state.date &&
                      <div style={{
                          fontSize: '.75rem',
                          fontWeight: 'normal'
                        }}>
                        {`Week of ${this.state.date.format('MM/DD/YYYY')}`}
                      </div>
                    }
                  </th>
                  <th
                    className="py-1 bg-dark border-left border-right border-bottom"
                    style={{ borderTop: this.state.borderEnhancedStyle }}
                  ></th>
                  { this.state.brands.map((brand, i) => {
                      return (
                        <th
                          key={`th-b-${i}`}
                          className="bg-dark border-bottom border-left"
                          style={{
                            borderTop: this.state.borderEnhancedStyle,
                            borderRight: (i + 1 === this.state.brands.length) ?
                              this.state.borderEnhancedStyle :
                              '1px solid #dee2e6'
                          }}
                        >
                          {brand}
                        </th>
                      )
                    })
                  }
                </tr>
              </thead>
              <tbody>
                { this.state.reviewSearchResultApps.map((app, i) => {
                    return (
                      <React.Fragment key={`rsrp-rows-${i}`}>
                        <tr>
                          <td
                            className="bg-light text-center align-middle border-top border-right"
                            rowSpan="3"
                            style={{
                              borderLeft: this.state.borderEnhancedStyle,
                              borderBottom: this.state.borderEnhancedStyle
                            }}
                          >
                            {app}
                          </td>
                          <td className="border">
                            Position on Page
                          </td>
                          { this.state.brands.map((brand, bi) => {
                              return(
                                <td
                                  key={`rsrp-rows-${i}-b-${bi}`}
                                  className={
                                    (
                                      this.state.reviewSearchResultsMap[app][brand] &&
                                      this.state.reviewSearchResultsMap[app].best_position === this.state.reviewSearchResultsMap[app][brand].position
                                    ) ?
                                      'bg-bops-blue text-light text-center align-middle border-top border-bottom border-left':
                                      'text-center align-middle border-top border-bottom border-left'
                                  }
                                  style={{
                                    borderRight: (bi + 1 === this.state.brands.length) ?
                                      this.state.borderEnhancedStyle :
                                      '1px solid #dee2e6'
                                  }}
                                >
                                  { this.state.reviewSearchResultsMap[app][brand] &&
                                    <React.Fragment>
                                      {this.state.reviewSearchResultsMap[app][brand].position}
                                    </React.Fragment>
                                  }
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="border">
                            Stars
                          </td>
                          { this.state.brands.map((brand, bi) => {
                              return(
                                <td
                                  key={`sdd-wpwk-b-${bi}`}
                                  className={
                                    (
                                      this.state.reviewSearchResultsMap[app][brand] &&
                                      this.state.reviewSearchResultsMap[app].highest_stars === this.state.reviewSearchResultsMap[app][brand].stars
                                    ) ?
                                      'bg-bops-blue text-light text-center align-middle border-top border-bottom border-left':
                                      'text-center align-middle border-top border-bottom border-left'
                                  }
                                  style={{
                                    borderRight: (bi + 1 === this.state.brands.length) ?
                                      this.state.borderEnhancedStyle :
                                      '1px solid #dee2e6'
                                  }}
                                >
                                  { this.state.reviewSearchResultsMap[app][brand] &&
                                    <React.Fragment>
                                      {this.state.reviewSearchResultsMap[app][brand].stars}
                                    </React.Fragment>
                                  }
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td
                            className="border-top border-left border-right"
                            style={{ borderBottom: this.state.borderEnhancedStyle }}
                          >
                            # of Reviews
                          </td>
                          { this.state.brands.map((brand, bi) => {
                              return(
                                <td
                                  key={`sdd-wpwk-b-${bi}`}
                                  className={
                                    (
                                      this.state.reviewSearchResultsMap[app][brand] &&
                                      this.state.reviewSearchResultsMap[app].highest_review_count === this.state.reviewSearchResultsMap[app][brand].review_count
                                    ) ?
                                      'bg-bops-blue text-light text-center align-middle border-top border-left':
                                      'text-center align-middle border-top border-left'
                                  }
                                  style={{
                                    borderRight: (bi + 1 === this.state.brands.length) ?
                                      this.state.borderEnhancedStyle :
                                      '1px solid #dee2e6',
                                    borderBottom: this.state.borderEnhancedStyle
                                  }}
                                >
                                  {this.state.reviewSearchResultsMap[app][brand] ? numberWithCommas(this.state.reviewSearchResultsMap[app][brand].review_count) : ''}
                                </td>
                              )
                            })
                          }
                        </tr>
                      </React.Fragment>
                    )
                  })
                }
              </tbody>
            </Table>
          </div>
        }
      </div>
    );
  }
};
