import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import history from '../../routers/history';

export default class LeftMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1'
    };
  }

  componentDidMount() {
    // open category accordion if url includes
    const categoryUrlName = encodeURIComponent(this.props.category.name);
    // temp fix for categories w/ & in name
    const altCategoryUrlName = categoryUrlName.replace('%26', '&');
    if (
      this.props.companyId !== 317 || // open for all customers, but brandops
      this.props.position === 0 || // always open first category menu
      window.location.pathname.includes(`/category-dashboard/${categoryUrlName}`) ||
      window.location.pathname.includes(`/category/${categoryUrlName}`) ||
      window.location.pathname.includes(`/category-dashboard/${altCategoryUrlName}`) ||
      window.location.pathname.includes(`/category/${altCategoryUrlName}`)
    ) {
      this.setState(() => ({ activeKey: '0' }));
    }
  };

  toggleAccordion = () => {
    this.setState(prevState => {
      if (prevState.activeKey === '0') {
        return { activeKey: '1' }
      } else {
        return { activeKey: '0' }
      }
    })
  };

  openCategory = () => {
    if (this.state.activeKey === '1') {
      this.setState(() => ({ activeKey: '0' }));
    }
  };

  getBrandNameDisplay = (brand) => {
    const brandName = String(brand.name);
    const companyName = String(brand.company_name);
    if (
      brandName.toLowerCase().includes(companyName.toLowerCase()) ||
      companyName.toLowerCase().includes(brandName.toLowerCase())
    ) {
      return brandName;
    } else {
      return `${companyName}, ${brandName}`
    }
  };

  render () {
    return (
      <div className="mb-2" style={{ fontSize: '.875rem' }}>
        <Accordion activeKey={this.state.activeKey}>
          <div className="px-2 hide-overflow-text">
            <Accordion.Toggle as="span" eventKey="0" onClick={this.toggleAccordion}>
              <FontAwesomeIcon
                className="mx-2"
                icon={faCaretDown}
                style={{ cursor: 'pointer' }}
              />
            </Accordion.Toggle>
            <Navbar
              className="d-inline-block p-0"
              onClick={this.openCategory}
            >
              <Nav className="w-100">
                <div
                  id={`cat-menu-${this.props.category.category_type}-${this.props.category.id}`}
                  className={`left-menu-cat-item nav-link p-0`}
                  onClick={() => this.props.maintainHashToCategory(this.props.category.name)}
                  style={{ cursor: 'pointer' }}
                >
                  {this.props.category.name}
                </div>
              </Nav>
            </Navbar>
          </div>
          <Accordion.Collapse eventKey="0">
            <div>
              <Nav>
                <div
                  className={`nav-link pl-4 p-0 w-100${this.props.isCategoryActive(this.props.category)?' active bg-bops-blue':''}`}
                  onClick={() => this.props.maintainHashToCategory(this.props.category.name)}
                  style={{ cursor: 'pointer' }}
                >
                  <div className={`pl-2 compare-brands ellipsis${!this.props.isCategoryActive(this.props.category)?' text-bops-blue':''}`}>
                    Compare Brands
                  </div>
                </div>
              </Nav>
              { this.props.category.product_brands.map(brand => {
                let className = `nav-link pl-4 p-0 w-100 left-menu-brand left-menu-brand-${brand.id}`;
                if (brand.company_id === this.props.companyId) {
                  className += ' nav-link-highlight';
                }
                if (this.props.isBrandActive(this.props.category, brand)) {
                  className += ' active bg-bops-blue';
                }
                return (
                  <Nav key={`lmc${this.props.category.id}-${this.props.category.category_type}-lmb${brand.id}`}>
                    <div
                      className={className}
                      onClick={() => this.props.maintainHashToBrand(this.props.category.name, brand.name)}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className="pl-2 ellipsis">
                        {this.getBrandNameDisplay(brand)}
                      </div>
                    </div>
                  </Nav>
                )
                })
              }
            </div>
          </Accordion.Collapse>
        </Accordion>
      </div>
    );
  }
};
