import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';
import ClipLoader from 'react-spinners/ClipLoader';
import DateRangePicker from '../common/DateRangePicker';
import GeneralSummaryGraph from '../graphs/GeneralSummaryGraph';
import YoutubeVideos from './YoutubeVideos';
import BrandMetricsByMonthTableContainer from '../../containers/metrics/BrandMetricsByMonthTableContainer';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CompanyYoutubeChannel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      channelsDrawerOpen: false,
      companyYoutubeChannels: [],
      companyYoutubeChannelsLoading: false,
      channelCaptures: [],
      channelCapturesLoading: false,
      netNewViewsData: [],
      netNewViewsDataLoading: false,
      channelNetSubscribersGainScoreType: undefined,
      netNewViewsCountScoreType: undefined,
      categoryCaptureAverageData: [],
      categoryNetNewAverageData: [],
      startDate: moment().utc().subtract(3, 'year').startOf('day'),
      endDate: moment().utc().endOf('day'),
      channelVideos: [],
      channelVideosLoading: false,
      videoResults: [],
      cppCounts: {},
      sortType: 'date'
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchCompanyYoutubeChannels();
    this.fetchVideoNetNewViews();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.companyId !== this.props.companyId ||
      prevProps.brand !== this.props.brand ||
      prevProps.allMonthsChecked !== this.props.allMonthsChecked
    ) {
      this.fetchCompanyYoutubeChannels();
      this.fetchVideoNetNewViews();
    } else if (
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      this.fetchChannelVideos(this.state.companyYoutubeChannels.youtube_channel_id);
    }
    if (
      prevProps.companyChecked !== this.props.companyChecked ||
      prevProps.portfolioChecked !== this.props.portfolioChecked ||
      prevProps.productChecked !== this.props.productChecked
    ) {
      this.updateVideoResults();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  fetchCompanyYoutubeChannels = () => {
    if (this.props.companyId) {
      this.setState(() => ({
        companyYoutubeChannelsLoading: true,
        channelCapturesLoading: true
      }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/company/${this.props.companyId}/youtube-channel-data`,
        HEADERS
      ).then(response => {
        const companyYoutubeChannels = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            companyYoutubeChannels,
            companyYoutubeChannelsLoading: false
          }));
          this.fetchChannelCapturesData();
          this.fetchChannelVideos();
        }
      }).catch(error => {
        console.error('Error: unable to load company youtube channel data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            companyYoutubeChannels: {},
            companyYoutubeChannelsLoading: false
          }))
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchChannelCapturesData = () => {
    if (this.props.brand) {
      this.setState(() => ({ channelCapturesLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/company-youtube-channels/captures-trend?product_brand_id=${this.props.brand.id}${allMonthsStartDateParam ? `&start-date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let channelCaptures = response.data.monthly_stats;
        const channelNetSubscribersGainScoreType = response.data.net_subscribers_gain ? response.data.net_subscribers_gain.score_type : undefined;
        // merge category average data into original data source
        channelCaptures = channelCaptures.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryCaptureAverageData[i]))
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            channelCaptures,
            channelNetSubscribersGainScoreType,
            channelCapturesLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to load youtube channel captures data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            channelCaptures: [],
            channelNetSubscribersGainScoreType: undefined,
            channelCapturesLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=youtube_avg_capture_trend${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryCaptureAverageData = response.data.category_stats;
          // merge category average data into original data sources
          const channelCaptures = this.state.channelCaptures.map((x, i) => {
            return (Object.assign({}, x, categoryCaptureAverageData[i]))
          });

          if (this.state.isMounted) {
            this.setState(() => ({
              categoryCaptureAverageData,
              channelCaptures,
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average capture youtube data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryCaptureAverageData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  fetchVideoNetNewViews = () => {
    if (this.props.brand) {
      this.setState(() => ({ netNewViewsDataLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/company-youtube-channels/net-view-trend?product_brand_id=${this.props.brand.id}${allMonthsStartDateParam ? `&start-date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let netNewViewsData = response.data.monthly_stats;
        const netNewViewsCountScoreType = response.data.net_views_count ? response.data.net_views_count.score_type : undefined;
        // merge category average data into original data source
        netNewViewsData = netNewViewsData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryNetNewAverageData[i]))
        });
        if (this.state.isMounted) {
          this.setState(() => ({
            netNewViewsData,
            netNewViewsCountScoreType,
            netNewViewsDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to load net new views data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            netNewViewsData: [],
            netNewViewsCountScoreType: undefined,
            netNewViewsDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=youtube_avg_net_views_trend${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryNetNewAverageData = response.data.category_stats;
          // merge category average data into original data sources
          const netNewViewsData = this.state.netNewViewsData.map((x, i) => {
            return (Object.assign({}, x, categoryNetNewAverageData[i]))
          });
          if (this.state.isMounted) {
            this.setState(() => ({
              categoryNetNewAverageData,
              netNewViewsData,
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average net new youtube data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryNetNewAverageData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  fetchChannelVideos = () => {
    if (this.props.companyId) {
      this.setState(() => ({ channelVideosLoading: true }));
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/company-youtube-channels/${this.props.companyId}/videos?product_brand_id=${this.props.brand.id}&start-date=${formattedStartDate}&end-date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        let channelVideos = response.data;
        const cppCounts = {
          companyCount: 0,
          portfolioCount: 0,
          productCount: 0,
        };
        for (const v of channelVideos) {
          if (Array.isArray(v.brand_types)) {
            if (v.brand_types.includes('company')) {
              cppCounts.companyCount++;
            }
            if (v.brand_types.includes('portfolio')) {
              cppCounts.portfolioCount++;
            }
            if (v.brand_types.includes('product')) {
              cppCounts.productCount++;
            }
          } else {
            v.brand_types = [];
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            channelVideos,
            channelVideosLoading: false,
            cppCounts,
          }), () => this.updateVideoResults());
        }
      }).catch(error => {
        console.error('Error: unable to load youtube channel videos...');
        if (this.state.isMounted) {
          this.setState(() => ({
            channelVideos: [],
            channelVideosLoading: false,
            cppCounts: {},
          }), () => this.updateVideoResults());
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onFilterDatesChange = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  };

  updateVideoResults = () => {
    let videoResults = [];
    for (const v of this.state.channelVideos) {
      if (
        Array.isArray(v.brand_types) &&
        (
          (this.props.companyChecked && v.brand_types.includes('company')) ||
          (this.props.productChecked && v.brand_types.includes('product')) ||
          (this.props.portfolioChecked && v.brand_types.includes('portfolio'))
        )
      ) {
        videoResults.push(v);
      }
    }

    if (this.state.isMounted) {
      this.setState(
        () => ({ videoResults }),
        () => this.changeSortType(this.state.sortType)
      );
    }
  };

  toggleChannelsDrawer = () => {
    this.setState((prevState) => ({
      channelsDrawerOpen: !prevState.channelsDrawerOpen
    }));
  };

  changeSortType = (sortType) => {
    if (sortType === 'date') {
      const dateSortedVideos = this.state.videoResults.sort((a, b) => {
        return moment(b.published_at).format('YYYYMMDDHHmmss') - moment(a.published_at).format('YYYYMMDDHHmmss')
      });
      this.setState(() => ({
        sortType: 'date',
        videoResults: dateSortedVideos
      }));
    } else if (sortType === 'views') {
      const viewsSortedVideos = this.state.videoResults.sort((a, b) => {
        if (a.views_count < b.views_count) {
          return 1;
        } else if (a.views_count > b.views_count) {
          return -1;
        } else {
          return 0;
        }
      });
      this.setState(() => ({
        sortType: 'views',
        videoResults: viewsSortedVideos
      }));
    }
  };

  viewsCustomTooltip = ({ active, payload, label }) => {
    if (
      active &&
      payload &&
      Array.isArray(payload) &&
      payload[0] &&
      payload[0].payload &&
      payload[0].payload.net_views_count < 0
    ) {
      return (
        <div
          className="p-2"
          style={{
            maxWidth: '300px',
            backgroundColor: '#ffffff',
            borderStyle: 'solid',
            borderWidth: 'thin',
            borderColor: '#bfbfbf'
          }}
        >
          <div className="py-1">
            {label}
          </div>
          <div
            className="py-1"
            style={{ color: payload[0].color }}
          >
            {`${payload[0].name}: ${parseFloat(payload[0].payload[payload[0].dataKey])}`}
          </div>
          <div>
            Negative numbers usually indicate that some content was deleted.
          </div>
        </div>
      )
    } else {
      return null;
    }
  };

  render () {
    return (
      <div>
        { this.state.companyYoutubeChannelsLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { (
            !this.state.companyYoutubeChannelsLoading &&
            this.state.companyYoutubeChannels.length === 0
          ) &&
          <div>
            { this.props.customerId !== this.props.companyId &&
              <div>
                Company YouTube channel is not setup.
              </div>
            }
          </div>
        }
        { (
            !this.state.companyYoutubeChannelsLoading &&
            this.state.companyYoutubeChannels.length > 0
          ) &&
          <div>
            <div>
              <Card>
                <Card.Header onClick={this.toggleChannelsDrawer}>
                  <div className="d-flex">
                    <div style={{ width: '100px' }}>
                      {/* YouTube Logo */}
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 502 210.649" width="100%">
                        <g>
                          <path d="M498.333 45.7s-2.91-20.443-11.846-29.447C475.157 4.44 462.452 4.38 456.627 3.687c-41.7-3-104.25-3-104.25-3h-.13s-62.555 0-104.255 3c-5.826.693-18.523.753-29.86 12.566-8.933 9.004-11.84 29.447-11.84 29.447s-2.983 24.003-2.983 48.009v22.507c0 24.006 2.983 48.013 2.983 48.013s2.907 20.44 11.84 29.446c11.337 11.817 26.23 11.44 32.86 12.677 23.84 2.28 101.315 2.983 101.315 2.983s62.62-.094 104.32-3.093c5.824-.694 18.527-.75 29.857-12.567 8.936-9.006 11.846-29.446 11.846-29.446s2.98-24.007 2.98-48.013V93.709c0-24.006-2.98-48.01-2.98-48.01" fill="#cd201f"/>
                          <g>
                            <path d="M187.934 169.537h-18.96V158.56c-7.19 8.24-13.284 12.4-19.927 12.4-5.826 0-9.876-2.747-11.9-7.717-1.23-3.02-2.103-7.736-2.103-14.663V68.744h18.957v81.833c.443 2.796 1.636 3.823 4.043 3.823 3.63 0 6.913-3.153 10.93-8.817V68.744h18.96v100.793zM102.109 139.597c.996 9.98-2.1 14.93-7.987 14.93s-8.98-4.95-7.98-14.93v-39.92c-1-9.98 2.093-14.657 7.98-14.657 5.89 0 8.993 4.677 7.996 14.657l-.01 39.92zm18.96-37.923c0-10.77-2.164-18.86-5.987-23.95-5.054-6.897-12.973-9.72-20.96-9.72-9.033 0-15.913 2.823-20.957 9.72-3.886 5.09-5.97 13.266-5.97 24.036l-.016 35.84c0 10.71 1.853 18.11 5.736 23.153 5.047 6.873 13.227 10.513 21.207 10.513 7.986 0 16.306-3.64 21.36-10.513 3.823-5.043 5.586-12.443 5.586-23.153v-35.926zM46.223 114.647v54.889h-19.96v-54.89S5.582 47.358 1.314 34.815H22.27L36.277 87.38l13.936-52.566H71.17l-24.947 79.833z"/>
                          </g>
                          <g fill="#fff">
                            <path d="M440.413 96.647c0-9.33 2.557-11.874 8.59-11.874 5.99 0 8.374 2.777 8.374 11.997v10.893l-16.964.02V96.647zm35.96 25.986l-.003-20.4c0-10.656-2.1-18.456-5.88-23.5-5.06-6.823-12.253-10.436-21.317-10.436-9.226 0-16.42 3.613-21.643 10.436-3.84 5.044-6.076 13.28-6.076 23.943v34.927c0 10.596 2.46 18.013 6.296 23.003 5.227 6.813 12.42 10.216 21.87 10.216 9.44 0 16.853-3.566 21.85-10.81 2.2-3.196 3.616-6.82 4.226-10.823.164-1.81.64-5.933.64-11.753v-2.827h-18.96c0 7.247.037 11.557-.133 12.54-1.033 4.834-3.623 7.25-8.07 7.25-6.203 0-8.826-4.636-8.76-13.843v-17.923h35.96zM390.513 140.597c0 9.98-2.353 13.806-7.563 13.806-2.973 0-6.4-1.53-9.423-4.553l.02-60.523c3.02-2.98 6.43-4.55 9.403-4.55 5.21 0 7.563 2.93 7.563 12.91v42.91zm2.104-72.453c-6.647 0-13.253 4.087-19.09 11.27l.02-43.603h-17.963V169.54h17.963l.027-10.05c6.036 7.47 12.62 11.333 19.043 11.333 7.193 0 12.45-3.85 14.863-11.267 1.203-4.226 1.993-10.733 1.993-19.956V99.684c0-9.447-1.21-15.907-2.416-19.917-2.41-7.466-7.247-11.623-14.44-11.623M340.618 169.537h-18.956V158.56c-7.193 8.24-13.283 12.4-19.926 12.4-5.827 0-9.877-2.747-11.9-7.717-1.234-3.02-2.107-7.736-2.107-14.663V69.744h18.96v80.833c.443 2.796 1.633 3.823 4.043 3.823 3.63 0 6.913-3.153 10.93-8.817V69.744h18.957v99.793z"/><path d="M268.763 169.537h-19.956V54.77h-20.956V35.835l62.869-.024v18.96h-21.957v114.766z"/>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="pl-4 flex-grow-1">
                      <div>
                        <div>
                          <span>Channel:</span>
                          <a
                            className="ml-2"
                            href={this.state.companyYoutubeChannels[0].channel_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {this.state.companyYoutubeChannels[0].channel_url}
                          </a>
                        </div>
                        <div className="mt-1">
                          <div className="d-inline-block">
                            {`${numberWithCommas(this.state.companyYoutubeChannels[0].subscribers_count)} Subscribers`}
                          </div>
                          <div className="d-inline-block ml-4">
                            {`${numberWithCommas(this.state.companyYoutubeChannels[0].views_count)} Total Views`}
                          </div>
                          <div className="d-inline-block ml-4">
                            {`${numberWithCommas(this.state.companyYoutubeChannels[0].videos_count)} Videos`}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Header>
                <Collapse in={this.state.channelsDrawerOpen}>
                  <div>
                    <Card.Body>
                      { this.state.companyYoutubeChannels.map((videoChannel, i) => {
                          if (i > 0) {
                            return (
                              <div className="d-flex mb-3">
                                <div style={{ width: '100px' }}>
                                  {/* YouTube Logo */}
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 502 210.649" width="100%">
                                    <g>
                                      <path d="M498.333 45.7s-2.91-20.443-11.846-29.447C475.157 4.44 462.452 4.38 456.627 3.687c-41.7-3-104.25-3-104.25-3h-.13s-62.555 0-104.255 3c-5.826.693-18.523.753-29.86 12.566-8.933 9.004-11.84 29.447-11.84 29.447s-2.983 24.003-2.983 48.009v22.507c0 24.006 2.983 48.013 2.983 48.013s2.907 20.44 11.84 29.446c11.337 11.817 26.23 11.44 32.86 12.677 23.84 2.28 101.315 2.983 101.315 2.983s62.62-.094 104.32-3.093c5.824-.694 18.527-.75 29.857-12.567 8.936-9.006 11.846-29.446 11.846-29.446s2.98-24.007 2.98-48.013V93.709c0-24.006-2.98-48.01-2.98-48.01" fill="#cd201f"/>
                                      <g>
                                        <path d="M187.934 169.537h-18.96V158.56c-7.19 8.24-13.284 12.4-19.927 12.4-5.826 0-9.876-2.747-11.9-7.717-1.23-3.02-2.103-7.736-2.103-14.663V68.744h18.957v81.833c.443 2.796 1.636 3.823 4.043 3.823 3.63 0 6.913-3.153 10.93-8.817V68.744h18.96v100.793zM102.109 139.597c.996 9.98-2.1 14.93-7.987 14.93s-8.98-4.95-7.98-14.93v-39.92c-1-9.98 2.093-14.657 7.98-14.657 5.89 0 8.993 4.677 7.996 14.657l-.01 39.92zm18.96-37.923c0-10.77-2.164-18.86-5.987-23.95-5.054-6.897-12.973-9.72-20.96-9.72-9.033 0-15.913 2.823-20.957 9.72-3.886 5.09-5.97 13.266-5.97 24.036l-.016 35.84c0 10.71 1.853 18.11 5.736 23.153 5.047 6.873 13.227 10.513 21.207 10.513 7.986 0 16.306-3.64 21.36-10.513 3.823-5.043 5.586-12.443 5.586-23.153v-35.926zM46.223 114.647v54.889h-19.96v-54.89S5.582 47.358 1.314 34.815H22.27L36.277 87.38l13.936-52.566H71.17l-24.947 79.833z"/>
                                      </g>
                                      <g fill="#fff">
                                        <path d="M440.413 96.647c0-9.33 2.557-11.874 8.59-11.874 5.99 0 8.374 2.777 8.374 11.997v10.893l-16.964.02V96.647zm35.96 25.986l-.003-20.4c0-10.656-2.1-18.456-5.88-23.5-5.06-6.823-12.253-10.436-21.317-10.436-9.226 0-16.42 3.613-21.643 10.436-3.84 5.044-6.076 13.28-6.076 23.943v34.927c0 10.596 2.46 18.013 6.296 23.003 5.227 6.813 12.42 10.216 21.87 10.216 9.44 0 16.853-3.566 21.85-10.81 2.2-3.196 3.616-6.82 4.226-10.823.164-1.81.64-5.933.64-11.753v-2.827h-18.96c0 7.247.037 11.557-.133 12.54-1.033 4.834-3.623 7.25-8.07 7.25-6.203 0-8.826-4.636-8.76-13.843v-17.923h35.96zM390.513 140.597c0 9.98-2.353 13.806-7.563 13.806-2.973 0-6.4-1.53-9.423-4.553l.02-60.523c3.02-2.98 6.43-4.55 9.403-4.55 5.21 0 7.563 2.93 7.563 12.91v42.91zm2.104-72.453c-6.647 0-13.253 4.087-19.09 11.27l.02-43.603h-17.963V169.54h17.963l.027-10.05c6.036 7.47 12.62 11.333 19.043 11.333 7.193 0 12.45-3.85 14.863-11.267 1.203-4.226 1.993-10.733 1.993-19.956V99.684c0-9.447-1.21-15.907-2.416-19.917-2.41-7.466-7.247-11.623-14.44-11.623M340.618 169.537h-18.956V158.56c-7.193 8.24-13.283 12.4-19.926 12.4-5.827 0-9.877-2.747-11.9-7.717-1.234-3.02-2.107-7.736-2.107-14.663V69.744h18.96v80.833c.443 2.796 1.633 3.823 4.043 3.823 3.63 0 6.913-3.153 10.93-8.817V69.744h18.957v99.793z"/><path d="M268.763 169.537h-19.956V54.77h-20.956V35.835l62.869-.024v18.96h-21.957v114.766z"/>
                                      </g>
                                    </g>
                                  </svg>
                                </div>
                                <div className="pl-4 flex-grow-1">
                                  <div>
                                    <div>
                                      <span>Channel:</span>
                                      <a
                                        className="ml-2"
                                        href={videoChannel.channel_url}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {videoChannel.channel_url}
                                      </a>
                                    </div>
                                    <div className="mt-1">
                                      <div className="d-inline-block">
                                        {`${numberWithCommas(videoChannel.subscribers_count)} Subscribers`}
                                      </div>
                                      <div className="d-inline-block ml-4">
                                        {`${numberWithCommas(videoChannel.views_count)} Total Views`}
                                      </div>
                                      <div className="d-inline-block ml-4">
                                        {`${numberWithCommas(videoChannel.videos_count)} Videos`}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        })
                      }
                    </Card.Body>
                  </div>
                </Collapse>
              </Card>
            </div>
            <div>
              <hr />
              <Row>
                <Col>
                  <GeneralSummaryGraph
                    title="Net New Subscribers"
                    metricName="social_subscriber_count_youtube"
                    data={this.state.channelCaptures}
                    dataKey="net_subscribers_gain"
                    dataLabel="Subscribers"
                    scoreType={this.state.channelNetSubscribersGainScoreType}
                    loading={this.state.channelCapturesLoading}
                    allowDecimals={false}
                    showPercentChange={true}
                    showAverageData={true}
                    averageDataKey="net_new_avg_followers"
                    averageDataLabel="Category Average"
                    showTooltip={true}
                    contextType="brand"
                    contextCategory={this.props.category}
                    contextBrand={this.props.brand}
                    contextChannel="Youtube"
                    contextChartName="Net New Subscribers"
                    shareClassName="youtube-net-new-subscribers-img"
                  />
                </Col>
                <Col>
                  <GeneralSummaryGraph
                    title="Net New Views"
                    metricName="video_views_youtube"
                    data={this.state.netNewViewsData}
                    dataKey="net_views_count"
                    dataLabel="Net New Views"
                    scoreType={this.state.netNewViewsCountScoreType}
                    loading={this.state.netNewViewsDataLoading}
                    allowDecimals={false}
                    showPercentChange={true}
                    customTooltip={this.viewsCustomTooltip}
                    negativeColor="#ff0000"
                    showAverageData={true}
                    averageDataKey="youtube_avg_net_views_trend"
                    averageDataLabel="Category Average"
                    showTooltip={true}
                    contextType="brand"
                    contextCategory={this.props.category}
                    contextBrand={this.props.brand}
                    contextChannel="Youtube"
                    contextChartName="Net New Views"
                    shareClassName="youtube-net-new-views-img"
                  />
                </Col>
              </Row>
              <div>
                <BrandMetricsByMonthTableContainer
                  type="Videos"
                  category={this.props.category}
                  brand={this.props.brand}
                  audienceProfileId={this.props.category.audience_profile_id}
                />
              </div>
              <h5
                ref={(ref) => this.videoDetails = ref}
                className="my-2 p-2 bg-bops-blue text-light"
              >
                Details
                <span
                  className="ml-4 mr-2"
                  style={{ fontSize: '1rem', fontWeight: 'normal' }}
                >Date Range</span>
                <DateRangePicker
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  updateDates={this.onFilterDatesChange}
                />
                <div
                  className="d-inline-block ml-4"
                  style={{ fontSize: '1rem', fontWeight: 'normal' }}
                >
                  <Form.Check
                    className="ml-4"
                    type="checkbox"
                    label={`Corporate ${this.state.cppCounts.companyCount != null ? `(${numberWithCommas(this.state.cppCounts.companyCount)})` : ''}`}
                    checked={this.props.companyChecked}
                    onChange={(event) => this.props.onCppCheckChange('company', event.currentTarget.checked)}
                    inline
                  />
                  <Form.Check
                    className="ml-2"
                    type="checkbox"
                    label={`Portfolio ${this.state.cppCounts.portfolioCount != null ? `(${numberWithCommas(this.state.cppCounts.portfolioCount)})` : ''}`}
                    checked={this.props.portfolioChecked}
                    onChange={(event) => this.props.onCppCheckChange('portfolio', event.currentTarget.checked)}
                    inline
                  />
                  <Form.Check
                    className="ml-2"
                    type="checkbox"
                    label={`Product Brand ${this.state.cppCounts.productCount != null ? `(${numberWithCommas(this.state.cppCounts.productCount)})` : ''}`}
                    checked={this.props.productChecked}
                    onChange={(event) => this.props.onCppCheckChange('product', event.currentTarget.checked)}
                    inline
                  />
                </div>
              </h5>
              <div>
                { this.state.channelVideosLoading &&
                  <div className="m-4 text-center">
                    <ClipLoader size={100}/>
                  </div>
                }
                { !this.state.channelVideosLoading &&
                  <YoutubeVideos
                    youtubeChannelId={this.state.companyYoutubeChannels.youtube_channel_id}
                    youtubeVideos={this.state.videoResults}
                    category={this.props.category}
                    brand={this.props.brand}
                    sortType={this.state.sortType}
                    changeSortType={this.changeSortType}
                  />
                }
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
};
