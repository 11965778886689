import React, { PureComponent } from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Tooltip from 'reactjs-popup';
import CalendarHeatmap from 'react-calendar-heatmap';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactTooltip from 'react-tooltip';
import BeforeAfterSlider from 'react-before-after-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompressArrowsAlt, faExpandArrowsAlt, faInfoCircle, faLink } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '../../utils/numbers';
import { CONTENT_SCRAPING_ENDPOINT, LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';
import { sortAlphabeticalByKey } from '../../utils/sorts';

const selectedTypeClasses = "bg-primary text-light rounded px-2";
const tabHeightHeatMapCollapsed = 'calc(100vh - 270px)';
const tabHeightHeatMapExpanded = 'calc(100vh - 450px)';

export default class WebCaptureDisplay extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      subPages: [],
      cppCounts: [],
      webCaptureDataLoading: false,
      selectedSubPageId: undefined,
      selectedSubPage: undefined,
      heatMapStartDate: new Date(),
      heatMapValues: [],
      selectedDate: undefined,
      changedSublinkIds: {},
      currentImageUrl: undefined,
      currentImageUrlDate: undefined,
      selectedImageUrl: undefined,
      currentText: '',
      currentTextDate: undefined,
      selectedText: '',
      imagesLoading: false,
      windowWidth: window.innerWidth*(.5)
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.refresh();
    this.fetchWebCaptureCppCounts();
    window.addEventListener("resize", this.resizeWindow);
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
    window.removeEventListener("resize", this.resizeWindow);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.brandId !== this.props.brandId) {
      if (this.props.brandId) {
        this.refresh();
        this.fetchWebCaptureCppCounts();
      } else {
        this.setState(() => ({
          subPages: [],
          selectedSubPageId: undefined,
          selectedSubPage: undefined,
          heatMapStartDate: new Date(),
          heatMapValues: [],
          selectedDate: undefined,
          changedSublinkIds: {},
          currentImageUrl: undefined,
          currentImageUrlDate: undefined,
          selectedImageUrl: undefined,
          currentText: '',
          currentTextDate: undefined,
          selectedText: '',
          heatMapCollapsed: false,
        }));
      }
    }
    if (
      prevProps.companyChecked !== this.props.companyChecked ||
      prevProps.portfolioChecked !== this.props.portfolioChecked ||
      prevProps.productChecked !== this.props.productChecked
    ) {
      this.refresh();
    }
  };

  refresh = () => {
    if (this.props.brand) {
      const refreshPromises = [];
      // refresh web capture pages
      this.setState(() => ({ webCaptureDataLoading: true }));
      let brandLevelsParam = '';
      if (this.props.companyChecked) {
        brandLevelsParam += '&brand_levels=company';
      }
      if (this.props.portfolioChecked) {
        brandLevelsParam += '&brand_levels=portfolio';
      }
      if (this.props.productChecked) {
        brandLevelsParam += '&brand_levels=product';
      }

      refreshPromises.push(
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/company-site-scrape-data?product_brand_id=${this.props.brand.id}`,
          HEADERS
        ).then(response => {
          const subPagesData = response.data.sub_urls;
          const subPages = [];
          for (const sp of subPagesData) {
            if (
              Array.isArray(sp.brand_types) &&
              (
                (this.props.companyChecked && sp.brand_types.includes('company')) ||
                (this.props.productChecked && sp.brand_types.includes('product')) ||
                (this.props.portfolioChecked && sp.brand_types.includes('portfolio'))
              )
            ) {
              subPages.push(sp);
            }
          }
          subPages.sort(sortAlphabeticalByKey('url'));

          let selectedSubPageId;
          let selectedSubPage;
          if (subPages.length > 0) {
            selectedSubPageId = subPages[0].id;
            selectedSubPage = subPages[0];
          }

          return {
            subPages,
            selectedSubPageId,
            selectedSubPage
          }
        }).catch(error => {
          console.error('Error: failed to fetch pages collected');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return {
            subPages: [],
            selectedSubPageId: undefined,
            selectedSubPage: undefined
          }
        })
      );

      // refresh web capture dates
      refreshPromises.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/company-site-scrape-dates-and-counts?product_brand_id=${this.props.brand.id}${brandLevelsParam}`,
          HEADERS
        ).then(response => {
          const datesDate = response.data.dates;
          const datesObj = {};
          const startDate = moment().startOf('day').subtract(89, 'days');
          let date = moment(startDate);
          // build dateObj
          for (let i = 0; i < 90; i++) {
            datesObj[date.format('YYYY-MM-DD')] = {
              date: date.toDate(),
              count: 0
            };
            date = moment(date).add(1, 'days');
          }

          let selectedDate;
          let changedSublinkIds = {};
          if (datesDate) {
            // override actual dates
            for (const [dateKey, value] of Object.entries(datesDate)) {
              if (datesObj[dateKey]) {
                datesObj[dateKey].count = value.count;
                datesObj[dateKey].selectable = true;
                datesObj[dateKey].changed_sublink_ids = value.changed_sublink_ids;
              }
            }
            const datesDateKeys = Object.keys(datesDate);
            const changeSublinksDate = datesDateKeys.slice(-1)[0];
            selectedDate = datesDateKeys.slice(-2)[0];
            if (datesObj[changeSublinksDate] && datesObj[changeSublinksDate].changed_sublink_ids) {
              changedSublinkIds = datesObj[changeSublinksDate].changed_sublink_ids.reduce((a,b)=> (a[b]=true,a),{});
            }
          }
          // convert datesObj to array for heatmap
          const heatMapValues = [];
          for (const [key, value] of Object.entries(datesObj)) {
            heatMapValues.push(value);
          }

          // subtract one more day due to issue with heatmap start date
          const heatMapStartDate = moment(startDate).subtract(1, 'days').toDate();
          return {
            heatMapStartDate,
            heatMapValues,
            selectedDate,
            changedSublinkIds
          }
        }).catch(error => {
          console.error('Error: failed to fetch dates and counts');
          const startDate = moment().startOf('day').subtract(89, 'days');
          const heatMapStartDate = moment(startDate).subtract(1, 'days').toDate();
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return {
            heatMapStartDate,
            heatMapValues: [],
            selectedDate: undefined,
            changedSublinkIds: []
          }
        })
      );

      Promise.all(refreshPromises).then(responses => {
        let subPages = [];
        let selectedSubPageId;
        let selectedSubPage;
        let heatMapStartDate = new Date();
        let heatMapValues = [];
        let selectedDate;
        let changedSublinkIds = {};
        for (const response of responses) {
          if (response) {
            if (response.subPages) {
              subPages = response.subPages;
              selectedSubPageId = response.selectedSubPageId;
              selectedSubPage = response.selectedSubPage;
            } else if (response.heatMapValues) {
              heatMapStartDate = response.heatMapStartDate;
              heatMapValues = response.heatMapValues;
              selectedDate = response.selectedDate;
              changedSublinkIds = response.changedSublinkIds;
            }
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            subPages,
            webCaptureDataLoading: false,
            selectedSubPageId,
            selectedSubPage,
            heatMapStartDate,
            heatMapValues,
            selectedDate,
            changedSublinkIds
          }), () => this.fetchTextAndImages());
        }
      });
    }
  };

  fetchWebCaptureCppCounts = () => {
    if (this.props.brand) {
      this.setState(() => ({ cppCounts: {} }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/web-image-scrape-url-sub-link-cpp-counts?product_brand_id=${this.props.brand.id}`,
        HEADERS
      ).then(response => {
        const cppCounts = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({ cppCounts }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch earned web capture cpp counts...');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchHistoricTextAndImage = () => {
    if (this.props.brand && this.state.selectedSubPageId && this.state.selectedDate) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/historic-text-and-image-by-date?date=${this.state.selectedDate}&product_brand_id=${this.props.brand.id}&url_sub_link_id=${this.state.selectedSubPageId}`,
        HEADERS
      ).then(response => {
        const historicTextAndImage = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            selectedImageUrl: historicTextAndImage.image_url,
            selectedText: historicTextAndImage.text
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch historic text and image');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchTextAndImages = () => {
    if (this.props.brand && this.state.selectedSubPageId && this.state.selectedDate) {
      // set svg view box to better fit
      const classArray = document.getElementsByClassName("react-calendar-heatmap");
      if (classArray.length > 0) {
        const heatMapSvg = classArray[0];
        heatMapSvg.setAttribute("viewBox", "0 0 185 100");
      }

      this.setState(() => ({ imagesLoading: true }));
      const textAndImagePromises = [];
      // fetch current text
      textAndImagePromises.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/current-text-and-image?product_brand_id=${this.props.brand.id}&url_sub_link_id=${this.state.selectedSubPageId}`,
          HEADERS
        ).then(response => {
          const currentTextAndImage = response.data;
          return { currentTextAndImage };
        }).catch(error => {
          console.error('Error: failed to fetch current text and image');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        })
      );
      // fetch historic text
      textAndImagePromises.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/historic-text-and-image-by-date?date=${this.state.selectedDate}&product_brand_id=${this.props.brand.id}&url_sub_link_id=${this.state.selectedSubPageId}`,
          HEADERS
        ).then(response => {
          const historicTextAndImage = response.data;
          return { historicTextAndImage };
        }).catch(error => {
          console.error('Error: failed to fetch historic text and image');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        })
      );

      Promise.all(textAndImagePromises).then(responses => {
        let currentImageUrl;
        let currentImageUrlDate;
        let currentText;
        let currentTextDate;
        let selectedImageUrl;
        let selectedText;
        for (const response of responses) {
          if (response.currentTextAndImage) {
            currentImageUrl =  response.currentTextAndImage.image_url;
            currentImageUrlDate = response.currentTextAndImage.image_capture_date;
            currentText = response.currentTextAndImage.text;
            currentTextDate = response.currentTextAndImage.text_capture_date;
          } else if (response.historicTextAndImage) {
            selectedImageUrl = response.historicTextAndImage.image_url;
            selectedText = response.historicTextAndImage.text;
          }
        }

        if (this.state.isMounted) {
          if (currentImageUrl) {
            let img = new Image();
            const scope = this;
            img.onload = function() {
              const currentImageWidth = this.width;
              const currentImageHeight = this.height;
              const currentImageDimensionRatio = (currentImageHeight)/(currentImageWidth);
              scope.setState({ currentImageDimensionRatio, currentImageWidth, currentImageHeight })
            }
            img.src = currentImageUrl;
          }

          this.setState(() => ({
            currentImageUrl,
            currentImageUrlDate,
            currentText,
            currentTextDate,
            selectedImageUrl,
            selectedText,
            imagesLoading: false,
          }));
        }
      });
    }
  };

  onSelectSubPage = (selectedSubPage) => {
    if (selectedSubPage.id !== this.state.selectedSubPageId) {
      this.setState(
        () => ({
          selectedSubPageId: selectedSubPage.id,
          selectedSubPage
        }),
        () => this.fetchTextAndImages()
      );
    }
  };

  onHeatMapSelect = (heatMapSelection) => {
    if (heatMapSelection.selectable) {
      const selectedDate = moment(heatMapSelection.date).format('YYYY-MM-DD');
      if (selectedDate !== this.state.selectedDate) {
        this.setState(
          () => ({ selectedDate }),
          () => this.fetchHistoricTextAndImage()
        );
      }
    }
  };

  toggleHeadMapCollapse = () => {
    this.setState((prevState) => ({
      heatMapCollapsed: !prevState.heatMapCollapsed
    }));
  };

  resizeWindow = () => {
    window.resizeTo(window.innerWidth, window.innerHeight);
    if (this.state.windowWidth != window.innerWidth*(.5))
      this.setState(() => ({ windowWidth: window.innerWidth*(.5) }));
  }

  render () {
    return (
      <div>
        <h5 className="my-2 p-2 bg-bops-blue text-light">
          Details
          <div
            className="d-inline-block ml-4"
            style={{ fontSize: '1rem', fontWeight: 'normal' }}
          >
            <Form.Check
              className="ml-4"
              type="checkbox"
              label={`Corporate ${this.state.cppCounts.company != null ? `(${numberWithCommas(this.state.cppCounts.company)})` : ''}`}
              checked={this.props.companyChecked}
              onChange={(event) => this.props.onCppCheckChange('company', event.currentTarget.checked)}
              inline
            />
            <Form.Check
              className="ml-2"
              type="checkbox"
              label={`Portfolio ${this.state.cppCounts.portfolio != null ? `(${numberWithCommas(this.state.cppCounts.portfolio)})` : ''}`}
              checked={this.props.portfolioChecked}
              onChange={(event) => this.props.onCppCheckChange('portfolio', event.currentTarget.checked)}
              inline
            />
            <Form.Check
              className="ml-2"
              type="checkbox"
              label={`Product Brand ${this.state.cppCounts.product != null ? `(${numberWithCommas(this.state.cppCounts.product)})` : ''}`}
              checked={this.props.productChecked}
              onChange={(event) => this.props.onCppCheckChange('product', event.currentTarget.checked)}
              inline
            />
          </div>
        </h5>
        { this.state.webCaptureDataLoading &&
          <div className="text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.webCaptureDataLoading &&
          <Row>
            <Col xs={3}>
              { this.props.brandId &&
                <div>
                  <div>
                    <div>
                      <b>Domain</b>
                      <span className="ml-2">
                        { this.props.brand &&
                          <a href={this.props.brand.company_url} target="_blank" rel="noreferrer">
                            {this.props.brand.company_url}
                          </a>
                        }
                      </span>
                    </div>
                  </div>
                  <div>
                    <b>Pages</b>
                    <Tooltip
                      trigger = {
                        <span>
                          <FontAwesomeIcon
                            className="ml-2"
                            icon={faInfoCircle}
                            color="#6c757d"
                          />
                        </span>
                      }
                      position="right center"
                      on="hover"
                      contentStyle={{ borderRadius: '5px' }}
                    >
                      <div>
                        Pages in highlight signify a change in the past week.
                      </div>
                    </Tooltip>
                  </div>
                  <div className="border rounded bg-white">
                    <div
                      className="pre-scrollable"
                      style={{
                        minHeight: '400px',
                        maxHeight: '400px',
                        fontSize: '.875rem'
                      }}
                    >
                      { this.state.subPages.map((subPage, i) => {
                          let classNames = 'px-2 ellipsis';
                          if (this.state.selectedSubPage && this.state.selectedSubPage.id === subPage.id) {
                            classNames = `${selectedTypeClasses} ellipsis`;
                          } else if (this.state.changedSublinkIds[subPage.id]) {
                            classNames = `${classNames} bg-light-yellow`;
                          }
                          let path;
                          try {
                            path = new URL(subPage.url).pathname;
                          } catch (e) {
                            path = subPage.url;
                          }

                          return (
                            <Tooltip
                              key={`sp${i}`}
                              trigger = {
                                <div
                                  className={classNames}
                                  onClick={() => this.onSelectSubPage(subPage)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {path}
                                </div>
                              }
                              position="right center"
                              on="hover"
                              contentStyle={{ borderRadius: '5px' }}
                            >
                              <div>
                                <div style={{ wordBreak: 'break-word' }}>
                                  <b>Path: </b>
                                  {path}
                                </div>
                                <div>
                                  <b>Page Type: </b>
                                  {subPage.url_type}
                                </div>
                              </div>
                            </Tooltip>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              }
            </Col>
            <Col>
              { this.props.brandId &&
                <div>
                  <div>
                    { this.state.heatMapValues.length > 0 &&
                      <Accordion defaultActiveKey="0">
                        <Accordion.Toggle
                          as="div"
                          eventKey="0"
                          onClick={this.toggleHeadMapCollapse}
                          style={{ cursor: 'pointer' }}
                        >
                          <b>Date Selection</b>
                          { this.state.heatMapCollapsed &&
                            <FontAwesomeIcon
                              className="ml-2 text-dark"
                              icon={faExpandArrowsAlt}
                            />
                          }
                          { !this.state.heatMapCollapsed &&
                            <FontAwesomeIcon
                              className="ml-2 text-dark"
                              icon={faCompressArrowsAlt}
                            />
                          }
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <div style={{ width: '300px' }}>
                            <CalendarHeatmap
                              startDate={this.state.heatMapStartDate}
                              endDate={this.state.heatMapValues[this.state.heatMapValues.length-1].date}
                              values={this.state.heatMapValues}
                              classForValue={(value) => {
                                let classes = [];
                                if (!value) {
                                  classes.push('color-empty');
                                } else {
                                  if (value.selectable) {
                                    if (value.count > 9) {
                                      classes.push('color-scale-4');
                                    } else if (value.count > 6) {
                                      classes.push('color-scale-3');
                                    } else if (value.count > 3) {
                                      classes.push('color-scale-2');
                                    } else if (value.count > 0) {
                                      classes.push('color-scale-1');
                                    } else {
                                      classes.push('color-empty');
                                    }
                                  } else {
                                    classes.push('color-disabled');
                                  }

                                  if (moment(value.date).format('YYYY-MM-DD') === this.state.selectedDate) {
                                    classes.push('selected');
                                  }
                                }
                                return classes.join(' ');
                              }}
                              tooltipDataAttrs={(value) => {
                                return {
                                  'data-tip': `${value.count} change(s) on ${moment(value.date).format('MM/DD/YYYY')}`
                                };
                              }}
                              onClick={(value) => this.onHeatMapSelect(value)}
                              showWeekdayLabels={true}
                            />
                            <ReactTooltip />
                          </div>
                        </Accordion.Collapse>
                      </Accordion>
                    }
                  </div>
                  <div>
                    { this.state.selectedSubPageId &&
                      <Tabs
                        defaultActiveKey="currentImage"
                        activeKey={this.state.selectedTab}
                        onSelect={(selectedTab) => {this.setState(() => ({ selectedTab }))}}
                      >
                        <Tab
                          eventKey="currentImage"
                          title= "Image Comparison"
                        >
                          { this.state.imagesLoading &&
                            <div className="text-center">
                              <ClipLoader size={100}/>
                            </div>
                          }
                          { !this.state.imagesLoading &&
                            <React.Fragment>
                              { this.state.currentImageUrl && this.state.selectedImageUrl &&
                                <div>
                                  <div className="text-center bg-dark text-light border">
                                    <div
                                      className="d-inline-block border-right"
                                      style={{ width: '49%' }}
                                    >
                                      <div className="p-2">
                                        { this.state.selectedDate ?
                                            `Selected Date (${moment(this.state.selectedDate).format('MM-DD-YYYY')})` :
                                            'Selected Date'
                                        }
                                        <FontAwesomeIcon
                                          className="mt-1 float-right"
                                          icon={faLink}
                                          onClick={() => window.open(this.state.selectedImageUrl, '_blank') }
                                          style={{ cursor: 'pointer' }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-inline-block w-50 border-left">
                                      <div className="p-2">
                                        Current Page
                                        <FontAwesomeIcon
                                          className="mt-1 float-right"
                                          icon={faLink}
                                          onClick={() => window.open(this.state.selectedSubPage.url, '_blank') }
                                          style={{ cursor: 'pointer' }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="pre-scrollable"
                                    style={{
                                      overflowX: "hidden",
                                      maxHeight: this.state.heatMapCollapsed ?
                                        tabHeightHeatMapCollapsed :
                                        tabHeightHeatMapExpanded
                                    }}
                                  >
                                    { this.state.windowWidth && this.state.currentImageDimensionRatio &&
                                      <BeforeAfterSlider
                                        before={this.state.currentImageUrl}
                                        after={this.state.selectedImageUrl}
                                        width={this.state.windowWidth}
                                        height={this.state.windowWidth * this.state.currentImageDimensionRatio}
                                      />
                                    }
                                  </div>
                                </div>
                              }
                            </React.Fragment>
                          }
                        </Tab>
                      </Tabs>
                    }
                  </div>
                </div>
              }
            </Col>
          </Row>
        }
      </div>
    );
  }
};
