import React from 'react';
import BrandTabOverview from '../BrandTabOverview';
import PodcastsTab from './PodcastsTab';
import WebinarTab from './WebinarTab';
import VideoDetailsTab from './VideoDetailsTab';
import VideoSearchResults from '../../video/VideoSearchResults';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPodcast, faDesktop, faVideo } from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { createUserActivityLog } from '../../../utils/users';
import history from '../../../routers/history';

export default class MediaCastTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'podcast',
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let subTabIndex = hash.indexOf('-');
      let selectedTab = subTabIndex > 0 ? hash.substring(subTabIndex+1) : 'podcast';
      this.setState(() => ({ selectedTab }));
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  changeSelectedTab = (selectedTab, subTabName) => {
    history.push(`#mediacast-${selectedTab}`);
    this.setState(() => ({ selectedTab }));
    if (subTabName) {
      createUserActivityLog(
        this.props.user,
        `Brand Tabs: Media Cast: ${subTabName}`,
        this.props.category,
        this.props.brand,
      )
    }
  };

  render () {
    return (
      <div className="p-4">
        <div className="mb-4">
          <button
            id="brandPodcastButton"
            type="button"
            className= {
              this.state.selectedTab === 'podcast' ?
                'btn border-primary bg-white mr-1' :
                'btn border bg-white mr-1'
            }
            onClick={(e) => this.changeSelectedTab('podcast', 'Podcast')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faPodcast}/>
            </div>
            <div className="d-inline-block">
              Podcast
            </div>
          </button>
          <button
            id="brandWebinarButton"
            type="button"
            className= {
              this.state.selectedTab === 'webinar' ?
                'btn border-primary bg-white mr-1' :
                'btn border bg-white mr-1'
            }
            onClick={(e) => this.changeSelectedTab('webinar', 'Webinar')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faDesktop}/>
            </div>
            <div className="d-inline-block">
              Webinar
            </div>
          </button>
          <button
            id="brandYoutubeActivityButton"
            type="button"
            className= {
              this.state.selectedTab === 'youtubeActivity' ?
                'btn border-primary bg-white mr-1' :
                'btn border bg-white mr-1'
            }
            onClick={(e) => this.changeSelectedTab('youtubeActivity', 'YouTube Activity')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faVideo}/>
            </div>
            <div className="d-inline-block">
              YouTube Activity
            </div>
          </button>
          <button
            id="brandYoutubeSearchButton"
            type="button"
            className= {
              this.state.selectedTab === 'youtubeSearch' ?
                'btn border-primary bg-white mr-1' :
                'btn border bg-white mr-1'
            }
            onClick={(e) => this.changeSelectedTab('youtubeSearch', 'YouTube Search')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faYoutube}/>
            </div>
            <div className="d-inline-block">
              YouTube Search
            </div>
          </button>
        </div>

        <div
          className="pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 200px)',
            maxHeight: 'calc(100vh - 200px)',
            overflow: 'auto',
            overflowX: 'hidden'
          }}
        >

          { this.state.selectedTab === 'podcast' &&
            <PodcastsTab
              customerId={this.props.user.customerId}
              customerUserId={this.props.user.id}
              categoryId={this.props.categoryId}
              categoryType={this.props.categoryType}
              category={this.props.category}
              brandId={this.props.brandId}
              brand={this.props.brand}
              brandName={this.props.brand.name}
              topicId={this.props.topicId}
              companyId={this.props.brand.company_id}
              startDateFilter={this.props.startDateFilter}
              endDateFilter={this.props.endDateFilter}
              onFilterDatesChange={this.onFilterDatesChange}
              allMonthsChecked={this.props.allMonthsChecked}
              updateAllMonthsChecked={this.props.updateAllMonthsChecked}
              allMonthsStartDate={this.props.allMonthsStartDate}
              companyChecked={this.props.companyChecked}
              portfolioChecked={this.props.portfolioChecked}
              productChecked={this.props.productChecked}
              onCppCheckChange={this.props.onCppCheckChange}
            />
          }
          { this.state.selectedTab === 'webinar' &&
            <WebinarTab
              customerId={this.props.user.customerId}
              categoryId={this.props.categoryId}
              categoryType={this.props.categoryType}
              category={this.props.category}
              brandId={this.props.brandId}
              brand={this.props.brand}
              brandName={this.props.brand.name}
              companyId={this.props.brand.company_id}
              allMonthsChecked={this.props.allMonthsChecked}
              updateAllMonthsChecked={this.props.updateAllMonthsChecked}
              allMonthsStartDate={this.props.allMonthsStartDate}
              companyChecked={this.props.companyChecked}
              portfolioChecked={this.props.portfolioChecked}
              productChecked={this.props.productChecked}
              onCppCheckChange={this.props.onCppCheckChange}
            />
          }
          { this.state.selectedTab === 'youtubeActivity' &&
            <VideoDetailsTab
              location={this.props.location}
              customerId={this.props.customerId}
              companyId={this.props.companyId}
              categoryId={this.props.categoryId}
              categoryType={this.props.categoryType}
              category={this.props.category}
              brandId={this.props.brandId}
              brand={this.props.brand}
              brandName={this.props.brandName}
              topicId={this.props.topicId}
              startDateFilter={this.props.startDateFilter}
              endDateFilter={this.props.endDateFilter}
              onFilterDatesChange={this.props.onFilterDatesChange}
              allMonthsChecked={this.props.allMonthsChecked}
              updateAllMonthsChecked={this.props.updateAllMonthsChecked}
              allMonthsStartDate={this.props.allMonthsStartDate}
              companyChecked={this.props.companyChecked}
              portfolioChecked={this.props.portfolioChecked}
              productChecked={this.props.productChecked}
              onCppCheckChange={this.props.onCppCheckChange}
            />
          }
          { this.state.selectedTab === 'youtubeSearch' &&
            <div>
              <BrandTabOverview
                overviewType="Youtube Search"
              />
              <VideoSearchResults
                location={this.props.location}
                user={this.props.user}
                customerId={this.props.customerId}
                companyId={this.props.companyId}
                categoryId={this.props.categoryId}
                categoryType={this.props.categoryType}
                category={this.props.category}
                brandId={this.props.brandId}
                brand={this.props.brand}
                brandName={this.props.brandName}
                topicId={this.props.topicId}
              />
            </div>
          }
        </div>
      </div>
    );
  }
};
