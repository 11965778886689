import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default class RefreshAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    return (
      <Modal
        size="sm"
        show={this.props.showRefreshAlert}
        onHide={() => this.props.setShowRefreshAlert(false)}
      >
        <Modal.Header
          className="bg-danger text-light"
          closeButton
        >
          <Modal.Title>
            Refresh Needed
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { this.props.message || 'We noticed you are running an out of date app. Refresh to get the latest and greatest features.' }
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => this.props.setShowRefreshAlert(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
};
