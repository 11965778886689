import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GeneralSummaryGraph from '../graphs/GeneralSummaryGraph';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class WebExternalStatsSummaryGraphs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      webStatsAlexaRankScoreType: undefined,
      webStatsViewPerUserScoreType: undefined,
      webStatsReachPerMillionScoreType: undefined,
      semrushStatsTrendData: [],
      semrushStatsTrendDataLoading: false,
      semrushStatsRankScoreType: undefined,
      semrushStatsOrganicTrafficScoreType: undefined,
      semrushStatsAdwordsTrafficScoreType: undefined,
      categoryAverageSemrushData: [],
      mozStatsTrendData: [],
      mozStatsBacklinkCountScoreType: undefined,
      mozStatsTrendDataLoading: false,
      categoryAverageMozData: [],
    }
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchSemrushStatsTrendData();
    this.fetchMozStatsTrendData();
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.domain !== this.props.domain ||
      prevProps.allMonthsChecked !== this.props.allMonthsChecked
    ) {
      this.fetchSemrushStatsTrendData();
      this.fetchMozStatsTrendData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchSemrushStatsTrendData = () => {
    if (this.props.brandId) {
      this.setState(() => ({ semrushStatsTrendDataLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/semrush-stats-trend?product_brand_id=${this.props.brandId}${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let semrushStatsTrendData = response.data.monthly_stats;
        const semrushStatsRankScoreType = response.data.rank ? response.data.rank.score_type : undefined;
        const semrushStatsOrganicTrafficScoreType = response.data.organic_traffic ? response.data.organic_traffic.score_type : undefined;
        const semrushStatsAdwordsTrafficScoreType = response.data.adwords_traffic ? response.data.adwords_traffic.score_type : undefined;
        // merge category average data into original data source
        semrushStatsTrendData = semrushStatsTrendData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAverageSemrushData[i]))
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            semrushStatsTrendData,
            semrushStatsRankScoreType,
            semrushStatsOrganicTrafficScoreType,
            semrushStatsAdwordsTrafficScoreType,
            semrushStatsTrendDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch semrush stats trend...');
        if (this.state.isMounted) {
          this.setState(() => ({
            semrushStatsTrendData: [],
            semrushStatsRankScoreType: undefined,
            semrushStatsOrganicTrafficScoreType: undefined,
            semrushStatsAdwordsTrafficScoreType: undefined,
            semrushStatsTrendDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=semrush_avg_stats${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAverageSemrushData = response.data.category_stats;
          // merge category average data into original data source
          const semrushStatsTrendData = this.state.semrushStatsTrendData.map((x, i) => {
            return (Object.assign({}, x, categoryAverageSemrushData[i]))
          });
          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAverageSemrushData,
              semrushStatsTrendData
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average semrush data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAverageSemrushData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  fetchMozStatsTrendData = () => {
    if (this.props.brand && this.props.brand.company_url) {
      this.setState(() => ({ mozStatsTrendDataLoading: true }));
      this.setState(() => ({ semrushStatsTrendDataLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/moz-stats-trend?url=${this.props.brand.company_url}${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let mozStatsTrendData = response.data.monthly_stats;
        const mozStatsBacklinkCountScoreType = response.data.external_pages_to_root_domain ? response.data.external_pages_to_root_domain.score_type : undefined;
        // merge category average data into original data source
        mozStatsTrendData = mozStatsTrendData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAverageMozData[i]))
        });
        if (this.state.isMounted) {
          this.setState(() => ({
            mozStatsTrendData,
            mozStatsBacklinkCountScoreType,
            mozStatsTrendDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch moz stats trend...');
        if (this.state.isMounted) {
          this.setState(() => ({
            mozStatsTrendData: [],
            mozStatsBacklinkCountScoreType: undefined,
            mozStatsTrendDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=moz_avg_backlinks_count${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAverageMozData = response.data.category_stats;
          // merge category average data into original data source
          const mozStatsTrendData = this.state.mozStatsTrendData.map((x, i) => {
            return (Object.assign({}, x, categoryAverageMozData[i]))
          });
          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAverageMozData,
              mozStatsTrendData
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average moz data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAverageMozData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  render () {
    return (
      <div>
        <Row>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Site Rank, SEMrush"
                metricName="web_semrush_domain_rank"
                data={this.state.semrushStatsTrendData}
                dataKey="rank"
                dataLabel="Site Rank, SEMrush"
                scoreType={this.state.semrushStatsRankScoreType}
                loading={this.state.semrushStatsTrendDataLoading}
                allowDecimals={false}
                showTooltip={true}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Web"
                contextChartName="Site Rank, SEMrush"
                shareClassName="site-rank-semrush-img"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Organic Traffic, SEMrush"
                metricName="web_semrush_organic_traffic"
                data={this.state.semrushStatsTrendData}
                dataKey="organic_traffic"
                dataLabel="Organic Traffic, SEMrush"
                scoreType={this.state.semrushStatsOrganicTrafficScoreType}
                loading={this.state.semrushStatsTrendDataLoading}
                allowDecimals={false}
                showPercentChange={true}
                showAverageData={true}
                averageDataKey="avg_semrush_organic_traffic"
                averageDataLabel="Category Average"
                showTooltip={true}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Web"
                contextChartName="Organic Traffic, SEMrush"
                shareClassName="organic-traffic-img"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Adwords Traffic, SEMrush"
                metricName="web_semrush_adwords_traffic"
                data={this.state.semrushStatsTrendData}
                dataKey="adwords_traffic"
                dataLabel="Adwords Traffic, SEMrush"
                scoreType={this.state.semrushStatsAdwordsTrafficScoreType}
                loading={this.state.semrushStatsTrendDataLoading}
                allowDecimals={false}
                showPercentChange={true}
                showAverageData={true}
                averageDataKey="avg_semrush_adwords_traffic"
                averageDataLabel="Category Average"
                showTooltip={true}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Web"
                contextChartName="Adwords Traffic, SEMrush"
                shareClassName="adwords-traffic-img"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Backlink Count (Pages), Moz"
                metricName="web_moz_backlink_count"
                data={this.state.mozStatsTrendData}
                dataKey="external_pages_to_root_domain"
                dataLabel="Backlink Count"
                scoreType={this.state.mozStatsBacklinkCountScoreType}
                loading={this.state.mozStatsTrendDataLoading}
                allowDecimals={false}
                showPercentChange={true}
                showAverageData={true}
                averageDataKey="moz_avg_backlinks_count"
                averageDataLabel="Category Average"
                showTooltip={true}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Web"
                contextChartName="Backlink Count (Pages), Moz"
                shareClassName="backlins-count-moz-img"
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};
