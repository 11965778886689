import React from 'react';
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  CartesianGrid,
  Scatter,
  ResponsiveContainer
} from 'recharts';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && Array.isArray(payload)) {
    return (
      <div
        className="p-2"
        style={{
          backgroundColor: (payload.length > 0 && payload[0].payload.event) ?
            '#ffffcc': '#ffffff',
            borderStyle: 'solid',
            borderWidth: 'thin',
            borderColor: '#bfbfbf'
        }}
      >
        { payload.map((item, i) => {
            if (item.name === 'Event') {
              return (
                <div
                  key={`tt-${item.payload.id}-${i}`}
                  className="py-1"
                >
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={faStar}
                    color="#ffc107"
                  />
                  <b>{item.payload.eventSummary}</b>
                </div>
              )
            } else {
              return (
                <div
                  key={`tt-${item.payload.id}-${i}`}
                  className="py-1"
                  style={{ color: item.color }}
                >
                  {`${item.name}: ${parseFloat(item.payload[item.dataKey]).toFixed(1)}`}
                </div>
              )
            }
          })
        }
        <div className="py-1">
          {moment(label).format('dddd, MMMM Do YYYY')}
        </div>
      </div>
    )
  } else {
    return null;
  }
};

const LCXRatingGraph = (props) => (
  <ResponsiveContainer width="99%" height="100%" minWidth={0} minHeight={0}>
    <ComposedChart data={props.ratingData}>
      <XAxis
        dataKey={props.dateKey}
        tickFormatter={(date) => {
          if (props.showTime) {
            return moment(date).format('hh:mma');
          } else {
            return moment(date).endOf('day').format('MM/DD');
          }
        }}
        minTickGap={20}
      />
      <YAxis
        orientation="right"
        axisLine={false}
        domain={[0, 100]}
      />
      <ZAxis
        type="number"
        dataKey="eventSize"
        range={[100, 300]}
      />
      { props.showTime &&
        <Tooltip
          labelFormatter={(label) => moment(label).format('dddd, MMMM Do YYYY, hh:mm:ss A')}
          formatter={(value) => value.toFixed(1)}
        />
      }
      { !props.showTime &&
        <Tooltip content={<CustomTooltip />} />
      }
      <CartesianGrid strokeDasharray="5 5"/>
      <Scatter name="Event" dataKey="event" fill="#ffc107" shape="star"/>
      <Line name="Social Sentiment" type="monotone" dataKey={props.ratingKey} stroke="#007bff"/>
      <Line name="Social Sentiment Moving Avg" type="monotone" dataKey={props.movingAverageKey} stroke="#00802b"/>
    </ComposedChart>
  </ResponsiveContainer>
);

export default LCXRatingGraph;
