import { connect } from 'react-redux';
import Objectives from '../../components/objectives/Objectives';

const mapStateToProps = (state) => ({
  user: state.user,
  myCategoryBrands: state.myCategoryBrands,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Objectives);
