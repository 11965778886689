import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import moment from 'moment';

const LCXVolumeGraph = (props) => (
  <ResponsiveContainer width="99%" height="100%" minWidth={0} minHeight={0}>
    <BarChart data={props.volumeData}>
      <XAxis dataKey={props.dateKey} tick={false} height={1}/>
      <YAxis orientation="right" axisLine={false}/>
      <Tooltip
        cursor={false}
        labelFormatter={(label) => moment(label).format('dddd, MMMM Do YYYY')}
      />

      <Bar name="Volume" dataKey={props.volumeKey} fill="#bfbfbf"/>
    </BarChart>
  </ResponsiveContainer>
);

export default LCXVolumeGraph;
