import React from 'react';
import axios from 'axios';
import moment from 'moment';
import GeneralSummaryGraph from '../graphs/GeneralSummaryGraph';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class BlogSummaryGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blogSummaryData: [],
      blogTotalCompanyBlogsScoreType: undefined,
      blogSummaryDataLoading: false,
      categoryAverageData: [],
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getBlogSummaryData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.brand !== this.props.brand ||
      prevProps.allMonthsChecked !== this.props.allMonthsChecked
    ) {
      this.getBlogSummaryData();
    }
  }

  getBlogSummaryData = () => {
    if (this.props.brand) {
      this.setState(() => ({ blogSummaryDataLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/product-brands/${this.props.brand.id}/blog-summary${allMonthsStartDateParam ? `?start_date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let blogSummaryData = response.data.monthly_stats;
        const blogTotalCompanyBlogsScoreType = response.data.total_company_blogs ? response.data.total_company_blogs.score_type : undefined;
        // merge category average data into original data source
        blogSummaryData = blogSummaryData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAverageData[i]))
        });
        if (this.state.isMounted) {
          this.setState(() => ({
            blogSummaryData,
            blogTotalCompanyBlogsScoreType,
            blogSummaryDataLoading: false
          }));
        }
      }).catch(error => {
        console.log('Error: failed to fetch blog summary data');
        if (this.state.isMounted) {
          this.setState(() => ({
            blogSummaryData: [],
            blogTotalCompanyBlogsScoreType: undefined,
            blogSummaryDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=pb_avg_blogs${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAverageData = response.data.category_stats;
          // merge category average data into original data source
          const blogSummaryData = this.state.blogSummaryData.map((x, i) => {
            return (Object.assign({}, x, categoryAverageData[i]))
          });
          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAverageData,
              blogSummaryData
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average blog data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAverageData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  }

  render () {
    return (
      <div>
        <GeneralSummaryGraph
          title="Number of Product Brand Blog Posts"
          metricName="web_company_blog_count"
          data={this.state.blogSummaryData}
          dataKey="total_company_blogs"
          dataLabel="Blog Posts per Month"
          scoreType={this.state.blogTotalCompanyBlogsScoreType}
          loading={this.state.blogSummaryDataLoading}
          allowDecimals={false}
          showPercentChange={true}
          showAverageData={true}
          averageDataKey="pb_avg_blogs"
          averageDataLabel="Category Average"
          showTooltip={true}
          contextType="brand"
          contextCategory={this.props.category}
          contextBrand={this.props.brand}
          contextChannel="Blogs"
          contextChartName="Number of Product Brand Blog Posts"
        />
      </div>
    );
  }
};
