import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import SentimentSnapshot from './SentimentSnapshot';
import SentimentRange from './SentimentRange';
import LCXRatingGraph from './LCXRatingGraph';
import TotalVolume from './TotalVolume';
import LCXVolumeGraph from './LCXVolumeGraph';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class LCXCustomGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lcxCloseData: []
    };
  }

  componentDidMount() {
    this.loadCompanyLcxData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.customerId !== this.props.customerId ||
      prevProps.topicId !== this.props.topicId ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter
    ) {
      this.loadCompanyLcxData();
    }
  }

  loadCompanyLcxData = () => {
    if (this.props.topicId && this.props.startDateFilter && this.props.endDateFilter) {
      const startDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
      const endDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
      const companyLcxDataPromises = [];
      companyLcxDataPromises.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/topics/${this.props.topicId}/lcx-closes?start-date=${startDate}&end-date=${endDate}&include_latest_rating=true`,
          HEADERS
        ).then(response => {
          const lcxCloseData = response.data;
          return { lcxCloseData };
          this.setState(() => ({ lcxCloseData }));
        }).catch(error => {
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        })
      );

      // get private events
      companyLcxDataPromises.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/events-curated?customer_id=${this.props.customerId}&product_brand_id=${this.props.brandId}&is_public=false&start_date=${startDate}&end_date=${endDate}`,
          HEADERS
        ).then(response => {
          const privateEventsData = response.data;
          return { privateEventsData };
        }).catch(error => {
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        })
      );

      Promise.all(companyLcxDataPromises).then(responses => {
        let lcxCloseData = [];
        let eventsData = [];
        for (const response of responses) {
          if (response.lcxCloseData) {
            lcxCloseData = response.lcxCloseData;
          } else if (response.publicEventsData) {
            eventsData = eventsData.concat(response.publicEventsData);
          } else if (response.privateEventsData) {
            eventsData = eventsData.concat(response.privateEventsData);
          }
        }

        // merge events data into lcx close data
        for (const event of eventsData) {
          const eventDate = moment(event.event_created_on).format('YYYY-MM-DD');
          for (const lcxClose of lcxCloseData) {
            if (eventDate === moment(lcxClose.close_date).format('YYYY-MM-DD')) {
              // Determine where to place event
              if (lcxClose.daily_lcx_rating > lcxClose.thirty_day_lcx_rating) {
                if (lcxClose.daily_lcx_rating < 85) {
                  lcxClose.event = Math.round(lcxClose.daily_lcx_rating + 10);
                } else {
                  lcxClose.event = Math.round(lcxClose.thirty_day_lcx_rating - 10)
                }
              } else {
                if (lcxClose.thirty_day_lcx_rating < 85) {
                  lcxClose.event = Math.round(lcxClose.thirty_day_lcx_rating + 10);
                } else {
                  lcxClose.event = Math.round(lcxClose.daily_lcx_rating - 10);
                }
              }
              lcxClose.eventSummary = event.summary;
              lcxClose.eventSize = 300;
            }
          }
        }
        this.setState(() => ({ lcxCloseData }));
      });
    }
  };

  render () {
    return (
      <div>
        <div>
          <Row className="mb-2" style={{ fontSize: '.875rem' }}>
            <Col>
              <SentimentSnapshot
                topicId={this.props.topicId}
              />
            </Col>
            <Col>
              <Row>
                <Col>
                  <SentimentRange
                    ratingKey="daily_lcx_rating"
                    ratingData={this.state.lcxCloseData}
                  />
                </Col>
                <Col>
                  <TotalVolume
                    totalDays={7}
                    topicId={this.props.topicId}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <div style={{ height: '350px' }}>
            <LCXRatingGraph
              dateKey="close_date"
              ratingKey="daily_lcx_rating"
              movingAverageKey="thirty_day_lcx_rating"
              ratingData={this.state.lcxCloseData}
            />
          </div>
        </div>
        <div style={{ height: '100px' }}>
          <LCXVolumeGraph
            dateKey="close_date"
            volumeKey="daily_volume"
            volumeData={this.state.lcxCloseData.slice(0, -1)}
          />
        </div>
      </div>
    );
  }
};
