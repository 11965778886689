import React from 'react';
import axios from 'axios';
import CategoryInstagramSummary from './CategoryInstagramSummary';
import CategoryInstagramFollowerShareOfGraphs from './CategoryInstagramFollowerShareOfGraphs';
import CategoryInstagramTopPosts from './CategoryInstagramTopPosts';
import ShareOfChart from '../../graphs/ShareOfChart';
import { round } from '../../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class CategoryDisplayAds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryInstagramShareOfData: [],
      ownedPostsShareOfGraphData: {},
      categoryOwnedPostsShareOfData: [],
      promoterShareOfGraphData: {},
      categoryPromoterSentimentShareOfData: [],
      detractorShareOfGraphData: {},
      categoryDetractorSentimentShareOfData: [],
      categoryInstagramShareOfDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchInstagramData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevProps.category !== this.props.category
    ) {
      this.fetchInstagramData();
    }
  };

  fetchInstagramData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryInstagramShareOfDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-instagram-brand-sentiments?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const categoryInstagramData = response.data;
        const ownedPostsShareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const promoterShareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const detractorShareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const categoryInstagramShareOfData = [];
        const categoryOwnedPostsShareOfData = [];
        const categoryPromoterSentimentShareOfData = [];
        const categoryDetractorSentimentShareOfData = [];
        for (const monthStats of categoryInstagramData) {
          const igMonthData = { month: monthStats.month };
          const ownedPostMonthData = { month: monthStats.month };
          const promoterMonthData = { month: monthStats.month };
          const detractorMonthData = { month: monthStats.month };
          let monthOwnedPostTotalCount = 0;
          let monthPromoterCount = 0;
          let monthDetractorCount = 0;

          // get totals for percent
          for (const pb of monthStats.product_brands) {
            monthOwnedPostTotalCount += pb.total_instagram_posts;
            monthPromoterCount += pb.promoter_count;
            monthDetractorCount += pb.detractor_count;
            // insert graph data
            ownedPostsShareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.total_instagram_posts,
            ]);
            promoterShareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.promoter_count,
            ]);
            detractorShareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.detractor_count,
            ]);
          }

          // loop again to determine %
          for (const pb of monthStats.product_brands) {
            igMonthData[pb.product_brand_name] = pb;

            let ownedPostPercent = 0;
            if (monthOwnedPostTotalCount > 0) {
              ownedPostPercent = round((pb.total_instagram_posts/monthOwnedPostTotalCount)*100, 1);
            }
            ownedPostMonthData[pb.product_brand_name] = {
              count: pb.total_instagram_posts,
              percent: ownedPostPercent
            }

            let promoterPercent = 0;
            if (monthPromoterCount > 0) {
              promoterPercent = round((pb.promoter_count/monthPromoterCount)*100, 1);
            }
            promoterMonthData[pb.product_brand_name] = {
              count: pb.promoter_count,
              percent: promoterPercent
            }

            let detractorPercent = 0;
            if (monthDetractorCount > 0) {
              detractorPercent = round((pb.detractor_count/monthDetractorCount)*100, 1);
            }
            detractorMonthData[pb.product_brand_name] = {
              count: pb.detractor_count,
              percent: detractorPercent
            }
          }
          categoryInstagramShareOfData.push(igMonthData);
          categoryOwnedPostsShareOfData.push(ownedPostMonthData);
          categoryPromoterSentimentShareOfData.push(promoterMonthData);
          categoryDetractorSentimentShareOfData.push(detractorMonthData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryInstagramShareOfData,
            ownedPostsShareOfGraphData,
            categoryOwnedPostsShareOfData,
            promoterShareOfGraphData,
            categoryPromoterSentimentShareOfData,
            detractorShareOfGraphData,
            categoryDetractorSentimentShareOfData,
            categoryInstagramShareOfDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch category share of instagram data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            categoryInstagramShareOfData: [],
            ownedPostsShareOfGraphData: {},
            categoryOwnedPostsShareOfData: [],
            promoterShareOfGraphData: {},
            categoryPromoterSentimentShareOfData: [],
            detractorShareOfGraphData: {},
            categoryDetractorSentimentShareOfData: [],
            categoryInstagramShareOfDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        <CategoryInstagramSummary
          user={this.props.user}
          sixMonths={this.props.sixMonths}
          selectedMonth={this.props.selectedMonth}
          updateSelectedMonth={this.props.updateSelectedMonth}
          category={this.props.category}
          categoryInstagramData={this.state.categoryInstagramShareOfData}
          categoryInstagramDataLoading={this.state.categoryInstagramShareOfDataLoading}
        />
        <div className="mt-5 mb-4 band-separator" />
        <ShareOfChart
          title="Share of Owned Posts"
          data={this.state.ownedPostsShareOfGraphData}
          legendData={this.state.categoryOwnedPostsShareOfData}
          loading={this.state.categoryInstagramShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="Instagram"
          contextChartName="Share of Owned Posts"
        />
        <ShareOfChart
          title="Share of Promoter Comments"
          data={this.state.promoterShareOfGraphData}
          legendData={this.state.categoryPromoterSentimentShareOfData}
          loading={this.state.categoryInstagramShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="Instagram"
          contextChartName="Share of Promoter Comments"
        />
        <ShareOfChart
          title="Share of Detractor Comments"
          data={this.state.detractorShareOfGraphData}
          legendData={this.state.categoryDetractorSentimentShareOfData}
          loading={this.state.categoryInstagramShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="Instagram"
          contextChartName="Share of Detractor Comments"
        />
        <CategoryInstagramFollowerShareOfGraphs
          category={this.props.category}
        />
        <CategoryInstagramTopPosts
          category={this.props.category}
        />
      </div>
    );
  }
};
