import moment from 'moment';

export default function endDateFilterReducer(state = moment().utc().endOf('day'), action) {
  switch (action.type) {
    case 'UPDATE_END_DATE_FILTER':
      return action.endDate;
    default:
      return state
  }
};
