import React from 'react';
import axios from 'axios';
import ShareOfChart from '../graphs/ShareOfChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortAlphabeticalByKey } from '../../utils/sorts';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryShareOfEverything extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryShareOfData: [],
      categoryShareOfDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchShareOfEverythingData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      this.fetchShareOfEverythingData();
    }
  };

  fetchShareOfEverythingData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryShareOfDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-share-of-metrics?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const categoryShareOfData = response.data;
        const shareOfEverythingGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const shareOfEarnedGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const shareOfOwnedGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const shareOfPaidGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        // sort brands alphabetically
        for (const monthStats of categoryShareOfData) {
          monthStats.product_brands.sort(sortAlphabeticalByKey('product_brand_name'));
        };

        const categoryShareOfEverythingData = [];
        const categoryShareOfEarnedData = [];
        const categoryShareOfOwnedData = [];
        const categoryShareOfPaidData = [];
        for (const monthStats of categoryShareOfData) {
          const everythingMonthData = { month: monthStats.month };
          const earnedMonthData = { month: monthStats.month };
          const ownedMonthData = { month: monthStats.month };
          const paidMonthData = { month: monthStats.month };

          for (const pb of monthStats.product_brands) {
            everythingMonthData[pb.product_brand_name] = {
              count: pb.share_of_everything || 0,
              percent: pb.share_of_everything || 0
            };
            earnedMonthData[pb.product_brand_name] = {
              count: pb.share_of_earned || 0,
              percent: pb.share_of_earned || 0
            };
            ownedMonthData[pb.product_brand_name] = {
              count: pb.share_of_owned || 0,
              percent: pb.share_of_owned|| 0
            };
            paidMonthData[pb.product_brand_name] = {
              count: pb.share_of_paid || 0,
              percent: pb.share_of_paid || 0
            };

            // insert graph data
            shareOfEverythingGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.share_of_everything || 0,
            ]);
            shareOfEarnedGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.share_of_earned || 0,
            ]);
            shareOfOwnedGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.share_of_owned || 0,
            ]);
            shareOfPaidGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.share_of_paid || 0,
            ]);
          }

          categoryShareOfEverythingData.push(everythingMonthData);
          categoryShareOfEarnedData.push(earnedMonthData);
          categoryShareOfOwnedData.push(ownedMonthData);
          categoryShareOfPaidData.push(paidMonthData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryShareOfData,
            categoryShareOfDataLoading: false,
            shareOfEverythingGraphData,
            shareOfEarnedGraphData,
            shareOfOwnedGraphData,
            shareOfPaidGraphData,
            categoryShareOfEverythingData,
            categoryShareOfEarnedData,
            categoryShareOfOwnedData,
            categoryShareOfPaidData,
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch share of everything graph data.');
        if (this.state.isMounted) {
          this.setState(() => ({
            categoryShareOfData: [],
            categoryShareOfDataLoading: false,
            shareOfEverythingGraphData: {},
            shareOfEarnedGraphData: {},
            shareOfOwnedGraphData: {},
            shareOfPaidGraphData: {},
            categoryShareOfEverythingData: [],
            categoryShareOfEarnedData: [],
            categoryShareOfOwnedData: [],
            categoryShareOfPaidData: [],
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        <ShareOfChart
          title="Share of Everything"
          data={this.state.shareOfEverythingGraphData}
          legendData={this.state.categoryShareOfEverythingData}
          loading={this.state.categoryShareOfDataLoading}
          precentDisplayOnly={true}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Analysis"
          contextProperty="Share Of"
          contextChartName="Share of Everything"
          shareClassName="category-share-of-everything-img"
        />
        <ShareOfChart
          title="Share of Earned"
          data={this.state.shareOfEarnedGraphData}
          legendData={this.state.categoryShareOfEarnedData}
          loading={this.state.categoryShareOfDataLoading}
          precentDisplayOnly={true}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Analysis"
          contextProperty="Share Of"
          contextChartName="Share of Earned"
          shareClassName="category-share-of-earned-img"
        />
        <ShareOfChart
          title="Share of Owned"
          data={this.state.shareOfOwnedGraphData}
          legendData={this.state.categoryShareOfOwnedData}
          loading={this.state.categoryShareOfDataLoading}
          precentDisplayOnly={true}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Analysis"
          contextProperty="Share Of"
          contextChartName="Share of Owned"
          shareClassName="category-share-of-owned-img"
        />
        <ShareOfChart
          title="Share of Paid"
          data={this.state.shareOfPaidGraphData}
          legendData={this.state.categoryShareOfPaidData}
          loading={this.state.categoryShareOfDataLoading}
          precentDisplayOnly={true}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Analysis"
          contextProperty="Share Of"
          contextChartName="Share of Paid"
          shareClassName="category-share-of-paid-img"
        />
      </div>
    );
  }
};
