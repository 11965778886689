import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import LoginContainer from '../../containers/common/LoginContainer';
import history from '../../routers/history';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentEmail: '',
      newEmail: '',
      password: '',
      errors: {},
      errorMessage: ''
    };
  }

  componentDidMount() {
    if (this.props.user.email) {
      this.setState(() => ({
        isMounted: true,
        currentEmail: this.props.user.email
      }));
    } else {
      this.setState(() => ({ isMounted: true }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.id !== this.props.user.id) {
      this.setState(() => ({
        currentEmail: this.props.user.email || ''
      }));
    }
  }

  onNewEmailChange = (event) => {
    const newEmail = event.currentTarget.value;
    this.setState(() => ({ newEmail }));
  };

  onPasswordChange = (event) => {
    const password = event.currentTarget.value;
    this.setState(() => ({ password }));
  };

  validRequest = () => {
    // validate
    const errors = {};
    let errorMessage = '';
    if (!this.state.newEmail || this.state.newEmail.trim() === '') {
      errors.newEmail = true;
    }
    if (!this.state.password || this.state.password.trim() === '') {
      errors.password = true;
    }
    const isValidEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.newEmail);
    if (!isValidEmail) {
      errors.newEmail = true;
      errorMessage = 'New email is not valid.'
    }

    this.setState(() => ({
      errors,
      errorMessage
    }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  changeEmail = () => {
    if (this.validRequest()) {
      const changeEmailRequest = {
        id: this.props.user.id,
        new_email: this.state.newEmail,
        password: this.state.password
      };

      axios.post(
        `${LISTEN_ENDPOINT}/api/customer-users/change-email`,
        changeEmailRequest,
        HEADERS
      ).then(response => {
        const user = response.data;
        console.log(user);
        this.props.updateUserAndCookie(user);
        console.log('past update');
        history.push('/user-settings');
      }).catch(error => {
        if (this.state.isMounted) {
          if (error && error.response && error.response.data && error.response.data.message) {
            this.setState(() => ({
              errorMessage: error.response.data.message
            }));
          } else {
            this.setState(() => ({
              errorMessage: 'Failed to update email, try again later'
            }));
          }
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };


  render () {
    return (
      <div className="m-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        { (this.props.user && this.props.user.id) &&
          <Row>
            <Col>
              <Card className="mx-auto w-50">
                <Card.Header>
                  Change Email
                </Card.Header>
                <Card.Body>
                  <Form>
                    <Form.Group>
                      <Form.Label>Current Email</Form.Label>
                      <Form.Control
                        type="email"
                        value={this.state.currentEmail}
                        disabled={true}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>New Email</Form.Label>
                      <Form.Control
                        type="email"
                        onChange={this.onNewEmailChange}
                        isInvalid={this.state.errors.newEmail}
                      />
                      <Form.Control.Feedback type="invalid">
                        Required
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        onChange={this.onPasswordChange}
                        isInvalid={this.state.errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        Required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                  <Alert show={Boolean(this.state.errorMessage)} variant="danger">
                    {this.state.errorMessage}
                  </Alert>
                  <Button variant="success" onClick={this.changeEmail}>
                    Change Email
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        }
      </div>
    );
  }
};
