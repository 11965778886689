import React from 'react';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { numberWithCommas } from '../../utils/numbers';

export default class SearchAdDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAdId: undefined,
      selectedAd: undefined
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    let selectedAdId;
    let selectedAd;
    if (this.props.ads && this.props.ads.length > 0) {
      selectedAdId = this.props.ads[0].id;
      selectedAd = this.props.ads[0];
    }
    this.setState(() => ({
      selectedAdId,
      selectedAd
    }));
  };

  componentDidUpdate(prevProps) {
    if (prevProps.ads !== this.props.ads) {
      if (this.props.ads.length > 0) {
        let selectedAdId;
        let selectedAd;
        if (this.props.ads && this.props.ads.length > 0) {
          selectedAdId = this.props.ads[0].id;
          selectedAd = this.props.ads[0];
        }
        this.setState(() => ({
          selectedAdId,
          selectedAd
        }));
      } else {
        this.setState(() => ({
          selectedAdId: undefined,
          selectedAd: undefined
        }));
      }
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  onAdSelect = (selectedAd) => {
    if (selectedAd.id !== this.state.selectedAd.id) {
      this.setState(() => ({
        selectedAdId: selectedAd.id,
        selectedAd
      }));
    }
  };

  render () {
    return (
      <div>
        <Row>
          <Col xs={4}>
            <ButtonGroup className="rounded border">
              <Button
                variant="info"
                size="sm"
              >Date</Button>
            </ButtonGroup>
            <div className="border rounded bg-white">
              <div
                className="pre-scrollable"
                style={{ minHeight: '600px', maxHeight: '600px' }}
              >
                { this.props.ads.map(ad => {
                    return (
                      <div
                        key={`ad-${ad.id}`}
                        className={
                          this.state.selectedAdId === ad.id?
                            'mb-1 px-2 bg-primary text-light rounded' :
                            'mb-1 px-2'
                        }
                        style={{
                          fontSize: '.875rem',
                          cursor: 'pointer'
                        }}
                        onClick={() => this.onAdSelect(ad)}
                      >
                        <div
                          style={{ fontWeight: 'bold' }}
                        >
                          {ad.title}
                        </div>
                        <div>{moment(ad.query_date).format('MM/DD/YY')}</div>
                        <hr className="m-0"/>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </Col>
          <Col xs={8}>
            <div className="container px-0" style={{ marginTop: '34px' }}>
              { this.state.selectedAd &&
                <Card>
                  <Card.Header
                    className="bg-white"
                    style={{ fontWeight: 'bold' }}
                  >
                    { this.state.selectedAd.link &&
                      <div>
                        <a href={this.state.selectedAd.link} target="_blank" rel="noreferrer">
                          {this.state.selectedAd.title}
                        </a>
                      </div>
                    }
                    { !this.state.selectedAd.link &&
                      <div>
                        {this.state.selectedAd.title}
                      </div>
                    }
                  </Card.Header>
                  <Card.Body>
                    <React.Fragment>
                      <div>
                        {this.state.selectedAd.description}
                      </div>
                      <div className="mt-4">
                        {`Published: ${moment(this.state.selectedAd.query_date).format('MM/DD/YY')}`}
                      </div>
                    </React.Fragment>
                  </Card.Body>
                </Card>
              }
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};
