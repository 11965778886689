import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { numberWithCommas } from '../../utils/numbers';

export default class NewsResultsHistogram extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render () {
    return (
      <div>
        <div className= "pb-2">
          <strong>Articles per Week</strong>
        </div>
        <ResponsiveContainer height={110} width="90%">
          <BarChart data={this.props.newsHistogramData}>
            <XAxis
              dataKey="key_as_string"
              tick={false}
            />
            <YAxis
              dataKey="doc_count"
              tickFormatter={(doc_count) => {
                return numberWithCommas(doc_count);
              }}
            />
            <Tooltip
              labelFormatter={(label) => "Week of " + moment(label).format('dddd, MMMM Do YYYY')}
            />
            <Bar name="Articles per Week" dataKey="doc_count" fill="#007bff"/>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
};
