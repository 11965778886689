import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class SentimentSnapshot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      socialSentiment: 0,
      todaysChange: 0,
      todaysChangePercent: 0.0
    };
  }

  componentDidMount() {
    this.loadCompanyLcxData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.topicId !== this.props.topicId) {
      this.loadCompanyLcxData();
    }
  }

  loadCompanyLcxData = () => {
    if (this.props.topicId) {
      const closeEndDate = moment.utc().startOf('day');
      const closeStartDate = moment.utc().startOf('day').subtract(2, 'days');
      axios.get(
        `${LISTEN_ENDPOINT}/api/topics/${this.props.topicId}/lcx-closes?start-date=${closeStartDate.toISOString()}&end-date=${closeEndDate.toISOString()}&include_latest_rating=true`,
        HEADERS
      ).then(response => {
        const lastScoreObj = response.data.slice(-1)[0];
        const lastCloseObj = response.data.slice(-2)[0];
        let lastRating = 0;
        let socialSentiment = 0;
        let todaysChange = 0;
        let todaysChangePercent = 0.0;
        if (lastScoreObj && lastCloseObj) {
          lastRating = lastScoreObj.daily_lcx_rating;
          socialSentiment = lastCloseObj.thirty_day_lcx_rating;
          todaysChange = lastRating - socialSentiment;
          todaysChangePercent = (todaysChange/Math.abs(socialSentiment)) * 100;
        }
        this.setState(() => ({
          socialSentiment,
          todaysChange,
          todaysChangePercent
         }));
      }).catch(error => {
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <Row className="ml-0 rounded border bg-light">
        <Col>
          <div>Social Sentiment</div>
          <div><b>{this.state.socialSentiment.toFixed(1)}</b></div>
        </Col>
        <Col>
          <div>Today's Change</div>
          <div><b>{`${numberWithCommas(this.state.todaysChange.toFixed(1))} (${numberWithCommas(this.state.todaysChangePercent.toFixed(1))}%)`}</b></div>
        </Col>
      </Row>
    );
  }
};
