import { connect } from 'react-redux';
import SurveysCollector from '../../../components/settings/contentCollectors/SurveysCollector';

const mapStateToProps = (state) => ({
  user: state.user,
  categories: state.categories
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SurveysCollector);
