import React from 'react';
import axios from 'axios';
import moment from 'moment';
import queryString from 'query-string';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import ClipLoader from 'react-spinners/ClipLoader';
import LoginContainer from '../../../containers/common/LoginContainer';
import AdminAuthenticationMessage from '../../common/AdminAuthenticationMessage';
import { isAuthenticatedAdmin } from '../../../utils/auth';
import EmailSubscriptionForm from './EmailSubscriptionForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  LISTEN_ENDPOINT,
  PROXY_EMAIL_ENDPOINT,
  HEADERS
} from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';
import history from '../../../routers/history';

export default class EmailSubscriptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      proxyEmails: [],
      selectedCategoryId: undefined,
      brands: [],
      selectedBrandId: undefined,
      emailSubscriptions: [],
      emailSubscriptionsLoading: false,
      emailsReceived: 0,
      createEmailSubscriptionFormIds: [],
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchProxyEmails();
    if (this.props.categories.length > 0) {
      this.onCategoryChange({
        currentTarget: {
          value: `${this.props.categories[0].category_type}-${this.props.categories[0].id}`
        }
      });
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.user.id !== this.props.user.id ||
      prevProps.user.customerId !== this.props.user.customerId
    ) {
      this.fetchProxyEmails();
    }
    if (prevProps.categories !== this.props.categories) {
      if (this.props.categories.length > 0) {
        this.onCategoryChange({
          currentTarget: {
            value: `${this.props.categories[0].category_type}-${this.props.categories[0].id}`
          }
        });
      } else {
        this.setState(() => ({
          selectedCategoryId: undefined,
          brands: [],
          selectedBrandId: undefined
        }));
      }
    }
  }

  fetchProxyEmails = () => {
    if (this.props.user.customerId) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/customer-user-proxy-emails?customer_id=${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        const proxyEmails = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({ proxyEmails }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch proxy emails');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchEmailSubscriptions = () => {
    if (this.props.user.customerId && this.state.selectedBrandId) {
      this.setState(() => ({ emailSubscriptionsLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/customer-user-proxy-email-subscription-detail?logged_customer_id=${this.props.user.customerId}&product_brand_id=${this.state.selectedBrandId}`,
        HEADERS
      ).then(response => {
        if (response.data.subscriptions) {
          const emailSubscriptions = response.data.subscriptions;
          const emailsReceived = response.data.emails_received;

          if (this.state.isMounted) {
            this.setState(() => ({
              emailSubscriptions,
              emailsReceived,
              emailSubscriptionsLoading: false
            }));
          }
        }
      }).catch(error => {
        console.error('Error: unable to fetch proxy email subscriptions');
        if (this.state.isMounted) {
          this.setState(() => ({
            emailSubscriptions: [],
            emailsReceived: 0,
            emailSubscriptionsLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onCategoryChange = (event) => {
    let selectedCategoryId;
    let brands = [];
    let selectedBrandId;
    // if query strings for category and/or brand, override
    const queryValues = queryString.parse(this.props.location.search);
    if (queryValues.category) {
      for (const category of this.props.categories) {
        if (queryValues.category === category.name) {
          selectedCategoryId = `${category.category_type}-${category.id}`;
          brands = category.product_brands;
          if (brands.length > 0) {
            if (queryValues.brand) {
              for (const brand of brands) {
                if (brand.name === queryValues.brand) {
                  selectedBrandId = brand.id;
                }
              }
              // did not find brand so default to first
              if (!selectedBrandId) {
                selectedBrandId = brands[0].id;
              }
            } else {
              selectedBrandId = brands[0].id;
            }
          }
        }
      }
    } else {
      selectedCategoryId = event.currentTarget.value;
      for (const category of this.props.categories) {
        if (selectedCategoryId === `${category.category_type}-${category.id}`) {
          brands = category.product_brands;
          if (brands.length > 0) {
            selectedBrandId = brands[0].id;
          }
        }
      }
    }

    // remove query strings as they have been processed for selection
    history.replace('/content-collectors/email');

    this.setState(() => ({
      selectedCategoryId,
      brands,
      selectedBrandId
    }), () => this.fetchEmailSubscriptions());
  };

  onSelectedBrandChange = (selectedBrand) => {
    const selectedBrandId = selectedBrand.id;
    if (this.state.selectedBrandId !== selectedBrandId) {
      this.setState(() => ({
        selectedBrandId
      }), () => this.fetchEmailSubscriptions());
    }
  };

  generateEmail = () => {
    if (this.props.user && this.props.user.customerId) {
      axios.post(
        `${PROXY_EMAIL_ENDPOINT}/generate_email`,
        {
          customer_id: this.props.user.customerId
        },
        HEADERS
      ).then(response => {
        const proxyEmail = response.data;
        this.fetchProxyEmails();
      }).catch(error => {
        console.error('Error: unable to generate proxy email');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  addEmailSubscriptionForm = () => {
    const id = Math.random().toString(36).substring(7);
    this.setState((prevState) => ({
      createEmailSubscriptionFormIds: [...prevState.createEmailSubscriptionFormIds, id]
    }));
  };

  removeEmailSubscriptionForm = (id) => {
    this.setState((prevState) => ({
      createEmailSubscriptionFormIds: prevState.createEmailSubscriptionFormIds.filter(cesfi => cesfi !== id)
    }));
  };

  render () {
    return (
      <div className="m-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        <AdminAuthenticationMessage user={this.props.user}/>
        { isAuthenticatedAdmin(this.props.user) &&
          <div>
            <div
              className="border rounded p-4"
              style={{ backgroundColor: '#e9ecef' }}
            >
              <div className="d-inline-block pr-2">
                <FontAwesomeIcon icon={faEnvelope} size="2x" color="#454d54" />
              </div>
              <div className="d-inline-block">
                <h4>Email Subscriptions</h4>
              </div>
              <p>Avoid filling up your inbox. Create a proxy id for email collections.</p>
              <hr className="my-4" />
              <p>Return emails are shared with the community.</p>
            </div>
            <div className="mt-4">
              <div>
                <Button
                  className="mt-2"
                  variant="success"
                  onClick={this.generateEmail}
                >
                  Get Proxy Email
                </Button>
              </div>
              { this.state.proxyEmails.length > 0 &&
                <Row>
                  <Col className="border-right">
                    <div className="my-2">
                      <b>Your Proxy Addresses</b>
                    </div>
                    <ListGroup
                      as="ul"
                      className="pre-scrollable"
                      style={{
                        height: 'calc(100vh - 500px)',
                        fontSize: '.875rem',
                        overflowX: 'hidden',
                        overflowY: 'auto'
                      }}
                    >
                      { this.state.proxyEmails.map(proxyEmail => {
                          return (
                            <ListGroup.Item
                              key={`pe${proxyEmail.id}`}
                              as="li"
                            >
                              <div>
                                <Form.Row>
                                  <Col>
                                    {proxyEmail.email}
                                  </Col>
                                  <Col>
                                    {proxyEmail.type}
                                  </Col>
                                </Form.Row>
                              </div>
                            </ListGroup.Item>
                          )
                        })
                      }
                    </ListGroup>
                  </Col>
                  <Col>
                    <Form.Control
                      as="select"
                      value={this.state.selectedCategoryId}
                      onChange={this.onCategoryChange}
                      style={{
                        minWidth: '200px',
                        maxWidth: '300px'
                      }}
                    >
                      { this.props.categories.map(category => {
                          return (
                            <option
                              key={`${category.category_type}-${category.id}`}
                              value={`${category.category_type}-${category.id}`}
                            >
                              {category.name}
                            </option>
                          )
                        })
                      }
                    </Form.Control>
                    <Row>
                      <Col>
                        <div>
                          <div className="my-2">
                            <b>Brands in Category</b>
                          </div>
                          <div className="border rounded bg-white">
                            <div className="pre-scrollable" style={{ height: '192px' }}>
                              { this.state.brands.map(brand => {
                                  return (
                                    <div
                                      key={`b${brand.id}`}
                                      className={brand.id === this.state.selectedBrandId ? 'bg-primary text-light rounded px-2' : 'px-2'}
                                      onClick={() => this.onSelectedBrandChange(brand)}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      {brand.name}
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div>
                          { this.state.emailSubscriptionsLoading &&
                            <div className="mt-4 text-center">
                              <ClipLoader size={50}/>
                            </div>
                          }
                          { (!this.state.emailSubscriptionsLoading && this.state.selectedBrandId) &&
                            <div>
                              <div className="my-2">
                                <b>Proxy Email Subscriptions</b>
                                <Button
                                  className="float-right py-0 px-1"
                                  variant="success"
                                  onClick={this.addEmailSubscriptionForm}
                                  size="sm"
                                >+</Button>
                              </div>
                              <div className="border rounded bg-white">
                                <div className="pre-scrollable" style={{ height: '192px' }}>
                                  { this.state.createEmailSubscriptionFormIds.map((id, i) => {
                                      return (
                                        <EmailSubscriptionForm
                                          key={`esf-${i}`}
                                          id={id}
                                          productBrandId={this.state.selectedBrandId}
                                          proxyEmails={this.state.proxyEmails}
                                          emailSubscriptions={this.state.emailSubscriptions}
                                          removeEmailSubscriptionForm={this.removeEmailSubscriptionForm}
                                          refreshEmailSubscriptions={this.fetchEmailSubscriptions}
                                        />
                                      )
                                    })
                                  }
                                  { this.state.emailSubscriptions.map((emailSubscription, i) => {
                                      return (
                                        <div
                                          key={`ems-${i}`}
                                          className="px-2"
                                          style={{ float: 'none', clear: 'both' }}
                                        >
                                          <div
                                            style={{
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis'
                                            }}
                                          >
                                            {emailSubscription.email}
                                          </div>
                                          <div
                                            className="text-muted"
                                            style={{ fontSize: '.75rem' }}
                                          >
                                            {moment(emailSubscription.subscription_date).format('MM/DD/YYYY')}
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                              <div className="mt-2 text-muted">
                                {`Emails Received: ${this.state.emailsReceived}`}
                              </div>
                            </div>
                          }
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
            </div>
          </div>
        }
      </div>
    );
  }
};
