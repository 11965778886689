import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CommentReviewScores from './CommentReviewScores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

export default class FacebookPost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render () {
    return (
      <div className="mb-2 bg-light border rounded" style={{ fontSize: '.875rem' }}>
        <Row noGutters>
          <Col md={3} sm={12} className="px-4 py-2 border-right">
            <Row>
              <Col>
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faFacebookSquare}
                  color="#3b5998"
                  size="lg"
                />
                {new Date(this.props.comment.facebook_post.posted_at).toLocaleString()}
                <FontAwesomeIcon
                  className="float-right"
                  icon={faLink}
                  size="lg"
                  onClick={() => {
                    window.open(`${this.props.comment.facebook_post.facebook_url}`, '_blank');
                  }}
                  style={{
                    cursor: 'pointer',
                    color: '#3b5998'
                  }}
                />
              </Col>
            </Row>
            <div style={{ wordBreak: 'break-all' }}>
              {this.props.comment.facebook_post.author}
            </div>
          </Col>
          <Col md={9} sm={12} className="px-4 py-2">
            <div>
              <div
                className="d-inline"
                dangerouslySetInnerHTML={{__html: this.props.comment.facebook_post.text}}
              />
              <div className="mt-2">
                <CommentReviewScores
                  comment={this.props.comment}
                  refreshComments={this.props.refreshComments}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};
