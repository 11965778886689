import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import ClipLoader from 'react-spinners/ClipLoader';
import { numberWithCommas } from '../../utils/numbers';
import { sortAlphabeticalByKey } from '../../utils/sorts';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class PageKeywordRanks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageKeywordRanksData: [],
      pageKeywordRanksDataLoading: false,
      selectedPageId: undefined,
      selectedPage: undefined,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchPageKeywordRanksData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.brand !== this.props.brand) {
      this.setState(() => ({
        selectedPageId: undefined,
        selectedPage: undefined,
      }), () => this.fetchPageKeywordRanksData());
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchPageKeywordRanksData = () => {
    if (this.props.category && this.props.brand) {
      this.setState(() => ({ pageKeywordRanksDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/web-sublink-keyword-serp-rank?product_brand_id=${this.props.brand.id}&category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const pageKeywordRanksData = response.data;
        for (const page of pageKeywordRanksData) {
          page.keywords_count = 0;
          for (const keyword of page.keywords) {
            if (keyword.google_rank) {
              page.keywords_count += 1;
            }
          }
          // sort by keyword to gather same terms together
          page.keywords.sort(sortAlphabeticalByKey('query_term'));
        }
        pageKeywordRanksData.sort((a,b) => b.keywords_count - a.keywords_count);
        let selectedPageId, selectedPage
        if (!this.state.selectedPageId) {
          selectedPageId = pageKeywordRanksData[0].source_id;
          selectedPage = pageKeywordRanksData[0];
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            pageKeywordRanksData,
            pageKeywordRanksDataLoading: false,
            selectedPageId,
            selectedPage,
          }))
        }
      }).catch(error => {
        console.error('Error: failed to fetch page keyword rank attributes...');
        if (this.state.isMounted) {
          this.setState(() => ({
            pageKeywordRanksData: [],
            pageKeywordRanksDataLoading: false,
            selectedPageId: undefined,
            selectedPage: undefined,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onPageSelect = (selectedPage) => {
    this.setState(() => ({
      selectedPageId: selectedPage.source_id,
      selectedPage
    }));
  };

  render () {
    return (
      <div>
        { this.state.pageKeywordRanksDataLoading &&
          <div className="text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.pageKeywordRanksDataLoading &&
          <Row>
            <Col xs={6}>
              <div className="my-1" style={{ fontWeight: 'bold' }}>
                Web pages with Ranking Keywords
              </div>
              <div
                className="pre-scrollable bg-white border rounded"
                style={{
                  minHeight: 'calc(100vh - 235px)',
                  maxHeight: 'calc(100vh - 235px)',
                  overflow: 'auto'
                }}
              >
                { this.state.pageKeywordRanksData.map((page, i) => {
                    let path;
                    try {
                      path = new URL(page.url).pathname;
                    } catch (e) {
                      path = page.url;
                    }
                    return (
                      <Row
                        key={`pkr-${page.source_id}-${i}`}
                        className={page.source_id === this.state.selectedPageId ? 'bg-primary text-light rounded' : 'border-bottom'}
                        onClick={() => this.onPageSelect(page)}
                        style={{ cursor: 'pointer' }}
                        noGutters
                      >
                        <Col xs={1}>
                          <div className="text-center border-right">
                            {numberWithCommas(page.keywords_count)}
                          </div>
                        </Col>
                        <Col xs={11}>
                          <div className="px-2 ellipsis">
                            {path}
                          </div>
                        </Col>
                      </Row>
                    )
                  })
                }
              </div>
            </Col>
            <Col xs={6}>
              { this.state.selectedPage &&
                <Table striped bordered size="sm">
                  <thead className="bg-dark text-light">
                    <tr>
                      <th className="py-1">Embedded Keywords</th>
                      <th className="py-1 text-center">Keyword SERP Rank</th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.state.selectedPage.keywords.map((keyword, i) => {
                        return (
                          <tr key={`spk-${keyword.query_id}-${i}`}>
                            <td>
                              <div>
                                {keyword.query_term}
                                { keyword.query_location &&
                                  <span
                                    className="ml-2 text-muted"
                                    style={{ fontSize: '.75rem' }}
                                  >
                                    {`(${keyword.query_location})`}
                                  </span>
                                }
                              </div>
                            </td>
                            <td className="text-center">
                              {keyword.google_rank == null &&
                                <div>-</div>
                              }
                              {keyword.google_rank != null &&
                                <div>{keyword.google_rank}</div>
                              }
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
              }
            </Col>
          </Row>
        }
      </div>
    );
  }
};
