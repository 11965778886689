import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import ClipLoader from 'react-spinners/ClipLoader';
import BarChartRace from './BarChartRace';
import CategoryRaceChartSetupModal from './CategoryRaceChartSetupModal';
import { round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

import * as d3 from 'd3';
import { select } from "d3-selection";
import 'd3-transition';

export default class CategoryRaceCharts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dates: [],
      brandScoresData: [],
      loading: false,
      setupRaceChartModalOpen: false,
      canPlay: false
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.category && this.categoryRaceChartSetupModal) {
      this.categoryRaceChartSetupModal.createChart();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.category !== this.props.category) ||
      (prevProps.user.customerId !== this.props.user.customerId)
    ) {
      if (this.props.category && this.categoryRaceChartSetupModal) {
        this.categoryRaceChartSetupModal.createChart();
      }
    }
  };

  isValidDate = (dateType, date) => {
    if (dateType === 'daily') {
      return true;
    } else if (dateType === 'weekly' && date.day() === 6) {
      return true;
    } else if (dateType === 'monthly' && date.date() === date.daysInMonth()) {
      return true;
    } else {
      return false;
    }
  };

  fetchBrandScores = (startDate, endDate, dateType, chartType, scoreType, secondsToDelay, speed, showIcons, showBrandNames, showBrandScores) => {
    this.setState(() => ({ loading: true }));
    const formattedStartDate = moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');

    axios.get(
      `${LISTEN_ENDPOINT}/api/scores/brand-scores-for-category/range?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}&include_stage_scores=true`,
      HEADERS
    ).then(response => {
      let brandMetricsData = response.data;
      let brandScoresData = [];
      let dates = [];
      for (const brandMetric of brandMetricsData) {
        if (this.isValidDate(dateType, moment.utc(brandMetric.score_at))) {
          let date = moment.utc(brandMetric.score_at).format('MM-D-YYYY');
          dates.push(date);
          for (var prop in brandMetric) {
            if (Object.prototype.hasOwnProperty.call(brandMetric, prop)) {
              if (brandMetric[prop].product_brand_id) {
                let brand = this.props.category.product_brands.find(e => e.id == brandMetric[prop].product_brand_id);
                if (brand) {
                  // default to overall brand score
                  let score = Math.round(brandMetric[prop].brand_score);
                  if (scoreType == "Presence") {
                    brandMetric[prop].digital_presence_score = brandMetric[prop].digital_presence_score || 0;
                    score = Math.round(brandMetric[prop].digital_presence_score);
                  } else if (scoreType == "Attention") {
                    brandMetric[prop].audience_attention_score = brandMetric[prop].audience_attention_score || 0;
                    score = Math.round(brandMetric[prop].audience_attention_score);
                  } else if (scoreType == "Reputation") {
                    brandMetric[prop].brand_reputation_score = brandMetric[prop].brand_reputation_score || 0;
                    score = Math.round(brandMetric[prop].brand_reputation_score);
                  } else if (scoreType == "Momentum") {
                    brandMetric[prop].momentum_score = brandMetric[prop].momentum_score || 0;
                    score = Math.round(brandMetric[prop].momentum_score);
                  }
                  brandScoresData.push({
                    id: brand.id,
                    name: brand.name,
                    date: date,
                    value: score,
                    favIcon: brand.company_favicon_url,
                  });
                }
              }
            }
          }
        }
      }
      dates.sort(function(a, b){
        a = new Date(a);
        b = new Date(b);
        return b>a ? -1 : b<a ? 1 : 0;
      });
      this.createChartRace(dates, brandScoresData, chartType, dateType, scoreType, secondsToDelay, speed, showIcons, showBrandNames, showBrandScores);
      this.setState(() => ({
        dates,
        brandScoresData,
        loading: false
      }));
    }).catch(error => {
      console.error('Error: unable to fetch brand scoring for category');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    })
  };

  openSetupRaceChartModal = () => {
    this.setState(() => ({ setupRaceChartModalOpen: true }));
  };

  closeSetupRaceChartModal = () => {
    this.setState(() => ({
      setupRaceChartModalOpen: false
    }));
  };

  createChartRace = (dates, brandScoresData, chartType, dateType, scoreType, secondsToDelay, speed, showIcons, showBrandNames, showBrandScores) => {
    let selectedChart = {
      type: chartType,
      form: (
        <BarChartRace
          ref={(ref) => this.barChart = ref}
          user={this.props.user}
          category={this.props.category}
          dates={dates}
          brandScoresData={brandScoresData}
          scoreType={scoreType}
          dateType={dateType}
          speed={speed}
          showIcons={showIcons}
          showBrandNames={showBrandNames}
          showBrandScores={showBrandScores}
          changePlayAccessibility={this.changePlayAccessibility}
          secondsToDelay={secondsToDelay}
        />
      )
    };
    this.setState(() => ({
      selectedChart,
      secondsToDelay,
      scoreType,
      dateType,
      speed,
      showIcons,
      showBrandNames,
      showBrandScores
    }));
  }

  playChartRace = () => {
    if (this.barChart) {
      this.changePlayAccessibility(false);
      this.barChart.runBarChartRace()
    }
  }

  changePlayAccessibility = (canPlay) => {
    this.setState(() => ({ canPlay }));
  }

  render () {
    return (
      <div className="m-4">
        { this.props.category &&
          <div>
            <div>
              <Button
                className="mr-4 d-inline-block"
                variant="primary"
                onClick={this.openSetupRaceChartModal}
              >
                Setup
              </Button>
              <Button
                className="d-inline-block"
                variant="success"
                onClick={this.playChartRace}
                disabled={!this.state.canPlay}
              >
                Play
              </Button>
            </div>
            { this.state.loading &&
              <div className="m-4 text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { (!this.state.loading && this.state.selectedChart) &&
              <div>
                {this.state.selectedChart.form}
              </div>
            }

            <CategoryRaceChartSetupModal
              ref={(ref) => this.categoryRaceChartSetupModal = ref}
              isOpen={this.state.setupRaceChartModalOpen}
              handleClose={this.closeSetupRaceChartModal}
              buildChart={this.fetchBrandScores}
              category={this.props.category}
              user={this.props.user}
            />
          </div>
        }
      </div>
    );
  }
};
