import React from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class NewSearchQueryModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
      searchOrigin: '',
      searchLocations: [],
      searchLocationsFetched: false,
      searchLocationsLoading: false,
      originCanonicalName: undefined,
      errors: {},
      errorMessage: '',
    };
  };

  onOpen = () => {
    this.setState(() => ({
      searchTerm: '',
      searchOrigin: '',
      searchLocations: [],
      searchLocationsFetched: false,
      searchLocationsLoading: false,
      originCanonicalName: undefined,
      errors: {},
      errorMessage: '',
    }));
  };

  onSearchTermChange = (event) => {
    const searchTerm = event.currentTarget.value;
    this.setState(() => ({ searchTerm }));
  };

  onSearchOriginChange = (event) => {
    const searchOrigin = event.currentTarget.value;
    this.setState(() => ({ searchOrigin }));
  };

  fetchSearchLocations = () => {
    this.setState(() => ({
      searchLocationsLoading: true,
      searchLocationsFetched: true,
      originCanonicalName: undefined
    }));
    axios.get(
      `${LISTEN_ENDPOINT}/api/url-proxy?url=https://serpapi.com/locations.json?q=${this.state.searchOrigin}&limit=10`,
      HEADERS
    ).then(response => {
      const searchLocations = response.data;
      this.setState(() => ({
        searchLocations,
        searchLocationsLoading: false,
        originCanonicalName: undefined,
      }));
    }).catch(error => {
      console.error('Error: unable to fetch search origin locations');
      this.setState(() => ({
        searchLocations: [],
        searchLocationsLoading: false,
        originCanonicalName: undefined,
      }));
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  onSearchLocationSelect = (searchLocation) => {
    if (searchLocation.canonical_name === this.state.originCanonicalName) {
      this.setState(() => ({ originCanonicalName: undefined }));
    } else {
      this.setState(() => ({ originCanonicalName: searchLocation.canonical_name }));
    }
  };

  isValidForm = () => {
    const errors = {};
    let errorMessage = '';
    if (!this.state.searchTerm || this.state.searchTerm.trim() === '') {
      errors.searchTerm = true;
      errorMessage = 'Must provide a search term'
    }

    this.setState(() => ({
      errors,
      errorMessage
    }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  addSearchTerm = () => {
    if (this.isValidForm()) {
      const idSplit = this.props.selectedCategoryId.split('-');
      const categoryType = idSplit[0];
      const categoryId = idSplit[1];
      const searchQuery = {
        query_term: this.state.searchTerm,
        linked_category_id: categoryId,
        linked_category_type: categoryType,
        customer_id: this.props.customerId
      };

      if (this.state.originCanonicalName) {
        searchQuery.search_location = this.state.originCanonicalName;
      }

      axios.post(
        `${LISTEN_ENDPOINT}/api/search_query/customer`,
        searchQuery,
        HEADERS
      ).then(response => {
        this.props.refreshSearches(this.props.selectedCategoryId);
        this.props.handleClose();
      }).catch(error => {
        console.error('Error: unable to create search query');
        this.setState(() => ({
          errorMessage: 'Unable to create keyword.'
        }));
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <Modal
        size="md"
        show={this.props.isOpen}
        onHide={this.props.handleClose}
        onEntering={this.onOpen}
      >
        <Modal.Header
          className="bg-bops-blue text-light"
          closeButton
        >
          <Modal.Title>
            Add Search Term
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Search Term</Form.Label>
              <Form.Control
                type="text"
                value={this.state.searchTerm}
                onChange={this.onSearchTermChange}
                isInvalid={this.state.errors.searchTerm}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Search Origin - Optional</Form.Label>
              <Form.Row>
                <Col>
                  <Form.Control
                    type="text"
                    value={this.state.searchOrigin}
                    onChange={this.onSearchOriginChange}
                    isInvalid={this.state.errors.searchOrigin}
                    style={{ width: '300px' }}
                  />
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    onClick={this.fetchSearchLocations}
                  >
                    Search Locations
                  </Button>
                </Col>
              </Form.Row>
            </Form.Group>
            { this.state.searchLocationsFetched &&
              <Form.Group>
                <div
                  className="pre-scrollable border rounded"
                  style={{
                    fontSize: '.875rem',
                    minHeight: '200px',
                    maxHeight: '200px',
                    overflow: 'auto'
                  }}
                >
                  { this.state.searchLocationsLoading &&
                    <div className="p-4 text-center">
                      <ClipLoader size={100}/>
                    </div>
                  }
                  { !this.state.searchLocationsLoading &&
                    <React.Fragment>
                      { this.state.searchLocations.map((location, i) => {
                          return (
                            <div
                              key={`nsq-sl-${i}`}
                              className={this.state.originCanonicalName === location.canonical_name ? 'px-2 py-1 bg-primary text-light' : 'px-2 py-1'}
                              onClick={() => this.onSearchLocationSelect(location)}
                              style={{ cursor: 'pointer' }}
                            >
                              <div>
                                {location.name}
                              </div>
                              <div
                                className="mt-1"
                                style={{ fontSize: '.75rem'}}
                              >
                                {location.canonical_name}
                              </div>
                              <hr className="mt-1 mb-0"/>
                            </div>
                          )
                        })
                      }
                    </React.Fragment>
                  }
                </div>
              </Form.Group>
            }
          </Form>
          <Alert show={Boolean(this.state.errorMessage)} variant="danger">
            {this.state.errorMessage}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.addSearchTerm}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};
