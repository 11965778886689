import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactTable from 'react-table';
import ClipLoader from 'react-spinners/ClipLoader';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import MetricTooltipContainer from '../../containers/metrics/MetricTooltipContainer';
import MetricDistributionSummaryModal from '../metrics/MetricDistributionSummaryModal';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';
import { NavLink } from 'react-router-dom';

export default class CategoryTwitterSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryTwitterData: [],
      monthlyCategoryTwitterData: [],
      categoryTwitterDataLoading: false,
      metricDistributionModalOpen: false,
      selectedMetricName: undefined,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchTwitterData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.categoryId !== this.props.categoryId ||
      prevProps.user.customerId !== this.props.user.customerId) {
      this.fetchTwitterData();
    }
    if (prevProps.selectedMonth !== this.props.selectedMonth) {
      this.setTwitterData(this.props.selectedMonth);
    }
  };

  selectMonth = (selectedMonth) => {
    if (selectedMonth !== this.props.selectedMonth) {
      this.props.updateSelectedMonth(selectedMonth);
    }
  };

  setTwitterData = (selectedMonth) => {
    let monthlyCategoryTwitterData = [];
    for (const brand of this.state.categoryTwitterData) {
      for (const monthStats of brand.stats) {
        if (monthStats.month == selectedMonth.label) {
          monthlyCategoryTwitterData.push({
            name: brand.product_brand_name,
            month: monthStats.month,
            mentions: monthStats.mentions,
            promoters: monthStats.promoters,
            detractors: monthStats.detractors,
            ownedTweets: monthStats.owned_tweets,
            engagements: monthStats.avg_post_engagement_without_replies
          });
        }
      }
    }
    this.setState(() => ({ monthlyCategoryTwitterData }));
  };

  fetchTwitterData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryTwitterDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-twitter-stats-trend?category_type=${this.props.category.category_type}&category_id=${this.props.categoryId}`,
        HEADERS
      ).then(response => {
        let categoryTwitterData = response.data;
        let monthlyCategoryTwitterData = [];
        for (const brand of categoryTwitterData) {
          for (const monthStats of brand.stats) {
            if (monthStats.month == this.props.selectedMonth.label) {
              monthlyCategoryTwitterData.push({
                name: brand.product_brand_name,
                month: monthStats.month,
                mentions: monthStats.mentions,
                promoters: monthStats.promoters,
                detractors: monthStats.detractors,
                ownedTweets: monthStats.owned_tweets,
                engagements: monthStats.avg_post_engagement_without_replies
              });
            }
          }
        }
        this.setState(() => ({
          categoryTwitterData,
          monthlyCategoryTwitterData,
          categoryTwitterDataLoading: false
        }));
      }).catch(error => {
        console.error('Error: failed to fetch category twitter data...');
        this.setState(() => ({
          categoryTwitterData: [],
          monthlyCategoryTwitterData: [],
          categoryTwitterDataLoading: false
        }));
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  openMetricDistributionModal = (selectedMetricName) => {
    this.setState(() => ({
      selectedMetricName,
      metricDistributionModalOpen: true
    }));
  };

  closeMetricDistributionModal = () => {
    this.setState(() => ({
      metricDistributionModalOpen: false
    }));
  };

  render () {
    return (
      <div>
        <Row>
          <Col xs={6} md={4} lg={3} xl={2}>
            { this.props.sixMonths.map((month, i) => {
                return (
                  <button
                    id={`categoryTwitterSummary${month.label}Button`}
                    key={`bmd${i}`}
                    type="button"
                    className= {
                      (this.props.selectedMonth && this.props.selectedMonth.label === month.label) ?
                        'mb-1 btn btn-primary w-100' :
                        'mb-1 btn border bg-white w-100'
                    }
                    onClick={() => this.selectMonth(month)}
                  >
                    {month.label}
                  </button>
                )
              })
            }
          </Col>
          <Col>
            { this.state.categoryTwitterDataLoading &&
              <div className="m-4 text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.categoryTwitterDataLoading &&
              <div id="categoryTwitterSummary">
                <ReactTable
                  className="-striped -highlight"
                  data={this.state.monthlyCategoryTwitterData}
                  columns={[
                    {
                      Header: 'Brand',
                      headerClassName: 'bg-dark text-light border-light wordwrap',
                      accessor: 'name',
                      sortable: true,
                      Cell: row => (
                        <div style={{ cursor: "pointer", textOverflow: 'ellipsis' }} >
                          <NavLink
                            key={`lmc${this.props.category.id}-${this.props.category.category_type}-lmb${row.original.id}`}
                            to={`/category/${encodeURIComponent(this.props.category.name)}/brand/${encodeURIComponent(row.original.name)}#social-twitter`}
                          >
                            {row.original.name}
                          </NavLink>
                        </div>
                      )
                    },
                    {
                      Header: (
                        <MetricTooltipContainer
                          tooltipTrigger={
                            <div>
                              Mentions
                            </div>
                          }
                          tooltipPosition="top"
                          metricName="social_mention_count_twitter"
                          openMetricDistributionModal={this.openMetricDistributionModal}
                        />
                      ),
                      headerClassName: 'bg-dark text-light border-light wordwrap',
                      accessor: 'mentions',
                      sortable: true,
                      className: 'text-center',
                      Cell: row => (
                        <div>
                          {numberWithCommas(row.original.mentions)}
                        </div>
                      )
                    },
                    {
                      Header: (
                        <MetricTooltipContainer
                          tooltipTrigger={
                            <div>
                              Promoter Sentiment
                            </div>
                          }
                          tooltipPosition="top"
                          metricName="social_sentiment_percent_of_promoters"
                          openMetricDistributionModal={this.openMetricDistributionModal}
                        />
                      ),
                      headerClassName: 'bg-dark text-light border-light wordwrap',
                      accessor: 'promoters',
                      sortable: true,
                      className: 'text-center',
                      Cell: row => (
                        <div>
                          {numberWithCommas(row.original.promoters)}
                        </div>
                      )
                    },
                    {
                      Header: (
                        <MetricTooltipContainer
                          tooltipTrigger={
                            <div>
                              Detractor Sentiment
                            </div>
                          }
                          tooltipPosition="top"
                          metricName="social_sentiment_percent_of_demoters"
                          openMetricDistributionModal={this.openMetricDistributionModal}
                        />
                      ),
                      headerClassName: 'bg-dark text-light border-light wordwrap',
                      accessor: 'detractors',
                      sortable: true,
                      className: 'text-center',
                      Cell: row => (
                        <div>
                          {numberWithCommas(row.original.detractors)}
                        </div>
                      )
                    },
                    {
                      Header: (
                        <MetricTooltipContainer
                          tooltipTrigger={
                            <div>
                              Owned Tweets
                            </div>
                          }
                          tooltipPosition="top"
                          metricName="social_owned_post_count_twitter"
                          openMetricDistributionModal={this.openMetricDistributionModal}
                        />
                      ),
                      headerClassName: 'bg-dark text-light border-light wordwrap',
                      accessor: 'ownedTweets',
                      sortable: true,
                      className: 'text-center',
                      Cell: row => (
                        <div>
                          {numberWithCommas(row.original.ownedTweets)}
                        </div>
                      )
                    },
                    {
                      Header: (
                        <MetricTooltipContainer
                          tooltipTrigger={
                            <div>
                              Engagement
                            </div>
                          }
                          tooltipPosition="top"
                          metricName="social_engagement_twitter"
                          openMetricDistributionModal={this.openMetricDistributionModal}
                        />
                      ),
                      headerClassName: 'bg-dark text-light border-light wordwrap',
                      accessor: 'engagements',
                      sortable: true,
                      className: 'text-center',
                      Cell: row => (
                        <div>
                          {numberWithCommas(row.original.engagements)}
                        </div>
                      )
                    }
                  ]}
                  minRows={1}
                  defaultPageSize={100}
                  showPagination={false}
                  defaultSorted={[{ id: 'mentions', desc: true }]}
                />
                <SharePopUpContainer
                  shareElementId="categoryTwitterSummary"
                  elementLabel="category-twitter-summary-img"
                  position="bottom"
                  contextType="category"
                  contextCategory={this.props.category}
                  contextBrand={null}
                  contextChannel="Social"
                  contextProperty="Twitter"
                  contextChartName="Summary"
                  contextDateRange={`${this.props.selectedMonth.label} ${this.props.selectedMonth.date.format('YYYY')}`}
                />
              </div>
            }
          </Col>
        </Row>

        <MetricDistributionSummaryModal
          isOpen={this.state.metricDistributionModalOpen}
          handleClose={this.closeMetricDistributionModal}
          metricName={this.state.selectedMetricName}
          audienceProfileId={this.props.category.audience_profile_id}
          customerId={this.props.user.customerId}
        />
      </div>
    );
  }
};
