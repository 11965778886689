import React from 'react';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import BrandChannelSummaryContainer from '../../../containers/brands/BrandChannelSummaryContainer';
import BrandMetricsByMonthTableContainer from '../../../containers/metrics/BrandMetricsByMonthTableContainer';
import ProxyEmailsSummaryGraphs from '../../proxyEmails/ProxyEmailsSummaryGraphs';
import ProxyEmailDetails from '../../proxyEmails/ProxyEmailDetails';
import { createUserActivityLog } from '../../../utils/users';
import history from '../../../routers/history';

export default class EmailTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'highlights'
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let selectedTab = hash.indexOf("-")>0 ? hash.substring(hash.indexOf("-")+1) : "highlights";
      this.setState(() => ({ selectedTab }));
    }
    if (this.props.scrollToDetails) {
      this.emailScroll.scrollTop = this.emailDetails.offsetTop - this.emailScroll.offsetTop;
      this.props.unsetScrollToDetails();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  changeSelectedTab = (selectedTab, subTabName) => {
    history.push(`#email-${selectedTab}`);
    this.setState(() => ({ selectedTab }));
    if (subTabName) {
      createUserActivityLog(
        this.props.user,
        `Brand Tabs: Email: ${subTabName}`,
        this.props.category,
        this.props.brand,
      )
    }
  };

  render () {
    return (
      <div className="p-4">
        <div className="mb-4">
          <button
            type="button"
            className= { this.state.selectedTab === 'highlights' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('highlights', 'Highlights')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faChartLine} />
            </div>
            <div className="d-inline-block">
              Highlights
            </div>
          </button>
        </div>
        <div
          ref={(ref) => this.emailScroll = ref}
          className="pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 200px)',
            maxHeight: 'calc(100vh - 200px)',
            overflow: 'auto',
            overflowX: 'hidden'
          }}
        >
          { this.state.selectedTab === 'highlights' &&
            <div>
              <div>
                <BrandChannelSummaryContainer
                  channel="Emails"
                  category={this.props.category}
                  brand={this.props.brand}
                />
              </div>
              <div>
                <h5 className="my-2 p-2 bg-bops-blue text-light">
                  Metric Trends
                  <div
                    className="d-inline-block ml-4"
                    style={{ fontSize: '1rem', fontWeight: 'normal' }}
                  >
                    <Form.Check
                      id="emailAllMonthsCheckbox"
                      className="ml-2"
                      type="checkbox"
                      label="All Months"
                      checked={this.props.allMonthsChecked}
                      onChange={(event) => this.props.updateAllMonthsChecked(event.currentTarget.checked)}
                      inline
                    />
                  </div>
                </h5>
                <div>
                  <ProxyEmailsSummaryGraphs
                    brand={this.props.brand}
                    category={this.props.category}
                    allMonthsChecked={this.props.allMonthsChecked}
                    updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                    allMonthsStartDate={this.props.allMonthsStartDate}
                  />
                </div>
              </div>
              <div>
                <BrandMetricsByMonthTableContainer
                  type="Emails"
                  category={this.props.category}
                  brand={this.props.brand}
                  audienceProfileId={this.props.category.audience_profile_id}
                />
              </div>
              <div ref={(ref) => this.emailDetails = ref}>
                <ProxyEmailDetails
                  topicId={this.props.topicId}
                  brand={this.props.brand}
                  customerId={this.props.customerId}
                  customerUserId={this.props.customerUserId}
                  companyChecked={this.props.companyChecked}
                  portfolioChecked={this.props.portfolioChecked}
                  productChecked={this.props.productChecked}
                  onCppCheckChange={this.props.onCppCheckChange}
                />
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
};
