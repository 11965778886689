import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ClipLoader from 'react-spinners/ClipLoader';
import { InView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { isMobile, isDesktop, deviceType, browserName, osName } from 'react-device-detect';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CustomReportsPublicScroll extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      widgetIndex: 0,
      publishToken: '',
      customReport: undefined,
      customReportLoading: false,
      customReportNotFound: false,
      companyDetails: undefined,
      cacheBreaker: Math.random().toString(36).substring(7),
      customReportView: undefined,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.publishToken
    ) {
      this.setState(
        () => ({ publishToken: this.props.match.params.publishToken }),
        () => this.fetchCustomReport()
      );
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.publishToken !== this.props.match.params.publishToken) {
      this.setState(
        () => ({ publishToken: this.props.match.params.publishToken }),
        () => this.fetchCustomReport()
      );
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchCustomReport = () => {
    if (this.state.publishToken) {
      this.setState(() => ({
        customReport: undefined,
        customReportLoading: true,
        customReportNotFound: false
      }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/custom-report-published?publish_token=${this.state.publishToken}`,
        HEADERS
      ).then(response => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.fetchCompanyDetails(response.data[0].company_id);
          this.createCustomReportView(response.data[0].id, response.data[0].publish_token);
          this.setState(() => ({
            customReport: response.data[0],
            customReportLoading: false,
          }));
        } else {
          this.setState(() => ({
            customReportLoading: false,
            customReportNotFound: true,
          }));
        }
      }).catch(error => {
        console.error('Error: failed find published report.');
        if (this.state.isMounted) {
          this.setState(() => ({
            customReportLoading: false,
            customReportNotFound: true,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchCompanyDetails = (companyId) => {
    if (companyId) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/companies/${companyId}`,
        HEADERS
      ).then(response => {
        const companyDetails = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({ companyDetails }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch company details.');
        if (this.state.isMounted) {
          this.setState(() => ({ companyDetails: undefined }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  createCustomReportView = (customReportId, reportPublishToken) => {
    let formattedDeviceType;
    if (isDesktop) {
      formattedDeviceType = 'desktop';
    } else if (isMobile) {
      formattedDeviceType = 'mobile';
    } else {
      formattedDeviceType = deviceType;
    }

    const customReportView = {
      custom_report_id: customReportId,
      report_publish_token: reportPublishToken,
      device_type: formattedDeviceType,
      browser: browserName,
      os_name: osName
    };

    axios.post(
      `${LISTEN_ENDPOINT}/api/custom-report-views`,
      customReportView,
      HEADERS
    ).then(response => {
      const customReportView = response.data;
      if (this.state.isMounted) {
        this.setState(() => ({ customReportView }));
      }
    }).catch(error => {
      console.error('Error: failed to create custom report view.');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  customReportViewCompleted = (viewCompleteTrigger) => {
    if (viewCompleteTrigger && this.state.customReportView && this.state.customReportView.id) {
      axios.put(
        `${LISTEN_ENDPOINT}/api/custom-report-views/${this.state.customReportView.id}`,
        { view_completed: true },
        HEADERS
      ).catch(error => {
        console.error('Error: failed to update custom report view.');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div
        className="pre-scrollable"
        style={{
          minHeight: '100vh',
          maxHeight: '100vh',
          overflow: 'auto',
          overflowX: 'hidden'
        }}
      >
        <div className="p-4">
          { this.state.customReportLoading &&
            <div className="m-4 text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { (!this.state.customReportLoading && this.state.customReport) &&
            <div>
              <div className="mb-3">
                <div className="d-inline-block">
                </div>
                <div className="d-inline-block float-right">
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(`${window.location.origin}/reports/public/scrollable/${this.state.customReport.publish_token}`);
                    }}
                    size="sm"
                  >
                    <FontAwesomeIcon
                      className="mr-2"
                      icon={faCopy}
                    />
                    Copy Report URL
                  </Button>
                </div>
              </div>
              <div id="scrollableReportPublic">
                { this.state.customReport.widgets.map((widget, i) => {
                    return (
                      <div
                        key={`cr-ps-w-${i}`}
                        style={{ marginBottom: '200px' }}
                      >
                        { i === this.state.customReport.widgets.length - 1 &&
                          <InView
                            triggerOnce={true}
                            onChange={(inView, entry) => this.customReportViewCompleted(inView)}
                          />
                        }
                        { widget.context_category_id &&
                          <div className="mb-2 bg-secondary text-light font-weight-bold rounded">
                            <Row style={{ height: '40px' }}>
                              <Col>
                                <div className="p-2 ellipsis">
                                  { (widget.context_category_name && widget.context_brand_name) &&
                                    <React.Fragment>
                                      {`${widget.context_category_name} - ${widget.context_brand_name}`}
                                    </React.Fragment>
                                  }
                                  { !(widget.context_category_name && widget.context_brand_name) &&
                                    <React.Fragment>
                                      {widget.context_category_name || ''}
                                      {widget.context_brand_name || ''}
                                    </React.Fragment>
                                  }
                                </div>
                              </Col>
                              <Col>
                                <div className="p-2 text-center ellipsis">
                                  {widget.title || ''}
                                </div>
                              </Col>
                              <Col>
                                <div
                                  className="mt-1 p-2 float-right"
                                  style={{
                                    fontSize: '.75rem',
                                    fontWeight: 'normal'
                                  }}
                                >
                                  {widget.date_range_context || ''}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        }
                        <div className="text-center">
                          <img
                            src={`${widget.image_url}?${this.state.cacheBreaker}`}
                            style={{ maxWidth: '100%' }}
                            alt=""
                            crossOrigin="anonymous"
                          />
                        </div>
                        { widget.notes &&
                          <div
                            className="container pre-scrollable"
                            style={{
                              maxHeight: '400px',
                              overflowX: 'hidden',
                              overflowY: 'auto',
                            }}
                          >
                            <div className="pt-4 text-left">
                              {widget.notes}
                            </div>
                          </div>
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
          }
          { (!this.state.customReportLoading && this.state.customReportNotFound) &&
            <div>
              <h1>Oops</h1>
              <div>We could not find the report you seek. It may have been unpublished.</div>
            </div>
          }
        </div>
      </div>
    );
  }
};
