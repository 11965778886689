import React from 'react';

const BoxScoreBar = (props) => {
  if (props.score > props.maxScore * .8) {
    return (
      <div>
        <div
          className="d-inline-block mr-1 align-middle bg-bops-blue"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle bg-bops-blue"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle bg-bops-blue"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle bg-bops-blue"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle bg-bops-blue"
          style={{ width: '25px', height: '20px' }}
        ></div>
      </div>
    );
  } else if (props.score > props.maxScore * .6) {
    return (
      <div>
        <div
          className="d-inline-block mr-1 align-middle bg-bops-blue"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle bg-bops-blue"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle bg-bops-blue"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle bg-bops-blue"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle border bg-white"
          style={{ width: '25px', height: '20px' }}
        ></div>
      </div>
    );
  } else if (props.score > props.maxScore * .4) {
    return (
      <div>
        <div
          className="d-inline-block mr-1 align-middle bg-bops-blue"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle bg-bops-blue"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle bg-bops-blue"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle border bg-white"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle border bg-white"
          style={{ width: '25px', height: '20px' }}
        ></div>
      </div>
    );
  } else if (props.score > props.maxScore * .2) {
    return (
      <div>
        <div
          className="d-inline-block mr-1 align-middle bg-bops-blue"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle bg-bops-blue"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle border bg-white"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle border bg-white"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle border bg-white"
          style={{ width: '25px', height: '20px' }}
        ></div>
      </div>
    );
  } else if (props.score > 0) {
    return (
      <div>
        <div
          className="d-inline-block mr-1 align-middle bg-bops-blue"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle border bg-white"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle border bg-white"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle border bg-white"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle border bg-white"
          style={{ width: '25px', height: '20px' }}
        ></div>
      </div>
    );
  } else {
    return (
      <div>
        <div
          className="d-inline-block mr-1 align-middle border bg-white"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle border bg-white"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle border bg-white"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle border bg-white"
          style={{ width: '25px', height: '20px' }}
        ></div>
        <div
          className="d-inline-block mr-1 align-middle border bg-white"
          style={{ width: '25px', height: '20px' }}
        ></div>
      </div>
    );
  }
};

export default BoxScoreBar;
