import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TwitterOwnedTweetSummaryGraph from './TwitterOwnedTweetSummaryGraph';
import TwitterEarnedTweetSummaryGraph from './TwitterEarnedTweetSummaryGraph';
import TwitterFollowersSummaryGraph from './TwitterFollowersSummaryGraph';
import TwitterEngagementSummaryGraph from './TwitterEngagementSummaryGraph';

export default class TwitterSummaryGraphs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  };

  componentDidUpdate(prevProps) {

  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  render () {
    return (
      <div>
        <Row>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <TwitterOwnedTweetSummaryGraph
                companyId={this.props.companyId}
                category={this.props.category}
                brand={this.props.brand}
                allMonthsChecked={this.props.allMonthsChecked}
                updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                allMonthsStartDate={this.props.allMonthsStartDate}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <TwitterEarnedTweetSummaryGraph
                category={this.props.category}
                brand={this.props.brand}
                topicId={this.props.topicId}
                category={this.props.category}
                allMonthsChecked={this.props.allMonthsChecked}
                updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                allMonthsStartDate={this.props.allMonthsStartDate}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <TwitterFollowersSummaryGraph
                companyId={this.props.companyId}
                category={this.props.category}
                brand={this.props.brand}
                allMonthsChecked={this.props.allMonthsChecked}
                updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                allMonthsStartDate={this.props.allMonthsStartDate}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <TwitterEngagementSummaryGraph
                companyId={this.props.companyId}
                category={this.props.category}
                brand={this.props.brand}
                allMonthsChecked={this.props.allMonthsChecked}
                updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                allMonthsStartDate={this.props.allMonthsStartDate}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};
