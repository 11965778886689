import React from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CustomReportModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      description: '',
      errors: {},
      errorMessage: '',
      saveLoading: false
    };
  }

  onOpen = () => {
    if (this.props.customReport) {
      this.setState(() => ({
        name: this.props.customReport.name,
        description: this.props.customReport.description,
        errors: {},
        errorMessage: '',
        saveLoading: false
      }));
    } else {
      // reset defaults
      this.setState(() => ({
        name: '',
        description: '',
        errors: {},
        errorMessage: '',
        saveLoading: false
      }));
    }
  };

  onNameChange = (event) => {
    const name = event.currentTarget.value;
    this.setState(() => ({ name }));
  };

  onDescriptionChange = (event) => {
    const description = event.currentTarget.value;
    this.setState(() => ({ description }));
  };

  validForm = () => {
    const errors = {};
    if (!this.state.name) {
      errors.name = true;
    }

    this.setState(() => ({ errors }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  saveCustomReport = () => {
    if (this.validForm()) {
      this.setState(() => ({ errorMessage: '', saveLoading: true }));
      const customReport = {
        name: this.state.name,
        description: this.state.description
      };

      if (this.props.customReport) {
        // update
        axios.put(
          `${LISTEN_ENDPOINT}/api/custom-reports/${this.props.customReport.id}`,
          customReport,
          HEADERS
        ).then(response => {
          this.props.refreshReports();
          this.props.handleClose();
        }).catch(error => {
          console.error('Error: failed to update report');
          this.setState(() => ({
            errorMessage: 'Failed to update report',
            saveLoading: false,
          }))
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      } else {
        // create
        customReport.company_id = this.props.user.customerId;
        customReport.author_customer_user_id = this.props.user.id;

        axios.post(
          `${LISTEN_ENDPOINT}/api/custom-reports`,
          customReport,
          HEADERS
        ).then(response => {
          if (this.props.updateSelectedReportId) {
            this.props.updateSelectedReportId(response.data.id);
          }
          if (this.props.updateLastCustomReportIdUsed) {
            this.props.updateLastCustomReportIdUsed(response.data.id);
          }
          this.props.refreshReports();
          this.props.handleClose();
        }).catch(error => {
          console.error('Error: failed to create report');
          this.setState(() => ({
            errorMessage: 'Failed to create report',
            saveLoading: false,
          }));
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  render () {
    return (
      <Modal
        size="md"
        show={this.props.isOpen}
        onHide={this.props.handleClose}
        onEntering={this.onOpen}
      >
        <Modal.Header
          className="bg-bops-blue text-light"
          closeButton
        >
          <Modal.Title>
            <FontAwesomeIcon
              className="mr-2"
              icon={faFileLines}
            />
            {this.props.alert ? 'Update ': 'Add '}
            Report
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                className="w-75"
                type="text"
                value={this.state.name}
                onChange={this.onNameChange}
                isInvalid={this.state.errors.name}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                value={this.state.description}
                onChange={this.onDescriptionChange}
                isInvalid={this.state.errors.description}
              />
            </Form.Group>
          </Form>
          <Alert show={Boolean(this.state.errorMessage)} variant="danger">
            {this.state.errorMessage}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.saveCustomReport} disabled={this.state.saveLoading}>
            { this.props.alert? 'Update' : 'Add' }
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};
