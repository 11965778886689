import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

export default class Listen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pagesBeforeAfterEllipsis: 4,
      pagesInEllipsis: 2,
      totalPageCountBeforeEllipsis: 10
    };
  }

  componentDidMount = () => {
    let pagesBeforeAfterEllipsis = 4;
    let pagesInEllipsis = 2;
    let totalPageCountBeforeEllipsis = 10;
    if (this.props.size === 'sm') {
      pagesBeforeAfterEllipsis = 3;
      pagesInEllipsis = 1;
      totalPageCountBeforeEllipsis = 5;
    }

    this.setState(() => ({
      pagesBeforeAfterEllipsis,
      pagesInEllipsis,
      totalPageCountBeforeEllipsis
    }));
  };

  getEllipsisPages = (totalPages) => {
    const pages = [];
    if (
      this.props.selectedPage > this.state.pagesBeforeAfterEllipsis &&
      this.props.selectedPage < totalPages-(this.state.pagesBeforeAfterEllipsis-1)
    ) {
      pages.push(
        <Pagination.Item
          key={`page1`}
          active={1 === this.props.selectedPage}
          onClick={() => this.props.onPageSelect(1)}
        >
          1
        </Pagination.Item>
      )
      pages.push(
        <Pagination.Ellipsis key="pageEliipsis1"/>
      )
      for (
        let i = this.props.selectedPage-this.state.pagesInEllipsis;
        i <= this.props.selectedPage+this.state.pagesInEllipsis;
        i++
      ) {
        pages.push(
          <Pagination.Item
            key={`page${i}`}
            active={i === this.props.selectedPage}
            onClick={() => this.props.onPageSelect(i)}
          >
            {i}
          </Pagination.Item>
        )
      }
      pages.push(
        <Pagination.Ellipsis key="pageEliipsis2"/>
      )
      pages.push(
        <Pagination.Item
          key={`page${totalPages}`}
          active={totalPages === this.props.selectedPage}
          onClick={() => this.props.onPageSelect(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      )
    } else {
      for (let i = 1; i <= this.state.pagesBeforeAfterEllipsis; i++) {
        pages.push(
          <Pagination.Item
            key={`page${i}`}
            active={i === this.props.selectedPage}
            onClick={() => this.props.onPageSelect(i)}
          >
            {i}
          </Pagination.Item>
        )
      }
      pages.push(
        <Pagination.Ellipsis key="pageEliipsis"/>
      )
      for (
        let i = totalPages-(this.state.pagesBeforeAfterEllipsis-1);
        i <= totalPages;
        i++
      ) {
        pages.push(
          <Pagination.Item
            key={`page${i}`}
            active={i === this.props.selectedPage}
            onClick={() => this.props.onPageSelect(i)}
          >
            {i}
          </Pagination.Item>
        )
      }
    }
    return pages;
  };

  getPages = (totalPages) => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <Pagination.Item
          key={`page${i}`}
          active={i === this.props.selectedPage}
          onClick={() => this.props.onPageSelect(i)}
        >
          {i}
        </Pagination.Item>
      )
    }
    return pages;
  };

  render () {
    const totalPages = Math.ceil(this.props.commentsTotal/this.props.commentsPerPage);
    let pages = [];
    if (totalPages > this.state.totalPageCountBeforeEllipsis) {
      pages = this.getEllipsisPages(totalPages);
    } else {
      pages = this.getPages(totalPages);
    }

    return (
      <Pagination className="m-0">
        <Pagination.First onClick={() => {
            if (this.props.selectedPage > 1) {
              this.props.onPageSelect(1)
            }
          }}
        />
        <Pagination.Prev onClick={() => {
            if (this.props.selectedPage > 1) {
              this.props.onPageSelect(this.props.selectedPage-1)
            }
          }}
        />

        {pages}

        <Pagination.Next onClick={() => {
            if (this.props.selectedPage < totalPages) {
              this.props.onPageSelect(this.props.selectedPage+1)
            }
          }}
        />
        <Pagination.Last onClick={() => {
          if (this.props.selectedPage < totalPages) {
            this.props.onPageSelect(totalPages)
          }
        }}
        />
      </Pagination>
    );
  }
};
