import React from 'react';
import axios from 'axios';
import CategorySearchAdsSummary from './CategorySearchAdsSummary';
import CategorySearchAdsAvgGraphs from './CategorySearchAdsAvgGraphs';
import ShareOfChart from '../../graphs/ShareOfChart';
import { round } from '../../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class CategorySearchAds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categorySearchAdsShareOfData: [],
      adTrafficShareOfData: {},
      categorySearchAdTrafficShareOfData: [],
      adCostShareOfData: {},
      categorySearchAdCostsShareOfData: [],
      categorySearchAdsShareOfDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchSearchAdsData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevProps.category !== this.props.category
    ) {
      this.fetchSearchAdsData();
    }
  };

  fetchSearchAdsData = () => {
    if (this.props.category) {
      this.setState(() => ({ categorySearchAdsShareOfDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-share-ads?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const categorySearchAdData = response.data;
        const adTrafficShareOfData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const adCostShareOfData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const categorySearchAdsShareOfData = [];
        const categorySearchAdTrafficShareOfData = [];
        const categorySearchAdCostsShareOfData = [];
        for (const monthStats of categorySearchAdData) {
          const adMonthData = { month: monthStats.month };
          const adTrafficMonthData = { month: monthStats.month };
          const adCostsMonthData = { month: monthStats.month };
          let monthTotalTraffic = 0;
          let monthTotalCosts = 0;

          // get totals for percent
          for (const pb of monthStats.product_brands) {
            monthTotalTraffic += pb.adwords_traffic;
            monthTotalCosts += pb.adwords_costs;
            // insert graph data
            adTrafficShareOfData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.adwords_traffic || 0,
            ]);
            adCostShareOfData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.adwords_costs || 0,
            ]);
          }

          // loop again to determine %
          for (const pb of monthStats.product_brands) {
            adMonthData[pb.product_brand_name] = pb;

            let trafficPercent = 0;
            if (monthTotalTraffic > 0) {
              trafficPercent = round((pb.adwords_traffic/monthTotalTraffic)*100, 1);
            }
            adTrafficMonthData[pb.product_brand_name] = {
              count: pb.adwords_traffic,
              percent: trafficPercent
            }

            let costPercent = 0;
            if (monthTotalCosts > 0) {
              costPercent = round((pb.adwords_costs/monthTotalCosts)*100, 1);
            }
            adCostsMonthData[pb.product_brand_name] = {
              count: pb.adwords_costs,
              percent: costPercent
            }
          }
          categorySearchAdsShareOfData.push(adMonthData);
          categorySearchAdTrafficShareOfData.push(adTrafficMonthData);
          categorySearchAdCostsShareOfData.push(adCostsMonthData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categorySearchAdsShareOfData,
            adTrafficShareOfData,
            categorySearchAdTrafficShareOfData,
            adCostShareOfData,
            categorySearchAdCostsShareOfData,
            categorySearchAdsShareOfDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch category share of search ads data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            categorySearchAdsShareOfData: [],
            adTrafficShareOfData: {},
            categorySearchAdTrafficShareOfData: [],
            adCostShareOfData: {},
            categorySearchAdCostsShareOfData: [],
            categorySearchAdsShareOfDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        <CategorySearchAdsSummary
          user={this.props.user}
          sixMonths={this.props.sixMonths}
          selectedMonth={this.props.selectedMonth}
          updateSelectedMonth={this.props.updateSelectedMonth}
          category={this.props.category}
          categoryAdsData={this.state.categorySearchAdsShareOfData}
          categoryAdsDataLoading={this.state.categorySearchAdsShareOfDataLoading}
        />
        <div className="mt-5 mb-4 band-separator" />
        <CategorySearchAdsAvgGraphs
          user={this.props.user}
          category={this.props.category}
        />
        <div className="my-4 band-separator" />
        <ShareOfChart
          title="Share of Ad Traffic"
          data={this.state.adTrafficShareOfData}
          legendData={this.state.categorySearchAdTrafficShareOfData}
          loading={this.state.categorySearchAdsShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Ads"
          contextProperty="Search Ads"
          contextChartName="Share of Ad Traffic"
        />
        <ShareOfChart
          title="Share of Ad Spend"
          data={this.state.adCostShareOfData}
          legendData={this.state.categorySearchAdCostsShareOfData}
          loading={this.state.categorySearchAdsShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Ads"
          contextProperty="Search Ads"
          contextChartName="Share of Ad Spend"
        />
      </div>
    );
  }
};
