import React from 'react';
import axios from 'axios';
import moment from 'moment';
import dompurify from 'dompurify';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import ClipLoader from 'react-spinners/ClipLoader';
import DateRangePicker from '../common/DateRangePicker';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class ProxyEmailDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailsData: [],
      emailResults: [],
      cppCounts: {},
      productBrandId: undefined,
      selectedEmailId: undefined,
      selectedEmail: undefined,
      startDate: moment().utc().subtract(3, 'year').startOf('day'),
      endDate: moment().utc().endOf('day'),
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.loadEmailsData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.brand !== this.props.brand) {
      this.loadEmailsData();
    }
    if (
      prevProps.companyChecked !== this.props.companyChecked ||
      prevProps.portfolioChecked !== this.props.portfolioChecked ||
      prevProps.productChecked !== this.props.productChecked
    ) {
      this.updateEmailResults();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  loadEmailsData = () => {
    if (this.props.brand) {
      this.setState(() => ({ emailsDataLoading: true }));
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/emails/search?company_id=${this.props.brand.company_id}&product_brand_id=${this.props.brand.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        const emailsData = response.data;
        const cppCounts = {
          companyCount: 0,
          portfolioCount: 0,
          productCount: 0,
        };
        for (const e of emailsData) {
          if (Array.isArray(e.brand_types)) {
            if (e.brand_types.includes('company')) {
              cppCounts.companyCount++;
            }
            if (e.brand_types.includes('portfolio')) {
              cppCounts.portfolioCount++;
            }
            if (e.brand_types.includes('product')) {
              cppCounts.productCount++;
            }
          } else {
            e.brand_types = [];
          }
          if (e.mail_to) {
            e.mail_to_display = e.mail_to.split('@')[0].replace('<', '');
          }
        }
        emailsData.sort((a, b) => moment(b.mail_date).format('YYYYMMDD') - moment(a.mail_date).format('YYYYMMDD'));

        if (this.state.isMounted) {
          this.setState(() => ({
            emailsData,
            emailsDataLoading: false,
            cppCounts,
          }), () => this.updateEmailResults());
        }
      }).catch(error => {
        if (this.state.isMounted) {
          this.setState(() => ({
            emailsData: [],
            emailsDataLoading: false,
            cppCounts: {},
          }), () => this.updateEmailResults());
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onFilterDatesChange = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  };

  updateEmailResults = () => {
    let emailResults = [];
    for (const e of this.state.emailsData) {
      if (
        Array.isArray(e.brand_types) &&
        (
          (this.props.companyChecked && e.brand_types.includes('company')) ||
          (this.props.productChecked && e.brand_types.includes('product')) ||
          (this.props.portfolioChecked && e.brand_types.includes('portfolio'))
        )
      ) {
        emailResults.push(e);
      }
    }

    // check if selected email is still available in email results
    let selectedEmailFound = false;
    for (const e of emailResults) {
      if (this.state.selectedEmailId === e._id) {
        selectedEmailFound = true;
      }
    }
    if (!selectedEmailFound) {
      let selectedEmailId;
      let selectedEmail;
      if (emailResults.length > 0) {
        selectedEmailId = emailResults[0]._id;
        selectedEmail = emailResults[0];
        this.selectEmail(selectedEmailId, selectedEmail);
      } else {
        this.setState(() => ({
          selectedEmailId,
          selectedEmail
        }));
      }
    }

    if (this.state.isMounted) {
      this.setState(() => ({ emailResults }));
    }
  };

  selectEmail = (selectedEmailId, selectedEmail) => {
    const sanitizer = dompurify.sanitize;
    selectedEmail.mail_body_html = sanitizer(
      selectedEmail.mail_body_html,
      {FORBID_TAGS: ['style']}
    );
    this.setState(() => ({
      selectedEmailId,
      selectedEmail
    }));
  };

  render () {
    return (
      <div>
        <h5 className="my-2 p-2 bg-bops-blue text-light">
          Details
          <span
            className="ml-4 mr-2"
            style={{ fontSize: '1rem', fontWeight: 'normal' }}
          >Date Range</span>
          <DateRangePicker
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            updateDates={this.onFilterDatesChange}
          />
          <div
            className="d-inline-block ml-4"
            style={{ fontSize: '1rem', fontWeight: 'normal' }}
          >
            <Form.Check
              className="ml-4"
              type="checkbox"
              label={`Corporate ${this.state.cppCounts.companyCount != null ? `(${numberWithCommas(this.state.cppCounts.companyCount)})` : ''}`}
              checked={this.props.companyChecked}
              onChange={(event) => this.props.onCppCheckChange('company', event.currentTarget.checked)}
              inline
            />
            <Form.Check
              className="ml-2"
              type="checkbox"
              label={`Portfolio ${this.state.cppCounts.portfolioCount != null ? `(${numberWithCommas(this.state.cppCounts.portfolioCount)})` : ''}`}
              checked={this.props.portfolioChecked}
              onChange={(event) => this.props.onCppCheckChange('portfolio', event.currentTarget.checked)}
              inline
            />
            <Form.Check
              className="ml-2"
              type="checkbox"
              label={`Product Brand ${this.state.cppCounts.productCount != null ? `(${numberWithCommas(this.state.cppCounts.productCount)})` : ''}`}
              checked={this.props.productChecked}
              onChange={(event) => this.props.onCppCheckChange('product', event.currentTarget.checked)}
              inline
            />
          </div>
        </h5>
        <div className="p-4 bg-white border rounded">
          <div>
            { this.state.emailsDataLoading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.emailsDataLoading &&
              <div>
                <Row>
                  <Col xs={3}>
                    <div>
                      <div><b>Emails</b></div>
                      <div
                        className="pre-scrollable border rounded"
                        style={{
                          minHeight: '500px',
                          maxHeight: '500px'
                        }}
                      >
                        { this.state.emailResults.map(email => {
                            return (
                              <div
                                key={`email-${email._id}`}
                                className={
                                  this.state.selectedEmailId === email._id ?
                                  "px-2 bg-primary text-light rounded" :
                                  `px-2${!email.brand_types.includes('product') ? ' bg-grey' : ''}`
                                }
                                onClick={() => this.selectEmail(email._id, email)}
                                style={{ cursor: 'pointer' }}
                              >
                                <div className="py-2">
                                  <div style={{ fontSize: '.875rem' }}>
                                    {email.mail_subject}
                                  </div>
                                  <div style={{ fontSize: '.75rem' }}>
                                    {moment(email.mail_date).format('MM/DD/YYYY')}
                                  </div>
                                </div>
                                <hr className="m-0"/>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </Col>
                  <Col xs={9}>
                    <div className="mt-4">
                      { this.state.selectedEmail &&
                        <div
                          className="pre-scrollable"
                          style={{
                            minHeight: '500px',
                            maxHeight: '500px'
                          }}
                        >
                          <div>
                            { this.state.selectedEmail.brand_types &&
                              <div>
                                { this.state.selectedEmail.brand_types.includes('company') &&
                                  <Badge
                                    className="mr-1"
                                    variant="warning"
                                    pill
                                  >
                                    Corporate
                                  </Badge>
                                }
                                { this.state.selectedEmail.brand_types.includes('product') &&
                                  <Badge
                                    className="mr-1"
                                    variant="warning"
                                    pill
                                  >
                                    Product
                                  </Badge>
                                }
                                { this.state.selectedEmail.brand_types.includes('portfolio') &&
                                  <Badge
                                    className="mr-1"
                                    variant="warning"
                                    pill
                                  >
                                    Portfolio
                                  </Badge>
                                }
                              </div>
                            }
                            <div>{`From: ${this.state.selectedEmail.mail_from}`}</div>
                            <div>{`Subject: ${this.state.selectedEmail.mail_subject}`}</div>
                            <div>{`Date: ${moment(this.state.selectedEmail.mail_date).format('MMM Do, YYYY @ H:mm:ss A')}`}</div>
                            <div>{`To: ${this.state.selectedEmail.mail_to_display}`}</div>
                          </div>
                          <hr />
                          <div dangerouslySetInnerHTML={{__html: this.state.selectedEmail.mail_body_html}} />
                        </div>
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
};
