import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipLoader from 'react-spinners/ClipLoader';

export default class SkipListModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      skipListCheckedIdsMap: {}
    };
  }

  onOpen = () => {
    this.setState(() => ({
      skipListCheckedIdsMap: {}
    }));
  };

  updateSkipListToPotentialCompetitors = (event, potenticalCompetitor) => {
    if (this.state.skipListCheckedIdsMap[potenticalCompetitor.id]) {
      this.setState((prevState) => {
        let skipListCheckedIdsMap = prevState.skipListCheckedIdsMap;
        delete skipListCheckedIdsMap[potenticalCompetitor.id];
        return ({ skipListCheckedIdsMap });
      });
    } else {
      this.setState((prevState) => ({
        skipListCheckedIdsMap: {
          ...prevState.skipListCheckedIdsMap,
          [potenticalCompetitor.id]: potenticalCompetitor,
        }
      }));
    }
  };

  moveToPotentialCompetitors = () => {
    const newPotentialCompetitors = [];
    for (const [id, potentialCompetitor] of Object.entries(this.state.skipListCheckedIdsMap)) {
      newPotentialCompetitors.push(potentialCompetitor);
    }
    this.props.updateSkipListToPotentialCompetitors(newPotentialCompetitors);
  };

  render() {
    return (
      <Modal
        show={this.props.isOpen}
        onHide={this.props.handleClose}
        onEntering={this.onOpen}
        size="lg"
      >
        <Modal.Header
          className="bg-bops-blue text-light"
          closeButton
        >
          <Modal.Title>
            Potential Competitors Skip List
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div
                className="pre-scrollable bg-white border rounded"
                style={{
                  minHeight: '200px',
                  maxHeight: '200px',
                  overflow: 'auto',
                }}
              >
                { this.props.potentialCompetitorsSkipList.map((pc, i) => {
                    return (
                      <div
                        key={`cpc-sl-${i}`}
                        className="px-2"
                      >
                        <Form.Check
                          type="checkbox"
                          label={pc.potential_competitor_domain}
                          checked={this.state.skipListCheckedIdsMap[pc.id] || false}
                          onChange={(event) => this.updateSkipListToPotentialCompetitors(event, pc)}
                        />
                      </div>
                    )
                  })
                }
              </div>
            </Col>
            <Col>
              <div className="border rounded bg-light p-3">
                These brands were identified by the AI, but you or a team member have put them on the skip list (not a competitor).
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => this.props.handleClose()}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => this.moveToPotentialCompetitors()}
            disabled={
              Object.keys(this.state.skipListCheckedIdsMap).length === 0 ||
              this.props.updateSkipListLoading
            }
          >
            { this.props.updateSkipListLoading &&
              <span className="mr-1">
                <ClipLoader
                  size={15}
                  color="ffffff"
                />
              </span>
            }
            Move to Potential Competitors
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
};
