const defaultWeights = {
  'Brand Presence': .25,
  'Brand Attention': .25,
  'Brand Reputation': .25,
  'Brand Momentum': .25,
};

export default function overallScoreWeightsReducer(state = defaultWeights, action) {
  switch (action.type) {
    case 'UPDATE_OVERALL_SCORE_WEIGHTS':
      return Object.assign({}, action.weights);
    default:
      return state
  }
};
