import { connect } from 'react-redux';
import SharePopUp from '../../components/share/SharePopUp';

const mapStateToProps = (state) => ({
  user: state.user,
  slackChannels: state.slackChannels,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SharePopUp);
