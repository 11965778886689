import React from 'react';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import SinceEventDateModalContainer from '../../containers/common/SinceEventDateModalContainer';

export default class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sinceEventDateSelectModalOpen: false,
    };
  }

  getWidth = () => {
    if (this.props.size) {
      if (this.props.size === 'lg') {
        return '220px'
      } else if (this.props.size === 'normal') {
        return '170px';
      } else {
        return '155px';
      }
    } else {
      return '155px';
    }
  }

  rangeCallbackTest = (index, value) => {
    if (value === 'Since Event') {
      this.setState(() => ({ sinceEventDateSelectModalOpen: true }));
      const cancelButton = document.getElementsByClassName('buttonSeperator cancelButton')[0];
      if (cancelButton) {
        cancelButton.click();
      }
    }
  };

  render() {
    return (
      <div className="d-inline-block">
        <DateTimeRangeContainer
          ranges={{
            "Last 30 days": [moment().subtract(30, 'days').startOf('day'), moment().endOf('day')],
            "Last Month": [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
            "Last 1 year": [moment().subtract(1, 'years').startOf('day'), moment().endOf('day')],
            "This year": [moment().startOf('year'), moment().endOf('day')],
            [`Q${moment().quarter()} - ${moment().year()}`]: [moment().startOf('quarter'), moment().endOf('day')],
            [`Q${moment().subtract(3, 'months').quarter()} - ${moment().subtract(3, 'months').year()}`]: [moment().subtract(3, 'months').startOf('quarter'), moment().subtract(3, 'months').endOf('quarter')],
            [`Q${moment().subtract(6, 'months').quarter()} - ${moment().subtract(6, 'months').year()}`]: [moment().subtract(6, 'months').startOf('quarter'), moment().subtract(6, 'months').endOf('quarter')],
            [`Q${moment().subtract(9, 'months').quarter()} - ${moment().subtract(9, 'months').year()}`]: [moment().subtract(9, 'months').startOf('quarter'), moment().subtract(9, 'months').endOf('quarter')],
            "Since Event": [moment(), moment()],
          }}
          start={this.props.startDate}
          end={this.props.endDate}
          local={{
            "format":"MM-DD-YYYY",
            "sundayFirst" : false
          }}
          maxDate={this.props.maxDate || moment().endOf('day')}
          rangeCallback={this.rangeCallbackTest}
          applyCallback={this.props.updateDates}
        >
          <Form.Control
            className="bg-white"
            type="text"
            value={`${this.props.startDate.format('MM/DD/YY')} - ${this.props.endDate.format('MM/DD/YY')}`}
            style={{
              cursor: 'pointer',
              width: this.getWidth()
            }}
            size={this.props.size || 'sm'}
            readOnly
          />
        </DateTimeRangeContainer>

        <SinceEventDateModalContainer
          isOpen={this.state.sinceEventDateSelectModalOpen}
          handleClose={() => this.setState(() => ({ sinceEventDateSelectModalOpen: false }))}
          updateDates={this.props.updateDates}
        />
      </div>
    );
  }
};
