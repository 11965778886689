import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import ClipLoader from 'react-spinners/ClipLoader';
import BoxScoreBar from '../graphs/BoxScoreBar';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import history from '../../routers/history';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryChannelsSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      channelScoresData: {},
      channels: [],
      channelScoresDataLoading: false,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchChannelScores();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.fetchChannelScores();
    }
  };

  fetchChannelScores = () => {
    if (this.props.category) {
      this.setState(() => ({ channelScoresDataLoading: true }));
      const channelScoreRequests = [];
      const formattedStartDate = moment(this.props.sixMonths[this.props.sixMonths.length-1].date).startOf('month').format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = moment(this.props.sixMonths[0].date).endOf('month').format('YYYY-MM-DD HH:mm:ss');
      for (const brand of this.props.category.product_brands) {
        channelScoreRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/scores/brand-channels-monthly-summary?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&product_brand_id=${brand.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
            HEADERS
          ).then(response => {
            const channelScoresData = response.data;
            const channelScoresByMonthMap = {};
            for (const monthData of channelScoresData) {
              channelScoresByMonthMap[monthData.month] = monthData.channel_scores;
              // round scores
              for (const key in channelScoresByMonthMap[monthData.month]) {
                channelScoresByMonthMap[monthData.month][key] = Math.round(channelScoresByMonthMap[monthData.month][key].score);
              }
            }
            return {
              brand,
              channelScoresByMonthMap
            }
          }).catch(error => {
            console.error('Error: unable to fetch brand branch channel scores monthly summary...');
            if (this.state.isMounted) {
              return {
                brand,
                channelScoresByMonthMap: {}
              }
            }
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
          })
        )
      }

      Promise.all(channelScoreRequests).then(responses => {
        const channelScoresData = {};
        for (const response of responses) {
          for (const [month, channelData] of Object.entries(response.channelScoresByMonthMap)) {
            for (const [channel, score] of Object.entries(channelData)) {
              if (!channelScoresData[month]) {
                channelScoresData[month] = {};
              }
              if (!channelScoresData[month][channel]) {
                channelScoresData[month][channel] = [];
              }
              channelScoresData[month][channel].push({
                brandName: response.brand.name,
                companyId: response.brand.company_id,
                score
              });
            }
          }
        }

        // sort scores for each channel
        for (const [month, monthData] of Object.entries(channelScoresData)) {
          for (const [channel, scoresArray] of Object.entries(monthData)) {
            scoresArray.sort((a, b) => {
              if (a.score < b.score) {
                return 1;
              } else if (a.score > b.score) {
                return -1;
              } else {
                if (a.brandName < b.brandName) {
                  return -1;
                } else if (a.brandName > b.brandName) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          }
        }

        this.setState(() => ({
          channelScoresData,
          channelScoresDataLoading: false
        }));
      });
    }
  };

  selectMonth = (selectedMonth) => {
    if (selectedMonth !== this.props.selectedMonth) {
      this.props.updateSelectedMonth(selectedMonth);
    }
  };

  onBrandChannelSelect = (brandName, channel) => {
    // convert channel to appropriate tab hash
    let channelHash;
    switch (channel) {
      case 'Ads':
        channelHash = 'ads';
        break;
      case 'Blogs':
        channelHash = 'web-blog';
        break;
      case 'Emails':
        channelHash = 'email';
        break;
      case 'News':
        channelHash = 'news';
        break;
      case 'Podcast':
        channelHash = 'podcasts';
        break;
      case 'Product Reviews':
        channelHash = 'reviews';
        break;
      case 'Employer Reviews':
        channelHash = 'company-employerReviews';
        break;
      case 'Search Engine Results':
        channelHash = 'search';
        break;
      case 'Social':
        channelHash = 'social';
        break;
      case 'Videos':
        channelHash = 'video';
        break;
      case 'Web':
        channelHash = 'web';
        break;
      case 'Webinar':
        channelHash = 'webinar';
        break;
      default:
        channelHash = 'channels';
    }
    history.push(`/category/${encodeURIComponent(this.props.category.name)}/brand/${encodeURIComponent(brandName)}#${channelHash}`);
  };

  render () {
    return (
      <div className="m-4">
        <div>
          { this.props.sixMonths.slice().reverse().map((month, i) => {
              return (
                <button
                  key={`bm-m${i}`}
                  type="button"
                  className= {
                    (this.props.selectedMonth && this.props.selectedMonth.label === month.label) ?
                      'mb-1 mr-1 btn btn-primary' :
                      'mb-1 mr-1 btn border bg-white'
                  }
                  onClick={() => this.selectMonth(month)}
                  style={{ width: '100px' }}
                >
                  {month.label}
                </button>
              )
            })
          }
        </div>
        <hr />
        <div>
          { this.state.channelScoresDataLoading &&
            <div className="text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !this.state.channelScoresDataLoading &&
            <div>
              { this.state.channelScoresData[this.props.selectedMonth.label] &&
                <Row>
                  { Object.entries(this.state.channelScoresData[this.props.selectedMonth.label]).map(([channel, scores]) => {
                      return (
                        <Col key={`c-${channel}`} xs={12} md={6} className="mb-4">
                          <div id={`category-${channel}`} className="border-bottom">
                            <div style={{ fontSize: '1.2rem' }}>
                              <img
                                className="mt-1 mr-2 align-top"
                                src="/images/Reviews_Detail_Icon.png"
                              />
                              <b>{channel}</b>
                              <div className="d-inline-block float-right">
                                <SharePopUpContainer
                                  shareElementId={`category-${channel}`}
                                  elementLabel={`category-${channel}-img`}
                                  position="top"
                                  contextType="category"
                                  contextCategory={this.props.category}
                                  contextBrand={null}
                                  contextChannel={channel}
                                  contextChartName={channel}
                                  contextDateRange={`${this.props.selectedMonth.label} ${this.props.selectedMonth.date.format('YYYY')}`}
                                />
                              </div>
                            </div>
                            <Table className="mt-1">
                              <thead className="bg-dark text-light">
                                <tr>
                                  <th className="py-1">Rank</th>
                                  <th className="py-1">Brand</th>
                                  <th className="py-1"></th>
                                  <th className="py-1 text-center">Attainment</th>
                                </tr>
                              </thead>
                              <tbody>
                                { scores.map((brandScore, i) => {
                                    return (
                                      <tr
                                        key={`c-${channel}-bs-${i}`}
                                        className={
                                          this.props.companyId === brandScore.companyId ?
                                            'text-bops-blue': ''
                                        }
                                      >
                                        <td>{i+1}</td>
                                        <td>
                                          <div
                                            onClick={() => this.onBrandChannelSelect(brandScore.brandName, channel)}
                                            style={{ cursor: 'pointer' }}
                                          >
                                            {brandScore.brandName}
                                          </div>
                                        </td>
                                        <td>
                                          <BoxScoreBar
                                            score={brandScore.score}
                                            maxScore={100}
                                          />
                                        </td>
                                        <td className="text-center">
                                          {brandScore.score}
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      )
                    })
                  }
                </Row>
              }
            </div>
          }
        </div>
      </div>
    );
  }
};
