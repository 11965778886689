import { connect } from 'react-redux';
import SinceEventDateModal from '../../components/common/SinceEventDateModal';

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SinceEventDateModal);
