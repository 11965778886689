import axios from 'axios';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { store } from '../../store/configureStore';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { reportError } from '../api/errors';
import history from '../../routers/history';

export const userLogin = (email, password) => {
  return (dispatch) => {
    axios.post(
      `${LISTEN_ENDPOINT}/api/login`,
      {
        email,
        password
      },
      HEADERS
    ).then(response => {
      const user = response.data;
      // Customer 312 should not have access as it is used for other purposes
      if (user.customer_id === 312) {
        dispatch(addUser({ 'error': 'Failed to login' }));
      } else{
        dispatch(updateUserAndCookie(user));
        if (user.first_login) {
          dispatch(updateShowRolePersonalizationSetup(true));
        }
      }
    }).catch(error => {
      if (error.response && error.response.data && error.response.data.message) {
        dispatch(addUser({ 'error': error.response.data.message }));
      } else {
        dispatch(addUser({ 'error': 'Failed to login' }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatch(reportError(error.response));
      }
    });
  };
};

export const ssoLogin = (token) => {
  return (dispatch) => {
    axios.post(
      `${LISTEN_ENDPOINT}/api/sso/login`,
      { token },
      HEADERS
    ).then(response => {
      const user = response.data;
      // Customer 312 should not have access as it is used for other purposes
      if (user.customer_id === 312) {
        dispatch(addUser({ 'error': 'Failed to login' }));
      } else{
        dispatch(updateUserAndCookie(user));
        if (user.first_login) {
          dispatch(updateShowRolePersonalizationSetup(true));
        }
      }
    }).catch(error => {
      if (error.response && error.response.data && error.response.data.message) {
        dispatch(addUser({ 'error': error.response.data.message }));
      } else {
        dispatch(addUser({ 'error': 'Failed to login via SSO' }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatch(reportError(error.response));
      }
    });
  };
};

export const userLogout = (user) => {
  return (dispatch) => {
    const cookies = new Cookies();
    cookies.remove('legendaryCXUser', { path: '/' });
    dispatch(removeUser());
    history.push('/login');
    if (user && user.id) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/sso/logout?customer_user_id=${user.id}`,
        HEADERS
      ).catch(error => {
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatch(reportError(error.response));
        }
      });
    }
  };
};

export const dispatchUserLogout = (user) => {
  store.dispatch(userLogout(user));
}

export const updateUserAndCookie = (user) => {
  return (dispatch) => {
    const userCookie = {
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      fullName: `${user.first_name} ${user.last_name}`,
      email: user.email,
      customerId: user.customer_id,
      isAdmin: (user.permission_level === 'admin' || user.permission_level === 'brandops admin'),
      role: user.permission_level,
      newFeatureFlag: user.email.includes('@brandops.io'),
      status: user.status,
    };

    const cookies = new Cookies();
    cookies.set('legendaryCXUser', userCookie, { path: '/', maxAge: 2592000 });
    cookies.set('statusLastChecked', moment().unix(), { path: '/', maxAge: 86400 });
    dispatch(addUser(userCookie));
  };
};

export const handleUpdateRepresentedCustomer = (user, customerId, companyName) => {
  return (dispatch) => {
    const userCookie = {
      id: user.id,
      email: user.email,
      customerId: customerId,
      isAdmin: user.isAdmin,
      role: user.role,
      newFeatureFlag: user.email.includes('@brandops.io'),
      status: user.status,
      representedCompanyName: companyName
    };

    const cookies = new Cookies();
    cookies.set('legendaryCXUser', userCookie, { path: '/', maxAge: 2592000 });
    dispatch(addUser(userCookie));
  };
};

export const updateShowRolePersonalizationSetup = (showRolePersonalizationSetup) => ({
  type: 'UPDATE_SHOW_ROLE_PERSONALIZATION_SETUP',
  showRolePersonalizationSetup
});

export const updateShowNewUserGuidedTour = (showNewUserGuidedTour) => ({
  type: 'UPDATE_SHOW_NEW_USER_GUIDED_TOUR',
  showNewUserGuidedTour
});

export const addUser = (user) => ({
  type: 'ADD_USER',
  user
});

export const removeUser = () => ({
  type: 'REMOVE_USER'
});
