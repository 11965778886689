import React from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { GKG_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CompanySearchComboBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  loadCompanyOptions = (searchInput) => {
    if (searchInput.length > 3) {
      return axios.get(
        `${GKG_ENDPOINT}/entities:search?query=${searchInput}&types=Corporation`,
        HEADERS
      ).then(response => {
        const results = response.data;
        const companyOptions = [{
          value: searchInput,
          label: searchInput,
          companyResult: {
            name: searchInput
          }
        }];
        for (let result of results.itemListElement) {
          companyOptions.push({
            value: result.result.name,
            label: result.result.name,
            companyResult: result.result
          });
        }
        return companyOptions;
      }).catch(error => {
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    } else {
      const companyOptions = [{
        value: searchInput,
        label: searchInput,
        companyResult: {
          name: searchInput
        }
      }];
      return Promise.resolve(companyOptions);
    }
  };

  render() {
    return (
      <AsyncSelect
        cacheOptions
        className={
          this.props.isInvalid ?
            'border border-danger rounded':
            ''
        }
        value={this.props.value}
        loadOptions={this.loadCompanyOptions}
        onChange={this.props.onSelect}
        placeholder={this.props.placeholder || "Search for companies..."}
      />
    )
  }
};
