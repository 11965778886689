import moment from 'moment';

export default function startDateFilterReducer(state = moment().utc().subtract(30, 'day').startOf('day'), action) {
  switch (action.type) {
    case 'UPDATE_START_DATE_FILTER':
      return action.startDate;
    default:
      return state
  }
};
