import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../../common/CardComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '../../../utils/numbers';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class GoogleAnalyticsUsageStatistics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      webAnalyticsPropertyStats: {},
      webAnalyticsPropertyStatsLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchGoogleAnalyticsPropertyStats();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.companyId !== this.props.companyId) {
      this.fetchGoogleAnalyticsPropertyStats();
    }
  };

  fetchGoogleAnalyticsPropertyStats = () => {
    if (this.props.companyId) {
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/ga-property-url-stats-trends?company_id=${this.props.companyId}`,
        HEADERS
      ).then(response => {
        let webAnalyticsPropertyStats = {};
        if (Array.isArray(response.data) && response.data.length > 0) {
          webAnalyticsPropertyStats = response.data[0];
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            webAnalyticsPropertyStats,
            webAnalyticsPropertyStatsLoading: false,
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch web analytics usage statistics data...`);
        if (this.state.isMounted) {
          this.setState(() => ({
            webAnalyticsPropertyStats: {},
            webAnalyticsPropertyStatsLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  render () {
    return (
      <div className="my-2">
        <CardComponent
          title="Usage Statistics - Last 30 days"
          icon={
            <FontAwesomeIcon
              className="mr-2"
              icon={faChartLine}
              color="#4fa2f3"
            />
          }
          body={
            <div>
              { this.state.webAnalyticsPropertyStatsLoading &&
                <div className="m-4 text-center">
                  <ClipLoader size={100}/>
                </div>
              }
              { !this.state.webAnalyticsPropertyStatsLoading &&
                <Row>
                  <Col xs={6}>
                    <div>
                      <div
                        className="d-inline-block text-right mr-2"
                        style={{ width: '260px' }}
                      >
                        Sessions:
                      </div>
                      <div className="d-inline-block">
                        {
                          this.state.webAnalyticsPropertyStats.sum_sessions != null ?
                            numberWithCommas(this.state.webAnalyticsPropertyStats.sum_sessions) :
                            '-'
                        }
                      </div>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div>
                      <div
                        className="d-inline-block text-right mr-2"
                        style={{ width: '260px' }}
                      >
                        Total Users:
                      </div>
                      <div className="d-inline-block">
                        {
                          this.state.webAnalyticsPropertyStats.sum_total_users != null ?
                            numberWithCommas(this.state.webAnalyticsPropertyStats.sum_total_users) :
                            '-'
                        }
                      </div>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div>
                      <div
                        className="d-inline-block text-right mr-2"
                        style={{ width: '260px' }}
                      >
                        Engaged Sessions:
                      </div>
                      <div className="d-inline-block">
                        {
                          this.state.webAnalyticsPropertyStats.sum_engaged_sessions != null ?
                            numberWithCommas(this.state.webAnalyticsPropertyStats.sum_engaged_sessions) :
                            '-'
                        }
                      </div>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div>
                      <div
                        className="d-inline-block text-right mr-2"
                        style={{ width: '260px' }}
                      >
                        New Users:
                      </div>
                      <div className="d-inline-block">
                        {
                          this.state.webAnalyticsPropertyStats.total_new_users != null ?
                            numberWithCommas(this.state.webAnalyticsPropertyStats.total_new_users) :
                            '-'
                        }
                      </div>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div>
                      <div
                        className="d-inline-block text-right mr-2"
                        style={{ width: '260px' }}
                      >
                        Avg Session (in seconds):
                      </div>
                      <div className="d-inline-block">
                        {
                          this.state.webAnalyticsPropertyStats.average_session_duration != null ?
                            numberWithCommas(this.state.webAnalyticsPropertyStats.average_session_duration) :
                            '-'
                        }
                      </div>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div>
                      <div
                        className="d-inline-block text-right mr-2"
                        style={{ width: '260px' }}
                      >
                        Page Views:
                      </div>
                      <div className="d-inline-block">
                        {
                          this.state.webAnalyticsPropertyStats.total_screen_page_views != null ?
                            numberWithCommas(this.state.webAnalyticsPropertyStats.total_screen_page_views) :
                            '-'
                        }
                      </div>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div>
                      <div
                        className="d-inline-block text-right mr-2"
                        style={{ width: '260px' }}
                      >
                        Avg User Engagement (in seconds):
                      </div>
                      <div className="d-inline-block">
                        {
                          this.state.webAnalyticsPropertyStats.avg_user_engagement_duration != null ?
                            numberWithCommas(this.state.webAnalyticsPropertyStats.avg_user_engagement_duration) :
                            '-'
                        }
                      </div>
                    </div>
                  </Col>
                </Row>
              }
            </div>
          }
          contextType="brand"
          contextCategory={this.props.category}
          contextBrand={this.props.brand}
          contextChannel="Web Analytics"
          contextChartName="Usage Statistics - Last 30 days"
          contextDateRange={`${moment().subtract(30, 'days').format('MM/DD/YYYY')} - ${moment().format('MM/DD/YYYY')}`}
        />
      </div>
    );
  }
};
