import axios from 'axios';
import { sortAlphabeticalByKey } from '../../utils/sorts';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { reportError } from '../api/errors';

export const fetchCategories = (customerId) => {
  return (dispatch) => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/companies/${customerId}/categories`,
      HEADERS
    ).then(response => {
      const categoriesData = response.data;
      const categories = [];
      const myCategoryBrands = [];
      // add private categories
      for (let pc of categoriesData.private_categories) {
        const privateCategory = {
          id: pc.id,
          name: pc.name,
          description: pc.description,
          market_stage_id: pc.market_stage_id,
          audience_profile_id: pc.audience_profile_id,
          baseline_date: pc.baseline_date,
          category_type: 'private',
          created_at: pc.created_at,
          product_brands: []
        };

        for (let pcpb of pc.product_brands) {
          const brand = {
            id: pcpb.product_brand_id,
            company_id: pcpb.company_id,
            company_name: pcpb.company_name,
            company_url: pcpb.company_url,
            company_favicon_url: pcpb.company_favicon_url,
            company_logo_url: pcpb.company_logo_url,
            description: pcpb.description,
            name: pcpb.name,
            position_in_stage: pcpb.position_in_stage,
            product_url: pcpb.product_url,
            category_id: pc.id,
            category_type: 'private',
            category_product_brand_id: pcpb.private_category_product_brand_id,
            topic: pcpb.topic,
            sponsor_customer_id: pcpb.sponsor_customer_id
          };
          privateCategory.product_brands.push(brand);

          if (customerId === brand.company_id) {
            myCategoryBrands.push({
              label: `${privateCategory.name} - ${brand.name}`,
              selectionId: `${privateCategory.category_type}-${privateCategory.id}-${brand.id}`,
              category: privateCategory,
              brand,
            });
          }
        }
        categories.push(privateCategory);
      }
      // Alphabetize categories
      categories.sort(sortAlphabeticalByKey('name'));
      dispatch(addCategories(categories));

      myCategoryBrands.sort(sortAlphabeticalByKey('label'));
      dispatch(addMyCategoryBrands(myCategoryBrands));
    }).catch(error => {
      console.log('Error: unable to fetch company categories');
      dispatch(addCategories([]));
      dispatch(addMyCategoryBrands([]));
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatch(reportError(error.response));
      }
    });
  };
};

export const addCategories = (categories) => ({
  type: 'ADD_CATEGORIES',
  categories
});

export const addMyCategoryBrands = (myCategoryBrands) => ({
  type: 'ADD_MY_CATEGORY_BRANDS',
  myCategoryBrands
});
