import React from 'react';
import moment from 'moment';
import CardComponent from '../common/CardComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '../../utils/numbers';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

const VideoViewsGraph = (props) => (
  <CardComponent
    title="Net New Views"
    icon={
      <FontAwesomeIcon
        className="mr-2"
        icon={faEye}
        color="#4fa2f3"
      />
    }
    description="Views for the video"
    body={
      <ResponsiveContainer width="99%" height={250}>
        <BarChart data={props.captureData}>
          <XAxis
            dataKey="captured_at"
            tickFormatter={(date) => {
              return moment(date).endOf('day').format('MM/DD');
            }}
            minTickGap={20}
          />
          <YAxis
            tick={{ fontSize: 12 }}
            tickFormatter={(count) => {
              return numberWithCommas(count);
            }}
            width={85}
            domain={[0, 'auto']}
            allowDecimals={false}
          />
          <Tooltip
            labelFormatter={(label) => moment(label).format('dddd, MMMM Do YYYY')}
            formatter={(value) => numberWithCommas(value)}
          />
        <Bar name="Net New Views" dataKey="net_new_views" fill="#007bff"/>
        </BarChart>
      </ResponsiveContainer>
    }
    contextType="brand"
    contextCategory={props.category}
    contextBrand={props.brand}
    contextChannel="Youtube"
    contextProperty="Video Stats"
    contextChartName="Net New Views"
  />
);

export default VideoViewsGraph;
