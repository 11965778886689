import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

export default class Event extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  render () {
    if (this.props.event) {
      return (
        <div>
          <Form.Row>
          { this.props.favIcon &&
            <div className="d-inline-block pl-2">
              <img
                src={this.props.favIcon}
                alt=""
                crossOrigin="anonymous"
                width="20"
                height="20"
              />
            </div>
          }
          <div className="pl-2 mt-1" style={{ fontSize: '10pt' }}>
            <b>{this.props.event.summary}</b>
          </div>
          { this.props.event.customer_id == this.props.customerId &&
            <Col>
            <Dropdown>
              <Dropdown.Toggle
                className="float-right"
                as="div"
                bsPrefix="none"
              >
                <Button variant="light" size="sm">
                  <FontAwesomeIcon icon={faEllipsisH} style={{ cursor: 'pointer' }} color="#5f666d"/>
                </Button>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => this.props.enableEditEvent(this.props.event.id)}
                >
                  <FontAwesomeIcon className="mr-2" icon={faPen} />
                    Edit
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => this.props.deleteEvent(this.props.event.id)}
                >
                  <FontAwesomeIcon className="mr-2" icon={faTrash} />
                    Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            </Col>
          }
          </Form.Row>
        </div>
      )
    };
  }
};
