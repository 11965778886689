import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClipLoader from 'react-spinners/ClipLoader';
import DateRangePicker from '../common/DateRangePicker';
import { RegionMultiSelect } from '../common/RegionMultiSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper, faLink } from '@fortawesome/free-solid-svg-icons';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryNewsDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryNews: [],
      categoryNewsLoading: false,
      categoryNewsResults: [],
      selectedRegions: [
        { label: 'Americas', value: 'americas' },
        { label: 'APAC', value: 'apac' },
        { label: 'EMEA', value: 'emea' },
      ],
      startDate: moment().utc().subtract(1, 'year').startOf('day'),
      endDate: moment().utc().endOf('day'),
      selectedArticle: undefined,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchCategoryNews();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.category !== this.props.category ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      this.fetchCategoryNews();
    }
  };

  fetchCategoryNews = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryNewsLoading: true }));
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/category-news-articles?private_category_id=${this.props.category.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        const categoryNews = response.data;
        categoryNews.sort((a, b) => moment(b.publish_date) - moment(a.publish_date))
        if (this.state.isMounted) {
          this.setState(() => ({
            categoryNews,
            categoryNewsLoading: false,
          }), () => this.updateCategoryNewsResults());
        }
      }).catch(error => {
        console.error('Error: failed to fetch news articles');
        if (this.state.isMounted) {
          this.setState(() => ({
            categoryNews: [],
            categoryNewsLoading: false,
          }), () => this.updateCategoryNewsResults());
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  updateCategoryNewsResults = () => {
    let categoryNewsResults = [];
    const selectedRegionsSet = new Set();
    for (const region of this.state.selectedRegions) {
      if (region.value === 'americas') {
        // americas includes 2 regions
        selectedRegionsSet.add('na');
        selectedRegionsSet.add('latam');
      } else {
        selectedRegionsSet.add(region.value);
      }
    }

    for (const article of this.state.categoryNews) {
      if (selectedRegionsSet.has(article.region)) {
        categoryNewsResults.push(article);
      }
    }

    let selectedArticle;
    if (categoryNewsResults.length > 0) {
      selectedArticle = categoryNewsResults[0];
    }

    if (this.state.isMounted) {
      this.setState(() => ({
        categoryNewsResults,
        selectedArticle
      }));
    }
  };

  selectNewsArticle = (selectedArticle) => {
    if (selectedArticle !== this.state.selectedArticle) {
      this.setState(() => ({ selectedArticle }));
    }
  };

  onRegionSelectChange = (selectedRegions) => {
    this.setState(
      () => ({ selectedRegions }),
      () => this.updateCategoryNewsResults()
    );
  };

  onFilterDatesChange = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  };

  render () {
    return (
      <div>
        <h5 className="mb-2 p-2 bg-bops-blue text-light">
          Details
          <span
            className="ml-4 mr-2"
            style={{ fontSize: '1rem', fontWeight: 'normal' }}
          >Date Range</span>
          <DateRangePicker
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            updateDates={this.onFilterDatesChange}
          />
          <div
            className="d-inline-block ml-4"
            style={{ fontSize: '.875rem', fontWeight: 'normal', color: '#000000' }}
          >
            <RegionMultiSelect
              value={this.state.selectedRegions}
              onChange={this.onRegionSelectChange}
            />
          </div>
        </h5>
        { this.state.categoryNewsLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.categoryNewsLoading &&
          <Row className="pt-2">
            <Col xs={12} md={3}>
              <div
                className="pre-scrollable container px-0 bg-white border rounded"
                style={{
                  overflow: 'auto',
                  fontSize: '10pt',
                  minHeight: 'calc(100vh - 270px)',
                  maxHeight: 'calc(100vh - 270px)',
                }}
              >
                { this.state.categoryNewsResults.map((article, i) => {
                    return (
                      <div
                        key={`na-${i}`}
                        className={
                          (this.state.selectedArticle && this.state.selectedArticle.id === article.id) ?
                            'px-2 bg-primary text-light rounded' :
                            'px-2'
                        }
                        onClick={() => this.selectNewsArticle(article)}
                        style={{ cursor: 'pointer' }}
                      >
                        <div className="py-2">
                          <div
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faNewspaper}
                            />
                            <span
                              dangerouslySetInnerHTML={{ __html: article.title }}
                            />
                          </div>
                          <div>
                            <div className="d-inline-block mr-2">
                              {moment.utc(article.publish_date).format('MM/DD')}
                            </div>
                            <div className="d-inline-block">
                              {article.domain}
                            </div>
                          </div>
                        </div>
                        <hr className="m-0"/>
                      </div>
                    )
                  })
                }
              </div>
            </Col>
            <Col xs={12} md={9}>
              { this.state.selectedArticle &&
                <div className="bg-white rounded border p-2">
                  <div className="p-2">
                    <div>
                      <div
                        className="d-inline-block ellipsis"
                        dangerouslySetInnerHTML={{ __html: this.state.selectedArticle.title }}
                        style={{
                          fontWeight: 'bold',
                          maxWidth: 'calc(100% - 30px)'
                        }}
                      />
                      { this.state.selectedArticle.link &&
                        <FontAwesomeIcon
                          className="ml-2 mt-1 align-top"
                          icon={faLink}
                          color="#4fa2f3"
                          onClick={() => window.open(this.state.selectedArticle.link, '_blank')}
                          style={{ cursor: 'pointer' }}
                        />
                      }
                    </div>
                  </div>
                  <hr className="m-0"/>
                  <div
                    className="p-2 container"
                    style={{
                      fontSize: '11pt',
                      whiteSpace: 'pre-wrap',
                      overflow: 'auto',
                      minHeight: 'calc(100vh - 335px)',
                      maxHeight: 'calc(100vh - 335px)',
                    }}
                    dangerouslySetInnerHTML={{ __html: this.state.selectedArticle.article_text }}
                  />
                </div>
              }
            </Col>
          </Row>
        }
      </div>
    );
  }
};
