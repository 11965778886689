import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import LoginContainer from '../../containers/common/LoginContainer';
import AdminAuthenticationMessage from '../common/AdminAuthenticationMessage';
import CustomerObjectives from './CustomerObjectives';
import { isAuthenticatedAdmin } from '../../utils/auth';
import history from '../../routers/history';

export default class ObjectivesAndGoals extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'objectivesAndGoals'
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let selectedTab = 'objectivesAndGoals';
      if (hash) {
        selectedTab = hash;
      }
      this.setState(() => ({ selectedTab }));
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  changeSelectedTab = (selectedTab) => {
    history.push(`#${selectedTab}`);
    this.setState(() => ({ selectedTab }));
  }

  render () {
    return (
      <div>
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        <AdminAuthenticationMessage user={this.props.user}/>
        { isAuthenticatedAdmin(this.props.user) &&
          <Tabs
            activeKey={this.state.selectedTab}
            onSelect={(selectedTab) => this.changeSelectedTab(selectedTab)}
          >
            <Tab
              eventKey="objectivesAndGoals"
              title="Objectives & Goals"
            >
              <div
                className="pre-scrollable"
                style={{
                  minHeight: 'calc(100vh - 100px)',
                  maxHeight: 'calc(100vh - 100px)',
                  overflow: 'auto',
                  overflowX: 'hidden'
                }}
              >
                <CustomerObjectives
                  user={this.props.user}
                  myCategoryBrands={this.props.myCategoryBrands}
                />
              </div>
            </Tab>
          </Tabs>
        }
      </div>
    );
  }
};
