import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { isAuthenticatedAdmin } from '../../utils/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const AdminAuthenticationMessage = (props) => {
  if (props.user && props.user.id) {
    if (!isAuthenticatedAdmin(props.user)) {
      return (
        <div>
          <Alert className="mt-4" variant="warning">
            <FontAwesomeIcon
              className="mr-2"
              icon={faLock}
            />
            Only administrators have access to this content. Please contact your administrator.
          </Alert>
        </div>
      )
    } else {
      return '';
    }
  }
   else {
     return '';
   }
};

export default AdminAuthenticationMessage;
