import React from 'react';
import CategoryWebSummary from './CategoryWebSummary';
import CategorySemrushDomainRankGraph from './CategorySemrushDomainRankGraph';
import CategoryWebShareOfGraphs from './CategoryWebShareOfGraphs';

export default class CategoryWebExternal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevProps.categoryId !== this.props.categoryId
    ) {

    }
  };

  render () {
    return (
      <div>
        <CategoryWebSummary
          user={this.props.user}
          sixMonths={this.props.sixMonths}
          selectedMonth={this.props.selectedMonth}
          updateSelectedMonth={this.props.updateSelectedMonth}
          category={this.props.category}
          categoryId={this.props.categoryId}
        />
        <div className="mt-5 mb-4 band-separator" />
        <div>
          <CategorySemrushDomainRankGraph
            user={this.props.user}
            category={this.props.category}
          />
        </div>
        <div className="mt-4">
          <CategoryWebShareOfGraphs
            category={this.props.category}
          />
        </div>
      </div>
    );
  }
};
