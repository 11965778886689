import { connect } from 'react-redux';
import Home from '../../components/common/Home';
import { fetchAlerts } from '../../actions/alerts/alerts';

const mapStateToProps = (state) => ({
  user: state.user,
  categories: state.categories,
  alerts: state.alerts,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAlerts: (customerId) => dispatch(fetchAlerts(customerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
