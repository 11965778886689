import { connect } from 'react-redux';
import SlackSetup from '../../../components/settings/contentCollectors/SlackSetup';
import { fetchSlackChannels } from '../../../actions/slack/slackChannels';

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  fetchSlackChannels: (companyId) => dispatch(fetchSlackChannels(companyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SlackSetup);
