import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Table from 'react-bootstrap/Table';
import ClipLoader from 'react-spinners/ClipLoader';
import GeneralSummaryGraph from '../../graphs/GeneralSummaryGraph';
import { intToShorthandString } from '../../../utils/numbers';
import { capitalizeFirstLetter } from '../../../utils/strings';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class GoogleAdsAdGroupTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adGroups: [],
      adGroupsLoading: false,
      selectedAdGroupId: undefined,
      selectedAdGroup: undefined,
      adGroupMetrics: [],
      adGroupMetricsLoading: false,
      adGroupAds: [],
      adGroupAdsLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchAdGroups();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.campaignId !== this.props.campaignId) {
      this.fetchAdGroups();
    }
  };

  fetchAdGroups = () => {
    if (this.props.campaignId) {
      this.setState(() => ({ adGroupsLoading: true }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/google-ad-groups?campaign_id=${this.props.campaignId}`,
        HEADERS
      ).then(response => {
        const adGroups = response.data;
        adGroups.sort((a, b) => {
          if (a.status === 'ENABLED' && b.status !== 'ENABLED') return -1;
          if (a.status !== 'ENABLED' && b.status === 'ENABLED') return 1;
          if (
            a.status === 'ENABLED' && b.status === 'ENABLED' ||
            a.status !== 'ENABLED' && b.status !== 'ENABLED'
          ) {
            // alphabetize
            if (a.ad_group_name.toLowerCase() < b.ad_group_name.toLowerCase()) return -1;
            if (a.ad_group_name.toLowerCase() > b.ad_group_name.toLowerCase()) return 1;
          }
          return 0;
        });
        if (this.state.isMounted) {
          this.setState(() => ({
            adGroups,
            adGroupsLoading: false,
          }), () => this.onAdGroupSelect(adGroups[0]));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch google ad groups`);
        if (this.state.isMounted) {
          this.setState(() => ({
            adGroups: [],
            adGroupsLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onAdGroupSelect = (adGroup) => {
    if (adGroup && adGroup.ad_group_id !== this.state.selectedAdGroupId) {
      this.setState(() => ({
        selectedAdGroupId: adGroup.ad_group_id,
        selectedAdGroup: adGroup
      }), () => this.fetchAdGroupData());
    }
  };

  fetchAdGroupData = () => {
    this.fetchAdGroupMetrics();
    this.fetchAdGroupAds();
  };

  fetchAdGroupMetrics = () => {
    if (this.state.selectedAdGroup) {
      this.setState(() => ({ adGroupMetricsLoading: true }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/ad-groups/${this.state.selectedAdGroup.ad_group_id}/ad-group-metric-trend`,
        HEADERS
      ).then(response => {
        const adGroupMetrics = response.data.monthly_stats;
        for (const metrics of adGroupMetrics) {
          metrics.total_clicks_label = metrics.total_clicks;
          if (!metrics.total_impressions) {
            metrics.total_clicks_label = `0 (0%)`;
          } else {
            metrics.total_clicks_label = `${intToShorthandString(metrics.total_clicks)} (${Math.round((metrics.total_clicks / metrics.total_impressions) * 100)}%)`;
          }
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            adGroupMetrics,
            adGroupMetricsLoading: false,
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch google ad group metric data`);
        if (this.state.isMounted) {
          this.setState(() => ({
            adGroupMetrics: [],
            adGroupMetricsLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchAdGroupAds = () => {
    if (this.state.selectedAdGroup) {
      this.setState(() => ({ adGroupAdsLoading: true }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/google-ad-group-ads?ad_group_id=${this.state.selectedAdGroup.ad_group_id}`,
        HEADERS
      ).then(response => {
        const adGroupAds = [];
        if (response.data.responsive_ads) {
          for (const ad of response.data.responsive_ads) {
            ad.ad_type = 'Responsive';
            try {
              ad.status = capitalizeFirstLetter(ad.status.toLowerCase());
            } catch (error) {
              // do nothing
            }
            adGroupAds.push(ad);
          }
        }
        if (response.data.text_ads) {
          for (const ad of response.data.text_ads) {
            ad.ad_type = 'Text';
            ad.description = ad.description1 || ad.description2;
            try {
              ad.status = capitalizeFirstLetter(ad.status.toLowerCase());
            } catch (error) {
              // do nothing
            }
            adGroupAds.push(ad);
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            adGroupAds,
            adGroupAdsLoading: false,
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch google ad group ads`);
        if (this.state.isMounted) {
          this.setState(() => ({
            adGroupAds: [],
            adGroupAdsLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="my-2">
        { this.state.adGroupsLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.adGroupsLoading &&
          <div>
            <h5 className="mb-2 p-2 bg-bops-blue text-light">
              <span
                className="mr-2"
                style={{ fontSize: '1rem', fontWeight: 'normal' }}
              >
                Ad Group
              </span>
              <div className="d-inline-block">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    style={{ cursor: 'pointer' }}
                  >
                    { this.state.selectedAdGroup &&
                      <div className="d-inline-block mr-1">
                        <div
                          className="d-inline-block mr-1"
                          style={{
                            position: 'relative',
                            top: '2px',
                            width: '16px',
                            height: '16px',
                            backgroundColor: this.state.selectedAdGroup.status === 'ENABLED' ?
                              '#28a745' : '#6c757d',
                            borderRadius: '50%'
                          }}
                        />
                        {this.state.selectedAdGroup.ad_group_name}
                      </div>
                    }
                    { !this.state.selectedAdGroup &&
                      <div className="d-inline-block">
                        Select Ad Group
                      </div>
                    }
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    { this.state.adGroups.map((adGroup, i) => {
                        return (
                          <Dropdown.Item
                            key={`ga-ag-${i}`}
                            className="px-2"
                            onClick={() => this.onAdGroupSelect(adGroup)}
                          >
                            <div
                              className="d-inline-block mr-1"
                              style={{
                                position: 'relative',
                                top: '2px',
                                width: '16px',
                                height: '16px',
                                backgroundColor: adGroup.status === 'ENABLED' ?
                                  '#28a745' : '#6c757d',
                                borderRadius: '50%'
                              }}
                            />
                            {adGroup.ad_group_name}
                          </Dropdown.Item>
                        )
                      })
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </h5>
            <Row>
              <Col xs={12} lg={6}>
                <div className="mb-2">
                  <GeneralSummaryGraph
                    title="Total Clicks"
                    data={this.state.adGroupMetrics}
                    dataKey="total_clicks"
                    dataLabel="Total Clicks"
                    barLabelKey="total_clicks_label"
                    customValues={true}
                    loading={this.state.adGroupMetricsLoading}
                    allowDecimals={false}
                    contextType="brand"
                    contextCategory={this.state.category}
                    contextBrand={this.state.brand}
                    contextChannel="Google Ads - Ad Group"
                    contextChartName="Total Clicks"
                  />
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <div className="mb-2">
                  <GeneralSummaryGraph
                    title="Total Impressions"
                    data={this.state.adGroupMetrics}
                    dataKey="total_impressions"
                    dataLabel="Total Impressions"
                    loading={this.state.adGroupMetricsLoading}
                    allowDecimals={false}
                    contextType="brand"
                    contextCategory={this.state.category}
                    contextBrand={this.state.brand}
                    contextChannel="Google Ads - Ad Group"
                    contextChartName="Total Impressions"
                  />
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <div className="mb-2">
                  <GeneralSummaryGraph
                    title="Total Interactions"
                    data={this.state.adGroupMetrics}
                    dataKey="total_interactions"
                    dataLabel="Total Interactions"
                    loading={this.state.adGroupMetricsLoading}
                    allowDecimals={false}
                    contextType="brand"
                    contextCategory={this.state.category}
                    contextBrand={this.state.brand}
                    contextChannel="Google Ads - Ad Group"
                    contextChartName="Total Interactions"
                  />
                </div>
              </Col>
            </Row>
            <h5 className="mb-2 p-2 bg-bops-blue text-light">Ads</h5>
            { this.state.adGroupAdsLoading &&
              <div className="m-4 text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.adGroupAdsLoading &&
              <Table
                className="fixed-header mb-0"
                size="sm"
                striped
                bordered
              >
                <thead className="bg-dark text-light text-center">
                  <tr>
                    <th className="border-left-0 border-right-0">
                      Asset
                    </th>
                    <th className="border-left-0 border-right-0">
                      Ad Type
                    </th>
                    <th className="border-left-0 border-right-0">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody
                  style={{
                    minHeight: '200px',
                    maxHeight: '400px'
                  }}
                >
                  { this.state.adGroupAds.map((ad, i) => {
                      return (
                        <tr key={`ga-ag-a-${i}`}>
                          <td>
                            <div>
                              <div className="font-weight-bold">
                                {ad.headline}
                              </div>
                              { ad.description &&
                                <div className="mt-2">
                                  {ad.description}
                                </div>
                              }
                            </div>
                          </td>
                          <td className="text-center">
                            {ad.ad_type}
                          </td>
                          <td className="text-center">
                            {ad.status}
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            }
          </div>
        }
      </div>
    );
  }
};
