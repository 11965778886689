import React from 'react';
import axios from 'axios';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Toast from 'react-bootstrap/Toast';
import BrandContributions from './BrandContributions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEllipsisH, faTrash, faCheck } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'categorySetup',
      marketStageId: 1,
      name: '',
      nameChanged: false,
      description: '',
      descriptionChanged: false,
      audienceProfileId: 2,
      audienceProfileIdChanged: false,
      showToast: false,
      errors: {},
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.setForm();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedCategory !== this.props.selectedCategory) {
      this.setForm();
    }
  };

  setForm = () => {
    if (this.props.selectedCategory) {
      let name = this.props.selectedCategory.name;
      if (this.props.selectedCategory.category_type === 'extended') {
        name = this.props.selectedCategory.extended_category_name;
      }

      this.setState(() => ({
        marketStageId: this.props.selectedCategory.market_stage_id,
        name,
        nameChanged: false,
        description: this.props.selectedCategory.description || '',
        descriptionChanged: false,
        audienceProfileId: this.props.selectedCategory.audience_profile_id,
        audienceProfileIdChanged: false,
        errors: {},
        errorMessage: '',
      }));
    }
  };

  onMarketStageChange = (event) => {
    const marketStageId = event.currentTarget.value;
    this.setState(() => ({ marketStageId }));
  };

  onNameChange = (event) => {
    const name = event.currentTarget.value;
    this.setState(() => ({ name, nameChanged: true }));
  };

  onDescriptionChange = (event) => {
    const description = event.currentTarget.value;
    this.setState(() => ({ description, descriptionChanged: true }));
  };

  onAudienceProfileChange = (audienceProfileId) => {
    if (audienceProfileId !== this.state.audienceProfileId) {
      this.setState(() => ({ audienceProfileId, audienceProfileIdChanged: true }));
    }
  }

  validForm = () => {
    const errors = {};
    if (!this.state.name || this.state.name.trim() === '') {
      errors.name = true;
    }

    this.setState(() => ({ errors }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  saveCategory = () => {
    if (this.validForm()) {
      if (this.props.selectedCategoryType === 'extended') {
        const extendedCategory = {
          name: this.state.name,
          audience_profile_id: this.state.audienceProfileId
        };
        if (this.state.description) {
          extendedCategory.description = this.state.description;
        } else {
          extendedCategory.description = null;
        }

        if (this.props.selectedCategory) {
          // Update
          axios.put(
            `${LISTEN_ENDPOINT}/api/extended-categories/${this.props.selectedCategory.id}`,
            extendedCategory,
            HEADERS
          ).then(response => {
            this.setState(() => ({ showToast: true, nameChanged: false, descriptionChanged: false }));
            this.props.fetchCompanyCategories(this.props.companyId);
          }).catch(error => {
            console.log('Error: unable to update extended category');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
          });
        }
      } else if (this.props.selectedCategoryType === 'private') {
        const privateCategory = {
          company_id: this.props.companyId,
          name: this.state.name,
          market_stage_id: this.state.marketStageId,
          audience_profile_id: this.state.audienceProfileId
        };
        if (this.state.description) {
          privateCategory.description = this.state.description;
        } else {
          privateCategory.description = null;
        }

        if (this.props.selectedCategory) {
          // Update
          axios.put(
            `${LISTEN_ENDPOINT}/api/private-categories/${this.props.selectedCategory.id}`,
            privateCategory,
            HEADERS
          ).then(response => {
            this.setState(() => ({ showToast: true, nameChanged: false, descriptionChanged: false }));
            this.props.fetchCompanyCategories(this.props.companyId);
          }).catch(error => {
            console.log('Error: unable to update private category');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
          });
        }
      }
    }
  };

  render () {
    return (
      <div className="px-2">
        <Tabs
          activekey={this.state.selectedTab}
          onSelect={(selectedTab) => this.setState(() => ({ selectedTab }))}
        >
          <Tab
            eventKey="categorySetup"
            title="Category Setup"
          >
            <Form className="mt-2">
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={this.state.name}
                      onChange={this.onNameChange}
                      isInvalid={this.state.errors.name}
                    />
                  </Form.Group>
                </Col>
                <Col>
                </Col>
              </Form.Row>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="5"
                  value={this.state.description}
                  onChange={this.onDescriptionChange}
                  isInvalid={this.state.errors.description}
                />
              </Form.Group>
              <Button
                variant="primary"
                onClick={this.saveCategory}
                disabled={!this.state.nameChanged && !this.state.descriptionChanged}
              >
                Save
              </Button>
            </Form>
            <div className="my-4 band-separator" />
            <div className="mb-4">
              <h5> Category Audience Size </h5>
              <p>
                BrandOps scores and compares brands in a category using benchmarks that are primarily based on revenue and target audience size.
              </p>
              <div>
                <b>
                  Select the largest audience size that fits for most brands in this category.
                </b>
              </div>
              <Form>
                <Form.Group>
                  <Form.Row className="pt-3">
                    <Col xs={3} sm={2} className="px-2 mt-1">
                      <Button
                        style={{ minWidth: "85px" }}
                        variant={
                          this.state.audienceProfileId === 1 ?
                            'info' :
                            'outline-info'
                        }
                        onClick={() => this.onAudienceProfileChange(1)}
                      >
                        Small
                      </Button>
                    </Col>
                    <Col>
                      <div className="text-left">
                        Less than $200 million in revenue or are building awareness with fewer than 20,000 people.
                      </div>
                    </Col>
                  </Form.Row>
                  <Form.Row className="pt-3">
                    <Col xs={3} sm={2} className="px-2 mt-1">
                      <Button
                        style={{ minWidth: "85px" }}
                        variant={
                          this.state.audienceProfileId === 2 ?
                            'info' :
                            'outline-info'
                        }
                        onClick={() => this.onAudienceProfileChange(2)}
                      >
                        Medium
                      </Button>
                    </Col>
                    <Col>
                      <div className="text-left">
                        Less than $1 billion in revenue or building awareness with fewer than 100,000 people.
                      </div>
                    </Col>
                  </Form.Row>
                  <Form.Row className="pt-3">
                    <Col xs={3} sm={2} className="px-2 mt-1">
                      <Button
                        style={{ minWidth: "85px" }}
                        variant={
                          this.state.audienceProfileId === 3 ?
                            'info' :
                            'outline-info'
                        }
                        onClick={() => this.onAudienceProfileChange(3)}
                      >
                        Large
                      </Button>
                    </Col>
                    <Col>
                      <div className="text-left">
                        More than $1 billion in revenue or building awareness with more than 100,000 people.
                      </div>
                    </Col>
                  </Form.Row>
                </Form.Group>
                <Button
                  variant="primary"
                  onClick={this.saveCategory}
                  disabled={!this.state.audienceProfileIdChanged}
                >
                  Save
                </Button>
              </Form>
            </div>
            <div className="my-4 band-separator" />
            <div>
              <h5> Brand Contributions </h5>
              <BrandContributions
                customerId={this.props.companyId}
                selectedCategory={this.props.selectedCategory}
              />
            </div>

            <Toast
              onClose={() => this.setState(() => ({ showToast: false }))}
              show={this.state.showToast}
              delay={2000}
              autohide
              style={{
                position: 'absolute',
                top: 0,
                right: 0
              }}
            >
              <Toast.Header>
                <div className="mr-auto">
                  <FontAwesomeIcon
                    className="text-success mr-2"
                    icon={faCheck}
                  />
                  Save successful
                </div>
              </Toast.Header>
            </Toast>
          </Tab>
        </Tabs>
      </div>
    );
  }
};
