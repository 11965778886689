import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import LoginContainer from '../../../containers/common/LoginContainer';
import DateRangePicker from '../../common/DateRangePicker';
import CardComponent from '../../common/CardComponent';
import GoogleAnalyticsConsiderationTab from './GoogleAnalyticsConsiderationTab';
import GoogleAnalyticsBuyingSignalsTab from './GoogleAnalyticsBuyingSignalsTab';
import GoogleAnalyticsSourceTab from './GoogleAnalyticsSourceTab';
import BrandMetricsByMonthTableContainer from '../../../containers/metrics/BrandMetricsByMonthTableContainer';
import GoogleAnalyticsUsageStatistics from './GoogleAnalyticsUsageStatistics';
import GoogleAnalyticsTopPagesVisited from './GoogleAnalyticsTopPagesVisited';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas, intToShorthandString, round } from '../../../utils/numbers';
import { colors } from '../../../utils/graphs';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class GoogleAnalytics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      category: undefined,
      brand: undefined,
      startDate: moment().subtract(90, 'days').startOf('day'),
      endDate: moment().startOf('day').endOf('day'),
      gaAggregatedData: {},
      gaData: [],
      gaDataLoading: false,
      totalSessionsChecked: true,
      totalPageVisitsChecked: true,
      homePageViewsChecked: true,
      selectedTab: 'consideration',
      refreshLoading: false,
      refreshLabel: undefined,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.myCategoryBrands.length > 0) {
      this.setState(() => ({
        category: this.props.myCategoryBrands[0].category,
        brand: this.props.myCategoryBrands[0].brand,
      }));
    }
    this.fetchGoogleAnalyticsData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      this.fetchGoogleAnalyticsData();
    }
    if (
      prevProps.myCategoryBrands !== this.props.myCategoryBrands &&
      this.props.myCategoryBrands.length > 0
    ) {
      this.setState(() => ({
        category: this.props.myCategoryBrands[0].category,
        brand: this.props.myCategoryBrands[0].brand,
      }));
    }
  };

  fetchGoogleAnalyticsData = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({ gaDataLoading: true }));
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      const gaDataRequests = [];
      // fetch google analytics aggregate data
      gaDataRequests.push(
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/ga-latest-stats?company_id=${this.props.user.customerId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
          HEADERS
        ).then(response => {
          const gaAggregatedData = response.data;
          return { gaAggregatedData };
        }).catch(error => {
          console.error('Error: unable to fetch google analytics aggragated data');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { gaAggregatedData: {} };
        })
      );
      // fetch google analytics trend data
      gaDataRequests.push(
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/ga-daily-stats?company_id=${this.props.user.customerId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
          HEADERS
        ).then(response => {
          const gaData = response.data;
          return { gaData };
        }).catch(error => {
          console.error('Error: unable to fetch google analytics data');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { gaData: [] };
        })
      );

      Promise.all(gaDataRequests).then(responses => {
        let gaAggregatedData = {};
        let gaData = [];
        for (const response of responses) {
          if (response.gaAggregatedData) {
            gaAggregatedData = response.gaAggregatedData;
          } else if (response.gaData) {
            gaData = response.gaData;
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            gaAggregatedData,
            gaData,
            gaDataLoading: false,
          }));
        }
      });
    }
  };

  onFilterDatesChange = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  };

  onCheckboxChange = (checked, checkbox) => {
    if (checked !== this.state[checkbox]) {
      this.setState(() => ({
        [checkbox]: checked
      }));
    }
  };

  runRefreshPoll = () => {
    if (this.props.user.customerId && !this.state.refreshLoading) {
      this.setState(() => ({ refreshLoading: true }));
      axios.post(
        `${CONTENT_SCRAPING_ENDPOINT}/api/ga-refresh-poll`,
        { company_id: this.props.user.customerId },
        HEADERS
      ).then(response => {
        const refreshMetadata = response.data;
        const refreshLabel = `Latest update ${moment.utc(refreshMetadata.last_refresh_date).format('MM/DD')}`
        if (this.state.isMounted) {
          this.setState(() => ({
            refreshLabel,
            refreshLoading: false,
          }));
        }
      }).catch(error => {
        console.error(`Error: failed to refresh google analytics data`);
        if (this.state.isMounted) {
          this.setState(() => ({
            refreshLabel: 'Refresh failed',
            refreshLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  render () {
    return (
      <div className="p-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        { (this.props.user.id && !this.props.companySetupChecklist.google_analytics_setup) &&
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              minHeight: 'calc(100vh - 200px)',
              maxHeight: 'calc(100vh - 200px)',
            }}
          >
            <div className="d-inline-block">
              <Alert variant="primary">
                Google Analytics is not setup for your company. You can get setup <Link to="/content-collectors/web-analytics">here</Link>.
              </Alert>
            </div>
          </div>
        }
        { (this.props.user.customerId && this.props.companySetupChecklist.google_analytics_setup) &&
          <div>
            <h4>
              <img
                className="mr-2"
                src="https://listen-company-logos.s3.amazonaws.com/google_analytics_logo.png"
                alt=""
                crossOrigin="anonymous"
                style={{ width: '24px' }}
              />
              Google Analytics
            </h4>
            <hr />
            <div>
              <h5 className="mb-2 p-2 bg-bops-blue text-light">
                <span
                  className="ml-4 mr-2"
                  style={{ fontSize: '1rem', fontWeight: 'normal' }}
                >
                  Date Range
                </span>
                <DateRangePicker
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  updateDates={this.onFilterDatesChange}
                />
              <div className="d-inline-block mt-1 float-right">
                  { this.state.refreshLabel &&
                    <span
                      className="mr-2"
                      style={{ fontSize: '1rem', fontWeight: 'normal' }}
                    >
                      {this.state.refreshLabel}
                    </span>
                  }
                  { this.state.refreshLoading &&
                    <ClipLoader
                      size={15}
                      color="ffffff"
                    />
                  }
                  { !this.state.refreshLoading &&
                    <FontAwesomeIcon
                      icon={faSyncAlt}
                      onClick={() => this.runRefreshPoll()}
                      style={{ cursor: 'pointer' }}
                    />
                  }
                </div>
              </h5>
            </div>
            <div
              className="pre-scrollable"
              style={{
                minHeight: 'calc(100vh - 200px)',
                maxHeight: 'calc(100vh - 200px)',
                overflow: 'auto',
                overflowX: 'hidden'
              }}
            >
              <CardComponent
                title="Overall Performance"
                body={
                  <div>
                    { this.state.gaDataLoading &&
                      <div className="m-4 text-center">
                        <ClipLoader size={100}/>
                      </div>
                    }
                    { !this.state.gaDataLoading &&
                      <div>
                        <div>
                          <div
                            className="d-inline-block px-4 py-3 border"
                            onClick={() => this.onCheckboxChange(!this.state.totalSessionsChecked, 'totalSessionsChecked')}
                            style={{
                              color: this.state.totalSessionsChecked ? '#ffffff' : '#000000',
                              backgroundColor: this.state.totalSessionsChecked ? colors[0] : '#f8f9fa'
                            }}
                          >
                            <Form.Check
                              type="checkbox"
                              label="Total Sessions"
                              checked={this.state.totalSessionsChecked}
                              readOnly
                            />
                            <div style={{ fontSize: '2rem' }}>
                              { (this.state.gaAggregatedData && this.state.gaAggregatedData.total_sessions != null) ?
                                  intToShorthandString(this.state.gaAggregatedData.total_sessions) : '-'
                              }
                            </div>
                          </div>
                          <div
                            className="d-inline-block px-4 py-3 border"
                            onClick={() => this.onCheckboxChange(!this.state.totalPageVisitsChecked, 'totalPageVisitsChecked')}
                            style={{
                              color: this.state.totalPageVisitsChecked ? '#ffffff' : '#000000',
                              backgroundColor: this.state.totalPageVisitsChecked ? colors[1] : '#f8f9fa'
                            }}
                          >
                            <Form.Check
                              type="checkbox"
                              label="Total Page Visits"
                              checked={this.state.totalPageVisitsChecked}
                              readOnly
                            />
                            <div style={{ fontSize: '2rem' }}>
                              { (this.state.gaAggregatedData && this.state.gaAggregatedData.total_page_visits != null) ?
                                  intToShorthandString(this.state.gaAggregatedData.total_page_visits) : '-'
                              }
                            </div>
                          </div>
                          <div
                            className="d-inline-block px-4 py-3 border"
                            onClick={() => this.onCheckboxChange(!this.state.homePageViewsChecked, 'homePageViewsChecked')}
                            style={{
                              color: this.state.homePageViewsChecked ? '#ffffff' : '#000000',
                              backgroundColor: this.state.homePageViewsChecked ? colors[2] : '#f8f9fa'
                            }}
                          >
                            <Form.Check
                              type="checkbox"
                              label="Home Page Views"
                              checked={this.state.homePageViewsChecked}
                              readOnly
                            />
                            <div style={{ fontSize: '2rem' }}>
                              { (this.state.gaAggregatedData && this.state.gaAggregatedData.total_home_page_views != null) ?
                                  intToShorthandString(this.state.gaAggregatedData.total_home_page_views) : '-'
                              }
                            </div>
                          </div>
                        </div>
                        <ResponsiveContainer height={300} width="99%">
                          <LineChart
                            data={this.state.gaData}
                            margin={{
                              left: 10,
                              right: 10,
                            }}
                          >
                            <XAxis
                              dataKey="date"
                              tickFormatter={(date) => {
                                return moment(date).format('MM/DD');
                              }}
                              minTickGap={15}
                            />
                            <YAxis
                              yAxisId={0}
                              orientation="left"
                              tickFormatter={(value) => {
                                return numberWithCommas(value)
                              }}
                            >
                              <Label
                                value="Total Sessions & Page Visits"
                                position="left"
                                offset={0}
                                angle={-90}
                                style={{
                                  textAnchor: 'middle',
                                  fontWeight: 'bold'
                                }}
                              />
                            </YAxis>
                            { this.state.homePageViewsChecked &&
                              <YAxis
                                yAxisId={1}
                                orientation="right"
                                tickFormatter={(value) => {
                                  return numberWithCommas(value)
                                }}
                              >
                                <Label
                                  value="Home Page Views"
                                  position="right"
                                  offset={0}
                                  angle={90}
                                  style={{
                                    textAnchor: 'middle',
                                    fontWeight: 'bold'
                                  }}
                                />
                              </YAxis>
                            }
                            <CartesianGrid strokeDasharray="5 5"/>
                            <Tooltip
                              labelFormatter={(label) => moment(label).format('dddd, MMMM Do YYYY')}
                              formatter={(value) => numberWithCommas(value)}
                            />
                            { this.state.totalSessionsChecked &&
                              <Line
                                name="Total Sessions"
                                type="monotype"
                                dataKey="total_sessions"
                                stroke={colors[0]}
                                dot={false}
                                yAxisId={0}
                                connectNulls
                              />
                            }
                            { this.state.totalPageVisitsChecked &&
                              <Line
                                name="Total Page Visits"
                                type="monotype"
                                dataKey="total_page_visits"
                                stroke={colors[1]}
                                dot={false}
                                yAxisId={0}
                                connectNulls
                              />
                            }
                            { this.state.homePageViewsChecked &&
                              <Line
                                name="Home Page Views"
                                type="monotype"
                                dataKey="total_home_page_views"
                                stroke={colors[2]}
                                dot={false}
                                yAxisId={1}
                                connectNulls
                              />
                            }
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    }
                  </div>
                }
                contextType="brand"
                contextCategory={this.state.category}
                contextBrand={this.state.category}
                contextChannel="Google Analytics"
                contextChartName="Overall Performance"
              />
              <div className="mt-4">
                <Tabs
                  activekey={this.state.selectedTab}
                  onSelect={(selectedTab) => this.setState(() => ({ selectedTab }))}
                >
                  <Tab
                    eventKey="consideration"
                    title="Consideration"
                  >
                    <GoogleAnalyticsConsiderationTab
                      companyId={this.props.user.customerId}
                      category={this.state.category}
                      brand={this.state.brand}
                    />
                  </Tab>
                  <Tab
                    eventKey="buyingSignals"
                    title="Buying Signals"
                  >
                    <GoogleAnalyticsBuyingSignalsTab
                      companyId={this.props.user.customerId}
                      category={this.state.category}
                      brand={this.state.brand}
                    />
                  </Tab>
                  <Tab
                    eventKey="source"
                    title="Source"
                  >
                    <GoogleAnalyticsSourceTab
                      companyId={this.props.user.customerId}
                      category={this.state.category}
                      brand={this.state.brand}
                    />
                  </Tab>
                  <Tab
                    eventKey="usageStatistics"
                    title="Usage Statistics"
                  >
                    <GoogleAnalyticsUsageStatistics
                      companyId={this.props.user.customerId}
                      category={this.state.category}
                      brand={this.state.brand}
                    />
                  </Tab>
                  <Tab
                    eventKey="topPagesVisited"
                    title="Top Pages Visited"
                  >
                    <GoogleAnalyticsTopPagesVisited
                      companyId={this.props.user.customerId}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
};
