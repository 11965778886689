export const setCompanyChecked = (companyChecked) => {
  return (dispatch) => {
    dispatch(updateCompanyChecked(companyChecked));
  };
};

export const setPortfolioChecked = (portfolioChecked) => {
  return (dispatch) => {
    dispatch(updatePortfolioChecked(portfolioChecked));
  };
};

export const setProductChecked = (productChecked) => {
  return (dispatch) => {
    dispatch(updateProductChecked(productChecked));
  };
};

export const updateCompanyChecked = (companyChecked) => ({
  type: 'UPDATE_COMPANY_CHECKED',
  companyChecked
});

export const updatePortfolioChecked = (portfolioChecked) => ({
  type: 'UPDATE_PORTFOLIO_CHECKED',
  portfolioChecked
});

export const updateProductChecked = (productChecked) => ({
  type: 'UPDATE_PRODUCT_CHECKED',
  productChecked
});
