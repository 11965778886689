import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import DateRangePicker from '../../../common/DateRangePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAssistiveListeningSystems,
  faChartBar,
  faChartSimple,
  faClipboardList,
  faDesktop,
  faEnvelope,
  faFileAlt,
  faFolderOpen,
  faGlobe,
  faMagnifyingGlass,
  faMoneyBillTrendUp,
  faNewspaper,
  faPodcast,
  faRectangleAd,
  faSackDollar,
  faStarHalfAlt,
  faVideo
} from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas, round } from '../../../../utils/numbers';
import { sortAlphabeticalByKey } from '../../../../utils/sorts';
import { LISTEN_ENDPOINT, HEADERS } from '../../../../utils/constants';
import { dispatchReportError } from '../../../../actions/api/errors';

export default class CorrelationInsightsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'standouts',
      selectedMetricCategory: 'Ads',
      selectedComparisonType: 'category',
      selectedDifferenceType: 'positive',
      selectedStandoutType: 'positive',
      appropriateAllBrandsSampleSize: 100,
      appropriateCategorySampleSize: 10,
      appropriateBrandSameSize: 3,
      brandMetrics: [],
      brandMetricsMap: {},
      metricIconMap: {},
      metricCategories: {
        'Ads': [],
        'Brand Pillars': [],
        'Economics': [],
        'Email': [],
        'Employer Review': [],
        'Google Analytics (Private)': [],
        'Media Cast': [],
        'Other': [],
        'PR, News, Blogs': [],
        'Product Review': [],
        'Public Markets': [],
        'Social': [],
        'Survey (Private)': [],
        'Web & Search': [],
      },
      metricExcludeList: [
        'industry_report_performance',
        'industry_award_performance',
        'customer_affirmation_impact',
        'industry_award_count',
        'industry_report_count',
        'web_serp_rank_organic_bing',
        'web_page_views_per_user',
        'web_awis_backlink_count',
        'web_reach_per_million',
        'web_semrush_adwords_cost',
      ],
      metricSampleSizeMap: {},
      allMetricCorrelationData: {},
      categoryMetricCorrelationData: {},
      brandMetricCorrelationData: {},
      allBrandsCorrelationData: [],
      allBrandsPositiveCorrelations: [],
      allBrandsNegativeCorrelations: [],
      selectedStandoutCorrelationData: [],
      categoryCorrelationData: [],
      categoryPositiveCorrelations: [],
      categoryNegativeCorrelations: [],
      brandCorrelationData: [],
      brandPositiveCorrelations: [],
      brandNegativeCorrelations: [],
      selectedCorrelationData: [],
      correlationDataLoading: false,
      startDate: moment().utc().subtract(6, 'month').startOf('day'),
      endDate: moment().utc().endOf('day'),
      showFavorableResultsAlert: true,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchBrandMetrics();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.categories !== this.props.categories ||
      prevProps.brand !== this.props.brand ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      this.fetchCorrelationData();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchBrandMetrics = () => {
    this.setState(() => ({ brandMetricsLoading: true }));
    axios.get(
      `${LISTEN_ENDPOINT}/api/brand-metrics`,
      HEADERS
    ).then(response => {
      let brandMetrics = [];
      for (const bm of response.data) {
        if (bm.production_status === 'prod') {
          brandMetrics.push(bm);
        }
      }
      // add brand pillars to metrics
      brandMetrics = brandMetrics.concat([
        {
          metric_name: 'brand_presence',
          metric_label: 'Brand Presence',
          channel_name: 'Brand Pillars',
          is_private: false,
          production_status: 'prod',
        },
        {
          metric_name: 'brand_attention',
          metric_label: 'Brand Attention',
          channel_name: 'Brand Pillars',
          is_private: false,
          production_status: 'prod',
        },
        {
          metric_name: 'brand_reputation',
          metric_label: 'Brand Reputation',
          channel_name: 'Brand Pillars',
          is_private: false,
          production_status: 'prod',
        },
        {
          metric_name: 'brand_momentum',
          metric_label: 'Brand Momentum',
          channel_name: 'Brand Pillars',
          is_private: false,
          production_status: 'prod',
        }
      ]);
      // add other to metrics
      brandMetrics = brandMetrics.concat([
        {
          metric_name: 'share_of_everything',
          metric_label: 'Share of Everything',
          channel_name: 'Other',
          is_private: false,
          production_status: 'prod',
        },
        {
          metric_name: 'share_of_owned',
          metric_label: 'Share of Owned',
          channel_name: 'Other',
          is_private: false,
          production_status: 'prod',
        },
        {
          metric_name: 'share_of_earned',
          metric_label: 'Share of Earned',
          channel_name: 'Other',
          is_private: false,
          production_status: 'prod',
        },
        {
          metric_name: 'share_of_paid',
          metric_label: 'Share of Paid',
          channel_name: 'Other',
          is_private: false,
          production_status: 'prod',
        },
        {
          metric_name: 'brandops_usage',
          metric_label: 'BrandOps Usage',
          channel_name: 'Other',
          is_private: false,
          production_status: 'prod',
          frequency: 'Every month',
        },
        {
          metric_name: 'brandops_months_active',
          metric_label: 'BrandOps Months Active',
          channel_name: 'Other',
          is_private: false,
          production_status: 'prod',
          frequency: 'Every month',
        },
      ]);
      // add economics to metrics
      brandMetrics = brandMetrics.concat([
        {
          metric_name: 'us_consumer_sentiment_index',
          metric_label: 'US Consumer Sentiment Index',
          channel_name: 'Economics',
          is_private: false,
          production_status: 'prod',
        },
        {
          metric_name: 'us_unemployment_rate_adjusted',
          metric_label: 'US Unemployment Rate Adjusted',
          channel_name: 'Economics',
          is_private: false,
          production_status: 'prod',
        },
        {
          metric_name: 'us_unemployment_rate_not_adjusted',
          metric_label: 'US Unemployment Rate',
          channel_name: 'Economics',
          is_private: false,
          production_status: 'prod',
        },
        {
          metric_name: 'us_consumer_price_index',
          metric_label: 'US Consumer Price Index',
          channel_name: 'Economics',
          is_private: false,
          production_status: 'prod',
        },
        {
          metric_name: 'us_purchasing_managers_index',
          metric_label: 'US Purchasing Managers Index',
          channel_name: 'Economics',
          is_private: false,
          production_status: 'prod',
        }
      ]);
      // add public markets to metrics
      brandMetrics = brandMetrics.concat([
        {
          metric_name: 'snp_market_summary',
          metric_label: 'S&P 500',
          channel_name: 'Public Markets',
          is_private: false,
          production_status: 'prod',
        },
        {
          metric_name: 'dji_market_summary',
          metric_label: 'Dow Jones',
          channel_name: 'Public Markets',
          is_private: false,
          production_status: 'prod',
        },
        {
          metric_name: 'nasdaq_market_summary',
          metric_label: 'Nasdaq Composite',
          channel_name: 'Public Markets',
          is_private: false,
          production_status: 'prod',
        },
      ]);

      brandMetrics.sort(sortAlphabeticalByKey('metric_label'));

      const brandMetricsMap = {};
      for (const metric of brandMetrics) {
        if (metric.production_status === 'prod') {
          brandMetricsMap[metric.metric_name] = metric;
        }
      }

      if (this.state.isMounted) {
        this.setState(() => ({
          brandMetrics,
          brandMetricsMap,
          brandMetricsLoading: false,
        }), () => this.setMetricCategories());
      }
    }).catch(error => {
      console.error('Error: unable to fetch brand metrics');
      if (this.state.isMounted) {
        this.setState(() => ({
          brandMetrics: [],
          brandMetricsMap: {},
          brandMetricsLoading: false,
        }), () => this.setMetricCategories());
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  }

  setMetricCategories = () => {
    // fetch correlation data now that brand metrics are retrieved
    this.fetchCorrelationData();
    // build metric categories
    const metricIconMap = {
      'Ads': faRectangleAd,
      'Brand Pillars': faChartSimple,
      'Economics': faSackDollar,
      'Email': faEnvelope,
      'Employer Review': faStarHalfAlt,
      'Google Analytics (Private)': faChartBar,
      'Media Cast': faVideo,
      'Other': faFolderOpen,
      'PR, News, Blogs': faNewspaper,
      'Product Review': faStarHalfAlt,
      'Public Markets': faMoneyBillTrendUp,
      'Social': faAssistiveListeningSystems,
      'Survey (Private)': faClipboardList,
      'Web & Search': faGlobe,
    };
    const metricCategories = {
      'Ads': [],
      'Brand Pillars': [],
      'Economics': [],
      'Email': [],
      'Employer Review': [],
      'Google Analytics (Private)': [],
      'Media Cast': [],
      'Other': [],
      'PR, News, Blogs': [],
      'Product Review': [],
      'Public Markets': [],
      'Social': [],
      'Survey (Private)': [],
      'Web & Search': [],
    };
    for (const metric of this.state.brandMetrics) {
      if (!this.state.metricExcludeList.includes(metric.metric_name)) {
        switch (metric.channel_name) {
          case 'Ads':
            metricIconMap[metric.metric_name] = faRectangleAd;
            metricCategories['Ads'].push(metric);
            break;
          case 'Brand Pillars':
            metricIconMap[metric.metric_name] = faChartSimple;
            metricCategories['Brand Pillars'].push(metric);
            break;
          case 'Economics':
            metricIconMap[metric.metric_name] = faSackDollar;
            metricCategories['Economics'].push(metric);
            break;
          case 'Emails':
          case 'Email Campaigns':
            metricIconMap[metric.metric_name] = faEnvelope;
            metricCategories['Email'].push(metric);
            break;
          case 'Employer Reviews':
            metricIconMap[metric.metric_name] = faStarHalfAlt;
            metricCategories['Employer Review'].push(metric);
            break;
          case 'Web Analytics':
            metricIconMap[metric.metric_name] = faChartBar;
            metricCategories['Google Analytics (Private)'].push(metric);
            break;
          case 'Videos':
          case 'Podcast':
          case 'Webinar':
            if (metric.channel_name === 'Videos') {
              metricIconMap[metric.metric_name] = faVideo;
            } else if (metric.channel_name === 'Podcast') {
              metricIconMap[metric.metric_name] = faPodcast;
            } else if (metric.channel_name === 'Webinar') {
              metricIconMap[metric.metric_name] = faDesktop;
            }
            metricCategories['Media Cast'].push(metric);
            break;
          case 'Blogs':
          case 'News':
            if (metric.channel_name === 'Blogs') {
              metricIconMap[metric.metric_name] = faFileAlt;
            } else if (metric.channel_name === 'News') {
              metricIconMap[metric.metric_name] = faNewspaper;
            }
            metricCategories['PR, News, Blogs'].push(metric);
            break;
          case 'Product Reviews':
            metricIconMap[metric.metric_name] = faStarHalfAlt;
            metricCategories['Product Review'].push(metric);
            break;
          case 'Public Markets':
            metricIconMap[metric.metric_name] = faMoneyBillTrendUp;
            metricCategories['Public Markets'].push(metric);
            break;
          case 'Other':
            metricIconMap[metric.metric_name] = faFolderOpen;
            metricCategories['Other'].push(metric);
            break;
          case 'Social':
            metricIconMap[metric.metric_name] = faAssistiveListeningSystems;
            metricCategories['Social'].push(metric);
            break;
          case 'Surveys via BrandOps':
            metricIconMap[metric.metric_name] = faClipboardList;
            metricCategories['Survey (Private)'].push(metric);
            break;
          case 'Web':
          case 'Search Engine Results':
            if (metric.channel_name === 'Web') {
              metricIconMap[metric.metric_name] = faGlobe;
            } else if (metric.channel_name === 'Search Engine Results') {
              metricIconMap[metric.metric_name] = faMagnifyingGlass;
            }
            metricCategories['Web & Search'].push(metric);
            break;
        }
      }
    }

    this.setState(
      () => ({
        metricIconMap,
        metricCategories
      }),
      () => this.setCorrelationData()
    );
  };

  updateCorrelationDataForFavorableResults = (correlationData) => {
    // if one and only one of the metrics is "low_is_good",
    // multiply the value by -1 to reverse the correlation direction
    for (const [firstMetricName, firstMetricValues] of Object.entries(correlationData)) {
      for (const [secondMetricName, value] of Object.entries(correlationData[firstMetricName])) {
        if (this.state.brandMetricsMap[firstMetricName] && this.state.brandMetricsMap[secondMetricName]) {
          if (
            (
              this.state.brandMetricsMap[firstMetricName].score_type === 'low_is_good' &&
              this.state.brandMetricsMap[secondMetricName].score_type === 'high_is_good'
            ) ||
            (
              this.state.brandMetricsMap[firstMetricName].score_type === 'high_is_good' &&
              this.state.brandMetricsMap[secondMetricName].score_type === 'low_is_good'
            ) &&
            value != null
          ) {
            correlationData[firstMetricName][secondMetricName] = value * -1;
          }
        }
      }
    }
    return correlationData;
  }

  fetchCorrelationData = () => {
    if (this.props.category && this.props.brand) {
      this.setState(() => ({ correlationDataLoading: true }));
      const correlationDataRequests = [];
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      // get metric sample sizes
      correlationDataRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/brand-metric-sample-size?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
          HEADERS
        ).then(response => {
          const metricSampleSizeMap = {};
          for (const mss of response.data) {
            metricSampleSizeMap[mss.brand_metric_name] = {
              all: mss.all_brands_sample_size,
              category: mss.category_sample_size
            }
            for (const bss of mss.category_product_brands) {
              if (bss.product_brand_id === this.props.brand.id) {
                metricSampleSizeMap[mss.brand_metric_name].brand = bss.sample_size;
              }
            }
          }

          const mockSampleSize = {
            all: 200,
            category: 30,
            brand: 6,
          }
          // add brand pillars to sample size to allow
          metricSampleSizeMap['brand_presence'] = mockSampleSize;
          metricSampleSizeMap['brand_attention'] = mockSampleSize;
          metricSampleSizeMap['brand_reputation'] = mockSampleSize;
          metricSampleSizeMap['brand_momentum'] = mockSampleSize;
          // add economics to sample size to allow
          metricSampleSizeMap['us_consumer_sentiment_index'] = mockSampleSize;
          metricSampleSizeMap['us_unemployment_rate_adjusted'] = mockSampleSize;
          metricSampleSizeMap['us_unemployment_rate_not_adjusted'] = mockSampleSize;
          metricSampleSizeMap['us_consumer_price_index'] = mockSampleSize;
          metricSampleSizeMap['us_purchasing_managers_index'] = mockSampleSize;
          // add public markets to sample size to allow
          metricSampleSizeMap['snp_market_summary'] = mockSampleSize;
          metricSampleSizeMap['dji_market_summary'] = mockSampleSize;
          metricSampleSizeMap['nasdaq_market_summary'] = mockSampleSize;

          return { metricSampleSizeMap };
        }).catch(error => {
          console.log('Error: failed to fetch metric sample sizes.');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { metricSampleSizeMap: {} };
        })
      );
      // all brands correlation data
      correlationDataRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/metric-correlations?start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
          HEADERS
        ).then(response => {
          let allMetricCorrelationData = response.data;
          allMetricCorrelationData = this.updateCorrelationDataForFavorableResults(allMetricCorrelationData);
          return { allMetricCorrelationData };
        }).catch(error => {
          console.log('Error: failed to fetch all metrics correlation...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { allMetricCorrelationData: {} };
        })
      );

      // category correlation data
      correlationDataRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/metric-correlations?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
          HEADERS
        ).then(response => {
          let categoryMetricCorrelationData = response.data;
          categoryMetricCorrelationData = this.updateCorrelationDataForFavorableResults(categoryMetricCorrelationData);
          return { categoryMetricCorrelationData };
        }).catch(error => {
          console.log(`Error: failed to fetch category "${this.props.category.name}" metrics correlation...`);
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryMetricCorrelationData: {} };
        })
      );

      // brand correlation data
      correlationDataRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/metric-correlations?product_brand_id=${this.props.brand.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
          HEADERS
        ).then(response => {
          let brandMetricCorrelationData = response.data;
          brandMetricCorrelationData = this.updateCorrelationDataForFavorableResults(brandMetricCorrelationData);
          return { brandMetricCorrelationData };
        }).catch(error => {
          console.log('Error: failed to fetch brand metrics correlation...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { brandMetricCorrelationData: {} };
        })
      );

      Promise.all(correlationDataRequests).then(responses => {
        let metricSampleSizeMap = {};
        let allMetricCorrelationData = {};
        let categoryMetricCorrelationData = {};
        let brandMetricCorrelationData = {};

        for (const response of responses) {
          if (response.metricSampleSizeMap) {
            metricSampleSizeMap = response.metricSampleSizeMap;
          } else if (response.allMetricCorrelationData) {
            allMetricCorrelationData = response.allMetricCorrelationData;
          } else if (response.categoryMetricCorrelationData) {
            categoryMetricCorrelationData = response.categoryMetricCorrelationData
          } else if (response.brandMetricCorrelationData) {
            brandMetricCorrelationData = response.brandMetricCorrelationData;
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            metricSampleSizeMap,
            allMetricCorrelationData,
            categoryMetricCorrelationData,
            brandMetricCorrelationData,
            correlationDataLoading: false,
          }), () => this.setCorrelationData());
        }
      });
    }
  }

  setCorrelationData = () => {
    if (
      this.state.selectedMetricCategory &&
      this.state.metricCategories[this.state.selectedMetricCategory] &&
      Object.keys(this.state.metricSampleSizeMap).length > 0
    ) {
      const allBrandsCorrelationData = [];
      const categoryCorrelationData = [];
      const brandCorrelationData = [];
      for (const bm of this.state.metricCategories[this.state.selectedMetricCategory]) {
        for (const sbm of this.state.brandMetrics) {
          // check metric exclude list
          // check second metric is not in first metric channel category
          // check all sample size is appropriate
          if (
            !this.state.metricExcludeList.includes(bm.metric_name) &&
            !this.state.metricExcludeList.includes(sbm.metric_name) &&
            this.state.metricCategories[this.state.selectedMetricCategory].filter(x => x.metric_name === sbm.metric_name).length === 0 &&
            this.state.metricSampleSizeMap[bm.metric_name] &&
            this.state.metricSampleSizeMap[bm.metric_name].all >= this.state.appropriateAllBrandsSampleSize &&
            this.state.metricSampleSizeMap[sbm.metric_name] &&
            this.state.metricSampleSizeMap[sbm.metric_name].all >= this.state.appropriateAllBrandsSampleSize
          ) {
            const allBrandsData = {
              firstMetricName: bm.metric_name,
              firstMetricLabel: bm.metric_label,
              secondMetricName: sbm.metric_name,
              secondMetricLabel: sbm.metric_label,
              allBrandsValue: null,
            };
            const categoryData = {
              firstMetricName: bm.metric_name,
              firstMetricLabel: bm.metric_label,
              secondMetricName: sbm.metric_name,
              secondMetricLabel: sbm.metric_label,
              allBrandsValue: null,
              categoryValue: null,
            };
            const brandData = {
              firstMetricName: bm.metric_name,
              firstMetricLabel: bm.metric_label,
              secondMetricName: sbm.metric_name,
              secondMetricLabel: sbm.metric_label,
              allBrandsValue: null,
              brandValue: null,
            }

            // add category value
            if (
              this.state.categoryMetricCorrelationData[bm.metric_name] &&
              this.state.categoryMetricCorrelationData[bm.metric_name][sbm.metric_name]
            ) {
              const categoryValue = round(this.state.categoryMetricCorrelationData[bm.metric_name][sbm.metric_name], 2);
              if (!isNaN(categoryValue)) {
                categoryData.categoryValue = categoryValue;
              }
            }
            // add brand value
            if (
              this.state.brandMetricCorrelationData[bm.metric_name] &&
              this.state.brandMetricCorrelationData[bm.metric_name][sbm.metric_name]
            ) {
              const brandValue = round(this.state.brandMetricCorrelationData[bm.metric_name][sbm.metric_name], 2);
              if (!isNaN(brandValue)) {
                brandData.brandValue = brandValue;
              }
            }
            // all brand value
            if (
              this.state.allMetricCorrelationData[bm.metric_name] &&
              this.state.allMetricCorrelationData[bm.metric_name][sbm.metric_name]
            ) {
              const allBrandsValue = round(this.state.allMetricCorrelationData[bm.metric_name][sbm.metric_name], 2);
              if (!isNaN(allBrandsValue)) {
                allBrandsData.allBrandsValue = allBrandsValue;
                categoryData.allBrandsValue = allBrandsValue;
                brandData.allBrandsValue = allBrandsValue;
              }
            }

            // calculate category difference
            if (categoryData.allBrandsValue != null && categoryData.categoryValue != null) {
              categoryData.difference = round(categoryData.allBrandsValue - categoryData.categoryValue, 2);
            } else {
              categoryData.difference = null;
            }
            // calculate brand difference
            if (brandData.allBrandsValue != null && brandData.brandValue != null) {
              brandData.difference = round(brandData.allBrandsValue - brandData.brandValue, 2);
            } else {
              brandData.difference = null;
            }

            allBrandsCorrelationData.push(allBrandsData);
            // check sample size before adding data
            if (
              this.state.metricSampleSizeMap[bm.metric_name] &&
              this.state.metricSampleSizeMap[bm.metric_name].category >= this.state.appropriateCategorySampleSize &&
              this.state.metricSampleSizeMap[sbm.metric_name] &&
              this.state.metricSampleSizeMap[sbm.metric_name].category >= this.state.appropriateCategorySampleSize
            ) {
              // add lowest sample size to data
              categoryData.lowestSampleSize = this.state.metricSampleSizeMap[bm.metric_name].category < this.state.metricSampleSizeMap[sbm.metric_name].category ?
                this.state.metricSampleSizeMap[bm.metric_name].category :
                this.state.metricSampleSizeMap[sbm.metric_name].category
              categoryCorrelationData.push(categoryData);
            }
            if (
              this.state.metricSampleSizeMap[bm.metric_name] &&
              this.state.metricSampleSizeMap[bm.metric_name].brand >= this.state.appropriateBrandSameSize &&
              this.state.metricSampleSizeMap[sbm.metric_name] &&
              this.state.metricSampleSizeMap[sbm.metric_name].brand >= this.state.appropriateBrandSameSize
            ) {
              // add lowest sample size to data
              brandData.lowestSampleSize = this.state.metricSampleSizeMap[bm.metric_name].brand < this.state.metricSampleSizeMap[sbm.metric_name].brand ?
                this.state.metricSampleSizeMap[bm.metric_name].brand :
                this.state.metricSampleSizeMap[sbm.metric_name].brand
              brandCorrelationData.push(brandData);
            }
          }
        }
      }
      allBrandsCorrelationData.sort((a, b) => b.allBrandsValue - a.allBrandsValue);
      const allBrandsPositiveCorrelations = allBrandsCorrelationData.slice(0, 40);
      const allBrandsNegativeCorrelations = allBrandsCorrelationData.slice(-40).reverse();
      let selectedStandoutCorrelationData = [];
      if (this.state.selectedStandoutType === 'positive') {
        selectedStandoutCorrelationData = allBrandsPositiveCorrelations;
      } else {
        selectedStandoutCorrelationData = allBrandsNegativeCorrelations;
      }

      categoryCorrelationData.sort((a, b) => b.difference - a.difference);
      const categoryPositiveCorrelations = categoryCorrelationData.slice(0, 40);
      const categoryNegativeCorrelations = categoryCorrelationData.slice(-40).reverse();
      brandCorrelationData.sort((a, b) => b.difference - a.difference);
      const brandPositiveCorrelations = brandCorrelationData.slice(0, 40);
      const brandNegativeCorrelations = brandCorrelationData.slice(-40).reverse();

      this.setState(() => ({
        allBrandsCorrelationData,
        allBrandsPositiveCorrelations,
        allBrandsNegativeCorrelations,
        selectedStandoutCorrelationData,
        categoryCorrelationData,
        categoryPositiveCorrelations,
        categoryNegativeCorrelations,
        brandCorrelationData,
        brandPositiveCorrelations,
        brandNegativeCorrelations,
      }), () => this.setSelectedCorrelationData());
    }
  };

  setSelectedCorrelationData = () => {
    let selectedCorrelationData = [];
    if (this.state.selectedComparisonType === 'category') {
      if (this.state.selectedDifferenceType === 'positive') {
        selectedCorrelationData = this.state.categoryPositiveCorrelations;
      } else {
        selectedCorrelationData = this.state.categoryNegativeCorrelations;
      }
    } else if (this.state.selectedComparisonType === 'brand') {
      if (this.state.selectedDifferenceType === 'positive') {
        selectedCorrelationData = this.state.brandPositiveCorrelations;
      } else {
        selectedCorrelationData = this.state.brandNegativeCorrelations;
      }
    }
    this.setState(() => ({ selectedCorrelationData }));
  };

  onSelectedComparisonTypeChange = (selectedComparisonType) => {
    if (selectedComparisonType !== this.state.selectedComparisonType) {
      this.setState(
        () => ({ selectedComparisonType }),
        () => this.setSelectedCorrelationData()
      );
    }
  };

  onSelectedDifferenceTypeChange = (selectedDifferenceType) => {
    if (selectedDifferenceType !== this.state.selectedDifferenceType) {
      this.setState(
        () => ({ selectedDifferenceType }),
        () => this.setSelectedCorrelationData()
      );
    }
  };

  setSelectedStandoutCorrelationData = () => {
    let selectedStandoutCorrelationData = [];
    if (this.state.selectedStandoutType === 'positive') {
      selectedStandoutCorrelationData = this.state.allBrandsPositiveCorrelations;
    } else {
      selectedStandoutCorrelationData = this.state.allBrandsNegativeCorrelations;
    }
    this.setState(() => ({ selectedStandoutCorrelationData }));
  };

  onSelectedStandoutTypeChange = (selectedStandoutType) => {
    if (selectedStandoutType !== this.state.selectedStandoutType) {
      this.setState(
        () => ({ selectedStandoutType }),
        () => this.setSelectedStandoutCorrelationData()
      );
    }
  };

  onMetricCategorySelect = (selectedMetricCategory) => {
    if (selectedMetricCategory !== this.state.selectedMetricCategory) {
      this.setState(
        () => ({ selectedMetricCategory }),
        () => this.setCorrelationData()
      );
    }
  };

  onFilterDatesChange = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  };

  render () {
    return (
      <div>
        <div className="mb-2 p-2 bg-bops-blue text-light">
          <div className="d-inline-block">
            <span
              className="mr-2"
              style={{ fontSize: '1rem', fontWeight: 'normal' }}
            >
              Date Range
            </span>
            <DateRangePicker
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              updateDates={this.onFilterDatesChange}
            />
          </div>
        </div>
        <Row>
          <Col xs={12} md={6} lg={3}>
            <div
              className="pre-scrollable border rounded bg-white"
              style={{
                minHeight: 'calc(100vh - 300px)',
                maxHeight: 'calc(100vh - 300px)',
              }}
            >
              { Object.keys(this.state.metricCategories).map((categoryName, i) => {
                  return (
                    <div
                      key={categoryName}
                      className={
                        this.state.selectedMetricCategory === categoryName ?
                          'px-2 bg-primary text-light rounded ellipsis' :
                          'px-2 ellipsis'
                      }
                      onClick={() => this.onMetricCategorySelect(categoryName)}
                      style={{ cursor: 'pointer' }}
                    >
                      { this.state.metricIconMap[categoryName] &&
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={this.state.metricIconMap[categoryName]}
                        />
                      }
                      {categoryName}
                    </div>
                  )
                })
              }
            </div>
          </Col>
          <Col xs={12} md={6} lg={9}>
            <Alert
              className="mb-2"
              variant="warning"
              show={this.state.showFavorableResultsAlert}
              onClose={() => this.setState({ showFavorableResultsAlert: false })}
              dismissible
            >
              For some metrics, a lower value is better. Correlations are based on favorable results.
            </Alert>
            <Tabs
              defaultActiveKey= "standouts"
              activeKey={this.state.selectedTab}
              onSelect={(selectedTab) => {
                this.setState(() => ({ selectedTab }));
              }}
            >
              {/*
              <Tab
                eventKey="highlights"
                title="Highlights"
              >
                <div
                  className="pre-scrollable"
                  style={{
                    minHeight: this.state.showFavorableResultsAlert ?
                      'calc(100vh - 400px)' : 'calc(100vh - 342px)',
                    maxHeight: this.state.showFavorableResultsAlert ?
                      'calc(100vh - 400px)' : 'calc(100vh - 342px)',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                  }}
                >
                  <div className="p-4">Coming Soon!</div>
                </div>
              </Tab>
              */}
              <Tab
                eventKey="standouts"
                title="Standouts"
              >
                <div className="my-2">
                  <div className="d-inline-block">
                    Analysis of all brands
                  </div>
                  <div className="d-inline-block ml-4">
                    <Form.Check
                      type="radio"
                      label="Positive"
                      checked={this.state.selectedStandoutType === 'positive'}
                      onChange={() => this.onSelectedStandoutTypeChange('positive')}
                      inline
                    />
                    <Form.Check
                      type="radio"
                      label="Negative"
                      checked={this.state.selectedStandoutType === 'negative'}
                      onChange={() => this.onSelectedStandoutTypeChange('negative')}
                      inline
                    />
                  </div>
                </div>
                <div
                  className="pre-scrollable"
                  style={{
                    minHeight: this.state.showFavorableResultsAlert ?
                      'calc(100vh - 400px)' : 'calc(100vh - 342px)',
                    maxHeight: this.state.showFavorableResultsAlert ?
                      'calc(100vh - 400px)' : 'calc(100vh - 342px)',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                  }}
                >
                  { this.state.correlationDataLoading &&
                    <div className="m-4 text-center">
                      <ClipLoader size={100}/>
                    </div>
                  }
                  { !this.state.correlationDataLoading &&
                    <Table
                      bordered
                      striped
                      size="sm"
                      style={{
                        overflow: 'auto',
                      }}
                    >
                      <thead className="bg-dark text-light">
                        <tr
                          style={{
                            position: 'sticky',
                            top: -1,
                            zIndex: 1
                          }}
                        >
                          <th className="bg-dark">
                            {`${this.state.selectedMetricCategory.endsWith('s') ? this.state.selectedMetricCategory.slice(0, -1) : this.state.selectedMetricCategory} Metrics`}
                          </th>
                          <th className="bg-dark">All Other Metrics</th>
                          <th className="bg-dark">Correlation Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        { this.state.selectedStandoutCorrelationData.map((data, i) => {
                            return (
                              <tr key={`ci-ct-tr-${i}`}>
                                <td>{data.firstMetricLabel}</td>
                                <td>
                                  { this.state.metricIconMap[data.secondMetricName] &&
                                    <FontAwesomeIcon
                                      className="mr-1"
                                      icon={this.state.metricIconMap[data.secondMetricName]}
                                    />
                                  }
                                  {data.secondMetricLabel}
                                </td>
                                <td>{data.allBrandsValue}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  }
                </div>
              </Tab>
              <Tab
                eventKey="comparisons"
                title="Comparisons"
              >
                <div className="my-2">
                  <div className="d-inline-block">
                    All Brands vs.
                  </div>
                  <div className="d-inline-block ml-2">
                    <Form.Check
                      type="radio"
                      label="Category"
                      checked={this.state.selectedComparisonType === 'category'}
                      onChange={() => this.onSelectedComparisonTypeChange('category')}
                      inline
                    />
                    <Form.Check
                      type="radio"
                      label={`Single Brand (${this.props.brand.name})`}
                      checked={this.state.selectedComparisonType === 'brand'}
                      onChange={() => this.onSelectedComparisonTypeChange('brand')}
                      inline
                    />
                  </div>
                  <div className="d-inline-block ml-4">
                    <Form.Check
                      type="radio"
                      label="Positive"
                      checked={this.state.selectedDifferenceType === 'positive'}
                      onChange={() => this.onSelectedDifferenceTypeChange('positive')}
                      inline
                    />
                    <Form.Check
                      type="radio"
                      label="Negative"
                      checked={this.state.selectedDifferenceType === 'negative'}
                      onChange={() => this.onSelectedDifferenceTypeChange('negative')}
                      inline
                    />
                  </div>
                </div>
                <div
                  className="pre-scrollable"
                  style={{
                    minHeight: this.state.showFavorableResultsAlert ?
                      'calc(100vh - 400px)' : 'calc(100vh - 342px)',
                    maxHeight: this.state.showFavorableResultsAlert ?
                      'calc(100vh - 400px)' : 'calc(100vh - 342px)',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                  }}
                >
                  { this.state.correlationDataLoading &&
                    <div className="m-4 text-center">
                      <ClipLoader size={100}/>
                    </div>
                  }
                  { !this.state.correlationDataLoading &&
                    <Table
                      bordered
                      striped
                      size="sm"
                      style={{
                        overflow: 'auto',
                      }}
                    >
                      <thead className="bg-dark text-light">
                        <tr
                          style={{
                            position: 'sticky',
                            top: -1,
                            zIndex: 1
                          }}
                        >
                          <th className="bg-dark">
                            {`${this.state.selectedMetricCategory.endsWith('s') ? this.state.selectedMetricCategory.slice(0, -1) : this.state.selectedMetricCategory} Metrics`}
                          </th>
                          <th className="bg-dark">All Other Metrics</th>
                          <th className="bg-dark">All Brands Value</th>
                          <th className="bg-dark">
                            { this.state.selectedComparisonType === 'category' ?
                                'Category Value':
                                'Brand Value'
                            }
                          </th>
                          <th className="bg-dark">Difference</th>
                          <th className="bg-dark">Lowest Sample Size</th>
                        </tr>
                      </thead>
                      <tbody>
                        { this.state.selectedCorrelationData.map((data, i) => {
                            return (
                              <tr key={`ci-ct-tr-${i}`}>
                                <td>{data.firstMetricLabel}</td>
                                <td>
                                  { this.state.metricIconMap[data.secondMetricName] &&
                                    <FontAwesomeIcon
                                      className="mr-1"
                                      icon={this.state.metricIconMap[data.secondMetricName]}
                                    />
                                  }
                                  {data.secondMetricLabel}
                                </td>
                                <td>{data.allBrandsValue}</td>
                                <td>
                                  { this.state.selectedComparisonType === 'category' ?
                                      data.categoryValue:
                                      data.brandValue
                                  }
                                </td>
                                <td>{data.difference}</td>
                                <td>{numberWithCommas(data.lowestSampleSize)}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  }
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </div>
    );
  }
};
