import React from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import ClipLoader from 'react-spinners/ClipLoader';
import LoginContainer from '../../containers/common/LoginContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { sortAlphabeticalByKey } from '../../utils/sorts';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryDetectedTechnology extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCategory: undefined,
      categoryDetectedTechnologyData: [],
      categoryDetectedTechnologyDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.categories.length > 0) {
      this.onCategoryChange({
        currentTarget: {
          value: this.props.categories[0].id
        }
      });
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevProps.categories !== this.props.categories
    ) {
      if (this.props.categories.length > 0) {
        this.onCategoryChange({
          currentTarget: { value: this.props.categories[0].id }
        });
      } else {
        this.setState(() => ({
          selectedCategoryId: undefined,
          selectedCategory: undefined,
        }));
      }
    }
  };

  onCategoryChange = (event) => {
    let selectedCategoryId = event.currentTarget.value;
    let selectedCategory;
    for (const category of this.props.categories) {
      if (String(selectedCategoryId) === String(category.id)) {
        selectedCategory = category;
      }
    }
    this.setState(() => ({
      selectedCategoryId,
      selectedCategory,
    }), () => this.fetchCategoryDetectedTechnologies());
  };

  fetchCategoryDetectedTechnologies = () => {
    if (this.state.selectedCategory) {
      this.setState(() => ({ categoryDetectedTechnologyDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-detected-technology?category_type=${this.state.selectedCategory.category_type}&category_id=${this.state.selectedCategory.id}`,
        HEADERS
      ).then(response => {
        // flatten data to help ui table
        const detectedTechnologiesMap = {};
        for (const brand of response.data) {
          for (const technology of Object.values(brand.technologies)) {
            if (!detectedTechnologiesMap[technology.Name]) {
              detectedTechnologiesMap[technology.Name] = technology;
            }
            detectedTechnologiesMap[technology.Name][brand.product_brand_name] = true;
          }
        }
        // convert detected technologies map into array
        const categoryDetectedTechnologyData = [];
        for (const technologyData of Object.values(detectedTechnologiesMap)) {
          categoryDetectedTechnologyData.push(technologyData);
        }
        // sort by technology name
        categoryDetectedTechnologyData.sort(sortAlphabeticalByKey('Name'));

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryDetectedTechnologyData,
            categoryDetectedTechnologyDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch category detected technology data...`);
        if (this.state.isMounted) {
          this.setState(() => ({
            categoryDetectedTechnologyData: [],
            categoryDetectedTechnologyDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="p-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        { this.props.user.customerId &&
          <div>
            <h4>
              <FontAwesomeIcon
                className="mr-2"
                icon={faLayerGroup}
                color="#6c757d"
              />
              Detected Technology
            </h4>
            <div className="mb-2">
              <div className="d-inline-block mr-2">
                Category
              </div>
              <div className="d-inline-block">
                <Form.Control
                  as="select"
                  value={this.state.selectedCategoryId}
                  onChange={this.onCategoryChange}
                  style={{ minWidth: '200px', maxWidth: '300px' }}
                  size="sm"
                >
                  { this.props.categories.map(category => {
                      return (
                        <option
                          key={`cdt-c-${category.id}`}
                          value={category.id}
                        >
                          {category.name}
                        </option>
                      )
                    })
                  }
                </Form.Control>
              </div>
              <div className="d-inline-block ml-4">
                <Alert
                  className="mb-0 px-2 py-1"
                  variant="info"
                  size="sm"
                >
                  Technologies are detected on a 'best effort' basis.
                </Alert>
              </div>
            </div>
            <hr />
            { this.state.selectedCategory &&
              <div>
                { this.state.categoryDetectedTechnologyDataLoading &&
                  <div className="m-4 text-center">
                    <ClipLoader size={100}/>
                  </div>
                }
                { !this.state.categoryDetectedTechnologyDataLoading &&
                  <Table
                    id="categoryDetectedTechnologyTable"
                    className="fixed-header mb-0"
                    size="sm"
                    striped
                    bordered
                  >
                    <thead className="bg-dark text-light text-center">
                      <tr>
                        <th
                          className="border-left-0 border-right-0"
                          style={{ width: '280px' }}
                        >
                          Analytics, Tracking, and Advertising
                        </th>
                        { this.state.selectedCategory.product_brands.map(pb => {
                            return (
                              <th
                                key={`th-pb-${pb.id}`}
                                className="border-left-0 border-right-0"
                                style={{
                                  textOverflow: 'ellipsis',
                                  overflowX: 'hidden',
                                  wordBreak: 'break-word',
                                }}
                              >
                                {pb.name}
                              </th>
                            )
                          })
                        }
                      </tr>
                    </thead>
                    <tbody style={{ height: 'calc(100vh - 270px)' }}>
                      { this.state.categoryDetectedTechnologyData.map((technology, i) => {
                          return (
                            <tr key={`cdt-t-${i}`}>
                              <td style={{ width: '280px' }}>
                                { technology.Link &&
                                  <OverlayTrigger
                                    trigger="hover"
                                    placement="right"
                                    overlay={
                                      <Popover>
                                        <Popover.Content>
                                          {technology.Description || 'N/A'}
                                        </Popover.Content>
                                      </Popover>
                                    }
                                  >
                                    <div>
                                      <a href={technology.Link} target="_blank">
                                        {technology.Name}
                                      </a>
                                    </div>
                                  </OverlayTrigger>

                                }
                                { !technology.Link &&
                                  <OverlayTrigger
                                    trigger="hover"
                                    placement="right"
                                    overlay={
                                      <Popover>
                                        <Popover.Content>
                                          {technology.Description || 'N/A'}
                                        </Popover.Content>
                                      </Popover>
                                    }
                                  >
                                    <div>
                                      {technology.Name}
                                    </div>
                                  </OverlayTrigger>
                                }
                              </td>
                              <React.Fragment>
                                { this.state.selectedCategory.product_brands.map(pb => {
                                    if (technology[pb.name]) {
                                      return (
                                        <td
                                          key={`cdt-t-${i}-pb-${pb.id}`}
                                          className="text-center"
                                        >
                                          <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faCheck}
                                            color="#28a745"
                                          />
                                        </td>
                                      )
                                    } else {
                                      return (
                                        <td key={`cdt-t-${i}-pb-${pb.id}`}></td>
                                      )
                                    }
                                  })
                                }
                              </React.Fragment>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                }
              </div>
            }
          </div>
        }
      </div>
    );
  }
};
