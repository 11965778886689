import { connect } from 'react-redux';
import Hubspot from '../../components/privateStack/hubspot/Hubspot';

const mapStateToProps = (state) => ({
  user: state.user,
  companySetupChecklist: state.companySetupChecklist,
  myCategoryBrands: state.myCategoryBrands,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Hubspot);
