import React from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class BrandForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formType: 'New',
      name: '',
      companyName: '',
      productUrl: '',
      companyUrl: '',
      description: '',
      positionInStageId: 2,
      customerTrackedCompanyId: undefined,
      showToast: false,
      errors: {},
      errorMessage: ''
    };
  }

  componentDidMount() {
    this.setForm();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedBrand !== this.props.selectedBrand) {
      this.setForm();
    }
  };

  setForm = () => {
    if (this.props.selectedBrand) {
      this.setState(() => ({
        formType: 'Update',
        name: this.props.selectedBrand.name,
        companyName: this.props.selectedBrand.company_name || '',
        productUrl: this.props.selectedBrand.product_url || '',
        companyUrl: this.props.selectedBrand.company_url || '',
        description: this.props.selectedBrand.description || '',
        positionInStageId: this.props.selectedBrand.position_in_stage,
        showToast: false,
        errors: {},
        errorMessage: '',
      }));
    } else {
      this.setState(() => ({
        formType: 'New',
        name: '',
        companyName: '',
        productUrl: '',
        companyUrl: '',
        description: '',
        positionInStageId: 2,
        customerTrackedCompanyId: undefined,
        showToast: false,
        errors: {},
        errorMessage: '',
      }));
    }
  };

  onNameChange = (event) => {
    const name = event.currentTarget.value;
    this.setState(() => ({ name }));
  };

  onCompanyNameChange = (event) => {
    const companyName = event.currentTarget.value;
    this.setState(() => ({ companyName }));
  };

  onProductUrlChange = (event) => {
    const productUrl = event.currentTarget.value;
    this.setState(() => ({ productUrl }));
  };

  onCompanyUrlChange = (event) => {
    const companyUrl = event.currentTarget.value;
    this.setState(() => ({ companyUrl }));
  };

  onDescriptionChange = (event) => {
    const description = event.currentTarget.value;
    this.setState(() => ({ description }));
  };

  onPositionInStageIdChange = (event) => {
    const positionInStageId = event.currentTarget.value;
    this.setState(() => ({ positionInStageId }));
  };

  onCustomerTrackedCompanyChange = (event) => {

  };

  validForm = () => {
    const errors = {};
    if (!this.state.name || this.state.name.trim() === '') {
      errors.name = true;
    }

    this.setState(() => ({ errors }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  saveProductBrand = () => {
    if (this.validForm()) {
      const productBrand = {
        company_id: this.props.companyId,
        name: this.state.name,
        position_in_stage_id: this.state.positionInStageId
      };

      if (this.state.companyName) {
        productBrand.company_name = this.state.companyName;
      } else {
        productBrand.company_name = null;
      }

      if (this.state.productUrl) {
        productBrand.product_url = this.state.productUrl;
      } else {
        productBrand.product_url = null;
      }

      if (this.state.companyUrl) {
        productBrand.company_url = this.state.companyUrl;
      } else {
        productBrand.company_url = null;
      }

      if (this.state.description) {
        productBrand.description = this.state.description;
      } else {
        productBrand.description = null;
      }

      if (this.state.customerTrackedCompanyId) {
        productBrand.customer_tracked_company_id = this.state.customerTrackedCompanyId;
      }

      if (this.props.selectedBrand) {
        // Update
        axios.put(
          `${LISTEN_ENDPOINT}/api/product-brands/${this.props.selectedBrand.id}`,
          productBrand,
          HEADERS
        ).then(response => {
          this.setState(() => ({ showToast: true }));
          this.props.refresh(this.props.companyId);
        }).catch(error => {
          console.log('Error: unable to update product brand');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  render () {
    return (
      <div className="px-2">
        { !this.props.disabled &&
          <h3>{this.state.formType} Brand</h3>
        }
        <Form>
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>Product Brand</Form.Label>
                <Form.Control
                  type="text"
                  value={this.state.name}
                  onChange={this.onNameChange}
                  isInvalid={this.state.errors.name}
                  disabled={this.props.disabled}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Product URL</Form.Label>
                <Form.Control
                  type="text"
                  value={this.state.productUrl}
                  onChange={this.onProductUrlChange}
                  isInvalid={this.state.errors.productUrl}
                  disabled={this.props.disabled}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>Company Brand</Form.Label>
                <Form.Control
                  type="text"
                  value={this.state.companyName}
                  onChange={this.onCompanyNameChange}
                  isInvalid={this.state.errors.companyName}
                  disabled={this.props.disabled}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Company URL</Form.Label>
                <Form.Control
                  type="text"
                  value={this.state.companyUrl}
                  onChange={this.onCompanyUrlChange}
                  isInvalid={this.state.errors.companyUrl}
                  disabled={this.props.disabled}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              value={this.state.description}
              onChange={this.onDescriptionChange}
              isInvalid={this.state.errors.description}
              disabled={this.props.disabled}
            />
          </Form.Group>
          { !this.props.hidePositionInStage &&
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label>Position in Stage</Form.Label>
                  <Form.Control
                    className="w-50"
                    as="select"
                    value={this.state.positionInStageId}
                    onChange={this.onPositionInStageIdChange}
                    disabled={this.props.disabled}
                  >
                    { this.props.positionInStages.map(positionInStage => {
                        return (
                          <option
                            key={`pis${positionInStage.id}`}
                            value={positionInStage.id}
                          >
                            {positionInStage.name}
                          </option>
                        )
                      })
                    }
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col></Col>
            </Form.Row>
          }
        </Form>
        { !this.props.disabled &&
          <Button
            variant="primary"
            onClick={this.saveProductBrand}
          >
            Save
          </Button>
        }

        <Toast
          onClose={() => this.setState(() => ({ showToast: false }))}
          show={this.state.showToast}
          delay={2000}
          autohide
          style={{
            position: 'absolute',
            top: 0,
            right: 0
          }}
        >
          <Toast.Header>
            <div className="mr-auto">
              <FontAwesomeIcon
                className="text-success mr-2"
                icon={faCheck}
              />
              Save successful
            </div>
          </Toast.Header>
        </Toast>
      </div>
    );
  }
};
