import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';
import ClipLoader from 'react-spinners/ClipLoader';
import DateRangePicker from '../../common/DateRangePicker';
import BrandChannelSummaryContainer from '../../../containers/brands/BrandChannelSummaryContainer';
import BrandMetricsByMonthTableContainer from '../../../containers/metrics/BrandMetricsByMonthTableContainer';
import PodcastSummaryGraphs from '../../podcasts/PodcastSummaryGraphs';
import PodcastEpisodesDisplay from '../../podcasts/PodcastEpisodesDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPodcast } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '../../../utils/numbers';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class PodcastsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      podcastsDrawerOpen: false,
      ownedPodcasts: [],
      ownedPodcastsLoading: false,
      earnedChecked: true,
      ownedChecked: true,
      podcastEpisodes: [],
      podcastEpisodeResults: [],
      cppCounts: {},
      podcastSetup: true,
      startDate: moment().utc().subtract(3, 'month').startOf('day'),
      endDate: moment().utc().endOf('day'),
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchOwnedPodcasts();
    this.fetchPodcastEpisodeData();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.companyId !== this.props.companyId) {
      this.fetchOwnedPodcasts();
      this.fetchPodcastEpisodeData();
    }
    if (
        prevState.startDate !== this.state.startDate ||
        prevState.endDate !== this.state.endDate
    ) {
      this.fetchPodcastEpisodeData();
    }
    if (
      prevProps.companyChecked !== this.props.companyChecked ||
      prevProps.portfolioChecked !== this.props.portfolioChecked ||
      prevProps.productChecked !== this.props.productChecked
    ) {
      this.updatePodcastEpisodeResults();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchOwnedPodcasts = () => {
    if (this.props.brand) {
      this.setState(() => ({ ownedPodcastsLoading: true }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/company-podcast-channels?company_id=${this.props.brand.company_id}`,
        HEADERS
      ).then(response => {
        const ownedPodcasts = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            ownedPodcasts,
            ownedPodcastsLoading: false,
          }));
        }
      }).catch(error => {
        console.log('Error: unable to fetch owned podcasts');
        if (this.state.isMounted) {
          this.setState(() => ({
            ownedPodcasts: [],
            ownedPodcastsLoading: false,
          }));
        }
      });
    }
  };

  fetchPodcastEpisodeData = () => {
    if (this.props.brand) {
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/podcast-episodes?company_id=${this.props.brand.company_id}&product_brand_id=${this.props.brand.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        const podcastEpisodes = response.data;
        const cppCounts = {
          companyCount: 0,
          portfolioCount: 0,
          productCount: 0,
        };
        for (const p of podcastEpisodes) {
          if (Array.isArray(p.brand_types)) {
            if (p.brand_types.includes('company')) {
              cppCounts.companyCount++;
            }
            if (p.brand_types.includes('portfolio')) {
              cppCounts.portfolioCount++;
            }
            if (p.brand_types.includes('product')) {
              cppCounts.productCount++;
            }
          } else {
            p.brand_types = []
          }
        }

        if (this.state.isMounted) {
          this.setState(
            () => ({
              podcastEpisodes,
              cppCounts,
              podcastSetup: true
            }),
            () => this.updatePodcastEpisodeResults()
          );
        }
      }).catch(error => {
        if (error.response.status === 404) {
          if (this.state.isMounted) {
            this.setState(
              () => ({
                podcastEpisodes: [],
                cppCounts: {},
                podcastSetup: false
              }),
              () => this.updatePodcastEpisodeResults()
            );
          }
        } else {
          console.log('Error: failed to fetch product brand podcast episodes...');
          if (this.state.isMounted) {
            this.setState(() => ({ podcastEpisodes: [] }));
          }
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onFilterDatesChange = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  };

  onCheckboxChange = (event, checkbox) => {
    const checked = event.currentTarget.checked;
    if (checked !== this.state[checkbox]) {
      this.setState(() => ({
        [checkbox]: checked
      }), () => this.updatePodcastEpisodeResults());
    }
  };

  updatePodcastEpisodeResults = () => {
    let podcastEpisodeResults = [];
    for (const pe of this.state.podcastEpisodes) {
      if (
        Array.isArray(pe.brand_types) &&
        (
          (this.props.companyChecked && pe.brand_types.includes('company')) ||
          (this.props.productChecked && pe.brand_types.includes('product')) ||
          (this.props.portfolioChecked && pe.brand_types.includes('portfolio'))
        )
      ) {
        if (this.state.earnedChecked && !pe.podcast_company_account_id) {
          podcastEpisodeResults.push(pe);
        } else if (this.state.ownedChecked && pe.podcast_company_account_id) {
          podcastEpisodeResults.push(pe);
        }
      }
    }

    if (this.state.isMounted) {
      this.setState(() => ({ podcastEpisodeResults }));
    }
  };

  togglePodcastsDrawer = () => {
    this.setState((prevState) => ({
      podcastsDrawerOpen: !prevState.podcastsDrawerOpen
    }));
  };

  render () {
    return (
      <div>
        <div>
          <BrandChannelSummaryContainer
            channel="Podcast"
            category={this.props.category}
            brand={this.props.brand}
          />
        </div>
        <div>
          <h5 className="my-2 p-2 bg-bops-blue text-light">
            Metric Trends
            <div
              className="d-inline-block ml-4"
              style={{ fontSize: '1rem', fontWeight: 'normal' }}
            >
              <Form.Check
                id="podcastAllMonthsCheckbox"
                className="ml-2"
                type="checkbox"
                label="All Months"
                checked={this.props.allMonthsChecked}
                onChange={(event) => this.props.updateAllMonthsChecked(event.currentTarget.checked)}
                inline
              />
            </div>
          </h5>
          <div>
            { this.state.ownedPodcastsLoading &&
              <div className="m-4 text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { (
                !this.state.ownedPodcastsLoading &&
                this.state.ownedPodcasts.length > 0
              ) &&
              <div>
                <Card>
                  <Card.Header onClick={this.togglePodcastsDrawer}>
                    <div className="d-flex">
                      <div style={{ width: '50px' }}>
                        { this.state.ownedPodcasts[0].image &&
                          <img
                            src={this.state.ownedPodcasts[0].image}
                            alt=""
                            crossOrigin="anonymous"
                            width="100%"
                          />
                        }
                        { !this.state.ownedPodcasts[0].image &&
                          <FontAwesomeIcon
                            icon={faPodcast}
                            color="#5f666d"
                            size="2x"
                          />
                        }
                      </div>
                      <div className="pl-4 flex-grow-4">
                        <div>
                          <div className="font-weight-bold">
                            <a
                              href={this.state.ownedPodcasts[0].listennotes_url}
                              target="_blank"
                            >
                              {this.state.ownedPodcasts[0].title_original}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Header>
                  <Collapse in={this.state.podcastsDrawerOpen}>
                    <div>
                      <Card.Body>
                        { this.state.ownedPodcasts.map((ownedPodcast, i) => {
                            if (i > 0) {
                              return (
                                <div className="d-flex mb-3">
                                  <div className="d-flex">
                                    <div style={{ width: '50px' }}>
                                      { ownedPodcast.image &&
                                        <img
                                          src={ownedPodcast.image}
                                          alt=""
                                          crossOrigin="anonymous"
                                          width="100%"
                                        />
                                      }
                                      { !ownedPodcast.image &&
                                        <FontAwesomeIcon
                                          icon={faPodcast}
                                          color="#5f666d"
                                          size="2x"
                                        />
                                      }
                                    </div>
                                    <div className="pl-4 flex-grow-4">
                                      <div>
                                        <div className="font-weight-bold">
                                          <a
                                            href={this.state.ownedPodcasts[0].listennotes_url}
                                            target="_blank"
                                          >
                                            {this.state.ownedPodcasts[0].title_original}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          })
                        }
                      </Card.Body>
                    </div>
                  </Collapse>
                </Card>
                <hr />
              </div>
            }
          </div>
          <PodcastSummaryGraphs
            productBrandId={this.props.brandId}
            category={this.props.category}
            brand={this.props.brand}
            allMonthsChecked={this.props.allMonthsChecked}
            updateAllMonthsChecked={this.props.updateAllMonthsChecked}
            allMonthsStartDate={this.props.allMonthsStartDate}
          />
        </div>
        <div>
          <BrandMetricsByMonthTableContainer
            type="Podcast"
            category={this.props.category}
            brand={this.props.brand}
            audienceProfileId={this.props.category.audience_profile_id}
          />
        </div>
        { this.state.podcastSetup &&
          <div>
            <h5
              ref={(ref) => this.podcastDetails = ref}
              className="my-2 p-2 bg-bops-blue text-light"
            >
              Details
              <span
                className="ml-4 mr-2"
                style={{ fontSize: '1rem', fontWeight: 'normal' }}
              >Date Range</span>
              <DateRangePicker
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                updateDates={this.onFilterDatesChange}
              />
              <div
                className="d-inline-block ml-4"
                style={{ fontSize: '1rem', fontWeight: 'normal' }}
              >
                <Form.Check
                  className="ml-4"
                  type="checkbox"
                  label="Earned"
                  checked={this.state.earnedChecked}
                  onChange={(event) => this.onCheckboxChange(event, 'earnedChecked')}
                  inline
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label="Owned"
                  checked={this.state.ownedChecked}
                  onChange={(event) => this.onCheckboxChange(event, 'ownedChecked')}
                  inline
                />
              </div>
              <div
                className="d-inline-block ml-4"
                style={{ fontSize: '1rem', fontWeight: 'normal' }}
              >
                <Form.Check
                  className="ml-4"
                  type="checkbox"
                  label={`Corporate ${this.state.cppCounts.companyCount != null ? `(${numberWithCommas(this.state.cppCounts.companyCount)})` : ''}`}
                  checked={this.props.companyChecked}
                  onChange={(event) => this.props.onCppCheckChange('company', event.currentTarget.checked)}
                  inline
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label={`Portfolio ${this.state.cppCounts.portfolioCount != null ? `(${numberWithCommas(this.state.cppCounts.portfolioCount)})` : ''}`}
                  checked={this.props.portfolioChecked}
                  onChange={(event) => this.props.onCppCheckChange('portfolio', event.currentTarget.checked)}
                  inline
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label={`Product Brand ${this.state.cppCounts.productCount != null ? `(${numberWithCommas(this.state.cppCounts.productCount)})` : ''}`}
                  checked={this.props.productChecked}
                  onChange={(event) => this.props.onCppCheckChange('product', event.currentTarget.checked)}
                  inline
                />
              </div>
            </h5>
            <div>
              <PodcastEpisodesDisplay
                brandId={this.props.brandId}
                podcastEpisodes={this.state.podcastEpisodeResults}
              />
            </div>
          </div>
        }
      </div>
    );
  }
};
