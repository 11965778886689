import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ShareOfChart from '../graphs/ShareOfChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryVideoShareOfGraphs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organicTrafficGraphData: {},
      adwordsTrafficGraphData: {},
      categoryWebShareOfData: [],
      categoryOrganicTrafficShareOfData: [],
      categoryAdwordTrafficShareOfData: [],
      categoryWebShareOfDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getCategoryWebShareOfData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.getCategoryWebShareOfData();
    }
  }

  getCategoryWebShareOfData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryWebShareOfDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-share-of-web-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const organicTrafficGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const adwordsTrafficGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const categoryWebShareOfData = response.data;
        const categoryOrganicTrafficShareOfData = [];
        const categoryAdwordTrafficShareOfData = [];
        for (const monthStats of categoryWebShareOfData) {
          const organicTrafficMonthData = { month: monthStats.month };
          let monthTotalOrganicTraffic = 0;
          const adwordsTrafficData = { month: monthStats.month };
          let monthTotalAdwordsTraffic = 0;

          // get totals for percent
          for (const pb of monthStats.product_brands) {
            monthTotalOrganicTraffic += pb.organic_traffic;
            monthTotalAdwordsTraffic += pb.adwords_traffic;
            // insert graph data
            organicTrafficGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.organic_traffic,
            ]);
            adwordsTrafficGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.adwords_traffic,
            ]);
          }

          // loop again to determine %
          for (const pb of monthStats.product_brands) {
            let organicTrafficPercent = 0;
            if (monthTotalOrganicTraffic > 0) {
              organicTrafficPercent = round((pb.organic_traffic/monthTotalOrganicTraffic)*100, 1);
            }
            organicTrafficMonthData[pb.product_brand_name] = {
              count: pb.organic_traffic,
              percent: organicTrafficPercent
            }

            let adwordsTrafficPercent = 0;
            if (monthTotalAdwordsTraffic > 0) {
              adwordsTrafficPercent = round((pb.adwords_traffic/monthTotalAdwordsTraffic)*100, 1);
            }
            adwordsTrafficData[pb.product_brand_name] = {
              count: pb.adwords_traffic,
              percent: adwordsTrafficPercent
            }
          }
          categoryOrganicTrafficShareOfData.push(organicTrafficMonthData);
          categoryAdwordTrafficShareOfData.push(adwordsTrafficData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            organicTrafficGraphData,
            adwordsTrafficGraphData,
            categoryWebShareOfData,
            categoryOrganicTrafficShareOfData,
            categoryAdwordTrafficShareOfData,
            categoryWebShareOfDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch category share of web stats...');
        if (this.state.isMounted) {
          this.setState(() => ({
            organicTrafficGraphData: {},
            adwordsTrafficGraphData: {},
            categoryWebShareOfData: [],
            categoryOrganicTrafficShareOfData: [],
            categoryAdwordTrafficShareOfData: [],
            categoryWebShareOfDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  }

  render () {
    return (
      <div>
        <ShareOfChart
          title="Share of Organic Traffic"
          icon={
            <FontAwesomeIcon
              className="mr-2"
              icon={faGlobe}
              color="#4fa2f3"
            />
          }
          description="Share of Organic Traffic allows you to visualize the organic traffic by brand for the category"
          data={this.state.organicTrafficGraphData}
          legendData={this.state.categoryOrganicTrafficShareOfData}
          loading={this.state.categoryWebShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Web"
          contextChartName="Share of Organic Traffic"
          shareClassName="category-share-of-organic-traffic-img"
        />
        <ShareOfChart
          title="Share of Adwords Traffic"
          icon={
            <FontAwesomeIcon
              className="mr-2"
              icon={faGlobe}
              color="#4fa2f3"
            />
          }
          description="Share of Adwords Traffic allows you to visualize the adwords traffic by brand for the category"
          data={this.state.adwordsTrafficGraphData}
          legendData={this.state.categoryAdwordTrafficShareOfData}
          loading={this.state.categoryWebShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Web"
          contextChartName="Share of Adwords Traffic"
          shareClassName="category-share-of-adwords-traffic-img"
        />
      </div>
    );
  }
};
