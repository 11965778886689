import React from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';

export default class CustomerObjectiveForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      objectiveName: '',
      error: ''
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  onObjectiveNameChange = (event) => {
    const objectiveName = event.currentTarget.value;
    this.setState(() => ({ objectiveName }));
  }

  saveCustomerObjective = () => {
    if (this.state.objectiveName && this.state.objectiveName.trim() !== '') {
      this.setState(() => ({ error: '' }));
      const customerObjective = {
        customer_id: this.props.customerId,
        name: this.state.objectiveName,
      };
      axios.post(
        `${LISTEN_ENDPOINT}/api/customer-objectives`,
        customerObjective,
        HEADERS
      ).then(response => {
        this.props.removeObjectiveForm(this.props.id);
        this.props.refreshObjectives();
      }).catch(error => {
        console.log('Error: unable to create customer objective');
        this.setState(() => ({ error: 'Unable to create objective.' }));
      });
    } else {
      this.setState(() => ({ error: 'Please provide and objective name.' }));
    }
  };

  render () {
    return (
      <div>
        <Form.Row>
          <Col>
            <Form.Control
              type="text"
              value={this.state.objectiveName}
              onChange={this.onObjectiveNameChange}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  this.saveCustomerObjective();
                }
              }}
              size="sm"
              autoFocus
            />
            { this.state.error &&
              <div className="text-danger" style={{ fontSize: '.75rem' }}>
                {this.state.error}
              </div>
            }
          </Col>
          <Col xs={3} lg={2}>
            <div className="mt-1 text-right">
              <Button
                className="px-1 py-0 mr-2"
                size="sm"
                variant="success"
                onClick={this.saveCustomerObjective}
              >
                <FontAwesomeIcon icon={faCheck}/>
              </Button>
              <Button
                size="sm"
                variant="danger"
                onClick={() => this.props.removeObjectiveForm(this.props.id)}
                style={{ padding: '0px 6px' }}
              >
                <FontAwesomeIcon icon={faTimes}/>
              </Button>
            </div>
          </Col>
        </Form.Row>
      </div>
    );
  }
};
