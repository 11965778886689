export const colors = [
  '#4171cd',
  '#03ae71',
  '#f4941b',
  '#f4c204',
  '#d49664',
  '#f25456',
  '#9e67ab',
  '#bca604',
  '#846e1c',
  '#fc763c',
  '#b462ac',
  '#f492fc',
  '#bc4a94',
  '#9c7ef4',
  '#9c52b4',
  '#5c6ebc',
  '#7c868c',
  '#ac968c',
  '#4c7450',
  '#ac7a4c',
  '#7cae54',
  '#4c7450',
  '#9c1a6c',
  '#ac3e94',
  '#b41204'
];
