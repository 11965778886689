import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ClipLoader from 'react-spinners/ClipLoader';
import DateRangePicker from '../common/DateRangePicker';
import BrandChannelSummaryContainer from '../../containers/brands/BrandChannelSummaryContainer';
import BrandMetricsByMonthTableContainer from '../../containers/metrics/BrandMetricsByMonthTableContainer';
import GeneralSummaryGraph from '../graphs/GeneralSummaryGraph';
import SearchAdDetails from './SearchAdDetails';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class SearchAds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchAdsTrendData: [],
      searchAdsTrendDataLoading: false,
      categoryAverageSearchAdData: [],
      searchAds: [],
      searchAdsLoading: false,
      searchAdCountScoreType: undefined,
      startDate: moment().utc().subtract(3, 'month').startOf('day'),
      endDate: moment().utc().endOf('day'),
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchSearchAdsTrendData();
    this.fetchSearchAds();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.brand !== this.props.brand) {
      this.fetchSearchAdsTrendData();
      this.fetchSearchAds();
    }
    if (prevProps.allMonthsChecked !== this.props.allMonthsChecked) {
      this.fetchSearchAdsTrendData();
    }
    if (
        prevState.startDate !== this.state.startDate ||
        prevState.endDate !== this.state.endDate
    ) {
      this.fetchSearchAds();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  fetchSearchAdsTrendData = () => {
    if (this.props.brand) {
      this.setState(() => ({ searchAdsTrendDataLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/serp-search-ads-trend?product_brand_id=${this.props.brandId}${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let searchAdsTrendData = response.data.monthly_stats;
        const searchAdCountScoreType = response.data.search_ad_count ? response.data.search_ad_count.score_type : undefined;
        searchAdsTrendData = searchAdsTrendData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAverageSearchAdData[i]))
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            searchAdsTrendData,
            searchAdCountScoreType,
            searchAdsTrendDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch brand ads data');
        if (this.state.isMounted) {
          this.setState(() => ({
            searchAdsTrendData: [],
            searchAdCountScoreType: undefined,
            searchAdsTrendDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=serp_search_ads_stats${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAverageSearchAdData = response.data.category_stats;
          // merge category average data into original data source
          const searchAdsTrendData = this.state.searchAdsTrendData.map((x, i) => {
            return (Object.assign({}, x, categoryAverageSearchAdData[i]))
          });
          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAverageSearchAdData,
              searchAdsTrendData
            }))
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average search ad data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAverageSearchAdData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        })
      }
    }
  };

  fetchSearchAds = () => {
    if (this.props.brand) {
      this.setState(() => ({ searchAdsLoading: true }));
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/serp-search-ads?product_brand_id=${this.props.brand.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        const searchAds = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            searchAds,
            searchAdsLoading: false
          }));
        }
      }).catch(error => {

        console.log('Error: failed to fetch search ads...');
        if (this.state.isMounted) {
          this.setState(() => ({
            searchAds: [],
            searchAdsLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onFilterDatesChange = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  };

  render () {
    return (
      <div>
        <div>
          <BrandChannelSummaryContainer
            channel="Web"
            overviewOverride="Search Ads"
            category={this.props.category}
            brand={this.props.brand}
          />
        </div>
        <div>
          <h5 className="my-2 p-2 bg-bops-blue text-light">
            Metric Trends
            <div
              className="d-inline-block ml-4"
              style={{ fontSize: '1rem', fontWeight: 'normal' }}
            >
              <Form.Check
                id="searchAdsAllMonthsCheckbox"
                className="ml-2"
                type="checkbox"
                label="All Months"
                checked={this.props.allMonthsChecked}
                onChange={(event) => this.props.updateAllMonthsChecked(event.currentTarget.checked)}
                inline
              />
            </div>
          </h5>
          { this.state.searchAdsTrendDataLoading &&
            <div className="m-4 text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !this.state.searchAdsTrendDataLoading &&
            <Row>
              <Col xs={12} lg={6}>
                <div className="mb-2">
                  <GeneralSummaryGraph
                    title="Search Ads"
                    icon={
                      <img
                        className="mr-2 align-top"
                        src="/images/Reviews_Summary_Icon.png"
                        alt=""
                      />
                    }
                    metricName="web_semrush_search_ads_count"
                    data={this.state.searchAdsTrendData}
                    dataKey="search_ad_count"
                    dataLabel="Search Ads"
                    scoreType={this.state.searchAdCountScoreType}
                    loading={this.state.searchAdsTrendDataLoading}
                    allowDecimals={false}
                    showPercentChange={true}
                    showAverageData={true}
                    averageDataKey="serp_search_ads_stats"
                    averageDataLabel="Category Average"
                    showTooltip={true}
                    contextType="brand"
                    contextCategory={this.props.category}
                    contextBrand={this.props.brand}
                    contextChannel="Ads"
                    contextChartName="Search Ads"
                  />
                </div>
              </Col>
            </Row>
          }
        </div>
        <div>
          <BrandMetricsByMonthTableContainer
            type="Ads"
            category={this.props.category}
            brand={this.props.brand}
            audienceProfileId={this.props.category.audience_profile_id}
          />
        </div>
        <div>
          <h5 className="my-2 p-2 bg-bops-blue text-light">
            Details
            <span
              className="ml-4 mr-2"
              style={{ fontSize: '1rem', fontWeight: 'normal' }}
            >Date Range</span>
            <DateRangePicker
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              updateDates={this.onFilterDatesChange}
            />
          </h5>
          <div>
            { this.state.searchAdsLoading &&
              <div className="m-4 text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.searchAdsLoading &&
              <div>
                <SearchAdDetails
                  ads={this.state.searchAds}
                  type="search"
                />
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
};
