import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import ClipLoader from 'react-spinners/ClipLoader';
import Table from 'react-bootstrap/Table';
import MetricTooltipContainer from '../../containers/metrics/MetricTooltipContainer';
import MetricDistributionSummaryModal from '../metrics/MetricDistributionSummaryModal';
import { getPercentChange, numberWithCommas, round } from '../../utils/numbers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAssistiveListeningSystems,
  faClipboardList,
  faDesktop,
  faEnvelope,
  faExclamationTriangle,
  faFileAlt,
  faGlobe,
  faMagnifyingGlass,
  faNewspaper,
  faPodcast,
  faRectangleAd,
  faStarHalfAlt,
  faVideo
} from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class BrandMetricsVsCompetitorTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      competitorBrandId: undefined,
      competitorBrand: undefined,
      competitorBrandMetricsMap: {},
      competitorBrandMetricsData: [],
      competitorBrandMetricsDataLoading: false,
      data: [],
      selectedMetricName: undefined,
      metricDistributionModalOpen: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.category && this.props.category.product_brands.length > 0) {
      for (const brand of this.props.category.product_brands) {
        if (brand.id !== this.props.brand.id) {
          this.onCompetitorBrandChange({
            currentTarget: {
              value: String(brand.id)
            }
          });
          break;
        }
      }
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.competitorBrand !== this.state.competitorBrand) {
      this.fetchCompetitorBrandData();
    }
    if (
      prevProps.brandMetricsData !== this.props.brandMetricsData ||
      prevState.competitorMetricsData !== this.state.competitorMetricsData ||
      prevProps.stageName !== this.props.stageName
    ) {
      this.setData();
    }
    if (prevProps.category !== this.props.category) {
      if (this.props.category && this.props.category.product_brands.length > 0) {
        for (const brand of this.props.category.product_brands) {
          if (brand.id !== this.props.brand.id) {
            this.onCompetitorBrandChange({
              currentTarget: {
                value: String(brand.id)
              }
            });
            break;
          }
        }
      }
    }
  };

  fetchCompetitorBrandData = () => {
    if (this.state.competitorBrand) {
      this.setState(() => ({ competitorBrandMetricsDataLoading: true }));
      const competitorBrandMetricDataRequests = [];
      const formattedEndDateStartDate = moment(this.props.endDate).subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDateEndDate = this.props.endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');
      for (const channel of this.props.channels) {
        competitorBrandMetricDataRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/metrics/channel/brand-scores/range?logged_customer_id=${this.props.user.customerId}&company_id=${this.state.competitorBrand.company_id}&product_brand_id=${this.state.competitorBrand.id}&category_id=${this.props.category.id}&category_type=${this.props.category.category_type}&channel_category_name=${channel}&start_date=${formattedEndDateStartDate}&end_date=${formattedEndDateEndDate}`,
            HEADERS
          ).then(response => {
            const competitorBrandMetrics = response.data;
            return { competitorBrandMetrics };
          }).catch(error => {
            console.error(`Error: unable to fetch competitor brand metrics for channel ${channel}...`);
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return { competitorBrandMetrics: [] };
          })
        );
      }

      Promise.all(competitorBrandMetricDataRequests).then(responses => {
        let competitorBrandMetricsMap = {};
        for (const response of responses) {
          if (response.competitorBrandMetrics) {
            for (const metric of response.competitorBrandMetrics)
            competitorBrandMetricsMap[metric.brand_metric_id] = metric;
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            competitorBrandMetricsMap,
            competitorBrandMetricsDataLoading: false
          }), () => this.setData());
        }
      });
    }
  };

  onCompetitorBrandChange = (event) => {
    const competitorBrandId = event.currentTarget.value;
    let competitorBrand;
    for (const brand of this.props.category.product_brands) {
      if (competitorBrandId === String(brand.id)) {
        competitorBrand = brand;
      }
    }
    this.setState(() => ({
      competitorBrandId,
      competitorBrand
    }));
  };

  setData = () => {
    if (this.props.brandMetricsData && this.state.competitorBrandMetricsMap) {
      // merge brand metrics data and competitor data
      const brandMetricsMap = {};
      for (let metric of this.props.brandMetricsData) {
        // set original brand end_date_score to brand_score
        metric.brand_score = metric.end_date_score;
        // set competitor brand score
        metric.competitor_brand_score = this.state.competitorBrandMetricsMap[metric.brand_metric_id] ?
          this.state.competitorBrandMetricsMap[metric.brand_metric_id].metric_score :
          null
        // calculate difference
        metric.percentage_difference = getPercentChange(metric.competitor_brand_score, metric.brand_score);
        if (metric.percentage_difference) {
          metric.percentage_difference = round(metric.percentage_difference, 1)
        }

        if (
          this.props.stageName === 'All' ||
          metric.stage_name === this.props.stageName
        ) {
          switch (metric.channel_name) {
            case 'Email Campaigns':
            case 'Emails':
              if (!brandMetricsMap['emails']) {
                brandMetricsMap['emails'] = [metric];
              } else {
                brandMetricsMap['emails'].push(metric);
              }
              break;
            case 'News':
              if (!brandMetricsMap['news']) {
                brandMetricsMap['news'] = [metric];
              } else {
                brandMetricsMap['news'].push(metric);
              }
              break;
            case 'Social':
              if (!brandMetricsMap['social']) {
                brandMetricsMap['social'] = [metric];
              } else {
                brandMetricsMap['social'].push(metric);
              }
              break;
            case 'Product Reviews':
              if (!brandMetricsMap['product_reviews']) {
                brandMetricsMap['product_reviews'] = [metric];
              } else {
                brandMetricsMap['product_reviews'].push(metric);
              }
              break;
            case 'Employer Reviews':
              if (!brandMetricsMap['employer_reviews']) {
                brandMetricsMap['employer_reviews'] = [metric];
              } else {
                brandMetricsMap['employer_reviews'].push(metric);
              }
              break;
            case 'Videos':
              if (!brandMetricsMap['video']) {
                brandMetricsMap['video'] = [metric];
              } else {
                brandMetricsMap['video'].push(metric);
              }
              break;
            case 'Web Analytics':
            case 'Web':
              if (!brandMetricsMap['web']) {
                brandMetricsMap['web'] = [metric];
              } else {
                brandMetricsMap['web'].push(metric);
              }
              break;
            case 'Search Engine Results':
              if (!brandMetricsMap['serp']) {
                brandMetricsMap['serp'] = [metric];
              } else {
                brandMetricsMap['serp'].push(metric);
              }
              break;
            case 'Ads':
              if (!brandMetricsMap['ads']) {
                brandMetricsMap['ads'] = [metric];
              } else {
                brandMetricsMap['ads'].push(metric);
              }
              break;
            case 'Webinar':
              if (!brandMetricsMap['webinar']) {
                brandMetricsMap['webinar'] = [metric];
              } else {
                brandMetricsMap['webinar'].push(metric);
              }
              break;
            case 'Blogs':
              if (!brandMetricsMap['blog']) {
                brandMetricsMap['blog'] = [metric];
              } else {
                brandMetricsMap['blog'].push(metric);
              }
              break;
            case 'Podcast':
              if (!brandMetricsMap['podcast']) {
                brandMetricsMap['podcast'] = [metric];
              } else {
                brandMetricsMap['podcast'].push(metric);
              }
              break;
            case 'Surveys via BrandOps':
              if (!brandMetricsMap['survey']) {
                brandMetricsMap['survey'] = [metric];
              } else {
                brandMetricsMap['survey'].push(metric);
              }
              break;
            default:
              break;
          }
        }
      }

      const data = [];
      if (brandMetricsMap.news) {
        data.push({
          type: 'news',
          rowsData: brandMetricsMap.news
        });
      }
      if (brandMetricsMap.social) {
        data.push({
          type: 'social',
          rowsData: brandMetricsMap.social
        });
      }
      if (brandMetricsMap.product_reviews) {
        data.push({
          type: 'product_reviews',
          rowsData: brandMetricsMap.product_reviews
        });
      }
      if (brandMetricsMap.employer_reviews) {
        data.push({
          type: 'employer_reviews',
          rowsData: brandMetricsMap.employer_reviews
        });
      }
      if (brandMetricsMap.video) {
        data.push({
          type: 'video',
          rowsData: brandMetricsMap.video
        });
      }
      if (brandMetricsMap.web) {
        data.push({
          type: 'web',
          rowsData: brandMetricsMap.web
        });
      }
      if (brandMetricsMap.serp) {
        data.push({
          type: 'serp',
          rowsData: brandMetricsMap.serp
        });
      }
      if (brandMetricsMap.ads) {
        data.push({
          type: 'ads',
          rowsData: brandMetricsMap.ads
        });
      }
      if (brandMetricsMap.webinar) {
        data.push({
          type: 'webinar',
          rowsData: brandMetricsMap.webinar
        });
      }
      if (brandMetricsMap.blog) {
        data.push({
          type: 'blog',
          rowsData: brandMetricsMap.blog
        });
      }
      if (brandMetricsMap.podcast) {
        data.push({
          type: 'podcast',
          rowsData: brandMetricsMap.podcast
        });
      }
      if (brandMetricsMap.emails) {
        data.push({
          type: 'emails',
          rowsData: brandMetricsMap.emails
        });
      }
      if (brandMetricsMap.survey) {
        data.push({
          type: 'survey',
          rowsData: brandMetricsMap.survey
        });
      }

      this.setState(() => ({ data }));
    }
  };

  getIcon = (type) => {
    let icon;
    let label;
    switch (type) {
      case 'web':
        icon = faGlobe;
        label = 'Web';
        break;
      case 'webinar':
        icon = faDesktop;
        label = 'Webinar';
        break;
      case 'social':
        icon = faAssistiveListeningSystems;
        label = 'Social';
        break;
      case 'news':
        icon = faNewspaper;
        label = 'News';
        break;
      case 'product_reviews':
        icon = faStarHalfAlt;
        label = 'Product Reviews';
        break;
      case 'employer_reviews':
        icon = faStarHalfAlt;
        label = 'Employer Reviews';
        break;
      case 'serp':
        icon = faMagnifyingGlass;
        label = 'Search';
        break;
      case 'ads':
        icon = faRectangleAd;
        label = 'Ads';
        break;
      case 'blog':
        icon = faFileAlt;
        label = 'Blogs';
        break;
      case 'podcast':
        icon = faPodcast;
        label = 'Podcasts';
        break;
      case 'survey':
        icon = faClipboardList;
        label = 'Surveys';
        break;
      case 'emails':
        icon = faEnvelope;
        label = 'Emails';
        break;
      case 'video':
        icon = faVideo;
        label = 'Video';
        break;
    }
    if (icon) {
      return (
        <div className="text-center">
          <FontAwesomeIcon
            icon={icon}
            size="2x"
          />
          <div><b>{label}</b></div>
        </div>
      )
    } else {
      return '';
    }
  }

  metricRows = (data) => (
    <React.Fragment>
      { data.map((group, gi) => {
          return (
            <React.Fragment key={`bmvc-g-${group.type}`}>
              { group.rowsData.map((row, ri) => {
                  if (!row.metric_name) {
                    // Spacing Row
                    return (
                      <tr
                        key={`bmvc-g-${group.type}-mr-${ri}`}
                        style={{ height: '33px' }}
                      >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  } else {
                    return(
                      <tr key={`bmvc-bmr-${ri}`}>
                        { ri === 0 &&
                          <td
                            className="bg-white align-middle"
                            rowSpan={`${group.rowsData.length+1}`}
                          >
                            {this.getIcon(group.type)}
                          </td>
                        }
                        <td
                          className="pl-4"
                          style={{
                            width: '40%',
                            color: this.isMetricSetup(row) ? 'black' : '#b3b3b3'
                          }}
                        >
                          <MetricTooltipContainer
                            tooltipTrigger={
                              <span>
                                {row.metric_label}
                              </span>
                            }
                            metricName={row.metric_name}
                            openMetricDistributionModal={this.openMetricDistributionModal}
                          />
                        </td>
                        <td className="text-center">
                          { row.brand_score != null &&
                            <div>
                              {numberWithCommas(row.brand_score)}
                              { row.is_percent === 1 &&
                                <span>{'%'}</span>
                              }
                            </div>
                          }
                        </td>
                        <td className="text-center">
                          { row.competitor_brand_score != null &&
                            <div>
                              {numberWithCommas(row.competitor_brand_score)}
                              { row.is_percent === 1 &&
                                <span>{'%'}</span>
                              }
                            </div>
                          }
                        </td>
                        <td className="text-center">
                          { (
                              (row.score_type === 'high_is_good' && row.percentage_difference < 0) ||
                              (row.score_type === 'low_is_good' && row.percentage_difference > 0)
                            ) &&
                            <FontAwesomeIcon
                              icon={faExclamationTriangle}
                              color="#6c757d"
                            />
                          }
                        </td>
                      </tr>
                    )
                  }
                })
              }
              <tr
                className="bg-white"
                style={{ height: '33px' }}
              >
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </React.Fragment>
          )
        })
      }
    </React.Fragment>
  );

  isMetricSetup = (metric) => {
    if (
      this.props.metricsSetupMap &&
      this.props.metricsSetupMap[metric.site_name] &&
      !this.props.metricsSetupMap[metric.site_name].setup
    ) {
      return false;
    } else {
      return true;
    }
  };

  openMetricDistributionModal = (selectedMetricName) => {
    if (selectedMetricName) {
      this.setState(() => ({
        selectedMetricName,
        metricDistributionModalOpen: true,
      }));
    }
  };

  closeMetricDistributionModal = () => {
    this.setState(() => ({
      selectedMetricName: undefined,
      metricDistributionModalOpen: false,
    }));
  };

  render () {
    return (
      <div>
        <div className="my-4">
          { this.props.category &&
            <div>
              <div className="d-inline-block mr-2">
                Competitor
              </div>
              <div
                className="d-inline-block"
                style={{
                  minWidth: '200px',
                  maxWidth: '300px'
                }}
              >
                <Form.Control
                  as="select"
                  value={this.state.competitorBrandId}
                  onChange={this.onCompetitorBrandChange}
                >
                  { this.props.category.product_brands.map((brand, i) => {
                      if (brand.id !== this.props.brand.id) {
                        return (
                          <option
                            key={`bmvc-b-${i}`}
                            value={brand.id}
                          >
                            {brand.name}
                          </option>
                        )
                      }
                    })
                  }
                </Form.Control>
              </div>
            </div>
          }
        </div>
        { this.state.competitorBrand &&
          <div>
            { (this.props.brandMetricsDataLoading || this.state.competitorBrandMetricsDataLoading) &&
              <div className="m-4 text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { (!this.props.brandMetricsDataLoading && !this.state.competitorBrandMetricsDataLoading) &&
              <div
                className="pre-scrollable"
                style={{
                  minHeight: 'calc(100vh - 350px)',
                  maxHeight: 'calc(100vh - 350px)',
                  overflow: 'auto',
                  overflowX: 'hidden'
                }}
              >
                <Table
                  bordered
                  striped
                  size="sm"
                  style={{
                    overflow: 'auto',
                  }}
                >
                  <thead className="bg-dark text-light text-center">
                    <tr
                      style={{
                        position: 'sticky',
                        top: -1,
                        zIndex: 1
                      }}
                    >
                      <th
                        className="bg-dark"
                        style={{ width: '100px' }}
                      ></th>
                      <th
                        className="bg-dark text-left"
                        style={{ width: '300px' }}
                      >
                        <div className="pl-3">
                          Metrics
                        </div>
                      </th>
                      <th className="bg-dark">
                        <div>{this.props.brand.name}</div>
                        <div style={{ fontSize: '.875rem', fontWeight: 'normal' }}>
                          {this.props.endDate.format('MM/DD/YYYY')}
                        </div>
                      </th>
                      <th className="bg-dark">
                        <div>{this.state.competitorBrand.name}</div>
                        <div style={{ fontSize: '.875rem', fontWeight: 'normal' }}>
                          {this.props.endDate.format('MM/DD/YYYY')}
                        </div>
                      </th>
                      <th className="bg-dark">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.state.data &&
                      <React.Fragment>
                        {this.metricRows(this.state.data)}
                      </React.Fragment>
                    }
                  </tbody>
                </Table>

                <MetricDistributionSummaryModal
                  isOpen={this.state.metricDistributionModalOpen}
                  handleClose={this.closeMetricDistributionModal}
                  metricName={this.state.selectedMetricName}
                  audienceProfileId={this.props.category.audience_profile_id}
                  customerId={this.props.user.customerId}
                />
              </div>
            }
          </div>
        }
      </div>
    );
  }
};
