import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import ClipLoader from 'react-spinners/ClipLoader';
import SharePopUpContainer from '../../../containers/share/SharePopUpContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faLink } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class CategoryInstagramTopPosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryTopPosts: [],
      categoryTopPostsLoading: false
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchCategoryTopPosts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.fetchCategoryTopPosts();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  fetchCategoryTopPosts = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryTopPostsLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-instagram-top-posts?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const categoryTopPosts = response.data;
        if (this.state.isMounted) {
          this.setState((prevState) => ({
            categoryTopPosts,
            categoryTopPostsLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch category instagram top posts.');
        if (this.state.isMounted) {
          this.setState((prevState) => ({
            categoryTopPosts: [],
            categoryTopPostsLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  parseURLs = (text) => {
    return text.replace(/[A-Za-z]+:\/\/[A-Za-z0-9-_]+\.[A-Za-z0-9-_:%&~?/.=]+/g, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };

  parseTextForHTMLReplacements = (text) => {
    text = this.parseURLs(text);
    return text;
  };

  render () {
    return (
      <div>
        <h5 className="mb-4 p-2 bg-bops-blue text-light">
          Top Content
        </h5>
        { this.state.categoryTopPostsLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.categoryTopPostsLoading &&
          <Row>
            { this.state.categoryTopPosts.map((post, i) => {
                return (
                  <Col
                    key={`fop-${post.id}`}
                    className="mb-4"
                    xs={12}
                    lg={6}
                  >
                    <Card
                      id={`ig-owned-post-${i}`}
                      className="h-100"
                      style={{ maxWidth: '800px' }}
                    >
                      <Card.Header className="bg-white">
                        { post.owner_username &&
                          <span
                            className="mr-2"
                            style={{ fontWeight: 'bold' }}
                          >
                            {post.owner_username}
                          </span>
                        }
                        { post.published_at &&
                          <span>
                            {`- ${moment(post.published_at).format('MMM D')}`}
                          </span>
                        }
                        <div className="d-inline-block float-right">
                          <SharePopUpContainer
                            shareElementId={`ig-owned-post-${i}`}
                            elementLabel={`ig-owned-post-${i}-img`}
                            position="top"
                            contextType="category"
                            contextCategory={this.props.category}
                            contextBrand={null}
                            contextChannel="Instagram"
                            contextProperty="Top Content"
                            contextChartName="Top Content"
                          />
                        </div>
                        <div className="d-inline-block mr-2 float-right">
                          <FontAwesomeIcon
                            className="mt-1 float-right"
                            icon={faLink}
                            color="#08a0e9"
                            onClick={() => {
                              window.open(post.post_url, '_blank');
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <div>
                          { post.post_type === 'Image' && post.display_url &&
                            <div className="mt-2 text-center">
                              <img
                                src={post.display_url}
                                alt=""
                                crossOrigin="anonymous"
                                style={{ maxWidth: '500px' }}
                              />
                            </div>
                          }
                          { (
                              post.post_type === 'Sidecar' &&
                              post.images &&
                              post.images.length > 0
                            ) &&
                            <div className="mt-2 text-center">
                              <Carousel>
                                { post.images.map((image, i) => {
                                    return (
                                      <Carousel.Item
                                        key={`ig-p-${post.id}-i-${i}`}
                                      >
                                        <img
                                          src={image}
                                          alt=""
                                          crossOrigin="anonymous"
                                          style={{ maxWidth: '500px' }}
                                        />
                                      </Carousel.Item>
                                    )
                                  })
                                }
                              </Carousel>
                            </div>
                          }
                          { post.post_type === 'Video' && post.video_url &&
                            <div className="mt-2 text-center">
                              <video
                                style={{
                                  maxWidth: '500px'
                                }}
                                controls
                              >
                                <source src={post.video_url} />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          }
                          { post.post_text &&
                            <div
                              className="mt-2"
                              dangerouslySetInnerHTML={{__html: this.parseTextForHTMLReplacements(post.post_text)}}
                            />
                          }
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={faHeart}
                          color="#6c757d"
                        />
                        {post.likes_count || 0}
                      </Card.Footer>
                    </Card>
                  </Col>
                )
              })
            }
          </Row>
        }
      </div>
    );
  }
};
