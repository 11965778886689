import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ReactTable from 'react-table';
import ClipLoader from 'react-spinners/ClipLoader';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import MetricTooltipContainer from '../../containers/metrics/MetricTooltipContainer';
import MetricDistributionSummaryModal from '../metrics/MetricDistributionSummaryModal';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';
import { NavLink } from 'react-router-dom';

export default class CategoryPodcastsSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      summaryData: [],
      metricDistributionModalOpen: false,
      selectedMetricName: undefined,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.setSummaryData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.selectedMonth !== this.props.selectedMonth ||
      prevProps.categoryPodcastData !== this.props.categoryPodcastData
    ) {
      this.setSummaryData();
    }
  };

  selectMonth = (selectedMonth) => {
    if (selectedMonth !== this.props.selectedMonth) {
      this.props.updateSelectedMonth(selectedMonth);
    }
  };

  setSummaryData = () => {
    if (this.props.selectedMonth) {
      let summaryData = [];
      for (const monthData of this.props.categoryPodcastData) {
        if (monthData.month === this.props.selectedMonth.label) {
          summaryData = monthData.product_brands;
        }
      }
      this.setState(() => ({ summaryData }));
    }
  };

  openMetricDistributionModal = (selectedMetricName) => {
    this.setState(() => ({
      selectedMetricName,
      metricDistributionModalOpen: true
    }));
  };

  closeMetricDistributionModal = () => {
    this.setState(() => ({
      metricDistributionModalOpen: false
    }));
  };

  render () {
    return (
      <div>
        <Row>
          <Col xs={6} md={4} lg={3} xl={2}>
            { this.props.sixMonths.map((month, i) => {
                return (
                  <button
                    id={`categoryPodcastSummary${month.label}Button`}
                    key={`bmd${i}`}
                    type="button"
                    className= {
                      (this.props.selectedMonth && this.props.selectedMonth.label === month.label) ?
                        'mb-1 btn btn-primary w-100' :
                        'mb-1 btn border bg-white w-100'
                    }
                    onClick={() => this.selectMonth(month)}
                  >
                    {month.label}
                  </button>
                )
              })
            }
          </Col>
          <Col>
            { this.props.categoryPodcastDataLoading &&
              <div className="m-4 text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.props.categoryPodcastDataLoading &&
              <div id="categoryPodcastSummary">
                <ReactTable
                  className="-striped -highlight"
                  data={this.state.summaryData}
                  columns={[
                    {
                      Header: 'Brand',
                      headerClassName: 'bg-dark text-light border-light wordwrap',
                      accessor: 'product_brand_name',
                      sortable: true,
                      Cell: row => (
                        <div style={{ cursor: "pointer", textOverflow: 'ellipsis' }} >
                          <NavLink
                            key={`lmc${this.props.category.id}-${this.props.category.category_type}-lmb${row.original.id}`}
                            to={`/category/${encodeURIComponent(this.props.category.name)}/brand/${encodeURIComponent(row.original.product_brand_name)}#mediacast-podcast`}
                          >
                            {row.original.product_brand_name}
                          </NavLink>
                        </div>
                      )
                    },
                    {
                      Header: (
                        <MetricTooltipContainer
                          tooltipTrigger={
                            <div>
                              Podcast Episode Mentions
                            </div>
                          }
                          tooltipPosition="top"
                          metricName="podcast_listen_mention_count"
                          openMetricDistributionModal={this.openMetricDistributionModal}
                        />
                      ),
                      headerClassName: 'bg-dark text-light border-light wordwrap',
                      accessor: 'mention_count',
                      sortable: true,
                      className: 'text-center',
                      Cell: row => (
                        <div>
                          {numberWithCommas(row.original.mention_count)}
                        </div>
                      )
                    },
                    {
                      Header: (
                        <MetricTooltipContainer
                          tooltipTrigger={
                            <div>
                              Owned Podcast Episodes
                            </div>
                          }
                          tooltipPosition="top"
                          metricName="owned_podcast_episode_count"
                          openMetricDistributionModal={this.openMetricDistributionModal}
                        />
                      ),
                      headerClassName: 'bg-dark text-light border-light wordwrap',
                      accessor: 'owned_episode_count',
                      sortable: true,
                      className: 'text-center',
                      Cell: row => (
                        <div>
                          {numberWithCommas(row.original.owned_episode_count)}
                        </div>
                      )
                    }
                  ]}
                  minRows={1}
                  defaultPageSize={100}
                  showPagination={false}
                  defaultSorted={[{ id: 'mention_count', desc: true }]}
                />
                <SharePopUpContainer
                  shareElementId="categoryPodcastSummary"
                  elementLabel="category-podcast-summary-img"
                  position="bottom"
                  contextType="category"
                  contextCategory={this.props.category}
                  contextBrand={null}
                  contextChannel="Podcast"
                  contextChartName="Summary"
                  contextDateRange={`${this.props.selectedMonth.label} ${this.props.selectedMonth.date.format('YYYY')}`}
                />
              </div>
            }
          </Col>
        </Row>

        <MetricDistributionSummaryModal
          isOpen={this.state.metricDistributionModalOpen}
          handleClose={this.closeMetricDistributionModal}
          metricName={this.state.selectedMetricName}
          audienceProfileId={this.props.category.audience_profile_id}
          customerId={this.props.user.customerId}
        />
      </div>
    );
  }
};
