import React from 'react';
import Form from 'react-bootstrap/Form';

const FreeFormQuestion = (props) => {
  return (
    <div>
      <Form.Group>
        <Form.Label>
          <b>{props.question}</b>
        </Form.Label>
        <Form.Control
          type="text"
          value={props.answer}
          onChange={props.onAnswerChange}
        />
      </Form.Group>
    </div>
  )
};

export default FreeFormQuestion;
