import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ResultDisplay from './ResultDisplay';
import NoneFound from './NoneFound';

const SearchEngineResultDisplay = (props) => {
  const rowCount = Math.ceil(props.results.length / 2);
  const rows = Array.from(Array(rowCount).keys());

  return (
    <div
      className="pre-scrollable"
      style={{
        minHeight: '70vh',
        maxHeight: '70vh',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
    >
      { props.results.length === 0 &&
        <NoneFound/>
      }
      { rows.map(row => {
          const column1Index = row;
          const column2Index = row + rowCount;
          return (
            <Row key={`${props.type}ResultRow${row}`}>
              <Col xs={6} className="mb-2">
                { props.results[column1Index] &&
                  <div className={`${(props.selectedResult && props.selectedResult.id === props.results[column1Index].id)? 'border border-primary rounded bg-white h-100' : 'h-100'}`}>
                    <ResultDisplay
                      type={props.type}
                      rank={props.results[column1Index].position}
                      domain={props.results[column1Index].domain}
                      title={props.results[column1Index].title}
                      result={props.results[column1Index]}
                      onSelect={props.onResultSelect}
                    />
                  </div>
                }
              </Col>
              <Col xs={6} className="mb-2">
                { props.results[column2Index] &&
                  <div className={`${(props.selectedResult && props.selectedResult.id === props.results[column2Index].id)? 'border border-primary rounded bg-white h-100' : 'h-100'}`}>
                    <ResultDisplay
                      type={props.type}
                      rank={props.results[column2Index].position}
                      domain={props.results[column2Index].domain}
                      title={props.results[column2Index].title}
                      result={props.results[column2Index]}
                      onSelect={props.onResultSelect}
                    />
                  </div>
                }
              </Col>
            </Row>
          )
        })
      }
    </div>
  )
};

export default SearchEngineResultDisplay;
