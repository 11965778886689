import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CategoryPodcasts from './CategoryPodcasts';
import CategoryWebinars from './webinars/CategoryWebinars';
import CategoryVideos from './CategoryVideos';
import VideoSearchResults from '../video/VideoSearchResults';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPodcast, faDesktop, faVideo } from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { createUserActivityLog } from '../../utils/users';
import history from '../../routers/history';

export default class CategoryMediaCast extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'podcast'
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let selectedTab = hash.indexOf('-')>0 ? hash.substring(hash.indexOf('-')+1) : 'podcast';
      this.setState(() => ({ selectedTab }));
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  changeSelectedTab = (selectedTab, subTabName) => {
    history.push(`#mediacast-${selectedTab}`);
    this.setState(() => ({ selectedTab }));
    if (subTabName) {
      createUserActivityLog(
        this.props.user,
        `Category Tabs: Media Cast: ${subTabName}`,
        this.props.category,
      )
    }
  }

  render () {
    return (
      <div className="p-4">
        <div className="mb-4">
          <button
            id="categoryPodcastButton"
            type="button"
            className= { this.state.selectedTab === 'podcast' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('podcast', 'Podcast')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faPodcast} />
            </div>
            <div className="d-inline-block">
              Podcast
            </div>
          </button>
          <button
            id="categoryWebinarButton"
            type="button"
            className= { this.state.selectedTab === 'webinar' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('webinar', 'Webinar')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faDesktop} />
            </div>
            <div className="d-inline-block">
              Webinar
            </div>
          </button>
          <button
            id="categoryYoutubeActivityButton"
            type="button"
            className= { this.state.selectedTab === 'youtubeActivity' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('youtubeActivity', 'YouTube Activity')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faVideo} />
            </div>
            <div className="d-inline-block">
              YouTube Activity
            </div>
          </button>
          <button
            id="categoryYoutubeSearchButton"
            type="button"
            className= { this.state.selectedTab === 'youtubeSearch' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('youtubeSearch', 'YouTube Search')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faYoutube} />
            </div>
            <div className="d-inline-block">
              YouTube Search
            </div>
          </button>
        </div>
        <div
          className="pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 200px)',
            maxHeight: 'calc(100vh - 200px)',
            overflow: 'auto',
            overflowX: 'hidden'
          }}
        >
          { this.state.selectedTab === 'podcast' &&
            <CategoryPodcasts
              user={this.props.user}
              sixMonths={this.props.sixMonths}
              selectedMonth={this.props.selectedMonth}
              updateSelectedMonth={this.props.updateSelectedMonth}
              categoryId={this.props.categoryId}
              category={this.props.category}
            />
          }
          { this.state.selectedTab === 'webinar' &&
            <CategoryWebinars
              user={this.props.user}
              sixMonths={this.props.sixMonths}
              selectedMonth={this.props.selectedMonth}
              updateSelectedMonth={this.props.updateSelectedMonth}
              categoryId={this.props.categoryId}
              category={this.props.category}
            />
          }
          { this.state.selectedTab === 'youtubeActivity' &&
            <CategoryVideos
              user={this.props.user}
              sixMonths={this.props.sixMonths}
              selectedMonth={this.props.selectedMonth}
              updateSelectedMonth={this.props.updateSelectedMonth}
              categoryId={this.props.categoryId}
              category={this.props.category}
            />
          }
          { this.state.selectedTab === 'youtubeSearch' &&
            <VideoSearchResults
              location={this.props.location}
              user={this.props.user}
              categoryId={this.props.categoryId}
              category={this.props.category}
            />
          }
        </div>
      </div>
    );
  }
};
