import React from 'react';
import ReviewDetailsTab from './ReviewDetailsTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { createUserActivityLog } from '../../../utils/users';
import history from '../../../routers/history';

export default class ReviewsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'highlights'
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let selectedTab = hash.indexOf('-')>0 ? hash.substring(hash.indexOf('-')+1) : 'highlights';
      this.setState(() => ({ selectedTab }));
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  changeSelectedTab = (selectedTab, subTabName) => {
    history.push(`#reviews-${selectedTab}`);
    this.setState(() => ({ selectedTab }));
    if (subTabName) {
      createUserActivityLog(
        this.props.user,
        `Brand Tabs: Reviews: ${subTabName}`,
        this.props.category,
        this.props.brand,
      )
    }
  }

  render () {
    return (
      <div className="p-4">
        <div className="mb-4">
          <button
            id="brandReviewHighlightsButton"
            type="button"
            className= { this.state.selectedTab === 'highlights' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('highlights', 'Highlights')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faChartLine} />
            </div>
            <div className="d-inline-block">
              Highlights
            </div>
          </button>
        </div>

        <div
          className="mt-2 pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 200px)',
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          { this.state.selectedTab === 'highlights' &&
            <ReviewDetailsTab
              location={this.props.location}
              customerId={this.props.customerId}
              categoryId={this.props.categoryId}
              categoryType={this.props.categoryType}
              category={this.props.category}
              brandId={this.props.brandId}
              brand={this.props.brand}
              brandName={this.props.brandName}
              topicId={this.props.topicId}
              startDateFilter={this.props.startDateFilter}
              endDateFilter={this.props.endDateFilter}
              onFilterDatesChange={this.props.onFilterDatesChange}
              scrollToDetails={this.props.scrollToDetails}
              unsetScrollToDetails={this.props.unsetScrollToDetails}
              allMonthsChecked={this.props.allMonthsChecked}
              updateAllMonthsChecked={this.props.updateAllMonthsChecked}
              allMonthsStartDate={this.props.allMonthsStartDate}
            />
          }
        </div>
      </div>
    );
  }
};
