import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../common/CardComponent';
import {
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryReviewAvgScoresGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryReviewAvgScoreData: [],
      categoryReviewAvgScoreDataLoading: false,
      categoryKingName: '',
      ownersBrandName: ''
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getCategoryAvgReviewScoresData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.getCategoryAvgReviewScoresData();
    }
  }

  getCategoryAvgReviewScoresData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryReviewAvgScoreDataLoading: true }));
      const categoryAvgReviewScoreRequests = [];
      // get category average review scores
      categoryAvgReviewScoreRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-review-score-trend?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
          HEADERS
        ).then(response => {
          const categoryReviewScoreData = response.data;
          return { categoryReviewScoreData };
        }).catch(error => {
          console.error('Error: unable to fetch category review score trend');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryReviewScoreData: [] };
        })
      );
      // get category king average review scores
      categoryAvgReviewScoreRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-king-review-score-trend?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
          HEADERS
        ).then(response => {
          const categoryKingReviewScoreData = response.data;
          return { categoryKingReviewScoreData };
        }).catch(error => {
          console.error('Error: unable to fetch category king review score trend');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryKingReviewScoreData: [] };
        })
      );
      // get customer average review scores
      let ownersBrand;
      for (const brand of this.props.category.product_brands) {
        if (!ownersBrand && brand.company_id === this.props.user.customerId) {
          ownersBrand = brand;
        }
      }
      if (ownersBrand) {
        categoryAvgReviewScoreRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/topics/${ownersBrand.topic.id}/review-score-trend`,
            HEADERS
          ).then(response => {
            const ownedBrandReviewScoreData = response.data.monthly_stats;
            return {
              ownedBrandReviewScoreData,
              ownersBrand
            };
          }).catch(error => {
            console.error('Error: unable to fetch owned brand review score trend');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return { ownedBrandReviewScoreData: [] };
          })
        );
      }

      Promise.all(categoryAvgReviewScoreRequests).then(responses => {
        let categoryReviewScoreData = [];
        let categoryKingReviewScoreData = [];
        let ownedBrandReviewScoreData = [];
        let categoryKingName = '';
        let ownersBrandName = '';
        for (let response of responses) {
          if (response.categoryReviewScoreData) {
            categoryReviewScoreData = response.categoryReviewScoreData;
          } else if (response.categoryKingReviewScoreData) {
            categoryKingReviewScoreData = response.categoryKingReviewScoreData;
            if (categoryKingReviewScoreData.length > 0) {
              categoryKingName = categoryKingReviewScoreData[0].product_brand_name;
            }
          } else if (response.ownedBrandReviewScoreData) {
            ownedBrandReviewScoreData = response.ownedBrandReviewScoreData;
            ownersBrandName = response.ownersBrand.name;
          }
        }
        // merge the 3 data arrays
        // may be able to optimize this, but its a small data set
        const categoryReviewAvgScoreData = [];
        for (let cd of categoryReviewScoreData) {
          categoryReviewAvgScoreData.push({
            month: cd.month,
            category_avg_review_score: cd.avg_review_score
          });
        }
        for (let ckd of categoryKingReviewScoreData) {
          for (let scoreData of categoryReviewAvgScoreData) {
            if (ckd.month === scoreData.month) {
              scoreData.category_king_avg_review_score = ckd.avg_review_score;
            }
          }
        }
        for (let obd of ownedBrandReviewScoreData) {
          for (let scoreData of categoryReviewAvgScoreData) {
            if (obd.month === scoreData.month) {
              scoreData.owned_brand_avg_review_score = obd.avg_review_score;
            }
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryReviewAvgScoreData,
            categoryReviewAvgScoreDataLoading: false,
            categoryKingName,
            ownersBrandName
          }));
        }
      });
    }
  }

  render () {
    return (
      <CardComponent
        title="Average Stars"
        body={
          <div>
            { this.state.categoryReviewAvgScoreDataLoading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.categoryReviewAvgScoreDataLoading &&
              <div style={{ fontSize: '14px' }}>
                <ResponsiveContainer width="99%" height={200}>
                  <ComposedChart data={this.state.categoryReviewAvgScoreData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month"/>
                    <YAxis
                      dataKey="count"
                      domain={[0, 5]}
                    />
                    <Tooltip />

                    <Area
                      name={
                        this.state.categoryKingName ?
                          `Leader, ${this.state.categoryKingName} Average Stars` :
                          'Category King Average Stars'
                      }
                      type="monotone"
                      dataKey="category_king_avg_review_score"
                      stroke="#007bff"
                      fillOpacity={.4}
                    />
                    <Line
                      name="Category Average Stars"
                      type="monotone"
                      dataKey="category_avg_review_score"
                      strokeDasharray="10 10"
                      stroke="#009900"
                      strokeWidth={3}
                    />
                    <Line
                      name={
                        this.state.ownersBrandName ?
                          `${this.state.ownersBrandName} Average Stars` :
                          'Your Brand Average Stars'
                      }
                      type="monotone"
                      dataKey="owned_brand_avg_review_score"
                      stroke="#ffc107"
                      strokeWidth={3}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            }
          </div>
        }
        contextType="category"
        contextCategory={this.props.category}
        contextBrand={null}
        contextChannel="Product Reviews"
        contextChartName="Average Stars"
      />
    );
  }
};
