export const numberWithOrdinalSuffix = (x) => {
  const n = x % 10;
  if (!isNaN(n)) {
    const m = x % 100;
    if (n == 1 && m != 11) {
     return x + "st";
   }
   if (n == 2 && m != 12) {
     return x + "nd";
   }
   if (n == 3 && m != 13) {
     return x + "rd";
   }
   return x + "th";
  } else {
    return 'N/A';
  }
}

export const numberWithCommas = (x) => {
  if (x === undefined || x === null) {
    return;
  } else {
    let decimalSplit = x.toString().split('.');
    decimalSplit[0] = decimalSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return decimalSplit.join('.');
  }
};

export const intToShorthandString = (value) => {
  if (value >= 1000000000000) {
    // trillions
    return (value / 1000000000000).toFixed(1).replace(/\.0$/, '') + 't';
  } else if (value >= 1000000000) {
    // billions
    return (value / 1000000000).toFixed(1).replace(/\.0$/, '') + 'b';
  } else if (value >= 1000000) {
    // millions
    return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'm';
  } else if (value >= 1000) {
    // thousands
    return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  }
  return value;
}

export const intToAltShorthandString = (value) => {
  var suffixes = ['', 'k', 'm', 'b', 't'];
  var suffixNum = Math.floor(('' + value).length/5);
  var shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(10));
  if (shortValue % 1 != 0) {
      shortValue = parseInt(shortValue);
  }
  return numberWithCommas(shortValue) + suffixes[suffixNum];
}

export const validParseInt = (string) => {
  const intValue = parseInt(string);
  if (isNaN(intValue)) {
    return false;
  } else {
    return true;
  }
}

export const round = (value, decimals) => {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

export const isEven = (number) => {
  const modNum = number % 2;
  if (modNum) {
    return false;
  } else {
    return true;
  }
};

export const isOdd = (number) => {
  const modNum = number % 2;
  if (modNum) {
    return true;
  } else {
    return false;
  }
};

export const getPercentChange = (initialNumber, endNumber) => {
  if (
    typeof(initialNumber) === "number" &&
    typeof(endNumber) === "number" &&
    initialNumber != 0
  ) {
    try {
      const percentChange = ((endNumber - initialNumber) / Math.abs(initialNumber)) * 100.0;
      if (isNaN(percentChange)) {
        return null;
      } else {
        return percentChange;
      }
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
};

export const getRandomInteger = (maxInt) => {
  return Math.floor(Math.random() * maxInt);
};

export const getRandomPercentChange = () => {
  const positiveChance = Math.floor(Math.random() * 10);
  if (positiveChance > 3) {
    return Math.floor(Math.random() * 10);
  } else {
    return (Math.floor(Math.random() * 10) * -1);
  }
};
