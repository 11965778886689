import { connect } from 'react-redux';
import BrandChannelSummary from '../../components/brands/BrandChannelSummary';

const mapStateToProps = (state) => ({
  brandChannelScoresMap: state.brandChannelScoresMap,
  brandChannelScoresMapLoading: state.brandChannelScoresMapLoading,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(BrandChannelSummary);
