import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClipLoader from 'react-spinners/ClipLoader';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, LAMBDA_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class Fonts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fontsData: [],
      fontsDataLoading: false,
      googleFontsUrlMap: {},
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchGoogleFonts();
    this.fetchFontsData();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.brandId !== this.props.brandId) {
      this.fetchFontsData();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchFontsData = () => {
    if (this.props.brand) {
      this.setState(() => ({ fontsDataLoading: true }));

      axios.get(
        `${LISTEN_ENDPOINT}/api/web-image-scrape-fonts?product_brand_id=${this.props.brand.id}`,
        HEADERS
      ).then(response => {
        const fontsData = response.data;

        if (this.state.isMounted) {
          this.setState(() => ({
            fontsData,
            fontsDataLoading: false
          }), () => this.loadFontFaces());
        }
      }).catch(error => {
        console.error('Error: unable to fetch fonts data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            fontsData: [],
            fontsDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchGoogleFonts = () => {
    axios.get(
      `${LAMBDA_ENDPOINT}/google-fonts-api`,
      HEADERS
    ).then(response => {
      const fonts = response.data.items;
      const googleFontsUrlMap = {};

      for (const font of fonts) {
        if (font.files.regular) {
          googleFontsUrlMap[font.family] = font.files.regular;
        }
      }

      if (this.state.isMounted) {
        this.setState(
          () => ({ googleFontsUrlMap }),
          () => this.loadFontFaces()
        );
      }
    }).catch(error => {
      console.error('Error: failed to fetch google fonts...');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  loadFontFaces = () => {
    for (const font of this.state.fontsData) {
      if (this.state.googleFontsUrlMap[font.family_name]) {
        const fontUrl = this.state.googleFontsUrlMap[font.family_name].replace('http:', 'https:');
        const newFont = new FontFace(
          font.family_name,
          `url(${fontUrl})`
        );
        newFont.load().then(loadedFont => {
          document.fonts.add(loadedFont);
        });
      }
    }
  };

  render () {
    return (
      <div>
        { this.state.fontsDataLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.fontsDataLoading &&
          <div>
            <div className="mb-4" style={{ fontWeight: 'bold' }}>
              {`Fonts Discovered: ${this.state.fontsData.length}`}
            </div>
            <div style={{ fontWeight: 'bold' }}>
              Fonts in HTML
            </div>
            <div
              className="pre-scrollable bg-white border"
              style={{
                minHeight: '80px',
                maxHeight: '600px',
                overflow: 'auto'
              }}
            >
              <table className="w-100">
                <tbody>
                  { this.state.fontsData.map((font, i) => {
                      return (
                        <tr key={`font-${i}`} className="border-bottom">
                          <td>
                            <div className="pl-2">
                              {numberWithCommas(font.occurrence)}
                            </div>
                          </td>
                          <td>
                            <div
                              className="text-center"
                              style={{ fontFamily: font.family_name }}
                            >
                              {font.family_name}
                            </div>
                          </td>
                          <td>
                            <div
                              className="mx-2"
                              style={{
                                fontFamily: font.family_name,
                                fontSize: '2.5rem'
                              }}
                            >
                              Aa
                            </div>
                          </td>
                          <td className="pr-2">
                            <div
                              className="my-2"
                              style={{ fontFamily: font.family_name }}
                            >
                              <div>ABCDEFGHIJKLMNOPQRSTUVWXYZ</div>
                              <div>abcdefghijklmnopqrstuvwxyz</div>
                              <div>{`123456789(,.;:'"?!/$&*#@%)`}</div>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        }
      </div>
    );
  }
};
