import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactTable from 'react-table';
import { NavLink } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import CategoryBlogsAvgGraph from './CategoryBlogsAvgGraph';
import ShareOfChart from '../graphs/ShareOfChart';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import MetricTooltipContainer from '../../containers/metrics/MetricTooltipContainer';
import MetricDistributionSummaryModal from '../metrics/MetricDistributionSummaryModal';
import { numberWithCommas, round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryBlogs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shareOfGraphData: {},
      categoryShareOfBlogs: [],
      categorySummaryData: {},
      categoryShareOfBlogsLoading: false,
      metricDistributionModalOpen: false,
      selectedMetricName: undefined,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchCategoryShareOfBlogs();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevProps.categoryId !== this.props.categoryId
    ) {
      this.fetchCategoryShareOfBlogs();
    }
  };

  fetchCategoryShareOfBlogs = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryShareOfBlogsLoading: true }))
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-share-of-blogs?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const shareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const categoryShareOfBlogs = [];
        const categorySummaryData = {};
        for (const monthStats of response.data) {
          categorySummaryData[monthStats.month] = monthStats.product_brands;
          const blogsMonthData = { month: monthStats.month };
          let monthTotalBlogs = 0;

          // get totals for percent
          for (const pb of monthStats.product_brands) {
            monthTotalBlogs += pb.blog_count;
            // insert graph data
            shareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.blog_count,
            ]);
          }

          // loop again to determine %
          for (const pb of monthStats.product_brands) {
            let blogsPercent = 0;
            if (monthTotalBlogs > 0) {
              blogsPercent = round((pb.blog_count/monthTotalBlogs)*100, 1);
            }
            blogsMonthData[pb.product_brand_name] = {
              count: pb.blog_count,
              percent: blogsPercent
            }
          }
          categoryShareOfBlogs.push(blogsMonthData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            shareOfGraphData,
            categoryShareOfBlogs,
            categorySummaryData,
            categoryShareOfBlogsLoading: false,
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to load category share of blogs...`);
        this.setState(() => ({
          shareOfGraphData: {},
          categoryShareOfBlogs: [],
          categorySummaryData: {},
          categoryShareOfBlogsLoading: false,
        }));
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  selectMonth = (selectedMonth) => {
    if (selectedMonth !== this.props.selectedMonth) {
      this.props.updateSelectedMonth(selectedMonth);
    }
  };

  openMetricDistributionModal = (selectedMetricName) => {
    this.setState(() => ({
      selectedMetricName,
      metricDistributionModalOpen: true
    }));
  };

  closeMetricDistributionModal = () => {
    this.setState(() => ({
      metricDistributionModalOpen: false
    }));
  };

  render () {
    return (
      <div>
        <Row>
          <Col xs={6} md={4} lg={3} xl={2}>
            { this.props.sixMonths.map((month, i) => {
                return (
                  <button
                    id={`categoryBlogsSummary${month.label}Button`}
                    key={`bmd${i}`}
                    type="button"
                    className= {
                      (this.props.selectedMonth && this.props.selectedMonth.label === month.label) ?
                        'mb-1 btn btn-primary w-100' :
                        'mb-1 btn border bg-white w-100'
                    }
                    onClick={() => this.selectMonth(month)}
                  >
                    {month.label}
                  </button>
                )
              })
            }
          </Col>
          <Col>
            { this.state.categoryShareOfBlogsLoading &&
              <div className="m-4 text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.categoryShareOfBlogsLoading &&
              <div id="categoryBlogsSummary">
                <ReactTable
                  className="-striped -highlight"
                  data={
                    this.state.categorySummaryData[this.props.selectedMonth.label] ?
                      this.state.categorySummaryData[this.props.selectedMonth.label] :
                      []
                  }
                  columns={[
                    {
                      Header: 'Brand',
                      headerClassName: 'bg-dark text-light border-light wordwrap',
                      accessor: 'name',
                      sortable: true,
                      Cell: row => (
                        <div style={{ cursor: "pointer", textOverflow: 'ellipsis' }} >
                          <NavLink
                            key={`lmc${this.props.category.id}-${this.props.category.category_type}-lmb-${row.original.product_brand_id}`}
                            to={`/category/${encodeURIComponent(this.props.category.name)}/brand/${encodeURIComponent(row.original.product_brand_name)}#web-blog`}
                          >
                            {row.original.product_brand_name}
                          </NavLink>
                        </div>
                      )
                    },
                    {
                      Header: (
                        <MetricTooltipContainer
                          tooltipTrigger={
                            <div>
                              Blog Posts
                            </div>
                          }
                          tooltipPosition="top"
                          metricName="web_company_blog_count"
                          openMetricDistributionModal={this.openMetricDistributionModal}
                        />
                      ),
                      headerClassName: 'bg-dark text-light border-light wordwrap',
                      accessor: 'blog_count',
                      sortable: true,
                      className: 'text-center',
                      Cell: row => (
                        <div>
                          {numberWithCommas(row.original.blog_count)}
                        </div>
                      )
                    },
                    {
                      Header: (
                        <MetricTooltipContainer
                          tooltipTrigger={
                            <div>
                              Email Promo Count
                            </div>
                          }
                          tooltipPosition="top"
                          metricName="blog_post_email_promo_count"
                          openMetricDistributionModal={this.openMetricDistributionModal}
                        />
                      ),
                      headerClassName: 'bg-dark text-light border-light wordwrap',
                      accessor: 'emails_share',
                      sortable: true,
                      className: 'text-center',
                      Cell: row => (
                        <div>
                          {numberWithCommas(row.original.emails_share)}
                        </div>
                      )
                    },
                    {
                      Header: (
                        <MetricTooltipContainer
                          tooltipTrigger={
                            <div>
                              Social Promo Count
                            </div>
                          }
                          tooltipPosition="top"
                          metricName="blog_post_social_share_count"
                          openMetricDistributionModal={this.openMetricDistributionModal}
                        />
                      ),
                      headerClassName: 'bg-dark text-light border-light wordwrap',
                      accessor: 'social_share',
                      sortable: true,
                      className: 'text-center',
                      Cell: row => (
                        <div>
                          {numberWithCommas(row.original.social_share)}
                        </div>
                      )
                    }
                  ]}
                  minRows={1}
                  defaultPageSize={100}
                  showPagination={false}
                  defaultSorted={[{ id: 'blog_count', desc: true }]}
                />
                <SharePopUpContainer
                  shareElementId="categoryBlogsSummary"
                  elementLabel="category-blogs-summary-img"
                  position="bottom"
                  contextType="category"
                  contextCategory={this.props.category}
                  contextBrand={null}
                  contextChannel="Website"
                  contextProperty="Blogs"
                  contextChartName="Summary"
                  contextDateRange={`${this.props.selectedMonth.label} ${this.props.selectedMonth.date.format('YYYY')}`}
                />
              </div>
            }
          </Col>
        </Row>
        <div className="mt-5 mb-4 band-separator" />
        <CategoryBlogsAvgGraph
          user={this.props.user}
          category={this.props.category}
        />
        <div className="my-4 band-separator" />
        <ShareOfChart
          title="Share of Blog Posts"
          data={this.state.shareOfGraphData}
          legendData={this.state.categoryShareOfBlogs}
          loading={this.state.categoryShareOfBlogsLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Blogs"
          contextChartName="Share of Blog Posts"
          shareClassName="category-blogs-img"
        />

        <MetricDistributionSummaryModal
          isOpen={this.state.metricDistributionModalOpen}
          handleClose={this.closeMetricDistributionModal}
          metricName={this.state.selectedMetricName}
          audienceProfileId={this.props.category.audience_profile_id}
          customerId={this.props.user.customerId}
        />
      </div>
    );
  }
};
