import React from 'react';
import Form from 'react-bootstrap/Form';

const SelectSingleQuestion = (props) => {
  return (
    <div>
      <Form.Group>
        <Form.Label>
          <b>{props.question}</b>
        </Form.Label>
        <Form.Control
          as="select"
          style={{ width: '280px' }}
          value={props.answer}
          onChange={props.onAnswerChange}
        >
          { props.options.map((option, i) => {
              return (
                <option
                  key={`q${props.questionId}-o${i}`}
                >
                  {option.choice}
                </option>
              )
            })
          }
        </Form.Control>
      </Form.Group>
    </div>
  )
};

export default SelectSingleQuestion;
