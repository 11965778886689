import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import ClipLoader from 'react-spinners/ClipLoader';
import DateRangePicker from '../common/DateRangePicker';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '../../utils/numbers';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class LinkedInPosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      linkedInAccountDetails: {},
      linkedInPosts: [],
      linkedInPostResults: [],
      linkedInPostsLoading: false,
      cppCounts: {},
      sortType: 'date'
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchLinkedInAccountDetails();
    this.fetchLinkedInPosts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.linkedInAccount !== this.props.linkedInAccount) {
      this.fetchLinkedInAccountDetails();
    }
    if (
      prevProps.brand !== this.props.brand ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter
    ) {
      this.fetchLinkedInPosts();
    }
    if (
      prevProps.companyChecked !== this.props.companyChecked ||
      prevProps.portfolioChecked !== this.props.portfolioChecked ||
      prevProps.productChecked !== this.props.productChecked
    ) {
      this.updateLinkedInPostResults();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  fetchLinkedInAccountDetails = () => {
    if (this.props.linkedInAccount && this.props.linkedInAccount.id) {
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/linkedin-descriptions?company_linkedin_account_id=${this.props.linkedInAccount.id}`,
        HEADERS
      ).then(response => {
        let linkedInAccountDetails = {};
        if (Array.isArray(response.data) && response.data.length > 0) {
          linkedInAccountDetails = response.data[0];
        }

        if (this.state.isMounted) {
          this.setState(() => ({ linkedInAccountDetails }));
        }
      }).catch(error => {
        console.error('Error: unable to load linkedin account details');
        if (this.state.isMounted) {
          this.setState(() => ({ linkedInAccountDetails: {} }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchLinkedInPosts = () => {
    if (this.props.brand) {
      this.setState(() => ({ linkedInPostsLoading: true }));
      const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/linkedin-posts?company_id=${this.props.brand.company_id}&product_brand_id=${this.props.brand.id}&start-date=${formattedStartDate}&end-date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        const linkedInPosts = response.data;
        const cppCounts = {
          companyCount: 0,
          portfolioCount: 0,
          productCount: 0,
        };
        for (const p of linkedInPosts) {
          if (Array.isArray(p.brand_types)) {
            if (p.brand_types.includes('company')) {
              cppCounts.companyCount++;
            }
            if (p.brand_types.includes('portfolio')) {
              cppCounts.portfolioCount++;
            }
            if (p.brand_types.includes('product')) {
              cppCounts.productCount++;
            }
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            linkedInPosts,
            linkedInPostsLoading: false,
            cppCounts,
          }), () => this.onSortTypeChange(this.state.sortType));
        }
      }).catch(error => {
        console.error('Error: unable to load company linkedin posts...');
        if (this.state.isMounted) {
          this.setState(() => ({
            linkedInPosts: [],
            linkedInPostsLoading: false,
            cppCounts: {},
          }), () => this.onSortTypeChange(this.state.sortType));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  updateLinkedInPostResults = () => {
    let linkedInPostResults = [];
    for (const p of this.state.linkedInPosts) {
      if (
        Array.isArray(p.brand_types) &&
        (
          (this.props.companyChecked && p.brand_types.includes('company')) ||
          (this.props.productChecked && p.brand_types.includes('product')) ||
          (this.props.portfolioChecked && p.brand_types.includes('portfolio'))
        )
      ) {
        linkedInPostResults.push(p);
      }
    }

    if (this.state.isMounted) {
      this.setState(() => ({ linkedInPostResults }));
    }
  };

  onSortTypeChange = (sortType) => {
    if (this.props.sortType !== sortType) {
      if (sortType === 'date') {
        const dateSortedPosts = this.state.linkedInPosts.sort((a, b) => {
          return moment(b.post_published_date).format('YYYYMMDDHHmmss') - moment(a.post_published_date).format('YYYYMMDDHHmmss')
        });
        this.setState(() => ({
          sortType: 'date',
          linkedInPosts: dateSortedPosts
        }), () => this.updateLinkedInPostResults());
      } else if (sortType === 'reactions') {
        const reactionSortedPosts = this.state.linkedInPosts.sort((a, b) => {
          if (a.like_count < b.like_count) {
            return 1;
          } else if (a.like_count > b.like_count) {
            return -1;
          } else {
            return 0;
          }
        });
        this.setState(() => ({
          sortType: 'reactions',
          linkedInPosts: reactionSortedPosts
        }), () => this.updateLinkedInPostResults());
      }
    }
  };

  render () {
    return (
      <div>
        { this.state.linkedInPostsLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.linkedInPostsLoading &&
          <div>
            <h5 className="my-2 p-2 bg-bops-blue text-light">
              Details
              <span
                className="ml-4 mr-2"
                style={{ fontSize: '1rem', fontWeight: 'normal' }}
              >Date Range</span>
              <DateRangePicker
                startDate={this.props.startDateFilter}
                endDate={this.props.endDateFilter}
                updateDates={this.props.onFilterDatesChange}
              />
              <div
                className="d-inline-block ml-4"
                style={{ fontSize: '1rem', fontWeight: 'normal' }}
              >
                <Form.Check
                  className="ml-4"
                  type="checkbox"
                  label={`Corporate ${this.state.cppCounts.companyCount != null ? `(${numberWithCommas(this.state.cppCounts.companyCount)})` : ''}`}
                  checked={this.props.companyChecked}
                  onChange={(event) => this.props.onCppCheckChange('company', event.currentTarget.checked)}
                  inline
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label={`Portfolio ${this.state.cppCounts.portfolioCount != null ? `(${numberWithCommas(this.state.cppCounts.portfolioCount)})` : ''}`}
                  checked={this.props.portfolioChecked}
                  onChange={(event) => this.props.onCppCheckChange('portfolio', event.currentTarget.checked)}
                  inline
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label={`Product Brand ${this.state.cppCounts.productCount != null ? `(${numberWithCommas(this.state.cppCounts.productCount)})` : ''}`}
                  checked={this.props.productChecked}
                  onChange={(event) => this.props.onCppCheckChange('product', event.currentTarget.checked)}
                  inline
                />
              </div>
            </h5>
            { this.state.linkedInPostResults.length === 0 &&
              <div>
                No posts are available.
              </div>
            }
            { this.state.linkedInPostResults.length > 0 &&
              <div>
                <ButtonGroup className="rounded border">
                  <Button
                    variant={`${this.state.sortType === 'date' ? 'info': 'light'}`}
                    size="sm"
                    data-sort-type="date"
                    onClick={() => this.onSortTypeChange('date')}
                  >Date</Button>
                  <Button
                    variant={`${this.state.sortType === 'reactions' ? 'info': 'light'}`}
                    size="sm"
                    onClick={() => this.onSortTypeChange('reactions')}
                  >Reactions</Button>
                </ButtonGroup>
                <Row>
                  <Col xs={12} lg={6}>
                    { this.state.linkedInPostResults.map((post, i) => {
                        return (
                          <div
                            id={`li-post-${i}`}
                            key={`lip${post.id}`}
                            className="my-2 p-4 bg-white border rounded"
                          >
                            <div>
                              <div className="d-inline-block mr-3 mt-2 align-top" style={{ width: '30px' }}>
                                <img
                                  src={this.props.brand.company_favicon_url}
                                  width="100%"
                                  alt=""
                                  crossOrigin="anonymous"
                                />
                              </div>
                              <div className="d-inline-block">
                                <div className="font-weight-bold">
                                  {this.state.linkedInAccountDetails.company_name || this.props.brand.name}
                                </div>
                                { this.props.linkedInAccount &&
                                  <div className="text-muted"  style={{ fontSize: '.75rem' }}>
                                    {`${numberWithCommas(this.props.linkedInAccount.follower_count)} followers`}
                                  </div>
                                }
                                <div className="text-muted"  style={{ fontSize: '.75rem' }}>
                                  {moment(post.post_published_date).format('MMM D')}
                                </div>
                              </div>
                              <div className="d-inline-block float-right">
                                <SharePopUpContainer
                                  shareElementId={`li-post-${i}`}
                                  elementLabel={`li-post-${i}-img`}
                                  position="top"
                                  contextType="brand"
                                  contextCategory={this.props.category}
                                  contextBrand={this.props.brand}
                                  contextChannel="Social"
                                  contextProperty="LinkedIn"
                                  contextChartName="Post"
                                />
                              </div>
                              <hr className="mt-1 mb-2" />
                            </div>
                            <div>
                              {post.post_content}
                            </div>
                            <div>
                              { post.s3_post_image_url &&
                                <img
                                  src={post.s3_post_image_url}
                                  width="100%"
                                  alt=""
                                  crossOrigin="anonymous"
                                  onClick={() => window.open(post.post_url, '_blank')}
                                  style={{ cursor: 'pointer' }}
                                />
                              }
                              { !post.s3_post_image_url &&
                                <div
                                  className="h-100 text-center"
                                  onClick={() => window.open(post.post_url, '_blank')}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <FontAwesomeIcon
                                    icon={faImage}
                                    color="#d9d9d9"
                                    size="6x"
                                  />
                                </div>
                              }
                            </div>
                            <div>
                              {`${numberWithCommas(post.like_count)} reactions`}
                              <span className="ml-3">
                                {`${numberWithCommas(post.comment_count)} comments`}
                              </span>
                            </div>
                          </div>
                        )
                      })
                    }
                  </Col>
                </Row>
              </div>
            }
          </div>
        }
      </div>
    );
  }
};
