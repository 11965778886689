import React from 'react';
import ReactTable from 'react-table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import history from '../../routers/history';

const CategoryKingsTable = (props) => {
  return (
    <div id="categoryKing">
      <div>
        <ReactTable
          className="-highlight scrollable"
          data={props.categoryBrandScores}
          columns={[
            {
              Header: 'Rank',
              headerClassName: 'py-3 text-center category-king-header wordwrap border-light',
              accessor: 'index',
              sortable: true,
              width: 110,
              Cell: row => (
                <div
                  className={`text-center${props.companyId === row.original.company_id ? ' text-bops-blue': ''}`}
                  style={{ fontSize: '1rem', fontWeight: 'bold' }}
                >
                  {row.original.index}
                </div>
              )
            }, {
              Header: 'Brand',
              headerClassName: 'py-3 pl-3 text-left category-king-header wordwrap border-light',
              accessor: 'name',
              sortable: true,
              Cell: row => (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push(`/category/${encodeURIComponent(props.category.name)}/brand/${encodeURIComponent(row.original.name)}#analysis-pillars`)}
                >
                  <span className={`pl-2 align-middle${props.companyId === row.original.company_id ? ' text-bops-blue': ''}`}>
                    {row.original.name}
                  </span>
                </div>
              )
            }, {
              Header: (
                <OverlayTrigger
                  trigger="hover"
                  placement="top"
                  overlay={
                    <Popover>
                      <Popover.Content>
                        The Brand Score indicates goal attainment across the four brand pillars.
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <div>
                    Score
                  </div>
                </OverlayTrigger>
              ),
              headerClassName: 'py-3 text-center category-king-header wordwrap border-light',
              accessor: 'brand_score',
              sortable: true,
              defaultSortDesc: true,
              Cell: row => (
                <div
                  className="d-inline-block text-center text-light align-middle w-100"
                  onClick={() => history.push(`/category/${encodeURIComponent(props.category.name)}/brand/${encodeURIComponent(row.original.name)}#analysis-pillars-brandScore`)}
                  style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    background: `linear-gradient(90deg, #ffc107 ${row.original.brand_score}%, #6c757d 0%)`,
                    cursor: 'pointer'
                  }}
                >
                  <strong>{Math.round(row.original.brand_score)}</strong>
                </div>
              )
            }, {
              Header: '',
              headerClassName: 'py-3 bg-light',
              accessor: 'empty',
              sortable: false,
              width: 40,
              Cell: row => (
                <div>
                  {/* EMPTY COLUMN */}
                </div>
              )
            }, {
              Header: (
                <OverlayTrigger
                  trigger="hover"
                  placement="top"
                  overlay={
                    <Popover>
                      <Popover.Content>
                        Presence indicates goal attainment for consistent brand activity across channels.
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <div>
                    <div>Digital</div>
                    <div>Presence</div>
                  </div>
                </OverlayTrigger>
              ),
              headerClassName: 'py-3 text-center category-king-header wordwrap border-light',
              accessor: 'digital_presence_score',
              sortable: true,
              defaultSortDesc: true,
              Cell: row => (
                <div
                  className="d-inline-block text-center text-light align-middle w-100"
                  onClick={() => history.push(`/category/${encodeURIComponent(props.category.name)}/brand/${encodeURIComponent(row.original.name)}#analysis-pillars-brandPresence`)}
                  style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    background: `linear-gradient(90deg, #24a0ed ${row.original.digital_presence_score}%, #6c757d 0%)`,
                    cursor: 'pointer'
                  }}
                >
                  {Math.round(row.original.digital_presence_score)}
                </div>
              )
            }, {
              Header: (
                <OverlayTrigger
                  trigger="hover"
                  placement="top"
                  overlay={
                    <Popover>
                      <Popover.Content>
                        Attention indicates goal attainment for followers, engagement, mentions, visits, reviews, video views, and similar behaviors.
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <div>
                    <div>Audience</div>
                    <div>Attention</div>
                  </div>
                </OverlayTrigger>
              ),
              headerClassName: 'py-3 text-center category-king-header wordwrap border-light',
              accessor: 'audience_attention_score',
              sortable: true,
              defaultSortDesc: true,
              Cell: row => (
                <div
                  className="d-inline-block text-center text-light align-middle w-100"
                  onClick={() => history.push(`/category/${encodeURIComponent(props.category.name)}/brand/${encodeURIComponent(row.original.name)}#analysis-pillars-brandAttention`)}
                  style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    background: `linear-gradient(90deg, #24a0ed ${row.original.audience_attention_score}%, #6c757d 0%)`,
                    cursor: 'pointer'
                  }}
                >
                  {Math.round(row.original.audience_attention_score)}
                </div>
              )
            }, {
              Header: (
                <OverlayTrigger
                  trigger="hover"
                  placement="top"
                  overlay={
                    <Popover>
                      <Popover.Content>
                        Reputation indicates goal attainment for quantity and quality of product and employer reviews as well as social sentiment.
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <div>
                    <div>Brand</div>
                    <div>Reputation</div>
                  </div>
                </OverlayTrigger>
              ),
              headerClassName: 'py-3 text-center category-king-header wordwrap border-light',
              accessor: 'brand_reputation_score',
              sortable: true,
              defaultSortDesc: true,
              Cell: row => (
                <div
                  className="d-inline-block text-center text-light align-middle w-100"
                  onClick={() => history.push(`/category/${encodeURIComponent(props.category.name)}/brand/${encodeURIComponent(row.original.name)}#analysis-pillars-brandReputation`)}
                  style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    background: `linear-gradient(90deg, #24a0ed ${row.original.brand_reputation_score}%, #6c757d 0%)`,
                    cursor: 'pointer'
                  }}
                >
                  {Math.round(row.original.brand_reputation_score)}
                </div>
              )
            }, {
              Header: (
                <OverlayTrigger
                  trigger="hover"
                  placement="top"
                  overlay={
                    <Popover>
                      <Popover.Content>
                        Momentum increases when key audience and attention metrics improve month over month.
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <div>
                    Momentum
                  </div>
                </OverlayTrigger>
              ),
              headerClassName: 'py-3 text-center category-king-header wordwrap border-light',
              accessor: 'momentum_score',
              sortable: true,
              defaultSortDesc: true,
              Cell: row => (
                <div
                  className="d-inline-block text-center text-light align-middle w-100"
                  onClick={() => history.push(`/category/${encodeURIComponent(props.category.name)}/brand/${encodeURIComponent(row.original.name)}#analysis-pillars-brandMomentum`)}
                  style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    background: `linear-gradient(90deg, #24a0ed ${row.original.momentum_score}%, #6c757d 0%)`,
                    cursor: 'pointer'
                  }}
                >
                  {Math.round(row.original.momentum_score)}
                </div>
              )
            }
          ]}
          getTrProps={(state, rowInfo) => {
              let style = {};
              if (rowInfo && rowInfo.row) {
                if (props.companyId === rowInfo.row._original.company_id) {
                  style.backgroundColor = '#0000000d';
                }
                return { style }
              } else {
                return { style }
              }
            }
          }
          minRows={1}
          showPagination={false}
          defaultSorted={[{ id: 'index', asc: true }]}
          style={{
            maxHeight: 'calc(100vh - 280px)'
          }}
        />
      </div>
    </div>
  );
};

export default CategoryKingsTable;
