import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryImagery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brandDominantColors: [],
      brandDominantColorsLoading: false,
      brandDominantColorsMap: {},
      brandLogosMap: {},
      removeNearWhiteChecked: true,
      removeNearBlackChecked: true,
      startDate: moment().subtract(30, 'days').startOf('day'),
      endDate: moment().endOf('day')
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchCategoryDominantColors();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      this.fetchCategoryDominantColors();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchCategoryDominantColors = () => {
    if (this.props.category) {
      this.setState(() => ({ brandDominantColorsLoading: true }));
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      const dominantColorRequests = [];

      for (const brand of this.props.category.product_brands) {
        dominantColorRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/media-visual-properties?product_brand_id=${brand.id}&company_id=${brand.company_id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
            HEADERS
          ).then(response => {
            const visualsData = response.data;
            let dominantColorCombinationsMap = {};
            for (const visual of visualsData) {
              // update dominant color combinations map
              if (visual.visual_properties && visual.visual_properties.length > 1) {
                const key = `${visual.visual_properties[0].color_hex}-${visual.visual_properties[1].color_hex}`;
                if (dominantColorCombinationsMap[key]) {
                  dominantColorCombinationsMap[key].count += 1;
                } else {
                  dominantColorCombinationsMap[key] = {
                    count: 1,
                    key: `${visual.visual_properties[0].color_hex}-${visual.visual_properties[1].color_hex}`,
                    combo_one: visual.visual_properties[0],
                    combo_two: visual.visual_properties[1]
                  };
                }
              }
            }
            // update dominant colors by combination into array and sort by count
            let dominantColorCombinations = Object.values(dominantColorCombinationsMap).sort((a, b) => b.count - a.count);

            return {
              brand,
              dominantColorCombinations
            };
          }).catch(error => {
            console.error(`Error: unable to load ${brand.name} visuals data`);
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return {
              brand,
              dominantColorCombinations: []
            };
          })
        )
      }

      Promise.all(dominantColorRequests).then(responses => {
        const brandDominantColors = [];
        for (const response of responses) {
          brandDominantColors.push(response);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            brandDominantColors,
            brandDominantColorsLoading: false
          }), () => this.updateDominantColorsMap());
        }
      });
    }
  };

  updateDominantColorsMap = () => {
    const brandDominantColorsMap = {}
    for (const bdc of this.state.brandDominantColors) {
      let dominantColor;
      if (this.state.removeNearWhiteChecked && this.state.removeNearBlackChecked) {
        for (const dc of bdc.dominantColorCombinations) {
          if (
            !this.isColorNearWhite(dc.combo_one) &&
            !this.isColorNearWhite(dc.combo_two) &&
            !this.isColorNearBlack(dc.combo_one) &&
            !this.isColorNearBlack(dc.combo_two)
          ) {
            dominantColor = dc;
            break
          }
        }
      } else if (this.state.removeNearWhiteChecked) {
        for (const dc of bdc.dominantColorCombinations) {
          if (
            !this.isColorNearWhite(dc.combo_one) &&
            !this.isColorNearWhite(dc.combo_two)
          ) {
            dominantColor = dc;
            break
          }
        }
      } else if (this.state.removeNearBlackChecked) {
        for (const dc of bdc.dominantColorCombinations) {
          if (
            !this.isColorNearBlack(dc.combo_one) &&
            !this.isColorNearBlack(dc.combo_two)
          ) {
            dominantColor = dc;
            break
          }
        }
      } else {
        dominantColor = bdc.dominantColorCombinations[0];
      }
      brandDominantColorsMap[bdc.brand.id] = dominantColor;
    }

    this.setState(() => ({ brandDominantColorsMap }));
  };

  isColorNearWhite = (color) => {
    if (
      color &&
      color.color_red != null &&
      color.color_green != null &&
      color.color_blue != null
    ) {
      const luma = 0.2126 * color.color_red + 0.7152 * color.color_green + 0.0722 * color.color_blue;
      if (luma > 226) {
        return true;
      } else {
        return false;
      }
    }
  };

  isColorNearBlack = (color) => {
    if (
      color &&
      color.color_red != null &&
      color.color_green != null &&
      color.color_blue != null
    ) {
      const luma = 0.2126 * color.color_red + 0.7152 * color.color_green + 0.0722 * color.color_blue;
      if (luma < 61) {
        return true;
      } else {
        return false;
      }
    }
  };

  onCheckboxChange = (event, checkbox) => {
    const checked = event.currentTarget.checked;
    if (checked !== this.state[checkbox]) {
      this.setState(() => ({
        [checkbox]: checked
      }), () => this.updateDominantColorsMap());
    }
  };

  render () {
    return (
      <div>
        <h4>
          Category Colors
          { !this.state.brandDominantColorsLoading &&
            <div
              className="d-inline-block"
              style={{
                fontSize: '1rem',
                fontWeight: 'normal'
              }}
            >
              <Form.Check
                className="ml-4"
                type="checkbox"
                label="Remove Near White"
                checked={this.state.removeNearWhiteChecked}
                onChange={(event) => this.onCheckboxChange(event, 'removeNearWhiteChecked')}
                inline
              />
              <Form.Check
                className="ml-2"
                type="checkbox"
                label="Remove Near Black"
                checked={this.state.removeNearBlackChecked}
                onChange={(event) => this.onCheckboxChange(event, 'removeNearBlackChecked')}
                inline
              />
            </div>
          }
        </h4>
        <div
          className="pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 250px)',
            maxHeight: 'calc(100vh - 250px)',
            overflow: 'auto',
            overflowX: 'hidden'
          }}
        >
          { this.state.brandDominantColorsLoading &&
            <div className="m-4 text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !this.state.brandDominantColorsLoading &&
            <div>
              <Row>
                { this.state.brandDominantColors.map(bdc => {
                    return (
                      <Col key={`b-${bdc.brand.id}-dc`} xs={12} lg={6} className="mb-4">
                        <Card id={`categoryImageryBrand${bdc.brand.id}`} className="h-100">
                          <Card.Header className="bg-white" style={{ fontWeight: 'bold' }}>
                            {bdc.brand.name}
                            <div className="d-inline-block float-right">
                              <SharePopUpContainer
                                shareElementId={`categoryImageryBrand${bdc.brand.id}`}
                                elementLabel="category-imagery-brand-img"
                                position="top"
                                contextType="category"
                                contextCategory={this.props.category}
                                contextBrand={null}
                                contextChannel="Identity"
                                contextProperty="Imagery"
                                contextChartName="Brand Colors"
                              />
                            </div>
                          </Card.Header>
                          <Card.Body>
                            <div className="text-center">
                              { this.props.user.customerId === bdc.brand.company_id &&
                                <div>
                                  { !bdc.brand.company_logo_url &&
                                    <div style={{ height: '50px' }}>
                                      <FontAwesomeIcon
                                        icon={faImage}
                                        color="#d9d9d9"
                                        style={{ fontSize: '3rem' }}
                                      />
                                    </div>
                                  }
                                  { bdc.brand.company_logo_url &&
                                    <img
                                      src={bdc.brand.company_logo_url}
                                      alt=""
                                      crossOrigin="anonymous"
                                      style={{ maxHeight: '50px' }}
                                    />
                                  }
                                </div>
                              }
                              { this.props.user.customerId !== bdc.brand.company_id &&
                                <div>
                                  { !bdc.brand.company_favicon_url &&
                                    <div style={{ height: '50px' }}>
                                      <FontAwesomeIcon
                                        icon={faImage}
                                        color="#d9d9d9"
                                        style={{ fontSize: '3rem' }}
                                      />
                                    </div>
                                  }
                                  { bdc.brand.company_favicon_url &&
                                    <img
                                      src={bdc.brand.company_favicon_url}
                                      alt=""
                                      crossOrigin="anonymous"
                                      style={{ maxHeight: '50px' }}
                                    />
                                  }
                                </div>
                              }
                            </div>
                            <div className="mt-2 text-center">
                              Predominantly Used
                            </div>
                            { this.state.brandDominantColorsMap[bdc.brand.id] &&
                              <Row noGutters>
                                <Col>
                                  { this.state.brandDominantColorsMap[bdc.brand.id].combo_one &&
                                    <div
                                      className="mr-2"
                                      style={{
                                        height: '30px',
                                        backgroundColor: this.state.brandDominantColorsMap[bdc.brand.id].combo_one.color_hex
                                      }}
                                    />
                                  }
                                </Col>
                                <Col>
                                  { this.state.brandDominantColorsMap[bdc.brand.id].combo_two &&
                                    <div
                                      style={{
                                        height: '30px',
                                        backgroundColor: this.state.brandDominantColorsMap[bdc.brand.id].combo_two.color_hex
                                      }}
                                    />
                                  }
                                </Col>
                              </Row>
                            }
                            { !this.state.brandDominantColorsMap[bdc.brand.id] &&
                              <div style={{ height: '30px' }}></div>
                            }
                          </Card.Body>
                        </Card>
                      </Col>
                    )
                  })
                }
              </Row>
            </div>
          }
        </div>
      </div>
    );
  }
};
