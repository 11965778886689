import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import {
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';
import CardComponent from '../../common/CardComponent';
import { numberWithCommas } from '../../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class CategoryWebinarSummaryGraphs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryWebinarSummaryData: [],
      categoryWebinarSummaryDataLoading: false,
      categoryKingName: '',
      ownersBrandName: '',
      highestWebinarKey: '',
      highestWebinarCount: 0
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getCategoryWebinarSummaryData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.getCategoryWebinarSummaryData();
    }
  }

  getCategoryWebinarSummaryData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryWebinarSummaryDataLoading: true }));
      const categoryWebinarSummaryRequests = [];
      // get category average webinar stats
      categoryWebinarSummaryRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-webinars?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=category-average`,
          HEADERS
        ).then(response => {
          const categoryAvgWebinarData = response.data;
          return { categoryAvgWebinarData };
        }).catch(error => {
          console.error('Error: unable to fetch category average webinar trend');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryAvgWebinarData: [] };
        })
      );
      // get category king webinar stats
      categoryWebinarSummaryRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-webinars?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=category-king`,
          HEADERS
        ).then(response => {
          const results = response.data;
          const categoryKingWebinarData = [];
          if (results.length > 0 && results[0].web_stats) {
            for (const webinarData of results[0].web_stats) {
              categoryKingWebinarData.push({
                ...webinarData,
                product_brand_name: results[0].product_brand_name
              });
            }
          }
          return { categoryKingWebinarData };
        }).catch(error => {
          console.error('Error: unable to fetch category king webinar trend');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryKingWebinarData: [] };
        })
      );
      // get customer webinar stats
      let ownersBrand;
      for (const brand of this.props.category.product_brands) {
        if (!ownersBrand && brand.company_id === this.props.user.customerId) {
          ownersBrand = brand;
        }
      }
      if (ownersBrand) {
        categoryWebinarSummaryRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/category-avg-webinars?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=product-brand&product_brand_id=${ownersBrand.id}`,
            HEADERS
          ).then(response => {
            const results = response.data;
            const ownedBrandWebinarData = [];
            if (results.length > 0 && results[0].web_stats) {
              for (const webinarData of results[0].web_stats) {
                ownedBrandWebinarData.push(webinarData);
              }
            }
            return {
              ownedBrandWebinarData,
              ownersBrand
            };
          }).catch(error => {
            console.error('Error: unable to fetch owned brand webinar trend');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return { ownedBrandWebinarData: [] };
          })
        );
      }

      Promise.all(categoryWebinarSummaryRequests).then(responses => {
        let categoryAvgWebinarData = [];
        let categoryKingWebinarData = [];
        let ownedBrandWebinarData = [];
        let highestWebinarCount = 0;
        let highestWebinarKey = 'category_king_webinars_count';
        let categoryKingName = '';
        let ownersBrandName = '';
        for (let response of responses) {
          if (response.categoryAvgWebinarData) {
            categoryAvgWebinarData = response.categoryAvgWebinarData;
          } else if (response.categoryKingWebinarData) {
            categoryKingWebinarData = response.categoryKingWebinarData;
            if (categoryKingWebinarData.length > 0) {
              categoryKingName = categoryKingWebinarData[0].product_brand_name;
            }
          } else if (response.ownedBrandWebinarData) {
            ownedBrandWebinarData = response.ownedBrandWebinarData;
            ownersBrandName = response.ownersBrand.name;
          }
        }
        // merge the 3 data arrays
        // may be able to optimize this, but its a small data set
        const categoryWebinarSummaryData = [];
        for (let cd of categoryAvgWebinarData) {
          if (cd.avg_webinars > highestWebinarCount) {
            highestWebinarCount = cd.avg_webinars;
            highestWebinarKey = 'category_avg_webinars_count';
          }
          categoryWebinarSummaryData.push({
            month: cd.month,
            category_avg_webinars_count: cd.avg_webinars
          });
        }
        for (let ckd of categoryKingWebinarData) {
          for (let scoreData of categoryWebinarSummaryData) {
            if (ckd.total_webinars > highestWebinarCount) {
              highestWebinarCount = ckd.total_webinars;
              highestWebinarKey = 'category_king_total_webinars_count';
            }
            if (ckd.month === scoreData.month) {
              scoreData.category_king_webinars_count = ckd.total_webinars;
            }
          }
        }
        for (let obd of ownedBrandWebinarData) {
          for (let scoreData of categoryWebinarSummaryData) {
            if (obd.total_webinars > highestWebinarCount) {
              highestWebinarCount = obd.total_webinars;
              highestWebinarKey = 'owned_brand_webinars_count';
            }
            if (obd.month === scoreData.month) {
              scoreData.owned_brand_webinars_count = obd.total_webinars;
            }
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryWebinarSummaryData,
            categoryWebinarSummaryDataLoading: false,
            categoryKingName,
            ownersBrandName,
            highestWebinarKey,
            highestWebinarCount
          }));
        }
      });
    }
  }

  render () {
    return (
      <CardComponent
        title="Webinars Discovered"
        icon={
          <FontAwesomeIcon
            className="mr-2"
            icon={faGlobe}
            color="#4fa2f3"
          />
        }
        body={
          <div>
            { this.state.categoryWebinarSummaryDataLoading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.categoryWebinarSummaryDataLoading &&
              <ResponsiveContainer width="99%" height={200}>
                <ComposedChart
                  data={this.state.categoryWebinarSummaryData}
                  margin={{
                    left: 10
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month"/>
                  <YAxis
                    dataKey={this.state.highestWebinarKey}
                    domain={[0, Math.ceil(this.state.highestWebinarCount)]}
                    tick={{ fontSize: 12 }}
                    tickFormatter={(value) => {
                      return numberWithCommas(value);
                    }}
                  />
                  <Tooltip
                    formatter={(value) => numberWithCommas(value)}
                  />

                  <Area
                    name={
                      this.state.categoryKingName ?
                        `Leader, ${this.state.categoryKingName} Webinars` :
                        'Category King Webinars'
                    }
                    type="monotone"
                    dataKey="category_king_webinars_count"
                    stroke="#007bff"
                    fillOpacity={.4}
                  />
                  <Line
                    name="Category Average Webinars"
                    type="monotone"
                    dataKey="category_avg_webinars_count"
                    strokeDasharray="10 10"
                    stroke="#009900"
                    strokeWidth={3}
                  />
                  <Line
                    name={
                      this.state.ownersBrandName ?
                        `${this.state.ownersBrandName} Webinars` :
                        'Your Brand Webinars'
                    }
                    type="monotone"
                    dataKey="owned_brand_webinars_count"
                    stroke="#ffc107"
                    strokeWidth={3}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            }
          </div>
        }
        contextType="category"
        contextCategory={this.props.category}
        contextBrand={null}
        contextChannel="Webinars"
        contextChartName="Webinars Discovered"
      />
    );
  }
};
