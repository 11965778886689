import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../common/CardComponent';
import ShareOfChart from '../graphs/ShareOfChart';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { round, numberWithCommas } from '../../utils/numbers';
import { colors } from '../../utils/graphs';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryBrandPower extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryBrandPowerData: [],
      brandPowerShareOfGraphData: {},
      categoryBrandPowerShareOfData: [],
      categoryBrandPowerDataShareOfLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchCategoryBrandPowerData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      this.fetchCategoryBrandPowerData();
    }
  };

  fetchCategoryBrandPowerData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryBrandPowerDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-share-of-brand-power?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const categoryBrandPowerData = response.data;
        const brandPowerShareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };

        // if last month missing data, remove it
        if (
          categoryBrandPowerData[categoryBrandPowerData.length-1] &&
          categoryBrandPowerData[categoryBrandPowerData.length-1].product_brands
        ) {
          let hasData = false;
          for (const pb of categoryBrandPowerData[categoryBrandPowerData.length-1].product_brands) {
            if (pb.brand_power_with_cpp != null) {
              hasData = true;
            }
          }
          if (!hasData) {
            categoryBrandPowerData.pop();
          }
        }

        const categoryBrandPowerShareOfData = [];
        for (const monthStats of categoryBrandPowerData) {
          const shareOfMonthData = { month: monthStats.month };
          let monthTotalBrandPower = 0;

          // get totals for percent
          for (const pb of monthStats.product_brands) {
            monthTotalBrandPower += pb.brand_power_with_cpp;
            // flatten into month stats for line graph
            monthStats[pb.product_brand_name] = pb.brand_power_with_cpp;
            // insert graph data
            brandPowerShareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.brand_power_with_cpp || 0,
            ]);
          }

          // loop again to determine %
          for (const pb of monthStats.product_brands) {
            let brandPowerPercent = 0;
            if (monthTotalBrandPower > 0) {
              brandPowerPercent = round((pb.brand_power_with_cpp/monthTotalBrandPower)*100, 1);
            }
            shareOfMonthData[pb.product_brand_name] = {
              count: pb.brand_power_with_cpp,
              percent: brandPowerPercent
            }
          }
          categoryBrandPowerShareOfData.push(shareOfMonthData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryBrandPowerData,
            brandPowerShareOfGraphData,
            categoryBrandPowerShareOfData,
            categoryBrandPowerDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch category share of brand power data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            categoryBrandPowerData: [],
            brandPowerShareOfGraphData: {},
            categoryBrandPowerShareOfData: [],
            categoryBrandPowerDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="m-4">
        <div>
          <CardComponent
            title="Brand Power"
            body={
              <div>
                { this.state.categoryBrandPowerDataLoading &&
                  <div className="m-4 text-center">
                    <ClipLoader size={100}/>
                  </div>
                }
                { !this.state.categoryBrandPowerDataLoading &&
                  <div>
                    <div className="text-secondary" style={{ fontSize: '14px' }}>
                      <Row>
                        <Col xs={12} md={3} lg={2}>
                          <div className="ml-4">
                            { this.props.category.product_brands.map((brand, i) => {
                                return (
                                  <Row
                                    key={`crs-l-b-${brand.id}`}
                                    style={{
                                      flexWrap: 'nowrap',
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                     }}
                                    noGutters
                                  >
                                    <Col style={{ flex: '0 0 16px' }}>
                                      <div
                                        className="mt-1"
                                        style={{
                                          width: '16px',
                                          height: '16px',
                                          borderRadius: '50%',
                                          backgroundColor: colors[i % colors.length]
                                        }}
                                      />
                                    </Col>
                                    <Col style={{ flex: '0 0 calc(100% - 16px)' }}>
                                      <div className="ml-2">
                                        {brand.name}
                                      </div>
                                    </Col>
                                  </Row>
                                )
                              })
                            }
                          </div>
                        </Col>
                        <Col xs={12} md={9} lg={10}>
                          <ResponsiveContainer height={300} width="99%">
                            <LineChart data={this.state.categoryBrandPowerData}>
                              <XAxis
                                dataKey="month"
                              />
                              <YAxis
                                tickFormatter={(value) => {
                                  return numberWithCommas(value)
                                }}
                              />
                              <CartesianGrid strokeDasharray="5 5"/>
                              <Tooltip
                                formatter={(value) => numberWithCommas(value)}
                              />
                              { this.props.category.product_brands.map((brand, i) => {
                                  return (
                                    <Line
                                      key={`crs-l-${brand.id}`}
                                      name={brand.name}
                                      type="monotone"
                                      dataKey={brand.name}
                                      stroke={colors[i % colors.length]}/>
                                  )
                                })
                              }
                            </LineChart>
                          </ResponsiveContainer>
                        </Col>
                      </Row>
                    </div>
                  </div>
                }
              </div>
            }
            contextType="category"
            contextCategory={this.props.category}
            contextBrand={null}
            contextChannel="Analysis"
            contextChartName="Brand Power"
          />
        </div>
        <div>
          <ShareOfChart
            title="Share of Brand Power"
            data={this.state.brandPowerShareOfGraphData}
            legendData={this.state.categoryBrandPowerShareOfData}
            loading={this.state.categoryBrandPowerDataLoading}
            contextType="category"
            contextCategory={this.props.category}
            contextBrand={null}
            contextChannel="Analysis"
            contextChartName="Share of Brand Power"
            shareClassName="category-share-of-brand-power-img"
          />
        </div>
      </div>
    );
  }
};
