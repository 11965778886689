import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ProgressBar from 'react-bootstrap/ProgressBar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import ClipLoader from 'react-spinners/ClipLoader';
import LoginContainer from '../../containers/common/LoginContainer';
import QuarterScrollPicker from '../common/QuarterScrollPicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCrosshairs, faExclamationCircle, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { sortAlphabeticalByKey } from '../../utils/sorts';
import { numberWithCommas, round } from '../../utils/numbers';
import { getMetricIcon } from '../../utils/metrics';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';
import history from '../../routers/history';

export default class Objectives extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      category: undefined,
      brand: undefined,
      selectedQuarter: moment(),
      currentQuarterStartDate: moment().startOf('quarter'),
      currentQuarterPercentThrough: ((moment() - moment().startOf('quarter')) / (moment().endOf('quarter') - moment().startOf('quarter')) * 100),
      minDate: moment().subtract(1, 'year').startOf('quarter'),
      maxDate: moment().endOf('quarter'),
      customerObjectives: [],
      customerObjectivesLoading: true,
      selectedObjective: undefined,
      myObjectivesChecked: false,
      myObjectivesSet: new Set(),
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchCustomerObjectiveProgress();
    if (this.props.myCategoryBrands.length > 0) {
      this.setState(() => ({
        category: this.props.myCategoryBrands[0].category,
        brand: this.props.myCategoryBrands[0].brand,
      }));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevState.category !== this.state.category ||
      prevState.brand !== this.state.brand ||
      prevState.selectedQuarter !== this.state.selectedQuarter
    ) {
      this.fetchCustomerObjectiveProgress();
    }
    if (
      prevProps.myCategoryBrands !== this.props.myCategoryBrands &&
      this.props.myCategoryBrands.length > 0
    ) {
      this.setState(() => ({
        category: this.props.myCategoryBrands[0].category,
        brand: this.props.myCategoryBrands[0].brand,
      }));
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  fetchCustomerObjectiveProgress = () => {
    if (
      this.props.user &&
      this.state.category &&
      this.state.brand
    ) {
      this.setState(() => ({ customerObjectivesLoading: true }));
      const formattedStartDate = this.state.selectedQuarter.startOf('quarter').format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.selectedQuarter.endOf('quarter').format('YYYY-MM-DD HH:mm:ss')
      axios.get(
        `${LISTEN_ENDPOINT}/api/customer-objectives-progress?customer_id=${this.props.user.customerId}&private_category_id=${this.state.category.id}&product_brand_id=${this.state.brand.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        const customerObjectives = response.data;
        const myObjectivesSet = new Set();
        // sort objectives by highest goal progress, then by name (not setup to bottom)
        customerObjectives.sort((a, b) => {
          if (a.objective_setup_complete === false && b.objective_setup_complete === false) {
            return 0;
          } else if (a.objective_setup_complete === false) {
            return 1;
          } else if (b.objective_setup_complete === false) {
             return -1;
          } else if (a.objective_goal_progress < b.objective_goal_progress) {
            return 1;
          } else if (a.objective_goal_progress > b.objective_goal_progress) {
            return -1;
          } else {
            if (a.objective_name < b.objective_name) {
              return -1;
            } else if (a.objective_name > b.objective_name) {
              return 1;
            } else {
              return 0;
            }
          }
        });
        for (let obj of customerObjectives) {
          // set user labels
          for (let user of obj.users) {
            if (user.first_name) {
              if (user.last_name) {
                  user.name_label = `${user.first_name} ${user.last_name[0].toUpperCase()}`;
              } else {
                user.name_label = user.first_name;
              }
            }
            if (this.props.user.id === user.user_id) {
              myObjectivesSet.add(obj.customer_objective_id);
            }
          }
          // sort users by name label
          obj.users.sort(sortAlphabeticalByKey('name_label'));
          // sort metrics by highest goal progress, then by label
          obj.metrics.sort((a, b) => {
            if (a.metric_goal_progress < b.metric_goal_progress) {
              return 1;
            } else if (a.metric_goal_progress > b.metric_goal_progress) {
              return -1;
            } else {
              if (a.metric_label < b.metric_label) {
                return -1;
              } else if (a.metric_label > b.metric_label) {
                return 1;
              } else {
                return 0;
              }
            }
          });
        }
        // preselect customer objective
        let selectedObjective;
        if (this.state.selectedObjective) {
          for (let obj of customerObjectives) {
            if (obj.customer_objective_id === this.state.selectedObjective.customer_objective_id) {
              selectedObjective = obj;
            }
          }
        }
        if (!selectedObjective && customerObjectives.length > 0) {
          selectedObjective = customerObjectives[0];
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            customerObjectives,
            customerObjectivesLoading: false,
            selectedObjective,
            myObjectivesSet,
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch customer objective progress data');
        if (this.state.isMounted) {
          this.setState(() => ({
            customerObjectives: [],
            customerObjectivesLoading: false,
            selectedObjective: undefined,
            myObjectivesSet: new Set(),
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  updateSelectedQuarter = (selectedQuarter) => {
    this.setState(() => ({ selectedQuarter }));
  };

  onObjectiveSelect = (selectedObjective) => {
    if (selectedObjective !== this.state.selectedObjective) {
      this.setState(() => ({ selectedObjective }));
    }
  }

  onMyObjectivesCheckedChange = (event) => {
    const myObjectivesChecked = event.currentTarget.checked;
    let selectedObjective = this.state.selectedObjective;
    if (
      myObjectivesChecked &&
      this.state.selectedObjective &&
      !this.state.myObjectivesSet.has(selectedObjective.customer_objective_id)
    ) {
      selectedObjective = undefined;
    }
    this.setState(() => ({
      myObjectivesChecked,
      selectedObjective
    }));
  };

  getGoalIcon = (goalProgress, isGoalOnTrack) => {
    let hoverContent;
    let goalIcon;
    let goalIconColor;
    if (goalProgress >= 100) {
      hoverContent = 'Goal Reached';
      goalIcon = faCheckCircle;
      goalIconColor = '#28a745';
    } else if (isGoalOnTrack) {
      hoverContent = 'On schedule to achieve goal';
      goalIcon = faThumbsUp;
      goalIconColor = '#28a745';
    } else {
      if (this.state.selectedQuarter >= this.state.currentQuarterStartDate) {
        hoverContent = 'Behind schedule to achieve goal';
      } else {
        hoverContent = 'Goal Not Reached';
      }
      goalIcon = faExclamationCircle;
      goalIconColor = '#dc3545';
    }

    return (
      <OverlayTrigger
        trigger="hover"
        placement="top"
        overlay={
          <Popover>
            <Popover.Content>
              {hoverContent}
            </Popover.Content>
          </Popover>
        }
      >
        <FontAwesomeIcon icon={goalIcon} color={goalIconColor} />
      </OverlayTrigger>
    )
  };

  render () {
    return (
      <div className="p-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        { this.props.user.customerId &&
          <div>
            <h5 className="mb-2 p-2 bg-bops-blue text-light" style={{ fontSize: '1rem' }}>
              <div className="d-inline-block ml-3">
                <QuarterScrollPicker
                  selectedQuarter={this.state.selectedQuarter}
                  updateSelectedQuarter={this.updateSelectedQuarter}
                  minDate={this.state.minDate}
                  maxDate={this.state.maxDate}
                  loading={this.state.customerObjectivesLoading}
                />
              </div>
              { this.state.selectedQuarter >= this.state.currentQuarterStartDate &&
                <div className="d-inline-block ml-4 align-middle">
                  { `This quarter is ${Math.round(this.state.currentQuarterPercentThrough)}% complete.`}
                </div>
              }
              <div className="mr-3 d-inline-block float-right" style={{ marginTop: '2px' }}>
                <Form.Check
                  type="checkbox"
                  label="My Objectives"
                  checked={this.state.myObjectivesChecked}
                  onChange={this.onMyObjectivesCheckedChange}
                  inline
                />
              </div>
            </h5>
            <div>
              { this.state.customerObjectivesLoading &&
                <div className="m-4 text-center">
                  <ClipLoader size={100}/>
                </div>
              }
              { !this.state.customerObjectivesLoading &&
                <div>
                  { this.state.customerObjectives.length === 0 &&
                    <div className="my-4 p-4 border rounded text-center">
                      <Button
                        variant="link"
                        onClick={() => history.push('/company-settings/objectives-and-goals')}
                      >
                        Click here to set up your objectives
                      </Button>
                    </div>
                  }
                  { this.state.customerObjectives.length > 0 &&
                    <Row>
                      <Col xs={6} lg={4}>
                        <div
                          className="pre-scrollable border rounded"
                          style={{
                            minHeight: 'calc(100vh - 150px)',
                            maxHeight: 'calc(100vh - 150px)',
                            overflow: 'auto',
                            overflowX: 'hidden'
                          }}
                        >
                          { this.state.customerObjectives.map((objective, i) => {
                              if (
                                this.state.myObjectivesChecked === false ||
                                this.state.myObjectivesSet.has(objective.customer_objective_id)
                              ) {
                                return (
                                  <Card
                                    key={`ogt-co-${i}`}
                                    className={
                                      (this.state.selectedObjective && this.state.selectedObjective.customer_objective_id === objective.customer_objective_id) ?
                                        'border-primary' :
                                        ''
                                    }
                                    onClick={() => this.onObjectiveSelect(objective)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <Card.Header
                                      className="px-2 ellipsis"
                                      style={{ fontSize: '1.2rem' }}
                                    >
                                      <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faCrosshairs}
                                        color="#6c757d"
                                      />
                                      {objective.objective_name}
                                    </Card.Header>
                                    <Card.Body>
                                      <div className="h-100 text-center">
                                        { objective.objective_setup_complete === false &&
                                          <div>
                                            { this.state.selectedQuarter >= this.state.currentQuarterStartDate &&
                                              <Button
                                                variant="link"
                                                onClick={() => history.push('/company-settings/objectives-and-goals')}
                                                size="sm"
                                              >
                                                Setup incomplete
                                              </Button>
                                            }
                                            { this.state.selectedQuarter < this.state.currentQuarterStartDate &&
                                              <div className="py-1">
                                                Objective was not setup this period.
                                              </div>
                                            }
                                          </div>
                                        }
                                        { objective.objective_setup_complete !== false &&
                                          <div>
                                            <Row noGutters>
                                              <Col xs={4}>
                                                <div className="d-inline-block align-middle"  style={{ fontSize: '2.5rem' }}>
                                                  {this.getGoalIcon(objective.objective_goal_progress, objective.objective_goal_on_track)}
                                                </div>
                                              </Col>
                                              <Col xs={8}>
                                                <div className="d-inline-block align-middle" style={{ fontSize: '2rem' }}>
                                                  { objective.objective_goal_progress != null ?
                                                      `${numberWithCommas(objective.objective_goal_progress)}%`:
                                                      '-'
                                                  }
                                                </div>
                                              </Col>
                                            </Row>
                                            <div className="mt-2 text-left" style={{ fontSize: '1rem' }}>
                                              { objective.users.map(user => {
                                                  return (
                                                    <Badge
                                                      key={`ogt-co-u-${user.user_id}`}
                                                      className="mr-1 mb-1"
                                                      variant="warning"
                                                      pill
                                                    >
                                                      {user.name_label}
                                                    </Badge>
                                                  )
                                                })
                                              }
                                            </div>
                                          </div>
                                        }
                                      </div>
                                    </Card.Body>
                                  </Card>
                                )
                              }
                            })
                          }
                        </div>
                      </Col>
                      <Col xs={6} lg={8}>
                        { (this.state.selectedObjective && this.state.selectedObjective.objective_setup_complete !== false) &&
                          <div
                            className="pre-scrollable border rounded"
                            style={{
                              minHeight: 'calc(100vh - 150px)',
                              maxHeight: 'calc(100vh - 150px)',
                              overflow: 'auto',
                              overflowX: 'hidden'
                            }}
                          >
                            { this.state.selectedObjective.metrics.map((metric, i) => {
                                return (
                                  <Card key={`ogt-so-m-${i}`}>
                                    <Card.Header className="p-2 ellipsis">
                                      {getMetricIcon(metric.channel_site_id)}
                                      {' '}
                                      {metric.metric_label}
                                    </Card.Header>
                                    <Card.Body className="p-2">
                                      <div>
                                        <Row noGutters>
                                          <Col xs={3}>
                                            <div className="mt-2" style={{ fontSize: '1.4rem' }}>
                                              <div className="d-inline-block mx-2">
                                                {this.getGoalIcon(metric.metric_goal_progress * 100, metric.metric_goal_on_track)}
                                              </div>
                                              <div className="d-inline-block">
                                                {numberWithCommas(metric.metric_current_value)}
                                              </div>
                                            </div>
                                          </Col>
                                          <Col xs={3}>
                                            <span className="align-middle">
                                              <div style={{ fontSize: '.75rem' }}>
                                                {`Goal: ${numberWithCommas(metric.metric_goal)}`}
                                              </div>
                                              <div>
                                                <ProgressBar
                                                  variant="warning"
                                                  now={metric.metric_goal_progress * 100}
                                                  style={{
                                                    height: '10px',
                                                    backgroundColor: '#d3d9df'
                                                  }}
                                                />
                                              </div>
                                              <div style={{ fontSize: '.75rem' }}>
                                                {`${round(metric.metric_goal_progress * 100, 1)}% complete`}
                                              </div>
                                            </span>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                )
                              })
                            }
                          </div>
                        }
                      </Col>
                    </Row>
                  }
                </div>
              }
            </div>
          </div>
        }
      </div>
    );
  }
};
