import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import ClipLoader from 'react-spinners/ClipLoader';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faLink } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class InstagramOwnedPosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: [],
      postsLoading: false,
      postResults: [],
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchInstagramOwnedPosts();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.brand !== this.props.brand ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter
    ) {
      this.fetchInstagramOwnedPosts();
    }
    if (
      prevProps.companyChecked !== this.props.companyChecked ||
      prevProps.portfolioChecked !== this.props.portfolioChecked ||
      prevProps.productChecked !== this.props.productChecked
    ) {
      this.updatePostResults();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  fetchInstagramOwnedPosts = () => {
    if (this.props.brand && this.props.startDateFilter && this.props.endDateFilter) {
      this.setState(() => ({ postsLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/instagram-owned-posts?company_id=${this.props.brand.company_id}&product_brand_id=${this.props.brand.id}&start_date=${this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss')}&end_date=${this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss')}`,
        HEADERS
      ).then(response => {
        const posts = response.data;
        const cppCounts = {
          companyCount: 0,
          portfolioCount: 0,
          productCount: 0,
        };
        for (const p of posts) {
          if (Array.isArray(p.brand_types)) {
            if (p.brand_types.includes('company')) {
              cppCounts.companyCount++;
            }
            if (p.brand_types.includes('portfolio')) {
              cppCounts.portfolioCount++;
            }
            if (p.brand_types.includes('product')) {
              cppCounts.productCount++;
            }
          }
        }
        // sort posts by date descending
        posts.sort((a, b) => moment(b.published_at) - moment(a.published_at));
        if (this.state.isMounted) {
          this.setState(() => ({
            posts,
            postsLoading: false,
          }), () => this.updatePostResults());
          this.props.updateCppCounts(cppCounts);
        }
      }).catch(error => {
        console.error('Error: failed to fetch instagram owned posts...');
        if (this.state.isMounted) {
          this.setState(() => ({
            posts: [],
            postsLoading: false,
          }), () => this.updatePostResults());
          this.props.updateCppCounts({});
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  updatePostResults = () => {
    let postResults = [];
    for (const p of this.state.posts) {
      if (
        Array.isArray(p.brand_types) &&
        (
          (this.props.companyChecked && p.brand_types.includes('company')) ||
          (this.props.productChecked && p.brand_types.includes('product')) ||
          (this.props.portfolioChecked && p.brand_types.includes('portfolio'))
        )
      ) {
        postResults.push(p);
      }
    }

    if (this.state.isMounted) {
      this.setState(() => ({ postResults }));
    }
  };

  parseURLs = (text) => {
    return text.replace(/[A-Za-z]+:\/\/[A-Za-z0-9-_]+\.[A-Za-z0-9-_:%&~\?\/.=]+/g, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };

  parseTextForHTMLReplacements = (text) => {
    text = this.parseURLs(text);
    return text;
  };

  render () {
    return (
      <div>
        { this.state.postsLoading &&
          <div className="p-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.postsLoading &&
          <div className="text-center">
            <div
              className="d-inline-block"
              style={{ width: '800px' }}
            >
              { this.state.postResults.map((post, i) => {
                  return (
                    <Card
                      id={`ig-owned-post-${i}`}
                      key={`fop-${post.id}`}
                      className="mb-4 text-left"
                      style={{ maxWidth: '800px' }}
                    >
                      <Card.Body>
                        <div>
                          { post.owner_username &&
                            <span
                              className="mr-2"
                              style={{ fontWeight: 'bold' }}
                            >
                              {post.owner_username}
                            </span>
                          }
                          { post.published_at &&
                            <span>
                              {`- ${moment(post.published_at).format('MMM D')}`}
                            </span>
                          }
                          <div className="d-inline-block float-right">
                            <SharePopUpContainer
                              shareElementId={`ig-owned-post-${i}`}
                              elementLabel={`ig-owned-post-${i}-img`}
                              position="top"
                              contextType="brand"
                              contextCategory={this.props.category}
                              contextBrand={this.props.brand}
                              contextChannel="Social"
                              contextProperty="Instagram"
                              contextChartName="Owned Post"
                            />
                          </div>
                          <div className="d-inline-block mr-2 float-right">
                            <FontAwesomeIcon
                              className="mt-1 float-right"
                              icon={faLink}
                              color="#08a0e9"
                              onClick={() => {
                                window.open(post.post_url, '_blank');
                              }}
                              style={{ cursor: 'pointer' }}
                            />
                          </div>
                          <hr />
                        </div>
                        <Row>
                          <Col>
                            { post.post_type === 'Image' && post.display_url &&
                              <div className="mt-2 text-center">
                                <img
                                  src={post.display_url}
                                  alt=""
                                  crossOrigin="anonymous"
                                  style={{ maxWidth: '500px' }}
                                />
                              </div>
                            }
                            { (
                                post.post_type === 'Sidecar' &&
                                post.images &&
                                post.images.length > 0
                              ) &&
                              <div className="mt-2 text-center">
                                <Carousel>
                                  { post.images.map((image, i) => {
                                      return (
                                        <Carousel.Item
                                          key={`ig-p-${post.id}-i-${i}`}
                                        >
                                          <img
                                            src={image}
                                            alt=""
                                            crossOrigin="anonymous"
                                            style={{ maxWidth: '500px' }}
                                          />
                                        </Carousel.Item>
                                      )
                                    })
                                  }
                                </Carousel>
                              </div>
                            }
                            { post.post_type === 'Video' && post.video_url &&
                              <div className="mt-2 text-center">
                                <video
                                  style={{
                                    maxWidth: '500px'
                                  }}
                                  controls
                                >
                                  <source src={post.video_url} />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            }
                            <div className="mt-1">
                              <FontAwesomeIcon
                                className="mr-2"
                                icon={faHeart}
                                color="#6c757d"
                              />
                              {post.likes_count || 0}
                            </div>
                            { post.post_text &&
                              <div
                                className="mt-2"
                                dangerouslySetInnerHTML={{__html: this.parseTextForHTMLReplacements(post.post_text)}}
                              />
                            }
                          </Col>
                          <Col>
                            <div className="mt-2" style={{ fontWeight: 'bold' }}>
                              Comments
                            </div>
                            <div
                              className="pre-scrollable"
                              style={{
                                minHeight: '476px',
                                maxHeight: '476px',
                                overflow: 'auto'
                              }}
                            >
                              { post.comments.map(comment => {
                                  return (
                                    <div
                                      key={`ig-c-${comment.comment_id}`}
                                      className="border-top py-2"
                                    >
                                      <div>
                                        <div className="d-inline-block mr-2">
                                          <img
                                            src={comment.owner_profile_pic_url}
                                            alt=""
                                            crossOrigin="anonymous"
                                            style={{
                                              maxWidth: '30px',
                                              borderRadius: '50%'
                                            }}
                                          />
                                        </div>
                                        <div
                                          className="d-inline-block"
                                          style={{ fontWeight: 'bold' }}
                                        >
                                          {comment.owner_username}
                                        </div>
                                        <div>
                                          {comment.comment}
                                        </div>
                                        <div
                                          className="text-muted"
                                          style={{ fontSize: '.875rem' }}
                                        >
                                          {moment(comment.published_at).format('MM/DD/YYYY')}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  )
                })
              }
            </div>
          </div>
        }
      </div>
    );
  }
};
