import { connect } from 'react-redux';
import EmailSubscriptions from '../../../components/settings/contentCollectors/EmailSubscriptions';

const mapStateToProps = (state) => ({
  user: state.user,
  categories: state.categories
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(EmailSubscriptions);
