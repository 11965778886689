import React from 'react';
import Card from 'react-bootstrap/Card';
import { prettyPrintDomainFromUrl } from '../../utils/urls';

const ResultDisplay = (props) => {
  return (
    <Card
      className="h-100"
      onClick={() => props.onSelect(props.type, props.result)}
      style={{ cursor: 'pointer' }}
    >
      <Card.Header className="bg-secondary text-light p-2">
        { props.type !== 'googleAds' &&
          <div
            className="mr-2 d-inline-block text-center"
            style={{
              backgroundColor: '#4fa2f3',
              borderRadius: '50%',
              color: '#ffffff',
              width: '30px',
              height: '30px',
              fontSize: '1.2rem'
            }}
          >
            {props.rank}
          </div>
        }
        <div className="d-inline-block align-middle">
          <b>{prettyPrintDomainFromUrl(props.domain)}</b>
        </div>
      </Card.Header>
      <Card.Body className="p-2">
        {props.title}
      </Card.Body>
      { props.type === 'googleAds' &&
        <Card.Footer className="p-2 bg-white" style={{ fontSize: '.75rem' }}>
          { (props.result && props.result.average_position) &&
            <div>
              {`Average position: ${props.result.average_position}`}
            </div>
          }
          { (props.result && props.result.times_seen && props.result.times_tried) &&
            <div>
              {`Ad found ${props.result.times_seen} of ${props.result.times_tried} tries`}
            </div>
          }
        </Card.Footer>
      }
    </Card>
  )
};

export default ResultDisplay;
