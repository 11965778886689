import React from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import ClipLoader from 'react-spinners/ClipLoader';
import { numberWithCommas } from '../../../utils/numbers';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class GoogleAdsMonthSummaryTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      monthlySummaryData: [],
      monthlySummaryDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchMonthlySummaryData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.companyId !== this.props.companyId ||
      prevProps.campaignId !== this.props.campaignId
    ) {
      this.fetchMonthlySummaryData();
    }
  };

  fetchMonthlySummaryData = () => {
    if (this.props.companyId) {
      let monthlySummaryDataEndpoint;
      if (this.props.campaignId === 'all') {
        monthlySummaryDataEndpoint = `${CONTENT_SCRAPING_ENDPOINT}/api/companies/${this.props.companyId}/google-ad-monthly-metrics`;
      } else {
        monthlySummaryDataEndpoint = `${CONTENT_SCRAPING_ENDPOINT}/api/campaigns/${this.props.campaignId}/campaign-metric-trend`;
      }

      axios.get(
        monthlySummaryDataEndpoint,
        HEADERS
      ).then(response => {
        const monthlySummaryData = response.data.monthly_stats.reverse();
        if (this.state.isMounted) {
          this.setState(() => ({
            monthlySummaryData,
            monthlySummaryDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch google ad monthly summary data`);
        if (this.state.isMounted) {
          this.setState(() => ({
            monthlySummaryData: [],
            monthlySummaryDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        { this.state.monthlySummaryDataLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.monthlySummaryDataLoading &&
          <Table
            className="text-center"
            size="sm"
            striped
          >
            <thead className="bg-dark text-light">
              <tr>
                <th style={{ width: '150px' }}>
                  Month
                </th>
                <th>
                  Total Cost
                </th>
                <th>
                  Conversions
                </th>
                <th>
                  Impressions
                </th>
                <th>
                  Clicks
                </th>
                <th>
                  CTR
                </th>
                <th>
                  Cost-per-click
                </th>
              </tr>
            </thead>
            <tbody>
              { this.state.monthlySummaryData.map((monthData, i) => {
                  return (
                    <tr key={`ga-acd-${i}`}>
                      <td style={{ width: '150px' }}>
                        {`${monthData.month} ${monthData.year}`}
                      </td>
                      <td>
                        { monthData.total_cost != null ?
                            `$${numberWithCommas(monthData.total_cost)}` : '-'
                        }
                      </td>
                      <td>
                        { monthData.total_conversion != null ?
                            numberWithCommas(monthData.total_conversion) : '-'
                        }
                      </td>
                      <td>
                        { monthData.total_impressions != null ?
                            numberWithCommas(monthData.total_impressions) : '-'
                        }
                      </td>
                      <td>
                        { monthData.total_clicks != null ?
                            numberWithCommas(monthData.total_clicks) : '-'
                        }
                      </td>
                      <td>
                        { monthData.ctr != null ?
                            `${monthData.ctr}%` : '-'
                        }
                      </td>
                      <td>
                        { monthData.cost_per_click != null ?
                            `$${numberWithCommas(monthData.cost_per_click)}` : '-'
                        }
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        }
      </div>
    );
  }
};
