import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import ClipLoader from 'react-spinners/ClipLoader';
import Popup from 'reactjs-popup';
import { numberWithCommas } from '../../utils/numbers';
import { sortAlphabeticalByKey } from '../../utils/sorts';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class SerpDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      serpDetailsData: [],
      brands: [],
      date: undefined,
      serpDetailsDataLoading: false,
      borderEnhancedStyle: '1px solid #343a40'
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.setBrands();
    this.fetchSerpDetailsData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.setBrands();
      this.fetchSerpDetailsData();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  setBrands = () => {
    if (this.props.category && this.props.category.product_brands) {
      const brands = [];
      for (const b of this.props.category.product_brands) {
        brands.push(b.name);
      }
      this.setState(() => ({ brands }));
    }
  };

  fetchSerpDetailsData = () => {
    if (this.props.category) {
      this.setState(() => ({ serpDetailsDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/web-sublink-keyword-serp-details?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const serpDetailsData = response.data;
        let date;
        // set highest value per row to allow highlighting of best
        for (const d of serpDetailsData) {
          if (d.SERP_earliest_page_found) {
            d.SERP_earliest_page_found.lowest_value = 100;
            for (const v of Object.values(d.SERP_earliest_page_found)) {
              if (v && v < d.SERP_earliest_page_found.lowest_value) {
                d.SERP_earliest_page_found.lowest_value = v;
              }
            }
          }
          if (d.SERP_total_urls_in_five_pages) {
            d.SERP_total_urls_in_five_pages.highest_value = 0;
            for (const v of Object.values(d.SERP_total_urls_in_five_pages)) {
              if (v && v > d.SERP_total_urls_in_five_pages.highest_value) {
                d.SERP_total_urls_in_five_pages.highest_value = v;
              }
            }
          }
          if (d.web_page_with_keyword_semantic) {
            d.web_page_with_keyword_semantic.highest_value = 0;
            for (const v of Object.values(d.web_page_with_keyword_semantic)) {
              if (v && v > d.web_page_with_keyword_semantic.highest_value) {
                d.web_page_with_keyword_semantic.highest_value = v;
              }
            }
          }
          if (d.web_page_with_keyword_verbatim) {
            d.web_page_with_keyword_verbatim.highest_value = 0;
            for (const v of Object.values(d.web_page_with_keyword_verbatim)) {
              if (v && v > d.web_page_with_keyword_verbatim.highest_value) {
                d.web_page_with_keyword_verbatim.highest_value = v;
              }
            }
          }

          if (!date && d.query_date) {
            date = moment.utc(d.query_date).format('MM/DD/YYYY');
          }
        }

        // sort serp keywords alphabetically
        serpDetailsData.sort(sortAlphabeticalByKey('web_search_keyword'));

        if (this.state.isMounted) {
          this.setState(() => ({
            serpDetailsData,
            serpDetailsDataLoading: false,
            date
          }))
        }
      }).catch(error => {
        console.error('Error: failed to fetch serp details data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            serpDetailsData: [],
            serpDetailsDataLoading: false,
            date: undefined
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        { this.state.serpDetailsDataLoading &&
          <div className="text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.serpDetailsDataLoading &&
          <div>
            <Table
              striped
              size="sm"
              style={{
                overflow: 'auto',
              }}
            >
              <thead className="bg-dark text-light text-center border-top">
                <tr
                  style={{
                    position: 'sticky',
                    top: -1,
                    zIndex: 1
                  }}
                >
                  <th
                    className="py-1 bg-dark border-bottom border-right"
                    style={{
                      borderTop: this.state.borderEnhancedStyle,
                      borderLeft: this.state.borderEnhancedStyle
                    }}
                  >
                    <div>Keyword</div>
                    { this.state.date &&
                      <div style={{
                          fontSize: '.75rem',
                          fontWeight: 'normal'
                        }}>
                        {`Week of ${this.state.date}`}
                      </div>
                    }
                  </th>
                  <th
                    className="py-1 bg-dark border-left border-right border-bottom"
                    style={{ borderTop: this.state.borderEnhancedStyle }}
                  ></th>
                  { this.state.brands.map((brand, i) => {
                      return (
                        <th
                          key={`th-b-${i}`}
                          className="bg-dark border-bottom border-left"
                          style={{
                            borderTop: this.state.borderEnhancedStyle,
                            borderRight: (i + 1 === this.state.brands.length) ?
                              this.state.borderEnhancedStyle :
                              '1px solid #dee2e6'
                          }}
                        >
                          {brand}
                        </th>
                      )
                    })
                  }
                </tr>
              </thead>
              <tbody>
                { this.state.serpDetailsData.map((keywordData, i) => {
                    return (
                      <React.Fragment key={`sdd-${keywordData.web_search_keyword_id}-${i}`}>
                        <tr>
                          <td
                            className="bg-light text-center align-middle border-top border-right"
                            rowSpan="4"
                            style={{
                              borderLeft: this.state.borderEnhancedStyle,
                              borderBottom: this.state.borderEnhancedStyle
                            }}
                          >
                            <div>{keywordData.web_search_keyword}</div>
                            <div className="text-muted" style={{ fontSize: '.75rem' }}>
                              {keywordData.web_search_keyword_location}
                            </div>
                          </td>
                          <td className="border">
                            <Popup
                              trigger = {
                                <div>
                                  SERP, Earliest Page Found
                                </div>
                              }
                              position="right top"
                              on="hover"
                              contentStyle={{ borderRadius: '5px' }}
                            >
                              <div className="text-dark">
                                The Earliest Page Found indicates how many pages deep in Google search a user had to go to find a specific brand's URL when a specific keyword was used. Only organic is considered.
                              </div>
                            </Popup>
                          </td>
                          { this.state.brands.map((brand, bi) => {
                              return(
                                <td
                                  key={`sdd-wpwk-b-${bi}`}
                                  className={
                                    (
                                      keywordData.SERP_earliest_page_found.lowest_value &&
                                      keywordData.SERP_earliest_page_found.lowest_value === keywordData.SERP_earliest_page_found[brand]
                                    ) ?
                                      'bg-bops-blue text-light text-center align-middle border-top border-bottom border-left':
                                      'text-center align-middle border-top border-bottom border-left'
                                  }
                                  style={{
                                    borderRight: (bi + 1 === this.state.brands.length) ?
                                      this.state.borderEnhancedStyle :
                                      '1px solid #dee2e6'
                                  }}
                                >
                                  {keywordData.SERP_earliest_page_found[brand] != null ? numberWithCommas(keywordData.SERP_earliest_page_found[brand]) : ''}
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="border">
                            <Popup
                              trigger = {
                                <div>
                                  SERP, Total URL's in First 5 pages
                                </div>
                              }
                              position="right top"
                              on="hover"
                              contentStyle={{ borderRadius: '5px' }}
                            >
                              <div className="text-dark">
                                The Total URL's in First 5 Pages counts the number of times a brand's URL is found in Google search when a specific keyword was used.
                              </div>
                            </Popup>
                          </td>
                          { this.state.brands.map((brand, bi) => {
                              return(
                                <td
                                  key={`sdd-wpwk-b-${bi}`}
                                  className={
                                    (
                                      keywordData.SERP_total_urls_in_five_pages.highest_value &&
                                      keywordData.SERP_total_urls_in_five_pages.highest_value === keywordData.SERP_total_urls_in_five_pages[brand]
                                    ) ?
                                      'bg-bops-blue text-light text-center align-middle border-top border-left':
                                      'text-center align-middle border-top border-left'
                                  }
                                  style={{
                                    borderRight: (bi + 1 === this.state.brands.length) ?
                                      this.state.borderEnhancedStyle :
                                      '1px solid #dee2e6'
                                  }}
                                >
                                  {keywordData.SERP_total_urls_in_five_pages[brand] != null ? numberWithCommas(keywordData.SERP_total_urls_in_five_pages[brand]) : ''}
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td className="border">
                            <Popup
                              trigger = {
                                <div>
                                  Web Page, Semantic Match
                                </div>
                              }
                              position="right top"
                              on="hover"
                              contentStyle={{ borderRadius: '5px' }}
                            >
                              <div className="text-dark">
                                The Semantic Match indicates the number of pages Google has indexed with similar content.
                              </div>
                            </Popup>
                          </td>
                          { this.state.brands.map((brand, bi) => {
                              return(
                                <td
                                  key={`sdd-wpwk-b-${bi}`}
                                  className={
                                    (
                                      keywordData.web_page_with_keyword_semantic.highest_value &&
                                      keywordData.web_page_with_keyword_semantic.highest_value === keywordData.web_page_with_keyword_semantic[brand]
                                    ) ?
                                      'bg-bops-blue text-light text-center align-middle border-top border-bottom border-left':
                                      'text-center align-middle border-top border-bottom border-left'
                                  }
                                  style={{
                                    borderRight: (bi + 1 === this.state.brands.length) ?
                                      this.state.borderEnhancedStyle :
                                      '1px solid #dee2e6'
                                  }}
                                >
                                  {keywordData.web_page_with_keyword_semantic[brand] != null ? numberWithCommas(keywordData.web_page_with_keyword_semantic[brand]) : ''}
                                </td>
                              )
                            })
                          }
                        </tr>
                        <tr>
                          <td
                            className="border-top border-left border-right"
                            style={{ borderBottom: this.state.borderEnhancedStyle }}
                          >
                            <Popup
                              trigger = {
                                <div>
                                  Web Page, Verbatim Match
                                </div>
                              }
                              position="right top"
                              on="hover"
                              contentStyle={{ borderRadius: '5px' }}
                            >
                              <div className="text-dark">
                                The Verbatim Match indicates the number of Web pages on the site that have an exact match to the keywords.
                              </div>
                            </Popup>
                          </td>
                          { this.state.brands.map((brand, bi) => {
                              return(
                                <td
                                  key={`sdd-wpwk-b-${bi}`}
                                  className={
                                    (
                                      keywordData.web_page_with_keyword_verbatim.highest_value &&
                                      keywordData.web_page_with_keyword_verbatim.highest_value === keywordData.web_page_with_keyword_verbatim[brand]
                                    ) ?
                                      'bg-bops-blue text-light text-center align-middle border-top border-left':
                                      'text-center align-middle border-top border-left'
                                  }
                                  style={{
                                    borderRight: (bi + 1 === this.state.brands.length) ?
                                      this.state.borderEnhancedStyle :
                                      '1px solid #dee2e6',
                                    borderBottom: this.state.borderEnhancedStyle
                                  }}
                                >
                                  {keywordData.web_page_with_keyword_verbatim[brand] != null ? numberWithCommas(keywordData.web_page_with_keyword_verbatim[brand]) : ''}
                                </td>
                              )
                            })
                          }
                        </tr>
                      </React.Fragment>
                    )
                  })
                }
              </tbody>
            </Table>
          </div>
        }
      </div>
    );
  }
};
