import React from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class RolePersonalizationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      executiveLeadershipRole: false,
      opsReportingRole: false,
      productFieldMarketingRole: false,
      publicRelationsRole: false,
      socialMediaRole: false,
      webSeoRole: false,
      partnerChannelsRole: false,
      audienceMarketResearchRole: false,
      brandCreativeCopyRole: false,
      eventsRole: false,
      advertisingRole: false,
      revenueMarketingRole: false,
      conversionPersonalizationRole: false,
      analystRole: false,
      competitiveIntelligenceRole: false,
      agencySupportRole: false,
    };
  }

  onOpen = () => {
    this.setState(() => ({
      executiveLeadershipRole: false,
      opsReportingRole: false,
      productFieldMarketingRole: false,
      publicRelationsRole: false,
      socialMediaRole: false,
      webSeoRole: false,
      partnerChannelsRole: false,
      audienceMarketResearchRole: false,
      brandCreativeCopyRole: false,
      eventsRole: false,
      advertisingRole: false,
      revenueMarketingRole: false,
      conversionPersonalizationRole: false,
      analystRole: false,
      competitiveIntelligenceRole: false,
      agencySupportRole: false,
    }));
  };

  updateRoleCheck = (role, checked) => {
    this.setState(() => ({ [role]: checked }));
  };


  handleClose = () => {
    if (this.props.type === 'firstLogin') {
      // update invite accepted
      axios.put(
        `${LISTEN_ENDPOINT}/api/customer-users/${this.props.userId}`,
        { invite_accepted: true },
        HEADERS
      ).catch(error => {
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
    this.props.handleClose();
  };

  saveRolePersonalization = () => {
    const userRolePersonalizationBody = {
      executive_leadership_role: this.state.executiveLeadershipRole,
      ops_reporting_role: this.state.opsReportingRole,
      product_field_marketing_role: this.state.productFieldMarketingRole,
      public_relations_role: this.state.publicRelationsRole,
      social_media_role: this.state.socialMediaRole,
      web_seo_role: this.state.webSeoRole,
      partner_channels_role: this.state.partnerChannelsRole,
      audience_market_research_role: this.state.audienceMarketResearchRole,
      brand_creative_copy_role: this.state.brandCreativeCopyRole,
      events_role: this.state.eventsRole,
      advertising_role: this.state.advertisingRole,
      revenue_marketing_role: this.state.revenueMarketingRole,
      conversion_personalization_role: this.state.conversionPersonalizationRole,
      analyst_role: this.state.analystRole,
      competitive_intelligence_role: this.state.competitiveIntelligenceRole,
      agency_support_role: this.state.agencySupportRole,
    };
    if (this.props.type === 'firstLogin') {
      userRolePersonalizationBody.invite_accepted = true;
    }

    axios.put(
      `${LISTEN_ENDPOINT}/api/customer-users/${this.props.userId}`,
      userRolePersonalizationBody,
      HEADERS
    ).then(response => {
      this.props.handleClose();
    }).catch(error => {
      this.props.handleClose();
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  render() {
    return (
      <Modal
        show={this.props.isOpen}
        onHide={this.handleClose}
        onEntering={this.onOpen}
        size="lg"
      >
        <Modal.Body>
          <h1 className="text-center">
            Welcome to Brand<b className="text-bops-blue">Ops</b>
          </h1>
          <hr />
          <h4 className="text-center">
            Personalize your experience.
          </h4>
          <div className="p-4 border rounded bg-light">
            <div className="mb-4 text-center font-weight-bold">
              Check all of the responsibilities that best describe your role.
            </div>
            <Row>
              <Col>
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label="Executive Leadership"
                  checked={this.state.executiveLeadershipRole}
                  onChange={(event) => this.updateRoleCheck('executiveLeadershipRole', event.currentTarget.checked)}
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label="Ops & Reporting"
                  checked={this.state.opsReportingRole}
                  onChange={(event) => this.updateRoleCheck('opsReportingRole', event.currentTarget.checked)}
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label="Product / Field Marketing"
                  checked={this.state.productFieldMarketingRole}
                  onChange={(event) => this.updateRoleCheck('productFieldMarketingRole', event.currentTarget.checked)}
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label="Comms & Public Relations"
                  checked={this.state.publicRelationsRole}
                  onChange={(event) => this.updateRoleCheck('publicRelationsRole', event.currentTarget.checked)}
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label="Social Media"
                  checked={this.state.socialMediaRole}
                  onChange={(event) => this.updateRoleCheck('socialMediaRole', event.currentTarget.checked)}
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label="Web & SEO"
                  checked={this.state.webSeoRole}
                  onChange={(event) => this.updateRoleCheck('webSeoRole', event.currentTarget.checked)}
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label="Partner / Channels"
                  checked={this.state.partnerChannelsRole}
                  onChange={(event) => this.updateRoleCheck('partnerChannelsRole', event.currentTarget.checked)}
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label="Audience & Mkt Research"
                  checked={this.state.audienceMarketResearchRole}
                  onChange={(event) => this.updateRoleCheck('audienceMarketResearchRole', event.currentTarget.checked)}
                />
              </Col>
              <Col>
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label="Brand, Creative & Copy"
                  checked={this.state.brandCreativeCopyRole}
                  onChange={(event) => this.updateRoleCheck('brandCreativeCopyRole', event.currentTarget.checked)}
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label="Events (live, webinar, podcasts)"
                  checked={this.state.eventsRole}
                  onChange={(event) => this.updateRoleCheck('eventsRole', event.currentTarget.checked)}
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label="Advertising"
                  checked={this.state.advertisingRole}
                  onChange={(event) => this.updateRoleCheck('advertisingRole', event.currentTarget.checked)}
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label="Revenue Mktg., Nurture"
                  checked={this.state.revenueMarketingRole}
                  onChange={(event) => this.updateRoleCheck('revenueMarketingRole', event.currentTarget.checked)}
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label="Conversion, A/B, Personalization"
                  checked={this.state.conversionPersonalizationRole}
                  onChange={(event) => this.updateRoleCheck('conversionPersonalizationRole', event.currentTarget.checked)}
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label="Analyst / Data Science"
                  checked={this.state.analystRole}
                  onChange={(event) => this.updateRoleCheck('analystRole', event.currentTarget.checked)}
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label="Competitive Intelligence"
                  checked={this.state.competitiveIntelligenceRole}
                  onChange={(event) => this.updateRoleCheck('competitiveIntelligenceRole', event.currentTarget.checked)}
                />
                <Form.Check
                  className="ml-2"
                  type="checkbox"
                  label="Agency Support"
                  checked={this.state.agencySupportRole}
                  onChange={(event) => this.updateRoleCheck('agencySupportRole', event.currentTarget.checked)}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => this.handleClose()}
          >
            Skip
          </Button>
          <Button
            variant="primary"
            onClick={() => this.saveRolePersonalization()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
};
