import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NoneFound from '../searchEngines/NoneFound';

const YouTubeResultDisplay = (props) => {
  return (
    <div
      className="pre-scrollable"
      style={{
        minHeight: '70vh',
        maxHeight: '70vh',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
    >
      { props.results.length === 0 &&
        <div className="mt-2">
          <NoneFound/>
        </div>
      }
      { props.results.map(result => {
          return (
            <div
              key={`ytr${result.id}`}
              className="p-2 w-100"
            >
              <div className="mt-1 d-inline-block">
                <Row>
                  <Col className="flex-column" style={{ flex: '0 0 30px' }}>
                    <div
                      className="text-center d-inline-block"
                      style={{
                        backgroundColor: '#4fa2f3',
                        borderRadius: '50%',
                        color: '#ffffff',
                        width: '30px',
                        height: '30px',
                        fontSize: '1.2rem'
                      }}
                    >
                      {result.position}
                    </div>
                  </Col>
                  <Col className="flex-column" style={{ flex: '0 0 246px' }}>
                    <img
                      src={result.static_thumbnail}
                      onClick={() => window.open(result.link, '_blank')}
                      style={{
                        width: '246px',
                        height: '138px',
                        cursor: 'pointer'
                      }}
                    />
                  </Col>
                  <Col>
                    <div style={{ fontSize: '1.1rem' }}>
                      {result.title}
                    </div>
                    <div style={{ fontSize: '.875rem' }}>
                      {result.snippet}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )
        })
      }
    </div>
  )
};

export default YouTubeResultDisplay;
