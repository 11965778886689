import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import CategorySynonymousMentionGraph from './CategorySynonymousMentionGraph';
import CategorySynonymousGoogleTrendGraph from './CategorySynonymousGoogleTrendGraph';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class CategoryTerms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categorySynonymousNamesData: [],
      newMentionsGraphData: {},
      podcastMentionsGraphData: {},
      categorySynonymousNamesDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchCategorySynonymousNamesData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      this.fetchCategorySynonymousNamesData();
    }
  };

  fetchCategorySynonymousNamesData = () => {
    if (this.props.category) {
      this.setState(() => ({ categorySynonymousNamesDataLoading: true }));
      const formattedStartDate = moment().utc().subtract(7, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      const formattedEndDate = moment().utc().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-synonymous-name-trends?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}&group_by=month`,
        HEADERS
      ).then(response => {
        const categorySynonymousNamesData = response.data;
        const newMentionsGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Name', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const podcastMentionsGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Name', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const googleTrendGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Name', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };

        for (const monthData of categorySynonymousNamesData) {
          for (const synonymousNameData of monthData.category_synonymous_names) {
            newMentionsGraphData.records.push([
              monthData.month,
              synonymousNameData.category_synonymous_name,
              synonymousNameData.news_mention_count,
            ]);
            podcastMentionsGraphData.records.push([
              monthData.month,
              synonymousNameData.category_synonymous_name,
              synonymousNameData.podcast_mention_count,
            ]);
            googleTrendGraphData.records.push([
              monthData.month,
              synonymousNameData.category_synonymous_name,
              synonymousNameData.google_trend_value,
            ]);
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categorySynonymousNamesData,
            newMentionsGraphData,
            podcastMentionsGraphData,
            googleTrendGraphData,
            categorySynonymousNamesDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch category synonymous names data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            categorySynonymousNamesData: [],
            newMentionsGraphData: {},
            podcastMentionsGraphData: {},
            googleTrendGraphData: {},
            categorySynonymousNamesDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="mx-4">
        { this.state.categorySynonymousNamesDataLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.categorySynonymousNamesDataLoading &&
          <div>
            <h5 className="my-2 p-2 bg-bops-blue text-light">Category Synonymous Names</h5>
            { this.state.categorySynonymousNamesData.length === 0 &&
              <div className="p-4 bg-white border rounded">
                <div className="p-4 text-center">
                  Not enough data collected
                </div>
              </div>
            }
            { this.state.categorySynonymousNamesData.length > 0 &&
              <div>
                <CategorySynonymousMentionGraph
                  title="News Mentions"
                  data={this.state.newMentionsGraphData}
                  loading={this.state.categorySynonymousNamesDataLoading}
                />
                <CategorySynonymousMentionGraph
                  title="Podcast Mentions"
                  data={this.state.podcastMentionsGraphData}
                  loading={this.state.categorySynonymousNamesDataLoading}
                />
                <CategorySynonymousGoogleTrendGraph
                  title="Google Trend"
                  data={this.state.googleTrendGraphData}
                  loading={this.state.categorySynonymousNamesDataLoading}
                />
              </div>
            }
          </div>
        }
      </div>
    );
  }
};
