import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../common/CardComponent';
import { numberWithCommas, round } from '../../utils/numbers';
import { colors } from '../../utils/graphs';
import { capitalizeFirstLetter } from '../../utils/strings';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class BrandPromoterDetractorPieGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      promoterDetractorData: [],
      promoterDetractorDataLoading: false,
      totalComments: 0
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.loadPromoterDetractorData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.brand !== this.props.brand ||
      prevProps.type !== this.props.type ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter
    ) {
      this.loadPromoterDetractorData();
    }
  }

  loadPromoterDetractorData = () => {
    if (
      this.props.brand &&
      this.props.brand.id &&
      this.props.type &&
      this.props.startDateFilter &&
      this.props.endDateFilter
    ) {
      this.setState(() => ({ promoterDetractorDataLoading: true }));
      const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/${this.props.type}-brand-sentiments?company_id=${this.props.brand.company_id}&product_brand_id=${this.props.brand.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        let totalComments = 0;
        totalComments += response.data.brand_promoters_count;
        totalComments += response.data.brand_detractors_count;
        const promoterPercent = round((response.data.brand_promoters_count/totalComments)*100, 1);
        const detractorPercent = round((response.data.brand_detractors_count/totalComments)*100, 1);
        const promoterDetractorData = [
          {
            name: 'Promoters',
            count: response.data.brand_promoters_count,
            percent: promoterPercent
          },
          {
            name: 'Detractors',
            count: response.data.brand_detractors_count,
            percent: detractorPercent
          }
        ];
        if (this.state.isMounted) {
          this.setState(() => ({
            promoterDetractorData,
            promoterDetractorDataLoading: false,
            totalComments
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch promoter detractor data...');
        this.setState(() => ({
          promoterDetractorData: [],
          promoterDetractorDataLoading: false,
          totalComments: 0
        }));
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <CardComponent
        title="Promoter vs Detractor Sentiment"
        icon={
          <img
            className="mr-2 align-top"
            src="/images/Significant_Common_Terms_Icon.png"
          />
        }
        body={
          <div>
            { this.state.promoterDetractorDataLoading &&
              <div className="p-4 text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.promoterDetractorDataLoading &&
              <div>
                { this.state.totalComments === 0 &&
                  <div className="p-4 text-center">
                    Not enough data collected
                  </div>
                }
                { this.state.totalComments > 0 &&
                  <Row noGutters>
                    <Col xs={8}>
                      <ResponsiveContainer height={350} width="99%">
                        <PieChart margin={{ top: 25}}>
                          <Pie
                            data={this.state.promoterDetractorData}
                            nameKey="name"
                            dataKey="percent"
                            cx="50%"
                            cy="40%"
                            innerRadius="40%"
                            outerRadius="85%"
                          >
                            { this.state.promoterDetractorData.map((entry, index) => {
                                if (index === 0) {
                                  // promoter color
                                  return <Cell key={`cell${index}`} fill="#28a745"/>;
                                } else if (index === 1) {
                                  // detractor color
                                  return <Cell key={`cell${index}`} fill="#dc3545"/>;
                                }
                              })
                            }
                          </Pie>
                          <Tooltip formatter={(value) => `${value}%`} />
                        </PieChart>
                      </ResponsiveContainer>
                    </Col>
                    <Col xs={4} style={{ height: '350px' }}>
                      <div className="d-inline-block align-middle">
                        { this.state.promoterDetractorData.map((entry, index) => {
                            return (
                              <div key={`bpd-key-${index}`}>
                                {`${entry.name}: ${entry.count}`}
                              </div>
                            )
                          })
                        }
                      </div>
                    </Col>
                  </Row>
                }
              </div>
            }
          </div>
        }
        contextType="brand"
        contextCategory={this.props.category}
        contextBrand={this.props.brand}
        contextChannel="Social"
        contextProperty={capitalizeFirstLetter(this.props.type)}
        contextChartName="Promoter vs Detractor Sentiment"
      />
    );
  }
};
