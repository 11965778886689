import React from 'react';
import axios from 'axios';
import moment from 'moment'
import Table from 'react-bootstrap/Table';
import ClipLoader from 'react-spinners/ClipLoader';
import { numberWithCommas } from '../../../utils/numbers';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class GoogleAdsConversionsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      conversionsData: [],
      conversionsDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchConversionsData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.companyId !== this.props.companyId ||
      prevProps.campaignId !== this.props.campaignId
    ) {
      this.fetchConversionsData();
    }
  };

  fetchConversionsData = () => {
    if (this.props.companyId) {
      this.setState(() => ({ conversionsDataLoading: true }));
      let campaignParam = '';
      if (this.props.campaignId !== 'all') {
        campaignParam = `&campaign_id=${this.props.campaignId}`;
      }
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/google-ad-campaign-conversion-stats?company_id=${this.props.companyId}${campaignParam}`,
        HEADERS
      ).then(response => {
        const conversionsData = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            conversionsData,
            conversionsDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch google ad conversions data`);
        if (this.state.isMounted) {
          this.setState(() => ({
            conversionsData: [],
            conversionsDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        { this.state.conversionsDataLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.conversionsDataLoading &&
          <Table
            className="text-center"
            size="sm"
            striped
          >
            <thead className="bg-dark text-light">
              <tr>
                <th style={{ width: '150px' }}>
                  Date
                </th>
                <th>
                  Campaign
                </th>
                <th>
                  Ad Group
                </th>
                <th>
                  Conversion
                </th>
                <th>
                  Cost of Conversion
                </th>
              </tr>
            </thead>
            <tbody>
              { this.state.conversionsData.map((cd, i) => {
                  return (
                    <tr key={`ga-cd-${i}`}>
                      <td style={{ width: '150px' }}>
                        {moment(cd.scoring_date).format('MM/DD/YYYY')}
                      </td>
                      <td>
                        {cd.campaign_name || '-'}
                      </td>
                      <td>
                        {cd.ad_group_name || '-'}
                      </td>
                      <td style={{ width: '150px' }}>
                        { cd.conversions != null ?
                            numberWithCommas(cd.conversions) : '-'
                        }
                      </td>
                      <td style={{ width: '200px' }}>
                        { cd.cost_per_conversion != null ?
                            `$${cd.cost_per_conversion}` : '-'
                        }
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        }
      </div>
    );
  }
};
