import React from 'react';
import axios from 'axios';
import moment from 'moment';
import GeneralSummaryGraph from '../graphs/GeneralSummaryGraph';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class TwitterEarnedTweetSummaryGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      earnedTweetTrendData: [],
      earnedtweetScoreType: undefined,
      earnedTweetTrendDataLoading: false,
      categoryAverageData: [],
    }
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchEarnedTweetTrendData();
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.topicId !== this.props.topicId ||
      prevProps.allMonthsChecked !== this.props.allMonthsChecked
    ) {
      this.fetchEarnedTweetTrendData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchEarnedTweetTrendData = () => {
    if (this.props.topicId) {
      this.setState(() => ({ earnedTweetTrendDataLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/topics/${this.props.topicId}/earned-tweet-trend${allMonthsStartDateParam ? `?start-date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let earnedTweetTrendData = response.data.monthly_stats;
        const earnedtweetScoreType = response.data.earned_tweet_count ? response.data.earned_tweet_count.score_type : undefined;
        // merge category average data into original data source
        earnedTweetTrendData = earnedTweetTrendData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAverageData[i]))
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            earnedTweetTrendData,
            earnedtweetScoreType,
            earnedTweetTrendDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch earned tweet trend data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            earnedTweetTrendData: [],
            earnedtweetScoreType: undefined,
            earnedTweetTrendDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=twitter_avg_earned_tweets${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAverageData = response.data.category_stats;
          // merge category average data into original data source
          const earnedTweetTrendData = this.state.earnedTweetTrendData.map((x, i) => {
            return (Object.assign({}, x, categoryAverageData[i]))
          });

          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAverageData,
              earnedTweetTrendData
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average earned tweet data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAverageData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  render () {
    return (
      <GeneralSummaryGraph
        title="Tweet Mentions"
        icon={
          <img
            className="mr-2 align-top"
            src="/images/Reviews_Summary_Icon.png"
          />
        }
        metricName="social_mention_count_twitter"
        data={this.state.earnedTweetTrendData}
        dataKey="earned_tweet_count"
        dataLabel="Tweet Mentions"
        scoreType={this.state.earnedtweetScoreType}
        loading={this.state.earnedTweetTrendDataLoading}
        allowDecimals={false}
        showPercentChange={true}
        showAverageData={true}
        averageDataKey="twitter_avg_earned_tweets"
        averageDataLabel="Category Average"
        showTooltip={true}
        contextType="brand"
        contextCategory={this.props.category}
        contextBrand={this.props.brand}
        contextChannel="Social"
        contextProperty="Twitter"
        contextChartName="Tweet Mentions"
        shareClassName="twitter-earned-tweets-img"
      />
    );
  }
};
