import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ConfirmationModal = (props) => {
  return (
    <Modal
      size="md"
      show={props.isOpen}
      onHide={props.handleClose}
    >
      <Modal.Header
        className="bg-bops-blue text-light"
        closeButton
      >
        <Modal.Title>
          {props.confirmationTitle || 'Confirmation'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.confirmationMessage}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          {props.closeConfirmationLabel || 'Close'}
        </Button>
        <Button variant="primary" onClick={props.confirm}>
          {props.confirmButtonLabel || 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
