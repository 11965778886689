import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import CardComponent from '../../common/CardComponent';
import ApiErrorMessage from '../../common/ApiErrorMessage';
import { numberWithCommas } from '../../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class SignificantTerms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      significantTermsData: [],
      significantTermsDataLoading: false,
      significantTermsApiErrorCode: undefined,
      companyName: undefined,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.loadSignificantTermsData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.topicId !== this.props.topicId ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter ||
      prevProps.companyChecked !== this.props.companyChecked ||
      prevProps.portfolioChecked !== this.props.portfolioChecked ||
      prevProps.productChecked !== this.props.productChecked
    ) {
      this.loadSignificantTermsData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  loadSignificantTermsData = () => {
    this.setState(() => ({
      significantTermsDataLoading: true,
      significantTermsApiErrorCode: undefined,
    }));
    const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
    let brandLevelsParam = '';
    if (this.props.companyChecked) {
      brandLevelsParam += '&brand_levels=company';
    }
    if (this.props.portfolioChecked) {
      brandLevelsParam += '&brand_levels=portfolio';
    }
    if (this.props.productChecked) {
      brandLevelsParam += '&brand_levels=product';
    }
    axios.get(
      `${LISTEN_ENDPOINT}/api/twitter-significant-terms?text=${this.props.brandName}&start-date=${formattedStartDate}&end-date=${formattedEndDate}${brandLevelsParam}&limit=10`,
      HEADERS
    ).then(response => {
      const significantTermsData = response.data.significant_terms;
      significantTermsData.sort((a, b) => b.doc_count - a.doc_count);
      if (this.state.isMounted) {
        this.setState(() => ({
          significantTermsData,
          significantTermsDataLoading: false
        }));
      }
    }).catch(error => {
      console.error('Error: failed to fetch twitter significant terms');
      if (this.state.isMounted) {
        this.setState(() => ({
          significantTermsData: [],
          significantTermsDataLoading: false
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
        if (this.state.isMounted) {
          this.setState(() => ({ significantTermsApiErrorCode: error.response.status }));
        }
      }
    });
  };

  toggleOptions = () => {
    this.setState((prevState) => ({ showConfig: !prevState.showConfig }));
  };

  render () {
    return (
      <CardComponent
        title="Significant / Common Terms"
        icon={
          <img
            className="mr-2 align-top"
            src="/images/Significant_Common_Terms_Icon.png"
          />
        }
        description="Significant / Common Terms allows you to view trending significant terms"
        body={
          <div>
            { this.state.significantTermsDataLoading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.significantTermsDataLoading &&
              <div>
                { this.state.significantTermsApiErrorCode &&
                  <ApiErrorMessage
                    statusCode={this.state.significantTermsApiErrorCode}
                  />
                }
                { !this.state.significantTermsApiErrorCode &&
                  <ResponsiveContainer height={350} width="98%">
                    <BarChart
                      data={this.state.significantTermsData}
                      layout="vertical"
                    >
                      <XAxis
                        type="number"
                        tickFormatter={(count) => {
                          return numberWithCommas(count);
                        }}
                      />
                      <YAxis type="category" dataKey="key" width={175}/>
                      <Tooltip cursor={false} />
                      <Bar name="count" dataKey="doc_count" fill="#b3b3b3"/>
                    </BarChart>
                  </ResponsiveContainer>
                }
              </div>
            }
          </div>
        }
        contextType="brand"
        contextCategory={this.props.category}
        contextBrand={this.props.brand}
        contextChannel="Social"
        contextProperty="Twitter"
        contextChartName="Significant / Common Terms"
        contextDateRange={`${this.props.startDateFilter.format('MM/DD/YYYY')} - ${this.props.endDateFilter.format('MM/DD/YYYY')}`}
      />
    );
  }
};
