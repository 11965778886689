import React from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination'

const RateQuestion = (props) => {
  const rates = props.rates || [1,2,3,4,5,6,7];
  return (
    <div>
      <Form.Group className="m-1">
        <Form.Label>
          <b>{props.question}</b>
        </Form.Label>
        <div style={{ width: '295px' }}>
          <div>
            <div className="d-inline-block">
              very dissatisfied
            </div>
            <div className="d-inline-block float-right">
              very satisfied
            </div>
          </div>
          <Pagination>
            { rates.map(rate => {
                return (
                  <Pagination.Item
                    key={`rate${rate}`}
                    className="p-1"
                    active={rate <= props.answer}
                    onClick={() => props.onAnswerChange(rate)}
                    style={{ cursor: 'pointer' }}
                  >
                    {rate}
                  </Pagination.Item>
                )
              })
            }
          </Pagination>
        </div>
      </Form.Group>
    </div>
  )
};

export default RateQuestion;
