export const setStartDateFilter = (startDate) => {
  return (dispatch) => {
    dispatch(updateStartDateFilter(startDate));
  };
};

export const setEndDateFilter = (endDate) => {
  return (dispatch) => {
    dispatch(updateEndDateFilter(endDate));
  };
};

export const updateStartDateFilter = (startDate) => ({
  type: 'UPDATE_START_DATE_FILTER',
  startDate
});

export const updateEndDateFilter = (endDate) => ({
  type: 'UPDATE_END_DATE_FILTER',
  endDate
});
