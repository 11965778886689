export const filterCaseInsensitive = (filter, row) => {
  const id = filter.pivotId || filter.id;
  if (row[id] !== undefined && row[id] !== null) {
    return String(row[id]).toLowerCase().startsWith(filter.value.toLowerCase());
  } else {
    return false;
  }
};

export const getColumnWidth = (rows, accessor, headerText) => {
  const maxWidth = 400;
  const magicSpacing = 10;
  const cellLength = Math.max(
    ...rows.map(row => (`${row[accessor]}` || '').length),
    headerText.length,
  );
  return Math.min(maxWidth, cellLength * magicSpacing);
};
