import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Slider } from '@material-ui/core';
import CategoryKingsTable from './CategoryKingsTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare, faCog } from '@fortawesome/free-solid-svg-icons';
import ClipLoader from "react-spinners/ClipLoader";
import UpdateOverallScoreWeightsModalContainer from '../../containers/categories/UpdateOverallScoreWeightsModalContainer';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import { LISTEN_ENDPOINT, LAMBDA_ENDPOINT, HEADERS } from '../../utils/constants';
import history from '../../routers/history';
import { dispatchReportError } from '../../actions/api/errors';
import html2canvas from 'html2canvas';

export default class CategoryKings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dates: [],
      selectedDate: moment().subtract(2, 'hour').subtract(1, 'day'), // allow time for scores to be run for previous day
      sliderValue: 30,
      categoryBrandScoresData: {},
      categoryBrandScores: [],
      categoryKingBrand: undefined,
      loading: false,
      updateOverallScoreWeightsModalOpen: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.setDates();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      this.fetchCategoryBrandScores();
    }
    if (
      !this.state.loading &&
      prevState.selectedDate !== this.state.selectedDate
    ) {
      const selectedDate = this.state.selectedDate.format('YYYY-MM-DD');
      let categoryBrandScores = [];
      let categoryKingBrand = undefined;
      if (this.state.categoryBrandScoresData[selectedDate]) {
        categoryBrandScores = this.state.categoryBrandScoresData[selectedDate].categoryBrandScores;
        categoryKingBrand = this.state.categoryBrandScoresData[selectedDate].categoryKingBrand;
      }
      this.setState(() => ({
        categoryBrandScores,
        categoryKingBrand
      }));
    }
    if (prevProps.overallScoreWeights !== this.props.overallScoreWeights) {
      const categoryBrandScoresData = {};
      for (const [date, brandScoresData] of Object.entries(this.state.categoryBrandScoresData)) {
        for (const brandScore of brandScoresData.categoryBrandScores) {
          const brandPresenceScore = brandScore.digital_presence_score || 0;
          const brandAttentionScore = brandScore.audience_attention_score || 0;
          const brandReputationScore = brandScore.brand_reputation_score || 0;
          const brandMomentumScore = brandScore.momentum_score || 0;
          // overwrite brand_score by using weights
          brandScore.brand_score = (brandPresenceScore * this.props.overallScoreWeights['Brand Presence']) +
                                   (brandAttentionScore * this.props.overallScoreWeights['Brand Attention']) +
                                   (brandReputationScore * this.props.overallScoreWeights['Brand Reputation']) +
                                   (brandMomentumScore * this.props.overallScoreWeights['Brand Momentum']);
        }
        brandScoresData.categoryBrandScores.sort((a, b) => { return b.brand_score - a.brand_score; });
        let index = 1;
        for (const i in brandScoresData.categoryBrandScores) {
          brandScoresData.categoryBrandScores[i].index = index;
          index++;
        }
        brandScoresData.categoryKingBrand = brandScoresData.categoryBrandScores[0];
        categoryBrandScoresData[date] = brandScoresData;
      }

      this.setState(() => ({
        categoryBrandScoresData,
        categoryBrandScores: categoryBrandScoresData[this.state.selectedDate.format('YYYY-MM-DD')].categoryBrandScores,
        categoryKingBrand: categoryBrandScoresData[this.state.selectedDate.format('YYYY-MM-DD')].categoryKingBrand
      }));
    }
  };

  setDates = () => {
    const dates = [];
    let date = moment().subtract(2, 'hour').subtract(1, 'day');
    let endDate = moment().subtract(2, 'hour').subtract(30, 'day');
    let index = 30;
    while(date.isAfter(endDate)) {
      dates.push({
        value: index,
        label: date.format('MM/DD'),
        date: date
      });
      date = moment.utc(date).subtract(1, 'day');
      index-=1;
    }
    const selectedDate = dates[0].date;

    this.setState(() => ({
      dates,
      selectedDate
    }), () => this.fetchCategoryBrandScores());
  };

  onDateChange = (event, value) => {
    let selectedDate = this.state.selectedDate;
    for (const date of this.state.dates) {
      if (date.value == value) {
        selectedDate = date.date;
      }
    }

    this.setState(() => ({
      selectedDate,
      sliderValue: value
    }));
  };

  getDateText = (value, index) => {
    for (const date of this.state.dates) {
      if (date.value == value) {
        return date.label;
      }
    }
  };

  fetchCategoryBrandScores = () => {
    if (this.props.category) {
      this.setState(() => ({ loading: true }));
      const categoryBrandScoreRequests = [];
      for (const date of this.state.dates) {
        const formattedSelectedDate = date.date.format('YYYY-MM-DD');
        categoryBrandScoreRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/scores/brand-scores-for-category?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&date=${formattedSelectedDate}`,
            HEADERS
          ).then(response => {
            const categoryBrandScores = [];
            for (const [brandName, data] of Object.entries(response.data)) {
              const brandScore = {
                name: brandName,
                ...data
              };

              // this may be removed when brand momentum score is returned by api
              // it is added to push ui before backend is ready
              brandScore.momentum_score = brandScore.momentum_score || 0;

              const brandPresenceScore = brandScore.digital_presence_score || 0;
              const brandAttentionScore = brandScore.audience_attention_score || 0;
              const brandReputationScore = brandScore.brand_reputation_score || 0;
              const brandMomentumScore = brandScore.momentum_score || 0;
              // overwrite brand_score by using weights
              brandScore.brand_score = (brandPresenceScore * this.props.overallScoreWeights['Brand Presence']) +
                                       (brandAttentionScore * this.props.overallScoreWeights['Brand Attention']) +
                                       (brandReputationScore * this.props.overallScoreWeights['Brand Reputation']) +
                                       (brandMomentumScore * this.props.overallScoreWeights['Brand Momentum']);
              categoryBrandScores.push(brandScore);
            }
            categoryBrandScores.sort((a, b) => { return b.brand_score - a.brand_score; });
            let index = 1;
            for (const i in categoryBrandScores) {
              categoryBrandScores[i].index = index;
              index++;
            }

            return {
              date: formattedSelectedDate,
              categoryKingBrand: categoryBrandScores[0],
              categoryBrandScores
            };
          }).catch(error => {
            console.error('Error: unable to fetch brand scores for category...');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return {
              date: formattedSelectedDate,
              categoryKingBrand: undefined,
              categoryBrandScores: []
            }
          })
        );
      }

      Promise.all(categoryBrandScoreRequests).then(responses => {
        const categoryBrandScoresData = {};
        for (const response of responses) {
          categoryBrandScoresData[response.date] = response;
        }

        let categoryBrandScores = [];
        let categoryKingBrand;
        const selectedDate = this.state.selectedDate.format('YYYY-MM-DD');
        if (categoryBrandScoresData[selectedDate]) {
          categoryBrandScores = categoryBrandScoresData[selectedDate].categoryBrandScores;
          categoryKingBrand = categoryBrandScoresData[selectedDate].categoryKingBrand;
        }

        // if latest day does not have data, log error
        const latestDay = moment().subtract(2, 'hour').subtract(1, 'day').format('YYYY-MM-DD');
        if (
          categoryBrandScoresData[latestDay] &&
          process.env.NODE_ENV === 'production' &&
          (
            !categoryBrandScoresData[latestDay].categoryBrandScores ||
            categoryBrandScoresData[latestDay].categoryBrandScores.length === 0
          )
        ) {
          // create github issue and log error
          let body = `Message: Got 0 brand_scores for "${this.props.category.name}" category - ${latestDay}\n\n`
          body += `User Id: ${this.props.user.id || ''}\n`;
          body += `User Email: ${this.props.user.email || ''}\n`;
          if (this.props.user.status) {
            body += `Company Status: ${this.props.user.status}`;
          }
          const issue = {
            repo: 'LegendaryAI/Operations',
            title: `Got 0 brand_scores for "${this.props.category.name}" category - ${latestDay}`,
            body: body,
            labels: ['bug'],
            assignee: 'shrikant14'
          };

          axios.post(
            `${LAMBDA_ENDPOINT}/github-issue`,
            issue,
            HEADERS
          );
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryBrandScoresData,
            categoryBrandScores,
            categoryKingBrand,
            loading: false
          }));
        }
      });
    }
  };

  captureCategoryKingTableImage = () => {
    const categoryKing = document.getElementById('categoryKing');
    html2canvas(categoryKing, { useCORS: true }).then(canvas => {
      const dataUrl = canvas.toDataURL('image/png');
      var link = document.createElement('a');
      document.body.appendChild(link);
      link.setAttribute('href', dataUrl);
      const timestamp = moment().format('YYYY-MM-DD');
      link.setAttribute('download', `category-king-img-${timestamp}.png`);
      link.click();
    }).catch(error => {
      console.log('Error: failed to generate canvas...');
    });
  };

  openUpdateOverallScoreWeightsModal = () => {
    this.setState(() => ({ updateOverallScoreWeightsModalOpen: true }));
  };

  closeUpdateOverallScoreWeightsModal = () => {
    this.setState(() => ({ updateOverallScoreWeightsModalOpen: false }));
  };

  render () {
    return (
      <div className="p-4">
        <div className="mt-2">
          <Slider
            valueLabelDisplay="on"
            valueLabelFormat={this.getDateText}
            value={this.state.sliderValue}
            min={2}
            max={30}
            onChange={this.onDateChange}
            style={{ zIndex: '100' }}
          />
        </div>
        { this.state.loading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.loading &&
          <div>
            <CategoryKingsTable
              companyId={this.props.user.customerId}
              categoryKingBrand={this.state.categoryKingBrand}
              category={this.props.category}
              categoryBrandScores={this.state.categoryBrandScores}
            />
            <SharePopUpContainer
              shareElementId="categoryKing"
              elementLabel="category-king-img"
              position="bottom"
              contextType="category"
              contextCategory={this.props.category}
              contextBrand={null}
              contextProperty="Category Ranks"
              contextChartName="Category Ranks"
              contextDateRange={`${this.state.selectedDate.format('MM/DD/YYYY')}`}
            />
            <button
              className="float-right px-1 py-0 bg-white border border-top-0 rounded-bottom"
              onClick={this.openUpdateOverallScoreWeightsModal}
            >
              <FontAwesomeIcon
                icon={faCog}
                color="#6c757d"
              />
            </button>
          </div>
        }

        <UpdateOverallScoreWeightsModalContainer
          isOpen={this.state.updateOverallScoreWeightsModalOpen}
          handleClose={this.closeUpdateOverallScoreWeightsModal}
        />
      </div>
    );
  }
};
