import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import UrlPreview from '../common/UrlPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faRetweet, faHeart,  } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryTopTweets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryTopTweets: [],
      categoryTopTweetsLoading: false
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchCategoryTopTweets();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.fetchCategoryTopTweets();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  fetchCategoryTopTweets = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryTopTweetsLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-top-tweets?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const categoryTopTweets = response.data;
        if (this.state.isMounted) {
          this.setState((prevState) => ({
            categoryTopTweets,
            categoryTopTweetsLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch category top tweets.');
        if (this.state.isMounted) {
          this.setState((prevState) => ({
            categoryTopTweets: [],
            categoryTopTweetsLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  parseURLs = (text) => {
    return text.replace(/[A-Za-z]+:\/\/[A-Za-z0-9-_]+\.[A-Za-z0-9-_:%&~?/.=]+/g, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };

  parseUsernames = (text) => {
    return text.replace(/[@]+[A-Za-z0-9-_]+/g, (handle) => {
      var username = handle.replace('@','');
      return `<a href="http://twitter.com/${username}" target="_blank">${handle}</a>`;
    });
  };

  parseHashtags = (text) => {
    return text.replace(/[#]+[A-Za-z0-9-_]+/g, function(hashtag) {
      var hashtagUrlEncoded = hashtag.replace('#','%23');
      return `<a href="http://twitter.com/search?q=${hashtagUrlEncoded}" target="_blank">${hashtag}</a>`;
    });
  };

  parseTextForHTMLReplacements = (text) => {
    text = this.parseURLs(text);
    text = this.parseUsernames(text);
    text = this.parseHashtags(text);
    return text;
  };

  getLastUrl = (text) => {
    const urlMatches = text.match(/[A-Za-z]+:\/\/[A-Za-z0-9-_]+\.[A-Za-z0-9-_:%&~?/.=]+/g);
    if (urlMatches && urlMatches.length > 0) {
      return urlMatches[urlMatches.length - 1];
    } else {
      return;
    }
  };

  render () {
    return (
      <div>
        <h5 className="mb-4 p-2 bg-bops-blue text-light">
          Top Content
        </h5>
        { this.state.categoryTopTweetsLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.categoryTopTweetsLoading &&
          <Row>
            { this.state.categoryTopTweets.map((comment, i) => {
                let mediaUrl;
                let urlPreview;
                if (comment.tweet.media) {
                  mediaUrl = comment.tweet.media[0].media_url;
                } else {
                  urlPreview = this.getLastUrl(comment.tweet.tweet_text);
                }

                return (
                  <Col
                    key={`c-${comment.id}`}
                    className="mb-4"
                    xs={12}
                    md={6}
                  >
                    <Card
                      id={`twit-top-post-${i}`}
                      className="h-100"
                    >
                      <Card.Header className="bg-white">
                        <div>
                          { comment.tweet.twit_user.profile_image_url &&
                            <div className="d-inline-block mr-3 mt-2 align-top">
                              <img
                                className="rounded-circle"
                                src={comment.tweet.twit_user.profile_image_url.replace('http://', 'https://')}
                                alt=""
                                crossOrigin="anonymous"
                                style={{ width: '30px' }}
                              />
                            </div>
                          }
                          <div className="d-inline-block">
                            <div className="font-weight-bold">
                              {comment.tweet.twit_user.name}
                            </div>
                            <span>
                              {`@${comment.tweet.twit_user.screen_name}`}
                            </span>
                            <div className="text-muted"  style={{ fontSize: '.75rem' }}>
                              {moment(comment.tweet.tweet_created_at).format('MMM D')}
                            </div>
                          </div>
                          <div className="d-inline-block float-right">
                            <SharePopUpContainer
                              shareElementId={`twit-top-post-${i}`}
                              elementLabel={`twit-top-post-${i}-img`}
                              position="top"
                              contextType="category"
                              contextCategory={this.props.category}
                              contextBrand={null}
                              contextChannel="Twitter"
                              contextProperty="Top Content"
                              contextChartName="Top Content"
                            />
                          </div>
                          <div className="d-inline-block mr-2 float-right">
                            <FontAwesomeIcon
                              className="mt-1 float-right"
                              icon={faLink}
                              color="#08a0e9"
                              onClick={() => {
                                window.open(`https://twitter.com/user/status/${comment.tweet.tweet_id}`, '_blank');
                              }}
                              style={{ cursor: 'pointer' }}
                            />
                          </div>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <div
                          dangerouslySetInnerHTML={{__html: this.parseTextForHTMLReplacements(comment.tweet.tweet_text)}}
                        />
                        { mediaUrl &&
                          <div className="mt-2 text-center">
                            <img
                              src={mediaUrl}
                              alt=""
                              crossOrigin="anonymous"
                              width="100%"
                            />
                          </div>
                        }
                        { urlPreview &&
                          <div className="my-2">
                            <UrlPreview url={urlPreview} />
                          </div>
                        }
                      </Card.Body>
                      <Card.Footer>
                        <Row>
                          <Col>
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faRetweet}
                              color="#6c757d"
                            />
                            {comment.tweet.tweet_retweet_count}
                          </Col>
                          <Col>
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faHeart}
                              color="#6c757d"
                            />
                            {comment.tweet.tweet_favorite_count}
                          </Col>
                        </Row>
                      </Card.Footer>
                    </Card>
                  </Col>
                )
              })
            }
          </Row>
        }
      </div>
    );
  }
};
