import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import LoginContainer from '../../../containers/common/LoginContainer';
import DateRangePicker from '../../common/DateRangePicker';
import HubspotDeals from './HubspotDeals';
import HubspotLandingPages from './HubspotLandingPages';
import HubspotOutboundEmails from './HubspotOutboundEmails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';

export default class Hubspot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      category: undefined,
      brand: undefined,
      startDate: moment().subtract(90, 'days').startOf('day'),
      endDate: moment().startOf('day').endOf('day'),
      selectedTab: 'deals',
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.myCategoryBrands !== this.props.myCategoryBrands &&
      this.props.myCategoryBrands.length > 0
    ) {
      this.setState(() => ({
        category: this.props.myCategoryBrands[0].category,
        brand: this.props.myCategoryBrands[0].brand,
      }));
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  updateDates = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  };

  render () {
    return (
      <div className="p-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        { (this.props.user.id && !this.props.companySetupChecklist.hubspot_setup) &&
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              minHeight: 'calc(100vh - 200px)',
              maxHeight: 'calc(100vh - 200px)',
            }}
          >
            <div className="d-inline-block">
              <Alert variant="primary">
                HubSpot is not setup for your company. You can get setup <Link to="/content-collectors/hubspot">here</Link>.
              </Alert>
            </div>
          </div>
        }
        { (this.props.user.customerId && this.props.companySetupChecklist.hubspot_setup) &&
          <div>
            <h4>
              <FontAwesomeIcon
                className="mr-2"
                icon={faHubspot}
                color="#f57722"
                style={{ fontSize: '2rem' }}
              />
              HubSpot
            </h4>
            <hr />
            <div className="mb-2 p-2 bg-bops-blue text-light">
              <span
                className="mr-2"
              >Date Range</span>
              <DateRangePicker
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                updateDates={this.updateDates}
              />
            </div>
            <Tabs
              activekey={this.state.selectedTab}
              onSelect={(selectedTab) => this.setState(() => ({ selectedTab }))}
            >
              <Tab
                eventKey="deals"
                title="Deals"
              >
                <div
                  className="pre-scrollable"
                  style={{
                    minHeight: 'calc(100vh - 250px)',
                    maxHeight: 'calc(100vh - 250px)',
                    overflow: 'auto',
                    overflowX: 'hidden'
                  }}
                >
                  <HubspotDeals
                    user={this.props.user}
                    category={this.state.category}
                    brand={this.state.brand}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                  />
                </div>
              </Tab>
              <Tab
                eventKey="conversionPages"
                title="Conversion Pages"
              >
                <div
                  className="pre-scrollable"
                  style={{
                    minHeight: 'calc(100vh - 250px)',
                    maxHeight: 'calc(100vh - 250px)',
                    overflow: 'auto',
                    overflowX: 'hidden'
                  }}
                >
                  <HubspotLandingPages
                    user={this.props.user}
                    category={this.state.category}
                    brand={this.state.brand}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                  />
                </div>
              </Tab>
              <Tab
                eventKey="outboundEmails"
                title="Outbound Emails"
              >
                <div
                  className="pre-scrollable"
                  style={{
                    minHeight: 'calc(100vh - 250px)',
                    maxHeight: 'calc(100vh - 250px)',
                    overflow: 'auto',
                    overflowX: 'hidden'
                  }}
                >
                  <HubspotOutboundEmails
                    user={this.props.user}
                    category={this.state.category}
                    brand={this.state.brand}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        }
      </div>
    );
  }
};
