import React from 'react';
import Badge from 'react-bootstrap/Badge';
import CategoryNewsHighlights from './CategoryNewsHighlights';
import CategoryNewsDetails from './CategoryNewsDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { createUserActivityLog } from '../../utils/users';
import history from '../../routers/history';

export default class CategoryNews extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'highlights'
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let selectedTab = hash.indexOf('-')>0 ? hash.substring(hash.indexOf('-')+1) : 'highlights';
      this.setState(() => ({ selectedTab }));
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  changeSelectedTab = (selectedTab, subTabName) => {
    history.push(`#news-${selectedTab}`);
    this.setState(() => ({ selectedTab }));
    if (subTabName) {
      createUserActivityLog(
        this.props.user,
        `Category Tabs: News: ${subTabName}`,
        this.props.category,
      )
    }
  }

  render () {
    return (
      <div className="p-4">
        <div className="mb-4">
          <button
            id="categoryNewsHighlightsButton"
            type="button"
            className= { this.state.selectedTab === 'highlights' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('highlights', 'Highlights')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faChartLine} />
            </div>
            <div className="d-inline-block">
              Highlights
            </div>
          </button>
          <button
            id="categoryNewsDetailsButton"
            type="button"
            className= { this.state.selectedTab === 'details' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('details', 'Category News')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faNewspaper} />
            </div>
            <div className="d-inline-block">
              Category News
              <Badge
                className="ml-1 align-middle"
                variant="warning"
                pill
              >
                Beta
              </Badge>
            </div>
          </button>
        </div>
        <div
          className="pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 200px)',
            maxHeight: 'calc(100vh - 200px)',
            overflow: 'auto',
            overflowX: 'hidden'
          }}
        >
          { this.state.selectedTab === 'highlights' &&
            <CategoryNewsHighlights
              user={this.props.user}
              sixMonths={this.props.sixMonths}
              selectedMonth={this.props.selectedMonth}
              updateSelectedMonth={this.props.updateSelectedMonth}
              categoryId={this.props.categoryId}
              category={this.props.category}
            />
          }
          { this.state.selectedTab === 'details' &&
            <CategoryNewsDetails
              user={this.props.user}
              sixMonths={this.props.sixMonths}
              selectedMonth={this.props.selectedMonth}
              updateSelectedMonth={this.props.updateSelectedMonth}
              categoryId={this.props.categoryId}
              category={this.props.category}
            />
          }
        </div>
      </div>
    );
  }
};
