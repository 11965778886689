import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import ClipLoader from 'react-spinners/ClipLoader';
import Parser from 'rss-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faLongArrowAltUp, faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';
import LoginContainer from '../../containers/common/LoginContainer';
import { numberWithCommas, round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';
import history from '../../routers/history';

export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'alerts',
      user: {},
      blogs: [],
      setupAlerts: [],
      setupAlertsLoading: true,
      welcomeImage: 'https://user-images.githubusercontent.com/1353365/87235709-4fda9780-c3a4-11ea-81a3-e29467fd4b96.png',
      brandOpsBlogRssUrl: 'https://blog.brandops.io/rss.xml'
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    // this.fetchBlogs();
    if (this.props.user.id) {
      this.fetchUser();
      this.props.fetchAlerts(this.props.user.customerId);
      this.fetchSetupAlerts();
    }
    if (this.props.location.hash) {
      // remove hash and set selected tab
      const hash = this.props.location.hash.replace('#', '');
      const selectedTab = hash.indexOf('-')>0 ? hash.substring(0, hash.indexOf('-')) : hash;
      this.setState(() => ({ selectedTab }));
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.id !== this.props.user.id) {
      this.fetchUser();
    }
    if (prevProps.user.customerId !== this.props.user.customerId) {
      this.props.fetchAlerts(this.props.user.customerId);
      this.fetchSetupAlerts();
    }
  };

  fetchBlogs = () => {
    const parser = new Parser({ headers: HEADERS.headers });
    const proxyBlogRssUrl = `${LISTEN_ENDPOINT}/api/url-proxy?url=${this.state.brandOpsBlogRssUrl}`;
    parser.parseURL(proxyBlogRssUrl, (error, feed) => {
      let blogs = [];
      if (error) {
        console.error('Failed to preview RSS.');
      } else if (feed) {
        if (feed.items.length > 6) {
          blogs = feed.items.slice(0, 6);
        } else {
          blogs = feed.items;
        }
      }

      // get blog image from content
      const htmlParser = new DOMParser();
      for (const blog of blogs) {
        try {
          const contentHtml = htmlParser.parseFromString(blog.content, 'text/html');
          blog.image = contentHtml.getElementsByClassName('hs-featured-image')[0].src;
        } catch (error) {
          blog.image = undefined;
        }
      }

      if (this.state.isMounted) {
        this.setState(() => ({
          blogs,
          loading: false
        }));
      }
    });
  };

  fetchUser = () => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/customer-users/${this.props.user.id}`,
      HEADERS
    ).then(response => {
      const user = response.data;
      this.setState(() => ({ user }));
    }).catch(error => {
      console.error('Error: failed to fetch customers');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  fetchSetupAlerts = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({ setupAlertsLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/brand-alert?added_by_customer_id=${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        const setupAlerts = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            setupAlerts,
            setupAlertsLoading: false,
          }));
        }
      }).catch(error => {
        if (this.state.isMounted) {
          this.setState(() => ({
            setupAlerts: [],
            setupAlertsLoading: false,
          }));
        }
        console.error('Error: unable to fetch setup alerts');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onAlertClick = (alert) => {
    if (
      alert &&
      alert.brand_alert &&
      alert.brand_alert.category_id &&
      alert.brand_alert.product_brand_id
    ) {
      let pathname;
      const alertCategoryId = `${alert.brand_alert.category_type}-${alert.brand_alert.category_id}`;
      for (const c of this.props.categories) {
        if (alertCategoryId === `${c.category_type}-${c.id}`) {
          for (const b of c.product_brands) {
            if (alert.brand_alert.product_brand_id === b.id) {
              pathname = `/category/${encodeURIComponent(c.name)}/brand/${encodeURIComponent(b.name)}`;
              break;
            }
          }
          if (pathname) {
            break;
          }
        }
      }
      if (pathname) {
        // add hash route
        if (alert.brand_alert.alert_on === 'brand_score') {
          pathname += '#analysis-pillars-brandScore';
        } else if (alert.brand_alert.alert_on === 'brand_stage_score') {
          switch (alert.brand_alert.identifier) {
            case 1:
              pathname += '#analysis-pillars-brandPresence';
              break;
            case 3:
              pathname += '#analysis-pillars-brandAttention';
              break;
            case 5:
              pathname += '#analysis-pillars-brandReputation';
              break;
            case 6:
              pathname += '#analysis-pillars-brandMomentum';
              break;
          }
        } else if (alert.brand_alert.alert_on === 'brand_metric') {
          switch (alert.brand_alert.channel_category_name) {
            case 'Social':
              pathname += '#social';
              if (alert.brand_alert.channel_site_name) {
                pathname += `-${alert.brand_alert.channel_site_name.charAt(0).toLowerCase() + alert.brand_alert.channel_site_name.slice(1)}`;
              }
              break;
            case 'Product Reviews':
              pathname += '#reviews';
              break;
            case 'Employer Reviews':
              pathname += '#company-employerReviews';
              break;
            case 'Blogs':
              pathname += '#web-blog';
              break;
            case 'News':
              pathname += '#news';
              break;
            case 'Videos':
              pathname += '#mediacast-youtubeActivity';
              break;
            case 'Email Campaigns':
              pathname += '#email-emailCampaigns';
              break;
            case 'Podcast':
              pathname += '#mediacast-podcast';
              break;
            case 'Webinar':
              pathname += '#mediacast-webinar';
              break;
            case 'Web Analytics':
              pathname += '#web-webAnalytics';
              break;
            case 'Web':
              pathname += '#web-highlights';
              break;
            case 'Surveys via BrandOps':
              pathname += '#surveys';
              break;
            case 'Emails':
              pathname += '#email-highlights';
              break;
            case 'Ad Intelligence':
              pathname += '#ads';
              break;
            case 'Search Engine Results':
              pathname += '#search-highlights';
              break;
            case 'Affirmation':
              pathname += '#affirmation';
              break;
          }
        }
        history.push(pathname);
      }
    }
  };

  render () {
    return (
      <div>
        { !(this.props.user && this.props.user.id) &&
          <div className="p-4">
            <LoginContainer />
          </div>
        }
        { (this.props.user && this.props.user.id) &&
          <div className="p-4 bg-white">
            <h4>
              Hello {this.state.user.first_name}!
              <img
                className="float-right"
                src={this.state.welcomeImage}
                alt=""
                crossOrigin="anonymous"
                style={{ width: '100px' }}
              />
            </h4>
            <p>Welcome to BrandOps.</p>
            <div className="float-right">
            </div>
            <hr className="my-2 bg-primary" />
            <Tabs
              id="home-tabs"
              className="pt-2"
              activeKey={this.state.selectedTab}
              onSelect={selectedTab => {
                // update hash for tab routing
                history.push(`#${selectedTab}`);
                this.setState({ selectedTab });
              }}
            >
              {/*
                <Tab
                  eventKey="new"
                  title="What's New"
                >
                  <div
                    className="pre-scrollable"
                    style={{
                      maxHeight: 'calc(100vh - 275px)',
                      overflowX: 'hidden'
                    }}
                  >
                    <Row>
                      { this.state.blogs.map((blog, i) => {
                          return (
                            <Col xs={12} md={4} className="mb-4" key={`bops-b-${i}`}>
                              <Card
                                className="h-100"
                                style={{ cursor: 'pointer' }}
                                onClick={() => window.open(blog.link, '_blank')}
                              >
                                <Card.Body>
                                  { blog.image &&
                                    <img
                                      src={blog.image}
                                      alt=""
                                      crossOrigin="anonymous"
                                      width="100%"
                                    />
                                  }
                                  { blog.title &&
                                    <div
                                      style={{
                                        fontSize: '1.2rem',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      {blog.title}
                                      <hr className="mt-0 mb-2"/>
                                    </div>
                                  }
                                  <div>
                                    {blog.contentSnippet}
                                  </div>
                                </Card.Body>
                                <Card.Footer
                                  className="bg-white"
                                  style={{ fontSize: '.875rem' }}
                                >
                                  <div>
                                    {blog.author}
                                  </div>
                                  <div>
                                    {moment(blog.date).format('MM/DD/YYYY')}
                                  </div>
                                </Card.Footer>
                              </Card>
                            </Col>
                          )
                        })
                      }
                    </Row>
                  </div>
                </Tab>
              */}
              <Tab
                eventKey="alerts"
                title="Alerts"
              >
                <div
                  className="pre-scrollable py-2"
                  style={{
                    maxHeight: 'calc(100vh - 275px)',
                    overflow: 'auto',
                    overflowX: 'hidden'
                  }}
                >
                  <div>
                    <Button
                      variant="success"
                      onClick={() => history.push('/user-settings/alerts')}
                      size="sm"
                    >
                      Add / Remove Alerts
                    </Button>
                    <div className="d-inline-block ml-3 align-middle">
                      { this.state.setupAlertsLoading &&
                        <ClipLoader size={15}/>
                      }
                      { !this.state.setupAlertsLoading &&
                        <React.Fragment>
                          { this.state.setupAlerts.length === 1 &&
                            <span>1 alert is active</span>
                          }
                          { this.state.setupAlerts.length !== 1 &&
                            <span>
                              {`${this.state.setupAlerts.length} alerts are active`}
                            </span>
                          }
                        </React.Fragment>
                      }
                    </div>
                  </div>
                  <Table>
                    <thead>
                      <tr>
                        <th className="border-top-0 border-bottom-0">
                          Brand
                        </th>
                        <th className="border-top-0 border-bottom-0">
                          Metric
                        </th>
                        <th className="border-top-0 border-bottom-0">
                          Trigger
                        </th>
                        <th className="border-top-0 border-bottom-0">
                          Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      { this.props.alerts.length === 0 &&
                        <tr>
                          <td>No alerts</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      }
                      { this.props.alerts.map((alert, i) => {
                          return (
                            <tr
                              key={`alert${i}`}
                              onClick={() => this.onAlertClick(alert)}
                              style={{ cursor: 'pointer' }}
                            >
                              <td>
                                <FontAwesomeIcon
                                  className="mr-2"
                                  icon={faExclamationTriangle}
                                />
                                {alert.brand_alert.product_brand_name}
                              </td>
                              <td>
                                {alert.brand_alert.metric_label}
                              </td>
                              <td>
                                { alert.score_change &&
                                  <FontAwesomeIcon
                                    className="mr-1"
                                    icon={
                                      alert.score_change > 0 ?
                                        faLongArrowAltUp:
                                        faLongArrowAltDown
                                    }
                                    color={alert.score_change_type === 'good' ? 'green' : 'red'}
                                  />
                                }
                                {`${numberWithCommas(round(alert.score_change, 0))}% from ${numberWithCommas(alert.old_score)} to ${numberWithCommas(alert.new_score)}`}
                              </td>
                              <td>
                                {moment.utc(alert.date_sent).format('MM/DD/YYYY')}
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </div>
              </Tab>
              <Tab
                eventKey="documentation"
                title="Documentation"
              >
                <iframe
                  className="border rounded"
                  src="https://docs.google.com/document/d/e/2PACX-1vQrgpFXRQGQXN2rCjz-QFM-J4-cklGLYEBanrC5lR8HNCSbBn7B4T_JB6XA-0r8g-7j1ljq7GZ_oeGi/pub?embedded=true"
                  width="100%"
                  style={{
                    minHeight: 'calc(100vh - 275px)',
                    maxHeight: 'calc(100vh - 275px)',
                  }}
                ></iframe>
              </Tab>
            </Tabs>
          </div>
        }
      </div>
    );
  }
};
