import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import ClipLoader from 'react-spinners/ClipLoader';
import SharePopUpContainer from '../../../containers/share/SharePopUpContainer';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  LabelList,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';
import HubspotStackedGraph from './HubspotStackedGraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas, round } from '../../../utils/numbers';
import { sortAlphabeticalByKey } from '../../../utils/sorts';
import { shorthandMonth } from '../../../utils/dates';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';
import { colors } from '../../../utils/graphs';

export default class HubspotDeals extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      graphType: 'Closed Won/Lost',
      selectedMonth: moment().format('MMMM YYYY'),
      dealsSummary: {},
      dealsByClosed: [],
      dealsByCreated: [],
      dealClosedBars: [],
      dealsClosedByMonthMap: {},
      dealsLoading: false,
    };
  }

  componentDidMount() {
    let selectedMonth = this.state.selectedMonth
    if (this.props.endDate) {
      selectedMonth = this.props.endDate.format('MMMM');
    }
    this.setState(() => ({
      selectedMonth,
      isMounted: true
    }));
    if (this.props.user.customerId) {
      this.fetchDeals();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      this.fetchDeals();
      if (prevProps.endDate !== this.props.endDate) {
        this.setState(() => ({
          selectedMonth: this.props.endDate.format('MMMM YYYY')
        }));
      }
    }

  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  fetchDeals = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({ dealsLoading: true }));
      const formattedStartDate = this.props.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.props.endDate.format('YYYY-MM-DD HH:mm:ss');
      const dealRequests = [];
      // get deals by closed
      dealRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/company-hubspot-deals?company_id=${this.props.user.customerId}&date_type=closed&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
          HEADERS
        ).then(response => {
          const dealsByClosed = response.data;
          return { dealsByClosed }
        }).catch(error => {
          console.error('Error: failed to fetch company deals by closed...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { dealsByClosed: [] }
        })
      );

      // get deals by created
      dealRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/company-hubspot-deals?company_id=${this.props.user.customerId}&date_type=created&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
          HEADERS
        ).then(response => {
          const dealsByCreated = response.data;
          return { dealsByCreated }
        }).catch(error => {
          console.error('Error: failed to fetch company deals by created...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { dealsByCreated: [] }
        })
      );

      Promise.all(dealRequests).then(responses => {
        let dealsByClosed = [];
        let dealsByCreated = [];
        for (const response of responses) {
          if (response.dealsByClosed) {
            dealsByClosed = response.dealsByClosed;
          } else if (response.dealsByCreated) {
            dealsByCreated = response.dealsByCreated;
          }
        }

        // group deals closed
        const dealClosedBarsSet = new Set();
        const dealsClosedByMonthMap = {};
        // loop months and add to map
        let dateByClosed = moment(this.props.startDate);
        dealsClosedByMonthMap[dateByClosed.format('MMMM YYYY')] = [];
        while (dateByClosed.isBefore(this.props.endDate)) {
          dateByClosed = dateByClosed.add(1, 'month');
          dealsClosedByMonthMap[dateByClosed.format('MMMM YYYY')] = [];
        }
        for (const deal of dealsByClosed) {
          const dealMonth = moment(deal.close_date).format('MMMM YYYY')
          deal.deal_stage_label = this.dealStageFormatter(deal.deal_stage);
          dealsClosedByMonthMap[dealMonth].push(deal);

          if (!dealClosedBarsSet.has(deal.deal_stage)) {
            dealClosedBarsSet.add(deal.deal_stage)
          }
        }

        const dealsSummary = {
          deal_count: 0,
          wins: 0,
          losses: 0,
          revenue_win: 0,
        };
        const dealsClosedByMonth = [];
        for (const [date, deals] of Object.entries(dealsClosedByMonthMap)) {
          // sort deals by deal name
          deals.sort(sortAlphabeticalByKey('deal_name'));
          const dateSplit = date.split(' ');
          let closedwon = 0;
          let closedlost = 0;
          let revenue = 0;
          for (const deal of deals) {
            dealsSummary.deal_count += 1;
            if (deal.deal_stage === 'closedwon') {
              dealsSummary.wins += 1;
              dealsSummary.revenue_win += deal.amount;
              revenue += deal.amount;
              closedwon += 1;
            }
            if (deal.deal_stage === 'closedlost') {
              dealsSummary.losses += 1;
              closedlost += 1;
            }
          }
          dealsClosedByMonth.push({
            closedwon,
            closedlost,
            revenue,
            date,
          });
        }

        // convert deal closed bars set into stacked graph dars array
        const dealClosedBars = [];
        dealClosedBarsSet.forEach((dealStage) => {
          const name = this.dealStageFormatter(dealStage);
          const color = this.getDealStageColor(dealStage);
          dealClosedBars.push({
            key: dealStage,
            name,
            color,
          })
        });

        // group deals created
        const dealsCreatedByMonthMap = {};
        // loop months and add to map
        let dateByCreated = moment(this.props.startDate);
        dealsCreatedByMonthMap[dateByCreated.format('MMMM YYYY')] = [];
        while (dateByCreated.isBefore(this.props.endDate)) {
          dateByCreated = dateByCreated.add(1, 'month');
          dealsCreatedByMonthMap[dateByCreated.format('MMMM YYYY')] = [];
        }
        for (const deal of dealsByCreated) {
          const dealMonth = moment(deal.obj_create_date).format('MMMM YYYY')
          deal.deal_stage_label = this.dealStageFormatter(deal.deal_stage);
          if (!dealsCreatedByMonthMap[dealMonth]) {
            dealsCreatedByMonthMap[dealMonth] = [deal];
          } else {
            dealsCreatedByMonthMap[dealMonth].push(deal);
          }
        }

        const dealsCreatedByMonth = [];
        for (const [date, deals] of Object.entries(dealsCreatedByMonthMap)) {
          const deals_created = deals.length;
          dealsCreatedByMonth.push({
            deals_created,
            date
          });
        }

        const dealsRevenueByMonth = [];

        if (this.state.isMounted) {
          this.setState(() => ({
            dealsSummary,
            dealsByClosed,
            dealsClosedByMonthMap,
            dealsClosedByMonth,
            dealClosedBars,
            dealsByCreated,
            dealsCreatedByMonthMap,
            dealsCreatedByMonth,
            dealsLoading: false,
          }));
        }
      });
    }
  };

  switchGraphType = (graphType) => {
    if (graphType !== this.state.graphType) {
      this.setState(() => ({ graphType }));
    }
  };

  onMonthSelect = (event, t2) => {
    if (event.activeLabel && event.activeLabel !== this.state.selectedMonth) {
      this.setState(() => ({ selectedMonth: event.activeLabel }));
    }
  };

  dealStageFormatter = (dealStage) => {
    switch (dealStage) {
      case 'closedlost':
        return 'Lost - Closed';
      case 'closedwon':
        return 'Won - Closed';
      case 'presentationscheduled':
        return 'Presentation Scheduled';
      case 'qualifiedtobuy':
        return 'Qualified to Buy';
      case 'appointmentscheduled':
        return 'Appointment Scheduled';
      default:
        return dealStage;
    }
  };

  getDealStageColor = (dealStage) => {
    switch (dealStage) {
      case 'closedlost':
        return colors[0];
      case 'closedwon':
        return colors[1];
      case 'presentationscheduled':
        return colors[2];
      case 'qualifiedtobuy':
        return colors[3];
      case 'appointmentscheduled':
        return colors[4];
      default:
        return colors[0];
    }
  }

  render () {
    return (
      <div className="py-2">
        <Row>
          <Col xs={12} lg={6}>
            <div className="mb-4">
              <Card id="hubspot-deals-by-stage" className="h-100">
                <Card.Header className="bg-white">
                  <FontAwesomeIcon
                    className="mr-2 text-bops"
                    icon={faHandshake}
                    color="#4fa2f3"
                  />
                  <b style={{ fontSize: '1.2rem'}}>
                    Deals
                  </b>
                  <div className="d-inline-block float-right">
                    <SharePopUpContainer
                      shareElementId="hubspot-deals"
                      elementLabel={`hubspot-deals-img`}
                      position="top"
                      contextType="brand"
                      contextCategory={this.props.category}
                      contextBrand={this.props.brand}
                      contextChannel="Emails"
                      contextChartName="HubSpot Deals"
                    />
                  </div>
                </Card.Header>
                <Card.Body>
                  <div>
                    <div>
                      <Form.Check
                        label="Closed Won/Lost"
                        type="radio"
                        checked={this.state.graphType == "Closed Won/Lost" ? true : false}
                        onChange={() => this.switchGraphType("Closed Won/Lost")}
                        inline
                      />
                      <Form.Check
                        label="Deals Created"
                        type="radio"
                        checked={this.state.graphType == "Deals Created" ? true : false}
                        onChange={() => this.switchGraphType("Deals Created")}
                        inline
                      />
                      <Form.Check
                        label="Revenue"
                        type="radio"
                        checked={this.state.graphType == "Revenue" ? true : false}
                        onChange={() => this.switchGraphType("Revenue")}
                        inline
                      />
                    </div>
                    { this.state.dealsLoading &&
                      <div className="text-center">
                        <ClipLoader size={100}/>
                      </div>
                    }
                    { !this.state.dealsLoading &&
                      <div>
                        { this.state.graphType === 'Closed Won/Lost' &&
                          <ResponsiveContainer width="99%" height={300}>
                            <BarChart
                              data={this.state.dealsClosedByMonth}
                              barSize={50}
                              margin={{
                                top: 16,
                                left: 10
                              }}
                              onClick={this.onMonthSelect}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis
                                dataKey="date"
                                tickFormatter={(date) => {
                                  const month = String(date).split(' ')[0];
                                  return shorthandMonth(month);
                                }}
                              />
                              <YAxis
                                tick={{ fontSize: 12 }}
                                tickFormatter={(value) => {
                                  return numberWithCommas(value);
                                }}
                                allowDecimals={false}
                              />
                              <Tooltip
                                formatter={(value) => numberWithCommas(value)}
                                cursor={{ fill: 'transparent' }}
                                wrapperStyle={{ zIndex: 1005 }}
                              />
                              { this.state.dealClosedBars.map((barData, i) => {
                                  return (
                                    <Bar
                                      key={`hs-d-b-${i}`}
                                      name={barData.name}
                                      dataKey={barData.key}
                                      stackId="a"
                                      fill={barData.color || colors[i % colors.length]}
                                    />
                                  )
                                })
                              }
                            </BarChart>
                          </ResponsiveContainer>
                        }
                        { this.state.graphType === 'Deals Created' &&
                          <ResponsiveContainer width="99%" height={300}>
                            <BarChart
                              data={this.state.dealsCreatedByMonth}
                              barSize={50}
                              margin={{
                                top: 16,
                                left: 10
                              }}
                              onClick={this.onMonthSelect}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis
                                dataKey="date"
                                tickFormatter={(date) => {
                                  const month = String(date).split(' ')[0];
                                  return shorthandMonth(month);
                                }}
                              />
                              <YAxis
                                tick={{ fontSize: 12 }}
                                tickFormatter={(value) => {
                                  return numberWithCommas(value);
                                }}
                                allowDecimals={false}
                              />
                              <Tooltip
                                formatter={(value) => numberWithCommas(value)}
                                cursor={{ fill: 'transparent' }}
                                wrapperStyle={{ zIndex: 1005 }}
                              />
                              <Bar
                                name="Deals Created"
                                dataKey="deals_created"
                                fill={colors[0]}
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        }
                        { this.state.graphType === 'Revenue' &&
                          <ResponsiveContainer width="99%" height={300}>
                            <BarChart
                              data={this.state.dealsClosedByMonth}
                              barSize={50}
                              margin={{
                                top: 16,
                                left: 10
                              }}
                              onClick={this.onMonthSelect}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis
                                dataKey="date"
                                tickFormatter={(date) => {
                                  const month = String(date).split(' ')[0];
                                  return shorthandMonth(month);
                                }}
                              />
                              <YAxis
                                tick={{ fontSize: 12 }}
                                tickFormatter={(value) => {
                                  return `$${numberWithCommas(value)}`;
                                }}
                                allowDecimals={false}
                              />
                              <Tooltip
                                formatter={(value) => `$${numberWithCommas(value)}`}
                                cursor={{ fill: 'transparent' }}
                                wrapperStyle={{ zIndex: 1005 }}
                              />
                              <Bar
                                name="Revenue"
                                dataKey="revenue"
                                fill={colors[0]}
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        }
                      </div>
                    }
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            { (this.state.dealsLoading || this.state.dealsLoading) &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { (!this.state.dealsLoading && !this.state.dealsLoading) &&
              <div>
                <div className="mb-2">
                  <Card>
                    <Card.Header className="bg-white">
                      <FontAwesomeIcon
                        className="mr-2 text-bops"
                        icon={faClipboardList}
                        color="#4fa2f3"
                      />
                      <b style={{ fontSize: '1.2rem'}}>
                        Summary
                      </b>
                    </Card.Header>
                    <Card.Body>
                      <table>
                        <tbody>
                          <tr>
                            <td className="text-right pr-2">
                              Deal Count:
                            </td>
                            <td>
                              { this.state.dealsSummary.deal_count != null ?
                                  numberWithCommas(this.state.dealsSummary.deal_count) :
                                  '-'
                              }
                            </td>
                          </tr>
                          <tr>
                            <td className="text-right pr-2">
                              Wins:
                            </td>
                            <td>
                              { this.state.dealsSummary.wins != null ?
                                  numberWithCommas(this.state.dealsSummary.wins) :
                                  '-'
                              }
                            </td>
                          </tr>
                          <tr>
                            <td className="text-right pr-2">
                              Losses:
                            </td>
                            <td>
                              { this.state.dealsSummary.losses != null ?
                                  numberWithCommas(this.state.dealsSummary.losses) :
                                  '-'
                              }
                            </td>
                          </tr>
                          <tr>
                            <td className="text-right pr-2">
                              Win Rate:
                            </td>
                            <td>
                              { this.state.dealsSummary.deal_count != null ?
                                  `${round((this.state.dealsSummary.wins / this.state.dealsSummary.deal_count) * 100, 1)}%` :
                                  '-'
                              }
                            </td>
                          </tr>
                          <tr>
                            <td className="text-right pr-2">
                              Revenue Win:
                            </td>
                            <td>
                              { this.state.dealsSummary.revenue_win != null ?
                                  `$${numberWithCommas(this.state.dealsSummary.revenue_win)}` :
                                  '-'
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Card.Body>
                  </Card>
                </div>
                <div
                  className="py-1 text-center bg-bops-blue text-light"
                  style={{ fontWeight: 'bold' }}
                >
                  {this.state.selectedMonth}
                </div>
                <div className="mt-1">
                  { this.state.dealsClosedByMonthMap[this.state.selectedMonth] &&
                    <div>
                      { this.state.dealsClosedByMonthMap[this.state.selectedMonth].map((deal, i) => {
                          return (
                            <div key={`hs-md-d-${i}`} className="mb-1">
                              <Row>
                                <Col xs={5}>
                                  {deal.deal_name}
                                </Col>
                                <Col xs={4}>
                                  { deal.amount != null ?
                                      `$${numberWithCommas(deal.amount)}` :
                                      '-'
                                  }
                                </Col>
                                <Col xs={3}>
                                  <div className="text-center">
                                  </div>
                                  {deal.deal_stage_label}
                                </Col>
                              </Row>
                            </div>
                          );
                        })
                      }
                    </div>
                  }
                </div>
              </div>
            }
          </Col>
        </Row>
      </div>
    );
  }
};
