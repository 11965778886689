import React from 'react';
import Form from 'react-bootstrap/Form';

const YesNoQuestion = (props) => {
  return (
    <div>
      <Form.Group>
        <Form.Label>
          <b>{props.question}</b>
        </Form.Label>
        <Form.Control
          as="select"
          style={{ width: '75px' }}
          value={props.answer}
          onChange={props.onAnswerChange}
        >
          <option>Yes</option>
          <option>No</option>
        </Form.Control>
      </Form.Group>
    </div>
  )
};

export default YesNoQuestion;
