import { useRef, useEffect, useState } from 'react';
import Vizzu from 'vizzu';
import VizzuModule from 'vizzu/dist/cvizzu.wasm';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../../common/CardComponent';

Vizzu.options({ wasmUrl: VizzuModule });

const stackedAreaSettings = {
  config: {
    channels: {
        x: { set: ['Month'] },
        y: {
          set: ['Name', 'Value'],
          range: {
            min: 'auto',
            max: 'auto'
          }
        },
        color: { set: ['Name'] }
    },
    geometry: 'area',
    align: 'min',
    split: false
  },
  style: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontSize: '14px',
    'plot.xAxis.label.angle': '-10deg'
  }
};

function CategorySynonymousMentionGraph(props) {
  const chartId = `csnc-${Math.random().toString(36).substring(7)}`;
  const canvasRef = useRef();
  const chartRef = useRef();

  useEffect(() => {
    if (!canvasRef.current) return;
    if (props.data && props.data.records && props.data.records.length > 0) {
      chartRef.current = new Vizzu(canvasRef.current, { data: props.data });
      chartRef.current.initializing.then((chart) => {
        // prevent logo from being drawn
        chart.on('logo-draw', (event) => {
          event.preventDefault();
        });
        chart.feature('tooltip', true);
        // stacked area chart
        chart.animate(stackedAreaSettings);
      });
    }
  }, [props.data]);

  return (
    <CardComponent
      title={props.title}
      icon={props.icon}
      description={props.description}
      shareClassName={props.shareClassName}
      body={
        <div>
          { props.loading &&
            <div className="text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !props.loading &&
            <div>
              <canvas
                className="w-100"
                ref={canvasRef}
                style={{ height: "300px" }}
              />
            </div>
          }
        </div>
      }
    />
  );
}

export default CategorySynonymousMentionGraph;
