import React from 'react';
import Button from 'react-bootstrap/Button';
import SelectSingleQuestion from '../../surveys/SelectSingleQuestion';
import SelectMultipleQuestion from '../../surveys/SelectMultipleQuestion';
import FreeFormQuestion from '../../surveys/FreeFormQuestion';
import RateQuestion from '../../surveys/RateQuestion';
import YesNoQuestion from '../../surveys/YesNoQuestion';
import TrueFalseQuestion from '../../surveys/TrueFalseQuestion';
import { beautifyVariables } from '../../../utils/strings';

export default class SurveyPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  };

  onPreviousButtonClick = () => {
    this.props.onChangeSurveyStep('setup');
  };

  onNextButtonClick = () => {
    this.props.onChangeSurveyStep('prepare_launch');
  };

  render () {
    return (
      <div>
        <div
          className="pre-scrollable"
          style={{
            maxHeight: `calc(100vh - 400px)`,
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          { this.props.questions.map((question, i) => {
              if (question.type === 'Select Single') {
                return (
                  <SelectSingleQuestion
                    key={`s${this.props.selectedSurvey.id}-q${i}`}
                    questionId={question.question_id}
                    question={beautifyVariables(question.resolved_question)}
                    options={question.multiple_choices}
                  />
                )
              } else if (question.type === 'Select Multiple') {
                return (
                  <SelectMultipleQuestion
                    key={`s${this.props.selectedSurvey.id}-q${i}`}
                    questionId={question.question_id}
                    question={beautifyVariables(question.resolved_question)}
                    options={question.multiple_choices}
                  />
                )
              } else if (question.type === 'Free form') {
                return (
                  <FreeFormQuestion
                    key={`s${this.props.selectedSurvey.id}-q${i}`}
                    questionId={question.question_id}
                    question={beautifyVariables(question.resolved_question)}
                  />
                )
              } else if (question.type === 'Rate(1-7)') {
                return (
                  <RateQuestion
                    key={`s${this.props.selectedSurvey.id}-q${i}`}
                    questionId={question.question_id}
                    question={beautifyVariables(question.resolved_question)}
                    rates={[1,2,3,4,5,6,7]}
                    answer={0}
                    onAnswerChange={() => {}}
                  />
                )
              } else if (question.type === 'Yes/No') {
                return (
                  <YesNoQuestion
                    key={`s${this.props.selectedSurvey.id}-q${i}`}
                    questionId={question.question_id}
                    question={beautifyVariables(question.resolved_question)}
                  />
                )
              } else if (question.type === 'True/False') {
                return (
                  <TrueFalseQuestion
                    key={`s${this.props.selectedSurvey.id}-q${i}`}
                    questionId={question.question_id}
                    question={beautifyVariables(question.resolved_question)}
                  />
                )
              }
            })
          }
        </div>
        <div className="mt-2">
          <Button
            variant="outline-secondary"
            onClick={this.onPreviousButtonClick}
          >
            Previous
          </Button>
          <Button
            className="ml-2"
            variant="success"
            onClick={this.onNextButtonClick}
          >
            Next
          </Button>
        </div>
      </div>
    );
  }
};
