import React from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import LoginContainer from '../../../containers/common/LoginContainer';
import AdminAuthenticationMessage from '../../common/AdminAuthenticationMessage';
import { isAuthenticatedAdmin } from '../../../utils/auth';
import DeleteConfirmationModal from '../../common/DeleteConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';
import history from '../../../routers/history';

export default class HubspotSetup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hubspotCompanyAccount: undefined,
      hubspotCompanyAccountLoading: true,
      privateAppToken: '',
      reenterPrivateAppToken: false,
      errors: {},
      errorMessage: '',
      deleteConfirmationModalOpen: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchHubspotCompanyAccount();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.customerId !== this.props.user.customerId) {
      this.fetchHubspotCompanyAccount();
    }
  }

  fetchHubspotCompanyAccount = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({
        hubspotCompanyAccountLoading: true,
        reenterPrivateAppToken: false,
      }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/hubspot-company-accounts?company_id=${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        let hubspotCompanyAccount = undefined;
        let privateAppToken = '';
        if (Array.isArray(response.data) && response.data.length > 0) {
          hubspotCompanyAccount = response.data[0];
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            hubspotCompanyAccount,
            hubspotCompanyAccountLoading: false,
            privateAppToken,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch hubspot company account');
        if (this.state.isMounted) {
          this.setState(() => ({
            hubspotCompanyAccount: undefined,
            hubspotCompanyAccountLoading: false,
            privateAppToken: '',
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onPrivateAppTokenChange = (event) => {
    const privateAppToken = event.currentTarget.value;
    this.setState(() => ({ privateAppToken }));
  };

  isValidForm = () => {
    const errors = {};
    let errorMessage = '';
    if (
      !this.state.hubspotCompanyAccount ||
      (this.state.hubspotCompanyAccount && this.state.reenterPrivateAppToken)
    ) {
      if (!this.state.privateAppToken || this.state.privateAppToken.trim() === '') {
        errors.privateAppToken = true;
      }
    }

    this.setState(() => ({
      errors,
      errorMessage,
    }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  saveHubspotCompanyAccount = () => {
    if (this.isValidForm()) {
      const hubspotCompanyAccount = {
        company_id: this.props.user.customerId,
      };

      if (this.state.hubspotCompanyAccount) {
        // Update
        if (this.state.reenterPrivateAppToken) {
          hubspotCompanyAccount.private_app_token = this.state.privateAppToken;
        }

        axios.put(
          `${LISTEN_ENDPOINT}/api/hubspot-company-accounts/${this.state.hubspotCompanyAccount.id}`,
          hubspotCompanyAccount,
          HEADERS
        ).then(response => {
          this.fetchHubspotCompanyAccount();
        }).catch(error => {
          console.error('Error: failed to update hubspot company account');
          this.setState(() => ({
            errorMessage: 'Unable to update account at this time. Please try again later.'
          }));
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      } else {
        // Create
        hubspotCompanyAccount.private_app_token = this.state.privateAppToken;

        axios.post(
          `${LISTEN_ENDPOINT}/api/hubspot-company-accounts`,
          hubspotCompanyAccount,
          HEADERS
        ).then(response => {
          this.fetchHubspotCompanyAccount();
        }).catch(error => {
          console.error('Error: failed to create hubspot company account');
          this.setState(() => ({
            errorMessage: 'Unable to create account at this time. Please try again later.'
          }));
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  openDeleteConfirmationModal = () => {
    this.setState(() => ({ deleteConfirmationModalOpen: true }));
  };

  closeDeleteConfirmationModal = () => {
    this.setState(() => ({ deleteConfirmationModalOpen: false }));
  };

  deleteHubspotCompanyAccount = () => {
    if (this.state.hubspotCompanyAccount) {
      axios.delete(
        `${LISTEN_ENDPOINT}/api/hubspot-company-accounts/${this.state.hubspotCompanyAccount.id}`,
        HEADERS
      ).then(response => {
        if (this.state.isMounted) {
          this.fetchHubspotCompanyAccount();
          this.closeDeleteConfirmationModal();
        }
      }).catch(error => {
        console.error('Error: failed to delete hubspot company account');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="m-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        <AdminAuthenticationMessage user={this.props.user}/>
        { isAuthenticatedAdmin(this.props.user) &&
          <div>
            <div
              className="border rounded p-4"
              style={{ backgroundColor: '#e9ecef' }}
            >
              <div className="d-inline-block pr-2">
                <FontAwesomeIcon
                  icon={faHubspot}
                  size="2x"
                  color="#454d54"
                />
              </div>
              <div className="d-inline-block">
                <h4>HubSpot</h4>
              </div>
              <p>Connect your HubSpot instance.</p>
              <hr className="my-4" />
              <p className="mb-0">
                Gain landing page insights by connecting HubSpot in BrandOps.
              </p>
            </div>
            <div className="mt-4">
              <Row>
                <Col>
                  <div
                    className="pre-scrollable"
                    style={{
                      maxHeight: 'calc(100vh - 300px)',
                      overflow: 'auto',
                      overflowX: 'hidden'
                    }}
                  >
                    <Form>
                      <Form.Group>
                        <Form.Label>Private App Token</Form.Label>
                        { (!this.state.hubspotCompanyAccount || this.state.reenterPrivateAppToken) &&
                          <Form.Control
                            type="text"
                            value={this.state.privateAppToken}
                            onChange={this.onPrivateAppTokenChange}
                            isInvalid={this.state.errors.privateAppToken}
                          />
                        }
                        { (this.state.hubspotCompanyAccount && !this.state.reenterPrivateAppToken) &&
                          <div>
                            <div className="d-inline-block">
                              ***************
                            </div>
                            <div className="d-inline-block ml-3">
                              <Button
                                variant="warning"
                                onClick={() => {
                                  this.setState(() => ({
                                    reenterPrivateAppToken: true,
                                    privateAppToken: '',
                                  }))
                                }}
                                size="sm"
                              >
                                Re-enter Private App Token
                              </Button>
                            </div>
                          </div>
                        }
                      </Form.Group>
                      <Button
                        variant="primary"
                        onClick={this.saveHubspotCompanyAccount}
                        disabled={!this.props.user.customerId}
                      >
                        Save
                      </Button>
                      { this.state.hubspotCompanyAccount &&
                        <Button
                          className="ml-4"
                          variant="danger"
                          onClick={this.openDeleteConfirmationModal}
                        >
                          Delete
                        </Button>
                      }
                    </Form>
                  </div>
                </Col>
                <Col>
                  <Card>
                    <Card.Header className="bg-white font-weight-bold">
                      Setup
                    </Card.Header>
                    <Card.Body
                      className="pre-scrollable"
                      style={{
                        maxHeight: 'calc(100vh - 350px)',
                        overflow: 'auto'
                      }}
                    >
                      <div>
                        <h5>Getting Started</h5>
                        <ul>
                          <li>
                            <a href="https://developers.hubspot.com/docs/api/overview" target="_blank">
                              Learning about HubSpot API
                            </a>
                          </li>
                          <li>
                            <a href="https://developers.hubspot.com/docs/api/private-apps" target="_blank">
                              Learning HubSpot Private Apps & Tokens
                            </a>
                          </li>
                        </ul>
                        <h5>Quickstart</h5>
                        <p>
                          Follow these steps <a href="https://developers.hubspot.com/docs/api/private-apps" target="_blank">here</a> for a more detailed walkthrough to create a private app and token.
                        </p>
                        <ol>
                          <li>
                            Head to HubSpot Settings
                            <FontAwesomeIcon className="mx-1" icon={faArrowRight}/>
                            Account Setup
                            <FontAwesomeIcon className="mx-1" icon={faArrowRight}/>
                            Private Apps
                          </li>
                          <li>
                            Create Private App and provide "Read" access under Scopes
                          </li>
                          <li>
                            Provide the Private App Token found under "View access token"
                          </li>
                        </ol>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>

            <DeleteConfirmationModal
              isOpen={this.state.deleteConfirmationModalOpen}
              handleClose={this.closeDeleteConfirmationModal}
              deleteMessage="Are you sure you would like to delete the current HubSpot setup?"
              deleteResource={this.deleteHubspotCompanyAccount}
            />
          </div>
        }
      </div>
    );
  }
};
