import { connect } from 'react-redux';
import ErrorBoundary from '../../components/common/ErrorBoundary';
import { setShowRefreshAlert } from '../../actions/refreshAlert/refreshAlert';

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setShowRefreshAlert: (showRefreshAlert) => dispatch(setShowRefreshAlert(showRefreshAlert)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
