import React from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';

export default class WebAnalyticsLandingPageRegexForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      landingPageRegexId: undefined,
      regex: '',
      tested: false,
      testLoading: false,
      regexUrlMatches: [],
      testErrorMessage: '',
      errors: {}
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.landingPageRegex) {
      this.setState(() => ({
        landingPageRegexId: this.props.landingPageRegex.id,
        regex: this.props.landingPageRegex.regex,
        tested: false,
        regexUrlMatches: [],
      }));
    } else {
      this.setState(() => ({
        landingPageRegexId: undefined,
        regex: '',
        tested: false,
        regexUrlMatches: [],
      }));
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  onRegexChange = (event) => {
    const regex = event.currentTarget.value;
    this.setState(() => ({
      regex,
      tested: false,
    }));
  };

  validForm = () => {
    const errors = {};
    if (!this.state.regex || this.state.regex.trim() === '')
      errors.regex = 'Please provide landing page regex.';

    this.setState(() => ({ errors }));
    if (Object.keys(errors).length === 0)
      return true;
    else
      return false;
  };

  testRegex = () => {
    if (this.validForm()) {
      this.setState(() => ({
        testLoading: true,
        regexUrlMatches: [],
        testErrorMessage: ''
      }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/ga-regex-url-match?company_id=${this.props.companyId}&regex=${this.state.regex}`,
        HEADERS
      ).then(response => {
        const regexUrlMatches = response.data;
        if (regexUrlMatches.length > 0) {
          if (this.state.isMounted) {
            this.setState(() => ({
              regexUrlMatches,
              tested: true,
              testLoading: false,
            }));
          }
        } else {
          this.setState(() => ({
            tested: false,
            testLoading: false,
            testErrorMessage: 'Regex did not match any landing pages.'
          }));
        }
      }).catch(error => {
        console.error('Error: failed to test landing page regex...');
        if (this.state.isMounted) {
          this.setState(() => ({
            errors: { regex: 'Failed to test landing page regex.' },
            testLoading: false,
          }));
        }
      });
    }
  };

  saveLandingPageRegex = () => {
    if (this.state.tested) {
      if (this.props.landingPageRegex) {
        // Update
        axios.put(
          `${CONTENT_SCRAPING_ENDPOINT}/api/ga-landing-page-regex/${this.props.landingPageRegex.id}`,
          { regex: this.state.regex },
          HEADERS
        ).then(response => {
          this.props.refreshLandingPageRegexes();
          this.props.removeLandingPageRegexForm(this.props.id);
        }).catch(error => {
          console.log('Error: unable to update landing page regex');
          if (this.state.isMounted) {
            this.setState(() => ({
              errors: { regex: 'Unable to update landing page regex.' }
            }));
          }
        });
      } else {
        const landingPageRegex = {
          ga_company_account_id: this.props.gaCompanyAccount.id,
          regex: this.state.regex,
          company_id: this.props.companyId
        };
        // Create
        axios.post(
          `${CONTENT_SCRAPING_ENDPOINT}/api/ga-landing-page-regex`,
          landingPageRegex,
          HEADERS
        ).then(response => {
          this.props.refreshLandingPageRegexes();
          this.props.removeLandingPageRegexForm(this.props.id);
        }).catch(error => {
          console.log('Error: unable to create landing page regex');
          if (this.state.isMounted) {
            this.setState(() => ({
              errors: { regex: 'Unable to create landing page regex.' }
            }));
          }
        });
      }
    }
  };

  render () {
    return (
      <div>
        <Form.Row>
          <Col>
            <Form.Control
              type="text"
              value={this.state.regex}
              onChange={this.onRegexChange}
              isInvalid={this.state.errors.regex}
              placeholder="Landing Page Regex"
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  this.saveLandingPageRegex();
                }
              }}
              size="sm"
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errors.regex}
            </Form.Control.Feedback>
          </Col>
          <Col style={{ flex: '0 0 140px' }} className="text-right">
            <Button
              className="px-1 py-0 mr-2"
              size="sm"
              variant="info"
              onClick={this.testRegex}
              disabled={
                this.state.testLoading ||
                this.state.regex.trim() === ''
              }
            >
              { this.state.testLoading &&
                <span>
                  <ClipLoader
                    size={10}
                    color="ffffff"
                  />
                </span>
              }
              { !this.state.testLoading &&
                <span>Test</span>
              }
            </Button>
            <Button
              className="px-1 py-0 mr-2"
              size="sm"
              variant="success"
              onClick={this.saveLandingPageRegex}
              disabled={!this.state.tested}
            >
              <FontAwesomeIcon icon={faCheck}/>
            </Button>
            <Button
              className="px-1 py-0"
              size="sm"
              variant="danger"
              onClick={() => this.props.removeLandingPageRegexForm(this.props.id)}
            >
              <FontAwesomeIcon icon={faTimes}/>
            </Button>
          </Col>
        </Form.Row>
        { this.state.regexUrlMatches.length > 0 &&
          <ul className="pl-3 mb-0">
            { this.state.regexUrlMatches.map((regexMatch, i) => {
                return (
                  <li key={`lprf-${this.props.id}-rm-${i}`}>
                    {regexMatch}
                  </li>
                )
              })
            }
          </ul>
        }
        <Alert
          className="mt-2 py-1 px-2"
          show={Boolean(this.state.testErrorMessage)}
          variant="danger"
        >
          {this.state.testErrorMessage}
        </Alert>
      </div>
    );
  }
};
