import { connect } from 'react-redux';
import AlertRouter from '../../components/alerts/AlertRouter';

const mapStateToProps = (state) => ({
  user: state.user,
  categories: state.categories
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(AlertRouter);
