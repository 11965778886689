import React from 'react';
import {
  ScatterChart,
  Scatter,
  Dot,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer
} from 'recharts';
import SharePopUpContainer from '../../../containers/share/SharePopUpContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const CustomizedShape = (props) => {
  const {cx, cy, fill, name} = props;
  return (
    <g>
      <Dot cx={cx} cy={cy} r={5} fill={fill} />
      <g transform={`translate(${cx},${cy})`}>
        <text stroke="white" strokeWidth="0.4em" x={0} y={-12} dy={4} textAnchor="middle">{name}</text>
        <text fill="black" x={0} y={-12} dy={4} textAnchor="middle">{name}</text>
      </g>
    </g>
   );
};

const ExcessShareOfEverythingGraph = (props) => (
  <div>
    <div id="esoeGraph" className="bg-white border rounded">
      <ResponsiveContainer width="99%" height={500}>
        <ScatterChart
          margin={{ top: 25, right: 40, bottom: 30, left: 40 }}
        >
          <CartesianGrid />
          <XAxis
            type="number"
            dataKey="marketShare"
            name="Market Share"
            domain={[0, 50]}
          >
            <Label
              value="Current Market Share (%)"
              position="bottom"
              style={{
                fontWeight: 'bold'
              }}
            />
          </XAxis>
          <YAxis
            type="number"
            dataKey={props.yAxisKey}
            name="Share Of"
            domain={[0, 50]}
          >
            <Label
              value={props.yAxisLabel}
              position="left"
              offset={10}
              angle={-90}
              style={{
                textAnchor: 'middle',
                fontWeight: 'bold'
              }}
            />
          </YAxis>
          <Scatter
            name="Brand"
            data={props.data}
            fill="#007bff"
            shape={<CustomizedShape />}
          />
          <ReferenceLine
            strokeDasharray="3 3"
            segment={[{ x: 0, y: 0 }, { x: 50, y: 50 }]}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
    <SharePopUpContainer
      shareElementId="esoeGraph"
      elementLabel="esoe-graph-img"
      position="bottom"
      contextType="category"
      contextCategory={props.category}
      contextBrand={null}
      contextProperty="Share of Everything"
      contextChartName="Summary"
      contextDateRange={`${props.startDate.format('MM/DD/YYYY')} - ${props.endDate.format('MM/DD/YYYY')}`}
    />
  </div>
);

export default ExcessShareOfEverythingGraph;
