import React from 'react';
import axios from 'axios';
import ShareOfChart from '../graphs/ShareOfChart';
import { round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryShareOfCommentsGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shareofGraphData: {},
      categoryShareOfComments: [],
      categoryShareOfCommentsLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.loadCategoryShareOfComments(this.props.categoryType, this.props.categoryId);
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.categoryType !== this.props.categoryType ||
      prevProps.categoryId !== this.props.categoryId
    ) {
      this.loadCategoryShareOfComments(this.props.categoryType, this.props.categoryId);
    }
  }

  loadCategoryShareOfComments = (categoryType, categoryId) => {
    this.setState(() => ({ categoryShareOfCommentsLoading: true }));
    let typeString = '';
    switch (this.props.commentsType) {
      case 'Promoter':
        typeString = 'promoter-';
        break;
      case 'Detractor':
        typeString = 'detractor-';
        break;
    }
    axios.get(
      `${LISTEN_ENDPOINT}/api/category-share-of-${typeString}comments?category_type=${categoryType}&category_id=${categoryId}`,
      HEADERS
    ).then(response => {
      const shareOfGraphData = {
        series: [
          { name: 'Month', type: 'dimension' },
          { name: 'Brand', type: 'dimension' },
          { name: 'Value', type: 'measure' },
        ],
        records: [],
      };
      const categoryShareOfComments = [];
      for (const monthStats of response.data) {
        const commentsMonthData = { month: monthStats.month };
        let monthTotalComments = 0;

        // get totals for percent
        for (const pb of monthStats.product_brands) {
          monthTotalComments += pb.comments_count;
          // insert graph data
          shareOfGraphData.records.push([
            monthStats.month,
            pb.product_brand_name,
            pb.comments_count,
          ]);
        }

        // loop again to determine %
        for (const pb of monthStats.product_brands) {
          let commentsPercent = 0;
          if (monthTotalComments > 0) {
            commentsPercent = round((pb.comments_count/monthTotalComments)*100, 1);
          }
          commentsMonthData[pb.product_brand_name] = {
            count: pb.comments_count,
            percent: commentsPercent
          }
        }
        categoryShareOfComments.push(commentsMonthData);
      }

      if (this.state.isMounted) {
        this.setState(() => ({
          shareOfGraphData,
          categoryShareOfComments,
          categoryShareOfCommentsLoading: false,
        }));
      }
    }).catch(error => {
      console.error(`Error: unable to load share of ${this.props.commentsType} comments...`)
      this.setState(() => ({
        shareOfGraphData: {},
        categoryShareOfComments: [],
        categoryShareOfCommentsLoading: false,
      }));
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  render () {
    return (
      <ShareOfChart
        title={this.props.title}
        icon={this.props.icon}
        description={this.props.description}
        data={this.state.shareOfGraphData}
        legendData={this.state.categoryShareOfComments}
        loading={this.state.categoryShareOfCommentsLoading}
        contextType="category"
        contextCategory={this.props.category}
        contextBrand={null}
        contextChannel="Social"
        contextChartName={this.props.title}
        shareClassName={this.props.shareClassName}
      />
    );
  }
};
