import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ShareOfChart from '../graphs/ShareOfChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faEye } from '@fortawesome/free-solid-svg-icons';
import { round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryVideoShareOfGraphs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryVideoShareOfData: [],
      subscribersShareOfGraphData: {},
      categorySubscribersShareOfData: [],
      viewsShareOfGraphData: {},
      categoryViewsShareOfData: [],
      categoryVideoShareOfDataLoading: false,
      netNewViewsShareOfGraphData: {},
      categoryNetNewViewsShareOfData: [],
      categoryNetNewViewsShareOfDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getCategoryVideoShareOfData();
    this.getCategoryVideoNetNewViewsShareOfData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.getCategoryVideoShareOfData();
    }
  }

  getCategoryVideoShareOfData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryVideoShareOfDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-share-of-video-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const categoryVideoShareOfData = response.data;
        const subscribersShareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const viewsShareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const categorySubscribersShareOfData = [];
        const categoryViewsShareOfData = [];
        for (const monthStats of categoryVideoShareOfData) {
          const subscribersMonthData = { month: monthStats.month };
          let monthTotalSubscribers = 0;
          const viewsMonthData = { month: monthStats.month };
          let monthTotalViews = 0;

          // get totals for percent
          for (const pb of monthStats.product_brands) {
            monthTotalSubscribers += pb.subscribers_count;
            monthTotalViews += pb.views_count;
            // insert graph data
            subscribersShareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.subscribers_count || 0,
            ]);
            viewsShareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.views_count || 0,
            ]);
          }

          // loop again to determine %
          for (const pb of monthStats.product_brands) {
            let subscribersPercent = 0;
            if (monthTotalSubscribers > 0) {
              subscribersPercent = round((pb.subscribers_count/monthTotalSubscribers)*100, 1);
            }
            subscribersMonthData[pb.product_brand_name] = {
              count: pb.subscribers_count,
              percent: subscribersPercent
            }

            let viewsPercent = 0;
            if (monthTotalViews > 0) {
              viewsPercent = round((pb.views_count/monthTotalViews)*100, 1);
            }
            viewsMonthData[pb.product_brand_name] = {
              count: pb.views_count,
              percent: viewsPercent
            }
          }
          categorySubscribersShareOfData.push(subscribersMonthData);
          categoryViewsShareOfData.push(viewsMonthData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryVideoShareOfData,
            subscribersShareOfGraphData,
            categorySubscribersShareOfData,
            viewsShareOfGraphData,
            categoryViewsShareOfData,
            categoryVideoShareOfDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch category share of video stats...');
        if (this.state.isMounted) {
          this.setState(() => ({
            categoryVideoShareOfData: [],
            subscribersShareOfGraphData: {},
            categorySubscribersShareOfData: [],
            viewsShareOfGraphData: {},
            categoryViewsShareOfData: [],
            categoryVideoShareOfDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  getCategoryVideoNetNewViewsShareOfData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryNetNewViewsShareOfDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-youtube-share-of-net-views?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const netNewViewsShareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const categoryNetNewViewsShareOfData = [];
        for (const monthStats of response.data) {
          const netNewViewsMonthData = { month: monthStats.month };
          let monthNetNewViews = 0;

          // get totals for percent
          for (const pb of monthStats.product_brands) {
            monthNetNewViews += pb.net_views;
            // insert graph data
            netNewViewsShareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.net_views || 0,
            ]);
          }

          // loop again to determine %
          for (const pb of monthStats.product_brands) {
            let netNewViewsPercent = 0;
            if (monthNetNewViews > 0) {
              netNewViewsPercent = round((pb.net_views/monthNetNewViews)*100, 1);
            }
            netNewViewsMonthData[pb.product_brand_name] = {
              count: pb.net_views,
              percent: netNewViewsPercent
            }
          }
          categoryNetNewViewsShareOfData.push(netNewViewsMonthData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryNetNewViewsShareOfData,
            netNewViewsShareOfGraphData,
            categoryNetNewViewsShareOfDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch category net new views data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            categoryNetNewViewsShareOfData: [],
            netNewViewsShareOfGraphData: {},
            categoryNetNewViewsShareOfDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        <ShareOfChart
          title="Share of Total Subscribers"
          icon={
            <FontAwesomeIcon
              className="mr-2"
              icon={faUserPlus}
              color="#4fa2f3"
            />
          }
          description="Share of Total Subscribers allows you to visualize the number of subscribers by brand for the category"
          data={this.state.subscribersShareOfGraphData}
          legendData={this.state.categorySubscribersShareOfData}
          loading={this.state.categoryVideoShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Youtube"
          contextChartName="Share of Total Subscribers"
        />
        <ShareOfChart
          title="Share of Total Views"
          icon={
            <FontAwesomeIcon
              className="mr-2"
              icon={faUserPlus}
              color="#4fa2f3"
            />
          }
          description="Share of Total Views allows you to visualize the number of total views gained by brand for the category"
          data={this.state.viewsShareOfGraphData}
          legendData={this.state.categoryViewsShareOfData}
          loading={this.state.categoryVideoShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Youtube"
          contextChartName="Share of Total Views"
        />
        <ShareOfChart
          title="Share of Net New Views"
          icon={
            <FontAwesomeIcon
              className="mr-2"
              icon={faEye}
              color="#4fa2f3"
            />
          }
          data={this.state.netNewViewsShareOfGraphData}
          legendData={this.state.categoryNetNewViewsShareOfData}
          loading={this.state.categoryNetNewViewsShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Youtube"
          contextChartName="Share of Net New Views"
        />
      </div>
    );
  }
};
