import { connect } from 'react-redux';
import OAuthHandler from '../../components/oauth/OAuthHandler';

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(OAuthHandler);
