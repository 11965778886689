import { connect } from 'react-redux';
import MetricDistributionSummaryModal from '../../components/metrics/MetricDistributionSummaryModal';

const mapStateToProps = (state) => ({
  user: state.user,
  customerId: state.user.customerId,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(MetricDistributionSummaryModal);
