import { connect } from 'react-redux';
import CategoryDashboard from '../../components/categories/CategoryDashboard';
import { setHeaderLabels } from '../../actions/header/headerLabels';
import { updateSelectedMonth } from '../../actions/filters/selectedMonth';

const mapStateToProps = (state) => ({
  user: state.user,
  customer: state.customer,
  categories: state.categories,
  sixMonths: state.sixMonths,
  selectedMonth: state.selectedMonth,
  overallScoreWeights: state.overallScoreWeights,
});

const mapDispatchToProps = (dispatch) => ({
  setHeaderLabels: (categoryLabel, brandLabel) => dispatch(setHeaderLabels(categoryLabel, brandLabel)),
  updateSelectedMonth: (selectedMonth) => dispatch(updateSelectedMonth(selectedMonth)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDashboard);
