import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GeneralSummaryGraph from '../graphs/GeneralSummaryGraph';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class ProxyEmailsSummaryGraphs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailsDiscoveredData: [],
      emailsDiscoveredScoreType: undefined,
      emailsDiscoveredDataLoading: false,
      categoryAverageData: [],
    }
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchEmailsDiscoveredData();
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.brand !== this.props.brand ||
      prevProps.allMonthsChecked !== this.props.allMonthsChecked
    ) {
      this.fetchEmailsDiscoveredData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchEmailsDiscoveredData = () => {
    if (this.props.brand) {
      this.setState(() => ({ emailsDiscoveredDataLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/email-discovered-trend?product_brand_id=${this.props.brand.id}${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let emailsDiscoveredData = response.data.monthly_stats;
        const emailsDiscoveredScoreType = response.data.emails_discovered ? response.data.emails_discovered : undefined;
        // merge category average data into original data source
        emailsDiscoveredData = emailsDiscoveredData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAverageData[i]))
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            emailsDiscoveredData,
            emailsDiscoveredScoreType,
            emailsDiscoveredDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch proxy emails discovered...');
        if (this.state.isMounted) {
          this.setState(() => ({
            emailsDiscoveredData: [],
            emailsDiscoveredScoreType: undefined,
            emailsDiscoveredDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=pb_avg_emails_discovered${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAverageData = response.data.category_stats;
          // merge category average data into original data source
          const emailsDiscoveredData = this.state.emailsDiscoveredData.map((x, i) => {
            return (Object.assign({}, x, categoryAverageData[i]))
          });

          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAverageData,
              emailsDiscoveredData
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average email data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAverageData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  render () {
    return (
      <div>
        <Row>
          <Col xs={12} lg={6}>
            <GeneralSummaryGraph
              icon={
                <img
                  className="mr-2 align-top"
                  src="/images/Company_Emails_Icon.png"
                />
              }
              title="Product Brand Emails Discovered"
              metricName="email_discovered_email_count"
              data={this.state.emailsDiscoveredData}
              dataKey="emails_discovered"
              dataLabel="Product Brand Emails Discovered"
              scoreType={this.state.emailsDiscoveredScoreType}
              loading={this.state.emailsDiscoveredDataLoading}
              allowDecimals={false}
              showPercentChange={true}
              showAverageData={true}
              averageDataKey="pb_avg_emails_discovered"
              averageDataLabel="Category Average"
              showTooltip={true}
              contextType="brand"
              contextCategory={this.props.category}
              contextBrand={this.props.brand}
              contextChannel="Emails"
              contextChartName="Product Brand Emails Discovered"
            />
          </Col>
          <Col></Col>
        </Row>
      </div>
    );
  }
};
