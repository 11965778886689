import React from 'react';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faReddit } from '@fortawesome/free-brands-svg-icons';

export default class RedditPost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {

  }

  addBrandNameHighlights = (text) => {
    if (this.props.brand && text) {
      // mark/highlight brand name within text
      const regex = new RegExp(this.props.brand.name, 'ig');
      return text.replaceAll(
        regex,
        `<mark style="background-color:#ffc107">$&</mark>`
      );
    } else {
      return text;
    }
  }

  unescapeHTML = (content) => {
    return content
         .replace(/&amp;/g, "&")
         .replace(/&lt;/g, "<")
         .replace(/&gt;/g, ">")
         .replace(/&quot;/g, '"')
         .replace(/&#039;/g, "'");
  }

  render () {
    return (
      <Card id={`reddit-post-${this.props.post._id}`} className="mb-4" style={{ fontSize: '.875rem' }}>
        { (this.props.post && this.props.post._source) &&
          <Card.Body>
            <div>
              <FontAwesomeIcon
                className="pt-1 pr-2"
                icon={faReddit}
                color="#FF4500"
                style={{ fontSize: '1.6rem' }}
              />
              <a
                className="mr-2"
                href={`https://reddit.com/r/${this.props.post._source.subreddit}`}
                target="_blank"
              >
                {`/r/${this.props.post._source.subreddit}`}
              </a>
              {this.props.post._source.author &&
                <span className="mr-4">
                  {`Post by: ${this.props.post._source.author}`}
                </span>
              }
              <span>
                {moment.utc(this.props.post._source.published_date).format('MM/DD/YY')}
              </span>
              <div className="d-inline-block float-right">
                <SharePopUpContainer
                  shareElementId={`reddit-post-${this.props.post._id}`}
                  elementLabel={`reddit-post-${this.props.post._id}-img`}
                  position="top"
                  contextType="brand"
                  contextCategory={this.props.category}
                  contextBrand={this.props.brand}
                  contextChannel="Social"
                  contextProperty="Reddit"
                  contextChartName="Post"
                />
              </div>
              <div className="d-inline-block mr-2 float-right">
                <FontAwesomeIcon
                  icon={faLink}
                  color="#4fa2f3"
                  onClick={() => window.open(this.props.post._source.url, '_blank')}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <hr />
            </div>
            {this.props.post._source.data.title &&
              <div className="mb-2" style={{ fontWeight: 'bold' }}>
                {this.props.post._source.data.title}
              </div>
            }
            <Row noGutters>
              { (
                  this.props.post._source.data &&
                  this.props.post._source.data.thumbnail &&
                  this.props.post._source.data.thumbnail.includes('http') &&
                  !(
                    this.props.post._source.data.media &&
                    this.props.post._source.data.media.type === 'youtube.com'
                  )
                ) &&
                <Col style={{ flex: '0 0 140px' }}>
                  <img
                    src={this.props.post._source.data.thumbnail}
                    width="100%"
                    onClick={() => window.open(this.props.post._source.data.url, '_blank')}
                    style={{ cursor: 'pointer' }}
                  />
                </Col>
              }
              <Col>
                { this.props.post._source.content &&
                  <pre
                    dangerouslySetInnerHTML={{__html: this.addBrandNameHighlights(this.props.post._source.content)}}
                    style={{
                      maxWidth: 'calc(100vw - 400px)',
                      fontFamily: 'inherit',
                      fontSize: 'unset',
                      whiteSpace: 'pre-wrap',
                      wordWrap: 'break-word'
                    }}
                  />
                }
              </Col>
            </Row>
            { ( this.props.post._source.data &&
                this.props.post._source.data.media &&
                this.props.post._source.data.media.type === 'youtube.com' &&
                this.props.post._source.data.media.oembed &&
                this.props.post._source.data.media.oembed.html
              ) &&
              <div
                dangerouslySetInnerHTML={{__html: this.unescapeHTML(this.props.post._source.data.media.oembed.html) }}
              />
            }
          </Card.Body>
        }
      </Card>
    );
  }
};
