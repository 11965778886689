import React from 'react';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ReactTable from 'react-table';
import ClipLoader from 'react-spinners/ClipLoader';
import MetricTooltipContainer from '../../../containers/metrics/MetricTooltipContainer';
import MetricDistributionSummaryModal from '../../metrics/MetricDistributionSummaryModal';
import { numberWithCommas } from '../../../utils/numbers';
import { NavLink } from 'react-router-dom';

export default class SearchEngineStatsSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMonthData: [],
      metricDistributionModalOpen: false,
      selectedMetricName: undefined,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.setSelectedMonthData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.searchEngineReportData !== this.props.searchEngineReportData ||
      prevProps.selectedMonth !== this.props.selectedMonth
    ) {
      this.setSelectedMonthData();
    }
  };

  selectMonth = (selectedMonth) => {
    if (selectedMonth !== this.props.selectedMonth) {
      this.props.updateSelectedMonth(selectedMonth);
    }
  };

  addToMap = (map, key, label) => {
    for (let brand of this.props.searchEngineReportData[this.props.type][key]) {
      if (!map[brand.product_brand_id]) {
        map[brand.product_brand_id] = {
          id: brand.product_brand_id,
          name: brand.product_brand_name,
          domain: brand.domain_name
        }
      }

      for (let x of brand.score_dates) {
        if (this.props.selectedMonth.date.format('M') === moment(x.date).format('M')) {
          map[brand.product_brand_id].date = moment(x.date);
          map[brand.product_brand_id][`${label}_rank`] = x.rank;
          map[brand.product_brand_id][`${label}_position`] = x.position;
        }
      }
    }
    return map;
  };

  setSelectedMonthData = () => {
    if (this.props.selectedMonth) {
      let productBrandDataMap = {};
      if (this.props.searchEngineReportData[this.props.type] && this.props.searchEngineReportData[this.props.type].queries) {
        if (this.props.searchEngineReportData[this.props.type].google_organic_serp_rank) {
          productBrandDataMap = this.addToMap(
            productBrandDataMap,
            'google_organic_serp_rank',
            'google_organic'
          );
        }
        if (this.props.searchEngineReportData[this.props.type].google_ad_serp_rank) {
          productBrandDataMap = this.addToMap(
            productBrandDataMap,
            'google_ad_serp_rank',
            'google_ad'
          );
        }
        if (this.props.searchEngineReportData[this.props.type].bing_organic_serp_rank) {
          productBrandDataMap = this.addToMap(
            productBrandDataMap,
            'bing_organic_serp_rank',
            'bing_organic'
          );
        }
        if (this.props.searchEngineReportData[this.props.type].bing_ad_serp_rank) {
          productBrandDataMap = this.addToMap(
            productBrandDataMap,
            'bing_ad_serp_rank',
            'bing_ad'
          );
        }
      }

      const selectedMonthData = [];
      for (const [pb_id, brand] of Object.entries(productBrandDataMap)) {
        selectedMonthData.push(brand);
      }
      this.setState(() => ({ selectedMonthData }));
    }
  };

  openMetricDistributionModal = (selectedMetricName) => {
    this.setState(() => ({
      selectedMetricName,
      metricDistributionModalOpen: true
    }));
  };

  closeMetricDistributionModal = () => {
    this.setState(() => ({
      metricDistributionModalOpen: false
    }));
  };

  render () {
    return (
      <div>
        <Row>
          <Col xs={6} md={4} lg={3} xl={2}>
            { this.props.sixMonths.map((month, i) => {
                return (
                  <button
                    key={`bmd${i}`}
                    type="button"
                    className= {
                      (this.props.selectedMonth && this.props.selectedMonth.label === month.label) ?
                        'mb-1 btn btn-primary w-100' :
                        'mb-1 btn border bg-white w-100'
                    }
                    onClick={() => this.selectMonth(month)}
                  >
                    {month.label}
                  </button>
                )
              })
            }
          </Col>
          <Col>
            <ReactTable
              className="-striped -highlight"
              data={this.state.selectedMonthData}
              columns={[
                {
                  Header: 'Brand',
                  headerClassName: 'bg-dark text-light border-light wordwrap',
                  accessor: 'name',
                  sortable: true,
                    Cell: row => (
                      <div style={{ cursor: "pointer", textOverflow: 'ellipsis' }} >
                        <NavLink
                          key={`lmc${this.props.category.id}-${this.props.category.category_type}-lmb${row.original.id}`}
                          to={`/category/${encodeURIComponent(this.props.category.name)}/brand/${encodeURIComponent(row.original.name)}#search-samples`}
                        >
                          {row.original.name}
                        </NavLink>
                      </div>
                    )
                }, {
                  Header: (
                    <MetricTooltipContainer
                      tooltipTrigger={
                        <div>
                          SERP Organic, Google
                        </div>
                      }
                      tooltipPosition="top"
                      metricName="web_serp_rank_organic_google"
                      openMetricDistributionModal={this.openMetricDistributionModal}
                    />
                  ),
                  headerClassName: 'bg-dark text-light border-light wordwrap',
                  accessor: 'google_organic_position',
                  sortable: true,
                  className: 'text-center',
                  width: 220,
                  Cell: row => (
                    <div>
                      { row.original.google_organic_position !== 15 &&
                        <React.Fragment>
                          {row.original.google_organic_position}
                        </React.Fragment>
                      }
                    </div>
                  )
                }, {
                  Header: (
                    <MetricTooltipContainer
                      tooltipTrigger={
                        <div>
                          SERP Paid, Google
                        </div>
                      }
                      tooltipPosition="top"
                      metricName="web_serp_rank_paid_google"
                      openMetricDistributionModal={this.openMetricDistributionModal}
                    />
                  ),
                  headerClassName: 'bg-dark text-light border-light wordwrap',
                  accessor: 'google_ad_position',
                  sortable: true,
                  className: 'text-center',
                  width: 200,
                  Cell: row => (
                    <div>
                      { row.original.google_ad_position !== 15 &&
                        <React.Fragment>
                          {row.original.google_ad_position}
                        </React.Fragment>
                      }
                    </div>
                  )
                }, {
                  Header: (
                    <MetricTooltipContainer
                      tooltipTrigger={
                        <div>
                          SERP Organic, Bing
                        </div>
                      }
                      tooltipPosition="top"
                      metricName="web_serp_rank_organic_bing"
                      openMetricDistributionModal={this.openMetricDistributionModal}
                    />
                  ),
                  headerClassName: 'bg-dark text-light border-light wordwrap',
                  accessor: 'bing_organic_position',
                  sortable: true,
                  className: 'text-center',
                  width: 200,
                  Cell: row => (
                    <div>
                      { row.original.bing_organic_position !== 15 &&
                        <React.Fragment>
                          {row.original.bing_organic_position}
                        </React.Fragment>
                      }
                    </div>
                  )
                }, {
                  Header: (
                    <MetricTooltipContainer
                      tooltipTrigger={
                        <div>
                          SERP Paid, Bing
                        </div>
                      }
                      tooltipPosition="top"
                      metricName="web_serp_rank_paid_bing"
                      openMetricDistributionModal={this.openMetricDistributionModal}
                    />
                  ),
                  headerClassName: 'bg-dark text-light border-light wordwrap',
                  accessor: 'bing_ad_position',
                  sortable: true,
                  className: 'text-center',
                  width: 180,
                  Cell: row => (
                    <div>
                      { row.original.bing_ad_position !== 15 &&
                        <React.Fragment>
                          {row.original.bing_ad_position}
                        </React.Fragment>
                      }
                    </div>
                  )
                }
              ]}
              minRows={1}
              defaultPageSize={100}
              showPagination={false}
              defaultSorted={[{ id: 'google_organic_position', asc: true }]}
            />
          </Col>
        </Row>

        <MetricDistributionSummaryModal
          isOpen={this.state.metricDistributionModalOpen}
          handleClose={this.closeMetricDistributionModal}
          metricName={this.state.selectedMetricName}
          audienceProfileId={this.props.category.audience_profile_id}
          customerId={this.props.user.customerId}
        />
      </div>
    );
  }
};
