import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import MetricTooltipContainer from '../../containers/metrics/MetricTooltipContainer';
import MetricDistributionSummaryModal from '../metrics/MetricDistributionSummaryModal';
import { sortAlphabeticalByKey } from '../../utils/sorts';
import { numberWithCommas } from '../../utils/numbers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretUp,
  faRightLong,
  faAssistiveListeningSystems,
  faClipboardList,
  faDesktop,
  faEnvelope,
  faFileAlt,
  faGlobe,
  faMagnifyingGlass,
  faNewspaper,
  faPodcast,
  faRectangleAd,
  faStarHalfAlt,
  faVideo
} from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class ScoreVisibilityTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectableMonths: [],
      selectedMonth: undefined,
      defaultGoalsMap: {},
      customGoalsMap: {},
      scoreVisibilityDataLoading: true,
      scoreVisibilityData: [],
      data: [],
      selectedMetricName: undefined,
      metricDistributionModalOpen: false,
      latestMonthRun: moment.utc().date(3).hour(0).minute(0).second(0),
      displayPriorToRun: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getSelectableMonths();
    this.getDefaultGoalsMap();
    this.getCustomGoalsMap();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      this.getDefaultGoalsMap();
    }
    if (prevProps.brand !== this.props.brand) {
      this.getCustomGoalsMap();
    }
    if (
      prevProps.category !== this.props.category ||
      prevProps.brand !== this.props.brand ||
      prevState.selectedMonth !== this.state.selectedMonth
    ) {
      this.fetchScoreVisibilityData();
    }
    if (
      prevProps.metricsMap !== this.props.metricsMap ||
      prevProps.stageName !== this.props.stageName ||
      prevState.scoreVisibilityData !== this.state.scoreVisibilityData
    ) {
      this.setData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  getSelectableMonths = () => {
    let selectableMonths = [];
    let date = moment().subtract(1, 'month');
    let sixMonthsDate = moment(date).subtract(6, 'month');
    while (date.isAfter(sixMonthsDate)) {
      selectableMonths.push({
        number: date.format('M'),
        label: date.format('MMMM'),
        year: date.format('YYYY'),
        date
      });
      date = moment(date).subtract(1, 'month');
    }
    selectableMonths = selectableMonths.reverse();
    this.setState(() => ({
      selectableMonths,
      selectedMonth: selectableMonths[selectableMonths.length - 1]
    }));
  };

  getDefaultGoalsMap = () => {
    if (this.props.category) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/brand-metric-weights-and-ranges?audience_profile_id=${this.props.category.audience_profile_id}`,
        HEADERS
      ).then(response => {
        const defaultGoalsMap = {};
        for (let mg of response.data) {
          defaultGoalsMap[mg.brand_metric_id] = mg;
        }

        if (this.state.isMounted) {
          this.setState(() => ({ defaultGoalsMap }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch default metric goals map');
        if (this.state.isMounted) {
          this.setState(() => ({ defaultGoalsMap: {} }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  getCustomGoalsMap = () => {
    if (
      this.props.brand &&
      this.props.user.customerId === this.props.brand.company_id
    ) {
      const formattedStartDate = moment().subtract(7, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/product-brands/${this.props.brand.id}/brand-metric-goals?start-date=${formattedStartDate}&end-date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        const customGoalsMap = {};
        for (let g of response.data) {
          const formattedGoalDate = moment(g.goal_date).format('M-YYYY');
          if (!customGoalsMap[formattedGoalDate]) {
            customGoalsMap[formattedGoalDate] = {};
          }
          customGoalsMap[formattedGoalDate][g.brand_metric_id] = g.goal_value;
        }
        if (this.state.isMounted) {
          this.setState(() => ({ customGoalsMap }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch custom goals');
        if (this.state.isMounted) {
          this.setState(() => ({ customGoalsMap: {} }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    } else {
      this.setState(() => ({ customGoalsMap: {} }));
    }
  };

  fetchScoreVisibilityData = () => {
    if (
      this.props.category &&
      this.props.brand &&
      this.state.selectedMonth &&
      this.props.user.customerId
    ) {
      // check if latest month prior to run for expectation display
      const now = moment.utc();
      const lastMonth = moment.utc(now).subtract(1, 'month');
      if (
        lastMonth.format('M') === this.state.selectedMonth.number &&
        now.isBefore(this.state.latestMonthRun)
      ) {
        this.setState(() => ({ displayPriorToRun: true }));
      } else {
        this.setState(() => ({ displayPriorToRun: false }));
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/brand-metric-monthly-values-points?month=${this.state.selectedMonth.number}&year=${this.state.selectedMonth.year}&private_category_id=${this.props.category.id}&product_brand_id=${this.props.brand.id}&logged_customer_id=${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        const scoreVisibilityData = response.data;
        scoreVisibilityData.sort(sortAlphabeticalByKey('metric_label'));
        if (this.state.isMounted) {
          this.setState(() => ({
            scoreVisibilityData,
            scoreVisibilityDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch score visibility data');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            scoreVisibilityData: [],
            scoreVisibilityDataLoading: false
          }));
        }
      })
    }
  };

  setData = () => {
    if (Object.keys(this.props.metricsMap).length > 0) {
      const brandMetricsMap = {};
      for (let svd of this.state.scoreVisibilityData) {
        if (this.props.metricsMap[svd.metric_name]) {
          const metricStageName = this.props.metricsMap[svd.metric_name].stage_name;
          const metricChannelName = this.props.metricsMap[svd.metric_name].channel_name;
          if (
            this.props.stageName === 'All' ||
            this.props.stageName === metricStageName
          ) {
            switch (metricChannelName) {
              case 'Email Campaigns':
              case 'Emails':
                if (!brandMetricsMap['emails']) {
                  brandMetricsMap['emails'] = [svd];
                } else {
                  brandMetricsMap['emails'].push(svd);
                }
                break;
              case 'News':
                if (!brandMetricsMap['news']) {
                  brandMetricsMap['news'] = [svd];
                } else {
                  brandMetricsMap['news'].push(svd);
                }
                break;
              case 'Social':
                if (!brandMetricsMap['social']) {
                  brandMetricsMap['social'] = [svd];
                } else {
                  brandMetricsMap['social'].push(svd);
                }
                break;
              case 'Product Reviews':
                if (!brandMetricsMap['product_reviews']) {
                  brandMetricsMap['product_reviews'] = [svd];
                } else {
                  brandMetricsMap['product_reviews'].push(svd);
                }
                break;
              case 'Employer Reviews':
                if (!brandMetricsMap['employer_reviews']) {
                  brandMetricsMap['employer_reviews'] = [svd];
                } else {
                  brandMetricsMap['employer_reviews'].push(svd);
                }
                break;
              case 'Videos':
                if (!brandMetricsMap['video']) {
                  brandMetricsMap['video'] = [svd];
                } else {
                  brandMetricsMap['video'].push(svd);
                }
                break;
              case 'Web Analytics':
              case 'Web':
                if (!brandMetricsMap['web']) {
                  brandMetricsMap['web'] = [svd];
                } else {
                  brandMetricsMap['web'].push(svd);
                }
                break;
              case 'Search Engine Results':
                if (!brandMetricsMap['serp']) {
                  brandMetricsMap['serp'] = [svd];
                } else {
                  brandMetricsMap['serp'].push(svd);
                }
                break;
              case 'Ads':
                if (!brandMetricsMap['ads']) {
                  brandMetricsMap['ads'] = [svd];
                } else {
                  brandMetricsMap['ads'].push(svd);
                }
                break;
              case 'Webinar':
                if (!brandMetricsMap['webinar']) {
                  brandMetricsMap['webinar'] = [svd];
                } else {
                  brandMetricsMap['webinar'].push(svd);
                }
                break;
              case 'Blogs':
                if (!brandMetricsMap['blog']) {
                  brandMetricsMap['blog'] = [svd];
                } else {
                  brandMetricsMap['blog'].push(svd);
                }
                break;
              case 'Podcast':
                if (!brandMetricsMap['podcast']) {
                  brandMetricsMap['podcast'] = [svd];
                } else {
                  brandMetricsMap['podcast'].push(svd);
                }
                break;
              case 'Surveys via BrandOps':
                if (!brandMetricsMap['survey']) {
                  brandMetricsMap['survey'] = [svd];
                } else {
                  brandMetricsMap['survey'].push(svd);
                }
                break;
              default:
                break;
            }
          }
        }
      }

      const data = [];
      if (brandMetricsMap.news) {
        data.push({
          type: 'news',
          rowsData: brandMetricsMap.news
        });
      }
      if (brandMetricsMap.social) {
        data.push({
          type: 'social',
          rowsData: brandMetricsMap.social
        });
      }
      if (brandMetricsMap.product_reviews) {
        data.push({
          type: 'product_reviews',
          rowsData: brandMetricsMap.product_reviews
        });
      }
      if (brandMetricsMap.employer_reviews) {
        data.push({
          type: 'employer_reviews',
          rowsData: brandMetricsMap.employer_reviews
        });
      }
      if (brandMetricsMap.video) {
        data.push({
          type: 'video',
          rowsData: brandMetricsMap.video
        });
      }
      if (brandMetricsMap.web) {
        data.push({
          type: 'web',
          rowsData: brandMetricsMap.web
        });
      }
      if (brandMetricsMap.serp) {
        data.push({
          type: 'serp',
          rowsData: brandMetricsMap.serp
        });
      }
      if (brandMetricsMap.ads) {
        data.push({
          type: 'ads',
          rowsData: brandMetricsMap.ads
        });
      }
      if (brandMetricsMap.webinar) {
        data.push({
          type: 'webinar',
          rowsData: brandMetricsMap.webinar
        });
      }
      if (brandMetricsMap.blog) {
        data.push({
          type: 'blog',
          rowsData: brandMetricsMap.blog
        });
      }
      if (brandMetricsMap.podcast) {
        data.push({
          type: 'podcast',
          rowsData: brandMetricsMap.podcast
        });
      }
      if (brandMetricsMap.emails) {
        data.push({
          type: 'emails',
          rowsData: brandMetricsMap.emails
        });
      }
      if (brandMetricsMap.survey) {
        data.push({
          type: 'survey',
          rowsData: brandMetricsMap.survey
        });
      }

      this.setState(() => ({ data }));
    }
  };

  getIcon = (type) => {
    let icon;
    let label;
    switch (type) {
      case 'web':
        icon = faGlobe;
        label = 'Web';
        break;
      case 'webinar':
        icon = faDesktop;
        label = 'Webinar';
        break;
      case 'social':
        icon = faAssistiveListeningSystems;
        label = 'Social';
        break;
      case 'news':
        icon = faNewspaper;
        label = 'News';
        break;
      case 'product_reviews':
        icon = faStarHalfAlt;
        label = 'Product Reviews';
        break;
      case 'employer_reviews':
        icon = faStarHalfAlt;
        label = 'Employer Reviews';
        break;
      case 'serp':
        icon = faMagnifyingGlass;
        label = 'Search';
        break;
      case 'ads':
        icon = faRectangleAd;
        label = 'Ads';
        break;
      case 'blog':
        icon = faFileAlt;
        label = 'Blogs';
        break;
      case 'podcast':
        icon = faPodcast;
        label = 'Podcasts';
        break;
      case 'survey':
        icon = faClipboardList;
        label = 'Surveys';
        break;
      case 'emails':
        icon = faEnvelope;
        label = 'Emails';
        break;
      case 'video':
        icon = faVideo;
        label = 'Video';
        break;
    }
    if (icon) {
      return (
        <div className="text-center">
          <FontAwesomeIcon
            icon={icon}
            size="2x"
          />
          <div><b>{label}</b></div>
        </div>
      )
    } else {
      return '';
    }
  }

  metricRows = (data) => (
    <React.Fragment>
      { data.map((group, gi) => {
          return (
            <React.Fragment key={`g-${group.type}`}>
              { group.rowsData.map((row, ri) => {
                  if (!row.metric_name) {
                    // Spacing Row
                    return (
                      <tr
                        key={`g-${group.type}-mr-${ri}`}
                        style={{ height: '33px' }}
                      >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        { this.props.user.customerId === this.props.brand.company_id &&
                          <td></td>
                        }
                      </tr>
                    );
                  } else {
                    let pointsObtainedStyle = {};
                    if (row.brand_points_obtained != null) {
                      if (row.brand_points_obtained === row.brand_points_available) {
                        pointsObtainedStyle.backgroundColor = '#adebad';
                      } else if (row.brand_points_obtained === 0) {
                        pointsObtainedStyle.backgroundColor = '#ffad99';
                      } else {
                        pointsObtainedStyle.backgroundColor = '#ffff99';
                      }
                    }
                    return(
                      <tr key={`bmr-${ri}`}>
                        { ri === 0 &&
                          <td
                            className="bg-white align-middle"
                            rowSpan={`${group.rowsData.length+1}`}
                          >
                            {this.getIcon(group.type)}
                          </td>
                        }
                        <td
                          className="pl-4"
                          style={{
                            width: '40%',
                            color: this.isMetricSetup(row) ? 'black' : '#b3b3b3'
                          }}
                        >
                          <MetricTooltipContainer
                            tooltipTrigger={
                              <span>
                                {row.metric_label}
                              </span>
                            }
                            metricName={row.metric_name}
                            openMetricDistributionModal={this.openMetricDistributionModal}
                          />
                        </td>
                        <td
                          className="text-center"
                          style={pointsObtainedStyle}
                        >
                          { row.brand_points_obtained === null &&
                            <div>-</div>
                          }
                          { row.brand_points_obtained !== null &&
                            <div>
                              {`${numberWithCommas(row.brand_points_obtained)} / ${numberWithCommas(row.brand_points_available)}`}
                            </div>
                          }
                        </td>
                        <td className="text-center">
                          <div>
                            {numberWithCommas(row.brand_metric_value)}
                            { (
                                row.brand_metric_value != null &&
                                this.props.metricsMap[row.metric_name] &&
                                this.props.metricsMap[row.metric_name].is_percent === 1
                              ) &&
                                <span>{'%'}</span>
                            }
                          </div>
                        </td>
                        <td className="text-center">
                          { this.state.defaultGoalsMap[row.brand_metric_id] &&
                            <div>
                              { this.props.metricsMap[row.metric_name].score_type === 'high_is_good' &&
                                <div>
                                  {numberWithCommas(this.state.defaultGoalsMap[row.brand_metric_id].low_value)}
                                  { (
                                      row.brand_metric_value != null &&
                                      this.props.metricsMap[row.metric_name] &&
                                      this.props.metricsMap[row.metric_name].is_percent === 1
                                    ) &&
                                      <span>{'%'}</span>
                                  }
                                </div>
                              }
                              { this.props.metricsMap[row.metric_name].score_type === 'low_is_good' &&
                                <div>
                                  {numberWithCommas(this.state.defaultGoalsMap[row.brand_metric_id].high_value)}
                                  { (
                                      row.brand_metric_value != null &&
                                      this.props.metricsMap[row.metric_name] &&
                                      this.props.metricsMap[row.metric_name].is_percent === 1
                                    ) &&
                                      <span>{'%'}</span>
                                  }
                                </div>
                              }
                            </div>
                          }
                        </td>
                        { this.props.user.customerId === this.props.brand.company_id &&
                          <td className="text-center">
                            { (
                                this.state.customGoalsMap[`${this.state.selectedMonth.number}-${this.state.selectedMonth.year}`] &&
                                this.state.customGoalsMap[`${this.state.selectedMonth.number}-${this.state.selectedMonth.year}`][row.brand_metric_id]
                              ) &&
                              <div>
                                {numberWithCommas(this.state.customGoalsMap[`${this.state.selectedMonth.number}-${this.state.selectedMonth.year}`][row.brand_metric_id])}
                                { (
                                    row.brand_metric_value != null &&
                                    this.props.metricsMap[row.metric_name] &&
                                    this.props.metricsMap[row.metric_name].is_percent === 1
                                  ) &&
                                    <span>{'%'}</span>
                                }
                              </div>
                            }
                          </td>
                        }
                      </tr>
                    )
                  }
                })
              }
              <tr
                className="bg-white"
                style={{ height: '33px' }}
              >
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                { this.props.user.customerId === this.props.brand.company_id &&
                  <td></td>
                }
              </tr>
            </React.Fragment>
          )
        })
      }
    </React.Fragment>
  );

  isMetricSetup = (metric) => {
    if (
      this.props.metricsSetupMap &&
      this.props.metricsSetupMap[metric.site_name] &&
      !this.props.metricsSetupMap[metric.site_name].setup
    ) {
      return false;
    } else {
      return true;
    }
  };

  selectMonth = (selectedMonth) => {
    if (selectedMonth !== this.state.selectedMonth) {
      this.setState(() => ({ selectedMonth }));
    }
  };

  openMetricDistributionModal = (selectedMetricName) => {
    if (selectedMetricName) {
      this.setState(() => ({
        selectedMetricName,
        metricDistributionModalOpen: true,
      }));
    }
  };

  closeMetricDistributionModal = () => {
    this.setState(() => ({
      selectedMetricName: undefined,
      metricDistributionModalOpen: false,
    }));
  };

  render () {
    return (
      <div>
        <div className="my-4">
          { this.state.selectableMonths.map((month, i) => {
              return (
                <button
                  key={`bm-m${i}`}
                  type="button"
                  className= {
                    (this.state.selectedMonth && this.state.selectedMonth.number === month.number) ?
                      'mb-1 mr-1 btn btn-primary' :
                      'mb-1 mr-1 btn border bg-white'
                  }
                  onClick={() => this.selectMonth(month)}
                  style={{ width: '100px' }}
                >
                  {month.label}
                </button>
              )
            })
          }
        </div>
        { this.state.scoreVisibilityDataLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.scoreVisibilityDataLoading &&
          <div
            className="pre-scrollable"
            style={{
              minHeight: 'calc(100vh - 350px)',
              maxHeight: 'calc(100vh - 350px)',
              overflow: 'auto',
              overflowX: 'hidden'
            }}
          >
            { this.state.scoreVisibilityData.length === 0 &&
              <div className="d-inline-block">
                <Alert variant="warning">
                  Data is not available.
                  { this.state.displayPriorToRun &&
                    <span className="ml-1">
                      {`Data will be available ${this.state.latestMonthRun.format('MM/DD')}`}
                    </span>
                  }
                </Alert>
              </div>
            }
            { this.state.scoreVisibilityData.length > 0 &&
              <Table
                bordered
                striped
                size="sm"
                style={{
                  overflow: 'auto',
                }}
              >
                <thead className="bg-dark text-light text-center">
                  <tr
                    style={{
                      position: 'sticky',
                      top: -1,
                      zIndex: 1
                    }}
                  >
                    <th
                      className="bg-dark"
                      style={{ width: '100px' }}
                    ></th>
                    <th
                      className="bg-dark text-left"
                      style={{ width: '300px' }}
                    >
                      <div className="pl-3">
                        {`Metrics for ${this.props.brand.name}`}
                      </div>
                    </th>
                    <th className="bg-dark">
                      Points Obtained
                    </th>
                    <th className="bg-dark">
                      Metric Value
                    </th>
                    <th className="bg-dark">
                      Default Goal
                    </th>
                    { this.props.user.customerId === this.props.brand.company_id &&
                      <th className="bg-dark">
                        Custom Goal
                      </th>
                    }
                  </tr>
                </thead>
                <tbody>
                  { this.state.data &&
                    <React.Fragment>
                      {this.metricRows(this.state.data)}
                    </React.Fragment>
                  }
                </tbody>
              </Table>
            }

            <MetricDistributionSummaryModal
              isOpen={this.state.metricDistributionModalOpen}
              handleClose={this.closeMetricDistributionModal}
              metricName={this.state.selectedMetricName}
              audienceProfileId={this.props.category.audience_profile_id}
              customerId={this.props.user.customerId}
            />
          </div>
        }
      </div>
    );
  }
};
