import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import CardComponent from '../../common/CardComponent';
import ShareOfChart from '../../graphs/ShareOfChart';
import { colors } from '../../../utils/graphs';
import { numberWithCommas} from '../../../utils/numbers';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class CategoryShareOfSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shareOfSearchSnapshot: [],
      snapshotDate: undefined,
      shareOfSearchSnapshotLoading: false,
      shareOfGraphData: {},
      shareOfSearchTrend: [],
      shareOfSearchTrendLoading: false,
      productBrands: [],
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchCategoryShareOfSearchSnapshot();
    this.fetchCategoryShareOfSearchTrend();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      this.fetchCategoryShareOfSearchSnapshot();
      this.fetchCategoryShareOfSearchTrend();
    }
  };

  fetchCategoryShareOfSearchSnapshot = () => {
    if (this.props.category) {
      this.setState(() => ({ shareOfSearchSnapshotLoading: true }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/google-trend-category-share?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const shareOfSearchSnapshot = [];
        let snapshotDate;
        let index = 0;
        for (const [key, value] of Object.entries(response.data)) {
          if (key === 'date') {
            snapshotDate = value;
          } else {
            shareOfSearchSnapshot.push({
              name: key,
              value: value,
              color: colors[index % colors.length],
            });
            index += 1;
          }
        }
        // sort by value descending
        shareOfSearchSnapshot.sort((a, b) => b.value - a.value);

        if (this.state.isMounted) {
          this.setState(() => ({
            shareOfSearchSnapshot,
            snapshotDate,
            shareOfSearchSnapshotLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch share of search snapshot.');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            shareOfSearchSnapshot: [],
            snapshotDate: undefined,
            shareOfSearchSnapshotLoading: false,
          }));
        }
      })
    }
  };

  fetchCategoryShareOfSearchTrend = () => {
    if (this.props.category) {
      this.setState(() => ({ shareOfSearchTrendLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-share-of-search?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const shareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const categoryShareOfSearchData = response.data;
        const shareOfSearchTrend = [];
        for (const monthStats of categoryShareOfSearchData) {
          const searchMonthData = { month: monthStats.month };
          for (const pb of monthStats.product_brands) {
            searchMonthData[pb.product_brand_name] = {
              count: pb.search_share,
              percent: pb.search_share
            }
            // insert graph data
            shareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.search_share,
            ]);
          }
          shareOfSearchTrend.push(searchMonthData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            shareOfGraphData,
            shareOfSearchTrend,
            shareOfSearchTrendLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch category share search trend.');
        if (this.state.isMounted) {
          this.setState(() => ({
            shareOfGraphData: {},
            shareOfSearchTrend: [],
            shareOfSearchTrendLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        { this.state.shareOfSearchSnapshotLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.shareOfSearchSnapshotLoading &&
          <CardComponent
            title={`Share of Branded Search${this.state.snapshotDate?` - ${moment.utc(this.state.snapshotDate).format('MMMM D, YYYY')}`:''}`}
            icon={
              <img
                className="mr-2 align-top"
                src="/images/Comments_by_Channel_Icon.png"
              />
            }
            body={
              <Row>
                <Col>
                  <ResponsiveContainer height={200} width="99%">
                    <PieChart>
                      <Pie
                        data={this.state.shareOfSearchSnapshot}
                        nameKey="name"
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        innerRadius="40%"
                        outerRadius="90%"
                      >
                        { this.state.shareOfSearchSnapshot.map((entry, index) => {
                            return <Cell key={`cell${index}`} fill={entry.color} />;
                          })
                        }
                      </Pie>
                      <Tooltip
                        formatter={(value) => `${numberWithCommas(value)}%`}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </Col>
                <Col>
                  { this.state.shareOfSearchSnapshot.map((shareOfData, i) => {
                      return (
                        <div key={`soss-${i}`}>
                          <Row noGutters>
                            <Col style={{ flex: '0 0 25px'}}>
                              <div
                                className="w-100 h-100"
                                style={{
                                  width: '25px',
                                  height: '24px',
                                  backgroundColor: shareOfData.color
                                }}
                              />
                            </Col>
                            <Col>
                              <div className="ml-2">
                                {shareOfData.name}
                              </div>
                            </Col>
                            <Col>
                              {`${numberWithCommas(shareOfData.value || 0)}%`}
                            </Col>
                          </Row>
                        </div>
                      )
                    })
                  }
                </Col>
              </Row>
            }
            contextType="category"
            contextCategory={this.props.category}
            contextBrand={null}
            contextChannel="Search"
            contextChartName="Share of Branded Search"
          />
        }
        <div className="my-4 band-separator" />
        <ShareOfChart
          title="Share of Branded Search"
          data={this.state.shareOfGraphData}
          legendData={this.state.shareOfSearchTrend}
          loading={this.state.shareOfSearchTrendLoading}
          precentDisplayOnly={true}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Search"
          contextChartName="Share of Branded Search"
          shareClassName="category-share-of-search-img"
        />
      </div>
    );
  }
};
