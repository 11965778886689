import React from 'react';
import CardComponent from '../common/CardComponent';
import CategoryRatingToVolume from './CategoryRatingToVolume';
import CategoryTwitterRatingToVolume from './CategoryTwitterRatingToVolume';
import CategoryShareOfCommentsGraph from '../graphs/CategoryShareOfCommentsGraph';
import CategoryLinkedInSummary from './CategoryLinkedInSummary';
import CategoryLinkedInGraphs from './CategoryLinkedInGraphs';
import CategoryLinkedInShareOfGraphs from './CategoryLinkedInShareOfGraphs';
import CategoryLinkedInTopPosts from '../linkedin/CategoryLinkedInTopPosts';
import CategoryTwitterSummary from './CategoryTwitterSummary';
import CategoryTwitterGraphs from './CategoryTwitterGraphs';
import CategoryTwitterShareOfGraphs from './CategoryTwitterShareOfGraphs';
import CategoryTopTweets from '../twitter/CategoryTopTweets';
import CategoryFacebook from './facebook/CategoryFacebook';
import CategoryInstagram from './instagram/CategoryInstagram';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { createUserActivityLog } from '../../utils/users';
import history from '../../routers/history';

export default class CategorySocial extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: "allSocial"
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let selectedTab = hash.indexOf("-")>0 ? hash.substring(hash.indexOf("-")+1) : "allSocial";
      this.setState(() => ({ selectedTab }));
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  changeSelectedTab = (selectedTab, subTabName) => {
    history.push(`#social-${selectedTab}`);
    this.setState(() => ({ selectedTab }));
    if (subTabName) {
      createUserActivityLog(
        this.props.user,
        `Category Tabs: Social: ${subTabName}`,
        this.props.category,
      )
    }
  }

  render () {
    return (
      <div className="p-4">
        <div className="mb-4">
          <button
            id="categoryAllSocialButton"
            type="button"
            className= { this.state.selectedTab === "allSocial" ? "btn border-primary bg-white mr-1" : "btn border bg-white mr-1" }
            onClick={(e) => this.changeSelectedTab('allSocial', 'All Social')}
          >
            <FontAwesomeIcon
              className="pr-2"
              icon={faUsers}
              color="#6c757d"
              style={{ paddingTop: '6px', paddingBottom: '2px', fontSize: '1rem' }}
            />
            <div className="pr-2 d-inline-block">
              All Social
            </div>
          </button>
          <button
            id="categoryLinkedInButton"
            type="button"
            className= { this.state.selectedTab === "linkedIn" ? "btn border-primary bg-white mr-1" : "btn border bg-white mr-1" }
            onClick={(e) => this.changeSelectedTab('linkedIn', 'LinkedIn')}
          >
            <FontAwesomeIcon
              className="pt-1 pr-2"
              icon={faLinkedin}
              color="#2867B2"
              style={{ fontSize: '1.2rem' }}
            />
            LinkedIn
          </button>
          <button
            id="categoryTwitterButton"
            type="button"
            className= { this.state.selectedTab === "twitter" ? "btn border-primary bg-white mr-1" : "btn border bg-white mr-1" }
            onClick={(e) => this.changeSelectedTab('twitter', 'Twitter')}
          >
            <FontAwesomeIcon
              className="pt-1 pr-2"
              icon={faTwitter}
              color="#1DA1F2"
              style={{ fontSize: '1.2rem' }}
            />
            Twitter
          </button>
          <button
            id="categoryFacebookButton"
            type="button"
            className= { this.state.selectedTab === "facebook" ? "btn border-primary bg-white mr-1" : "btn border bg-white mr-1" }
            onClick={(e) => this.changeSelectedTab('facebook', 'Facebook')}
          >
            <FontAwesomeIcon
              className="pt-1 pr-2"
              icon={faFacebook}
              color="#4267B2"
              style={{ fontSize: '1.2rem' }}
            />
            Facebook
          </button>
          <button
            id="categoryInstagramButton"
            type="button"
            className= { this.state.selectedTab === "instagram" ? "btn border-primary bg-white mr-1" : "btn border bg-white mr-1" }
            onClick={(e) => this.changeSelectedTab('instagram', 'Instagram')}
          >
            <FontAwesomeIcon
              className="pt-1 pr-2"
              icon={faInstagram}
              color="#C13584"
              style={{ fontSize: '1.2rem' }}
            />
            Instagram
          </button>
        </div>

        <div
          className="mt-2 pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 200px)',
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
        { this.state.selectedTab == "allSocial" &&
        <div>
          <CardComponent
            title={
              <span>
                Social Sentiment
                <span className="ml-2" style={{ fontSize: '.75rem' }}>1 month</span>
              </span>
            }
            icon={
              <img
                className="mr-2 align-top"
                src="/images/Brand_Experience_Icon.png"
              />
            }
            description="View the social sentiment for the category"
            body={
              <CategoryRatingToVolume
                categoryId={this.props.categoryId}
                category={this.props.category}
              />
            }
            contextType="category"
            contextCategory={this.props.category}
            contextBrand={null}
            contextChannel="Social"
            contextChartName="Social Sentiment"
          />
          <div className="my-4 band-separator" />
          <CategoryShareOfCommentsGraph
            title="Share of All Comments"
            description="Share of All Comments allows you to visualize the amount of comments by brand for the category"
            categoryType={this.props.category.category_type}
            categoryId={this.props.categoryId}
            category={this.props.category}
            commentsType="All"
            commentsDataKey="comments_count"
            shareClassName="category-share-of-all-comments-img"
          />
          <CategoryShareOfCommentsGraph
            title="Share of Promoter Comments"
            icon={
              <img
                className="mr-2 align-top"
                src="/images/Brand_Experience_Icon.png"
              />
            }
            description="Share of Promoter Comments allows you to visualize the amount of positive comments by brand for the category"
            categoryType={this.props.category.category_type}
            categoryId={this.props.categoryId}
            category={this.props.category}
            commentsType="Promoter"
            commentsDataKey="promoter_comments_count"
          />
          <CategoryShareOfCommentsGraph
            title="Share of Detractor Comments"
            icon={
              <img
                className="mr-2 align-top"
                src="/images/Brand_Experience_Icon.png"
              />
            }
            description="Share of Detractor Comments allows you to visualize the amount of negative comments by brand for the category"
            categoryType={this.props.category.category_type}
            category={this.props.category}
            categoryId={this.props.categoryId}
            commentsType="Detractor"
            commentsDataKey="detractor_comments_count"
          />
        </div>
        }
        { this.state.selectedTab == "linkedIn" &&
          <div>
            <CategoryLinkedInSummary
              user={this.props.user}
              sixMonths={this.props.sixMonths}
              selectedMonth={this.props.selectedMonth}
              updateSelectedMonth={this.props.updateSelectedMonth}
              category={this.props.category}
              categoryId={this.props.categoryId}
            />
            <div className="mt-5 mb-4 band-separator" />
            <CategoryLinkedInGraphs
              user={this.props.user}
              categoryId={this.props.categoryId}
              category={this.props.category}
            />
            <div className="my-4 band-separator" />
            <CategoryLinkedInShareOfGraphs
              user={this.props.user}
              categoryId={this.props.categoryId}
              category={this.props.category}
            />
            <CategoryLinkedInTopPosts
              category={this.props.category}
            />
          </div>
        }
        { this.state.selectedTab == "twitter" &&
          <div>
            <CategoryTwitterSummary
              user={this.props.user}
              sixMonths={this.props.sixMonths}
              selectedMonth={this.props.selectedMonth}
              updateSelectedMonth={this.props.updateSelectedMonth}
              category={this.props.category}
              categoryId={this.props.categoryId}
            />
            <div className="mt-5 mb-4 band-separator" />
            <CategoryTwitterGraphs
              user={this.props.user}
              categoryId={this.props.categoryId}
              category={this.props.category}
            />
            <div className="my-4 band-separator" />
            <CardComponent
              title={
                <span>
                  Social Sentiment
                  <span className="ml-2" style={{ fontSize: '.75rem' }}>1 month</span>
                </span>
              }
              icon={
                <img
                  className="mr-2 align-top"
                  src="/images/Brand_Experience_Icon.png"
                />
              }
              description="View the social sentiment for the category (Twitter only)"
              body={
                <CategoryTwitterRatingToVolume
                  categoryId={this.props.categoryId}
                  category={this.props.category}
                />
              }
              contextType="category"
              contextCategory={this.props.category}
              contextBrand={null}
              contextChannel="Social"
              contextChartName="Social Sentiment"
            />
            <div className="my-4 band-separator" />
            <CategoryTwitterShareOfGraphs
              category={this.props.category}
            />
            <CategoryTopTweets
              category={this.props.category}
            />
          </div>
        }
        { this.state.selectedTab == "facebook" &&
          <div>
            <CategoryFacebook
              user={this.props.user}
              sixMonths={this.props.sixMonths}
              selectedMonth={this.props.selectedMonth}
              updateSelectedMonth={this.props.updateSelectedMonth}
              category={this.props.category}
              categoryId={this.props.categoryId}
            />
          </div>
        }
        { this.state.selectedTab == "instagram" &&
          <div>
            <CategoryInstagram
              user={this.props.user}
              sixMonths={this.props.sixMonths}
              selectedMonth={this.props.selectedMonth}
              updateSelectedMonth={this.props.updateSelectedMonth}
              category={this.props.category}
              categoryId={this.props.categoryId}
            />
          </div>
        }
        </div>
      </div>
    );
  }
};
