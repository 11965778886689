import axios from 'axios';
import { store } from '../../store/configureStore';
import { listenBuildCheck } from '../projectBuilds/projectBuilds';
import { LAMBDA_ENDPOINT, HEADERS } from '../../utils/constants';

export const reportError = (errorResponse) => {
  return (dispatch, getStore, er) => {
    if (errorResponse && process.env.NODE_ENV === 'production') {
      const reduxStore = getStore();
      // Send to github issue
      let body = `API: ${errorResponse.config.url}\n\n`;
      body += `Method: ${errorResponse.config.method.toUpperCase()}\n\n`;
      if (errorResponse.config.data) {
        body += `Body: ${errorResponse.config.data}\n\n`;
      }
      body += `Status Code: ${errorResponse.status}\n\n`;
      body += `Message: ${errorResponse.data.message || errorResponse.statusText}\n\n`;
      body += `User Id: ${reduxStore.user.id || ''}\n`;
      body += `User Email: ${reduxStore.user.email || ''}\n`;
      body += `Company: ${reduxStore.company.name || ''}\n`;
      if (reduxStore.user.status) {
        body += `Company Status: ${reduxStore.user.status}`;
      }

      let api = errorResponse.config.url;
      try {
        api = errorResponse.config.url.split('?')[0];
      } catch (e) {
        // do nothing - allow api to stay
      }

      const issue = {
        repo: 'LegendaryAI/Operations',
        title: `${errorResponse.status} - ${api}`,
        body: body,
        labels: ['bug'],
        assignee: 'shrikant14'
      };

      axios.post(
        `${LAMBDA_ENDPOINT}/github-issue`,
        issue,
        HEADERS
      );

      // if api is listen, build check it
      if (errorResponse.config.url && errorResponse.config.url.includes('api.brandops.io/listen/')) {
        dispatch(listenBuildCheck(errorResponse))
      }
    }
  };
};

export const dispatchReportError = (errorResponse) => {
  store.dispatch(reportError(errorResponse));
};
