import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactTable from 'react-table';
import ClipLoader from 'react-spinners/ClipLoader';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import MetricTooltipContainer from '../../containers/metrics/MetricTooltipContainer';
import MetricDistributionSummaryModal from '../metrics/MetricDistributionSummaryModal';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';
import { NavLink } from 'react-router-dom';

export default class CategoryWebSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryWebData: [],
      monthlyCategoryWebStats: [],
      categoryWebLoading: false,
      metricDistributionModalOpen: false,
      selectedMetricName: undefined,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchWebStats();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.categoryId !== this.props.categoryId) {
      this.fetchWebStats();
    }
    if (prevProps.selectedMonth !== this.props.selectedMonth) {
      this.setWebStats(this.props.selectedMonth);
    }
  };

  selectMonth = (selectedMonth) => {
    if (selectedMonth !== this.props.selectedMonth) {
      this.props.updateSelectedMonth(selectedMonth);
    }
  };

  fetchWebStats = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryWebLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-web-stats?category_type=${this.props.category.category_type}&category_id=${this.props.categoryId}`,
        HEADERS
      ).then(response => {
        let categoryWebData = response.data;
        let monthlyCategoryWebStats = [];
        for (const brand of categoryWebData) {
          for (const monthStats of brand.web_stats) {
            if (monthStats.month == this.props.selectedMonth.label) {
              monthlyCategoryWebStats.push({
                name: brand.product_brand_name,
                domain: brand.domain,
                month: monthStats.month,
                alexaRank: monthStats.alexa_rank,
                backlinks: monthStats.backlinks,
                pageViews: monthStats.page_views_per_user,
                reachPerMil: monthStats.reach_per_million,
                semrushDomainRank: monthStats.semrush_domain_rank,
                mozDomainAuthority: monthStats.moz_domain_authority
              });
            }
          }
        }
        this.setState(() => ({
          categoryWebData,
          monthlyCategoryWebStats,
          categoryWebLoading: false
        }));
      }).catch(error => {
        console.error('Error: failed to fetch category web data...');
        this.setState(() => ({
          categoryWebData: [],
          monthlyCategoryWebStats: [],
          categoryWebLoading: false
        }));
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  }

  setWebStats = (selectedMonth) => {
    let monthlyCategoryWebStats = [];
    for (const brand of this.state.categoryWebData) {
      for (const monthStats of brand.web_stats) {
        if (monthStats.month == selectedMonth.label) {
          monthlyCategoryWebStats.push({
            name: brand.product_brand_name,
            domain: brand.domain,
            month: monthStats.month,
            alexaRank: monthStats.alexa_rank,
            backlinks: monthStats.backlinks,
            pageViews: monthStats.page_views_per_user,
            reachPerMil: monthStats.reach_per_million,
            semrushDomainRank: monthStats.semrush_domain_rank,
            mozDomainAuthority: monthStats.moz_domain_authority
          });
        }
      }
    }
    this.setState(() => ({ monthlyCategoryWebStats }));
  };

  openMetricDistributionModal = (selectedMetricName) => {
    this.setState(() => ({
      selectedMetricName,
      metricDistributionModalOpen: true
    }));
  };

  closeMetricDistributionModal = () => {
    this.setState(() => ({
      metricDistributionModalOpen: false
    }));
  };

  render () {
    return (
      <div>
        <Row>
          <Col xs={6} md={4} lg={3} xl={2}>
            { this.props.sixMonths.map((month, i) => {
                return (
                  <button
                    id={`categoryWebSummary${month.label}Button`}
                    key={`bmd${i}`}
                    type="button"
                    className= {
                      (this.props.selectedMonth && this.props.selectedMonth.label === month.label) ?
                        'mb-1 btn btn-primary w-100' :
                        'mb-1 btn border bg-white w-100'
                    }
                    onClick={() => this.selectMonth(month)}
                  >
                    {month.label}
                  </button>
                )
              })
            }
          </Col>
          <Col>
            { this.state.categoryWebLoading &&
              <div className="m-4 text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.categoryWebLoading &&
              <div id="categoryWebSummary">
                <ReactTable
                  className="-striped -highlight overflow-hidden"
                  data={this.state.monthlyCategoryWebStats}
                  columns={[
                    {
                      Header: 'Domain',
                      headerClassName: 'bg-dark text-light border-light wordwrap',
                      accessor: 'domain',
                      sortable: true,
                      Cell: row => (
                        <div style={{ cursor: "pointer", textOverflow: 'ellipsis' }} >
                          <NavLink
                            key={`lmc${this.props.category.id}-${this.props.category.category_type}-lmb${row.original.id}`}
                            to={`/category/${encodeURIComponent(this.props.category.name)}/brand/${encodeURIComponent(row.original.name)}#web-highlights`}
                          >
                            {row.original.domain}
                          </NavLink>
                        </div>
                      )
                    },
                    {
                      Header: (
                        <MetricTooltipContainer
                          tooltipTrigger={
                            <div>
                              Domain Rank, SEMrush
                            </div>
                          }
                          tooltipPosition="top"
                          metricName="web_semrush_domain_rank"
                          openMetricDistributionModal={this.openMetricDistributionModal}
                        />
                      ),
                      headerClassName: 'bg-dark text-light border-light wordwrap',
                      accessor: 'semrushDomainRank',
                      sortable: true,
                      className: 'text-center',
                      Cell: row => (
                        <div>
                          {numberWithCommas(row.original.semrushDomainRank)}
                        </div>
                      )
                    },
                    {
                      Header: (
                        <MetricTooltipContainer
                          tooltipTrigger={
                            <div>
                              Domain Authority, Moz
                            </div>
                          }
                          tooltipPosition="top"
                          metricName="web_moz_domain_authority"
                          openMetricDistributionModal={this.openMetricDistributionModal}
                        />
                      ),
                      headerClassName: 'bg-dark text-light border-light wordwrap',
                      accessor: 'mozDomainAuthority',
                      sortable: true,
                      className: 'text-center',
                      Cell: row => (
                        <div>
                          {numberWithCommas(row.original.mozDomainAuthority)}
                        </div>
                      )
                    },
                  ]}
                  minRows={1}
                  defaultPageSize={100}
                  showPagination={false}
                  defaultSorted={[{ id: 'semrushDomainRank', asc: true }]}
                />
                <SharePopUpContainer
                  shareElementId="categoryWebSummary"
                  elementLabel="category-web-summary-img"
                  position="bottom"
                  contextType="category"
                  contextCategory={this.props.category}
                  contextBrand={null}
                  contextChannel="Web"
                  contextChartName="Summary"
                  contextDateRange={`${this.props.selectedMonth.label} ${this.props.selectedMonth.date.format('YYYY')}`}
                />
              </div>
            }
          </Col>
        </Row>

        <MetricDistributionSummaryModal
          isOpen={this.state.metricDistributionModalOpen}
          handleClose={this.closeMetricDistributionModal}
          metricName={this.state.selectedMetricName}
          audienceProfileId={this.props.category.audience_profile_id}
          customerId={this.props.user.customerId}
        />
      </div>
    );
  }
};
