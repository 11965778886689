import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryYoutubeTopVideos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brandInfoByCompanyIdMap: {},
      categoryTopVideos: [],
      categoryTopVideosLoading: false
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.setBrandInfoMap();
    this.fetchCategoryTopVideos();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.setBrandInfoMap();
      this.fetchCategoryTopVideos();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  setBrandInfoMap = () => {
    if (this.props.category && this.props.category.product_brands) {
      const brandInfoByCompanyIdMap = {};
      for (const brand of this.props.category.product_brands) {
        brandInfoByCompanyIdMap[brand.company_id] = brand;
      }
      this.setState(() => ({ brandInfoByCompanyIdMap }));
    }
  };

  fetchCategoryTopVideos = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryTopVideosLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-youtube-top-videos?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const categoryTopVideos = response.data;
        if (this.state.isMounted) {
          this.setState((prevState) => ({
            categoryTopVideos,
            categoryTopVideosLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch category youtube top videos.');
        if (this.state.isMounted) {
          this.setState((prevState) => ({
            categoryTopVideos: [],
            categoryTopVideosLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  prettyDuration = (seconds) => {
    if (seconds != null) {
      const duration = moment.duration(seconds, 'seconds');
      if (seconds >= 3600) {
        // display hour as well
        return moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
      } else {
        return moment.utc(duration.as('milliseconds')).format('m:ss');
      }
    }
    return '';
  };

  render () {
    return (
      <div>
        <h5 className="mb-4 p-2 bg-bops-blue text-light">
          Top Content
        </h5>
        { this.state.categoryTopVideosLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.categoryTopVideosLoading &&
          <Row>
            { this.state.categoryTopVideos.map((video, i) => {
                return (
                  <Col
                    key={`ctv-${video.id}`}
                    className="mb-4"
                    xs={12}
                    lg={6}
                  >
                    <Card
                      id={`ctv-${video.id}`}
                      className="h-100"
                    >
                      <Card.Header className="bg-white">
                        <div>
                          <div className="d-inline-block font-weight-bold">
                            {this.state.brandInfoByCompanyIdMap[video.company_id].name}
                          </div>
                          <div className="d-inline-block float-right">
                            <SharePopUpContainer
                              shareElementId={`ctv-${video.id}`}
                              elementLabel={`ctv-${video.id}-img`}
                              position="top"
                              contextType="category"
                              contextCategory={this.props.category}
                              contextBrand={null}
                              contextChannel="Youtube"
                              contextProperty="Top Content"
                              contextChartName="Top Content"
                            />
                          </div>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <div>
                          <Row noGutters>
                            <Col xs={4}>
                              <div className="text-center">
                                <img
                                  src={video.thumbnail_url}
                                  onClick={() => window.open(video.video_url, '_blank')}
                                  style={{
                                    height: 'auto',
                                    maxWidth: '88px',
                                    cursor: 'pointer'
                                  }}
                                />
                              </div>
                              <div className="text-center">
                                <div>{this.prettyDuration(video.duration_in_seconds)}</div>
                                <div>{moment(video.published_at).format('MM/DD/YY')}</div>
                                <div>{`${numberWithCommas(video.views_count)} views`}</div>
                                <div>{`${numberWithCommas(video.comments_count)} comments`}</div>
                                <div>
                                  <Row>
                                    <Col>
                                      <div className="float-right">
                                        <FontAwesomeIcon
                                          className="mr-1"
                                          icon={faThumbsUp}
                                          color="#5f666d"
                                        />
                                        {numberWithCommas(video.likes_count)}
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="float-left">
                                        <FontAwesomeIcon
                                          className="mr-1"
                                          icon={faThumbsDown}
                                          color="#5f666d"
                                        />
                                        {numberWithCommas(video.dislikes_count)}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div
                                onClick={() => window.open(video.video_url, '_blank')}
                                style={{ cursor: 'pointer' }}
                              >
                                <b>{video.title}</b>
                              </div>
                              <div style={{ maxHeight: '168px', overflow: 'hidden' }}>
                                {video.description}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <div>{`${numberWithCommas(video.net_new_views)} net new views`}</div>
                      </Card.Footer>
                    </Card>
                  </Col>
                )
              })
            }
          </Row>
        }
      </div>
    );
  }
};
