import React from 'react';

export default class ApiErrorMessage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: 'Error',
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.setMessage();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  setMessage = () => {
    let message = 'Error';
    if (this.props.message) {
      message = this.props.message;
    } else if (this.props.statusCode) {
      switch (this.props.statusCode) {
        case 500:
          message = 'The server is having trouble. We are looking into it, please try again later.';
          break;
        case 504:
          message = 'The server is taking to long to respond, please try again later.';
          break;
      }
    }
    this.setState(() => ({ message }));
  };

  render() {
    return (
      <div className="p-4 text-center border rounded">
        {this.state.message}
      </div>
    );
  };
};
