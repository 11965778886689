import { connect } from 'react-redux';
import AddToReportsModal from '../../components/share/AddToReportsModal';
import {
  fetchCompanyCustomReports,
  updateLastCustomReportIdUsed
} from '../../actions/customReports/customReports';

const mapStateToProps = (state) => ({
  user: state.user,
  customReports: state.customReports,
  lastCustomReportIdUsed: state.lastCustomReportIdUsed,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCompanyCustomReports: (companyId) => dispatch(fetchCompanyCustomReports(companyId)),
  updateLastCustomReportIdUsed: (customReportId) => dispatch(updateLastCustomReportIdUsed(customReportId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddToReportsModal);
