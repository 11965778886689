import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';
import CardComponent from '../common/CardComponent';
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

const ViewsCustomTooltip = (props) => {
  const {active, payload, label} = props;
  if (
    active &&
    payload &&
    Array.isArray(payload) &&
    payload[0] &&
    payload[0].payload &&
    payload[0].payload.category_avg_net_views_gain < 0
  ) {
    return (
      <div
        className="p-2"
        style={{
          maxWidth: '350px',
          backgroundColor: '#ffffff',
          borderStyle: 'solid',
          borderWidth: 'thin',
          borderColor: '#bfbfbf'
        }}
      >
        <div className="py-1">
          {label}
        </div>
        <div
          className="py-1"
          style={{ color: payload[0].color }}
        >
          {`${payload[0].name}: ${numberWithCommas(parseFloat(payload[0].payload[payload[0].dataKey]))}`}
        </div>
        <div
          className="py-1"
          style={{ color: payload[1].color }}
        >
          {`${payload[1].name}: ${numberWithCommas(parseFloat(payload[1].payload[payload[1].dataKey]))}`}
        </div>
        { payload[2] &&
          <div
            className="py-1"
            style={{ color: payload[2].color }}
          >
            {`${payload[2].name}: ${numberWithCommas(parseFloat(payload[2].payload[payload[2].dataKey]))}`}
          </div>
        }
        <div>
          Negative numbers usually indicate that some content was deleted.
        </div>
      </div>
    )
  } else {
    return (
      <DefaultTooltipContent
        {...props}
        formatter={(value) => numberWithCommas(value)}
      />
    );
  }
};

export default class CategoryVideoSummaryGraphs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryVideoSummaryData: [],
      categoryVideoSummaryDataLoading: false,
      categoryKingName: '',
      ownersBrandName: ''
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getCategoryVideoSummaryData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.getCategoryVideoSummaryData();
    }
  }

  getCategoryVideoSummaryData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryVideoSummaryDataLoading: true }));
      const categoryVideoSummaryRequests = [];
      // get category average video stats
      categoryVideoSummaryRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-average-video-stats-trend?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=category-average`,
          HEADERS
        ).then(response => {
          const categoryAvgVideoData = response.data;
          return { categoryAvgVideoData };
        }).catch(error => {
          console.error('Error: unable to fetch category average video trend');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryAvgVideoData: [] };
        })
      );
      // get category king video stats
      categoryVideoSummaryRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-average-video-stats-trend?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=category-king`,
          HEADERS
        ).then(response => {
          const categoryKingVideoData = response.data;
          return { categoryKingVideoData };
        }).catch(error => {
          console.error('Error: unable to fetch category king video trend');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryKingVideoData: [] };
        })
      );
      // get customer video stats
      let ownersBrand;
      for (const brand of this.props.category.product_brands) {
        if (!ownersBrand && brand.company_id === this.props.user.customerId) {
          ownersBrand = brand;
        }
      }
      if (ownersBrand) {
        categoryVideoSummaryRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/category-average-video-stats-trend?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=product-brand&product_brand_id=${ownersBrand.id}`,
            HEADERS
          ).then(response => {
            const ownedBrandVideoData = response.data;
            return {
              ownedBrandVideoData,
              ownersBrand
            };
          }).catch(error => {
            console.error('Error: unable to fetch owned brand video trend');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return { ownedBrandVideoData: [] };
          })
        );
      }

      Promise.all(categoryVideoSummaryRequests).then(responses => {
        let categoryAvgVideoData = [];
        let categoryKingVideoData = [];
        let ownedBrandVideoData = [];
        let highestSubscriberCount = 0;
        let highestSubscriberKey = 'category_king_net_subscribers_gain';
        let highestViewsCount = 0;
        let highestViewsKey = 'category_king_net_views_gain';
        let categoryKingName = '';
        let ownersBrandName = '';
        for (let response of responses) {
          if (response.categoryAvgVideoData) {
            categoryAvgVideoData = response.categoryAvgVideoData;
          } else if (response.categoryKingVideoData) {
            categoryKingVideoData = response.categoryKingVideoData;
            if (categoryKingVideoData.length > 0) {
              categoryKingName = categoryKingVideoData[0].product_brand_name;
            }
          } else if (response.ownedBrandVideoData) {
            ownedBrandVideoData = response.ownedBrandVideoData;
            ownersBrandName = response.ownersBrand.name;
          }
        }
        // merge the 3 data arrays
        // may be able to optimize this, but its a small data set
        const categoryVideoSummaryData = [];
        for (let cd of categoryAvgVideoData) {
          if (cd.net_subscribers_gain > highestSubscriberCount) {
            highestSubscriberCount = cd.net_subscribers_gain;
            highestSubscriberKey = 'category_avg_net_subscribers_gain';
          }
          if (cd.net_views_gain > highestViewsCount) {
            highestViewsCount = cd.net_views_gain;
            highestViewsKey = 'category_avg_net_views_gain';
          }
          categoryVideoSummaryData.push({
            month: cd.month,
            category_avg_net_subscribers_gain: cd.net_subscribers_gain,
            category_avg_net_views_gain: cd.net_views_gain,
            category_avg_subscribers_count: Math.round(cd.avg_subscribers_count),
            category_avg_views_count: Math.round(cd.avg_views_count)
          });
        }
        for (let ckd of categoryKingVideoData) {
          for (let scoreData of categoryVideoSummaryData) {
            if (ckd.net_subscribers_gain > highestSubscriberCount) {
              highestSubscriberCount = ckd.net_subscribers_gain;
              highestSubscriberKey = 'category_king_net_subscribers_gain';
            }
            if (ckd.net_views_gain > highestViewsCount) {
              highestViewsCount = ckd.net_views_gain;
              highestViewsKey = 'category_king_net_views_gain';
            }
            if (ckd.month === scoreData.month) {
              scoreData.category_king_net_subscribers_gain = ckd.net_subscribers_gain;
              scoreData.category_king_net_views_gain = ckd.net_views_gain;
            }
          }
        }
        for (let obd of ownedBrandVideoData) {
          for (let scoreData of categoryVideoSummaryData) {
            if (obd.net_subscribers_gain > highestSubscriberCount) {
              highestSubscriberCount = obd.net_subscribers_gain;
              highestSubscriberKey = 'owned_brand_net_subscribers_gain';
            }
            if (obd.net_views_gain > highestViewsCount) {
              highestViewsCount = obd.net_views_gain;
              highestViewsKey = 'owned_brand_net_views_gain';
            }
            if (obd.month === scoreData.month) {
              scoreData.owned_brand_net_subscribers_gain = obd.net_subscribers_gain;
              scoreData.owned_brand_net_views_gain = obd.net_views_gain;
            }
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryVideoSummaryData,
            categoryVideoSummaryDataLoading: false,
            highestSubscriberKey,
            highestViewsKey,
            categoryKingName,
            ownersBrandName
          }));
        }
      });
    }
  }

  render () {
    return (
      <div>
        <CardComponent
          title="Net New Subscribers"
          body={
            <div>
              { this.state.categoryVideoSummaryDataLoading &&
                <div className="text-center">
                  <ClipLoader size={100}/>
                </div>
              }
              { !this.state.categoryVideoSummaryDataLoading &&
                <ResponsiveContainer width="99%" height={200}>
                  <ComposedChart
                    data={this.state.categoryVideoSummaryData}
                    margin={{
                      left: 16
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month"/>
                    <YAxis
                      dataKey={this.state.highestSubscriberKey}
                      tick={{ fontSize: 12 }}
                      tickFormatter={(value) => {
                        return numberWithCommas(value);
                      }}
                    />
                    <Tooltip
                      formatter={(value) => numberWithCommas(value)}
                    />

                    <Area
                      name={
                        this.state.categoryKingName ?
                          `Leader, ${this.state.categoryKingName} Net New Subscribers` :
                          'Category King Net New Subscribers'
                      }
                      type="monotone"
                      dataKey="category_king_net_subscribers_gain"
                      stroke="#007bff"
                      fillOpacity={.4}
                    />
                    <Line
                      name="Category Average Net New Subscribers"
                      type="monotone"
                      dataKey="category_avg_net_subscribers_gain"
                      strokeDasharray="10 10"
                      stroke="#009900"
                      strokeWidth={3}
                    />
                    <Line
                      name={
                        this.state.ownersBrandName ?
                          `${this.state.ownersBrandName} Net New Subscribers` :
                          'Your Brand Net New Subscribers'
                      }
                      type="monotone"
                      dataKey="owned_brand_net_subscribers_gain"
                      stroke="#ffc107"
                      strokeWidth={3}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              }
            </div>
          }
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Youtube"
          contextChartName="Net New Subscribers"
        />
      </div>
    );
  }
};
