import axios from 'axios';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { reportError } from '../api/errors';

export const fetchBrandMetrics = () => {
  return (dispatch) => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/brand-metrics?add_frequency_data=True`,
      HEADERS
    ).then(response => {
      const brandMetrics = response.data;
      const brandMetricsMap = {};
      // add by id & metric_name to map for convenience
      for (const bm of brandMetrics) {
        brandMetricsMap[bm.id] = bm;
        brandMetricsMap[bm.metric_name] = bm;
      }
      dispatch(addBrandMetrics(brandMetrics));
      dispatch(addBrandMetricsMap(brandMetricsMap));
    }).catch(error => {
      console.error('Error: failed to fetch brand metrics');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatch(reportError(error.response));
      }
      dispatch(addBrandMetrics([]));
      dispatch(addBrandMetricsMap({}));
    });
  };
};

export const addBrandMetrics = (brandMetrics) => ({
  type: 'ADD_BRAND_METRICS',
  brandMetrics
});

export const addBrandMetricsMap = (brandMetricsMap) => ({
  type: 'ADD_BRAND_METRICS_MAP',
  brandMetricsMap
});
