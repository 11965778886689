import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../common/CardComponent';
import {
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryPodcastsAvgGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryPodcastsSummaryData: [],
      categoryPodcastsSummaryDataLoading: false,
      categoryKingName: '',
      ownersBrandName: ''
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getCategoryPodcastSummaryData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.getCategoryPodcastSummaryData();
    }
  }

  getCategoryPodcastSummaryData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryPodcastsSummaryDataLoading: true }));
      const categoryPodcastSummaryRequests = [];
      // get category average podcast stats
      categoryPodcastSummaryRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-average-podcast-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=category-average`,
          HEADERS
        ).then(response => {
          const categoryAvgPodcastData = response.data;
          return { categoryAvgPodcastData };
        }).catch(error => {
          console.error('Error: unable to fetch category average podcast trend');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryAvgPodcastData: [] };
        })
      );
      // get category king podcast stats
      categoryPodcastSummaryRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-average-podcast-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=category-king`,
          HEADERS
        ).then(response => {
          const categoryKingPodcastData = response.data;
          return { categoryKingPodcastData };
        }).catch(error => {
          console.error('Error: unable to fetch category king podcast trend');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryKingPodcastData: [] };
        })
      );
      // get customer podcast stats
      let ownersBrand;
      for (const brand of this.props.category.product_brands) {
        if (!ownersBrand && brand.company_id === this.props.user.customerId) {
          ownersBrand = brand;
        }
      }
      if (ownersBrand) {
        categoryPodcastSummaryRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/category-average-podcast-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=product-brand&product_brand_id=${ownersBrand.id}`,
            HEADERS
          ).then(response => {
            const ownedBrandPodcastData = response.data;
            return {
              ownedBrandPodcastData,
              ownersBrand
            };
          }).catch(error => {
            console.error('Error: unable to fetch owned brand podcast trend');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return { ownedBrandPodcastData: [] };
          })
        );
      }

      Promise.all(categoryPodcastSummaryRequests).then(responses => {
        let categoryAvgPodcastData = [];
        let categoryKingPodcastData = [];
        let ownedBrandPodcastData = [];
        let highestMentionCount = 0;
        let highestMentionKey = 'category_king_mention_count';
        let categoryKingName = '';
        let ownersBrandName = '';
        for (let response of responses) {
          if (response.categoryAvgPodcastData) {
            categoryAvgPodcastData = response.categoryAvgPodcastData;
          } else if (response.categoryKingPodcastData) {
            categoryKingPodcastData = response.categoryKingPodcastData;
            if (categoryKingPodcastData.length > 0) {
              categoryKingName = categoryKingPodcastData[0].product_brand_name;
            }
          } else if (response.ownedBrandPodcastData) {
            ownedBrandPodcastData = response.ownedBrandPodcastData;
            ownersBrandName = response.ownedBrandPodcastData[0].product_brand_name;
          }
        }
        // merge the 3 data arrays
        // may be able to optimize this, but its a small data set
        const categoryPodcastsSummaryData = [];
        for (let cd of categoryAvgPodcastData) {
          if (cd.avg_mention_count > highestMentionCount) {
            highestMentionCount = cd.avg_mention_count;
            highestMentionKey = 'category_avg_mention_gain';
          }
          categoryPodcastsSummaryData.push({
            month: cd.month,
            category_avg_mention_gain: cd.avg_mention_count,
          });
        }
        for (let ckd of categoryKingPodcastData) {
          for (let scoreData of categoryPodcastsSummaryData) {
            if (ckd.mention_count > highestMentionCount) {
              highestMentionCount = ckd.mention_count;
              highestMentionKey = 'category_king_mention_count';
            }
            if (ckd.month === scoreData.month) {
              scoreData.category_king_mention_count = ckd.mention_count;
            }
          }
        }
        for (let obd of ownedBrandPodcastData) {
          for (let scoreData of categoryPodcastsSummaryData) {
            if (obd.mention_count > highestMentionCount) {
              highestMentionCount = obd.mention_count;
              highestMentionKey = 'owned_brand_mention_count';
            }
            if (obd.month === scoreData.month) {
              scoreData.owned_brand_mention_count = obd.mention_count;
            }
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryPodcastsSummaryData,
            categoryPodcastsSummaryDataLoading: false,
            highestMentionKey,
            categoryKingName,
            ownersBrandName
          }));
        }
      });
    }
  }

  render () {
    return (
      <CardComponent
        title="Podcast Product Brand Mentions"
        body={
          <div>
            { this.state.categoryPodcastsSummaryDataLoading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.categoryPodcastsSummaryDataLoading &&
              <ResponsiveContainer width="99%" height={200}>
                <ComposedChart
                  data={this.state.categoryPodcastsSummaryData}
                  margin={{
                    left: 16
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month"/>
                  <YAxis
                    dataKey={this.state.highestMentionKey}
                    tick={{ fontSize: 12 }}
                    tickFormatter={(value) => {
                      return numberWithCommas(value);
                    }}
                  />
                  <Tooltip
                    formatter={(value) => numberWithCommas(value)}
                  />

                  <Area
                    name={
                      this.state.categoryKingName ?
                        `Leader, ${this.state.categoryKingName} Podcast Mentions` :
                        'Category King Podcast Mentions'
                    }
                    type="monotone"
                    dataKey="category_king_mention_count"
                    stroke="#007bff"
                    fillOpacity={.4}
                  />
                  <Line
                    name="Category Average Podcast Mentions"
                    type="monotone"
                    dataKey="category_avg_mention_gain"
                    strokeDasharray="10 10"
                    stroke="#009900"
                    strokeWidth={3}
                  />
                  <Line
                    name={
                      this.state.ownersBrandName ?
                        `${this.state.ownersBrandName} Podcast Mentions` :
                        'Your Brand Podcast Mentions'
                    }
                    type="monotone"
                    dataKey="owned_brand_mention_count"
                    stroke="#ffc107"
                    strokeWidth={3}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            }
          </div>
        }
        contextType="category"
        contextCategory={this.props.category}
        contextBrand={null}
        contextChannel="Podcast"
        contextChartName="Podcast Product Brand Mentions"
      />
    );
  }
};
