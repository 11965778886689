import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import LoginContainer from '../../containers/common/LoginContainer';
import AdminAuthenticationMessage from '../common/AdminAuthenticationMessage';
import { isAuthenticatedAdmin } from '../../utils/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, EXPORT_KEY, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCategoryId: undefined,
      selectedCategory: undefined,
      selectedMonth: undefined,
      monthsAvailable: [],
      selectedYear: undefined,
      yearsAvailable: [],
      errorMessage: undefined,
      exportToCsvLoading: false,
      exportToJsonLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.categories.length > 0) {
      this.onCategoryChange({
        currentTarget: {
          value: `${this.props.categories[0].category_type}-${this.props.categories[0].id}`
        }
      });
    }

  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.categories !== this.props.categories) {
      if (this.props.categories.length > 0) {
        this.onCategoryChange({
          currentTarget: {
            value: `${this.props.categories[0].category_type}-${this.props.categories[0].id}`
          }
        });
      } else {
        this.setState(() => ({
          selectedCategoryId: undefined,
        }));
      }
    }
  }

  onCategoryChange = (event) => {
    const selectedCategoryId = event.currentTarget.value;
    let selectedCategory;
    for (const category of this.props.categories) {
      if (selectedCategoryId === `${category.category_type}-${category.id}`) {
        selectedCategory = category;
      }
    }
    this.setState(() => ({
      selectedCategoryId,
      selectedCategory
    }), () => this.setYearsAvailable());
  }

  setYearsAvailable = () => {
    if (this.state.selectedCategory) {
      // only allow years since category creation
      const startDate = moment.utc(this.state.selectedCategory.created_at).startOf('year');
      const endDate = moment.utc().endOf('year');
      let date = moment(startDate);
      const yearsAvailable = [];
      while (date.isSameOrAfter(startDate) && date.isBefore(endDate)) {
        yearsAvailable.push(date.year());
        date.add(1, 'year');
      }

      this.setState(() => ({
        selectedYear: endDate.year(),
        yearsAvailable
      }), () => this.setMonthsAvailable());
    } else {
      this.setState(() => ({
        selectedMonth: undefined,
        monthsAvailable: [],
        selectedYear: undefined,
        yearsAvailable: [],
      }));
    }
  }

  setMonthsAvailable = () => {
    if (this.state.selectedCategory) {
      // only allow months since creation within selected year
      const startDate = moment.utc(this.state.selectedCategory.created_at).startOf('month');
      const endDate = moment.utc().endOf('month');
      let date = moment(startDate);
      const monthsAvailable = [];
      while (date.isSameOrAfter(startDate) && date.isBefore(endDate)) {
        if (date.year() === parseInt(this.state.selectedYear)) {
          monthsAvailable.push({
            value: date.month()+1,
            label: date.format('MMMM')
          });
        }
        date.add(1, 'month');
      }

      if (!this.state.selectedMonth && monthsAvailable.length > 1) {
        this.setState(() => ({
          selectedMonth: monthsAvailable[monthsAvailable.length-2].value,
          monthsAvailable,
        }));
      } else {
        // use selectedMonth if still available
        let selectedMonthFound = false;
        for (const m of monthsAvailable) {
          if (m.value === parseInt(this.state.selectedMonth)) {
            selectedMonthFound = true;
          }
        }

        if (selectedMonthFound) {
          this.setState(() => ({ monthsAvailable }));
        } else {
          this.setState(() => ({
            selectedMonth: monthsAvailable[0].value,
            monthsAvailable,
          }));
        }
      }
    } else {
      this.setState(() => ({
        selectedMonth: undefined,
        monthsAvailable: [],
        selectedYear: undefined,
        yearsAvailable: [],
      }));
    }
  }

  onSelectedMonthChange = (event) => {
    const selectedMonth = event.currentTarget.value;
    this.setState(() => ({ selectedMonth }));
  };

  onSelectedYearChange = (event) => {
    const selectedYear = event.currentTarget.value;
    this.setState(
      () => ({ selectedYear }),
      () => this.setMonthsAvailable()
    );
  };

  exportToCsv = () => {
    if (this.props.user && this.state.selectedCategory) {
      this.setState(() => ({
        errorMessage: undefined,
        exportToCsvLoading: true
      }));
      const selectedCategory = this.state.selectedCategory;
      const selectedMonth = this.state.selectedMonth;
      const selectedYear = this.state.selectedYear;
      const exportHeaders = JSON.parse(JSON.stringify(HEADERS));
      exportHeaders.headers['brandops-access-token'] = EXPORT_KEY;
      axios.get(
        `${LISTEN_ENDPOINT}/api/customer/scores-export?customer_name=BrandOps&category_name=${encodeURIComponent(this.state.selectedCategory.name)}&month=${this.state.selectedMonth}&year=${this.state.selectedYear}&represent_as_id=${this.props.user.customerId}`,
        exportHeaders
      ).then(response => {
        const csvData = [];
        let headerRowAdded = false;
        for (const brandData of response.data) {
          if (!headerRowAdded) {
            // add header row to csv data
            // put names/labels in quotes incase they include commas
            const headerRow = ['"Brand Name"', '"Overall Score"'];
            for (const s of brandData.stage_scores) {
              headerRow.push(`"${s.stage_name}"`);
            }
            for (const c of brandData.channel_scores) {
              headerRow.push(`"${c.name}"`);
            }
            for (const m of brandData.metric_values) {
              headerRow.push(`"${m.metric_label}"`)
            }
            csvData.push(headerRow);
            headerRowAdded = true;
          }

          // add brand data row
          const brandRow = [brandData.name || '', brandData.brand_scores.length > 0 ? brandData.brand_scores[0].score : ''];
          for (const s of brandData.stage_scores) {
            brandRow.push(s.score != null ? s.score : '');
          }
          for (const c of brandData.channel_scores) {
            brandRow.push(c.score != null ? c.score : '');
          }
          for (const m of brandData.metric_values) {
            brandRow.push(m.value != null ? m.value : '')
          }
          csvData.push(brandRow);
        }

        // process data into .csv
        let csvContent = 'data:text/csv;charset=utf-8,' + csvData.map(d => d.join(',')).join('\n');
        const dateDisplay = `${moment().month(selectedMonth-1).format('MMMM')} - ${selectedYear}`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `${selectedCategory.name} ${dateDisplay}.csv`);
        document.body.appendChild(link); // Required for FF
        // download the .csv
        link.click();

        if (this.state.isMounted) {
          this.setState(() => ({ exportToCsvLoading: false }));
        }
      }).catch(error => {
        console.error('Failed to export to CSV');
        if (this.state.isMounted) {
          if (error.response && error.response.data && error.response.data.message) {
            this.setState(() => ({
              errorMessage: error.response.data.message,
              exportToCsvLoading: false,
            }));
          } else {
            this.setState(() => ({
              errorMessage: 'Failed to export to CSV. Please try again later.',
              exportToCsvLoading: false,
            }));
          }
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  exportToJson = () => {
    if (this.props.user && this.state.selectedCategory) {
      this.setState(() => ({
        errorMessage: undefined,
        exportToJsonLoading: true
      }));
      const selectedCategory = this.state.selectedCategory;
      const selectedMonth = this.state.selectedMonth;
      const selectedYear = this.state.selectedYear;
      const exportHeaders = JSON.parse(JSON.stringify(HEADERS));
      exportHeaders.headers['brandops-access-token'] = EXPORT_KEY;
      axios.get(
        `${LISTEN_ENDPOINT}/api/customer/scores-export?customer_name=BrandOps&category_name=${encodeURIComponent(this.state.selectedCategory.name)}&month=${this.state.selectedMonth}&year=${this.state.selectedYear}&represent_as_id=${this.props.user.customerId}`,
        exportHeaders
      ).then(response => {
        // process data into .json
        let csvContent = 'data:text/json;charset=utf-8,' + JSON.stringify(response.data, null, 2);
        const dateDisplay = `${moment().month(selectedMonth-1).format('MMMM')}_${selectedYear}.json`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `${selectedCategory.name.toLowerCase()}_${dateDisplay.toLowerCase()}`);
        document.body.appendChild(link); // Required for FF
        // download the .csv
        link.click();

        if (this.state.isMounted) {
          this.setState(() => ({ exportToJsonLoading: false }));
        }
      }).catch(error => {
        console.error('Failed to export to JSON');
        if (this.state.isMounted) {
          this.setState(() => ({
            errorMessage: 'Failed to export to JSON. Please try again later.',
            exportToJsonLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="p-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        <AdminAuthenticationMessage user={this.props.user}/>
        { isAuthenticatedAdmin(this.props.user) &&
          <div>
            <div
              className="border rounded p-4"
              style={{ backgroundColor: '#e9ecef' }}
            >
              <div className="d-inline-block pr-2">
                <FontAwesomeIcon icon={faCloudDownloadAlt} size="2x" color="#454d54" />
              </div>
              <div className="d-inline-block">
                <h4>Export</h4>
              </div>
              <hr />
              <p className="mb-0">
                Export brand scores and metric values for a category.
              </p>
            </div>

            <div className="mt-3">
              <div
                className="d-inline-block mr-2"
                style={{
                  minWidth: '200px',
                  maxWidth: '300px'
                }}
              >
                <Form.Group>
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.selectedCategoryId}
                    onChange={this.onCategoryChange}
                  >
                    { this.props.categories.map(category => {
                        return (
                          <option
                            key={`exp-c-${category.category_type}-${category.id}`}
                            value={`${category.category_type}-${category.id}`}
                          >
                            {category.name}
                          </option>
                        )
                      })
                    }
                  </Form.Control>
                </Form.Group>
              </div>
              <div
                className="d-inline-block mr-2"
                style={{ width: '150px' }}
              >
                <Form.Group>
                  <Form.Label>Month</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.selectedMonth}
                    onChange={this.onSelectedMonthChange}
                  >
                    { this.state.monthsAvailable.map((month, i) => {
                        return (
                          <option
                            key={`e-ma-${i}`}
                            value={month.value}
                          >
                            {month.label}
                          </option>
                        )
                      })
                    }
                  </Form.Control>
                </Form.Group>
              </div>
              <div
                className="d-inline-block mr-2"
                style={{ width: '100px' }}
              >
                <Form.Group>
                  <Form.Label>Year</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.selectedYear}
                    onChange={this.onSelectedYearChange}
                  >
                    { this.state.yearsAvailable.map((year, i) => {
                        return (
                          <option
                            key={`e-ya-${year}`}
                            value={year}
                          >
                            {year}
                          </option>
                        )
                      })
                    }
                  </Form.Control>
                </Form.Group>
              </div>
            </div>

            <div>
              <Alert show={Boolean(this.state.errorMessage)} variant="danger">
                {this.state.errorMessage}
              </Alert>
              <Button
                variant="success"
                onClick={this.exportToCsv}
                disabled={
                  !this.state.selectedCategory ||
                  !this.state.selectedMonth ||
                  !this.state.selectedYear ||
                  this.state.exportToCsvLoading ||
                  this.state.exportToJsonLoading
                }
              >
                { this.state.exportToCsvLoading &&
                  <span className="mr-1">
                    <ClipLoader
                      size={15}
                      color="ffffff"
                    />
                  </span>
                }
                Export to CSV
              </Button>
              <Button
                className="ml-4"
                variant="primary"
                onClick={this.exportToJson}
                disabled={
                  !this.state.selectedCategory ||
                  !this.state.selectedMonth ||
                  !this.state.selectedYear ||
                  this.state.exportToCsvLoading ||
                  this.state.exportToJsonLoading
                }
              >
                { this.state.exportToJsonLoading &&
                  <span className="mr-1">
                    <ClipLoader
                      size={15}
                      color="ffffff"
                    />
                  </span>
                }
                Export to JSON
              </Button>
            </div>

            <div className="mt-4">
              <div className="d-inline-block">
                <Alert variant="info">
                  For advanced use cases, ask your BrandOps account executive about our API!
                </Alert>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
};
