import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import history from '../../routers/history';

export default class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: ''
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user && nextProps.user.email) {
      history.push('/');
    }
    return prevState;
  }

  onEmailChange = (event) => {
    const email = event.currentTarget.value;
    this.setState(() => ({ email }));
  };

  onPasswordChange = (event) => {
    const password = event.currentTarget.value;
    this.setState(() => ({ password }));
  };

  login = () => {
    this.props.handleUserLogin(this.state.email, this.state.password);
  };

  render() {
    return (
      <div className="m-4">
        <Row>
          <Col xs={0} lg={1}>
          </Col>
          <Col xs={12} lg={7} className="mb-4">
            <Card className="h-100 mb-4">
              <Card.Header>
                Login
              </Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      id="loginEmail"
                      type="email"
                      onChange={this.onEmailChange}
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          this.login();
                        }
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      id="loginPassword"
                      type="password"
                      onChange={this.onPasswordChange}
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          this.login();
                        }
                      }}
                    />
                    <div className="mt-1">
                      <Link to="/password-help">
                        Forgot password?
                      </Link>
                    </div>
                  </Form.Group>
                </Form>
                <Alert show={Boolean(this.props.user.error)} variant="danger">
                  {this.props.user.error}
                </Alert>
                <Button id="loginButton" variant="success" onClick={this.login}>
                  Login
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} lg={3} className="mb-4">
            <Card className="h-100">
              <Card.Header>
                Single Sign-On
              </Card.Header>
              <Card.Body>
                <div>
                  For single sign-on customers, you may login from your single sign on provider or use button below.
                </div>
                <div className="my-3 text-center">
                  <Link
                    className="btn btn-light btn-sm"
                    to="/sso/login"
                  >
                    <FontAwesomeIcon
                      className="mr-1"
                      icon={faKey}
                    />
                    SSO Login
                  </Link>
                </div>
                <div>
                  To get started with single sign-on please reach out to your BrandOps account executive or visit App & Data Connectors under Setup!
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={0} lg={1}>
          </Col>
        </Row>
      </div>
    );
  }
};
