import React from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';

export default class CustomerObjectiveUserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedUser: null,
      error: ''
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  onUserSelect = (selectedUser) => {
    this.setState(() => ({ selectedUser }));
  }

  saveCustomerObjectiveUser = () => {
    if (this.props.objectiveId && this.state.selectedUser) {
      this.setState(() => ({ error: '' }));
      const customerObjectiveUser = {
        customer_objective_id: this.props.objectiveId,
        customer_user_id: this.state.selectedUser.value,
      };
      axios.post(
        `${LISTEN_ENDPOINT}/api/customer-objective-to-users`,
        customerObjectiveUser,
        HEADERS
      ).then(response => {
        this.props.removeObjectiveUserForm(this.props.id);
        this.props.refreshObjectiveUsers();
      }).catch(error => {
        console.log('Error: unable to create customer objective to user');
        this.setState(() => ({ error: 'Unable to create objective user.' }));
      });
    } else {
      this.setState(() => ({ error: 'Please select a user.' }));
    }
  };

  render () {
    return (
      <div>
        <Form.Row>
          <Col>
            <Select
              value={this.state.selectedUser}
              options={
                this.props.users.map(user => {
                  return ({
                    value: user.id,
                    label: user.name_label,
                    user
                  })
                })
              }
              onChange={this.onUserSelect}
              placeholder="Select User"
            />
            { this.state.error &&
              <div className="text-danger" style={{ fontSize: '.75rem' }}>
                {this.state.error}
              </div>
            }
          </Col>
          <Col xs={3} lg={2}>
            <div className="mt-1 text-right">
              <Button
                className="px-1 py-0 mr-2"
                size="sm"
                variant="success"
                onClick={this.saveCustomerObjectiveUser}
              >
                <FontAwesomeIcon icon={faCheck}/>
              </Button>
              <Button
                size="sm"
                variant="danger"
                onClick={() => this.props.removeObjectiveUserForm(this.props.id)}
                style={{ padding: '0px 6px' }}
              >
                <FontAwesomeIcon icon={faTimes}/>
              </Button>
            </div>
          </Col>
        </Form.Row>
      </div>
    );
  }
};
