import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactTable from 'react-table';
import LoginContainer from '../../containers/common/LoginContainer';
import DateRangePicker from '../common/DateRangePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '../../utils/numbers';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class WebAnalyticsCommonSearchTerms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      webAnalyticsCommonSearchTerms: [],
      webAnalyticsCommonSearchTermsLoading: true,
      startDate: moment().utc().subtract(90, 'days').startOf('day'),
      endDate: moment().utc().endOf('day'),
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchWebAnalyticsCommonSearchTerms();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.customerId !== this.props.user.customerId) {
      this.fetchWebAnalyticsCommonSearchTerms();
    }
    if (
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      this.fetchWebAnalyticsCommonSearchTerms();
    }
  };

  fetchWebAnalyticsCommonSearchTerms = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({ webAnalyticsCommonSearchTermsLoading: true }));
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/ga-web-search-query-trends?company_id=${this.props.user.customerId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        const webAnalyticsCommonSearchTerms = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            webAnalyticsCommonSearchTerms,
            webAnalyticsCommonSearchTermsLoading: false,
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch web analytics common search terms data...`);
        if (this.state.isMounted) {
          this.setState(() => ({
            webAnalyticsCommonSearchTerms: {},
            webAnalyticsCommonSearchTermsLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  onFilterDatesChange = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  };

  render () {
    return (
      <div className="p-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        { (this.props.user.id && !this.props.companySetupChecklist.google_analytics_setup) &&
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              minHeight: 'calc(100vh - 200px)',
              maxHeight: 'calc(100vh - 200px)',
            }}
          >
            <div className="d-inline-block">
              <Alert variant="primary">
                Google Analytics is not setup for your company. You can get setup <Link to="/content-collectors/web-analytics">here</Link>.
              </Alert>
            </div>
          </div>
        }
        { (this.props.user.customerId && this.props.companySetupChecklist.google_analytics_setup) &&
          <div>
            <h4>
              <FontAwesomeIcon
                className="mr-2"
                icon={faSearch}
                color="#6c757d"
              />
              Google Search
            </h4>
            <h5 className="mb-2 p-2 bg-bops-blue text-light">
              Most Common Search Terms
              <span
                className="ml-4 mr-2"
                style={{ fontSize: '1rem', fontWeight: 'normal' }}
              >Date Range</span>
              <DateRangePicker
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                updateDates={this.onFilterDatesChange}
              />
            </h5>
            <div
              className="pre-scrollable"
              style={{
                minHeight: 'calc(100vh - 190px)',
                maxHeight: 'calc(100vh - 190px)',
                overflow: 'auto',
                overflowX: 'hidden'
              }}
            >
              { this.state.webAnalyticsCommonSearchTermsLoading &&
                <div className="m-4 text-center">
                  <ClipLoader size={100}/>
                </div>
              }
              { !this.state.webAnalyticsCommonSearchTermsLoading &&
                <div>
                  { this.state.webAnalyticsCommonSearchTerms.length === 0 &&
                    <Alert variant="warning">
                      For insights on the most common Google Search terms driving traffic, please connect BrandOps to Google Analytics. <Link to="/content-collectors/web-analytics">Learn More</Link>.
                    </Alert>
                  }
                  { this.state.webAnalyticsCommonSearchTerms.length > 0 &&
                    <ReactTable
                      className="-striped -highlight scrollable"
                      data={this.state.webAnalyticsCommonSearchTerms}
                      columns={[
                        {
                          Header: 'Search Frequency',
                          headerClassName: 'text-center wordwrap bg-dark text-light font-weight-bold border-light',
                          accessor: 'total',
                          sortable: true,
                          width: 120,
                          Cell: row => (
                            <div className="text-center">
                              {numberWithCommas(row.original.total)}
                            </div>
                          ),
                        },
                        {
                          Header: 'Search Query',
                          headerClassName: 'text-center wordwrap bg-dark text-light font-weight-bold border-light',
                          accessor: 'session_google_ads_query',
                          sortable: true,
                        },
                        {
                          Header: 'Ads Keyword',
                          headerClassName: 'text-center wordwrap bg-dark text-light font-weight-bold border-light',
                          accessor: 'session_google_ads_keyword',
                          sortable: true,
                        }
                      ]}
                      minRows={1}
                      defaultPageSize={20}
                      showPagination={true}
                      defaultSorted={[{ id: 'total', desc: true }]}
                      style={{ fontSize: '.875rem' }}
                    />
                  }
                </div>
              }
            </div>
          </div>
        }
      </div>
    );
  }
};
