import React from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class EmailSubscriptionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      availableProxyEmails: [],
      selectedProxyEmailId: undefined,
      errors: {}
    };
  }

  componentDidMount() {
    const availableProxyEmails = [];
    for (const proxyEmail of this.props.proxyEmails) {
      if (!this.props.emailSubscriptions.some(es => es.email === proxyEmail.email)) {
        availableProxyEmails.push(proxyEmail);
      }
    }
    let selectedProxyEmailId;
    if (availableProxyEmails.length > 0) {
      selectedProxyEmailId = availableProxyEmails[0].id;
    }
    this.setState(() => ({
      availableProxyEmails,
      selectedProxyEmailId
    }));
  };

  onProxyEmailChange = (event) => {
    const selectedProxyEmailId = event.currentTarget.value;
    this.setState(() => ({ selectedProxyEmailId }));
  };

  validForm = () => {
    const errors = {};
    if (!this.state.selectedProxyEmailId)
      errors.selectedProxyEmailId = true;

    this.setState(() => ({ errors }));
    if (Object.keys(errors).length === 0)
      return true;
    else
      return false;
  };

  saveEmailSubscription = () => {
    if (this.validForm()) {
      if (this.props.productBrandId) {
        const emailSubscription = {
          customer_user_proxy_email_id: this.state.selectedProxyEmailId,
          linked_product_brand_id: this.props.productBrandId
        };

        axios.post(
          `${LISTEN_ENDPOINT}/api/customer-user-proxy-email-subscriptions`,
          emailSubscription,
          HEADERS
        ).then(response => {
          this.props.removeEmailSubscriptionForm(this.props.id);
          this.props.refreshEmailSubscriptions();
        }).catch(error => {
          console.error('Error: unable to create email subscription');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  render () {
    return (
      <div>
        <Form.Control
          as="select"
          value={this.state.selectedProxyEmailId}
          onChange={this.onProxyEmailChange}
          isInvalid={this.state.errors.selectedProxyEmailId}
          size="sm"
        >
          { this.state.availableProxyEmails.map(proxyEmail => {
              return (
                <option
                  key={`peo-${proxyEmail.id}`}
                  value={proxyEmail.id}
                >
                  {proxyEmail.email}
                </option>
              )
            })
          }
        </Form.Control>
        <div>
          <div className="d-inline-block float-right">
            <Button
              className="px-1 py-0 mr-2"
              size="sm"
              variant="success"
              onClick={this.saveEmailSubscription}
            >
              <FontAwesomeIcon icon={faCheck}/>
            </Button>
            <Button
              className="px-1 py-0"
              size="sm"
              variant="danger"
              onClick={() => this.props.removeEmailSubscriptionForm(this.props.id)}
            >
              <FontAwesomeIcon icon={faTimes}/>
            </Button>
          </div>
        </div>
      </div>
    );
  }
};
