import React from 'react';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import Card from 'react-bootstrap/Card';
import Popup from 'reactjs-popup';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import MetricTooltipContainer from  '../../containers/metrics/MetricTooltipContainer';
import MetricDistributionSummaryModalContainer from '../../containers/metrics/MetricDistributionSummaryModalContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import {
  ComposedChart,
  Bar,
  Line,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  LabelList,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';
import {
  getPercentChange,
  intToShorthandString,
  numberWithCommas,
  round
} from '../../utils/numbers';
import { shorthandMonth } from '../../utils/dates';

export default class GeneralSummaryGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: `gsgc-${Math.random().toString(36).substring(7)}`,
      data: [],
      minDomain: 'auto',
      maxDomain: 'auto',
      intialMonth: undefined,
      endMonth: undefined,
      percentChange: 0,
      displayRegressionWarning: false,
      metricDistributionModalOpen: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.setData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setData();
    }
  }

  setData = () => {
    // replace null values with 0, but capture it was null to allow for initial value
    const propsData = Array.isArray(this.props.data) ? this.props.data : [];
    const data = [];
    let highestValue = 0;
    let lowestValue = 999999;
    let hasNegativeNumber = false;
    for (const d of propsData) {
      // check for highest value for domain
      if (d[this.props.dataKey] && d[this.props.dataKey] > highestValue) {
        highestValue = d[this.props.dataKey];
      }
      if (this.props.averageDataKey && d[this.props.averageDataKey] && d[this.props.averageDataKey] > highestValue) {
        highestValue = d[this.props.averageDataKey];
      }
      // check for lowest value for domain
      if (d[this.props.dataKey] && d[this.props.dataKey] < lowestValue) {
        lowestValue = d[this.props.dataKey];
        if (lowestValue < 0) {
          hasNegativeNumber = true;
        }
      }
      if (this.props.averageDataKey && d[this.props.averageDataKey] && d[this.props.averageDataKey] < lowestValue) {
        lowestValue = d[this.props.averageDataKey];
        if (lowestValue < 0) {
          hasNegativeNumber = true;
        }
      }

      if (d[this.props.dataKey] == null) {
        d[this.props.dataKey] = 0;
        d.was_null = true;
        data.push(d);
      } else {
        data.push(d);
      }
    }

    let minDomain;
    if (this.props.minDomainValue !== undefined) {
      minDomain = this.props.minDomainValue;
    } else if (this.props.averageDataKey && lowestValue) {
      let dataMin = Math.floor((lowestValue - (lowestValue * .25)) / 5) * 5;
      if (!hasNegativeNumber && dataMin < 0) {
        dataMin = 0;
      }
      minDomain = Math.floor(dataMin);
    } else {
      minDomain = 'auto';
    }

    let maxDomain;
    if (this.props.maxDomainValue !== undefined) {
      maxDomain = this.props.maxDomainValue;
    } else if (this.props.averageDataKey && highestValue) {
      let dataMax = Math.ceil((highestValue + (highestValue * .25)) / 5) * 5;
      maxDomain = Math.ceil(dataMax);
    } else {
      maxDomain = 'auto';
    }

    this.setState(
      () => ({
        data,
        minDomain,
        maxDomain,
      }),
      () => this.calculatePercentChange()
    );
  }

  calculatePercentChange = () => {
    if (this.props.showPercentChange) {
      let initialMonth, endMonth, percentChange;
      let displayRegressionWarning = false;
      if (Array.isArray(this.props.data) && this.props.data.length > 1) {
        let initialValue;
        for (const d of this.props.data) {
          if (!d.was_null) {
            initialMonth = d.month;
            initialValue = d[this.props.dataKey];
            break;
          }
        }

        let endValue = this.props.data[this.props.data.length - 2][this.props.dataKey];
        if (initialValue != null && endValue != null) {
          endMonth = this.props.data[this.props.data.length - 2].month;
          percentChange = getPercentChange(initialValue, endValue);
          if (percentChange != null) {
            percentChange = round(percentChange, 1);
          }
        }
      }

      if (
        (this.props.scoreType === 'high_is_good' && percentChange < 0) ||
        (this.props.scoreType === 'low_is_good' && percentChange > 0)
      ) {
        displayRegressionWarning = true;
      }
      this.setState(() => ({
        initialMonth,
        endMonth,
        percentChange,
        displayRegressionWarning
      }));
    }
  };

  openMetricDistributionModal = (selectedMetricName) => {
    this.setState(() => ({
      selectedMetricName,
      metricDistributionModalOpen: true
    }));
  };

  closeMetricDistributionModal = () => {
    this.setState(() => ({
      metricDistributionModalOpen: false
    }));
  };

  render () {
    return (
      <Card id={this.state.id} className="h-100">
        <Card.Header className="bg-white">
          { this.props.icon &&
            <React.Fragment>
              {this.props.icon}
            </React.Fragment>
          }
          { !this.props.icon &&
            <img
              className="mt-1 mr-2 align-top"
              src="/images/Reviews_Detail_Icon.png"
            />
          }
          { (this.props.metricName || this.props.description) &&
            <React.Fragment>
              <MetricTooltipContainer
                tooltipTrigger={
                  <b style={{ fontSize: '1.2rem' }}>
                    {this.props.title}
                  </b>
                }
                tooltipPosition="top"
                showChartDescription={true}
                metricName={this.props.metricName}
                openMetricDistributionModal={this.openMetricDistributionModal}
                description={this.props.description}
                chartDescription={this.props.chartDescription}
              />
              { this.props.metricName &&
                <MetricDistributionSummaryModalContainer
                  isOpen={this.state.metricDistributionModalOpen}
                  handleClose={this.closeMetricDistributionModal}
                  metricName={this.props.metricName}
                  audienceProfileId={
                    this.props.contextCategory ?
                      this.props.contextCategory.audience_profile_id :
                      null
                  }
                />
              }
            </React.Fragment>
          }
          { !(this.props.metricName || this.props.description) &&
            <b style={{ fontSize: '1.2rem' }}>
              {this.props.title}
            </b>
          }
          { !this.props.loading &&
            <div className="d-inline-block float-right">
              <SharePopUpContainer
                shareElementId={this.state.id}
                elementLabel={
                  this.props.shareClassName ?
                    this.props.shareClassName :
                    `${this.state.id}-img`
                }
                position="top"
                contextType={this.props.contextType}
                contextCategory={this.props.contextCategory}
                contextBrand={this.props.contextBrand}
                contextChannel={this.props.contextChannel}
                contextProperty={this.props.contextProperty}
                contextChartName={this.props.contextChartName}
                contextDateRange={this.props.contextDateRange}
              />
            </div>
          }
          { (!this.props.loading && this.props.showPercentChange) &&
            <div
              className="pt-2 pr-2 float-right"
              style={{
                fontSize: '.75rem',
                fontWeight: 'bold'
              }}
            >
              { this.state.percentChange != null &&
                <React.Fragment>
                  {`${shorthandMonth(this.state.initialMonth)} to ${shorthandMonth(this.state.endMonth)}: ${this.state.percentChange > 0 ? '+' : ''}${numberWithCommas(this.state.percentChange)}%`}
                  { this.state.displayRegressionWarning &&
                    <div className="ml-2 d-inline-block">
                      <Popup
                        position="top center"
                        on="hover"
                        contentStyle={{ borderRadius: '5px', width: '140px' }}
                        trigger={
                          <span>
                            <FontAwesomeIcon
                              icon={faExclamationTriangle}
                              color="#6c757d"
                            />
                          </span>
                        }
                      >
                        Metric regressed
                      </Popup>
                    </div>
                  }
                </React.Fragment>
              }
            </div>
          }
        </Card.Header>
        <Card.Body>
          <div>
            { this.props.loading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.props.loading &&
              <ResponsiveContainer width="99%" height={200}>
                <ComposedChart
                  data={this.state.data}
                  barSize={50}
                  margin={{
                    top: 16,
                    left: 10
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="month"
                    tickFormatter={(month) => {
                      return shorthandMonth(month);
                    }}
                  >
                    { this.props.privateDataLabel &&
                      <Label
                        value="Private Data"
                        position="top"
                        offset={85}
                      />
                    }
                  </XAxis>
                  <YAxis
                    dataKey={this.props.dataKey}
                    tick={{ fontSize: 12 }}
                    tickFormatter={(value) => {
                      if (this.props.percentValues) {
                        return `${numberWithCommas(value)}%`
                      } else {
                        if (this.props.yAxisShorthandInt) {
                          return intToShorthandString(value);
                        } else {
                          return numberWithCommas(value);
                        }
                      }
                    }}
                    domain={[
                      this.state.minDomain,
                      this.state.maxDomain
                    ]}
                    allowDecimals={
                      this.props.allowDecimals !== undefined ?
                        this.props.allowDecimals :
                        true
                    }
                  />
                  { this.props.showTooltip &&
                    <Tooltip
                      formatter={(value) => numberWithCommas(value)}
                    />
                  }
                  <Bar
                    name={this.props.dataLabel}
                    dataKey={this.props.dataKey}
                  >
                    <LabelList
                      dataKey={this.props.barLabelKey || this.props.dataKey}
                      fill="#000000"
                      position="top"
                      formatter={(value) => {
                        let formattedValue;
                        if (this.props.percentValues) {
                          formattedValue = `${value}%`;
                        } else if (this.props.costValues) {
                          formattedValue = `$${numberWithCommas(Math.round(value))}`;
                        } else if (this.props.customValues) {
                          formattedValue = value;
                        } else {
                          formattedValue = intToShorthandString(value);
                        }
                        return formattedValue;
                      }}
                      style={{ fontSize: '.75rem' }}
                    />
                    { this.props.data.map((entry, i) => {
                        if (this.props.data.length-1 === i) {
                          return (
                            <Cell key={`gsg-${this.props.title}-e-${i}`} fill="#a0a6ac"/>
                          )
                        } else if (this.props.negativeColor && entry[this.props.dataKey] < 0) {
                          return (
                            <Cell key={`gsg-${this.props.title}-e-${i}`} fill={this.props.negativeColor}/>
                          )
                        } else {
                          return (
                            <Cell key={`gsg-${this.props.title}-e-${i}`} fill="#007bff"/>
                          )
                        }
                      })
                    }
                  </Bar>
                  { this.props.showAverageData &&
                    <Line
                      name={this.props.averageDataLabel}
                      type="monotone"
                      dataKey={this.props.averageDataKey}
                      stroke="#ffc107"
                      dot={false}
                    />
                  }
                </ComposedChart>
              </ResponsiveContainer>
            }
          </div>
        </Card.Body>
      </Card>
    );
  }
};
