import React from 'react';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { numberWithCommas, round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';


export default class AudienceGeography extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      geographyData: [],
      geographyDisplayData: [],
      geographyDataLoading: false
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchGeographyData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.brand !== this.props.brand) {
      this.fetchGeographyData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchGeographyData = () => {
    if (this.props.brand) {
      this.setState(() => ({ geographyDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/audience-proxy?product_brand_id=${this.props.brand.id}&type=geography`,
        HEADERS
      ).then(response => {
        const geographyData = [];
        if (
          response.data &&
          response.data.data &&
          response.data.data.country
        ) {
          for (const countryData of response.data.data.country) {
            countryData.count = round(countryData.count, 1);
            geographyData.push(countryData);
          }
        }
        // only show top 10 geographies
        const geographyDisplayData = geographyData.slice(0, 10);


        if (this.state.isMounted) {
          this.setState(() => ({
            geographyData,
            geographyDisplayData,
            geographyDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch audience geography data.');
        if (this.state.isMounted) {
          this.setState(() => ({
            geographyData: [],
            geographyDisplayData: [],
            geographyDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  render () {
    return (
      <Card>
        <Card.Header className="bg-white font-weight-bold">
          <FontAwesomeIcon
            className="mr-2"
            icon={faGlobeAmericas}
            color="#4fa2f3"
            style={{ fontSize: '1.2rem' }}
          />
          Country Geographic Distribution
        </Card.Header>
        <Card.Body>
          { this.state.geographyDataLoading &&
            <div className="m-4 text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !this.state.geographyDataLoading &&
            <div>
              <ResponsiveContainer height={300} width="99%">
                <BarChart
                  data={this.state.geographyDisplayData}
                  layout="vertical"
                >
                  <XAxis
                    type="number"
                    tickFormatter={(count) => {
                      return `${numberWithCommas(count)}%`;
                    }}
                  />
                  <YAxis
                    type="category"
                    dataKey="name"
                    width={175}
                    interval={0}
                  />
                  <Tooltip
                    cursor={false}
                    formatter={(value) => `${numberWithCommas(value)}%`}
                  />
                  <Bar name="Share" dataKey="count" fill="#009900"/>
                </BarChart>
              </ResponsiveContainer>
            </div>
          }
        </Card.Body>
      </Card>
    );
  }
};
