import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

export default class MonthScrollPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {

  }

  onPreviousMonthClick = () => {
    if (moment(this.props.selectedMonth).subtract(1, 'month') >= this.props.minDate) {
      this.props.updateSelectedMonth(moment(this.props.selectedMonth).subtract(1, 'month'));
    }
  };

  onNextMonthClick = () => {
    if (moment(this.props.selectedMonth).add(1, 'month') <= this.props.maxDate) {
      this.props.updateSelectedMonth(moment(this.props.selectedMonth).add(1, 'month'));
    }
  };

  render() {
    return (
      <div className="d-inline-block mx-2">
        <FontAwesomeIcon
          className={
            (!this.props.loading && moment(this.props.selectedMonth).subtract(1, 'month') >= this.props.minDate) ?
              'mr-2 fa-button align-middle' :
              'mr-2 fa-disabled align-middle'
          }
          icon={faCaretLeft}
          onClick={this.onPreviousMonthClick}
          style={{ fontSize: '1.5rem' }}
        />
        <div className="d-inline-block text-center align-middle" style={{ width: '90px' }}>
          {this.props.selectedMonth.format('MMMM')}
        </div>
        <FontAwesomeIcon
          className={
            (!this.props.loading && moment(this.props.selectedMonth).add(1, 'month') <= this.props.maxDate) ?
              'ml-2 fa-button align-middle' :
              'ml-2 fa-disabled align-middle'
          }
          icon={faCaretRight}
          onClick={this.onNextMonthClick}
          style={{ fontSize: '1.5rem' }}
        />
      </div>
    );
  }
};
