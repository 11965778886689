export const setHeaderLabels = (categoryLabel, brandLabel) => {
  return (dispatch) => {
    dispatch(updateCategoryHeaderLabel(categoryLabel));
    dispatch(updateBrandHeaderLabel(brandLabel));
  };
};

export const updateCategoryHeaderLabel = (label) => ({
  type: 'UPDATE_CATEGORY_HEADER_LABEL',
  label
});

export const updateBrandHeaderLabel = (label) => ({
  type: 'UPDATE_BRAND_HEADER_LABEL',
  label
});
