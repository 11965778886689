import React from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ReactSelect from 'react-select';
import { validParseInt } from '../../utils/numbers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class AlertModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryId: '',
      brands: [],
      productBrandId: '',
      metricId: '',
      distributionList: '',
      alertType: 'either',
      percentChange: '50',
      changePeriod: '',
      sendToSlack: false,
      slackChannel: null,
      errors: {},
      errorMessage: '',
      saveLoading: false
    };
  }

  onOpen = () => {
    if (this.props.alert) {
      const categoryId = `${this.props.alert.category_type}-${this.props.alert.category_id}`;
      let brands = [];
      for (const c of this.props.categories) {
        if (categoryId === `${c.category_type}-${c.id}`) {
          brands = c.product_brands;
        }
      }
      const emailRecipients = [];
      let sendToSlack = false;
      let slackChannel = null;
      for (const r of this.props.alert.recipients) {
        if (r.recipient_type === 'email') {
          emailRecipients.push(r.recipient);
        } else if (r.recipient_type === 'slack') {
          sendToSlack = true;
          const slackSplit = r.recipient.split('---');
          slackChannel = {
            label: slackSplit[0],
            value: slackSplit[1],
          }
        }
      }
      this.setState(() => ({
        categoryId,
        brands,
        productBrandId: this.props.alert.product_brand_id,
        metricId: `${this.props.alert.alert_on}-${this.props.alert.identifier}`,
        distributionList: emailRecipients.join(', '),
        alertType: this.props.alert.alert_type,
        percentChange: this.props.alert.percent_change * 100,
        changePeriod: this.props.alert.change_period_time,
        sendToSlack,
        slackChannel,
        errors: {},
        errorMessage: '',
        saveLoading: false
      }));
    } else {
      // reset defaults
      let categoryId, productBrandId, metricId = '';
      let brands = [];
      if (this.props.categories.length > 0) {
        categoryId = `${this.props.categories[0].category_type}-${this.props.categories[0].id}`;
        if (this.props.categories[0].product_brands.length > 0) {
          brands = this.props.categories[0].product_brands;
          productBrandId = brands[0].id;
        }
      }
      if (this.props.alertMetrics.length > 0) {
        metricId = `${this.props.alertMetrics[0].alert_type || 'brand_metric'}-${this.props.alertMetrics[0].id}`;
      }
      this.setState(() => ({
        categoryId,
        brands,
        productBrandId,
        metricId,
        distributionList: this.props.user.email ? this.props.user.email : '',
        alertType: 'either',
        percentChange: '50',
        changePeriod: '',
        sendToSlack: false,
        slackChannel: null,
        errors: {},
        errorMessage: '',
        saveLoading: false
      }));
    }
  };

  onCategoryChange = (event) => {
    const categoryId = event.currentTarget.value;
    let brands = [];
    let productBrandId;
    for (const category of this.props.categories) {
      if (categoryId === `${category.category_type}-${category.id}`) {
        brands = category.product_brands;
        if (brands.length > 0) {
          productBrandId = brands[0].id;
        }
      }
    }
    this.setState(() => ({
      categoryId,
      brands,
      productBrandId,
    }));
  };

  onBrandChange = (event) => {
    const productBrandId = parseInt(event.currentTarget.value);
    this.setState(() => ({ productBrandId }));
  };

  onMetricChange = (event) => {
    const metricId = event.currentTarget.value;
    this.setState(() => ({ metricId }));
  };

  onAlertTypeChange = (event) => {
    const alertType = event.currentTarget.value;
    this.setState(() => ({ alertType }));
  };

  onPercentChange = (event) => {
    const percentChange = event.currentTarget.value;
    this.setState(() => ({ percentChange }));
  };

  onChangePeriodChange = (event) => {
    const changePeriod = event.currentTarget.value;
    this.setState(() => ({ changePeriod }));
  };

  onDistributionListChange = (event) => {
    const distributionList = event.currentTarget.value;
    this.setState(() => ({ distributionList }));
  };

  onSendToSlackChange = (event) => {
    const sendToSlack = event.currentTarget.checked;
    this.setState(() => ({ sendToSlack }));
  };

  onSlackChannelChange = (slackChannel) => {
    if (slackChannel !== this.state.slackChannel) {
      this.setState(() => ({ slackChannel }));
    }
  };

  validForm = () => {
    const errors = {};
    if (!this.state.categoryId) {
      errors.categoryId = true;
    }
    if (!this.state.productBrandId) {
      errors.productBrandId = true;
    }
    if (!this.state.metricId) {
      errors.metricId = true;
    }
    if (!validParseInt(this.state.percentChange)) {
      errors.percentChange = true;
    }
    if (!validParseInt(this.state.changePeriod)) {
      errors.changePeriod = true;
    }

    this.setState(() => ({ errors }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  saveAlert = () => {
    if (this.validForm()) {
      this.setState(() => ({ errorMessage: '', saveLoading: true }));
      const recipients = [];
      const emailRecipients = this.state.distributionList.split(',');
      // trim emails of whitespace
      emailRecipients.forEach((r, i) => {
        let recipient = r.trim();
        if (recipient) {
          recipients.push({
            type: 'email',
            recipient: r.trim()
          });
        }
      });
      if (this.state.sendToSlack && this.state.slackChannel) {
        recipients.push({
          type: 'slack',
          recipient: `${this.state.slackChannel.label}---${this.state.slackChannel.value}`
        });
      }

      if (this.props.alert) {
        // update
        const alert = {
          alert_type: this.state.alertType,
          percent_change: parseInt(this.state.percentChange) * .01,
          change_period_time: parseInt(this.state.changePeriod)
        }

        axios.put(
          `${LISTEN_ENDPOINT}/api/brand-alert/${this.props.alert.id}`,
          alert,
          HEADERS
        ).then(response => {
          const alert = response.data;
          this.updateAlertRecipients(alert.id, recipients);
        }).catch(error => {
          console.error('Error: failed to update alert');
          this.setState(() => ({
            errorMessage: 'Failed to update alert',
            saveLoading: false,
          }))
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      } else {
        // create
        const categorySplit = this.state.categoryId.split('-');
        const categoryType = categorySplit[0];
        const categoryId = categorySplit[1];
        const metricSplit = this.state.metricId.split('-');
        const metricType = metricSplit[0];
        let metricId = metricSplit[1];
        if (metricId === 'null') {
          metricId = null;
        }

        const alert = {
          category_type: categoryType,
          category_id: categoryId,
          alert_on: metricType,
          identifier: metricId,
          added_by_customer_id: this.props.customerId,
          product_brand_id: this.state.productBrandId,
          alert_type: this.state.alertType,
          percent_change: parseInt(this.state.percentChange) * .01,
          change_period_time: parseInt(this.state.changePeriod),
          recipients,
        }

        axios.post(
          `${LISTEN_ENDPOINT}/api/brand-alert`,
          alert,
          HEADERS
        ).then(response => {
          this.props.refreshAlerts();
          this.props.handleClose();
        }).catch(error => {
          console.error('Error: failed to create alert');
          this.setState(() => ({
            errorMessage: 'Failed to create alert',
            saveLoading: false,
          }));
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  updateAlertRecipients(alertId, alertRecipients) {
    const updateAlertRecipientRequests = [];
    const newRecipients = [];
    for (const alertRecipient of alertRecipients) {
      if (!this.props.alert.recipients.some(r => r.recipient === alertRecipient.recipient)) {
        newRecipients.push(alertRecipient);
      }
    }
    const removeRecipients = [];
    for (const existingRecipient of this.props.alert.recipients) {
      if (!alertRecipients.some(r => r.recipient === existingRecipient.recipient)) {
        removeRecipients.push(existingRecipient);
      }
    }

    for (const newRecipient of newRecipients) {
      const alertRecipientBody = {
        brand_alert_id: alertId,
        recipient_type: newRecipient.type,
        recipient: newRecipient.recipient
      };

      updateAlertRecipientRequests.push(
        axios.post(
          `${LISTEN_ENDPOINT}/api/brand-alert-recipients`,
          alertRecipientBody,
          HEADERS
        ).catch(error => {
          console.log('Error: unable to add alert recipient');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        })
      );
    }

    for (const removeRecipient of removeRecipients) {
      if (removeRecipient.recipient_id) {
        updateAlertRecipientRequests.push(
          axios.delete(
            `${LISTEN_ENDPOINT}/api/brand-alert-recipients/${removeRecipient.recipient_id}`,
            HEADERS
          ).catch(error => {
            console.log('Error: unable to remove alert recipient');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
          })
        );
      }
    }

    Promise.all(updateAlertRecipientRequests).then(() => {
      this.props.refreshAlerts();
      this.props.handleClose();
    });
  };

  render () {
    return (
      <Modal
        size="lg"
        show={this.props.isOpen}
        onHide={this.props.handleClose}
        onEntering={this.onOpen}
      >
        <Modal.Header
          className="bg-bops-blue text-light"
          closeButton
        >
          <Modal.Title>
            <FontAwesomeIcon
              className="mr-2"
              icon={faBell}
              color="#ffc107"
            />
            {this.props.alert ? 'Update ': 'Add '}
            Alert
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Row>
                <Col>
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.categoryId}
                    onChange={this.onCategoryChange}
                    isInvalid={this.state.errors.categoryId}
                    disabled={this.props.alert}
                  >
                    { this.props.categories.map(category => {
                        return (
                          <option
                            key={`${category.category_type}-${category.id}`}
                            value={`${category.category_type}-${category.id}`}
                          >
                            {category.name}
                          </option>
                        )
                      })
                    }
                  </Form.Control>
                </Col>
                <Col>
                  <Form.Label>Brand</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.productBrandId}
                    onChange={this.onBrandChange}
                    isInvalid={this.state.errors.productBrandId}
                    disabled={this.props.alert}
                  >
                    { this.state.brands.map(brand => {
                        return (
                          <option
                            key={`b${brand.id}`}
                            value={brand.id}
                          >
                            {brand.name}
                          </option>
                        )
                      })
                    }
                  </Form.Control>
                </Col>
              </Form.Row>
            </Form.Group>
            <Form.Group>
              <Form.Row>
                <Col xs={5}>
                  <Form.Label>Metric</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.metricId}
                    onChange={this.onMetricChange}
                    isInvalid={this.state.errors.metricId}
                    disabled={this.props.alert}
                  >
                    { this.props.alertMetrics.map(metric => {
                        return (
                          <option
                            key={`m-${metric.alert_type || 'brand_metric'}-${metric.id}`}
                            value={`${metric.alert_type || 'brand_metric'}-${metric.id}`}
                          >
                            {metric.metric_label}
                          </option>
                        )
                      })
                    }
                  </Form.Control>
                </Col>
                <Col xs={2}>
                  <Form.Label>Alert Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.alertType}
                    onChange={this.onAlertTypeChange}
                    isInvalid={this.state.errors.alertType}
                  >
                    <option value="good">Good</option>
                    <option value="bad">Bad</option>
                    <option value="either">Either</option>
                  </Form.Control>
                </Col>
                <Col xs={2}>
                  <Form.Label>Change Percent</Form.Label>
                  <Form.Control
                    type="text"
                    value={this.state.percentChange}
                    onChange={this.onPercentChange}
                    isInvalid={this.state.errors.percentChange}
                  />
                </Col>
                <Col xs={3}>
                  <Form.Label>Change Period</Form.Label>
                  <Form.Control
                    type="text"
                    value={this.state.changePeriod}
                    onChange={this.onChangePeriodChange}
                    placeholder="days"
                    isInvalid={this.state.errors.changePeriod}
                  />
                </Col>
              </Form.Row>
            </Form.Group>
            <Form.Group>
              <Form.Label>Email Distribution List</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                value={this.state.distributionList}
                onChange={this.onDistributionListChange}
                size="sm"
                isInvalid={this.state.errors.distributionList}
              />
              <div className="mt-1" style={{ fontSize: '.75rem' }}>
                Separate email recipients with a comma
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Check
                className="ml-2"
                type="checkbox"
                label="Send to Slack"
                checked={this.state.sendToSlack}
                onChange={this.onSendToSlackChange}
                disabled={this.props.slackChannels.length === 0}
                inline
              />
            <div className="d-inline-block" style={{ width: '300px' }}>
                <ReactSelect
                  cacheOptions
                  value={this.state.slackChannel}
                  options={
                    this.props.slackChannels.map(channel => {
                      return ({
                        label: `#${channel.name}`,
                        value: channel.id,
                        channel,
                      })
                    })
                  }
                  onChange={this.onSlackChannelChange}
                  aria-invalid={true}
                  isClearable={true}
                  placeholder="Slack channel..."
                  isDisabled={!this.state.sendToSlack}
                />
              </div>
            </Form.Group>
          </Form>
          <Alert show={Boolean(this.state.errorMessage)} variant="danger">
            {this.state.errorMessage}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.saveAlert} disabled={this.state.saveLoading}>
            { this.props.alert? 'Update' : 'Add' }
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};
