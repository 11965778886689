import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tooltip from 'reactjs-popup';
import CommentReviewScores from './CommentReviewScores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faLink, faReply } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

export default class Tweet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  parseURLs = (text) => {
    return text.replace(/[A-Za-z]+:\/\/[A-Za-z0-9-_]+\.[A-Za-z0-9-_:%&~\?\/.=]+/g, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };

  parseUsernames = (text) => {
    return text.replace(/[@]+[A-Za-z0-9-_]+/g, (handle) => {
      var username = handle.replace('@','');
      return `<a href="http://twitter.com/${username}" target="_blank">${handle}</a>`;
    });
  };

  parseHashtags = (text) => {
    return text.replace(/[#]+[A-Za-z0-9-_]+/g, function(hashtag) {
      var hashtagUrlEncoded = hashtag.replace('#','%23');
      return `<a href="http://twitter.com/search?q=${hashtagUrlEncoded}" target="_blank">${hashtag}</a>`;
    });
  };

  parseTextForHTMLReplacements = (text) => {
    text = this.parseURLs(text);
    text = this.parseUsernames(text);
    text = this.parseHashtags(text);
    return text;
  };

  render () {
    return (
      <div className="mb-2 bg-light border rounded" style={{ fontSize: '.875rem' }}>
        <Row noGutters>
          <Col md={3} sm={12} className="px-4 py-2 border-right">
            <Row>
              <Col>
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faTwitter}
                  color="#08a0e9"
                  size="lg"
                />
                {new Date(this.props.comment.tweet.tweet_created_at).toLocaleString()}
                <FontAwesomeIcon
                  className="float-right"
                  icon={faLink}
                  color="#08a0e9"
                  size="lg"
                  onClick={() => {
                    window.open(`https://twitter.com/user/status/${this.props.comment.tweet.tweet_id}`, '_blank');
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                { this.props.comment.tweet.twit_user.profile_image_url &&
                  <div className="mt-2 mr-2 float-left">
                    <img
                      className="rounded-circle"
                      src={this.props.comment.tweet.twit_user.profile_image_url.replace('http://', 'https://')}
                      alt=""
                      crossOrigin="anonymous"
                      style={{ width: '30px' }}
                    />
                  </div>
                }
                <div>
                  <div>
                    {this.props.comment.tweet.twit_user.name}
                    { this.props.customerTwitUserTags[this.props.comment.tweet.twit_user.screen_name.toLowerCase()] &&
                      <Tooltip
                        trigger = {
                          <span>
                            <FontAwesomeIcon
                              className="mx-2"
                              icon={faExclamationTriangle}
                              size="lg"
                            />
                          </span>
                        }
                        position="top center"
                        on="hover"
                        contentStyle={{ borderRadius: '5px' }}
                      >
                        <div>
                          {this.props.customerTwitUserTags[this.props.comment.tweet.twit_user.screen_name.toLowerCase()].join(', ')}
                        </div>
                      </Tooltip>
                    }
                  </div>
                  <div>
                    <a
                      href={`http://twitter.com/${this.props.comment.tweet.twit_user.screen_name}`}
                      target="_blank"
                    >
                      {`@${this.props.comment.tweet.twit_user.screen_name}`}
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={9} sm={12} className="px-4 py-2">
            { this.props.comment.tweet.in_reply_to &&
              <div style={{ fontSize: '.75rem' }}>
                <div dangerouslySetInnerHTML={{__html: this.parseTextForHTMLReplacements(this.props.comment.tweet.in_reply_to.tweet_text)}}/>
              </div>
            }
            <div>
              { this.props.comment.tweet.in_reply_to &&
                <FontAwesomeIcon className="mr-2" icon={faReply} />
              }
              <div
                className="d-inline"
                dangerouslySetInnerHTML={{__html: this.parseTextForHTMLReplacements(this.props.comment.tweet.tweet_text)}}
              />
              <div className="mt-2">
                <CommentReviewScores
                  comment={this.props.comment}
                  refreshComments={this.props.refreshComments}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};
