import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../../common/CardComponent';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class CommentVolume extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      commentVolumeData: [],
      commentVolumeDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.loadCommentVolumeData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.topicId !== this.props.topicId ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter
    ) {
      this.loadCommentVolumeData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  loadCommentVolumeData = () => {
    this.setState(() => ({ commentVolumeDataLoading: true }));
    const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
    axios.get(
      `${LISTEN_ENDPOINT}/api/topics/${this.props.topicId}/comment-volume-trend?start-date=${formattedStartDate}&end-date=${formattedEndDate}`,
      HEADERS
    ).then(response => {
      const commentVolumeData = response.data;
      if (this.state.isMounted) {
        this.setState(() => ({
          commentVolumeData,
          commentVolumeDataLoading: false
        }));
      }
    }).catch(error => {
      if (this.state.isMounted) {
        this.setState(() => ({
          commentVolumeData: [],
          commentVolumeDataLoading: false
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  toggleOptions = () => {
    this.setState((prevState) => ({ showConfig: !prevState.showConfig }));
  };

  render () {
    return (
      <CardComponent
        title="Comment Volume"
        icon={
          <img
            className="mr-2 align-top"
            src="/images/Comment_Volume_Icon.png"
          />
        }
        description="Comment Volume allows you to visualize the amount of comments for the topic"
        body={
          <div>
            { this.state.commentVolumeDataLoading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.commentVolumeDataLoading &&
              <ResponsiveContainer height={300} width="99%">
                <LineChart data={this.state.commentVolumeData}>
                  <XAxis
                    dataKey="date"
                    tickFormatter={(date) => {
                      return moment(date).endOf('day').format('MM/DD');
                    }}
                  />
                  <YAxis
                    allowDecimals={false}
                  />
                  <Tooltip labelFormatter={(label) => moment(label).format('dddd, MMMM Do YYYY')}/>
                  <CartesianGrid strokeDasharray="5 5"/>
                  <Line name="Total Comments" type="monotone" dataKey="total_comments" stroke="#ff4d4d"/>
                  <Line name="Twitter" type="monotone" dataKey="twitter_comments" stroke="#1da1f2"/>
                  <Line name="Facebook" type="monotone" dataKey="facebook_comments" stroke="#3b5998"/>
                  <Line name="Other" type="monotone" dataKey="other_comments" stroke="#009900"/>
                </LineChart>
              </ResponsiveContainer>
            }
          </div>
        }
        contextType="brand"
        contextCategory={this.props.category}
        contextBrand={this.props.brand}
        contextChannel="Social"
        contextChartName="Comment Volume"
      />
    );
  }
};
