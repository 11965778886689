import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Confetti from 'react-dom-confetti';
import LoginContainer from '../../../containers/common/LoginContainer';
import AdminAuthenticationMessage from '../../common/AdminAuthenticationMessage';
import { isAuthenticatedAdmin } from '../../../utils/auth';
import SlackTokenModal from './SlackTokenModal';
import DeleteConfirmationModal from '../../common/DeleteConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class SlackSetup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companySlackToken: undefined,
      companySlackTokenLoading: false,
      triggerConfetti: false,
      slackTokenModalOpen: false,
      deleteConfirmationModalOpen: false,
      deleteSlackTokenId: undefined,
      deleteSlackTokenLabel: undefined,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchCompanySlackToken();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.customerId !== this.props.user.customerId) {
      this.fetchCompanySlackToken();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchCompanySlackToken = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({ companySlackTokenLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/company-slack-tokens?company_id=${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        let companySlackToken;
        if (response.data.length > 0) {
          companySlackToken = response.data[0];
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            companySlackToken,
            companySlackTokenLoading: false,
          }))
        }
      }).catch(error => {
        console.error('Error: unable to fetch company slack token');
        if (this.state.isMounted) {
          this.setState(() => ({
            companySlackToken: undefined,
             companySlackTokenLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  openSlackTokenModal = () => {
    this.setState(() => ({ slackTokenModalOpen: true }));
  };

  closeSlackTokenModal = () => {
    this.setState(() => ({ slackTokenModalOpen: false }));
  };

  triggerConfetti = () => {
    this.setState(() => ({ triggerConfetti: true }));
  };

  openDeleteConfirmationModal = () => {
    this.setState(() => ({
      deleteConfirmationModalOpen: true,
      deleteSlackTokenId: this.state.companySlackToken.id,
      deleteSlackTokenLabel: `************${this.state.companySlackToken.token_ending}`
    }));
  };

  closeDeleteConfirmationModal = () => {
    this.setState(() => ({
      deleteConfirmationModalOpen: false,
      deleteSlackTokenId: undefined,
      deleteSlackTokenLabel: undefined
    }));
  };

  deleteSlackToken = () => {
    if (this.state.deleteSlackTokenId) {
      axios.delete(
        `${LISTEN_ENDPOINT}/api/company-slack-tokens/${this.state.deleteSlackTokenId}`,
        HEADERS
      ).then(response => {
        this.closeDeleteConfirmationModal();
        this.fetchCompanySlackToken();
        this.props.fetchSlackChannels(this.props.user.customerId);
      }).catch(error => {
        console.log('Error: failed to delete company slack token');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="m-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        <AdminAuthenticationMessage user={this.props.user}/>
        { isAuthenticatedAdmin(this.props.user) &&
          <div>
            <div
              className="border rounded p-4"
              style={{ backgroundColor: '#e9ecef' }}
            >
              <div className="d-inline-block pr-2">
                <FontAwesomeIcon icon={faEnvelope} size="2x" color="#454d54" />
              </div>
              <div className="d-inline-block">
                <h4>Slack</h4>
              </div>
              <p>Connect slack workspace to share insights.</p>
              <hr className="my-4" />
              <p>Slack integration is handled by setting up an App within your Slack workspace and creating a bot token. Learn more below.</p>
            </div>
            { this.props.user.customerId &&
              <Row className="mt-4">
                <Col>
                  <div>
                    { this.state.companySlackToken &&
                      <div>
                        <b>Existing Token</b>
                        <div className="mt-2">
                          <div className="d-inline-block ml-4" style={{ width: '200px' }}>
                            {`************${this.state.companySlackToken.token_ending}`}
                          </div>
                          <div className="d-inline-block">
                            <Button
                              variant="link"
                              onClick={this.openSlackTokenModal}
                            >
                              update
                            </Button>
                          </div>
                          <div className="d-inline-block">
                            <Button
                              variant="link"
                              onClick={this.openDeleteConfirmationModal}
                            >
                              delete
                            </Button>
                          </div>
                        </div>
                      </div>
                    }
                    { (!this.state.companySlackToken && !this.state.companySlackTokenLoading) &&
                      <div>
                        <Button
                          variant="primary"
                          onClick={this.openSlackTokenModal}
                        >
                          Add Bot Token
                        </Button>
                      </div>
                    }
                  </div>
                </Col>
                <Col>
                  <Card>
                    <Card.Header className="bg-bops-blue text-light font-weight-bold">
                      Bot Token
                    </Card.Header>
                    <Card.Body
                      className="pre-scrollable"
                      style={{
                        maxHeight: 'calc(100vh - 380px)',
                        overflow: 'auto'
                      }}
                    >
                      <div>
                        <h5>Getting Started</h5>
                        <ol>
                          <li>
                            After creating your app, go to <a href="https://api.slack.com/apps" target="_blank">https://api.slack.com/apps</a>.
                          </li>
                          <li>
                            Click the app you created.
                          </li>
                          <li>
                            In the left menu select OAuth & Permissions.
                          </li>
                          <li>
                            Under OAuth Tokens for Your Workspace, copy your bot token.
                          </li>
                          <li>
                            Add the Bot Token to BrandOps.
                          </li>
                        </ol>
                        <h5>Slack Documentation</h5>
                        <ul>
                          <li>
                            <a href="https://api.slack.com/authentication/token-types" target="_blank">
                              Learning about Access tokens & Bot tokens
                            </a>
                          </li>
                          <li>
                            <a href="https://api.slack.com/authentication/basics" target="_blank">
                              Basic app setup
                            </a>
                          </li>
                        </ul>
                        <h5>Permissions</h5>
                        <p>
                          {'The bot will need the capabilities to view and join channels, post messages, and write files. Below is a list of necessary permissions to allow the Slack integration to function properly.'}
                        </p>
                        <ul>
                          <li>channels:read</li>
                          <li>channels:join</li>
                          <li>chat:write</li>
                          <li>chat:write.public</li>
                          <li>files:read</li>
                          <li>files:write</li>
                          <li>groups:read</li>
                          <li>im:read</li>
                          <li>mpim:read</li>
                          <li>incoming-webhook</li>
                        </ul>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            }
            <Confetti
              className="confetti-center"
              active={this.state.triggerConfetti}
              config={{
                spread: 300
              }}
            />

            <SlackTokenModal
              isOpen={this.state.slackTokenModalOpen}
              handleClose={this.closeSlackTokenModal}
              companySlackToken={this.state.companySlackToken}
              companyId={this.props.user.customerId}
              refreshCompanySlackToken={this.fetchCompanySlackToken}
              refreshSlackChannels={this.props.fetchSlackChannels}
              triggerConfetti={this.triggerConfetti}
            />
            <DeleteConfirmationModal
              isOpen={this.state.deleteConfirmationModalOpen}
              handleClose={this.closeDeleteConfirmationModal}
              resourceName={this.state.deleteSlackTokenLabel}
              deleteResource={this.deleteSlackToken}
            />
          </div>
        }
      </div>
    );
  }
};
