import { connect } from 'react-redux';
import LicenseUsage from '../../components/common/LicenseUsage';

const mapStateToProps = (state) => ({
  user: state.user,
  categories: state.categories,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(LicenseUsage);
