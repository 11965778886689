import React from 'react';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import Table from 'react-bootstrap/Table';
import CategoryShareOfEverythingGraphs from './CategoryShareOfEverythingGraphs';
import DateRangePicker from '../common/DateRangePicker';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import { round } from '../../utils/numbers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryShareOfEverything extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shareOfEverything: {},
      shareOfEarned: {},
      shareOfOwned: {},
      shareOfEverythingLoading: false,
      productBrands: [],
      earnedShareOfs: [
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-reviews',
          name: 'Share of Reviews',
          key: 'shareOfReviews',
          countKey: 'review_count',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-reviews',
          additionalParams: '&condition=gte&threshold=4',
          name: 'Share of 4 & 5 Star Reviews',
          key: 'shareOfGoodReviews',
          countKey: 'review_count',
          type: 'earned'
        },
        {
          endpoint: CONTENT_SCRAPING_ENDPOINT,
          pathname: '/api/category-share-of-employer-reviews',
          name: 'Share of Employer Reviews',
          key: 'shareOfEmployerReviews',
          countKey: 'review_count',
          type: 'earned'
        },
        {
          endpoint: CONTENT_SCRAPING_ENDPOINT,
          pathname: '/api/category-share-of-employer-reviews',
          additionalParams: '&condition=gte&threshold=4',
          name: 'Share of 4 & 5 Star Employer Reviews',
          key: 'shareOfGoodEmployerReviews',
          countKey: 'review_count',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-video-stats',
          name: 'Share of Youtube Followers',
          key: 'shareOfYoutubeFollowers',
          countKey: 'subscribers_count',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-youtube-share-of-net-followers',
          name: 'Share of Youtube Net New Followers',
          key: 'shareOfYoutubeNetNewFollowers',
          countKey: 'net_followers',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-video-stats',
          name: 'Share of Youtube Net New Views',
          key: 'shareOfYoutubeViews',
          countKey: 'net_views',
          type: 'earned'
        },
        {
          endpoint: CONTENT_SCRAPING_ENDPOINT,
          pathname: '/api/category-linkedin-share-of-followers',
          name: 'Share of LinkedIn Followers',
          key: 'shareOfLinkedInFollowers',
          countKey: 'followers',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-linkedin-share-of-net-followers',
          name: 'Share of LinkedIn Net New Followers',
          key: 'shareOfLinkedInNetNewFollowers',
          countKey: 'net_followers',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-linkedin-share-stats',
          name: 'Share of LinkedIn Engagement',
          key: 'shareOfLinkedInEngagement',
          countKey: 'engagement',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-twitter-share-of-followers',
          name: 'Share of Twitter Followers',
          key: 'shareOfTwitterFollowers',
          countKey: 'followers',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-twitter-share-of-net-followers',
          name: 'Share of Twitter Net New Followers',
          key: 'shareOfTwitterNetNewFollowers',
          countKey: 'net_followers',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-twitter-share-stats',
          name: 'Share of Twitter Mentions',
          key: 'shareOfTwitterMentions',
          countKey: 'mentions',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-twitter-share-stats',
          name: 'Share of Twitter Promoter Mentions',
          key: 'shareOfTwitterPromoterMentions',
          countKey: 'promoters',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-facebook-share-of-followers',
          name: 'Share of Facebook Followers',
          key: 'shareOfFacebookFollowers',
          countKey: 'followers',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-facebook-share-of-net-followers',
          name: 'Share of Facebook Net New Followers',
          key: 'shareOfFacebookNetNewFollowers',
          countKey: 'net_followers',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-instagram-share-of-followers',
          name: 'Share of Instagram Followers',
          key: 'shareOfInstagramFollowers',
          countKey: 'followers',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-instagram-share-of-net-followers',
          name: 'Share of Instagram Net New Followers',
          key: 'shareOfInstagramNetNewFollowers',
          countKey: 'net_followers',
          type: 'earned'
        },
        {
          endpoint: CONTENT_SCRAPING_ENDPOINT,
          pathname: '/api/category-share-of-news-mention',
          name: 'Share of News Mentions',
          key: 'shareOfNewsMentions',
          countKey: 'total_mentions',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-podcast-stats',
          name: 'Share of Podcast Mentions',
          key: 'shareOfPodcastMentions',
          countKey: 'mention_count',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-web-stats',
          name: 'Share of Organic Traffic',
          key: 'shareOfOrganicTraffic',
          countKey: 'organic_traffic',
          type: 'earned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-serp',
          name: 'Share of Google Organic Results',
          key: 'shareOfOrganicSearchResults',
          countKey: 'serp_points',
          type: 'earned',
          additionalParams: '&type=google-organic',
        },
      ],
      ownedShareOfs: [
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-blogs',
          name: 'Share of Blog Posts',
          key: 'shareOfBlogPosts',
          countKey: 'blog_count',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-linkedin-share-stats',
          name: 'Share of LinkedIn Posts',
          key: 'shareOfLinkedInPosts',
          countKey: 'post_count',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-twitter-share-stats',
          name: 'Share of Owned Tweets',
          key: 'shareOfOwnedTweets',
          countKey: 'owned_tweets',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-facebook-share-of-owned-posts',
          name: 'Share of Facebook Posts',
          key: 'shareOfFacebookPosts',
          countKey: 'facebook_owned_posts',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-new-videos',
          name: 'Share of Youtube New Videos',
          key: 'shareOfYoutubeNewVideos',
          countKey: 'new_video_count',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-podcast-stats',
          name: 'Share of Owned Podcast Episodes',
          key: 'shareOfOwnedPodcastEpisodes',
          countKey: 'owned_episode_count',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-webinars',
          name: 'Share of Webinars',
          key: 'shareOfWebinars',
          countKey: 'total_webinars',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-press-release-stats',
          name: 'Share of Press Releases',
          key: 'shareOfPressReleases',
          countKey: 'press_release_count',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-email-stats',
          name: 'Share of Emails',
          key: 'shareOfEmails',
          countKey: 'email_count',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-web-pages',
          name: 'Share of New Web Pages',
          key: 'shareOfNewWebPages',
          countKey: 'count_new',
          type: 'owned'
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-web-pages',
          name: 'Share of Modified Web Pages',
          key: 'shareOfModifiedWebPages',
          countKey: 'count_modified',
          type: 'owned'
        },
      ],
      paidShareOfs: [
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-of-serp',
          name: 'Share of Google Ad Impressions',
          key: 'shareOfPaidSearchResults',
          countKey: 'serp_points',
          type: 'paid',
          additionalParams: '&type=google-paid',
        },
        {
          endpoint: LISTEN_ENDPOINT,
          pathname: '/api/category-share-ads',
          name: 'Share of Google Adword Spend',
          key: 'shareOfGoogleAdwordSpend',
          countKey: 'adwords_costs',
          type: 'paid'
        }
      ],
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.setProductBrands();
    this.fetchShareOfEverything();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      this.setProductBrands();
      this.fetchShareOfEverything();
    }
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      this.fetchShareOfEverything();
    }
  };

  setProductBrands = () => {
    if (this.props.category && this.props.category.product_brands) {
      const myBrands = [];
      const filteredProductBrands = this.props.category.product_brands.filter(pb => {
        if (pb.company_id === this.props.user.customerId) {
          myBrands.push(pb);
        } else {
          return pb;
        }
      });
      const productBrands = myBrands.concat(filteredProductBrands).map(pb => pb.name);
      this.setState(() => ({ productBrands }));
    }
  };

  fetchShareOfEverything = () => {
    if (this.props.category) {
      this.setState(() => ({ shareOfEverythingLoading: true }));
      const shareOfs = this.state.earnedShareOfs.concat(this.state.ownedShareOfs).concat(this.state.paidShareOfs);
      const formattedStartDate = this.props.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.props.endDate.format('YYYY-MM-DD HH:mm:ss');
      const shareOfEverythingRequests = [];
      for (const s of shareOfs) {
        this.setState(() => ({ [`${s.key}Loading`]: true }));
        const additionalParams = s.additionalParams || '';
        shareOfEverythingRequests.push(
          axios.get(
            `${s.endpoint}${s.pathname}?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}&group_by=custom${additionalParams}`,
            HEADERS
          ).then(response => {
            const shareOfAttributes = this.getShareOfAttributes(
              s.name,
              s.type,
              response.data,
              s.countKey
            );

            if (this.state.isMounted) {
              this.setState(() => ({
                [s.key]: shareOfAttributes,
                [`${s.key}Loading`]: false,
              }));
            }
            return { key: s.key, [s.key]: shareOfAttributes };
          }).catch(error => {
            console.error(`Error: unable to load Category ${s.name}...`);
            if (this.state.isMounted) {
              this.setState(() => ({
                [s.key]: {
                  name: s.name,
                  type: s.type,
                  highest_count: 0,
                  total: 0
                },
                [`${s.key}Loading`]: false,
              }));
            }
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return {
              key: s.key,
              [s.key]: {
                name: s.name,
                type: s.type,
                highest_count: 0,
                total: 0
              }
            };
          })
        );
      }

      Promise.all(shareOfEverythingRequests).then(responses => {
        const shareOfEverything = {
          highest_percent: 0,
          total_share_of: 0
        };
        const shareOfEarned = {
          highest_percent: 0,
          total_share_of: 0
        };
        const shareOfOwned = {
          highest_percent: 0,
          total_share_of: 0
        };
        const shareOfPaid = {
          highest_percent: 0,
          total_share_of: 0
        };
        const respIgnoredKeys = ['name', 'type', 'total', 'highest_count'];
        const aggIgnoredKeys = ['highest_percent', 'total_share_of'];
        for (const response of responses) {
          if (response && response.key) {
            // add totals for share of everything/eared/owned
            shareOfEverything.total_share_of += 1;
            if (response[response.key].type === 'earned') {
              shareOfEarned.total_share_of += 1;
            } else if (response[response.key].type === 'owned') {
              shareOfOwned.total_share_of += 1;
            } else if (response[response.key].type === 'paid') {
              shareOfPaid.total_share_of += 1;
            }

            // add values for share of everything/eared/owned
            for (const [statKey, statValue] of Object.entries(response[response.key])) {
              if (!respIgnoredKeys.includes(statKey)) {
                if (shareOfEverything[statKey]) {
                  shareOfEverything[statKey].total_percent += statValue.percent;
                } else {
                  shareOfEverything[statKey] = {
                    total_percent: statValue.percent
                  }
                }
                // check everything highest total percent
                if (shareOfEverything[statKey].total_percent > shareOfEverything.highest_percent) {
                  shareOfEverything.highest_percent = shareOfEverything[statKey].total_percent;
                }

                if (response[response.key].type === 'earned') {
                  if (shareOfEarned[statKey]) {
                    shareOfEarned[statKey].total_percent += statValue.percent;
                  } else {
                    shareOfEarned[statKey] = {
                      total_percent: statValue.percent
                    }
                  }
                  // check earned highest total percent
                  if (shareOfEarned[statKey].total_percent > shareOfEarned.highest_percent) {
                    shareOfEarned.highest_percent = shareOfEarned[statKey].total_percent;
                  }
                } else if (response[response.key].type === 'owned') {
                  if (shareOfOwned[statKey]) {
                    shareOfOwned[statKey].total_percent += statValue.percent;
                  } else {
                    shareOfOwned[statKey] = {
                      total_percent: statValue.percent,
                    }
                  }
                  // check owned highest total percent
                  if (shareOfOwned[statKey].total_percent > shareOfOwned.highest_percent) {
                    shareOfOwned.highest_percent = shareOfOwned[statKey].total_percent;
                  }
                } else if (response[response.key].type === 'paid') {
                  if (shareOfPaid[statKey]) {
                    shareOfPaid[statKey].total_percent += statValue.percent;
                  } else {
                    shareOfPaid[statKey] = {
                      total_percent: statValue.percent,
                    }
                  }
                  // check paid highest total percent
                  if (shareOfPaid[statKey].total_percent > shareOfPaid.highest_percent) {
                    shareOfPaid.highest_percent = shareOfPaid[statKey].total_percent;
                  }
                }
              }
            }
          }
        }
        // loop everything to determine percents
        for (const [key, value] of Object.entries(shareOfEverything)) {
          if (!aggIgnoredKeys.includes(key)) {
            if (shareOfEverything.total_share_of) {
              shareOfEverything[key].percent = round((value.total_percent/shareOfEverything.total_share_of), 1);
            } else {
              shareOfEverything[key].percent = 0;
            }
          }
        }
        // loop earned to determine percents
        for (const [key, value] of Object.entries(shareOfEarned)) {
          if (!aggIgnoredKeys.includes(key)) {
            if (shareOfEarned.total_share_of) {
              shareOfEarned[key].percent = round((value.total_percent/shareOfEarned.total_share_of), 1);
            } else {
              shareOfEarned[key].percent = 0;
            }
          }
        }
        // loop owned to determine percents
        for (const [key, value] of Object.entries(shareOfOwned)) {
          if (!aggIgnoredKeys.includes(key)) {
            if (shareOfOwned.total_share_of) {
              shareOfOwned[key].percent = round((value.total_percent/shareOfOwned.total_share_of), 1);
            } else {
              shareOfOwned[key].percent = 0;
            }
          }
        }
        // loop paid to determine percents
        for (const [key, value] of Object.entries(shareOfPaid)) {
          if (!aggIgnoredKeys.includes(key)) {
            if (shareOfPaid.total_share_of) {
              shareOfPaid[key].percent = round((value.total_percent/shareOfPaid.total_share_of), 1);
            } else {
              shareOfPaid[key].percent = 0;
            }
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            shareOfEverything,
            shareOfEarned,
            shareOfOwned,
            shareOfPaid,
            shareOfEverythingLoading: false,
          }));
        }
      });
    }
  };

  getShareOfAttributes = (name, type, data, countKey) => {
    const categoryShareOfAttributes = { name, type };
    const stats = data.length > 0 ? data[0].product_brands : [];
    let total = 0;
    // get total for percent
    for (const brandStats of stats) {
      total += brandStats[countKey];
    }
    categoryShareOfAttributes.total = total;

    // get highest count and set percent
    categoryShareOfAttributes.highest_count = 0;
    for (const brandStats of stats) {
      categoryShareOfAttributes[brandStats.product_brand_name] = brandStats;
      categoryShareOfAttributes[brandStats.product_brand_name].count = brandStats[countKey];
      // highest count
      if (brandStats[countKey] > categoryShareOfAttributes.highest_count) {
        categoryShareOfAttributes.highest_count = brandStats[countKey];
      }
      // percent
      if (total) {
        categoryShareOfAttributes[brandStats.product_brand_name].percent = round((brandStats[countKey]/total)*100, 1);
      } else {
        categoryShareOfAttributes[brandStats.product_brand_name].percent = 0;
      }
    }
    return categoryShareOfAttributes;
  };

  loadingRow = (identifier) => {
    return (
      <React.Fragment>
        { this.state.productBrands.map((pb, i) => {
            if (i === 0) {
              return (
                <td
                  key={`${identifier}-load-td-${i}`}
                  className="text-center"
                >
                  <ClipLoader size={15} />
                </td>
              );
            } else {
              return (<td key={`${identifier}-load-td-${i}`}></td>);
            }
          })
        }
      </React.Fragment>
    )
  }

  render () {
    return (
      <div className="m-4">
        <div>
          <CategoryShareOfEverythingGraphs
            category={this.props.category}
          />
        </div>
        <Table
          id="categoryShareOfEverythingTable"
          className="fixed-header mb-0"
          size="sm"
          style={{ fontSize: '.875rem' }}
        >
          <thead className="bg-dark text-light text-center">
            <tr>
              <th className="text-left" style={{ width: '285px' }}>
                <DateRangePicker
                  startDate={this.props.startDate}
                  endDate={this.props.endDate}
                  updateDates={this.props.updateDates}
                />
              </th>
              { this.state.productBrands.map((pb, i) => {
                  return (
                    <th key={`th-pb-${i}`}>{pb}</th>
                  )
                })
              }
            </tr>
          </thead>
          <tbody style={{ height: 'calc(100vh - 280px)' }}>
            <tr>
              <td style={{ fontWeight: 'bold', width: '285px' }}>
                Share of Everything
              </td>
              { this.state.shareOfEverythingLoading &&
                <React.Fragment>
                  {this.loadingRow('everything')}
                </React.Fragment>
              }
              { !this.state.shareOfEverythingLoading &&
                <React.Fragment>
                  { this.state.productBrands.map((pb, i) => {
                      if (this.state.shareOfEverything[pb] != null) {
                        return (
                          <td
                            key={`so-everything-td-${i}`}
                            className={
                              (
                                this.state.shareOfEverything[pb].total_percent === this.state.shareOfEverything.highest_percent &&
                                this.state.shareOfEverything[pb].total_percent !== 0
                              ) ?
                                'bg-bops-blue text-light text-center':
                                'text-center'
                            }
                          >
                            {`${this.state.shareOfEverything[pb].percent}%`}
                          </td>
                        )
                      } else {
                        return (
                          <td key={`so-everything-td-${i}`}></td>
                        )
                      }
                    })
                  }
                </React.Fragment>
              }
            </tr>
            <tr style={{ height: '31px' }}>
              <td style={{ width: '285px' }}></td>
              { this.state.productBrands.map((pb, i) => {
                  return (
                    <td key={`so-everything-b-td-${i}`}></td>
                  )
                })
              }
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold', width: '285px' }}>
                Share of Earned
              </td>
              { this.state.shareOfEverythingLoading &&
                <React.Fragment>
                  {this.loadingRow('earned')}
                </React.Fragment>
              }
              { !this.state.shareOfEverythingLoading &&
                <React.Fragment>
                  { this.state.productBrands.map((pb, i) => {
                      if (this.state.shareOfEarned[pb] != null) {
                        return (
                          <td
                            key={`so-everything-td-${i}`}
                            className={
                              (
                                this.state.shareOfEarned[pb].total_percent === this.state.shareOfEarned.highest_percent &&
                                this.state.shareOfEarned[pb].total_percent !== 0
                              ) ?
                                'bg-bops-blue text-light text-center':
                                'text-center'
                            }
                          >
                            {`${this.state.shareOfEarned[pb].percent}%`}
                          </td>
                        )
                      } else {
                        return (
                          <td key={`so-everything-td-${i}`}></td>
                        )
                      }
                    })
                  }
                </React.Fragment>
              }
            </tr>
            { this.state.earnedShareOfs.map((shareOf, i) => {
                return (
                  <tr key={`so-earn-tr-${i}`}>
                    <td style={{ width: '285px' }}>{shareOf.name}</td>
                    { this.state[`${shareOf.key}Loading`] &&
                      <React.Fragment>
                        {this.loadingRow(shareOf.key)}
                      </React.Fragment>
                    }
                    { (!this.state[`${shareOf.key}Loading`] && this.state[shareOf.key]) &&
                      <React.Fragment>
                        { this.state.productBrands.map((pb, i) => {
                            if (this.state[shareOf.key][pb] != null) {
                              return (
                                <td
                                  key={`so-td-${shareOf.key}-${i}`}
                                  className={
                                    (
                                      this.state[shareOf.key][pb].count === this.state[shareOf.key].highest_count &&
                                      this.state[shareOf.key][pb].count !== 0
                                    ) ?
                                      'bg-bops-blue text-light text-center':
                                      'text-center'
                                  }
                                >
                                  {`${this.state[shareOf.key][pb].percent}%`}
                                </td>
                              )
                            } else {
                              return (
                                <td key={`so-td-${shareOf.key}-${i}`}></td>
                              )
                            }
                          })
                        }
                      </React.Fragment>
                    }
                  </tr>
                )
              })
            }
            <tr style={{ height: '31px' }}>
              <td style={{ width: '285px' }}></td>
              { this.state.productBrands.map((pb, i) => {
                  return (
                    <td key={`so-earned-b-td-${i}`}></td>
                  )
                })
              }
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold', width: '285px' }}>
                Share of Owned
              </td>
              { this.state.shareOfEverythingLoading &&
                <React.Fragment>
                  {this.loadingRow('owned')}
                </React.Fragment>
              }
              { !this.state.shareOfEverythingLoading &&
                <React.Fragment>
                  { this.state.productBrands.map((pb, i) => {
                      if (this.state.shareOfOwned[pb] != null) {
                        return (
                          <td
                            key={`so-everything-td-${i}`}
                            className={
                              (
                                this.state.shareOfOwned[pb].total_percent === this.state.shareOfOwned.highest_percent &&
                                this.state.shareOfOwned[pb].total_percent !== 0
                              ) ?
                                'bg-bops-blue text-light text-center':
                                'text-center'
                            }
                          >
                            {`${this.state.shareOfOwned[pb].percent}%`}
                          </td>
                        )
                      } else {
                        return (
                          <td key={`so-everything-td-${i}`}></td>
                        )
                      }
                    })
                  }
                </React.Fragment>
              }
            </tr>
            { this.state.ownedShareOfs.map((shareOf, i) => {
                return (
                  <tr key={`so-own-tr-${i}`}>
                    <td style={{ width: '285px' }}>{shareOf.name}</td>
                    { this.state[`${shareOf.key}Loading`] &&
                      <React.Fragment>
                        {this.loadingRow(shareOf.key)}
                      </React.Fragment>
                    }
                    { (!this.state[`${shareOf.key}Loading`] && this.state[shareOf.key]) &&
                      <React.Fragment>
                        { this.state.productBrands.map((pb, i) => {
                            if (this.state[shareOf.key][pb] != null) {
                              return (
                                <td
                                  key={`so-td-${shareOf.key}-${i}`}
                                  className={
                                    (
                                      this.state[shareOf.key][pb].count === this.state[shareOf.key].highest_count &&
                                      this.state[shareOf.key][pb].count !== 0
                                    ) ?
                                      'bg-bops-blue text-light text-center':
                                      'text-center'
                                  }
                                >
                                  {`${this.state[shareOf.key][pb].percent}%`}
                                </td>
                              )
                            } else {
                              return (
                                <td key={`so-td-${shareOf.key}-${i}`}></td>
                              )
                            }
                          })
                        }
                      </React.Fragment>
                    }
                  </tr>
                )
              })
            }
            <tr style={{ height: '31px' }}>
              <td style={{ width: '285px' }}></td>
              { this.state.productBrands.map((pb, i) => {
                  return (
                    <td key={`so-owned-b-td-${i}`}></td>
                  )
                })
              }
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold', width: '285px' }}>
                Share of Paid
              </td>
              { this.state.shareOfEverythingLoading &&
                <React.Fragment>
                  {this.loadingRow('paid')}
                </React.Fragment>
              }
              { !this.state.shareOfEverythingLoading &&
                <React.Fragment>
                  { this.state.productBrands.map((pb, i) => {
                      if (this.state.shareOfPaid[pb] != null) {
                        return (
                          <td
                            key={`so-paid-td-${i}`}
                            className={
                              (
                                this.state.shareOfPaid[pb].total_percent === this.state.shareOfPaid.highest_percent &&
                                this.state.shareOfPaid[pb].total_percent !== 0
                              ) ?
                                'bg-bops-blue text-light text-center':
                                'text-center'
                            }
                          >
                            {`${this.state.shareOfPaid[pb].percent}%`}
                          </td>
                        )
                      } else {
                        return (
                          <td key={`so-paid-td-${i}`}></td>
                        )
                      }
                    })
                  }
                </React.Fragment>
              }
            </tr>
            { this.state.paidShareOfs.map((shareOf, i) => {
                return (
                  <tr key={`so-paid-tr-${i}`}>
                    <td style={{ width: '285px' }}>{shareOf.name}</td>
                    { this.state[`${shareOf.key}Loading`] &&
                      <React.Fragment>
                        {this.loadingRow(shareOf.key)}
                      </React.Fragment>
                    }
                    { (!this.state[`${shareOf.key}Loading`] && this.state[shareOf.key]) &&
                      <React.Fragment>
                        { this.state.productBrands.map((pb, i) => {
                            if (this.state[shareOf.key][pb] != null) {
                              return (
                                <td
                                  key={`so-td-${shareOf.key}-${i}`}
                                  className={
                                    (
                                      this.state[shareOf.key][pb].count === this.state[shareOf.key].highest_count &&
                                      this.state[shareOf.key][pb].count !== 0
                                    ) ?
                                      'bg-bops-blue text-light text-center':
                                      'text-center'
                                  }
                                >
                                  {`${this.state[shareOf.key][pb].percent}%`}
                                </td>
                              )
                            } else {
                              return (
                                <td key={`so-td-${shareOf.key}-${i}`}></td>
                              )
                            }
                          })
                        }
                      </React.Fragment>
                    }
                  </tr>
                )
              })
            }
            <tr style={{ height: '31px' }}>
              <td style={{ width: '285px' }}></td>
              { this.state.productBrands.map((pb, i) => {
                  return (
                    <td key={`so-paid-b-td-${i}`}></td>
                  )
                })
              }
            </tr>
          </tbody>
        </Table>
        <SharePopUpContainer
          shareElementId="categoryShareOfEverythingTable"
          elementLabel="category-share-of-everything-img"
          position="bottom"
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextProperty="Share Of"
          contextChartName="Summary"
        />
      </div>
    );
  }
};
