import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ReactSelect from 'react-select';
import ClipLoader from 'react-spinners/ClipLoader';
import CustomReportModal from '../customReports/CustomReportModal';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class AddToReportsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customReport: undefined,
      title: '',
      notes: '',
      errors: {},
      errorMessage: '',
      addToReportLoading: false,
      customReportModalOpen: false,
    };
  };

  componentDidUpdate(prevProps) {
    if (prevProps.customReports !== this.props.customReports) {
      // select last report used if available
      let customReport;
      if (this.props.lastCustomReportIdUsed) {
        for (const cr of this.props.customReports) {
          if (cr.id === this.props.lastCustomReportIdUsed) {
            customReport = {
              label: cr.name,
              value: cr.id,
              report: cr
            }
          }
        }
      }
      if (customReport) {
        this.setState(() => ({ customReport }));
      }
    }
  };

  onOpen = () => {
    // select last report used if available
    let customReport;
    if (this.props.lastCustomReportIdUsed) {
      for (const cr of this.props.customReports) {
        if (cr.id === this.props.lastCustomReportIdUsed) {
          customReport = {
            label: cr.name,
            value: cr.id,
            report: cr
          }
        }
      }
    }

    // determine context attributes to create title
    let titleAttributes = [];
    if (
      this.props.contextType === 'brand' &&
      this.props.contextBrand &&
      this.props.contextBrand.company_id !== this.props.user.customerId
    ) {
      titleAttributes.push(this.props.contextBrand.name);
    }
    if (this.props.contextChannel) {
      titleAttributes.push(this.props.contextChannel);
    }
    if (this.props.contextProperty) {
      titleAttributes.push(this.props.contextProperty);
    }
    const title = titleAttributes.join(' - ');

    this.setState(() => ({
      customReport,
      title,
      notes: '',
      errors: {},
      errorMessage: '',
      addToReportLoading: false,
      customReportModalOpen: false,
    }));
  };

  refreshReports = () => {
    if (this.props.user.customerId) {
      this.props.fetchCompanyCustomReports(this.props.user.customerId);
    }
  };

  onNotesChange = (event) => {
    const notes = event.currentTarget.value;
    this.setState(() => ({ notes }));
  };

  onReportChange = (customReport) => {
    if (customReport !== this.state.customReport) {
      this.setState(() => ({ customReport }));
      if (customReport) {
        this.props.updateLastCustomReportIdUsed(customReport.value);
      } else {
        this.props.updateLastCustomReportIdUsed(null);
      }
    }
  };

  isValidForm = () => {
    const errors = {};
    let errorMessage = '';
    if (!this.state.customReport) {
      errors.customReport = true;
    }
    if (!this.props.imageDataUrl) {
      errors.imageDataUrl = true;
    }

    this.setState(() => ({
      errors,
      errorMessage,
    }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  addToReport = () => {
    if (this.isValidForm()) {
      this.setState(() => ({ addToReportLoading: true }));
      const reportWidget = {
        report_id: this.state.customReport.value,
        title: this.state.title,
        image_data: this.props.imageDataUrl,
      };
      if (this.state.notes) {
        reportWidget.notes = this.state.notes;
      }
      if (this.props.contextDateRange) {
        reportWidget.date_range_context = this.props.contextDateRange;
      }
      if (this.props.contextCategory) {
        reportWidget.context_category_id = this.props.contextCategory.id;
        reportWidget.context_category_name = this.props.contextCategory.name;
      }
      if (this.props.contextBrand) {
        reportWidget.context_brand_name = this.props.contextBrand.name;
      }
      if (this.props.contextChannel) {
        reportWidget.context_channel = this.props.contextChannel;
      }
      if (this.props.contextProperty) {
        reportWidget.context_property = this.props.contextProperty;
      }
      if (this.props.contextChartName) {
        reportWidget.context_chart_name = this.props.contextChartName;
      }

      axios.post(
        `${LISTEN_ENDPOINT}/api/custom-report-widgets`,
        reportWidget,
        HEADERS
      ).then(response => {
        this.props.handleClose();
      }).catch(error => {
        console.error('Error: failed to add to report');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        this.setState(() => ({
          errorMessage: 'Failed to add to report.',
          addToReportLoading: false,
        }));
      });
    }
  };

  openCustomReportModal = (type) => {
    this.setState(() => ({ customReportModalOpen: true }));
  };

  closeCustomReportModal = () => {
    this.setState(() => ({ customReportModalOpen: false }));
  };

  render () {
    return (
      <div>
        <Modal
          show={this.props.isOpen && !this.state.customReportModalOpen}
          onHide={this.props.handleClose}
          onEntering={this.onOpen}
          size="lg"
        >
          <Modal.Header
            className="bg-bops-blue text-light"
            closeButton
          >
            <Modal.Title>
              Add to Report
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    Report
                    <span className="ml-1 text-danger">*</span>
                  </Form.Label>
                  <div>
                    <div
                      className="d-inline-block"
                      style={{ width: '90%' }}
                    >
                      <ReactSelect
                        id="reports-select"
                        classNamePrefix="reports-select"
                        cacheOptions
                        value={this.state.customReport}
                        options={
                          this.props.customReports.map(report => {
                            return ({
                              label: report.name,
                              value: report.id,
                              report,
                            })
                          })
                        }
                        onChange={this.onReportChange}
                        aria-invalid={true}
                        isClearable={true}
                        placeholder="Report..."
                      />
                    </div>
                    <div
                      className="d-inline-block align-top"
                      style={{ width: '10%' }}
                    >
                      <Button
                        className="mt-1 ml-2 px-2 py-0"
                        variant="success"
                        onClick={this.openCustomReportModal}
                        style={{
                          fontWeight: 'bold',
                          fontSize: '1.2rem'
                        }}
                      >+</Button>
                    </div>
                  </div>
                </Form.Group>
                { this.props.customReports.length === 0 &&
                  <Alert variant="info">
                    Press the + button above to create your first report!
                  </Alert>
                }
                <Form.Group>
                  <Form.Label>
                    Notes
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    value={this.state.notes}
                    onChange={this.onNotesChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    Preview
                  </Form.Label>
                  { this.props.imageDateUrlLoading &&
                    <div className="m-4 text-center">
                      <ClipLoader size={50}/>
                    </div>
                  }
                  { !this.props.imageDateUrlLoading &&
                    <div>
                      <img
                        src={this.props.imageDataUrl}
                        alt=""
                        crossOrigin="anonymous"
                        width="100%"
                      />
                    </div>
                  }
                  { this.state.notes &&
                    <pre
                      style={{
                        fontFamily: 'inherit',
                        fontSize: 'unset',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word'
                      }}
                    >
                      {this.state.notes}
                    </pre>
                  }
                </Form.Group>
              </Col>
            </Row>
            <div>
              <Alert show={Boolean(this.state.errorMessage)} variant="danger">
                {this.state.errorMessage}
              </Alert>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="closeAddToReportButton"
              variant="secondary"
              onClick={this.props.handleClose}
            >
              Close
            </Button>
            <Button
              id="addToReportButton"
              variant="primary"
              onClick={this.addToReport}
              disabled={
                !this.state.customReport ||
                this.state.addToReportLoading
              }
            >
              Add to Report
            </Button>
          </Modal.Footer>
        </Modal>

        <CustomReportModal
          isOpen={this.state.customReportModalOpen}
          handleClose={this.closeCustomReportModal}
          user={this.props.user}
          refreshReports={this.refreshReports}
          updateLastCustomReportIdUsed={this.props.updateLastCustomReportIdUsed}
        />
      </div>
    );
  }
};
