import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import ClipLoader from 'react-spinners/ClipLoader';
import LoginContainer from '../../containers/common/LoginContainer';
import BrandMetricsByMonthTableContainer from '../../containers/metrics/BrandMetricsByMonthTableContainer';
import EmailCampaignsSummaryGraphs from './EmailCampaignsSummaryGraphs';
import EmailStatDonutChart from './EmailStatDonutChart';
import EmailCampaignStatsGraph from './EmailCampaignStatsGraph';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

const selectedTypeClasses = "bg-primary text-light rounded px-2";
const row1Height = '250px';
const row2Height = '300px';

export default class MarketoEmailCampaigns extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailCampaignStats: {},
      emailCampaignStatsLoading: false,
      selectedCampaignId: undefined,
      selectedCampaign: undefined
    }
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchEmailCampaignStats();
    if (this.props.myCategoryBrands.length > 0) {
      this.setState(() => ({
        category: this.props.myCategoryBrands[0].category,
        brand: this.props.myCategoryBrands[0].brand,
      }));
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.user.customerId !== this.props.user.customerId) {
      this.fetchEmailCampaignStats();
    }
    if (
      prevProps.myCategoryBrands !== this.props.myCategoryBrands &&
      this.props.myCategoryBrands.length > 0
    ) {
      this.setState(() => ({
        category: this.props.myCategoryBrands[0].category,
        brand: this.props.myCategoryBrands[0].brand,
      }));
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchEmailCampaignStats = () => {
    if (this.props.user.customerId) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/email-campaign-stats?company_id=${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        const emailCampaignStats = response.data;
        let selectedCampaignId;
        let selectedCampaign;
        emailCampaignStats.campaigns.sort((a, b) => moment(b.campaign_created_at) - moment(a.campaign_created_at));
        if (emailCampaignStats.campaigns && emailCampaignStats.campaigns.length > 0) {
          selectedCampaignId = emailCampaignStats.campaigns[0].campaign_id;
          selectedCampaign = emailCampaignStats.campaigns[0];
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            emailCampaignStats,
            selectedCampaignId,
            selectedCampaign
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch company email campaigns...');
        if (this.state.isMounted) {
          this.setState(() => ({ emailCampaignStats: {} }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onSelectedCampaignChange = (campaign) => {
    const selectedCampaignId = campaign.campaign_id;
    const selectedCampaign = campaign;
    if (this.state.selectedCampaignId !== selectedCampaignId) {
      this.setState(() => ({
        selectedCampaignId,
        selectedCampaign
      }));
    }
  };

  isHighestBenchmarkValue = (key, benchmark) => {
    let isHighestBenchmarkValue = true;
    if (this.state.emailCampaignStats && this.state.emailCampaignStats.benchmarks) {
      const value = this.state.emailCampaignStats.benchmarks[benchmark][key];
      const benchmarks = ['all_companies', 'company_results'];
      const compareBenchmarks = benchmarks.filter(b => b !== benchmark);
      for (const compareBenchmark of compareBenchmarks) {
        if (
          this.state.emailCampaignStats.benchmarks[compareBenchmark] &&
          typeof this.state.emailCampaignStats.benchmarks[compareBenchmark][key] === 'number'
        ) {
          const compareValue = this.state.emailCampaignStats.benchmarks[compareBenchmark][key];
          if (value < compareValue) {
            isHighestBenchmarkValue = false;
          }
        }
      }
    }
    return isHighestBenchmarkValue;
  };

  render () {
    return (
      <div className="p-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        { (this.props.user.id && !this.props.companySetupChecklist.marketo_setup) &&
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              minHeight: 'calc(100vh - 200px)',
              maxHeight: 'calc(100vh - 200px)',
            }}
          >
            <div className="d-inline-block">
              <Alert variant="primary">
                Marketo is not setup for your company. You can get setup <Link to="/content-collectors/marketo">here</Link>.
              </Alert>
            </div>
          </div>
        }
        { (this.props.user.customerId && this.props.companySetupChecklist.marketo_setup) &&
          <div>
            <h4>
              <img
                className="mr-2"
                src="https://listen-company-logos.s3.amazonaws.com/marketo_logo.png"
                alt=""
                crossOrigin="anonymous"
                style={{ width: '30px' }}
              />
              Marketo - Email Campaigns
            </h4>
            <div
              className="pre-scrollable"
              style={{
                minHeight: 'calc(100vh - 150px)',
                maxHeight: 'calc(100vh - 150px)',
                overflow: 'auto',
                overflowX: 'hidden'
              }}
            >
              <div>
                <h5  className="mb-2 p-2 bg-bops-blue text-light">
                  Metric Trends
                </h5>
                <div>
                  <EmailCampaignsSummaryGraphs
                    companyId={this.props.user.customerId}
                    category={this.state.category}
                    brand={this.state.brand}
                  />
                </div>
              </div>
              <div>
                <BrandMetricsByMonthTableContainer
                  type="Email Campaigns"
                  category={this.state.category}
                  brand={this.state.brand}
                  audienceProfileId={
                    this.state.category ?
                      this.state.category.audience_profile_id :
                      undefined
                  }
                />
              </div>
              <h5  className="my-2 p-2 bg-bops-blue text-light">
                Email Campaigns
              </h5>
              <div>
                { this.state.emailCampaignsLoading &&
                  <div className="m-4 text-center">
                    <ClipLoader size={100}/>
                  </div>
                }
                { !this.state.emailCampaignsLoading &&
                  <div>
                    { (
                        this.state.emailCampaignStats &&
                        this.state.emailCampaignStats.campaigns &&
                        this.state.emailCampaignStats.campaigns.length > 0
                      ) &&
                      <div>
                        <div>
                          <Row>
                            <Col xs={12} lg={3}>
                              <div>
                                <div><b>By campaign</b></div>
                                <div className="border rounded bg-white">
                                  <div
                                    className="pre-scrollable"
                                    style={{
                                      height: row1Height,
                                      overflowY: 'auto'
                                    }}
                                  >
                                    { this.state.emailCampaignStats.campaigns.map(campaign => {
                                        return (
                                          <div
                                            key={`c-${campaign.campaign_id}`}
                                            className={campaign.campaign_id === this.state.selectedCampaignId ? selectedTypeClasses : 'px-2'}
                                            onClick={() => this.onSelectedCampaignChange(campaign)}
                                            style={{ cursor: 'pointer' }}
                                          >
                                            {campaign.campaign_name}
                                            <div style={{ fontSize: '.75rem' }}>
                                              {moment(campaign.campaign_created_at).format('MM/DD/YYYY')}
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xs={12} lg={9}>
                              { this.state.selectedCampaign &&
                                <Row>
                                  <Col>
                                    <Card className="mt-4" style={{ height: row1Height }}>
                                      <Card.Header className="bg-secondary text-light">
                                        Sent
                                      </Card.Header>
                                      <Card.Body className="p-0">
                                        <EmailStatDonutChart
                                          emailStatData={[
                                            {
                                              label: 'Sent',
                                              value: this.state.selectedCampaign.total_emails_sent
                                            },
                                            {
                                              label: 'Not Sent',
                                              value: this.state.selectedCampaign.total_emails_not_sent
                                            }
                                          ]}
                                          color="#007bff"
                                          offColor="#e3f2ff"
                                          centerLabel={`${this.state.selectedCampaign.sent_percentage || 0}%`}
                                        />
                                        <div className="text-center">
                                          {this.state.selectedCampaign.total_emails_sent}
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                  <Col>
                                    <Card className="mt-4" style={{ height: row1Height }}>
                                      <Card.Header className="bg-secondary text-light">
                                        Delivered
                                      </Card.Header>
                                      <Card.Body className="p-0">
                                        <EmailStatDonutChart
                                          emailStatData={[
                                            {
                                              label: 'Delivered',
                                              value: this.state.selectedCampaign.total_emails_delivered
                                            },
                                            {
                                              label: 'Not Delivered',
                                              value: this.state.selectedCampaign.total_emails_not_delivered
                                            }
                                          ]}
                                          color="#007bff"
                                          offColor="#e3f2ff"
                                          centerLabel={`${this.state.selectedCampaign.delivered_percentage || 0}%`}
                                        />
                                        <div className="text-center">
                                          {this.state.selectedCampaign.total_emails_delivered}
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                  <Col>
                                    <Card className="mt-4" style={{ height: row1Height }}>
                                      <Card.Header className="bg-secondary text-light">
                                        Opened
                                      </Card.Header>
                                      <Card.Body className="p-0">
                                        <EmailStatDonutChart
                                          emailStatData={[
                                            {
                                              label: 'Opened',
                                              value: this.state.selectedCampaign.total_emails_opened
                                            },
                                            {
                                              label: 'Not Opened',
                                              value: this.state.selectedCampaign.total_emails_not_opened
                                            }
                                          ]}
                                          color="#fcba03"
                                          offColor="#ffeebf"
                                          centerLabel={`${this.state.selectedCampaign.open_rate_percentage || 0}%`}
                                        />
                                        <div className="text-center">
                                          {this.state.selectedCampaign.total_emails_opened}
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                  <Col>
                                    <Card className="mt-4" style={{ height: row1Height }}>
                                      <Card.Header className="bg-secondary text-light">
                                        Click Through
                                      </Card.Header>
                                      <Card.Body className="p-0">
                                        <EmailStatDonutChart
                                          emailStatData={[
                                            {
                                              label: 'Click Through',
                                              value: this.state.selectedCampaign.total_emails_clicked
                                            },
                                            {
                                              label: 'Not Click Through',
                                              value: this.state.selectedCampaign.total_emails_not_clicked
                                            }
                                          ]}
                                          color="#fcba03"
                                          offColor="#ffeebf"
                                          centerLabel={`${this.state.selectedCampaign.click_thru_percentage || 0}%`}
                                        />
                                        <div className="text-center">
                                          {this.state.selectedCampaign.total_emails_clicked}
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                  <Col>
                                    <Card className="mt-4" style={{ height: row1Height }}>
                                      <Card.Header
                                        className="bg-secondary text-light"
                                        style={{ height: '49px' }}
                                      ></Card.Header>
                                      <Card.Body className="p-2">
                                        <div>
                                          <div>Unsubscribed:</div>
                                          <Row>
                                            <Col>
                                              {this.state.selectedCampaign.total_emails_unsubscribed || 0}
                                            </Col>
                                            <Col>
                                              {`${this.state.selectedCampaign.unsubscribed_percentage}%`}
                                            </Col>
                                          </Row>
                                        </div>
                                        <div>
                                          <div>Bounces:</div>
                                          <Row>
                                            <Col>
                                              {this.state.selectedCampaign.total_emails_bounced || 0}
                                            </Col>
                                            <Col>
                                              {`${this.state.selectedCampaign.bounce_rate_percentage}%`}
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                </Row>
                              }
                            </Col>
                          </Row>
                        </div>
                        <div className="mt-2">
                          <Row>
                            <Col xs={12} lg={8}>
                              <div>
                                <div><b>Opens, Clicks & Unsubscribed</b></div>
                                <div
                                  className="p-4 border rounded bg-white"
                                  style={{ height: row2Height }}
                                >
                                  <EmailCampaignStatsGraph
                                    campaignData={this.state.selectedCampaign.data}
                                    dateKey="captured_at"
                                    openedKey="emails_opened"
                                    clickedKey="emails_clicked"
                                    unsubscribedKey="emails_unsubscribed"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col xs={12} lg={4}>
                              <div>
                                <div><b>Benchmarks</b></div>
                                <div
                                  className="p-4 border rounded bg-white"
                                  style={{ height: row2Height }}
                                >
                                  <div className="mb-2">
                                    (trailing 6 months)
                                  </div>
                                  { this.state.emailCampaignStats.benchmarks &&
                                    <Table size="sm" striped bordered>
                                      <thead>
                                        <tr>
                                          <th>Email Metrics</th>
                                          <th>Our Results</th>
                                          <th>All Companies</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Open Rates</td>
                                          <td className={`text-center align-middle${this.isHighestBenchmarkValue('open_rate_percentage', 'company_results')?' bg-warning':''}`}>
                                            {`${this.state.emailCampaignStats.benchmarks.company_results.open_rate_percentage || 0}%`}
                                          </td>
                                          <td className={`text-center align-middle${this.isHighestBenchmarkValue('open_rate_percentage', 'all_companies')?' bg-warning':''}`}>
                                            {`${this.state.emailCampaignStats.benchmarks.all_companies.open_rate_percentage || 0}%`}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Click Through Rates</td>
                                          <td className={`text-center align-middle${this.isHighestBenchmarkValue('click_thru_percentage', 'company_results')?' bg-warning':''}`}>
                                            {`${this.state.emailCampaignStats.benchmarks.company_results.click_thru_percentage || 0}%`}
                                          </td>
                                          <td className={`text-center align-middle${this.isHighestBenchmarkValue('click_thru_percentage', 'all_companies')?' bg-warning':''}`}>
                                            {`${this.state.emailCampaignStats.benchmarks.all_companies.click_thru_percentage || 0}%`}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Unsubscribe Rates</td>
                                          <td className={`text-center align-middle${!this.isHighestBenchmarkValue('unsubscribed_percentage', 'company_results')?' bg-warning':''}`}>
                                            {`${this.state.emailCampaignStats.benchmarks.company_results.unsubscribed_percentage || 0}%`}
                                          </td>
                                          <td className={`text-center align-middle${!this.isHighestBenchmarkValue('unsubscribed_percentage', 'all_companies')?' bg-warning':''}`}>
                                            {`${this.state.emailCampaignStats.benchmarks.all_companies.unsubscribed_percentage || 0}%`}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  }
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
};
