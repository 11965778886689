import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../common/CardComponent';
import StarScoreBar from '../graphs/StarScoreBar';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class ReviewSiteScores extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reviewsData: [],
      reviewsDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.loadReviewsData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.topicId !== this.props.topicId ||
      prevProps.selectedReviewLocation !== this.props.selectedReviewLocation ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter
    ) {
      this.loadReviewsData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  loadReviewsData = () => {
    if (this.props.topicId && this.props.category) {
      this.setState(() => ({ reviewsDataLoading: true }));
      const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
      let additionalParams = '';
      if (this.props.selectedReviewLocation !== 'all') {
        additionalParams = `&review_url_id=${this.props.selectedReviewUrlId}`
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/topics/${this.props.topicId}/review-site-trend?private_category_id=${this.props.category.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}${additionalParams}`,
        HEADERS
      ).then(response => {
        const reviewsData = response.data;
        if (this.state.isMounted) {
          for (const review of reviewsData) {
            review.checked = true;
            if (review.score_average !== null) {
              review.score_average = Number(review.score_average).toFixed(2);
            }
          }
          this.props.filterReviews(reviewsData);
          this.setState(() => ({
            reviewsData,
            reviewsDataLoading: false
          }));
        }
      }).catch(error => {
        if (this.state.isMounted) {
          this.setState(() => ({
            reviewsData: [],
            reviewsDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  filterReviews = (reviewIndex) => {
    let reviewsData = this.state.reviewsData;
    for (const i in reviewsData) {
      const review = reviewsData[i];
      if (i == reviewIndex)
        review.checked = review.checked==false ? true : false;
    }
    this.props.filterReviews(reviewsData);
    this.setState(() => ({ reviewsData }));
  }

  render () {
    return (
      <CardComponent
        title="Review Sites"
        description="Review Site Scores gives a view of comments and stars by review site"
        body={
          <div style={{ fontSize: '.825rem' }}>
            { this.state.reviewsDataLoading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.reviewsDataLoading &&
              <div>
                <Table
                  striped
                  bordered
                  size="sm"
                >
                  <thead>
                    <tr>
                      <th className="p-1" style={{ minWidth: "25px" }}></th>
                      <th className="p-2" style={{ minWidth: "200px" }}>Review Site</th>
                      <th className="p-2" style={{ minWidth: "100px" }}>Stars</th>
                      <th className="p-2">Average Stars</th>
                      <th className="p-2">Reviews</th>
                      <th className="p-2">CTR</th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.state.reviewsData.map((reviewSite, i) => {
                        if (
                          this.props.selectedReviewLocation === 'all' ||
                          this.props.selectedReviewType === reviewSite.review_site_name.toLowerCase()
                        ) {
                          return (
                            <tr key={`rs${i}`}>
                              <td className="p-1 text-center align-middle" style={{ width: "25px" }}>
                                <Form.Check
                                  className=""
                                  type="checkbox"
                                  checked={reviewSite.checked}
                                  onChange={() => this.filterReviews(i)}
                                  style={{ transform: 'scale(1.2)' }}
                                />
                              </td>
                              <td className="p-2">
                                <img
                                  src={reviewSite.review_site_favicon_url}
                                  style={{ width: '25px' }}
                                />
                                <a
                                  className="ml-2"
                                  href={reviewSite.review_url}
                                  target="_blank"
                                >
                                  {reviewSite.review_site_name}
                                </a>
                              </td>
                              <td className="p-2" style={{ minWidth: "100px" }}>
                                { reviewSite.score_average === null &&
                                  <div>-</div>
                                }
                                { reviewSite.score_average !== null &&
                                  <StarScoreBar
                                    score={reviewSite.score_average}
                                    maxScore={1}
                                    starColor="gold"
                                  />
                                }
                              </td>
                              <td className="p-2">
                                { reviewSite.score_average === null &&
                                  <div>-</div>
                                }
                                { reviewSite.score_average !== null &&
                                  <div>
                                    {reviewSite.review_site_name=="Facebook" ? "N/A" : ((reviewSite.score_average * 10) / 2).toFixed(1)}
                                  </div>
                                }
                              </td>
                              <td className="p-2">
                                {numberWithCommas(reviewSite.comment_count)}
                              </td>
                              <td className="p-2">
                                { reviewSite.review_site_impact_score === null &&
                                  <div>-</div>
                                }
                                { reviewSite.review_site_impact_score !== null &&
                                  <div>
                                    {reviewSite.review_site_impact_score ? `${numberWithCommas(reviewSite.review_site_impact_score)}%` : '0%'}
                                  </div>
                                }
                              </td>
                            </tr>
                          )
                        }
                      })
                    }
                  </tbody>
                </Table>
              </div>
            }
          </div>
        }
        contextType="brand"
        contextCategory={this.props.category}
        contextBrand={this.props.brand}
        contextChannel="Product Reviews"
        contextChartName="Review Site Scores"
        contextDateRange={`${this.props.startDateFilter.format('MM/DD/YYYY')} - ${this.props.endDateFilter.format('MM/DD/YYYY')}`}
        shareClassName="product-brand-review-site-scores-img"
      />
    );
  }
};
