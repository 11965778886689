import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  ScatterChart,
  Scatter,
  Dot,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

const CustomizedShape = (props) => {
  const {cx, cy, fill, product_brand_name} = props;
  return (
    <g>
      <Dot cx={cx} cy={cy} r={5} fill={fill} />
      <g transform={`translate(${cx},${cy})`}>
        <text stroke="white" strokeWidth="0.4em" x={0} y={-15} dy={4} textAnchor="middle">{product_brand_name}</text>
        <text fill="black" x={0} y={-15} dy={4} textAnchor="middle">{product_brand_name}</text>
      </g>
    </g>
   );
};

const CustomedTooltip = (props) => {
  if (!props.active) {
    return null
  }
  const newPayload = [
    {
      name: 'Brand',
      value: props.payload[0].payload.product_brand_name
    },
    ...props.payload
  ];
  return (<DefaultTooltipContent {...props} payload={newPayload} />);
};

export default class CategoryTwitterRatingToVolume extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      twitterRatingToVolumeData: [],
      noDataBrands: [],
      loading: false
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchVolumeToScoreData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      this.fetchVolumeToScoreData();
    }
  };

  fetchVolumeToScoreData = () => {
    if (this.props.category) {
      this.setState(() => ({ loading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-twitter-brand-sentiments?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const ratingToVolumeData = response.data;
        const twitterRatingToVolumeData = [];
        const noDataBrands = [];
        for (let brand of ratingToVolumeData) {
          if (brand.bx_score == 0 && brand.tweet_volume == 0) {
            noDataBrands.push(brand);
          } else {
            twitterRatingToVolumeData.push(brand);
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            twitterRatingToVolumeData,
            noDataBrands,
            loading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch twitter rating to volume data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            twitterRatingToVolumeData: [],
            noDataBrand: [],
            loading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="p-4">
        <div>
          { this.state.loading &&
            <div
              className='text-center align-middle'
              style={{ maxHeight: '35vh' }}
            >
              <ClipLoader size={100}/>
            </div>
          }
          { !this.state.loading &&
            <div>
              <ResponsiveContainer width="99%" height={500} minWidth={0} minHeight={0}>
                <ScatterChart
                  margin={{
                    top: 25, right: 60, bottom: 30, left: 60,
                  }}
                >
                  <CartesianGrid />
                  <XAxis
                    type="number"
                    dataKey="bx_score"
                    name="Sentiment"
                    domain={[0, 'auto']}
                  >
                    <Label
                      value="Sentiment"
                      position="bottom"
                      style={{
                        fontSize: '1.2rem',
                        fontWeight: 'bold'
                      }}
                    />
                  </XAxis>
                  <YAxis
                    type="number"
                    dataKey="tweet_volume"
                    name="Volume"
                    tickFormatter={(count) => {
                      return numberWithCommas(count);
                    }}
                  >
                    <Label
                      value="Volume"
                      position="left"
                      offset={40}
                      angle={-90}
                      style={{
                        textAnchor: 'middle',
                        fontSize: '1.2rem',
                        fontWeight: 'bold'
                      }}
                    />
                  </YAxis>
                  <Tooltip
                    cursor={{ strokeDasharray: '3 3' }}
                    content={<CustomedTooltip />}
                    formatter={(value) => numberWithCommas(value)}
                  />
                  <Scatter
                    data={this.state.twitterRatingToVolumeData}
                    fill="#007bff"
                    shape={<CustomizedShape />}
                  />
                </ScatterChart>
              </ResponsiveContainer>
              { this.state.noDataBrands.length>0 &&
              <div>
                <div>
                  The following brands do not have enough Social Sentiment data to be included:
                </div>
              { this.state.noDataBrands.map((noDataBrand, i) => {
                  let index = i; index++;
                  if (index < this.state.noDataBrands.length) {
                    return (
                      <div key={`ndb-${i}`} className="d-inline-block mr-2">
                        {noDataBrand.product_brand_name},
                      </div>
                    )
                  } else {
                    return (
                      <div key={`ndb-${i}`} className="d-inline-block mr-2">
                        {noDataBrand.product_brand_name}
                      </div>
                    )
                  }
                })
              }
              </div>
              }
            </div>
          }
        </div>
      </div>
    );
  }
};
