import { connect } from 'react-redux';
import CompanyInformation from '../../components/settings/CompanyInformation';

const mapStateToProps = (state) => ({
  user: state.user,
  company: state.company
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInformation);
