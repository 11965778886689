export const setSelectedMonth = (selectedMonth) => {
  return (dispatch) => {
    dispatch(updateSelectedMonth(selectedMonth));
  };
};

export const updateSelectedMonth = (selectedMonth) => ({
  type: 'UPDATE_SELECTED_MONTH',
  selectedMonth
});
