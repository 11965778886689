import React, { useRef } from 'react';
import ReactSelect, { components } from 'react-select';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';

export const RegionMultiSelect = props => {
  // isOptionSelected sees previous props.value after onChange
  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  const selectAllOption = {
    value: '<SELECT_ALL>',
    label: props.selectAllLabel || 'All Regions'
  };

  const isSelectAllSelected = () => {
    return valueRef.current.length === 3;
  }

  const isOptionSelected = option => {
    return valueRef.current.some(({ value }) => value === option.value) || isSelectAllSelected();
  }

  const getValue = () => {
    return isSelectAllSelected() ? [selectAllOption] : props.value;
  }

  const CheckboxOption = (props) => {
    if (props.isDisabled) {
      return null;
    } else {
      return (
        <div className="px-1 pb-1">
          <components.Option
            className="rounded"
            {...props}
            >
            <Form.Check
              type="checkbox"
              checked={props.isSelected}
              onChange={() => {
                // do nothing
              }}
              inline
            />
            {props.label}
          </components.Option>
        </div>
      );
    }
  }

  return (
    <div>
      { props.showIcon !== false &&
        <FontAwesomeIcon
          className="mr-2 align-middle"
          icon={faGlobeAmericas}
          color={props.iconColor || '#34487f'}
          style={{ fontSize: '1.5rem' }}
        />
      }
      <div className="d-inline-block" style={{ width: '275px' }}>
        <ReactSelect
          isMulti
          options={[
            { value: 'americas', label: 'Americas' },
            { value: 'apac', label: 'APAC' },
            { value: 'emea', label: 'EMEA' },
          ]}
          value={props.value}
          onChange={props.onChange}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          isClearable={false}
          placeholder={props.placeholder || 'Select Regions'}
          components={{
            Option: CheckboxOption
          }}
          styles={{
            control: (provided, state) => ({
              ...provided,
              background: '#fff',
              borderColor: '#ced4da',
              minHeight: '30px',
              height: '30px',
              boxShadow: state.isFocused ? null : null,
            }),
            menu: (provided, state) => ({
                ...provided,
                marginTop: '0px'
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              height: '30px',
              padding: '0 6px'
            }),
            input: (provided, state) => ({
              ...provided,
              margin: '0px',
            }),
            indicatorSeparator: state => ({
              display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
              ...provided,
              height: '30px',
            }),
          }}
        />
      </div>
    </div>
  );
};
