import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../../common/CardComponent';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { intToShorthandString, numberWithCommas, round } from '../../../utils/numbers';
import { capitalizeFirstLetter } from '../../../utils/strings';
import { colors } from '../../../utils/graphs';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class HubspotLandingPages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allLandingPageData: {},
      hubspotLandingPages: [],
      hubspotLandingPagesLoading: false,
      viewsChecked: true,
      leadsChecked: true,
      selectedTab: 'conversionPages',
      sortType: 'recentLeads',
      selectedLandingPageId: undefined,
      selectedLandingPage: undefined,
      refreshLoading: false,
      refreshLabel: undefined,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchLandingPages();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      this.fetchLandingPages();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchLandingPages = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({ hubspotLandingPagesLoading: true }));
      const formattedStartDate = this.props.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.props.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/company-hubspot-landing-pages?company_id=${this.props.user.customerId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        let hubspotLandingPages = response.data;
        let selectedLandingPageId;
        let selectedLandingPage;
        for (let hlp of hubspotLandingPages) {
          hlp.landing_page_type = 'hubspot';
          hlp.leads.sort((a, b) => moment(b.lead_created_at) - moment(a.lead_created_at));
          for (const lead of hlp.leads) {
            lead.domain_from_email = capitalizeFirstLetter(lead.domain_from_email);
          }
        }

        const allLandingPageData = {
          landing_page_id: 'all',
          landing_page_name: 'All',
          total_views: 0,
          total_leads: 0,
          most_recent_lead: moment().add(1, 'day'),
          data: [],
        }
        if (hubspotLandingPages.length > 0) {
          // condense landing pages into an "all" as first selection
          const allDataMap = {};
          for (const lp of hubspotLandingPages) {
            // aggregate into all landing page
            allLandingPageData.total_leads += lp.total_leads;
            allLandingPageData.total_views += lp.total_views;
            for (const lpd of lp.data) {
              if (!allDataMap[lpd.captured_at]) {
                allDataMap[lpd.captured_at] = {
                  captured_at: lpd.captured_at,
                  views_count: 0,
                  lead_count: 0,
                }
              }
              allDataMap[lpd.captured_at].views_count += lpd.views_count;
              allDataMap[lpd.captured_at].lead_count += lpd.lead_count;
            }
          }
          for (const d of Object.values(allDataMap)) {
            allLandingPageData.data.push(d);
          }
          if (allLandingPageData.total_views > 0) {
            allLandingPageData.conversion_rate = round((allLandingPageData.total_leads / allLandingPageData.total_views) * 100, 1)
          } else {
            allLandingPageData.conversion_rate = 0;
          }
          allLandingPageData.data.sort((a, b) => moment(b.date) - moment(a.date));

          // apply appropriate sort
          if (this.state.sortType === 'recentLeads') {
            hubspotLandingPages.sort((a, b) => {
              if (a.most_recent_lead && b.most_recent_lead) {
                return moment(b.most_recent_lead) - moment(a.most_recent_lead);
              } else if (a.most_recent_lead) {
                return -1;
              } else {
                return 1;
              }
            });
          } else if (this.state.sortType === 'mostLeads') {
            hubspotLandingPages.sort((a, b) => b.total_leads - a.total_leads);
          } else if (this.state.sortType === 'date') {
            hubspotLandingPages.sort((a, b) => moment(b.landing_page_updated_at) - moment(a.landing_page_updated_at));
          }

          selectedLandingPageId = hubspotLandingPages[0].landing_page_id;
          selectedLandingPage = hubspotLandingPages[0];
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            allLandingPageData,
            hubspotLandingPages,
            hubspotLandingPagesLoading: false,
            selectedLandingPageId,
            selectedLandingPage,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch company hubspot landing pages...');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            allLandingPageData: {},
            hubspotLandingPages: [],
            hubspotLandingPagesLoading: false,
            selectedLandingPageId: undefined,
            selectedLandingPage: undefined,
          }));
        }
      });
    }
  };

  onCheckboxChange = (checked, checkbox) => {
    if (checked !== this.state[checkbox]) {
      this.setState(() => ({
        [checkbox]: checked
      }));
    }
  };

  onSortTypeChange = (event) => {
    const sortType = event.currentTarget.dataset.sortType;
    if (this.state.sortType !== sortType) {
      const hubspotLandingPages = Object.assign([], this.state.hubspotLandingPages);
      if (sortType === 'recentLeads') {
        hubspotLandingPages.sort((a, b) => {
          if (a.most_recent_lead && b.most_recent_lead) {
            return moment(b.most_recent_lead) - moment(a.most_recent_lead);
          } else if (a.most_recent_lead) {
            return -1;
          } else {
            return 1;
          }
        });
      } else if (sortType === 'mostLeads') {
        hubspotLandingPages.sort((a, b) => b.total_leads - a.total_leads);
      } else if (sortType === 'date') {
        hubspotLandingPages.sort((a, b) => moment(b.landing_page_updated_at) - moment(a.landing_page_updated_at));
      }

      this.setState(() => ({
        sortType,
        hubspotLandingPages
      }));
    }
  };

  onLandingPageSelect = (selectedLandingPage) => {
    if (selectedLandingPage && selectedLandingPage.landing_page_id !== this.state.selectedLandingPageId) {
      this.setState(() => ({
        selectedLandingPageId: selectedLandingPage.landing_page_id,
        selectedLandingPage,
      }));
    }
  };

  runRefreshPoll = () => {
    if (this.props.user.customerId && !this.state.refreshLoading) {
      this.setState(() => ({ refreshLoading: true }));
      axios.post(
        `${LISTEN_ENDPOINT}/api/hubspot-popular-landing-pages-refresh-poll`,
        { company_id: this.props.user.customerId },
        HEADERS
      ).then(response => {
        const refreshMetadata = response.data;
        const refreshLabel = `Latest update ${moment.utc(refreshMetadata.last_refresh_date).format('MM/DD')}`
        if (this.state.isMounted) {
          this.setState(() => ({
            refreshLabel,
            refreshLoading: false,
          }), () => this.fetchLandingPages());
        }
      }).catch(error => {
        console.error(`Error: failed to refresh company popular landing pages refresh poll`);
        if (this.state.isMounted) {
          this.setState(() => ({
            refreshLabel: 'Refresh failed',
            refreshLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  render () {
    return (
      <div className="py-2">
        <CardComponent
          title="Overall Performance"
          body={
            <div>
              { this.state.hubspotLandingPagesLoading &&
                <div className="m-4 text-center">
                  <ClipLoader size={100}/>
                </div>
              }
              { !this.state.hubspotLandingPagesLoading &&
                <div>
                  <div>
                    <div
                      className="d-inline-block px-4 py-3 border"
                      onClick={() => this.onCheckboxChange(!this.state.viewsChecked, 'viewsChecked')}
                      style={{
                        color: this.state.viewsChecked ? '#ffffff' : '#000000',
                        backgroundColor: this.state.viewsChecked ? colors[0] : '#f8f9fa'
                      }}
                    >
                      <Form.Check
                        type="checkbox"
                        label="Views"
                        checked={this.state.viewsChecked}
                        readOnly
                      />
                      <div style={{ fontSize: '2rem' }}>
                        { (this.state.allLandingPageData && this.state.allLandingPageData.total_views != null) ?
                            intToShorthandString(this.state.allLandingPageData.total_views) : '-'
                        }
                      </div>
                    </div>
                    <div
                      className="d-inline-block px-4 py-3 border"
                      onClick={() => this.onCheckboxChange(!this.state.leadsChecked, 'leadsChecked')}
                      style={{
                        color: this.state.leadsChecked ? '#ffffff' : '#000000',
                        backgroundColor: this.state.leadsChecked ? colors[1] : '#f8f9fa'
                      }}
                    >
                      <Form.Check
                        type="checkbox"
                        label="Leads"
                        checked={this.state.leadsChecked}
                        readOnly
                      />
                      <div style={{ fontSize: '2rem' }}>
                        { (this.state.allLandingPageData && this.state.allLandingPageData.total_leads != null) ?
                            intToShorthandString(this.state.allLandingPageData.total_leads) : '-'
                        }
                      </div>
                    </div>
                    <div
                      className="d-inline-block px-4 py-3 border"
                      style={{
                        color: '#000000',
                        backgroundColor: '#f8f9fa'
                      }}
                    >
                      Conversion Rate
                      <div style={{ fontSize: '2rem' }}>
                        { (this.state.allLandingPageData && this.state.allLandingPageData.conversion_rate != null) ?
                            `${this.state.allLandingPageData.conversion_rate}%` : '-'
                        }
                      </div>
                    </div>
                  </div>
                  <ResponsiveContainer height={300} width="99%">
                    <LineChart
                      data={
                        (this.state.allLandingPageData && this.state.allLandingPageData.data) ?
                          this.state.allLandingPageData.data :
                          []
                      }
                      margin={{
                        left: 10,
                        right: 10,
                      }}
                    >
                      <XAxis
                        dataKey="captured_at"
                        tickFormatter={(date) => {
                          return moment(date).format('MM/DD');
                        }}
                        minTickGap={15}
                      />
                      <YAxis
                        yAxisId={0}
                        orientation="left"
                        tickFormatter={(value) => {
                          return numberWithCommas(value)
                        }}
                      >
                        <Label
                          value="Views"
                          position="left"
                          offset={0}
                          angle={-90}
                          style={{
                            textAnchor: 'middle',
                            fontWeight: 'bold'
                          }}
                        />
                      </YAxis>>
                      { this.state.leadsChecked &&
                        <YAxis
                          yAxisId={1}
                          orientation="right"
                          tickFormatter={(value) => {
                            return numberWithCommas(value)
                          }}
                        >
                          <Label
                            value="Leads"
                            position="right"
                            offset={0}
                            angle={90}
                            style={{
                              textAnchor: 'middle',
                              fontWeight: 'bold'
                            }}
                          />
                        </YAxis>
                      }
                      <CartesianGrid strokeDasharray="5 5"/>
                      <Tooltip
                        labelFormatter={(label) => moment(label).format('dddd, MMMM Do YYYY')}
                        formatter={(value) => numberWithCommas(value)}
                      />
                      { this.state.viewsChecked &&
                        <Line
                          name="Views"
                          type="monotype"
                          dataKey="views_count"
                          stroke={colors[0]}
                          dot={false}
                          yAxisId={0}
                        />
                      }
                      { this.state.leadsChecked &&
                        <Line
                          name="Leads"
                          type="monotype"
                          dataKey="lead_count"
                          stroke={colors[1]}
                          dot={false}
                          yAxisId={1}
                        />
                      }
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              }
            </div>
          }
          contextType="brand"
          contextCategory={this.props.category}
          contextBrand={this.props.category}
          contextChannel="HubSpot Conversion Pages"
          contextChartName="Overall Performance"
        />
        <div className="mt-4">
          <Tabs
            activekey={this.state.selectedTab}
            onSelect={(selectedTab) => this.setState(() => ({ selectedTab }))}
          >
            <Tab
              eventKey="conversionPages"
              title="Conversion Pages"
            >
              { this.state.hubspotLandingPagesLoading &&
                <div className="m-4 text-center">
                  <ClipLoader size={100}/>
                </div>
              }
              { !this.state.hubspotLandingPagesLoading &&
                <div className="my-2">
                  { this.state.hubspotLandingPages.length === 0 &&
                    <div>
                      <div className="border rounded mb-4 p-4 text-center">
                        Landing pages not found
                      </div>
                      <div className="text-center">
                        <img
                          src="https://listen-company-logos.s3.amazonaws.com/LandingPageSample.png"
                          alt=""
                          crossOrigin="anonymous"
                          width="100%"
                          style={{ maxWidth: '1200px' }}
                        />
                      </div>
                    </div>
                  }
                  { this.state.hubspotLandingPages.length > 0 &&
                    <Row>
                      <Col xs={6} md={4} lg={3}>
                        <ButtonGroup className="rounded border">
                          <Button
                            variant={`${this.state.sortType === 'recentLeads' ? 'info': 'light'}`}
                            size="sm"
                            data-sort-type="recentLeads"
                            onClick={this.onSortTypeChange}
                          >Recent Leads</Button>
                          <Button
                            variant={`${this.state.sortType === 'mostLeads' ? 'info': 'light'}`}
                            size="sm"
                            data-sort-type="mostLeads"
                            onClick={this.onSortTypeChange}
                          >Most Leads</Button>
                          <Button
                            variant={`${this.state.sortType === 'date' ? 'info': 'light'}`}
                            size="sm"
                            data-sort-type="date"
                            onClick={this.onSortTypeChange}
                          >Date</Button>
                        </ButtonGroup>
                        <div
                          className="pre-scrollable bg-white border rounded"
                          style={{ overflow: 'auto' }}
                        >
                          { this.state.hubspotLandingPages.map(landingPage => {
                              return (
                                <div
                                  key={`c-lp-${landingPage.landing_page_id}`}
                                  className={landingPage.landing_page_id === this.state.selectedLandingPageId ? 'bg-primary text-light rounded px-2 ellipsis' : 'px-2 ellipsis'}
                                  onClick={() => this.onLandingPageSelect(landingPage)}
                                  style={{
                                    fontSize: '.875rem',
                                    cursor: 'pointer'
                                  }}
                                >
                                  <div className="ellipsis">
                                    {`[${landingPage.total_leads}] ${landingPage.landing_page_name}`}
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </Col>
                      <Col xs={6} md={8} lg={9}>
                        { this.state.selectedLandingPage &&
                          <div>
                            <Card className="mb-4">
                              <Card.Header
                                className="bg-white font-weight-bold"
                                style={{ fontSize: '1.2rem' }}
                              >
                                Conversion Page Details
                              </Card.Header>
                              <Card.Body>
                                <Row>
                                  <Col className="text-center" xs={3}>
                                    <div>
                                      <div className="bg-bops-blue text-light font-weight-bold">
                                        Total Views
                                      </div>
                                      <div style={{ fontSize: '1.5rem' }}>
                                        {numberWithCommas(this.state.selectedLandingPage.total_views)}
                                      </div>
                                    </div>
                                    <div>
                                      <div className="bg-bops-blue text-light font-weight-bold">
                                        Total Leads
                                      </div>
                                      <div style={{ fontSize: '1.5rem' }}>
                                        {numberWithCommas(this.state.selectedLandingPage.total_leads)}
                                      </div>
                                    </div>
                                  </Col>
                                  { this.state.selectedLandingPage.landing_page_id !== 'all' &&
                                    <Col xs={9} className="border-left">
                                      <table style={{ fontSize: '.875rem' }}>
                                        <tbody>
                                          <tr>
                                            <td className="font-weight-bold text-right" style={{ width: '120px' }}>
                                              Name:
                                            </td>
                                            <td className="pl-2">
                                              {this.state.selectedLandingPage.landing_page_name}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="font-weight-bold text-right" style={{ width: '120px' }}>
                                              URL:
                                            </td>
                                            <td className="pl-2">
                                              <a href={this.state.selectedLandingPage.landing_page_url} target="_blank">
                                                Preview
                                              </a>
                                            </td>
                                          </tr>
                                          { this.state.selectedLandingPage.landing_page_description &&
                                            <tr>
                                              <td className="font-weight-bold text-right" style={{ width: '120px' }}>
                                                Description:
                                              </td>
                                              <td className="pl-2">
                                                {this.state.selectedLandingPage.landing_page_description}
                                              </td>
                                            </tr>
                                          }
                                          <tr>
                                            <td className="font-weight-bold text-right" style={{ width: '120px' }}>
                                              Last Updated:
                                            </td>
                                            <td className="pl-2">
                                              {moment(this.state.selectedLandingPage.landing_page_updated_at).format('MM/DD/YYYY')}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="font-weight-bold text-right" style={{ width: '120px' }}>
                                              Created At:
                                            </td>
                                            <td className="pl-2">
                                              {moment(this.state.selectedLandingPage.landing_page_created_at).format('MM/DD/YYYY')}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </Col>
                                  }
                                </Row>
                              </Card.Body>
                            </Card>
                            <CardComponent
                              title="Conversion Page Views & Leads"
                              body={
                                <ResponsiveContainer height={270} width="99%">
                                  <LineChart data={this.state.selectedLandingPage.data}>
                                    <XAxis
                                      dataKey="captured_at"
                                      tickFormatter={(date) => {
                                        return moment(date).format('MM/DD');
                                      }}
                                      minTickGap={15}
                                    />
                                    <YAxis
                                      yAxisId={0}
                                      orientation="left"
                                      tickFormatter={(value) => {
                                        return numberWithCommas(value)
                                      }}
                                      allowDecimals={false}
                                    >
                                      <Label
                                        value="Views"
                                        position="left"
                                        offset={0}
                                        angle={-90}
                                        style={{
                                          textAnchor: 'middle',
                                          fontWeight: 'bold'
                                        }}
                                      />
                                    </YAxis>
                                    <YAxis
                                      yAxisId={1}
                                      orientation="right"
                                      tickFormatter={(value) => {
                                        return numberWithCommas(value)
                                      }}
                                      allowDecimals={false}
                                    >
                                      <Label
                                        value="Leads"
                                        position="right"
                                        offset={0}
                                        angle={90}
                                        style={{
                                          textAnchor: 'middle',
                                          fontWeight: 'bold'
                                        }}
                                      />
                                    </YAxis>
                                    <Tooltip labelFormatter={(label) => moment(label).format('dddd, MMMM Do YYYY')}/>
                                    <CartesianGrid strokeDasharray="5 5"/>
                                    <Line
                                      name="Views"
                                      type="monotone"
                                      dataKey="views_count"
                                      dot={false}
                                      stroke="#007bff"
                                      yAxisId={0}
                                    />
                                    <Line
                                      name="Leads"
                                      type="monotone"
                                      dataKey="lead_count"
                                      dot={false}
                                      stroke="#03ae71"
                                      yAxisId={1}
                                    />
                                  </LineChart>
                                </ResponsiveContainer>
                              }
                              contextType="brand"
                              contextCategory={this.props.category}
                              contextBrand={this.props.brand}
                              contextChannel="HubSpot"
                              contextChartName="Conversion Page Views & Leads"
                            />

                            { Array.isArray(this.state.selectedLandingPage.leads) &&
                              <div>
                                <h5 className="my-4 p-2 bg-bops-blue text-light">
                                  Conversion Page Leads
                                </h5>
                                { this.state.selectedLandingPage.leads.length > 0 &&
                                  <Table
                                    className="fixed-header text-center"
                                    size="sm"
                                    striped
                                  >
                                    <thead className="bg-dark text-light">
                                      <tr>
                                        <th style={{ width: '130px' }}>
                                          Date
                                        </th>
                                        <th>
                                          Company / Email Domain
                                        </th>
                                        <th style={{ width: '100px' }}>
                                          Stage
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody style={{ maxHeight: '500px' }}>
                                      { this.state.selectedLandingPage.leads.map((lead, i) => {
                                          return (
                                            <tr key={`h-lpl-${i}`}>
                                              <td style={{ width: '130px' }}>
                                                {moment(lead.lead_created_at).format('MM/DD/YY')}
                                              </td>
                                              <td>
                                                {lead.company_name || lead.domain_from_email || '-'}
                                              </td>
                                              <td style={{ width: '100px' }}>
                                                {lead.lifecycle_stage || '-'}
                                              </td>
                                            </tr>
                                          )
                                        })
                                      }
                                    </tbody>
                                  </Table>
                                }
                                { this.state.selectedLandingPage.leads.length === 0 &&
                                  <div className="border rounded mb-4 p-4 text-center">
                                    No leads have been detected.
                                  </div>
                                }
                              </div>
                            }
                          </div>
                        }
                      </Col>
                    </Row>
                  }
                </div>
              }
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
};
