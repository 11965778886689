import { useRef, useEffect, useState } from 'react';
import Vizzu from 'vizzu';
import VizzuModule from 'vizzu/dist/cvizzu.wasm';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../common/CardComponent';
import { numberWithCommas } from '../../utils/numbers';

Vizzu.options({ wasmUrl: VizzuModule });

const barChartSettings = {
  config: {
    channels: {
      color: { set: ['Channel'] },
      x: { set: ['Score'] },
      y: { set: ['Channel'] },
      size: { set: null },
      label: { set: null }
    },
    coordSystem: 'cartesian'
  },
  style: {
    plot: {
      paddingLeft: '150px'
    }
  }
};

const radialSettings = {
  config: {
    channels: {
      color: { set: ['Channel'] },
      x: { set: ['Score'] },
      y: {
        set: ['Channel'],
        /* Setting the radius of
        the empty circle in the centre. */
        range: {
          min: '-30%',
        }
      },
      size: { set: null },
      label: { set: null }
    },
    coordSystem: 'polar'
  },
  style: {
    plot: {
      paddingLeft: null
    }
  }
};

const radialBarSettings = {
  config: {
    channels: {
      color: { set: ['Channel'] },
      x: { set: ['Channel'] },
      y: {
        set: ['Score'],
        /* Setting the radius of
        the empty circle in the centre. */
        range: {
          min: '-30%',
        }
      },
      size: { set: null },
      label: { set: null }
    },
    coordSystem: 'polar'
  },
  style: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontSize: '16px',
    'plot.paddingTop': '16px',
    'plot.paddingLeft': null,
    'plot.yAxis.title.color': '#000000',
    'plot.yAxis.label.color': '#000000',
    'plot.xAxis.title.color': '#000000',
    'plot.xAxis.label.color': '#000000',
  }
};

const treeMapSettings = {
  config: {
    channels: {
      color: { set: ['Channel'] },
      size: { set: ['Score'] },
      label: { set: ['Channel'] },
      x: { set: null },
      y: { set: null },
    },
    coordSystem: 'cartesian'
  },
  style: {
    plot: {
      paddingLeft: null
    }
  }
}

function ShareOfChart(props) {
  const chartId = `csrc-${Math.random().toString(36).substring(7)}`;
  const canvasRef = useRef();
  const chartRef = useRef();
  const [displayType, setDisplayType] = useState('radialBar');

  const animateThroughViews = (initialDelay = 1) => {
    if (chartRef.current) {
      // animate through views
      // radial bar
      chartRef.current.animate(radialBarSettings, {
        delay: 1,
        duration: 1
      });
      // bar chart
      chartRef.current.animate(barChartSettings, {
        delay: initialDelay,
        duration: 1
      });
      // radial
      chartRef.current.animate(radialSettings, {
        delay: 1,
        duration: 1
      });
      // tree map
      chartRef.current.animate(treeMapSettings, {
        delay: 1,
        duration: 1
      });
      // radial bar
      chartRef.current.animate(radialBarSettings, {
        delay: 1,
        duration: 1
      });
    }
  }

  useEffect(() => {
    if (!chartRef.current) return;
    if (displayType === 'radialBar') {
      chartRef.current.animate(radialBarSettings);
    } else if (displayType === 'bar') {
      chartRef.current.animate(barChartSettings);
    } else if (displayType === 'radial') {
      chartRef.current.animate(radialSettings);
    } else if (displayType === 'tree') {
      chartRef.current.animate(treeMapSettings);
    } else if (displayType === 'animate') {
      animateThroughViews(1);
    }
  }, [displayType]);

  useEffect(() => {
    if (!canvasRef.current) return;
    if (props.data && props.data.records && props.data.records.length > 0) {
      chartRef.current = new Vizzu(canvasRef.current, { data: props.data });
      chartRef.current.initializing.then((chart) => {
        // prevent logo from being drawn
        chart.on('logo-draw', (event) => {
          event.preventDefault();
        });
        // animate to default chart
        chart.animate(radialBarSettings);
      });
    }
  }, [props.data]);

  return (
    <CardComponent
      title={props.title}
      icon={props.icon}
      description={props.description}
      shareClassName={props.shareClassName}
      body={
        <div>
          { props.loading &&
            <div className="text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !props.loading &&
            <div>
              <ButtonGroup className="rounded border mr-4">
                <Button
                  variant={`${displayType === 'radialBar' ? 'info': 'light'}`}
                  size="sm"
                  onClick={() => setDisplayType('radialBar')}
                >Radial Bar Values</Button>
                <Button
                  variant={`${displayType === 'bar' ? 'info': 'light'}`}
                  size="sm"
                  onClick={() => setDisplayType('bar')}
                >Bar Values</Button>
                <Button
                  variant={`${displayType === 'radial' ? 'info': 'light'}`}
                  size="sm"
                  onClick={() => setDisplayType('radial')}
                >Radial Values</Button>
                <Button
                  variant={`${displayType === 'tree' ? 'info': 'light'}`}
                  size="sm"
                  onClick={() => setDisplayType('tree')}
                >Tree Map</Button>
                <Button
                  variant={`${displayType === 'animate' ? 'info': 'light'}`}
                  size="sm"
                  onClick={() => setDisplayType('animate')}
                >Animate</Button>
              </ButtonGroup>
              <div>
                <canvas
                  className="w-100"
                  ref={canvasRef}
                  style={{ height: "500px" }}
                />
              </div>
            </div>
          }
        </div>
      }
      contextType="brand"
      contextCategory={props.category}
      contextBrand={props.brand}
      contextChannel="Channel Analysis"
      contextChartName={props.title}
    />
  );
}

export default ShareOfChart;
