import React from 'react';
import Badge from 'react-bootstrap/Badge';
import MetricsGridTab from './MetricsGridTab';
import PillarsTab from './PillarsTab';
import ChannelsTab from './ChannelsTab';
import BrandPowerTab from './BrandPowerTab';
import CalendarTab from './CalendarTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBolt,
  faCalendarAlt,
  faChartSimple,
  faTh,
  faProjectDiagram,
} from '@fortawesome/free-solid-svg-icons';
import { createUserActivityLog } from '../../../utils/users';
import history from '../../../routers/history';

export default class AnalysisTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'pillars'
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let hashSplit = hash.split('-');
      let selectedTab = 'pillars';
      if (hashSplit.length > 1) {
        selectedTab = hashSplit[1];
      }
      this.setState(() => ({ selectedTab }));
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  changeSelectedTab = (selectedTab, subTabName) => {
    history.push(`#analysis-${selectedTab}`);
    this.setState(() => ({ selectedTab }));
    if (subTabName) {
      createUserActivityLog(
        this.props.user,
        `Brand Tabs: Analysis: ${subTabName}`,
        this.props.category,
        this.props.brand,
      )
    }
  }

  render () {
    return (
      <div className="p-4">
        <div className="mb-4">
          <button
            id="pillarsButton"
            type="button"
            className= { this.state.selectedTab === 'pillars' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('pillars', 'Pillars')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faChartSimple} />
            </div>
            <div className="d-inline-block">
              Pillars
            </div>
          </button>
          <button
            id="channelsButton"
            type="button"
            className= { this.state.selectedTab === 'channels' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('channels', 'Channels')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faProjectDiagram} />
            </div>
            <div className="d-inline-block">
              Channels
            </div>
          </button>
          <button
            id="brandPowerButton"
            type="button"
            className= { this.state.selectedTab === 'brandPower' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('brandPower', 'Brand Power')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faBolt} />
            </div>
            <div className="d-inline-block">
              Brand Power
              <Badge
                className="ml-1"
                variant="warning"
                pill
              >
                Beta
              </Badge>
            </div>
          </button>
          <button
            id="calendarButton"
            type="button"
            className= { this.state.selectedTab === 'calendar' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('calendar', 'Calendar')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faCalendarAlt} />
            </div>
            <div className="d-inline-block">
              Calendar
            </div>
          </button>
        </div>

        <div
          className="mt-2 pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 200px)',
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          { this.state.selectedTab === 'metricsGrid' &&
            <MetricsGridTab
              user={this.props.user}
              customerId={this.props.user.customerId}
              categoryId={this.props.categoryId}
              categoryType={this.props.categoryType}
              category={this.props.category}
              brandId={this.props.brandId}
              brand={this.props.brand}
              brandName={this.props.brand.name}
            />
          }
          { this.state.selectedTab === 'pillars' &&
            <PillarsTab
              location={this.props.location}
              user={this.props.user}
              customerId={this.props.user.customerId}
              categoryId={this.props.categoryId}
              categoryType={this.props.categoryType}
              category={this.props.category}
              brandId={this.props.brandId}
              brand={this.props.brand}
              brandName={this.props.brand.name}
            />
          }
          { this.state.selectedTab === 'channels' &&
            <div>
              <ChannelsTab
                user={this.props.user}
                customerId={this.props.user.customerId}
                categoryId={this.props.categoryId}
                categoryType={this.props.categoryType}
                category={this.props.category}
                brandId={this.props.brandId}
                brand={this.props.brand}
                brandName={this.props.brand.name}
              />
            </div>
          }
          { this.state.selectedTab === 'brandPower' &&
            <div>
              <BrandPowerTab
                user={this.props.user}
                category={this.props.category}
                brand={this.props.brand}
              />
            </div>
          }
          {this.state.selectedTab === 'calendar' &&
            <CalendarTab
              user={this.props.user}
              customerId={this.props.user.customerId}
              categoryId={this.props.categoryId}
              categoryType={this.props.categoryType}
              category={this.props.category}
              brandId={this.props.brandId}
              brand={this.props.brand}
              brandName={this.props.brand.name}
            />
          }
        </div>
      </div>
    );
  }
};
