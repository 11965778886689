import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Tooltip from 'reactjs-popup';
import DateRangePicker from '../../common/DateRangePicker';
import BrandChannelSummaryContainer from '../../../containers/brands/BrandChannelSummaryContainer';
import BrandMetricsByMonthTableContainer from '../../../containers/metrics/BrandMetricsByMonthTableContainer';
import WebinarsDiscoveredGraph from '../../graphs/WebinarsDiscoveredGraph';
import WebinarsMentionGraph from '../../graphs/WebinarsMentionGraph';
import WebinarTooltipContent from '../../webinars/WebinarTooltipContent';
import ClipLoader from 'react-spinners/ClipLoader';
import { getRandomInteger, getRandomPercentChange, numberWithCommas } from '../../../utils/numbers';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class WebinarTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment().utc().subtract(3, 'year').startOf('day'),
      endDate: moment().utc().endOf('day'),
      webinarDataLoading: false,
      webinarData: [],
      webinarResults: [],
      cppCounts: {},
      selectedWebinarId: undefined,
      selectedWebinar: undefined
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchWebinars();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.companyId !== this.props.companyId ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      this.fetchWebinars();
    }
    if (
      prevProps.companyChecked !== this.props.companyChecked ||
      prevProps.portfolioChecked !== this.props.portfolioChecked ||
      prevProps.productChecked !== this.props.productChecked
    ) {
      this.updateWebinarResults();
    }
  };

  fetchWebinars = () => {
    if (this.props.brand) {
      this.setState(() => ({ webinarDataLoading: true }));
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/webinars-detected?company_id=${this.props.brand.company_id}&product_brand_id=${this.props.brand.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        const webinarData = response.data;
        const cppCounts = {
          companyCount: 0,
          portfolioCount: 0,
          productCount: 0,
        };
        for (const webinar of webinarData) {
          webinar.date_used = webinar.webinar_at || webinar.created_at;
          if (Array.isArray(webinar.brand_types)) {
            if (webinar.brand_types.includes('company')) {
              cppCounts.companyCount++;
            }
            if (webinar.brand_types.includes('portfolio')) {
              cppCounts.portfolioCount++;
            }
            if (webinar.brand_types.includes('product')) {
              cppCounts.productCount++;
            }
          } else {
            webinar.brand_types = [];
          }
        }
        if (this.state.isMounted) {
          webinarData.sort(function(a, b){
            a = new Date(a.date_used);
            b = new Date(b.date_used);
            return a>b ? -1 : b>a ? 1 : 0;
          });
          if (this.state.isMounted) {
            this.setState(() => ({
              webinarData,
              webinarDataLoading: false,
              cppCounts,
            }), () => this.updateWebinarResults());
            this.webinarImage.scrollLeft = 300;
            this.webinarImage.scrollTop = 0;
          }
        }
      }).catch(error => {
        console.log('Error: failed to fetch webinar data');
        if (this.state.isMounted) {
          this.setState(() => ({
            webinarData: [],
            webinarDataLoading: false,
            cppCounts: {},
          }), () => this.updateWebinarResults());
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  updateWebinarResults = () => {
    let webinarResults = [];
    for (const w of this.state.webinarData) {
      if (
        Array.isArray(w.brand_types) &&
        (
          (this.props.companyChecked && w.brand_types.includes('company')) ||
          (this.props.productChecked && w.brand_types.includes('product')) ||
          (this.props.portfolioChecked && w.brand_types.includes('portfolio'))
        )
      ) {
        webinarResults.push(w);
      }
    }

    // check if selected episode is still available in podcast episode results
    let selectedWebinarFound = false;
    if (this.state.selectedWebinarId) {
      for (const w of webinarResults) {
        if (this.state.selectedWebinarId === w.id) {
          selectedWebinarFound = true;
        }
      }
    }

    if (!selectedWebinarFound) {
      let selectedWebinarId;
      let selectedWebinar;
      if (webinarResults.length > 0) {
        selectedWebinarId = webinarResults[0].id;
        selectedWebinar = webinarResults[0];
      }
      this.setState(() => ({
        selectedWebinarId,
        selectedWebinar,
        webinarResults
      }));
    } else {
      this.setState(() => ({ webinarResults }));
    }
  };

  onFilterDatesChange = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  };

  selectWebinar = (selectedWebinar) => {
    if (selectedWebinar !== this.state.selectedWebinar) {
      this.setState(() => ({
        selectedWebinarId: selectedWebinar.id,
        selectedWebinar
      }));
      this.webinarImage.scrollLeft = 300;
      this.webinarImage.scrollTop = 0;
    }
  };

  render () {
    return (
      <div>
        <div>
          <BrandChannelSummaryContainer
            channel="Webinar"
            category={this.props.category}
            brand={this.props.brand}
          />
        </div>
        <div>
          <h5 className="my-2 p-2 bg-bops-blue text-light">
            Metric Trends
            <div
              className="d-inline-block ml-4"
              style={{ fontSize: '1rem', fontWeight: 'normal' }}
            >
              <Form.Check
                id="webinarAllMonthsCheckbox"
                className="ml-2"
                type="checkbox"
                label="All Months"
                checked={this.props.allMonthsChecked}
                onChange={(event) => this.props.updateAllMonthsChecked(event.currentTarget.checked)}
                inline
              />
            </div>
          </h5>
          <Row>
            <Col xs={12} lg={6}>
              <WebinarsDiscoveredGraph
                brand={this.props.brand}
                category={this.props.category}
                allMonthsChecked={this.props.allMonthsChecked}
                updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                allMonthsStartDate={this.props.allMonthsStartDate}
              />
            </Col>
            <Col xs={12} lg={6}>
              <WebinarsMentionGraph
                brand={this.props.brand}
                category={this.props.category}
                allMonthsChecked={this.props.allMonthsChecked}
                updateAllMonthsChecked={this.props.updateAllMonthsChecked}
                allMonthsStartDate={this.props.allMonthsStartDate}
              />
            </Col>
          </Row>
        </div>
        <div>
          <BrandMetricsByMonthTableContainer
            type="Webinar"
            category={this.props.category}
            brand={this.props.brand}
            audienceProfileId={this.props.category.audience_profile_id}
          />
        </div>
        <h5
          ref={(ref) => this.webinarDetails = ref}
          className="my-2 p-2 bg-bops-blue text-light"
        >
          Details
          <span
            className="ml-4 mr-2"
            style={{ fontSize: '1rem', fontWeight: 'normal' }}
          >Date Range</span>
          <DateRangePicker
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            updateDates={this.onFilterDatesChange}
          />
          <div
            className="d-inline-block ml-4"
            style={{ fontSize: '1rem', fontWeight: 'normal' }}
          >
            <Form.Check
              className="ml-4"
              type="checkbox"
              label={`Corporate ${this.state.cppCounts.companyCount != null ? `(${numberWithCommas(this.state.cppCounts.companyCount)})` : ''}`}
              checked={this.props.companyChecked}
              onChange={(event) => this.props.onCppCheckChange('company', event.currentTarget.checked)}
              inline
            />
            <Form.Check
              className="ml-2"
              type="checkbox"
              label={`Portfolio ${this.state.cppCounts.portfolioCount != null ? `(${numberWithCommas(this.state.cppCounts.portfolioCount)})` : ''}`}
              checked={this.props.portfolioChecked}
              onChange={(event) => this.props.onCppCheckChange('portfolio', event.currentTarget.checked)}
              inline
            />
            <Form.Check
              className="ml-2"
              type="checkbox"
              label={`Product Brand ${this.state.cppCounts.productCount != null ? `(${numberWithCommas(this.state.cppCounts.productCount)})` : ''}`}
              checked={this.props.productChecked}
              onChange={(event) => this.props.onCppCheckChange('product', event.currentTarget.checked)}
              inline
            />
          </div>
        </h5>
        { this.state.webinarDataLoading &&
          <div className="text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.webinarDataLoading &&
          <Row>
            <Col xs={4}>
              <ButtonGroup className="rounded border">
                <Button
                  variant="info"
                  size="sm"
                >Date</Button>
              </ButtonGroup>
              <div className="border rounded bg-white">
                <div
                  className="pre-scrollable"
                  style={{ minHeight: '600px', maxHeight: '600px' }}
                >
                  { this.state.webinarResults.map(webinar => {
                      return (
                        <Tooltip
                          key={`w${webinar.id}`}
                          position="right top"
                          on="hover"
                          contentStyle={{ borderRadius: '5px' }}
                          trigger={
                            <div
                              className={
                                this.state.selectedWebinarId === webinar.id ?
                                  'px-2 bg-primary text-light rounded' :
                                  `px-2${!webinar.brand_types.includes('product') ? ' bg-grey': ''}`
                              }
                              onClick={() => this.selectWebinar(webinar)}
                              style={{
                                cursor: 'pointer',
                                overflowX: 'hidden'
                              }}
                            >
                              <div className="py-2" style={{ fontSize: '.875rem' }}>
                                <div>
                                  <b>
                                    { webinar.title ?
                                        webinar.title :
                                        (webinar.description.split(" ", 5).join(" ")+"...")
                                    }
                                  </b>
                                </div>
                                <div>
                                  {moment(webinar.date_used).format('MM/DD/YY')}
                                </div>
                              </div>
                              <hr className="m-0"/>
                            </div>
                          }
                        >
                          <WebinarTooltipContent
                            webinarId={webinar.id}
                            webinarSignUpLink={webinar.signup_url}
                          />
                        </Tooltip>
                      )
                    })
                  }
                </div>
              </div>
            </Col>
            <Col xs={8}>
              <div className="container px-0" style={{ marginTop: '34px' }}>
                { this.state.selectedWebinar &&
                  <div>
                    { this.state.selectedWebinar.brand_types &&
                      <div className="mb-1">
                        { this.state.selectedWebinar.brand_types.includes('company') &&
                          <Badge
                            className="mr-1"
                            variant="warning"
                            pill
                          >
                            Corporate
                          </Badge>
                        }
                        { this.state.selectedWebinar.brand_types.includes('product') &&
                          <Badge
                            className="mr-1"
                            variant="warning"
                            pill
                          >
                            Product
                          </Badge>
                        }
                        { this.state.selectedWebinar.brand_types.includes('portfolio') &&
                          <Badge
                            className="mr-1"
                            variant="warning"
                            pill
                          >
                            Portfolio
                          </Badge>
                        }
                      </div>
                    }
                    <div
                      ref={(ref) => this.webinarImage = ref}
                      style={{ overflow: 'auto', minHeight: '600px', maxHeight: '600px' }}
                    >
                      <img
                        width="1600px"
                        src={this.state.selectedWebinar.s3_webinar_image_url}
                        alt=""
                        crossOrigin="anonymous"
                        onClick={() => window.open(this.state.selectedWebinar.signup_url, '_blank')}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </div>
                }
              </div>
            </Col>
          </Row>
        }
      </div>
    );
  }
};
