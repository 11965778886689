import React from 'react';
import axios from 'axios';
import moment from 'moment';
import GeneralSummaryGraph from './GeneralSummaryGraph';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class WebinarsMentionGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      webinarsMentionData: [],
      webinarsMentionScoreType: undefined,
      webinarsMentionDataLoading: false,
      categoryAverageData: [],
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getWebinarsMentionData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.brand !== this.props.brand ||
      prevProps.allMonthsChecked !== this.props.allMonthsChecked
    ) {
      this.getWebinarsMentionData();
    }
  }

  getWebinarsMentionData = () => {
    if (this.props.brand) {
      this.setState(() => ({ webinarsMentionDataLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/product-brands/${this.props.brand.id}/webinars-mention-trend${allMonthsStartDateParam ? `?start-date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let webinarsMentionData = response.data.monthly_stats;
        const webinarsMentionScoreType = response.data.mention_count ? response.data.mention_count.score_type : undefined;
        // merge category average data into original data source
        webinarsMentionData = webinarsMentionData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAverageData[i]))
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            webinarsMentionData,
            webinarsMentionScoreType,
            webinarsMentionDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error loading webinars mention trend...');
        this.setState(() => ({
          webinarsMentionData: [],
          webinarsMentionScoreType: undefined,
          webinarsMentionDataLoading: false
        }));
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=webinar_avg_promo_mention_count${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAverageData = response.data.category_stats;
          // merge category average data into original data source
          const webinarsMentionData = this.state.webinarsMentionData.map((x, i) => {
            return (Object.assign({}, x, categoryAverageData[i]))
          });

          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAverageData,
              webinarsMentionData
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average webinars promo mention data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAverageData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  }

  render () {
    return (
      <div>
        <GeneralSummaryGraph
          title="Promo & Mention Count"
          metricName="web_webinars_promo_mention_count"
          data={this.state.webinarsMentionData}
          dataKey="mention_count"
          dataLabel="Promo & Mention Count"
          scoreType={this.state.webinarsMentionScoreType}
          loading={this.state.webinarsMentionDataLoading}
          allowDecimals={false}
          showPercentChange={true}
          showAverageData={true}
          averageDataKey="webinar_avg_promo_mention_count"
          averageDataLabel="Category Average"
          showTooltip={true}
          contextType="brand"
          contextCategory={this.props.category}
          contextBrand={this.props.brand}
          contextChannel="Webinars"
          contextChartName="Discovered Count"
        />
      </div>
    );
  }
};
