import { connect } from 'react-redux';
import BrandMetricsByMonthTable from '../../components/metrics/BrandMetricsByMonthTable';
import { updateSelectedMonth } from '../../actions/filters/selectedMonth';

const mapStateToProps = (state) => ({
  brandMetricsByMonthWithGoals: state.brandMetricsByMonthWithGoals,
  brandMetricsByMonthWithGoalsLoading: state.brandMetricsByMonthWithGoalsLoading,
  user: state.user,
  sixMonths: state.sixMonths,
  selectedMonth: state.selectedMonth
});

const mapDispatchToProps = (dispatch) => ({
  updateSelectedMonth: (selectedMonth) => dispatch(updateSelectedMonth(selectedMonth)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandMetricsByMonthTable);
