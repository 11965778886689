import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const NoneFound = (props) => {
  return (
    <div>
      <FontAwesomeIcon
        className="mr-2"
        icon={faExclamationTriangle}
        color="#6c757d"
      />
      None found
    </div>
  )
};

export default NoneFound;
