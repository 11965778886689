import React from 'react';
import axios from 'axios';
import CategoryWebinarSummary from './CategoryWebinarSummary';
import CategoryWebinarAvgGraphs from './CategoryWebinarAvgGraphs';
import ShareOfChart from '../../graphs/ShareOfChart';
import { round } from '../../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class CategoryWebinars extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      webinarShareOfGraphData: {},
      categoryWebinarShareOfData: [],
      categoryWebinarShareOfDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchWebinarData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevProps.category !== this.props.category
    ) {
      this.fetchWebinarData();
    }
  };

  fetchWebinarData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryWebinarShareOfDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-share-of-webinars?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&brand_level=product`,
        HEADERS
      ).then(response => {
        const categoryWebinarData = response.data;
        const webinarShareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const categoryWebinarShareOfData = [];
        for (const monthStats of categoryWebinarData) {
          const webinarMonthData = { month: monthStats.month };
          let monthTotalCount = 0;

          // get totals for percent
          for (const pb of monthStats.product_brands) {
            monthTotalCount += pb.total_webinars;
            // insert graph data
            webinarShareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.total_webinars || 0,
            ]);
          }

          // loop again to determine %
          for (const pb of monthStats.product_brands) {
            webinarMonthData[pb.product_brand_name] = pb;

            let countPercent = 0;
            if (monthTotalCount > 0) {
              countPercent = round((pb.total_webinars/monthTotalCount)*100, 1);
            }
            webinarMonthData[pb.product_brand_name].count = pb.total_webinars;
            webinarMonthData[pb.product_brand_name].percent = countPercent;
          }
          categoryWebinarShareOfData.push(webinarMonthData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            webinarShareOfGraphData,
            categoryWebinarShareOfData,
            categoryWebinarShareOfDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch category share of webinar data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            webinarShareOfGraphData: {},
            categoryWebinarShareOfData: [],
            categoryWebinarShareOfDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        <CategoryWebinarSummary
          user={this.props.user}
          sixMonths={this.props.sixMonths}
          selectedMonth={this.props.selectedMonth}
          updateSelectedMonth={this.props.updateSelectedMonth}
          category={this.props.category}
          categoryWebinarData={this.state.categoryWebinarShareOfData}
          categoryWebinarDataLoading={this.state.categoryWebinarShareOfDataLoading}
        />
        <div className="mt-5 mb-4 band-separator" />
        <CategoryWebinarAvgGraphs
          user={this.props.user}
          category={this.props.category}
        />
        <div className="my-4 band-separator" />
        <ShareOfChart
          title="Share of Webinars"
          data={this.state.webinarShareOfGraphData}
          legendData={this.state.categoryWebinarShareOfData}
          loading={this.state.categoryWebinarShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Webinars"
          contextChartName="Share of Detractor Comments"
        />
      </div>
    );
  }
};
