import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ClipLoader from 'react-spinners/ClipLoader';
import Tooltip from 'reactjs-popup';
import SearchEngineResultDisplay from './SearchEngineResultDisplay';
import OrganicResultDetails from './OrganicResultDetails';
import AdResultDetails from './AdResultDetails';
import NoneFound from './NoneFound';
import LoginContainer from '../../containers/common/LoginContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

const selectedItemClasses = "bg-primary text-light rounded px-2";

export default class SearchEngines extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSearchEngine: 'google',
      keywords: [],
      selectedKeywordId: undefined,
      dates: [],
      selectedDate: undefined,
      selectedResultType: 'organic',
      organicResults: [],
      organicResultsLoading: false,
      selectedOrganicResult: undefined,
      googleAdResults: [],
      adResults: [],
      adResultsLoading: false,
      selectedGoogleAdResult: undefined,
      selectedAdResult: undefined,
      relatedSearchResults: [],
      relatedSearchResultsLoading: false
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.categoryId)
      this.fetchKeywords(true);
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if ( prevProps.user.customerId !== this.props.user.customerId ||
        prevProps.categoryId !== this.props.categoryId ) {
      this.fetchKeywords(true);
    }
  };

  fetchKeywords = () => {
    this.setState(() => ({
      keywords: [],
      selectedKeywordId: undefined,
      dates: [],
      selectedDate: undefined,
      organicResults: [],
      selectedOrganicResult: undefined,
      googleAdResults: [],
      selectedGoogleAdResult: undefined,
      adResults: [],
      selectedAdResult: undefined,
      relatedSearchResults: []
    }));
    axios.get(
      `${LISTEN_ENDPOINT}/api/search_query/customer?linked_category_id=${this.props.categoryId}&linked_category_type=${this.props.categoryType}`,
      HEADERS
    ).then(response => {
      const keywords = response.data;
      if (this.state.isMounted) {
        this.setState(() => ({
          keywords,
          selectedKeywordId: keywords[0] ? keywords[0].id : undefined
        }), () => this.fetchDates());
      }
    }).catch(error => {
      console.error('Error: unable to fetch keywords');
      if (this.state.isMounted) {
        this.setState(() => ({
          keywords: [],
          selectedKeywordId: undefined
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  fetchDates = (previousDate = undefined) => {
    if (this.state.selectedKeywordId) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/search_query_dates?query_id=${this.state.selectedKeywordId}`,
        HEADERS
      ).then(response => {
        const dates = response.data.sort((a, b) => moment(b).format('YYYYMMDD') - moment(a).format('YYYYMMDD'));
        if (this.state.isMounted) {
          if (dates.length > 0) {
            let selectedDate;
            if (previousDate && dates.includes(previousDate)) {
              selectedDate = previousDate;
            } else {
              selectedDate = dates[0]
            }
            this.setState(() => ({
              dates,
              selectedDate
            }));
            this.fetchResults(this.state.selectedSearchEngine, this.state.selectedKeywordId, selectedDate);
          } else {
            this.setState(() => ({
              dates,
              selectedDate: undefined,
              organicResults: [],
              selectedOrganicResult: undefined,
              googleAdResults: [],
              selectedGoogleAdResult: undefined,
              adResults: [],
              selectedAdResult: undefined,
              relatedSearchResults: []
            }));
          }
        }
      }).catch(error => {
        console.error('Error: unable to fetch search query dates');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchOrganicResults = (searchEngine, searchId, date) => {
    this.setState(() => ({ organicResultsLoading: true }));
    axios.get(
      `${LISTEN_ENDPOINT}/api/search_query_organics?query_engine=${searchEngine}&query_id=${searchId}&query_date=${date}`,
      HEADERS
    ).then(response => {
      const organicResults = response.data;
      if (this.state.isMounted) {
        if (organicResults.length > 0) {
          this.setState(() => ({
            organicResults,
            selectedOrganicResult: organicResults[0],
            organicResultsLoading: false
          }));
        } else {
          this.setState(() => ({
            organicResults,
            selectedOrganicResult: undefined,
            organicResultsLoading: false
          }));
        }
      }
    }).catch(error => {
      console.error('Error: unable to fetch organic results');
      if (this.state.isMounted) {
        this.setState(() => ({
          organicResults: [],
          selectedOrganicResult: undefined,
          organicResultsLoading: false
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  fetchAdResults = (searchEngine, searchId, date) => {
    this.setState(() => ({ adResultsLoading: true }));
    if (searchEngine === 'google') {
      axios.get(
        `${LISTEN_ENDPOINT}/api/search_query_google_ads?query_id=${searchId}&query_date=${date}`,
        HEADERS
      ).then(response => {
        const googleAdResults = response.data;
        for (const googleAd of googleAdResults) {
          if (googleAd.positions && Array.isArray(googleAd.positions)) {
            let positionTotal = 0;
            for (const position of googleAd.positions) {
              positionTotal += position;
            }
            googleAd.average_position = round(positionTotal / googleAd.positions.length, 1);
          }
        }
        if (this.state.isMounted) {
          if (googleAdResults.length > 0) {
            this.setState(() => ({
              googleAdResults,
              selectedGoogleAdResult: googleAdResults[0],
              adResultsLoading: false
            }));
          } else {
            this.setState(() => ({
              googleAdResults,
              selectedGoogleAdResult: undefined,
              adResultsLoading: false
            }));
          }
        }
      }).catch(error => {
        console.error('Error: unable to fetch google ad results');
        if (this.state.isMounted) {
          this.setState(() => ({
            googleAdResults: [],
            selectedGoogleAdResult: undefined,
            adResultsLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    } else {
      axios.get(
        `${LISTEN_ENDPOINT}/api/search_query_ads?query_engine=${searchEngine}&query_id=${searchId}&query_date=${date}`,
        HEADERS
      ).then(response => {
        const adResults = response.data;
        if (this.state.isMounted) {
          if (adResults.length > 0) {
            this.setState(() => ({
              adResults,
              selectedAdResult: adResults[0],
              adResultsLoading: false
            }));
          } else {
            this.setState(() => ({
              adResults,
              selectedAdResult: undefined,
              adResultsLoading: false
            }));
          }
        }
      }).catch(error => {
        console.error('Error: unable to fetch ad results');
        if (this.state.isMounted) {
          this.setState(() => ({
            adResults: [],
            selectedAdResult: undefined,
            adResultsLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchRelatedSearchResults = (searchEngine, searchId, date) => {
    this.setState(() => ({ relatedSearchResultsLoading: true }));
    axios.get(
      `${LISTEN_ENDPOINT}/api/search_query_related_searches?query_engine=${searchEngine}&query_id=${searchId}&query_date=${date}`,
      HEADERS
    ).then(response => {
      const relatedSearchResults = response.data;

      if (this.state.isMounted) {
        this.setState(() => ({
          relatedSearchResults,
          relatedSearchResultsLoading: false
        }));
      }
    }).catch(error => {
      console.error('Error: unable to fetch related search results');
      if (this.state.isMounted) {
        this.setState(() => ({
          relatedSearchResults: [],
          relatedSearchResultsLoading: false
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  fetchResults = (searchEngine, searchId, date) => {
    this.fetchOrganicResults(searchEngine, searchId, date);
    this.fetchAdResults(searchEngine, searchId, date);
    this.fetchRelatedSearchResults(searchEngine, searchId, date);
  };

  selectSearchEngine = (selectedSearchEngine) => {
    if (selectedSearchEngine !== this.state.selectedSearchEngine) {
      this.setState(() => ({ selectedSearchEngine }));
      if (this.state.selectedKeywordId && this.state.selectedDate) {
        this.fetchResults(selectedSearchEngine, this.state.selectedKeywordId, this.state.selectedDate);
      }
    }
  };

  selectKeyword = (selectedKeywordId) => {
    if (selectedKeywordId !== this.state.selectedKeywordId) {
      this.setState(
        () => ({ selectedKeywordId }),
        () => this.fetchDates(this.state.selectedDate)
      );
    }
  };

  selectDate = (selectedDate) => {
    if (selectedDate !== this.state.selectedDate) {
      this.setState(() => ({ selectedDate }));
      if (this.state.selectedSearchEngine && this.state.selectedKeywordId) {
        this.fetchResults(this.state.selectedSearchEngine, this.state.selectedKeywordId, selectedDate);
      }
    }
  };

  selectResult = (type, selectedResult) => {
    switch (type) {
      case 'organic':
        this.setState(() => ({ selectedOrganicResult: selectedResult }));
        break;
      case 'googleAds':
        this.setState(() => ({ selectedGoogleAdResult: selectedResult }));
        break;
      case 'ads':
        this.setState(() => ({ selectedAdResult: selectedResult }));
        break;
      case 'relatedSearch':
        this.setState(() => ({ selectedRelatedSearchResult: selectedResult }));
        break;
    }
  };

  render () {
    return (
      <div>
        { this.props.user.customerId &&
          <div>
            <div className="mb-2">
              <div
                className={`d-inline-block mr-1 px-2 py-1 bg-white border rounded${this.state.selectedSearchEngine==='google'?' border-primary':''}`}
                onClick={() => this.selectSearchEngine('google')}
                style={{ cursor: 'pointer' }}
              >
                <img
                  className="mr-2"
                  src="https://www.google.com/favicon.ico"
                  style={{ width: '24px' }}
                />
                <b>Google</b>
              </div>
              <div
                className={`d-inline-block mr-1 px-2 py-1 bg-white border rounded${this.state.selectedSearchEngine==='bing'?' border-primary':''}`}
                onClick={() => this.selectSearchEngine('bing')}
                style={{ cursor: 'pointer' }}
              >
                <img
                  className="mr-2"
                  src="https://www.bing.com/favicon.ico"
                  style={{ width: '24px' }}
                />
                <b>Bing</b>
              </div>
              <div
                className={`d-inline-block mr-1 px-2 py-1 bg-white border rounded${this.state.selectedSearchEngine==='yahoo'?' border-primary':''}`}
                onClick={() => this.selectSearchEngine('yahoo')}
                style={{ cursor: 'pointer' }}
              >
                <img
                  className="mr-2"
                  src="https://www.yahoo.com/favicon.ico"
                  style={{ width: '24px' }}
                />
                <b>Yahoo</b>
              </div>
            </div>
            <Row>
              <Col xs={4} md={3} lg={2}>
                <div className="mb-2">
                  <h5>
                    Keywords
                  </h5>
                  <div
                    className="pre-scrollable border rounded bg-white p-2"
                    style={{
                      minHeight: '125px',
                      maxHeight: '125px'
                    }}
                  >
                    { this.state.keywords.map(keyword => {
                        return (
                          <Tooltip
                            key={`priv-s-${keyword.id}`}
                            trigger = {
                              <div
                                className={this.state.selectedKeywordId===keyword.id?selectedItemClasses:'px-2'}
                                onClick={() => this.selectKeyword(keyword.id)}
                                style={{
                                  cursor: 'pointer',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  fontSize: "11pt"
                                }}
                              >
                                {keyword.query_term}
                              </div>
                            }
                            position="right top"
                            on="hover"
                            contentStyle={{ borderRadius: '5px', width: '250px' }}
                          >
                            <div
                              className="text-dark"
                              style={{ fontSize: '.875rem', fontWeight: 'normal' }}
                            >
                              <div>
                                {keyword.query_term}
                              </div>
                              <div>
                                {`(${keyword.search_location})`}
                              </div>
                            </div>
                          </Tooltip>
                        )
                      })
                    }
                  </div>
                </div>
                <div className="mb-2">
                  <h5>
                    Week of
                    <Tooltip
                      trigger = {
                        <span>
                          <FontAwesomeIcon
                            className="ml-2"
                            icon={faInfoCircle}
                            color="#6c757d"
                            style={{ fontSize: '1rem' }}
                          />
                        </span>
                      }
                      position="right top"
                      on="hover"
                      contentStyle={{
                        width: '300px',
                        borderRadius: '5px',
                        fontSize: '.875rem',
                        fontWeight: 'normal',
                      }}
                    >
                      <div>
                        <p>
                          The most recent "Week Of" date includes results from that date until now.
                        </p>
                        <p>
                          Older "Week Of" dates include results from the selected date to the next date.
                        </p>
                        <ul className="mb-0 pl-3">
                          <li>Organic Search results are collected once per week.</li>
                          <li>Paid Search results are collected five times per day.</li>
                        </ul>
                      </div>
                    </Tooltip>
                  </h5>
                  <div
                    className="pre-scrollable border rounded bg-white p-2"
                    style={{
                      minHeight: '125px',
                      maxHeight: '125px'
                    }}
                  >
                    { this.state.dates.map((date, i) => {
                        return (
                          <div
                            key={`date${i}`}
                            className={this.state.selectedDate===date?selectedItemClasses:'px-2'}
                            onClick={() => this.selectDate(date)}
                            style={{
                              cursor: 'pointer',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontSize: "11pt"
                            }}
                          >
                            {moment(date).format('MM-D-YYYY')}
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </Col>
              <Col>
                <Tabs
                  activekey={this.state.selectedResultType}
                  onSelect={(selectedResultType) => this.setState(() => ({ selectedResultType }))}
                >
                  <Tab eventKey="organic" title="Organic Search">
                    { this.state.organicResultsLoading &&
                      <div className="m-4">
                        <ClipLoader/>
                      </div>
                    }
                    { !this.state.organicResultsLoading &&
                      <div>
                        <Row className="mt-2">
                          <Col>
                            <SearchEngineResultDisplay
                              type="organic"
                              results={this.state.organicResults}
                              onResultSelect={this.selectResult}
                              selectedResult={this.state.selectedOrganicResult}
                            />
                          </Col>
                          <Col>
                            <OrganicResultDetails
                              organicResult={this.state.selectedOrganicResult}
                            />
                          </Col>
                        </Row>
                      </div>
                    }
                  </Tab>
                  <Tab eventKey="ads" title="Paid Ads">
                    { this.state.adResultsLoading &&
                      <div className="m-4">
                        <ClipLoader/>
                      </div>
                    }
                    { !this.state.adResultsLoading &&
                      <React.Fragment>
                        { this.state.selectedSearchEngine === 'google' &&
                          <div>
                            <Row className="mt-2">
                              <Col>
                                <SearchEngineResultDisplay
                                  type="googleAds"
                                  results={this.state.googleAdResults}
                                  onResultSelect={this.selectResult}
                                  selectedResult={this.state.selectedGoogleAdResult}
                                />
                              </Col>
                              <Col>
                                <AdResultDetails
                                  type="googleAd"
                                  adResult={this.state.selectedGoogleAdResult}
                                />
                              </Col>
                            </Row>
                          </div>
                        }
                        { this.state.selectedSearchEngine !== 'google' &&
                          <div>
                            <Row className="mt-2">
                              <Col>
                                <SearchEngineResultDisplay
                                  type="ads"
                                  results={this.state.adResults}
                                  onResultSelect={this.selectResult}
                                  selectedResult={this.state.selectedAdResult}
                                />
                              </Col>
                              <Col>
                                <AdResultDetails
                                  adResult={this.state.selectedAdResult}
                                />
                              </Col>
                            </Row>
                          </div>
                        }
                      </React.Fragment>
                    }
                  </Tab>
                  <Tab eventKey="relatedSearch" title="Related Searches">
                    { this.state.relatedSearchResultsLoading &&
                      <div className="m-4">
                        <ClipLoader/>
                      </div>
                    }
                    { !this.state.relatedSearchResultsLoading &&
                      <div className="mt-2">
                        { this.state.relatedSearchResults.length === 0 &&
                          <NoneFound/>
                        }
                        { this.state.relatedSearchResults.map((relatedSearch, i) => {
                            return (
                              <div
                                key={`rs${relatedSearch.id}`}
                                className="my-1"
                              >
                                <div
                                  className="mr-2 d-inline-block text-center"
                                  style={{
                                    backgroundColor: '#4fa2f3',
                                    borderRadius: '50%',
                                    color: '#ffffff',
                                    width: '30px',
                                    height: '30px',
                                    fontSize: '1.2rem'
                                  }}
                                >
                                  {i+1}
                                </div>
                                <div className="d-inline-block">
                                  <a href={relatedSearch.link} target="_blank">
                                    {relatedSearch.query}
                                  </a>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    }
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </div>
        }
      </div>
    );
  }
};
