import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HubspotStackedGraph from './HubspotStackedGraph';
import { capitalizeFirstLetter } from '../../../utils/strings';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';
import { colors } from '../../../utils/graphs'

export default class HubspotOutboundEmails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      outboundEmailsBySubject: [],
      subjectBars: [],
      outboundEmailsBySubjectLoading: false,
      outboundEmailsBySender: [],
      senderBars: [],
      outboundEmailsBySenderLoading: false,
      outboundEmailsByCustomer: [],
      customerBars: [],
      outboundEmailsByCustomerLoading: false,
      outboundEmailsByStage: [],
      stageBars: [],
      outboundEmailsByStageLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchOutboundEmailsBySubject();
    this.fetchOutboundEmailsBySender();
    this.fetchOutboundEmailsByCustomer();
    this.fetchOutboundEmailsByStage();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      this.fetchOutboundEmailsBySubject();
      this.fetchOutboundEmailsBySender();
      this.fetchOutboundEmailsByCustomer();
      this.fetchOutboundEmailsByStage();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  fetchOutboundEmailsBySubject = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({ outboundEmailsBySubjectLoading: true }));
      const formattedStartDate = this.props.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.props.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/company-hubspot-outbound-emails-by-subject?company_id=${this.props.user.customerId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        if (this.state.isMounted) {
          const outboundEmailsBySubject = response.data;
          const subjectBarsMap = {};
          for (const monthData of outboundEmailsBySubject) {
            for (const subject of monthData.subjects) {
              subjectBarsMap[subject.subject] = true;
              // add subject directly to month data to flatten for stacked graph
              monthData[subject.subject] = subject.outbound_email_count;
            }
          }
          // setup subject stacked bars metadata
          const subjectBars = [];
          Object.keys(subjectBarsMap).forEach((key, index) => {
            subjectBars.push({
              name: key,
              color: colors[index % colors.length],
            });
          });

          this.setState(() => ({
            outboundEmailsBySubject,
            subjectBars,
            outboundEmailsBySubjectLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch company outbound emails by subject...');
        if (this.state.isMounted) {
          this.setState(() => ({
            outboundEmailsBySubject: [],
            subjectBars: [],
            outboundEmailsBySubjectLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  fetchOutboundEmailsBySender = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({ outboundEmailsBySubjectLoading: true }));
      const formattedStartDate = this.props.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.props.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/company-hubspot-outbound-emails-by-sender?company_id=${this.props.user.customerId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        if (this.state.isMounted) {
          const outboundEmailsBySender = response.data;
          const senderBarsMap = {};
          for (const monthData of outboundEmailsBySender) {
            for (const sender of monthData.senders) {
              senderBarsMap[sender.sender] = true;
              // add sender directly to month data to flatten for stacked graph
              monthData[sender.sender] = sender.outbound_email_count;
            }
          }
          // setup sender stacked bars metadata
          const senderBars = [];
          Object.keys(senderBarsMap).forEach((key, index) => {
            senderBars.push({
              name: key,
              color: colors[index % colors.length],
            });
          });

          this.setState(() => ({
            outboundEmailsBySender,
            senderBars,
            outboundEmailsBySenderLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch company outbound emails by sender...');
        if (this.state.isMounted) {
          this.setState(() => ({
            outboundEmailsBySender: [],
            senderBars: [],
            outboundEmailsBySenderLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  fetchOutboundEmailsByCustomer = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({ outboundEmailsByCustomerLoading: true }));
      const formattedStartDate = this.props.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.props.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/company-hubspot-outbound-emails-by-customer?company_id=${this.props.user.customerId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        if (this.state.isMounted) {
          const outboundEmailsByCustomer = response.data;
          const customerBarsMap = {};
          for (const monthData of outboundEmailsByCustomer) {
            for (const customer of monthData.customers) {
              const customerFormatted = capitalizeFirstLetter(customer.customer);
              customerBarsMap[customerFormatted] = true;
              // add customer directly to month data to flatten for stacked graph
              monthData[customerFormatted] = customer.outbound_email_count;
            }
          }
          // setup customer stacked bars metadata
          const customerBars = [];
          Object.keys(customerBarsMap).forEach((key, index) => {
            customerBars.push({
              name: key,
              color: colors[index % colors.length],
            });
          });

          this.setState(() => ({
            outboundEmailsByCustomer,
            customerBars,
            outboundEmailsByCustomerLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch company outbound emails by customer...');
        if (this.state.isMounted) {
          this.setState(() => ({
            outboundEmailsByCustomer: [],
            customerBars: [],
            outboundEmailsByCustomerLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  fetchOutboundEmailsByStage = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({ outboundEmailsByStageLoading: true }));
      const formattedStartDate = this.props.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.props.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/company-hubspot-outbound-emails-by-stage?company_id=${this.props.user.customerId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        if (this.state.isMounted) {
          const outboundEmailsByStage = response.data;
          const stageBarsMap = {};
          for (const monthData of outboundEmailsByStage) {
            for (const stage of monthData.stages) {
              const stageFormatted = capitalizeFirstLetter(stage.stage);
              stageBarsMap[stageFormatted] = true;
              // add stage directly to month data to flatten for stacked graph
              monthData[stageFormatted] = stage.outbound_email_count;
            }
          }
          // setup stage stacked bars metadata
          const stageBars = [];
          Object.keys(stageBarsMap).forEach((key, index) => {
            stageBars.push({
              name: key,
              color: colors[index % colors.length],
            });
          });

          this.setState(() => ({
            outboundEmailsByStage,
            stageBars,
            outboundEmailsByStageLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch company outbound emails by stage...');
        if (this.state.isMounted) {
          this.setState(() => ({
            outboundEmailsByStage: [],
            stageBars: [],
            outboundEmailsByStageLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  render () {
    return (
      <div className="py-2">
        <Row>
          <Col xs={12} lg={6}>
            <div className="mb-4">
              <HubspotStackedGraph
                title="Outbound Emails by Subject"
                category={this.props.category}
                brand={this.props.brand}
                data={this.state.outboundEmailsBySubject}
                barsData={this.state.subjectBars}
                loading={this.state.outboundEmailsBySubjectLoading}
                allowDecimals={false}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-4">
              <HubspotStackedGraph
                title="Outbound Emails by Sender"
                category={this.props.category}
                brand={this.props.brand}
                data={this.state.outboundEmailsBySender}
                barsData={this.state.senderBars}
                loading={this.state.outboundEmailsBySenderLoading}
                allowDecimals={false}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-4">
              <HubspotStackedGraph
                title="Outbound Emails by Customer"
                category={this.props.category}
                brand={this.props.brand}
                data={this.state.outboundEmailsByCustomer}
                barsData={this.state.customerBars}
                loading={this.state.outboundEmailsByCustomerLoading}
                allowDecimals={false}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-4">
              <HubspotStackedGraph
                title="Outbound Emails by Stage"
                category={this.props.category}
                brand={this.props.brand}
                data={this.state.outboundEmailsByStage}
                barsData={this.state.stageBars}
                loading={this.state.outboundEmailsByStageLoading}
                allowDecimals={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};
