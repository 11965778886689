import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import ClipLoader from 'react-spinners/ClipLoader';
import Tooltip from 'reactjs-popup';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import LoginContainer from '../../containers/common/LoginContainer';
import CustomReportWidget from './CustomReportWidget';
import CustomReportModal from './CustomReportModal';
import CustomReportSettingsModal from './CustomReportSettingsModal';
import AddSlideModal from './AddSlideModal';
import CustomReportUnsavedChangesModal from './CustomReportUnsavedChangesModal';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faEllipsisH,
  faGear,
  faTrash,
  faSyncAlt,
  faImage,
  faLevelUpAlt,
  faLevelDownAlt,
  faSort,
} from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { sortAlphabeticalByKey } from '../../utils/sorts';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, LAMBDA_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';
import history from '../../routers/history';

export default class CustomReports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedReportId: undefined,
      selectedReport: undefined,
      selectedReportWidgets: [],
      selectedWidgetId: undefined,
      selectedWidget: undefined,
      customReportModalOpen: false,
      customReportForModal: undefined,
      customReportSettingsModalOpen: false,
      addSlideModalOpen: false,
      widgetPlacement: undefined,
      unsavedChangesModalOpen: false,
      deleteReportConfirmationModalOpen: false,
      deleteReportId: undefined,
      deleteReportName: undefined,
      deleteWidgetConfirmationModalOpen: false,
      deleteWidgetId: undefined,
      publishLoading: false,
      voiceOptions: [],
      reportPublishedViews: {},
      reportPublishedViewsLoading: false,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.user.customerId) {
      this.props.fetchCompanyCustomReports(this.props.user.customerId);
    }
    if (this.props.location) {
      this.processLocation();
    }
    this.fetchVoiceOptions();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.customReports !== this.props.customReports) {
      let selectedReportFound = false;
      for (const report of this.props.customReports) {
        if (String(this.state.selectedReportId) === String(report.id)) {
          selectedReportFound = true;
          this.onSelectedReportChange({ currentTarget: { value: report.id } });
        }
      }
      if (!selectedReportFound && this.props.customReports.length > 0) {
        this.onSelectedReportChange({ currentTarget: { value: this.props.customReports[0].id } });
      }
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.processLocation();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  updateSelectedReportId = (selectedReportId) => {
    this.setState(() => ({ selectedReportId }));
  }

  processLocation = () => {
    // select appropriate report
    const pathSplit = this.props.location.pathname.split('/');
    const reportNameEncoded = pathSplit[pathSplit.length-1];
    const reportName = decodeURIComponent(reportNameEncoded)
    for (const report of this.props.customReports) {
      if (report.name === reportName) {
        this.onSelectedReportChange({ currentTarget: { value: report.id } });
      }
    }
  };

  refreshReports = () => {
    if (this.props.user.customerId) {
      this.props.fetchCompanyCustomReports(this.props.user.customerId);
    }
  };

  fetchVoiceOptions = () => {
    const voiceOptionRequests = [];
    voiceOptionRequests.push(
      axios.get(
        `${LAMBDA_ENDPOINT}/polly-describe-voices`,
        HEADERS
      ).then(response => {
        const voiceOptions = response.data;
        for (const v of voiceOptions) {
          v.voice_source = 'polly';
          v.SourceLabel = 'Polly';
        }
        return { voiceOptions };
      }).catch(error => {
        console.error('Error: failed to fetch polly voice options.');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        return { voiceOptions: [] };
      })
    );
    voiceOptionRequests.push(
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/azure-describe-voices`,
        HEADERS
      ).then(response => {
        const voiceOptions = response.data;
        for (const v of voiceOptions) {
          v.voice_source = 'azure';
          v.SourceLabel = 'Azure';
          v.LanguageName = v.LanguageCode;
        }
        return { voiceOptions };
      }).catch(error => {
        console.error('Error: failed to fetch azure voice options.');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        return { voiceOptions: [] };
      })
    );

    Promise.all(voiceOptionRequests).then(responses => {
      let voiceOptions = [];
      for (const response of responses) {
        voiceOptions = voiceOptions.concat(response.voiceOptions);
      }
      voiceOptions.sort(sortAlphabeticalByKey('Name'));
      if (this.state.isMounted) {
        this.setState(() => ({ voiceOptions }));
      }
    });
  };

  onSelectedReportChange = (event) => {
    const selectedReportId = event.currentTarget.value;
    this.props.updateLastCustomReportIdUsed(selectedReportId);
    let selectedReport;
    let selectedReportWidgets = [];
    let selectedWidgetId;
    let selectedWidget;
    for (const report of this.props.customReports) {
      if (String(selectedReportId) === String(report.id)) {
        selectedReport = report;
        selectedReportWidgets = selectedReport.widgets;
        // select same widget if found
        let selectedWidgetFound = false;
        for (const widget of selectedReportWidgets) {
          if (widget.widget_id === this.state.selectedWidgetId) {
            selectedWidgetFound = true;
            selectedWidgetId = widget.widget_id;
            selectedWidget = widget;
          }
        }
        if (!selectedWidgetFound && selectedReport.widgets.length > 0) {
          selectedWidgetId = selectedReport.widgets[0].widget_id;
          selectedWidget = selectedReport.widgets[0];
        }
      }
    }
    this.setState(() => ({
      selectedReportId,
      selectedReport,
      selectedReportWidgets,
      selectedWidgetId,
      selectedWidget,
    }));
    if (selectedReport) {
      history.replace(`/reports/${encodeURIComponent(selectedReport.name)}`);
      if (selectedReport.publish_token) {
        this.fetchReportPublishedViews(selectedReport.publish_token);
      }
    }
  };

  fetchReportPublishedViews = (reportPublishToken) => {
    if (reportPublishToken) {
      this.setState(() => ({ reportPublishedViewsLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/report-published-views?report_publish_token=${reportPublishToken}`,
        HEADERS
      ).then(response => {
        const reportPublishedViews = response.data;
        if (reportPublishedViews.unique_views != null) {
          reportPublishedViews.unique_views = numberWithCommas(reportPublishedViews.unique_views);
        }
        if (reportPublishedViews.views != null) {
          reportPublishedViews.views = numberWithCommas(reportPublishedViews.views);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            reportPublishedViews,
            reportPublishedViewsLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch report published views.');
        if (this.state.isMounted) {
          this.setState(() => ({
            reportPublishedViews: {},
            reportPublishedViewsLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onWidgetSelect = (widget) => {
    if (widget) {
      if (this.selectedReportWidget && this.selectedReportWidget.unsavedChangesCheck()) {
        this.setState(() => ({
          nextSelectedWidget: widget,
          unsavedChangesModalOpen: true,
        }));
      } else {
        this.setState(() => ({
          selectedWidgetId: widget.widget_id,
          selectedWidget: widget
        }));
      }
    }
  };

  saveUnsavedChanges = () => {
    const selectedWidget = this.state.nextSelectedWidget;
    if (selectedWidget) {
      this.selectedReportWidget.saveWidget();
      this.setState(() => ({
        selectedWidgetId: selectedWidget.widget_id,
        selectedWidget: selectedWidget,
        nextSelectedWidget: undefined,
        unsavedChangesModalOpen: false,
      }));
    }
  };

  discardUnsavedChanges = () => {
    const selectedWidget = this.state.nextSelectedWidget;
    if (selectedWidget) {
      this.setState(() => ({
        selectedWidgetId: selectedWidget.widget_id,
        selectedWidget: selectedWidget,
        nextSelectedWidget: undefined,
        unsavedChangesModalOpen: false,
      }));
    }
  };

  updateReportOrder = (result) => {
    const { destination, source, draggableId } = result;
    // no destination
    if (!destination) {
      return;
    }
    // location did not change
    if (destination.index === source.index) {
      return;
    }
    // update order of report widgets
    const selectedReportWidgets = Array.from(this.state.selectedReportWidgets);
    const movedWidget = selectedReportWidgets.splice(source.index, 1)[0];
    selectedReportWidgets.splice(destination.index, 0, movedWidget);
    this.setState(() => ({ selectedReportWidgets }));
    // persist this ordering
    const widgetOrderUpdateRequests = [];
    for (const [index, widget] of selectedReportWidgets.entries()) {
      widgetOrderUpdateRequests.push(
        axios.put(
          `${LISTEN_ENDPOINT}/api/custom-report-widgets/${widget.widget_id}`,
          { widget_order: index + 1 },
          HEADERS
        )
      )
    }
    Promise.all(widgetOrderUpdateRequests).then(responses => {
      this.refreshReports();
    });
  };

  openCustomReportModal = (type) => {
    let customReportForModal;
    if (type === 'edit') {
      customReportForModal = this.state.selectedReport;
    }
    this.setState(() => ({
      customReportModalOpen: true,
      customReportForModal
    }));
  };

  closeCustomReportModal = () => {
    this.setState(() => ({
      customReportModalOpen: false,
      customReportForModal: undefined
    }));
  };

  openAddSlideModal = () => {
    this.setState(() => ({ addSlideModalOpen: true }));
  };

  closeAddSlideModal = () => {
    this.setState(() => ({ addSlideModalOpen: false }));
  };

  openCustomReportSettingsModal = () => {
    this.setState(() => ({ customReportSettingsModalOpen: true }));
  };

  closeCustomReportSettingsModal = () => {
    this.setState(() => ({ customReportSettingsModalOpen: false }));
  };

  openDeleteReportConfirmationModal = () => {
    this.setState(() => ({
      deleteReportConfirmationModalOpen: true,
      deleteReportId: this.state.selectedReport.id,
      deleteReportName: this.state.selectedReport.name
    }));
  };

  closeDeleteReportConfirmationModal = () => {
    this.setState(() => ({
      deleteReportConfirmationModalOpen: false,
      deleteReportId: undefined,
      deleteReportName: undefined
    }));
  };

  deleteReport = () => {
    if (this.state.selectedReport) {
      axios.delete(
        `${LISTEN_ENDPOINT}/api/custom-reports/${this.state.selectedReport.id}`,
        HEADERS
      ).then(response => {
        this.props.updateLastCustomReportIdUsed(null);
        this.refreshReports();
        if (this.state.isMounted) {
          this.setState(() => ({ deleteReportConfirmationModalOpen: false }));
        }
      }).catch(error => {
        console.error('Error: failed to delete report.');
        if (this.state.isMounted) {
          this.setState(() => ({ deleteReportConfirmationModalOpen: false }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  togglePublishReport = (event) => {
    const checked = event.currentTarget.checked;
    if (this.state.selectedReport) {
      this.setState(() => ({ publishLoading: true }));
      if (checked) {
        // publish
        axios.post(
          `${LISTEN_ENDPOINT}/api/publish-custom-report`,
          { report_id: this.state.selectedReport.id },
          HEADERS
        ).then(response => {
          // update selectedReport state
          if (this.state.isMounted) {
            this.setState((prevState) => {
              const selectedReport = prevState.selectedReport;
              selectedReport.publish_token = response.data.publish_token;
              return ({
                selectedReport,
                publishLoading: false
              });
            });
          }
          // call to update reports
          this.refreshReports();
        }).catch(error => {
          console.error('Error: failed to publish report.');
          if (this.state.isMounted) {
            this.setState(() => ({ publishLoading: false }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      } else {
        // unpublish
        axios.delete(
          `${LISTEN_ENDPOINT}/api/unpublish-custom-report/${this.state.selectedReport.id}`,
          HEADERS
        ).then(response => {
          // update selectedReport state
          if (this.state.isMounted) {
            this.setState((prevState) => {
              const selectedReport = prevState.selectedReport;
              selectedReport.publish_token = null;
              return ({
                selectedReport,
                publishLoading: false
              });
            });
          }
          // call to update reports
          this.refreshReports();
        }).catch(error => {
          console.error('Error: failed to unpublish report.');
          if (this.state.isMounted) {
            this.setState(() => ({ publishLoading: false }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  updateWidgetsVoice = (voiceId, voiceSource, onlyUnsetVoices) => {
    if (this.state.selectedReport && this.state.selectedReport.widgets) {
      const updateWidgetVoiceRequests = [];
      if (onlyUnsetVoices) {
        for (const widget of this.state.selectedReport.widgets) {
          if (!widget.voice_id) {
            updateWidgetVoiceRequests.push(
              axios.put(
                `${LISTEN_ENDPOINT}/api/custom-report-widgets/${widget.widget_id}`,
                {
                  voice_id: voiceId,
                  voice_source: voiceSource,
                },
                HEADERS
              ).catch(error => {
                console.error('Error: failed to update widget voice.');
                if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
                  dispatchReportError(error.response);
                }
              })
            );
          }
          }
      } else {
        this.setState(() => ({ updateWidgetsVoiceLoading: true }));
        for (const widget of this.state.selectedReport.widgets) {
          updateWidgetVoiceRequests.push(
            axios.put(
              `${LISTEN_ENDPOINT}/api/custom-report-widgets/${widget.widget_id}`,
              {
                voice_id: voiceId,
                voice_source: voiceSource,
              },
              HEADERS
            ).catch(error => {
              console.error('Error: failed to update widget voice.');
              if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
                dispatchReportError(error.response);
              }
            })
          );
        }
      }

      if (updateWidgetVoiceRequests.length > 0) {
        Promise.all(updateWidgetVoiceRequests).then(responses => {
          this.refreshReports();
          this.setState(() => ({ updateWidgetsVoiceLoading: false }));
        });
      }
    }
  };

  contextMenuAddSlideBefore = (widget) => {
    this.setState(() => ({
      addSlideModalOpen: true,
      widgetPlacement: widget.widget_order
    }));
  };

  contextMenuAddSlideAfter = (widget) => {
    this.setState(() => ({
      addSlideModalOpen: true,
      widgetPlacement: widget.widget_order + 1
    }));
  };

  openDeleteWidgetConfirmationModal = (widget) => {
    if (widget) {
      this.setState(() => ({
        deleteWidgetConfirmationModalOpen: true,
        deleteWidgetId: widget.widget_id,
      }));
    }
  };

  closeDeleteWidgetConfirmationModal = () => {
    this.setState(() => ({
      deleteWidgetConfirmationModalOpen: false,
      deleteWidgetId: undefined,
    }));
  };

  deleteWidget = () => {
    if (this.state.deleteWidgetId) {
      axios.delete(
        `${LISTEN_ENDPOINT}/api/custom-report-widgets/${this.state.deleteWidgetId}`,
        HEADERS
      ).then(response => {
        this.refreshReports();
        if (this.state.isMounted) {
          this.setState(() => ({
            deleteWidgetConfirmationModalOpen: false,
            deleteWidgetId: undefined,
          }));
        }
      }).catch(error => {
        console.error('Error: failed to delete widget.');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            deleteWidgetConfirmationModalOpen: false,
            deleteWidgetId: undefined,
          }));
        }
      });
    }
  };

  render () {
    return (
      <div className="p-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        { (this.props.user && this.props.user.id) &&
          <div>
            <Row className="mb-2">
              <Col xs={4}>
                <div>
                  <div className="font-weight-bold">
                    My Reports
                  </div>
                  <div>
                    <div className="d-inline-block" style={{ maxWidth: '250px' }}>
                      <Form.Control
                        as="select"
                        value={this.state.selectedReportId}
                        onChange={this.onSelectedReportChange}
                        size="sm"
                      >
                        { this.props.customReports.map(report => {
                            return (
                              <option
                                key={`cr-o-${report.id}`}
                                value={report.id}
                              >
                                {report.name}
                              </option>
                            )
                          })
                        }
                      </Form.Control>
                    </div>
                    <div className="d-inline-block ml-2">
                      <Dropdown>
                        <Dropdown.Toggle
                          as="div"
                          bsPrefix="none"
                        >
                          <Button variant="light" size="sm">
                            <FontAwesomeIcon icon={faEllipsisH} style={{ cursor: 'pointer' }} color="#5f666d"/>
                          </Button>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => this.openCustomReportModal('new')}>
                            <FontAwesomeIcon className="mr-2" icon={faPlus}/>
                            Add New Report
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={this.openCustomReportSettingsModal}
                            disabled={!this.props.user.id || !this.state.selectedReport}
                          >
                            <FontAwesomeIcon className="mr-2" icon={faGear}/>
                            Current Report Settings
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={this.openAddSlideModal}
                            disabled={!this.props.user.id || !this.state.selectedReport}
                          >
                            <FontAwesomeIcon className="mr-2" icon={faImage}/>
                            Add Slide to Current Report
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={this.openDeleteReportConfirmationModal}
                            disabled={
                              this.props.user.id &&
                              this.state.selectedReport &&
                              this.props.user.id !== this.state.selectedReport.author_customer_user_id
                            }
                          >
                            <FontAwesomeIcon className="mr-2" icon={faTrash}/>
                            Delete Current Report
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={8}>
                { this.state.selectedReport &&
                  <div>
                    <div className="d-inline-block font-weight-bold">
                      {this.state.selectedReport.name}
                    </div>
                    <div
                      className="d-inline-block ml-2 text-muted"
                      style={{ fontSize: '.75rem' }}
                    >
                      {`Created: ${moment(this.state.selectedReport.created_at).format('MM/DD/YYYY')}`}
                    </div>
                    <div className="d-inline-block">
                      { this.state.publishLoading &&
                        <ClipLoader size={15} />
                      }
                      { !this.state.publishLoading &&
                        <div className="d-inline-block ml-3">
                          <Form.Check
                            type="checkbox"
                            label="Publish"
                            checked={this.state.selectedReport.publish_token !== null}
                            onChange={this.togglePublishReport}
                            inline
                          />
                        </div>
                      }
                    </div>
                    { (!this.state.publishLoading && this.state.selectedReport.publish_token) &&
                      <div>
                        <Button
                          variant="success"
                          onClick={() => window.open(`${window.location.origin}/reports/public/${this.state.selectedReport.publish_token}`, '_blank')}
                          size="sm"
                        >
                          <FontAwesomeIcon className="mr-1" icon={faImage}/>
                          View Presentation
                        </Button>
                        <Button
                          className="ml-2"
                          variant="success"
                          onClick={() => window.open(`${window.location.origin}/reports/public/scrollable/${this.state.selectedReport.publish_token}`, '_blank')}
                          size="sm"
                        >
                          <FontAwesomeIcon className="mr-1" icon={faSort}/>
                          View Report
                        </Button>
                        <div
                          className="d-inline-block ml-2"
                          style={{ fontSize: '.75rem' }}
                        >
                          { this.state.reportPublishedViewsLoading &&
                            <ClipLoader size={15} />
                          }
                          { !this.state.reportPublishedViewsLoading &&
                            <React.Fragment>
                              {`Unique Viewers: ${this.state.reportPublishedViews.unique_views || '-'} Views: ${this.state.reportPublishedViews.views || '-'}`}
                              <Tooltip
                                position="bottom center"
                                on="hover"
                                contentStyle={{
                                  borderRadius: '5px',
                                  width: '135px',
                                  fontSize: '.875rem'
                                }}
                                trigger={
                                  <span>
                                    <FontAwesomeIcon
                                      className="ml-2"
                                      icon={faSyncAlt}
                                      onClick={() => this.fetchReportPublishedViews(this.state.selectedReport.publish_token)}
                                      style={{
                                        cursor: 'pointer',
                                        fontSize: '.875rem'
                                      }}
                                    />
                                  </span>
                                }
                              >
                                Refresh report views
                              </Tooltip>
                            </React.Fragment>
                          }
                        </div>
                      </div>
                    }
                  </div>
                }
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                { this.state.selectedReport &&
                  <div
                    className="pre-scrollable border rounded"
                    style={{
                      minHeight: 'calc(100vh - 160px)',
                      maxHeight: 'calc(100vh - 160px)',
                      overflowX: 'hidden',
                      overflowY: 'auto',
                    }}
                  >
                    { this.state.selectedReport.widgets.length > 0 &&
                      <DragDropContext onDragEnd={this.updateReportOrder}>
                        <Droppable droppableId="1">
                          { (provided) => (
                              <ListGroup
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                { this.state.selectedReportWidgets.map((widget, i) => {
                                    return (
                                      <Draggable
                                        key={`scr-w-${widget.widget_id}`}
                                        draggableId={String(widget.widget_id)}
                                        index={i}
                                      >
                                        { (draggableProvided, snapshot) => (
                                            <React.Fragment>
                                              <ContextMenuTrigger
                                                id={`rw-cm-${widget.widget_id}`}
                                              >
                                                <ListGroup.Item
                                                  className={
                                                    this.state.selectedWidgetId === widget.widget_id ?
                                                      'bg-grey':
                                                      ''
                                                  }
                                                  onClick={() => this.onWidgetSelect(widget)}
                                                  {...draggableProvided.draggableProps}
                                                  {...draggableProvided.dragHandleProps}
                                                  ref={draggableProvided.innerRef}
                                                  isDragging={snapshot.isDragging && !snapshot.isDropAnimating}
                                                >
                                                  <Row noGutters>
                                                    <Col style={{ flex: '0 0 35px' }}>
                                                      {i + 1}
                                                    </Col>
                                                    <Col>
                                                      <img
                                                        className="w-100"
                                                        src={widget.image_url}
                                                        alt=""
                                                        crossOrigin="anonymous"
                                                      />
                                                    </Col>
                                                  </Row>
                                                </ListGroup.Item>
                                              </ContextMenuTrigger>
                                              <ContextMenu
                                                className="ml-n2 mt-n2 px-3 py-2 bg-white border rounded"
                                                id={`rw-cm-${widget.widget_id}`}
                                                hideOnLeave={true}
                                                style={{ zIndex: '9999' }}
                                              >
                                                <MenuItem onClick={() => this.contextMenuAddSlideBefore(widget)}>
                                                  <div style={{ cursor: 'pointer' }}>
                                                    <FontAwesomeIcon className="mr-2" icon={faLevelUpAlt} />
                                                    Add Slide Before
                                                  </div>
                                                </MenuItem>
                                                <MenuItem onClick={() => this.contextMenuAddSlideAfter(widget)}>
                                                  <div style={{ cursor: 'pointer' }}>
                                                    <FontAwesomeIcon className="mr-2" icon={faLevelDownAlt} />
                                                    Add Slide After
                                                  </div>
                                                </MenuItem>
                                                <MenuItem onClick={() => this.openDeleteWidgetConfirmationModal(widget)}>
                                                  <div style={{ cursor: 'pointer' }}>
                                                    <FontAwesomeIcon className="mr-2" icon={faTrash} />
                                                    Delete Slide
                                                  </div>
                                                </MenuItem>
                                              </ContextMenu>
                                            </React.Fragment>
                                          )
                                        }
                                      </Draggable>
                                    )
                                  })
                                }
                                {provided.placeholder}
                              </ListGroup>
                            )
                          }
                        </Droppable>
                      </DragDropContext>
                    }
                    { this.state.selectedReport.widgets.length === 0 &&
                      <ListGroup className="mt-4">
                        <ListGroup.Item>
                          Add new slides from the app to build out a report!
                        </ListGroup.Item>
                      </ListGroup>
                    }
                  </div>
                }
              </Col>
              <Col xs={8}>
                { this.state.selectedWidgetId &&
                  <CustomReportWidget
                    ref={(ref) => this.selectedReportWidget = ref}
                    widget={this.state.selectedWidget}
                    refreshReports={this.refreshReports}
                    voiceOptions={this.state.voiceOptions}
                    updateWidgetsVoice={this.updateWidgetsVoice}
                    updateWidgetsVoiceLoading={this.state.updateWidgetsVoiceLoading}
                  />
                }
              </Col>
            </Row>
          </div>
        }

        <CustomReportModal
          isOpen={this.state.customReportModalOpen}
          handleClose={this.closeCustomReportModal}
          user={this.props.user}
          customReport={this.state.customReportForModal}
          refreshReports={this.refreshReports}
          updateSelectedReportId={this.updateSelectedReportId}
          updateLastCustomReportIdUsed={this.props.updateLastCustomReportIdUsed}
        />
        <CustomReportSettingsModal
          isOpen={this.state.customReportSettingsModalOpen}
          handleClose={this.closeCustomReportSettingsModal}
          customReport={this.state.selectedReport}
          refreshReports={this.refreshReports}
        />
        <AddSlideModal
          isOpen={this.state.addSlideModalOpen}
          handleClose={this.closeAddSlideModal}
          customReport={this.state.selectedReport}
          widgetPlacement={this.state.widgetPlacement}
          refreshReports={this.refreshReports}
        />
        <CustomReportUnsavedChangesModal
          isOpen={this.state.unsavedChangesModalOpen}
          discardChanges={this.discardUnsavedChanges}
          saveChanges={this.saveUnsavedChanges}
        />
        <DeleteConfirmationModal
          isOpen={this.state.deleteReportConfirmationModalOpen}
          handleClose={this.closeDeleteReportConfirmationModal}
          resourceName={this.state.deleteReportName}
          deleteResource={this.deleteReport}
        />
        <DeleteConfirmationModal
          isOpen={this.state.deleteWidgetConfirmationModalOpen}
          handleClose={this.closeDeleteWidgetConfirmationModal}
          resourceName="this slide"
          deleteResource={this.deleteWidget}
        />
      </div>
    );
  }
};
