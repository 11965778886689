import React from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import LicenseUsageContainer from '../../../containers/common/LicenseUsageContainer';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class RequestNewMonitoredCompetitorModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      licenseLoading: true,
      licenseAllowsMore: false,
      additionalBrandsRequest: '1',
      requestLoading: false,
      errorMessage: undefined,
    };
  }

  onOpen = () => {
    this.setState(() => ({
      licenseLoading: true,
      licenseAllowsMore: false,
      errorMessage: '',
    }));
  };

  updateLicenseAllowsAdd = (licenseAllowsMore) => {
    this.setState(() => ({
      licenseLoading: false,
      licenseAllowsMore,
    }));
  };

  onAdditionalBrandsRequestChange = (event) => {
    const additionalBrandsRequest = event.currentTarget.value;
    this.setState(() => ({ additionalBrandsRequest }));
  };

  requestAdditionalBrandsQuote = () => {
    if (
      this.state.additionalBrandsRequest &&
      this.state.additionalBrandsRequest.trim() !== '' &&
      this.props.user &&
      this.props.category
    ) {
      this.setState(() => ({
        requestLoading: true,
        errorMessage: undefined,
      }));
      axios.post(
        `${CONTENT_SCRAPING_ENDPOINT}/api/potential-competitor-request`,
        {
          company_id: this.props.user.customerId,
          category_name: this.props.category.name,
          request_type: 'additional_brands',
          additional_brands: this.state.additionalBrandsRequest
        },
        HEADERS
      ).then(response => {
        this.props.handleClose();
      }).catch(error => {
        console.error(`Error: failed to make potential competitor change request`);
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        this.setState(() => ({ errorMessage: 'Failed to make request, please try again later.' }));
      });
    }
  };

  requestNewMonitoredCompetitor = () => {
    if (this.props.user && this.props.category && this.props.potentialCompetitor) {
      this.setState(() => ({
        requestLoading: true,
        errorMessage: undefined,
      }));
      axios.post(
        `${CONTENT_SCRAPING_ENDPOINT}/api/potential-competitor-request`,
        {
          company_id: this.props.user.customerId,
          category_name: this.props.category.name,
          request_type: 'new_monitored_competitor',
          competitor_domain: this.props.potentialCompetitor.potential_competitor_domain
        },
        HEADERS
      ).then(response => {
        this.props.handleClose();
      }).catch(error => {
        console.error(`Error: failed to make potential competitor change request`);
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        this.setState(() => ({ errorMessage: 'Failed to make request, please try again later.' }));
      });
    }
  };

  render() {
    return (
      <Modal
        show={this.props.isOpen}
        onHide={this.props.handleClose}
        onEntering={this.onOpen}
        size="lg"
      >
        <Modal.Header
          className="bg-bops-blue text-light"
          closeButton
        >
          <Modal.Title>
            Monitor New Competitor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LicenseUsageContainer
            type="brands"
            customerId={this.props.user.customerId}
            updateLicenseAllowsAdd={this.updateLicenseAllowsAdd}
          />
          <div className="my-4">
            { !this.state.licenseLoading &&
              <div>
                { this.state.licenseAllowsMore &&
                  <div>
                    { this.props.potentialCompetitor &&
                      <div>
                        <div className="d-inline-block">
                          {`Add "${this.props.potentialCompetitor.potential_competitor_domain}" to your list?`}
                        </div>
                        <div className="d-inline-block ml-2">
                          <Button
                            variant="primary"
                            onClick={this.requestNewMonitoredCompetitor}
                            size="sm"
                          >
                            Yes
                          </Button>
                        </div>
                        <div className="mt-2 text-muted" style={{ fontSize: '.875rem' }}>
                          Note: New brands will be set up by a BrandOps Customer Support Agent within 2 business days.
                        </div>
                      </div>
                    }
                  </div>
                }
                { !this.state.licenseAllowsMore &&
                  <div>
                    <p>Your license is currently at the limit.</p>
                    <div>
                      <div className="d-inline-block">
                        How many additional brands?
                      </div>
                      <div className="d-inline-block ml-2" style={{ width: '75px' }}>
                        <Form.Control
                          type="number"
                          value={this.state.additionalBrandsRequest}
                          onChange={this.onAdditionalBrandsRequestChange}
                          size="sm"
                        />
                      </div>
                      <div className="d-inline-block ml-3">
                        <Button
                          variant="primary"
                          onClick={this.requestAdditionalBrandsQuote}
                          size="sm"
                        >
                          Request Quote
                        </Button>
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
          <Alert
            show={Boolean(this.state.errorMessage)}
            className="mt-4"
            variant="danger"
          >
            {this.state.errorMessage}
          </Alert>
        </Modal.Body>
      </Modal>
    )
  }
};
