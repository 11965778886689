import React from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CardComponent from '../../common/CardComponent';
import CommentsSummary from '../../listen/CommentsSummary';
import CommentsPagination from '../../listen/CommentsPagination';
import Tweet from '../../listen/Tweet';
import FacebookPost from '../../listen/FacebookPost';
import InstagramComment from '../../listen/InstagramComment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class SocialStreamSentiment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 'all',
      twitter: true,
      facebook: true,
      instagram: true,
      selectedPage: 1,
      commentsPerPage: 20,
      commentsTotal: 0,
      commentsSummary: {},
      commentsData: [],
      commentsDataLoading: false,
      customerTwitUserTags: {},
    };
  }

  componentDidMount() {
    let stateChange = false;
    let twitter = this.state.twitter;
    let facebook = this.state.facebook;
    let instagram = this.state.instagram;
    if (this.props.twitter !== undefined) {
      stateChange = true;
      twitter = this.props.twitter;
    }
    if (this.props.facebook !== undefined) {
      stateChange = true;
      facebook = this.props.facebook;
    }
    if (this.props.instagram !== undefined) {
      stateChange = true;
      instagram = this.props.instagram;
    }

    if (stateChange) {
      this.setState(
        () => ({
          isMounted: true,
          twitter,
          facebook,
          instagram,
        }),
        () => this.refresh()
      );
    } else {
      this.setState(() => ({ isMounted: true }));
      this.refresh();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.topicId !== this.props.topicId ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter
    ) {
      this.refresh();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  refresh = () => {
    this.loadCommentsSummary();
    this.loadComments();
  };

  loadCommentsSummary = () => {
    this.setState(() => ({
      commentsSummary: {
        total: '-',
        promoters: '-',
        detractors: '-',
        neutral: '-'
      }
    }));
    const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
    const filters = ['filters=company_earned'];
    if (!this.state.twitter) {
      filters.push('filters=twitter');
    }
    if (!this.state.facebook) {
      filters.push('filters=facebook');
    }
    if (!this.state.instagram) {
      filters.push('filters=instagram');
    }
    axios.get(
      `${LISTEN_ENDPOINT}/api/comments-summary/topic-id/${this.props.topicId}?${filters.join('&')}&start-date=${formattedStartDate}&end-date=${formattedEndDate}`,
      HEADERS
    ).then(response => {
      const commentsSummary = response.data;
      let commentsTotal;
      switch (this.state.type) {
        case 'all':
          commentsTotal = commentsSummary.total;
          break;
        case 'promoters':
          commentsTotal = commentsSummary.promoters;
          break;
        case 'detractors':
          commentsTotal = commentsSummary.detractors;
          break;
        case 'neutral':
          commentsTotal = commentsSummary.neutral;
          break;
        default:
          commentsTotal = commentsSummary.total;
      }
      if (this.state.isMounted) {
        this.setState(() => ({
          commentsSummary,
          commentsTotal
        }));
      }
    }).catch(error => {
      console.error('Error loading comments summary...');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  loadComments = () => {
    this.setState(() => ({ commentsDataLoading: true }));
    const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
    const filters = ['filters=company_earned'];
    if (!this.state.twitter) {
      filters.push('filters=twitter');
    }
    if (!this.state.facebook) {
      filters.push('filters=facebook');
    }
    if (!this.state.instagram) {
      filters.push('filters=instagram');
    }
    axios.get(
      `${LISTEN_ENDPOINT}/api/comments/topic-id/${this.props.topicId}?type=${this.state.type}&${filters.join('&')}&start-date=${formattedStartDate}&end-date=${formattedEndDate}&limit=${this.state.commentsPerPage}&offset=${this.state.commentsPerPage*(this.state.selectedPage-1)}`,
      HEADERS
    ).then(response => {
      const commentsData = response.data;
      if (this.state.isMounted) {
        this.setState(() => ({
          commentsData,
          commentsDataLoading: false
        }));
      }
    }).catch(error => {
      console.error('Error loading social stream sentiment comments data...');
      if (this.state.isMounted) {
        this.setState(() => ({
          commentsData: [],
          commentsDataLoading: false
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  onTypeChange = (type) => {
    this.setState({
      selectedPage: 1,
      type
    }, this.loadComments);
  }

  onCheckboxChange = (event, type) => {
    const checked = event.currentTarget.checked;
    if (type === 'twitter') {
      this.setState(() => ({
        twitter: checked
      }), this.refresh);
    } else if (type === 'facebook') {
      this.setState(() => ({
        facebook: checked
      }), this.refresh);
    } else if (type === 'instagram') {
      this.setState(() => ({
        instagram: checked
      }), this.refresh);
    }
  };

  updateCommentsTotal = (commentsTotal) => {
    this.setState(({ commentsTotal }));
  }

  onPageSelect = (pageNumber) => {
    if (this.state.selectedPage !== pageNumber) {
      this.setState({ selectedPage: pageNumber }, this.loadComments);
    }
  };

  render () {
    return (
      <CardComponent
        title="Stream with Sentiment"
        icon={
          <img
            className="mr-2 align-top"
            src="/images/Stream_with_Sentiment_Icon.png"
            alt=""
          />
        }
        description="Stream with Sentiment allows you to view social comments with deep sentiment analysis"
        body={
          <div>
            <div className="d-inline-block">
              <CommentsSummary
                commentsSummary={this.state.commentsSummary}
                type={this.state.type}
                onTypeChange={this.onTypeChange}
                updateCommentsTotal={this.updateCommentsTotal}
              />
            </div>
            <div className="d-inline-block ml-4">
              <Form.Check
                type="checkbox"
                label="Twitter"
                checked={this.state.twitter}
                onChange={(event) => this.onCheckboxChange(event, 'twitter')}
                inline
              />
              <Form.Check
                type="checkbox"
                label="Facebook"
                checked={this.state.facebook}
                onChange={(event) => this.onCheckboxChange(event, 'facebook')}
                inline
              />
              <Form.Check
                type="checkbox"
                label="Instagram"
                checked={this.state.instagram}
                onChange={(event) => this.onCheckboxChange(event, 'instagram')}
                inline
              />
            </div>
            <FontAwesomeIcon
              className="float-right"
              icon={faSyncAlt}
              onClick={this.refresh}
              style={{ cursor: 'pointer' }}
            />
            { this.state.commentsDataLoading &&
              <div style={{ height: '100px' }}>
                <ScaleLoader />
              </div>
            }
            { !this.state.commentsDataLoading &&
              <div
                className="mt-2 pre-scrollable"
                style={{
                  height: '400px',
                  maxHeight: '400px'
                }}
              >
                { this.state.commentsData.map((comment) => {
                    if (comment.tweet) {
                      return (
                        <Tweet
                          key={`comment${comment.id}`}
                          comment={comment}
                          customerTwitUserTags={this.state.customerTwitUserTags}
                          refreshComments={this.refresh}
                        />
                      )
                    } else if (comment.facebook_post) {
                      return (
                        <FacebookPost
                          key={`comment${comment.id}`}
                          comment={comment}
                          refreshComments={this.refresh}
                        />
                      )
                    } else if (comment.instagram_post) {
                      return (
                        <InstagramComment
                          key={`comment${comment.id}`}
                          comment={comment}
                          refreshComments={this.refresh}
                        />
                      )
                    } else {
                      return null;
                    }
                  })
                }
              </div>
            }
            { this.state.commentsTotal > this.state.commentsPerPage &&
              <div className="mt-2 text-center">
                <div className="d-inline-block">
                  <div className="d-xs-block d-md-none">
                    <CommentsPagination
                      size="sm"
                      selectedPage={this.state.selectedPage}
                      commentsPerPage={this.state.commentsPerPage}
                      commentsTotal={this.state.commentsTotal}
                      onPageSelect={this.onPageSelect}
                    />
                  </div>
                  <div className="d-none d-md-block">
                    <CommentsPagination
                      selectedPage={this.state.selectedPage}
                      commentsPerPage={this.state.commentsPerPage}
                      commentsTotal={this.state.commentsTotal}
                      onPageSelect={this.onPageSelect}
                    />
                  </div>
                </div>
              </div>
            }
          </div>
        }
        contextType="brand"
        contextCategory={this.props.category}
        contextBrand={this.props.brand}
        contextChannel="Social"
        contextChartName="Stream with Sentiment"
        contextDateRange={`${this.props.startDateFilter.format('MM/DD/YYYY')} - ${this.props.endDateFilter.format('MM/DD/YYYY')}`}
      />
    );
  }
};
