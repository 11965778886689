export const shorthandMonth = (month) => {
  switch (String(month).toLowerCase()) {
    case 'january':
      return 'Jan';
    case 'february':
      return 'Feb';
    case 'march':
      return 'Mar';
    case 'april':
      return 'Apr';
    case 'may':
      return 'May';
    case 'june':
      return 'Jun';
    case 'july':
      return 'Jul';
    case 'august':
      return 'Aug';
    case 'september':
      return 'Sep';
    case 'october':
      return 'Oct';
    case 'november':
      return 'Nov';
    case 'december':
      return 'Dec';
    default:
      return '';
  }
}
