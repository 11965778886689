import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';
import CardComponent from '../../common/CardComponent';
import { numberWithCommas } from '../../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class CategorySearchAdsAvgGraphs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categorySearchAdsSummaryData: [],
      categorySearchAdsSummaryDataLoading: false,
      categoryKingName: '',
      ownersBrandName: ''
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getCategoryAdSummaryData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.getCategoryAdSummaryData();
    }
  }

  getCategoryAdSummaryData = () => {
    if (this.props.category) {
      this.setState(() => ({ categorySearchAdsSummaryDataLoading: true }));
      const categoryAdSummaryRequests = [];
      // get category average ad stats
      categoryAdSummaryRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-average-ads-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=category-average`,
          HEADERS
        ).then(response => {
          const categoryAvgAdData = response.data;
          return { categoryAvgAdData };
        }).catch(error => {
          console.error('Error: unable to fetch category average ads trend');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryAvgAdData: [] };
        })
      );
      // get category king ad stats
      categoryAdSummaryRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-average-ads-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=category-king`,
          HEADERS
        ).then(response => {
          const categoryKingAdData = response.data;
          return { categoryKingAdData };
        }).catch(error => {
          console.error('Error: unable to fetch category king ads trend');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryKingAdData: [] };
        })
      );
      // get customer ad stats
      let ownersBrand;
      for (const brand of this.props.category.product_brands) {
        if (!ownersBrand && brand.company_id === this.props.user.customerId) {
          ownersBrand = brand;
        }
      }
      if (ownersBrand) {
        categoryAdSummaryRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/category-average-ads-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=product-brand&product_brand_id=${ownersBrand.id}`,
            HEADERS
          ).then(response => {
            const ownedBrandAdData = response.data;
            return {
              ownedBrandAdData,
              ownersBrand
            };
          }).catch(error => {
            console.error('Error: unable to fetch owned brand ads trend');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return { ownedBrandAdData: [] };
          })
        );
      }

      Promise.all(categoryAdSummaryRequests).then(responses => {
        let categoryAvgAdData = [];
        let categoryKingAdData = [];
        let ownedBrandAdData = [];
        let highestTraffic = 0;
        let highestTrafficKey = 'category_king_adwords_traffic';
        let highestCosts = 0;
        let highestCostsKey = 'category_king_adwords_costs'
        let categoryKingName = '';
        let ownersBrandName = '';
        for (let response of responses) {
          if (response.categoryAvgAdData) {
            categoryAvgAdData = response.categoryAvgAdData;
          } else if (response.categoryKingAdData) {
            categoryKingAdData = response.categoryKingAdData;
            if (categoryKingAdData.length > 0) {
              categoryKingName = categoryKingAdData[0].product_brand_name;
            }
          } else if (response.ownedBrandAdData) {
            ownedBrandAdData = response.ownedBrandAdData;
            ownersBrandName = response.ownedBrandAdData[0].product_brand_name;
          }
        }
        // merge the 3 data arrays
        // may be able to optimize this, but its a small data set
        const categorySearchAdsSummaryData = [];
        for (let cd of categoryAvgAdData) {
          if (cd.avg_adwords_traffic > highestTraffic) {
            highestTraffic = cd.avg_adwords_traffic;
            highestTrafficKey = 'category_avg_adwords_traffic';
          }
          if (cd.avg_adwords_costs > highestCosts) {
            highestCosts = cd.avg_adwords_costs;
            highestCostsKey = 'category_avg_adwords_costs';
          }
          categorySearchAdsSummaryData.push({
            month: cd.month,
            category_avg_adwords_traffic: cd.avg_adwords_traffic,
            category_avg_adwords_costs: cd.avg_adwords_costs,
          });
        }
        for (let ckd of categoryKingAdData) {
          for (let scoreData of categorySearchAdsSummaryData) {
            if (ckd.adwords_traffic > highestTraffic) {
              highestTraffic = ckd.adwords_traffic;
              highestTrafficKey = 'category_king_adwords_traffic';
            }
            if (ckd.adwords_costs > highestCosts) {
              highestCosts = ckd.adwords_costs;
              highestCostsKey = 'category_king_adwords_costs';
            }
            if (ckd.month === scoreData.month) {
              scoreData.category_king_adwords_traffic = ckd.adwords_traffic;
              scoreData.category_king_adwords_costs = ckd.adwords_costs;
            }
          }
        }
        for (let obd of ownedBrandAdData) {
          for (let scoreData of categorySearchAdsSummaryData) {
            if (obd.adwords_traffic > highestTraffic) {
              highestTraffic = obd.adwords_traffic;
              highestTrafficKey = 'owned_brand_adwords_traffic';
            }
            if (obd.adwords_costs > highestCosts) {
              highestCosts = obd.adwords_costs;
              highestCostsKey = 'owned_brand_adwords_costs';
            }
            if (obd.month === scoreData.month) {
              scoreData.owned_brand_adwords_traffic = obd.adwords_traffic;
              scoreData.owned_brand_adwords_costs = obd.adwords_costs;
            }
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categorySearchAdsSummaryData,
            categorySearchAdsSummaryDataLoading: false,
            highestTrafficKey,
            highestCostsKey,
            categoryKingName,
            ownersBrandName
          }));
        }
      });
    }
  }

  render () {
    return (
      <div>
        <CardComponent
          title="Product Brand Ad Traffic"
          body={
            <div>
              { this.state.categorySearchAdsSummaryDataLoading &&
                <div className="text-center">
                  <ClipLoader size={100}/>
                </div>
              }
              { !this.state.categorySearchAdsSummaryDataLoading &&
                <ResponsiveContainer width="99%" height={200}>
                  <ComposedChart
                    data={this.state.categorySearchAdsSummaryData}
                    margin={{
                      left: 16
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month"/>
                    <YAxis
                      dataKey={this.state.highestTrafficKey}
                      tick={{ fontSize: 12 }}
                      tickFormatter={(value) => {
                        return numberWithCommas(value);
                      }}
                    />
                    <Tooltip
                      formatter={(value) => numberWithCommas(value)}
                    />

                    <Area
                      name={
                        this.state.categoryKingName ?
                          `Leader, ${this.state.categoryKingName} Ad Traffic` :
                          'Category King Ad Traffic'
                      }
                      type="monotone"
                      dataKey="category_king_adwords_traffic"
                      stroke="#007bff"
                      fillOpacity={.4}
                    />
                    <Line
                      name="Category Average Ad Traffic"
                      type="monotone"
                      dataKey="category_avg_adwords_traffic"
                      strokeDasharray="10 10"
                      stroke="#009900"
                      strokeWidth={3}
                    />
                    <Line
                      name={
                        this.state.ownersBrandName ?
                          `${this.state.ownersBrandName} Ad Traffic` :
                          'Your Brand Ad Traffic'
                      }
                      type="monotone"
                      dataKey="owned_brand_adwords_traffic"
                      stroke="#ffc107"
                      strokeWidth={3}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              }
            </div>
          }
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Ads"
          contextProperty="Search Ads"
          contextChartName="Product Brand Ad Traffic"
        />
        <CardComponent
          title="Product Brand Ad Spend"
          body={
            <div>
              { this.state.categorySearchAdsSummaryDataLoading &&
                <div className="text-center">
                  <ClipLoader size={100}/>
                </div>
              }
              { !this.state.categorySearchAdsSummaryDataLoading &&
                <ResponsiveContainer width="99%" height={200}>
                  <ComposedChart
                    data={this.state.categorySearchAdsSummaryData}
                    margin={{
                      left: 16
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month"/>
                    <YAxis
                      dataKey={this.state.highestCostsKey}
                      tick={{ fontSize: 12 }}
                      tickFormatter={(value) => {
                        return numberWithCommas(value);
                      }}
                    />
                    <Tooltip
                      formatter={(value) => `$${numberWithCommas(value)}`}
                    />

                    <Area
                      name={
                        this.state.categoryKingName ?
                          `Leader, ${this.state.categoryKingName} Ad Spend` :
                          'Category King Ad Spend'
                      }
                      type="monotone"
                      dataKey="category_king_adwords_costs"
                      stroke="#007bff"
                      fillOpacity={.4}
                    />
                    <Line
                      name="Category Average Ad Spend"
                      type="monotone"
                      dataKey="category_avg_adwords_costs"
                      strokeDasharray="10 10"
                      stroke="#009900"
                      strokeWidth={3}
                    />
                    <Line
                      name={
                        this.state.ownersBrandName ?
                          `${this.state.ownersBrandName} Ad Spend` :
                          'Your Brand Ad Spend'
                      }
                      type="monotone"
                      dataKey="owned_brand_adwords_costs"
                      stroke="#ffc107"
                      strokeWidth={3}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              }
            </div>
          }
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Ads"
          contextProperty="Search Ads"
          contextChartName="Product Brand Ad Spend"
        />
      </div>
    );
  }
};
