import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ReactTable from 'react-table';
import ClipLoader from 'react-spinners/ClipLoader';
import LoginContainer from '../../containers/common/LoginContainer';
import { filterCaseInsensitive } from '../../utils/reactTable';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class SaasIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saasIndexData: [],
      saasIndexDataLoading: false,
      date: moment().subtract(1, 'months').endOf('month'),
      searchFilter: '',
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchSaasIndexData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevProps.user.customerId &&
      this.props.user.customerId
    ) {
      this.fetchSaasIndexData();
    }
  }

  fetchSaasIndexData = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({ saasIndexDataLoading: true }));
      const formattedDate = this.state.date.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/saas-index-summary?date=${formattedDate}`,
        HEADERS
      ).then(response => {
        const saasIndexData = response.data;
        for (const sd of saasIndexData) {
          if (sd.total_revenue && sd.brand_power) {
            sd.total_revenue_over_brand_power = Math.round(sd.total_revenue/sd.brand_power);
          }
          if (sd.net_income && sd.brand_power) {
            sd.net_income_over_brand_power = Math.round(sd.net_income/sd.brand_power);
          }
        }
        if (this.state.isMounted){
          this.setState(() => ({
            saasIndexData,
            saasIndexDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error(`Error: failed to fetch saas index data`);
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            saasIndexData: [],
            saasIndexDataLoading: false,
          }));
        }
      });
    }
  };

  onSearchFilterChange = (event) => {
    const searchFilter = event.currentTarget.value;
    this.setState(() => ({ searchFilter }));
  };

  render () {
    return (
      <div className="p-4">
        { !(this.props.user && this.props.user.customerId) &&
          <LoginContainer />
        }
        { (this.props.user && this.props.user.customerId) &&
          <div>
            { this.state.saasIndexDataLoading &&
              <div className="m-4 text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.saasIndexDataLoading &&
              <div>
                <ReactTable
                  className="-striped -highlight scrollable"
                  data={this.state.saasIndexData}
                  columns={[
                    {
                      Header: 'Company Name',
                      accessor: 'company_name',
                      headerClassName: 'bg-dark text-light',
                      sortable: true,
                      filterable: true,
                      Cell: row => (
                        <div style={{ fontSize: '.875rem' }}>
                          {row.original.company_name}
                        </div>
                      )
                    }, {
                      Header: 'Ticker',
                      accessor: 'ticker_symbol',
                      headerClassName: 'bg-dark text-light align-bottom',
                      className: 'text-center',
                      sortable: true,
                      filterable: true,
                      width: 100,
                      Cell: row => (
                        <div style={{ fontSize: '.875rem' }}>
                          {row.original.ticker_symbol}
                        </div>
                      )
                    }, {
                      Header: 'Brand Power',
                      accessor: 'brand_power',
                      headerClassName: 'bg-dark text-light',
                      className: 'text-center',
                      sortable: true,
                      Cell: row => (
                        <div style={{ fontSize: '.875rem' }}>
                          {numberWithCommas(Math.round(row.original.brand_power))}
                        </div>
                      )
                    }, {
                      Header: (
                        <div>
                          <div>Monthly</div>
                          <div>Revenue</div>
                        </div>
                      ),
                      accessor: 'total_revenue',
                      headerClassName: 'bg-dark text-light',
                      className: 'text-center',
                      Cell: row => (
                        <div style={{ fontSize: '.875rem' }}>
                          { row.original.total_revenue !== null &&
                            <span>$</span>
                          }
                          {numberWithCommas(row.original.total_revenue)}
                        </div>
                      )
                    }, {
                      Header: (
                        <div>
                          <div>Monthly Revenue</div>
                          <hr
                            style={{
                              margin: '2px 8px',
                              backgroundColor: 'white'
                            }}
                          />
                          <div>Brand Power</div>
                        </div>
                      ),
                      accessor: 'total_revenue_over_brand_power',
                      headerClassName: 'bg-dark text-light',
                      className: 'text-center',
                      Cell: row => (
                        <div style={{ fontSize: '.875rem' }}>
                          {numberWithCommas(row.original.total_revenue_over_brand_power)}
                        </div>
                      )
                    }, {
                      Header: (
                        <div>
                          <div>Monthly Net</div>
                          <div>Income</div>
                        </div>
                      ),
                      accessor: 'net_income',
                      headerClassName: 'bg-dark text-light',
                      className: 'text-center',
                      Cell: row => (
                        <div style={{ fontSize: '.875rem' }}>
                          { row.original.net_income < 0 &&
                            <span>-</span>
                          }
                          { row.original.net_income !== null &&
                            <span>
                              {`$${numberWithCommas(Math.abs(row.original.net_income))}`}
                            </span>
                          }
                        </div>
                      )
                    }, {
                      Header: (
                        <div>
                          <div>Monthly Net Income</div>
                          <hr
                            style={{
                              margin: '2px 8px',
                              backgroundColor: 'white'
                            }}
                          />
                          <div>Brand Power</div>
                        </div>
                      ),
                      accessor: 'net_income_over_brand_power',
                      headerClassName: 'bg-dark text-light',
                      className: 'text-center',
                      Cell: row => (
                        <div style={{ fontSize: '.875rem' }}>
                          {numberWithCommas(row.original.net_income_over_brand_power)}
                        </div>
                      )
                    }
                  ]}
                  minRows = {1}
                  defaultPageSize={
                    this.state.saasIndexData.length > 0 ?
                      this.state.saasIndexData.length : 1
                  }
                  showPagination={false}
                  defaultSorted={[{ id: 'company_name', desc: false }]}
                  defaultFilterMethod={filterCaseInsensitive}
                  style={{
                    maxHeight: 'calc(100vh - 110px)'
                  }}
                />
              </div>
            }
          </div>
        }
      </div>
    );
  }
};
