import React from 'react';
import Card from 'react-bootstrap/Card';
import Tooltip from './Tooltip';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';

export default class CardComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: `card-${Math.random().toString(36).substring(7)}`,
    };
  }

  render () {
    return (
      <Card id={this.state.id} className="mb-4 h-100">
        <Card.Header className="bg-white">
          { this.props.icon &&
            <React.Fragment>
              {this.props.icon}
            </React.Fragment>
          }
          { !this.props.icon &&
            <img
              className="mt-1 mr-2 align-top"
              src="/images/Reviews_Detail_Icon.png"
            />
          }
          { this.props.description &&
            <Tooltip
              tooltipPosition="top"
              tooltipTrigger={
                <b style={{ fontSize: '1.2rem'}}>
                  {this.props.title}
                </b>
              }
              tooltip={this.props.description}
            />
          }
          { !this.props.description &&
            <b style={{ fontSize: '1.2rem'}}>
              {this.props.title}
            </b>
          }
          { !this.props.loading &&
            <div className="d-inline-block float-right">
              <SharePopUpContainer
                shareElementId={this.state.id}
                elementLabel={
                  this.props.shareClassName ?
                    this.props.shareClassName :
                    `${this.state.id}-img`
                }
                position="top"
                contextType={this.props.contextType}
                contextCategory={this.props.contextCategory}
                contextBrand={this.props.contextBrand}
                contextChannel={this.props.contextChannel}
                contextProperty={this.props.contextProperty}
                contextChartName={this.props.contextChartName}
                contextDateRange={this.props.contextDateRange}
              />
            </div>
          }
        </Card.Header>
        <Card.Body>
          {this.props.body}
        </Card.Body>
      </Card>
    );
  }
};
