import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardComponent from '../common/CardComponent';
import CategoryLinkedInPostsGraph from '../graphs/CategoryLinkedInPostsGraph';
import CategoryLinkedInFollowersGraph from '../graphs/CategoryLinkedInFollowersGraph';
import CategoryLinkedInEngagementGraph from '../graphs/CategoryLinkedInEngagementGraph';
import CategoryLinkedInEmployeesGraph from '../graphs/CategoryLinkedInEmployeesGraph';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryReviews extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryLinkedInData: [],
      categoryLinkedInDataLoading: false,
      categoryKingName: '',
      ownersBrandName: '',
      maxPosts: 0,
      maxFollowers: 0,
      maxEngagement: 0,
      maxEmployees: 0
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getCategoryLinkedInData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.categoryId !== this.props.categoryId ||
      prevProps.user.customerId !== this.props.user.customerId) {
      this.getCategoryLinkedInData();
    }
  };

  getCategoryLinkedInData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryLinkedInDataLoading: true }));
      const categoryLinkedInRequests = [];
      // get category average LinkedIn data
      categoryLinkedInRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-average-linkedin-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=category-average`,
          HEADERS
        ).then(response => {
          const categoryAvgLinkedInData = response.data;
          return { categoryAvgLinkedInData };
        }).catch(error => {
          console.error('Error: unable to fetch category LinkedIn data');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryAvgLinkedInData: [] };
        })
      );
      // get category king LinkedIn data
      categoryLinkedInRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-average-linkedin-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=category-king`,
          HEADERS
        ).then(response => {
          const categoryKingLinkedInData = response.data;
          return { categoryKingLinkedInData };
        }).catch(error => {
          console.error('Error: unable to fetch category king LinkedIn data');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { categoryKingLinkedInData: [] };
        })
      );
      // get customer LinkedIn data
      let ownersBrand;
      for (const brand of this.props.category.product_brands) {
        if (!ownersBrand && brand.company_id === this.props.user.customerId) {
          ownersBrand = brand;
        }
      }
      if (ownersBrand) {
        categoryLinkedInRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/category-average-linkedin-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&type=product-brand&product_brand_id=${ownersBrand.id}`,
            HEADERS
          ).then(response => {
            const ownedBrandLinkedInData = response.data;
            return {
              ownedBrandLinkedInData,
              ownersBrand
            };
          }).catch(error => {
            console.error('Error: unable to fetch owned brand LinkedIn data');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return { ownedBrandLinkedInData: [] };
          })
        );
      }

      Promise.all(categoryLinkedInRequests).then(responses => {
        let categoryAvgLinkedInData = [];
        let categoryKingLinkedInData = [];
        let ownedBrandLinkedInData = [];
        let categoryKingName = '';
        let ownersBrandName = '';
        for (let response of responses) {
          if (response.categoryAvgLinkedInData) {
            categoryAvgLinkedInData = response.categoryAvgLinkedInData;
          } else if (response.categoryKingLinkedInData) {
            categoryKingLinkedInData = response.categoryKingLinkedInData;
            if (categoryKingLinkedInData.length > 0)
              categoryKingName = categoryKingLinkedInData[0].product_brand_name;
          } else if (response.ownedBrandLinkedInData) {
            ownedBrandLinkedInData = response.ownedBrandLinkedInData;
            ownersBrandName = response.ownersBrand.name;
          }
        }
        // merge the 3 data arrays
        // may be able to optimize this, but its a small data set
        const categoryLinkedInData = [];
        let maxPosts = 0;
        let maxFollowers = 0;
        let maxEngagement = 0;
        let maxEmployees = 0;
        for (let cd of categoryAvgLinkedInData) {
          maxPosts = cd.posts > maxPosts ? cd.posts : maxPosts;
          maxFollowers = cd.followers > maxFollowers ? cd.followers : maxFollowers;
          maxEngagement = cd.engagement > maxEngagement ? cd.engagement : maxEngagement;
          maxEmployees = cd.employees > maxEmployees ? cd.employees : maxEmployees;
          categoryLinkedInData.push({
            month: cd.month,
            category_avg_posts: cd.posts,
            category_avg_followers: cd.followers,
            category_avg_engagement: cd.engagement,
            category_avg_employees: cd.employees
          });
        }
        for (let ckd of categoryKingLinkedInData) {
          for (let linkedInData of categoryLinkedInData) {
            maxPosts = ckd.posts > maxPosts ? ckd.posts : maxPosts;
            maxFollowers = ckd.followers > maxFollowers ? ckd.followers : maxFollowers;
            maxEngagement = ckd.engagement > maxEngagement ? ckd.engagement : maxEngagement;
            maxEmployees = ckd.employees > maxEmployees ? ckd.employees : maxEmployees;
            if (ckd.month === linkedInData.month) {
              linkedInData.category_king_posts = ckd.posts;
              linkedInData.category_king_followers = ckd.followers;
              linkedInData.category_king_engagement = ckd.engagement;
              linkedInData.category_king_employees = ckd.employees;
            }
          }
        }
        for (let obd of ownedBrandLinkedInData) {
          for (let linkedInData of categoryLinkedInData) {
            maxPosts = obd.posts > maxPosts ? obd.posts : maxPosts;
            maxFollowers = obd.followers > maxFollowers ? obd.followers : maxFollowers;
            maxEngagement = obd.engagement > maxEngagement ? obd.engagement : maxEngagement;
            maxEmployees = obd.employees > maxEmployees ? obd.employees : maxEmployees;
            if (obd.month === linkedInData.month) {
              linkedInData.owned_brand_posts = obd.posts;
              linkedInData.owned_brand_followers = obd.followers;
              linkedInData.owned_brand_engagement = obd.engagement;
              linkedInData.owned_brand_employees = obd.employees;
            }
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryLinkedInData,
            categoryLinkedInDataLoading: false,
            categoryKingName,
            ownersBrandName,
            maxPosts,
            maxFollowers,
            maxEngagement,
            maxEmployees
          }));
        }
      }).catch(error => {
        console.log('Error: unable to promise all LinkedIn data');
        this.setState(() => ({
          categoryLinkedInData: [],
          categoryLinkedInDataLoading: false,
          categoryKingName: "",
          ownersBrandName: "",
          maxPosts: 0,
          maxFollowers: 0,
          maxEngagement: 0,
          maxEmployees: 0
        }));
      });
    }
  }

  render () {
    return (
      <div>
        <Row className="mb-4">
          <Col>
            <CardComponent
              title="Posts"
              body={
                <CategoryLinkedInPostsGraph
                  categoryLinkedInDataLoading={this.state.categoryLinkedInDataLoading}
                  categoryLinkedInData={this.state.categoryLinkedInData}
                  maxPosts={this.state.maxPosts}
                  categoryKingName={this.state.categoryKingName}
                  ownersBrandName={this.state.ownersBrandName}
                />
              }
              contextType="category"
              contextCategory={this.props.category}
              contextBrand={null}
              contextChannel="Social"
              contextProperty="LinkedIn"
              contextChartName="Posts"
              shareClassName="category-linkedin-posts-img"
            />
          </Col>
          <Col>
            <CardComponent
              title="Follower Count"
              body={
                <CategoryLinkedInFollowersGraph
                  categoryLinkedInDataLoading={this.state.categoryLinkedInDataLoading}
                  categoryLinkedInData={this.state.categoryLinkedInData}
                  maxFollowers={this.state.maxFollowers}
                  categoryKingName={this.state.categoryKingName}
                  ownersBrandName={this.state.ownersBrandName}
                />
              }
              contextType="category"
              contextCategory={this.props.category}
              contextBrand={null}
              contextChannel="Social"
              contextProperty="LinkedIn"
              contextChartName="Follower Count"
              shareClassName="category-linkedin-followers-img"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CardComponent
              title="Engagement"
              body={
                <CategoryLinkedInEngagementGraph
                  categoryLinkedInDataLoading={this.state.categoryLinkedInDataLoading}
                  categoryLinkedInData={this.state.categoryLinkedInData}
                  maxEngagement={this.state.maxEngagement}
                  categoryKingName={this.state.categoryKingName}
                  ownersBrandName={this.state.ownersBrandName}
                />
              }
              contextType="category"
              contextCategory={this.props.category}
              contextBrand={null}
              contextChannel="Social"
              contextProperty="LinkedIn"
              contextChartName="Engagement"
              shareClassName="category-linkedin-engagement-img"
            />
          </Col>
          <Col>
            <CardComponent
              title="Employees"
              body={
                <CategoryLinkedInEmployeesGraph
                  categoryLinkedInDataLoading={this.state.categoryLinkedInDataLoading}
                  categoryLinkedInData={this.state.categoryLinkedInData}
                  maxEmployees={this.state.maxEmployees}
                  categoryKingName={this.state.categoryKingName}
                  ownersBrandName={this.state.ownersBrandName}
                />
              }
              contextType="category"
              contextCategory={this.props.category}
              contextBrand={null}
              contextChannel="Social"
              contextProperty="LinkedIn"
              contextChartName="Employees"
              shareClassName="category-linkedin-employees-img"
            />
          </Col>
        </Row>
      </div>
    );
  }
};
