import React, { useRef } from 'react';
import ReactSelect, { components } from 'react-select';
import Form from 'react-bootstrap/Form';

export const BrandsMultiSelect = props => {
  // isOptionSelected sees previous props.value after onChange
  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  const selectAllOption = {
    value: '<SELECT_ALL>',
    label: props.selectAllLabel || 'All Brands'
  };

  const isSelectAllSelected = () => {
    return valueRef.current.length === props.options.length;
  }

  const isOptionSelected = option => {
    return valueRef.current.some(({ value }) => value === option.value) || isSelectAllSelected();
  }

  const getOptions = () => {
    return [selectAllOption, ...props.options];
  }

  const getValue = () => {
    return isSelectAllSelected() ? [selectAllOption] : props.value;
  }

  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;

    if (action === "select-option" && option.value === selectAllOption.value) {
      props.onChange(props.options, actionMeta);
    } else if (
      (action === "deselect-option" && option.value === selectAllOption.value) ||
      (action === "remove-value" && removedValue.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta);
    } else if (actionMeta.action === "deselect-option" && isSelectAllSelected()) {
      props.onChange(props.options.filter(({ value }) => value !== option.value), actionMeta);
    } else {
      props.onChange(newValue || [], actionMeta);
    }
  }

  const ValueContainer = (props) => {
    return (
      <div className="mx-2">
        Select Brands...
      </div>
    )
  }

  const CheckboxOption = (props) => {
    if (props.isDisabled) {
      return null;
    } else {
      return (
        <components.Option {...props}>
          <Form.Check
            type="checkbox"
            checked={props.isSelected}
            onChange={() => {
              // do nothing
            }}
            inline
          />
          {props.label}
        </components.Option>
      );
    }
  }

  return (
    <ReactSelect
      isOptionSelected={isOptionSelected}
      options={getOptions()}
      value={getValue()}
      onChange={onChange}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      placeholder={props.placeholder || 'Select Brands...'}
      components={{
        ValueContainer: ValueContainer,
        Option: CheckboxOption
      }}
      isMulti
    />
  );
};
