import React from 'react';
import { numberWithCommas } from '../../utils/numbers';

const CommentsSummary = (props) => {
  return (
    <div>
      { props.commentsSummary &&
        <div>
          <div className="d-inline-block mr-4 text-secondary">
            <div className="text-center" style={{ fontSize: '1.5rem' }}>
              <b>{numberWithCommas(props.commentsSummary.total)}</b>
            </div>
            <button
              type="button"
              className= {
                props.type === 'all' ?
                  'btn border-primary bg-white text-secondary' :
                  'btn border bg-white text-secondary'
              }
              onClick={() => {
                props.onTypeChange('all');
                props.updateCommentsTotal(props.commentsSummary.total);
              }}
            >
              Comments
            </button>
          </div>
          <div className="d-inline-block mr-4 text-success">
            <div className="text-center" style={{ fontSize: '1.5rem' }}>
              <b>{numberWithCommas(props.commentsSummary.promoters)}</b>
            </div>
            <button
              type="button"
              className= {
                props.type === 'promoters' ?
                  'btn border-primary bg-white text-success' :
                  'btn border bg-white text-success'
              }
              onClick={() => {
                props.onTypeChange('promoters');
                props.updateCommentsTotal(props.commentsSummary.promoters);
              }}
            >
              Promoters
            </button>
          </div>
          <div className="d-inline-block mr-4 text-primary">
            <div className="text-center" style={{ fontSize: '1.5rem' }}>
              <b>{numberWithCommas(props.commentsSummary.neutral)}</b>
            </div>
            <button
              type="button"
              className= {
                props.type === 'neutral' ?
                  'btn border-primary bg-white text-primary' :
                  'btn border bg-white text-primary'
              }
              onClick={() => {
                props.onTypeChange('neutral');
                props.updateCommentsTotal(props.commentsSummary.neutral);
              }}
            >
              Neutral
            </button>
          </div>
          <div className="d-inline-block mr-4 text-danger">
            <div className="text-center" style={{ fontSize: '1.5rem' }}>
              <b>{numberWithCommas(props.commentsSummary.detractors)}</b>
            </div>
            <button
              type="button"
              className= {
                props.type === 'detractors' ?
                  'btn border-primary bg-white text-danger' :
                  'btn border bg-white text-danger'
              }
              onClick={() => {
                props.onTypeChange('detractors');
                props.updateCommentsTotal(props.commentsSummary.detractors);
              }}
            >
              Detractors
            </button>
          </div>
        </div>
      }
    </div>
  );
};

export default CommentsSummary;
