import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '../../utils/numbers';

const ReviewSummaryBar = (props) => {
  return (
    <div>
      <div className="d-inline-block align-top text-secondary">
        <div className="d-inline-block" style={{ width: '10px' }}>
          {props.starNumber}
        </div>
        <FontAwesomeIcon icon={faStar} />
      </div>
      <div className="d-inline-block ml-2" style={{ width: '85%' }}>
        <div
          className="d-inline-block mt-1"
          style={{
            height: '18px',
            background: '#007bff',
            width: `${props.reviewsPercent}%`
          }}
        />
        <div className={`d-inline-block align-top text-secondary${props.reviewsCount > 0 ? ' ml-1' : ''}`}>
          {numberWithCommas(props.reviewsCount)}
        </div>
      </div>
    </div>
  )
};

export default ReviewSummaryBar;
