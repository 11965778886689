import { connect } from 'react-redux';
import UpdateOverallScoreWeightsModal from '../../components/categories/UpdateOverallScoreWeightsModal';
import { updateOverallScoreWeights } from '../../actions/scores/overallScoreWeights';

const mapStateToProps = (state) => ({
  overallScoreWeights: state.overallScoreWeights,
});

const mapDispatchToProps = (dispatch) => ({
  updateOverallScoreWeights: (weights) => dispatch(updateOverallScoreWeights(weights)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateOverallScoreWeightsModal);
