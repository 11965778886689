import React from 'react';
import moment from 'moment';
import { numberWithCommas } from '../../utils/numbers';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

const EmailCampaignStatsGraph = (props) => (
  <div>
  <ResponsiveContainer height={270} width="99%">
    <LineChart data={props.campaignData}>
      <XAxis
        dataKey={props.dateKey}
        tickFormatter={(date) => {
          return moment(date).endOf('day').format('MM/DD');
        }}
      />
      <YAxis/>
      <Tooltip labelFormatter={(label) => moment(label).format('dddd, MMMM Do YYYY')}/>
      <CartesianGrid strokeDasharray="5 5"/>
      <Line name="Opened" type="monotone" dataKey={props.openedKey} stroke="#007bff"/>
      <Line name="Clicked" type="monotone" dataKey={props.clickedKey} stroke="#fcba03"/>
      <Line name="Unsubscribed" type="monotone" dataKey={props.unsubscribedKey} stroke="#ff4d4d"/>
    </LineChart>
  </ResponsiveContainer>
  </div>
);

export default EmailCampaignStatsGraph;
