import React from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class LicenseUsage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      type: '',
      licenseUsed: 0,
      licenseTotal: 0,
      licenseAllowsAdd: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.refresh();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.customerId !== this.props.customerId) {
      this.refresh();
    }
  };

  refresh = () => {
    this.setState(() => ({ loading: true }));
    if (this.props.type === 'brands') {
      this.getBrandsLicenseUsageData();
    } else if (this.props.type === 'privateCollectors') {
      this.getPrivateCollectorsLicenseUsageData();
    } else if (this.props.type === 'reviewLocations') {
      this.getReviewLocationsLicenseUsageData();
    }
  };

  getBrandsLicenseUsageData = () => {
    const type = 'Brands';
    let licenseUsed = 0;
    if (this.props.categories) {
      for (const c of this.props.categories) {
        for (const b of c.product_brands) {
          licenseUsed += 1;
        }
      }
    }

    axios.get(
      `${LISTEN_ENDPOINT}/api/customers/${this.props.customerId}`,
      HEADERS
    ).then(response => {
      const customer = response.data;
      let licenseBase = 0;
      if (customer.license_type === 'brand watcher') {
        licenseBase = 6;
      } else if (customer.license_type === 'brand master') {
        licenseBase = 20;
      }
      if (this.state.isMounted) {
        this.setState(() => ({
          type,
          licenseUsed,
          licenseTotal: licenseBase + customer.expansion_brand_count,
          loading: false
        }), () => this.updateLicenseAllowsAdd());
      }
    }).catch(error => {
      console.error('Error: failed to fetch customer for license usage...');
      if (this.state.isMounted) {
        this.setState(() => ({
          type,
          licenseUsed,
          licenseTotal: 'N/A',
          loading: false
        }), () => this.updateLicenseAllowsAdd());
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  getPrivateCollectorsLicenseUsageData = () => {
    const type = 'Private Collectors';
    const privateCollectorRequests = [];
    // get customer
    privateCollectorRequests.push(
      axios.get(
        `${LISTEN_ENDPOINT}/api/customers/${this.props.customerId}`,
        HEADERS
      ).then(response => {
        const customer = response.data;
        let licenseBase = 0;
        if (customer.license_type === 'brand watcher') {
          licenseBase = 1;
        } else if (customer.license_type === 'brand master') {
          licenseBase = 5;
        }
        return { licenseTotal: licenseBase + customer.expansion_private_collector_count };
      }).catch(error => {
        console.error('Error: failed to fetch customer for license usage...');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        return { licenseTotal: 'N/A' };
      })
    );
    // get private collectors
    privateCollectorRequests.push(
      axios.get(
        `${LISTEN_ENDPOINT}/api/private-keys?company_id=${this.props.customerId}`,
        HEADERS
      ).then(response => {
        const companyPrivateKeys = response.data;
        return { licenseUsed: companyPrivateKeys.length };
      }).catch(error => {
        console.error('Error: unable to fetch company private keys...');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        if (this.state.isMounted) {
          return { licenseUsed: 'N/A' };
        }
      })
    );

    Promise.all(privateCollectorRequests).then(responses => {
      let licenseUsed = 'N/A';
      let licenseTotal = 'N/A';
      for (const response of responses) {
        if (response.licenseUsed != null) {
          licenseUsed = response.licenseUsed;
        } else if (response.licenseTotal != null) {
          licenseTotal = response.licenseTotal;
        }
      }

      if (this.state.isMounted) {
        this.setState(() => ({
          type,
          licenseUsed,
          licenseTotal,
          loading: false
        }), () => this.updateLicenseAllowsAdd());
      }
    });
  };

  getReviewLocationsLicenseUsageData = () => {
    const type = 'Review Locations';
    const reviewLocationsRequests = [];
    // get customer
    reviewLocationsRequests.push(
      axios.get(
        `${LISTEN_ENDPOINT}/api/customers/${this.props.customerId}`,
        HEADERS
      ).then(response => {
        const customer = response.data;
        return { licenseTotal: customer.expansion_review_locations_count };
      }).catch(error => {
        console.error('Error: failed to fetch customer for license usage...');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        return { licenseTotal: 'N/A' };
      })
    );
    // get review locations
    reviewLocationsRequests.push(
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/review-locations?sponsor_customer_id=${this.props.customerId}`,
        HEADERS
      ).then(response => {
        const reviewLocations = response.data;
        return { licenseUsed: reviewLocations.length };
      }).catch(error => {
        console.error('Error: unable to fetch company review locations...');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        if (this.state.isMounted) {
          return { licenseUsed: 'N/A' };
        }
      })
    );

    Promise.all(reviewLocationsRequests).then(responses => {
      let licenseUsed = 'N/A';
      let licenseTotal = 'N/A';
      for (const response of responses) {
        if (response.licenseUsed != null) {
          licenseUsed = response.licenseUsed;
        } else if (response.licenseTotal != null) {
          licenseTotal = response.licenseTotal;
        }
      }

      if (this.state.isMounted) {
        this.setState(() => ({
          type,
          licenseUsed,
          licenseTotal,
          loading: false
        }), () => this.updateLicenseAllowsAdd());
      }
    });
  };

  updateLicenseAllowsAdd = () => {
    let licenseAllowsAdd = false;
    if (
      !isNaN(parseInt(this.state.licenseUsed)) &&
      !isNaN(parseInt(this.state.licenseTotal)) &&
      this.state.licenseUsed < this.state.licenseTotal
    ) {
      licenseAllowsAdd = true;
    }

    if (this.state.isMounted) {
      this.setState(() => ({ licenseAllowsAdd }));
      if (this.props.updateLicenseAllowsAdd) {
        this.props.updateLicenseAllowsAdd(licenseAllowsAdd);
      }
    }
  }

  render() {
    return (
      <div>
        { this.state.loading &&
          <ClipLoader />
        }
        { !this.state.loading &&
          <Alert variant={this.state.licenseAllowsAdd ? 'info': 'danger'}>
            <React.Fragment>
              {`${this.state.type} License Usage: ${this.state.licenseUsed} of ${this.state.licenseTotal}`}
              { !this.state.licenseAllowsAdd &&
                <span className="ml-2">
                  - unable to add more
                </span>
              }
            </React.Fragment>
          </Alert>
        }
      </div>
    );
  }
};
