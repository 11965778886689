import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClipLoader from 'react-spinners/ClipLoader';
import Tooltip from 'reactjs-popup';
import StarScoreBar from '../../graphs/StarScoreBar';
import { sortAlphabeticalByKey } from '../../../utils/sorts';
import { numberWithCommas } from '../../../utils/numbers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';
import history from '../../../routers/history';

export default class EcommerceSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEcommerce: 'target',
      keywords: [],
      selectedKeywordId: undefined,
      dates: [],
      selectedDate: undefined,
      results: [],
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchSearchTerms();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      this.setState(() => ({
        keywords: [],
        selectedKeywordId: undefined,
        dates: [],
        selectedDate: undefined,
        results: [],
      }));
      this.fetchSearchTerms();
    }
  };

  fetchSearchTerms = () => {
    if (this.props.category) {
      const fetchKeywordRequests = [];
      if (this.props.category.category_type === 'extended') {
        fetchKeywordRequests.push(
          axios.get(
            `${CONTENT_SCRAPING_ENDPOINT}/api/ecommerce-search-terms?category_type=public&category_id=${this.props.category.public_category_id}`,
            HEADERS
          ).then(response => {
            const publicKeywords = response.data;
            publicKeywords.sort(sortAlphabeticalByKey('search_term'));
            return { publicKeywords };
          }).catch(error => {
            console.error('Error: unable to fetch category ecommerce private keywords...');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return { publicKeywords: [] };
          })
        );
      }

      fetchKeywordRequests.push(
        axios.get(
          `${CONTENT_SCRAPING_ENDPOINT}/api/ecommerce-search-terms?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
          HEADERS
        ).then(response => {
          const privateKeywords = response.data;
          privateKeywords.sort(sortAlphabeticalByKey('search_term'));
          return { privateKeywords };
        }).catch(error => {
          console.error('Error: unable to fetch category ecommerce private keywords...');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { privateKeywords: [] };
        })
      );

      Promise.all(fetchKeywordRequests).then(responses => {
        let keywords = [];
        let publicKeywords = [];
        let privateKeywords = [];
        for (const response of responses) {
          if (response.publicKeywords) {
            publicKeywords = response.publicKeywords;
          } else if (response.privateKeywords) {
            privateKeywords = response.privateKeywords;
          }
        }
        keywords = keywords.concat(publicKeywords, privateKeywords);

        let selectedKeywordId;
        if (keywords.length > 0) {
          selectedKeywordId = keywords[0].id;
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            keywords,
            selectedKeywordId,
            dates: [],
            selectedDate: undefined,
          }), () => this.fetchDates());
        }
      });
    }
  };

  fetchDates = () => {
    if (this.state.selectedEcommerce && this.state.selectedKeywordId) {
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/ec-product-brand-search-dates?site_name=${this.state.selectedEcommerce}&ecommerce_search_term_id=${this.state.selectedKeywordId}`,
        HEADERS
      ).then(response => {
        const dates = response.data.sort((a, b) => moment(b).format('YYYYMMDD') - moment(a).format('YYYYMMDD'));
        if (this.state.isMounted) {
          if (dates.length > 0) {
            this.setState(() => ({
              dates,
              selectedDate: dates[0]
            }), () => this.fetchResults());
          } else {
            this.setState(() => ({
              dates,
              selectedDate: undefined,
              results: []
            }));
          }
        }
      }).catch(error => {
        console.error('Error: unable to fetch ecommerce product brand search dates');
        if (this.state.isMounted) {
          this.setState(() => ({
            dates: [],
            selectedDate: undefined,
            results: []
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchResults = () => {
    if (this.state.selectedEcommerce && this.state.selectedKeywordId && this.state.selectedDate) {
      const formattedDate = moment.utc(this.state.selectedDate).format('YYYY-MM-DD');
      this.setState(() => ({ resultsLoading: true }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/ec-product-brand-positions?ecommerce_search_term_id=${this.state.selectedKeywordId}&site_name=${this.state.selectedEcommerce}&capture_date=${formattedDate}`,
        HEADERS
      ).then(response => {
        const results = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            results,
            resultsLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch ecommerce product brand positions...');
        if (this.state.isMounted) {
          this.setState(() => ({
            results: [],
            resultsLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  selectEcommerce = (selectedEcommerce) => {
    if (this.state.selectedEcommerce !== selectedEcommerce) {
      this.setState(
        () => ({ selectedEcommerce }),
        () => this.fetchDates()
      );
    }
  };

  selectSearch = (selectedKeywordId) => {
    if (this.state.selectedKeywordId !== selectedKeywordId) {
      this.setState(
        () => ({ selectedKeywordId }),
        () => this.fetchDates()
      );
    }
  };

  selectDate = (selectedDate) => {
    if (this.state.selectedDate !== selectedDate) {
      this.setState(
        () => ({ selectedDate }),
        () => this.fetchResults()
      );
    }
  };

  openItemUrl = (url) => {
    if (this.state.selectedEcommerce === 'target') {
      window.open(`https://target.com${url}`);
    }
  };

  render () {
    return (
      <div
        className="m-4 pre-scrollable"
        style={{
          minHeight: 'calc(100vh - 200px)',
          maxHeight: 'calc(100vh - 200px)',
          overflow: 'auto',
          overflowX: 'hidden'
        }}
      >
        <div className="mb-4">
          <div
            className={
              this.state.selectedEcommerce === 'target' ?
                'd-inline-block mr-2 px-2 py-1 bg-white border border-primary rounded' :
                'd-inline-block mr-2 px-2 py-1 bg-white border rounded'
            }
            onClick={() => this.selectEcommerce('target')}
            style={{ cursor: 'pointer' }}
          >
            <img
              className="mr-2"
              alt=""
              crossOrigin="anonymous"
              src="https://www.target.com/favicon.ico"
              style={{ width: '24px' }}
            />
            <b>Target</b>
          </div>
          <div
            className={
              this.state.selectedEcommerce === 'amazon' ?
                'd-inline-block mr-2 px-2 py-1 bg-white border border-primary rounded' :
                'd-inline-block mr-2 px-2 py-1 bg-white border rounded'
            }
            onClick={() => this.selectEcommerce('amazon')}
            style={{ cursor: 'pointer' }}
          >
            <img
              className="mr-2"
              alt=""
              crossOrigin="anonymous"
              src="https://www.amazon.com/favicon.ico"
              style={{ width: '24px' }}
            />
            <b>Amazon</b>
          </div>
          <div
            className={
              this.state.selectedEcommerce === 'walmart' ?
                'd-inline-block mr-2 px-2 py-1 bg-white border border-primary rounded' :
                'd-inline-block mr-2 px-2 py-1 bg-white border rounded'
            }
            onClick={() => this.selectEcommerce('walmart')}
            style={{ cursor: 'pointer' }}
          >
            <img
              className="mr-2"
              alt=""
              crossOrigin="anonymous"
              src="https://www.walmart.com/favicon.ico"
              style={{ width: '24px' }}
            />
            <b>Walmart</b>
          </div>
        </div>
        <Row>
          <Col xs={4} md={3} lg={2}>
            <div className="mb-2">
              <h5>
                Keywords
              </h5>
              <div
                className="pre-scrollable border rounded bg-white"
                style={{
                  minHeight: '125px',
                  maxHeight: '125px'
                }}
              >
                { this.state.keywords.map(keyword => {
                    return (
                      <Tooltip
                        key={`st-${keyword.id}`}
                        trigger = {
                          <div
                            className={
                              this.state.selectedKeywordId === keyword.id ?
                                'bg-primary text-light rounded px-2' :
                                'px-2'
                            }
                            onClick={() => this.selectSearch(keyword.id)}
                            style={{
                              cursor: 'pointer',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontSize: "11pt"
                            }}
                          >
                            { keyword.category_type === 'public' &&
                              <FontAwesomeIcon
                                className="mr-1"
                                icon={faLock}
                              />
                            }
                            {keyword.search_term}
                          </div>
                        }
                        position="right top"
                        on="hover"
                        contentStyle={{ borderRadius: '5px' }}
                      >
                        <div
                          className="text-dark"
                          style={{ fontSize: '.875rem', fontWeight: 'normal' }}
                        >
                          {keyword.search_term}
                        </div>
                      </Tooltip>
                    )
                  })
                }
              </div>
            </div>
            <div className="mb-2">
              <h5>Date</h5>
              <div
                className="pre-scrollable border rounded bg-white"
                style={{
                  minHeight: '125px',
                  maxHeight: '125px'
                }}
              >
                { this.state.dates.map((date, i) => {
                    return (
                      <div
                        key={`date-${i}`}
                        className={
                          this.state.selectedDate === date ?
                            'bg-primary text-light rounded px-2' :
                            'px-2'
                        }
                        onClick={() => this.selectDate(date)}
                        style={{
                          cursor: 'pointer',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontSize: "11pt"
                        }}
                      >
                        {moment.utc(date).format('MM-D-YYYY')}
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </Col>
          <Col>
            { this.state.resultsLoading &&
              <div className="m-4 text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.resultsLoading &&
              <div>
                <Row>
                  { this.state.results.map((r, i) => {
                      return (
                        <Col
                          key={`es-r-${i}`}
                          sm={12} md={6} lg={4}
                          className="mb-4"
                        >
                          <div className="p-2 bg-white border rounded h-100">
                            <div className="text-center">
                              { r.primary_image &&
                                <img
                                  src={r.primary_image}
                                  alt=""
                                  crossOrigin="anonymous"
                                  style={{  maxWidth: '100%' }}
                                />
                              }
                            </div>
                            <hr />
                            <div
                              className="font-weight-bold"
                              onClick={() => this.openItemUrl(r.url)}
                              style={{ cursor: 'pointer' }}
                            >
                              <div
                                dangerouslySetInnerHTML={{ __html: r.title }}
                              />
                            </div>
                            <div className="text-muted" style={{ fontSize: '.875rem' }}>
                              {r.brand}
                            </div>
                            { r.average_score != null &&
                              <div style={{ fontSize: '.875rem' }}>
                                <div className="d-inline-block">
                                  <StarScoreBar
                                    score={r.average_score}
                                    maxScore={5}
                                    starColor="gold"
                                  />
                                </div>
                                <div className="d-inline-block ml-2">
                                  {numberWithCommas(r.total_reviews_count)}
                                </div>
                              </div>
                            }
                            { r.formatted_current_price &&
                              <div className="mt-2">
                                {r.formatted_current_price}
                              </div>
                            }
                          </div>
                        </Col>
                      )
                    })
                  }
                </Row>
              </div>
            }
          </Col>
        </Row>
      </div>
    );
  }
};
