const nodeEnv = process.env.NODE_ENV || 'development';
let listenEndpoint;
let contentScrapingEndpoint;
let lambdaEndpoint;
let stanzaEndpoint;
let lcxCoreExpressEndpoint;
let lcxDisplayExpressEndpoint;
let proxyEmailEndpoint;
let gkgEndpoint;
let topicModelsEndpoint;
let bucketKey;
let bucketSKey;
let apiGatewayKey;
let exportKey;
let cannyBoardToken;

if (nodeEnv === 'production') {
  listenEndpoint = process.env.REACT_APP_LISTEN_ENDPOINT;
  contentScrapingEndpoint = process.env.REACT_APP_CONTENT_SCRAPING_ENDPOINT;
  lambdaEndpoint = process.env.REACT_APP_LAMBDA_ENDPOINT;
  stanzaEndpoint = process.env.REACT_APP_STANZA_ENDPOINT;
  lcxCoreExpressEndpoint = process.env.REACT_APP_LCX_CORE_EXPRESS_ENDPOINT;
  lcxDisplayExpressEndpoint = process.env.REACT_APP_LCX_DISPLAY_EXPRESS_ENDPOINT;
  proxyEmailEndpoint = process.env.REACT_APP_PROXY_EMAIL_ENDPOINT;
  gkgEndpoint = process.env.REACT_APP_GKG_ENDPOINT;
  topicModelsEndpoint = process.env.REACT_APP_TOPIC_MODELS_ENDPOINT;
  bucketKey = process.env.REACT_APP_BUCKET_KEY;
  bucketSKey = process.env.REACT_APP_BUCKET_SKEY;
  apiGatewayKey = process.env.REACT_APP_API_GATEWAY_KEY;
  exportKey = process.env.REACT_APP_EXPORT_KEY;
  cannyBoardToken = process.env.REACT_APP_CANNY_BOARD_TOKEN;
} else {
  listenEndpoint = process.env.REACT_APP_DEV_LISTEN_ENDPOINT;
  contentScrapingEndpoint = process.env.REACT_APP_DEV_CONTENT_SCRAPING_ENDPOINT;
  lambdaEndpoint = process.env.REACT_APP_DEV_LAMBDA_ENDPOINT;
  stanzaEndpoint = process.env.REACT_APP_DEV_STANZA_ENDPOINT;
  lcxCoreExpressEndpoint = process.env.REACT_APP_DEV_LCX_CORE_EXPRESS_ENDPOINT;
  lcxDisplayExpressEndpoint = process.env.REACT_APP_DEV_LCX_DISPLAY_EXPRESS_ENDPOINT;
  proxyEmailEndpoint = process.env.REACT_APP_DEV_PROXY_EMAIL_ENDPOINT;
  gkgEndpoint = process.env.REACT_APP_DEV_GKG_ENDPOINT;
  topicModelsEndpoint = process.env.REACT_APP_DEV_TOPIC_MODELS_ENDPOINT;
  bucketKey = process.env.REACT_APP_DEV_BUCKET_KEY;
  bucketSKey = process.env.REACT_APP_DEV_BUCKET_SKEY;
  apiGatewayKey = process.env.REACT_APP_DEV_API_GATEWAY_KEY;
  exportKey = process.env.REACT_APP_DEV_EXPORT_KEY;
  cannyBoardToken = process.env.REACT_APP_DEV_CANNY_BOARD_TOKEN;
}

export const LISTEN_ENDPOINT = listenEndpoint;
export const CONTENT_SCRAPING_ENDPOINT = contentScrapingEndpoint;
export const LAMBDA_ENDPOINT = lambdaEndpoint;
export const STANZA_ENDPOINT = stanzaEndpoint;
export const LCX_CORE_EXPRESS_ENDPOINT = lcxCoreExpressEndpoint;
export const LCX_DISPLAY_EXPRESS_ENDPOINT = lcxDisplayExpressEndpoint;
export const PROXY_EMAIL_ENDPOINT = proxyEmailEndpoint;
export const GKG_ENDPOINT = gkgEndpoint;
export const TOPIC_MODELS_ENDPOINT = topicModelsEndpoint;
export const BUCKET_KEY = bucketKey;
export const BUCKET_SKEY = bucketSKey;
export const API_GATEWAY_KEY = apiGatewayKey;
export const EXPORT_KEY = exportKey;
export const CANNY_BOARD_TOKEN = cannyBoardToken;
export const HEADERS = {
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': apiGatewayKey
  }
};
