import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import CategoryImagery from '../identity/CategoryImagery';
import { createUserActivityLog } from '../../utils/users';
import history from '../../routers/history';

export default class CategoryIdentity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'imagery'
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let selectedTab = hash.indexOf('-')>0 ? hash.substring(hash.indexOf('-')+1) : 'imagery';
      this.setState(() => ({ selectedTab }));
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  changeSelectedTab = (selectedTab, subTabName) => {
    history.push(`#identity-${selectedTab}`);
    this.setState(() => ({ selectedTab }));
    if (subTabName) {
      createUserActivityLog(
        this.props.user,
        `Category Tabs: Identity: ${subTabName}`,
        this.props.category,
      )
    }
  }

  render () {
    return (
      <div className="p-4">
        <div className="mb-4">
          <button
            type="button"
            className= {
              this.state.selectedTab === 'imagery' ?
                'btn border-primary bg-white mr-1' :
                'btn border bg-white mr-1'
            }
            onClick={(e) => this.changeSelectedTab('imagery', 'Imagery')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon
                icon={faImages}
                color="#6c757d"
              />
            </div>
            <div className="pr-2 d-inline-block">
              Imagery
            </div>
          </button>
        </div>

        <div
          className="mt-2 pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 200px)',
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          { this.state.selectedTab === 'imagery' &&
            <CategoryImagery
              user={this.props.user}
              category={this.props.category}
            />
          }
        </div>
      </div>
    );
  }
};
