import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactTable from 'react-table';
import Tooltip from 'reactjs-popup';
import { round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class PageProperties extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      seoKeywords: [],
      seoKeywordsLoading: false,
      selectedSeoKeywordId: undefined,
      pageRankingAttributes: [],
      pageRankingAttributesLoading: false,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchSeoKeywords();
    this.fetchPageRankingAttributes();

  };

  componentDidUpdate(prevProps) {
    if (prevProps.brand !== this.props.brand) {
      this.fetchSeoKeywords();
      this.fetchPageRankingAttributes();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchSeoKeywords = () => {
    if (this.props.category) {
      this.setState(() => ({ seoKeywordsLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/search_query/customer?linked_category_id=${this.props.category.id}&linked_category_type=${this.props.category.category_type}`,
        HEADERS
      ).then(response => {
        const seoKeywords = response.data;
        let selectedSeoKeywordId = this.state.selectedSeoKeywordId;
        if (!selectedSeoKeywordId) {
          selectedSeoKeywordId = seoKeywords[0] ? seoKeywords[0].id : undefined;
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            seoKeywords,
            selectedSeoKeywordId,
            seoKeywordsLoading: false
          }), () => this.fetchPageRankingAttributes());
        }
      }).catch(error => {
        console.error('Error: unable to fetch seo keywords');
        if (this.state.isMounted) {
          this.setState(() => ({
            seoKeywords: [],
            selectedSeoKeywordId: undefined,
            seoKeywordsLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchPageRankingAttributes = () => {
    if (this.props.brand && this.state.selectedSeoKeywordId) {
      this.setState(() => ({ pageRankingAttributesLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/web-sublink-ranking-attribute?product_brand_id=${this.props.brand.id}&web_search_query_id=${this.state.selectedSeoKeywordId}`,
        HEADERS
      ).then(response => {
        const pageRankingAttributes = response.data;
        // update data for display
        for (const pra of pageRankingAttributes) {
          let path;
          try {
            path = new URL(pra.url).pathname;
          } catch (e) {
            path = pra.url;
          }
          pra.url_path = path;
          try {
            if (pra.desktop_scores.cumulative_layout_shift) {
              pra.desktop_scores.cumulative_layout_shift = round(pra.desktop_scores.cumulative_layout_shift, 2);
            }
            if (pra.mobile_scores.cumulative_layout_shift) {
              pra.mobile_scores.cumulative_layout_shift = round(pra.mobile_scores.cumulative_layout_shift, 2);
            }
          } catch (e) {
            // do nothing
          }
          try {
            if (pra.desktop_scores.largest_contentful_paint) {
              pra.desktop_scores.largest_contentful_pane = `${round(pra.desktop_scores.largest_contentful_paint * .001, 2)}s`;
            }
            if (pra.mobile_scores.largest_contentful_paint) {
              pra.mobile_scores.largest_contentful_pane = `${round(pra.mobile_scores.largest_contentful_paint * .001, 2)}s`;
            }
          } catch (e) {
            // do nothing
          }

          // tally up keyword in columns
          pra.keyword_in_count = 0;
          const keywordInKeys = ['in_url', 'in_meta', 'in_h1', 'in_h2', 'in_title', 'in_body'];
          for (const keywordInKey of keywordInKeys) {
            if (pra[keywordInKey]) {
              pra.keyword_in_count += 1;
            }
          }
        }
        // sort on keyword in count
        pageRankingAttributes.sort((a,b) => b.keyword_in_count - a.keyword_in_count);

        if (this.state.isMounted) {
          this.setState(() => ({
            pageRankingAttributes,
            pageRankingAttributesLoading: false,
          }))
        }
      }).catch(error => {
        console.error('Error: failed to fetch page ranking attributes...');
        if (this.state.isMounted) {
          this.setState(() => ({
            pageRankingAttributes: [],
            pageRankingAttributesLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onSeoKeywordSelect = (selectedSeoKeyword) => {
    this.setState(
      () => ({ selectedSeoKeywordId: selectedSeoKeyword.id }),
      () => this.fetchPageRankingAttributes()
    );
  };

  getPerformanceColor = (cellType, performanceNum) => {
    if (cellType === 'lcp') {
      if (performanceNum) {
        if (performanceNum <= 2500) {
          return '#40bf79';
        } else if (performanceNum > 2500 && performanceNum < 4000) {
          return '#ffa500';
        } else {
          return '#ff6347';
        }
      } else {
        return 'inherit';
      }
    } else if (cellType === 'fid') {
      if (performanceNum) {
        if (performanceNum <= 100) {
          return '#40bf79';
        } else if (performanceNum > 100 && performanceNum < 300) {
          return '#ffa500';
        } else {
          return '#ff6347';
        }
      } else {
        return 'inherit';
      }
    } else if (cellType === 'cls') {
      if (performanceNum) {
        if (performanceNum <= .1) {
          return '#40bf79';
        } else if (performanceNum > .1 && performanceNum < .25) {
          return '#ffa500';
        } else {
          return '#ff6347';
        }
      } else {
        return 'inherit';
      }
    }
  };

  render () {
    return (
      <div>
        <Row>
          <Col xs={2}>
            <div className="mb-1" style={{ fontWeight: 'bold' }}>
              Keywords
            </div>
            <div
              className="pre-scrollable bg-white border rounded"
              style={{
                minHeight: 'calc(100vh - 228px)',
                maxHeight: 'calc(100vh - 228px)',
                overflow: 'auto'
              }}
            >
              { this.state.seoKeywords.map(keyword => {
                  return (
                    <Tooltip
                      key={`kw-${keyword.id}`}
                      trigger = {
                        <div
                          className={keyword.id === this.state.selectedSeoKeywordId ? 'bg-primary text-light rounded px-2 ellipsis' : 'px-2 ellipsis'}
                          onClick={() => this.onSeoKeywordSelect(keyword)}
                          style={{ cursor: 'pointer' }}
                        >
                          <div>
                            {keyword.query_term}
                          </div>
                          <div
                            className={keyword.id === this.state.selectedSeoKeywordId ? 'text-light' : 'text-muted'}
                            style={{ fontSize: '.75rem' }}
                          >
                            {`(${keyword.search_location})`}
                          </div>
                        </div>
                      }
                      position="right center"
                      on="hover"
                      contentStyle={{ borderRadius: '5px' }}
                    >
                      <div style={{ wordBreak: 'break-word' }}>
                        {keyword.query_term}
                      </div>
                    </Tooltip>
                  )
                })
              }
            </div>
          </Col>
          <Col xs={10}>
            { (this.state.seoKeywordsLoading || this.state.pageRankingAttributesLoading) &&
              <div className="m-4 text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { (!this.state.seoKeywordsLoading && !this.state.pageRankingAttributesLoading) &&
              <div className="container px-0">
                <ReactTable
                  className="-striped -highlight scrollable"
                  data={this.state.pageRankingAttributes}
                  columns={[
                    {
                      Header: '',
                      headerClassName: 'text-center wordwrap bg-dark text-light font-weight-bold border-light',
                      accessor: 'url_path',
                      sortable: true,
                      Cell: row => (
                        <a href={row.original.url} target="_blank">
                          {row.original.url_path}
                        </a>
                      ),
                    },
                    {
                      Header: 'In URL',
                      headerClassName: 'text-center wordwrap bg-dark text-light font-weight-bold border-light',
                      className: 'text-center',
                      accessor: 'in_url',
                      sortable: true,
                      width: 50,
                      Cell: row => (
                        <div>
                          {row.original.in_url ? 'Y' : 'N'}
                        </div>
                      ),
                      getProps: (state, rowInfo) => {
                        return {
                          style: {
                            background: (
                              rowInfo &&
                              rowInfo.original &&
                              rowInfo.original.in_url
                            ) ? '#40bf79' : 'inherit',
                          }
                        }
                      }
                    },
                    {
                      Header: 'In Meta',
                      headerClassName: 'text-center wordwrap bg-dark text-light font-weight-bold border-light',
                      className: 'text-center',
                      accessor: 'in_meta',
                      sortable: true,
                      width: 50,
                      Cell: row => (
                        <div>
                          {row.original.in_meta ? 'Y' : 'N'}
                        </div>
                      ),
                      getProps: (state, rowInfo) => {
                        return {
                          style: {
                            background: (
                              rowInfo &&
                              rowInfo.original &&
                              rowInfo.original.in_meta
                            ) ? '#40bf79' : 'inherit',
                          }
                        }
                      }
                    },
                    {
                      Header: 'In H1',
                      headerClassName: 'text-center wordwrap bg-dark text-light font-weight-bold border-light',
                      className: 'text-center',
                      accessor: 'in_h1',
                      sortable: true,
                      width: 45,
                      Cell: row => (
                        <div>
                          {row.original.in_h1 ? 'Y' : 'N'}
                        </div>
                      ),
                      getProps: (state, rowInfo) => {
                        return {
                          style: {
                            background: (
                              rowInfo &&
                              rowInfo.original &&
                              rowInfo.original.in_h1
                            ) ? '#40bf79' : 'inherit',
                          }
                        }
                      }
                    },
                    {
                      Header: 'In H2',
                      headerClassName: 'text-center wordwrap bg-dark text-light font-weight-bold border-light',
                      className: 'text-center',
                      accessor: 'in_h2',
                      sortable: true,
                      width: 45,
                      Cell: row => (
                        <div>
                          {row.original.in_h2 ? 'Y' : 'N'}
                        </div>
                      ),
                      getProps: (state, rowInfo) => {
                        return {
                          style: {
                            background: (
                              rowInfo &&
                              rowInfo.original &&
                              rowInfo.original.in_h2
                            ) ? '#40bf79' : 'inherit',
                          }
                        }
                      }
                    },
                    {
                      Header: 'In Title',
                      headerClassName: 'text-center wordwrap bg-dark text-light font-weight-bold border-light',
                      className: 'text-center',
                      accessor: 'in_title',
                      sortable: true,
                      width: 50,
                      Cell: row => (
                        <div>
                          {row.original.in_title ? 'Y' : 'N'}
                        </div>
                      ),
                      getProps: (state, rowInfo) => {
                        return {
                          style: {
                            background: (
                              rowInfo &&
                              rowInfo.original &&
                              rowInfo.original.in_title
                            ) ? '#40bf79' : 'inherit',
                          }
                        }
                      }
                    },
                    {
                      Header: 'In Body',
                      headerClassName: 'text-center wordwrap bg-dark text-light font-weight-bold border-light',
                      className: 'text-center',
                      accessor: 'in_title',
                      sortable: true,
                      width: 50,
                      Cell: row => (
                        <div>
                          {row.original.in_body ? 'Y' : 'N'}
                        </div>
                      ),
                      getProps: (state, rowInfo) => {
                        return {
                          style: {
                            background: (
                              rowInfo &&
                              rowInfo.original &&
                              rowInfo.original.in_body
                            ) ? '#40bf79' : 'inherit',
                          }
                        }
                      }
                    },
                    {
                      Header: 'Out Links',
                      headerClassName: 'text-center wordwrap bg-dark text-light font-weight-bold border-light',
                      className: 'text-center',
                      accessor: 'outbound_links_from_html',
                      sortable: true,
                      width: 55
                    },
                    {
                      Header: 'In Links',
                      headerClassName: 'text-center wordwrap bg-dark text-light font-weight-bold border-light',
                      className: 'text-center',
                      accessor: 'inbound_links',
                      sortable: true,
                      width: 55
                    },
                    {
                      Header: 'Largest Contentful Pane',
                      headerClassName: 'text-center wordwrap bg-dark text-light font-weight-bold border-light',
                      className: 'text-center',
                      accessor: 'desktop_scores.largest_contentful_pane',
                      sortable: true,
                      width: 90,
                      getProps: (state, rowInfo) => {
                        return {
                          style: {
                            background: (
                              rowInfo &&
                              rowInfo.original &&
                              rowInfo.original.desktop_scores
                            ) ? this.getPerformanceColor('lcp', rowInfo.original.desktop_scores.largest_contentful_paint) : 'inherit',
                          },
                        }
                      }
                    },
                    {
                      Header: 'First Input Delay',
                      headerClassName: 'text-center wordwrap bg-dark text-light font-weight-bold border-light',
                      className: 'text-center',
                      accessor: 'desktop_scores.first_input_delay',
                      sortable: true,
                      width: 65,
                      getProps: (state, rowInfo) => {
                        return {
                          style: {
                            background: (
                              rowInfo &&
                              rowInfo.original &&
                              rowInfo.original.desktop_scores
                            ) ? this.getPerformanceColor('fid', rowInfo.original.desktop_scores.first_input_delay) : 'inherit',
                          },
                        }
                      }
                    },
                    {
                      Header: 'Cum. Layout Shift',
                      headerClassName: 'text-center wordwrap bg-dark text-light font-weight-bold border-light',
                      className: 'text-center',
                      accessor: 'desktop_scores.cumulative_layout_shift',
                      sortable: true,
                      width: 65,
                      getProps: (state, rowInfo) => {
                        return {
                          style: {
                            background: (
                              rowInfo &&
                              rowInfo.original &&
                              rowInfo.original.desktop_scores
                            ) ? this.getPerformanceColor('cls', rowInfo.original.desktop_scores.cumulative_layout_shift) : 'inherit',
                          },
                        }
                      }
                    }
                  ]}
                  minRows={1}
                  defaultPageSize={100}
                  showPagination={false}
                  style={{
                    fontSize: '.875rem',
                    maxHeight: 'calc(100vh - 200px)',
                    overflowX: 'hidden'
                  }}
                />
              </div>
            }
          </Col>
        </Row>
      </div>
    );
  }
};
