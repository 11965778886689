import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClipLoader from 'react-spinners/ClipLoader';
import { round } from '../../utils/numbers';

export default class ColorSwath extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      colorProperties: this.props.colors[0]
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loading !== this.props.loading) {
      this.setState(() => ({ colorProperties: this.props.colors[0] }));
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  onColorHover = (colorProperties) => {
    this.setState(() => ({ colorProperties }));
  }

  render () {
    return (
      <div>
        { this.props.loading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.props.loading &&
          <div>
            { this.props.label &&
              <div>{this.props.label}</div>
            }
            { this.props.colors.map((color, i) => {
                return (
                  <div
                    key={`color-${i}`}
                    className="d-inline-block"
                    style={{
                      width: `${color.pixel_percent}%`,
                      height: this.props.height ?
                        `${this.props.height}px`:
                        '100px',
                      backgroundColor: color.color_hex
                    }}
                    onMouseOver={() => this.onColorHover(color)}
                  />
                )
              })
            }
            { this.state.colorProperties &&
              <div>
                <div>
                  {`${this.state.colorProperties.color_hex}, RGB(${this.state.colorProperties.color_red}, ${this.state.colorProperties.color_green}, ${this.state.colorProperties.color_blue})`}
                  <span className="float-right">{`${round(this.state.colorProperties.pixel_percent, 1)}%`}</span>
                </div>
                <div style={{
                    height: '24px',
                    backgroundColor: this.state.colorProperties.color_hex
                  }}
                />
              </div>
            }
          </div>
        }
      </div>
    );
  }
};
