import React from 'react';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import CategoryAnalysisOverview from './CategoryAnalysisOverview';
import CategoryKings from './CategoryKings';
import CategoryRaceCharts from './CategoryRaceCharts';
import CategoryChannelsSummary from './CategoryChannelsSummary';
import CategoryShareOfEverything from './CategoryShareOfEverything';
import CategoryExcessShareOfEverything from './esoe/CategoryExcessShareOfEverything';
import CategoryTopSpot from './CategoryTopSpot';
import CategoryPotentialCompetitors from './potentialCompetitors/CategoryPotentialCompetitors';
import CategoryBrandPower from './CategoryBrandPower';
import CategoryTerms from './terms/CategoryTerms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAward,
  faSortAmountUp,
  faTrophy,
  faFlagCheckered,
  faProjectDiagram,
  faChartArea,
  faChartLine,
  faBorderAll,
  faChartPie,
  faColumns,
  faBolt,
} from '@fortawesome/free-solid-svg-icons';
import { createUserActivityLog } from '../../utils/users';
import history from '../../routers/history';

export default class CategoryAnalysis extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'ranks',
      startDate: moment().utc().subtract(1, 'month').startOf('day'),
      endDate: moment().utc().endOf('day'),
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let selectedTab = hash.indexOf('-')>0 ? hash.substring(hash.indexOf('-')+1) : 'ranks';
      this.setState(() => ({ selectedTab }));
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  changeSelectedTab = (selectedTab, subTabName) => {
    history.push(`#analysis-${selectedTab}`);
    this.setState(() => ({ selectedTab }));
    if (subTabName) {
      createUserActivityLog(
        this.props.user,
        `Category Tabs: Analysis: ${subTabName}`,
        this.props.category,
      )
    }
  };

  updateDates = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  };

  render () {
    return (
      <div>
        <div className="p-4">
          <button
            id="ranksButton"
            type="button"
            className= { this.state.selectedTab === 'ranks' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('ranks', 'Ranks')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faSortAmountUp} />
            </div>
            <div className="d-inline-block">
              Ranks
            </div>
          </button>
          <button
            id="racesButton"
            type="button"
            className= { this.state.selectedTab === 'races' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('races', 'Races')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faFlagCheckered} />
            </div>
            <div className="d-inline-block">
              Races
            </div>
          </button>
          <button
            id="channelsButton"
            type="button"
            className= { this.state.selectedTab === 'channels' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('channels', 'Channels')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faProjectDiagram} />
            </div>
            <div className="d-inline-block">
              Channels
            </div>
          </button>
          <button
            id="shareOfButton"
            type="button"
            className= { this.state.selectedTab === 'shareOf' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('shareOf', 'Share Of')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faChartArea} />
            </div>
            <div className="d-inline-block">
              Share Of
            </div>
          </button>
          <button
            id="esoeButton"
            type="button"
            className= { this.state.selectedTab === 'esoe' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('esoe', 'ESoE')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faChartPie} />
            </div>
            <div className="d-inline-block">
              ESoE
            </div>
          </button>
          <button
            id="topspotButton"
            type="button"
            className= { this.state.selectedTab === 'topspot' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('topspot', 'Top Spot')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faTrophy} />
            </div>
            <div className="d-inline-block">
              Top Spot
            </div>
          </button>
          <button
            id="competitorsButton"
            type="button"
            className= { this.state.selectedTab === 'competitors' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('competitors', 'Competitors')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faColumns} />
            </div>
            <div className="d-inline-block">
              Competitors
            </div>
          </button>
          <button
            id="brandPowerButton"
            type="button"
            className= { this.state.selectedTab === 'brandPower' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('brandPower', 'Brand Power')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faBolt} />
            </div>
            <div className="d-inline-block">
              Brand Power
              <Badge
                className="ml-1"
                variant="warning"
                pill
              >
                Beta
              </Badge>
            </div>
          </button>
          <button
            id="trendsButton"
            type="button"
            className= { this.state.selectedTab === 'terms' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('terms', 'Terms')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faChartLine} />
            </div>
            <div className="d-inline-block">
              Terms
            </div>
          </button>
        </div>
        <div
          className="pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 200px)',
            maxHeight: 'calc(100vh - 200px)',
            overflow: 'auto',
            overflowX: 'hidden'
          }}
        >
          { this.state.selectedTab === 'ranks' &&
            <div>
              <CategoryAnalysisOverview
                overviewType="Ranks"
              />
              <CategoryKings
                user={this.props.user}
                categoryId={this.props.categoryId}
                categoryType={this.props.category.category_type}
                category={this.props.category}
                overallScoreWeights={this.props.overallScoreWeights}
              />
            </div>
          }
          { this.state.selectedTab === 'races' &&
            <div>
              <CategoryAnalysisOverview
                overviewType="Races"
              />
              <CategoryRaceCharts
                user={this.props.user}
                category={this.props.category}
              />
            </div>
          }
          { this.state.selectedTab === 'channels' &&
            <div>
              <CategoryAnalysisOverview
                overviewType="Channels"
              />
              <CategoryChannelsSummary
                sixMonths={this.props.sixMonths}
                selectedMonth={this.props.selectedMonth}
                updateSelectedMonth={this.props.updateSelectedMonth}
                companyId={this.props.user.customerId}
                categoryId={this.props.categoryId}
                category={this.props.category}
              />
            </div>
          }
          { this.state.selectedTab === 'shareOf' &&
            <div>
              <CategoryAnalysisOverview
                overviewType="Share Of"
              />
              <CategoryShareOfEverything
                user={this.props.user}
                category={this.props.category}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                updateDates={this.updateDates}
              />
            </div>
          }
          {this.state.selectedTab === 'esoe' &&
            <div>
              <CategoryAnalysisOverview
                overviewType="ESoE"
              />
              <CategoryExcessShareOfEverything
                user={this.props.user}
                category={this.props.category}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                updateDates={this.updateDates}
              />
            </div>
          }
          {this.state.selectedTab === 'topspot' &&
            <div>
              <CategoryAnalysisOverview
                overviewType="Top Spot"
              />
              <CategoryTopSpot
                user={this.props.user}
                category={this.props.category}
              />
            </div>
          }
          {this.state.selectedTab === 'competitors' &&
            <div>
              <CategoryAnalysisOverview
                overviewType="Competitors"
              />
              <CategoryPotentialCompetitors
                user={this.props.user}
                categories={this.props.categoire}
                category={this.props.category}
              />
            </div>
          }
          { this.state.selectedTab === 'brandPower' &&
            <div>
              <CategoryAnalysisOverview
                overviewType="Brand Power"
              />
              <CategoryBrandPower
                user={this.props.user}
                categoryId={this.props.categoryId}
                categoryType={this.props.category.category_type}
                category={this.props.category}
              />
            </div>
          }
          { this.state.selectedTab === 'terms' &&
            <div>
              <CategoryTerms
                user={this.props.user}
                category={this.props.category}
              />
            </div>
          }
        </div>
      </div>
    );
  }
};
