import { connect } from 'react-redux';
import CategoryDetectedTechnology from '../../components/privateStack/CategoryDetectedTechnology';

const mapStateToProps = (state) => ({
  user: state.user,
  categories: state.categories,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetectedTechnology);
