import React from 'react';
import axios from 'axios';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import WebHeaders from './WebHeaders';
import { sortAlphabeticalByKey } from '../../utils/sorts';
import { LISTEN_ENDPOINT, LAMBDA_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class WebHeadersTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'active',
      allowTabToLoadMap: {
        'active': true
      },
      callToActionData: []
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchCallToActionData();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.brandId !== this.props.brandId) {
      this.fetchCallToActionData();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchCallToActionData = () => {
    if (this.props.brand) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/web-image-scrape-elements?product_brand_id=${this.props.brand.id}&element_type=buttons`,
        HEADERS
      ).then(response => {
        const callToActionsDedupMap = {};
        const callToActionData = [];
        for (const cta of response.data) {
          const caseInsensitiveCta = cta.text.toLowerCase();
          if (!callToActionsDedupMap[caseInsensitiveCta]) {
            callToActionData.push(cta);
            callToActionsDedupMap[caseInsensitiveCta] = true;
          }
        }
        callToActionData.sort(sortAlphabeticalByKey('text'));

        if (this.state.isMounted) {
          this.setState(() => ({ callToActionData }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch call to action data...');
        if (this.state.isMounted) {
          this.setState(() => ({ callToActionData: [] }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        <Tabs
          defaultActiveKey= "active"
          activeKey={this.state.selectedTab}
          onSelect={(selectedTab) => {
            this.setState((prevState) => ({
              selectedTab,
              allowTabToLoadMap: {
                ...prevState.allowTabToLoadMap,
                [selectedTab]: true
              }
            }));
          }}
        >
          <Tab
            eventKey="active"
            title="Active Messages"
          >
            { this.state.allowTabToLoadMap.active &&
              <WebHeaders
                type="active"
                brand={this.props.brand}
                brandId={this.props.brandId}
                callToActionData={this.state.callToActionData}
              />
            }
          </Tab>
          <Tab
            eventKey="inactive"
            title="Inactive Messages"
          >
            { this.state.allowTabToLoadMap.inactive &&
              <WebHeaders
                type="inactive"
                brand={this.props.brand}
                brandId={this.props.brandId}
                callToActionData={this.state.callToActionData}
              />
            }
          </Tab>
        </Tabs>
      </div>
    );
  }
};
