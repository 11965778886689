import axios from 'axios';
import moment from 'moment';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { reportError } from '../api/errors';

export const fetchBrandChannelScoresMap = (loggedCustomerId, companyId, productBrandId, categoryId, categoryType, audienceProfileId) => {
  return (dispatch) => {
    dispatch(setBrandChannelScoresMapLoading(true));
    const sixMonthDates = [];
    let date = moment().subtract(1, 'day');
    let sixMonthsDate = moment().subtract(1, 'day').subtract(6 * 30, 'day');
    while (date.isAfter(sixMonthsDate)) {
      sixMonthDates.push(date);
      date = moment(date).subtract(1, 'month');
    }

    const formattedStartDate = moment(sixMonthDates[sixMonthDates.length-1]).startOf('month').format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = moment(sixMonthDates[0]).endOf('month').format('YYYY-MM-DD HH:mm:ss');
    let includePrivateDataParam = '';
    if (loggedCustomerId === companyId) {
      includePrivateDataParam = '&include_private_data=true';
    }
    axios.get(
      `${LISTEN_ENDPOINT}/api/scores/brand-channels-monthly-summary?category_type=${categoryType}&category_id=${categoryId}&product_brand_id=${productBrandId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}${includePrivateDataParam}`,
      HEADERS
    ).then(response => {
      const channelScoresData = response.data;
      const channelScoresByChannelMap = {};
      for (const monthData of channelScoresData) {
        for (const [channelName, channelScoreValues] of Object.entries(monthData.channel_scores)) {
          // use custom goal score if available otherwise use score
          let score = channelScoreValues.goal_score || channelScoreValues.score;
          score = Math.round(score);

          if (channelScoresByChannelMap[channelName]) {
            channelScoresByChannelMap[channelName].push({
              month: monthData.month,
              score,
            });
          } else {
            channelScoresByChannelMap[channelName] = [
              {
                month: monthData.month,
                score,
              }
            ];
          }
        }
      }
      dispatch(addBrandChannelScoresMap(channelScoresByChannelMap));
      dispatch(setBrandChannelScoresMapLoading(false));
    }).catch(error => {
      console.error('Error: unable to fetch brand branch channel scores monthly summary...');
      dispatch(addBrandChannelScoresMap({}));
      dispatch(setBrandChannelScoresMapLoading(false));
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatch(reportError(error.response));
      }
    })
  };
};

export const addBrandChannelScoresMap = (brandChannelScoresMap) => ({
  type: 'ADD_BRAND_CHANNEL_SCORES_MAP',
  brandChannelScoresMap
});

export const setBrandChannelScoresMapLoading = (brandChannelScoresMapLoading) => ({
  type: 'SET_BRAND_CHANNEL_SCORES_MAP_LOADING',
  brandChannelScoresMapLoading
});
