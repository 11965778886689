import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GeneralSummaryGraph from '../../graphs/GeneralSummaryGraph';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class GoogleAnalyticsConsiderationTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      googleAnalyticsTrendData: {},
      googleAnalyticsTrendDataLoading: false,
      googleAnalyticLandingPageTrendData: [],
      googleAnalyticLandingPageTrendDataLoading: false,
    }
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchGoogleAnalyticsTrendData();
    this.fetchLandingPageTrendData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.companyId !== this.props.companyId) {
      this.fetchGoogleAnalyticsTrendData();
      this.fetchLandingPageTrendData();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchGoogleAnalyticsTrendData = () => {
    if (this.props.companyId) {
      this.setState(() => ({ googleAnalyticsTrendDataLoading: true }));
      const googleAnalyticTrendRequests = [];
      const metricTypes = [
        'web_ga_product_page_views',
        'web_ga_solution_page_views',
        'web_ga_sign_up_page_views',
        'web_ga_document_page_views',
        'web_ga_case_study_page_views',
        'web_ga_blog_page_views'
      ];
      for (const metricType of metricTypes) {
        googleAnalyticTrendRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/ga-score-trend?company_id=${this.props.companyId}&metric_type=${metricType}`,
            HEADERS
          ).then(response => {
            return { [metricType]: response.data };
          }).catch(error => {
            console.error(`Error: unable to fetch google analytic ${metricType} data...`);
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return { [metricType]: [] };
          })
        );
      }

      Promise.all(googleAnalyticTrendRequests).then(responses => {
        const googleAnalyticsTrendData = {};
        for (const response of responses) {
          for (const [key, value] of Object.entries(response)) {
            googleAnalyticsTrendData[key] = value;
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            googleAnalyticsTrendData,
            googleAnalyticsTrendDataLoading: false
          }));
        }
      });
    }
  };

  fetchLandingPageTrendData = () => {
    if (this.props.companyId) {
      this.setState(() => ({ googleAnalyticLandingPageTrendDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/ga-landing-page-trend?company_id=${this.props.companyId}`,
        HEADERS
      ).then(response => {
        const googleAnalyticLandingPageTrendData = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            googleAnalyticLandingPageTrendData,
            googleAnalyticLandingPageTrendDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch google analytic landing page trend data...`);
        if (this.state.isMounted) {
          this.setState(() => ({
            googleAnalyticLandingPageTrendData: [],
            googleAnalyticLandingPageTrendDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="my-2">
        <Row>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Product Page Views"
                metricName="web_ga_product_page_views"
                data={this.state.googleAnalyticsTrendData.web_ga_product_page_views || []}
                dataKey="total_visitors"
                dataLabel="Product Page Views"
                loading={this.state.googleAnalyticsTrendDataLoading}
                allowDecimals={false}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Google Analytics"
                contextChartName="Product Page Views"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Solution Page Views"
                metricName="web_ga_solution_page_views"
                data={this.state.googleAnalyticsTrendData.web_ga_solution_page_views || []}
                dataKey="total_visitors"
                dataLabel="Solution Page Views"
                loading={this.state.googleAnalyticsTrendDataLoading}
                allowDecimals={false}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Google Analytics"
                contextChartName="Solution Page Views"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Sign Up Page Views"
                metricName="web_ga_sign_up_page_views"
                data={this.state.googleAnalyticsTrendData.web_ga_sign_up_page_views || []}
                dataKey="total_visitors"
                dataLabel="Sign Up Page Views"
                loading={this.state.googleAnalyticsTrendDataLoading}
                allowDecimals={false}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Google Analytics"
                contextChartName="Sign Up Page Views"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Landing Page Views"
                description="The number of unique pageviews for pages identified as Landing pages."
                data={this.state.googleAnalyticLandingPageTrendData || []}
                dataKey="screen_page_views"
                dataLabel="Landing Page Views"
                loading={this.state.googleAnalyticLandingPageTrendDataLoading}
                allowDecimals={false}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Google Analytics"
                contextChartName="Landing Page Views"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Documentation Page Views"
                metricName="web_ga_document_page_views"
                data={this.state.googleAnalyticsTrendData.web_ga_document_page_views || []}
                dataKey="total_visitors"
                dataLabel="Documentation Page Views"
                loading={this.state.googleAnalyticsTrendDataLoading}
                allowDecimals={false}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Google Analytics"
                contextChartName="Documentation Page Views"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Blog Page Views"
                metricName="web_ga_blog_page_views"
                data={this.state.googleAnalyticsTrendData.web_ga_blog_page_views || []}
                dataKey="total_visitors"
                dataLabel="Blog Page Views"
                loading={this.state.googleAnalyticsTrendDataLoading}
                allowDecimals={false}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Google Analytics"
                contextChartName="Blog Page Views"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Case Study Page Views"
                metricName="web_ga_case_study_page_views"
                data={this.state.googleAnalyticsTrendData.web_ga_case_study_page_views || []}
                dataKey="total_visitors"
                dataLabel="Case Study Page Views"
                loading={this.state.googleAnalyticsTrendDataLoading}
                allowDecimals={false}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Google Analytics"
                contextChartName="Case Study Page Views"
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};
