import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import ClipLoader from 'react-spinners/ClipLoader';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class KeyPhrases extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyPhraseType: 'web',
      keyPhrasesData: [],
      keyPhrasesDataLoading: false,
      keyPhrasesLimit: 5,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchKeyPhrases();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.brand !== this.props.brand) {
      this.fetchKeyPhrases();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchKeyPhrases = () => {
    if (this.props.brand) {
      this.setState(() => ({ keyPhrasesDataLoading: true }));

      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/key-phrase-stats?company_id=${this.props.brand.company_id}&channels=${this.state.keyPhraseType}&limit=${this.state.keyPhrasesLimit}`,
        HEADERS
      ).then(response => {
        const keyPhrasesData = [];
        if (typeof(response.data) === 'object') {
          for (const month of this.props.sixMonths) {
            if (response.data[month.label]) {
              keyPhrasesData.push({
                term: `${month.label} - ${month.date.format('YYYY')}`,
                keyPhrases: response.data[month.label]
              });
            }
          }
        } else {
          console.error(`Error: unable to parse key phrases data...`);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            keyPhrasesData,
            keyPhrasesDataLoading: false
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch key phrases data...`);
        if (this.state.isMounted) {
          this.setState(() => ({
            keyPhrasesData: [],
            keyPhrasesDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  switchKeyPhrasesType = (keyPhraseType) => {
    this.setState(
      () => ({ keyPhraseType }),
      () => this.fetchKeyPhrases()
    );
  }

  render () {
    return (
      <div>
        <div className="mb-2">
          <Form.Check
            label="Web"
            type="radio"
            checked={this.state.keyPhraseType == 'web' ? true : false}
            onChange={() => this.switchKeyPhrasesType('web')}
            inline
          />
          <Form.Check
            label="Blog"
            type="radio"
            checked={this.state.keyPhraseType == 'blog' ? true : false}
            onChange={() => this.switchKeyPhrasesType("blog")}
            inline
          />
        </div>
        <div>
          { this.state.keyPhrasesDataLoading &&
            <div className="m-4 text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !this.state.keyPhrasesDataLoading &&
            <div>
              <Table
                bordered
                striped
                size="sm"
                style={{
                  overflow: 'auto',
                }}
              >
                <thead className="bg-dark text-light text-center">
                  <tr>
                    <th>Term</th>
                    { [...Array(this.state.keyPhrasesLimit)].map((x, i) => {
                        return (
                          <th key={`kp-th-${i}`}>
                            {i+1}
                          </th>
                        )
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  { this.state.keyPhrasesData.map((kpd, i) => {
                      return (
                        <tr key={`kpd-${i}`}>
                          <td>{kpd.term}</td>
                          { [...Array(this.state.keyPhrasesLimit)].map((x, i) => {
                              if (kpd.keyPhrases[i]) {
                                return (
                                  <td key={`kpd-td-${i}`}>
                                    {kpd.keyPhrases[i].key_phrase || ''}
                                  </td>
                                )
                              } else {
                                return (
                                  <td key={`kpd-td-${i}`}></td>
                                )
                              }
                            })
                          }
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </div>
          }
        </div>
      </div>
    );
  }
};
