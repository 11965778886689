import React from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class AddCategoryModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'create',
      allPublicCategories: [],
      customerCategories: [],
      categories: [],
      categoriesSearchTerm: '',
      companySearchTerm: '',
      selectedCategoryId: undefined,
      selectedCategoryDescription: '',
      selectedCategoryName: '',
      selectedCategoryAudienceProfileId: undefined,
      selectedCategoryBrandsLoading: false,
      selectedCategoryBrands: [],
      newCategoryName: '',
      newCategoryDescription: '',
      newCategoryMarketStageId: 1,
      errors: {}
    };
  }

  onOpen = () => {
    let customerCategories = [];
    for (const category of this.props.categories) {
      customerCategories.push(category.category.name);
    }
    let selectedTab = 'create';
    // allow brandops users to use subscribe tab
    if (this.props.companyId === 317) {
      selectedTab = 'subscribe';
    }
    this.setState(() => ({
      selectedTab,
      allPublicCategories: [],
      customerCategories,
      categories: [],
      categoriesSearchTerm: '',
      companySearchTerm: '',
      selectedCategoryId: undefined,
      selectedCategoryDescription: '',
      selectedCategoryName: '',
      selectedCategoryAudienceProfileId: undefined,
      selectedCategoryBrandsLoading: false,
      selectedCategoryBrands: [],
      newCategoryName: '',
      newCategoryDescription: '',
      newCategoryMarketStageId: 1,
      errors: {}
    }));
    this.fetchAllPublicCategories();
  };

  fetchAllPublicCategories = () => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/public-categories`,
      HEADERS
    ).then(response => {
      const allPublicCategories = response.data;
      allPublicCategories.sort(function(a, b){
        if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        return 0;
      });
      this.setState(() => ({
        categories: allPublicCategories,
        allPublicCategories
      }));
      if (allPublicCategories.length > 0) {
        this.selectCategory(
          allPublicCategories[0].id,
          allPublicCategories[0].name,
          allPublicCategories[0].description,
          allPublicCategories[0].audience_profile_id
        );
      }
    }).catch(error => {
      console.error('Error: failed to fetch public categories');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  searchCategories = (searchTerm) => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/public-categories/search/${searchTerm}`,
      HEADERS
    ).then(response => {
      const categories = response.data;
      this.setState(() => ({ categories }));
      if (categories.length > 0) {
        this.selectCategory(
          categories[0].id,
          categories[0].name,
          categories[0].description,
          categories[0].audience_profile_id
        );
      }
    }).catch(error => {
      console.error('Error: failed to fetch public categories by search term');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  searchCategoriesByCompany = (searchTerm) => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/public-categories/company/search/${searchTerm}`,
      HEADERS
    ).then(response => {
      const categories = response.data;
      this.setState(() => ({ categories }));
      if (categories.length > 0) {
        this.selectCategory(categories[0].id, categories[0].name, categories[0].description, categories[0].audience_profile_id);
      }
    }).catch(error => {
      console.error('Error: failed to fetch public categories by company search term');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  fetchCategoryProductBrands = (categoryId) => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/public-category-product-brands-full/${categoryId}`,
      HEADERS
    ).then(response => {
      const categoryBrands = response.data;
      const selectedCategoryBrands = [];
      for (let brand of categoryBrands) {
        selectedCategoryBrands.push(brand.name);
      }
      this.setState(() => ({ selectedCategoryBrands }));
    }).catch(error => {
      this.setState(() => ({ selectedCategoryBrands: [] }));
      console.error('Error: failed to get selected category product brands');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  }

  onCategoriesSearchTermChange = (event) => {
    const categoriesSearchTerm = event.currentTarget.value;
    if (categoriesSearchTerm === '') {
      this.setState(() => ({
        categoriesSearchTerm,
        categories: this.state.allPublicCategories
      }));
    } else {
      this.setState(() => ({ categoriesSearchTerm }));
      this.searchCategories(categoriesSearchTerm);
    }
  };

  onCompanySearchTermChange = (event) => {
    const companySearchTerm = event.currentTarget.value;
    if (companySearchTerm === '') {
      this.setState(() => ({
        companySearchTerm,
        categories: this.state.allPublicCategories
      }));
    } else {
      this.setState(() => ({ companySearchTerm }));
      this.searchCategoriesByCompany(companySearchTerm);
    }
  };

  selectCategory = (selectedCategoryId, selectedCategoryName, selectedCategoryDescripion, selectedCategoryAudienceProfileId) => {
    this.setState(() => ({
      selectedCategoryId,
      selectedCategoryName,
      selectedCategoryDescripion,
      selectedCategoryAudienceProfileId
    }));
    this.fetchCategoryProductBrands(selectedCategoryId);
  };

  onNewCategoryNameChange = (event) => {
    const newCategoryName = event.currentTarget.value;
    this.setState(() => ({ newCategoryName }));
  };

  onNewCategoryDescriptionChange = (event) => {
    const newCategoryDescription = event.currentTarget.value;
    this.setState(() => ({ newCategoryDescription }));
  };

  onNewCategoryMarketStageIdChange = (event) => {
    const newCategoryMarketStageId = parseInt(event.currentTarget.value);
    this.setState(() => ({ newCategoryMarketStageId }));
  };

  subscribeToCategory = () => {
    if (this.state.selectedCategoryId) {
      const newExtendedCategory = {
        company_id: this.props.companyId,
        name: this.state.selectedCategoryName,
        public_category_id: this.state.selectedCategoryId,
        audience_profile_id: this.state.selectedCategoryAudienceProfileId
      };

      axios.post(
        `${LISTEN_ENDPOINT}/api/extended-categories`,
        newExtendedCategory,
        HEADERS
      ).then(response => {
        this.props.refreshCompanyCategories(this.props.companyId);
        this.props.handleClose();
      }).catch(error => {
        console.log('Error: failed to subscribe to public category');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  createNewIsValidForm = () => {
    const errors = {};
    if (!this.state.newCategoryName || this.state.newCategoryName.trim() === '') {
      errors.newCategoryName = true;
    }

    this.setState(() => ({ errors }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  createNewCategory = () => {
    if (this.createNewIsValidForm()) {
      const newPrivateCategory = {
        company_id: this.props.companyId,
        name: this.state.newCategoryName,
        market_stage_id: this.state.newCategoryMarketStageId
      };
      if (this.state.newCategoryDescription) {
        newPrivateCategory.description = this.state.newCategoryDescription;
      }

      axios.post(
        `${LISTEN_ENDPOINT}/api/private-categories`,
        newPrivateCategory,
        HEADERS
      ).then(response => {
        this.props.refreshCompanyCategories(this.props.companyId);
        this.props.handleClose();
      }).catch(error => {
        console.log('Error: failed to create new private category');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <Modal
        show={this.props.isOpen}
        onHide={this.props.handleClose}
        onEntering={this.onOpen}
        size="xl"
      >
        <Modal.Header
          className="bg-bops-blue text-light"
          closeButton
        >
          <Modal.Title>
            Add Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            activeKey={this.state.selectedTab}
            onSelect={(selectedTab) => this.setState(() => ({ selectedTab }))}
          >
            { this.props.companyId === 317 &&
              <Tab eventKey="subscribe" title="Subscribe to an Existing Category">
                <div className="mt-2">
                  <div className="p-1 border rounded" style={{ backgroundColor: '#e9ecef' }}>
                    By copying an existing category, you can view all of the brands in the category
                    and their respective data. This becomes your own copy of the data allowing you
                    to add/remove brands and collectors.
                  </div>
                  <Form inline>
                    <Form.Group className="pt-2">
                      <Form.Label className="mr-2">
                        Search BrandOps Categories
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={this.state.categoriesSearchTerm}
                        onChange={this.onCategoriesSearchTermChange}
                        style={{ width: '300px' }}
                      />
                    </Form.Group>
                    <Form.Group className="ml-4 pt-2">
                      <Form.Label className="mr-2">
                        Search By Company Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={this.state.companySearchTerm}
                        onChange={this.onCompanySearchTermChange}
                        style={{ width: '300px' }}
                      />
                    </Form.Group>
                  </Form>
                  <hr />
                  <Row>
                    <Col xs={4}>
                      <div>
                        <b>Categories</b>
                      </div>
                      <div className="border rounded">
                        <div
                          className="pre-scrollable"
                          style={{ height: '200px', minHeight: '200px' }}
                        >
                          { this.state.categories.map(category => {
                              let className = 'px-2';
                              if (category.id === this.state.selectedCategoryId) {
                                className += ' bg-primary text-light';
                              }
                              if (this.state.customerCategories.includes(category.name)) {
                                return (
                                  <div
                                    key={`cat${category.id}`}
                                    className={className}
                                    onClick={() => this.selectCategory(category.id, category.name, category.description, category.audience_profile_id)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {category.name}
                                    <FontAwesomeIcon
                                      className="mr-1 mt-1 float-right"
                                      icon={faCheck}
                                    />
                                  </div>
                                )
                              } else {
                                return (
                                  <div
                                    key={`cat${category.id}`}
                                    className={className}
                                    onClick={() => this.selectCategory(category.id, category.name, category.description, category.audience_profile_id)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {category.name}
                                  </div>
                                )
                              }
                            })
                          }
                        </div>
                      </div>
                    </Col>
                    <Col>
                      { this.state.selectedCategoryId &&
                        <div className="mt-4">
                          <div className="pb-2">
                            <b>Description: </b>
                            <span>{this.state.selectedCategoryDescripion}</span>
                          </div>
                          <div>
                            <b>Product Brands: </b>
                            <span>{this.state.selectedCategoryBrands.join(', ')}</span>
                          </div>
                        </div>
                      }
                    </Col>
                  </Row>
                </div>
              </Tab>
            }
            <Tab eventKey="create" title="Create a Category">
              <div className="mt-2">
                <div className="p-1 border rounded" style={{ backgroundColor: '#e9ecef' }}>
                  If a category doesn't exist, create your own! Give the category a name,
                  add brands and collectors. The category will be shared with your team.
                </div>
                <Form className="mt-2">
                  <Form.Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          value={this.state.newCategoryName}
                          onChange={this.onNewCategoryNameChange}
                          isInvalid={this.state.errors.newCategoryName}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                    </Col>
                  </Form.Row>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="8"
                      value={this.state.newCategoryDescription}
                      onChange={this.onNewCategoryDescriptionChange}
                      isInvalid={this.state.errors.newCategoryDescription}
                    />
                  </Form.Group>
                </Form>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
          { this.state.selectedTab === 'subscribe' &&
            <Button variant="primary" onClick={this.subscribeToCategory}>
              Add
            </Button>
          }
          { this.state.selectedTab === 'create' &&
            <Button variant="primary" onClick={this.createNewCategory}>
              Add
            </Button>
          }
        </Modal.Footer>
      </Modal>
    );
  }
};
