import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import ClipLoader from 'react-spinners/ClipLoader';
import MonthScrollPicker from '../../common/MonthScrollPicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAssistiveListeningSystems,
  faBlog,
  faCheckCircle,
  faCircle,
  faClipboardList,
  faDesktop,
  faEnvelope,
  faExclamationCircle,
  faFolderOpen,
  faGlobe,
  faMagnifyingGlass,
  faMinusCircle,
  faNewspaper,
  faPodcast,
  faRectangleAd,
  faStarHalfAlt,
  faUsers,
  faVideo
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { sortAlphabeticalByKey } from '../../../utils/sorts';
import { numberWithCommas } from '../../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class MetricsGridTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // subtract of 2 days is due to monthly cache not running immediately after new month
      selectedMonth: moment().subtract(2, 'days').subtract(1, 'month'),
      selectedShowFilter: 'all',
      selectedRelativeTo: 'relative_to_goal',
      minDate: moment().subtract(2, 'days').subtract(6, 'month'),
      maxDate: moment().subtract(2, 'days').subtract(1, 'month'),
      metricsData: {},
      metricsDataLoading: false,
      metricsDisplayData: [],
      customerObjectives: [],
      customerObjectivesMap: [],
      selectedMetric: undefined,
      goodGradeChecked: true,
      mediumGradeChecked: true,
      badGradeChecked: true,
      goodGradeCounter: 0,
      mediumGradeCounter: 0,
      badGradeCounter: 0,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchObjectivesAndMetrics();
    this.fetchBrandMetricValues();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.customerId != this.props.user.customerId) {
      this.fetchObjectivesAndMetrics();
    }
    if (
      prevProps.category !== this.props.category ||
      prevProps.brand !== this.props.brand
    ) {
      this.fetchBrandMetricValues();
    }
    if (prevState.selectedMonth !== this.state.selectedMonth) {
      this.setMetricsDisplayData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  fetchObjectivesAndMetrics = () => {
    if (this.props.user && this.props.user.customerId) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/customer-objectives-and-metrics?customer_id=${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        const customerObjectives = [];
        const customerObjectivesMap = {};
        for (let co of response.data) {
          customerObjectives.push(co);
          customerObjectivesMap[co.customer_objective_id] = co
          customerObjectivesMap[String(co.customer_objective_id)].metrics_id_map = new Map();
          for (let com of co.metrics) {
            customerObjectivesMap[co.customer_objective_id].metrics_id_map.set(com.metric_id);
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            customerObjectives,
            customerObjectivesMap,
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch customer objectives and metrics');
        if (this.state.isMounted) {
          this.setState(() => ({
            customerObjectives: [],
            customerObjectivesMap: {},
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchBrandMetricValues = () => {
    if (
      this.props.user &&
      this.props.category &&
      this.props.brand
    ) {
      this.setState(() => ({ metricsDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/brand-metrics-relative-data?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&product_brand_id=${this.props.brand.id}&audience_profile_id=${this.props.category.audience_profile_id}`,
        HEADERS
      ).then(response => {
        const metricsData = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            metricsData,
            metricsDataLoading: false,
          }), () => this.setMetricsDisplayData());
        }
      }).catch(error => {
        console.error('Error: failed to fetch metric data');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            metricsData: {},
            metricsDataLoading: false,
          }), () => this.setMetricsDisplayData());
        }
      });
    }
  };

  setMetricsDisplayData = () => {
    if (
      this.state.selectedMonth &&
      this.state.selectedRelativeTo &&
      this.state.metricsData &&
      this.state.metricsData[this.state.selectedMonth.format('M-YYYY')] &&
      this.state.metricsData[this.state.selectedMonth.format('M-YYYY')].metrics
    ) {
      const relativeToKey = this.state.selectedRelativeTo;
      const metricsData = this.state.metricsData[this.state.selectedMonth.format('M-YYYY')].metrics;
      const metricsDisplayData = [];
      let goodGradeCounter = 0;
      let mediumGradeCounter = 0;
      let badGradeCounter = 0;
      let selectedMetric;
      for (const m of metricsData) {
        if (m[relativeToKey] !== null && !m.is_private && m.brand_stage_id !== 6) {
          if (
            this.state.selectedShowFilter === 'all' ||
            // metric is in customer objective
            (
              this.state.customerObjectivesMap[this.state.selectedShowFilter] &&
              this.state.customerObjectivesMap[this.state.selectedShowFilter].metrics_id_map &&
              this.state.customerObjectivesMap[this.state.selectedShowFilter].metrics_id_map.has(m.id)
            )
          ) {
            m.grade = this.getGrade(m[relativeToKey]);
            // grade counter
            switch (m.grade) {
              case 'good':
                goodGradeCounter += 1;
                break;
              case 'medium':
                mediumGradeCounter += 1;
                break;
              case 'bad':
                badGradeCounter += 1;
                break;
            }
            // check grade filter
            if (
              (m.grade === 'good' && !this.state.goodGradeChecked) ||
              (m.grade === 'medium' && !this.state.mediumGradeChecked) ||
              (m.grade === 'bad' && !this.state.badGradeChecked)
            ) {
              // skip to next metric
              continue;
            }
            // get icon
            m.icon = this.getIcon(m.channel_name, m.channel_site_name);
            // round larger numbers
            if (
              m[relativeToKey] !== null &&
              (m[relativeToKey] > 100 || m[relativeToKey] < -100)
            ) {
              m[relativeToKey] = Math.round(m[relativeToKey]);
            }
            // select equivalient metric
            if (this.state.selectedMetric && this.state.selectedMetric.id === m.id) {
              selectedMetric = m;
            }
            metricsDisplayData.push(m);
          }
        }
      }
      // sort lowest scores on top, then by label
      metricsDisplayData.sort((a, b) => {
        if (a[relativeToKey] > b[relativeToKey]) {
          return 1;
        } else if (a[relativeToKey] < b[relativeToKey]) {
          return -1;
        } else {
          if (a.metric_label < b.metric_label) {
            return -1;
          } else if (a.metric_label > b.metric_label) {
            return 1;
          } else {
            return 0;
          }
        }
      });
      if (!selectedMetric && metricsDisplayData.length > 0) {
        selectedMetric = metricsDisplayData[0];
      }

      this.setState(() => ({
        metricsDisplayData,
        selectedMetric,
        goodGradeCounter,
        mediumGradeCounter,
        badGradeCounter,
      }));
    } else {
      this.setState(() => ({
        metricsDisplayData: [],
        selectedMetric: undefined,
        goodGradeCounter: 0,
        mediumGradeCounter: 0,
        badGradeCounter: 0,
      }));
    }
  };

  getIcon = (metricChannel, metricSite) => {
    switch (metricChannel) {
      case 'Social':
        if (metricSite === 'Twitter') {
          return (<FontAwesomeIcon icon={faTwitter} color="#1da1f2" />);
        } else if (metricSite === 'Facebook') {
          return (<FontAwesomeIcon icon={faFacebook} color="#4267b2" />);
        } else if (metricSite === 'LinkedIn') {
          return (<FontAwesomeIcon icon={faLinkedin} color="#2867b2" />);
        } else if (metricSite === 'Instagram') {
          return (<FontAwesomeIcon icon={faInstagram} color="#c13584" />);
        } else {
          return (<FontAwesomeIcon icon={faAssistiveListeningSystems} color="#6c757d" />);
        }
      case 'Product Reviews':
      case 'Employer Reviews':
        return (<FontAwesomeIcon icon={faStarHalfAlt} color="#ffc107" />);
      case 'Blogs':
        return (<FontAwesomeIcon icon={faBlog} color="#6c757d" />);
      case 'News':
        return (<FontAwesomeIcon icon={faNewspaper} color="#6c757d" />);
      case 'Videos':
        return (<FontAwesomeIcon icon={faVideo} color="#ff0000" />);
      case 'Email Campaigns':
      case 'Email':
        return (<FontAwesomeIcon icon={faEnvelope} color="#6c757d" />);
      case 'Podcast':
        return (<FontAwesomeIcon icon={faPodcast} color="#6c757d" />);
      case 'Webinar':
        return (<FontAwesomeIcon icon={faDesktop} color="#6c757d" />);
      case 'Web Analytics':
      case 'Web':
        return (<FontAwesomeIcon icon={faGlobe} color="#6c757d" />);
      case 'Ads':
        return (<FontAwesomeIcon icon={faRectangleAd} color="#6c757d" />);
      case 'Search Engine Results':
        return (<FontAwesomeIcon icon={faMagnifyingGlass} color="#6c757d" />);
      default:
        return (<FontAwesomeIcon icon={faFolderOpen} color="#6c757d" />);
    }
  };

  getGrade = (value) => {
    if (value == null) {
      return 'none';
    } else if (value >= 80) {
      return 'good';
    } else if (value >= 30) {
      return 'medium';
    } else {
      return 'bad';
    }
  };

  onGradeCheckChange = (grade, checked) => {
    if (this.state[`${grade}GradeChecked`] !== checked) {
      this.setState(
        () => ({ [`${grade}GradeChecked`]: checked }),
        () => this.setMetricsDisplayData()
      );
    }
  };

  updateSelectedMonth = (selectedMonth) => {
    this.setState(() => ({ selectedMonth }));
  };

  onSelectedShowFilterChange = (event) => {
    const selectedShowFilter = event.currentTarget.value;
    this.setState(
      () => ({ selectedShowFilter }),
      () => this.setMetricsDisplayData()
    );
  };

  onSelectedRelativeToChange = (event) => {
    const selectedRelativeTo = event.currentTarget.value;
    this.setState(
      () => ({ selectedRelativeTo }),
      () => this.setMetricsDisplayData()
    );
  };

  onMetricSelect = (selectedMetric) => {
    if (selectedMetric !== this.state.selectedMetric) {
      this.setState(() => ({ selectedMetric }));
    }
  };

  render () {
    return (
      <div>
        <h5 className="mb-2 p-2 bg-bops-blue text-light">
          <div className="d-inline-block ml-4" style={{ fontSize: '1rem' }}>
            <MonthScrollPicker
              selectedMonth={this.state.selectedMonth}
              updateSelectedMonth={this.updateSelectedMonth}
              minDate={this.state.minDate}
              maxDate={this.state.maxDate}
              loading={this.state.metricsDataLoading}
            />
          </div>
          <div className="d-inline-block ml-4">
            <div
              className="d-inline-block mr-2"
              style={{ fontSize: '1rem' }}
            >
              Show
            </div>
            <div className="d-inline-block"  style={{ width: '150px' }}>
              <Form.Control
                as="select"
                value={this.state.selectedShowFilter}
                onChange={this.onSelectedShowFilterChange}
                size="sm"
              >
                <option value="all">All Metrics</option>
                { this.state.customerObjectives.map((co, i) => {
                    return (
                      <option
                        key={`co-o-${i}`}
                        value={co.customer_objective_id}
                      >
                        {co.objective_name}
                      </option>
                    )
                  })
                }
              </Form.Control>
            </div>
          </div>
          <div className="d-inline-block ml-4">
            <div
              className="d-inline-block mr-2"
              style={{ fontSize: '1rem' }}
            >
              Relative to
            </div>
            <div className="d-inline-block"  style={{ width: '150px' }}>
              <Form.Control
                as="select"
                value={this.state.selectedRelativeTo}
                onChange={this.onSelectedRelativeToChange}
                size="sm"
              >
                <option value="relative_to_goal">Goals</option>
                <option value="relative_to_points_obtained">BrandOps Grade</option>
                <option value="relative_to_prior_month">Prior Month</option>
                <option value="relative_to_best_in_category">Best in Category</option>
              </Form.Control>
            </div>
          </div>
          { !this.state.metricsDataLoading &&
            <div className="d-inline-block ml-4" style={{ fontSize: '1rem' }}>
              <div className="d-inline-block mr-3">
                <Form.Check
                  className="mr-0"
                  type="checkbox"
                  checked={this.state.goodGradeChecked}
                  onChange={(event) => this.onGradeCheckChange('good', event.currentTarget.checked)}
                  inline
                />
                {`Green: ${numberWithCommas(this.state.goodGradeCounter)}`}
              </div>
              <div className="d-inline-block mr-3">
                <Form.Check
                  className="mr-0"
                  type="checkbox"
                  checked={this.state.mediumGradeChecked}
                  onChange={(event) => this.onGradeCheckChange('medium', event.currentTarget.checked)}
                  inline
                />
                {`Yellow: ${numberWithCommas(this.state.mediumGradeCounter)}`}
              </div>
              <div className="d-inline-block mr-3">
                <Form.Check
                  className="mr-0"
                  type="checkbox"
                  checked={this.state.badGradeChecked}
                  onChange={(event) => this.onGradeCheckChange('bad', event.currentTarget.checked)}
                  inline
                />
                {`Red: ${numberWithCommas(this.state.badGradeCounter)}`}
              </div>
            </div>
          }
        </h5>
        { this.state.metricsDataLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.metricsDataLoading &&
          <Row>
            <Col xs={8}>
              <div
                className="pre-scrollable border rounded"
                style={{
                  minHeight: 'calc(100vh - 255px)',
                  maxHeight: 'calc(100vh - 255px)',
                  overflow: 'auto',
                  overflowX: 'hidden'
                }}
              >
                <Row noGutters>
                  { this.state.metricsDisplayData.map((metricData, i) => {
                      return (
                        <Col
                          key={`mgt-m-${i}`}
                          className="mb-2"
                          xs={4}
                        >
                          <Card
                            className={
                              (this.state.selectedMetric && this.state.selectedMetric.id === metricData.id) ?
                                'mx-1 h-100 border-primary' :
                                'mx-1 h-100'
                            }
                            onClick={() => this.onMetricSelect(metricData)}
                            style={{ cursor: 'pointer' }}
                          >
                            <Card.Header className="p-2 ellipsis">
                              {metricData.icon}
                              {' '}
                              {metricData.metric_label}
                            </Card.Header>
                            <Card.Body>
                              <div className="h-100 text-center" style={{ fontSize: '2rem' }}>
                                <Row noGutters>
                                  <Col xs={4}>
                                    <div className="d-inline-block align-middle">
                                      { (!metricData.grade || metricData.grade === 'none') &&
                                        <FontAwesomeIcon icon={faMinusCircle} color="#6c757d" />
                                      }
                                      { metricData.grade === 'good' &&
                                        <FontAwesomeIcon icon={faCheckCircle} color="#28a745" />
                                      }
                                      { metricData.grade === 'medium' &&
                                        <FontAwesomeIcon icon={faCircle} color="#ffc107" />
                                      }
                                      { metricData.grade === 'bad' &&
                                        <FontAwesomeIcon icon={faExclamationCircle} color="#dc3545" />
                                      }
                                    </div>
                                  </Col>
                                  <Col xs={8}>
                                    <div className="d-inline-block align-middle">
                                      { metricData[this.state.selectedRelativeTo] != null ?
                                          `${numberWithCommas(metricData[this.state.selectedRelativeTo])}%`:
                                          '-'
                                      }
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      )
                    })
                  }
                </Row>
              </div>
            </Col>
            <Col xs={4}>
              { this.state.selectedMetric &&
                <div className="h-100 border rounded">
                  <div className="py-2 bg-bops-blue text-center">
                    <div
                      className="text-light"
                      style={{
                        fontSize: '1.2rem',
                        fontWeight: 'bold'
                      }}
                    >
                      {this.state.selectedMetric.metric_label}
                    </div>
                    <div>
                      {`Channel: ${this.state.selectedMetric.channel_name}`}
                    </div>
                  </div>
                  <div className="mt-3 text-center font-weight-bold">
                    Relative To
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <div className="text-right">
                          Goal
                        </div>
                      </Col>
                      <Col>
                        { this.state.selectedMetric.relative_to_goal != null ?
                            `${numberWithCommas(this.state.selectedMetric.relative_to_goal)}%` : '-'
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-right">
                          BrandOps Grade
                        </div>
                      </Col>
                      <Col>
                        { this.state.selectedMetric.relative_to_points_obtained != null ?
                            `${numberWithCommas(this.state.selectedMetric.relative_to_points_obtained)}%` : '-'
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-right">
                          Prior Month
                        </div>
                      </Col>
                      <Col>
                        { this.state.selectedMetric.relative_to_prior_month != null ?
                            `${numberWithCommas(this.state.selectedMetric.relative_to_prior_month)}%` : '-'
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-right">
                          Best in Category
                        </div>
                      </Col>
                      <Col>
                        { this.state.selectedMetric.relative_to_best_in_category != null ?
                            `${numberWithCommas(this.state.selectedMetric.relative_to_best_in_category)}%` : '-'
                        }
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-3 text-center font-weight-bold">
                    Comparisons
                  </div>
                  <div>
                    { this.state.selectedMetric.relative_to_competitors.map((competitor, i) => {
                        return (
                          <Row key={`mgt-rtc-c-${i}`}>
                            <Col>
                              <div className="text-right">
                                {competitor.product_brand_name}
                              </div>
                            </Col>
                            <Col>
                              { competitor.relative_to_value != null ?
                                  `${numberWithCommas(competitor.relative_to_value )}%` : '-'
                              }
                            </Col>
                          </Row>
                        )
                      })
                    }
                  </div>
                </div>
              }
            </Col>
          </Row>
        }
      </div>
    );
  }
};
