import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ClipLoader from 'react-spinners/ClipLoader';
import history from '../../routers/history';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class SSOLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      domain: '',
      loadingSsoUrlByDomain: false,
      errors: {},
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.user && this.props.user.id) {
      history.push('/');
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  onDomainChange = (event) => {
    const domain = event.currentTarget.value;
    this.setState(() => ({ domain }));
  };

  isValidForm = () => {
    const errors = {};
    let errorMessage = '';
    if (!this.state.domain) {
      errors.domain = true;
      errorMessage = 'Please provide domain.';
    }

    this.setState(() => ({
      errors,
      errorMessage
    }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  ssoLoginRedirect = () => {
    if (this.isValidForm()) {
      this.setState(() => ({ loadingSsoUrlByDomain: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/sso-url-by-domain?domain=${this.state.domain}`,
        HEADERS
      ).then(response => {
        if (
          response.data &&
          response.data.sign_in_url
        ) {
          if (this.state.isMounted) {
            // redirect to sso url
            document.location.href = response.data.sign_in_url;
          }
        } else {
          if (this.state.isMounted) {
            this.setState(() => ({
              errorMessage: 'Unable to route to SSO URL.',
              loadingSsoUrlByDomain: false,
            }));
          }
        }
      }).catch(error => {
        console.error('Error: failed to fetch sso url by domain...');
        if (this.state.isMounted) {
          this.setState(() => ({
            errorMessage: 'Unable to route to SSO URL.',
            loadingSsoUrlByDomain: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="m-4">
        <Row>
          <Col></Col>
          <Col>
            <Card className="h-100 mb-4">
              <Card.Header>
                SSO
              </Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group>
                    <Form.Label>Enter your company Domain.</Form.Label>
                    <Form.Control
                      type="text"
                      value={this.state.domain}
                      onChange={this.onDomainChange}
                      isInvalid={this.state.errors.domain}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          this.ssoLoginRedirect();
                        }
                      }}
                    />
                  </Form.Group>
                </Form>
                <Alert show={Boolean(this.state.errorMessage)} variant="danger">
                  {this.state.errorMessage}
                </Alert>
                <Button
                  id="ssoLoginContinue"
                  variant="primary"
                  onClick={this.ssoLoginRedirect}
                  disabled={this.state.loadingSsoUrlByDomain}
                >
                  { this.state.loadingSsoUrlByDomain &&
                    <span className="mr-2">
                      <ClipLoader
                        size={15}
                        color="ffffff"
                      />
                    </span>
                  }
                  Continue
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col></Col>
        </Row>
      </div>
    );
  }
};
