import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import history from '../../routers/history';
import queryString from 'query-string';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 'password-reset',
      password: '',
      confirmPassword: '',
      errors: {},
      errorMessage: ''
    };
  }

  componentDidMount() {
    let type;
    if (this.props.location.pathname.includes('/invite/')) {
      type = 'invite';
    } else {
      type = 'password-reset'
    }
    this.setState(() => ({
      isMounted: true,
      type,
    }));
  }

  onPasswordChange = (event) => {
    const password = event.currentTarget.value;
    this.setState(() => ({ password }));
  };

  onConfirmPasswordChange = (event) => {
    const confirmPassword = event.currentTarget.value;
    this.setState(() => ({ confirmPassword }));
  };

  validRequest = () => {
    // validate
    const errors = {};
    let errorMessage = '';
    if (!this.state.password || this.state.password.trim() === '') {
      errors.password = true;
    }
    if (!this.state.confirmPassword || this.state.confirmPassword.trim() === '') {
      errors.confirmPassword = true;
    }
    if (this.state.password !== this.state.confirmPassword) {
      errors.matchingPasswords = true;
      errorMessage = 'Passwords do not match';
    }
    const hasUpperCase = /[A-Z]/.test(this.state.password);
    const hasLowerCase = /[a-z]/.test(this.state.password);
    const hasNumbers = /\d/.test(this.state.password);
    const hasSymbols = /\W/.test(this.state.password);
    if (
      this.state.password.length < 8 &&
      hasUpperCase + hasLowerCase + hasNumbers + hasSymbols < 3
    ) {
      errors.password = true;
      errors.confirmPassword = true;
      errorMessage = 'Password needs to include both lower and upper case characters, number or symbol, and be at least 8 characters';
    }

    this.setState(() => ({
      errors,
      errorMessage
    }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  updatePassword = () => {
    if (this.validRequest()) {
      const queryValues = queryString.parse(this.props.location.search);
      const passwordResetRequest = {
        id: this.props.match.params.customerUserId,
        token: queryValues.token,
        password: this.state.password
      };

      axios.post(
        `${LISTEN_ENDPOINT}/api/customer-users/password-reset`,
        passwordResetRequest,
        HEADERS
      ).then(response => {
        history.push('/login');
      }).catch(error => {
        if (this.state.isMounted) {
          this.setState(() => ({
            errorMessage: 'Token is no longer valid'
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };


  render () {
    return (
      <div className="m-4">
        { this.state.type === 'invite' &&
          <div className="p-4 text-center">
            <h1>Welcome to Brand<b className="text-bops-blue">Ops</b></h1>
          </div>
        }
        <Row>
          <Col>
            <Card className="mx-auto w-75">
              <Card.Header>
                { this.state.type === 'invite' ?
                  'To get started, create a password' :
                  'Update Password'
                }
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col xs={8}>
                    <Form>
                      <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          onChange={this.onPasswordChange}
                          isInvalid={this.state.errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          Required
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          type="password"
                          onChange={this.onConfirmPasswordChange}
                          isInvalid={this.state.errors.confirmPassword}
                        />
                        <Form.Control.Feedback type="invalid">
                          Required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col xs={4}>
                    <div>Your password needs to:</div>
                    <ul>
                      <li>include both lower and upper case characters</li>
                      <li>include at least one number or symbol</li>
                      <li>be at least 8 characters long</li>
                    </ul>
                  </Col>
                </Row>
                <Alert show={Boolean(this.state.errorMessage)} variant="danger">
                  {this.state.errorMessage}
                </Alert>
                <Button variant="success" onClick={this.updatePassword}>
                  Update Password
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
};
