import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GeneralSummaryGraph from '../graphs/GeneralSummaryGraph';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class PodcastSummaryGraphs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      podcastMentionData: [],
      podcastMentionCountScoreType: undefined,
      podcastMentionDataLoading: false,
      categoryAverageMentionData: [],
      podcastEstimatedReachData: [],
      podcastEstimatedReachScoreType: undefined,
      podcastEstimatedReachDataLoading: false,
      categoryAverageEstimatedReachData: [],
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getPodcastMentionData();
    this.getPodcastEstimatedReachData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.productBrandId !== this.props.productBrandId ||
      prevProps.allMonthsChecked !== this.props.allMonthsChecked
    ) {
      this.getPodcastMentionData();
      this.getPodcastEstimatedReachData();
    }
  }

  getPodcastMentionData = () => {
    this.setState(() => ({ podcastMentionDataLoading: true }));
    let allMonthsStartDateParam = '';
    if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
      const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
      if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
        allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
      }
    }
    if (this.props.productBrandId) {
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/product-brand/${this.props.productBrandId}/podcasts/stats?group_by=month${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let podcastMentionData = response.data.monthly_stats;
        const podcastMentionCountScoreType = response.data.mention_count ? response.data.mention_count.score_type : undefined;
        // merge category average data into original data source
        podcastMentionData = podcastMentionData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAverageMentionData[i]))
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            podcastMentionData,
            podcastMentionCountScoreType,
            podcastMentionDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch podcast mention count data');
        if (this.state.isMounted) {
          this.setState(() => ({
            podcastMentionData: [],
            podcastMentionCountScoreType: undefined,
            podcastMentionDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=podcast_avg_episode_mention${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAverageMentionData = response.data.category_stats;
          // merge category average data into original data source
          const podcastMentionData = this.state.podcastMentionData.map((x, i) => {
            return (Object.assign({}, x, categoryAverageMentionData[i]))
          });

          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAverageMentionData,
              podcastMentionData
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average podcast mention data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAverageMentionData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  getPodcastEstimatedReachData = () => {
    this.setState(() => ({ podcastEstimatedReachDataLoading: true }));
    let allMonthsStartDateParam = '';
    if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
      const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
      if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
        allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
      }
    }
    if (this.props.productBrandId) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/product-brands/${this.props.productBrandId}/podcast-estimated-reach${allMonthsStartDateParam ? `?start_date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let podcastEstimatedReachData = response.data.monthly_stats;
        const podcastEstimatedReachScoreType = response.data.podcast_estimated_reach ? response.data.podcast_estimated_reach.score_type : undefined;
        // merge category average data into original data source
        podcastEstimatedReachData = podcastEstimatedReachData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAverageEstimatedReachData[i]))
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            podcastEstimatedReachData,
            podcastEstimatedReachScoreType,
            podcastEstimatedReachDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch podcast estimated reach data');
        if (this.state.isMounted) {
          this.setState(() => ({
            podcastEstimatedReachData: [],
            podcastEstimatedReachScoreType: undefined,
            podcastEstimatedReachDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=podcast_avg_estimated_subscriber_reach${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAverageEstimatedReachData = response.data.category_stats;
          // merge category average data into original data source
          const podcastEstimatedReachData = this.state.podcastEstimatedReachData.map((x, i) => {
            return (Object.assign({}, x, categoryAverageEstimatedReachData[i]))
          });

          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAverageEstimatedReachData,
              podcastEstimatedReachData
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average podcast reach data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAverageEstimatedReachData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  }

  render () {
    return (
      <div>
        <Row>
          <Col>
            <GeneralSummaryGraph
              title="Podcast Episode Product Brand Mentions"
              metricName="podcast_listen_mention_count"
              data={this.state.podcastMentionData}
              dataKey="mention_count"
              dataLabel="Podcast Episode Product Brand Mentions"
              scoreType={this.state.podcastMentionCountScoreType}
              loading={this.state.podcastMentionDataLoading}
              allowDecimals={false}
              showPercentChange={true}
              showAverageData={true}
              averageDataKey="podcast_avg_episode_mention"
              averageDataLabel="Category Average"
              showTooltip={true}
              contextType="brand"
              contextCategory={this.props.category}
              contextBrand={this.props.brand}
              contextChannel="Podcast"
              contextChartName="Podcast Episode Product Brand Mentions"
              shareClassName="podcast-episode-mentions-img"
            />
          </Col>
          <Col>
            <GeneralSummaryGraph
              title="Podcast Estimated Subscriber Reach"
              metricName="podcast_estimated_subscriber_reach"
              data={this.state.podcastEstimatedReachData}
              dataKey="podcast_estimated_reach"
              dataLabel="Podcast Estimated Subscriber Reach"
              scoreType={this.state.podcastEstimatedReachScoreType}
              loading={this.state.podcastEstimatedReachDataLoading}
              allowDecimals={false}
              showPercentChange={true}
              showAverageData={true}
              averageDataKey="podcast_avg_estimated_subscriber_reach"
              averageDataLabel="Category Average"
              showTooltip={true}
              contextType="brand"
              contextCategory={this.props.category}
              contextBrand={this.props.brand}
              contextChannel="Podcast"
              contextChartName="Podcast Estimated Subscriber Reach"
              shareClassName="podcast-estimated-subscriber-reach-img"
            />
          </Col>
        </Row>
      </div>
    );
  }
};
