import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import Table from 'react-bootstrap/Table';
import MetricTooltipContainer from '../../containers/metrics/MetricTooltipContainer';
import MetricDistributionSummaryModal from '../metrics/MetricDistributionSummaryModal';
import { numberWithCommas } from '../../utils/numbers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretUp,
  faRightLong,
  faAssistiveListeningSystems,
  faClipboardList,
  faDesktop,
  faEnvelope,
  faFileAlt,
  faGlobe,
  faMagnifyingGlass,
  faNewspaper,
  faPodcast,
  faRectangleAd,
  faStarHalfAlt,
  faVideo
} from '@fortawesome/free-solid-svg-icons';

export default class BrandMetricsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      selectedMetricName: undefined,
      metricDistributionModalOpen: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.brandMetricsData !== this.props.brandMetricsData ||
      prevProps.stageName !== this.props.stageName
    ) {
      this.setData();
    }
  };

  setData = () => {
    if (this.props.brandMetricsData) {
      const brandMetricsMap = {};
      for (let metric of this.props.brandMetricsData) {
        if (
          this.props.stageName === 'All' ||
          metric.stage_name === this.props.stageName
        ) {
          switch (metric.channel_name) {
            case 'Email Campaigns':
            case 'Emails':
              if (!brandMetricsMap['emails']) {
                brandMetricsMap['emails'] = [metric];
              } else {
                brandMetricsMap['emails'].push(metric);
              }
              break;
            case 'News':
              if (!brandMetricsMap['news']) {
                brandMetricsMap['news'] = [metric];
              } else {
                brandMetricsMap['news'].push(metric);
              }
              break;
            case 'Social':
              if (!brandMetricsMap['social']) {
                brandMetricsMap['social'] = [metric];
              } else {
                brandMetricsMap['social'].push(metric);
              }
              break;
            case 'Product Reviews':
              if (!brandMetricsMap['product_reviews']) {
                brandMetricsMap['product_reviews'] = [metric];
              } else {
                brandMetricsMap['product_reviews'].push(metric);
              }
              break;
            case 'Employer Reviews':
              if (!brandMetricsMap['employer_reviews']) {
                brandMetricsMap['employer_reviews'] = [metric];
              } else {
                brandMetricsMap['employer_reviews'].push(metric);
              }
              break;
            case 'Videos':
              if (!brandMetricsMap['video']) {
                brandMetricsMap['video'] = [metric];
              } else {
                brandMetricsMap['video'].push(metric);
              }
              break;
            case 'Web Analytics':
            case 'Web':
              if (!brandMetricsMap['web']) {
                brandMetricsMap['web'] = [metric];
              } else {
                brandMetricsMap['web'].push(metric);
              }
              break;
            case 'Search Engine Results':
              if (!brandMetricsMap['serp']) {
                brandMetricsMap['serp'] = [metric];
              } else {
                brandMetricsMap['serp'].push(metric);
              }
              break;
            case 'Ads':
              if (!brandMetricsMap['ads']) {
                brandMetricsMap['ads'] = [metric];
              } else {
                brandMetricsMap['ads'].push(metric);
              }
              break;
            case 'Webinar':
              if (!brandMetricsMap['webinar']) {
                brandMetricsMap['webinar'] = [metric];
              } else {
                brandMetricsMap['webinar'].push(metric);
              }
              break;
            case 'Blogs':
              if (!brandMetricsMap['blog']) {
                brandMetricsMap['blog'] = [metric];
              } else {
                brandMetricsMap['blog'].push(metric);
              }
              break;
            case 'Podcast':
              if (!brandMetricsMap['podcast']) {
                brandMetricsMap['podcast'] = [metric];
              } else {
                brandMetricsMap['podcast'].push(metric);
              }
              break;
            case 'Surveys via BrandOps':
              if (!brandMetricsMap['survey']) {
                brandMetricsMap['survey'] = [metric];
              } else {
                brandMetricsMap['survey'].push(metric);
              }
              break;
            default:
              break;
          }
        }
      }

      const data = [];
      if (brandMetricsMap.news) {
        data.push({
          type: 'news',
          rowsData: brandMetricsMap.news
        });
      }
      if (brandMetricsMap.social) {
        data.push({
          type: 'social',
          rowsData: brandMetricsMap.social
        });
      }
      if (brandMetricsMap.product_reviews) {
        data.push({
          type: 'product_reviews',
          rowsData: brandMetricsMap.product_reviews
        });
      }
      if (brandMetricsMap.employer_reviews) {
        data.push({
          type: 'employer_reviews',
          rowsData: brandMetricsMap.employer_reviews
        });
      }
      if (brandMetricsMap.video) {
        data.push({
          type: 'video',
          rowsData: brandMetricsMap.video
        });
      }
      if (brandMetricsMap.web) {
        data.push({
          type: 'web',
          rowsData: brandMetricsMap.web
        });
      }
      if (brandMetricsMap.serp) {
        data.push({
          type: 'serp',
          rowsData: brandMetricsMap.serp
        });
      }
      if (brandMetricsMap.ads) {
        data.push({
          type: 'ads',
          rowsData: brandMetricsMap.ads
        });
      }
      if (brandMetricsMap.webinar) {
        data.push({
          type: 'webinar',
          rowsData: brandMetricsMap.webinar
        });
      }
      if (brandMetricsMap.blog) {
        data.push({
          type: 'blog',
          rowsData: brandMetricsMap.blog
        });
      }
      if (brandMetricsMap.podcast) {
        data.push({
          type: 'podcast',
          rowsData: brandMetricsMap.podcast
        });
      }
      if (brandMetricsMap.emails) {
        data.push({
          type: 'emails',
          rowsData: brandMetricsMap.emails
        });
      }
      if (brandMetricsMap.survey) {
        data.push({
          type: 'survey',
          rowsData: brandMetricsMap.survey
        });
      }

      this.setState(() => ({ data }));
    }
  };

  getIcon = (type) => {
    let icon;
    let label;
    switch (type) {
      case 'web':
        icon = faGlobe;
        label = 'Web';
        break;
      case 'webinar':
        icon = faDesktop;
        label = 'Webinar';
        break;
      case 'social':
        icon = faAssistiveListeningSystems;
        label = 'Social';
        break;
      case 'news':
        icon = faNewspaper;
        label = 'News';
        break;
      case 'product_reviews':
        icon = faStarHalfAlt;
        label = 'Product Reviews';
        break;
      case 'employer_reviews':
        icon = faStarHalfAlt;
        label = 'Employer Reviews';
        break;
      case 'serp':
        icon = faMagnifyingGlass;
        label = 'Search';
        break;
      case 'ads':
        icon = faRectangleAd;
        label = 'Ads';
        break;
      case 'blog':
        icon = faFileAlt;
        label = 'Blogs';
        break;
      case 'podcast':
        icon = faPodcast;
        label = 'Podcasts';
        break;
      case 'survey':
        icon = faClipboardList;
        label = 'Surveys';
        break;
      case 'emails':
        icon = faEnvelope;
        label = 'Emails';
        break;
      case 'video':
        icon = faVideo;
        label = 'Video';
        break;
    }
    if (icon) {
      return (
        <div className="text-center">
          <FontAwesomeIcon
            icon={icon}
            size="2x"
          />
          <div><b>{label}</b></div>
        </div>
      )
    } else {
      return '';
    }
  }

  metricRows = (data) => (
    <React.Fragment>
      { data.map((group, gi) => {
          return (
            <React.Fragment key={`g-${group.type}`}>
              { group.rowsData.map((row, ri) => {
                  if (!row.metric_name) {
                    // Spacing Row
                    return (
                      <tr
                        key={`g-${group.type}-mr-${ri}`}
                        style={{ height: '33px' }}
                      >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  } else {
                    return(
                      <tr key={`bmr-${ri}`}>
                        { ri === 0 &&
                          <td
                            className="bg-white align-middle"
                            rowSpan={`${group.rowsData.length+1}`}
                          >
                            {this.getIcon(group.type)}
                          </td>
                        }
                        <td
                          className="pl-4"
                          style={{
                            width: '40%',
                            color: this.isMetricSetup(row) ? 'black' : '#b3b3b3'
                          }}
                        >
                          <MetricTooltipContainer
                            tooltipTrigger={
                              <span>
                                {row.metric_label}
                              </span>
                            }
                            metricName={row.metric_name}
                            openMetricDistributionModal={this.openMetricDistributionModal}
                          />
                        </td>
                        <td className="text-center">
                          { (row.amount_display && row.amount_value != null) &&
                            <div>
                              {numberWithCommas(row.amount_value)}
                              { row.is_percent === 1 &&
                                <span>{'%'}</span>
                              }
                            </div>
                          }
                        </td>
                        <td className="text-center">
                          { (row.change_display && row.percentage_change != null) &&
                            <div>
                              { row.percentage_change > 0 &&
                                <span>+</span>
                              }
                              {`${numberWithCommas(row.percentage_change)}%`}
                            </div>
                          }
                        </td>
                        <td className="text-center">
                          { (row.values_display && !(row.initial_date_score == null && row.end_date_score == null)) &&
                            <div>
                              { row.initial_date_score != null &&
                                <div className="d-inline-block">
                                  {numberWithCommas(row.initial_date_score)}
                                  { row.is_percent === 1 &&
                                    <span>{'%'}</span>
                                  }
                                </div>
                              }
                              { row.initial_date_score == null &&
                                <div className="d-inline-block">
                                  N/A
                                </div>
                              }
                              <div className="d-inline-block px-2">
                                <FontAwesomeIcon icon={faRightLong}/>
                              </div>
                              { row.end_date_score != null &&
                                <div className="d-inline-block">
                                  {numberWithCommas(row.end_date_score)}
                                  { row.is_percent === 1 &&
                                    <span>{'%'}</span>
                                  }
                                </div>
                              }
                              { row.end_date_score == null &&
                                <div className="d-inline-block">
                                  N/A
                                </div>
                              }
                            </div>
                          }
                        </td>
                        <td className="text-center">
                          { row.percentage_change === 0 &&
                            <div>-</div>
                          }
                          { (
                              (row.score_type === 'high_is_good' && row.percentage_change > 0) ||
                              (row.score_type === 'low_is_good' && row.percentage_change < 0)
                            ) &&
                            <FontAwesomeIcon
                              icon={faCaretUp}
                              color="#00ff00"
                              style={{ fontSize: '1.5rem' }}
                            />
                          }
                          { (
                              (row.score_type === 'high_is_good' && row.percentage_change < 0) ||
                              (row.score_type === 'low_is_good' && row.percentage_change > 0)
                            ) &&
                            <FontAwesomeIcon
                              icon={faCaretDown}
                              color="#dc3545"
                              style={{ fontSize: '1.5rem' }}
                            />
                          }
                        </td>
                      </tr>
                    )
                  }
                })
              }
              <tr
                className="bg-white"
                style={{ height: '33px' }}
              >
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </React.Fragment>
          )
        })
      }
    </React.Fragment>
  );

  isMetricSetup = (metric) => {
    if (
      this.props.metricsSetupMap &&
      this.props.metricsSetupMap[metric.site_name] &&
      !this.props.metricsSetupMap[metric.site_name].setup
    ) {
      return false;
    } else {
      return true;
    }
  };

  openMetricDistributionModal = (selectedMetricName) => {
    if (selectedMetricName) {
      this.setState(() => ({
        selectedMetricName,
        metricDistributionModalOpen: true,
      }));
    }
  };

  closeMetricDistributionModal = () => {
    this.setState(() => ({
      selectedMetricName: undefined,
      metricDistributionModalOpen: false,
    }));
  };

  render () {
    return (
      <div className="w-100">
        { this.props.brandMetricsDataLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.props.brandMetricsDataLoading &&
          <div
            className="pre-scrollable"
            style={{
              minHeight: 'calc(100vh - 350px)',
              maxHeight: 'calc(100vh - 350px)',
              overflow: 'auto',
              overflowX: 'hidden'
            }}
          >
            <Table
              bordered
              striped
              size="sm"
              style={{
                overflow: 'auto',
              }}
            >
              <thead className="bg-dark text-light text-center">
                <tr
                  style={{
                    position: 'sticky',
                    top: -1,
                    zIndex: 1
                  }}
                >
                  <th
                    className="bg-dark"
                    style={{ width: '100px' }}
                  ></th>
                  <th
                    className="bg-dark text-left"
                    style={{ width: '300px' }}
                  >
                    <div className="pl-3">
                      {`Metrics for ${this.props.brand.name}`}
                    </div>
                  </th>
                  <th className="bg-dark">
                    Count
                  </th>
                  <th className="bg-dark">
                    Change
                  </th>
                  <th className="bg-dark text-center">
                    Initial & End Value
                    <div style={{ fontSize: '.875rem', fontWeight: 'normal' }}>
                      {`${this.props.initialDate.format('MM/DD/YY')} to ${this.props.endDate.format('MM/DD/YY')}`}
                    </div>
                  </th>
                  <th className="bg-dark" style={{ width: '110px' }}>
                    Period Trend
                  </th>
                </tr>
              </thead>
              <tbody>
                { this.state.data &&
                  <React.Fragment>
                    {this.metricRows(this.state.data)}
                  </React.Fragment>
                }
              </tbody>
            </Table>

            <MetricDistributionSummaryModal
              isOpen={this.state.metricDistributionModalOpen}
              handleClose={this.closeMetricDistributionModal}
              metricName={this.state.selectedMetricName}
              audienceProfileId={this.props.category.audience_profile_id}
              customerId={this.props.user.customerId}
            />
          </div>
        }
      </div>
    );
  }
};
