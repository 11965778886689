import React from 'react';
import queryString from 'query-string';
import ClipLoader from 'react-spinners/ClipLoader';
import LoginContainer from '../../containers/common/LoginContainer';
import history from '../../routers/history';

export default class Alerts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.user.customerId && this.props.categories.length > 0) {
      this.findProductBrandAndRoute();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevProps.categories !== this.props.categories
    ) {
      this.findProductBrandAndRoute();
    }
  }

  findProductBrandAndRoute = () => {
    const queryValues = queryString.parse(this.props.location.search);
    if (this.props.categories.length > 0 && queryValues.i) {
      const productBrandId = parseInt(queryValues.i);
      for (const category of this.props.categories) {
        for (const brand of category.product_brands) {
          if (brand.id === productBrandId) {
            history.push(`/category/${encodeURIComponent(category.name)}/brand/${encodeURIComponent(brand.name)}#social`);
          }
        }
      }
    }
  };

  render () {
    return (
      <div className="p-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        { (this.props.user && this.props.user.id) &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
      </div>
    );
  }
};
