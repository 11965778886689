import { connect } from 'react-redux';
import LeftMenu from '../../components/common/LeftMenu';
import { fetchCustomer } from '../../actions/customer/customer';
import { fetchCompany } from '../../actions/company/company';
import { fetchCompanySetupChecklist } from '../../actions/company/companySetupChecklist';
import { fetchCategories } from '../../actions/category/categories';
import { fetchCompanyCustomReports } from '../../actions/customReports/customReports';
import { fetchAlerts } from '../../actions/alerts/alerts';
import { fetchSlackChannels } from '../../actions/slack/slackChannels';
import { fetchBrandMetrics } from '../../actions/metrics/brandMetrics';
import { setListenProjectBuild } from '../../actions/projectBuilds/projectBuilds';

const mapStateToProps = (state) => ({
  user: state.user,
  customer: state.customer,
  company: state.company,
  companySetupChecklist: state.companySetupChecklist,
  categories: state.categories,
  customReports: state.customReports,
  alerts: state.alerts,
  slackChannels: state.slackChannels,
  myCategoryBrands: state.myCategoryBrands,
  showRefreshAlert: state.showRefreshAlert,
  showNewUserGuidedTour: state.showNewUserGuidedTour,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCustomer: (customerId) => dispatch(fetchCustomer(customerId)),
  fetchCompany: (companyId) => dispatch(fetchCompany(companyId)),
  fetchCompanySetupChecklist: (companyId) => dispatch(fetchCompanySetupChecklist(companyId)),
  fetchCategories: (customerId) => dispatch(fetchCategories(customerId)),
  fetchCompanyCustomReports: (companyId) => dispatch(fetchCompanyCustomReports(companyId)),
  fetchAlerts: (customerId) => dispatch(fetchAlerts(customerId)),
  fetchSlackChannels: (customerId) => dispatch(fetchSlackChannels(customerId)),
  fetchBrandMetrics: () => dispatch(fetchBrandMetrics()),
  setListenProjectBuild: () => dispatch(setListenProjectBuild()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);
