import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import queryString from 'query-string';
import ClipLoader from 'react-spinners/ClipLoader';
import FreeFormQuestion from './FreeFormQuestion';
import RateQuestion from './RateQuestion';
import SelectMultipleQuestion from './SelectMultipleQuestion';
import SelectSingleQuestion from './SelectSingleQuestion';
import TrueFalseQuestion from './TrueFalseQuestion';
import YesNoQuestion from './YesNoQuestion';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class Survey extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      surveyId: undefined,
      respondentId: undefined,
      questionPosition: 1,
      highestQuestionPosition: 1,
      questionLoading: false,
      question: undefined,
      update: true
    };
  }

  componentDidMount() {
    const queryValues = queryString.parse(this.props.location.search);
    this.setState(() => ({
      isMounted: true,
      surveyId: queryValues.survey_id,
      respondentId: queryValues.respondent_id
    }), () => this.loadQuestion(1));
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {

  };

  loadQuestion = (questionPosition) => {
    if (this.state.surveyId) {
      this.setState(() => ({ questionLoading: true }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/customer-surveys/${this.state.surveyId}/questions?question_position=${questionPosition}&survey_issuance_respondent_id=${this.state.respondentId}`,
        HEADERS
      ).then(response => {
        const question = response.data;
        if (this.state.isMounted) {
          // set default answer
          let answer;
          switch (question.type) {
            case 'Select Single':
              answer = question.multiple_choices[0].choice;
              break;
            case 'Select Multiple':
              answer = [];
              break;
            case 'Free form':
              answer = '';
              break;
            case 'Rate(1-7)':
              answer = 1;
              break;
            case 'Yes/No':
              answer = 'Yes';
              break;
            case 'True/False':
              answer = 'True';
              break;
          }

          this.setState(() => ({
            question,
            questionLoading: false,
            answer
          }));
        }
      }).catch(error => {
        // 404 is being returned when end of survey
        this.setState(() => ({
          question: error.response.data,
          questionLoading: false
        }));
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onAnswerChangeEventValue = (event) => {
    const answer = event.currentTarget.value;
    this.setState(() => ({ answer }));
  };

  onAnswerChangeDirect = (answer) => {
    this.setState(() => ({ answer }));
  };

  onAnswerChangeMultiple = (checked, value) => {
    this.setState((prevState) => {
      if (checked) {
        return ({ answer: [...prevState.answer, value] });
      } else {
        return ({ answer: prevState.answer.filter(a => a != value) });
      }
    });
  }
  // onAnswerChangeMultiple = (limit, value) => {
  //   this.setState((prevState) => {
  //     if (Array.isArray(this.state.answer) && this.state.answer.includes(value)) {
  //       return ({ answer: prevState.answer.filter(a => a != value) });
  //     }
  //     else if (Array.isArray(this.state.answer) && this.state.answer.length==limit) {
  //       return;
  //     }
  //     else {
  //       return ({ answer: [...prevState.answer, value] });
  //     }
  //   });
  // }

  onAnswerChangeSingle = (checked, value) => {
    if (checked) {
      const answer = value;
      this.setState(() => ({ answer, update: false }));
      this.setState(() => ({ update: true }));
    }
  }

  submitAnswer = () => {
    const surveyResponseBody = {
      survey_issuance_respondent_id: this.state.respondentId,
      question_id: this.state.question.question_id,
      response_date: moment().utc().format('YYYY-MM-DD HH:mm:ss')
    };

    if (this.state.question.type === 'Select Multiple') {
      const surveyResponsePromises = [];
      for (let a of this.state.answer) {
        const surveyResponseBodyCopy = Object.assign({}, surveyResponseBody);
        surveyResponseBodyCopy.textual_answer = a;
        surveyResponsePromises.push(
          axios.post(
            `${CONTENT_SCRAPING_ENDPOINT}/api/customer-surveys/${this.state.surveyId}/answer-question`,
            surveyResponseBodyCopy,
            HEADERS
          ).then(response => {
            return;
          }).catch(error => {
            console.error('Error: failed to answer question');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return;
          })
        );
      }

      Promise.all(surveyResponsePromises).then(responses => {
        let questionPosition = this.state.questionPosition;
        questionPosition += 1;
        this.setState(() => ({
          questionPosition,
          highestQuestionPosition: questionPosition
        }), () => this.loadQuestion(questionPosition));
      });
    } else {
      switch (this.state.question.type) {
        case 'Select Single':
        case 'Free form':
        case 'Rate(1-7)':
          surveyResponseBody.textual_answer = this.state.answer;
          break;
        case 'Yes/No':
          surveyResponseBody.boolean_answer = this.state.answer === 'Yes';
          break;
        case 'True/False':
          surveyResponseBody.boolean_answer = this.state.answer === 'True';
          break;
      }

      axios.post(
        `${CONTENT_SCRAPING_ENDPOINT}/api/customer-surveys/${this.state.surveyId}/answer-question`,
        surveyResponseBody,
        HEADERS
      ).then(response => {
        let questionPosition = this.state.questionPosition;
        questionPosition += 1;
        this.setState(() => ({
          questionPosition,
          highestQuestionPosition: questionPosition
        }), () => this.loadQuestion(questionPosition));
      }).catch(error => {
        console.error('Error: failed to answer question');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="p-4">
        { this.state.questionLoading &&
          <div className="m-4">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.questionLoading &&
          <div>
            { this.state.question &&
              <div>
                { this.state.question.message === 'invalid question' &&
                  <Alert variant="dark">
                    Survey Complete. Thanks!
                  </Alert>
                }
                { this.state.question.message === 'Survey already taken' &&
                  <Alert variant="dark">
                    Survey already taken. Thanks!
                  </Alert>
                }
                { this.state.question.question_id &&
                  <div>
                    { this.state.question.type === 'Select Single' &&
                      <SelectSingleQuestion
                        questionId={this.state.question.question_id}
                        question={this.state.question.resolved_question}
                        options={this.state.question.multiple_choices}
                        answer={this.state.answer}
                        onAnswerChange={this.onAnswerChangeEventValue} // onAnswerChangeSingle
                        update={this.state.update}
                      />
                    }
                    { this.state.question.type === 'Select Multiple' &&
                      <SelectMultipleQuestion
                        questionId={this.state.question.question_id}
                        question={this.state.question.resolved_question}
                        options={this.state.question.multiple_choices}
                        answer={this.state.answer}
                        onAnswerChange={this.onAnswerChangeMultiple}
                        // limit={this.state.limit}
                      />
                    }
                    { this.state.question.type === 'Free form' &&
                      <FreeFormQuestion
                        questionId={this.state.question.question_id}
                        question={this.state.question.resolved_question}
                        answer={this.state.answer}
                        onAnswerChange={this.onAnswerChangeEventValue}
                      />
                    }
                    { this.state.question.type === 'Rate(1-7)' &&
                      <RateQuestion
                        questionId={this.state.question.question_id}
                        question={this.state.question.resolved_question}
                        answer={this.state.answer}
                        onAnswerChange={this.onAnswerChangeDirect}
                      />
                    }
                    { this.state.question.type === 'Yes/No' &&
                      <YesNoQuestion
                        questionId={this.state.question.question_id}
                        question={this.state.question.resolved_question}
                        answer={this.state.answer}
                        onAnswerChange={this.onAnswerChangeEventValue} // onAnswerChangeDirect
                      />
                    }
                    { this.state.question.type === 'True/False' &&
                      <TrueFalseQuestion
                        questionId={this.state.question.question_id}
                        question={this.state.question.resolved_question}
                        answer={this.state.answer}
                        onAnswerChange={this.onAnswerChangeEventValue} // onAnswerChangeDirect
                      />
                    }
                    <div>
                      <Button
                        variant="success"
                        onClick={this.submitAnswer}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    );
  }
};
