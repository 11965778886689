import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  ScatterChart,
  Scatter,
  Dot,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

const CustomizedShape = (props) => {
  const {cx, cy, fill, name} = props;
  return (
    <g>
      <Dot cx={cx} cy={cy} r={5} fill={fill} />
      <g transform={`translate(${cx},${cy})`}>
        <text stroke="white" strokeWidth="0.4em" x={0} y={-12} dy={4} textAnchor="middle">{name}</text>
        <text fill="black" x={0} y={-12} dy={4} textAnchor="middle">{name}</text>
      </g>
    </g>
   );
};

const CustomedTooltip = (props) => {
  if (!props.active) {
    return null
  }
  const newPayload = [
    {
      name: 'Brand',
      value: props.payload[0].payload.name
    },
    ...props.payload
  ];
  return (<DefaultTooltipContent {...props} payload={newPayload} />);
};

export default class CategoryRatingToVolume extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lastCloseDate: moment.utc().subtract(1, 'days'),
      ratingToVolumeData: [],
      noDataBrands: [],
      loading: false
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchVolumeToScoreData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      this.fetchVolumeToScoreData();
    }
  };

  fetchVolumeToScoreData = () => {
    this.setState(() => ({ loading: true }));
    const brandClosePromises = [];
    const formattedStartDate = this.state.lastCloseDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = this.state.lastCloseDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');
    for (let brand of this.props.category.product_brands) {
      if (brand.topic && brand.topic.id) {
        const result = {
          name: brand.name,
          topic_id: brand.topic.id,
          brand
        };

        brandClosePromises.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/topics/${brand.topic.id}/lcx-closes?start-date=${formattedStartDate}&end-date=${formattedEndDate}`,
            HEADERS
          ).then(response => {
            if (response.data.length > 0) {
              const closeData = response.data[0];
              result.close_data = closeData;
              result.close_date = closeData.close_date;
              // multiply average daily volume out 31 days to resemble the last 30 days plus current day
              result.average_volume = parseInt(parseFloat(closeData.thirty_day_avg_volume || 0) * 31);
              result.average_bx_score = Number(closeData.thirty_day_lcx_rating.toFixed(1));
              return result;
            } else {
              console.log(`Error: failed to get volume and score data for brand "${brand.name}"`);
              result.average_volume = 0;
              result.average_bx_score = 0;
              return result;
            }
          }).catch(error => {
            console.error(`Error: failed to get volume and score data for brand "${brand.name}"`);
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
          })
        );
      } else {
        console.log(`Error: topic not found for brand "${brand.name}"`);
      }
    }

    Promise.all(brandClosePromises).then(results => {
      let ratingToVolumeData = [];
      if (this.state.isMounted) {
        let ratingToVolumeData = [];
        let noDataBrands = [];
        for (const data of results) {
          if (!(data.average_volume==0 && data.average_bx_score==0)) {
            let duplicate = ratingToVolumeData.filter(function(pb) {
              return (pb.average_volume == data.average_volume && pb.average_bx_score == data.average_bx_score);
            });
            // if brand with same data point, combine brand names in label
            if (duplicate.length>0) {
              for (const i in ratingToVolumeData) {
                let brandData = ratingToVolumeData[i];
                if (duplicate[0].name == brandData.name) {
                  ratingToVolumeData[i].name = brandData.name + ", " + data.name;
                }
              }
            } else {
              ratingToVolumeData.push(data);
            }
          } else {
            noDataBrands.push(data);
          }
        }
        this.setState(() => ({
          ratingToVolumeData,
          noDataBrands,
          loading: false
        }));
      }
    });
  };

  render () {
    return (
      <div className="p-4">
        <div>
          { this.state.loading &&
            <div
              className='text-center align-middle'
              style={{ maxHeight: '35vh' }}
            >
              <ClipLoader size={100}/>
            </div>
          }
          { !this.state.loading &&
            <div>
              <ResponsiveContainer width="99%" height={500} minWidth={0} minHeight={0}>
                <ScatterChart
                  margin={{
                    top: 25, right: 60, bottom: 30, left: 60,
                  }}
                >
                  <CartesianGrid />
                  <XAxis
                    type="number"
                    dataKey="average_bx_score"
                    name="Sentiment"
                    domain={[0, 'auto']}
                  >
                    <Label
                      value="Sentiment"
                      position="bottom"
                      style={{
                        fontSize: '1.2rem',
                        fontWeight: 'bold'
                      }}
                    />
                    { this.state.ratingToVolumeData.length === 0 &&
                      <Label
                        value="Not enough data gathered"
                        position="top"
                        offset={225}
                        style={{ fontSize: '1.5rem', fill: '#6c757d' }}
                      />
                    }
                  </XAxis>
                  <YAxis
                    type="number"
                    dataKey="average_volume"
                    name="Volume"
                    tickFormatter={(count) => {
                      return numberWithCommas(count);
                    }}
                  >
                    <Label
                      value="Volume"
                      position="left"
                      offset={40}
                      angle={-90}
                      style={{
                        textAnchor: 'middle',
                        fontSize: '1.2rem',
                        fontWeight: 'bold'
                      }}
                    />
                  </YAxis>
                  <Tooltip
                    cursor={{ strokeDasharray: '3 3' }}
                    content={<CustomedTooltip />}
                    formatter={(value) => numberWithCommas(value)}
                  />
                  <Scatter
                    data={this.state.ratingToVolumeData}
                    fill="#007bff"
                    shape={<CustomizedShape />}
                  />
                </ScatterChart>
              </ResponsiveContainer>
              { this.state.noDataBrands.length>0 &&
              <div>
                <div>
                  The following brands do not have enough Social Sentiment data to be included:
                </div>
              { this.state.noDataBrands.map((noDataBrand, i) => {
                  let index = i; index++;
                  if (index < this.state.noDataBrands.length) {
                    return (
                      <div key={`ndb-${i}`} className="d-inline-block mr-2">
                        {noDataBrand.name},
                      </div>
                    )
                  } else {
                    return (
                      <div key={`ndb-${i}`} className="d-inline-block mr-2">
                        {noDataBrand.name}
                      </div>
                    )
                  }
                })
              }
              </div>
              }
            </div>
          }
        </div>
      </div>
    );
  }
};
