import axios from 'axios';
import { sortAlphabeticalByKey } from '../../utils/sorts';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { reportError } from '../api/errors';

export const fetchSlackChannels = (companyId) => {
  return (dispatch) => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/slack-channels?company_id=${companyId}`,
      HEADERS
    ).then(response => {
      const slackChannels = response.data;
      slackChannels.sort(sortAlphabeticalByKey('name'));
      dispatch(addSlackChannels(slackChannels));
    }).catch(error => {
      console.error('Error: unable to fetch company slack channels');
      dispatch(addSlackChannels([]));
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatch(reportError(error.response));
      }
    });
  };
};

export const addSlackChannels = (slackChannels) => ({
  type: 'ADD_SLACK_CHANNELS',
  slackChannels
});
