import { useRef, useEffect, useState } from 'react';
import Vizzu from 'vizzu';
import VizzuModule from 'vizzu/dist/cvizzu.wasm';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../common/CardComponent';
import { numberWithCommas } from '../../utils/numbers';
import { colors } from '../../utils/graphs';

Vizzu.options({ wasmUrl: VizzuModule });

const stackedByPercentSettings = {
  config: {
    channels: {
      x: { set: ['Month'] },
      y: {
        set: ['Brand', 'Value'],
        range: {
          min: 'auto',
          max: 'auto'
        }
      },
      color: { set: ['Brand'] }
    },
    geometry: 'area',
    align: 'stretch',
    split: false
  },
  style: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontSize: '16px',
    'plot.paddingTop': '16px',
    'plot.paddingRight': '16px',
    'plot.yAxis.title.fontSize': '0px',
    'plot.yAxis.label.color': '#000000',
    'plot.xAxis.label.color': '#000000',
    'plot.xAxis.label.angle': '-10deg',
    'legend.width': '0px'
  }
};

const stackedValueSettings = {
  config: {
    channels: {
      x: { set: ['Month'] },
      y: {
        set: ['Brand', 'Value'],
        range: {
          min: 'auto',
          max: 'auto'
        }
      },
      color: { set: ['Brand'] }
    },
    geometry: 'area',
    align: 'min',
    split: false
  },
  style: {
    'plot.yAxis.label.color': '#000000'
  }
};

const splitValueSettings = {
  config: {
    channels: {
      y: {
        /* Making the chart elements fill the whole of the y-axis
        as the default value is now 110% */
        range: {
          max: '100%'
        }
      },
    },
    align: 'min',
    split: true
  },
  style: {
    'plot.yAxis.label.color': '#FFFFFF'
  }
};

function ShareOfChart(props) {
  const chartId = `soc-${Math.random().toString(36).substring(7)}`;
  const canvasRef = useRef();
  const chartRef = useRef();
  const [displayType, setDisplayType] = useState('byPercent');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [legendDisplayData, setLegendDisplayData] = useState([]);

  const formatLegendDisplayData = (monthData) => {
    if (monthData) {
      let selectedMonth = monthData.month;
      const legendDisplayData = [];
      let index = 0;
      for (const [key, value] of Object.entries(monthData)) {
        if (key !== 'month') {
          legendDisplayData.push({
            brandName: key,
            count: value.count,
            percent: value.percent,
            color: colors[index%colors.length],
          });
          index += 1;
        }
      }
      // reverse legend order to match colors to chart
      return legendDisplayData.reverse();
    } else {
      return [];
    }
  };

  const animateThroughViews = (initialDelay = 1) => {
    if (chartRef.current) {
      // animate through the views
      chartRef.current.animate(stackedValueSettings, {
        delay: 1,
        duration: 1
      });
      chartRef.current.animate(splitValueSettings, {
        delay: 1,
        duration: 1
      });
      chartRef.current.animate(stackedByPercentSettings, {
        delay: initialDelay,
        duration: 1
      });
    }
  }

  useEffect(() => {
    if (!chartRef.current) return;
    if (displayType === 'byPercent') {
      chartRef.current.animate(stackedByPercentSettings);
    } else if (displayType === 'stacked') {
      chartRef.current.animate(stackedValueSettings);
    } else if (displayType === 'split') {
      chartRef.current.animate(splitValueSettings);
    } else if (displayType === 'animate') {
      animateThroughViews(1);
    }
  }, [displayType]);

  useEffect(() => {
    if (props.legendData && props.legendData.length > 1) {
      setSelectedMonth(props.legendData[props.legendData.length-1].month);
      setLegendDisplayData(formatLegendDisplayData(props.legendData[props.legendData.length-1]));
    }
  }, [props.legendData]);

  useEffect(() => {
    if (!canvasRef.current) return;
    if (props.data && props.data.records && props.data.records.length > 0) {
      chartRef.current = new Vizzu(canvasRef.current, { data: props.data });
      chartRef.current.initializing.then((chart) => {
        // prevent logo from being drawn
        chart.on('logo-draw', (event) => {
          event.preventDefault();
        });
        // update legend display on hover
        chart.on('mouseon', (event) => {
          if (
            event &&
            event.data &&
            event.data.marker &&
            event.data.marker.categories &&
            event.data.marker.categories.Month
          ) {
            for (const d of props.legendData) {
              if (event.data.marker.categories.Month === d.month) {
                if (selectedMonth !== d.month) {
                  setSelectedMonth(d.month);
                  setLegendDisplayData(formatLegendDisplayData(d));
                }
              }
            }
          }
        });
        // animate to default chart
        chart.animate(stackedByPercentSettings);
      });
    }
  }, [props.data]);

  return (
    <CardComponent
      title={props.title}
      icon={props.icon}
      description={props.description}
      shareClassName={props.shareClassName}
      loading={props.loading}
      body={
        <div>
          { props.loading &&
            <div className="text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !props.loading &&
            <div>
              <ButtonGroup className="rounded border mr-4">
                <Button
                  variant={`${displayType === 'byPercent' ? 'info': 'light'}`}
                  size="sm"
                  data-display-type="byPercent"
                  onClick={() => setDisplayType('byPercent')}
                >By Percent</Button>
                <Button
                  variant={`${displayType === 'stacked' ? 'info': 'light'}`}
                  size="sm"
                  data-display-type="stacked"
                  onClick={() => setDisplayType('stacked')}
                >Stacked Values</Button>
                <Button
                  variant={`${displayType === 'split' ? 'info': 'light'}`}
                  size="sm"
                  data-display-type="split"
                  onClick={() => setDisplayType('split')}
                >Split Values</Button>
                <Button
                  variant={`${displayType === 'animate' ? 'info': 'light'}`}
                  size="sm"
                  data-display-type="animate"
                  onClick={() => setDisplayType('animate')}
                >Animate</Button>
              </ButtonGroup>
              <Row noGutters>
                <Col xs={8}>
                  <div>
                    <canvas
                      className="w-100"
                      ref={canvasRef}
                      style={{ height: "400px" }}
                    />
                  </div>
                </Col>
                <Col xs={4}>
                  <div
                    style={{
                      marginTop: '22px',
                      fontSize: '16px'
                    }}
                  >
                    <div
                      className="py-1 text-center bg-bops-blue text-light"
                      style={{ fontWeight: 'bold' }}
                    >
                      {selectedMonth}
                    </div>
                    <div className="mt-1">
                      {legendDisplayData.map((pb, i) => {
                          return (
                            <div key={`${chartId}-ld-${i}`} className="mb-1">
                              <Row noGutters>
                                <Col xs={7}>
                                  <div>
                                    <div
                                      className="d-inline-block mr-1 align-middle"
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: pb.color,
                                        borderRadius: '50%'
                                      }}
                                    />
                                    <div className="d-inline-block">
                                      {pb.brandName}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={5}>
                                  { props.precentDisplayOnly &&
                                    <div>
                                      {`${pb.percent}%`}
                                    </div>
                                  }
                                  { !props.precentDisplayOnly &&
                                    <div>
                                      {`${numberWithCommas(pb.count || 0)} (${pb.percent}%)`}
                                    </div>
                                  }
                                </Col>
                              </Row>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </Col>
              </Row>
              { props.chartDescription &&
                <div>
                  {props.chartDescription}
                </div>
              }
            </div>
          }
        </div>
      }
      contextType={props.contextType}
      contextCategory={props.contextCategory}
      contextBrand={props.contextBrand}
      contextChannel={props.contextChannel}
      contextProperty={props.contextProperty}
      contextChartName={props.contextChartName}
      contextDateRange={props.contextDateRange}
    />
  );
}

export default ShareOfChart;
