import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const DeleteConfirmationModal = (props) => {
  let deleteMessage;
  if (props.deleteMessage) {
    deleteMessage = props.deleteMessage;
  } else {
    deleteMessage = `Are you sure you would like to delete ${props.resourceName}?`;
  }

  let deleteButtonLabel;
  if (props.deleteButtonLabel) {
    deleteButtonLabel = props.deleteButtonLabel;
  } else {
    deleteButtonLabel = 'Delete';
  }

  return (
    <Modal
      size="sm"
      show={props.isOpen}
      onHide={props.handleClose}
    >
      <Modal.Header
        className="bg-danger text-light"
        closeButton
      >
        <Modal.Title>
          Confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {deleteMessage}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={props.deleteResource}>
          {deleteButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmationModal;
