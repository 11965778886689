import React from 'react';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPodcast } from '@fortawesome/free-solid-svg-icons';
import { round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';


export default class AudienceYoutube extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      podcastData: [],
      podcastDisplayData: [],
      podcastDataLoading: false,
      showMoreToggle: false,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchPodcastData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.brand !== this.props.brand) {
      this.fetchPodcastData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchPodcastData = () => {
    if (this.props.brand) {
      this.setState(() => ({
        podcastDataLoading: true,
        showMoreToggle: false,
      }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/audience-proxy?product_brand_id=${this.props.brand.id}&type=podcasts`,
        HEADERS
      ).then(response => {
        const podcastData = [];
        if (
          response.data &&
          response.data.data
        ) {
          for (const podcast of response.data.data) {
            podcast.share = round(podcast.share, 1);
            podcastData.push(podcast);
          }
        }
        // only show up to 5 websites originally
        const podcastDisplayData = podcastData.slice(0, 5);

        if (this.state.isMounted) {
          this.setState(() => ({
            podcastData,
            podcastDisplayData,
            podcastDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch audience podcast data.');
        if (this.state.isMounted) {
          this.setState(() => ({
            podcastData: [],
            podcastDisplayData: [],
            podcastDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  onShowMoreToggle = () => {
    this.setState((prevState) => {
      const showMoreToggle = !prevState.showMoreToggle;
      let podcastDisplayData = prevState.podcastData;
      if (!showMoreToggle) {
        podcastDisplayData = prevState.podcastData.slice(0, 5);
      }
      return ({
        showMoreToggle,
        podcastDisplayData,
      })
    });
  };

  render () {
    return (
      <Card>
        <Card.Header className="bg-white font-weight-bold">
          <FontAwesomeIcon
            className="mr-2"
            icon={faPodcast}
            color="#4fa2f3"
            style={{ fontSize: '1.2rem' }}
          />
          Podcasts They Listen To
        </Card.Header>
        <Card.Body>
          { this.state.podcastDataLoading &&
            <div className="m-4 text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !this.state.podcastDataLoading &&
            <div>
              { this.state.podcastDisplayData.map((podcastData, i) => {
                  return (
                    <div key={`aw-wd-${i}`}>
                      <div
                        className="d-inline-block text-right"
                        style={{ width: '150px' }}
                      >
                        {`${podcastData.share}% engage with`}
                      </div>
                      { podcastData.profile_image &&
                        <img
                          className="ml-2"
                          src={podcastData.profile_image}
                          alt=""
                          crossOrigin="anonymous"
                          style={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%'
                          }}
                        />
                      }
                      <a
                        className="ml-2"
                        href={podcastData.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {podcastData.title}
                      </a>
                    </div>
                  )
                })
              }
            </div>
          }
        </Card.Body>
        { (!this.state.podcastDataLoading && this.state.podcastData.length > 5) &&
          <Card.Footer className="bg-white text-center">
            <div
              onClick={() => this.onShowMoreToggle()}
              style={{ cursor: 'pointer' }}
            >
              {this.state.showMoreToggle ? 'Show Less': 'Show More'}
            </div>
          </Card.Footer>
        }
      </Card>
    );
  }
};
