const psl = require('psl');

export const getDomainFromUrl = (url) => {
  if (url && typeof url === 'string') {
    try {
      if (!psl.isValid(url)) {
        const urlSplit = url.split('/');
        if (urlSplit.length > 2) {
          url = urlSplit[2];
        }
      }
      const domain = psl.parse(url).domain;
      return domain;
    } catch (error) {
      console.log(error);
      return null;
    }
  } else {
    return null;
  }
};

export const prettyPrintDomainFromUrl = (url) => {
  if (url && typeof url === 'string') {
    try {
      const domain = psl.parse(url).domain;
      const domainName = domain.split('.')[0];
      return domainName.charAt(0).toUpperCase() + domainName.slice(1);
    } catch (error) {
      console.log(error);
      return url;
    }
  } else {
    return '';
  }
};
