import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import AddCategoryModal from './AddCategoryModal';
import AddBrandModal from './AddBrandModal';
import CategoryMenu from './CategoryMenu';
import CategoryForm from './CategoryForm';
import BrandForm from './BrandForm';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import LoginContainer from '../../containers/common/LoginContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faPlus, faTimes, faLock, faUnlink,  faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class Brands extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      marketStages: [],
      positionInStages: [],
      selectedCategory: undefined,
      selectedCategoryType: undefined,
      selectedBrand: undefined,
      selectedCategoryId: undefined,
      displayCategoryForm: false,
      displayBrandForm: false,
      addCategoryModalOpen: false,
      addBrandModalOpen: false,
      addBrandCategoryId: undefined,
      addBrandCategoryType: undefined,
      deleteConfirmationModalOpen: false,
      deleteResource: undefined,
      deleteMessage: undefined,
      deleteButtonLabel: 'Delete'
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchMarketStages();
    this.fetchPositionInStages();
    if (this.props.categories.length > 0) {
      this.onCategorySelect(this.props.categories[0]);
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.categories !== this.props.categories) {
      if (this.props.categories.length > 0) {
        this.onCategorySelect(this.props.categories[0]);
      } else {
        this.setState(() => ({
          selectedCategory: undefined,
          selectedCategoryType: undefined,
          selectedBrand: undefined,
          selectedCategoryId: undefined,
          displayCategoryForm: false,
          displayBrandForm: false,
        }));
      }
    }
  };

  fetchMarketStages = () => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/market-stages`,
      HEADERS
    ).then(response => {
      const marketStages = response.data;
      if (this.state.isMounted) {
        this.setState(() => ({ marketStages }));
      };
    }).catch(error => {
      console.error('Error: unable to fetch market stages');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  fetchPositionInStages = () => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/position-in-stages`,
      HEADERS
    ).then(response => {
      const positionInStages = response.data;
      if (this.state.isMounted) {
        this.setState(() => ({ positionInStages }));
      };
    }).catch(error => {
      console.error('Error: unable to fetch position in stages');
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  onCategorySelect = (category) => {
    if (this.state.selectedCategory !== category) {
      this.setState(() => ({
        selectedCategory: category,
        selectedCategoryType: category.category_type,
        selectedCategoryId: category.id,
        selectedBrand: undefined,
        displayCategoryForm: true,
        displayBrandForm: false
      }));
    }
  };

  onBrandSelect = (brand, category) => {
    if (this.state.selectedBrand !== brand) {
      this.setState(() => ({
        selectedCategory: undefined,
        selectedBrand: brand,
        selectedCategoryId: category.id,
        selectedCategoryType: category.category_type,
        displayCategoryForm: false,
        displayBrandForm: true
      }));
    }
  };

  openAddCategoryModal = () => {
    this.setState(() => ({ addCategoryModalOpen: true }));
  };

  closeAddCategoryModal = () => {
    this.setState(() => ({ addCategoryModalOpen: false }));
  };

  openAddBrandModalButton = () => {
    let addBrandCategoryId;
    let addBrandCategoryType;
    if (this.state.selectedCategory) {
      addBrandCategoryId = this.state.selectedCategory.id;
      if (this.state.selectedCategory.public_category_id) {
        addBrandCategoryType = 'extended';
      } else {
        addBrandCategoryType = 'private';
      }
    } else if (this.state.selectedBrand) {
      addBrandCategoryId = this.state.selectedBrand.category_id;
      addBrandCategoryType = this.state.selectedBrand.category_type;
    }

    if (addBrandCategoryId && addBrandCategoryType) {
      this.setState(() => ({
        addBrandModalOpen: true,
        addBrandCategoryId,
        addBrandCategoryType
      }));
    }
  };

  openAddBrandModal = (node, path) => {
    this.setState(() => ({
      addBrandModalOpen: true,
      addBrandCategoryId: node.category.id,
      addBrandCategoryType: node.category_type
    }));
  };

  closeAddBrandModal = () => {
    this.setState(() => ({
      addBrandModalOpen: false,
      addBrandCategoryId: undefined,
      addBrandCategoryType: undefined
    }));
  };

  openDeleteConfirmationModal = (node, path) => {
    if (node) {
      let deleteMessage;
      let deleteButtonLabel;
      if (node.type === 'category' && node.category_type === 'private') {
        deleteMessage = `This will delete your private category ${node.title}`;
        deleteButtonLabel = 'Delete';
      } else {
        deleteMessage = `This will remove your subscription to ${node.title} ${node.type}`;
        deleteButtonLabel = 'Remove';
      }
      this.setState(() => ({
        deleteConfirmationModalOpen: true,
        deleteResource: node,
        deleteMessage,
        deleteButtonLabel
      }));
    }
  };

  closeDeleteConfirmationModal = () => {
    this.setState(() => ({
      deleteConfirmationModalOpen: false,
      deleteResource: undefined,
      deleteMessage: undefined
    }));
  };

  deleteCategoryOrBrand = () => {
    if (this.state.deleteResource) {
      if (this.state.deleteResource.type === 'category') {
        let categoryTypePath;
        if (this.state.deleteResource.category_type === 'extended') {
          categoryTypePath = 'extended-categories';
        } else if (this.state.deleteResource.category_type === 'private') {
          categoryTypePath = 'private-categories';
        }

        if (categoryTypePath) {
          axios.delete(
            `${LISTEN_ENDPOINT}/api/${categoryTypePath}/${this.state.deleteResource.category.id}`,
            HEADERS
          ).then(response => {
            if (
              this.state.selectedCategory &&
              this.state.selectedCategory.id === this.state.deleteResource.category.id &&
              this.state.selectedCategoryType === this.state.deleteResource.category_type
            ) {
              // set state to trigger select first
              this.setState(() => ({
                selectedCategory: undefined,
                selectedCategoryType: undefined,
                selectedBrand: undefined,
                selectedCategoryId: undefined,
              }), () => this.props.fetchCategories(this.props.user.customerId));
            } else {
              this.props.fetchCategories(this.props.user.customerId);
            }
            this.closeDeleteConfirmationModal();
          }).catch(error => {
            console.log('Error: unable to delete category');
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
          });
        }
      } else if (this.state.deleteResource.type === 'brand') {
        axios.delete(
          `${LISTEN_ENDPOINT}/api/${this.state.deleteResource.brand.category_type}-category-product-brands/${this.state.deleteResource.brand.category_product_brand_id}`,
          HEADERS
        ).then(response => {
          if (this.state.selectedBrand && this.state.selectedBrand.id === this.state.deleteResource.brand.id) {
            // set state to trigger select first
            this.setState(() => ({
              selectedCategory: undefined,
              selectedCategoryType: undefined,
              selectedBrand: undefined,
              selectedCategoryId: undefined,
            }), () => this.props.fetchCategories(this.props.user.customerId));
          } else {
            this.props.fetchCategories(this.props.user.customerId);
          }
          this.closeDeleteConfirmationModal();
        }).catch(error => {
          console.log('Error: unable to delete product brand association');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  render () {
    return (
      <div className="p-4">
        { !(this.props.user && this.props.user.customerId) &&
          <LoginContainer />
        }
        { (this.props.user && this.props.user.customerId) &&
          <div>
            <div
              className="border rounded p-4"
              style={{ backgroundColor: '#e9ecef' }}
            >
              <div className="d-inline-block pr-2">
                <FontAwesomeIcon icon={faLayerGroup} size="2x" color="#454d54" />
              </div>
              <div className="d-inline-block">
                <h4>Category & Brand Setup</h4>
              </div>
              <p>
                Categories are used to group sets of competing brands together. After a category has been setup,
                you can quickly compares competing brands to each other. BrandOps has created several template
                categories that you can extend.
              </p>
              <hr className="my-4" />
              <p>
                Use the "Add Brand to Category" button to search our existing brands, or add new brands.
              </p>
            </div>
            <Row className="mt-4">
              <Col style={{ fontSize: '1rem', width: "30%" }}>
                {/*
                  <div>
                    <Button
                      className="mr-2"
                      variant="success"
                      onClick={this.openAddCategoryModal}
                    >
                      Add Category
                    </Button>
                    <Button
                      variant="success"
                      onClick={this.openAddBrandModalButton}
                    >
                      Add Brand to Category
                    </Button>
                  </div>
                */}
                <div
                  className="bg-white border rounded"
                  style={{
                    height: 'calc(100vh - 350px)',
                    overflowY: 'auto'
                  }}
                >
                  { this.props.categories.map((category, i) => {
                      return (
                        <CategoryMenu
                          key={`cs-cm-c-${category.id}`}
                          category={category}
                          selectedCategory={this.state.selectedCategory}
                          selectedBrand={this.state.selectedBrand}
                          onCategorySelect={this.onCategorySelect}
                          onBrandSelect={this.onBrandSelect}
                        />
                      )
                    })
                  }
                </div>
              </Col>
              <Col xs={12} md={6} lg={8}>
                <div
                  className="pre-scrollable"
                  style={{
                    minHeight: 'calc(100vh - 350px)',
                    maxHeight: 'calc(100vh - 350px)',
                    overflowY: 'auto'
                  }}
                >
                  { (this.state.displayCategoryForm && this.state.selectedCategory) &&
                    <CategoryForm
                      companyId={this.props.user.customerId}
                      marketStages={this.state.marketStages}
                      categories={this.props.categories}
                      selectedCategory={this.state.selectedCategory}
                      selectedCategoryType={this.state.selectedCategoryType}
                      fetchCompanyCategories={this.props.fetchCategories}
                    />
                  }
                  { (this.state.displayBrandForm && this.state.selectedBrand) &&
                    <BrandForm
                      companyId={this.props.user.customerId}
                      competitiveCategoryId={this.state.selectedCategoryId}
                      positionInStages={this.state.positionInStages}
                      selectedBrand={this.state.selectedBrand}
                      refresh={this.props.fetchCategories}
                      disabled={!(
                        this.state.selectedBrand.company_id === this.props.user.customerId ||
                        this.state.selectedBrand.sponsor_customer_id === this.props.user.customerId
                      )}
                    />
                  }
                </div>
              </Col>
            </Row>

            <AddCategoryModal
              isOpen={this.state.addCategoryModalOpen}
              handleClose={this.closeAddCategoryModal}
              marketStages={this.state.marketStages}
              companyId={this.props.user.customerId}
              refreshCompanyCategories={this.props.fetchCategories}
              categories={this.props.categories}
            />
            <AddBrandModal
              isOpen={this.state.addBrandModalOpen}
              handleClose={this.closeAddBrandModal}
              positionInStages={this.state.positionInStages}
              companyId={this.props.user.customerId}
              categories={this.props.categories}
              initialCategoryId={this.state.addBrandCategoryId}
              initialCategoryType={this.state.addBrandCategoryType}
              refreshCompanyCategories={this.props.fetchCategories}
            />
            <DeleteConfirmationModal
              isOpen={this.state.deleteConfirmationModalOpen}
              handleClose={this.closeDeleteConfirmationModal}
              deleteMessage={this.state.deleteMessage}
              deleteButtonLabel={this.state.deleteButtonLabel}
              deleteResource={this.deleteCategoryOrBrand}
            />
          </div>
        }
      </div>
    );
  }
};
