import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import ClipLoader from 'react-spinners/ClipLoader';
import DateRangePicker from '../../common/DateRangePicker';
import BrandChannelSummaryContainer from '../../../containers/brands/BrandChannelSummaryContainer';
import BrandMetricsByMonthTableContainer from '../../../containers/metrics/BrandMetricsByMonthTableContainer';
import GeneralSummaryGraph from '../../graphs/GeneralSummaryGraph';
import EmployerReviewScoresSummary from '../../reviews/EmployerReviewScoresSummary';
import EmployerReviewSiteScores from '../../reviews/EmployerReviewSiteScores';
import StarScoreBar from '../../graphs/StarScoreBar';
import SharePopUpContainer from '../../../containers/share/SharePopUpContainer';
import { sentimentWordsMap } from '../../../utils/sentiment';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class EmployerReviewsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      employerReviewVolumeData: [],
      employerReviewVolumeScoreType: undefined,
      employerReviewVolumeDataLoading: false,
      categoryAverageVolumeData: [],
      employerReviewScoreData: [],
      employerReviewScoreType: undefined,
      employerReviewScoreDataLoading: false,
      categoryAverageScoreData: [],
      employerReviews: [],
      employerReviewsLoading: false,
      filteredEmployerReviewSites: [],
      startDate: moment().utc().subtract(1, 'year').startOf('day'),
      endDate: moment().utc().endOf('day'),
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchEmployerReviews();
    this.fetchEmployerReviewVolumeData();
    this.fetchEmployerReviewScoreData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.customerId !== this.props.customerId ||
      prevProps.brand !== this.props.brand ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      this.fetchEmployerReviews();
      this.fetchEmployerReviewVolumeData();
      this.fetchEmployerReviewScoreData();
    }
    if (prevProps.allMonthsChecked !== this.props.allMonthsChecked) {
      this.fetchEmployerReviewVolumeData();
      this.fetchEmployerReviewScoreData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  fetchEmployerReviewVolumeData = () => {
    if (this.props.brand) {
      this.setState(() => ({ employerReviewVolumeDataLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/product-brands/${this.props.brand.id}/employer-review-volume-trend${allMonthsStartDateParam ? `?start_date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let employerReviewVolumeData = response.data.monthly_stats;
        const employerReviewVolumeScoreType = response.data.review_volume ? response.data.review_volume.score_type: undefined;
        // merge category average data into original data source
        employerReviewVolumeData = employerReviewVolumeData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAverageVolumeData[i]))
        });
        if (this.state.isMounted) {
          this.setState(() => ({
            employerReviewVolumeData,
            employerReviewVolumeScoreType,
            employerReviewVolumeDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch employer review volume data');
        if (this.state.isMounted) {
          this.setState(() => ({
            employerReviewVolumeData: [],
            employerReviewVolumeScoreType: undefined,
            employerReviewVolumeDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=employer_reviews_avg_volume${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAverageVolumeData = response.data.category_stats;
          // merge category average data into original data source
          const employerReviewVolumeData = this.state.employerReviewVolumeData.map((x, i) => {
            return (Object.assign({}, x, categoryAverageVolumeData[i]))
          });
          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAverageVolumeData,
              employerReviewVolumeData,
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average employer review volume data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAverageVolumeData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  fetchEmployerReviewScoreData = () => {
    if (this.props.brand) {
      this.setState(() => ({ employerReviewScoreDataLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/companies/${this.props.brand.company_id}/employer-review-score-trend${allMonthsStartDateParam ? `?start_date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let employerReviewScoreData = response.data.monthly_stats;
        const employerReviewScoreType = response.data.avg_review_score ? response.data.avg_review_score.score_type : undefined;
        // merge category average data into original data source
        employerReviewScoreData = employerReviewScoreData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAverageScoreData[i]))
        });
        if (this.state.isMounted) {
          this.setState(() => ({
            employerReviewScoreData,
            employerReviewScoreType,
            employerReviewScoreDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch employer review score data');
        if (this.state.isMounted) {
          this.setState(() => ({
            employerReviewScoreData: [],
            employerReviewScoreDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=employer_reviews_avg_score${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAverageScoreData = response.data.category_stats;
          // merge category average data into original data source
          const employerReviewScoreData = this.state.employerReviewScoreData.map((x, i) => {
            return (Object.assign({}, x, categoryAverageScoreData[i]))
          });
          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAverageScoreData,
              employerReviewScoreData
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average employer review score data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAverageScoreData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  fetchEmployerReviews = () => {
    if (this.props.brand) {
      this.setState(() => ({ employerReviewsLoading: true }));
      const formattedStartDate = this.state.startDate.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.state.endDate.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/employer-reviews?company_id=${this.props.brand.company_id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        const employerReviews = response.data;
        for (const review of employerReviews) {
          if (review.url) {
            if (review.url.includes('glassdoor.com')) {
              review.review_site_name = 'glassdoor';
              review.review_site_favicon_url = 'https://www.glassdoor.com/favicon.ico';
            } else if (review.url.includes('indeed.com')) {
              review.review_site_name = 'indeed';
              review.review_site_favicon_url = 'https://www.indeed.com/images/favicon.ico';
            }
          }
          let textDisplay = review.text;
          const words = review.text.split(/\s+/);
          const replacedMap = {};
          for (const word of words) {
            if (
              sentimentWordsMap[word.toLowerCase()] != null &&
              !replacedMap[word]
            ) {
              try {
                const regex = new RegExp('(\\s+)' + word + '((\\s+)|([.,!?:;\"\')]))', 'g');
                textDisplay = textDisplay.replaceAll(
                  regex,
                  (regexMatch) => {
                    return regexMatch.replace(word, `<mark style="background-color:#ffc107">${word}</mark>`);
                  }
                );
                replacedMap[word] = true;
              } catch (error) {
                // do nothing
              }
            }
          }
          review.text_display = textDisplay;
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            employerReviews,
            employerReviewsLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch employer reviews');
        if (this.state.isMounted) {
          this.setState(() => ({
            employerReviews: [],
            employerReviewsLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  filterEmployerReviews = (reviewsData) => {
    let filteredEmployerReviewSites = [];
    for (const reviewSummary of reviewsData) {
      if (reviewSummary.checked == true) {
        filteredEmployerReviewSites.push(reviewSummary.review_site_name);
      }
    }
    this.setState(() => ({ filteredEmployerReviewSites }));
  }

  onFilterDatesChange = (startDate, endDate) => {
    this.setState(() => ({
      startDate,
      endDate
    }));
  }

  render () {
    return (
      <div>
        <div>
          <BrandChannelSummaryContainer
            channel="Employer Reviews"
            category={this.props.category}
            brand={this.props.brand}
          />
        </div>
        <div>
          <h5 className="my-2 p-2 bg-bops-blue text-light">
            Metric Trends
            <div
              className="d-inline-block ml-4"
              style={{ fontSize: '1rem', fontWeight: 'normal' }}
            >
              <Form.Check
                id="employerReviewsAllMonthsCheckbox"
                className="ml-2"
                type="checkbox"
                label="All Months"
                checked={this.props.allMonthsChecked}
                onChange={(event) => this.props.updateAllMonthsChecked(event.currentTarget.checked)}
                inline
              />
            </div>
          </h5>
          <Row>
            <Col xs={12} lg={6}>
              <GeneralSummaryGraph
                title="Number of Employer Reviews"
                metricName="employer_reviews_count_all"
                data={this.state.employerReviewVolumeData}
                dataKey="review_volume"
                dataLabel="Employer Reviews"
                scoreType={this.state.employerReviewVolumeScoreType}
                loading={this.state.employerReviewVolumeDataLoading}
                allowDecimals={false}
                showPercentChange={true}
                showAverageData={true}
                averageDataKey="employer_reviews_avg_volume"
                averageDataLabel="Category Average"
                showTooltip={true}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Employer Reviews"
                contextChartName="Number of Employer Reviews"
                shareClassName="employer-reviews-img"
              />
            </Col>
            <Col xs={12} lg={6}>
              <GeneralSummaryGraph
                title="Average Employer Review Scores"
                metricName="employer_reviews_stars"
                data={this.state.employerReviewScoreData}
                dataKey="avg_review_score"
                dataLabel="Average Review Score"
                scoreType={this.state.employerReviewScoreType}
                loading={this.state.employerReviewScoreDataLoading}
                showPercentChange={true}
                minDomainValue={0}
                maxDomainValue={5}
                showAverageData={true}
                averageDataKey="employer_reviews_avg_score"
                averageDataLabel="Category Average"
                showTooltip={true}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Employer Reviews"
                contextChartName="Average Employer Review Scores"
                shareClassName="employer-reviews-avg-scores-img"
              />
            </Col>
          </Row>
        </div>
        <div>
          <BrandMetricsByMonthTableContainer
            type="Employer Reviews"
            siteName="Employer-Reviews"
            category={this.props.category}
            brand={this.props.brand}
            audienceProfileId={this.props.category.audience_profile_id}
          />
        </div>
        <h5 className="my-2 p-2 bg-bops-blue text-light">
          Details
          <span
            className="ml-4 mr-2"
            style={{ fontSize: '1rem', fontWeight: 'normal' }}
          >Date Range</span>
          <DateRangePicker
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            updateDates={this.onFilterDatesChange}
          />
        </h5>
        <div>
          <Row>
            <Col xs={12} md={6}>
              <EmployerReviewScoresSummary
                category={this.props.category}
                brand={this.props.brand}
                startDateFilter={this.state.startDate}
                endDateFilter={this.state.endDate}
              />
            </Col>
            <Col xs={12} md={6}>
              <EmployerReviewSiteScores
                category={this.props.category}
                brand={this.props.brand}
                startDateFilter={this.state.startDate}
                endDateFilter={this.state.endDate}
                filterEmployerReviews={this.filterEmployerReviews}
              />
            </Col>
          </Row>
        </div>
        <div className="mt-4">
          { this.state.employerReviewsLoading &&
            <div className="text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !this.state.employerReviewsLoading &&
            <div>
              { this.state.employerReviews.map((review, i) => {
                  if (this.state.filteredEmployerReviewSites.includes(review.review_site_name)) {
                    return (
                      <Card
                        id={`er-review-${i}`}
                        key={`er-${review.id}`}
                        className="mb-4"
                      >
                        <Card.Body>
                          <Row noGutters>
                            <Col style={{ flex: '0 0 40px' }}>
                              { review.url &&
                                <div className="mt-2">
                                  <img
                                    src={review.review_site_favicon_url}
                                    alt=""
                                    onClick={() => window.open(review.url, '_blank')}
                                    style={{
                                      width: '30px',
                                      cursor: 'pointer'
                                    }}
                                  />
                                </div>
                              }
                            </Col>
                            <Col>
                              <div>
                                { review.title &&
                                  <div className="d-inline-block mr-4">
                                    {review.title}
                                  </div>
                                }
                                { review.reviewer &&
                                  <div
                                    className="d-inline-block mr-4 align-bottom text-bops-blue"
                                    style={{
                                      fontSize: '.875rem',
                                      maxWidth: '200px',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis'
                                    }}
                                  >
                                    {review.reviewer}
                                  </div>
                                }
                                <div className="d-inline-block float-right">
                                  <SharePopUpContainer
                                    shareElementId={`er-review-${i}`}
                                    elementLabel={`er-review-${i}-img`}
                                    position="top"
                                    contextType="brand"
                                    contextCategory={this.props.category}
                                    contextBrand={this.props.brand}
                                    contextProperty="Employer Review"
                                    contextChartName="Employer Review"
                                  />
                                </div>
                              </div>
                              <div>
                                {moment(review.review_date).format('MM/DD/YY')}
                              </div>
                            </Col>
                          </Row>
                          { review.rating != null &&
                            <div className="mt-2">
                              <StarScoreBar
                                score={review.rating}
                                maxScore={1}
                                starColor="gold"
                              />
                            </div>
                          }
                          <pre
                            className="mt-4"
                            style={{
                              maxWidth: 'calc(100vw - 400px)',
                              fontFamily: 'inherit',
                              fontSize: 'unset',
                              whiteSpace: 'pre-wrap',
                              wordWrap: 'break-word'
                            }}
                            dangerouslySetInnerHTML={{ __html: review.text_display }}
                          />
                        </Card.Body>
                      </Card>
                    )
                  }
                })
              }
            </div>
          }
        </div>
      </div>
    );
  }
};
