import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import ClipLoader from 'react-spinners/ClipLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTasks } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import history from '../../../routers/history';
import { dispatchReportError } from '../../../actions/api/errors';

export default class CategorySetupChecklist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCategoryId: undefined,
      selectedCategoryName: undefined,
      categorySetupChecklistData: [],
      channelSites: [],
      loading: false
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.categories.length > 0) {
      this.onCategoryChange({
        currentTarget: {
          value: `${this.props.categories[0].category_type}-${this.props.categories[0].id}`
        }
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevProps.categories !== this.props.categories
    ) {
      if (this.props.categories.length > 0) {
        this.onCategoryChange({
          currentTarget: {
            value: `${this.props.categories[0].category_type}-${this.props.categories[0].id}`
          }
        });
      } else {
        this.setState(() => ({
          categorySetupChecklistData: [],
          loading: false
        }));
      }
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  onCategoryChange = (event) => {
    const selectedCategoryId = event.currentTarget.value;
    let selectedCategoryName;
    let brands = [];
    for (const category of this.props.categories) {
      if (selectedCategoryId === `${category.category_type}-${category.id}`) {
        selectedCategoryName = category.name;
        brands = category.product_brands;
      }
    }
    this.setState(() => ({
      selectedCategoryId,
      selectedCategoryName,
      brands,
    }), () => this.fetchCategorySetupChecklistData());
  };

  fetchCategorySetupChecklistData = () => {
    if (this.state.selectedCategoryId) {
      this.setState(() => ({ loading: true }));
      const categoryIdSplit = this.state.selectedCategoryId.split('-');
      const categoryType = categoryIdSplit[0];
      const categoryId = categoryIdSplit[1];
      const checklistDataRequests = [];
      for (const brand of this.state.brands) {
        checklistDataRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/pb-setup-checklist?product_brand_id=${brand.id}&category_type=${categoryType}&category_id=${categoryId}`,
            HEADERS
          ).then(response => {
            const productBrandChecklistData = response.data;
            return { productBrandChecklistData };
          }).catch(error => {
            console.error(`Error: failed to fetch setup checklist for ${brand.name}...`);
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return {};
          })
        );
      }

      Promise.all(checklistDataRequests).then(responses => {
        const categorySetupChecklistData = [];
        for (const response of responses) {
          if (response.productBrandChecklistData) {
            categorySetupChecklistData.push(response.productBrandChecklistData);
          }
        }
        const channelSites = [];
        if (categorySetupChecklistData.length > 0) {
          for (const channelSite of Object.keys(categorySetupChecklistData[0].checklist_data)) {
            if (
              channelSite !== 'Customer' &&
              channelSite !== 'SERP-API' &&
              !channelSite.includes('All-')
            ) {
              channelSites.push(channelSite);
            }
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            categorySetupChecklistData,
            channelSites,
            loading: false
          }));
        }
      });
    }
  };

  onChecklistClick = (brand, channelSite) => {
    const queryParams = `?category=${encodeURIComponent(this.state.selectedCategoryName)}&brand=${encodeURIComponent(brand.name)}`;
    switch (channelSite) {
      case 'Campaigns':
        history.push(`/content-collectors/email-campaign`);
        break;
      case 'Email Collector':
        history.push(`/content-collectors/email${queryParams}`);
        break;
      case 'Google Analytics':
        history.push(`/content-collectors/web-analytics`);
        break;
      case 'Industry Analysts':
        history.push(`/content-collectors/affirmations${queryParams}`);
        break;
      case 'SERP-API':
        history.push(`/content-collectors/search-engine-terms${queryParams}`);
        break;
      case 'Survey':
        history.push(`/content-collectors/surveys`);
        break;
    }
  };

  render () {
    return (
      <div className="m-4">
        { this.props.user && this.props.user.customerId &&
          <div>
            <div
              className="border rounded p-4"
              style={{ backgroundColor: '#e9ecef' }}
            >
              <div className="d-inline-block pr-2">
                <FontAwesomeIcon icon={faTasks} size="2x" color="#454d54" />
              </div>
              <div className="d-inline-block">
                <h4>Checklist</h4>
              </div>
              <p>
                Use this handy checklist matrix to manage the setup of collectors for the brands in your categories.
              </p>
              <hr className="my-4" />
              <p>
                Checked boxes indicate that the collector has been setup. Click on an empty box to navigate
                directly to that channel's collector.
              </p>
            </div>
            <div className="mt-4">
              <Form.Control
                as="select"
                value={this.state.selectedCategoryId}
                onChange={this.onCategoryChange}
                style={{
                  minWidth: '200px',
                  maxWidth: '300px'
                }}
              >
                { this.props.categories.map(category => {
                    return (
                      <option
                        key={`${category.category_type}-${category.id}`}
                        value={`${category.category_type}-${category.id}`}
                      >
                        {category.name}
                      </option>
                    )
                  })
                }
              </Form.Control>
            </div>
            { this.state.loading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { (!this.state.loading && this.state.selectedCategoryId) &&
              <div>
                <div className="mt-4">
                  <div className="mb-2">
                    <b>Category Setup</b>
                  </div>
                  <Row className="mb-4 pl-3">
                  { this.state.categorySetupChecklistData.length>0 &&
                    <div>
                      <div
                        className="text-center d-inline-block"
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onChecklistClick(this.state.categorySetupChecklistData[0].product_brand, 'SERP-API')}
                      >
                      { this.state.categorySetupChecklistData[0].checklist_data['SERP-API'] &&
                        <FontAwesomeIcon
                          className="text-success"
                          icon={faCheck}
                        />
                      }
                      { !this.state.categorySetupChecklistData[0].checklist_data['SERP-API'] &&
                        <div
                          className="d-inline-block border border-dark bg-white align-middle"
                          style={{
                            height: '20px',
                            width: '20px'
                          }}
                        >
                        </div>
                      }
                      </div>
                      <div className="d-inline-block pl-2 pr-4">
                        Search Terms
                      </div>
                    </div>
                  }
                  </Row>

                  <div className="mb-2">
                    <b>Brand Setup Checklist</b>
                  </div>
                  <div
                    className="pre-scrollable"
                    style={{
                      maxWidth: 'calc(100vw - 280px)',
                      maxHeight: 'calc(100vh - 500px)',
                      overflowY: 'auto'
                    }}
                  >
                    <Table striped bordered>
                      <thead>
                        <tr>
                          <th></th>
                          { this.state.categorySetupChecklistData.map(brandSetup => {
                              return (
                                <th key={`th-pb-${brandSetup.product_brand.id}`}>
                                  {brandSetup.product_brand.name}
                                </th>
                              )
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        { this.state.channelSites.map((channelSite, i) => {
                            return (
                              <tr key={`csc-cs-${i}`}>
                                <td>{channelSite}</td>
                                { this.state.categorySetupChecklistData.map(brandSetup => {
                                    return (
                                      <td key={`td-li-${brandSetup.product_brand.id}`}>
                                        { brandSetup.checklist_data[channelSite].system_setup &&
                                          <div className="text-center">
                                            <FontAwesomeIcon
                                              className="text-secondary"
                                              icon={faCheck}
                                            />
                                          </div>
                                        }
                                        { !brandSetup.checklist_data[channelSite].system_setup &&
                                          <div
                                            className="text-center"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => this.onChecklistClick(brandSetup.product_brand, channelSite)}
                                          >
                                            { brandSetup.checklist_data[channelSite].setup &&
                                              <FontAwesomeIcon
                                                className="text-success"
                                                icon={faCheck}
                                              />
                                            }
                                            { !brandSetup.checklist_data[channelSite].setup &&
                                              <div
                                                className="d-inline-block border border-dark bg-white align-middle"
                                                style={{
                                                  height: '20px',
                                                  width: '20px'
                                                }}
                                              >
                                              </div>
                                            }
                                          </div>
                                        }
                                      </td>
                                    )
                                  })
                                }
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            }
          </div>
        }
      </div>
    );
  }
};
