import React from 'react';
import axios from 'axios';
import moment from 'moment';
import ShareOfChart from '../../graphs/ShareOfChart';
import { round } from '../../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class CategoryInstagramFollowerShareOfGraphs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      followersShareOfGraphData: {},
      categoryFollowersShareOfData: [],
      categoryFollowersShareOfDataLoading: false,
      netFollowersShareOfGraphData: {},
      categoryNetFollowersShareOfData: [],
      categoryNetFollowersShareOfDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getCategoryFollowersShareOfData();
    this.getCategoryNetFollowersShareOfData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.getCategoryFollowersShareOfData();
      this.getCategoryNetFollowersShareOfData();
    }
  }

  getCategoryFollowersShareOfData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryFollowersShareOfDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-instagram-share-of-followers?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const followersShareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const categoryFollowersShareOfData = [];
        for (const monthStats of response.data) {
          const followersMonthData = { month: monthStats.month };
          let monthTotalFollowers = 0;

          // get totals for percent
          for (const pb of monthStats.product_brands) {
            monthTotalFollowers += pb.followers;
            // insert graph data
            followersShareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.followers,
            ]);
          }

          // loop again to determine %
          for (const pb of monthStats.product_brands) {
            let followersPercent = 0;
            if (monthTotalFollowers > 0) {
              followersPercent = round((pb.followers/monthTotalFollowers)*100, 1);
            }
            followersMonthData[pb.product_brand_name] = {
              count: pb.followers,
              percent: followersPercent
            }
          }
          categoryFollowersShareOfData.push(followersMonthData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            followersShareOfGraphData,
            categoryFollowersShareOfData,
            categoryFollowersShareOfDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch category share of instagram followers...');
        if (this.state.isMounted) {
          this.setState(() => ({
            followersShareOfGraphData: {},
            categoryFollowersShareOfData: [],
            categoryFollowersShareOfDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  }

  getCategoryNetFollowersShareOfData = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryNetFollowersShareOfDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-instagram-share-of-net-followers?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const netFollowersShareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const categoryNetFollowersShareOfData = [];
        for (const monthStats of response.data) {
          const netFollowersMonthData = { month: monthStats.month };
          let monthNetNewFollowers = 0;

          // get totals for percent
          for (const pb of monthStats.product_brands) {
            monthNetNewFollowers += pb.net_followers;
            // insert graph data
            netFollowersShareOfGraphData.records.push([
              monthStats.month,
              pb.product_brand_name,
              pb.net_followers || 0,
            ]);
          }

          // loop again to determine %
          for (const pb of monthStats.product_brands) {
            let netNewFollowersPercent = 0;
            if (monthNetNewFollowers > 0) {
              netNewFollowersPercent = round((pb.net_followers/monthNetNewFollowers)*100, 1);
            }
            netFollowersMonthData[pb.product_brand_name] = {
              count: pb.net_followers || 0,
              percent: netNewFollowersPercent
            }
          }
          categoryNetFollowersShareOfData.push(netFollowersMonthData);
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            netFollowersShareOfGraphData,
            categoryNetFollowersShareOfData,
            categoryNetFollowersShareOfDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch category share of instagram net new followers...');
        if (this.state.isMounted) {
          this.setState(() => ({
            netFollowersShareOfGraphData: {},
            categoryNetFollowersShareOfData: [],
            categoryNetFollowersShareOfDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  }

  render () {
    return (
      <div>
        <ShareOfChart
          title="Share of Followers"
          data={this.state.followersShareOfGraphData}
          legendData={this.state.categoryFollowersShareOfData}
          loading={this.state.categoryFollowersShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="Instagram"
          contextChartName="Share of Followers"
          shareClassName="category-instagram-share-of-followers-img"
        />
        <ShareOfChart
          title="Share of Net New Followers"
          data={this.state.netFollowersShareOfGraphData}
          legendData={this.state.categoryNetFollowersShareOfData}
          loading={this.state.categoryNetFollowersShareOfDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Social"
          contextProperty="Instagram"
          contextChartName="Share of Net New Followers"
          shareClassName="category-instagram-share-of-net-new-followers-img"
        />
      </div>
    );
  }
};
