import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClipLoader from 'react-spinners/ClipLoader';
import { getDomainFromUrl } from '../../utils/urls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class UrlPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      urlPreviewData: {},
      loading: false
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchUrlPreviewData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchUrlPreviewData = () => {
    if (this.props.url) {
      this.setState(() => ({ loading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/url-preview?url=${this.props.url}`,
        HEADERS
      ).then(response => {
        const urlPreviewData = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            urlPreviewData,
            loading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch url for preview');
        if (this.state.isMounted) {
          this.setState(() => ({ loading: false }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        { this.state.urlPreviewData.url &&
          <div
            className="border rounded"
            onClick={() => window.open(this.state.urlPreviewData.url, '_blank')}
            style={{
              cursor: 'pointer'
            }}
          >
            { this.state.urlPreviewData.type === 'twitter' &&
              <div className="p-2">
                { this.state.urlPreviewData.image &&
                  <div className="d-inline-block">
                    <img
                      src={this.state.urlPreviewData.image}
                      alt=""
                      crossOrigin="anonymous"
                      style={{
                        width: '40px'
                      }}
                    />
                  </div>
                }
                <div
                  className="d-inline-block ml-2"
                  style={{
                    color: '#a6a6a6',
                    fontSize: '.875rem',
                  }}
                >
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={faLink}
                  />
                  {getDomainFromUrl(this.state.urlPreviewData.url)}
                </div>
              </div>
            }
            { this.state.urlPreviewData.type !== 'twitter' &&
              <div>
                { this.state.urlPreviewData.image &&
                  <div className="text-center">
                    <img
                      src={this.state.urlPreviewData.image}
                      alt=""
                      crossOrigin="anonymous"
                      width="100%"
                    />
                  </div>
                }
                <div className="px-2">
                  { this.state.urlPreviewData.title &&
                    <div>
                      <b>{this.state.urlPreviewData.title}</b>
                    </div>
                  }
                  <div
                    className="pb-2"
                    style={{
                      color: '#a6a6a6',
                      fontSize: '.875rem',
                    }}
                  >
                    { this.state.urlPreviewData.description &&
                      <div
                        style={{
                          maxHeight: '46px',
                          overflow: 'hidden'
                        }}
                      >
                        {this.state.urlPreviewData.description}
                      </div>
                    }
                    <div>
                      <FontAwesomeIcon
                        className="mr-2"
                        icon={faLink}
                      />
                      {getDomainFromUrl(this.state.urlPreviewData.url)}
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        }
      </div>
    );
  }
};
