import React from 'react';
import moment from 'moment';
import Card from 'react-bootstrap/Card';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faReddit } from '@fortawesome/free-brands-svg-icons';

export default class RedditComment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {

  }

  addBrandNameHighlights = (text) => {
    if (this.props.brand && text) {
      // mark/highlight brand name within text
      const regex = new RegExp(this.props.brand.name, 'ig');
      return text.replaceAll(
        regex,
        `<mark style="background-color:#ffc107">$&</mark>`
      );
    } else {
      return text;
    }
  }

  render () {
    return (
      <Card id={`reddit-comment-${this.props.comment._id}`} className="mb-4" style={{ fontSize: '.875rem' }}>
        { (this.props.comment && this.props.comment._source) &&
          <Card.Body>
            <div>
              <FontAwesomeIcon
                className="pt-1 pr-2"
                icon={faReddit}
                color="#FF4500"
                style={{ fontSize: '1.6rem' }}
              />
              <a
                className="mr-2"
                href={`https://reddit.com/r/${this.props.comment._source.subreddit}`}
                target="_blank"
              >
                {`/r/${this.props.comment._source.subreddit}`}
              </a>
              {this.props.comment._source.author &&
                <span className="mr-4">
                  {`Comment by: ${this.props.comment._source.author}`}
                </span>
              }
              <span>
                {moment.utc(this.props.comment._source.published_date).format('MM/DD/YY')}
              </span>
              <div className="d-inline-block float-right">
                <SharePopUpContainer
                  shareElementId={`reddit-comment-${this.props.comment._id}`}
                  elementLabel={`reddit-comment-${this.props.comment._id}-img`}
                  position="top"
                  contextType="brand"
                  contextCategory={this.props.category}
                  contextBrand={this.props.brand}
                  contextChannel="Social"
                  contextProperty="Reddit"
                  contextChartName="Comment"
                />
              </div>
              <div className="d-inline-block mr-2 float-right">
                <FontAwesomeIcon
                  icon={faLink}
                  color="#4fa2f3"
                  onClick={() => window.open(this.props.comment._source.url, '_blank')}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <hr />
            </div>
            {this.props.comment._source.content &&
              <pre
                dangerouslySetInnerHTML={{__html: this.addBrandNameHighlights(this.props.comment._source.content)}}
                style={{
                  maxWidth: 'calc(100vw - 400px)',
                  fontFamily: 'inherit',
                  fontSize: 'unset',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word'
                }}
              />
            }
          </Card.Body>
        }
      </Card>
    );
  }
};
