import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GeneralSummaryGraph from '../../graphs/GeneralSummaryGraph';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class GoogleAnalyticsBuyingSignalsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      googleAnalyticTrendData: {},
      googleAnalyticTrendDataLoading: false,
    }
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchGoogleAnalyticsTrendData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.companyId !== this.props.companyId) {
      this.fetchGoogleAnalyticsTrendData();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchGoogleAnalyticsTrendData = () => {
    if (this.props.companyId) {
      this.setState(() => ({ googleAnalyticTrendDataLoading: true }));
      const googleAnalyticTrendRequests = [];
      const metricTypes = [
        'web_ga_pricing_page_views',
        'web_ga_contact_us_page_views',
      ];
      for (const metricType of metricTypes) {
        googleAnalyticTrendRequests.push(
          axios.get(
            `${LISTEN_ENDPOINT}/api/ga-score-trend?company_id=${this.props.companyId}&metric_type=${metricType}`,
            HEADERS
          ).then(response => {
            return { [metricType]: response.data };
          }).catch(error => {
            console.error(`Error: unable to fetch google analytic ${metricType} data...`);
            if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
              dispatchReportError(error.response);
            }
            return { [metricType]: [] };
          })
        );
      }

      Promise.all(googleAnalyticTrendRequests).then(responses => {
        const googleAnalyticTrendData = {};
        for (const response of responses) {
          for (const [key, value] of Object.entries(response)) {
            googleAnalyticTrendData[key] = value;
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            googleAnalyticTrendData,
            googleAnalyticTrendDataLoading: false
          }));
        }
      });
    }
  };

  render () {
    return (
      <div className="my-2">
        <Row>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Pricing Page Views"
                metricName="web_ga_pricing_page_views"
                data={this.state.googleAnalyticTrendData.web_ga_pricing_page_views || []}
                dataKey="total_visitors"
                dataLabel="Pricing Page Views"
                loading={this.state.googleAnalyticTrendDataLoading}
                allowDecimals={false}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Web Analytics"
                contextChartName="Pricing Page Views"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Contact Us Page Views"
                metricName="web_ga_contact_us_page_views"
                data={this.state.googleAnalyticTrendData.web_ga_contact_us_page_views || []}
                dataKey="total_visitors"
                dataLabel="Contact Us Page Views"
                loading={this.state.googleAnalyticTrendDataLoading}
                allowDecimals={false}
                contextType="brand"
                contextCategory={this.props.category}
                contextBrand={this.props.brand}
                contextChannel="Web Analytics"
                contextChartName="Contact Us Page Views"
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};
