import React from 'react';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

export default class FacebookResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  parseURLs = (text) => {
    return text.replace(/[A-Za-z]+:\/\/[A-Za-z0-9-_]+\.[A-Za-z0-9-_:%&~\?\/.=]+/g, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };

  parseTextForHTMLReplacements = (text) => {
    text = this.parseURLs(text);
    return text;
  };

  render () {
    return (
      <div
        className="mb-2 bg-white border rounded"
        style={{
          fontSize: '.875rem'
        }}
      >
        <Row noGutters className="p-2">
          <Col md={9} sm={12} className="px-4 py-2">
            <div>
              <div
                className="d-inline"
                dangerouslySetInnerHTML={{__html: this.parseTextForHTMLReplacements(this.props.comment.text)}}
              />
            </div>
          </Col>
          <Col md={3} sm={12} className="px-4 py-2 border-left">
            <Row>
              <Col>
                {moment.utc(this.props.comment.posted_at).local().format('MM/DD/YYYY h:mm:ss A')}
                <FontAwesomeIcon
                    className="float-right"
                    icon={faFacebook}
                    color="#3b5998"
                    size="lg"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
};
