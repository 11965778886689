import React from 'react';
import BrandTabOverview from '../BrandTabOverview';
import Imagery from '../../identity/Imagery';
import Fonts from '../../identity/Fonts';
import KeyPhrases from '../../messages/KeyPhrases';
import WebHeadersTab from '../../messages/WebHeadersTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages, faFont, faComment, faHeading } from '@fortawesome/free-solid-svg-icons';
import { createUserActivityLog } from '../../../utils/users';
import history from '../../../routers/history';

export default class ImageryTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'imagery',
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let subTabIndex = hash.indexOf('-');
      let selectedTab = subTabIndex > 0 ? hash.substring(subTabIndex+1) : 'imagery';
      this.setState(() => ({ selectedTab }));
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  changeSelectedTab = (selectedTab, subTabName) => {
    history.push(`#identity-${selectedTab}`);
    this.setState(() => ({ selectedTab }));
    if (subTabName) {
      createUserActivityLog(
        this.props.user,
        `Brand Tabs: Identity: ${subTabName}`,
        this.props.category,
        this.props.brand,
      )
    }
  };

  render () {
    return (
      <div className="p-4">
        <div className="mb-4">
          <button
            type="button"
            className= {
              this.state.selectedTab === 'imagery' ?
                'btn border-primary bg-white mr-1' :
                'btn border bg-white mr-1'
            }
            onClick={(e) => this.changeSelectedTab('imagery', 'Imagery')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon
                icon={faImages}
                color="#6c757d"
              />
            </div>
            <div className="d-inline-block">
              Imagery
            </div>
          </button>
          <button
            type="button"
            className= {
              this.state.selectedTab === 'fonts' ?
                'btn border-primary bg-white mr-1' :
                'btn border bg-white mr-1'
            }
            onClick={(e) => this.changeSelectedTab('fonts', 'Fonts')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon
                icon={faFont}
                color="#6c757d"
              />
            </div>
            <div className="d-inline-block">
              Fonts
            </div>
          </button>
          <button
            type="button"
            className= {
              this.state.selectedTab === 'keyPhrases' ?
                'btn border-primary bg-white mr-1' :
                'btn border bg-white mr-1'
            }
            onClick={(e) => this.changeSelectedTab('keyPhrases', 'Key Phrases')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon
                icon={faComment}
                color="#6c757d"
              />
            </div>
            <div className="d-inline-block">
              Key Phrases
            </div>
          </button>
          <button
            type="button"
            className= {
              this.state.selectedTab === 'webHeaders' ?
                'btn border-primary bg-white mr-1' :
                'btn border bg-white mr-1'
            }
            onClick={(e) => this.changeSelectedTab('webHeaders', 'Web Headers')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon
                icon={faHeading}
                color="#6c757d"
              />
            </div>
            <div className="d-inline-block">
              Web Headers
            </div>
          </button>
        </div>

        <div
          className="pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 200px)',
            maxHeight: 'calc(100vh - 200px)',
            overflow: 'auto',
            overflowX: 'hidden'
          }}
        >
          { this.state.selectedTab === 'imagery' &&
            <div>
              <BrandTabOverview
                overviewType="Imagery"
              />
              <Imagery
                location={this.props.location}
                categoryId={this.props.categoryId}
                categoryType={this.props.categoryType}
                category={this.props.category}
                brandId={this.props.brandId}
                brand={this.props.brand}
                brandName={this.props.brand.name}
              />
            </div>
          }
          { this.state.selectedTab === 'fonts' &&
            <div>
              <BrandTabOverview
                overviewType="Fonts"
              />
              <Fonts
                location={this.props.location}
                categoryId={this.props.categoryId}
                categoryType={this.props.categoryType}
                category={this.props.category}
                brandId={this.props.brandId}
                brand={this.props.brand}
                brandName={this.props.brand.name}
              />
            </div>
          }
          { this.state.selectedTab === 'keyPhrases' &&
            <div>
              <BrandTabOverview
                overviewType="Key Phrases"
              />
              <KeyPhrases
                location={this.props.location}
                sixMonths={this.props.sixMonths}
                selectedMonth={this.props.selectedMonth}
                updateSelectedMonth={this.props.updateSelectedMonth}
                categoryId={this.props.categoryId}
                categoryType={this.props.categoryType}
                category={this.props.category}
                brandId={this.props.brandId}
                brand={this.props.brand}
                brandName={this.props.brand.name}
              />
            </div>
          }
          { this.state.selectedTab === 'webHeaders' &&
            <div>
              <BrandTabOverview
                overviewType="Web Headers"
              />
              <WebHeadersTab
                location={this.props.location}
                categoryId={this.props.categoryId}
                categoryType={this.props.categoryType}
                category={this.props.category}
                brandId={this.props.brandId}
                brand={this.props.brand}
                brandName={this.props.brand.name}
              />
            </div>
          }
        </div>
      </div>
    );
  }
};
