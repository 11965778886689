import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import CardComponent from '../common/CardComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '../../utils/numbers';
import ClipLoader from 'react-spinners/ClipLoader';
import ReviewSummaryBar from './ReviewSummaryBar';
import StarScoreBar from '../graphs/StarScoreBar';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class ReviewScoresSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reviewsData: {},
      reviewsDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.loadReviewsData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.topicId !== this.props.topicId ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter
    ) {
      this.loadReviewsData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  loadReviewsData = () => {
    this.setState(() => ({ reviewsDataLoading: true }));
    const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
    axios.get(
      `${LISTEN_ENDPOINT}/api/topics/${this.props.topicId}/review-scores-summary?start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
      HEADERS
    ).then(response => {
      const reviewsData = response.data;
      if (reviewsData.total_reviews > 0) {
        reviewsData.five_star_percent = (reviewsData.five_star_reviews / reviewsData.total_reviews) * 100;
        reviewsData.four_star_percent = (reviewsData.four_star_reviews / reviewsData.total_reviews) * 100;
        reviewsData.three_star_percent = (reviewsData.three_star_reviews / reviewsData.total_reviews) * 100;
        reviewsData.two_star_percent = (reviewsData.two_star_reviews / reviewsData.total_reviews) * 100;
        reviewsData.one_star_percent = (reviewsData.one_star_reviews / reviewsData.total_reviews) * 100;
      } else {
        reviewsData.five_star_percent = 0;
        reviewsData.four_star_percent = 0;
        reviewsData.three_star_percent = 0;
        reviewsData.two_star_percent = 0;
        reviewsData.one_star_percent = 0;
      }

      if (this.state.isMounted) {
        this.setState(() => ({
          reviewsData,
          reviewsDataLoading: false
        }));
      }
    }).catch(error => {
      if (this.state.isMounted) {
        this.setState(() => ({
          reviewsData: {
            error: true
          },
          reviewsDataLoading: false
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  render () {
    return (
      <CardComponent
        title="Review Summary"
        body={
          <div>
            { this.state.reviewsDataLoading &&
              <div className="text-center">
                <ClipLoader size={100}/>
              </div>
            }
            { !this.state.reviewsDataLoading &&
              <div>
                { !this.state.reviewsData.error &&
                  <Row noGutters>
                    <Col xs={9}>
                      <div>
                        <ReviewSummaryBar
                          starNumber="5"
                          reviewsPercent={this.state.reviewsData.five_star_percent}
                          reviewsCount={this.state.reviewsData.five_star_reviews}
                        />
                        <ReviewSummaryBar
                          starNumber="4"
                          reviewsPercent={this.state.reviewsData.four_star_percent}
                          reviewsCount={this.state.reviewsData.four_star_reviews}
                        />
                        <ReviewSummaryBar
                          starNumber="3"
                          reviewsPercent={this.state.reviewsData.three_star_percent}
                          reviewsCount={this.state.reviewsData.three_star_reviews}
                        />
                        <ReviewSummaryBar
                          starNumber="2"
                          reviewsPercent={this.state.reviewsData.two_star_percent}
                          reviewsCount={this.state.reviewsData.two_star_reviews}
                        />
                        <ReviewSummaryBar
                          starNumber="1"
                          reviewsPercent={this.state.reviewsData.one_star_percent}
                          reviewsCount={this.state.reviewsData.one_star_reviews}
                        />
                      </div>
                      <div className="ml-3 text-secondary">
                        { this.state.reviewsData.no_rating_reviews === 1 &&
                          <React.Fragment>
                            1 review with no rating
                          </React.Fragment>
                        }
                        { this.state.reviewsData.no_rating_reviews !== 1 &&
                          <React.Fragment>
                            {`${numberWithCommas(this.state.reviewsData.no_rating_reviews)} reviews with no rating`}
                          </React.Fragment>
                        }
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="text-center">
                        <div style={{ fontSize: '3rem', color: '#007bff' }}>
                          {((this.state.reviewsData.average_reviews_score * 10) / 2).toFixed(1)}
                        </div>
                        <div>
                          <StarScoreBar
                            score={this.state.reviewsData.average_reviews_score}
                            maxScore={1}
                            starColor="#007bff"
                          />
                        </div>
                        <div className="text-secondary">
                          { this.state.reviewsData.total_reviews === 1 &&
                            <React.Fragment>
                              1 review
                            </React.Fragment>
                          }
                          { this.state.reviewsData.total_reviews !== 1 &&
                            <React.Fragment>
                              {`${numberWithCommas(this.state.reviewsData.total_reviews)} reviews`}
                            </React.Fragment>
                          }
                        </div>
                      </div>
                    </Col>
                  </Row>
                }
                { this.state.reviewsData.error &&
                  <div>
                    <Alert variant="danger">
                      Failed to load
                    </Alert>
                  </div>
                }
              </div>
            }
          </div>
        }
        contextType="brand"
        contextCategory={this.props.category}
        contextBrand={this.props.brand}
        contextChannel="Product Reviews"
        contextChartName="Review Summary"
        contextDateRange={`${this.props.startDateFilter.format('MM/DD/YYYY')} - ${this.props.endDateFilter.format('MM/DD/YYYY')}`}
        shareClassName="product-brand-review-scores-summary-img"
      />
    );
  }
};
