import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentMedical, faBinoculars } from '@fortawesome/free-solid-svg-icons';
// import { faCommentAlt } from '@fortawesome/free-regular-svg-icons';

export default class CategoryAnalysisOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: `ca-overview-${Math.random().toString(36).substring(7)}`,
      analysisText: {
        'Ranks': {
          overview: 'Scores represent the overall health of your brand. The score is the combination of several different aspects (or pillars). Dive into brands or pillars for in-depth analysis.',
          questions_to_consider: [
            'Which brand pillars are helping and hurting my scores?',
            'How have the rankings changed over the last month?'
          ]
        },
        'Races': {
          overview: 'The brand races are a fun way to see how brands compete over time. Use your favorite screen capture to record and share with colleagues.',
          questions_to_consider: [
            'How have the brand rankings changed over time?',
            'Is anyone pulling away as a clear leader - or loser?'
          ]
        },
        'Channels': {
          overview: 'Brands compete across several channels. The channel scores indicate the extent to which a brand is performing relative to the category goals.',
          questions_to_consider: [
            'In which channels is our brand performing best?',
            'Are there channels where our performance is low and should be improved?'
          ]
        },
        'Share Of': {
          overview: 'Great brands are prevalent across channels and their "share of" owned, earned and paid exceeds the competitors.',
          questions_to_consider: [
            'Is our "share of" exceeding the competition in each area we want to dominate?',
            'Where should we focus next?'
          ]
        },
        'ESoE': {
          overview: '"Excess Share of Everything" is a tool used to determine if you share of earned, owned and paid is exceeding your market share.',
          questions_to_consider: [
            'Is our Share of Everything exceeding our market share?',
            'How will SoE positively or negatively effect our market share over time?'
          ]
        },
        'Top Spot': {
          overview: 'Some brands will place emphasis on specific channels or metrics.',
          questions_to_consider: [
            'Which metrics do we want to dominate?',
            'Who is currently winning in the most important areas?'
          ]
        },
        'Competitors': {
          overview: 'Each month, BrandOps scans online sources to identify potential new competitors.',
          questions_to_consider: [
            'Who is sending out brand signals that match our message & market?',
            'Are there any key competitors we should have BrandOps monitor?'
          ]
        },
        'Brand Power': {
          overview: 'Brand Power measures the extent to which a brand can attract and influence buyers. Grow your brand power by increasing followers, reach and traffic to digital properties.',
          questions_to_consider: [
            'How has brand power changed over time?',
            'Is anyone pulling away as a clear leader - or loser?'
          ]
        },
      }
    };
  }

  render () {
    return (
      <div className="mb-4 mx-4">
        <Row>
          <Col>
            <Card className="h-100">
              <Card.Header className="bg-white" style={{ fontSize: '1.2rem'}}>
                <img
                  className="mt-1 mr-2 align-top"
                  src="/images/Reviews_Detail_Icon.png"
                />
                <b>
                  Overview
                </b>
              </Card.Header>
              <Card.Body>
                { this.state.analysisText[this.props.overviewType] &&
                  <div>
                    {this.state.analysisText[this.props.overviewType].overview}
                  </div>
                }
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="h-100">
              <Card.Header className="bg-white" style={{ fontSize: '1.2rem'}}>
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faBinoculars}
                  color="#4fa2f3"
                />
                <b>
                  Questions to Consider
                </b>
              </Card.Header>
              <Card.Body>
                { this.state.analysisText[this.props.overviewType] &&
                  <ul className="pl-3 mb-0">
                    { this.state.analysisText[this.props.overviewType].questions_to_consider.map((question, i) => {
                        return (
                          <li key={`${this.state.id}-${i}`}>
                            {question}
                          </li>
                        )
                      })
                    }
                  </ul>
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
};
