import React from 'react';
import Form from 'react-bootstrap/Form';

const SelectMultipleQuestion = (props) => {
  return (
    <div>
      <Form.Group>
        <Form.Label>
          <b>{props.question}</b>
        </Form.Label>
        <div>
          { props.options.map((option, i) => {
              return (
                <Form.Check
                  key={`q${props.questionId}-o${i}`}
                  type="checkbox"
                  label={option.choice}
                  checked={Array.isArray(props.answer)?props.answer.includes(option.choice):undefined}
                  onChange={(e) => props.onAnswerChange(e.currentTarget.checked, option.choice)}
                  inline
                />
              )
            })
          }
        </div>
      </Form.Group>
    </div>
  )
};

export default SelectMultipleQuestion;
