import React from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ClipLoader from 'react-spinners/ClipLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class AddSlideModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSlide: undefined,
      transitionSlides: [
        {
          id: 1,
          slideName: 'Blank Slide',
          imageUrl: 'https://custom-report-images.s3.amazonaws.com/static/blank-placeholder.png',
        },
        {
          id: 2,
          slideName: 'Pillar Transition Slide',
          imageUrl: 'https://custom-report-images.s3.amazonaws.com/static/pillars-transition.png',
        },
        {
          id: 3,
          slideName: 'Social Transition Slide',
          imageUrl: 'https://custom-report-images.s3.amazonaws.com/static/social-transition.png',
        },
        {
          id: 4,
          slideName: 'Media Cast Transition Slide',
          imageUrl: 'https://custom-report-images.s3.amazonaws.com/static/mediacast-transition.png',
        },
        {
          id: 5,
          slideName: 'News Transition Slide',
          imageUrl: 'https://custom-report-images.s3.amazonaws.com/static/news-transition.png',
        },
        {
          id: 6,
          slideName: 'Reviews Transition Slide',
          imageUrl: 'https://custom-report-images.s3.amazonaws.com/static/reviews-transition.png',
        },
        {
          id: 7,
          slideName: 'Web Transition Slide',
          imageUrl: 'https://custom-report-images.s3.amazonaws.com/static/web-transition.png',
        },
      ],
      errors: {},
      errorMessage: '',
      saveLoading: false
    };
  }

  onOpen = () => {
    // reset defaults
    this.setState(() => ({
      selectedSlideId: this.state.transitionSlides[0].id,
      selectedSlide: this.state.transitionSlides[0],
      errors: {},
      errorMessage: '',
      saveLoading: false
    }));
  };

  onSelectedSlideChange = (selectedSlide) => {
    if (selectedSlide && selectedSlide.id !== this.state.selectedSlideId) {
      this.setState(() => ({
        selectedSlideId: selectedSlide.id,
        selectedSlide
      }));
    }
  };

  validForm = () => {
    const errors = {};
    if (!this.state.selectedSlide) {
      errors.selectedSlide = true;
    }

    this.setState(() => ({ errors }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  addSlideToCustomReport = () => {
    if (this.validForm()) {
      this.setState(() => ({ errorMessage: '', saveLoading: true }));
      const reportWidget = {
        report_id: this.props.customReport.id,
        title: '',
        image_url: this.state.selectedSlide.imageUrl,
      };
      if (this.props.widgetPlacement) {
        reportWidget.widget_order = this.props.widgetPlacement;
      }

      axios.post(
        `${LISTEN_ENDPOINT}/api/custom-report-widgets`,
        reportWidget,
        HEADERS
      ).then(response => {
        this.props.refreshReports();
        this.props.handleClose();
      }).catch(error => {
        console.error('Error: failed to add to report');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
        this.setState(() => ({
          errorMessage: 'Failed to add to report.',
          addToReportLoading: false,
        }));
      });
    }
  };

  render () {
    return (
      <Modal
        size="lg"
        show={this.props.isOpen}
        onHide={this.props.handleClose}
        onEntering={this.onOpen}
      >
        <Modal.Header
          className="bg-bops-blue text-light"
          closeButton
        >
          <Modal.Title>
            <FontAwesomeIcon
              className="mr-2"
              icon={faImage}
            />
            Add Slide
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            { this.state.transitionSlides.map((transitionSlide, i) => {
                return (
                  <Col key={`asm-ts-${transitionSlide.id}`} xs={4}>
                    <div
                      className={`mb-4 p-2 border rounded ${this.state.selectedSlideId === transitionSlide.id ? 'border-primary' : 'border-light'}`}
                      onClick={() => this.onSelectedSlideChange(transitionSlide)}
                      style={{ cursor: 'pointer' }}
                    >
                      <div>
                        <img
                          src={transitionSlide.imageUrl}
                          alt=""
                          crossOrigin="anonymous"
                          width="100%"
                        />
                      </div>
                      <div className="mt-2 text-center">
                        {transitionSlide.slideName}
                      </div>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
          <Alert show={Boolean(this.state.errorMessage)} variant="danger">
            {this.state.errorMessage}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.addSlideToCustomReport} disabled={this.state.saveLoading}>
            { this.state.saveLoading &&
              <span className="mr-1">
                <ClipLoader
                  size={15}
                  color="ffffff"
                />
              </span>
            }
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};
