import React from 'react';
import axios from 'axios';
import moment from 'moment';
import GeneralSummaryGraph from '../graphs/GeneralSummaryGraph';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class TwitterOwnedTweetSummaryGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ownedTweetTrendData: [],
      ownedTweetScoreType: undefined,
      ownedTweetTrendDataLoading: false,
      categoryAverageData: [],
    }
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchOwnedTweetTrendData();
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.companyId !== this.props.companyId ||
      prevProps.allMonthsChecked !== this.props.allMonthsChecked
    ) {
      this.fetchOwnedTweetTrendData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchOwnedTweetTrendData = () => {
    if (this.props.companyId) {
      this.setState(() => ({ ownedTweetTrendDataLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/companies/${this.props.companyId}/twitter-owned-post-trend${allMonthsStartDateParam ? `?start-date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let ownedTweetTrendData = response.data.monthly_stats;
        const ownedTweetScoreType = response.data.twitter_owned_post_count ? response.data.twitter_owned_post_count.score_type : undefined;
        // merge category average data into original data source
        ownedTweetTrendData = ownedTweetTrendData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAverageData[i]))
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            ownedTweetTrendData,
            ownedTweetScoreType,
            ownedTweetTrendDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch owner tweet trend data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            ownedTweetTrendData: [],
            ownedTweetScoreType: undefined,
            ownedTweetTrendDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=twitter_avg_owned_tweets${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAverageData = response.data.category_stats;
          // merge category average data into original data source
          const ownedTweetTrendData = this.state.ownedTweetTrendData.map((x, i) => {
            return (Object.assign({}, x, categoryAverageData[i]))
          });

          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAverageData,
              ownedTweetTrendData
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average owned tweet data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAverageData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  };

  render () {
    return (
      <GeneralSummaryGraph
        title="Owned Tweets"
        icon={
          <img
            className="mr-2 align-top"
            src="/images/Reviews_Summary_Icon.png"
          />
        }
        metricName="social_owned_post_count_twitter"
        data={this.state.ownedTweetTrendData}
        dataKey="twitter_owned_post_count"
        dataLabel="Owned Tweets"
        scoreType={this.state.ownedTweetScoreType}
        loading={this.state.ownedTweetTrendDataLoading}
        allowDecimals={false}
        showPercentChange={true}
        showAverageData={true}
        averageDataKey="twitter_avg_owned_tweets"
        averageDataLabel="Category Average"
        showTooltip={true}
        contextType="brand"
        contextCategory={this.props.category}
        contextBrand={this.props.brand}
        contextChannel="Social"
        contextProperty="Twitter"
        contextChartName="Owned Tweets"
        shareClassName="twitter-owned-tweets-img"
      />
    );
  }
};
