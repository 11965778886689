import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getDomainFromUrl } from '../../utils/urls';
import CategoryEmailSummary from './CategoryEmailSummary';
import EmailsDiscoveredGraph from './EmailsDiscoveredGraph';
import ShareOfChart from '../graphs/ShareOfChart';
import { round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shareOfGraphData: {
        series: [
          { name: 'Month', type: 'dimension' },
          { name: 'Brand', type: 'dimension' },
          { name: 'Value', type: 'measure' },
        ],
        records: [],
      },
      emailsDiscoveredData: [],
      selectedMonthEmailsData: [],
      emailsDiscoveredDataLoading: false,
      categoryShareOfEmailsData: [],
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchEmailsDiscovered();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.customerId !== this.props.user.customerId ||
      prevProps.categoryId !== this.props.categoryId
    ) {
      this.fetchEmailsDiscovered();
    }
    if (prevProps.selectedMonth !== this.props.selectedMonth) {
      this.setEmailData(this.props.selectedMonth);
    }
  };

  fetchEmailsDiscovered = () => {
    if (this.props.category) {
      this.setState(() => ({ emailsDiscoveredDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-stats?stats_type=emails&category_id=${this.props.category.id}&category_type=${this.props.category.category_type}`,
        HEADERS
      ).then(response => {
        const shareOfGraphData = {
          series: [
            { name: 'Month', type: 'dimension' },
            { name: 'Brand', type: 'dimension' },
            { name: 'Value', type: 'measure' },
          ],
          records: [],
        };
        const emailsDiscoveredData = response.data.product_brand_stats;
        // convert stats from object to array and sort dates
        for (const pbs of emailsDiscoveredData) {
          pbs.stats = Object.entries(pbs.stats).map(([month, data], i) => {
            return { month, ...data };
          })
          pbs.stats.sort((a, b) => moment(a.date) - moment(b.date));
        }

        let selectedMonthEmailsData = [];
        for (const pb of emailsDiscoveredData) {
          for (const data of pb.stats) {
            if (data.month == this.props.selectedMonth.label) {
              const pbData = {
                month: data.month,
                product_brand_name: pb.product_brand_name,
                product_email_count: data.product_email_count || 0,
                company_email_count: data.company_email_count || 0,
              };
              selectedMonthEmailsData.push(pbData);
            }
          }
        }

        const monthsDataTotals = {};
        // get product brands & totals for percent
        for (const pb of emailsDiscoveredData) {
          for (const data of pb.stats) {
            if (!monthsDataTotals[data.month]) {
              monthsDataTotals[data.month] = data.product_email_count;
            } else {
              monthsDataTotals[data.month] += data.product_email_count;
            }
            // insert graph data
            shareOfGraphData.records.push([
              data.month,
              pb.product_brand_name,
              data.product_email_count,
            ]);
          }
        }

        const monthsData = {};
        for (const pb of emailsDiscoveredData) {
          for (const data of pb.stats) {
            if (!monthsData[data.month]) {
              monthsData[data.month] = {
                month: data.month,
                date: data.date
              };
            }
            if (monthsDataTotals[data.month] === 0) {
              monthsData[data.month][pb.product_brand_name] = {
                count: 0,
                percent: 0
              }
            } else {
              monthsData[data.month][pb.product_brand_name] = {
                count: data.product_email_count,
                percent: round((data.product_email_count/monthsDataTotals[data.month])*100, 1)
              }
            }
          }
        }
        // convert to array sort by date
        const sortedMonthsArray = Object.values(monthsData).sort((a, b) => moment(a.date) - moment(b.date));
        // remove date object once sorted, affects legend in stacked area chart
        const categoryShareOfEmailsData = sortedMonthsArray.map(monthData => {
          delete monthData.date;
          return monthData;
        });

        this.setState(() => ({
          shareOfGraphData,
          categoryShareOfEmailsData,
          emailsDiscoveredData,
          selectedMonthEmailsData,
          emailsDiscoveredDataLoading: false
        }))
      }).catch(error => {
        console.error('Error: unable to fetch proxy emails discovered...');
        this.setState(() => ({
          shareOfGraphData: {},
          categoryShareOfEmailsData: [],
          emailsDiscoveredData: [],
          selectedMonthEmailsData: [],
          emailsDiscoveredDataLoading: false
        }));
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  }

  selectMonth = (selectedMonth) => {
    if (selectedMonth !== this.props.selectedMonth) {
      this.props.updateSelectedMonth(selectedMonth);
    }
  };

  setEmailData = (selectedMonth) => {
    let emailsDiscoveredData = this.state.emailsDiscoveredData;
    let selectedMonthEmailsData = [];
    for (const pb of emailsDiscoveredData) {
      for (const data of pb.stats) {
        if (data.month == this.props.selectedMonth.label) {
          const pbData = {
            month: data.month,
            product_brand_name: pb.product_brand_name,
            product_email_count: data.product_email_count || 0,
            company_email_count: data.company_email_count || 0,
          };
          selectedMonthEmailsData.push(pbData);
        }
      }
    }
    this.setState(() => ({ selectedMonthEmailsData }));
  };

  render () {
    return (
      <div
        className="p-4 pre-scrollable"
        style={{
          minHeight: 'calc(100vh - 100px)',
          maxHeight: 'calc(100vh - 100px)'
        }}
      >
        <CategoryEmailSummary
          user={this.props.user}
          category={this.props.category}
          sixMonths={this.props.sixMonths}
          selectedMonth={this.props.selectedMonth}
          selectMonth={this.selectMonth}
          emailsDiscoveredData={this.state.selectedMonthEmailsData}
          emailsDiscoveredDataLoading={this.state.emailsDiscoveredDataLoading}
        />
        <div className="mt-5 mb-4 band-separator" />
        <EmailsDiscoveredGraph
          customerId={this.props.user.customerId}
          category={this.props.category}
          data={this.state.emailsDiscoveredData}
          sixMonths={this.props.sixMonths}
          emailsDiscoveredDataLoading={this.state.emailsDiscoveredDataLoading}
        />
        <div className="mt-4 mb-2 band-separator" />
        <ShareOfChart
          title="Share of Emails Discovered"
          data={this.state.shareOfGraphData}
          legendData={this.state.categoryShareOfEmailsData}
          loading={this.state.emailsDiscoveredDataLoading}
          contextType="category"
          contextCategory={this.props.category}
          contextBrand={null}
          contextChannel="Emails"
          contextChartName="Share of Emails Discovered"
          shareClassName="category-emails-discovered-img"
        />
      </div>
    );
  }
};
