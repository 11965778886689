import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import {
  ComposedChart,
  Area,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ZAxis,
  Label,
  Tooltip,
  ReferenceArea,
  ResponsiveContainer,
  Scatter
} from 'recharts';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import EditEventFormModal from '../eventTimeline/EditEventFormModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faArrowRight, faStar } from '@fortawesome/free-solid-svg-icons';
import { round } from '../../utils/numbers';
import { colors } from '../../utils/graphs'
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class BrandScoreGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedBrands: [],
      selectedBrandIdsMap: {},
      predictionForecastData: [],
      editEventFormModalOpen: false,
      selectedEvent: undefined,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.predictionDisplay) {
      this.getPredictionData();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.predictionDisplay &&
      prevProps.primaryBrandId !== this.props.primaryBrandId ||
      prevProps.scoreKey !== this.props.scoreKey
    ) {
      this.getPredictionData();
    }
  };

  brandCheckboxChange = (event, brand, color) => {
    const checked = event.currentTarget.checked;
    if (checked) {
      this.setState((prevState) => ({
        selectedBrands: [...prevState.selectedBrands, {...brand, color }],
        selectedBrandIdsMap: {...prevState.selectedBrandIdsMap, [brand.id]: true}
      }));
    } else {
      this.setState((prevState) => {
        const selectedBrandIdsMap = Object.assign({}, prevState.selectedBrandIdsMap);
        delete selectedBrandIdsMap[brand.id];
        return ({
          selectedBrands: prevState.selectedBrands.filter(b => b.id !== brand.id),
          selectedBrandIdsMap
        })
      });
    }
  };

  togglePrediction = () => {
    if (!this.props.predictionDisplay) {
      this.getPredictionData();
      this.props.updatePredictionDisplay(true);
    } else {
      this.props.updatePredictionDisplay(false);
    }
  };

  getPredictionData = () => {
    // format data for forecast
    const data = [];
    for (const d of this.props.brandScoresData) {
      data.push({
        date: d.score_at,
        value: d[`${this.props.primaryBrandId}_${this.props.scoreKey}`]
      });
    }
    const predictionKey = `${this.props.primaryBrandId}_${this.props.scoreKey}_prediction`;

    axios.post(
      `${LISTEN_ENDPOINT}/api/arima-forecast`,
      { data },
      HEADERS
    ).then(response => {
      const predictionDataMap = {};
      const predictionForecastData = [];
      for (const d of response.data) {
        predictionDataMap[d.date] = round(d.prediction, 2);
        if (d.forecast) {
          predictionForecastData.push({
            [predictionKey]: round(d.prediction, 2),
            score_at: d.date
          });
        }
      }
      this.props.updateBrandScoresDataWithPrediction(predictionKey, predictionDataMap);
      this.setState(() => ({
        predictionDisplay: true,
        predictionForecastData
      }));
    }).catch(error => {
      console.error('Error: unable to fetch arima forecast data...');
      this.setState(() => ({
        predictionDisplay: false,
        predictionForecastData: []
      }))
    })
  };

  openAddEventModal = () => {
    const selectedEvent = {
      customer_id: this.props.user.customerId,
      user_id: this.props.user.id,
      product_brand_id: this.props.primaryBrandId,
      summary: '',
      is_public: 0,
      event_created_on: moment().utc().subtract(1, 'day').hour(12).minute(0).second(0)
    };
    this.setState(() => ({
      selectedEvent,
      editEventFormModalOpen: true
    }));
  };

  closeEditEventFormModal = () => {
    this.setState(() => ({
      selectedEvent: undefined,
      editEventFormModalOpen: false
    }));
  };

  customTooltip = ({ active, payload, label }) => {
    if (active && payload && Array.isArray(payload)) {
      return (
        <div
          className="p-2"
          style={{
            backgroundColor: (payload.length > 0 && payload[0].payload.event) ?
              '#ffffcc': '#ffffff',
              borderStyle: 'solid',
              borderWidth: 'thin',
              borderColor: '#bfbfbf'
          }}
        >
          <div className="py-1">
            {moment(label).format('dddd, MMMM Do YYYY')}
          </div>
          { payload.map((item, i) => {
              if (item.name && item.name.includes(' Event')) {
                const brandName = item.name.replace(' Event', '');
                return (
                  <div
                    key={`bsg-tt-${item.dataKey}-${i}`}
                    className="py-1"
                  >
                    <FontAwesomeIcon
                      className="mr-2"
                      icon={faStar}
                      color="#ffc107"
                    />
                    <b>{`${brandName}: ${item.payload[`${item.dataKey}_summary`]}`}</b>
                  </div>
                )
              } else {
                return (
                  <div
                    key={`tt-${item.payload.id}-${i}`}
                    className="py-1"
                    style={{ color: item.color }}
                  >
                    {`${item.name}: ${parseFloat(item.payload[item.dataKey]).toFixed(1)}`}
                  </div>
                )
              }
            })
          }
        </div>
      )
    } else {
      return null;
    }
  };

  render () {
    return (
      <Card id="brandScores">
        <Card.Header className="bg-white">
          <FontAwesomeIcon
            className="mr-2"
            icon={faChartLine}
            color="#4fa2f3"
          />
          <b style={{ fontSize: '1.2rem'}}>
            Scores
          </b>
          <div className="d-inline-block float-right">
            <button
              className={`float-right ml-2 px-1 py-0 border rounded${this.props.predictionDisplay ? ' bg-secondary' : ' bg-white'}`}
              onClick={this.togglePrediction}
            >
              <FontAwesomeIcon
                icon={faArrowRight}
                color={
                  this.props.predictionDisplay ?
                    "#ffffff" :
                    "#6c757d"
                }
                style={{
                  paddingTop: '1px'
                }}
              />
            </button>
            <button
              className={`float-right ml-2 px-1 border rounded bg-white text-secondary`}
              onClick={this.openAddEventModal}
              style={{
                paddingTop: '1px',
                paddingBottom: '2px',
                fontSize: '.875rem'
              }}
            >
              Add Event
            </button>
          </div>
          <div className="d-inline-block float-right">
            <SharePopUpContainer
              shareElementId="brandScores"
              elementLabel="brand-scores-img"
              position="top"
              contextType="brand"
              contextCategory={this.props.category}
              contextBrand={this.props.primaryBrand}
              contextProperty={this.props.scoreLabel}
              contextChartName={this.props.scoreLabel}
            />
          </div>
        </Card.Header>
        <Card.Body>
          { (this.props.category && this.props.category.product_brands) &&
            <div>
              <Form.Row>
                <div className="d-inline-block ml-3 mb-2">
                  <div
                    className="d-inline-block mr-1 align-middle"
                    style={{
                      width: '16px',
                      height: '16px',
                      backgroundColor: colors[0],
                      borderRadius: '50%'
                    }}
                  />
                  <div className="d-inline-block align-middle">
                    {this.props.primaryBrand.name}
                  </div>
                </div>
                { this.props.category.product_brands.map((brand, i) => {
                    if (brand.id !== this.props.primaryBrand.id) {
                      return (
                        <Form.Check
                          key={`bcb-${brand.id}`}
                          className="ml-3 mb-2"
                          label={
                            <div className="d-inline-block">
                              <div
                                className="d-inline-block mr-1 align-middle"
                                style={{
                                  width: '16px',
                                  height: '16px',
                                  backgroundColor: colors[i+1%colors.length],
                                  borderRadius: '50%'
                                }}
                              />
                              <div className="d-inline-block align-middle">
                                {brand.name}
                              </div>
                            </div>
                          }
                          checked={this.state.selectedBrandIdsMap[brand.id] || false}
                          onChange={(e) => this.brandCheckboxChange(e, brand, colors[i+1%colors.length])}
                        />
                      )
                    }
                  })
                }
              </Form.Row>
            </div>
          }
          <ResponsiveContainer height={300} width="99%">
            <ComposedChart
              data={
                this.props.predictionDisplay ?
                  [
                    ...this.props.brandScoresData,
                    ...this.state.predictionForecastData,
                  ] :
                  this.props.brandScoresData
              }
              margin={{
                top: 0, right: 5, bottom: 0, left: 10,
              }}
              onMouseDown={(event) => {
                if (this.props.onChartDateStart) {
                  this.props.onChartDateStart(event)
                }
              }}
              onMouseUp={(event) => {
                if (this.props.onChartDateEnd) {
                  this.props.onChartDateEnd(event)
                }
              }}
              onMouseMove={(event) => {
                if (this.props.onChartMouseMove) {
                  this.props.onChartMouseMove(event)
                }
              }}
              style={{
                WebkitTouchCallout: 'none',
                WebkitUserSelect: 'none',
                KhtmlUserSelect: 'none',
                MozUserSelect: 'none',
                MsUserSelect: 'none',
                UserSelect: 'none'
              }}
            >
              <defs>
                <linearGradient id="primaryBrandGradient"  x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={colors[0]} stopOpacity={0.8}/>
                  <stop offset="95%" stopColor={colors[0]} stopOpacity={0.2}/>
                </linearGradient>
              </defs>
              <XAxis
                dataKey="score_at"
                tickFormatter={(date) => {
                  return moment(date).endOf('day').format('MM/DD');
                }}
                minTickGap={20}
              />
              <YAxis
                type="number"
                domain={[
                  (dataMin) => {
                    if (isFinite(dataMin)) {
                      dataMin = Math.floor((dataMin - (dataMin * .25)) / 5) * 5;
                      if (dataMin < 0) {
                        dataMin = 0;
                      }
                      return Math.floor(dataMin);
                    } else {
                      return dataMin;
                    }
                  },
                  (dataMax) => {
                    if (isFinite(dataMax)) {
                      dataMax = Math.ceil((dataMax + (dataMax * .25)) / 5) * 5;
                      if (dataMax > 100) {
                        dataMax = 100;
                      }
                      return Math.ceil(dataMax);
                    } else {
                      return dataMax;
                    }
                  }
                ]}
              >
                <Label
                  value={this.props.scoreLabel}
                  position="left"
                  offset={0}
                  angle={-90}
                  style={{
                    textAnchor: 'middle',
                    fontSize: '1.2rem',
                    fontWeight: 'bold'
                  }}
                />
              </YAxis>
              <ZAxis
                type="number"
                dataKey="eventSize"
                range={[100, 200]}
              />
              <Tooltip content={this.customTooltip} />
              <CartesianGrid strokeDasharray="5 5"/>

              <Area
                name={`${this.props.primaryBrandName} ${this.props.scoreLabel}`}
                type="monotone"
                dataKey={`${this.props.primaryBrandId}_${this.props.scoreKey}`}
                stroke={colors[0] || '#000000'}
                fillOpacity={1}
                fill={`url(#primaryBrandGradient)`}
              />
              { this.props.predictionDisplay &&
                <Line
                  name={`${this.props.primaryBrandName} Prediction`}
                  type="monotone"
                  dataKey={`${this.props.primaryBrandId}_${this.props.scoreKey}_prediction`}
                  stroke="#ff751a"
                  strokeWidth={this.props.lineWidth || 2}
                  strokeDasharray="5 5"
                />
              }
              { this.state.selectedBrands.map((brand, i) => {
                  return (
                    <Line
                      key={`bs-mgl-${brand.id}`}
                      name={`${brand.name} ${this.props.scoreLabel}`}
                      type="monotone"
                      dataKey= {`${brand.id}_${this.props.scoreKey}`}
                      stroke={brand.color}
                      strokeWidth={this.props.lineWidth || 1}
                    />
                  )
                })
              }
              <Scatter
                name={`${this.props.primaryBrandName} Event`}
                dataKey={`${this.props.primaryBrandId}_${this.props.scoreKey}_event`}
                fill="#ffc107"
                shape="star"
              />
            { this.state.selectedBrands.map((brand, i) => {
                  return (
                    <Scatter
                      name={`${brand.name} Event`}
                      key={`bs-mgs-${brand.id}`}
                      dataKey={`${brand.id}_${this.props.scoreKey}_event`}
                      fill="#ffc107"
                      shape="star"
                    />
                  )
                })
              }
              { (this.props.chartDateStart && this.props.chartDateEnd) &&
                <ReferenceArea
                  x1={this.props.chartDateStart}
                  x2={this.props.chartDateEnd}
                  stroke="#cccccc"
                  fill="#cccccc"
                />
              }
              { (
                  this.props.predictionDisplay &&
                  this.state.predictionForecastData.length > 0 &&
                  this.props.brandScoresData.length > 0
                ) &&
                <ReferenceArea
                  x1={this.props.brandScoresData[this.props.brandScoresData.length-1].score_at}
                  x2={this.state.predictionForecastData[this.state.predictionForecastData.length-1].score_at}
                  stroke="#f2f2f2"
                  fill="#f2f2f2"
                />
              }
            </ComposedChart>
          </ResponsiveContainer>

          <EditEventFormModal
            isOpen={this.state.editEventFormModalOpen}
            handleClose={this.closeEditEventFormModal}
            event={this.state.selectedEvent}
            refreshEvents={this.props.refreshEvents}
            categoryEvent={true}
            productBrands={[
              this.props.primaryBrand,
              ...this.state.selectedBrands
            ]}
          />
        </Card.Body>
      </Card>
    );
  }
};
