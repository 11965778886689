import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import ClipLoader from 'react-spinners/ClipLoader';
import CardComponent from '../common/CardComponent';
import DateRangePicker from '../common/DateRangePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { getDomainFromUrl } from '../../utils/urls';
import { numberWithCommas } from '../../utils/numbers';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class PageChanges extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageChangesByMonthData: [],
      pageChangesByMonthDataLoading: false,
      pageChangesData: [],
      pageChangesDataLoading: false,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchPageChangesByMonth();
    this.fetchPageChanges();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.brand !== this.props.brand) {
      this.fetchPageChangesByMonth();
      this.fetchPageChanges();
    }
    if (
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter
    ) {
      this.fetchPageChanges();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchPageChangesByMonth = () => {
    if (this.props.brand) {
      this.setState(() => ({ pageChangesByMonthDataLoading: true }));
      const domain = getDomainFromUrl(this.props.brand.company_url);
      const formattedStartDate = moment().subtract(12, 'month').startOf('month').format('YYYY-MM-DD');
      const formattedEndDate = moment().format('YYYY-MM-DD');
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/sitemap-last-changed-count?domain=${domain}&start_date=${formattedStartDate}&end_date=${formattedEndDate}&group_by=month`,
        HEADERS
      ).then(response => {
        const pageChangesByMonthData = [];
        for (const monthData of response.data) {
          if (monthData.month && monthData.year) {
            monthData.date = moment(`${monthData.month}-15-${monthData.year}`).toString();
            pageChangesByMonthData.push(monthData);
          }
        }
        // sort by date
        pageChangesByMonthData.sort((a, b) => moment(a.date).diff(moment(b.date)));

        if (this.state.isMounted) {
          this.setState(() => ({
            pageChangesByMonthData,
            pageChangesByMonthDataLoading: false,
          }))
        }
      }).catch(error => {
        console.error('Error: failed to fetch page changes by month...');
        if (this.state.isMounted) {
          this.setState(() => ({
            pageChangesByMonthData: [],
            pageChangesByMonthDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchPageChanges = () => {
    if (this.props.brand) {
      this.setState(() => ({ pageChangesDataLoading: true }));
      const domain = getDomainFromUrl(this.props.brand.company_url);
      const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD');
      const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD');
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/sitemap-last-changed?domain=${domain}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        const pageChangesData = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            pageChangesData,
            pageChangesDataLoading: false,
          }))
        }
      }).catch(error => {
        console.error('Error: failed to fetch page changes...');
        if (this.state.isMounted) {
          this.setState(() => ({
            pageChangesData: [],
            pageChangesDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        <CardComponent
          title="Website Change Trend"
          icon={
            <FontAwesomeIcon
              className="mt-1 mr-2"
              icon={faChartBar}
              color="#4fa2f3"
            />
          }
          body={
            <div>
              { this.state.pageChangesByMonthDataLoading &&
                <div className="m-4 text-center">
                  <ClipLoader size={100}/>
                </div>
              }
              { !this.state.pageChangesByMonthDataLoading &&
                <div>
                  { this.state.pageChangesByMonthData.length === 0 &&
                    <div className="p-4 bg-white border rounded">
                      <div className="p-4 text-center">
                        Not enough data collected
                      </div>
                    </div>
                  }
                  { this.state.pageChangesByMonthData.length > 0 &&
                    <ResponsiveContainer height={350} width="99%">
                      <BarChart
                        data={this.state.pageChangesByMonthData}
                      >
                        <XAxis
                          dataKey="date"
                          tickFormatter={(date) => {
                            return moment(date).format('MM/YY');
                          }}
                        />
                        <YAxis
                          allowDecimals={false}
                          tickFormatter={(count) => {
                            return numberWithCommas(count);
                          }}
                        />
                        <Tooltip
                          labelFormatter={(label) => moment(label).format('dddd, MMMM Do YYYY')}
                          formatter={(value) => numberWithCommas(value)}
                        />
                        <Bar name="New Pages" dataKey="count_new" stackId="a" fill="#007bff"/>
                        <Bar name="Modified Pages" dataKey="count_modified" stackId="a" fill="#00b300"/>
                      </BarChart>
                    </ResponsiveContainer>
                  }
                </div>
              }
            </div>
          }
          contextType="brand"
          contextCategory={this.props.category}
          contextBrand={this.props.brand}
          contextChannel="Website"
          contextProperty="Page Changes"
          contextChartName="Website Change Trend"
        />
        <h5 className="my-2 p-2 bg-bops-blue text-light">
          Website Additions and Changes
          <span
            className="ml-4 mr-2"
            style={{ fontSize: '1rem', fontWeight: 'normal' }}
          >Date Range</span>
          <DateRangePicker
            startDate={this.props.startDateFilter}
            endDate={this.props.endDateFilter}
            updateDates={this.props.onFilterDatesChange}
          />
        </h5>
        <div>
          { this.state.pageChangesDataLoading &&
            <div className="m-4 text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !this.state.pageChangesDataLoading &&
            <div>
              { this.state.pageChangesData.length === 0 &&
                <div className="p-4 bg-white border rounded">
                  <div className="p-4 text-center">
                    Not enough data collected
                  </div>
                </div>
              }
              { this.state.pageChangesData.length > 0 &&
                <Table
                  striped
                  border
                  size="sm"
                  style={{
                    overflow: 'auto',
                  }}
                >
                  <thead className="bg-dark text-light">
                    <tr
                      style={{
                        position: 'sticky',
                        top: -1,
                        zIndex: 1
                      }}
                    >
                      <th
                        className="py-1 bg-dark text-center"
                        style={{ width: '150px' }}
                      >
                        Date
                      </th>
                      <th className="py-1 bg-dark text-center">
                        Status
                      </th>
                      <th className="py-1 bg-dark">URL</th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.state.pageChangesData.map((pageChange, i) => {
                        return (
                          <React.Fragment key={`pc-${i}`}>
                            <tr>
                              <td className="text-center">
                                {moment(pageChange.date_last_updated).format('MM/DD/YYYY')}
                              </td>
                              <td className="text-center">
                                {pageChange.status}
                              </td>
                              <td>
                                <a href={pageChange.sitemap_url} target="_blank">
                                  {pageChange.sitemap_url}
                                </a>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })
                    }
                  </tbody>
                </Table>
              }
            </div>
          }
        </div>
      </div>
    );
  }
};
