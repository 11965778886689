import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Parser from 'rss-parser';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';

export default class RssPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: undefined,
      errorMessage: '',
      loading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchRssUrl();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.rssUrl !== this.props.rssUrl) {
      this.fetchRssUrl();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchRssUrl = () => {
    this.setState(() => ({
      preview: undefined,
      errorMessage: '',
      loading: true,
    }));
    if (this.props.rssUrl) {
      const parser = new Parser({ headers: HEADERS.headers });
      const proxyRssUrl = `${LISTEN_ENDPOINT}/api/url-proxy?url=${this.props.rssUrl}`;
      parser.parseURL(proxyRssUrl, (error, feed) => {
        let preview;
        let errorMessage = '';
        if (error) {
          errorMessage = 'Failed to preview RSS.';
        } else if (feed) {
          preview = feed;
          if (
            this.props.typeLabel !== 'news' &&
            preview.items &&
            preview.items.length > 0 &&
            !preview.items[0].pubDate
          ) {
            preview.date_warning = true;
          }
          if (this.props.itemLimit) {
            preview.items = preview.items.slice(0, this.props.itemLimit);
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            preview,
            errorMessage,
            loading: false
          }));
        }
      });
    }
  };

  render () {
    return (
      <div>
        { this.state.loading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.loading &&
          <div>
            <Alert show={Boolean(this.state.errorMessage)} variant="danger">
              {this.state.errorMessage}
            </Alert>
            { this.state.preview &&
              <div
                className="bg-white border rounded p-4"
                style={{ fontSize: '.875rem' }}
              >
                <Row>
                  { typeof(this.state.preview.image) === 'string' &&
                    <Col style={{ flex: '0 0 35px' }}>
                      <img
                        src={this.state.preview.image}
                        alt=""
                        crossOrigin="anonymous"
                        style={{ width: '30px' }}
                      />
                    </Col>
                  }
                  <Col>
                    <div>
                      <div
                        onClick={() => window.open(this.state.preview.link, '_blank')}
                        style={{ cursor: 'pointer' }}
                      >
                        {this.state.preview.title}
                      </div>
                      { this.state.preview.description &&
                        <div className="text-muted">
                          {this.state.preview.description}
                        </div>
                      }
                      { this.state.preview.date_warning &&
                        <Alert variant="warning">
                          {`Post dates are not present for ${this.props.typeLabel || 'items'} in this RSS feed. Existing posts will be collected and given today's date. Future posts will be dated using the date they are collected.`}
                        </Alert>
                      }
                    </div>
                    <div>
                      { this.state.preview.items.length === 0 &&
                        <div className="mt-4">
                          {`No ${this.props.typeLabel || 'items'} found.`}
                        </div>
                      }
                      { this.state.preview.items.map((item, i) => {
                          return (
                            <Card
                              key={`rss-prev-${i}`}
                              className="mt-4"
                            >
                              <Card.Body>
                                <div
                                  onClick={() => window.open(item.link, '_blank')}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {item.title}
                                </div>
                                { item.description &&
                                  <div className="text-muted">
                                    {item.description}
                                  </div>
                                }
                                { item.pubDate &&
                                  <div>
                                    {moment(item.pubDate).format('MM/DD/YYYY')}
                                  </div>
                                }
                              </Card.Body>
                            </Card>
                          )
                        })
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            }
          </div>
        }
      </div>
    );
  }
};
