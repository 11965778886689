import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Badge from 'react-bootstrap/Badge';
import CategoryMenuAccordion from './CategoryMenuAccordion';
import NewUserGuidedTour from './NewUserGuidedTour';
import RefreshAlertContainer from '../../containers/common/RefreshAlertContainer';
import { withRouter, NavLink } from 'react-router-dom';
import { isAuthenticatedAdmin } from '../../utils/auth';
import { createUserActivityLog } from '../../utils/users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faBell,
  faCogs,
  faCommentDots,
  faRankingStar,
  faRectangleList,
  faSearch,
  faTasks,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import history from '../../routers/history';

class LeftMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.props.setListenProjectBuild();
    this.props.fetchBrandMetrics();
    if (this.props.user.customerId) {
      this.props.fetchCustomer(this.props.user.customerId);
      this.props.fetchCompany(this.props.user.customerId);
      this.props.fetchCompanySetupChecklist(this.props.user.customerId);
      this.props.fetchCategories(this.props.user.customerId);
      this.props.fetchCompanyCustomReports(this.props.user.customerId);
      this.props.fetchAlerts(this.props.user.customerId);
      this.props.fetchSlackChannels(this.props.user.customerId);
    }
    if (this.props.location.pathname.includes('/reports/public/')) {
      this.props.changeLeftMenuSize(16);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.customerId !== this.props.user.customerId) {
      if (this.props.user.customerId) {
        this.props.fetchCustomer(this.props.user.customerId);
        this.props.fetchCompany(this.props.user.customerId);
        this.props.fetchCompanySetupChecklist(this.props.user.customerId);
        this.props.fetchCategories(this.props.user.customerId);
        this.props.fetchCompanyCustomReports(this.props.user.customerId);
        this.props.fetchAlerts(this.props.user.customerId);
        this.props.fetchSlackChannels(this.props.user.customerId);
      } else {
        // use invalid id to clear exisiting data
        this.props.fetchCustomer(-1);
        this.props.fetchCompany(-1);
        this.props.fetchCompanySetupChecklist(-1);
        this.props.fetchCategories(-1);
        this.props.fetchCompanyCustomReports(-1);
        this.props.fetchAlerts(-1);
        this.props.fetchSlackChannels(-1);
      }
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  onHomeSelection = () => {
    history.push(`/home`);
  };

  isCategoryActive = (category) => {
    let active = false;
    if (category) {
      const decodedPathname = decodeURIComponent(this.props.location.pathname);
      const pathnameSplit = this.props.location.pathname.split('/');
      let categoryName = pathnameSplit[pathnameSplit.length-1];
      if (categoryName) {
        categoryName = categoryName.split('?')[0];
        categoryName = categoryName.split('#')[0];
        categoryName = decodeURIComponent(categoryName);
      }
      if (
        decodedPathname.includes(`/category-dashboard/${category.name}`) &&
        category.name === categoryName
      ) {
        active = true;
      }
    }
    return active;
  };

  isBrandActive = (category, brand) => {
    let active = false;
    if (category && brand) {
      const decodedPathname = decodeURIComponent(this.props.location.pathname);
      const pathnameSplit = this.props.location.pathname.split('/');
      let brandName = pathnameSplit[pathnameSplit.length-1];
      if (brandName) {
        brandName = brandName.split('?')[0];
        brandName = brandName.split('#')[0];
        brandName = decodeURIComponent(brandName);
      }
      if (
        decodedPathname.includes(`/category/${category.name}/brand/${brand.name}`) &&
        brand.name === brandName
      ) {
        active = true;
      }
    }
    return active;
  };

  isPrivateStackActive = (privateStackName) => {
    let active = false;
    if (privateStackName) {
      const decodedPathname = decodeURIComponent(this.props.location.pathname);
      if (decodedPathname.includes(`/private-stack/${privateStackName}`)) {
        active = true;
      }
    }
    return active;
  };

  isReportActive = (report) => {
    let active = false;
    if (report) {
      const decodedPathname = decodeURIComponent(this.props.location.pathname);
      const pathnameSplit = this.props.location.pathname.split('/');
      let reportName = pathnameSplit[pathnameSplit.length-1];
      if (reportName) {
        reportName = reportName.split('?')[0];
        reportName = reportName.split('#')[0];
        reportName = decodeURIComponent(reportName);
      }
      if (
        decodedPathname.includes(`/reports/${report.name}`) &&
        report.name === reportName
      ) {
        active = true;
      }
    }
    return active;
  };

  guidedTourCallback = (event) => {
    const { action, index, status, type } = event;
    if (
      index === 5 &&
      type === 'step:after'
    ) {
      // after reports step, route to first category to continue tour
      if (this.props.categories.length > 0) {
        this.setState(() => ({ guidedTourRun: false }));
        history.push(`/category-dashboard/${encodeURIComponent(this.props.categories[0].name)}#analysis`);
        setTimeout(() => {
          this.setState(() => ({
            guidedTourRun: true,
            guidedTourStepIndex: 6
          }));
        }, 1000);
      }
    } else if (
      index === 7 &&
      type === 'step:after'
    ) {
      // after category tour, route to brand to continue tour
      if (this.props.categories.length > 0 && this.props.categories[0].product_brands.length > 0) {
        this.setState(() => ({ guidedTourRun: false }));
        history.push(`/category/${encodeURIComponent(this.props.categories[0].name)}/brand/${encodeURIComponent(this.props.categories[0].product_brands[0].name)}#analysis`);
        setTimeout(() => {
          this.setState(() => ({
            guidedTourRun: true,
            guidedTourStepIndex: 8
          }));
        }, 1000);
      }
    } else if (type === 'step:after') {
      this.setState(() => ({ guidedTourStepIndex: index + 1 }));
    }
  };

  onLeftMenuNavClick = (navName) => {
    createUserActivityLog(
      this.props.user,
      navName
    );
  };

  render () {
    return (
      <div className="left-menu bg-dark">
        <div
          className="mt-2 ml-4 ellipsis"
          style={{ fontSize: '1.5rem' }}
        >
          Brand<b className="text-bops-blue">Ops</b>
          <FontAwesomeIcon
            className="float-right m-2"
            icon={faHome}
            color="#ffffff"
            onClick={() => this.onHomeSelection()}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <hr className="my-2" style={{ backgroundColor: '#fff' }}/>
        { this.props.user.id &&
          <div className="pre-scrollable left-menu-content pb-4">
            <div>
              <Navbar variant="dark">
                <Nav className="flex-column">
                  <NavLink
                    className="nav-link pt-0"
                    to="/leaderboard"
                    onClick={() => this.onLeftMenuNavClick('Leaderboard')}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faRankingStar}/>
                    Leaderboard
                  </NavLink>
                  <NavLink
                    className="nav-link pt-0"
                    to="/objectives"
                    onClick={() => this.onLeftMenuNavClick('Objectives')}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faTasks}/>
                    Objectives
                  </NavLink>
                  <NavLink
                    className="nav-link pt-0"
                    to="/home"
                    isActive={() => false}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faBell}/>
                    Alerts
                  </NavLink>
                </Nav>
              </Navbar>
            </div>
            <div id="competitive-landscape">
              <hr className="my-2" style={{ backgroundColor: '#fff' }}/>
              <div className="ml-4">
                <b>Competitive Landscape</b>
              </div>
              <hr className="my-2" style={{ backgroundColor: '#fff' }}/>
              <Navbar className="px-0" variant="dark">
                <Nav className="flex-column w-100">
                  { this.props.categories.map((category, i) => {
                      return (
                        <CategoryMenuAccordion
                          key={`lmc${category.id}-${category.category_type}`}
                          category={category}
                          companyId={this.props.user.customerId}
                          location={this.props.location}
                          maintainHashToBrand={this.props.maintainHashToBrand}
                          maintainHashToCategory={this.props.maintainHashToCategory}
                          isCategoryActive={this.isCategoryActive}
                          isBrandActive={this.isBrandActive}
                          position={i}
                        />
                      )
                    })
                  }
                </Nav>
              </Navbar>
            </div>
            <div id="internal-stack">
              <hr className="my-2" style={{ backgroundColor: '#fff' }}/>
              <div className="ml-4">
                <b>Internal Stack</b>
              </div>
              <hr className="my-2" style={{ backgroundColor: '#fff' }}/>
              <Navbar className="px-0" variant="dark" style={{ fontSize: '.875rem' }}>
                <Nav className="flex-column w-100">
                  <div
                    className={`nav-link p-0${this.isPrivateStackActive('category-detected-technology')?' active bg-bops-blue':' text-bops-blue'}`}
                    onClick={() => {
                      history.push(`/private-stack/category-detected-technology`);
                      createUserActivityLog(
                        this.props.user,
                        'Detected Technology'
                      );
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className={`pl-4 ellipsis${!this.isPrivateStackActive('category-detected-technology')?' text-bops-blue':''}`}>
                      Detected Technology
                    </div>
                  </div>
                  <div
                    className={`nav-link p-0${this.isPrivateStackActive('google-ads')?' active bg-bops-blue':''}`}
                    onClick={() => {
                      history.push(`/private-stack/google-ads`);
                      createUserActivityLog(
                        this.props.user,
                        'Google Ads'
                      );
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="pl-4 ellipsis">
                      Google Ads
                    </div>
                  </div>
                  <div
                    className={`nav-link p-0${this.isPrivateStackActive('google-analytics')?' active bg-bops-blue':''}`}
                    onClick={() => {
                      history.push(`/private-stack/google-analytics`);
                      createUserActivityLog(
                        this.props.user,
                        'Google Analytics'
                      );
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="pl-4 ellipsis">
                      Google Analytics
                    </div>
                  </div>
                  <div
                    className={`nav-link p-0${this.isPrivateStackActive('google-search-console')?' active bg-bops-blue':''}`}
                    onClick={() => {
                      history.push(`/private-stack/google-search-console`);
                      createUserActivityLog(
                        this.props.user,
                        'Google Search Console'
                      );
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="pl-4 ellipsis">
                      Google Search Console
                    </div>
                  </div>
                  <div
                    className={`nav-link p-0${this.isPrivateStackActive('google-search-terms')?' active bg-bops-blue':''}`}
                    onClick={() => {
                      history.push(`/private-stack/google-search-terms`);
                      createUserActivityLog(
                        this.props.user,
                        'Google Search Terms'
                      );
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="pl-4 ellipsis">
                      Google Search Terms
                    </div>
                  </div>
                  { this.props.companySetupChecklist.hubspot_setup &&
                    <div
                      className={`nav-link p-0${this.isPrivateStackActive('hubspot')?' active bg-bops-blue':''}`}
                      onClick={() => {
                        history.push(`/private-stack/hubspot`);
                        createUserActivityLog(
                          this.props.user,
                          'HubSpot'
                        );
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className="pl-4 ellipsis">
                        HubSpot
                      </div>
                    </div>
                  }
                  { this.props.companySetupChecklist.marketo_setup &&
                    <React.Fragment>
                      <div
                        className={`nav-link p-0${this.isPrivateStackActive('marketo-email-campaigns')?' active bg-bops-blue':''}`}
                        onClick={() => {
                          history.push(`/private-stack/marketo-email-campaigns`);
                          createUserActivityLog(
                            this.props.user,
                            'Marketo Email Campaigns'
                          );
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <div className="pl-4 ellipsis">
                          Marketo Email Campaigns
                          <Badge
                            className="ml-2"
                            variant="warning"
                            pill
                          >
                            Beta
                          </Badge>
                        </div>
                      </div>
                      <div
                        className={`nav-link p-0${this.isPrivateStackActive('marketo-landing-pages')?' active bg-bops-blue':''}`}
                        onClick={() => {
                          history.push(`/private-stack/marketo-landing-pages`);
                          createUserActivityLog(
                            this.props.user,
                            'Marketo Landing Pages'
                          );
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <div className="pl-4 ellipsis">
                          Marketo Landing Pages
                          <Badge
                            className="ml-2"
                            variant="warning"
                            pill
                          >
                            Beta
                          </Badge>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                  <div
                    className="nav-link p-0"
                    onClick={() => {
                      history.push(`/settings`);
                      createUserActivityLog(
                        this.props.user,
                        'Settings'
                      );
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="pl-4 ellipsis">
                      More...
                    </div>
                  </div>
                </Nav>
              </Navbar>
            </div>
            <div id="custom-reports">
              <hr className="my-2" style={{ backgroundColor: '#fff' }}/>
              <div className="ml-4">
                <b>Reports</b>
              </div>
              <hr className="my-2" style={{ backgroundColor: '#fff' }}/>
              <Navbar className="px-0" variant="dark" style={{ fontSize: '.875rem' }}>
                <Nav className="flex-column w-100">
                  { this.props.customReports.map(report => {
                      return (
                        <div
                          key={`cust-r-${report.id}`}
                          className={`nav-link p-0${this.isReportActive(report)?' active bg-bops-blue':''}`}
                          onClick={() => {
                            history.push(`/reports/${encodeURIComponent(report.name)}`);
                            createUserActivityLog(
                              this.props.user,
                              'Reports'
                            );
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <div className="pl-4 ellipsis">
                            {report.name}
                          </div>
                        </div>
                      )
                    })
                  }
                </Nav>
              </Navbar>
            </div>
            <div>
              <hr className="my-2" style={{ backgroundColor: '#fff' }}/>
              <Navbar variant="dark">
                <Nav className="flex-column">
                  <NavLink
                    className="nav-link pt-0"
                    to="/quick-search"
                    onClick={() => this.onLeftMenuNavClick('Quick Search')}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faSearch}/>
                    Quick Search
                  </NavLink>
                  <NavLink
                    className="nav-link pt-0"
                    to="/saas-index"
                    onClick={() => this.onLeftMenuNavClick('SaaS Index')}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faRectangleList}/>
                    SaaS Index
                  </NavLink>
                </Nav>
              </Navbar>
            </div>
            <div>
              <hr className="my-2" style={{ backgroundColor: '#fff' }}/>
              <div className="ml-4">
                <b>Setup</b>
              </div>
              <hr className="my-2" style={{ backgroundColor: '#fff' }}/>
              <Navbar variant="dark">
                <Nav className="flex-column">
                  { isAuthenticatedAdmin(this.props.user) &&
                    <div>
                      <NavLink
                        className="nav-link pt-0"
                        to="/settings"
                        onClick={() => this.onLeftMenuNavClick('Settings')}
                      >
                        <FontAwesomeIcon className="mr-2" icon={faCogs}/>
                        Settings
                      </NavLink>
                    </div>
                  }
                  <NavLink
                    className="nav-link pt-0"
                    to="/feedback"
                    onClick={() => this.onLeftMenuNavClick('Feedback')}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faCommentDots}/>
                    Feedback
                    <Badge
                      className="ml-2"
                      variant="warning"
                      pill
                    >
                      Beta
                    </Badge>
                  </NavLink>
                  <NavLink
                    className="nav-link pt-0"
                    to="/support"
                    onClick={() => this.onLeftMenuNavClick('Support')}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faEnvelope}/>
                    Support
                  </NavLink>
                </Nav>
              </Navbar>
            </div>
          </div>
        }

        { this.props.showNewUserGuidedTour &&
          <NewUserGuidedTour
            myCategoryBrands={this.props.myCategoryBrands}
          />
        }
        <RefreshAlertContainer />
      </div>
    );
  }
};

export default withRouter(LeftMenu);
