import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import ClipLoader from 'react-spinners/ClipLoader';
import Tooltip from 'reactjs-popup';
import VideoViewsGraph from './VideoViewsGraph';
import VideoInteractionsGraph from './VideoInteractionsGraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class YoutubeVideos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedVideo: undefined,
      videoCaptures: [],
      videoCapturesLoading: false,
      sortType: 'date'
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.youtubeVideos.length > 0) {
      this.setState(() => ({
        selectedVideo: this.props.youtubeVideos[0]
      }), () => this.fetchVideoCaptures());
    } else {
      this.setState(() => ({
        selectedVideo: undefined,
        videoCaptures: []
      }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.youtubeVideos !== this.props.youtubeVideos) {
      // check if selected video is still available in video results
      let selectedVideoFound = false;
      if (this.state.selectedVideo) {
        for (const v of this.props.youtubeVideos) {
          if (this.state.selectedVideo.youtube_video_id === v.youtube_video_id) {
            selectedVideoFound = true;
          }
        }
      }

      if (!selectedVideoFound) {
        let selectedVideo;
        if (this.props.youtubeVideos.length > 0) {
          selectedVideo = this.props.youtubeVideos[0];
        }
        this.setState(
          () => ({ selectedVideo }),
          () => this.fetchVideoCaptures()
        );
      }
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  fetchVideoCaptures = () => {
    if (this.state.selectedVideo) {
      this.setState(() => ({ videoCapturesLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/youtube-video-captures?youtube_video_id=${this.state.selectedVideo.youtube_video_id}`,
        HEADERS
      ).then(response => {
        const videoCaptures = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            videoCaptures,
            videoCapturesLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to load youtube video captures...');
        if (this.state.isMounted) {
          this.setState(() => ({
            videoCaptures: [],
            videoCapturesLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onVideoSelect = (selectedVideo) => {
    this.setState(() => ({
      selectedVideo
    }), () => this.fetchVideoCaptures());
  };

  onSortTypeChange = (event) => {
    const sortType = event.currentTarget.dataset.sortType;
    if (this.props.sortType !== sortType) {
      this.props.changeSortType(sortType);
    }
  };

  prettyDuration = (seconds) => {
    if (seconds != null) {
      const duration = moment.duration(seconds, 'seconds');
      if (seconds >= 3600) {
        // display hour as well
        return moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
      } else {
        return moment.utc(duration.as('milliseconds')).format('m:ss');
      }
    }
    return '';
  };

  render () {
    return (
      <div>
        { this.props.youtubeVideos.length === 0 &&
          <div>
            No videos are available.
          </div>
        }
        { this.props.youtubeVideos.length > 0 &&
          <Row>
            <Col xs={4}>
              <ButtonGroup className="rounded border">
                <Button
                  variant={`${this.props.sortType === 'date' ? 'info': 'light'}`}
                  size="sm"
                  data-sort-type="date"
                  onClick={this.onSortTypeChange}
                >Date</Button>
                <Button
                  variant={`${this.props.sortType === 'views' ? 'info': 'light'}`}
                  size="sm"
                  data-sort-type="views"
                  onClick={this.onSortTypeChange}
                >Views</Button>
              </ButtonGroup>
              <div className="border rounded bg-white">
                <div
                  className="pre-scrollable"
                  style={{ minHeight: '600px', maxHeight: '600px' }}
                >
                  { this.props.youtubeVideos.map(video => {
                      return (
                        <Tooltip
                          key={`v${video.youtube_video_id}`}
                          position="right center"
                          on="hover"
                          contentStyle={{ borderRadius: '5px', width: '500px' }}
                          trigger={
                            <div
                              className={
                                (
                                  this.state.selectedVideo &&
                                  this.state.selectedVideo.youtube_video_id === video.youtube_video_id
                                ) ?
                                  'px-2 bg-primary text-light rounded' :
                                  `px-2${!video.brand_types.includes('product') ? ' bg-grey': ''}`
                              }
                              onClick={() => this.onVideoSelect(video)}
                              style={{
                                cursor: 'pointer',
                                overflowX: 'hidden'
                              }}
                            >
                              <Row className="py-2">
                                <Col xs={4}>
                                  { video.thumbnail_url &&
                                    <div className="my-1 text-center align-middle">
                                      <img
                                        src={video.thumbnail_url}
                                        style={{ height: 'auto', maxWidth: '88px' }}
                                      />
                                    </div>
                                  }
                                </Col>
                                <Col>
                                  <div style={{ fontSize: '.875rem' }}>
                                    <div>
                                      <b>{video.title}</b>
                                    </div>
                                    <div>
                                      {moment(video.published_at).format('MM/DD/YY')}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <hr className="m-0"/>
                            </div>
                          }
                        >
                          <div style={{ fontSize: '.875rem' }}>
                            <Row noGutters>
                              <Col xs={4}>
                                <div className="text-center">
                                  <img
                                    src={video.thumbnail_url}
                                    onClick={() => window.open(video.video_url, '_blank')}
                                    style={{
                                      height: 'auto',
                                      maxWidth: '88px',
                                      cursor: 'pointer'
                                    }}
                                  />
                                </div>
                                <div className="text-center">
                                  <div>{this.prettyDuration(video.duration_in_seconds)}</div>
                                  <div>{moment(video.published_at).format('MM/DD/YY')}</div>
                                  <div>{`${numberWithCommas(video.views_count)} views`}</div>
                                  <div>{`${numberWithCommas(video.comments_count)} comments`}</div>
                                  <div>
                                    <Row>
                                      <Col>
                                        <div className="float-right">
                                          <FontAwesomeIcon
                                            className="mr-1"
                                            icon={faThumbsUp}
                                            color="#5f666d"
                                          />
                                          {numberWithCommas(video.likes_count)}
                                        </div>
                                      </Col>
                                      <Col>
                                        <div className="float-left">
                                          <FontAwesomeIcon
                                            className="mr-1"
                                            icon={faThumbsDown}
                                            color="#5f666d"
                                          />
                                          {numberWithCommas(video.dislikes_count)}
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div
                                  onClick={() => window.open(video.video_url, '_blank')}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <b>{video.title}</b>
                                </div>
                                <div style={{ maxHeight: '126px', overflow: 'hidden' }}>
                                  {video.description}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Tooltip>
                      )
                    })
                  }
                </div>
              </div>
            </Col>
            <Col>
              <div className="mt-4">
                { this.state.videoCapturesLoading &&
                  <div className="m-4 text-center">
                    <ClipLoader size={100}/>
                  </div>
                }
                { !this.state.videoCapturesLoading &&
                  <div>
                    { this.state.selectedVideo && this.state.selectedVideo.brand_types &&
                      <div className="mb-1">
                        { this.state.selectedVideo.brand_types.includes('company') &&
                          <Badge
                            className="mr-1"
                            variant="warning"
                            pill
                          >
                            Corporate
                          </Badge>
                        }
                        { this.state.selectedVideo.brand_types.includes('product') &&
                          <Badge
                            className="mr-1"
                            variant="warning"
                            pill
                          >
                            Product
                          </Badge>
                        }
                        { this.state.selectedVideo.brand_types.includes('portfolio') &&
                          <Badge
                            className="mr-1"
                            variant="warning"
                            pill
                          >
                            Portfolio
                          </Badge>
                        }
                      </div>
                    }
                    <VideoViewsGraph
                      captureData={this.state.videoCaptures}
                      category={this.props.category}
                      brand={this.props.brand}
                    />
                    <VideoInteractionsGraph
                      captureData={this.state.videoCaptures}
                      category={this.props.category}
                      brand={this.props.brand}
                    />
                  </div>
                }
              </div>
            </Col>
          </Row>
        }
      </div>
    );
  }
};
