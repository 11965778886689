import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import ClipLoader from 'react-spinners/ClipLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartArea,
  faClipboardList,
  faDesktop,
  faEnvelope,
  faFileAlt,
  faFolderOpen,
  faGlobe,
  faMagnifyingGlass,
  faNewspaper,
  faPodcast,
  faStarHalfAlt,
  faUsers,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import {
  faTwitter,
  faFacebook,
  faLinkedin,
  faInstagram,
  faYoutube,
  faGoogle,
  faReddit,
} from '@fortawesome/free-brands-svg-icons';
import { intToShorthandString, numberWithCommas, getPercentChange, round } from '../../utils/numbers';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Label,
  LabelList,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer
} from 'recharts';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';
import history from '../../routers/history';

export default class MetricDistributionSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'distribution',
      metricHistogramData: {},
      metricHistogramTicks: [],
      metricHistrogramText: undefined,
      metricHistogramDataLoading: true,
      metricZScoreData: {},
      metricZScoreDataLoading: false,
      preLabel: (
          this.props.metric &&
          this.props.metric.metric_label &&
          this.props.metric.metric_label.includes(' Cost')
        ) ? '$': '',
      pillarColor: '#4171cd',
      pillarGradiantId: 'brandPresenceDistribution',
      channelSiteIconMap: {
        1: { icon: faTwitter, color: '#08a0e9' }, // Twitter
        2: { icon: faFacebook, color: '#4267B2' }, // Facebook
        3: { icon: faLinkedin, color: '#2867B2' }, // LinkedIn
        4: { icon: faInstagram, color: '#c13584' }, // Instagram
        5: { icon: faYoutube, color: '#ff0000' }, // YouTube
        6: { icon: faUsers, color: '##6c757d' }, // All Social
        7: { icon: faStarHalfAlt, color: '#ffd700' }, // All Reviews
        8: { icon: faEnvelope, color: '#6c757d' }, // Campaigns
        9: { icon: faEnvelope, color: '#6c757d' }, // Email Collector
        10: { icon: faNewspaper, color: '#6c757d' }, // News Sites
        11: { icon: faGlobe, color: '#6c757d' }, // Site Crawler
        12: { icon: faFileAlt, color: '#6c757d' }, // Blog Crawler
        13: { icon: faGoogle, color: '#4285f4' }, // Google Analytics
        14: { icon: faDesktop, color: '#6c757d' }, // Webinar Detector
        15: { icon: faMagnifyingGlass, color: '#6c757d' }, // SERP-API
        16: { icon: faGlobe, color: '#6c757d' }, // External-AWIS
        17: { icon: faClipboardList, color: '#6c757d' }, // Survey
        18: { icon: faGlobe, color: '#6c757d' }, // Moz
        19: { icon: faGlobe, color: '#6c757d' }, // Semrush
        20: { icon: faPodcast, color: '#6c757d' }, // Podcast
        21: { icon: faUser, color: '#6c757d' }, // Customer
        22: { icon: faChartArea, color: '#6c757d' }, // Industry Analysts
        23: { icon: faReddit, color: '#ff4500' }, // Reddit
        24: { icon: faStarHalfAlt, color: '#ffd700' }, // Employer-Reviews
        25: { icon: faFolderOpen, color: '#6c757d' }, // Other
      },
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.setPillarColor();
    this.fetchHistogramData();
    // this.fetchZScoreHistogramData();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.metric.id !== this.props.metric.id ||
      prevProps.audienceProfileId !== this.props.audienceProfileId ||
      prevProps.customerId !== this.props.customerId
    ) {
      if (
        this.props.metric &&
        this.props.metric.metric_label &&
        this.props.metric.metric_label.includes(' Cost')
      ) {
        this.setState(() => ({ preLabel: '$' }));
      } else {
        this.setState(() => ({ preLabel: '' }));
      }
      this.setPillarColor();
      this.fetchHistogramData();
      // this.fetchZScoreHistogramData();
    }
  };

  setPillarColor = () => {
    if (this.props.metric) {
      let pillarColor;
      let pillarGradiantId;
      switch (this.props.metric.stage_name) {
        case 'Digital Presence':
          pillarColor = '#4171cd';
          pillarGradiantId = 'brandPresenceDistribution';
          break;
        case 'Audience Attention':
          pillarColor = '#03ae71';
          pillarGradiantId = 'brandAttentionDistribution';
          break;
        case 'Brand Reputation':
          pillarColor = '#9c7ef4';
          pillarGradiantId = 'brandReputationDistribution';
          break;
        case 'Momentum':
          pillarColor = '#ac968c';
          pillarGradiantId = 'brandMomentumDistribution';
          break;
        default:
          pillarColor = '#4171cd';
          pillarGradiantId = 'brandPresenceDistribution';
          break;
      }
      this.setState(() => ({
        pillarColor,
        pillarGradiantId
      }));
    }
  };

  fetchHistogramData = () => {
    if (this.props.metric && this.props.metric.id) {
      this.setState(() => ({ metricHistogramDataLoading: true }));
      let additionalParams = '';
      if (this.props.audienceProfileId) {
        additionalParams = `&audience_profile_id=${this.props.audienceProfileId}`;
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/brand-metric-histogram-analysis?metric_id=${this.props.metric.id}&company_id=${this.props.customerId}${additionalParams}`,
        HEADERS
      ).then(response => {
        const metricHistogramData = response.data;
        let metricHistogramTicks = [];
        const total = metricHistogramData.sample_size || 1;
        if (metricHistogramData.hist_values) {
          for (const data of metricHistogramData.hist_values) {
            metricHistogramTicks.push(parseInt(data.start_value));
            // evaluate bucket percent
            data.percent = round((data.count / total) * 100, 1);
          }
          // find trailing right 0's
          let trailingZeroIndex;
          for (let i = metricHistogramData.hist_values.length-1; i > 0; i--) {
            const currentData = metricHistogramData.hist_values[i];
            const nextData = metricHistogramData.hist_values[i-1];
            if (
              currentData &&
              currentData.percent === 0 &&
              nextData &&
              nextData.percent === 0
            ) {
              trailingZeroIndex = i;
            } else {
              break;
            }
          }
          // remove trailing right 0's
          if (trailingZeroIndex) {
            metricHistogramData.hist_values = metricHistogramData.hist_values.slice(0, trailingZeroIndex);
            metricHistogramTicks = metricHistogramTicks.slice(0, trailingZeroIndex);
          }
        }
        // evaluate metric text
        let metricHistrogramText;
        if (metricHistogramData.avg_score != null && metricHistogramData.your_value != null) {
          if (
            this.props.metric.is_percent ||
            this.props.metric.metric_label.includes(' Count')
          ) {
            if (this.props.metric.is_percent) {
              metricHistrogramText = (
                <div>
                  The average value is {numberWithCommas(round(metricHistogramData.avg_score, 1))}%, yours is <span style={{ color: '#ff9933' }}>{numberWithCommas(round(metricHistogramData.your_value, 1))}%</span>.
                </div>
              );
            } else {
              metricHistrogramText = (
                <div>
                  The average value is {numberWithCommas(round(metricHistogramData.avg_score, 1))}, yours is <span style={{ color: '#ff9933' }}>{numberWithCommas(round(metricHistogramData.your_value, 1))}</span>.
                </div>
              );
            }
          } else {
            const startingValue = metricHistogramData.avg_score || 1;
            const percentChange = round(getPercentChange(startingValue, metricHistogramData.your_value), 0);
            let audienceLabel;
            switch (this.props.audienceProfileId) {
              case 1:
              case '1':
                audienceLabel = 'small audience';
                break;
              case 2:
              case '2':
                audienceLabel = 'medium audience';
                break;
              case 3:
              case '3':
                audienceLabel = 'large audience';
                break;
              case 'all':
                audienceLabel = 'all audience';
                break;
              default:
                audienceLabel = 'all audience';
            }
            if (percentChange > 0) {
              metricHistrogramText = (
                <div>
                  You are <span style={{ color: 'green' }}>outperforming the average by {numberWithCommas(percentChange)}%</span> in the "{audienceLabel}" cohort.
                </div>
              );
            } else {
              metricHistrogramText = (
                <div>
                  You are <span style={{ color: 'red' }}>underperforming the average by {numberWithCommas(percentChange*-1)}%</span> in the "{audienceLabel}" cohort.
                </div>
              );
            }

          }
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            metricHistogramData,
            metricHistogramTicks,
            metricHistrogramText,
            metricHistogramDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch metrics histogram data');
        if (this.state.isMounted) {
          this.setState(() => ({
            metricHistogramData: {},
            metricHistogramTicks: [],
            metricHistrogramText: undefined,
            metricHistogramDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchZScoreHistogramData = () => {
    if (this.props.metric && this.props.metric.id) {
      this.setState(() => ({ metricZScoreDataLoading: true }));
      let additionalParams = '';
      if (this.props.audienceProfileId) {
        additionalParams = `&audience_profile_id=${this.props.audienceProfileId}`;
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/brand-metric-z-score-analysis?metric_id=${this.props.metric.id}&company_id=${this.props.customerId}${additionalParams}`,
        HEADERS
      ).then(response => {
        const metricZScoreData = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            metricZScoreData,
            metricZScoreDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch metrics z score data');
        if (this.state.isMounted) {
          this.setState(() => ({
            metricZScoreData: {},
            metricZScoreDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        <div>
          <OverlayTrigger
            trigger="hover"
            placement="right"
            overlay={
              <Popover>
                <Popover.Content>
                  {this.props.metric.description}
                </Popover.Content>
              </Popover>
            }
          >
            <span className="font-weight-bold" style={{ fontSize: '1.5rem' }}>
              {this.props.metric.metric_label}
            </span>
          </OverlayTrigger>
          <div className="d-inline-block ml-3 text-muted" style={{ fontSize: '.875rem' }}>
            Monthly
          </div>
        </div>
        <div className="mt-2" style={{ fontSize: '.875rem' }}>
          { this.state.channelSiteIconMap[this.props.metric.channel_site_id] &&
            <FontAwesomeIcon
              className="mr-2"
              icon={this.state.channelSiteIconMap[this.props.metric.channel_site_id].icon}
              color={this.state.channelSiteIconMap[this.props.metric.channel_site_id].color}
            />
          }
          { !this.state.channelSiteIconMap[this.props.metric.channel_site_id] &&
            <FontAwesomeIcon
              className="mr-2"
              icon={faFolderOpen}
              color="6c757d"
            />
          }
          {this.props.metric.site_name}
        </div>
        <div className="p-2">
          { this.state.metricHistogramDataLoading &&
            <div className="text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !this.state.metricHistogramDataLoading &&
            <div>
              { (!this.state.metricHistogramData.hist_values || this.state.metricHistogramData.hist_values.length === 0) &&
                <div>
                  { this.state.metricHistogramData.sample_size_below_limit &&
                    <React.Fragment>
                      { this.state.metricHistogramData.is_participant_in_benchmark_program &&
                        <Alert className="d-inline-block" variant="warning">
                          Benchmarks not available at this time.
                        </Alert>
                      }
                      { !this.state.metricHistogramData.is_participant_in_benchmark_program &&
                        <Alert className="d-inline-block" variant="warning">
                          Private data is only available to benchmark program participants.
                        </Alert>
                      }
                    </React.Fragment>
                  }
                  { (!this.state.metricHistogramData || !this.state.metricHistogramData.sample_size_below_limit) &&
                    <Alert className="d-inline-block" variant="danger">
                      Error from API
                    </Alert>
                  }
                </div>
              }
              { (this.state.metricHistogramData.hist_values && this.state.metricHistogramData.hist_values.length > 0) &&
                <div>
                  <ResponsiveContainer width="99%" height={200}>
                    <AreaChart
                      data={this.state.metricHistogramData.hist_values}
                      margin={{
                        top: 20,
                        left: 24,
                        right: 20
                      }}
                    >
                      <defs>
                        <linearGradient id="brandPresenceDistribution"  x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#4171cd" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#4171cd" stopOpacity={0.2}/>
                        </linearGradient>
                        <linearGradient id="brandAttentionDistribution"  x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#03ae71" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#03ae71" stopOpacity={0.2}/>
                        </linearGradient>
                        <linearGradient id="brandReputationDistribution"  x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#9c7ef4" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#9c7ef4" stopOpacity={0.2}/>
                        </linearGradient>
                        <linearGradient id="brandMomentumDistribution"  x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#ac968c" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#ac968c" stopOpacity={0.2}/>
                        </linearGradient>
                      </defs>
                      <XAxis
                        dataKey="start_value"
                        type="number"
                        tick={{ fontSize: 12 }}
                        ticks={this.state.metricHistogramTicks}
                        tickFormatter={(value) => {
                          return numberWithCommas(value);
                        }}
                        domain={this.state.metricHistogramTicks.length > 0 ?
                          [this.state.metricHistogramTicks[0], this.state.metricHistogramTicks[this.state.metricHistogramTicks.length-1]]:
                          ['auto', 'auto']
                        }
                        axisLine={false}
                      />
                      <YAxis
                        dataKey="percent"
                        tick={{ fontSize: 12 }}
                        domain={[0,100]}
                        hide={true}
                      />
                      <Area
                        name="Percent"
                        type="monotone"
                        dataKey="percent"
                        stroke={this.state.pillarColor}
                        fillOpacity={1}
                        fill={`url(#${this.state.pillarGradiantId})`}
                      >
                        {/*
                          <LabelList
                            dataKey="percent"
                            position="top"
                            formatter={(value) => {
                              return `${value}%`;
                            }}
                            style={{ fontSize: '.75rem' }}
                          />
                        */}
                      </Area>
                      { this.state.metricHistogramData.avg_score != null &&
                        <ReferenceLine
                          x={this.state.metricHistogramData.avg_score}
                          stroke="#cccccc"
                          strokeWidth={2}
                        >
                          <Label
                            value="▼"
                            offset={0}
                            position="insideTop"
                            style={{
                              fill: '#cccccc',
                              fontSize: '.875rem'
                            }}
                          />
                          <Label
                            value="Average"
                            position="top"
                            style={{
                              fill: '#cccccc',
                              fontSize: '.875rem'
                            }}
                          />
                        </ReferenceLine>
                      }
                      { this.state.metricHistogramData.your_value != null &&
                        <ReferenceLine
                          x={this.state.metricHistogramData.your_value}
                          stroke="#ff9933"
                          strokeWidth={2}
                        >
                          <Label
                            value="▼"
                            offset={0}
                            position="insideTop"
                            style={{
                              fill: '#ff9933',
                              fontSize: '.875rem'
                            }}
                          />
                          <Label
                            value="You"
                            position="right"
                            style={{
                              fill: '#ff9933',
                              fontSize: '.875rem'
                            }}
                          />
                        </ReferenceLine>
                      }
                    </AreaChart>
                  </ResponsiveContainer>
                  { this.state.metricHistrogramText &&
                    <div className="py-2 text-center">
                      {this.state.metricHistrogramText}
                    </div>
                  }
                </div>
              }
            </div>
          }
        </div>
      </div>
    );
  }
};
