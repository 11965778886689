export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

export const capitalizeWords = (string) => {
  if (string) {
    return string.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
  }
};

export const beautifyVariables = (string) => {
  if (string) {
    let foundVar = false; let i = 0;
    for (const char of string) {
      if(char == "$") {
        string = string.replace(char, "{");
        foundVar = true;
      }
      if(foundVar == true && (char == " " || char == "?")) {
        string = string.substring(0, i) + string.substring(i).replace(char, "}"+char);
        foundVar = false;
      }
      i++;
    }
    return string;
  }
}
