import React from 'react';
import axios from 'axios';
import moment from 'moment';
import GeneralSummaryGraph from '../graphs/GeneralSummaryGraph';
import { round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class AverageReviewScores extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reviewScoreData: [],
      reviewScoreDataLoading: false,
      categoryAverageScoreData: [],
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getReviews();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.topicId !== this.props.topicId ||
      prevProps.allMonthsChecked !== this.props.allMonthsChecked
    ) {
      this.getReviews();
    }
  }

  getReviews = () => {
    this.setState(() => ({ reviewScoreDataLoading: true }));
    let allMonthsStartDateParam = '';
    if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
      const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
      if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
        allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
      }
    }
    axios.get(
      `${LISTEN_ENDPOINT}/api/topics/${this.props.topicId}/review-score-trend${allMonthsStartDateParam ? `?start-date=${allMonthsStartDateParam}` : ''}`,
      HEADERS
    ).then(response => {
      let reviewScoreData = response.data.monthly_stats;
      const reviewAvgScoreType = response.data.avg_review_score ? response.data.avg_review_score.score_type : undefined;
      for (const reviewScore of reviewScoreData) {
        if (reviewScore.avg_review_score) {
          reviewScore.avg_review_score = round(reviewScore.avg_review_score, 1);
        }
      }
      // merge category average data into original data source
      reviewScoreData = reviewScoreData.map((x, i) => {
        return (Object.assign({}, x, this.state.categoryAverageScoreData[i]))
      });

      if (this.state.isMounted) {
        this.setState(() => ({
          reviewScoreData,
          reviewAvgScoreType,
          reviewScoreDataLoading: false
        }));
      }
    }).catch(error => {
      console.error('Error loading review score trend...');
      this.setState(() => ({
        reviewScoreData: [],
        reviewAvgScoreType: undefined,
        reviewScoreDataLoading: false
      }));
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });

    if (this.props.category) {
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=pb_reviews_avg_score${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        const categoryAverageScoreData = response.data.category_stats;
        // merge category average data into original data source
        const reviewScoreData = this.state.reviewScoreData.map((x, i) => {
          return (Object.assign({}, x, categoryAverageScoreData[i]))
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            categoryAverageScoreData,
            reviewScoreData
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch category average employer review score data');
        if (this.state.isMounted) {
          this.setState(() => ({ categoryAverageScoreData: [] }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  }

  render () {
    return (
      <div>
        <GeneralSummaryGraph
          title="Avg Product Brand Review Scores"
          metricName="reviews_stars"
          data={this.state.reviewScoreData}
          dataKey="avg_review_score"
          dataLabel="Avg Product Brand Review Scores"
          scoreType={this.state.reviewAvgScoreType}
          loading={this.state.reviewScoreDataLoading}
          showPercentChange={true}
          minDomainValue={0}
          maxDomainValue={5}
          showAverageData={true}
          averageDataKey="pb_reviews_avg_score"
          averageDataLabel="Category Average"
          showTooltip={true}
          contextType="brand"
          contextCategory={this.props.category}
          contextBrand={this.props.brand}
          contextChannel="Product Reviews"
          contextChartName="Avg Product Brand Review Scores"
          shareClassName="product-brand-avg-review-scores-img"
        />
      </div>
    );
  }
};
