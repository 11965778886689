import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import ClipLoader from 'react-spinners/ClipLoader';
import RedditPost from './RedditPost';
import RedditComment from './RedditComment';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class RedditMentionsDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redditMentions: [],
      redditMentionsLoading: false
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchRedditMentions();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.brandId !== this.props.brandId ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter
    ) {
      this.fetchRedditMentions();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  fetchRedditMentions = () => {
    if (this.props.brandId) {
      this.setState(() => ({ redditMentionsLoading: true }));
      const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
      axios.get(
        `${LISTEN_ENDPOINT}/api/reddit-mentions?product_brand_id=${this.props.brandId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        HEADERS
      ).then(response => {
        const redditMentions = response.data;
        // sort for date descending
        redditMentions.sort((a, b) => {
          return moment(b._source.published_date).format('YYYYMMDDHHmmss') - moment(a._source.published_date).format('YYYYMMDDHHmmss')
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            redditMentions,
            redditMentionsLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: unable to load reddit mentions...');
        if (this.state.isMounted) {
          this.setState(() => ({
            redditMentions: [],
            redditMentionsLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        { this.state.redditMentionsLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.redditMentionsLoading &&
          <div>
            { this.state.redditMentions.length === 0 &&
              <div>
                No mentions are available.
              </div>
            }
            { this.state.redditMentions.length > 0 &&
              <div>
                { this.state.redditMentions.map(mention => {
                    if (mention._source.mention_type === 'submission') {
                      return (
                        <RedditPost
                          category={this.props.category}
                          brand={this.props.brand}
                          key={mention._id}
                          post={mention}
                        />
                      )
                    } else if (mention._source.mention_type === 'comment') {
                      return (
                        <RedditComment
                          category={this.props.category}
                          brand={this.props.brand}
                          key={mention._id}
                          comment={mention}
                        />
                      )
                    }
                  })
                }
              </div>
            }
          </div>
        }
      </div>
    );
  }
};
