import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import LoginContainer from '../../containers/common/LoginContainer';
import AdminAuthenticationMessage from '../common/AdminAuthenticationMessage';
import { isAuthenticatedAdmin } from '../../utils/auth';
import SearchEngineTermsForm from './SearchEngineTermsForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default class SearchEngineTerms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  render () {
    return (
      <div className="m-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        <AdminAuthenticationMessage user={this.props.user}/>
        { isAuthenticatedAdmin(this.props.user) &&
          <div
            className="pre-scrollable"
            style={{
              minHeight: 'calc(100vh - 100px)',
              maxHeight: 'calc(100vh - 100px)',
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
          >
            <div
              className="border rounded p-4"
              style={{ backgroundColor: '#e9ecef' }}
            >
              <div className="d-inline-block pr-2">
                <FontAwesomeIcon icon={faSearch} size="2x" color="#454d54" />
              </div>
              <div className="d-inline-block">
                <h4>Search Engine Terms</h4>
              </div>
              <p>
                Run search engine queries to determine who performs best for important keywords.
              </p>
              <hr/>
              <p>
                Create a set of search queries that represent how your customers might search for your brand.
                These queries will be used across all search engines.
              </p>
            </div>
            <SearchEngineTermsForm
              user={this.props.user}
              categories={this.props.categories}
              location={this.props.location}
            />

          </div>
        }
      </div>
    );
  }
};
