import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class CategoryLinkedInTopPosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brandInfoByCompanyIdMap: {},
      categoryTopPosts: [],
      categoryTopPostsLoading: false
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.setBrandInfoMap();
    this.fetchCategoryTopPosts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.setBrandInfoMap();
      this.fetchCategoryTopPosts();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  setBrandInfoMap = () => {
    if (this.props.category && this.props.category.product_brands) {
      const brandInfoByCompanyIdMap = {};
      for (const brand of this.props.category.product_brands) {
        brandInfoByCompanyIdMap[brand.company_id] = brand;
      }
      this.setState(() => ({ brandInfoByCompanyIdMap }));
    }
  };

  fetchCategoryTopPosts = () => {
    if (this.props.category) {
      this.setState(() => ({ categoryTopPostsLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/category-linkedin-top-posts?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}`,
        HEADERS
      ).then(response => {
        const categoryTopPosts = response.data;
        if (this.state.isMounted) {
          this.setState((prevState) => ({
            categoryTopPosts,
            categoryTopPostsLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch category linkedin top posts.');
        if (this.state.isMounted) {
          this.setState((prevState) => ({
            categoryTopPosts: [],
            categoryTopPostsLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        <h5 className="mb-4 p-2 bg-bops-blue text-light">
          Top Content
        </h5>
        { this.state.categoryTopPostsLoading &&
          <div className="m-4 text-center">
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.categoryTopPostsLoading &&
          <Row>
            { this.state.categoryTopPosts.map((post, i) => {
                return (
                  <Col
                    key={`litp-${post.id}`}
                    className="mb-4"
                    xs={12}
                    lg={6}
                  >
                    <Card
                      id={`li-top-post-${i}`}
                      className="h-100"
                    >
                      <Card.Header className="bg-white">
                        <div>
                          <div className="d-inline-block mr-3 mt-2 align-top" style={{ width: '30px' }}>
                            { this.state.brandInfoByCompanyIdMap[post.company_id] &&
                              <img
                                src={this.state.brandInfoByCompanyIdMap[post.company_id].company_favicon_url}
                                width="100%"
                                alt=""
                                crossOrigin="anonymous"
                              />
                            }
                          </div>
                          <div className="d-inline-block">
                            <div className="font-weight-bold">
                              {post.linkedin_company_name || this.state.brandInfoByCompanyIdMap[post.company_id].name}
                            </div>
                            { post.linkedin_company_follower_count &&
                              <div className="text-muted"  style={{ fontSize: '.75rem' }}>
                                {`${numberWithCommas(post.linkedin_company_follower_count)} followers`}
                              </div>
                            }
                            <div className="text-muted"  style={{ fontSize: '.75rem' }}>
                              {moment(post.post_published_date).format('MMM D')}
                            </div>
                          </div>
                          <div className="d-inline-block float-right">
                            <SharePopUpContainer
                              shareElementId={`li-top-post-${i}`}
                              elementLabel={`li-top-post-${i}-img`}
                              position="top"
                              contextType="category"
                              contextCategory={this.props.category}
                              contextBrand={null}
                              contextChannel="LinkedIn"
                              contextProperty="Top Content"
                              contextChartName="Top Content"
                            />
                          </div>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <div>
                          {post.post_content}
                        </div>
                        <div className="mt-2">
                          { post.s3_post_image_url &&
                            <img
                              src={post.s3_post_image_url}
                              alt=""
                              crossOrigin="anonymous"
                              width="100%"
                              onClick={() => window.open(post.post_url, '_blank')}
                              style={{ cursor: 'pointer' }}
                            />
                          }
                          { !post.s3_post_image_url &&
                            <div
                              className="h-100 text-center"
                              onClick={() => window.open(post.post_url, '_blank')}
                              style={{ cursor: 'pointer' }}
                            >
                              <FontAwesomeIcon
                                icon={faImage}
                                color="#d9d9d9"
                                size="6x"
                              />
                            </div>
                          }
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <div>
                          {`${numberWithCommas(post.like_count)} reactions`}
                          <span className="float-right">
                            {`${numberWithCommas(post.comment_count)} comments`}
                          </span>
                        </div>
                      </Card.Footer>
                    </Card>
                  </Col>
                )
              })
            }
          </Row>
        }
      </div>
    );
  }
};
