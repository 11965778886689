import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GeneralSummaryGraph from '../../graphs/GeneralSummaryGraph';
import { intToShorthandString } from '../../../utils/numbers';
import { CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class GoogleAdsCampaignDataTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaignMetrics: [],
      campaignMetricsLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchCampaignMetrics();
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.campaignId !== this.props.campaignId) {
      this.fetchCampaignMetrics();
    }
  };

  fetchCampaignMetrics = () => {
    if (this.props.campaignId) {
      this.setState(() => ({ campaignMetricsLoading: true }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/campaigns/${this.props.campaignId}/campaign-metric-trend`,
        HEADERS
      ).then(response => {
        const campaignMetrics = response.data.monthly_stats;
        for (const metrics of campaignMetrics) {
          metrics.total_clicks_label = metrics.total_clicks;
          if (!metrics.total_impressions) {
            metrics.total_clicks_label = `0 (0%)`;
          } else {
            metrics.total_clicks_label = `${intToShorthandString(metrics.total_clicks)} (${Math.round((metrics.total_clicks / metrics.total_impressions) * 100)}%)`;
          }
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            campaignMetrics,
            campaignMetricsLoading: false,
          }));
        }
      }).catch(error => {
        console.error(`Error: unable to fetch google ad campaign metric data`);
        if (this.state.isMounted) {
          this.setState(() => ({
            campaignMetrics: [],
            campaignMetricsLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="my-2">
        <Row>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Total Cost"
                data={this.state.campaignMetrics}
                dataKey="total_cost"
                dataLabel="Total Cost"
                loading={this.state.campaignMetricsLoading}
                allowDecimals={false}
                costValues={true}
                contextType="brand"
                contextCategory={this.state.category}
                contextBrand={this.state.brand}
                contextChannel="Google Ads"
                contextChartName="Total Cost"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Total Clicks"
                data={this.state.campaignMetrics}
                dataKey="total_clicks"
                dataLabel="Total Clicks"
                barLabelKey="total_clicks_label"
                customValues={true}
                loading={this.state.campaignMetricsLoading}
                allowDecimals={false}
                contextType="brand"
                contextCategory={this.state.category}
                contextBrand={this.state.brand}
                contextChannel="Google Ads"
                contextChartName="Total Clicks"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Total Impressions"
                data={this.state.campaignMetrics}
                dataKey="total_impressions"
                dataLabel="Total Impressions"
                loading={this.state.campaignMetricsLoading}
                allowDecimals={false}
                contextType="brand"
                contextCategory={this.state.category}
                contextBrand={this.state.brand}
                contextChannel="Google Ads"
                contextChartName="Total Impressions"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Total Interactions"
                data={this.state.campaignMetrics}
                dataKey="total_interactions"
                dataLabel="Total Interactions"
                loading={this.state.campaignMetricsLoading}
                allowDecimals={false}
                contextType="brand"
                contextCategory={this.state.category}
                contextBrand={this.state.brand}
                contextChannel="Google Ads"
                contextChartName="Total Interactions"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mb-2">
              <GeneralSummaryGraph
                title="Average Conversions"
                data={this.state.campaignMetrics}
                dataKey="average_conversion"
                dataLabel="Average Conversions"
                loading={this.state.campaignMetricsLoading}
                allowDecimals={false}
                contextType="brand"
                contextCategory={this.state.category}
                contextBrand={this.state.brand}
                contextChannel="Google Ads"
                contextChartName="Average Conversions"
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};
