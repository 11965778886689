import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import ConfirmationModal from '../common/ConfirmationModal';
import MetricDistributionSummaryModal from '../metrics/MetricDistributionSummaryModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas, round } from '../../utils/numbers';
import { getMetricIcon } from '../../utils/metrics';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class ObjectiveMetricGoalForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      goal: '',
      adjustPercent: '',
      weight: 2,
      existingGoals: [],
      saveLoading: false,
      errors: {},
      supportObjectives: [],
      categoryData: {},
      categoryDataLoading: false,
      benchmarkData: {},
      benchmarkDataLoading: false,
      confirmationModalOpen: false,
      metricDistributionModalOpen: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.setForm();
    this.fetchCategoryData();
    this.fetchBenchmarkData();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.brandMetricId !== this.props.brandMetricId ||
      prevProps.brandGoalsMap !== this.props.brandGoalsMap
    ) {
      this.setForm();
    }
    if (
      prevProps.brandMetricId !== this.props.brandMetricId ||
      prevProps.category !== this.props.category
    ) {
      this.fetchCategoryData();
      this.fetchBenchmarkData();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  setForm = () => {
    if (
      this.props.brandGoalsMap &&
      this.props.goalPeriod &&
      this.props.brandGoalsMap[this.props.goalPeriod.label] &&
      this.props.brandGoalsMap[this.props.goalPeriod.label][this.props.brandMetricId]
    ) {
      this.setState(() => ({
        goal: this.props.brandGoalsMap[this.props.goalPeriod.label][this.props.brandMetricId].goal,
        adjustPercent: '',
        weight: this.props.brandGoalsMap[this.props.goalPeriod.label][this.props.brandMetricId].weight,
        existingGoals: this.props.brandGoalsMap[this.props.goalPeriod.label][this.props.brandMetricId].existingGoals,
      }));
    } else {
      this.setState(() => ({
        goal: '',
        adjustPercent: '',
        weight: 2,
        saveLoading: false,
        existingGoals: [],
      }));
    }
    if (this.props.objectivesAndMetrics) {
      // add other objectives this metric supports
      const supportObjectives = [];
      for (let objective of this.props.objectivesAndMetrics) {
        for (let metric of objective.metrics) {
          if (metric.metric_id === this.props.brandMetricId) {
            supportObjectives.push(objective);
          }
        }
      }
      this.setState(() => ({ supportObjectives }));
    }
  };

  fetchCategoryData = () => {
    if (this.props.category && this.props.brand && this.props.brandMetricId) {
      this.setState(() => ({ categoryDataLoading: true }));
      const formattedDate = moment().format('YYYY-MM-DD');
      axios.get(
        `${LISTEN_ENDPOINT}/api/brand-metric-values-goals-quarterly?private_category_id=${this.props.category.id}&product_brand_id=${this.props.brand.id}&brand_metric_id=${this.props.brandMetricId}&current_date=${formattedDate}`,
        HEADERS
      ).then(response => {
        const categoryData = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            categoryData,
            categoryDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch category data');
        if (this.state.isMounted) {
          this.setState(() => ({
            categoryData: {},
            categoryDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchBenchmarkData = () => {
    if (this.props.brandMetricId && this.props.category) {
      this.setState(() => ({ benchmarkDataLoading: true }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/metrics-benchmark-percentiles?audience_profile_id=${this.props.category.audience_profile_id}&brand_metric_id=${this.props.brandMetricId}&percentile=99&percentile=75&percentile=50`,
        HEADERS
      ).then(response => {
        const benchmarkData = {};
        for (let data of response.data) {
          if (data.percentile === 99) {
            benchmarkData.top_1 = data.value;
          } else if (data.percentile === 75) {
            benchmarkData.top_25 = data.value;
          } else if (data.percentile === 50) {
            benchmarkData.top_50 = data.value;
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            benchmarkData,
            benchmarkDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: failed to fetch benchmark data');
        if (this.state.isMounted) {
          this.setState(() => ({
            benchmarkData: {},
            benchmarkDataLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  onGoalChange = (event) => {
    const goal = event.currentTarget.value.replace(/[^\d.]/g, '');
    this.setState(() => ({ goal }));
  };

  onAdjustPercentChange = (event) => {
    const adjustPercent = event.currentTarget.value;
    if (!isNaN(adjustPercent)) {
      let goal = this.state.goal;
      if (this.state.categoryData.our_achieved_result_prior_quarter) {
        goal = Math.round(this.state.categoryData.our_achieved_result_prior_quarter + (this.state.categoryData.our_achieved_result_prior_quarter * (adjustPercent * .01)));
      }
      this.setState(() => ({
        adjustPercent,
        goal,
        errors: {},
      }));
    } else {
      this.setState(() => ({
        adjustPercent,
        errors: { adjustPercent: true },
      }));
    }
  };

  onWeightChange = (event) => {
    const weight = event.currentTarget.value;
    this.setState(() => ({ weight }));
  };

  onSave = () => {
    this.setState(() => ({ saveLoading: true }));
    if (this.state.supportObjectives.length > 1 && this.state.existingGoals.length > 0) {
      this.setState(() => ({ confirmationModalOpen: true }));
    } else {
      this.saveCustomerObjectiveMetricGoal();
    }
  };

  closeConfirmationModal = () => {
    this.setState(() => ({ 
      confirmationModalOpen: false,
      saveLoading: false,
    }));
  };

  isValidForm = () => {
    const errors = {};
    let errorMessage = '';
    if (!this.state.goal || isNaN(this.state.goal)) {
      errors.goal = true;
      errorMessage = 'Must provide a valid goal.'
    }

    this.setState(() => ({
      errors,
      errorMessage,
      confirmationModalOpen: false,
    }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  saveCustomerObjectiveMetricGoal = () => {
    if (this.isValidForm()) {
      if (this.props.goalPeriod && this.props.goalPeriod.startDate) {
        const brandMetricGoal = {
          brand_metric_id: this.props.brandMetricId,
          product_brand_id: this.props.brandId,
          goal_weight: this.state.weight,
        }
        // counts divide amoungst months, otherwise same value for each month
        if (this.props.brandMetric.metric_aggregation_type === 'count') {
          brandMetricGoal.goal_value = Number(this.state.goal) / 3;
        } else {
          brandMetricGoal.goal_value = this.state.goal;
        }
        // save 3 goals due to periods being quarters and goals being monthly
        const saveGoalRequests = [];
        let date = moment(this.props.goalPeriod.startDate).endOf('month');
        if (this.state.existingGoals.length > 0) {
          // update
          for (let i = 0; i < 3; i++) {
            // set date
            brandMetricGoal.goal_date = date.format('YYYY-MM-DD HH:mm:ss');
            let found = false;
            for (let existingGoal of this.state.existingGoals) {
              if (moment.utc(existingGoal.goal_date).format('YYYY-MM-DD HH:mm:ss') === brandMetricGoal.goal_date) {
                found = true;
                saveGoalRequests.push(
                  axios.put(
                    `${LISTEN_ENDPOINT}/api/brand-metric-goals/${existingGoal.id}`,
                    brandMetricGoal,
                    HEADERS
                  ).then(response => {
                    const goal = response.data;
                    return { goal };
                  }).catch(error => {
                    console.error('Error: failed to save brand metric goal');
                    if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
                      dispatchReportError(error.response);
                    }
                    return { goal: false };
                  })
                )
              }
            }

            // existing goal not found, create new goal
            if (!found) {
              saveGoalRequests.push(
                axios.post(
                  `${LISTEN_ENDPOINT}/api/brand-metric-goals`,
                  brandMetricGoal,
                  HEADERS
                ).then(response => {
                  const goal = response.data;
                  return { goal };
                }).catch(error => {
                  console.error('Error: failed to save brand metric goal');
                  if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
                    dispatchReportError(error.response);
                  }
                  return { goal: false };
                })
              );
            }
            // update date
            date = moment(date).add(1, 'month').endOf('month');
          }
        } else {
          // post
          for (let i = 0; i < 3; i++) {
            // set date
            brandMetricGoal.goal_date = date.format('YYYY-MM-DD HH:mm:ss');
            // save monthly goal
            saveGoalRequests.push(
              axios.post(
                `${LISTEN_ENDPOINT}/api/brand-metric-goals`,
                brandMetricGoal,
                HEADERS
              ).then(response => {
                const goal = response.data;
                return { goal };
              }).catch(error => {
                console.error('Error: failed to save brand metric goal');
                if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
                  dispatchReportError(error.response);
                }
                return { goal: false };
              })
            )
            // update date
            date = moment(date).add(1, 'month').endOf('month');
          }
        }

        Promise.all(saveGoalRequests).then(responses => {
          this.props.refreshGoals();
          this.setState(() => ({ saveLoading: false }));
        });
      }
    } else {
      this.setState(() => ({ saveLoading: false }));
    }
  };

  openMetricDistributionModal = (selectedMetricName) => {
    if (selectedMetricName) {
      this.setState(() => ({
        selectedMetricName,
        metricDistributionModalOpen: true,
      }));
    }
  };

  closeMetricDistributionModal = () => {
    this.setState(() => ({
      selectedMetricName: undefined,
      metricDistributionModalOpen: false,
    }));
  };

  render () {
    return (
      <div>
        <div>
          <div>
            <div
              className="d-inline-block"
              style={{
                fontWeight: 'bold',
                fontSize: '1.2rem'
              }}
            >
              {getMetricIcon(this.props.brandMetric.channel_site_id)}
              {' '}
              {this.props.brandMetric.metric_label}
            </div>
            <OverlayTrigger
              trigger="hover"
              placement="top"
              overlay={
                <Popover>
                  <Popover.Content>
                    { this.state.supportObjectives.map((obj, i) => {
                        return (
                          <div key={`co-mf-so-${i}`}>
                            {obj.objective_name}
                          </div>
                        )
                      })
                    }
                  </Popover.Content>
                </Popover>
              }
            >
              <div
                className="ml-3 d-inline-block btn-link"
                style={{ cursor: 'default' }}
              >
                {`Supports ${this.state.supportObjectives.length} ${this.state.supportObjectives.length === 1 ? 'objective' : 'objectives'}`}
              </div>
            </OverlayTrigger>
          </div>
        </div>
        <hr className="mt-2" />
        <Row noGutters>
          <Col xs={6} lg={4}>
            <Form.Group>
              <Form.Label>
                Quarterly Goal
              </Form.Label>
              <Form.Control
                type="text"
                value={numberWithCommas(this.state.goal)}
                onChange={this.onGoalChange}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    this.onSave();
                  }
                }}
                isInvalid={this.state.errors.goal}
                size="sm"
              />
            </Form.Group>
          </Col>
          <Col xs={6} lg={2}>
            <div className="mx-2">
              <Form.Label>
                <OverlayTrigger
                  trigger="hover"
                  placement="top"
                  overlay={
                    <Popover>
                      <Popover.Content>
                        <div>
                          This is an optional tool to help set goals when thinking in terms of percentage gain/declines.
                        </div>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <div style={{ textDecoration: 'underline' }}>
                    Adjust %
                  </div>
                </OverlayTrigger>
              </Form.Label>
              <div>
                <div className="d-inline-block mr-1" style={{ width: '50px' }}>
                  <Form.Control
                    type="number"
                    value={this.state.adjustPercent}
                    onChange={this.onAdjustPercentChange}
                    isInvalid={this.state.errors.adjustPercent}
                    size="sm"
                  />
                </div>
                %
              </div>
            </div>
          </Col>
          <Col xs={6} lg={4}>
            <div className="mx-2">
              <Form.Label>
                <OverlayTrigger
                  trigger="hover"
                  placement="top"
                  overlay={
                    <Popover>
                      <Popover.Content>
                        <div>
                          Medium is twice as much as Low. High is three times as much as Low.
                        </div>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <div style={{ textDecoration: 'underline' }}>
                    Weight
                  </div>
                </OverlayTrigger>
              </Form.Label>
              <Form.Row>
                <Col style={{ flex: '0 0 40px' }}>Low</Col>
                <Col>
                  <div className="mt-1">
                    <Form.Control
                      type="range"
                      value={this.state.weight}
                      onChange={this.onWeightChange}
                      min={1}
                      max={3}
                      step={1}
                    />
                  </div>
                </Col>
                <Col style={{ flex: '0 0 45px' }}>High</Col>
              </Form.Row>
            </div>
          </Col>
          <Col xs={6} lg={2}>
            <Button
              className="mt-4"
              variant="primary"
              onClick={this.onSave}
              size="sm"
              disabled={this.state.saveLoading}
            >
              { this.state.saveLoading &&
                <span className="mr-2">
                  <ClipLoader size="15" color="#ffffff" />
                </span>
              }
              Save
            </Button>
          </Col>
        </Row>
        <Row noGutters>
          <Col xs={12} lg={7}>
            <Card className="h-100">
              <Card.Header className="py-2 bg-bops-blue text-light text-center">
                Category Data (prior quarter)
              </Card.Header>
              <Card.Body className="p-2">
                { this.state.categoryDataLoading &&
                  <div className="m-4 text-center">
                    <ClipLoader size={50}/>
                  </div>
                }
                { !this.state.categoryDataLoading &&
                  <div style={{ fontSize: '.875rem' }}>
                    <div>
                      <div
                        className="d-inline-block text-right"
                        style={{ width: '150px' }}
                      >
                        Our Prior Goal:
                      </div>
                      <div className="d-inline-block ml-2">
                        { this.state.categoryData.our_goal_prior_quarter != null ?
                            numberWithCommas(round(this.state.categoryData.our_goal_prior_quarter, 1)) :
                            '-'
                        }
                      </div>
                    </div>
                    <div>
                      <div
                        className="d-inline-block text-right"
                        style={{ width: '150px' }}
                      >
                        Our Results:
                      </div>
                      <div className="d-inline-block ml-2">
                        { this.state.categoryData.our_achieved_result_prior_quarter != null ?
                            numberWithCommas(round(this.state.categoryData.our_achieved_result_prior_quarter, 1)) :
                            '-'
                        }
                      </div>
                    </div>
                    <div>
                      <div
                        className="d-inline-block text-right"
                        style={{ width: '150px' }}
                      >
                        Best in Category:
                        <div style={{ fontSize: '.6rem', paddingRight: '30px' }}>
                          { this.state.categoryData.category_leader_product_brand_name != null ?
                              `(${this.state.categoryData.category_leader_product_brand_name})` :
                              ''
                          }
                        </div>
                      </div>
                      <div className="d-inline-block ml-2 align-top">
                        { this.state.categoryData.category_leader_achieved_result_prior_quarter != null ?
                            numberWithCommas(round(this.state.categoryData.category_leader_achieved_result_prior_quarter, 1)) :
                            '-'
                        }
                      </div>
                    </div>
                  </div>
                }
              </Card.Body>
            </Card>
          </Col>
          <Col  xs={12} lg={5}>
            <Card className="h-100 ml-2">
              <Card.Header className="py-2 bg-bops-blue text-light text-center">
                <div
                  className="d-inline-block"
                  onClick={this.openMetricDistributionModal}
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}
                >
                  Benchmark Data
                </div>
              </Card.Header>
              <Card.Body className="p-2">
                { this.state.benchmarkDataLoading &&
                  <div className="m-4 text-center">
                    <ClipLoader size={50}/>
                  </div>
                }
                { !this.state.benchmarkDataLoading &&
                  <div style={{ fontSize: '.875rem' }}>
                    <div>
                      <div
                        className="d-inline-block text-right"
                        style={{ width: '75px' }}
                      >
                        Top 1%:
                      </div>
                      <div className="d-inline-block ml-2">
                        { this.state.benchmarkData.top_1 != null ?
                            numberWithCommas(round(this.state.benchmarkData.top_1, 1)) :
                            '-'
                        }
                      </div>
                    </div>
                    <div>
                      <div
                        className="d-inline-block text-right"
                        style={{ width: '75px' }}
                      >
                        At 25%:
                      </div>
                      <div className="d-inline-block ml-2">
                        { this.state.benchmarkData.top_25 != null ?
                            numberWithCommas(round(this.state.benchmarkData.top_25, 1)) :
                            '-'
                        }
                      </div>
                    </div>
                    <div>
                      <div
                        className="d-inline-block text-right"
                        style={{ width: '75px' }}
                      >
                        At 50%:
                      </div>
                      <div className="d-inline-block ml-2">
                        { this.state.benchmarkData.top_50 != null ?
                            numberWithCommas(round(this.state.benchmarkData.top_50, 1)) :
                            '-'
                        }
                      </div>
                    </div>
                  </div>
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <ConfirmationModal
          isOpen={this.state.confirmationModalOpen}
          handleClose={this.closeConfirmationModal}
          confirmationTitle="Save Confirmation"
          confirmationMessage="This save will update the goals of this metric in other objectives as well. Are you sure you want to save?"
          confirm={this.saveCustomerObjectiveMetricGoal}
        />
        <MetricDistributionSummaryModal
          isOpen={this.state.metricDistributionModalOpen}
          handleClose={this.closeMetricDistributionModal}
          metricName={this.props.brandMetric.metric_name}
          audienceProfileId={this.props.category ? this.props.category.audience_profile_id : undefined}
          customerId={this.props.customerId}
        />
      </div>
    );
  }
};
