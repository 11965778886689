import { connect } from 'react-redux';
import MarketoEmailCampaigns from '../../components/emailCampaigns/MarketoEmailCampaigns';
import { updateSelectedMonth } from '../../actions/filters/selectedMonth';

const mapStateToProps = (state) => ({
  user: state.user,
  companySetupChecklist: state.companySetupChecklist,
  myCategoryBrands: state.myCategoryBrands,
  sixMonths: state.sixMonths,
  selectedMonth: state.selectedMonth,
});

const mapDispatchToProps = (dispatch) => ({
  updateSelectedMonth: (selectedMonth) => dispatch(updateSelectedMonth(selectedMonth)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketoEmailCampaigns);
