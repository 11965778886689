import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import ClipLoader from 'react-spinners/ClipLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class SinceEventDateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      eventsLoading: false,
      eventsSearchFilter: '',
      selectedEventId: undefined,
      selectedEvent: undefined,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  onOpen = () => {
    this.setState(() => ({
      events: [],
      eventsLoading: false,
      eventsSearchFilter: '',
      selectedEventId: undefined,
      selectedEvent: undefined,
    }), () => this.fetchEvents());
  };

  fetchEvents = () => {
    if (this.props.user.customerId)
    this.setState(() => ({ eventsLoading: true }));
    axios.get(
      `${LISTEN_ENDPOINT}/api/events-curated?customer_id=${this.props.user.customerId}&is_public=false`,
      HEADERS
    ).then(response => {
      const events = response.data;
      events.sort((a, b) => moment(b.event_created_on).format('YYYYMMDD') - moment(a.event_created_on).format('YYYYMMDD'));
      if (this.state.isMounted) {
        this.setState(() => ({
          events,
          eventsLoading: false,
        }));
      }
    }).catch(error => {
      console.error('Error: failed to load events');
      if (this.state.isMounted) {
        this.setState(() => ({
          events: [],
          eventsLoading: false,
        }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    })
  };

  onEventsSearchFilterChange = (event) => {
    const eventsSearchFilter = event.currentTarget.value;
    this.setState(() => ({ eventsSearchFilter }));
  };

  onEventSelect = (event) => {
    if (this.state.selectedEventId !== event.id) {
      this.setState(() => ({
        selectedEventId: event.id,
        selectedEvent: event,
      }));
    }
  };

  setSinceEventDate = () => {
    if (this.state.selectedEvent) {
      const startDate = moment(this.state.selectedEvent.event_created_on).startOf('day');
      const endDate = moment().endOf('day');
      this.props.updateDates(startDate, endDate);
      this.props.handleClose();
    }
  };

  render () {
    return (
      <Modal
        size="md"
        show={this.props.isOpen}
        onHide={this.props.handleClose}
        onEntering={this.onOpen}
      >
        <Modal.Header
          className="bg-bops-blue text-light"
          closeButton
        >
          <Modal.Title>
            Since Event Date
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { this.state.eventsLoading &&
            <div className="p-4 text-center">
              <ClipLoader size={50}/>
            </div>
          }
          { !this.state.eventsLoading &&
            <div>
              <InputGroup className="mb-2 mr-sm-2">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch}/>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  value={this.state.eventsSearchFilter}
                  onChange={this.onEventsSearchFilterChange}
                  size="sm"
                />
              </InputGroup>
              <div
                className="mt-2 pre-scrollable"
                style={{
                  minHeight: '100px',
                  maxHeight: '200px',
                  overflow: 'auto',
                  overflowX: 'hidden'
                }}
              >
                <ListGroup>
                  { this.state.events.map(event => {
                      if (event.summary.toLowerCase().includes(this.state.eventsSearchFilter.toLowerCase())) {
                        return (
                          <ListGroup.Item
                            key={`sedm-${event.id}`}
                            className={`px-2 py-1${event.id === this.state.selectedEventId ? ' active' : ''}`}
                            onClick={() => this.onEventSelect(event)}
                            style={{
                              fontSize: '.875rem',
                              cursor: 'pointer',
                            }}
                          >
                            <div>
                              {event.summary}
                            </div>
                            <div>
                              {moment(event.event_created_on).format('MM/DD/YYYY')}
                            </div>
                          </ListGroup.Item>
                        )
                      }
                    })
                  }
                </ListGroup>
              </div>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={this.setSinceEventDate}
            disabled={!this.state.selectedEvent}
          >
            Set Since Event Date
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};
