import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SSOSamlSettings from './SSOSamlSettings';

const SSOSamlSettingsWrapper = (props) => {
  return (
    <div className="p-4">
      <Row>
        <Col xs={12} lg={6}>
          <SSOSamlSettings />
        </Col>
      </Row>
    </div>
  );
};

export default SSOSamlSettingsWrapper;
