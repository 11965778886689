import React from 'react';
import axios from 'axios';
import moment from 'moment';
import GeneralSummaryGraph from './GeneralSummaryGraph';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class WebinarsDiscoveredGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      webinarsDiscoveredData: [],
      webinarsDiscoveredScoreType: undefined,
      webinarsDiscoveredDataLoading: false,
      categoryAverageData: [],
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getWebinarsDiscoveredData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.brand !== this.props.brand ||
      prevProps.allMonthsChecked !== this.props.allMonthsChecked
    ) {
      this.getWebinarsDiscoveredData();
    }
  }

  getWebinarsDiscoveredData = () => {
    if (this.props.brand) {
      this.setState(() => ({ webinarsDiscoveredDataLoading: true }));
      let allMonthsStartDateParam = '';
      if (this.props.allMonthsChecked && this.props.allMonthsStartDate) {
        const originalStartDate = moment().subtract(6 * 30, 'days').startOf('month');
        if (moment(this.props.allMonthsStartDate).isBefore(originalStartDate)) {
          allMonthsStartDateParam = this.props.allMonthsStartDate.format('YYYY-MM-DD HH:mm:ss');
        }
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/product-brands/${this.props.brand.id}/webinars-discovered-trend${allMonthsStartDateParam ? `?start-date=${allMonthsStartDateParam}` : ''}`,
        HEADERS
      ).then(response => {
        let webinarsDiscoveredData = response.data.monthly_stats;
        const webinarsDiscoveredScoreType = response.data.discovered_count ? response.data.discovered_count.score_type : undefined;
        // merge category average data into original data source
        webinarsDiscoveredData = webinarsDiscoveredData.map((x, i) => {
          return (Object.assign({}, x, this.state.categoryAverageData[i]))
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            webinarsDiscoveredData,
            webinarsDiscoveredScoreType,
            webinarsDiscoveredDataLoading: false
          }));
        }
      }).catch(error => {
        console.error('Error loading webinars discovered trend...');
        this.setState(() => ({
          webinarsDiscoveredData: [],
          webinarsDiscoveredScoreType: undefined,
          webinarsDiscoveredDataLoading: false
        }));
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });

      if (this.props.category) {
        axios.get(
          `${LISTEN_ENDPOINT}/api/category-avg-stats?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&source_name=webinar_avg_discovered_count${allMonthsStartDateParam ? `&start_date=${allMonthsStartDateParam}` : ''}`,
          HEADERS
        ).then(response => {
          const categoryAverageData = response.data.category_stats;
          // merge category average data into original data source
          const webinarsDiscoveredData = this.state.webinarsDiscoveredData.map((x, i) => {
            return (Object.assign({}, x, categoryAverageData[i]))
          });

          if (this.state.isMounted) {
            this.setState(() => ({
              categoryAverageData,
              webinarsDiscoveredData
            }));
          }
        }).catch(error => {
          console.error('Error: failed to fetch category average webinars discovered data');
          if (this.state.isMounted) {
            this.setState(() => ({ categoryAverageData: [] }));
          }
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
        });
      }
    }
  }

  render () {
    return (
      <div>
        <GeneralSummaryGraph
          title="Discovered Count"
          metricName="web_webinars_discovered_count"
          data={this.state.webinarsDiscoveredData}
          dataKey="discovered_count"
          dataLabel="Discovered Count"
          scoreType={this.state.webinarsDiscoveredScoreType}
          loading={this.state.webinarsDiscoveredDataLoading}
          allowDecimals={false}
          showPercentChange={true}
          showAverageData={true}
          averageDataKey="webinar_avg_discovered_count"
          averageDataLabel="Category Average"
          showTooltip={true}
          contextType="brand"
          contextCategory={this.props.category}
          contextBrand={this.props.brand}
          contextChannel="Webinars"
          contextChartName="Discovered Count"
        />
      </div>
    );
  }
};
