import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClipLoader from 'react-spinners/ClipLoader';
import InfiniteScroll from 'react-infinite-scroller';
import SharePopUpContainer from '../../containers/share/SharePopUpContainer';
import UrlPreview from '../common/UrlPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faLink, faRetweet, faHeart,  } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class TwitterFeed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialLoading: false,
      currentOffset: 0,
      commentLimit: 20,
      commentsTotal: 0,
      comments: [],
      commentIdsMap: {},
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.topicId) {
      this.loadComments(true);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.topicId !== this.props.topicId ||
      prevProps.feedType !== this.props.feedType ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter ||
      prevProps.companyChecked !== this.props.companyChecked ||
      prevProps.portfolioChecked !== this.props.portfolioChecked ||
      prevProps.productChecked !== this.props.productChecked
    ) {
      this.loadComments(true);
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  loadComments = (initialLoad) => {
    if (this.props.topicId && this.props.feedType) {
      const formattedStartDate = this.props.startDateFilter.format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = this.props.endDateFilter.format('YYYY-MM-DD HH:mm:ss');
      let brandLevelsParam = '';
      if (this.props.companyChecked) {
        brandLevelsParam += '&brand_levels=company';
      }
      if (this.props.portfolioChecked) {
        brandLevelsParam += '&brand_levels=portfolio';
      }
      if (this.props.productChecked) {
        brandLevelsParam += '&brand_levels=product';
      }
      let offset;
      if (initialLoad) {
        this.setState(() => ({ initialLoading: true }));
        offset = 0;
      } else {
        offset = this.state.currentOffset;
      }
      axios.get(
        `${LISTEN_ENDPOINT}/api/topics/${this.props.topicId}/twitter-feed?feed_type=${this.props.feedType}&start_date=${formattedStartDate}&end_date=${formattedEndDate}${brandLevelsParam}&limit=${this.state.commentLimit}&offset=${offset}`,
        HEADERS
      ).then(response => {
        let comments = response.data;
        if (this.state.isMounted) {
          if (initialLoad) {
            const commentIdsMap = {};
            for (const c of comments) {
              commentIdsMap[c.id] = true;
            }
            this.setState((prevState) => ({
              comments,
              commentIdsMap,
              currentOffset: prevState.commentLimit,
              initialLoading: false
            }));
          } else {
            const commentIdsMap = this.state.commentIdsMap;
            comments = comments.filter(c => !commentIdsMap[c.id]);
            for (const c of comments) {
              commentIdsMap[c.id] = true;
            }
            this.setState((prevState) => ({
              comments: [...prevState.comments, ...comments],
              commentIdsMap,
              currentOffset: prevState.currentOffset + prevState.commentLimit,
              initialLoading: false
            }));
          }
        }
      }).catch(error => {
        console.error('Error loading comments...');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  parseURLs = (text) => {
    return text.replace(/[A-Za-z]+:\/\/[A-Za-z0-9-_]+\.[A-Za-z0-9-_:%&~\?\/.=]+/g, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };

  parseUsernames = (text) => {
    return text.replace(/[@]+[A-Za-z0-9-_]+/g, (handle) => {
      var username = handle.replace('@','');
      return `<a href="http://twitter.com/${username}" target="_blank">${handle}</a>`;
    });
  };

  parseHashtags = (text) => {
    return text.replace(/[#]+[A-Za-z0-9-_]+/g, function(hashtag) {
      var hashtagUrlEncoded = hashtag.replace('#','%23');
      return `<a href="http://twitter.com/search?q=${hashtagUrlEncoded}" target="_blank">${hashtag}</a>`;
    });
  };

  parseTextForHTMLReplacements = (text) => {
    text = this.parseURLs(text);
    text = this.parseUsernames(text);
    text = this.parseHashtags(text);
    return text;
  };

  getLastUrl = (text) => {
    const urlMatches = text.match(/[A-Za-z]+:\/\/[A-Za-z0-9-_]+\.[A-Za-z0-9-_:%&~\?\/.=]+/g);
    if (urlMatches && urlMatches.length > 0) {
      return urlMatches[urlMatches.length - 1];
    } else {
      return;
    }
  };

  render () {
    return (
      <div>
        { this.state.initialLoading &&
          <div
            className="text-center"
            style={{
              overflowX: 'hidden',
              minHeight: this.props.height ? `${this.props.height}px` : '500px',
              maxHeight: this.props.height ? `${this.props.height}px` : '500px',
            }}
          >
            <ClipLoader size={100}/>
          </div>
        }
        { !this.state.initialLoading &&
          <div
            className="pre-scrollable"
            ref={(ref) => this.parentScrollRef = ref }
            style={{
              overflowX: 'hidden',
              minHeight: this.props.height ? `${this.props.height}px` : '500px',
              maxHeight: this.props.height ? `${this.props.height}px` : '500px',
            }}
          >
            { this.state.comments.length === 0 &&
              <div className="p-4 bg-white border rounded">
                No tweets are available.
              </div>
            }
            { this.state.comments.length > 0 &&
              <InfiniteScroll
                pageStart={0}
                loadMore={() => this.loadComments(false)}
                hasMore={true}
                loader={<div key={0}>Loading ...</div>}
                useWindow={false}
                initialLoad={false}
                threshold={50}
                getScrollParent={() => this.parentScrollRef}
              >
                { this.state.comments.map((comment, i) => {
                    let mediaUrl;
                    let urlPreview;
                    if (comment.tweet.media) {
                      mediaUrl = comment.tweet.media[0].media_url;
                    } else {
                      urlPreview = this.getLastUrl(comment.tweet.tweet_text);
                    }

                    let displayComment = false;
                    if (
                      this.props.feedType === 'owned' &&
                      this.props.twitterUserId &&
                      this.props.twitterUserId !== '0'
                    ) {
                      if (String(comment.tweet.twit_user.twitter_user_id) === String(this.props.twitterUserId)) {
                        displayComment = true;
                      }
                    } else {
                      displayComment = true;
                    }

                    if (displayComment) {
                      return (
                        <div
                          id={`twit-${this.props.feedType}-post-${i}`}
                          key={`c-${comment.id}`}
                          className="mb-2 p-2 border rounded bg-white"
                        >
                          <div>
                            { comment.tweet.twit_user.profile_image_url &&
                              <div className="d-inline-block mr-3 mt-2 align-top">
                                <img
                                  className="rounded-circle"
                                  src={comment.tweet.twit_user.profile_image_url.replace('http://', 'https://')}
                                  alt=""
                                  crossOrigin="anonymous"
                                  style={{ width: '30px' }}
                                />
                              </div>
                            }
                            <div className="d-inline-block">
                              <div className="font-weight-bold">
                                {comment.tweet.twit_user.name}
                              </div>
                              <span>
                                {`@${comment.tweet.twit_user.screen_name}`}
                              </span>
                              <div className="text-muted"  style={{ fontSize: '.75rem' }}>
                                {moment(comment.tweet.tweet_created_at).format('MMM D')}
                              </div>
                            </div>
                            <div className="d-inline-block float-right">
                              <SharePopUpContainer
                                shareElementId={`twit-${this.props.feedType}-post-${i}`}
                                elementLabel={`twit-${this.props.feedType}-post-${i}-img`}
                                position="top"
                                contextType="brand"
                                contextCategory={this.props.category}
                                contextBrand={this.props.brand}
                                contextChannel="Social"
                                contextProperty="Twitter"
                                contextChartName="Tweet"
                              />
                            </div>
                            <div className="d-inline-block mr-2 float-right">
                              <FontAwesomeIcon
                                className="mt-1 float-right"
                                icon={faLink}
                                color="#08a0e9"
                                onClick={() => {
                                  window.open(`https://twitter.com/user/status/${comment.tweet.tweet_id}`, '_blank');
                                }}
                                style={{ cursor: 'pointer' }}
                              />
                            </div>
                            <hr className="mt-1 mb-2" />
                          </div>
                          <div
                            dangerouslySetInnerHTML={{__html: this.parseTextForHTMLReplacements(comment.tweet.tweet_text)}}
                          />
                          { mediaUrl &&
                            <div className="text-center">
                              <img
                                src={mediaUrl}
                                alt=""
                                crossOrigin="anonymous"
                                width="100%"
                              />
                            </div>
                          }
                          { urlPreview &&
                            <div className="my-2">
                              <UrlPreview url={urlPreview} />
                            </div>
                          }
                          <div>
                            <Row>
                              <Col>
                                <FontAwesomeIcon
                                  className="mr-2"
                                  icon={faRetweet}
                                  color="#6c757d"
                                />
                                {comment.tweet.tweet_retweet_count}
                              </Col>
                              <Col>
                                <FontAwesomeIcon
                                  className="mr-2"
                                  icon={faHeart}
                                  color="#6c757d"
                                />
                                {comment.tweet.tweet_favorite_count}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )
                    }
                  })
                }
              </InfiniteScroll>
            }
          </div>
        }
      </div>
    );
  }
};
