import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const OrganicResultDetails = (props) => {
  return (
    <div>
      { props.organicResult &&
        <div className="p-2 w-100 border rounded bg-white">
          { props.organicResult.displayed_link &&
            <div style={{ fontSize: '.75rem' }}>
              {props.organicResult.displayed_link}
            </div>
          }
          { props.organicResult.link &&
            <div style={{ fontSize: '1.2rem' }}>
              <a href={props.organicResult.link} target="_blank">
                {props.organicResult.title}
              </a>
            </div>
          }
          { props.organicResult.snippet &&
            <div>
              {props.organicResult.snippet}
            </div>
          }
          { (Array.isArray(props.organicResult.site_links) && props.organicResult.site_links.length > 0) &&
            <div>
              <hr/>
              <Row>
                { props.organicResult.site_links.map((siteLink, i) => {
                    return (
                      <Col
                        key={`osl-${props.organicResult.id}-${i}`}
                        xs={6}
                        className="mb-4"
                      >
                        <div style={{ fontSize: '1.2rem' }}>
                          <a href={siteLink.link} target="_blank">
                            {siteLink.title}
                          </a>
                        </div>
                        { siteLink.snippets &&
                          <div>
                            {siteLink.snippets}
                          </div>
                        }
                      </Col>
                    )
                  })
                }
              </Row>
            </div>
          }
        </div>
      }
    </div>
  )
};

export default OrganicResultDetails;
