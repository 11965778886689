import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CategoryEmployerReviews from './CategoryEmployerReviews';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { createUserActivityLog } from '../../utils/users';
import history from '../../routers/history';

export default class CategoryCompany extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'employerReviews'
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let selectedTab = hash.indexOf('-')>0 ? hash.substring(hash.indexOf('-')+1) : 'employerReviews';
      this.setState(() => ({ selectedTab }));
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  changeSelectedTab = (selectedTab, subTabName) => {
    history.push(`#company-${selectedTab}`);
    this.setState(() => ({ selectedTab }));
    if (subTabName) {
      createUserActivityLog(
        this.props.user,
        `Category Tabs: Company: ${subTabName}`,
        this.props.category,
      )
    }
  }

  render () {
    return (
      <div className="p-4">
        <div className="mb-4">
          <button
            id="categoryEmployerReviewsButton"
            type="button"
            className= { this.state.selectedTab === 'employerReviews' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('employerReviews', 'Employer Reviews')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon
                icon={faStarHalfAlt}
                color="#6c757d"
              />
            </div>
            <div className="pr-2 d-inline-block">
              Employer Reviews
            </div>
          </button>
        </div>
        <div
          className="mt-2 pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 200px)',
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          { this.state.selectedTab == 'employerReviews' &&
            <div>
              <CategoryEmployerReviews
                user={this.props.user}
                sixMonths={this.props.sixMonths}
                selectedMonth={this.props.selectedMonth}
                updateSelectedMonth={this.props.updateSelectedMonth}
                categoryId={this.props.categoryId}
                categoryType={this.props.category.category_type}
                category={this.props.category}
              />
            </div>
          }
        </div>
      </div>
    );
  }
};
