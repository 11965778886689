import React from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';

export default class CustomerObjectiveMetricForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMetric: null,
      error: ''
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  onMetricSelect = (selectedMetric) => {
    this.setState(() => ({ selectedMetric }));
  }

  saveCustomerObjectiveMetric = () => {
    if (this.props.objectiveId && this.state.selectedMetric) {
      this.setState(() => ({ error: '' }));
      const customerObjectiveMetric = {
        customer_objective_id: this.props.objectiveId,
        brand_metric_id: this.state.selectedMetric.value,
      };
      axios.post(
        `${LISTEN_ENDPOINT}/api/customer-objective-to-metrics`,
        customerObjectiveMetric,
        HEADERS
      ).then(response => {
        this.props.removeObjectiveMetricForm(this.props.id);
        this.props.refreshObjectives();
      }).catch(error => {
        console.log('Error: unable to create customer objective to metric');
        this.setState(() => ({ error: 'Unable to create objective metric.' }));
      });
    } else {
      this.setState(() => ({ error: 'Please select a metric.' }));
    }
  };

  render () {
    return (
      <div>
        <Form.Row>
          <Col>
            <Select
              value={this.state.selectedMetric}
              options={
                this.props.brandMetrics.map(bm => {
                  return ({
                    value: bm.id,
                    label: bm.metric_label,
                    metric: bm
                  })
                })
              }
              onChange={this.onMetricSelect}
              placeholder="Select Metric"
            />
            { this.state.error &&
              <div className="text-danger" style={{ fontSize: '.75rem' }}>
                {this.state.error}
              </div>
            }
          </Col>
          <Col xs={3} lg={2}>
            <div className="mt-1 text-right">
              <Button
                className="px-1 py-0 mr-2"
                size="sm"
                variant="success"
                onClick={this.saveCustomerObjectiveMetric}
              >
                <FontAwesomeIcon icon={faCheck}/>
              </Button>
              <Button
                size="sm"
                variant="danger"
                onClick={() => this.props.removeObjectiveMetricForm(this.props.id)}
                style={{ padding: '0px 6px' }}
              >
                <FontAwesomeIcon icon={faTimes}/>
              </Button>
            </div>
          </Col>
        </Form.Row>
      </div>
    );
  }
};
