import React from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { beautifyVariables } from '../../../utils/strings';
import SelectSingleQuestion from '../../surveys/SelectSingleQuestion';
import SelectMultipleQuestion from '../../surveys/SelectMultipleQuestion';
import FreeFormQuestion from '../../surveys/FreeFormQuestion';
import RateQuestion from '../../surveys/RateQuestion';
import YesNoQuestion from '../../surveys/YesNoQuestion';
import TrueFalseQuestion from '../../surveys/TrueFalseQuestion';

export default class SurveySetup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      errorMessage: ''
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  isValidForm = () => {
    const errors = {};
    let errorMessage = '';
    let selectedCompetitorsCount = 0;
    for (let [key, value] of Object.entries(this.props.selectedCompetitors)) {
      if (value) {
        selectedCompetitorsCount += 1;
      }
    }
    if (selectedCompetitorsCount < 1) {
      errors.selectedCompetitors = true;
      errorMessage = 'Please select competitors for this survey.';
    }

    this.setState(() => ({
      errors,
      errorMessage
    }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  onNextButtonClick = () => {
    if (this.isValidForm()) {
      if (this.props.selectedSurvey) {
        this.props.onChangeSurveyStep('preview');
      } else {
        this.props.createSurvey();
      }
    }
  };

  render () {
    return (
      <div>
        <div className="mb-2">
          <div
            className="d-inline-block pr-2 text-right"
            style={{ width: '120px' }}
          >
            Category
          </div>
          <div className="d-inline-block mr-4">
            <Form.Control
              as="select"
              value={this.props.selectedCategoryId}
              onChange={this.props.onCategoryChange}
              style={{
                minWidth: '200px',
                maxWidth: '300px'
              }}
            >
              { this.props.categories.map(category => {
                  return (
                    <option
                      key={`${category.category_type}-${category.id}`}
                      value={`${category.category_type}-${category.id}`}
                    >
                      {category.name}
                    </option>
                  )
                })
              }
            </Form.Control>
          </div>
          <div className="d-inline-block mr-2">
            My Brand
          </div>
          <div className="d-inline-block">
            <Form.Control
              as="select"
              value={this.props.selectedBrandId}
              onChange={this.props.onBrandChange}
              style={{
                minWidth: '200px',
                maxWidth: '300px'
              }}
            >
              { this.props.brands.map(brand => {
                  if (brand.company_id === this.props.companyId) {
                    return (
                      <option
                        key={`b${brand.id}`}
                        value={brand.id}
                      >
                        {brand.name}
                      </option>
                    )
                  }
                })
              }
            </Form.Control>
          </div>
        </div>
        <div className="mb-2">
          <div
            className="d-inline-block pr-2 text-right"
            style={{ width: '120px' }}
          >
            Template
          </div>
          <div className="d-inline-block">
            <Form.Control
              as="select"
              value={this.props.survey_type}
              onChange={this.props.onSurveyTemplateChange}
            >
              <option value="official brandops survey">
                Official BrandOps Survey
              </option>
            </Form.Control>
          </div>
        </div>
        <div className="ml-4">
          <div className="ml-4">
            Select up to 5 Competitors
          </div>
          <div className="ml-4">
            { this.props.brands.map(brand => {
                if (brand.company_id !== this.props.companyId) {
                  return (
                    <Form.Check
                      key={`sc${brand.id}`}
                      type="checkbox"
                      label={brand.name}
                      checked={this.props.selectedCompetitors[brand.name]?true:false}
                      onChange={(event) => this.props.toggleSelectedCompetitors(brand.name, event.currentTarget.checked)}
                      inline
                    />
                  )
                }
              })
            }
          </div>
          <div className="ml-4">
            <Alert show={Boolean(this.state.errorMessage)} variant="danger">
              {this.state.errorMessage}
            </Alert>
            <Button
              className="mt-2"
              variant="success"
              onClick={this.onNextButtonClick}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    );
  }
};
