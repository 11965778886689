import moment from 'moment';

let date = moment().subtract(1, 'day');
const selectedMonth = {
  label: date.format('MMMM'),
  date
};

export default function selectedMonthReducer(state = selectedMonth, action) {
  switch (action.type) {
    case 'UPDATE_SELECTED_MONTH':
      return action.selectedMonth;
    default:
      return state
  }
};
