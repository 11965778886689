import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

export default class QuarterScrollPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {

  }

  onPreviousQuarterClick = () => {
    if (moment(this.props.selectedQuarter).subtract(3, 'month') >= this.props.minDate) {
      this.props.updateSelectedQuarter(moment(this.props.selectedQuarter).subtract(3, 'month'));
    }
  };

  onNextQuarterClick = () => {
    if (moment(this.props.selectedQuarter).add(3, 'month') <= this.props.maxDate) {
      this.props.updateSelectedQuarter(moment(this.props.selectedQuarter).add(3, 'month'));
    }
  };

  render() {
    return (
      <div className="d-inline-block mx-2">
        <FontAwesomeIcon
          className={
            (!this.props.loading && moment(this.props.selectedQuarter).subtract(3, 'month') >= this.props.minDate) ?
              'mr-2 fa-button align-middle' :
              'mr-2 fa-disabled align-middle'
          }
          icon={faCaretLeft}
          onClick={this.onPreviousQuarterClick}
          style={{ fontSize: '1.5rem' }}
        />
        <div className="d-inline-block text-center align-middle" style={{ width: '90px' }}>
          {`Q${this.props.selectedQuarter.quarter()} - ${this.props.selectedQuarter.year()}`}
        </div>
        <FontAwesomeIcon
          className={
            (!this.props.loading && moment(this.props.selectedQuarter).add(3, 'month') <= this.props.maxDate) ?
              'ml-2 fa-button align-middle' :
              'ml-2 fa-disabled align-middle'
          }
          icon={faCaretRight}
          onClick={this.onNextQuarterClick}
          style={{ fontSize: '1.5rem' }}
        />
      </div>
    );
  }
};
