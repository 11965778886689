import React from 'react';
import CorrelationInsightsTab from './metricCorrelations/CorrelationInsightsTab';
import CorrelationsTab from './metricCorrelations/CorrelationsTab';
// import RegressionTab from './regression/RegressionTab';
// import RawDataTab from './rawData/RawDataTab';
// import CausalFlowTab from './causalFlow/CausalFlowTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faChartGantt,
  // faCodeFork,
  // faDatabase,
  faLightbulb,
  faProjectDiagram
} from '@fortawesome/free-solid-svg-icons';
import { createUserActivityLog } from '../../../utils/users';
import history from '../../../routers/history';

export default class BrandScienceTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'insights'
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    if (this.props.location.hash) {
      // remove hash and set selected tab
      let hash = this.props.location.hash.replace('#', '');
      let hashSplit = hash.split('-');
      let selectedTab = 'insights';
      if (hashSplit.length > 1) {
        selectedTab = hashSplit[1];
      }
      this.setState(() => ({ selectedTab }));
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  changeSelectedTab = (selectedTab, subTabName) => {
    history.push(`#brandscience-${selectedTab}`);
    this.setState(() => ({ selectedTab }));
    if (subTabName) {
      createUserActivityLog(
        this.props.user,
        `Brand Tabs: Brand Science: ${subTabName}`,
        this.props.category,
        this.props.brand,
      )
    }
  }

  render () {
    return (
      <div className="p-4">
        <div className="mb-4">
          <button
            id="insightsButton"
            type="button"
            className= { this.state.selectedTab === 'insights' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('insights', 'Insights')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faLightbulb} />
            </div>
            <div className="d-inline-block">
              Insights
            </div>
          </button>
          <button
            id="correlationsButton"
            type="button"
            className= { this.state.selectedTab === 'correlations' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('correlations', 'Correlations')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faProjectDiagram} />
            </div>
            <div className="d-inline-block">
              Correlations
            </div>
          </button>
          {/*
          <button
            id="regressionButton"
            type="button"
            className= { this.state.selectedTab === 'regression' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('regression', 'Regression')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faChartGantt} />
            </div>
            <div className="d-inline-block">
              Regression
            </div>
          </button>
          <button
            id="rawdataButton"
            type="button"
            className= { this.state.selectedTab === 'rawdata' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('rawdata', 'Raw Data')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faDatabase} />
            </div>
            <div className="d-inline-block">
              Raw Data
            </div>
          </button>
          <button
            id="causalFlowButton"
            type="button"
            className= { this.state.selectedTab === 'causalflow' ? 'btn border-primary bg-white mr-1' : 'btn border bg-white mr-1' }
            onClick={(e) => this.changeSelectedTab('causalflow', 'Causal Flow')}
          >
            <div className="pr-2 d-inline-block">
              <FontAwesomeIcon icon={faCodeFork} />
            </div>
            <div className="d-inline-block">
              Causal Flow
            </div>
          </button>
          */}
        </div>

        <div
          className="mt-2 pre-scrollable"
          style={{
            minHeight: 'calc(100vh - 200px)',
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          { this.state.selectedTab === 'insights' &&
            <CorrelationInsightsTab
              user={this.props.user}
              location={this.props.location}
              categories={this.props.categories}
              categoryId={this.props.categoryId}
              categoryType={this.props.categoryType}
              category={this.props.category}
              brandId={this.props.brandId}
              brand={this.props.brand}
              brandName={this.props.brandName}
            />
          }
          { this.state.selectedTab === 'correlations' &&
            <CorrelationsTab
              user={this.props.user}
              location={this.props.location}
              categories={this.props.categories}
              categoryId={this.props.categoryId}
              categoryType={this.props.categoryType}
              category={this.props.category}
              brandId={this.props.brandId}
              brand={this.props.brand}
              brandName={this.props.brandName}
            />
          }
          {/*
            { this.state.selectedTab === 'regression' &&
            <RegressionTab
              location={this.props.location}
              categoryId={this.props.categoryId}
              categoryType={this.props.categoryType}
              category={this.props.category}
              brandId={this.props.brandId}
              brand={this.props.brand}
              brandName={this.props.brandName}
            />
          }
          { this.state.selectedTab === 'rawdata' &&
            <RawDataTab
              location={this.props.location}
              categoryId={this.props.categoryId}
              categoryType={this.props.categoryType}
              category={this.props.category}
              brandId={this.props.brandId}
              brand={this.props.brand}
              brandName={this.props.brandName}
            />
          }
          { this.state.selectedTab === 'causalflow' &&
            <CausalFlowTab
              location={this.props.location}
              categoryId={this.props.categoryId}
              categoryType={this.props.categoryType}
              category={this.props.category}
              brandId={this.props.brandId}
              brand={this.props.brand}
              brandName={this.props.brandName}
            />
          }
          */}
        </div>
      </div>
    );
  }
};
