import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import CardComponent from '../common/CardComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '../../utils/numbers';
import { shorthandMonth } from '../../utils/dates';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';

export default class EmployeesGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      employeesData: [],
      employeesDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.getEmployeesData();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.category !== this.props.category ||
      prevProps.brand !== this.props.brand
    ) {
      this.getEmployeesData();
    }
  }

  getEmployeesData = () => {
    if (this.props.brand) {
      this.setState(() => ({ employeesDataLoading: true }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/companies/${this.props.brand.company_id}/linkedin-employees-trend`,
        HEADERS
      ).then(response => {
        let employeesData = response.data;

        // update data for cases of lacking data
        for (const [i, employeeData] of employeesData.entries()) {
          // if first data point does not have count, check forward data
          if (i === 0 && employeeData.employees_count === 0) {
            for (const ed of employeesData) {
              if (ed.employees_count > 0) {
                employeesData[0].employees_count = ed.employees_count;
                break;
              }
            }
          } else if (
            i > 0 &&
            employeeData.employees_count === 0 &&
            employeesData[i-1].employees_count !== 0
          ) {
            employeeData.employees_count = employeesData[i-1].employees_count;
          }
        }

        if (this.state.isMounted) {
          this.setState(() => ({
            employeesData,
            employeesDataLoading: false
          }))
        }
      }).catch(error => {
        console.error('Error: unable to fetch company employees data...');
        if (this.state.isMounted) {
          this.setState(() => ({
            employeesData: [],
            employeesDataLoading: false
          }))
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div>
        <Row>
          <Col xs={12} md={6}>
            <CardComponent
              title="Employees"
              icon={
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faUserFriends}
                  color="#4fa2f3"
                />
              }
              body={
                <div>
                  { this.state.employeesDataLoading &&
                    <div className="m-4 text-center">
                      <ClipLoader size={100}/>
                    </div>
                  }
                  { !this.state.employeesDataLoading &&
                    <ResponsiveContainer height={250} width="99%">
                      <LineChart data={this.state.employeesData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="month"
                          tickFormatter={(month) => {
                            return shorthandMonth(month);
                          }}
                        >
                        </XAxis>
                        <YAxis
                          dataKey="employees_count"
                          tick={{ fontSize: 12 }}
                          tickFormatter={(value) => {
                            return numberWithCommas(value);
                          }}
                        />
                        <Tooltip
                          formatter={(value) => numberWithCommas(value)}
                        />
                        <Line
                          name="Employees"
                          type="monotone"
                          dataKey="employees_count"
                          stroke="#007bff"
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  }
                </div>
              }
              contextType="brand"
              contextCategory={this.props.category}
              contextBrand={this.props.brand}
              contextChannel="Company"
              contextProperty="Profile"
              contextChartName="Employees"
            />
          </Col>
          <Col xs={12} md={6}></Col>
        </Row>
      </div>
    );
  }
};
