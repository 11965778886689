import React from 'react';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { round } from '../../utils/numbers';
import { LISTEN_ENDPOINT, HEADERS } from '../../utils/constants';
import { dispatchReportError } from '../../actions/api/errors';


export default class AudiencePress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pressData: [],
      pressDisplayData: [],
      pressDataLoading: false,
      showMoreToggle: false,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchPressData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.brand !== this.props.brand) {
      this.fetchPressData();
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchPressData = () => {
    if (this.props.brand) {
      this.setState(() => ({
        pressDataLoading: true,
        showMoreToggle: false,
      }));
      axios.get(
        `${LISTEN_ENDPOINT}/api/audience-proxy?product_brand_id=${this.props.brand.id}&type=press`,
        HEADERS
      ).then(response => {
        const pressData = [];
        if (
          response.data &&
          response.data.data
        ) {
          for (const press of response.data.data) {
            press.share = round(press.share, 1);
            pressData.push(press);
          }
        }
        // only show up to 5 websites originally
        const pressDisplayData = pressData.slice(0, 5);

        if (this.state.isMounted) {
          this.setState(() => ({
            pressData,
            pressDisplayData,
            pressDataLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch audience press data.');
        if (this.state.isMounted) {
          this.setState(() => ({
            pressData: [],
            pressDisplayData: [],
            pressDataLoading: false
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      })
    }
  };

  onShowMoreToggle = () => {
    this.setState((prevState) => {
      const showMoreToggle = !prevState.showMoreToggle;
      let pressDisplayData = prevState.pressData;
      if (!showMoreToggle) {
        pressDisplayData = prevState.pressData.slice(0, 5);
      }
      return ({
        showMoreToggle,
        pressDisplayData,
      })
    });
  };

  render () {
    return (
      <Card>
        <Card.Header className="bg-white font-weight-bold">
          <FontAwesomeIcon
            className="mr-2"
            icon={faNewspaper}
            color="#4fa2f3"
            style={{ fontSize: '1.2rem' }}
          />
          Press Accounts They Read
        </Card.Header>
        <Card.Body>
          { this.state.pressDataLoading &&
            <div className="m-4 text-center">
              <ClipLoader size={100}/>
            </div>
          }
          { !this.state.pressDataLoading &&
            <div>
              { this.state.pressDisplayData.map((pressData, i) => {
                  return (
                    <div key={`aw-wd-${i}`}>
                      <div
                        className="d-inline-block text-right"
                        style={{ width: '150px' }}
                      >
                        {`${pressData.share}% engage with`}
                      </div>
                      { pressData.profile_image &&
                        <img
                          className="ml-2"
                          src={pressData.profile_image}
                          alt=""
                          crossOrigin="anonymous"
                          style={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%'
                          }}
                          alt=""
                        />
                      }
                      <a
                        className="ml-2"
                        href={pressData.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {pressData.name}
                      </a>
                    </div>
                  )
                })
              }
            </div>
          }
        </Card.Body>
        { (!this.state.pressDataLoading && this.state.pressData.length > 5) &&
          <Card.Footer className="bg-white text-center">
            <div
              onClick={() => this.onShowMoreToggle()}
              style={{ cursor: 'pointer' }}
            >
              {this.state.showMoreToggle ? 'Show Less': 'Show More'}
            </div>
          </Card.Footer>
        }
      </Card>
    );
  }
};
