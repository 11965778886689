import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';
import Cookies from 'universal-cookie';
import CustomerSearchComboBox from './CustomerSearchComboBox';
import RolePersonalizationModal from '../settings/RolePersonalizationModal';
import { withRouter, NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faUser, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import history from '../../routers/history';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showHeader: true,
      superUserHistory: []
    };
  }

  componentDidMount() {
    if (this.props.location.pathname.includes('/reports/public/')) {
      this.setState(() => ({ showHeader: false }));
    }
    const cookies = new Cookies();
    const user = cookies.get('legendaryCXUser');
    if (user) {
      this.props.handleUserCookie(user);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.id !== this.props.user.id) {

    }
  };

  selectNewRepresentedCustomer = (selectedOption) => {
    const cookies = new Cookies();
    const user = cookies.get('legendaryCXUser');
    this.props.handleUpdateRepresentedCustomer(
      user,
      selectedOption.customer.id,
      selectedOption.value
    );
    this.updateSuperUserHistory(selectedOption);
  };

  updateSuperUserHistory = (selection) => {
    // check if selection already in history
    if (this.state.superUserHistory.some(x => x.customer.id === selection.customer.id)) {
      // move selection to beginning of array
      this.setState((prevState) => ({
        superUserHistory: [
          selection,
          ...prevState.superUserHistory.filter(x => x.customer.id !== selection.customer.id)
        ]
      }));
    } else {
      if (this.state.superUserHistory.length < 5) {
        // add selection to beginning of array
        this.setState((prevState) => ({
          superUserHistory: [selection, ...prevState.superUserHistory]
        }));
      } else {
        // add selection to beginning of array, and remove last
        this.setState((prevState) => ({
          superUserHistory: [
            selection,
            ...prevState.superUserHistory.slice(0, prevState.superUserHistory.length-1)
          ]
        }));
      }
    }
  };

  logout = () => {
    const cookies = new Cookies();
    cookies.remove('legendaryCXUser', { path: '/' });
    this.props.handleUserLogout(this.props.user);
  };

  render () {
    return (
      <div
        className={
          this.state.showHeader ?
            '' :
            'd-none'
        }
      >
        <Navbar
          className="main-header"
          expand="lg"
          bg="dark"
          variant="dark"
        >
          <div className="text-light" style={{ fontSize: '1.2rem' }}>
            { this.props.brandHeaderLabel &&
              <div className="d-inline-block mr-2">
                {this.props.brandHeaderLabel},
              </div>
            }
            { this.props.categoryHeaderLabel &&
              <div className="d-inline-block">
                <span
                  className="text-bops-blue"
                  onClick={() => this.props.maintainHashToCategory(this.props.categoryHeaderLabel)}
                  style={{ cursor: 'pointer' }}
                >
                  {this.props.categoryHeaderLabel}
                </span>
              </div>
            }
          </div>
          <Nav className="ml-auto" style={{ fontSize: '1.2rem' }}>
            { !this.props.user.email &&
              <NavLink className="nav-link" to="/login">
                Login
              </NavLink>
            }
            { this.props.user.email &&
              <Dropdown>
                <Dropdown.Toggle
                  id="userMenuDropdown"
                  variant="secondary"
                  style={{ cursor: 'pointer' }}
                >
                  {this.props.user.email}
                  {this.props.user.representedCompanyName &&
                    <span className="ml-1">
                      {`(${this.props.user.representedCompanyName})`}
                    </span>
                  }
                  <FontAwesomeIcon
                    className="ml-2"
                    icon={faUser}
                  />
                  { this.props.alerts.length > 0 &&
                    <Badge
                      className="ml-1 align-top"
                      variant="light"
                      style={{ fontSize: '.6rem' }}
                    >
                      {this.props.alerts.length}
                    </Badge>
                  }
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  { (this.props.user.email === 'admin@brandops.io' && this.props.user.role === 'brandops admin') &&
                    <div className="px-2" style={{ width: '225px' }}>
                      <CustomerSearchComboBox
                        onSelect={this.selectNewRepresentedCustomer}
                        placeholder="Represent Customer"
                      />
                      <div className="mt-2">
                        { this.state.superUserHistory.map((previousSelection, i) => {
                            return (
                              <Dropdown.Item
                                key={`su-ps-${i}`}
                                className="px-2"
                                onClick={() => this.selectNewRepresentedCustomer(previousSelection)}
                              >
                                {previousSelection.value}
                              </Dropdown.Item>
                            )
                          })
                        }
                      </div>
                    </div>
                  }
                  <Dropdown.Divider />
                    <Dropdown.Item onClick={() => history.push('/home#alerts')}>
                      <FontAwesomeIcon icon={faBell}/>
                      {' Alerts'}
                      { this.props.alerts.length > 0 &&
                        <Badge
                          className="ml-1"
                          variant="info"
                        >
                          {this.props.alerts.length}
                        </Badge>
                      }
                    </Dropdown.Item>
                  <Dropdown.Item onClick={this.logout}>
                    <FontAwesomeIcon icon={faSignOutAlt}/>
                    {' Logout'}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            }
          </Nav>
        </Navbar>

        <RolePersonalizationModal
          isOpen={this.props.showRolePersonalizationSetup}
          handleClose={() => {
            this.props.updateShowRolePersonalizationSetup(false);
            // start new user guided tour
            this.props.updateShowNewUserGuidedTour(true);
          }}
          type="firstLogin"
          userId={this.props.user.id}
        />
      </div>
    );
  }
};

export default withRouter(Header);
