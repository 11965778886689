import { connect } from 'react-redux';
import Categories from '../../components/brands/Categories';
import { fetchCategories } from '../../actions/category/categories';

const mapStateToProps = (state) => ({
  user: state.user,
  categories: state.categories
});

const mapDispatchToProps = (dispatch) => ({
  fetchCategories: (customerId) => dispatch(fetchCategories(customerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
