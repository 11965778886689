import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ChannelScoreChart from '../../graphs/ChannelScoreChart';
import { LISTEN_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class ChannelsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      channelsMap: {},
      brandChannelScores: [],
      channelScoresGraphData: {},
      brandScoresDataLoading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchBrandChannelScores();
  }

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.category !== this.props.category ||
      prevProps.brand !== this.props.brand
    ) {
      this.fetchBrandChannelScores();
    }
  };

  fetchBrandChannelScores = () => {
    if (this.props.category && this.props.brand) {
      this.setState(() => ({ brandScoresDataLoading: true }));
      const brandChannelScoreRequests = [];
      // get channels map
      brandChannelScoreRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/channel_categories`,
          HEADERS
        ).then(response => {
          const channelsMap = {};
          for (const channel of response.data) {
            channelsMap[channel.id] = channel;
          }
          return { channelsMap };
        }).catch(error => {
          console.error('Error: unable to fetch channels map');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { channelsMap: {} };
        })
      );

      // get branch channel scores
      const formattedDate = moment().utc().subtract(1, 'day').hours(12).minutes(0).seconds(0).format('YYYY-MM-DD HH:mm:ss');
      brandChannelScoreRequests.push(
        axios.get(
          `${LISTEN_ENDPOINT}/api/brand-channel-scores?category_type=${this.props.category.category_type}&category_id=${this.props.category.id}&product_brand_id=${this.props.brand.id}&score_at=${formattedDate}`,
          HEADERS
        ).then(response => {
          const brandChannelScores = response.data;
          return { brandChannelScores };
        }).catch(error => {
          console.error('Error: unable to fetch brand channel scores');
          if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
            dispatchReportError(error.response);
          }
          return { brandChannelScores: [] };
        })
      );

      Promise.all(brandChannelScoreRequests).then(responses => {
        let channelsMap = {};
        let brandChannelScores = [];
        let channelScoresGraphData = {
          series: [
            { name: 'Channel', type: 'dimension' },
            { name: 'Score', type: 'measure' },
          ],
          records: [],
        };

        for (const response of responses) {
          if (response.channelsMap) {
            channelsMap = response.channelsMap;
          } else if (response.brandChannelScores) {
            brandChannelScores = response.brandChannelScores;
          }
        }

        for (const s of brandChannelScores) {
          if (
            channelsMap[s.channel_category_id] &&
            channelsMap[s.channel_category_id].name &&
            channelsMap[s.channel_category_id].is_public
          ) {
            channelScoresGraphData.records.push([
              channelsMap[s.channel_category_id].name,
              s.score
            ]);
          }
        }
        channelScoresGraphData.records.sort((a, b) => {
          if (a[0] < b[0]) {
            return 1;
          } else if (b[0] < a[0]) {
            return -1;
          } else {
            return 0;
          }
        });

        if (this.state.isMounted) {
          this.setState(() => ({
            channelScoresGraphData,
            brandScoresDataLoading: false
          }));
        }
      });
    }
  };

  render () {
    return (
      <div>
        <ChannelScoreChart
          title="Channel Scores"
          data={this.state.channelScoresGraphData}
          loading={this.state.brandScoresDataLoading}
          category={this.props.category}
          brand={this.props.brand}
          shareClassName="channel-scores-img"
        />
      </div>
    );
  }
};
