import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import LoginContainer from '../../../containers/common/LoginContainer';
import AdminAuthenticationMessage from '../../common/AdminAuthenticationMessage';
import { isAuthenticatedAdmin } from '../../../utils/auth';
import GoogleSearchConsoleForm from './GoogleSearchConsoleForm';
import DeleteConfirmationModal from '../../common/DeleteConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class GoogleSearchConsoleSetup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      googleSearchConsoleAccounts: [],
      googleSearchConsoleAccountsLoading: false,
      googleSearchConsoleForms: [],
      googleOauthRedirectUrl: undefined,
      deleteConfirmationModalOpen: false,
      deleteGoogleSearchConsoleAccountId: undefined,
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchGoogleSearchConsoleAccounts();
    this.fetchGoogleOauthRedirectUrl();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.customerId !== this.props.user.customerId) {
      this.fetchGoogleSearchConsoleAccounts();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchGoogleSearchConsoleAccounts = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({ googleSearchConsoleAccountsLoading: true }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/google-search-console-accounts?company_id=${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        let googleSearchConsoleAccounts = response.data;
        if (this.state.isMounted) {
          this.setState(() => ({
            googleSearchConsoleAccounts,
            googleSearchConsoleAccountsLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch google search console account');
        if (this.state.isMounted) {
          this.setState(() => ({
            googleSearchConsoleAccounts: undefined,
            googleSearchConsoleAccountsLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchGoogleOauthRedirectUrl = () => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/oauth-app-redirect-urls?app_name=google_search_console&url_type=login`,
      HEADERS
    ).then(response => {
      let googleOauthRedirectUrl;
      if (response.data.length > 0) {
        googleOauthRedirectUrl = response.data[0].url;
      }
      if (this.state.isMounted) {
        this.setState(() => ({ googleOauthRedirectUrl }));
      }
    }).catch(error => {
      console.error('Error: unable to fetch google search console oauth redirect');
      if (this.state.isMounted) {
        this.setState(() => ({ googleOauthRedirectUrl: undefined }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  addGoogleSearchConsoleForm = () => {
    const id = Math.random().toString(36).substring(7);
    this.setState((prevState) => ({
      googleSearchConsoleForms: [...prevState.googleSearchConsoleForms, id]
    }));
  };

  removeGoogleSearchConsoleForm = (id) => {
    this.setState((prevState) => ({
      googleSearchConsoleForms: prevState.googleSearchConsoleForms.filter(gscfi => gscfi !== id)
    }));
  };

  connectGoogleSearchConsoleAccounts = () => {
    window.location.replace(this.state.googleOauthRedirectUrl);
  };

  openDeleteConfirmationModal = (gscAccount) => {
    if (gscAccount) {
      this.setState(() => ({
        deleteConfirmationModalOpen: true,
        deleteGoogleSearchConsoleAccountId: gscAccount.id,
      }));
    }
  };

  closeDeleteConfirmationModal = () => {
    this.setState(() => ({
      deleteConfirmationModalOpen: false,
      deleteGoogleSearchConsoleAccountId: undefined,
    }));
  };

  deleteGoogleSearchConsoleAccount = () => {
    if (this.state.deleteGoogleSearchConsoleAccountId) {
      axios.delete(
        `${CONTENT_SCRAPING_ENDPOINT}/api/google-search-console-accounts/${this.state.deleteGoogleSearchConsoleAccountId}`,
        HEADERS
      ).then(response => {
        this.closeDeleteConfirmationModal();
        this.fetchGoogleSearchConsoleAccounts();
      }).catch(error => {
        console.log('Error: failed to delete company google search console account');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="m-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        <AdminAuthenticationMessage user={this.props.user}/>
        { isAuthenticatedAdmin(this.props.user) &&
          <div>
            <div
              className="border rounded p-4"
              style={{ backgroundColor: '#e9ecef' }}
            >
              <div className="d-inline-block pr-2">
                <FontAwesomeIcon
                  icon={faGoogle}
                  size="2x"
                  color="#454d54"
                />
              </div>
              <div className="d-inline-block">
                <h4>Google Search Console</h4>
              </div>
              <p>Connect to Google Search Console for search analysis.</p>
              <hr className="my-4" />
              <p>Gain better insights into your Google search initiatives.</p>
            </div>
            <div className="mt-4">
              <Row>
                <Col>
                  <div>
                  </div>
                  { this.state.googleSearchConsoleAccountsLoading &&
                    <div className="m-4 text-center">
                      <ClipLoader size={100}/>
                    </div>
                  }
                  { !this.state.googleSearchConsoleAccountsLoading &&
                    <div>
                      <div className="mb-1 font-weight-bold">
                        Domains
                        <Button
                          className="ml-2 py-0 px-1"
                          variant="success"
                          onClick={this.addGoogleSearchConsoleForm}
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faPlus}/>
                        </Button>
                      </div>
                      { (this.state.googleSearchConsoleAccounts.length + this.state.googleSearchConsoleForms.length === 0) &&
                        <Alert variant="info">
                          No domain names setup.
                        </Alert>
                      }
                      <ListGroup
                        as="ul"
                        className="pre-scrollable"
                        style={{
                          overflowY: 'auto',
                          overflowX: 'hidden',
                          maxHeight: '300px',
                          width: '100%',
                          fontSize: '.875rem'
                        }}
                      >
                        { this.state.googleSearchConsoleForms.map((id, i) => {
                            return (
                              <ListGroup.Item key={`lpr-n-${id}`}>
                                <GoogleSearchConsoleForm
                                  id={id}
                                  companyId={this.props.user.customerId}
                                  removeGoogleSearchConsoleForm={this.removeGoogleSearchConsoleForm}
                                  refreshGoogleSearchConsoleAccounts={this.fetchGoogleSearchConsoleAccounts}
                                />
                              </ListGroup.Item>
                            )
                          })
                        }
                        { this.state.googleSearchConsoleAccounts.map((gscAccount, i) => {
                          return (
                            <ListGroup.Item key={`gsca-${gscAccount.id}`}>
                              <Row className="mt-1">
                                <Col>
                                  <div>
                                    {gscAccount.domain_name}
                                  </div>
                                </Col>
                                <Col xs={2} className="text-right">
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    onClick={() => this.openDeleteConfirmationModal(gscAccount)}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          )
                          })
                        }
                      </ListGroup>
                      { (this.state.googleSearchConsoleAccounts.length > 0 && !this.state.googleSearchConsoleAccounts[0].encrypted_refresh_token) &&
                        <div className="mt-3">
                          <Alert variant="danger">
                            Google Search Console Setup is not complete, please proceed to connect via OAuth.
                          </Alert>
                          <Button
                            variant="primary"
                            onClick={this.connectGoogleSearchConsoleAccounts}
                          >
                            Connect
                          </Button>
                        </div>
                      }
                    </div>
                  }
                </Col>
                <Col>
                </Col>
              </Row>
            </div>

            <DeleteConfirmationModal
              isOpen={this.state.deleteConfirmationModalOpen}
              handleClose={this.closeDeleteConfirmationModal}
              resourceName="Google Search Console Account setup"
              deleteResource={this.deleteGoogleSearchConsoleAccount}
            />
          </div>
        }
      </div>
    );
  }
};
