import React from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ClipLoader from 'react-spinners/ClipLoader';
import LoginContainer from '../../../containers/common/LoginContainer';
import AdminAuthenticationMessage from '../../common/AdminAuthenticationMessage';
import { isAuthenticatedAdmin } from '../../../utils/auth';
import DeleteConfirmationModal from '../../common/DeleteConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { LISTEN_ENDPOINT, CONTENT_SCRAPING_ENDPOINT, HEADERS } from '../../../utils/constants';
import { dispatchReportError } from '../../../actions/api/errors';

export default class GoogleAdsSetup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      googleAdAccount: undefined,
      googleAdAccountLoading: false,
      adsAccountId: '',
      googleOauthRedirectUrl: undefined,
      deleteConfirmationModalOpen: false,
      deleteGoogleAdAccountId: undefined,
      errors: {},
      errorMessage: ''
    };
  };

  componentDidMount() {
    this.setState(() => ({ isMounted: true }));
    this.fetchGoogleAdAccount();
    this.fetchGoogleOauthRedirectUrl();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.customerId !== this.props.user.customerId) {
      this.fetchGoogleAdAccount();
    }
  };

  componentWillUnmount() {
    this.setState(() => ({ isMounted: false }));
  };

  fetchGoogleAdAccount = () => {
    if (this.props.user.customerId) {
      this.setState(() => ({
        googleAdAccountLoading: true,
        adsAccountId: ''
      }));
      axios.get(
        `${CONTENT_SCRAPING_ENDPOINT}/api/google-ad-accounts?company_id=${this.props.user.customerId}`,
        HEADERS
      ).then(response => {
        let googleAdAccount;
        if (response.data.length > 0) {
          googleAdAccount = response.data[0];
        }
        if (this.state.isMounted) {
          this.setState(() => ({
            googleAdAccount,
            googleAdAccountLoading: false,
          }));
        }
      }).catch(error => {
        console.error('Error: unable to fetch google ad account');
        if (this.state.isMounted) {
          this.setState(() => ({
            googleAdAccount: undefined,
            googleAdAccountLoading: false,
          }));
        }
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  fetchGoogleOauthRedirectUrl = () => {
    axios.get(
      `${LISTEN_ENDPOINT}/api/oauth-app-redirect-urls?app_name=google_ads&url_type=login`,
      HEADERS
    ).then(response => {
      let googleOauthRedirectUrl;
      if (response.data.length > 0) {
        googleOauthRedirectUrl = response.data[0].url;
      }
      if (this.state.isMounted) {
        this.setState(() => ({ googleOauthRedirectUrl }));
      }
    }).catch(error => {
      console.error('Error: unable to fetch google ad oauth redirect');
      if (this.state.isMounted) {
        this.setState(() => ({ googleOauthRedirectUrl: undefined }));
      }
      if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
        dispatchReportError(error.response);
      }
    });
  };

  onAdsAccountIdChange = (event) => {
    const adsAccountId = event.currentTarget.value;
    this.setState(() => ({ adsAccountId }));
  };

  isValidForm = () => {
    const errors = {};
    let errorMessage = '';
    if (!this.state.adsAccountId || this.state.adsAccountId.trim() === '') {
      errors.adsAccountId = true;
      errorMessage = 'Must provide an ads account id.'
    }

    this.setState(() => ({
      errors,
      errorMessage
    }));
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  connectGoogleAdAccount = () => {
    if (this.isValidForm()) {
      const googleAdAccount = {
        company_id: this.props.user.customerId,
        ads_account_id: this.state.adsAccountId,
      };

      axios.post(
        `${CONTENT_SCRAPING_ENDPOINT}/api/google-ad-accounts`,
        googleAdAccount,
        HEADERS
      ).then(response => {
        // redirect to oauth login
        if (this.state.googleOauthRedirectUrl) {
          window.location.replace(this.state.googleOauthRedirectUrl);
        }
      }).catch(error => {
        this.setState(() => ({ errorMessage: 'Failed to create new google ads account' }));
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  openDeleteConfirmationModal = () => {
    if (this.state.googleAdAccount) {
      this.setState(() => ({
        deleteConfirmationModalOpen: true,
        deleteGoogleAdAccountId: this.state.googleAdAccount.id,
      }));
    }
  };

  closeDeleteConfirmationModal = () => {
    this.setState(() => ({
      deleteConfirmationModalOpen: false,
      deleteGoogleAdAccountId: undefined,
    }));
  };

  deleteGoogleAdAccount = () => {
    if (this.state.deleteGoogleAdAccountId) {
      axios.delete(
        `${CONTENT_SCRAPING_ENDPOINT}/api/google-ad-accounts/${this.state.deleteGoogleAdAccountId}`,
        HEADERS
      ).then(response => {
        this.closeDeleteConfirmationModal();
        this.fetchGoogleAdAccount();
      }).catch(error => {
        console.log('Error: failed to delete company google ad account');
        if (error.response && (error.response.status >= 500 || error.response.status >= 404)) {
          dispatchReportError(error.response);
        }
      });
    }
  };

  render () {
    return (
      <div className="m-4">
        { !(this.props.user && this.props.user.id) &&
          <LoginContainer />
        }
        <AdminAuthenticationMessage user={this.props.user}/>
        { isAuthenticatedAdmin(this.props.user) &&
          <div>
            <div
              className="border rounded p-4"
              style={{ backgroundColor: '#e9ecef' }}
            >
              <div className="d-inline-block pr-2">
                <FontAwesomeIcon
                  icon={faGoogle}
                  size="2x"
                  color="#454d54"
                />
              </div>
              <div className="d-inline-block">
                <h4>Google Ads</h4>
              </div>
              <p>Connect to Google Ads for ad analysis.</p>
              <hr className="my-4" />
              <p>Gain better insights into your Google Ad initiatives.</p>
            </div>
            <div className="mt-4">
              <Row>
                <Col>
                  <div>
                  </div>
                  { this.state.googleAdAccountLoading &&
                    <div className="m-4 text-center">
                      <ClipLoader size={100}/>
                    </div>
                  }
                  { !this.state.googleAdAccountLoading &&
                    <div
                      className="pre-scrollable"
                      style={{
                        maxHeight: 'calc(100vh - 400px)',
                        overflow: 'auto'
                      }}
                    >
                      { this.state.googleAdAccount &&
                        <div>
                          <b>Existing Setup</b>
                          <div className="mt-2">
                            <div>
                              {`Google Ad Account ID: ${this.state.googleAdAccount.ads_account_id}`}
                            </div>
                            { !this.state.googleAdAccount.encrypted_refresh_token &&
                              <Alert variant="danger">
                                Google Ad Setup is not complete, please proceed to connect via OAuth <a href={this.state.googleOauthRedirectUrl}>here.</a>
                              </Alert>
                            }
                            <hr />
                            <div className="mt-3">
                              <Button
                                variant="danger"
                                onClick={this.openDeleteConfirmationModal}
                                size="sm"
                              >
                                Delete Google Ad Setup
                              </Button>
                            </div>
                          </div>
                        </div>
                      }
                      { !this.state.googleAdAccount &&
                        <div>
                          <Form>
                            <Form.Group>
                              <Form.Label>Google Ad Account ID</Form.Label>
                              <Form.Control
                                type="text"
                                value={this.state.adsAccountId}
                                onChange={this.onAdsAccountIdChange}
                                isInvalid={this.state.errors.adsAccountId}
                              />
                            </Form.Group>
                            <Alert show={Boolean(this.state.errorMessage)} variant="danger">
                              {this.state.errorMessage}
                            </Alert>
                            <Button
                              variant="primary"
                              onClick={this.connectGoogleAdAccount}
                              disabled={this.state.adsAccountId === '' || !this.state.googleOauthRedirectUrl}
                            >
                              Connect
                            </Button>
                          </Form>
                        </div>
                      }
                    </div>
                  }
                </Col>
                <Col>
                </Col>
              </Row>
            </div>

            <DeleteConfirmationModal
              isOpen={this.state.deleteConfirmationModalOpen}
              handleClose={this.closeDeleteConfirmationModal}
              resourceName="Google Ad Account setup"
              deleteResource={this.deleteGoogleAdAccount}
            />
          </div>
        }
      </div>
    );
  }
};
